import ShiftUtils from "sh-application/utility/ShiftUtils";
import UniqueShiftStore from "../store";
import { action } from "satcheljs/lib/legacy";
import { IUniqueShiftEntity } from "sh-models";

/**
 * Check if a shift is unique, if it is conver to a UniqueShift and add to top of list
 * @param newUniqueShift
 */
export let prependUniqueShift = action("prependUniqueShift")(
    function prependUniqueShift(newUniqueShift: IUniqueShiftEntity) {
        // find a similar shift from the list or undefined
        const similarUniqueShift = UniqueShiftStore().uniqueShifts.find((uniqueShift: IUniqueShiftEntity) => {
            return ShiftUtils.areSimilarUniqueShifts(uniqueShift, newUniqueShift);
        });
        if (similarUniqueShift) {
            // move it to top. Use existing version of unique shift.
            UniqueShiftStore().uniqueShifts.remove(similarUniqueShift);
            UniqueShiftStore().uniqueShifts.unshift(similarUniqueShift);
        } else {
            // add to top
            UniqueShiftStore().uniqueShifts.unshift(newUniqueShift);
        }
    });