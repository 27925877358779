import * as React from "react";
import classNames from "classnames/bind";
import SlotBar from "./SlotBar";
import { SlotBarProps } from "./SlotBar";

const styles = require("./ShiftThemeContainer.scss");

interface ShiftContainerProps {
    /* The color to render in the horizontal bar containing shift color */
    theme: string;
    /* (optional) true if this instance of the component wraps a time off shift */
    isTimeOff?: boolean;
    /* (optional) Shouldn't show cursor in shift view (overview) as it is not actionable. Should be Clickable by default */
    nonClickable?: boolean;
    /* (optional) if this is provided, we will render a thick color bar and include slot information. Used in open shifts */
    slotBarProps?: SlotBarProps;
    /** (optional) additional className */
    className?: string;
}

/**
 * ShiftThemeContainer - Basic wrapper for Shifts. Applies theme style. For open shifts, also renders slot data.
 */
const ShiftThemeContainer: React.FunctionComponent<ShiftContainerProps> = props => {
    const { theme, nonClickable, isTimeOff, slotBarProps } = props;

    let shiftContainerClass: string;
    if (nonClickable && nonClickable === true) {
        shiftContainerClass = styles.shiftContainer;
    } else {
        shiftContainerClass = classNames(styles.shiftContainer, styles.shiftContainerInteractive);
    }

    const containerStyles = classNames(
        shiftContainerClass,
        styles[theme],
        styles.faded,
        props.className,
        { [`${styles.timeOff}`]: isTimeOff});

    return (
        <div className={ containerStyles } >
            { slotBarProps && <SlotBar { ...slotBarProps }/> }
            { props.children }
        </div>
    );
};

export default ShiftThemeContainer;
