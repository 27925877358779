export const instrumentationShiftRequestActionTaken = {
    Cancel: "Cancel",
    Accept: "Accept",
    Decline: "Decline",
    AcceptAndApprove: "AcceptAndApprove",
    DeclineAndDeny: "DeclineAndDeny",
    Approve: "Approve",
    Deny: "Deny",
    OpenShiftRequest: "OpenShiftRequest",
    OpenShiftApprove: "OpenShiftApprove",
    OpenShiftDeny: "OpenShiftDeny"
};