import { mutatorAction } from "satcheljs";
import { TagHeaderViewStateStore } from "../store/schema/TagHeaderViewStateStoreSchema";

/**
 * Sets tag name in the tag header viewstate
 */
export default mutatorAction("setTagName", function setTagName(
    viewState: TagHeaderViewStateStore,
    tagName: string
) {
    if (viewState) {
        viewState.tagName = tagName;
    }
});