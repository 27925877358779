import ShiftRequestDetailsStoreSchema from "./schema/ShiftRequestDetailsStoreSchema";
import { createStore } from "satcheljs";

/**
 * --------------------------------------------------------
 * Don't forget to reinitialize these in resetStore mutator
 * --------------------------------------------------------
*/
const initialShiftRequestDetailsStore: ShiftRequestDetailsStoreSchema = {
    request: null,
    senderMember: null,
    receiverMember: null,
    managerMember: null,
    shift: null,
    otherShift: null,
    shiftNotFound: false,
    otherShiftNotFound: false,
    isDataReady: false,
    userResponseMessage: null
};

const shiftRequestDetailsStore = createStore("shiftRequestDetailsStore", initialShiftRequestDetailsStore)();
export default shiftRequestDetailsStore;

/* export action, mutatorActions and orchestrator for ease of import */
export { default as setShiftInStore } from "../lib/mutatorActions/setShiftInStore";
export { default as setShiftNotFoundInStore } from "../lib/mutatorActions/setShiftNotFoundInStore";
export { default as setSenderMemberInStore } from "../lib/mutatorActions/setSenderMemberInStore";
export { default as setReceiverMemberInStore } from "../lib/mutatorActions/setReceiverMemberInStore";
export { default as setManagerMemberInStore } from "../lib/mutatorActions/setManagerMemberInStore";
export { default as setUserResponseMessageInStore } from "../lib/mutatorActions/setUserResponseMessageInStore";
export { default as setOtherShiftInStore } from "../lib/mutatorActions/setOtherShiftInStore";
export { default as resetRequestDetailsStore } from "../lib/mutatorActions/resetRequestDetailsStore";
export { default as loadDataForRequest } from "../lib/actions/loadDataForRequest";
export { default as setOtherShiftNotFoundInStore } from "../lib/mutatorActions/setOtherShiftNotFoundInStore";
export { default as setIsDataReadyInStore } from "../lib/mutatorActions/setIsDataReadyInStore";

import "../lib/orchestrators/loadDataForRequest";