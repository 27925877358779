import TeamStore from "../store";
import { action } from "satcheljs/lib/legacy";
import { LocationSetting } from "sh-models";

export let updateTimeClockLocation = action("updateTimeClockLocation")(
    /**
     * Updates the time clock location setting of the current team
     */
    function updateTimeClockLocation(timeClockLocation: LocationSetting) {
        if (timeClockLocation) {
            TeamStore().team.locationSettings = [timeClockLocation];
        } else {
            TeamStore().team.locationSettings = [];
        }
    });