import fireAccessibilityAlert from "../actions/fireAccessibilityAlert";
import setAlertText from "../mutators/setAlertText";
import { orchestrator } from "satcheljs";
import { generateUUID } from 'sh-application/utility/utility';

// Duration to wait before initiating rendering of the alert message
// This delay appears to help ensure that screen readers pickup the alert message.  Sometimes messages
// wouldn't always get read out when many things were happening (eg, performing an action from a dialog,
// where the focus and context change and get read out at the same time).
const ACCESSIBILITY_ALERT_INITIAL_DELAY: number = 500;
// Duration for the alert message to be "displayed" by the AccessibilityAlert component
const ACCESSIBILITY_ALERT_DISPLAY_DURATION: number = 3000;
// // Duration to wait before displaying the next alert message after clearing the previous one
// const ACCESSIBILITY_ALERT_RESET_DURATION: number = 500;

/**
 * Fire an accessibility alert message for use by screen readers.
 * The alert message will only be detected by screen readers and will not be visually visible.
 */
export default orchestrator(fireAccessibilityAlert, actionMessage => {
    // The AccessibilityAlert component uses Aria Live Regions to implement these alerts
    //
    // Info about Aria Live Regions:  https://learn.microsoft.com/rgcontents/CONT16998/scormcontent/class/ms/dynamic-updates/notify-users/aria-live.html
    //
    // Aria Live Region requirements:
    // 1.  The live region must be empty before being set with the announcement message
    // 2.  The announcement message text should set in the live region for a short period of time, and then removed.

    setTimeout(() => {
        const alertText = actionMessage.alertText;
        const alertId: string = generateUUID();
        setAlertText(alertId, alertText);
        setTimeout(() => {
            setAlertText(alertId, "");
        }, ACCESSIBILITY_ALERT_DISPLAY_DURATION);
    }, ACCESSIBILITY_ALERT_INITIAL_DELAY );
});

// TODO DCoh:  Check if we need to add aria-atomic=true for iOS voiceover.
// https://www.w3.org/WAI/GL/wiki/Using_ARIA_role%3Dalert_or_Live_Regions_to_Identify_Errors