import { mutatorAction } from "satcheljs";

import schedulesViewStateStore from "../store/store";

/**
 * Sets the 'Profile pictures' schedule view filter value.
 */
export const setShowProfilePicturesView = mutatorAction("SET_SHOW_PROFILE_PICTURES_VIEW", (showProfilePictures: boolean) => {
    schedulesViewStateStore().showProfilePictures = showProfilePictures;
});
