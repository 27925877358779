import * as moment from "moment";
import * as React from "react";
import AutomationUtil from "sh-application/utility/AutomationUtil";
import DateRangeArrows from "./DateRangeArrows";
import DateTimeFormatter from "sh-application/utility/DateTimeFormatter";
import DateUtils from "sh-application/utility/DateUtils";
import PrintComponent from "../../common/PrintComponent";
import StringsStore from "sh-strings/store";
import { AriaRoles } from "owa-accessibility";
import {
    Calendar,
    Callout,
    CommandBarButton,
    DateRangeType,
    DayOfWeek,
    DefaultButton,
    DirectionalHint,
    FocusTrapZone,
    ICalendarStrings,
    IDateFormatting
    } from "@fluentui/react";
import { DEFAULT_DATE_FORMATTING } from '@fluentui/date-time-utilities';
import { fireAccessibilityAlert } from "sh-application/components/accessibilityAlert";
import { getGenericEventPropertiesObject } from "sh-instrumentation";
import { InstrumentationService } from "sh-services";
import { Moment } from "moment";
import { observer } from "mobx-react";
import { ScheduleCalendarType, ScheduleCalendarTypes } from "sh-models";
import { TeamStore } from "sh-team-store";
import { TeamPermissionsStore } from "../../../../sh-stores/sh-team-permissions-store";
import { enforcePastDateLimit, getCalendarProps, getDateRangeArrowsProps } from "./DateNavigator.mappers";

const classNames = require("classnames/bind");
const styles = require("./DateNavigator.scss");
const EnterKeyName = "Enter";

/**
 * The props of the DateNavigator
 */
export interface DateNavigatorProps {
    // The current date selected by the user
    // For day view, the startDate, currentDate and endDate are all equal.
    // For week view, the startDate is the start of the week, the endDate is the end of the week.
    // For month view, the startDate is the start of the month, the endDate is the end of the month.
    currentSelectedDate: Moment;

    // The start date of the current view
    viewStartDate: Moment;

    // The end date of the current view
    viewEndDate: Moment;

    // the time zone that the team is on and the date navigator is set to
    timeZoneOlsonCode: string;

    // The schedule calendar type : Day/Week/Month
    scheduleCalendarType: ScheduleCalendarType;

    // The callback function when a date is selected by the user
    onSelectedDateChange: (selectedDate: Moment, isFromCalendar?: boolean) => void;

    // Call back when the user opens the calendar to select the date
    onDateSelectionStart?: () => void;

    // Call back when the callout is closed the calendar to select the date
    onDateSelectionEnd?: () => void;

    // Starting day of week to use for the calendar date picker
    firstDayOfWeek?: DayOfWeek;

    // Is the date picking enabled.
    // This could be set to false and the header could be reused in publish panel.
    isDatePickerEnabled?: boolean;

    navArrowsId?: string;

    // additional classname to add to the date navigator container
    containerClassName?: string;

    // if true, the date range will only display the Month and Year
    forceMonthDateFormat?: boolean;

    // show today button
    showGoToToday?: boolean;
}

/**
 * The date navigator in the schedule page header.
 */
@observer
export default class DateNavigator extends React.Component<DateNavigatorProps, any> {
    private _currLocale: string;
    // This is the down arrow for invoking date selection.
    private _datePickerInvoker = React.createRef<HTMLDivElement>();
    // The calendar strings for the date picker
    private _calendarStrings: ICalendarStrings;
    private _schedulesStrings: Map<string, string>;
    private _datePickerLocalizedFormats: Map<string, string>;
    private _dateTimeFormatter: IDateFormatting;
    private _dateTimeMomentJsStrings: Map<string, string>;

    constructor(props: DateNavigatorProps) {
        super(props);
        this._currLocale = StringsStore().currentLocale;
        this._datePickerLocalizedFormats = StringsStore().registeredStringModules.get("datePicker").strings;
        this._schedulesStrings = StringsStore().registeredStringModules.get("schedulePage").strings;
        this._dateTimeMomentJsStrings = StringsStore().registeredStringModules.get("dateTimeFormatsMomentJs").strings;
        this._calendarStrings = {
            months: [], shortMonths: [], days: [], shortDays: [],
            goToToday: this._datePickerLocalizedFormats.get("goToToday"),
            prevMonthAriaLabel: this._datePickerLocalizedFormats.get("goToPreviousMonth"),
            nextMonthAriaLabel: this._datePickerLocalizedFormats.get("goToNextMonth"),
            prevYearAriaLabel: this._datePickerLocalizedFormats.get("goToPreviousYear"),
            nextYearAriaLabel: this._datePickerLocalizedFormats.get("goToNextYear")
        };

        this._calendarStrings.days = moment.localeData(this._currLocale).weekdays();
        this._calendarStrings.months = moment.localeData(this._currLocale).months();
        this._calendarStrings.shortMonths = moment.localeData(this._currLocale).monthsShort();

        this._dateTimeFormatter = Object.assign({}, DEFAULT_DATE_FORMATTING, {
            formatMonthYear:
                (date: Date, strings: ICalendarStrings) => {
                    let dateFormatYearMonth = this._dateTimeMomentJsStrings.get("dateFormatYearMonth");
                    return moment(date).format(dateFormatYearMonth);
                }
        });
        // ##TODO
        // The is a temporary fix to get the very short weekdays [For Ex: M T W T F S S].
        // Many locales do not have this format supported. Moment also doesn't support this display format.
        // Need to check with PM/Design on this.
        // .weekdaysMin() API gives a two-letter format of "Mo Tu We Th Fr Sa Su".
        this._calendarStrings.shortDays = moment.localeData(this._currLocale).weekdaysMin().map(weekday => weekday.charAt(0));
        // ravalib TODO: Need to move out of state and use store/actions here.
        this.state = {
            isCalloutVisible: false
        };
    }

    render(): JSX.Element {
        let { isCalloutVisible } = this.state;

        if (!this.props.timeZoneOlsonCode) {
            // We need to know the time zone before displaying anything related to dates
            return (<div className={ styles.dateNavigator } />);
        } else {
            const selectedDate = this.props.currentSelectedDate ? this.props.currentSelectedDate : moment();

            // The DatePicker component uses native Javascript Date, so we need to convert our date time values, which are in the specified timezone,
            // to display versions for the user's browser.  Any date values that are returned from the component need to be converted back to the
            // timezone.
            const selectedDateForCalendarPicker = DateUtils.convertTimezoneDateTimeToDisplayTime(selectedDate.toDate(), this.props.timeZoneOlsonCode);
            const todayDateForCalendarPicker = DateUtils.convertTimezoneDateTimeToDisplayTime(moment.tz(this.props.timeZoneOlsonCode).startOf("day").toDate(), this.props.timeZoneOlsonCode);

            const { permission } = TeamPermissionsStore();
            const { me } = TeamStore();
            const calendarProps = getCalendarProps(this.props, permission, me);
            const dateRangeArrowsProps = getDateRangeArrowsProps(this.props, permission, me);

            return (
                <div className={ classNames(styles.dateNavigatorContainer, this.props.containerClassName) }>
                    <div className={ styles.dateNavigator }>
                        {
                            this.props.isDatePickerEnabled &&
                            <DateRangeArrows
                                id={ this.props.navArrowsId }
                                scheduleCalendarType={ this.props.scheduleCalendarType }
                                onPreviousDateRangeClicked={ this.previousDateRangeClicked }
                                onNextDateRangeClicked={ this.nextDateRangeClicked }
                                {...dateRangeArrowsProps}
                            />
                        }
                        <div
                            className={ styles.dateRangeDisplay }
                            data-automation-id={ AutomationUtil.getAutomationId("header", "QAIDDateRangeDisplay") }>
                            {
                                this.props.isDatePickerEnabled ? // interactive date picker
                                    <div className={ styles.commandBarContainer } ref={ this._datePickerInvoker }>
                                        <CommandBarButton
                                            role={ AriaRoles.navigation }
                                            data-automation-id={ AutomationUtil.getAutomationId("scheduler", "QAIDYearRangeArrow") }
                                            disabled={ false }
                                            checked={ this.state.isCalloutVisible }
                                            onMenuClick={ this.onShowCalloutClicked }
                                            text={ this.getDateRange() }
                                            menuProps={ {
                                                items: []
                                            } }
                                            aria-label={ this._schedulesStrings.get("pickDateAria").format(this.getDateRange(null, true /*isForAria*/)) }
                                            aria-expanded={ isCalloutVisible }
                                        />
                                    </div>
                                    : // non interactive date picker
                                    <span className={ styles.dateRange }>
                                        { this.getDateRange() }
                                    </span>
                            }
                            <PrintComponent>
                                <>
                                    <div className={ styles.printTeamName }>{ TeamStore().name }</div>
                                    {
                                        this.renderPrintedOnDate()
                                    }
                                </>
                            </PrintComponent>

                        </div>
                        {
                            isCalloutVisible && (
                                <Callout
                                    isBeakVisible={ false }
                                    directionalHint={ DirectionalHint.bottomCenter }
                                    className={ styles.dateNavigatorCallout }
                                    target={ this._datePickerInvoker }
                                    onDismiss={ this.onCalloutDismiss }
                                    gapSpace={ 5 }
                                    doNotLayer={ false }
                                    setInitialFocus={ true }>
                                        <FocusTrapZone isClickableOutsideFocusTrap={ true }>
                                            <Calendar showGoToToday={ false }
                                                value={ selectedDateForCalendarPicker }
                                                today={ todayDateForCalendarPicker }
                                                onSelectDate={ this.onSelectDateClick }
                                                isMonthPickerVisible={ false }
                                                strings={ this._calendarStrings }
                                                firstDayOfWeek={ this.props.firstDayOfWeek }
                                                dateRangeType={ this.getDateRangeType() }
                                                dateTimeFormatter = { this._dateTimeFormatter }
                                                {...calendarProps}>
                                            </Calendar>
                                            {
                                                 this.props.showGoToToday &&
                                                    <DefaultButton
                                                        className= { styles.goToToday }
                                                        text= { this._calendarStrings.goToToday }
                                                        onClick={ this.onGoToTodayClicked }
                                                    />
                                            }
                                        </FocusTrapZone>
                                </Callout>
                            )
                        }
                    </div>
                </div>
            );
        }
    }

    /**
     * Returns the date range as a string depending on the schedule calendar type.
     * @param props (optional) Override which props to use - defaults to this.props
     */
    getDateRange(props?: DateNavigatorProps, isForAria: boolean = false): string {
        const p = props || this.props;

        return DateTimeFormatter.getScheduleHeaderDateRangeAsString(
            p.currentSelectedDate ? p.currentSelectedDate : moment(),
            p.viewStartDate,
            p.viewEndDate,
            p.forceMonthDateFormat ? ScheduleCalendarTypes.Month : p.scheduleCalendarType,
            isForAria);
    }

    /**
     * For accessiblity support, on enter key press trigger the date picker selection.
     * @param keyPressEvent The keyboard event which has the key name which was pressed.
     */
    showCallOutKeyPressed = (keyPressEvent: React.KeyboardEvent<HTMLSpanElement>) => {
        if (keyPressEvent.key === EnterKeyName) {
            this.onShowCalloutClicked();
        }
    }

    /**
     * Listen for updates to the props and set the month if the date changes
     * @param prevProps
     */
    componentDidUpdate(prevProps: DateNavigatorProps) {
        // announce the new date range if it changed
        const previousDateRange = this.getDateRange(prevProps);
        const updatedDateRange = this.getDateRange();

        if (previousDateRange !== updatedDateRange) {
            fireAccessibilityAlert(this.getDateRange(null, true /*isForAria*/));
        }

        const { permission } = TeamPermissionsStore();
        const { me } = TeamStore();
        const setDate = (date: Date): void => this.onSelectDateClick(date);

        enforcePastDateLimit(this.props, setDate, permission, me);
    }

    /**
     * Decrements the date range by 1 units (where unit is a day or week or month depending on the calendar type)
     */
    previousDateRangeClicked = (): void => {
        this.changeDateRange(-1);

        let movingRangeMessage = "";
        switch (this.getDateRangeType()) {
            case DateRangeType.Day:
                movingRangeMessage = this._datePickerLocalizedFormats.get("movedToPreviousDayMessage");
                break;
            case DateRangeType.Week:
                movingRangeMessage = this._datePickerLocalizedFormats.get("movedToPreviousWeekMessage");
                break;
            case DateRangeType.Month:
                movingRangeMessage = this._datePickerLocalizedFormats.get("movedToPreviousMonthMessage");
                break;
        }

        // announce we are moving to the previous range - the actual date range will be announced after
        fireAccessibilityAlert(movingRangeMessage);
    }

    /**
     * Increments the date range by 1 units (where unit is a day or week or month depending on the calendar type)
     */
    nextDateRangeClicked = () => {
        this.changeDateRange(1);

        let movingRangeMessage = "";
        switch (this.getDateRangeType()) {
            case DateRangeType.Day:
                movingRangeMessage = this._datePickerLocalizedFormats.get("movedToNextDayMessage");
                break;
            case DateRangeType.Week:
                movingRangeMessage = this._datePickerLocalizedFormats.get("movedToNextWeekMessage");
                break;
            case DateRangeType.Month:
                movingRangeMessage = this._datePickerLocalizedFormats.get("movedToNextMonthMessage");
                break;
        }

        // announce we are moving to the next range - the actual date range will be announced after
        fireAccessibilityAlert(movingRangeMessage);
    }

    /**
     * Call back for when Go To Today is clicked by the user
     */
    onGoToTodayClicked = () => {
        InstrumentationService.logEvent(InstrumentationService.events.ScheduleNavigation,
            [getGenericEventPropertiesObject(InstrumentationService.properties.TypeOfNavigation, InstrumentationService.values.Today)]);
        this.props.onSelectedDateChange(moment().startOf('date'));
        this.onCalloutDismiss();
    }

    /**
     * Call back for a new date selected by the user.
     * @param newDate The new date selected by the user
     */
    onSelectDateClick = (newDate: Date) => {
        if (newDate) {
            InstrumentationService.logEvent(InstrumentationService.events.ScheduleNavigation,
                [getGenericEventPropertiesObject(InstrumentationService.properties.TypeOfNavigation, InstrumentationService.values.GoTo)]);
            // The DatePicker component uses native Javascript Date, so we need to convert our date time values, which are in the specified timezone,
            // to display versions for the user's browser.  Any date values that are returned from the component need to be converted back to the
            // timezone.
            const dateSelectedInTimeZone = DateUtils.convertDisplayTimeToTimezoneDateTime(newDate, this.props.timeZoneOlsonCode);
            const dateSelectedMoment = moment(dateSelectedInTimeZone).tz(this.props.timeZoneOlsonCode);
            this.props.onSelectedDateChange(dateSelectedMoment, true);
        }

        this.onCalloutDismiss();
    }

    /**
     * Sets the visibility of the Callout containing the calendar
     */
    private onShowCalloutClicked = (ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => {
        if (this.props.isDatePickerEnabled) {
            if (this.props.onDateSelectionStart) {
                this.props.onDateSelectionStart();
                setTimeout(this.setCalloutVisible, 100);
            } else {
                this.setCalloutVisible();
            }

            ev.preventDefault();
        }
    }

    setCalloutVisible = () => {
        this.setState({
            isCalloutVisible: !this.state.isCalloutVisible
        });
    }

    /**
     * Hides the Callout containing the Calendar
     */
    private onCalloutDismiss = () => {
        if (this.props.onDateSelectionEnd) {
            this.props.onDateSelectionEnd();
        }

        this.setState({
            isCalloutVisible: false
        });
    }

    /**
     * Gets the DateRangeType (Enum specific to Fabric's Calendar) from the current schedule calendar type
     */
    private getDateRangeType = (): DateRangeType => {
        if (this.props.scheduleCalendarType) {
            switch (this.props.scheduleCalendarType) {
                case ScheduleCalendarTypes.Day:
                    return DateRangeType.Day;
                case ScheduleCalendarTypes.Week:
                    return DateRangeType.Week;
            case ScheduleCalendarTypes.Month:
                    return DateRangeType.Month;
            }
        }

        return DateRangeType.Week;
    }

    /**
     * Sets the current selected date based on the number of units to go forward or back.
     * @param dateRangeUnits The number of units of days/weeks/mnths to forward or back
     */
    private changeDateRange(dateRangeUnits: number) {
        const nextDateRangeStart = DateRangeArrows.calculateNextDateRange(this.props.currentSelectedDate, this.props.scheduleCalendarType, dateRangeUnits);
        if (nextDateRangeStart) {
            this.props.onSelectedDateChange(nextDateRangeStart);
        }
    }

    /**
     * Returns the printed on friendly string used in the scheduler print out.
     * Example: Printed on October, 3 2017 at 5:30pm
     */
    private renderPrintedOnDate(): JSX.Element {
        const currentTime = moment();
        const theString = this._schedulesStrings.get("printedOn").format(currentTime.format('LL'), currentTime.format('LT'));
        return (
            <span className={ styles.printedOn }>
                { theString }
            </span>
        );
    }
}