import IShiftrObjectModelBase from "../IShiftrObjectModelBase";
import { Moment } from "moment";

type UnpaidBreakType = "UnpaidBreak";
type PaidBreakType = "PaidBreakType";
type PaidWorkType = "PaidWork";
export type SubshiftType = UnpaidBreakType | PaidBreakType | PaidWorkType;

type SubshiftStateActiveType = "Active";
type SubshiftStatePendingType = "Pending";
type SubshiftStateDeletedType = "Deleted";
export type SubshiftState = SubshiftStateActiveType | SubshiftStatePendingType | SubshiftStateDeletedType;

export const SubshiftStates = {
    Active: "Active" as SubshiftStateActiveType,
    Pending: "Pending" as SubshiftStatePendingType,
    Deleted: "Deleted" as SubshiftStateDeletedType
};

export const SubshiftTypes = {
    UnpaidBreak: "UnpaidBreak" as UnpaidBreakType,
    PaidBreak: "PaidBreak" as PaidBreakType,
    PaidWork: "PaidWork" as PaidWorkType
};

/**
 * Interface that represents Subshift entity model
 */
export interface IBaseSubshiftEntity<DateTimeType> extends IShiftrObjectModelBase {
    subshiftType: SubshiftType;
    startTime: DateTimeType;
    endTime: DateTimeType;
    state: SubshiftState;
    title: string;
    theme: string;
    code: string;
}

/**
 * The client Subshift entity type
 * Uses moment as the DateTimeType
 */
export type ISubshiftEntity = IBaseSubshiftEntity<Moment>;
export default ISubshiftEntity;
