export type DeepLinkViewType = "schedules" | "requests" | "timeclock";
export const DeepLinkViewTypes = {
    Schedules: "schedules" as DeepLinkViewType,
    Requests: "requests" as DeepLinkViewType,
    Timeclock: "timeclock" as DeepLinkViewType
};

export interface DeepLinkContext {
    view: DeepLinkViewType;
    groupId: string;
    shiftId: string;
    requestId: string;
    startDateTime: string;
    endDateTime: string;
    source: string;
}