import { IImportJobEntity } from "../../sh-models";
import RestClient from "../../sh-rest-client";
import { ImportJobsStore, setImportJobs } from "../../sh-stores/sh-team-importjobs-store";
import { DataProvider } from "./DataProvider";

/**
 * Import Jobs Data Provider.
 */
export class ImportJobsDataProvider extends DataProvider<IImportJobEntity[]>{
    protected teamId: string;

    /**
     * Constructor for ImportJobsDataProvider.
     * @param teamId Current team id.
     */
    constructor(teamId: string) {
        super();
        this.teamId = teamId;
    }

    /**
     * Return data from memory.
     * Returns {IImportJobEntity[]} data.
     */
    async getDataFromMemory(): Promise<IImportJobEntity[]> {
        return ImportJobsStore().jobs;
    }

    /**
     * Not implemeted.
     */
    async getDataFromDatabase(): Promise<IImportJobEntity[]> {
        throw new Error("ImportJobsDataProvider.getDataFromDatabase is not implemented");
    }

    /**
     * Not implemeted.
     */
    async getDefaultData(): Promise<IImportJobEntity[]> {
        throw new Error("ImportJobsDataProvider.getDefaultData is not implemented");
    }

    /**
     * Make an HTTP request to fetch the data from the network.
     * @returns {IImportJobEntity[]} Data.
     */
    async getDataFromNetwork(): Promise<IImportJobEntity[]> {
        const latestResponse = await RestClient.getImportJobs(this.teamId);
        return latestResponse;
    }

    /**
     * Set data in memory.
     * @param data IImportJobEntity[].
     */
    async setDataInMemory(data: IImportJobEntity[]): Promise<void> {
        setImportJobs(data);
    }

    /**
     * Not implemeted.
     */
    async setDataInDatabase(): Promise<void> {
       return;
    }

    /**
     * Not implemeted.
     */
    async saveDataToNetwork(): Promise<IImportJobEntity[]> {
        throw new Error("ImportJobsDataProvider.saveDataToNetwork is not implemented");
    }
}