
import { action } from "satcheljs/lib/legacy";
import { IUserEntity } from 'sh-models';
import { UserStore } from 'sh-stores';

/**
 * Store current logged in user
 */

export let setUser = action("setUser")(
    function setUser(userModel: IUserEntity) {
        UserStore().user = userModel;
    });
