import { INoteEntity, NoteEntity, NoteStates } from "sh-models";
import { Moment } from "moment";

/**
 * Utilities for Notes
 */
export default class NoteUtils {
    /**
     * Returns true if the note is deleted
     * @param note
     */
    public static isDeletedNote(note: INoteEntity): boolean {
        return note && note.state === NoteStates.Deleted;
    }

    /*
     * Returns the last shared version of the note, or null if no shared version of the note exists.
     * @param note
     */
    public static getSharedChangesForNote(note: INoteEntity): INoteEntity {
        let sharedChanges: INoteEntity = null;
        // If the note is shared, the current note model is equivalent to the last sharedChanges
        if (note.isPublished) {
            sharedChanges = NoteEntity.clone(note);
        } else if (note.sharedChanges) {
            // If the note is not shared and has a sharedChanges field, this field is equivalent to the last sharedChanges
            sharedChanges = NoteEntity.clone(note.sharedChanges);
        }
        // Otherwise no shared version of the note exists, so we will return null

        return sharedChanges;
    }

    /**
     * Returns true if the note fits into the given range and is displayable for the schedule
     * @param note
     * @param viewStart
     * @param viewEnd
     */
    public static isDisplayableNoteInScheduleRange(note: INoteEntity, viewStart: Moment, viewEnd: Moment): boolean {
        return NoteUtils.isNoteInRange(note, viewStart, viewEnd)  && NoteUtils.isNoteDisplayableForScheduleView(note);
    }

    /**
     * Returns true if note is visible in the given range
     * @param note
     * @param rangeStart
     * @param rangeEnd
     */
    public static isNoteInRange(note: INoteEntity, rangeStart: Moment, rangeEnd: Moment): boolean {
        // Last parameter [] tells moment to include both start and end date in the comparison
        // If a range spanning two days is given, then any note on one of those two days will be considered
        // in range
        return note.startTime.isBetween(rangeStart, rangeEnd, "day", "[]");
    }

    /**
     * Returns true if the specified note should be allowed to be displayed in the Schedule View
     * If there is a shared change, then the shared change has to be not deleted
     * If there is no shared change, look at the state of the top level note
     */
    public static isNoteDisplayableForScheduleView(note: INoteEntity): boolean {
        const sharedChanges = NoteUtils.getSharedChangesForNote(note);
        let isDisplayable =
            (sharedChanges && !NoteUtils.isDeletedNote(sharedChanges)) || // A note with a shared version that is not deleted should be displayed. If the saved version is deleted, UI will reflect this
            (note.state === NoteStates.Active); // Active
        return isDisplayable;
    }

    /**
     * Returns true if the two provided notes are identical. Does not consider their sharedChanges fields.
     * @param note
     * @param otherNote
     * @param considerIsPublished - check their isPublished fields unless considerIsPublished is set to false.
     */
    public static areIdenticalNotes(
        note: INoteEntity,
        otherNote: INoteEntity,
        considerIsPublished: boolean = true): boolean {
        let areIdentical: boolean = true;
        // normalize text value so that null and empty string are treated the same
        const noteText: string = note.text || "";
        const otherNoteText: string = otherNote.text || "";
        areIdentical = note.tenantId === otherNote.tenantId &&
                        note.teamId === otherNote.teamId &&
                        note.id === otherNote.id &&
                        note.noteType === otherNote.noteType &&
                        noteText === otherNoteText &&
                        note.state === otherNote.state &&
                        note.startTime.isSame(otherNote.startTime) &&
                        note.endTime.isSame(otherNote.endTime);

        if (areIdentical && considerIsPublished) {
            areIdentical = note.isPublished === otherNote.isPublished;
        }

        return areIdentical;
    }

    /**
     * Returns true if the note has unshared edits.
     * @param note
     */
    public static noteHasUnsharedEdits(note: INoteEntity) {
        if (!note) {
            return false;
        }

        let hasUnsharedEdits: boolean = true;

        if (note.isPublished) {
            hasUnsharedEdits = false;
        } else {
            const sharedChanges: INoteEntity = note.sharedChanges;
            if (!sharedChanges) {
                hasUnsharedEdits = true;
            } else {
                hasUnsharedEdits = !NoteUtils.areIdenticalNotes(note, sharedChanges, false /* considerIsPublished */);
            }
        }
        return hasUnsharedEdits;
    }
}
