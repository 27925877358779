import { action } from "satcheljs";
import { ITeamInfoEntity } from "sh-models";

const teamSelected = action(
    "TEAM_SELECTED", (
        team: ITeamInfoEntity,
        doInitializeSchedulesViewState: boolean
    ) => ({
        team: team,
        doInitializeSchedulesViewState: doInitializeSchedulesViewState
    })
);

export default teamSelected;
