import { DataProvider } from "sh-services/dataproviders/DataProvider";
import { IUserPolicySettingsEntity } from "sh-models";
import { IUserDatabase } from "sh-services/data/IUserDatabase";
import { setUserPolicySettings } from "sh-stores/sh-user-store";
import { UserStore } from "sh-stores";

/**
 * UserPolicySettings Data Provider
 */
export class UserPolicySettingsDataProvider extends DataProvider<IUserPolicySettingsEntity> {
    protected loggedInUserId: string;
    protected userDatabase: IUserDatabase;

    constructor(loggedInUserId: string, userDatabase: IUserDatabase) {
        super();
        this.loggedInUserId = loggedInUserId;
        this.userDatabase = userDatabase;
    }

    /**
     * Return data if it's found in memory (otherwise return undefined)
     */
    async getDataFromMemory(): Promise<IUserPolicySettingsEntity> {
        return (UserStore().userPolicySettings ? UserStore().userPolicySettings : undefined);
    }

    /**
     * Return data if it's found in the database (otherwise return undefined)
     */
    async getDataFromDatabase(): Promise<IUserPolicySettingsEntity> {
        return await this.userDatabase.getUserPolicySettings(this.loggedInUserId);
    }

    /**
     * We will not make a http network call explicitly for user policy
     * This information is obtained for the login api call
     */
    async getDataFromNetwork(): Promise<IUserPolicySettingsEntity> {
        // already gets data from login call and never gets called, just added this function for completeness
        throw new Error("UserPolicySettingsDataProvider.getDataFromNetwork not implemented");
    }

    /**
     * Set data in memory
     */
    async setDataInMemory(data: IUserPolicySettingsEntity) {
        setUserPolicySettings(data);
    }

    /**
     * Set data in the database
     */
    async setDataInDatabase(data: IUserPolicySettingsEntity) {
        return await this.userDatabase.setUserPolicySettings(data);
    }

    /**
     * We do not make any changes to policy settings from client side
     */
    async saveDataToNetwork(data: IUserPolicySettingsEntity): Promise<IUserPolicySettingsEntity> {
        throw new Error("UserPolicySettingsDataProvider.saveDataToNetwork not implemented");
    }
}