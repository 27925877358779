import accessibilityAlertViewStateStore from "../store/store";
import { mutatorAction } from "satcheljs";

/**
 * Update the store to set the accessibility alert text
 */
export default mutatorAction('setAlertText', function setAlertText(
    alertId: string,
    alertText: string
) {
    if (alertText) {
        accessibilityAlertViewStateStore().alertMessages.set(alertId, alertText);
    } else {
        accessibilityAlertViewStateStore().alertMessages.delete(alertId);
    }
});