import * as React from "react";
import AutomationUtils from "sh-application/utility/AutomationUtil";
import FlexiRow, { FlexiRowCellSettings, FlexiRowProps } from "./FlexiRow";
import SortableFlexiRow from "./SortableFlexiRow";
import { AriaProperties, generateDomPropertiesForAria } from "owa-accessibility";
import { observer } from "mobx-react";
import { trace } from "owa-trace";
import { VirtualList } from "sh-virtual-list";

const classNames = require("classnames/bind");

/**
 * Properties interface for the FlexiGrid component
 *
 * To enable reactive rendering updates of cells, the cellSettings data that is passed to this FlexiGrid component
 * should be designated as observable.
 */
export interface FlexiGridProps {
    gridClass?: string; // CSS classes for the grid
    rowBaseClass?: string; // Base CSS classes for rows in the grid
    cellBaseClass?: string; // Base CSS classes for cells in the grid
    rowSettings: FlexiGridRowSettings[]; // Settings for the rows in the grid
    cellSettings: FlexiRowCellSettings[][]; // Settings for all cells in all rows of the grid. [rowIndex][columnIndex]
    allowReorder?: boolean; // true, if the grid allows for drag and drop reordering
    ariaPropsForGrid?: AriaProperties; // Aria accessibility properties to assign for this grid

    // Virtualized list threshold:  Mininum number of rows required to enable virtualized list rendering. null or -1 to disable.
    // Virtualized list rendering will render a subset of the rows that are currently in view or adjacent to the view.
    // This helps maintain reasonable browser rendering performance for grids with large amounts of data to render.
    // However, this causes items to "pop" in especially when scrolling quickly, so this threshold setting can
    // be used to perform normal rendering for smaller sets of data.
    virtualizedListRenderingThreshold?: number;
    virtualRowsPerPage: number; // the number of rows to render per virtual page
    virtualRowHeight?: number; // the height (in px) of each row height - if not provided, each FlexiRow must provide the rowHeight value in their FlexiGridRowSettings
}

/**
 * Settings for a row within the context of the grid
 */
export interface FlexiGridRowSettings {
    rowHeight?: number; // row height in pixels, used by virtualized list to calculate height of scheduler
    rowElementId?: string; // Element id to assign for the row element
    rowElementKey?: string; // Unique key for the row element. Used for optimizing reactive rendering updates.
    rowClass?: string; // CSS classes for the row container
    rowDataAutomationId?: string; // data-automation-id attribute value for the row element
    isDraggable?: boolean; // true, if dragging should be allowed for the row
    dragIndex?: number; // number used for drag index with in the group
    groupId?: string; // id of the group of which the row is a part of
    memberRowIndex?: number; // number of member row index, if > 0 we dont show reoder instructions when in re-order mode
    showBlockingOverlay?: boolean; // true, if a blocking overlay needs to be shown on top of the row
    ariaPropsForRow?: AriaProperties; // Aria accessibility properties to assign for this row
}

/**
 * FlexiGrid component
 * This component renders a grid of cells with customizable contents.
 */
@observer
export default class FlexiGrid extends React.Component<FlexiGridProps, {}> {
    /**
     * Render a grid row with the specified settings
     * @param rowSettings row settings for the row
     * @param cellSettingsForRow cell settings for the row
     * @param rowIndex index for the current row
     * @param allowReorder true, if the row should be draggable/sortable
     * @param rowBaseClass row base CSS class
     * @param cellBaseClass base CSS class for cells in the row
     */
    private renderRow(
        rowSettings: FlexiGridRowSettings,
        cellSettingsForRow: FlexiRowCellSettings[],
        rowIndex: number,
        allowReorder: boolean,
        rowBaseClass?: string,
        cellBaseClass?: string,
        key?: string,
        styles?: React.CSSProperties
    ): React.ReactNode {
        // Calculate CSS classes for the row
        const rowClasses = classNames(rowBaseClass, rowSettings.rowClass);

        const elementKeyId = FlexiGrid.calculateRowKeyId(rowSettings, rowIndex);
        const isSortable = allowReorder && rowSettings.isDraggable && typeof rowSettings.dragIndex === "number";

        let rowProps: FlexiRowProps = {
            rowElementId: rowSettings.rowElementId,
            rowElementKey: key || elementKeyId,
            rowClass: rowClasses,
            rowDataAutomationId: rowSettings.rowDataAutomationId,
            cellBaseClass: cellBaseClass,
            cellSettings: cellSettingsForRow,
            memberRowIndex: rowSettings.memberRowIndex,
            showBlockingOverlay: !!rowSettings.showBlockingOverlay,
            ariaPropsForRow: rowSettings.ariaPropsForRow,
            rowStyle: styles,
            isSortable: isSortable
        };

        if (isSortable) {
            // collection property allows us to create groups under a single sortable container
            return (
                <SortableFlexiRow
                    key={rowProps.rowElementKey}
                    index={rowSettings.dragIndex}
                    collection={rowSettings.groupId}
                    {...rowProps}
                />
            );
        } else {
            return <FlexiRow key={rowProps.rowElementKey} {...rowProps} />;
        }
    }

    /**
     * Generate unique React list item key for the specified row for more efficient React re-rendering.
     *
     * The goal is that the keys should remain stable while the layout remains the same, so that React can recycle the
     * item elements and just re-render their contents.  This helps to improve our rendering performance.
     *
     * However, if the layout changes (eg, number of items change, the items which span different widths change, etc), we may need to ensure
     * that the keys should are different from the previous layout.  Otherwise rendering issues may occur where old cells from the previous
     * layout don't get re-rendered or removed properly.
     *
     * Adding extra information such as the number of items in the row and cell widths to the keys would help ensure correct React rendering,
     * but from running performance tests, this degrades rendering performance by some amount, so we need to be careful if we find
     * specific scenarios where rendering issues occur that we want to fix.
     */
    private static calculateRowKeyId(rowSettings: FlexiGridRowSettings, rowIndex: number) {
        return rowSettings.rowElementKey || rowIndex.toString();
    }

    /**
     * virtualized list render item callback
     * @param index the index into this.props.rowSettings
     */
    renderVirtualItem = (index: number) => {
        const { rowBaseClass, cellBaseClass, rowSettings, cellSettings, allowReorder } = this.props;

        return this.renderRow(rowSettings[index], cellSettings[index], index, allowReorder, rowBaseClass, cellBaseClass, null);
    };

    /**
     * virtualized list callback to get fixed height of row at index
     * @param item instance of FlexiGridRowSettings
     */
    getRowHeight = (item: FlexiGridRowSettings) => {
        return item.rowHeight;
    };

    render() {
        const { gridClass, rowSettings, cellSettings, virtualRowsPerPage, virtualRowHeight } = this.props;

        let virtualizedListOutput = null;

        if (rowSettings && rowSettings.length && cellSettings && cellSettings.length) {
            if (rowSettings.length === cellSettings.length) {
                let useVirtualizedListRendering: boolean = !!(
                    this.props.virtualizedListRenderingThreshold && // null or -1 to disable virtualized list rendering
                    this.props.virtualizedListRenderingThreshold >= 0 &&
                    rowSettings.length >= this.props.virtualizedListRenderingThreshold
                );

                virtualizedListOutput = (
                    <VirtualList
                        forceRender={!useVirtualizedListRendering}
                        rowsPerPage={virtualRowsPerPage}
                        items={rowSettings}
                        onRenderRow={this.renderVirtualItem}
                        lastRender={new Date().getTime()}
                        defaultRowHeight={virtualRowHeight || 0}
                        onGetRowHeight={virtualRowHeight ? null : this.getRowHeight}
                    />
                );
            } else {
                trace.error("FlexiGrid.render(): Number of rowSettings does not equal the number of rows in cellSettings.");
            }
        }

        return (
            <div
                data-automation-id={AutomationUtils.getAutomationId("group", "QAIDScheduleGrid")}
                className={gridClass}
                {...generateDomPropertiesForAria(this.props.ariaPropsForGrid)}>
                {virtualizedListOutput}
            </div>
        );
    }
}
