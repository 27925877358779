import * as moment from "moment";
import { IShiftDatabase } from "./IShiftDatabase";
import { AppDb } from "sh-services/data/AppDb";
import { ECSConfigKey, ECSConfigService, InstrumentationService } from "sh-services";
import { IItemsInTimeRangeSyncState, IShiftDbEntity, IShiftEntity, ShiftEntity } from "sh-models";
import { Moment } from "moment";

/**
 * MyShifts Database Accessor, this should be in sync with Shifts Database accessor
 */
export class MyShiftDatabase implements IShiftDatabase {
    private db: AppDb;

    constructor(db: AppDb) {
        if (MyShiftDatabase.IsShiftsIndexedDbEnabled()) {
            this.db = db;
        }
    }
    /**
     * Fetch myShift in a team with the specific ID
     */
    public async getShift(teamId: string, shiftId: string): Promise<IShiftEntity> {
        try {
            const shiftFromDb: IShiftDbEntity = await this.db.myShifts
                    .where({id: shiftId, teamId: teamId}).first();
            return ShiftEntity.fromJson(shiftFromDb);
        } catch (error) {
            InstrumentationService.trackException(error, "getMyShiftFromDb");
        }
        return null;
    }

    /**
     * Fetch myShift in a team
     */
    public async getShifts(teamId: string, fetchStartTime: Moment, fetchEndTime: Moment): Promise<IShiftEntity[]> {
        if (!this.db) {
            return null;
        }
        let myShift: IShiftEntity[] = null;
        const fetchStartTimeStamp: number = fetchStartTime.valueOf();
        const fetchEndTimeStamp: number = fetchEndTime.valueOf();

        try {
            const myShiftsFromDb: IShiftDbEntity[] = await this.db.myShifts
                .where("[startTime+endTime+teamId]")
                // Use the key to do a more performant exclusion of items that don't overlap with the current fetch range.
                // With compound indexes, we can't really do complex logic filters as the filter() handler below, but we still
                // use the indexes here to partially weed out items that should excluded.
                .below([fetchEndTimeStamp, fetchEndTimeStamp, teamId]) // Only include items where (item.startTime < fetchEndTimeStamp)
                // Filter out items that do not overlap with the current fetch range
                .filter((myShift: IShiftDbEntity) => { return myShift.startTime < fetchEndTimeStamp && myShift.endTime > fetchStartTimeStamp && myShift.teamId === teamId; })
                .toArray();
                myShift = myShiftsFromDb.map((myShift: IShiftDbEntity) => ShiftEntity.fromJson(myShift));
        } catch (error) {
            InstrumentationService.trackException(error, "getMyShiftsFromDb");
        }
        return myShift;
    }

    /**
     * Set myShifts in Database
     * @param teams teams list
     */
    public async setShifts(shifts: IShiftEntity[]): Promise<void> {
        if (!this.db || !shifts || !shifts.length) {
            return;
        }

        try {
            await this.db.myShifts.bulkPut(shifts.map( (myShift: IShiftEntity) => ShiftEntity.toDbModel(myShift)));
        } catch (error) {
            InstrumentationService.trackException(error, "setMyShiftsInDb");
        }
    }

    /**
     * Delete myShift in a team
     * @param teamId ID of the team
     * @param myShiftIds IDs of shifts to delete
     */
    public async deleteShifts(teamId: string, myShiftIds: string[]): Promise<void> {
        if (!this.db || !myShiftIds || !myShiftIds.length) {
            return;
        }

        try {
            await this.db.transaction("rw", this.db.myShifts, async () => {
                for (let i = 0; i < myShiftIds.length; i++) {
                    // delete shifts
                    await this.db.myShifts.where({teamId: teamId, id: myShiftIds[i]}).delete();
                }
            });
        } catch (error) {
            InstrumentationService.trackException(error, "deleteMyShiftsInDb");
        }
    }

    /**
     * Atomically set myShifts (and the time range that's in sync for the session) in the Database
     * @param teamId Team ID
     * @param sessionId Current Session ID
     * @param updatedShifts List of updated shifts
     * @param deletedShiftIds List of IDs of shifts that were hard deleted
     * @param sessionShiftsCacheStartTimestamp Start of time range that's cached in current session
     * @param sessionShiftsCacheEndTimestamp End of timestamp that's cached in current session
     */
    public async setShiftsInTimeRange(teamId: string, sessionId: string, updatedShifts: IShiftEntity[], deletedShiftIds: string[], sessionShiftsCacheStartTimestamp: number, sessionShiftsCacheEndTimestamp: number): Promise<void> {
        if (!this.db) {
            return;
        }

        try {
            await this.db.transaction("rw", [this.db.myShifts, this.db.myShiftsSyncState], async () => {
                await this.setShifts(updatedShifts);
                await this.deleteShifts(teamId, deletedShiftIds);
                await this.setShiftsCacheTimeRange(teamId, sessionId, sessionShiftsCacheStartTimestamp, sessionShiftsCacheEndTimestamp);
            });
        } catch (error) {
            InstrumentationService.trackException(error, "setMyShiftsInTimeRangeInDb");
        }
    }

    /**
     * Set Shifts cache timerange in Database
     * @param teamId Team ID
     * @param sessionId Session ID
     * @param syncStateStartTimestamp Start time of the contiguous timerange that's in sync
     * @param syncStateEndTimestamp End time of the contiguous timerange that's in sync
     */
    public async setShiftsCacheTimeRange(teamId: string, sessionId: string, syncStateStartTimestamp: number, syncStateEndTimestamp: number): Promise<void> {
        if (!this.db) {
            return;
        }

        const myShiftsSyncState: IItemsInTimeRangeSyncState = {
            sessionId: sessionId,
            teamId: teamId,
            syncStateStartTimestamp: syncStateStartTimestamp,
            syncStateEndTimestamp: syncStateEndTimestamp,
            lastUpdateTimestamp: moment().valueOf()
        };

        try {
            await this.db.myShiftsSyncState.put(myShiftsSyncState);
        } catch (error) {
            InstrumentationService.trackException(error, "setMyShiftsCacheTimeRangeInDb");
        }
    }

    /**
     * Get the shift sync state for a team in the current session
     * @param teamId Team ID
     * @param sessionId  Session ID
     */
    public async getShiftsSyncState(teamId: string, sessionId: string): Promise<IItemsInTimeRangeSyncState> {
        if (!this.db) {
            return null;
        }

        try {
            return await this.db.myShiftsSyncState
                .where({sessionId: sessionId, teamId: teamId}).first();
        } catch (error) {
            InstrumentationService.trackException(error, "getMyShiftsSyncStateFromDb");
        }
        return null;
    }

    /**
     * Delete the shift sync state for the current session
     * @param sessionId  Session ID
     */
    public async deleteShiftsSyncState(sessionId: string): Promise<void> {
        if (!this.db) {
            return null;
        }

        try {
            await this.db.myShiftsSyncState.where({sessionId: sessionId}).delete();
        } catch (error) {
            InstrumentationService.trackException(error, "deleteMyShiftsSyncStateInDb");
        }
    }

    /**
     * Returns true if storing & retrieving of shifts/notes/openshifts from IndexedDb is enabled
     */
    public static IsShiftsIndexedDbEnabled() {
        return ECSConfigService.isECSFeatureEnabled(ECSConfigKey.EnableShiftsIndexedDb) && ECSConfigService.isECSFeatureEnabled(ECSConfigKey.EnableMyShiftsApi);
    }

    /**
     * Delete the sessions
     * @param sessionIds List of sessionIds to delete
     */
    public async deleteSessionData(sessionIds: string[]) {
        if (!this.db || !sessionIds || !sessionIds.length) {
            return;
        }
        try {
            for (const sessionId of sessionIds) {
                await this.db.myShiftsSyncState.where({sessionId: sessionId}).delete();
            }
        } catch (error) {
            InstrumentationService.trackException(error, "deleteSessionDataFromDb");
        }
    }
}