import { addShiftsToStoreMap } from "sh-conflict-store";
import { IShiftEntity } from "sh-models";
import { loadStoreForConflictCalculations } from "..";
import { orchestrator } from "satcheljs";
import { ShiftRequestDataService } from "sh-services";
import { TeamStore } from "sh-team-store";
import { trace } from "owa-trace";

/**
 * This orchestrator is called whenever request tab is clicked to fetch the member Shifts for request shift time range
 * It will make network call to fetch single member shift for specified time range and add those shifts to membertoShift store
 * It will also update the fetched shifted in conflict Request store
 */
export default orchestrator(loadStoreForConflictCalculations, async actionMessage => {
    const { memberId, startTime, endTime } = actionMessage;

    if (memberId && startTime && endTime) {
        // make a network call to getch specified userId shifts for time range provided
        try {
            const memberShifts = await ShiftRequestDataService.getShiftsInDateRangeForUserId(memberId, TeamStore().teamId, startTime, endTime);
            if (memberShifts.length > 0) {
                memberShifts.forEach((shiftAdded: IShiftEntity) => {
                    // update in both maps of the store
                    addShiftsToStoreMap(shiftAdded);
                });
            }
        } catch (error) {
            trace.warn("loadStoreForConflictCalculations failed ");
        }
    }
});