import { ConflictStore } from "sh-conflict-store";
import { mutatorAction } from "satcheljs";

export default mutatorAction('RESET_CONFLICT_STORE', function resetConflictStore() {
   ConflictStore().shiftIdToConflictsMap.clear();
   ConflictStore().conflictCount = {
      overlappingShiftConflictCount: 0,
      totalConflictCount: 0,
      shiftAvailabilityConflictCount: 0,
      shiftTimeoffConflictCount: 0
   };
   ConflictStore().didIgnoreConflictsOnLongShifts = false;
   ConflictStore().didConflictCountExceedLimit = false;
   ConflictStore().startTime = null;
   ConflictStore().endTime = null;
   ConflictStore().myConflictCount = {
      overlappingShiftConflictCount: 0,
      totalConflictCount: 0,
      shiftAvailabilityConflictCount: 0,
      shiftTimeoffConflictCount: 0
  };
});
