import * as React from "react";
import KeyboardUtils from "sh-application/utility/KeyboardUtils";
import StringsStore from "sh-strings/store";
import StringUtils from "sh-application/utility/StringUtils";
import { AriaRoles, generateDomPropertiesForAria } from "owa-accessibility";
import { IGroupedOpenShiftRequestEntity } from "sh-models";
import { observer } from "mobx-react";
import { ScheduleCellRenderSize } from "sh-application/../StaffHubConstants";

const styles = require("./ShiftRequestsIndicator.scss");
const classNames = require("classnames/bind");

export interface ShiftRequestsIndicatorProps {
    /* An object containing the grouped shif trequests for this component to display an indicator for */
    groupedShiftRequest: IGroupedOpenShiftRequestEntity;

    /* Size of the cell */
    cellSize: ScheduleCellRenderSize;

    /* Callback fired when the indicator is clicked on or activated with the keyboard  */
    onIndicatorClick: (isClick: boolean) => void;
}

/**
 * ShiftRequestsIndicator - Renders an indicator to communicate the existence of shift requests
 */
@observer
export default class ShiftRequestsIndicator extends React.Component<ShiftRequestsIndicatorProps, any> {
    private _strings: Map<string, string>;

    constructor(props: ShiftRequestsIndicatorProps) {
        super(props);
        this._strings = StringsStore().registeredStringModules.get("shiftRequests").strings;
    }

    private onRequestsIndicatorClick = () => {
        if (this.props.onIndicatorClick) {
            this.props.onIndicatorClick(true /*isClick*/);
        }
    }

    private onRequestsIndicatorKeyDown = (key: React.KeyboardEvent<HTMLElement>) => {
        if (KeyboardUtils.isActionKeyPressed(key) && this.props.onIndicatorClick) {
            this.props.onIndicatorClick(false /*isClick*/);
        }
    }

    render() {
        const { groupedShiftRequest, cellSize } = this.props;

        const numRequests: number = (groupedShiftRequest && groupedShiftRequest.shiftRequests) ? groupedShiftRequest.shiftRequests.size : 0;

        // for month view cells (small) we only render the # of requests with no additional label
        const isSmall = cellSize === ScheduleCellRenderSize.Small;
        // for day view cells (large) we need to remove the margins and hide overflow since the user can create a very small day shift
        // bug #568988
        const isLarge = cellSize === ScheduleCellRenderSize.Large;

        const requestsCountForDisplay: string = isSmall ? String(numRequests) : StringUtils.usePluralForm(numRequests)
                ? this._strings.get("requestsCountInGroup").format(numRequests.toString())
                : this._strings.get("oneRequestInGroup");

        const disabled = (numRequests === 0);

        const containerClasses = classNames(styles.requestsIndicatorContainer, "requestsIndicator", { "disabled": disabled }, { [styles.small]: isSmall, [styles.large]: isLarge });
        const labelClasses = classNames(styles.numAssignedSlotsLabel, { "disabled": disabled });

        return (
            disabled
            ?
                <div
                    aria-label={ this._strings.get("shiftRequestsIndicatorAriaLabel") }
                    className={ containerClasses } >
                        <span className={ labelClasses }>{ requestsCountForDisplay }</span>
                </div>
            :
                <div
                    onClick={ this.onRequestsIndicatorClick }
                    onKeyDown={ this.onRequestsIndicatorKeyDown }
                    tabIndex={ 0 }
                    data-is-focusable={ true }
                    { ...generateDomPropertiesForAria({ role: AriaRoles.button, label: this._strings.get("shiftRequestsIndicatorAriaLabel")}) }
                    className={ containerClasses } >
                    <span className={ labelClasses }>{ requestsCountForDisplay }</span>
                </div>
        );
    }
}