import {
    ConfigModel,
    EcsCachedConfig,
    EcsConfigType,
    EcsHeaders,
    EcsResponseBase
} from "@skype/ecsclient/dist/Models";
import * as SyncTasks from "synctasks";
import EcsClient from "@skype/ecsclient";

class ECSResponse implements EcsResponseBase {
    // ECS Project Team: https://ecs.skype.com/?page=CreateOrEditProjectPage&team=MicrosoftTeamsVVM&client=Skype

    public Headers: EcsHeaders = {
        CountryCode: "",
        ETag: "",
        Expires: "",
        StatusCode: ""
    };

    public TeamsClientMwtPackages = {
        TelemetryRegion: "",
        TelemetryUrl: ""
    };

    public static getDefault = (): ECSResponse => new ECSResponse();
}

enum ECSConstants {
    EcsProjectMwtPackage = "TeamsClientMwtPackages"
}

class EcsConfig {
    private ecsClient = new EcsClient<EcsResponseBase>();

    public loadConfig(config: ConfigModel<EcsResponseBase>): Promise<EcsResponseBase> {
        return new Promise<EcsResponseBase>(
            (
                resolve: (value: EcsResponseBase) => void,
                reject: (reason?: string) => void
            ): void => {
                this.ecsClient.initialize(config).then(() => {
                    this.ecsClient.configUpdated.subscribe(() => {
                        const response: EcsCachedConfig<EcsResponseBase> =
                            this.ecsClient.getConfig();
                        if (!!response && !!response.config) {
                            resolve(response.config);
                        } else {
                            reject("[EcsConfig][loadConfig] Failed to retrieve config");
                        }
                    });
                });
            }
        );
    }
}

const EcsHelper: EcsConfig = new EcsConfig();
let cachedTelemetryRegion: string;

const getconfig = (tenantId: string, projectName: string): ConfigModel<EcsResponseBase> => {
    return {
        clientName: "MicrosoftTeams",
        clientVersion: "1.0.0.0",
        configsToFetch: [EcsConfigType.Default],
        hosts: ["https://config.teams.microsoft.com"],
        initialAppActiveState: true,
        getEcsParameters: (): SyncTasks.Promise<{
            [key: string]: string | number;
        }> =>
            SyncTasks.Resolved({
                agents: projectName,
                TenantID: tenantId
            })
    };
};

export const loadTelemetryRegion = async (
    tenantId: string
): Promise<string> => {
    if (!!cachedTelemetryRegion) {
        // Return cached telemetry region if available
        // TODO: EcsHelper.loadConfig has bugs that prevents it from being called twice
        // This needs to be fixed and we should switch to the MicrosoftTeamsVerticalsFLW ECS project
        // to load the telemetry region
        return cachedTelemetryRegion;
    }
    try {
        const ecsSettings = await EcsHelper.loadConfig(getconfig(tenantId, ECSConstants.EcsProjectMwtPackage));
        // Get the project ecsSettings from ECS. Can change in future
        const resp = ecsSettings as ECSResponse;
        cachedTelemetryRegion = resp?.TeamsClientMwtPackages?.TelemetryRegion;
        return cachedTelemetryRegion;
    } catch (error) {
        throw error;
    }
};
