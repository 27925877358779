import { mutatorAction } from "satcheljs";
import { SchedulesViewStateStore } from "sh-application/components/schedules/lib/store/schema/SchedulesViewStateStore";
import { UserStorageKeys } from "sh-models";
import { UserStorageService } from "sh-services";

/**
 * Toggle the show staff per 30 rows in the shift view of the Schedules page
 */
export default mutatorAction("TOGGLE_STAFF_PER_30_ROWS",
    function toggleShowStaffPer30Rows(viewState: SchedulesViewStateStore) {
        viewState.showStaffPer30Rows = !viewState.showStaffPer30Rows;
        if (viewState.showStaffPer30Rows) {
            UserStorageService.setItem<boolean>(UserStorageKeys.ShowStaffPer30Rows, true);
        } else {
            UserStorageService.removeItem(UserStorageKeys.ShowStaffPer30Rows);
        }
    }
);