import * as microsoftTeams from "@microsoft/teams-js";
import { createStore } from "satcheljs";
import { MSTeamPickerPaneStore } from "./schema/MSTeamPickerPaneStore";

/**
 * Team picker pane view state
 */
const initialMSTeamPickerPaneStore: MSTeamPickerPaneStore = {
    isDataLoading: false,                                               // true if data is currently being loaded
    msTeamInfos: new Array<microsoftTeams.TeamInformation>()            // MS Teams to render in the picker list
};

// let msTeamPickerPaneStore = createStore("msTeamPickerPaneStore", initialMSTeamPickerPaneStore)();

let msTeamPickerPaneStore = createStore<MSTeamPickerPaneStore>(
    "msTeamPickerPaneStore",
    initialMSTeamPickerPaneStore);

export default msTeamPickerPaneStore;

/* export actions and mutator actions for ease of import */
export { default as setIsTeamPickerPaneDataLoading } from "../mutatorActions/setIsTeamPickerPaneDataLoading";
export { default as setMSTeamInfos } from "../mutatorActions/setMSTeamInfos";
