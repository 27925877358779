import RestClient from "sh-rest-client";
import {
    deleteOpenShiftsInStore,
    OpenShiftStore,
    resetOpenShiftsStore,
    setOpenShiftsCacheEndTimeInStore,
    setOpenShiftsCacheStartTimeInStore,
    updateOpenShiftsInStore
    } from "sh-open-shift-store";
import { DataFilter } from "sh-services";
import { IItemsInTimeRangeSyncState, IOpenShiftEntity } from "sh-models";
import { IOpenShiftDatabase } from "sh-services/data/IOpenShiftDatabase";
import { ItemsInDateRangeDataProvider } from "./ItemsInDateRangeDataProvider";
import { Moment } from "moment";
import { ObservableMap } from "mobx";

/**
 * Data Provider to fetch OpenShifts in DateRange for the current team
 */
export class OpenShiftsInDateRangeDataProvider extends ItemsInDateRangeDataProvider<IOpenShiftEntity> {

    protected openShiftDatabase: IOpenShiftDatabase;

    constructor(openShiftDatabase: IOpenShiftDatabase, tenantId: string, teamId: string, sessionId: string, fetchStartTime: Moment, fetchEndTime: Moment, dontClearCache: boolean) {
        super(tenantId, teamId, sessionId, fetchStartTime, fetchEndTime, dontClearCache);
        this.openShiftDatabase = openShiftDatabase;
    }

    /**
     * Fetch all items that are in memory
     */
    getAllItemsFromMemory(): ObservableMap<string, IOpenShiftEntity> {
        return OpenShiftStore().openShifts;
    }

    /**
     * Fetch the sync start time from memory
     */
    getSyncCacheStartTimeFromMemory(): Moment {
        return OpenShiftStore().cacheStartTime;
    }

    /**
     * Fetch the sync end time from memory
     */
    getSyncStateEndTimeFromMemory(): Moment {
        return OpenShiftStore().cacheEndTime;
    }

    /**
     * Set sync cache start time in memory
     */
    setSyncCacheStartTimeInMemory(syncStateStartTime: Moment): void {
        setOpenShiftsCacheStartTimeInStore(syncStateStartTime);
    }

    /**
     * Set sync cache end time in memory
     */
    setSyncStateEndTimeInMemory(syncStateEndTime: Moment): void {
        setOpenShiftsCacheEndTimeInStore(syncStateEndTime);
    }

    /**
     * Set list of items in memory
     */
    setItemsInMemory(items: IOpenShiftEntity[]): void {
        updateOpenShiftsInStore(items);
    }

    /**
     * Hard delete items in memory
     */
    hardDeleteItemsInMemory(items: IOpenShiftEntity[]): void {
        deleteOpenShiftsInStore(items);
    }

    /**
     * Reset the items and sync state in memory
     */
    resetDataInMemory(): void {
        resetOpenShiftsStore();
    }

    /**
     * Returns true if this item matches the data filter.
     * @param item the open shift
     * @param dataFilter data filter
     */
    isItemInDataFilter(item: IOpenShiftEntity, dataFilter: DataFilter): boolean {
        let isInTagFilter = false;
        let isInMemberFilter = false;

        if (dataFilter && item) {
            if (dataFilter.memberIds) {
                // open shifts aren't filtered by member so if we are filtering by member, this
                // item is in the filter (should be returned)
                isInMemberFilter = true;
            } else if (dataFilter.tagIds) {
                // check if this item is in the tag filter
                isInTagFilter = this.isItemInTagDataFilter(item, dataFilter);
            }
        }

        return isInTagFilter || isInMemberFilter;
    }

    /**
     * Set Items and the sync state timerange in the Database
     */
    async setItemsAndSyncStateInDatabase(updatedItems: IOpenShiftEntity[], deletedItemIds: string[], syncStateStartTimestamp: number, syncStateEndTimestamp: number): Promise<void> {
        return await this.openShiftDatabase.setOpenShiftsInTimeRange(this.teamId, this.sessionId, updatedItems, deletedItemIds, syncStateStartTimestamp, syncStateEndTimestamp);
    }

    /**
     * Get items in the fetch time range from the Database
     */
    async getItemsInDateRangeFromDatabase(): Promise<IOpenShiftEntity[]> {
        return await this.openShiftDatabase.getOpenShifts(this.teamId, this.fetchStartTime, this.fetchEndTime);
    }

    /**
     * Get sync state from the Database
     */
    async getItemsSyncStateFromDatabase(): Promise<IItemsInTimeRangeSyncState> {
        return await this.openShiftDatabase.getOpenShiftsSyncState(this.teamId, this.sessionId);
    }

    /**
     * Get list of items within time range from network
     */
    async getItemsInDateRangeFromNetwork(): Promise<IOpenShiftEntity[]> {
        return await RestClient.getOpenShifts(this.tenantId, this.teamId, this.fetchStartTime, this.fetchEndTime);
    }
}