import ChangeStore from "../store";
import { computed } from "mobx";
import { IChangeEntity } from "sh-models";

/**
 * computed value representing the last set of changes or null
 */
export let lastRedoableChanges = computed(function(): Array<IChangeEntity> {
    if (ChangeStore().redoStack && ChangeStore().redoStack.length > 0) {
        return ChangeStore().redoStack[ChangeStore().redoStack.length - 1];
    } else {
        return null;
    }
});