import resetTagsStore from "../actions/resetTagsStore";
import TagStore from "../store";
import { mutator } from "satcheljs";

/**
 * Reset the tag store
 */
export default mutator(resetTagsStore, ({ }) => {
    TagStore().tags.clear();
    TagStore().focusedTagId = "";
});