export type AriaRole = 'alert'
                    | 'alertdialog'
                    | 'application'
                    | 'article'
                    | 'banner'
                    | 'button'
                    | 'checkbox'
                    | 'columnheader'
                    | 'combobox'
                    | 'complementary'
                    | 'contentinfo'
                    | 'definition'
                    | 'dialog'
                    | 'directory'
                    | 'document'
                    | 'form'
                    | 'grid'
                    | 'gridcell'
                    | 'group'
                    | 'heading'
                    | 'img'
                    | 'link'
                    | 'list'
                    | 'listbox'
                    | 'listitem'
                    | 'log'
                    | 'main'
                    | 'marquee'
                    | 'math'
                    | 'menu'
                    | 'menubar'
                    | 'menuitem'
                    | 'menuitemcheckbox'
                    | 'menuitemradio'
                    | 'navigation'
                    | 'note'
                    | 'option'
                    | 'presentation'
                    | 'progressbar'
                    | 'radio'
                    | 'radiogroup'
                    | 'region'
                    | 'row'
                    | 'rowgroup'
                    | 'rowheader'
                    | 'scrollbar'
                    | 'search'
                    | 'separator'
                    | 'slider'
                    | 'spinbutton'
                    | 'status'
                    | 'tab'
                    | 'tablist'
                    | 'tabpanel'
                    | 'textbox'
                    | 'timer'
                    | 'toolbar'
                    | 'tooltip'
                    | 'tree'
                    | 'treegrid'
                    | 'treeitem';

export const AriaRoles = {
    /** http://www.w3.org/TR/wai-aria/roles#alert */
     alert: 'alert' as AriaRole,
    /** http://www.w3.org/TR/wai-aria/roles#alertdialog */
     alertdialog: 'alertdialog' as AriaRole,
    /** http://www.w3.org/TR/wai-aria/roles#application */
     application: 'application' as AriaRole,
    /** http://www.w3.org/TR/wai-aria/roles#article */
     article: 'article' as AriaRole,
    /** http://www.w3.org/TR/wai-aria/roles#banner */
     banner: 'banner' as AriaRole,
    /** http://www.w3.org/TR/wai-aria/roles#button */
     button: 'button' as AriaRole,
    /** http://www.w3.org/TR/wai-aria/roles#checkbox */
     checkbox: 'checkbox' as AriaRole,
    /** http://www.w3.org/TR/wai-aria/roles#columnheader */
     columnheader: 'columnheader' as AriaRole,
    /** http://www.w3.org/TR/wai-aria/roles#combobox */
     combobox: 'combobox' as AriaRole,
    /** http://www.w3.org/TR/wai-aria/roles#complementary */
     complementary: 'complementary' as AriaRole,
    /** http://www.w3.org/TR/wai-aria/roles#contentinfo */
     contentinfo: 'contentinfo' as AriaRole,
    /** http://www.w3.org/TR/wai-aria/roles#definition */
     definition: 'definition' as AriaRole,
    /** http://www.w3.org/TR/wai-aria/roles#dialog */
     dialog: 'dialog' as AriaRole,
    /** http://www.w3.org/TR/wai-aria/roles#directory */
     directory: 'directory' as AriaRole,
    /** http://www.w3.org/TR/wai-aria/roles#document */
     document: 'document' as AriaRole,
    /** http://www.w3.org/TR/wai-aria/roles#form */
     form: 'form' as AriaRole,
    /** http://www.w3.org/TR/wai-aria/roles#grid */
     grid: 'grid' as AriaRole,
    /** http://www.w3.org/TR/wai-aria/roles#gridcell */
     gridcell: 'gridcell' as AriaRole,
    /** http://www.w3.org/TR/wai-aria/roles#group */
     group: 'group' as AriaRole,
    /** http://www.w3.org/TR/wai-aria/roles#heading */
     heading: 'heading' as AriaRole,
    /** http://www.w3.org/TR/wai-aria/roles#img */
     img: 'img' as AriaRole,
    /** http://www.w3.org/TR/wai-aria/roles#link */
     link: 'link' as AriaRole,
    /** http://www.w3.org/TR/wai-aria/roles#list */
     list: 'list' as AriaRole,
    /** http://www.w3.org/TR/wai-aria/roles#listbox */
     listbox: 'listbox' as AriaRole,
    /** http://www.w3.org/TR/wai-aria/roles#listitem */
     listitem: 'listitem' as AriaRole,
    /** http://www.w3.org/TR/wai-aria/roles#log */
     log: 'log' as AriaRole,
    /** http://www.w3.org/TR/wai-aria/roles#main */
     main: 'main' as AriaRole,
    /** http://www.w3.org/TR/wai-aria/roles#marquee */
     marquee: 'marquee' as AriaRole,
    /** http://www.w3.org/TR/wai-aria/roles#math */
     math: 'math' as AriaRole,
    /** http://www.w3.org/TR/wai-aria/roles#menu */
     menu: 'menu' as AriaRole,
    /** http://www.w3.org/TR/wai-aria/roles#menubar */
     menubar: 'menubar' as AriaRole,
    /** http://www.w3.org/TR/wai-aria/roles#menuitem */
     menuitem: 'menuitem' as AriaRole,
    /** http://www.w3.org/TR/wai-aria/roles#menuitemcheckbox */
     menuitemcheckbox: 'menuitemcheckbox' as AriaRole,
    /** http://www.w3.org/TR/wai-aria/roles#menuitemradio */
     menuitemradio: 'menuitemradio' as AriaRole,
    /** http://www.w3.org/TR/wai-aria/roles#navigation */
     navigation: 'navigation' as AriaRole,
    /** http://www.w3.org/TR/wai-aria/roles#note */
     note: 'note' as AriaRole,
    /** http://www.w3.org/TR/wai-aria/roles#option */
     option: 'option' as AriaRole,
    /** http://www.w3.org/TR/wai-aria/roles#presentation */
     presentation: 'presentation' as AriaRole,
    /** http://www.w3.org/TR/wai-aria/roles#progressbar */
     progressbar: 'progressbar' as AriaRole,
    /** http://www.w3.org/TR/wai-aria/roles#radio */
     radio: 'radio' as AriaRole,
    /** http://www.w3.org/TR/wai-aria/roles#radiogroup */
     radiogroup: 'radiogroup' as AriaRole,
    /** http://www.w3.org/TR/wai-aria/roles#region */
     region: 'region' as AriaRole,
    /** http://www.w3.org/TR/wai-aria/roles#row */
     row: 'row' as AriaRole,
    /** http://www.w3.org/TR/wai-aria/roles#rowgroup */
     rowgroup: 'rowgroup' as AriaRole,
    /** http://www.w3.org/TR/wai-aria/roles#rowheader */
     rowheader: 'rowheader' as AriaRole,
    /** http://www.w3.org/TR/wai-aria/roles#scrollbar */
     scrollbar: 'scrollbar' as AriaRole,
    /** http://www.w3.org/TR/wai-aria/roles#search */
     search: 'search' as AriaRole,
    /** http://www.w3.org/TR/wai-aria/roles#separator */
     separator: 'separator' as AriaRole,
    /** http://www.w3.org/TR/wai-aria/roles#slider */
     slider: 'slider' as AriaRole,
    /** http://www.w3.org/TR/wai-aria/roles#spinbutton */
     spinbutton: 'spinbutton' as AriaRole,
    /** http://www.w3.org/TR/wai-aria/roles#status */
     status: 'status' as AriaRole,
    /** http://www.w3.org/TR/wai-aria/roles#tab */
     tab: 'tab' as AriaRole,
    /** http://www.w3.org/TR/wai-aria/roles#tablist */
     tablist: 'tablist' as AriaRole,
    /** http://www.w3.org/TR/wai-aria/roles#tabpanel */
     tabpanel: 'tabpanel' as AriaRole,
    /** http://www.w3.org/TR/wai-aria/roles#textbox */
     textbox: 'textbox' as AriaRole,
    /** http://www.w3.org/TR/wai-aria/roles#timer */
     timer: 'timer' as AriaRole,
    /** http://www.w3.org/TR/wai-aria/roles#toolbar */
     toolbar: 'toolbar' as AriaRole,
    /** http://www.w3.org/TR/wai-aria/roles#tooltip */
     tooltip: 'tooltip' as AriaRole,
    /** http://www.w3.org/TR/wai-aria/roles#tree */
     tree: 'tree' as AriaRole,
    /** http://www.w3.org/TR/wai-aria/roles#treegrid */
     treegrid: 'treegrid' as AriaRole,
    /** http://www.w3.org/TR/wai-aria/roles#treeitem */
     treeitem: 'treeitem' as AriaRole
};
