import { DataService } from "./DataService";
import { ITeamMemberPermissionsEntity } from "../../sh-models";
import { ITeamMemberPermissionsDataService } from "./ITeamMemberPermissionsDataService";
import { TeamMemberPermissionsDataProvider } from "../dataproviders/TeamMemberPermissionsDataProvider";

/**
 * Team Permissions Data Service.
 */
export class TeamMemberPermissionsDataService extends DataService implements ITeamMemberPermissionsDataService {
    /**
     * Gets the member privacy permissions for a team.
     * @param teamId Id for the team.
     * @param tenantId Id for the tenant.
     * @param forceFetchFromNetwork If true, fetches data from network.
     */
    public async getTeamMemberPermissions(
        tenantId: string,
        teamId: string,
        forceFetchFromNetwork?: boolean
    ): Promise<ITeamMemberPermissionsEntity> {
        const dataProvider = new TeamMemberPermissionsDataProvider(this.teamDatabase, teamId, tenantId);
        return this.getData(dataProvider, forceFetchFromNetwork);
    }

    /**
     * Sets the member privacy permissions for a team.
     * @param teamId Id for the team.
     * @param tenantId Id for the tenant.
     * @param forceFetchFromNetwork If true, fetches data from network.
     */
    public async updateTeamMemberPermissions(
        tenantId: string,
        teamId: string,
        permissions: ITeamMemberPermissionsEntity
    ): Promise<ITeamMemberPermissionsEntity> {
        // TODO: Currently, DataService and DataProvider does not support Partial updates. Passing full data for now, update to Partial later once supported.
        const dataProvider = new TeamMemberPermissionsDataProvider(this.teamDatabase, teamId, tenantId);
        return this.updateData(permissions, dataProvider);
    }

    /**
     * Resets the sync state.
     */
    public async resetSyncState() {}
}

const service: ITeamMemberPermissionsDataService = new TeamMemberPermissionsDataService();
export default service;
