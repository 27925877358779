import * as moment from "moment";
import { AriaProperties, AriaRoles, generateDomPropertiesForAria } from "owa-accessibility";
import * as React from "react";
import { Stack } from "@microsoft/shifts-ui-core";

import { PeopleCount } from "../../common/peopleCount/PeopleCount";
import { getPeopleCountProps } from "./ScheduleHeaderCell.mappers";

interface ScheduleHeaderCellProps {
    cellTitle?: string;
    cellSubTitle?: string;
    bodyCssClass?: string;
    titleCssClass?: string;
    subtitleCssClass?: string;
    hoursCssClass?: string;
    onClick?: (day: moment.Moment) => void;
    cellDate?: moment.Moment;
    hoursTotal?: string;
    showHours?: boolean;
    peopleCount?: number;
}

/**
 The cell component for Schedule header flexi row.
 */
export default class ScheduleHeaderCell extends React.PureComponent<ScheduleHeaderCellProps, {}> {
    public render() {
        const { cellTitle, cellSubTitle, bodyCssClass, hoursCssClass, hoursTotal, peopleCount, showHours, titleCssClass, subtitleCssClass } = this.props;
        const peopleCountProps = getPeopleCountProps(peopleCount);

        const ariaProps: AriaProperties = {
            role: AriaRoles.button
        };
        return (
            <div className={ bodyCssClass } { ...generateDomPropertiesForAria(ariaProps) } onClick={ this.onClick }>
                <Stack horizontal>
                    <Stack.Item className={titleCssClass} grow>
                        {cellTitle}
                    </Stack.Item>
                    {peopleCountProps ? <Stack.Item align="center"><PeopleCount {...peopleCountProps} /></Stack.Item> : null}
                </Stack>
                <div className={subtitleCssClass}>
                    { cellSubTitle }
                </div>
                {
                    showHours &&
                        <div className={hoursCssClass}>
                            { hoursTotal }
                        </div>
                }
            </div>
        );
    }

    private onClick = () => {
        if (this.props.onClick) {
            this.props.onClick(this.props.cellDate);
        }
    }
}
