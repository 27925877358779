import { orchestrator } from "satcheljs";
import { handleConnectorAuthRequest } from "../actions/handleConnectorAuthRequest";
import {
    connectorAuthDialogStore,
    setIsConnectorAuthDialogOpen,
} from "../../../common/ConnectorAuthDialog/lib";
import { ECSConfigKey, ECSConfigService } from "sh-services";

export const handleConnectorAuthRequestOrchestratror = orchestrator(
    handleConnectorAuthRequest,
    () => {
        if (ECSConfigService.isECSFeatureEnabled(ECSConfigKey.EnableConnectorAuthenticationFlow)) {
            setIsConnectorAuthDialogOpen(connectorAuthDialogStore(), true);
        }
    }
);
