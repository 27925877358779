import addShiftInMemoryDateStore from "sh-stores/sh-conflict-store/actions/addShiftInMemoryDateStore";
import { IShiftEntity } from "sh-models";
import { orchestrator } from "satcheljs";
import { ShiftsByMemberAndDateStore } from "sh-conflict-store";

export default orchestrator(addShiftInMemoryDateStore, actionMessage => {
    let memberDateIdToShiftMap = ShiftsByMemberAndDateStore().memberIdToShiftsByDate;
    const shift = actionMessage.shift;
    const shiftDateIntervals: Set<number> =  actionMessage.shiftMemberDateInfo && actionMessage.shiftMemberDateInfo.startOfDates;

    // if the slot for memberId already exists, check the slot for date interval
    if (memberDateIdToShiftMap.has(shift.memberId)) {
        // get the list of shift date intervals, if a member id is in the list then shiftDatesIntervalList.shiftsByDateMap will always be initilaized.
        let shiftDatesIntervalList = memberDateIdToShiftMap.get(shift.memberId);

        shiftDateIntervals.forEach((shiftDateInterval: number) => {
            if (shiftDatesIntervalList.shiftsByDateMap && shiftDatesIntervalList.shiftsByDateMap.has(shiftDateInterval)) {
                let dateIntervalListOfShifts = shiftDatesIntervalList.shiftsByDateMap.get(shiftDateInterval);

                const index = dateIntervalListOfShifts && dateIntervalListOfShifts.findIndex((shiftItem: IShiftEntity) => { return shiftItem && shift.id === shiftItem.id; });

                // make sure you dont double add
                if (index < 0) {
                    dateIntervalListOfShifts.push(shift);
                    shiftDatesIntervalList.shiftsByDateMap.set(shiftDateInterval, dateIntervalListOfShifts);
                }
            } else {
                // date interval doesnt exist add with the shift
                shiftDatesIntervalList.shiftsByDateMap.set(shiftDateInterval, [shift]);
            }
        });
        memberDateIdToShiftMap.set(shift.memberId, shiftDatesIntervalList);
    } else {
        // if the slot for memberId is new, add the shift entity.
        let newShiftDatesIntervalList = {
            shiftsByDateMap: new Map<number, IShiftEntity[]>()
        };
        shiftDateIntervals.forEach((shiftDateInterval: number) => {
            newShiftDatesIntervalList.shiftsByDateMap.set(shiftDateInterval, [shift]);
        });
        memberDateIdToShiftMap.set(shift.memberId, newShiftDatesIntervalList);
    }
});
