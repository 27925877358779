import errorViewStateStore from "../store/store";
import { mutatorAction } from "satcheljs";

/**
 * Update the store to set a blocking error
 */
export default mutatorAction('setShowWorkforceIntError', function setShowWorkforceIntError(
    showWorkforceIntError: boolean
) {
    errorViewStateStore().showWorkforceIntError = showWorkforceIntError;
});
