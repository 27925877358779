import { mutatorAction } from "satcheljs";

import schedulesViewStateStore from "../store/store";

import { SetStaffPerIntervalEnabledAction } from "./SetStaffPerIntervalEnabledAction";

/**
 * Sets the staff per interval enabled value in the state.
 */
export const setStaffPerIntervalEnabled = mutatorAction<(action: SetStaffPerIntervalEnabledAction) => void>(
    "SET_STAFF_PER_INTERVAL_ENABLED", ({ value }): void => {
        schedulesViewStateStore().staffPerIntervalEnabled = value;
    }
);