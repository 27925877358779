import { IBaseShiftEntity } from "sh-models";
import { mutatorAction } from "satcheljs";
import { ShiftEditorViewStateSchema } from "..";

export default mutatorAction("setShiftInStore", function setShiftInStore(
    store: ShiftEditorViewStateSchema,
    shift: IBaseShiftEntity
) {
    if (store) {
        store.shift = shift;
    }
});