import * as React from "react";
import ScheduleTeamPickerPane from "../scheduleteampicker/ScheduleTeamPickerPane";
import StringsStore from "sh-strings/store";
import TeamSetupPickerUtils from "../TeamSetupPickerUtils";
import { getGenericEventPropertiesObject, logPageView } from "sh-instrumentation";
import { InstrumentationService } from "sh-services";
import { ITeamInfoEntity } from "../../../../sh-models";
import { SetupNavigation } from "../SetupNavigation";
import { teamSelected } from "sh-team-store";
import { TeamSetupPickerStepProps } from "../TeamSetupPickerStepProps";
import { UrlFactory } from "sh-application";

/**
 * React component for the schedule team picker step in the team setup workflow.
 * In this setup step, the user selects a schedule team to switch to.
 */
@logPageView("Setup.SelectScheduleTeam")
export default class TeamSetupScheduleTeamPicker extends React.Component<TeamSetupPickerStepProps, {}> {
    private _teamSetupPickerStrings: Map<string, string>;
    private _scheduleTeamPicker: Map<string, string>;

    constructor(props: TeamSetupPickerStepProps) {
        super(props);
        this._teamSetupPickerStrings = StringsStore().registeredStringModules.get("teamSetupPicker").strings;
        this._scheduleTeamPicker = StringsStore().registeredStringModules.get("scheduleTeamPicker").strings;
    }

    /**
     * Handler for when the user selects a team to view
     */
    private handleTeamSelected = (team: ITeamInfoEntity) => {
        InstrumentationService.logEvent(
            this.getInstrumentationEventName(),
            [getGenericEventPropertiesObject(InstrumentationService.properties.EventType, InstrumentationService.values.ScheduleTeamSelectedClicked)]);

        // Send the user to the team page of the selected team
        // TODO DCoh: Also, hook up the navigationHandler once Teams exposes it in their SDK so that forward/back navigating within Teams works properly.
        teamSelected(team, UrlFactory.isSchedulesPageLocation(this.props.history.location.pathname) /*doInitializeSchedulesViewState*/);
        this.props.history.push(UrlFactory.getSchedulesUrl(team.id));
        this.props.onClose();
    }

    /**
     * Handler for when the user clicks on the Create New Schedule entrypoint
     */
    private handleCreateNewScheduleClicked = () => {
        InstrumentationService.logEvent(
            this.getInstrumentationEventName(),
            [getGenericEventPropertiesObject(InstrumentationService.properties.EventType, InstrumentationService.values.CreateNewScheduleButtonClicked)]);

        this.props.onGotoProvisionTeamFlow();
    }

    private getInstrumentationEventName(): string {
        return TeamSetupPickerUtils.getInstrumentationEventNameForScheduleTeamSwitcher(this.props.isOnboarding);
    }

    render() {
        const cancelButtonLabel: string = this._teamSetupPickerStrings.get("cancelButtonLabel");
        const showCancelButton: boolean = this.props.isSetupUserDismissable;
        return (
            <div className={ showCancelButton ? "stepWithNavbar" : "stepNoNavBar" } role="region" aria-label={ this._scheduleTeamPicker.get("scheduleTeamPickerTitle") }>
                <ScheduleTeamPickerPane
                    instrumentationEventName={ this.getInstrumentationEventName() }
                    onTeamSelected={ this.handleTeamSelected }
                    onCreateNewScheduleClicked={ this.props.showCreateNewScheduleTeamEntrypoints ? this.handleCreateNewScheduleClicked : null } />
                {
                    showCancelButton &&
                        <SetupNavigation
                            onCancel={ this.props.onCancel }
                            cancelButtonLabel={ cancelButtonLabel }
                        />
                }
            </div>
        );
    }
}