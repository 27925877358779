import * as React from "react";
import * as Sticky from "react-stickynode";
import FilterableCommandBar from "./FilterableCommandBar";
import GlobalNavigationPivot from "./GlobalNavigationPivot";
import KeyboardUtils from "sh-application/utility/KeyboardUtils";
import schedulesViewStateStore from "sh-application/components/schedules/lib/store/store";
import StringsStore from "sh-strings/store";
import { appViewState, launchTeamSetupPicker } from "sh-application/store";
import { AriaProperties, AriaRoles, generateDomPropertiesForAria } from "owa-accessibility";
import { default as FilterableCommandBarItem, DisplayOption } from "./FilterableCommandBarItem";
import { getGenericEventPropertiesObject } from "sh-instrumentation";
import { ContextualMenuItemType, Icon, IContextualMenuItem } from "@fluentui/react";
import { InstrumentationService } from "sh-services";
import { observer } from "mobx-react";
import { ScrollOffsetInjectedProps } from "sh-application/components/common/withScrollOffset";
import { STICKY_Z_INDEX } from "sh-application/../StaffHubConstants";
import { TeamSetupPickerMode } from "sh-application/components/teamsetuppicker";
import { TeamStore } from "sh-team-store";
import { getFilterableCommandBarExtraProps } from "./GlobalCommandBar.mappers";

const styles = require("./GlobalCommandBar.scss");

// const teamAvatar = require("sh-assets/images/teamavatar.png");
interface GlobalCommandBarState {
    isSticky: boolean;
}

const menuItemKeys = {
    teamPicker: "teamPickerMenuItemKey",
    teamName: "teamNameMenuItemKey",
    scheduleType: "scheduleTypeMenuItemKey"
};

@observer
export default class GlobalCommandBar extends React.Component<ScrollOffsetInjectedProps, GlobalCommandBarState> {
    private _strings: Map<string, string>;
    private _ariaItemProps: AriaProperties = {
        role: AriaRoles.menuitem
    };

    constructor(props: ScrollOffsetInjectedProps) {
        super(props);

        this._strings = StringsStore().registeredStringModules.get("header").strings;

        this.state = {
            isSticky: false
        };
    }

    /**
     * When the header row is scrolled horizontally and has been wrapped with the withScrollOffset HOC,
     * it will receive an injectetd xOffset prop. Because this component is sometimes wrapped in a sticky
     * component, it is inside a "position: fixed" div and will not scroll horizontally with the page. In order
     * to keep the header cells aligned with the cells of the grid, we adjust the left and right margins of the
     * header row to shift the cells.
     */
    private getInjectedStyle = () => {
        const headerRowInjectedStyle =
            (this.props.xOffset && (this.state.isSticky && !schedulesViewStateStore().isHeaderExpanded))
            ? {
                left: -this.props.xOffset,
                right: this.props.xOffset
            }
            : null;
        return headerRowInjectedStyle;
    }

    private onStickyStateChange = (updatedStatus: Sticky.Status) => {
        this.setState({ isSticky: updatedStatus.status === Sticky.STATUS_FIXED });
    }

    render() {
        const extraProps = getFilterableCommandBarExtraProps();

        // Make the global command bar sticky with z-index more than the schedule header row
        return (<Sticky top={ 0 } innerZ={ STICKY_Z_INDEX + 1 } onStateChange={ this.onStickyStateChange }>
            <FilterableCommandBar
                ariaLabel={ this._strings.get("globalCommandBarAriaLabel") }
                className={ styles.globalCommandBar }
                filterableItems={ this.getFilterableCommandBarItems() }
                style={ this.getInjectedStyle() }
                {...extraProps}
            />
        </Sticky>);
    }

    private getFilterableCommandBarItems() {
        return [...this.leftFilterableItems(), ...appViewState().globalCommandBarViewState.rightCommandBarItems ];
    }

    /**
     * The standard left global command bar items which include: The hamburger menu, Team icon avatar, Team name, Schedule, Settings tabs
     * These remaim constant throughout the App.
     */
    private leftFilterableItems (): FilterableCommandBarItem[] {
        const items: FilterableCommandBarItem[] = [];

        items.push(
            FilterableCommandBarItem.create({
                name: "team picker",
                key: menuItemKeys.teamPicker,
                iconOnly: true,
                className: "teamPicker",
                displayOption: DisplayOption.Left,
                onRender: this._renderTeamPickerButton,
                role: AriaRoles.button
            })
        );

        // Commenting this out till the Teams API for Team Avtar is available.
        //     items.push(
        //         new FilterableCommandBarItem(
        //            "team avtar",
        //            "",
        //            true,  /* iconOnly */
        //            () => {},
        //            "teamAvtar",
        //            null,   /* shouldShow */
        //            DisplayOption.Left,
        //            null /* disabled */,
        //            null /* menuContent */,
        //            null  /* itemType */,
        //            null /* title */,
        //            this._renderTeamAvtar /* onRender */,
        //            undefined /* onMouseDown */,
        //            AriaRoles.button /* role*/,
        //            "" /* ariaLabel*/
        //        )
        //    );

       items.push(
            FilterableCommandBarItem.create({
                name: TeamStore().name,
                key: menuItemKeys.teamName,
                icon: "team name",
                iconOnly: false,
                itemType: ContextualMenuItemType.Header,
                className: "teamName",
                displayOption: DisplayOption.Left,
                onRender: this._renderTeamName,
                ariaLabel: TeamStore().name
            })
        );

        items.push(
            FilterableCommandBarItem.create({
                name: "Schedule type",
                key: menuItemKeys.scheduleType,
                iconOnly: false,
                className: "scheduleType",
                displayOption: DisplayOption.Left,
                onRender: this._renderNavigationPivot
            })
        );

        return items;
    }

    // private _renderTeamAvtar = (item: IContextualMenuItem): React.ReactNode => {
    //     return (<Image className={ styles.teamAvatar } src={ teamAvatar } />);
    // }

    private _renderTeamName = (_item: IContextualMenuItem): React.ReactNode => {
        const ariaHeadingProps: AriaProperties = {
            role: AriaRoles.heading,
            level: 1
        };
        return (
            <div role="none">
                <div tabIndex={ -1 } {...generateDomPropertiesForAria(ariaHeadingProps) } className={ styles.teamName }>{ TeamStore().name }</div>
            </div>
        );
    }

    private _renderNavigationPivot = (item: IContextualMenuItem): React.ReactNode => {
        return (
            <div role="none">
                <GlobalNavigationPivot />
            </div>
        );
    }

    private _handleScheduleTeamPickerClicked = (ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>, item?: IContextualMenuItem): boolean => {
        InstrumentationService.logEvent(
            InstrumentationService.events.ScheduleHeader,
            [getGenericEventPropertiesObject(InstrumentationService.properties.EventType, InstrumentationService.values.ChooseScheduleClicked)]);

        launchTeamSetupPicker(
            appViewState().teamSetupPickerViewState,
            false /* isOnboarding */,
            TeamSetupPickerMode.SwitchTeams,
            true /* isUserDismissable */,
            true /* useTeamPickerPanel */);
        return true;
    }

    private _renderTeamPickerButton = (item: IContextualMenuItem) => {
        const ariaLabel = this._strings.get("teamPickerAriaLabel");

        let ariaProps: AriaProperties = {
            role: AriaRoles.button,
            label: ariaLabel
        };
        return (
            <div data-is-focusable="true"
                title={ ariaLabel }
                id={ "team_Picker_Icon_Id" }
                {...generateDomPropertiesForAria(ariaProps) }
                onKeyDown={ this.onKeyDown }
                role={AriaRoles.menuitem}
                onClick={ this._handleScheduleTeamPickerClicked }
                tabIndex={ 0 } className={ styles.teamPickerWrapper }>
                    <Icon iconName="teams-icons-menu-light" className={ styles.teamPickerIcon } />
            </div>
        );
    }

    private onKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
        if (KeyboardUtils.isActionKeyPressed(e)) {
            this._handleScheduleTeamPickerClicked();
        }
    }

    get ariaItemProps() {
        return this._ariaItemProps;
    }
}
