import * as React from "react";
import { Image, ImageFit } from "@fluentui/react";
import { StaffHubError } from "sh-application";

const styles = require("./ExternalErrorDetails.scss");

/**
 * Shows the external error details
 */

export interface ExternalErrorDetailsProps {
    staffHubError: StaffHubError;
}

export default class ExternalErrorDetails extends React.PureComponent<ExternalErrorDetailsProps, {}> {
    render() {
        const { staffHubError } = this.props;

        return (
            <div className={ styles.errorDetails }>
                {
                    staffHubError && staffHubError.externalImageLink &&
                        <Image className={ styles.externalImage } imageFit={ ImageFit.contain } src={ staffHubError.externalImageLink } />
                }
                <div>
                    <div className={ styles.externalTitle }>
                        { staffHubError && staffHubError.externalSource }
                    </div>
                    <div className={ styles.externalError }>
                        { staffHubError && staffHubError.externalErrorMessage }
                    </div>
                </div>
            </div>
        );
    }
}