import * as React from "react";
import { Icon } from "@fluentui/react";

const styles = require("./WarningIndicator.scss");
const classNames = require("classnames/bind");

interface WarningIndicatorProps {
    /* optional - a class to add to the indicator */
    className?: string;

    /* optional - a string to display next to the indicator */
    text?: string;

    /* optional QAId */
    qaid?: string;
}

/**
 * WarningIndicator - renders an indicator to communicate a warning
 */
export default class WarningIndicator extends React.PureComponent<WarningIndicatorProps, {}> {

    render() {
        const { className, text, qaid } = this.props;

        return (
            <div tabIndex={ 0 } className={ classNames(styles.warningIndicator, className) } data-automation-id={ qaid } >
                <Icon className={ styles.warningIndicatorIcon } iconName="teams-warning-svg"/>
                <span className={ styles.warningIndicatorText }>{ text }</span>
            </div>
        );
    }
}