import { trace } from "owa-trace";
import { orchestrator } from "satcheljs";
import { InstrumentationEventPropertyInterface, getGenericEventPropertiesObject } from "sh-instrumentation";
import { InstrumentationService } from "sh-services";

import { ImportJobsDataService } from "../../../sh-services";
import { getImportJobs } from "../actions/getImportJobs";
import { setImportJobs } from "../mutatorActions/setImportJobs";

/**
 * Orchestrator to get import jobs and update the store with latest import jobs.
 */
export const onGetImportJobs = orchestrator(getImportJobs, async action => {
    const eventProperties: Array<InstrumentationEventPropertyInterface> = [];
    let isGetImportJobsSuccess = false;

    try {
        const importJobs = await ImportJobsDataService.getImportJobs(action.teamId, true);
        setImportJobs(importJobs);
        isGetImportJobsSuccess = true;
    } catch (error) {
        // TODO: Show generic error message on the page
        trace.error("Import Jobs API Call Failed", error);
    } finally {
        // log event for Import jobs loaded
        eventProperties.push(
            getGenericEventPropertiesObject(InstrumentationService.properties.EventType, InstrumentationService.values.ImportJobsLoaded)
        );
        eventProperties.push(getGenericEventPropertiesObject(InstrumentationService.properties.IsSuccess, isGetImportJobsSuccess));
        InstrumentationService.logEvent(InstrumentationService.events.ImportExcel, eventProperties);
    }
});
