import { action } from "satcheljs";

const importSchedule = action(
    "importScheduleJobStatus",
    (
        teamId: string,
        jobId: string
    ) => ({
        teamId: teamId,
        jobId: jobId
    })
);

export default importSchedule;