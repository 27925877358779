import Features from "../store/schema/Features";
import store from "../store/Store";
import { mutatorAction } from "satcheljs";
import { UrlFactory } from "sh-application";

/**
 * Update the store to set a feature flag
 */
export default mutatorAction("setFeatureFlag", function setFeatureFlag(
    feature: Features, isOn: boolean
) {
    if (!store().featureFlags.has(feature)) {
        throw `No feature information for ${feature}`;
    }

    store().featureFlags.set(feature, isOn);

    // update the feature flag in the URL
    const updatedUrl = `${window.location.origin}${window.location.pathname}${UrlFactory.getQueryStringWithFeatureFlag(feature, isOn)}`;
    history.replaceState({}, null, updatedUrl);
});
