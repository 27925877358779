import ShiftUtils from "sh-application/utility/ShiftUtils";
import { extendObservable } from "mobx";
import {
    IOpenShiftEntity,
    ISubshiftEntity,
    ITeamInfoEntity,
    OpenShiftEntity,
    ShiftStates,
    ShiftTypes
    } from "sh-models";
import { orchestrator } from "satcheljs";
import { setOpenShiftInStore, initializeLocalObservableOpenShift } from "../index";
import { TeamDataService } from "sh-services";
import { UniqueShiftStore } from "sh-uniqueshift-store";

/**
 * Initializes a open shift object in the store and marks it and all of its subshifts as observable so that the panel UI
 * reacts to changes in it.
 */
export const initializeLocalObservableOpenShiftOrchestrator = orchestrator(initializeLocalObservableOpenShift, actionMessage => {
    const { shiftEditorViewState, openShift, memberId, groupTagId, startDate } = actionMessage;
    const team: ITeamInfoEntity = TeamDataService.getCurrentTeam();
    const teamId = team ? team.id : "";
    const tenantId = team ? team.tenantId : "";
    let observableShift: IOpenShiftEntity = null;
    // When editing an existing open shift, just make all the existing properties observable
    // If we are editing a unshared deleted shift (title string will still say Add), the panel
    // behaves as though this was the add of a new open shift. But when changes are saved, we will edit the existing
    // deleted open shift
    if (openShift && !ShiftUtils.isUnsharedDeletedShift(openShift)) {
        observableShift = OpenShiftEntity.clone(openShift as IOpenShiftEntity);
        observableShift.subshifts.map((activity: ISubshiftEntity, index: number) => extendObservable(activity, {...openShift.subshifts[index]})); // do the same for the subshifts within the shift, as each is an object with its own properties
    } else {
        // When creating a new open shift
        const tagIds = groupTagId ? [groupTagId] : [];
        observableShift = new OpenShiftEntity({
            id: null,
            eTag: null,
            tenantId: tenantId,
            teamId: teamId,
            memberId: memberId || memberId,
            shiftType: ShiftTypes.Working,
            startTime: null,
            endTime: null,
            state: ShiftStates.Active,
            title: null,
            notes: null,
            theme: null,
            tagIds: tagIds,
            subshifts: null,
            breaks: null,
            shiftRequestId: null,
            timeOffReasonId: null,
            isPublished: false,
            openSlots: undefined,
            isCrossLocationOpenShift: false
        });

        // When creating a new open shift we use the last unique open shift created/modified in the app. If we don't have such a open shift,
        // we instead use default values
        if (UniqueShiftStore().uniqueShifts && UniqueShiftStore().uniqueShifts.length > 0) {
            const uniqueShift = UniqueShiftStore().uniqueShifts[0];
            ShiftUtils.setShiftValuesFromUniqueShift(uniqueShift, observableShift, startDate);
        } else {
            ShiftUtils.setDefaultShiftValues(observableShift, startDate);
        }

        // If we are editing a unshared deleted shift, we'll want to update it at the end, so we use its
        // id, eTag, and sharedChanges for our local observable shift object.
        if (ShiftUtils.isUnsharedDeletedShift(openShift)) {
            observableShift.id = openShift.id;
            observableShift.eTag = openShift.eTag;
            observableShift.sharedChanges = openShift.sharedChanges;
        }
    }

    observableShift.subshifts.sort(ShiftUtils.shiftComparator);

    // extendObservable (target, props) copies all props value pairs onto target and marks them as observable.
    // In this panel we need the open Shift component to react to changes to properties of the open shift, so we mark every property of the shift as observable
    setOpenShiftInStore(shiftEditorViewState, extendObservable(observableShift, {...observableShift}));
});