import { importScheduleDialogViewState } from "../";
import { mutatorAction } from "satcheljs";

/**
 * Sets the error message to display in the message bar or clears the existing error.
 * @param message - The error message to display. Set to empty string to clear the error.
 * @param showRefreshButton - Whether to show the refresh app button in the error message bar
 */
export default mutatorAction("setErrorMessage", function setErrorMessage(
    message: string,
    showRefreshButton: boolean
) {
    importScheduleDialogViewState().errorMessage = message;
});