import * as React from "react";

export interface PageConfig {
    intersected: boolean; // true when the page is marked as intersecting (render will then happen)
    height: number; // the calculated height (in px) of the page
    items: Array<any>; // array of items in the page
    sentinelElement: Element | null; // reference to the sentinel Element. This is what is observed by IntersectionObserver.
    sentinelPosition: number; // the top position (in px) of the absolute positioned sentinel
}

interface VirtualListPageProps extends PageConfig {
    index: number; // the page index
    onRenderRow: (index: number) => React.ReactNode; // render callback for the row
    onSetPageRef: (element: Element, index: number) => void; // callback to notify when the DOM reference a page sentinel has been set
    maxRowsPerPage: number; // max number of the items to render in the page
    forceRender?: boolean; // if true, all items in the page will be forced to render
}

/**
 * A page of items in a VirtualList. When the sentinel element is intersected, the page
 * is responsible for rendering each of its items. When the page is not intersected, the
 * page renders a simple div with its height set to the calcalulated height of the page so
 * that the scrollbar can display correctly.
 */

export default class VirtualListPage extends React.PureComponent<VirtualListPageProps, {}> {
    render() {
        const { intersected, height, forceRender, sentinelPosition } = this.props;
        const pageIndex = this.props.index;
        const shouldRenderItems = intersected || forceRender;

        let sentinelVisibleStyle = null;

        if (shouldRenderItems) {
            // when the sentinel is visible (intersected), we set its style to be an absolutely positioned item that
            // covers all the items in this specific page. This way we always know if any bit of the page is within
            // the observered area.
            sentinelVisibleStyle = {
                height: height,
                width: "100%",
                top: sentinelPosition,
                pointerEvents: "none" as "none",
                position: "absolute" as "absolute"
            };
        }

        const style = shouldRenderItems ? sentinelVisibleStyle : { height };

        return (
            <>
                <div data-key={pageIndex} ref={this.resolveSentinelDiv} style={style} />
                {shouldRenderItems && this.renderItems()}
            </>
        );
    }

    /**
     * Resolve the DOM element ref for the top page sentinel by calling the onSetPageRef callback. React
     * will call this with element=null when the element is removed from the DOM.
     */
    resolveSentinelDiv = (element: Element) => {
        this.props.onSetPageRef(element, this.props.index);
    };

    renderItems = (): JSX.Element[] => {
        const { items, maxRowsPerPage, onRenderRow } = this.props;
        const pageIndex = this.props.index;

        const renderedItems: JSX.Element[] = [];

        for (let i = 0; i < items.length; i++) {
            renderedItems.push(<React.Fragment key={`VI-${pageIndex}-${i}`}>{onRenderRow(pageIndex * maxRowsPerPage + i)}</React.Fragment>);
        }

        return renderedItems;
    };
}
