import schedulesViewStateStore from "../../store/store";
import { INoteEntity } from "sh-models";
import { mutatorAction } from "satcheljs";
import { ObservableMap } from "mobx";

export default mutatorAction('DELETE_NOTES_IN_VIEW', function deleteeNotesInView(
    notesToDelete: INoteEntity[]
) {
    let notes: ObservableMap<string, INoteEntity> = schedulesViewStateStore().notesInView;
    for (let i = 0; i < notesToDelete.length; i++) {
        notes.delete(notesToDelete[i].id);
    }
});
