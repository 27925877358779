import { action } from "satcheljs";
import { IShiftEntity } from "sh-models";

const shiftsModified = action(
    "SHIFTS_MODIFIED",
    (
        shiftsAdded: IShiftEntity[],
        shiftsDeleted: IShiftEntity[],
        shiftsUpdated: IShiftEntity[]
    ) => ({
        shiftsAdded: shiftsAdded || [],
        shiftsDeleted: shiftsDeleted || [],
        shiftsUpdated: shiftsUpdated || []
    })
);

export default shiftsModified;
