import { AriaRole } from "owa-accessibility";
import {
    ContextualMenuItemType,
    ICommandBarItemProps,
    IContextualMenuItemProps,
    IContextualMenuItemRenderFunctions,
    IContextualMenuProps,
    IContextualMenuRenderItem,
    IIconProps,
    IRefObject,
    ITooltipHostProps
} from "@fluentui/react";
import { ShouldShowBehavior } from "./ShouldShowBehavior";

const cssExports = require("styles/Exports.scss");

// |-- OVERFLOW -- LEFT -------------------------------------- RIGHT --|
export enum DisplayOption {
    Left, // render the item on the left side of the command bar
    Right, // render the item on the right side of the command bar
    Overflow // render the item in an overflow menu
}

export interface IFilterableCommandBarItem
    extends Omit<ICommandBarItemProps, "text" | "iconProps" | "key"> {
    key: string;
    /* the display name of the item (not tooltip - use title for tooltip) */
    name?: string;
    /* the name of the fabric icon (this should not be localized) - https://dev.office.com/fabric#/styles/icons */
    icon?: string | IIconProps;
    /* (optional) - if the item visible */
    shouldShow?: ShouldShowBehavior;
    /* (optional) - how to display the item (left, right or in an overflow menu) */
    displayOption?: DisplayOption;
    /* (optional) - menu items to display in items contextual menu - if no items are passed, the item will not show a menu when clicked */
    menuContent?: ICommandBarItemProps[] | IContextualMenuProps;
}

/**
 * A command bar item that can be rendered on a FilterableCommandBar
 */
export default class FilterableCommandBarItem implements ICommandBarItemProps {
    public className?;
    public disabled?;
    public subMenuProps?;
    public key;
    public text;
    public onClick;
    public onRender?;
    public onRenderContent?;
    public itemType;
    private displayOption: DisplayOption;
    private shouldShowBehavior: ShouldShowBehavior;
    public title;
    public role;
    public ariaLabel;
    public iconOnly;
    public iconProps;
    public onMouseDown;
    public tooltipHostProps: ITooltipHostProps;
    public componentRef: IRefObject<IContextualMenuRenderItem> = null;
    public id;

    /**
     * Static factory method to create a FilterableCommandBarItem
     * @param {IFilterableCommandBarItem} dataBag - IFilterableCommandBarItem
     * @returns FilterableCommandBarItem
     */
    public static create({
        ariaLabel,
        className,
        disabled,
        displayOption,
        itemType,
        icon,
        iconOnly,
        id,
        key,
        name,
        onClick,
        onMouseDown,
        onRender,
        onRenderContent,
        shouldShow,
        menuContent,
        onMenuDismissed,
        role,
        title
    }: IFilterableCommandBarItem): FilterableCommandBarItem {
        return new FilterableCommandBarItem(
            key,
            name,
            icon,
            iconOnly,
            onClick,
            className,
            shouldShow,
            displayOption,
            disabled,
            menuContent,
            itemType,
            title,
            onRender,
            onRenderContent,
            onMouseDown,
            role,
            ariaLabel,
            id,
            onMenuDismissed
        );
    }

    /**
     * FilterableCommandBarItem constructor
     * Do not initialize a FilterableCommandItem using the constructor, use the static create method instead.
     * @param name - the display name of the item
     * @param icon - the name of the fabric icon (this should not be localized) - https://dev.office.com/fabric#/styles/icons
     * @param onClick - callback when the item is clicked
     * @param className (optional) - custom css classname to add to the item
     * @param shouldShow (optional) - if the item visible
     * @param displayOption (optional) - how to display the item (left, right or in an overflow menu)
     * @param disabled (optional) - is the item disabled
     * @param menuContent (optional) - menu items to display in items contextual menu - if no items are passed, the item will not show a menu when clicked
     * @param itemType (optional) - type of item (divider, header, normal)
     * @param title (optional) - the tooltip on the item
     * @param role (optional) - aria role on the item
     * @param ariaLabel (optional) - aria label on the item
     * @param key (optional) - key for the item
     * @param id (optional) - id for this item
     * @param onMenuDismissed (optional) - dismiss submenu.
     */
    constructor(
        key: string,
        name: string,
        icon: string | IIconProps,
        iconOnly: boolean,
        onClick: (ev?: any, item?: ICommandBarItemProps) => void,
        className?: string,
        shouldShow?: ShouldShowBehavior,
        displayOption?: DisplayOption,
        disabled?: boolean,
        menuContent?: ICommandBarItemProps[] | IContextualMenuProps,
        itemType?: ContextualMenuItemType,
        title?: string,
        onRender?: (item: any) => React.ReactNode,
        onRenderContent?: (
            props: IContextualMenuItemProps,
            defaultRenders: IContextualMenuItemRenderFunctions
        ) => React.ReactNode,
        onMouseDown?: (item: ICommandBarItemProps, event: any) => void,
        role?: AriaRole,
        ariaLabel?: string,
        id?: string,
        onMenuDismissed?: () => void
    ) {
        this.disabled = !!disabled;
        this.className = className;
        this.key = key || name || "";
        this.id = id;
        this.text = name;
        this.onClick = onClick;
        this.onMouseDown = onMouseDown;
        this.shouldShowBehavior = shouldShow || this.alwaysShow;
        this.displayOption = displayOption || DisplayOption.Left;
        this.itemType = itemType || ContextualMenuItemType.Normal;
        this.title = title || "";
        this.onRender = onRender;
        this.onRenderContent = onRenderContent;
        this.role = role;
        this.ariaLabel = ariaLabel;
        this.iconOnly = iconOnly;
        this.tooltipHostProps = {
            calloutProps: {
                isBeakVisible: false,
                gapSpace: parseInt(cssExports.tooltipGapSpace)
            }
        };
        // Correctly parse icon prop to the fluent one.
        if (icon) {
            if (typeof icon === "string") {
                this.iconProps = {
                    iconName: icon
                };
            } else {
                this.iconProps = icon;
            }
        }
        // Add the menuContent as a list of items or as context menu props.
        if (menuContent) {
            if (Array.isArray(menuContent)) {
                this.subMenuProps = onMenuDismissed
                    ? {
                          items: menuContent,
                          onMenuDismissed: () => {
                              onMenuDismissed();
                          }
                      }
                    : { items: menuContent };
            } else {
                this.subMenuProps = menuContent;
            }
        }
    }

    /**
     * Checks if the item can be displayed in the DisplayOption passed as an argument.
     * @param {DisplayOption} displayOption - the display name where you want to show the item.
     * @returns boolean
     */
    public shouldShow(displayOption: DisplayOption): boolean {
        return this.displayOption == displayOption && this.shouldShowBehavior();
    }

    private alwaysShow(): boolean {
        return true;
    }

    /**
     * Used to set a ref object (created with createRef()) that will get initialized to the command bar
     * item once it is rendered. The ref can then be used to programmatically interact with the component.
     */
    public setRef(itemRef: IRefObject<IContextualMenuRenderItem>) {
        this.componentRef = itemRef;
    }
}
