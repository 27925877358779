import { ObservableMap } from "mobx";
import { Moment } from "moment";
import { MobxUtils } from "sh-application";
import {
    GroupedMemberData,
    GroupedShiftsData,
    MemberShiftsData,
    MemberShiftsDataGroup,
    ScheduleData,
    TemporalItem,
    TemporalItemTypes
} from "sh-application/components/schedule/ScheduleData";
import { ScheduleGridUtils } from "sh-application/components/schedules/lib";
import DateTimeFormatter, { DateTimeFormatType } from "sh-application/utility/DateTimeFormatter";
import DateUtils from "sh-application/utility/DateUtils";
import MemberUtils from "sh-application/utility/MemberUtils";
import ShiftUtils from "sh-application/utility/ShiftUtils";
import TagUtils from "sh-application/utility/TagUtils";
import ThemeUtils from "sh-application/utility/ThemeUtils";
import { IMemberEntity, INoteEntity, IShiftEntity, ITagEntity, ITimeOffReasonEntity, ScheduleCalendarTypes } from "sh-models";
import TagStates from "sh-models/tags/TagStates";
import { DataProcessingHelpers } from "sh-services";
import StringsStore from "sh-strings/store";
import { TagStore } from "sh-tag-store";
import { TeamStore } from "sh-team-store";
import { TimeOffReasonsStore } from "sh-timeoffreasons-store";

import ExportDataUtils from "./ExportDataUtils";

export interface ExportScheduleDataOptions {
    includeShiftNotes: boolean;
    includeDailyHours: boolean;
    includeTimeOff: boolean;
}

interface ShiftsFullAndPartialDaysTotal {
    numFullDays: number;
    numPartialDayHours: number;
}

/**
 * Utilities for Exporting time clock report to excel
 */
export default class ExportShiftsReportUtils {
    private exportDataUtils = new ExportDataUtils();

    // Filename extension for export as Excel XLSX
    private FileNameExtensionXlsx = ".xlsx";

    // Cell widths in number of characters
    private MinCellWidth = 15;
    public MemberNameCellWidth = 25;
    private DayNoteCellWidth = 20;
    private ShiftWithTextCellWidth = 20;
    public TagsListCellWidth = 20;
    public HoursDataCellWidth = 20;

    private shiftThemeData: Map<string, string>;

    private timeOffRequestEventCellColorValue = "D7D7D7"; // @colorTimeOffRequest/@subdom4

    private _strings: Map<string, string> = StringsStore().registeredStringModules.get("exportShiftsReport").strings;

    constructor() {
        this.shiftThemeData = new Map<string, string>();
        // IE bug: set values in map separately. Initializing in Map constructor doesn't work
        this.shiftThemeData.set("themeGreen", "E8F2D1");
        this.shiftThemeData.set("themeBlue", "CCE4F7");
        this.shiftThemeData.set("themeYellow", "FFF1CC");
        this.shiftThemeData.set("themePurple", "E6E4F8");
        this.shiftThemeData.set("themePink", "FFD9D9");
        this.shiftThemeData.set("themeGray", "CCCCCC");
        this.shiftThemeData.set("themeWhite", "ECEFF0");
        this.shiftThemeData.set("themeDarkGreen", "DBE6CD");
        this.shiftThemeData.set("themeDarkBlue", "CCDCE8");
        this.shiftThemeData.set("themeDarkYellow", "FFEEDA");
        this.shiftThemeData.set("themeDarkPurple", "DCD3E5");
        this.shiftThemeData.set("themeDarkPink", "EDD4D5");
        this.shiftThemeData.set("themeTimeOffGray", "D7D7D7");
        this.shiftThemeData.set("themeOrange", "F8AA73"); // deprecated
        this.shiftThemeData.set("themeLime", "CCFFCC"); // deprecated
    }

    /**
     * Calculates a filename for export shifts data.
     * @param {Moment} startDate Start of date range for export.
     * @param {Moment} endDate End of date range for export.
     * @param {string} teamId Team id.
     * @returns {string} Filename in this format ExportSchedule_<StartDate to <EndDate> <TeamID>.xlsx
     */
    public getExportShiftsReportFileName(startDate: Moment, endDate: Moment, teamId: string): string {
        const fileNameDateFormat = ExportDataUtils.getDateFormatForFileName();
        const fileName =
            this._strings
                .get("exportShiftsFileNameFormat")
                .format(startDate.format(fileNameDateFormat), endDate.format(fileNameDateFormat), teamId) + this.FileNameExtensionXlsx;
        return fileName;
    }
    /**
     * Returns the worksheet name for the shifts data in export shifts excel file
     * @returns worksheet name
     */
    public getShiftsDataWorksheetName(): string {
        return this._strings.get("exportSchedulesWorksheetName");
    }

    /**
     * Returns the worksheet name for the totals data in export shifts excel file
     * @returns worksheet name
     */
    public getTotalsDataWorksheetName(): string {
        return this._strings.get("exportTotalsWorksheetName");
    }

    /**
     * Generates row data for date headers for the given dates range
     * @param {Array} datesInRange
     * @returns array of cells containing date headers
     */
    public generateDateHeadersRowData(datesInRange: Moment[]): any[] {
        let dateHeadersRow = [];

        if (datesInRange) {
            for (let i = 0; i < datesInRange.length; i++) {
                const currDate = datesInRange[i];
                const dayNoteCell = this.exportDataUtils.OutputCell(
                    DateTimeFormatter.getDateTimeAsString(currDate, DateTimeFormatType.Date_DayDateMonthShort)
                );
                dateHeadersRow.push(dayNoteCell);
            }
        }
        return dateHeadersRow;
    }

    /**
     * Get the day note for this particular date from the provided dayNotes
     * @param noteDate the date for which the day note should be returned
     * @param dayNotes all day notes
     * @returns Note for this day
     */
    public getDayNoteFromListForDate(noteDate: Moment, dayNotes: INoteEntity[]): INoteEntity {
        if (dayNotes) {
            for (let i = 0; i < dayNotes.length; i++) {
                let note = dayNotes[i];
                if (DateUtils.areSameDates(noteDate, note.startTime)) {
                    return note;
                }
            }
        }
        return null;
    }

    /**
     * Generates row data for day notes for the given dates range
     * @param datesInRange
     * @param dayNotesData - all day notes data
     * @returns array of cells containing day notes
     */
    public generateDayNotesRowData(datesInRange: Moment[], dayNotesData: INoteEntity[]) {
        let dayNotesRow = [];

        if (datesInRange && dayNotesData) {
            for (let i = 0; i < datesInRange.length; i++) {
                const currDate = datesInRange[i];
                const dayNote = this.getDayNoteFromListForDate(currDate, dayNotesData);
                let dayNoteText = "";
                let cellWidth = this.MinCellWidth;
                if (dayNote) {
                    dayNoteText = dayNote.text;
                    // Set a suggested cell width so that there is enough room for the day notes text to be visible
                    cellWidth = this.DayNoteCellWidth;
                }

                const dayNoteCell = this.exportDataUtils.OutputCell(dayNoteText, true, null, null, cellWidth);
                dayNotesRow.push(dayNoteCell);
            }
        }

        return dayNotesRow;
    }

    /**
     * Generate row data for total paid hours per day for the given date range
     *
     * @param datesInRange - array of dates to calculate data for
     * @param {Object} groupedShiftsData - grouped data containing info about shift events
     * @returns {Array} array of cells containing total paid hours per day
     */
    public generateTotalHoursPerDayRowData(datesInRange: Moment[], groupedShiftsData: GroupedShiftsData) {
        let totalHoursPerDayRowData = [];
        if (datesInRange && groupedShiftsData) {
            for (let i = 0; i < datesInRange.length; i++) {
                const currDateIndex = DateUtils.fastCalculateDateIndex(datesInRange[i]);
                const totalHoursForDate = groupedShiftsData.hoursByDate.has(currDateIndex)
                    ? groupedShiftsData.hoursByDate.get(currDateIndex)
                    : 0;
                totalHoursPerDayRowData.push(this.exportDataUtils.OutputCell(totalHoursForDate));
            }
        }
        return totalHoursPerDayRowData;
    }

    /**
     * Generate export schedule data
     *
     * @param {Object} groupedShiftsData - grouped schedule data
     * @param {Array} notesInRange - notes in the date range
     * @param {Object} exportDataOptions - options for export data
     * @param {Date} startDate - start of date range to export (inclusive)
     * @param {Date} endDate - end of date range to export (inclusive)
     * @returns {Array} schedule cell data for export (array of cell data rows)
     */
    public generateDataForScheduleExport(
        groupedShiftsData: GroupedShiftsData,
        notesInRange: INoteEntity[],
        exportDataOptions: ExportScheduleDataOptions,
        startDate: Moment,
        endDate: Moment
    ) {
        let datesInRange = DateUtils.getDatesInRange(startDate, endDate);
        let emptyCellData = this.exportDataUtils.OutputCell("");

        //// Top row:  Total hours for team + Headers + Date headers

        let topRowData = [];

        // First column has no header.  This column consists of row header titles and member names.
        topRowData.push(emptyCellData);

        // Member position name header
        topRowData.push(this.exportDataUtils.OutputCell(this._strings.get("exportDataHeaderMemberPosition")));

        // Member total hours header
        topRowData.push(this.exportDataUtils.OutputCell(this._strings.get("exportDataHeaderPaidHoursTotal")));

        // Date headers
        let dateHeadersRowData = this.generateDateHeadersRowData(datesInRange);
        topRowData = topRowData.concat(dateHeadersRowData);

        //// Total hours row:  Total hours for team + Total hours per day

        // Team total hours
        let teamTotalHours = groupedShiftsData && groupedShiftsData.hours ? groupedShiftsData.hours : 0;
        teamTotalHours = Math.round(teamTotalHours * 100) / 100;
        let teamTotalHoursCellData = this.exportDataUtils.OutputCell(teamTotalHours);

        // Total hours per day
        let totalHoursPerDayTitleCellData = this.exportDataUtils.OutputCell(this._strings.get("exportDataHeaderPaidHoursTotal"));
        let totalHoursPerDayRowData = this.generateTotalHoursPerDayRowData(datesInRange, groupedShiftsData);

        let totalHoursPerDayFullRowData = [totalHoursPerDayTitleCellData, /* Position column */ emptyCellData, teamTotalHoursCellData];
        totalHoursPerDayFullRowData = totalHoursPerDayFullRowData.concat(totalHoursPerDayRowData);

        //// Day Notes row

        // Day notes header + empty cells for non-day notes columns
        let dayNotesTitleCellData = this.exportDataUtils.OutputCell(this._strings.get("dayNotesTitle"));
        let dayNotesFullRowData = [dayNotesTitleCellData, /* Position column */ emptyCellData, /* Total hours column */ emptyCellData];

        // Day notes data
        let dayNotesRowData = this.generateDayNotesRowData(datesInRange, notesInRange);
        dayNotesFullRowData = dayNotesFullRowData.concat(dayNotesRowData);

        let scheduleExportData = [];
        // Assemble all rows for final export
        scheduleExportData.push(topRowData);
        scheduleExportData.push(totalHoursPerDayFullRowData);
        scheduleExportData.push(dayNotesFullRowData);

        // Member rows with shifts data
        this.generateShiftsDataForSchedule(datesInRange, groupedShiftsData, exportDataOptions, scheduleExportData, startDate, endDate);

        return scheduleExportData;
    }

    /**
     * Generate export totals data, which contains summary information for the specified date range
     *
     * @param {Object} groupedShiftsData - grouped schedule data
     * @param {Object} exportDataOptions - options for export data
     * @param {Date} startDate - start of date range to export (inclusive)
     * @param {Date} endDate - end of date range to export (inclusive)
     * @returns {Array} schedule cell data for export (array of cell data rows)
     */
    public generateDataForTotalsExport(
        groupedShiftsData: GroupedShiftsData,
        exportDataOptions: ExportScheduleDataOptions,
        startDate: Moment,
        endDate: Moment
    ) {
        // Base headers
        let headersRowData: any[] = [];

        const memberNameHeaderCell = this.exportDataUtils.OutputCell(this._strings.get("exportHeaderMemberName"));
        memberNameHeaderCell.cellWidth = this.MemberNameCellWidth;
        headersRowData.push(memberNameHeaderCell);

        const tagsListHeaderCell = this.exportDataUtils.OutputCell(this._strings.get("exportHeaderMemberGroups"));
        tagsListHeaderCell.cellWidth = this.TagsListCellWidth;
        headersRowData.push(tagsListHeaderCell);

        const paidHoursTotalHeaderCell = this.exportDataUtils.OutputCell(this._strings.get("exportDataHeaderPaidHoursTotal"));
        paidHoursTotalHeaderCell.cellWidth = this.HoursDataCellWidth;
        headersRowData.push(paidHoursTotalHeaderCell);

        let datesInRange = DateUtils.getDatesInRange(startDate, endDate);

        // Header names for daily paid hours data
        if (exportDataOptions && exportDataOptions.includeDailyHours) {
            const numDates = datesInRange.length;
            for (let index = 0; index < numDates; index++) {
                const currentDate = datesInRange[index];
                const currentPaidHoursForDateHeader = this._strings
                    .get("exportHeaderPaidHoursForDate")
                    .format(DateTimeFormatter.getDateTimeAsString(currentDate, DateTimeFormatType.Date_DayDateMonthShort));
                const dailyPaidHoursHeaderCell = this.exportDataUtils.OutputCell(currentPaidHoursForDateHeader);
                dailyPaidHoursHeaderCell.cellWidth = this.HoursDataCellWidth;
                headersRowData.push(dailyPaidHoursHeaderCell);
            }
        }

        // Assemble rows for final export
        let totalsExportData: any[] = [];
        totalsExportData.push(headersRowData);

        const timeOffReasons = TimeOffReasonsStore().timeOffReasons;

        // Header names for TimeOff data
        if (exportDataOptions && exportDataOptions.includeTimeOff) {
            // TimeOff totals
            headersRowData.push(this.exportDataUtils.OutputCell(this._strings.get("exportHeaderTimeOffsFullDays")));
            headersRowData.push(this.exportDataUtils.OutputCell(this._strings.get("exportHeaderTimeOffsPartialHours")));

            // TimeOff totals per reason type
            timeOffReasons.forEach(currentReason => {
                const currentReasonDisplayName = this.getCurrentTimeOffReasonName(currentReason);
                const reasonHeaderNameFullDays = this._strings
                    .get("exportHeaderTimeOffsFullDaysWithReason")
                    .format(currentReasonDisplayName);
                const reasonHeaderNamePartialHours = this._strings
                    .get("exportHeaderTimeOffsPartialHoursWithReason")
                    .format(currentReasonDisplayName);

                headersRowData.push(this.exportDataUtils.OutputCell(reasonHeaderNameFullDays, null, null, null, this.HoursDataCellWidth));
                headersRowData.push(
                    this.exportDataUtils.OutputCell(reasonHeaderNamePartialHours, null, null, null, this.HoursDataCellWidth)
                );
            });
        }

        // Sort members by their display index
        let members = MobxUtils.MapToArray(TeamStore().members);
        members = members.sort(MemberUtils.memberComparator);
        const tags = TagStore().tags;

        // Generate row data for each team member
        members.forEach(currentMember => {
            let memberRow: any[] = [];

            let memberName = MemberUtils.getDisplayNameForMember(currentMember);
            memberRow.push(this.exportDataUtils.OutputCell(memberName));

            let memberTagsString = "";
            let memberPaidHoursTotal = 0;
            let memberTimeOffFullDaysTotal = 0;
            let memberTimeOffPartialDayHoursTotal = 0;

            let currentMemberData: GroupedMemberData =
                groupedShiftsData && groupedShiftsData.groupedMembersData && groupedShiftsData.groupedMembersData.get(currentMember.id);

            if (currentMemberData) {
                currentMemberData = groupedShiftsData.groupedMembersData.get(currentMember.id);
                memberTagsString = this.getTagsListStringForExportDataOutput(tags, currentMemberData.tagIds);
                memberPaidHoursTotal = currentMemberData.hours || 0;

                const timeOffShiftsTotals: ShiftsFullAndPartialDaysTotal = this.getTimeOffsFullDaysAndPartialDayHours(
                    currentMemberData.timeOffShifts,
                    startDate,
                    endDate
                );
                memberTimeOffFullDaysTotal = timeOffShiftsTotals ? timeOffShiftsTotals.numFullDays : 0;
                memberTimeOffPartialDayHoursTotal = timeOffShiftsTotals ? timeOffShiftsTotals.numPartialDayHours : 0;
            }

            // Only output removed members if they have notable data for the current output range
            const isMemberRemoved =
                MemberUtils.isMemberDeletedFromTeam(currentMember) || // Member is deleted from the team
                !currentMemberData ||
                !currentMemberData.tagIds ||
                currentMemberData.tagIds.length === 0; // Member does not belong to any groups
            if (
                isMemberRemoved &&
                memberPaidHoursTotal === 0 &&
                memberTimeOffFullDaysTotal === 0 &&
                memberTimeOffPartialDayHoursTotal === 0
            ) {
                return;
            }

            memberRow.push(this.exportDataUtils.OutputCell(memberTagsString));
            memberRow.push(this.exportDataUtils.OutputCell(memberPaidHoursTotal));

            // Daily hours data
            if (exportDataOptions && exportDataOptions.includeDailyHours && datesInRange) {
                datesInRange.forEach(currentDate => {
                    const currentDateIndex = DateUtils.fastCalculateDateIndex(currentDate);
                    const memberPaidHoursForDate =
                        currentMemberData && currentMemberData.hoursByDate && currentMemberData.hoursByDate.has(currentDateIndex)
                            ? currentMemberData.hoursByDate.get(currentDateIndex)
                            : 0;
                    memberRow.push(this.exportDataUtils.OutputCell(memberPaidHoursForDate));
                });
            }

            // TimeOff data
            if (exportDataOptions && exportDataOptions.includeTimeOff) {
                // TimeOff totals
                memberRow.push(this.exportDataUtils.OutputCell(memberTimeOffFullDaysTotal));
                memberRow.push(this.exportDataUtils.OutputCell(memberTimeOffPartialDayHoursTotal));

                // TimeOff totals per reason type
                timeOffReasons.forEach(currentReason => {
                    const timeOffReasonShiftsTotals: ShiftsFullAndPartialDaysTotal = this.getTimeOffsFullDaysAndPartialDayHours(
                        currentMemberData.timeOffShifts,
                        startDate,
                        endDate,
                        currentReason
                    );
                    const numFullDays = timeOffReasonShiftsTotals ? timeOffReasonShiftsTotals.numFullDays : 0;
                    const numPartialHours = timeOffReasonShiftsTotals ? timeOffReasonShiftsTotals.numPartialDayHours : 0;

                    memberRow.push(this.exportDataUtils.OutputCell(numFullDays));
                    memberRow.push(this.exportDataUtils.OutputCell(numPartialHours));
                });
            }

            totalsExportData.push(memberRow);
        });

        return totalsExportData;
    }

    /**
     * Get Timeoff reason name as it is or with 'Deleted' prefix if the reason is deleted.
     * @param currentReason Current timeoff reason.
     * @returns Timeoff reason name.
     */
    private getCurrentTimeOffReasonName(currentReason: ITimeOffReasonEntity): string {
        if (currentReason?.name && currentReason?.state !== TagStates.Deleted) {
            return currentReason.name;
        } else if (currentReason?.name && currentReason?.state === TagStates.Deleted) {
            return this._strings.get("deletedTimeOffReason").format(currentReason.name);
        } else {
            return this._strings.get("timeOffReasonFallbackName");
        }
    }

    /**
     * Generate export schedule data for MemberShiftsDataGroup
     */
    public generateDataForGroup(
        groupedShiftsData: GroupedShiftsData,
        groupData: MemberShiftsDataGroup,
        exportDataOptions: ExportScheduleDataOptions,
        scheduleExportData: any[],
        startDate: Moment,
        endDate: Moment
    ): void {
        if (!groupData) {
            return;
        }
        const timeOffReasons = TimeOffReasonsStore().timeOffReasons;

        let tagName: string = "";
        let isTagDeleted: boolean = false;

        // get the tag name
        const tag = groupData.tag;
        const tagId = tag && tag.id;
        if (TagUtils.isDefaultTag(tagId)) {
            tagName = this._strings.get("otherTagGroupName");
        } else {
            if (!tag) {
                return;
            }
            tagName = tag.name;
            isTagDeleted = tag && tag.state === TagStates.Deleted;
        }

        // Determine if at least one member in the group has shifts.
        // consider only assigned Shifts and exclude any time-offs. if tag is deleted, we dont need to render this group with only timeoffs
        let groupHasShifts = false;
        const numMembers = groupData.members ? groupData.members.length : 0;
        for (let i = 0; i < numMembers; i++) {
            const memberShiftsData: MemberShiftsData = groupData.memberShiftsData.get(groupData.members[i].id);
            if (memberShiftsData && memberShiftsData.memberShifts.length > 0) {
                groupHasShifts = true;
                break;
            }
        }

        // Don't show if tag is deleted and has no shifts
        if (!groupHasShifts && isTagDeleted) {
            return;
        }

        groupData.members.forEach((member: IMemberEntity, memberRowIndex: number) => {
            const memberId = member.id;

            const memberShiftsData: MemberShiftsData = groupData.memberShiftsData.get(memberId);
            if (!memberShiftsData) {
                return;
            }

            // Do not show the member if tag is deleted and member has no shifts.
            const memberHasShifts =
                (memberShiftsData.memberShifts && memberShiftsData.memberShifts.length > 0) ||
                (memberShiftsData.memberTimeOffs && memberShiftsData.memberTimeOffs.length > 0);
            if (isTagDeleted && !memberHasShifts) {
                return;
            }

            const isMemberDeletedFromTeam = MemberUtils.isMemberDeletedFromTeam(member);
            const isMemberRemovedFromTag = TagUtils.isMemberRemovedFromTag(tagId, memberId);
            // show shifts if the member has not been deleted or removed or if the member has active shifts
            if (!memberHasShifts && (isMemberDeletedFromTeam || isMemberRemovedFromTag)) {
                return;
            }

            // get the paid hours for the member
            const groupedTagData = groupedShiftsData.groupedTagData.has(tagId) && groupedShiftsData.groupedTagData.get(tagId);
            let groupedMemberData =
                groupedTagData && groupedTagData.groupedMembersData.has(memberId) ? groupedTagData.groupedMembersData.get(memberId) : null;
            let allottedHoursForMember = groupedMemberData ? groupedMemberData.hours : 0;

            let temporalItemsForMember: TemporalItem[] = ScheduleGridUtils.calculateTemporalItemsForMember(
                startDate,
                endDate,
                memberShiftsData
            );
            let temporalItemsForMemberByRow: TemporalItem[][] = ScheduleData.getNonOverlappingTemporalItemLists(temporalItemsForMember);

            temporalItemsForMemberByRow.forEach((temporalItemsForRow: TemporalItem[], memberRowIndex: number) => {
                let sortedShiftTemporalItems = temporalItemsForRow.slice().sort((firstItem: TemporalItem, secondItem: TemporalItem) => {
                    return ShiftUtils.shiftComparator(firstItem.shiftEvent, secondItem.shiftEvent);
                });

                // Calculate the ordered list of temporal items for this row, including both shift events and empty cell ranges
                let orderedTemporalItems: TemporalItem[] = ScheduleData.getOrderedTemporalItemsForRowInView(
                    startDate,
                    endDate,
                    sortedShiftTemporalItems,
                    (momentTime: Moment, isStartTime: boolean) => {
                        return ScheduleGridUtils.roundShiftTemporalItemTimeByDay(momentTime, isStartTime);
                    }
                );

                let shiftsDataRow: any[] = [];
                // Each shift row begins with the member name cell
                let memberName = MemberUtils.getDisplayNameForMember(member);
                shiftsDataRow.push(this.exportDataUtils.OutputCell(memberName, null, null, null, this.MemberNameCellWidth));

                // Add cell with the tag group name
                shiftsDataRow.push(this.exportDataUtils.OutputCell(tagName));

                // Add cell with allotted hours
                if (memberRowIndex === 0) {
                    shiftsDataRow.push(this.exportDataUtils.OutputCell(allottedHoursForMember));
                } else {
                    shiftsDataRow.push(this.exportDataUtils.OutputCell(null));
                }

                orderedTemporalItems.forEach((currentTemporalItem: TemporalItem) => {
                    if (currentTemporalItem.type === TemporalItemTypes.empty) {
                        // For an empty temporal item, construct a set of empty cells that span the duration of the temporal item
                        const datesInEmptyTemporalItemRange = DateUtils.getDatesInRange(
                            currentTemporalItem.startTime,
                            currentTemporalItem.endTime
                        );
                        datesInEmptyTemporalItemRange.forEach((currentEmptyCellDate: Moment) => {
                            shiftsDataRow.push(this.exportDataUtils.OutputCell(""));
                        });
                    } else {
                        const shiftEventCellsData: any[] = this.generateShiftEventCellData(
                            exportDataOptions,
                            currentTemporalItem,
                            DataProcessingHelpers.getArrayFromMap(timeOffReasons)
                        );
                        shiftsDataRow.push(...shiftEventCellsData);
                    }
                });
                scheduleExportData.push(shiftsDataRow);
            });
        });
    }

    /**
     * Generate export schedule data for shift events
     *
     * @param datesInRange - array of dates to calculate schedule data for
     * @param groupedShiftsData - grouped data containing info about shift events
     * @param {Object} exportScheduleDataOptions - options for export data
     * @param {Array} scheduleExportData - schedule cell data for export (array of cell data rows) should be added to this array
     */
    public generateShiftsDataForSchedule(
        datesInRange: Moment[],
        groupedShiftsData: GroupedShiftsData,
        exportDataOptions: ExportScheduleDataOptions,
        scheduleExportData: any[],
        startDate: Moment,
        endDate: Moment
    ): void {
        if (!groupedShiftsData) {
            return;
        }

        let sortedMemberShiftsDataGroups: MemberShiftsDataGroup[] = ScheduleGridUtils.calculateMemberShiftRowsData(
            groupedShiftsData,
            startDate,
            endDate,
            DataProcessingHelpers.getArrayFromMap(TagStore().tags),
            [] /* filtered tags: Empty as no filter should be applied while exporting */,
            DataProcessingHelpers.getArrayFromMap(TimeOffReasonsStore().timeOffReasons),
            DataProcessingHelpers.getArrayFromMap(TeamStore().members),
            [] /* filtered members: Empty as no filter should be applied while exporting */,
            ScheduleCalendarTypes.Month,
            true /* isViewGrouped */
        );
        sortedMemberShiftsDataGroups.forEach((memberShiftsDataGroup: MemberShiftsDataGroup, groupIndex) => {
            this.generateDataForGroup(groupedShiftsData, memberShiftsDataGroup, exportDataOptions, scheduleExportData, startDate, endDate);
        });
    }

    /**
     * Generates shift event cell data
     * @param {Object} exportScheduleDataOptions
     * @param {TemporalItem} temporalItem - the temporal item that contains the shift
     * @param {Array} timeOffReasons
     * @returns {Array} cell data array for the shift event. For time off shifts that span multiple days, should return a list with data in first cell followed by empty cells to match the span size
     */
    private generateShiftEventCellData(
        exportScheduleDataOptions: ExportScheduleDataOptions,
        temporalItem: TemporalItem,
        timeOffReasons: Array<ITimeOffReasonEntity>
    ): any[] {
        let shiftText = "";
        let shiftEventCellColor: string = null;
        let shiftEventTheme = null;
        let hasShiftNotes = false;
        let needsWideText = false;

        let shiftEvent: IShiftEntity = temporalItem && temporalItem.shiftEvent;

        if (shiftEvent) {
            let shiftCellUnitSize = 1;
            if (ShiftUtils.isTimeOffEvent(shiftEvent)) {
                // The temporalItem start and end times are within the view. The shift time can be beyond the view range
                const timeOffDurationDaysInView = Math.ceil(temporalItem.endTime.diff(temporalItem.startTime, "days", true /* precise */));

                if (shiftCellUnitSize < timeOffDurationDaysInView) {
                    shiftCellUnitSize = timeOffDurationDaysInView;
                }

                // Time Off events
                let timeOffReason = ShiftUtils.getTimeOffReasonFromShift(shiftEvent, timeOffReasons);

                shiftText = timeOffReason ? timeOffReason.name : this._strings.get("timeOffReasonFallbackName");
                shiftEventTheme = ThemeUtils.getValidShiftTheme(shiftEvent.theme, /* isTimeOff */ true);
                hasShiftNotes =
                    exportScheduleDataOptions &&
                    exportScheduleDataOptions.includeShiftNotes &&
                    shiftEvent.notes &&
                    shiftEvent.notes.length > 0;
            } else if (ShiftUtils.isTimeOffRequestEvent(shiftEvent)) {
                // The temporalItem start and end times are within the view. The shift time can be beyond the view range
                const timeOffDurationDaysInView = Math.ceil(temporalItem.endTime.diff(temporalItem.startTime, "days", true /* precise */));

                if (shiftCellUnitSize < timeOffDurationDaysInView) {
                    shiftCellUnitSize = timeOffDurationDaysInView;
                }

                // Time Off Requests
                shiftText = this._strings.get("timeOffRequestCellTitle");
                shiftEventCellColor = this.timeOffRequestEventCellColorValue;
            } else {
                // Shift events
                if (shiftEvent.title) {
                    // If the shift has a text label, then use that
                    shiftText = shiftEvent.title;
                } else {
                    // Otherwise use the time range string
                    shiftText = DateTimeFormatter.getEventTimeRangeAsString(shiftEvent.startTime, shiftEvent.endTime);
                    needsWideText = true;
                }

                hasShiftNotes =
                    exportScheduleDataOptions &&
                    exportScheduleDataOptions.includeShiftNotes &&
                    shiftEvent.notes &&
                    shiftEvent.notes.length > 0;
                shiftEventTheme = ThemeUtils.getValidShiftTheme(shiftEvent.theme, /* isTimeOff */ false);
            }

            if (hasShiftNotes) {
                shiftText += "\n" + shiftEvent.notes;
                needsWideText = true;
            }

            if (shiftEventTheme) {
                shiftEventCellColor = this.shiftThemeData.has(shiftEventTheme) && this.shiftThemeData.get(shiftEventTheme);
            }

            let cellWidth = this.MinCellWidth;
            if (needsWideText) {
                cellWidth = this.ShiftWithTextCellWidth;
            }
            // Enable text wrapping when shift notes are present for better rendering of multi-line text in Excel.
            // Otherwise only the first line will be visible without formatting fixup in Excel.
            let outputCell = this.exportDataUtils.OutputCell(
                shiftText,
                hasShiftNotes,
                null,
                shiftEventCellColor,
                cellWidth,
                null,
                1,
                shiftCellUnitSize
            );
            let shiftEventCellsData: any[] = [outputCell];
            if (shiftCellUnitSize > 1) {
                // Add empty cells so that subsequent cells don't get overwritten because of the cell span.
                // Even though we specify shiftCellUnitSize (or cell span), excel needs each cell item to be
                // specified and will merge those cells into one with the data from the first cell in the
                // range. The below loop will add the additional empty cell that will be merged in excel
                for (let shiftCellUnitIndex = 1; shiftCellUnitIndex < shiftCellUnitSize; shiftCellUnitIndex++) {
                    shiftEventCellsData.push(this.exportDataUtils.OutputCell(""));
                }
            }
            return shiftEventCellsData;
        } else {
            let outputCell = this.exportDataUtils.OutputCell("");
            return [outputCell];
        }
    }

    /**
     * Returns the list of non-deleted tag names for export data output.
     * @param tags The tags by their ids.
     * @param tagIds The list of tag ids to lookup. It can be null if no tags.
     * @returns The list of non-deleted tag names.
     */
    private getTagsListStringForExportDataOutput(tags: ObservableMap<string, ITagEntity>, tagIds: string[]): string {
        const tagNames: string[] = [];

        if (tags && tagIds) {
            tagIds.forEach(tagId => {
                const tag = tags.get(tagId);

                if (tags.has(tagId) && tag.state != TagStates.Deleted) {
                    let tagName = tag.name;

                    if (!tagName || tagName.trim().length == 0) {
                        // Use a default tag group name for groups that have no name
                        tagName = this._strings.get("unnamedTagGroupName");
                    }

                    tagNames.push(tagName);
                }
            });
        }

        return tagNames.join(this._strings.get("dataValueDelimiter"));
    }

    /**
     * Gets the duration in number of full days and partial day hours
     * @param {Array} shifts - Array of shifts
     * @param {Date} startDate - start of date range to export (inclusive)
     * @param {Date} endDate - end of date range to export (inclusive)
     * @param {ITimeOffReasonEntity} timeOffReason - If specified, get totals only for timeoffs with the specific reason
     * @returns {IShiftsFullAndPartialDaysTotal}
     */
    private getTimeOffsFullDaysAndPartialDayHours(
        shifts: IShiftEntity[],
        startDate: Moment,
        endDate: Moment,
        timeOffReason?: ITimeOffReasonEntity
    ): ShiftsFullAndPartialDaysTotal {
        // endDate is the view end date which can be a minute before midnight
        const endDayForDate: Moment = DateUtils.getEndOfDayForDate(endDate);

        // To keep things simple, the number of full days is the number of days for an All Day date range, and partial day hours are for
        // non-All Day date ranges even if they span multiple days.
        let numFullDays = 0;
        let numPartialDayHours = 0;
        if (shifts) {
            shifts.forEach(shift => {
                if (!timeOffReason || timeOffReason.id === shift.timeOffReasonId) {
                    const timeOffStartTimeInView: Moment = shift.startTime.isBefore(startDate) ? startDate : shift.startTime;
                    const timeOffEndTimeInView: Moment = shift.endTime.isAfter(endDayForDate) ? endDayForDate : shift.endTime;

                    if (DateUtils.isAllDayTimeRange(timeOffStartTimeInView, timeOffEndTimeInView)) {
                        numFullDays += timeOffEndTimeInView.diff(timeOffStartTimeInView, "days");
                    } else {
                        numPartialDayHours += timeOffEndTimeInView.diff(timeOffStartTimeInView, "hours", true /* precise */);
                    }
                }
            });
        }
        const shiftsTotals: ShiftsFullAndPartialDaysTotal = { numFullDays: numFullDays, numPartialDayHours: numPartialDayHours };
        return shiftsTotals;
    }

    /**
     * Save export cell data as an XLSX file
     * @param {Array} cellDataArray - Array of arrays of cell data rows. Each item contains the row data for an Excel worksheet.
     * @param {Array} workSheetNamesArray - Array of names for Excel worksheets
     * @param {String} xlsxFileName
     */
    public saveDataAsXlsx(cellDataArray: any[], workSheetNamesArray: string[], xlsxFileName: string) {
        this.exportDataUtils.saveDataAsXlsx(cellDataArray, workSheetNamesArray, xlsxFileName);
    }
}
