import errorViewStateStore from "../store/store";
import { ErrorViewDetails } from "../store/schema/ErrorWatcherViewStateStore";
import { mutatorAction } from "satcheljs";

/**
 * Update the store to set a blocking error
 */
export default mutatorAction('setBlockingError', function setBlockingError(
    blockingError: ErrorViewDetails
) {
    errorViewStateStore().blockingError = blockingError;
});
