import TraceLevel from './TraceLevel';

export declare type TraceHandler = (message: string, tracelevel?: TraceLevel, errorObject?: any) => void;

let listeners: TraceHandler[] = [];

export function registerTracerListener(traceListener: (message: string, tracelevel?: TraceLevel, errorObject?: any) => any) {
    listeners.push(traceListener);
}

function trace(message: string, traceLevel: TraceLevel, errorObject?: any) {
    listeners.forEach(listener => {
        listener(message, traceLevel, errorObject);
    });
}

function info(message: string) {
    trace(message, TraceLevel.Info);
}

function warn(message: string) {
    trace(message, TraceLevel.Warning);
}

function error(message: string, errorObject?: any) {
    trace(message, TraceLevel.Error, errorObject || { error: new Error(message) });
}

export default {
    info: info,
    warn: warn,
    error: error
};