import schedulesViewStateStore from "../store/store";
import setGlobalMessage from "sh-application/actions/setGlobalMessage";
import { appViewState } from "sh-application/store";
import { MessageBarType } from "@fluentui/react";
import { mutatorAction } from "satcheljs";
import { ScheduleGridUtils } from "..";

export default mutatorAction('SET_FEATURE_CLICKED_BEFORE_DATA_FETCHED', function setGlobalMessageForScheduleLoaded() {
    if (schedulesViewStateStore().featureClickedBeforeDataFetched) {
        setGlobalMessage(appViewState().globalMessageViewState, ScheduleGridUtils.getScheduleMessageLoadedMessage() /* text shown when schedule is loaded */, MessageBarType.success, null, null, true, true);
    }
});
