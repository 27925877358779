import deleteShifts from "sh-stores/sh-shift-store/actions/deleteShifts";
import deleteShiftsInStore from "sh-stores/sh-shift-store/mutatorActions/deleteShiftsInStore";
import shiftsModified from "sh-stores/sh-shift-store/actions/shiftsModified";
import { MyShiftStore, ShiftStore, ShiftStoreSchema } from "..";
import { orchestrator } from "satcheljs";
import { ShiftStoreTypes } from "sh-application/../StaffHubConstants";

export default orchestrator(deleteShifts, async actionMessage => {
    let shiftStoreEntity: ShiftStoreSchema;
    actionMessage.shiftStoreType === ShiftStoreTypes.MyShiftStore ?
    shiftStoreEntity = MyShiftStore()
    :
    shiftStoreEntity = ShiftStore();

    // Delete the shifts from the Shift Store
    deleteShiftsInStore(actionMessage.shiftsDeleted, shiftStoreEntity);

    // Fire the shifts modified event so the UI knows to update
    shiftsModified(null, actionMessage.shiftsDeleted, null);
});