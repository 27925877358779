import * as React from "react";
import App from "./App";
import { HomeRedirect } from "./HomeRedirect";
import { RouteComponentProps } from "react-router-dom";

export default class NoMatch404 extends React.Component<RouteComponentProps<any>, {}> {
    componentDidMount() {
        if (!ENV_PROD) {
            App.hideLoadingScreen();
        }
    }

    render() {
        // in release environments, redirect to home
        if (ENV_PROD) {
            return (<HomeRedirect />);
        } else {
            const { pathname } = this.props.location;

            return (
                <>
                    <p>Unknown route: { pathname }</p>
                </>
            );
        }
    }
}
