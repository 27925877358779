import INoteDataService from "./INoteDataService";
import NoteUtils from "sh-application/utility/NoteUtils";
import RestClient from "sh-rest-client";
import { DataService } from "./DataService";
import { deleteNotes, NoteStore, updateNotes } from "sh-note-store";
import { INoteEntity, NoteEntity } from "sh-models";
import { TeamStore } from "sh-stores/sh-team-store";
import { TestDataIDConstant } from "sh-application/../StaffHubConstants";

class NoteDataService extends DataService implements INoteDataService {
    /**
     * saveNote - adds or updates a note
     * @param {string} tenantId - Tenant id
     * @param {string} teamId - Team id
     * @param {INoteEntity} note - the note service model that needs to be saved
     * @param {boolean} isNewNote - when true, we are saving a new note - else we are updating an existing note
     * @param {boolean} isPublished - indicates if saved note should be published immediately
     */
    public async saveNote(tenantId: string, teamId: string, noteEntity: INoteEntity, isNewNote: boolean, isPublished: boolean = false): Promise<INoteEntity> {

            noteEntity.isPublished = isPublished;
            const addOrUpdateNote = isNewNote ? RestClient.addNote : RestClient.updateNote;

            const updatedNote: INoteEntity = await addOrUpdateNote(tenantId, teamId, noteEntity);
            // add or update the note in storage
            this.updateNotesInStorage([updatedNote], false /* isOptimisticUpdate */);
            return updatedNote;
    }

    /**
     * delete note action - deletes a note
     * @param {string} tenantId - Tenant id
     * @param {string} teamId - Team id
     * @param {INoteEntity} note - The note that needs to be deleted
     * @param {boolean} isPublished (optional) - Set to true to mark the note as published/shared. Default is false
     */
    public async deleteNote(tenantId: string, teamId: string, note: INoteEntity, isPublished: boolean = false): Promise<INoteEntity> {
        note.isPublished = isPublished;
        const deletedNote: INoteEntity = await RestClient.deleteNote(tenantId, teamId, note);
        this.updateNotesInStorage([deletedNote], false /* isOptimisticUpdate */);
        return deletedNote;
    }

    /**
     * FOR TESTING PURPOSES ONLY
     * Deletes all members found in the store that have ids containing the test data string constant.
     */
    public async deleteTestNotes(): Promise<INoteEntity[]> {
        let notesToDelete: INoteEntity[] = [];
        NoteStore().notes.forEach((note: INoteEntity) => {
            if (note.id.startsWith(`NOTE_${TestDataIDConstant}`) && !NoteUtils.isDeletedNote(note)) {
                notesToDelete.push(note);
            }
        });

        let noteDeletionPromises: Promise<INoteEntity>[] = [];
        for ( let i = 0; i < notesToDelete.length; i++) {
            noteDeletionPromises.push(this.deleteNote(notesToDelete[i].tenantId, notesToDelete[i].teamId, notesToDelete[i], true /* isPublished */));
        }
        return Promise.all(noteDeletionPromises);
    }

    /**
     * Update notes in memory (and asynchronously in the db)
     * @param notes Updated notes
     * @param {boolean} isOptimisticUpdate - When true, the note is being updated optimistically and should only be updated in memory
     */
    public updateNotesInStorage(notes: INoteEntity[], isOptimisticUpdate: boolean): void {
        if (!notes || !notes.length) {
            return;
        }

        if (notes[0].teamId === TeamStore().teamId) {
            // Update the in-memory store if the notes are for the current team
            updateNotes(notes, isOptimisticUpdate);
        }
        if (!isOptimisticUpdate) {
            // asynchronously update the database
            setTimeout(async () => {
                await this.noteDatabase.setNotes(notes);
            }, 0);
        }
    }

    /**
     * Delete notes in memory (and asynchronously in the db)
     * @param notes Delete notes
     * @param {boolean} isOptimisticUpdate - When true, the note is being updated optimistically and should only be updated in memory
     */
    public deleteNotesInStorage(notes: INoteEntity[], isOptimisticUpdate: boolean): void {
        if (!notes || !notes.length) {
            return;
        }

        let notesToSoftDelete: INoteEntity[] = [];
        let notesToHardDelete: INoteEntity[] = [];

        // We use clones of the argument entities. This is because setting the entities in the satchel stores will mark them as observables
        // and any callers who still hold references to these entities will not be able to mutate them (in event of an error, for example) without
        // triggering mobx exceptions
        const notesToProcess: Array<INoteEntity> = notes.map(note => NoteEntity.clone(note));
        notesToProcess.map((noteModel) => {
            // If the deleted note is not intended to be displayed in the schedule, remove it from the store
            if (!NoteUtils.isNoteDisplayableForScheduleView(noteModel)) {
                notesToHardDelete.push(noteModel);
            } else {
                // note is not deleted because it is displayable. Just update the cache
                notesToSoftDelete.push(noteModel);
            }
        });

        // update the notes that are soft deleted because they are still displayable
        if (notesToSoftDelete.length) {
            this.updateNotesInStorage(notesToSoftDelete, isOptimisticUpdate);
        }
        // Remove the hard deleted notes from memory and the db
        if (notesToHardDelete.length) {
            if (notesToHardDelete[0].teamId === TeamStore().teamId) {
                // Update the in-memory store if the notes are for the current team
                deleteNotes(notesToHardDelete);
            }

            if (!isOptimisticUpdate) {
                // asynchronously update the database
                setTimeout(async () => {
                    await this.noteDatabase.deleteNotes(notesToHardDelete[0].teamId, notesToHardDelete.map((noteDeleted: INoteEntity) => noteDeleted.id));
                }, 0);
            }
        }
    }

    /**
     * Resets the sync state
     */
    public async resetSyncState() {
        await this.noteDatabase.deleteNotesSyncState(this.sessionId);
    }

    /**
     * Delete the old sessions
     * @param sessionIds List of sessionIds to delete
     */
    public async deleteSessionData(sessionIds: string[]) {
        await this.noteDatabase.deleteSessionData(sessionIds);
    }
}

const service: INoteDataService = new NoteDataService();
export default service;