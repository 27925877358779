import ShiftHintDatePickerStoreSchema from "./schema/ShiftHintDatePickerStoreSchema";
import { IBaseShiftEntity } from "sh-models";
import { Moment } from "moment";

/**
 * Get a new instance of the ShiftHintDatePickerStore
 */
export function getNewShiftHintDatePickerStore(): ShiftHintDatePickerStoreSchema {
    return {
        isCalloutShowing: false,
        dateHasShiftsMap: new Map<string, boolean>(),
        areShiftsFetched: false
    };
}

/**
 * Get the key for the dateHasShiftsMap from a date
 * @param date
 */
export function getDateHasShiftsMapKeyFromDate(date: Moment): string {
    return date && date.clone().startOf("day").toString();
}

/**
 * Get the key for the dateHasShiftsMap from a shift
 * @param shift
 */
export function getDateHasShiftsMapKeyFromShift(shift: IBaseShiftEntity): string {
    return shift && getDateHasShiftsMapKeyFromDate(shift.startTime);
}