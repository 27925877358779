import { importScheduleDialogViewState } from "../";
import { mutatorAction } from "satcheljs";

export default mutatorAction("resetStore", function resetStore() {
    // Reset view state of dialog before closing
    importScheduleDialogViewState().job = null;
    importScheduleDialogViewState().errorMessage = null;
    importScheduleDialogViewState().dialogErrorMessage = null;
    importScheduleDialogViewState().importScheduleInProgress = false;
    importScheduleDialogViewState().dialogOpen = false;
    importScheduleDialogViewState().reachedMaxRetries = false;
    importScheduleDialogViewState().hideTryImportAgainButton = true;
    importScheduleDialogViewState().jobResult = null;
});