import CurrentTeamDataProvider from "sh-services/dataproviders/CurrentTeamDataProvider";
import RestClient from "sh-rest-client";
import { ITagEntity } from "sh-models";
import { ITeamDatabase } from "sh-services/data/ITeamDatabase";
import { MobxUtils } from "sh-application";
import { setTags, TagStore } from "sh-tag-store";
import { TeamStore } from "sh-team-store";

/**
 * Tags Data Provider for the current team
 */
export class TagsDataProvider extends CurrentTeamDataProvider<ITagEntity[]> {

    protected teamDatabase: ITeamDatabase;
    protected tenantId: string;

    constructor(teamDatabase: ITeamDatabase, tenantId: string, teamId: string) {
        super(teamId);
        this.teamDatabase = teamDatabase;
        this.tenantId = tenantId;
    }

    /**
     * Return data if it's found in memory (otherwise return undefined)
     */
    async getDataFromMemoryForCurrentTeam() {
        return (TeamStore().team && TeamStore().team.id == this.teamId && TagStore().tags && TagStore().tags.size ? MobxUtils.MapToArray(TagStore().tags) : undefined);
    }

    /**
     * Return data if it's found in the database (otherwise return undefined)
     */
    async getDataFromDatabase() {
        return await this.teamDatabase.getTags(this.teamId);
    }

    /**
     * Make an HTTP request to fetch the data from the network
     */
    async getDataFromNetwork() {
        return await RestClient.getTags(this.tenantId, this.teamId);
    }

    /**
     * Set data in memory
     */
    async setDataInMemoryForCurrentTeam(data: ITagEntity[]) {
        setTags(data);
    }

    /**
     * Set data in the database
     */
    async setDataInDatabase(data: ITagEntity[]) {
        if (data) {
            return await this.teamDatabase.setTags(data);
        }
    }

    /**
     * Make a network call to update the data
     */
    async saveDataToNetwork(data: ITagEntity[]): Promise<ITagEntity[]> {
        throw new Error("TagsDataProvider.saveDataToNetwork not implemented");
    }
}