import schedulesViewStateStore from "../../store/store";
import { INoteEntity } from "sh-models";
import { mutatorAction } from "satcheljs";
import { ObservableMap } from "mobx";

export default mutatorAction('UPDATE_NOTES_IN_VIEW', function updateNotesInView(
    notesInView: INoteEntity[]
) {
    let notes: ObservableMap<string, INoteEntity> = schedulesViewStateStore().notesInView;
    for (let i = 0; i < notesInView.length; i++) {
        notes.set(notesInView[i].id, notesInView[i]);
    }
});
