export default class UserFRETypes {
    // Note: The values should all be in lower case. usersettings api converts it to lower case before saving
    public static readonly TimeClockManagerSetup: UserFREType = "timeclockmanagersetup";    // TimeClock setup FRE for Manager
    // Removed the 2 Staffhub specific FRE flag. Added the equivalent Shifts ones
    // public static readonly WelcomeToStaffHub: UserFREType = "welcometostaffhub";         // Welcome to StaffHub Dialog
    // public static readonly SchedulesTour: UserFREType = "schedulestour";                 // Schedules tour
    public static readonly WelcomeToShifts: UserFREType = "welcometoshifts";                // Welcome to Shifts Dialog
    public static readonly ShiftsSchedulesTour: UserFREType = "shiftsschedulestour";        // Shifts App Schedules tour
    public static readonly SaveShareShiftTour: UserFREType = "saveshareshifttour";          // Share or save shift tour
    public static readonly NewTeamShareShiftsTour: UserFREType = "newteamsharetour";        // Share shifts tour (only shown to new teams)
    public static readonly TestTour: UserFREType = "testtour";                              // Test tour
    public static readonly TestTour2: UserFREType = "testtour2";                            // Test tour 2
    public static readonly PrintTour: UserFREType = "printtour";
    public static readonly PrintTourWithDontShow: UserFREType = "printtourwithdontshow";
    public static readonly AvailabilityTour: UserFREType = "availabilitytour";              // FRE for Availability feature
    public static readonly OpenShifts: UserFREType = "openshifts";                          // FRE for Open Shifts feature
    public static readonly CopyShiftActivities: UserFREType = "web_copyshiftactivities";    // FRE for CopyShiftActivitiesWithShiftsEnabled setting
    public static readonly EmployeeViewMenuTour: UserFREType = "employeeviewmenutour";      // FRE for Employee View Menu Tour
    public static readonly ScheduleFilterTour: UserFREType = "web_schedulefilterbutton";    // FRE for Schedule Filter Tour
    public static readonly TimeClockTour: UserFREType = "web_timeclockbutton";              // FRE for Time Clock Tour
}

/**
 * FREs that are run upon viewing the Schedule view for the first time
 * Note: Keep this list updated if new startup FREs are added.
 */
export const scheduleStartupFREs: UserFREType[] = [
    UserFRETypes.ShiftsSchedulesTour,
    UserFRETypes.AvailabilityTour,
    UserFRETypes.NewTeamShareShiftsTour,
    UserFRETypes.OpenShifts
];

export type UserFREType = "timeclockmanagersetup" | "welcometoshifts" |  "shiftsschedulestour" | "saveshareshifttour" | "newteamsharetour" | "testtour" |
                          "testtour2" | "printtour" | "printtourwithdontshow" | "availabilitytour" | "openshifts" | "web_copyshiftactivities" | "employeeviewmenutour" | "web_schedulefilterbutton" | "web_timeclockbutton";