import { ComponentClassNames, mergeStyleSets } from "@microsoft/shifts-ui-core";

type ClassNames = "icon" | "primaryText";

/**
 * Gets the 'UserPersonaWithoutPicture' component CSS class names.
 * @returns The 'UserPersonaWithoutPicture' component CSS class names.
 */
export const getClassNames = (): ComponentClassNames<ClassNames> => {

    return mergeStyleSets({
        icon: {
            display: "none"
        },
        primaryText: {
            overflow: "hidden",
            textOverflw: "ellipsis",
            display: "-webkit-box",
            height: "100%",
            lineHeight: "1.5rem",
            whiteSpace: "break-spaces",
            wordWrap: "break-word",
            "-webkit-line-clamp": "2",
            "-webkit-box-orient": "vertical"
        }
    });
};