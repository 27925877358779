import { action } from "satcheljs";
import { StaffHubHttpError } from 'sh-application';

const setErrorMessageFromException = action(
    "SET_ERROR_MESSAGE_FROM_EXCEPTION",
    (
        error: StaffHubHttpError
    ) => ({
        error: error
    })
);

export default setErrorMessageFromException;