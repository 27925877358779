import { mutatorAction } from "satcheljs";
import { ReorderMemberOverlayProps } from "sh-application/components/schedules/ReorderMemberOverlay";
import { SchedulesViewStateStore } from "sh-application/components/schedules/lib/store/schema/SchedulesViewStateStore";

/**
 * Set the props to be used by the reorder member overlay
 * @param viewState - Schedule view state
 * @param props
 */
export default mutatorAction("SET_REORDER_MEMBER_PROPS",
    function setReorderMemberProps(viewState: SchedulesViewStateStore, props: ReorderMemberOverlayProps) {
        viewState.reorderMemberProps = props;
    }
);