import IShiftrObjectModelBase from "../IShiftrObjectModelBase";
import { ICustomFieldEntity } from "sh-models";

/**
 * Base class that sets up common aspects for the TeamInfo client and service models. NOTE: If Service and Client models diverge, split this interface into client and service interfaces
 */
export default interface ITeamInfoEntity extends IShiftrObjectModelBase {

    /**
     * The tenant ID
     */
    tenantId: string;

    /**
     * The team handle (unique user-readable team ID)
     */
    handle: string;

    /**
     * The name of the team
     */
    name: string;

    /**
     * The icon of the team.
     */
    pictureUrl: string;

    /**
     * The location of the team (this is the name of the department)
     */
    location: string;

    /**
     * A two letter country code (ISO standard 3166) of the team. This is used to to enforce license restrictions for the users in this team
     */
    countryCode: string;

    /**
     * Time Clock Enabled Flag
     */
    timeClockEnabled: boolean;

    /**
     * Hide Open Shifts toggle
     */
    hideOpenShifts: boolean;

    /**
     * Setting to determine if the Team's schedule need to be shared with other teams
     * @deprecated TODO (CLM): Confirm this is no longer used (including in service side) and remove
     */
    enableCrossTeamScheduling?: boolean;

    /**
     * Swap Shifts Enabled Flag
     */
    swapShiftsRequestsEnabled: boolean;

    /**
     * Offer Shifts Enabled Flag
     */
    offerShiftRequestsEnabled: boolean;

    /**
     * TimeOff Shifts Requests Enabled Flag
     */
    timeOffRequestsEnabled: boolean;

    /**
     * Setting to determine if, when copying shifts, shift activities should be copied or not
     */
    copyShiftActivitiesWithShiftsEnabled: boolean;

    /**
     * The list of team ids with which the current team's schedule need to be shared
     * @deprecated TODO (CLM): Confirm this is no longer used (including in service side) and remove
     */
    teamIdsToShareScheduleWith?: Array<string>;

    /**
     * LocationSettings for the team
     */
    locationSettings: Array<LocationSetting>;

    /**
     * The identifier to the group provisioned for this team
     */
    groupId: string;

    /**
     * The identifier for the drive associated to the group provisioned for this team
     */
    groupDriveId: string;

    /**
     * State of drive provisioning. = ['Unattempted', 'Success', 'Failed', 'FailedNoLicense'
     */
    driveProvisionState: string;

    /**
     * Group Classification details - fetched from graph APIs instead of what is persisted in the DocDB
     */
    classification: string;

    /**
     * Cross Location Shifts Enabled Flag
     * TODO (CLM): Make this required once the service side is updated.
     */
    crossLocationShiftsEnabled?: boolean;

    /**
     * Cross Location Shift Request Approval Required Flag
     * TODO (CLM): Make this required once the service side is updated.
     */
    requireCrossLocationShiftRequestApprovalEnabled?: boolean;

    /**
     * The customFields for the team
     */
    customFields: Array<ICustomFieldEntity>;

    /**
     * The ID of the General channel in Teams (aka Teams' TeamId)
     */
    teamChatId: string;

    /**
     * Enable Last Name Initial Flag
     */
    enableLastNameInitial: boolean;

    /**
     * The application that manages this team ['StaffHub', 'Teams']
     */
    managedBy: TeamManagedByType;

    /**
     * Workforce Integration Ids
     */
    workforceIntegrationIds: Array<string>;
}

/**
 * TeamType
 */
export enum TeamTypes {
    /**
     * Standard team is an existing StaffHub team that is neither new (just created)
     */
    Standard = "Standard",

    /**
     * New team is a team that was just created and this is the first time running StaffHub.
     */
    New = "New"
}

type TeamManagedByStaffHubType = "StaffHub";
type TeamManagedByTeamsType = "Teams";
export type TeamManagedByType = TeamManagedByStaffHubType | TeamManagedByTeamsType;
export const TeamManagedByTypes = {
    StaffHub: "StaffHub" as TeamManagedByStaffHubType,
    Teams: "Teams" as TeamManagedByTeamsType
};

/**
 * States in one drive provisioning
 */
export const driveProvisionState = {
    // StaffHub web client custom state (won't ever be part of service response)
    IN_PROGRESS: "IN_PROGRESS",

    // Service response states
    UNATTEMPTED: "Unattempted",
    SUCCESS: "Success",
    FAILED: "Failed",
    FAILED_NO_LICENSE: "FailedNoLicense"
};

export interface LocationSetting {
    name: string;
    address: string;
    coordinates: Location;
}

export interface Location {
    latitude: string;
    longitude: string;
}
