import { StaffHubError } from 'sh-application';
import IScheduleProvisionJobResult from 'sh-models/job/IScheduleProvisionJobResult';

/**
 * The result of the job that's provisioning a Shifts (StaffHub) team from a Teams team
 */
export default class ScheduleProvisionJobResult implements IScheduleProvisionJobResult {
    teamId: string;
    groupId: string;
    error: StaffHubError;

    constructor(teamId: string, groupId: string, error: StaffHubError) {
        this.teamId = teamId;
        this.groupId = groupId;
        this.error = error;
    }

    static fromJson(jsonData: IScheduleProvisionJobResult): ScheduleProvisionJobResult {
        if (!jsonData) {
            return null;
        }

        return new ScheduleProvisionJobResult(jsonData.teamId, jsonData.groupId, jsonData.error);
    }
}