import { action } from "satcheljs/lib/legacy";
import { SchedulesViewStateStore } from "sh-application/components/schedules/lib/store/schema/SchedulesViewStateStore";

/**
 * Sets the visiblity of the hover column in the schedules view state
 */
export let setHoverColumnHidden = action("setHoverColumnHidden")(
    function setHoverColumnHidden(viewState: SchedulesViewStateStore, isHidden: boolean) {
        viewState.isHoverColumnHidden = isHidden;
    }
);