
import { action } from "satcheljs";

import { LoadingState } from "../common/LoadingState";

import { SetUserPictureLoadingStateAction } from "./SetUserPictureLoadingStateAction";

type SetUserPictureLoadingStateActionSignature = (userId: string, loadingState: LoadingState) => SetUserPictureLoadingStateAction;

/**
 * Sets the user profile picture loading state.
 */
export const setUserPictureLoadingState = action<SetUserPictureLoadingStateAction, SetUserPictureLoadingStateActionSignature>(
    "SET_USER_PICTURE_LOADING_STATE",
    (userId: string, loadingState: LoadingState) => ({ loadingState, userId })
);
