import {
    AppSettingsClientModel,
    AppSettingsServiceModel,
    IMemberEntity,
    IRoleEntity,
    ITagEntity,
    ITeamInfoEntity,
    ITimeOffReasonEntity,
    MemberEntity,
    RoleEntity,
    TagEntity,
    TeamInfoEntity,
    ITeamSettingEntity,
    TimeOffReasonEntity,
    TeamSettingEntity
    } from "sh-models";

/**
 * Collection of main team details that are sent in a response when we fetch a team
 */
export default class TeamDetailsResponse {
    team: ITeamInfoEntity;
    members: IMemberEntity[];
    adminRoleId: string;
    roles: IRoleEntity[];
    tags: ITagEntity[];
    teamSettings: ITeamSettingEntity[];
    timeOffReasons: ITimeOffReasonEntity[];
    appSettings: AppSettingsClientModel;

    constructor(
        team: ITeamInfoEntity,
        members: IMemberEntity[],
        adminRoleId: string,
        roles: IRoleEntity[],
        tags: ITagEntity[],
        teamSettings: ITeamSettingEntity[],
        timeOffReasons: ITimeOffReasonEntity[],
        appSettings: AppSettingsClientModel) {
        this.team = team;
        this.members = members;
        this.adminRoleId = adminRoleId;
        this.roles = roles;
        this.tags = tags;
        this.teamSettings = teamSettings;
        this.timeOffReasons = timeOffReasons;
        this.appSettings = appSettings;
    }

    /**
     * Function that converts json from service response to TeamDetailsEntity
     * @param jsonData - response from service
     */
    static fromJson(jsonData: any): TeamDetailsResponse {
        if (!jsonData) {
            return null;
        }

        // convert team data
        const team = TeamInfoEntity.fromJson(jsonData.team);

        // convert Roles data
        let adminRoleId = "";
        const roles: IRoleEntity[] = jsonData.roles && jsonData.roles.length ? jsonData.roles.map((roleDataJson: any) => {
            // Convert to client model object
            let role = RoleEntity.fromJson(roleDataJson);
            if (role.isAdmin) {
                adminRoleId = role.id;
            }
            return role;
        }) : [];

        // convert member data
        const members: IMemberEntity[] = jsonData.members && jsonData.members.length ? jsonData.members.map((memberDataJson: any) => {
            // Convert to client model object
            return MemberEntity.fromJson(memberDataJson);
        }) : [];

        // convert tag data
        let tags: ITagEntity[] = jsonData.tags && jsonData.tags.length ? jsonData.tags.map((tagJson: any) => {
            return TagEntity.fromJson(tagJson);
        }) : [];

        // set TeamSettings
        let teamSettings: ITeamSettingEntity[] = jsonData.teamSettings && jsonData.teamSettings.length ? jsonData.teamSettings.map((teamSettingsItemJson: any) => {
            // Parse team info from service response JSON
            return TeamSettingEntity.fromJson(teamSettingsItemJson);
        }) : [];

        // set TimeOff Reasons
        let timeOffReasons: ITimeOffReasonEntity[] = jsonData.timeOffReasons && jsonData.timeOffReasons.length ? jsonData.timeOffReasons.map((timeOffReasonJson: any) => {
            return TimeOffReasonEntity.fromJson(timeOffReasonJson);
        }) : [];

        // set appSettings for team
        const appSettingsServiceModel = AppSettingsServiceModel.fromJson(jsonData.appSettings);
        const appSettings = AppSettingsServiceModel.toClientModel(appSettingsServiceModel);

        return new TeamDetailsResponse(
            team,
            members,
            adminRoleId,
            roles,
            tags,
            teamSettings,
            timeOffReasons,
            appSettings);
    }
}