import setTimeOffReasonsInStore from "../mutatorActions/setTimeOffReasonsInStore";
import setTimeOffReasons from "../actions/setTimeOffReasons";
import timeOffReasonsChanged from "../actions/timeOffReasonsChanged";
import { orchestrator } from "satcheljs";

export default orchestrator(setTimeOffReasons, async actionMessage => {
    const timeOffReasons = actionMessage.timeOffReasons;
    setTimeOffReasonsInStore(timeOffReasons);

    // Alert the view that new time off reasons are available!
    timeOffReasonsChanged();
});