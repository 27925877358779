import { createStore } from "satcheljs";
import { ConnectorAuthDialogStoreSchema } from "./schema/ConnectorAuthDialogStoreSchema";

// Register Mutators
import "../mutators/setIsConnectorAuthDialogOpen";

export const connectorAuthDialogStore = createStore<ConnectorAuthDialogStoreSchema>("connectorAuthDialogStore", {
    isOpen: false,
    triesCount: 0
});
