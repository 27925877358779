import { action  } from "satcheljs";
import { IMemberEntity } from "sh-models";

const reorderTeamMember = action(
    "REORDER_TEAM_MEMBER",
    (
        currentlyRenderedMembers: IMemberEntity[], sourceIndex: number, targetIndex: number
    ) => ({
        currentlyRenderedMembers: currentlyRenderedMembers, sourceIndex: sourceIndex, targetIndex: targetIndex
    })
);

export default reorderTeamMember;