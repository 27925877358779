import RestClient from "sh-rest-client";
import { UniqueShiftStore } from "../";
import { action } from "satcheljs/lib/legacy";
import { IUniqueShiftEntity } from "sh-models";
import { trace } from "owa-trace";
import { InstrumentationService } from "sh-services";

export let deleteUniqueShift = action("deleteUniqueShift")(
    async function deleteUniqueShift(tenantId: string, teamId: string, uniqueShift: IUniqueShiftEntity) {
        let marker = "deleteUniqueShift";
        marker = InstrumentationService.perfMarkerStart(marker);

        try {
            // update unique shifts list optomistically. Don't handle failure.  The unique shift will return when they refresh the page and they can try to delete again
            UniqueShiftStore().uniqueShifts.remove(uniqueShift);
            await RestClient.deleteUniqueShift(tenantId, teamId, uniqueShift);
            return uniqueShift;
        } catch (httpError) {
                trace.warn("deleteUniqueShiftFailed: " + httpError.message);
                throw httpError;
        } finally {
            InstrumentationService.perfMarkerEnd(marker);
        }
    });