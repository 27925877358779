import * as moment from "moment";
import { AppDb } from "sh-services/data/AppDb";
import { ECSConfigKey, ECSConfigService, InstrumentationService } from "sh-services";
import {
    IItemsInTimeRangeSyncState,
    INoteDbEntity,
    INoteEntity,
    NoteEntity
    } from "sh-models";
import { INoteDatabase } from "./INoteDatabase";
import { Moment } from "moment";

/**
 * Notes Database Accessor
 */
export class NoteDatabase implements INoteDatabase {
    private db: AppDb;

    constructor(db: AppDb) {
        if (NoteDatabase.IsShiftsIndexedDbEnabled()) {
            this.db = db;
        }
    }

    /**
     * Fetch notes in a team
     */
    public async getNotes(teamId: string, fetchStartTime: Moment, fetchEndTime: Moment): Promise<INoteEntity[]> {
        if (!this.db) {
            return null;
        }
        let notes: INoteEntity[] = null;
        const fetchStartTimeStamp: number = fetchStartTime.valueOf();
        const fetchEndTimeStamp: number = fetchEndTime.valueOf();

        try {
            const notesFromDb: INoteDbEntity[] = await this.db.notes
                .where("[startTime+endTime+teamId]")
                // Use the key to do a more performant exclusion of items that don't overlap with the current fetch range.
                // With compound indexes, we can't really do complex logic filters as the filter() handler below, but we still
                // use the indexes here to partially weed out items that should excluded.
                .below([fetchEndTimeStamp, fetchEndTimeStamp, teamId]) // Only include items where (item.startTime < fetchEndTimeStamp)
                // Filter out items that do not overlap with the current fetch range
                .filter((note: INoteDbEntity) => { return note.startTime < fetchEndTimeStamp && note.endTime > fetchStartTimeStamp && note.teamId === teamId; })
                .toArray();
            notes = notesFromDb.map((note: INoteDbEntity) => NoteEntity.fromJson(note));
        } catch (error) {
            InstrumentationService.trackException(error, "getNotesFromDb");
        }
        return notes;
    }

    /**
     * Set Notes in Database
     * @param teams teams list
     */
    public async setNotes(notes: INoteEntity[]): Promise<void> {
        if (!this.db || !notes || !notes.length) {
            return;
        }

        try {
            await this.db.notes.bulkPut(notes.map( (note: INoteEntity) => NoteEntity.toDbModel(note)));
        } catch (error) {
            InstrumentationService.trackException(error, "setNotesInDb");
        }
    }

    /**
     * Delete notes in a team
     * @param teamId ID of the team
     * @param noteIds IDs of notes to delete
     */
    public async deleteNotes(teamId: string, noteIds: string[]): Promise<void> {
        if (!this.db || !noteIds || !noteIds.length) {
            return;
        }

        try {
            await this.db.transaction("rw", this.db.notes, async () => {
                for (let i = 0; i < noteIds.length; i++) {
                    // delete notes
                    await this.db.notes.where({teamId: teamId, id: noteIds[i]}).delete();
                }
            });
        } catch (error) {
            InstrumentationService.trackException(error, "deleteNotesInDb");
        }
    }

    /**
     * Atomically set Notes (and the time range that's in sync for the session) in the Database
     * @param teamId Team ID
     * @param sessionId Current Session ID
     * @param updatedNotes List of updated notes
     * @param deletedNoteIds List of IDs of notes that were hard deleted
     * @param sessionNotesCacheStartTimestamp Start of time range that's cached in current session
     * @param sessionNotesCacheEndTimestamp End of timestamp that's cached in current session
     */
    public async setNotesInTimeRange(teamId: string, sessionId: string, updatedNotes: INoteEntity[], deletedNoteIds: string[], sessionNotesCacheStartTimestamp: number, sessionNotesCacheEndTimestamp: number): Promise<void> {
        if (!this.db) {
            return;
        }

        try {
            await this.db.transaction("rw", [this.db.notes, this.db.notesSyncState], async () => {
                await this.setNotes(updatedNotes);
                await this.deleteNotes(teamId, deletedNoteIds);
                await this.setNotesCacheTimeRange(teamId, sessionId, sessionNotesCacheStartTimestamp, sessionNotesCacheEndTimestamp);
            });
        } catch (error) {
            InstrumentationService.trackException(error, "setNotesInTimeRangeInDb");
        }
    }

    /**
     * Set Notes cache timerange in Database
     * @param teamId Team ID
     * @param sessionId Session ID
     * @param syncStateStartTimestamp Start time of the contiguous timerange that's in sync
     * @param syncStateEndTimestamp End time of the contiguous timerange that's in sync
     */
    public async setNotesCacheTimeRange(teamId: string, sessionId: string, syncStateStartTimestamp: number, syncStateEndTimestamp: number): Promise<void> {
        if (!this.db) {
            return;
        }

        const notesSyncState: IItemsInTimeRangeSyncState = {
            sessionId: sessionId,
            teamId: teamId,
            syncStateStartTimestamp: syncStateStartTimestamp,
            syncStateEndTimestamp: syncStateEndTimestamp,
            lastUpdateTimestamp: moment().valueOf()
        };

        try {
            await this.db.notesSyncState.put(notesSyncState);
        } catch (error) {
            InstrumentationService.trackException(error, "setNotesCacheTimeRangeInDb");
        }
    }

    /**
     * Get the notes sync state for a team in the current session
     * @param teamId Team ID
     * @param sessionId  Session ID
     */
    public async getNotesSyncState(teamId: string, sessionId: string): Promise<IItemsInTimeRangeSyncState> {
        if (!this.db) {
            return null;
        }

        try {
            return await this.db.notesSyncState
                .where({sessionId: sessionId, teamId: teamId}).first();
        } catch (error) {
            InstrumentationService.trackException(error, "getNotesSyncStateFromDb");
        }
        return null;
    }

    /**
     * Delete the notes sync state for the current session
     * @param sessionId  Session ID
     */
    public async deleteNotesSyncState(sessionId: string): Promise<void> {
        if (!this.db) {
            return null;
        }

        try {
            await this.db.notesSyncState.where({sessionId: sessionId}).delete();
        } catch (error) {
            InstrumentationService.trackException(error, "deleteNotesSyncStateInDb");
        }
    }

    /**
     * Returns true if storing & retrieving of shifts/notes/openshifts from IndexedDb is enabled
     */
    public static IsShiftsIndexedDbEnabled() {
        return ECSConfigService.isECSFeatureEnabled(ECSConfigKey.EnableShiftsIndexedDb);
    }

    /**
     * Delete the sessions
     * @param sessionIds List of sessionIds to delete
     */
    public async deleteSessionData(sessionIds: string[]) {
        if (!this.db || !sessionIds || !sessionIds.length) {
            return;
        }
        try {
            for (const sessionId of sessionIds) {
                await this.db.notesSyncState.where({sessionId: sessionId}).delete();
            }
        } catch (error) {
            InstrumentationService.trackException(error, "deleteNotesSessionDataFromDb");
        }
    }
}