
type NoteActiveStateType = "Active";
type NoteDeletedStateType = "Deleted";
export type NoteStatesType = NoteActiveStateType | NoteDeletedStateType;

const NoteStates = {
    Active: "Active" as NoteActiveStateType,
    Deleted: "Deleted" as NoteDeletedStateType
};

export default NoteStates;
