import { ActionFunction, LegacyDispatchFunction } from "satcheljs/lib/legacy";

const maxActionsToKeep = 5;
let actionQueue: string[] = [];

/**
 * Keeps a queue of the last 'maxActionsToKeep' satchel actions that have been run. This
 * is useful for adding to logs that are displayed in error dialogs for dev builds.
 */
export function legacyAddToActionQueue(
    next: LegacyDispatchFunction,
    action: ActionFunction,
    actionType: string,
    args: IArguments,
    options: any) {

    while (actionQueue.length >= maxActionsToKeep) {
        actionQueue = actionQueue.slice(1);
    }

    actionQueue.push(actionType);
    return next(action, actionType, args, options);
}

export function getActionQueue() {
    return actionQueue;
}