import { action } from "satcheljs";
import { IShiftEntity } from "sh-models";
import { Moment } from 'moment';

const setupConflicts = action(
    "SETUP_CONFLICTS",
    (
        shiftsAdded: IShiftEntity[],
        shiftsDeleted: IShiftEntity[],
        shiftsUpdated: IShiftEntity[],
        fetchStartTime: Moment,
        fetchEndTime: Moment,
        forceCalculate?: boolean,
        isProgressiveRenderingMode?: boolean,
        userSettingsEnabled?: boolean
    ) => ({
        shiftsAdded: shiftsAdded || [],
        shiftsDeleted: shiftsDeleted || [],
        shiftsUpdated: shiftsUpdated || [],
        fetchStartTime: fetchStartTime,
        fetchEndTime: fetchEndTime,
        forceCalculate: forceCalculate,
        isProgressiveRenderingMode: isProgressiveRenderingMode,
        userSettingsEnabled: userSettingsEnabled
    })
);

export default setupConflicts;