import { ConflictStoreSchema } from "./schema/ConflictStoreSchema";
import { createStore } from "satcheljs";
import { IBaseConflictEntity } from "sh-models";
import { ObservableMap,  } from "mobx";

/**
 * Store for conflicts of both overlapping shift conflicts and shift-availability conflicts
 */
let ConflictStore = createStore<ConflictStoreSchema>(
    "conflictStore",
    {
        // map of all conflicts using the shift id as the key
        shiftIdToConflictsMap: new ObservableMap<string, Array<IBaseConflictEntity>>(),
        // true count of total number of conflicts
        conflictCount: {
            overlappingShiftConflictCount: 0,
            totalConflictCount: 0,
            shiftAvailabilityConflictCount: 0,
            shiftTimeoffConflictCount: 0
        },
        // Tracks if we ignored shifts/timeoffs for being too long
        didIgnoreConflictsOnLongShifts: false,
        // Tracks if the number of conflicts found exceeded the limit
        didConflictCountExceedLimit: false,
        // startTime of the shifts that the conflict store is tracking
        startTime: null,
        // endTime of the shifts that the conflict store is tracking
        endTime: null,
        // populate only current members conflicts, so there is no need to compute conflict count again in my shifts view
        myConflictCount: {
            overlappingShiftConflictCount: 0,
            totalConflictCount: 0,
            shiftAvailabilityConflictCount: 0,
            shiftTimeoffConflictCount: 0
        }
    });

export default ConflictStore;