import { action } from "satcheljs";
import { ITeamInfoEntity } from "sh-models";

const teamsUpdated = action(
    "teamsUpdated",
    (
        teams: ITeamInfoEntity[],
        resetExistingTeams: boolean = false
    ) => ({
        teams: teams,
        resetExistingTeams: resetExistingTeams
    })
);

export default teamsUpdated;