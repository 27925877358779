import { clearFilters } from "../actions/filter/clearFilters";
import schedulesViewStateStore from "../store/store";
import setIsProgressiveRendering from "sh-application/components/schedules/lib/actions/setIsProgressiveRendering";
import updateIsDataInDateRangeLoaded from "../mutators/updateIsDataInDateRangeLoaded";
import {
    clearScheduleDataInView,
    setConflictCountInView,
    setConflictsInView,
    setDatesWithNewEdits,
    setIsConflictCalculationInProgress,
    setIsViewStatePopulated,
    setNewlyEditedEntities,
    setNotesInView,
    setOpenShiftsInView,
    setShiftsInView
    } from "../index";
import { IBaseConflictEntity } from "sh-models";
import { ObservableMap } from "mobx";
import { orchestrator } from "satcheljs";

/**
 * Clear schedule data in view. Currently this consists of shifts, open shifts, notes and conflicts.
 */

 export const clearScheduleDataInViewOrchestrator = orchestrator(clearScheduleDataInView, actionMessage => {
        setIsProgressiveRendering(false, false /* didComplete */);
        setShiftsInView([]);
        clearFilters(true /* shouldClearLabel */);
        setOpenShiftsInView([]);
        setNotesInView([]);
        setNewlyEditedEntities([], false /* isNewlyEdited */);
        setDatesWithNewEdits([], false /* hasNewEdit */);
        updateIsDataInDateRangeLoaded(schedulesViewStateStore(), false);
        if (actionMessage.clearIsViewStatePopulated) {
            setIsViewStatePopulated(schedulesViewStateStore(), false);
        }
        setIsConflictCalculationInProgress(false);
        setConflictCountInView(0, 0);
        setConflictsInView(new ObservableMap<string, IBaseConflictEntity[]>());
    }
);