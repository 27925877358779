import { CommandBar, IButtonProps, IComponentAs } from "@fluentui/react";
import { observer } from "mobx-react";
import * as React from "react";
import StringsStore from "sh-strings/store";

import { useCommandBarExtraProps } from "./FilterableCommandBar.mappers";
import { DisplayOption, default as FilterableCommandBarItem } from "./FilterableCommandBarItem";

const classNames = require("classnames/bind");
const styles = require("./FilterableCommandBar.scss");

type IDisplayKey = "rightItems" | "leftItems" | "overflowItems";

type IDisplayOption = Record<IDisplayKey, FilterableCommandBarItem[]>;

export interface FilterableCommandBarProps {
    /** ClassNames in the filterable command bar */
    className?: string;

    /** Items to be displayed as buttons in the filterable command bar */
    filterableItems?: FilterableCommandBarItem[];

    /** Style override filterable command bar */
    style?: React.CSSProperties;

    /** Aria label for filterable commandbar */
    ariaLabel?: string;

    /** Custom Button title override for rendering buttons in the filterable command bar */
    customButton?: IComponentAs<IButtonProps>;

    /**
     * The callback when menu items did render.
     * This is required for measuring TTI because 'CommandBar' does not render menu items directly.
     */
    onDidRender?: () => void;
}

const DISPLAY_OPTION: IDisplayOption = { leftItems: [], overflowItems: [], rightItems: [] };

const getDisplayOptionType = (item: FilterableCommandBarItem): IDisplayKey => {
    if (item.shouldShow(DisplayOption.Overflow)) {
        return "overflowItems";
    }

    return item.shouldShow(DisplayOption.Left) ? "leftItems" : "rightItems";
};

const filterItemByDisplayOption = (acc: IDisplayOption, curr: FilterableCommandBarItem) => {
    const itemType = getDisplayOptionType(curr);
    return {
        ...acc,
        [itemType]: [...acc[itemType], curr]
    };
};

function filterItemsByDisplayOption(items: FilterableCommandBarItem[]): IDisplayOption {
    if (!Array.isArray(items) || items.length === 0) {
        return DISPLAY_OPTION;
    }

    return items.reduce(filterItemByDisplayOption, DISPLAY_OPTION);
}

/**
 * A command bar that accepts an array of FilterableCommandBarItems. The command bar will filter each item
 * according to its DisplayOption location and render each item in the correct location in the command bar.
 */

const FilterableCommandBar = observer((props: FilterableCommandBarProps) => {
    const { filterableItems, customButton } = props;
    const commandBarProps = useCommandBarExtraProps(props);

    const strings: Map<string, string> = React.useMemo(
        () => StringsStore().registeredStringModules.get("schedulePage").strings,
        []
    );

    const { leftItems, overflowItems, rightItems } = React.useMemo(
        () => filterItemsByDisplayOption(filterableItems),
        [filterableItems]
    );

    /**
     * In order to use the StringsStore in a functional component we must use The <Observer /> component
     * oddly enough the <Observer /> component requires a function and not a ReactNode as a children.
     * TODO: We must update the library to be able to use the regular @observer function
     */
    return (
        <div
            style={props.style}
            className={classNames(
                props.className,
                styles.commandBarContainer,
                "filterableCommandBarContainer"
            )}
        >
            <CommandBar
                className={styles.commandBar}
                ariaLabel={props.ariaLabel || strings.get("filterableCommandBarAriaLabel")}
                items={leftItems}
                farItems={rightItems}
                overflowItems={overflowItems}
                buttonAs={customButton}
                overflowButtonProps={{
                    ariaLabel: strings.get("moreOptionsLabel")
                }}
                {...commandBarProps}
            />
        </div>
    );
});

export default FilterableCommandBar;
