import { EmployeeViewType } from "sh-models";
import { mutatorAction } from "satcheljs";
import { SchedulesViewStateStore } from "sh-application/components/schedules/lib/store/schema/SchedulesViewStateStore";

/**
 * Set the employee view type of the Schedules page.
 */
export default mutatorAction("SET_EMPLOYEE_VIEW",
    function setEmployeeView(viewState: SchedulesViewStateStore, employeeView: EmployeeViewType) {
        viewState.employeeViewType = employeeView;
    }
);