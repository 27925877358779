import { AppSettingsStoreSchema } from "./schema/AppSettingsStoreSchema";
import { createStore } from "satcheljs";

/**
 * Store for app settings
 */
let AppSettingsStore = createStore<AppSettingsStoreSchema>(
    "appSettingsStore",
    {
        teamSettings: null,
        tenantSettings: null
    });

export default AppSettingsStore;