import createImportId from "./utils/createImportId";
import createQuickPromise from "./utils/createQuickPromise";
import Getter from "./types/Getter";
import isImportLoaded from "./selectors/isImportLoaded";
import LazyModule from "./LazyModule";
import markImportAsLoaded from "./mutators/markImportAsLoaded";

export default class LazyImport<TImport, TModule> {
    private id: string;
    private importPromise: Promise<TImport>;
    private importValue: TImport;

    constructor(private lazyModule: LazyModule<TModule>, private getter: Getter<TImport, TModule>) {
        // Each LazyImport has a unique ID
        this.id = createImportId();
    }

    import() {
        if (!this.importPromise) {
            this.importPromise = this.lazyModule
                .import()
                .then(module => {
                    // Get the value out of the module
                    this.importValue = this.getter(module);

                    // Once the import is loaded, we return a synchronous promise for faster access
                    this.importPromise = createQuickPromise(this.importValue);

                    // Update the loaded imports map in the store
                    markImportAsLoaded(this.id);
                    return this.importValue;
                })
                .catch(error => {
                    this.importPromise = null;
                    throw error;
                });
        }

        return this.importPromise;
    }

    tryImportSync() {
        // Check if we already have the value.  It's important that we check
        // the loaded state via the store -- that way, if this method is called
        // during render(), the component will get rerendered once the import
        // is loaded.
        if (isImportLoaded(this.id)) {
            return this.importValue;
        }

        // Kick off the import so that it will be available eventually
        this.import();
        return undefined;
    }
}
