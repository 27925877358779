import LogMessage, { LogLevel } from "./LogMessage";
import LogMessageQueue from "./LogMessageQueue";

export class LoggingService {

    private readonly _maxMessageSize: number;
    private readonly _maxQueueLength: number;
    private _logMessagesQueue: LogMessageQueue;

    private _errorCount = 0;
    private _filterLogLevel = LogLevel.None;

    public constructor(logSize?: number, messageSize?: number) {
        this._maxQueueLength = (logSize && logSize > 0) ? logSize : 3000;
        this._maxMessageSize = (messageSize && messageSize > 0) ? messageSize : 512;

        this._logMessagesQueue = new LogMessageQueue(this._maxQueueLength);
    }

    /**
     * Clears the log messages
     */
    public clear() {
        this._logMessagesQueue.empty();
        this._errorCount = 0;
    }

    /**
     * Returns the log messages in the queue
     */
    public getLogMessages(): LogMessage[] {
        this._logMessagesQueue.flush();
        return this._logMessagesQueue.getLogMessages();
    }

    /**
     * Returns the error messages count
     */
    public getErrorCount(): number {
        return this._errorCount;
    }

    /**
     * Returns the log level filter
     */
    public getLogLevelFilter(): LogLevel {
        return this._filterLogLevel;
    }

    /**
     * Sets the log level filter
     * @param logLevel log level filter
     */
    public setLogLevelFilter(logLevel: LogLevel) {
        if (this._filterLogLevel !== logLevel) {
            this._filterLogLevel = logLevel;
        }
    }

    /**
     * Pushes the given message with level onto the log messages
     * @param level log level for the message
     * @param message log message
     */
    public addLog(level: LogLevel, message: string): void {
        if (level === LogLevel.Error) {
            this._errorCount += 1;
        }

        // Do not maintain log messages below the filter level
        if (level < this._filterLogLevel) {
            return;
        }

        // truncate long messages, to not inflate the stored events size
        if (message.length > this._maxMessageSize) {
            message = message.substring(0, this._maxMessageSize).concat("...");
        }

        this._logMessagesQueue.enqueLogMessage(new LogMessage(message, level, new Date()));
    }

    /**
     * Gets the log file as string
     * @param logHeader (optional) appends the text as the header for the log
     */
    public getLogFileString(logHeader: string = ""): string {
        const logMessages: LogMessage[] = this.getLogMessages();
        const mainLog = this.getSortedLogString(logMessages);

        return logHeader + "\n\n" + mainLog;
    }

    /**
     * Sort all logs ordered by time stamp and return as string
     * @param logs log messages
     */
    private getSortedLogString(logs: LogMessage[]): string {
        let result = "";
        const sortedLog: LogMessage[] = logs.sort(this.logMessageSortComparator);

        sortedLog.forEach((logMessage: LogMessage) => {
            const dateString: string = (logMessage.timeStamp) ? logMessage.timeStamp.toISOString() : "";
            result += ("{0} {1}\t{2}\r\n").format(dateString, logMessage.levelName.substring(0, 3), logMessage.message);
        });

        return result;
    }

    /**
     * Comparator for sorting log messages
     * @param firstMessage first log message for comparison
     * @param secondMessage second log message for comparison
     * @returns {number} comparison result
     */
    private logMessageSortComparator(firstMessage: LogMessage, secondMessage: LogMessage): number {
        if (!firstMessage.timeStamp) {
            return 1;
        } else if (!secondMessage.timeStamp) {
            return -1;
        } else {
            return firstMessage.timeStamp >= secondMessage.timeStamp ? 1 : -1;
        }
    }
}