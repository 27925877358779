import { appViewState } from "sh-application/store";
import { DeepLinkState } from "../store/schema/DeepLinkState";
import { mutatorAction } from "satcheljs";

export default mutatorAction('SET_DEEP_LINK_STATE',
    function setDeepLinkState(deepLinkState: DeepLinkState) {
        appViewState().deepLinkState.isDeepLinkMode = deepLinkState && deepLinkState.isDeepLinkMode;
        appViewState().deepLinkState.deepLinkSource = deepLinkState && deepLinkState.deepLinkSource;
        appViewState().deepLinkState.isActivityFeedDeepLinkMode = deepLinkState && deepLinkState.isActivityFeedDeepLinkMode;
    }
);
