import { BaseConflictEntity, IAvailabilityConflictEntity } from "sh-models";
import { ConflictType } from "..";

export default class AvailabilityConflictEntity extends BaseConflictEntity implements IAvailabilityConflictEntity {
    // conflicting availability entity id for shift-availability conflict
    availabilityId: string;

    constructor(
        memberId: string,
        shiftId: string,
        conflictType: ConflictType,
        isDismissed: boolean,
        availabilityId: string
    ) {
        super(memberId,
                shiftId,
                conflictType,
                isDismissed);
        this.availabilityId = availabilityId;
    }
}