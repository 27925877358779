import { InstrumentationEventPropertyInterface } from "sh-instrumentation";
import { trace } from "owa-trace";

/**
 * Get a nicely formatted string for an array of instrumentation event objects
 * @param {Array} eventDataArray - Array of eventPropertyObjects (each object has key, value, piiKind)
 * @returns {String}
 */
export const instrumentationEventPropertyArrayString = (
    eventDataArray: Array<InstrumentationEventPropertyInterface>
) => {
    const stringsArray = eventDataArray.map((eventData) => {
        return `${eventData?.key} = ${eventData?.value}`;
    });
    return JSON.stringify(stringsArray);
};

/**
 * Get a monotonic (if supported) timestamp value in milliseconds
 */
export const getCurrentTimeStamp = (): number => {
    return window.performance?.now ? Math.round(window.performance.now()) : Date.now();
};

/**
 * Helper to get the adjusted marker name for a marker start
 */
export const getMarkerStart = (marker: string): string => `${marker}_START`;

/**
 * Helper to get the adjusted marker name for a marker end
 */
export const getMarkerEnd = (marker: string): string => `${marker}_END`;

/**
 * Returns a copy of the marker with a timestamp appended after a _ character
 * @param marker
 */
export const getTimeStampedMarker = (marker: string): string => {
    return `${marker}_${getCurrentTimeStamp()}`;
};

/**
 * Returns a copy of the marker with anything after the first _ character stripped out
 * @param marker
 */
export const cleanMeasureMarker = (marker: string): string => {
    let cleanedMarker = marker;
    let tokens = marker.split("_");
    if (tokens && tokens.length) {
        cleanedMarker = tokens[0];
    }
    return cleanedMarker;
};

/**
 * Mark the error as handled so that the global error handler considers this error taken care of an does not
 * display a warning dialog
 * @param e
 */
export const markErrorHandled = (e: any): void => {
    e.isHandled = true;
};

/**
 *  Wrapper around the User Timings getEntriesByName() API
 * @param name
 */
export const getMarksOrMeasures = (name: string): PerformanceEntry[] => {
    try {
        if (window.performance && window.performance.getEntriesByName) {
            const entries: PerformanceEntry[] = window.performance.getEntriesByName(name);
            return entries;
        }
    } catch (e) {
        markErrorHandled(e);
        trace.error(`Error: getMarkOrMeasure failed with error ${e}`);
    }
};

/**
 * Wrapper around the User Timings clearMarks() API
 * @param markToClear
 */
export const clearMarks = (markToClear: string): void => {
    try {
        if (window.performance && window.performance.clearMarks) {
            window.performance.clearMarks(markToClear);
        }
    } catch (e) {
        markErrorHandled(e);
        trace.error(`Error: clearMarks failed with error ${e}`);
    }
};

/**
 * Wrapper around the User Timings mark() API
 * @param marker
 */
export const mark = (marker: string): void => {
    try {
        if (window.performance && window.performance.mark) {
            const existingMarks = getMarksOrMeasures(marker);
            if (existingMarks && existingMarks.length) {
                trace.warn(`Warning: mark ${marker} already exists and will not be added`);
            } else {
                window.performance.mark(marker);
            }
        }
    } catch (e) {
        markErrorHandled(e);
        trace.error(`Error: mark failed with error ${e}`);
    }
};

/**
 * Wrapper around the User Timings clearMeasures() API
 * @param measureToClear
 */
export const clearMeasures = (measureToClear: string): void => {
    try {
        if (window.performance && window.performance.clearMeasures) {
            window.performance.clearMeasures(measureToClear);
        }
    } catch (e) {
        markErrorHandled(e);
        trace.error(`Error: clearMeasures failed with error ${e}`);
    }
};

/**
 * Wrapper around the User Timings measure() API
 * @param measureName
 * @param firstMarker
 * @param secondMarker
 */
export const measure = (
    measureName: string,
    firstMarker: string,
    secondMarker: string,
    outputPerfDataToConsole: boolean
): void | string => {
    try {
        if (window.performance && window.performance.measure) {
            if (
                !getMarksOrMeasures(firstMarker).length ||
                !getMarksOrMeasures(secondMarker).length
            ) {
                trace.warn(
                    `Warning: either mark ${firstMarker} or mark ${secondMarker} do not exist. No measurement will be made.`
                );
            } else {
                window.performance.measure(measureName, firstMarker, secondMarker);
                if (outputPerfDataToConsole) {
                    const items = window.performance.getEntriesByName(measureName);
                    // we only look at the first item
                    if (items.length) {
                        const item = items[0];
                        console.log(
                            `[PERFORMANCE] ${measureName} - duration: ${item.duration.toFixed(
                                2
                            )} - startTime: ${item.startTime.toFixed(4)}`
                        );
                        return item.duration.toFixed(2);
                    }
                }
            }
        }
    } catch (e) {
        markErrorHandled(e);
        trace.error(`Error: measure failed with error ${e}`);
    }
};

/**
 * Wrapper around the User Timings getEntriesByName() API to retrieve the duration for a measure
 * @param measure
 */
export const getMeasureDuration = (measure: string): number => {
    try {
        if (window.performance && window.performance.getEntriesByName) {
            const entries: PerformanceEntry[] = getMarksOrMeasures(measure);
            if (!entries || entries.length < 1) {
                trace.warn(`Warning: No measures found for ${measure}`);
                return -1;
            } else if (entries && entries.length > 1) {
                trace.warn(`Warning: Multiple measures found for ${measure}`);
                return -1;
            } else {
                return entries[0].duration;
            }
        }
    } catch (e) {
        markErrorHandled(e);
        trace.error(`Error: getMeasureDuration failed with error ${e}`);
    }
};
