import { ConflictStore } from "sh-stores/sh-conflict-store";
import { orchestrator } from "satcheljs";
import { setConflictCountInView, setConflictsInView, refreshConflictsInView } from "..";

/**
 * when conflict stores are updated, conflict count and conflict icon needs to be refreshed in view
 */
export default orchestrator(refreshConflictsInView, actionMessage => {
    // update schedule viewstore conflicts in view
    const conflicts = ConflictStore().shiftIdToConflictsMap;
    setConflictCountInView(ConflictStore().conflictCount.totalConflictCount, ConflictStore().myConflictCount.totalConflictCount);
    setConflictsInView(conflicts);
});