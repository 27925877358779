import { IUserPolicySettingsEntity } from './IUserPolicySettingsEntity';

export default class UserPolicySettingsEntity implements IUserPolicySettingsEntity {
    scheduleOwnerPermissionEnabled: boolean;
    userId: string;

    constructor(userId: string, scheduleOwnerEnabled: boolean) {
        this.userId = userId;
        this.scheduleOwnerPermissionEnabled = scheduleOwnerEnabled;
    }

    public static fromJson(jsonData: IUserPolicySettingsEntity): IUserPolicySettingsEntity {
        if (!jsonData) {
            return null;
        }

        return new UserPolicySettingsEntity(
            jsonData.userId,
            jsonData.scheduleOwnerPermissionEnabled
        );
    }

    public static toJson(userPolicySettings: IUserPolicySettingsEntity): IUserPolicySettingsEntity {
        return { ...userPolicySettings };
    }
}