import { action } from "satcheljs/lib/legacy";
import { TeamDataService } from "sh-services";
import { UserFREType, UserSettingsEntity } from "sh-models";

/**
 * Adds a list of new FRE type to the fresShownSoFar in user settings
 */

export let setFREsShownSoFar = action("setFREsShownSoFar")(
    async function setFREsShownSoFar(freTypes: UserFREType[]) {
        const userSettings: UserSettingsEntity = await TeamDataService.getUserSettings();
        if (!freTypes || !freTypes.length) {
            return null;
        }
        for (let i = 0; i < freTypes.length; i++) {
            const freType = freTypes[i];
            if (!userSettings.fresShownSoFar) {
                userSettings.fresShownSoFar = [];
            }
            if (userSettings.fresShownSoFar.indexOf(freType) === -1) {
                userSettings.fresShownSoFar.push(freType);
            }
        }
        return await TeamDataService.saveUserSettings(userSettings);
    });
