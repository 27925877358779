import { action } from "satcheljs/lib/legacy";
import { CurrentlySharedSchedulePanelViewState } from "../schema/CurrentlySharedSchedulePanelViewState";

/**
 * setCurrentlySharedSchedulePanelOpen action - launches the current shared schedule panel
 * @param isOpen
 */
export let setCurrentlySharedSchedulePanelOpen = action("setCurrentlySharedSchedulePanelOpen")(
    function setCurrentlySharedSchedulePanelOpen(viewState: CurrentlySharedSchedulePanelViewState , isOpen: boolean) {
        viewState.panelOpen = isOpen;
    }
);