import { Checkbox, Icon, PersonaSize } from "@fluentui/react";
import { AriaProperties, AriaRoles, generateDomPropertiesForAria } from "owa-accessibility";
import * as React from "react";
import AutomationUtil from "sh-application/utility/AutomationUtil";
import ConflictUtils from "sh-application/utility/ConflictUtils";
import DateTimeFormatter from "sh-application/utility/DateTimeFormatter";
import MemberUtils from "sh-application/utility/MemberUtils";
import ShiftRequestUtils from "sh-application/utility/ShiftRequestUtils";
import {
    IBaseConflictEntity,
    IMemberEntity,
    IShiftRequestEntity,
    ShiftRequestStates,
    ShiftRequestTypes
} from "sh-models";
import { conflictRequestStore } from "sh-requestconflict-store";
import { MemberDataService } from "sh-services";
import StringsStore from "sh-strings/store";

import { UserPersona } from "../../../common/UserPersona";
import ShiftRequestStateIndicator from "../../ShiftRequestStateIndicator";

const styles = require("./OpenShiftRequestDetails.scss");
const classNames = require("classnames/bind");
const conflictStyles = require("../../conflict/RequestConflict.scss");

export interface OpenShiftRequestDetailsProps {
    shiftRequest: IShiftRequestEntity;
    managerMember?: IMemberEntity;
    className?: string;
    isSelected?: boolean;
    isSelectionEnabled?: boolean;
    onRequestClickCallback?: (openShiftRequestId: string) => void; // Click callback will be called for both select and unselect
    isCalloutMode?: boolean;
}

/**
 * Component to show the openshift request details and handle its selection
 */
export default class OpenShiftRequestDetails extends React.Component<OpenShiftRequestDetailsProps, {}> {
    private _strings: Map<string, string>;
    private _conflictStrings: Map<string, string>;

    constructor(props: OpenShiftRequestDetailsProps) {
        super(props);
        this._strings = StringsStore().registeredStringModules.get("shiftRequests").strings;
        this._conflictStrings = StringsStore().registeredStringModules.get("conflictManagement").strings;
    }

    /**
     * Callback for selecting the request in the list
     */
    private onRequestSelectCallback = (evt: React.MouseEvent<HTMLElement>) => {
        const { shiftRequest, isSelectionEnabled } = this.props;

        if (isSelectionEnabled && this.props.onRequestClickCallback && shiftRequest) {
            this.props.onRequestClickCallback(shiftRequest.id);
            evt.preventDefault();
        }
    }

    /**
     * Helper method to render the image head for a member
     * @param member
     */
    private renderPersonaImage(member: IMemberEntity) {
        if (!member) {
            return null;
        }

        return <UserPersona
            imageInitials={ MemberUtils.getInitials(member) }
            imageShouldFadeIn={ true }
            initialsColor={ MemberUtils.getCustomPersonaInitialColor(member) }
            size={ PersonaSize.size48 }
            userId={ member.userId }
            className={ styles.persona }
        />;
    }

    /**
     * Helper function to render the response status and the manager response if there is one
     * @param request
     * @param managerMember
     */
    private renderResponse(request: IShiftRequestEntity, managerMember: IMemberEntity): JSX.Element {
        const managerApproved: boolean = request.state === ShiftRequestStates.ManagerApproved;
        const managerDeclined: boolean = request.state === ShiftRequestStates.ManagerDeclined;

        const cancelledOrAutoDeclined: boolean = request.state === ShiftRequestStates.AutoDeclined || request.state === ShiftRequestStates.SenderDeclined;

        // AutoDeclined and SenderDeclined requests appear red, but do not include a message
        if (managerApproved || managerDeclined || cancelledOrAutoDeclined) {
            const responseStatus = ShiftRequestUtils.getOpenShiftRequestResponseStatusForDisplay(request, managerMember);
            const responseMessage = !cancelledOrAutoDeclined && ShiftRequestUtils.getResponseMessageForOpenShiftRequest(request);

            return (
                <div className={ styles.managerResponseRow }>
                    <div className={ styles.divider } />
                    <div className={ styles.responseRow }>
                        <ShiftRequestStateIndicator shiftRequestState={ request.state } status={ responseStatus } />
                    </div>
                    {
                        responseMessage &&
                            <div className={ styles.notes }>
                                <div className={ styles.noteHeader }>{ this._strings.get("note") }</div>
                                <div>{ responseMessage }</div>
                            </div>
                    }
                </div>
            );
        }
    }

    render() {
        const { shiftRequest, className, isSelectionEnabled, isSelected, managerMember, isCalloutMode } = this.props;

        if (!shiftRequest || shiftRequest.requestType !== ShiftRequestTypes.Open) {
            return null;
        }

        const senderMember: IMemberEntity = MemberDataService.getMemberByIdFromStore(shiftRequest.senderMemberId); // TODO: get from store
        const displayName: string = MemberUtils.getDisplayNameForMember(senderMember);
        const requestCreatedInstanceText: string = this._strings.get("requestCreatedInstanceText").format(DateTimeFormatter.getDisplayTimeInRelativeLongFormat(shiftRequest.creationTime));
        let ariaProps: AriaProperties = { role: isSelectionEnabled ? AriaRoles.listitem : AriaRoles.listitem };
        if (isSelectionEnabled) {
            ariaProps.checked = isSelected;
        }
        const conflictEntities = ConflictUtils.getRequestConflictEntities(shiftRequest.shiftId, conflictRequestStore().requestShiftIdToConflictsMap);

        let hasConflicts: boolean = false;
        if (conflictEntities) {
            conflictEntities.forEach((conflictEntity: IBaseConflictEntity) => {
                if (conflictEntity.memberId === shiftRequest.senderMemberId) {
                    hasConflicts = true;
                }
            });
        }
        const showShiftConflict = hasConflicts && conflictEntities && conflictEntities.length > 0;

        return(
            <div
                onClick={ this.onRequestSelectCallback }
                className={ classNames(styles.container, className, {[styles.selectableRequest]: isSelectionEnabled}) }
                { ...generateDomPropertiesForAria(ariaProps) }>
                <div className={ styles.requestDetailsRow }>
                    {isSelectionEnabled ? <Checkbox checked={isSelected} /> : null}
                    { this.renderPersonaImage(senderMember) }
                    <div className={ styles.requestDetails } >
                        <div className={ styles.name } title={ displayName }>
                            { displayName }
                        </div>
                        <div className={ styles.requestInfo }>
                            { requestCreatedInstanceText }
                        </div>
                    </div>
                </div>
                { isCalloutMode &&
                    <div className={ conflictStyles.requestConflictContainer }>
                        { showShiftConflict &&
                            <div className={ classNames(conflictStyles.requestConflictError, conflictStyles.calloutConflictDetails) } data-automation-id={ AutomationUtil.getAutomationId("conflictCallout", "QAIDRequestConflictMesssage") }>
                                <Icon className={ classNames(conflictStyles.conflictIcon, conflictStyles.red) } iconName={ "teams-conflict-icon" }></Icon>
                                { this._conflictStrings.get("requestShiftConflict") }
                            </div>
                        }
                    </div>
                }
                { this.renderResponse(shiftRequest, managerMember) }
            </div>
        );
    }
}