import schedulesViewStateStore from "../store/store";
import { mutatorAction } from "satcheljs";

/**
 * The mutatorAction to set conflict counts
 * @param conflictCount - number - the conflict count
 * @param myConflictCount - number - the conflict count for just your shifts
 */
export default mutatorAction('SET_CONFLICTS_COUNT_IN_VIEW', function setIsConflictsCountInView(
    conflictCount: number,
    myConflictCount: number
) {
    schedulesViewStateStore().conflictCountInView = conflictCount;
    schedulesViewStateStore().myConflictCountInView = myConflictCount;
});