import { getDateHasShiftsMapKeyFromShift, ShiftHintDatePickerStoreSchema } from "../store";
import { IBaseShiftEntity } from "sh-models";
import { mutatorAction } from "satcheljs";

/**
 * Update shiftsForMember in the shiftHintDatePickerStore.  Only add shifts that are not currently represented in the list.
 */
export default mutatorAction('UPDATE_SHIFTS_FOR_MEMBER_IN_STORE', function updateShiftsForMemberInStore(shiftHintDatePickerStore: ShiftHintDatePickerStoreSchema, shiftsForMemberForMonth: IBaseShiftEntity[]) {
    if (shiftsForMemberForMonth && shiftHintDatePickerStore && shiftHintDatePickerStore.dateHasShiftsMap) {
        // add unique shifts to list
        shiftsForMemberForMonth.forEach((shift: IBaseShiftEntity) => {
            shiftHintDatePickerStore.dateHasShiftsMap.set(getDateHasShiftsMapKeyFromShift(shift), true);
        });
    }
});
