import { mutatorAction } from "satcheljs";
import { appViewState } from "sh-application/store";

export default mutatorAction('RESET_ACTIVE_TOUR',
    function resetActiveTour() {
        appViewState().tourGuideViewState.activeTour = null;
        appViewState().tourGuideViewState.activeTourStopIndex = 0;
        appViewState().tourGuideViewState.dontShowChecked = false;
        appViewState().tourGuideViewState.showWelcome = false;
    }
);
