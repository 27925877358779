import IConflictDismissDataService from "./IConflictDismissDataService";
import RestClient from "sh-rest-client";
import { ConflictDismissalsDataProvider } from "../dataproviders/ConflictDismissalsDataProvider";
import { DataService } from "./DataService";
import { IConflictDismissEntity, IDismissEntity } from "sh-models";
import { Moment } from "moment";

class ConflictDismissDataService extends DataService implements IConflictDismissDataService {
    /**
     * Creates a dismiss entity in service
     * @param teamId string team id of the conflicts
     * @param entityOne IDismissEntity first entity(shift/timeoff/availability) that is related to the conflict
     * @param entityTwo IDismissEntity second entity(shift/timeoff/availability) that is related to the conflict
     */
    public async createDismissEntity(teamId: string, entityOne: IDismissEntity, entityTwo: IDismissEntity): Promise<IConflictDismissEntity> {
        if (!teamId || !entityOne || !entityTwo) {
            return null;
        }
        const response  = await RestClient.createDismissal(teamId, entityOne, entityTwo);
        return response;
    }

    /**
     * gets the dismissed conflicts in the time range from service
     * @param teamId string teamId of the conflict
     * @param startDateTime Moment the start time of the range
     * @param endDateTime Moment the end time of the range
     */
    public async getDismissedConflicts(teamId: string, startDate: Moment, endDate: Moment): Promise<IConflictDismissEntity[]> {
       const conflictDismissalDataProvider = new ConflictDismissalsDataProvider(this.teamDatabase, teamId, startDate, endDate);
       const dismissedConflicts = await this.getData(conflictDismissalDataProvider);
       return dismissedConflicts;
    }

    /**
     * reverses dismissal of conflict in service
     * @param teamId string teamId of the conflict
     * @param conflictDismissId string dismissal id of the conflict that was dismissed
     */
    public async undoDismissConflict(teamId: string, conflictDismissEntity: IConflictDismissEntity): Promise<IConflictDismissEntity> {
        if (!teamId || !conflictDismissEntity) {
            return null;
        }

        const response = await RestClient.undoDismissConflict(teamId, conflictDismissEntity);
        return response;
    }

    /**
     * Resets the sync state
     */
    public async resetSyncState() {
    }
}

const service: IConflictDismissDataService = new ConflictDismissDataService();
export default service;