import schedulesViewStateStore from "../store/store";
import setIsProgressiveRendering from "../actions/setIsProgressiveRendering";
import { InstrumentationService } from "sh-services";
import { mutator } from "satcheljs";

/**
 * Sets the boolean to indicate progressive rendering is in progress
 * @param inProgress - boolean indicating if progressive rendering is in progess
 */
export default mutator(setIsProgressiveRendering, ({ inProgress, didComplete }) => {
    // check if progressive render is already in progress
    if (inProgress && schedulesViewStateStore().isProgressiveRendering) {
        InstrumentationService.logDataProgressiveRenderEnded(false /* didComplete */);
        schedulesViewStateStore().progressiveRenderEndTimestamp = new Date().getTime();
    }

    if (inProgress) {
        // mark the time of the start
        InstrumentationService.setProgressiveRenderStarted();
        schedulesViewStateStore().progressiveRenderStartTimestamp = new Date().getTime();
        // clear the end time
        schedulesViewStateStore().progressiveRenderEndTimestamp = -1;
        // reset groups and members in view since we are starting over
        schedulesViewStateStore().groupsInView = [];
        schedulesViewStateStore().membersInView = [];
    } else if (schedulesViewStateStore().isProgressiveRendering) {
        // mark the end time of the progressive rendering
        InstrumentationService.logDataProgressiveRenderEnded(didComplete);
        schedulesViewStateStore().progressiveRenderEndTimestamp = new Date().getTime();
    }
    schedulesViewStateStore().isProgressiveRendering = inProgress;
});