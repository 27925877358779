import { ComponentClassNames, mergeStyleSets } from "@microsoft/shifts-ui-core";

/**
 * Gets the 'EmptyFlexItem' component CSS class names.
 * @returns The 'EmptyFlexItem' component CSS class names.
 */
export const getClassNames = (): ComponentClassNames<"emptyFlexItem"> => {

    return mergeStyleSets({
        emptyFlexItem : {
            position: "relative",
            zIndex: -1,
            visibility: "hidden"
        }
    });
};