import { action } from "satcheljs";

/**
 * Action to download import schedule error file.
 */
const getDownloadErrorFile = action(
    "getDownloadErrorFile",
    (fileUrl: string) => ({fileUrl: fileUrl})
);

export default getDownloadErrorFile;