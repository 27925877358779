import { action } from "satcheljs";
import {
    ChangeSource,
    IBaseShiftEntity,
    IMemberEntity,
    ScheduleCalendarType,
    ShiftMoveActionTypes
    } from "sh-models";
import { Moment } from "moment";
import { StaffHubHttpError } from "sh-application";

const triggerShiftMove = action(
    "triggerShiftMove",
    (
        sourceShift: IBaseShiftEntity,      // Source shift
        moveType: ShiftMoveActionTypes,     // Shift move action type
        slotStartTime: Moment,              // Target slot start time
        slotMember: IMemberEntity,          // Target slot member
        slotTagId: string,                  // Target slot group tag id
        targetShift: IBaseShiftEntity,      // Target shift (null if the target is an empty cell)
        changeSource: ChangeSource,         // Change source for change tracking
        scheduleCalendarType: ScheduleCalendarType, // Type of view drop occured in. Used to calculate offsets
        onServiceError: (error: StaffHubHttpError) => void  // Service error callback
    ) => ({
        sourceShift: sourceShift,
        moveType: moveType,
        slotStartTime: slotStartTime,
        slotMember: slotMember,
        slotTagId: slotTagId,
        targetShift: targetShift,
        changeSource: changeSource,
        scheduleCalendarType: scheduleCalendarType,
        onServiceError: onServiceError
    })
);

export default triggerShiftMove;
