import { TraceLevel, registerTracerListener, trace } from "owa-trace";
import * as React from "react";
import confirm from "sh-confirm-dialog/lib/actions/confirm";
import { getGenericEventPropertiesObject } from "sh-instrumentation";
import { InstrumentationService } from "sh-services";

import { getActionQueue } from "../middleware/addToActionQueue";

import { SecurityPolicyViolationManager } from "./SecurityPolicyViolationManager";

/**
 * Registers a global error handler and trace listener to listen to all
 * errors.
 * @param shouldNotifyUserOfError Whether user should be notified
 * of the error. Most likely, only set to true for non-production builds.
 */
export default function initializeErrorHandler(shouldNotifyUserOfError?: boolean): void {
    window.addEventListener("error", (event: ErrorEvent) => {
        event.preventDefault();

        const { error } = event;
        const message = error.message ?? "Unknown error";

        // Called for every unhandled exception. If the exception happens within a promise
        // it will be picked up by "unhandledrejection" listener which rethrows to this handler.
        InstrumentationService.trackException(error, InstrumentationService.events.UnknownError, "initializeErrorHandler:error", [
            getGenericEventPropertiesObject(InstrumentationService.properties.Error, message)
        ]);

        // call all error trace listeners (will most likely log the error to the console)
        trace.error(error.message, error);
    });

    window.addEventListener("unhandledrejection", (event: PromiseRejectionEvent) => {
        // NOTE: e.preventDefault() must be manually called to prevent the default
        // action which is currently to log the stack trace to console.warn
        event.preventDefault();

        // the spec says e.reason and e.promise should exist, however I have seen different browsers or browsers with various extensions installed
        // store the reason in different places.
        // TODO: Consolidate the different error types into standard Error class at the sources (e.g., service client).
        const error = event.reason ?? getEventDetail(event)?.reason;
        const message = error?.message ?? "Unknown error";

        InstrumentationService.trackException(error, InstrumentationService.events.UnknownError, "initializeErrorHandler:unhandledrejection", [
            getGenericEventPropertiesObject(InstrumentationService.properties.Error, message)
        ]);

        if (!error?.isHandled) {
            trace.warn("unhandled rejection occurred in promise: " + message);
        }
    });

    // TODO: Remove usage of 'any' type.
    const getEventDetail = (event: any): any => event.detail;

    window.addEventListener("rejectionhandled", function (e: any) {
        // NOTE: e.preventDefault() must be manually called prevent the default
        // action which is currently unset (but might be set to something in the future)
        e.preventDefault();
        trace.info("rejected promise was handled at a later time");
    });

    // The securitypolicyviolation listener logs Content-Security-Policy (CSP) violations.
    window.addEventListener("securitypolicyviolation", (event: SecurityPolicyViolationEvent) => {
        const manager = new SecurityPolicyViolationManager();
        const error = manager.createErrorFromEvent(event);
        const exceptionCategory = InstrumentationService.events.SecurityPolicyViolationError;
        const handledAt = "initializeErrorHandler:securitypolicyviolation";

        InstrumentationService.trackException(error, exceptionCategory, handledAt, [
            getGenericEventPropertiesObject(InstrumentationService.properties.Error, error.message)
        ]);
    });

    if (shouldNotifyUserOfError) {
        registerTracerListener((message, traceLevel, nativeError) => {
            if (traceLevel == TraceLevel.Error) {
                if (nativeError && nativeError.error) {
                    nativeError = nativeError.error;
                }

                // don't show the error popup if the error has been handled
                if (nativeError && nativeError.isHandled) {
                    return;
                }

                // Add the fields for this specific error
                let errorFields = [message];
                if (nativeError && nativeError.stack) {
                    errorFields.push(nativeError.stack);
                }
                errorFields.push(getActionQueue().join(","));

                // format them for the dialog
                let dialogHtml = errorFields.map(function (item: string, index: number) {
                    return <pre key={index}>{item}</pre>;
                });

                confirm("Error", null, false, {
                    bodyElement: <div>{dialogHtml}</div>,
                    okText: "OK",
                    hideCancelButton: true,
                    containerClassName: "devErrorDialog"
                }).then(() => {
                    // TODO - maybe something with error
                    // for now we don't need to do anything because the error should have
                    // been logged to sentry
                });
            }
        });
    }
}
