import { ITeamMemberPermissionsEntity } from "..";
import { VISIBILITY_DEFAULT_PERMISSIONS } from "../../StaffHubConstants";

/**
 * Entity class from TeamPermissions.
 */
export class TeamMemberPermissionsEntity implements ITeamMemberPermissionsEntity {
    canSeeOtherTeamMemberScheduleData: boolean;
    canSeeTimeOffDetails: boolean;
    canSeeShiftDetails: boolean;
    canSeeUnlimitedPastScheduleData: boolean;
    pastScheduleDataLimitInDays: number;

    /**
     * Constructor with parameters.
     * @param canSeeOtherTeamMemberScheduleData canSeeOtherTeamMemberScheduleData parameter.
     * @param canSeeTimeOffDetails canSeeTimeOffDetails parameter.
     * @param canSeeShiftDetails canSeeShiftDetails parameter.
     * @param canSeeUnlimitedPastScheduleData canSeeUnlimitedPastScheduleData parameter.
     * @param pastScheduleDataLimitInDays pastScheduleDataLimitInDays parameter.
     */
    constructor(
        canSeeOtherTeamMemberScheduleData: boolean = VISIBILITY_DEFAULT_PERMISSIONS.canSeeOtherTeamMemberScheduleData,
        canSeeTimeOffDetails: boolean = VISIBILITY_DEFAULT_PERMISSIONS.canSeeTimeOffDetails,
        canSeeShiftDetails: boolean = VISIBILITY_DEFAULT_PERMISSIONS.canSeeShiftDetails,
        canSeeUnlimitedPastScheduleData: boolean = VISIBILITY_DEFAULT_PERMISSIONS.canSeeUnlimitedPastScheduleData,
        pastScheduleDataLimitInDays: number = VISIBILITY_DEFAULT_PERMISSIONS.pastScheduleDataLimitInDays
    ) {
        this.canSeeOtherTeamMemberScheduleData = canSeeOtherTeamMemberScheduleData;
        this.canSeeTimeOffDetails = canSeeTimeOffDetails;
        this.canSeeShiftDetails = canSeeShiftDetails;
        this.canSeeUnlimitedPastScheduleData = canSeeUnlimitedPastScheduleData;
        this.pastScheduleDataLimitInDays = pastScheduleDataLimitInDays;
    }

    /**
     * FromJson method for TeamMemberPermissionEntity.
     * @param jsonData ITeamMemberPermissionsEntity.
     * @returns ITeamMemberPermissionsEntity.
     */
    public static fromJson(jsonData: ITeamMemberPermissionsEntity): ITeamMemberPermissionsEntity {
        if (!jsonData) {
            return null;
        } else {
            return TeamMemberPermissionsEntity.clone(jsonData);
        }
    }

    /**
     * Clone the team permission entity.
     * @param ITeamMemberPermissionsEntity ITeamMemberPermissionsEntity.
     * @returns ITeamMemberPermissionsEntity.
     */
    public static clone(permissions: ITeamMemberPermissionsEntity): ITeamMemberPermissionsEntity {
        return new TeamMemberPermissionsEntity(
            permissions.canSeeOtherTeamMemberScheduleData,
            permissions.canSeeTimeOffDetails,
            permissions.canSeeShiftDetails,
            permissions.canSeeUnlimitedPastScheduleData,
            permissions.pastScheduleDataLimitInDays
        );
    }
}
