import { createLazyComponent, LazyModule } from "owa-bundling";

const lazyModule = new LazyModule(() =>
    import(/* webpackChunkName: "ShiftRequestsPanel" */ "./lazyIndex")
);

export { ShiftRequestPanelSelectedItemType, default as ShiftRequestPanelStoreSchema } from "./store/schema/ShiftRequestPanelStoreSchema";

export const ShiftRequestsPanel = createLazyComponent(lazyModule, m => m.ShiftRequestsPanel);

// Store
export { default as shiftRequestsPanelStore } from "./store/store";

/* export actions and mutator actions */
export { actionShiftRequest } from "./actions/actionShiftRequest";
export { default as approveDeclineTimeOffRequest } from "./actions/approveDeclineTimeOffRequest";
export { default as approveSwapHandoffRequest } from "./actions/approveSwapHandoffRequest";
export { default as createSwapHandoffRequest } from "./actions/createSwapHandoffRequest";
export { default as createTimeOffRequest } from "./actions/createTimeOffRequest";
export { default as declineSwapHandoffRequest } from "./actions/declineSwapHandoffRequest";
export { default as deleteShiftRequest } from "./actions/deleteShiftRequest";
export { default as getShiftRequests } from "./actions/getShiftRequests";
export { default as resetRequestsPanelStore } from "./mutatorActions/resetRequestsPanelStore";
export { default as setErrorMessageFromException } from "./actions/setErrorMessageFromException";
export { default as setErrorMessageInStore } from "./mutatorActions/setErrorMessageInStore";
export { default as setIsSavingInStore } from "./mutatorActions/setIsSavingInStore";
export { default as setSelectedItemType } from "./mutatorActions/setSelectedItemType";
export { default as setSelectedRequest } from "./actions/setSelectedRequest";
export { default as setSelectedRequestIdInStore } from "./mutatorActions/setSelectedRequestIdInStore";
export { default as setShiftRequestRead } from "./actions/setShiftRequestRead";
export { default as setShouldScrollToSelected } from "./mutatorActions/setShouldScrollToSelected";
export { default as setShowCreateRequestDialog } from "./actions/setShowCreateRequestDialog";
export { default as setShowCreateRequestDialogInStore } from "./mutatorActions/setShowCreateRequestDialogInStore";
