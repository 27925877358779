import CurrentTeamDataProvider from "sh-services/dataproviders/CurrentTeamDataProvider";
import RestClient from "sh-rest-client";
import { IMemberEntity } from "sh-models";
import { ITeamDatabase } from "sh-services/data/ITeamDatabase";
import { MobxUtils } from "sh-application";
import { setMembers, TeamStore } from "sh-team-store";

/**
 * Members List Data Provider for the current team
 */
export class MembersDataProvider extends CurrentTeamDataProvider<IMemberEntity[]> {

    protected teamDatabase: ITeamDatabase;
    protected tenantId: string;

    constructor(teamDatabase: ITeamDatabase, tenantId: string, teamId: string) {
        super(teamId);
        this.teamDatabase = teamDatabase;
        this.tenantId = tenantId;
    }

    /**
     * Return data if it's found in memory (otherwise return undefined)
     */
    async getDataFromMemoryForCurrentTeam() {
        return (TeamStore().team && TeamStore().team.id == this.teamId && TeamStore().members && TeamStore().members.size ? MobxUtils.MapToArray(TeamStore().members) : undefined);
    }

    /**
     * Return data if it's found in the database (otherwise return undefined)
     */
    async getDataFromDatabase() {
        return await this.teamDatabase.getMembers(this.teamId);
    }

    /**
     * Make an HTTP request to fetch the data from the network
     */
    async getDataFromNetwork() {
        return await RestClient.getMembers(this.tenantId, this.teamId);
    }

    /**
     * Set data in memory
     */
    async setDataInMemoryForCurrentTeam(data: IMemberEntity[]) {
        setMembers(data);
    }

    /**
     * Set data in the database
     */
    async setDataInDatabase(data: IMemberEntity[]) {
        if (data) {
            return await this.teamDatabase.setMembers(data);
        }
    }

    /**
     * Make a network call to update the data
     */
    async saveDataToNetwork(data: IMemberEntity[]): Promise<IMemberEntity[]> {
        return await RestClient.updateTeamMembers(this.tenantId, this.teamId, data);
    }
}