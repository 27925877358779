import { mutator } from "satcheljs";

import { setUserPictureUrl } from "../actions/setUserPictureUrl";
import { UsersPicturesStore } from "../store/UsersPicturesStore";

/**
 * Sets user profile picture URL with succeed loading state.
 */
export const setUserPictureUrlMutator = mutator(setUserPictureUrl, (action) => {
    const { pictureUrl, userId } = action;

    UsersPicturesStore().pictureUrls.set(userId, pictureUrl);
});
