import { mutatorAction } from "satcheljs";
import { ScheduleOverViewType } from "sh-models";
import { SchedulesViewStateStore } from "sh-application/components/schedules/lib/store/schema/SchedulesViewStateStore";

/**
 * Sets the schedule overview type of the Schedules page. (People/Shifts view)
 */
export default mutatorAction("SET_SCHEDULE_OVERVIEW_TYPE",
    function setScheduleOverViewType(viewState: SchedulesViewStateStore, newScheduleOverViewType: ScheduleOverViewType) {
        viewState.scheduleOverViewType = newScheduleOverViewType;
    }
);