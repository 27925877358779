import ShiftrObjectModelBase from "../ShiftrObjectModelBase";
import {
    BreakEntity,
    IBaseShiftEntity,
    IBreakEntity,
    ISubshiftEntity,
    ShiftState,
    ShiftType,
    SubshiftEntity
    } from "sh-models";
import { Moment } from "moment";

/**
 * Base Entity for Shifts and OpenShifts
 */
export default class BaseShiftEntity extends ShiftrObjectModelBase implements IBaseShiftEntity {
    // id
    // etag
    title?: string;
    memberId?: string;
    startTime: Moment;
    endTime: Moment;
    notes?: string;
    theme?: string;
    shiftType: ShiftType;
    breaks?: IBreakEntity[];
    subshifts?: ISubshiftEntity[];
    timeOffReasonId?: string;
    tagIds?: string[];
    isPublished?: boolean;
    teamId?: string;
    tenantId?: string;
    state: ShiftState;
    shiftRequestId?: string;

    constructor(
        id: string,
        eTag: string,
        tenantId: string,
        teamId: string,
        memberId: string,
        shiftType: ShiftType,
        startTime: Moment,
        endTime: Moment,
        state: ShiftState,
        title: string,
        notes: string,
        theme: string,
        tagIds: Array<string>,
        subshifts: Array<ISubshiftEntity>,
        breaks: Array<IBreakEntity>,
        shiftRequestId: string,
        timeOffReasonId: string,
        isPublished: boolean) {

        super(id, eTag);

        this.tenantId = tenantId;
        this.teamId = teamId;
        this.memberId = memberId;
        this.shiftType = shiftType;
        this.startTime = startTime;
        this.endTime = endTime;
        this.state = state;
        this.title = title;
        this.notes = notes;
        this.shiftRequestId = shiftRequestId;
        this.timeOffReasonId = timeOffReasonId;
        this.isPublished = isPublished;

        this.theme = theme;

         // Note: cowuertz - do not change the .slice() functionality here. If we pass in a mobx observable array as the tagIds
         // this slice call is necessary to convert the mobx version of the array to a regular array. If we don't do this, we can end up with
         // tagIds = ["id", "id", ObservableArray] where the observable array becomes a nested list.
         // ref: https://github.com/mobxjs/mobx/blob/gh-pages/docs/best/pitfalls.md#arrayisarrayobservable123--false
        this.tagIds = [].concat(tagIds ? tagIds.slice() : []);

        if (subshifts) {
            this.subshifts = subshifts.map(subshift => SubshiftEntity.clone(subshift));
        } else {
            this.subshifts = [];
        }
        if (breaks) {
            this.breaks = breaks.map((currentBreak: IBreakEntity) => BreakEntity.clone(currentBreak));
        } else {
            this.breaks = [];
        }
    }
}