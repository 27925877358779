import * as moment from "moment";
import { IAvailabilityEntity, IRecurrencePattern, IRecurrenceRange, ITimeSlot } from "./IAvailabilityEntity";

/**
 * Availability entity model.
 * Used to represent a member's available times to work for a given day
 */
export default class AvailabilityEntity implements IAvailabilityEntity {
    allDay: boolean;
    id: string;
    recurrencePattern: IRecurrencePattern;
    recurrenceRange: IRecurrenceRange;
    timeSlots: ITimeSlot[];
    timeZoneOlsonCode: string;

    constructor(
        allDay: boolean,
        id: string,
        timeSlots: ITimeSlot[],
        timeZoneOlsonCode: string,
        recurrencePattern?: IRecurrencePattern,
        recurrenceRange?: IRecurrenceRange
        ) {
        this.allDay = allDay;
        this.id = id;
        this.timeSlots = timeSlots;
        this.timeZoneOlsonCode = timeZoneOlsonCode;
        this.recurrencePattern = recurrencePattern;
        this.recurrenceRange = this.computeRecurrenceRangeUtcDates(recurrenceRange, timeZoneOlsonCode);
    }

    public static fromJson(jsonData: IAvailabilityEntity): AvailabilityEntity {
        // If neither recurrencePattern nor recurrenceRange are defined, return undefined as it is not a valid availability
        // TODO newAvailability: add telemetry for when neither recurrencePattern nor recurrenceRange are defined as it should not happen
        // TODO newAvailability: consider throwing an exception instead of returning undefined when neither recurrencePattern nor recurrenceRange are defined
        if (!jsonData || (!jsonData.recurrencePattern && !jsonData.recurrenceRange)) {
            return;
        }

        // TODO newAvailability: investigate why we need to clone these objects
        // TODO newAvailability: consider using lodash cloneDeep
        const clonedRecurrencePattern = jsonData.recurrencePattern ? JSON.parse(JSON.stringify(jsonData.recurrencePattern)) : undefined;
        const clonedRecurrenceRange = jsonData.recurrenceRange ? JSON.parse(JSON.stringify(jsonData.recurrenceRange)) : undefined;
        const clonedTimeSlots = JSON.parse(JSON.stringify(jsonData.timeSlots));
        return new AvailabilityEntity(
            jsonData.allDay,
            jsonData.id,
            clonedTimeSlots,
            jsonData.timeZoneOlsonCode,
            clonedRecurrencePattern,
            clonedRecurrenceRange);
    }

    /**
     * Function that clones availability object
     * @param availabilityEntity - availability entity that needs to be cloned
     */
    public static clone(availabilityEntity: IAvailabilityEntity): IAvailabilityEntity {
        return AvailabilityEntity.fromJson(availabilityEntity);
    }

    /**
     * Computes the UTC dates for the recurrence range.
     * @param recurrenceRange The recurrence range of the availability.
     * @param timeZone The availability time-zone to use.
     * @returns The updated recurrence range if applicable.
     */
    private computeRecurrenceRangeUtcDates(recurrenceRange: IRecurrenceRange, timeZone: string): IRecurrenceRange | undefined {
        if (!recurrenceRange) {
            return undefined;
        }

        const { startDate, endDate } = recurrenceRange;

        recurrenceRange.startDate = this.computeUtcDatetimeFromLocalDate(startDate, timeZone);
        recurrenceRange.endDate = this.computeUtcDatetimeFromLocalDate(endDate, timeZone);
        recurrenceRange.endDate = moment(recurrenceRange.endDate).add(1, "day").toISOString(); // Add 1 day to the end date to include the end date in the range.

        return recurrenceRange;
    }

    /**
     * Computes UTC date-time from local date and time-zone.
     * @param localDate The local date.
     * @param timeZone The local time-zone.
     * @returns UTC date-time from local date and time-zone.
     * @example computeUtcDatetimeFromLocalDate("2023-01-01", "America/New_York") => "2023-01-01T05:00:00.000Z"
     */
    private computeUtcDatetimeFromLocalDate(localDate: string, timeZone: string): string {
        const utcDate = moment.tz(localDate, timeZone);

        return utcDate.toISOString();
    }
}