import { action } from "satcheljs";
import { Moment } from "moment";

const loadStoreForConflictCalculations = action(
    "LOAD_STORE_FOR_CONFLICT_CALCULATIONS",
    (
        memberId: string,
        startTime: Moment,
        endTime: Moment
    ) => ({
        memberId: memberId,
        startTime: startTime,
        endTime: endTime
    })
);

export default loadStoreForConflictCalculations;