import * as moment from "moment";
import ModelUtils from "../ModelUtils";
import ShiftrObjectModelBase from "../ShiftrObjectModelBase";

const scheduleIdPrefix = "SCH_";

/**
 * Base class that sets up common aspects for the Schedule client and service models
 */
export default class ScheduleModelBase extends ShiftrObjectModelBase {

    tenantId: string;
    teamId: string;
    startTime: moment.Moment;
    endTime: moment.Moment;
    state: string;

    constructor(
        id: string,
        eTag: string,
        tenantId: string,
        teamId: string,
        startTime: moment.Moment,
        endTime: moment.Moment,
        state: string) {

        let modelId = id || ScheduleModelBase.generateNewScheduleId();
        super(modelId, eTag);

        this.tenantId = tenantId;
        this.teamId = teamId;
        this.startTime = startTime ? startTime.clone() : null;
        this.endTime = endTime ? endTime.clone() : null;
        this.state = state;
    }

    /**
     * Generate new shiftId used by StaffHub App
     * @returns {string} shiftId in form of SHFT_<uuid>
     */
    public static generateNewScheduleId(): string {
        return ModelUtils.generateUUIDWithPrefix(scheduleIdPrefix);
    }
}
