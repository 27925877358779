import { IBaseShiftEntity } from "sh-models";
import { mutatorAction } from "satcheljs";
import { ShiftEditorViewStateSchema } from "..";

export default mutatorAction("setTimeoffInStore", function setTimeoffInStore(
    store: ShiftEditorViewStateSchema,
    timeoff: IBaseShiftEntity
) {
    if (store) {
        store.timeoff = timeoff;
    }
});