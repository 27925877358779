import notesModified from "sh-stores/sh-note-store/actions/notesModified";
import NoteUtils from "sh-application/utility/NoteUtils";
import schedulesViewStateStore from "sh-application/components/schedules/lib/store/store";
import ShiftUtils from "sh-application/utility/ShiftUtils";
import updateNoteInStore from "sh-stores/sh-note-store/mutatorActions/updateNoteInStore";
import updateNotes from "sh-stores/sh-note-store/actions/updateNotes";
import { DataProcessingHelpers } from "sh-services";
import { INoteEntity, NoteEntity } from "sh-models";
import { Moment } from "moment";
import { NoteStore } from "sh-note-store";
import { orchestrator } from "satcheljs";
import { transaction } from "mobx";
import { updateNotesInView } from "sh-application/components/schedules/lib";

export default orchestrator(updateNotes, async actionMessage => {
    let notesAdded: Array<INoteEntity> = [];
    let notesUpdated: Array<INoteEntity> = [];
    let notesDeleted: Array<INoteEntity> = [];
    let notesMaybeAddToView: Array<INoteEntity> = [];

    const cacheStartTime: Moment = NoteStore().notesCacheStartTime;
    const cacheEndTime: Moment = NoteStore().notesCacheEndTime;

    // We use clones of the argument entities. This is because setting the entities in the satchel stores will mark them as observables
    // and any callers who still hold references to these entities will not be able to mutate them (in event of an error, for example) without
    // triggering mobx exceptions
    const notesToProcess: Array<INoteEntity> = actionMessage.notesUpdated.map(note => NoteEntity.clone(note));

    transaction(() => {
        notesToProcess.forEach((noteModel: INoteEntity) => {
            let setNote = true;
            const noteInStore: INoteEntity = NoteStore().notes.get(noteModel.id);
            if (!noteInStore) {
                // if this noteModel is not already in the cache, this is an add.
                // validate that it fits within our cache range
                if (!ShiftUtils.shiftOverlapsStartsOrEndsBetween(noteModel, cacheStartTime, cacheEndTime, false /*includeEdges*/)) {
                    // if note is not within cache range do not set note in cache
                    setNote = false;
                } else {
                    // keep track of all the notes added
                    notesAdded.push(noteModel);
                }
            } else {
                // Check if the note has been modified by the service (or if it's an optimistic update where note data has changed but etag hasn't)
                if (ShiftUtils.hasItemBeenModifiedByService(noteInStore, noteModel) || actionMessage.isOptimisticUpdate) {
                    // keep track of all the notes updated/deleted
                    if (NoteUtils.isDeletedNote(noteModel)) {
                        notesDeleted.push(noteModel);
                    } else {
                        notesUpdated.push(noteModel);
                    }
                } else {
                    // this is the case where the note is already in the memory cache, but it may not actually be in
                    // the "in view" cache. To handle these notes, we first check that they are not currently in the view cache
                    // and later we will add them if they are in the viewable range.
                    if (!schedulesViewStateStore().notesInView.has(noteModel.id)) {
                        notesMaybeAddToView.push(noteModel);
                    }
                }
            }

            // if the note is already in the cache, we always allow the update

            // set the note in the cache (this will either add a new entry or update an existing one)
            if (setNote) {
                updateNoteInStore(noteModel);
            }
        });
    });

    if (notesAdded.length || notesUpdated.length || notesDeleted.length) {
        // new stachel v3 action
        notesModified(notesAdded, notesDeleted, notesUpdated);
    }

    if (notesMaybeAddToView.length) {
        // we have notes that may need to be added to the in view memory cache. Check if they are in our current view
        // range and if so, add them to the in view cache.
        const notesInRange: INoteEntity[] = DataProcessingHelpers.getDisplayableNotesInScheduleRange(
            notesMaybeAddToView,
            schedulesViewStateStore().viewStartDate,
            schedulesViewStateStore().viewEndDate);
        if (notesInRange && notesInRange.length) {
            updateNotesInView(notesInRange);
        }
    }
});