import setAppSettingsForTenant from "../actions/setAppSettingsForTenant";
import { AppSettingsStore } from "sh-appsettings-store";
import { mutator } from "satcheljs";

/**
 * Update the Tenant AppSettings in the store
 */
export default mutator(setAppSettingsForTenant, ({ appSettingsClientModel }) => {
    AppSettingsStore().tenantSettings = appSettingsClientModel;
});
