import { action } from "satcheljs";
import { IShiftRequestEntity } from "sh-models";

const compareShiftsForConflicts = action(
    "COMPARE_SHIFTS_FOR_CONFLICTS",
    (
        shiftRequest: IShiftRequestEntity,
        memberId: string
    ) => ({
        shiftRequest: shiftRequest,
        memberId: memberId
    })
);

export default compareShiftsForConflicts;