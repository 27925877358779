import { ITeamSettingEntity, TeamSettingsKeys } from "sh-models";
import { mutatorAction } from "satcheljs";
import { TeamSettingsStore } from "..";

export default mutatorAction('SET_TEAM_SETTING_IN_STORE', function setTeamSettingInStore(teamSettingsItem: ITeamSettingEntity) {
    switch (teamSettingsItem.key) {
        case TeamSettingsKeys.ScheduleStartDate: {
                TeamSettingsStore().scheduleStartDate = teamSettingsItem;
                break;
            }
        case TeamSettingsKeys.ScheduleType: {
                TeamSettingsStore().scheduleType = teamSettingsItem;
                break;
            }
        case TeamSettingsKeys.ScheduleTypeCount: {
                TeamSettingsStore().scheduleLength = teamSettingsItem;
                break;
            }
        case TeamSettingsKeys.StartingDayOfWeek: {
                TeamSettingsStore().startingDayOfWeek = teamSettingsItem;
                break;
            }
        case TeamSettingsKeys.TimeZoneOlsonCode: {
                TeamSettingsStore().timeZoneOlsonCode = teamSettingsItem;
                break;
            }
        case TeamSettingsKeys.TimeClockEnabled: {
                TeamSettingsStore().timeClockEnabled = teamSettingsItem;
                break;
            }
    }
});
