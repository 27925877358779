import { orchestrator } from "satcheljs";
import { ShiftRequestDataService } from "sh-services";
import { shiftRequestStore } from "sh-stores/sh-shiftrequests-store";
import { TeamStore } from "sh-team-store";

import { setErrorMessageFromException, setSelectedRequest, setSelectedRequestIdInStore, setShouldScrollToSelected, shiftRequestsPanelStore } from "../";
import getShiftRequests from "../actions/getShiftRequests";

/**
 * This orchestrator will call the api to get shift requests
 */
export default orchestrator(getShiftRequests, async actionMessage => {
    const { cursor } = actionMessage;

    try {
        await ShiftRequestDataService.getShiftRequestsForTeam(TeamStore().tenantId, TeamStore().teamId, cursor);

        const { groupedOpenShiftRequests, shiftRequests } = shiftRequestStore();
        const { selectedRequestId } = shiftRequestsPanelStore();

        if (!selectedRequestId) {
            return;
        } else if (!shiftRequests.has(selectedRequestId)) {
            await ShiftRequestDataService.getShiftRequestById(TeamStore().tenantId, TeamStore().teamId, selectedRequestId);
        }

        if (shiftRequests.has(selectedRequestId)) {
            setSelectedRequest(shiftRequests.get(selectedRequestId));
            setShouldScrollToSelected(true);
        } else if (groupedOpenShiftRequests.has(selectedRequestId)) {
            setSelectedRequest(groupedOpenShiftRequests.get(selectedRequestId));
            setShouldScrollToSelected(true);
        } else {
            // TODO: Remove 'setSelectedRequest' and only rely on 'setSelectedRequestIdInStore' for setting selected request.
            setSelectedRequestIdInStore(null);
            setSelectedRequest(null);
        }
    } catch (error) {
        setErrorMessageFromException(error);
    }
});
