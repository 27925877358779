import UniqueShiftStore from "../store";
import { action } from "satcheljs/lib/legacy";
import { IUniqueShiftEntity } from "sh-models";

/**
 * Set unique shifts in UniqueShiftStore
 * @param {IUniqueShiftEntity[]} uniqueShiftModels - array of uniqueShift models that needs to be set on the store
 */
export let setUniqueShifts = action("setUniqueShifts")(
    function setUniqueShifts(uniqueShiftModels: Array<IUniqueShiftEntity>) {
        UniqueShiftStore().uniqueShifts.clear();
        UniqueShiftStore().hasFetchedUniqueShifts.set(false);
        uniqueShiftModels.forEach((uniqueShift: IUniqueShiftEntity) => {
            UniqueShiftStore().uniqueShifts.push(uniqueShift);
        });
        UniqueShiftStore().hasFetchedUniqueShifts.set(true);
    });