import * as moment from "moment";
import { action } from "satcheljs";
import { IAvailabilityEntity, IShiftEntity } from "sh-models";

const handleConflictManagement = action(
    "HANDLE_CONFLICT_MANAGEMENT",
    (
        shiftsAdded: IShiftEntity[],
        shiftsDeleted: IShiftEntity[],
        shiftsUpdated: IShiftEntity[],
        viewStartDatetime: moment.Moment,
        viewEndDatetime: moment.Moment,
        forceCalculate?: boolean,
        availabilities?: Map<string, IAvailabilityEntity[]>,
        calculateConflicts?: boolean,
        refreshConflictsCallback?: Function
    ) => ({
        shiftsAdded: shiftsAdded || [],
        shiftsDeleted: shiftsDeleted || [],
        shiftsUpdated: shiftsUpdated || [],
        viewStartDatetime: viewStartDatetime,
        viewEndDatetime: viewEndDatetime,
        forceCalculate: forceCalculate,
        availabilities: availabilities,
        calculateConflicts: calculateConflicts,
        refreshConflictsCallback: refreshConflictsCallback
    })
);

export default handleConflictManagement;