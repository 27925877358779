import { mutatorAction } from "satcheljs";
import { SchedulesViewStateStore } from "sh-application/components/schedules/lib/store/schema/SchedulesViewStateStore";

/**
 * Function that removes a tag from collapsed tags if it's present. Otherwise adds it.
 * @param viewState - Schedule view state
 * @param tagId - Id of the tag to flip the view
 * @param collapseTag - true if should collapse the tag
 */
export default mutatorAction("TOGGLE_TAG_VIEW",
    function toggleTagView(viewState: SchedulesViewStateStore, tagId: string, collapseTag: boolean) {
        if (collapseTag) {
            viewState.collapsedTags.push(tagId);
        } else {
            const tagIndex = viewState.collapsedTags.indexOf(tagId);
            viewState.collapsedTags.splice(tagIndex, 1);
        }
    }
);