import schedulesViewStateStore from "sh-application/components/schedules/lib/store/store";
import { orchestrator } from "satcheljs";
import { notesModified } from "sh-note-store";
import { updateNotes } from "../";

/**
 * When updates to notes are triggered via the UI or via sync notifications, this orchestrator will be triggered.
 * It is used to keep track of all the notes during progressive rendering.
 */
export default orchestrator(notesModified, async actionMessage => {
    if (schedulesViewStateStore().isProgressiveRendering) {
        if (actionMessage.notesAdded && actionMessage.notesAdded.length) {
            updateNotes(actionMessage.notesAdded);
        }

        if (actionMessage.notesUpdated && actionMessage.notesUpdated.length) {
            updateNotes(actionMessage.notesUpdated);
        }

        if (actionMessage.notesDeleted && actionMessage.notesDeleted.length) {
            updateNotes(actionMessage.notesDeleted);
        }
    }
});
