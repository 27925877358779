import TeamStore from "../store";
import { mutatorAction } from "satcheljs";

/**
 * Set the minimal team data in the store in order to unblock the application as soon as possible
 */
export default mutatorAction("INIT_BASIC_TEAM_DATA", (tenantId: string, groupId: string, teamId: string, name: string) => {
    TeamStore().tenantId = tenantId;
    TeamStore().groupId = groupId;
    TeamStore().teamId = teamId;
    TeamStore().name = name;
});