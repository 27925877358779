import ITenantDataService from "sh-services/dataservice/ITenantDataService";
import { AppSettingsClientModel } from "sh-models";
import { DataService } from "./DataService";
import { TenantAppSettingsDataProvider } from "sh-services/dataproviders/TenantAppSettingsDataProvider";

/**
 * Functions for retrieving and editing information related to Tenant settings.
 */
class TenantDataService extends DataService implements ITenantDataService {

    /**
     * Get Tenant level AppSettings (Flight Settings)
     */
    public async getAppSettingsForTenant(forceLoadFromNetwork: boolean = false): Promise<AppSettingsClientModel> {
        const tenantAppSettingsDataProvider = new TenantAppSettingsDataProvider(this.tenantDatabase, this.tenantId);
        return await this.getData(tenantAppSettingsDataProvider, forceLoadFromNetwork);
    }

    /**
     * Resets the sync state
     */
    public async resetSyncState() {
    }
}

const service: ITenantDataService = new TenantDataService();
export default service;