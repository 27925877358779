import * as React from "react";
import FilterableCommandBarItem, { DisplayOption } from "sh-application/components/commandbar/FilterableCommandBarItem";

const styles = require("./SpacerItem.scss");
const classNames = require("classnames/bind");

export default class SpacerItem {
    static spacerCount = 0;

    public static getComponent(showLine?: boolean, className?: string, center?: boolean) {
        return <div className={ classNames(className, { [styles.showLine]: showLine, [styles.center]: center }) } />;
    }

    public static getItem(displayRight: boolean = false, className?: string, showLine: boolean = false, center?: boolean) {
        const spacerName = `Spacer_${SpacerItem.spacerCount++}`;

        return FilterableCommandBarItem.create({
            key: spacerName,
            name: spacerName,
            iconOnly: false,  /* iconOnly */
            displayOption: displayRight ? DisplayOption.Right : null,
            onRender: () => this.getComponent(showLine, className, center)
        });
    }
}