import * as moment from "moment";
import { AppDb } from "sh-services/data/AppDb";
import {
    AppSettingsServiceModel,
    ConflictDismissEntity,
    IConflictDismissDbEntity,
    IConflictDismissEntity,
    IMemberEntity,
    IRoleEntity,
    ITagEntity,
    ITeamDetailsSyncState,
    ITeamInfoEntity,
    ITeamMemberPermissionsEntity,
    ITeamPermissionDbEntity,
    ITeamPermissionEntity,
    ITeamSettingEntity,
    ITimeClockEntity,
    ITimeOffReasonEntity,
    TeamDetailsResponse,
    TimeClockEntity
    } from "sh-models";
import { InstrumentationService } from "sh-services";
import { ITeamDatabase } from "./ITeamDatabase";
import { Moment } from "moment";

/**
 * Team Database Accessor
 */
export class TeamDatabase implements ITeamDatabase {
    private db: AppDb;

    constructor(db: AppDb) {
        this.db = db;
    }

    /**
     * Fetch teams from database
     */
    public async getTeams(): Promise<ITeamInfoEntity[]> {
        if (!this.db) {
            return null;
        }

        try {
            const teams = await this.db.teams.toArray();
            if (teams && teams.length > 0) {
                return teams;
            } else {
                // We assume that the teams database hasn't been initialized if there are no teams (this will force a network fetch)
                return undefined;
            }
        } catch (error) {
            InstrumentationService.trackException(error, "getTeamsFromDb");
        }
    }

    /**
     * Set Teams in Database
     * @param teams teams list
     */
    public async setTeams(teams: ITeamInfoEntity[]): Promise<void> {
        if (!this.db) {
            return;
        }

        try {
            await this.db.transaction("rw", this.db.teams, async () => {
                await this.db.teams.clear();
                await this.db.teams.bulkPut(teams);
            });
        } catch (error) {
            InstrumentationService.trackException(error, "setTeamsInDb");
        }
    }

    /**
     * Set Team in Database
     * @param team team
     */
    public async setTeam(team: ITeamInfoEntity): Promise<void> {
        if (!this.db) {
            return;
        }

        try {
            await this.db.teams.put(team);
        } catch (error) {
            InstrumentationService.trackException(error, "setTeamInDb");
        }
    }

    /**
     * Fetch team
     */
    public async getTeam(teamId: string): Promise<ITeamInfoEntity> {
        if (!this.db) {
            return null;
        }
        let team: ITeamInfoEntity = null;

        try {
            team = await this.db.teams.get(teamId);
        } catch (error) {
            InstrumentationService.trackException(error, "getTeamFromDb");
        }
        return team;
    }

    /**
     * Fetch team by groupId
     */
    public async getGroup(groupId: string): Promise<ITeamInfoEntity> {
        if (!this.db) {
            return null;
        }
        let team: ITeamInfoEntity = null;

        try {
            team = await this.db.teams.where({groupId: groupId}).first();
        } catch (error) {
            InstrumentationService.trackException(error, "getTeamFromDb");
        }
        return team;
    }

    /**
     * Delete team
     */
    public async deleteTeam(teamId: string): Promise<void> {
        if (!this.db) {
            return null;
        }

        try {
            await this.db.transaction("rw", [this.db.teams, this.db.roles, this.db.members, this.db.tags, this.db.teamSettings, this.db.timeOffReasons, this.db.appSettings], async () => {
                // delete team
                await this.db.teams.delete(teamId);
                // delete roles
                await this.db.roles.where({teamId: teamId}).delete();
                // delete members
                await this.db.members.where({teamId: teamId}).delete();
                // delete tags
                await this.db.tags.where({teamId: teamId}).delete();
                // delete teamSettings
                await this.db.teamSettings.where({teamId: teamId}).delete();
                // delete timeOffReasons
                await this.db.timeOffReasons.where({teamId: teamId}).delete();
                // delete appSettings
                await this.db.appSettings.where({teamId: teamId}).delete();
            });
        } catch (error) {
            InstrumentationService.trackException(error, "deleteTeamInDb");
        }
    }

    /**
     * Fetch team details
     */
    public async getTeamDetails(teamId: string): Promise<TeamDetailsResponse> {
        if (!this.db) {
            return null;
        }
        let teamDetails: TeamDetailsResponse = null;

        try {
            // Note: Make sure to include all tables that will be needed by the transaction or it will fail
            await this.db.transaction("r", [ this.db.teams, this.db.roles, this.db.members, this.db.tags, this.db.teamSettings, this.db.timeOffReasons, this.db.appSettings], async () => {
                const team: ITeamInfoEntity = await this.db.teams.get(teamId);
                const roles: IRoleEntity[] = await this.db.roles.where({teamId: teamId}).toArray();
                if (team && roles && roles.length) {
                    // assume the team data is in cache if a team and at least one role
                    const adminRoleId = roles.find(role => role.isAdmin).id;
                    const members: IMemberEntity[] = await this.db.members.where({teamId: teamId}).toArray();
                    const tags: ITagEntity[] = await this.db.tags.where({teamId: teamId}).toArray();
                    const teamSettings: ITeamSettingEntity[] = await this.db.teamSettings.where({teamId: teamId}).toArray();
                    const timeOffReasons: ITimeOffReasonEntity[] = await this.db.timeOffReasons.where({teamId: teamId}).toArray();
                    const appSettings: AppSettingsServiceModel = await this.db.appSettings.where({teamId: teamId}).first();
                    teamDetails = new TeamDetailsResponse(team, members, adminRoleId, roles, tags, teamSettings, timeOffReasons, AppSettingsServiceModel.toClientModel(appSettings));
                }
            });
        } catch (error) {
            InstrumentationService.trackException(error, "getTeamDetailsFromDb");
        }
        return teamDetails;
    }

    /**
     * Set Team Details in the Database
     * @param teamDetails Team Details
     */
    public async setTeamDetails(teamDetails: TeamDetailsResponse): Promise<void> {
        if (!this.db) {
            return;
        }

        try {
            await this.db.transaction("rw", [this.db.teams, this.db.roles, this.db.members, this.db.tags, this.db.teamSettings, this.db.timeOffReasons, this.db.appSettings], async () => {
                // Update team
                await this.db.teams.put(teamDetails.team);
                // Update roles (we can bulk put because we assume roles can't be hard deleted)
                await this.db.roles.bulkPut(teamDetails.roles);
                // Update members (we can bulk put because we assume members can't be hard deleted)
                await this.db.members.bulkPut(teamDetails.members);
                // Update tags (we can bulk put because we assume tags can't be hard deleted)
                await this.db.tags.bulkPut(teamDetails.tags);
                // Update teamSettings (we can bulk put because we assume teamSettings can't be hard deleted)
                await this.db.teamSettings.bulkPut(teamDetails.teamSettings);
                // Update timeOffReasons (we can bulk put because we assume timeOffReasons can't be hard deleted)
                await this.db.timeOffReasons.bulkPut(teamDetails.timeOffReasons);
                // Update appSettings (we can put because we assume appSettings can't be hard deleted)
                await this.db.appSettings.put(AppSettingsServiceModel.fromClientModel(teamDetails.appSettings));
            });
        } catch (error) {
            InstrumentationService.trackException(error, "setTeamDetailsInDb");
        }
    }

    /**
     * Fetch Members
     */
    public async getMembers(teamId: string): Promise<IMemberEntity[]> {
        if (!this.db) {
            return null;
        }
        let members: IMemberEntity[] = null;

        try {
            members = await this.db.members.where({teamId: teamId}).toArray();
        } catch (error) {
            InstrumentationService.trackException(error, "getMembersFromDb");
        }
        return members;
    }

    /**
     * Set Members in the Database
     * @param members Members
     */
    public async setMembers(members: IMemberEntity[]): Promise<void> {
        if (!this.db) {
            return;
        }

        try {
            // Update members (we can bulk put because we assume members can't be hard deleted)
            await this.db.members.bulkPut(members);
        } catch (error) {
            InstrumentationService.trackException(error, "setTeamMembersInDb");
        }
    }

    /**
     * Get a tag by ID
     */
    public async getTag(teamId: string, tagId: string): Promise<ITagEntity> {
        if (!this.db) {
            return null;
        }

        let tag: ITagEntity = null;

        try {
            tag = await this.db.tags.where({teamId: teamId, id: tagId}).first();
        } catch (error) {
            InstrumentationService.trackException(error, "getTagFromDb");
        }
        return tag;
    }

    /**
     * Fetch tags in a team
     */
    public async getTags(teamId: string): Promise<ITagEntity[]> {
        if (!this.db) {
            return null;
        }
        let tags: ITagEntity[] = null;

        try {
            tags = await this.db.tags.where({teamId: teamId}).toArray();
        } catch (error) {
            InstrumentationService.trackException(error, "getTagsFromDb");
        }
        return tags;
    }

    /**
    * Set tags in the Database
    * @param tags Tags
    */
    public async setTags(tags: ITagEntity[]): Promise<void> {
        if (!this.db) {
            return;
        }

        try {
            // Update tags (we can bulk put because we assume tags can't be hard deleted)
            await this.db.tags.bulkPut(tags);
        } catch (error) {
            InstrumentationService.trackException(error, "setTeamTagsInDb");
        }
    }

    /**
     * Get a team setting by ID
     */
    public async getTeamSetting(teamId: string, teamSettingId: string): Promise<ITeamSettingEntity> {
        if (!this.db) {
            return null;
        }

        let teamSetting: ITeamSettingEntity = null;

        try {
            teamSetting = await this.db.teamSettings.where({teamId: teamId, id: teamSettingId}).first();
        } catch (error) {
            InstrumentationService.trackException(error, "getTeamSettingFromDb");
        }
        return teamSetting;
    }

    /**
     * Set team settings in the Database
     * @param teamSettings Team Settings
     */
    public async setTeamSettings(teamSettings: ITeamSettingEntity[]): Promise<void> {
        if (!this.db) {
            return;
        }

        try {
            // Update team settings
            await this.db.teamSettings.bulkPut(teamSettings);
        } catch (error) {
            InstrumentationService.trackException(error, "setTeamSettingsInDb");
        }
    }

    /**
     * Get time off reasons in a team
     */
    public async getTimeOffReasons(teamId: string): Promise<ITimeOffReasonEntity[]> {
        if (!this.db) {
            return null;
        }

        let timeOffReasons: ITimeOffReasonEntity[] = null;

        try {
            timeOffReasons = await this.db.timeOffReasons.where({teamId: teamId}).toArray();
        } catch (error) {
            InstrumentationService.trackException(error, "getTimeOffReasonsFromDb");
        }
        return timeOffReasons;
    }

    /**
     * Set time off reasons in the Database
     * @param timeOffReasons Time off reasons
     */
    public async setTimeOffReasons(timeOffReasons: ITimeOffReasonEntity[]): Promise<void> {
        if (!this.db) {
            return;
        }

        try {
            // Update time off reasons
            await this.db.timeOffReasons.bulkPut(timeOffReasons);
        } catch (error) {
            InstrumentationService.trackException(error, "setTimeOffReasonsInDb");
        }
    }

    /**
     * Set if team details are in sync for the current session in Database
     * @param teamId Team ID
     * @param sessionId Session ID
     * @param areTeamDetailsInSync If team details are in sync for the current session
     */
    public async setAreTeamDetailsInSync(teamId: string, sessionId: string, areTeamDetailsInSync: boolean): Promise<void> {
        if (!this.db) {
            return;
        }

        await this.updateTeamDetailsSyncState(teamId, sessionId, (teamDetailsSyncState) => {
            if (teamDetailsSyncState && teamDetailsSyncState.areTeamDetailsInSync != areTeamDetailsInSync) {
                teamDetailsSyncState.areTeamDetailsInSync = areTeamDetailsInSync;
                return teamDetailsSyncState;
            } else {
                // Skip the update if there are no changes to be made
                return null;
            }
        });
    }

    /**
     * Get if team details are in sync for the current session
     * @param teamId Team ID
     * @param sessionId Session ID
     */
    public async getAreTeamDetailsInSync(teamId: string, sessionId: string): Promise<boolean> {
        if (!this.db) {
            return;
        }

        const teamDetailsSyncState = await this.getTeamDetailsSyncState(teamId, sessionId);
        return !!teamDetailsSyncState && teamDetailsSyncState.areTeamDetailsInSync;
    }

    /**
     * Modify team details sync state for the current session in Database
     * @param teamId Team ID
     * @param sessionId Session ID
     * @param modifyTeamDetailsSyncState Function to modify the team details sync state (skips the update if this function returns null/undefined)
     */
    private async updateTeamDetailsSyncState(teamId: string, sessionId: string, modifyTeamDetailsSyncState: (teamDetailsSyncState: ITeamDetailsSyncState) => ITeamDetailsSyncState ): Promise<void> {
        if (!this.db) {
            return;
        }

        try {
            await this.db.transaction("rw", this.db.teamDetailsSyncState, async () => {
                let teamDetailsSyncState: ITeamDetailsSyncState = await this.getTeamDetailsSyncState(teamId, sessionId);
                if (teamDetailsSyncState) {
                    let updatedTeamDetailsSyncState = modifyTeamDetailsSyncState(teamDetailsSyncState);
                    if (updatedTeamDetailsSyncState) {
                        await this.setTeamDetailsSyncState(teamId, sessionId, updatedTeamDetailsSyncState);
                    }
                }
            });
        } catch (error) {
            InstrumentationService.trackException(error, "setShiftsCacheTimeRangeInDb");
        }
    }

    /**
     * Set team details sync state for a team in the current session
     * @param teamId Team ID
     * @param sessionId Session ID
     * @param teamDetailsSyncState Team Details sync state
     */
    private async setTeamDetailsSyncState(teamId: string, sessionId: string, teamDetailsSyncState: ITeamDetailsSyncState): Promise<void> {
        if (!this.db) {
            return;
        }

        try {
            await this.db.teamDetailsSyncState.put(teamDetailsSyncState);
        } catch (error) {
            InstrumentationService.trackException(error, "setShiftsCacheTimeRangeInDb");
        }
    }

    /**
     * Get the team details sync state for a team in the current session
     * @param teamId Team ID
     * @param sessionId  Session ID
     */
    public async getTeamDetailsSyncState(teamId: string, sessionId: string): Promise<ITeamDetailsSyncState> {
        if (!this.db) {
            return null;
        }

        try {
            let teamDetailsSyncState: ITeamDetailsSyncState = await this.db.teamDetailsSyncState.where({sessionId: sessionId, teamId: teamId}).first();
            if (!teamDetailsSyncState) {
                // Initialize default team details sync state
                teamDetailsSyncState = {
                    sessionId: sessionId,
                    teamId: teamId,
                    areTeamDetailsInSync: false,
                    areUniqueShiftsInSync: false,
                    areUniqueSubShiftsInSync: false,
                    areMemberAvailabilitesInSync: false,
                    areShiftRequestsInSync: false,
                    lastUpdateTimestamp: moment().valueOf()
                };
            }
            return teamDetailsSyncState;
        } catch (error) {
            InstrumentationService.trackException(error, "getTeamDetailsSyncStateFromDb");
        }
        return null;
    }

    /**
     * Delete the sessions
     * @param sessionIds List of sessionIds to delete
     */
    public async deleteSessionData(sessionIds: string[]) {
        if (!this.db || !sessionIds || !sessionIds.length) {
            return;
        }
        try {
            for (const sessionId of sessionIds) {
                await this.db.teamDetailsSyncState.where({sessionId: sessionId}).delete();
            }
        } catch (error) {
            InstrumentationService.trackException(error, "deleteSessionDataFromDb");
        }
    }

    /**
     * Get timeclock entry by team ID
     * @param teamId
     */
    public async getLatestTimeClockEntry(teamId: string): Promise<ITimeClockEntity> {
        if (!this.db) {
            return null;
        }

        let timeClockEntry: ITimeClockEntity = null;

        try {
            const timeClockDbEntry = await this.db.latestTimeClockEntry.where({teamId: teamId}).first();
            timeClockEntry = TimeClockEntity.fromJson(timeClockDbEntry);
        } catch (error) {
            InstrumentationService.trackException(error, "getLatestTimeClockEntryFromDb");
        }
        return timeClockEntry;
    }

    /**
     * Set TimeClockEntry in Database
     * @param timeClockEntry
     */
    public async setLatestTimeClockEntry(timeClockEntry: ITimeClockEntity): Promise<void> {
        if (!this.db) {
            return;
        }

        try {
            await this.db.latestTimeClockEntry.put(TimeClockEntity.toDbModel(timeClockEntry));
        } catch (error) {
            InstrumentationService.trackException(error, "setLatestTimeClockEntryInDb");
        }
    }

    /**
     * Get team permissions by Team ID.
     * @param teamId Team ID.
     * @returns {ITeamPermissionEntity} Team permissions.
     */
    public async getTeamPermissions(teamId: string): Promise<ITeamPermissionEntity> {
        if (!this.db) {
            return null;
        }

        let teamPermissions: ITeamPermissionEntity = null;

        try {
            const record = await this.db.teamPermissions.get({teamId: teamId}) as Partial<ITeamPermissionDbEntity>;

            teamPermissions = record?.permissions;
        } catch (error) {
            InstrumentationService.trackException(error, "getTeamPermissionsFromDb");
        }
        return teamPermissions;
    }

    /**
     * Set team permissions in the Database for a given Team ID or Group ID.
     * @param teamId Team ID.
     * @param teamPermissions Team permissions.
     */
    public async setTeamPermissions(teamId: string, teamPermissions: ITeamPermissionEntity): Promise<void> {
        if (!this.db) {
            return;
        }

        try {
            await this.db.teamPermissions.put({permissions: teamPermissions, teamId: teamId});
        } catch (error) {
            InstrumentationService.trackException(error, "setTeamPermissionsInDb");
        }
    }

    /**
     * Set team member privacy permissions in the Database.
     * @param teamMemberPermissions Team permissions.
     */
    public async setTeamMemberPermissions(teamId: string, permissions: ITeamMemberPermissionsEntity): Promise<void> {
        if (!this.db) {
            return;
        }

        try {
            await this.db.teamMemberPermissions.put({ permissions, teamId });
        } catch (error) {
            InstrumentationService.trackException(error, "setTeamMemberPermissions");
        }
    }

    /**
     * Get team member privacy permissions by Team ID.
     * @param teamId Team ID.
     */
    public async getTeamMemberPermissions(teamId: string): Promise<ITeamMemberPermissionsEntity> {
        if (!this.db) {
            return null;
        }

        let permissions: ITeamMemberPermissionsEntity = null;

        try {
            const record = await this.db.teamMemberPermissions.get(teamId);

            permissions = record?.permissions;
        } catch (error) {
            InstrumentationService.trackException(error, "getTeamMemberPermissions");
        }
        return permissions;
    }

    /**
     * Delete TimeClockEntry in Database by team ID
     * @param teamId
     */
    public async deleteLatestTimeClockEntry(teamId: string): Promise<void> {
        if (!this.db) {
            return;
        }

        try {
            await this.db.latestTimeClockEntry.where({teamId: teamId}).delete();
        } catch (error) {
            InstrumentationService.trackException(error, "deleteLatestTimeClockEntryInDb");
        }
    }

     /**
     * Fetch conflict dismissals in a team, for a time range
     */
    public async getDismissedConflicts(teamId: string, fetchStartTime: Moment, fetchEndTime: Moment): Promise<IConflictDismissEntity[]> {
        if (!this.db) {
            return null;
        }
        let conflicts: IConflictDismissEntity[] = null;
        const fetchStartTimeStamp: number = fetchStartTime.valueOf();
        const fetchEndTimeStamp: number = fetchEndTime.valueOf();

        try {
            const conflictDismissalsFromDb: IConflictDismissDbEntity[] = await this.db.conflictDismissals
                .where("[startTime+endTime+teamId]")
                // Use the key to do a more performant exclusion of items that don't overlap with the current fetch range.
                // With compound indexes, we can't really do complex logic filters as the filter() handler below, but we still
                // use the indexes here to partially weed out items that should excluded.
                .below([fetchEndTimeStamp, fetchEndTimeStamp, teamId]) // Only include items where (item.startTime < fetchEndTimeStamp)
                // Filter out items that do not overlap with the current fetch range
                .filter((conflictDismissal: IConflictDismissDbEntity) => { return conflictDismissal.startTime.valueOf() < fetchEndTimeStamp && conflictDismissal.endTime.valueOf() > fetchStartTimeStamp && conflictDismissal.teamId === teamId; })
                .toArray();
            if (conflictDismissalsFromDb && conflictDismissalsFromDb.length > 0) {
                conflicts = conflictDismissalsFromDb.map((conflictDismissal: IConflictDismissDbEntity) => ConflictDismissEntity.fromJson(conflictDismissal));
            }
        } catch (error) {
            InstrumentationService.trackException(error, "getConflictDismissals");
        }
        return conflicts;
    }

    /**
     * Set Dismissed Conflicts in Database
     * @param teams teams list
     */
    public async setDismissedConflicts(conflictDismissals: IConflictDismissEntity[]): Promise<void> {
        if (!this.db || !conflictDismissals || !conflictDismissals.length) {
            return;
        }

        try {
            await this.db.conflictDismissals.bulkPut(conflictDismissals.map((conflictDismissal: IConflictDismissEntity) => ConflictDismissEntity.toDbModel(conflictDismissal)));
        } catch (error) {
            InstrumentationService.trackException(error, "setConflictDismissalsInDb");
        }
    }

    /**
     * Delete conflict dismissals in a team
     * @param teamId ID of the team
     * @param conflictDismissalsId IDs of conflict dismissals to delete
     */
    public async deleteDismissedConflicts(teamId: string, conflictDismissals: IConflictDismissEntity[]): Promise<void> {
        if (!this.db || !conflictDismissals || !conflictDismissals.length) {
            return;
        }

        try {
            await this.db.transaction("rw", this.db.shifts, async () => {
                for (let i = 0; i < conflictDismissals.length; i++) {
                    // delete conflict dismissals
                    await this.db.conflictDismissals.where({teamId: teamId, id: conflictDismissals[i].id}).delete();
                }
            });
        } catch (error) {
            InstrumentationService.trackException(error, "deleteConflictDismissalsInDb");
        }
    }
}