/**
 * Instrumentation module for logging events and page views.
 */

/**
 * Interface for event properties.
 */
export interface InstrumentationEventPropertyInterface {
    /**
     * The key for the event property.
     */
    key: string;
    /**
     * The value for the event property.
     */
    value: number | string | boolean;
    /**
     * The kind of PII (Personal Identifiable Information).
     */
    piiKind: PiiKind;
}

/**
 * The PiiKind enumeration contains a set of values that specify the kind of PII (Personal Identifiable Information).
 * @enum {number}
 */
export enum PiiKind {
    /**
     * No kind.
     */
    NotSet = 0,
    /**
     * An LDAP distinguished name. For example, CN=Jeff Smith,OU=Sales,DC=Fabrikam,DC=COM.
     */
    DistinguishedName = 1,
    /**
     * Generic information.
     */
    GenericData = 2,
    /**
     * An IPV4 Internet address. For example, 192.0.2.1.
     */
    IPV4Address = 3,
    /**
     * An IPV6 Internet address. For example, 2001:0db8:85a3:0000:0000:8a2e:0370:7334.
     */
    IPv6Address = 4,
    /**
     * The Subject of an e-mail message.
     */
    MailSubject = 5,
    /**
     * A telephone number.
     */
    PhoneNumber = 6,
    /**
     * A query string.
     */
    QueryString = 7,
    /**
     * An SIP (Session Internet Protocol) address.
     */
    SipAddress = 8,
    /**
     * An e-mail address.
     */
    SmtpAddress = 9,
    /**
     * An user ID.
     */
    Identity = 10,
    /**
     * A URI (Uniform Resource Identifier).
     */
    Uri = 11,
    /**
     * The fully-qualified domain name.
     */
    Fqdn = 12,
    /**
     * Scrubs the last octet in a IPV4 Internet address.
     * For example: 10.121.227.147 becomes 10.121.227.*.
     */
    IPV4AddressLegacy = 13
}

/**
 * Return generic event properties object with given key, value pairs
 * @param {String} key
 * @param {any} value
 * @param {String} piiKind
 * @returns {Object}
 */
export function getGenericEventPropertiesObject(key: string, value: number | string | boolean, piiKind: PiiKind = PiiKind.NotSet): InstrumentationEventPropertyInterface {
    let eventPropertiesObject: InstrumentationEventPropertyInterface = {
        key: key,
        value: value,
        piiKind: piiKind
    };
    return eventPropertiesObject;
}
/**
 * Log levels used for logging events.
 * P1 is the highest priority and P3 is the lowest.
 * We use this logLevel to compare with global allowed logging level and log only events that are higher priority than global allowed logging level
 */
export enum logLevel {
    P1 = 1,
    P2 = 2,
    P3 = 3,
    Default = 3,
    Min = 1,
    Max = 3
}

/**
 * SDK Collector Configuration Settings for a Region.
 */
export interface ICollectorConfig {
    /** The Telemetry region (null/empty/undefined for the default region setting). */
    Region?: string;

    /** Collector URL for the region. */
    CollectorUrl: string;
}

/**
 * Defines the level of severity for the event.
 */
export enum SeverityLevel {
    Verbose = 0,
    Information = 1,
    Warning = 2,
    Error = 3,
    Critical = 4
}

/**
 * EUDB related for the telemetry client configuration.
 */
export interface EUDBTelemetryConfig {
    /**
     * Whether to use the compliant client.
     * (i.e. the client that is compliant with the EU General Data Protection Regulation (GDPR) and the EU ePrivacy Directive)
     */
    useCompliantClient: boolean;

    /**
     * The current telemetry region code (EU or US) based on the logged in user's tenant region.
     */
    currentTelemetryRegionCode?: string;
}

/**
 * Decorator for Page components to log their view/render metrics
 */
export { logPageView } from "./utils/PageViewLoggerDecorator";

/**
 * Interface for logger extensions
 */
export interface IInstrumentationLogger {
    /**
     * Log PanelAction UserBI event with specified options (attributes)
     * @param {String} eventName
     * @param {Array} eventDataArray - Array of eventPropertyObjects (each object has key, value, piiKind)
     * @param { [name: string]: number } measurements map[string, number] - metrics associated with this event, displayed in Metrics Explorer on the portal. Defaults to empty.
     * @returns {}
     */
    logEvent(eventName: string, eventDataArray?: Array<InstrumentationEventPropertyInterface>, measurements?: { [name: string]: number }): void;

    /**
     * Log page view UserBI event
     * @param {String} pageName
     * @param {String} currentPageUri
     * @param {Array} eventDataArray - Array of eventPropertyObjects (each object has key, value, piiKind)
     * @param { [name: string]: number } measurements - metrics associated with this event, displayed in Metrics Explorer on the portal. Defaults to empty.
     * @param  {number} duration - the number of milliseconds it took to load the page. Defaults to undefined. If set to default value, page load time is calculated internally.
     * @returns {}
     */
    logPageView(pageName: string, currentPageUri?: string, eventDataArray?: Array<InstrumentationEventPropertyInterface>, measurements?: { [name: string]: number }, duration?: number): void;

    /**
     * Log Perf Event.
     * @param eventName Name of the event.
     * @param eventDataArray Array of eventPropertyObjects (each object has key, value, piiKind).
     * @param measurements Metrics associated with this event, displayed in Metrics Explorer on the portal. Defaults to empty.
     * @param perfLoggingLevel Perf event logging level.
     */
    logPerfEvent(eventName: string, eventDataArray: Array<InstrumentationEventPropertyInterface>, measurements?: { [name: string]: number }, perfLoggingLevel?: logLevel): void;

    /**
     * Set custom dimension for instrumentation events.
     * Note:  Calling this will just update the custom dimension state that will be passed along with subsequent instrumentation events.
     *        This will not trigger its own instrumentation network request, so it should be ok to call this multiple times.
     * @param {Object} eventData - eventData has key, value, piiKind for custom dimension
     * @param {boolean} isGlobal - indicates if the custom dimension needs to be logged as global dimension or within internal data bag
     */
    setCustomDimension(eventData: InstrumentationEventPropertyInterface, isGlobal?: boolean): void;

    /**
     * Clear custom dimension for instrumentation events.
     * Note:  Calling this will just update the custom dimension state that will be passed along with subsequent instrumentation events.
     *        This will not trigger its own instrumentation network request, so it should be ok to call this multiple times.
     * @param {string} key - key for event data
     */
    clearCustomDimension(key: string): void;

    /**
     * Set UserId & TenantId as Custom dimensions so that all events performed by user could be grouped together.
     * @param {string} userId
     * @param {string} tenantId
     * @returns {}
     */
    setUserContext(userId: string, tenantId: string): void;

    /**
     * Clear the user context so that subsequent events will not be grouped with user
     * @returns {}
     */
    clearUserContext(): void;

    /**
     * Start a new logging session.
     */
   startLogSession(): void;

   /**
    * End the current logging session.
    */
   endLogSession(): void;

    /**
     * Set AppVersion in instrumenation logs
     * @param {} version
     */
    setAppVersion(version: string): void;
}

export { instrumentationPerfScenario as InstrumentationPerfScenario } from "./utils/InstrumentationPerfScenario";