import NetworkQueueAutoRejectError from "./NetworkQueueAutoRejectError";

export default class NetworkQueue {

    private static queuedRequests: Map<string, Array<Function>> = new Map();

    private static pendingRequests: Map<string, number> = new Map();

    private static consumablePromises: Map<string, Array<any>> = new Map();

/**
     * Queue the given network request for the given id. It will execute after all the other requests
     * already in the queue for that id have executed. The request parameter is a function that makes a network request
     * and returns a network promise. addRequest returns a consumable promise that is resolved or rejected with the response of the
     * network promise.
     * @param {string} id - a shared id that serializes a set of requests. often an object id
     * @param {Function} request - a function that receieves the previous response and returns a promise that makes a network requeust
     * @returns {Promise<T>} a consumable promise that is resolved or rejected with the response of the network promise
     */
    public static addRequestWithPreviousResponse<T>(id: string, request: (previousResponse?: T) => Promise<T>): Promise<T> {
        return NetworkQueue.addRequestBase(id, (etag, previousResponse) => { return request(previousResponse); });
    }

    /**
     * Queue the given network request for the given id. It will execute after all the other requests
     * already in the queue for that id have executed. The request parameter is a function that makes a network request
     * and returns a network promise. addRequest returns a consumable promise that is resolved or rejected with the response of the
     * network promise.
     * @param {string} id - a shared id that serializes a set of requests. often an object id
     * @param {Function} request - a function that receives the etag from the previous response and returns a promise that makes a network requeust
     * @param {Function} getUpdatedETag - optional function callback to get the updated eTag from the previous response so that the next request in the queue has the updated eTag
     * @returns {Promise<T>} a consumable promise that is resolved or rejected with the response of the network promise
     */
    public static addRequestWithUpdateEtag<T, M>(id: string, request: (eTag?: M) => Promise<T>, getUpdatedETag?: (successResponse: T, id: string) => M): Promise<T> {
        return NetworkQueue.addRequestBase(id, (eTag, previousResponse) => { return request(eTag); }, getUpdatedETag);
    }

    /**
     * Queue the given network request for the given id. It will execute after all the other requests
     * already in the queue for that id have executed. The request parameter is a function that makes a network request
     * and returns a network promise. addRequest returns a consumable promise that is resolved or rejected with the response of the
     * network promise.
     * @param {string} id - a shared id that serializes a set of requests. often an object id
     * @param {Function} request - a function that returns a promise that makes a network requeust
     * @param {Function} getUpdatedETag - optional function callback to get the updated eTag from the previous response so that the next request in the queue has the updated eTag
     * @returns {Promise<T>} a consumable promise that is resolved or rejected with the response of the network promise
     */
    private static addRequestBase<T, M>(id: string, request: (eTag?: M, previousResponse?: T) => Promise<T>, getUpdatedETag?: (successResponse: T, id: string) => M): Promise<T> {
        // create a consumable promise that is resolved when the request function is run and the returned promise
        // is fulfilled or rejected. the consumable promise is returned to the caller who called addRequest
        const consumablePromise = new Promise<T>((resolve, reject) => {

            // create a function that will run the request function and resolve or reject the consumablePromise
            // based on whether the promise returned by the request function is fulfilled or rejected
            // this function will take a parameter that will indicate if the request should automatically be rejected.
            // this would happen if a request earlier in the queue is rejected, as we don't want subsequent requests to
            // be sent to the service.
            // Ex: Create, Update, Update are in the queue. Create is rejected, so Update and Update should be rejected
            /**
             * function that will run the request function and resolve or reject the consumablePromise
             * based on whether the promise returned by the request function is fulfilled or rejected
             * @param autoReject - if true, promise is auto rejected. This happens if one of the requests in the queue fails
             * @param eTag - optional - updated eTag to use on the next request in the queue
             */
            const executableFn = (autoReject: boolean = false, eTag?: M, previousResponse?: T) => {

                // if autoReject is true, then a preceding request was rejected and we will reject this request without executing it
                if (autoReject) {
                    reject(new NetworkQueueAutoRejectError());
                } else {
                    this.incrementPendingRequests(id);

                    request(eTag, previousResponse).then((successResponse: T) => {
                        // the request has completed, so we decrement the number of pending requests for this id
                        this.decrementPendingRequests(id);

                        // since this requests was completed, pop the corresponding promise off the queue (for bulk request serialization)
                        this.popConsumablePromise(id);

                        const responseETag = getUpdatedETag ? getUpdatedETag(successResponse, id) : null;

                        // run the next request in the queue
                        this.executeNextRequest(id, responseETag, successResponse);

                        // the promise returned by the request was resolved successfully, so we resolve our consumablePromise
                        resolve(successResponse);
                    }, (errorResponse: any) => {
                        // the request has completed, so we decrement the number of pending requests for this id
                        this.decrementPendingRequests(id);

                        // because this request was rejected, we will reject the remaining queued requests for this id
                        this.rejectAllQueuedRequests(id);

                        // We pop all promises for this id, as they have all been rejected.
                        this.popAllConsumablePromises(id);

                        // the promise returned by the request was rejected , so we reject our consumablePromise
                        reject(errorResponse);
                    });
                }
            };

            // if other requests are currently in progress for the specified id, queue this request and fulfill it later
            if (this.isAnyRequestInProgress(id)) {
                this.queueRequest(id, executableFn);
            // otherwise, we can run the executable function immediately, which will resolve the consumablePromise for the caller
            } else {
                executableFn(false, null);
            }
        });

        // We queue and track this promise in order to serialize bulk requests
        this.queueConsumablePromise(id, consumablePromise);

        return consumablePromise;
    }

    /**
     * Queue the given bulk network request that modifies the given list of ids. It will execute after all the other requests
     * already in the queue for each id have executed. The request parameter is a function that makes a network request
     * and returns a network promise. addBulkRequest returns a consumable promise that is resolved or rejected with the response of the
     * network promise.
     * @param {string[]} ids - an array of shared ids, each of which serialize a set of requests. often an object id
     * @param {Function} request - a function that returns a promise that makes a network requeust
     * @param {Function} getUpdatedETag - optional function callback to get the updated eTag from the previous response so that the next request in the queue has the updated eTag
     * @returns {Promise<T>} a consumable promise that is resolved or rejected with the response of the network promise
     */
    public static addBulkRequest<T, M>(ids: string[], request: () => Promise<T>,  getUpdatedETag?: (successResponse: T, id: string) => M): Promise<T> {
        // create a consumable promise that is resolved when the request function is run and the returned promise
        // is fulfilled or rejected. the consumable promise is returned to the caller who called addBulkRequest
        let consumablePromise = new Promise<T>((resolve, reject) => {
            // if the ids in the bulk request have unfulfilled promises queued, we must wait for every unresolved promise to finish.
            let unfulfilledPromisesForIds = [] as Promise<T>[];
            for (let i = 0; i < ids.length; i++) {
                const id = ids[i];
                unfulfilledPromisesForIds = unfulfilledPromisesForIds.concat(this.getAllConsumablePromises<T>(id));
                // we want to reserve each id for our bulk request, so we increment the number of pending requests for each id. This will cause
                // further incoming requests for each id to be queued.
                this.incrementPendingRequests(id);
            }

            const allPromisesForIdsFulfilled = Promise.all(unfulfilledPromisesForIds);

            allPromisesForIdsFulfilled.then(() => {
                request().then((successResponse: T) => {
                    // We decrement the pending requests for each id in the bulk request, pop a queued promise to account for the completed bulk request,
                    // and we manually call this.executeNextRequest to fire off the next request in each queue, if there is one
                    ids.forEach((id: string) => {
                        this.popConsumablePromise(id);
                        this.decrementPendingRequests(id);
                        const eTag = getUpdatedETag ? getUpdatedETag(successResponse, id) : "";
                        this.executeNextRequest(id, eTag, successResponse);
                    });

                    // the promise returned by the request was resolved successfully, so we resolve our consumablePromise
                    resolve(successResponse);
                }, (errorResponse: any) => {
                    // In the event of an error response, for each id, we will reject all queued requests, pop all the promises from the queue,
                    // and decrement the num of pending requests. This resets the queue for those ids.
                    ids.forEach((id: string) => {
                        this.decrementPendingRequests(id);
                        // because this request was rejected, we will reject the remaining queued requests for this id
                        this.rejectAllQueuedRequests(id);
                        // We pop all promises for this id, as they have all been rejected.
                        this.popAllConsumablePromises(id);
                    });

                    // the promise returned by the request was rejected , so we reject our consumablePromise
                    reject(errorResponse);
                });
            });
        });

        // In order to serialize multiple bulk requests for the same ids, we will add this consumable promise to the queue of promises for each id
        // This will cause incoming bulk requests to wait until this promise is fulfilled
        ids.forEach((id: string) => {
            this.queueConsumablePromise(id, consumablePromise);
        });

        return consumablePromise;
    }

    /**
     * Queue the given consumablePromise for the given id.
     * @param id
     * @param consumablePromise
     */
    private static queueConsumablePromise<T>(id: string, consumablePromise: Promise<T>) {
        let storedPromises = this.consumablePromises.get(id) || [];
        storedPromises.push(consumablePromise);
        this.consumablePromises.set(id, storedPromises);
    }

    /**
     * Pop the first consumable promise queued for the given id.
     * @param id
     */
    private static popConsumablePromise<T>(id: string): Promise<T> {
        let storedPromises = this.consumablePromises.get(id);
        if (storedPromises && storedPromises.length) {
            return storedPromises.shift();
        }
    }

    /**
     * Return all the promises queued for a given id and reset the queue.
     * @param id
     */
    private static popAllConsumablePromises<T>(id: string): Promise<T>[] {
        const storedPromises = this.getAllConsumablePromises<T>(id);
        this.consumablePromises.set(id, []);
        return storedPromises;
    }

    /**
     * Return a list of all the promises queued for a given id.
     * @param id
     */
    private static getAllConsumablePromises<T>(id: string): Promise<T>[] {
        const storedPromises = this.consumablePromises.get(id) || [];
        return storedPromises;
    }

    /**
     * Adds the given function to the end of the queue of requests for the specified id.
     * @param {string} id - a shared id that serializes a set of requests. often an object id
     * @param {Function} request - a function that returns a promise that makes a network requeust
     * @returns {}
     */
    private static queueRequest(id: string, executableFn: Function) {
        let queuedRequestsForId = this.getQueuedRequests(id);
        queuedRequestsForId.push(executableFn);
    }

    /**
     * Removes the first item from the front of the queue and returns it. Returns null if queue is empty.
     * @param {string} id - a shared id that serializes a set of requests. often an object id
     * @returns {Function} the queued function that returns a network promise
     */
    private static popRequest(id: string): Function {
        let request = null;
        let queuedRequestsForId = this.getQueuedRequests(id);
        if (queuedRequestsForId && queuedRequestsForId.length > 0) {
            request = queuedRequestsForId.splice(0, 1)[0];
        }
        return request;
    }

    /**
     * Pops the next queued request for the specified id and executes it if it is not null .
     * @param {string} id - a shared id that serializes a set of requests. often an object id
     * @param eTag - Etag from previous response
     * @param previousResponse - Previous success response
     * @returns {}
     */
    private static executeNextRequest<T, M>(id: string, eTag: M, previousResponse: T) {
        let nextExecutableFn = this.popRequest(id);
        if (nextExecutableFn) {
            nextExecutableFn(false, eTag, previousResponse);
        }
    }

    /**
     * Returns true if there are any requests currently in progress for the specified id.
     * @param {string} id - a shared id that serializes a set of requests. often an object id
     * @returns {boolean} a boolean that is true if any requests are currently in progress for the specified id
     */
    private static isAnyRequestInProgress(id: string): boolean {
        let numPendingRequestsForId = this.getNumPendingRequests(id);
        return numPendingRequestsForId > 0;
    }

    /**
     * Returns an array of the requests that are queued up for the specified id. Returns an empty array if no requests are queued.
     * @param {string} id - a shared id that serializes a set of requests. often an object id
     * @returns {Array<Function>} an array of the reqeuests that are queued up for the specified id
     */
    private static getQueuedRequests(id: string): Array<Function> {
        let queuedRequests = this.queuedRequests.get(id);
        if (!queuedRequests) {
            this.queuedRequests.set(id, []);
        }
        return this.queuedRequests.get(id);
    }

    /**
     * Rejects all queued requests for the specified id.
     * @param {string} id - a shared id that serializes a set of requests. often an object id
     * @returns {}
     */
    private static rejectAllQueuedRequests(id: string) {
        let queuedRequests = this.queuedRequests.get(id);
        if (queuedRequests && queuedRequests.length > 0) {
            for (let i = 0; i < queuedRequests.length; i++) {
                // Call the queued executable function with the autoReject parameter set to true. This will cause the request to be rejected
                queuedRequests[i](true /* autoReject */);
            }
        }
    }

    /**
     * Returns the number of requests in progress for the specified id. Sets the number of pending requests to 0 if the number of pending
     * requests is yet to be initialized for the specified id.
     * @param {string} id - a shared id that serializes a set of requests. often an object id
     * @returns {number} the number of requests currently in progress (not the same as number of requests queued) for the specified id
     */
    public static getNumPendingRequests(id: string): number {
        let pendingRequests = this.pendingRequests.get(id);
        if (!pendingRequests) {
            this.pendingRequests.set(id, 0);
        }
        return this.pendingRequests.get(id);
    }

    /**
     * Increment the number of pending requests for the specified id.
     * @param {string} id - a shared id that serializes a set of requests. often an object id
     * @returns {}
     */
    private static incrementPendingRequests(id: string) {
        this.pendingRequests.set(id, this.getNumPendingRequests(id) + 1);
    }

    /**
     * Decrement the number of pending requests for the specified id.
     * @param {string} id - a shared id that serializes a set of requests. often an object id
     * @returns {}
     */
    private static decrementPendingRequests(id: string) {
        this.pendingRequests.set(id, this.getNumPendingRequests(id) - 1);
    }
}