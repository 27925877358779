import groupedRequestDetailsStore from "../store/store";
import { IndividualOpenShiftRequestDetails } from "../store/schema/GroupedRequestDetailsStoreSchema";
import { mutatorAction } from "satcheljs";

export default mutatorAction('TOGGLE_REQUEST_SELECTION_IN_STORE', function toggleRequestSelectionInStore(openShiftRequestId: string) {
    if (groupedRequestDetailsStore) {
        if (!(groupedRequestDetailsStore.individualRequestDetails && groupedRequestDetailsStore.individualRequestDetails.has(openShiftRequestId))) {
            return;
        }

        const requestDetails: IndividualOpenShiftRequestDetails = groupedRequestDetailsStore.individualRequestDetails.get(openShiftRequestId);

        let requestUpdated: boolean = false;
        if (requestDetails.isSelected && groupedRequestDetailsStore.selectedRequestsCount > 0) {
            groupedRequestDetailsStore.selectedRequestsCount--;
            requestUpdated = true;
        } else if (!requestDetails.isSelected) {
            groupedRequestDetailsStore.selectedRequestsCount++;
            requestUpdated = true;
        }

        if (requestUpdated) {
            requestDetails.isSelected = !requestDetails.isSelected;
            groupedRequestDetailsStore.individualRequestDetails.set(openShiftRequestId, requestDetails); // TODO: Check if this is needed
        }
    }
});
