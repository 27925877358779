/**
 * Map for the icon names in the Service (which mapped to the icon names in angular) with new ones in Fabric for compatibility with the Service.
 * The icon Names in the Service are mapped directly to the Icon names used in Angular.
 * Hence, we keep this data to map the icon names in Fabric to the ones in the Service.
 * Updating the icon names in the Service might lead to backward compatibility issues and hence held off for now.
 */

export default class TimeOffReasonsIconData {
    public static readonly DefaultIconName = "Calendar";
    public static readonly DeleteIconName = "Trash";
    /**
     * This is the list of icons in the Service.
     */
    public static readonly OldIcons: Array<string> =
        ["Car",
        "Calendar",
        "Running",
        "Plane",
        "FirstAid",
        "Doctor",
        "NotWorking",
        "Clock",
        "JuryDuty",
        "Globe",
        "Cup",
        "Phone",
        "Weather",
        "Umbrella",
        "PiggyBank",
        "Cake",
        "Pin",
        "TrafficCone",
        "Dog",
        "Sunny"];

      /**
       * This is the list of Fabric Icon names for time off reasons.
       * The OldIcons and NewIcons are ordered by index for look up.
       * So, OldIcons[i] is the icon name in the Service for the Fabric icon NewIcons[i].
       */
      public static readonly NewIcons: Array<string> =
        ["requests-car",
        "requests-meetingtime",
        "requests-leave",
        "requests-plane",
        "requests-doctor",
        "requests-sick",
        "requests-crossincircle",
        "requests-alarm",
        "requests-glossary",
        "requests-globe",
        "requests-mug",
        "requests-mobile",
        "requests-snow",
        "requests-rain",
        "requests-meh",
        "requests-party",
        "requests-pin",
        "requests-ship",
        "requests-dog",
        "requests-day"];

    /**
     * Gets the icon name in the Service for the icon in Fabric by getting in the index in the NewIcons and looking up in OldIcons.
     * @param newIconName The new Icon name from Fabric.
     */
    public static getOldIconName(newIconName: string) {
        let ind = TimeOffReasonsIconData.NewIcons.indexOf(newIconName);
        if (ind >= 0 && ind < TimeOffReasonsIconData.OldIcons.length) {
            return TimeOffReasonsIconData.OldIcons[ind];
        }

        return newIconName;
    }

    /**
     * Gets the Fabric Icon name for the icon name in the Service by getting the index in the OldIcons and looking up in the NewIcons.
     * @param oldIconName The icon name in the Service.
     */
    public static getNewIconName(oldIconName: string) {
        let ind = TimeOffReasonsIconData.OldIcons.indexOf(oldIconName);
        if (ind >= 0 && ind < TimeOffReasonsIconData.NewIcons.length) {
            return TimeOffReasonsIconData.NewIcons[ind];
        }

        return oldIconName;
    }
}