import { mutatorAction } from "satcheljs";

import { ITeamMemberPermissionsEntity } from "../../../sh-models";
import { TeamMemberPermissionsStore } from "../store/TeamMemberPermissionsStore";

/**
 * Set the team member permissions for the current team to given value.
 * @param permissions The team member permissions.
 */
export const setTeamMemberPermissions = mutatorAction("setTeamMemberPermissions", (permissions: ITeamMemberPermissionsEntity) => {
    TeamMemberPermissionsStore().permissions = permissions;
});