import { action } from "satcheljs";
import { StaffHubHttpError } from "sh-application";

/**
 * Triggers handlers for Workflow Integration API errors
 */
export default action(
    "HANDLE_WFI_ERROR",
    (
        staffHubHttpError: StaffHubHttpError
    ) => ({
        staffHubHttpError: staffHubHttpError
    })
);