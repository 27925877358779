import TagStore from "../store";
import { ITagEntity, TagEntity } from "sh-models";
import { mutatorAction } from "satcheljs";
import setEntitiesInMap from 'sh-stores/setEntitiesInMap';

/**
 * Set tags in the tag store
 * @param {ITagEntity[]} tagModels - array of tag models that needs to be set on the store
 */
export let setTags = mutatorAction("setTags",
    function setTags(tags: ITagEntity[]) {
        // We use clones of the argument entities. This is because setting the entities in the satchel stores will mark them as observables
        // and any callers who still hold references to these entities will not be able to mutate them (in event of an error, for example) without
        // triggering mobx exceptions
        setEntitiesInMap<ITagEntity>(tags.map(tag => TagEntity.clone(tag)), TagStore().tags);
    });
