import { memberEditorViewState } from "../";
import { action } from "satcheljs/lib/legacy";

/**
 * Generic action that updates a showInlineError boolean on a view state
 * @param showInlineError - value for showInlineError view state property
 */
export default action("setShowInlineError")(
    function setShowInlineError(showInlineError: boolean) {
      memberEditorViewState().showInlineError = showInlineError;
    }
);