import * as moment from "moment";
import { createStore } from "satcheljs";
import {
    IBaseConflictEntity,
    IMemberEntity,
    INoteEntity,
    IOpenShiftEntity,
    IShiftEntity,
    ITagEntity,
    ScheduleCalendarTypes,
    ScheduleOverViewTypes,
    ShiftFilterType
    } from "sh-models";
import { ObservableMap, ObservableSet } from "mobx";
import { PrintEntryPoint, PrintOrientation, PrintSize } from "sh-application/../StaffHubConstants";
import { SaveStates } from "sh-application/components/common/SaveStatusCommandBarItem";
import { SchedulesViewStateStore } from "sh-application/components/schedules/lib/store/schema/SchedulesViewStateStore";
import "../orchestrators/onShiftsModified";
import "../orchestrators/onResetGlobalOpenShiftStore";
import "../orchestrators/onNotesModified";
import "../orchestrators/onTimeZoneUpdated";
import "../orchestrators/onOpenShiftsDeleted";
import "../orchestrators/onOpenShiftsAdded";
import "../orchestrators/onOpenShiftsUpdated";
import "../mutators/setIsProgressiveRendering";
import "../orchestrators/onResetGlobalShiftStore";
import { ScheduleComponents } from "./ScheduleComponents";

// Register orchestrators

const initialStore: SchedulesViewStateStore = {
    employeeViewType: null,                                   // Initializing to null, as this is set in the initiazeViewState call
    scheduleCalendarType: ScheduleCalendarTypes.Week,         // the calendar type of the schedules view, default it to null: Day/Week/Month
    scheduleOverViewType: ScheduleOverViewTypes.PeopleView,   // the shifts overview type of the schedules page view : View by Schedule/ShiftView
    staffPerIntervalEnabled: false,                           // True when the staff per interval is enabled in the shift view.
    showProfilePictures: true,                                        // Whether user profile pictures are shown in the shift view.
    showStaffPer30Rows: false,                                // True when the staff per 30 rows are shown in the shift view
    viewSelectedDate: moment(),                               // the date selected by the user (today by default)
    viewStartDate: moment(),                                  // the start date of the current view (today by default). For example, for week view this would be the start date of the week.
    viewEndDate: moment(),                                    // the current end date of the view (today by default). For example, for week view this would be the end date of the week.
    isDataInDateRangeLoaded: false,                           // Indicates if the shifts and notes in the current date range are retrieved from the Service
    isViewGrouped: true,                                      // toggle between grouped/ungrouped views of shifts
    rowKeyForMemberInReorderMode: null,                       // Flexi row key for the member row that is in reorder mode
    collapsedTags: [],                                        // List of tag ids which are collapsed in the schedule page
    isReorderTagsPanelOpen: false,                            // Indicates if the Tags panel is open
    isRowBeingDragged: false,                                 // True, if a row is being dragged
    isViewStatePopulated: false,                              // True when the view state has been populated/initialized. Should be set after loading a schedule (eg, app launch or team switch).
    isHoverColumnHidden: false,                               // True if focus/hover is on a group control and we wish to hide hover column
    saveState: SaveStates.Loaded,                             // Indicates that the schedule has loaded and no save is in progress
    isCopyDateRangeDialogOpen: false,                         // True if the copy last date range panel is open
    printScale: 1,                                            // Scale for printing in scale to one page mode
    printOrientation: PrintOrientation.Portrait,              // Print orientation selected by user
    printSize: PrintSize.Letter,                              // Print size selected by user
    printScalingEnabled: false,                               // True when scale to one page is enabled by user
    scrolledPastNavigationArrows: false,                      // True when user has scrolled past the navigation arrows in the header
    isScheduleTogglesMenuOpen: false,                         // True when the contextual menu is open in the header
    shouldRenderPrintDOM: false,                              // When true, schedules gets rendered in parallel for print measurements
    printLoading: false,                                      // True when scale to one page printing is loading
    printButtonPressed: false,                                // True when print button is pressed
    printScalingPasses: 0,                                    // No.of print scale compute passes done for print to one page
    isPrintScaleComputed: false,                              // True when print scale has been computed after multiple passes for scale to one page
    printTeamSize: 0,                                         // No.of members who appear in the Schedules print. Used for instrumentation.
    printEntryPoint: PrintEntryPoint.Other,                   // From where the user initiated print. Used for instrumentation.
    printInvokeTime: null,                                    // Time when the print was initiated. Used to calculate time taken to generate print for instrumentation
    shouldInstrumentPrint: true,                              // Make sure we instrument only once as onBeforePrint gets fired multiple times in Chrome (https://bugs.chromium.org/p/chromium/issues/detail?id=105743#c14)
    isScheduleHeaderRowSticky: false,                         // True if the scheduleHeaderRow is in a sticky state
    scheduleHeaderRowNeedsScrollAdjustment: false,            // True is the schedule header row needs its x axis updated to match the horizontal scroll position
    reorderMemberProps: null,                                 // Props for the ReorderMemberOverlay component
    memberPanelValues: null,                                  // The needed values for the add member panel.  Null is valid and means panel shouldn't show
    isHeaderExpanded: false,                                  // Boolean to indicate if the header is expanded
    forceHeaderExpand: false,                                 // Indicates if the header has to be forcefully kept expanded
    showDateInHeader: false,                                  // Indicates if the date needs to be shown in the header
    isReorderMemberInProgress: false,                         // Indicates if reordering member is still in progress
    showFilterError: false,                                   // Indicates if there is an error/no match for the selected filter
    isCopyScheduleButtonDisabled: false,                      // Indicates if we should disable the copy schedule button
    filterHeaderLabel: "",                                    // The label of the filter button in the command bar

    notesInView: new ObservableMap<string, INoteEntity>(),
    shiftsInView: new ObservableMap<string, IShiftEntity>(),
    openShiftsInView: new ObservableMap<string, IOpenShiftEntity>(),
    newlyEditedEntities: new ObservableMap<string, boolean>(),
    datesWithNewEdits: new ObservableMap<string, boolean>(),
    filteredMembers: new ObservableMap<string, IMemberEntity>(),        // List of selected members in the schedule filter
    filteredTags: new ObservableMap<string, ITagEntity>(),
    selectedShiftFilters: new ObservableMap<string, ShiftFilterType>(),
    isProgressiveRendering: false,                              // True when schedule is progressive rendering (false when complete)
    progressiveRenderStartTimestamp: -1,                        // Timestamp of the beginning of the most recent progressive rendering cycle
    progressiveRenderEndTimestamp: -1,                          // Timestamp of the end of the most recent progressive rendering cycle
    groupsInView: [],                                           // Array of tag ids for groups that have been added to the view
    membersInView: [],                                          // Array of member ids that have been added to the view
    featureClickedBeforeDataFetched: false,                     // Flag set when a feature is clicked while still progressive rendering
    conflictsInView: new ObservableMap<string, IBaseConflictEntity[]>(),           // Conflicts in a schedule view
    conflictCountInView: 0,                                     // Total count of conflict of shifts in schedule view
    myConflictCountInView: 0,                                   // Total conflict calculation for only your shifts
    isConflictCalculationInProgress: false,                     // True when conflict caclulation are in progress,false when done.
    showShiftConflicts: true,                                    // True when toggled in UI view options menu.
    renderedComponents: new ObservableSet<ScheduleComponents>()
};

const schedulesViewStateStore = createStore<SchedulesViewStateStore>('schedulesViewStateStore', initialStore);
export default schedulesViewStateStore;