/**
 * Strings class for the team settings keys.
 */
export const TeamSettingsKeys = {
    ScheduleStartDate: "ScheduleStartDate",
    ScheduleType: "ScheduleType",
    ScheduleTypeCount: "ScheduleTypeCount",
    StartingDayOfWeek: "StartingDayOfWeek",
    TimeZoneOlsonCode: "TimeZoneOlsonCode",
    TimeClockEnabled: "TimeClockEnabled"
};
