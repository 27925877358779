import { createStore } from "satcheljs";
import { ITimeOffReasonEntity } from "sh-models";
import { ObservableMap } from "mobx";
import { TimeOffReasonsStoreSchema } from "./schema/TimeOffReasonsStore";

/**
 * The store for Time Off Reasons data
 */
let TimeOffReasonsStore = createStore<TimeOffReasonsStoreSchema>("timeoffreasonsstore", {
    timeOffReasons: new ObservableMap<string, ITimeOffReasonEntity>(),
    requestableTimeOffReasonsIds: []
});

export default TimeOffReasonsStore;
