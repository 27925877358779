import * as React from "react";
import { LineHtmlModel, PlaceholderTypes } from "sh-models";

const htmlEncode = require('htmlencode').htmlEncode;

/**
 * Utilities for body line html formatting
 *
 * Link html model
 *      {   text: "message text",
 *          cssClasses: "css classes override for the line",
 *          placeholders: [
 *              { text: "placeholder text", type: "placeholder type", cssClasses: "placeholder CSS" },  // Text placeholders
 *              { text: "placeholder text", type: "placeholder type", cssClasses: "placeholder CSS" },  // TextSpan placeholders
 *          ]
 *      }
 */
export default class LineHtmlFormatUtils {
    /**
     * Gets the html for the line items after applying the css styles for each placeholders
     * @param lineHtmlModel: LineHtmlModel represents text and placeholder items
     */
    public static getLineHtmlElement(lineHtmlModel: LineHtmlModel): JSX.Element {
        let bodyLineHtml = "";
        if (lineHtmlModel.text && lineHtmlModel.placeholders) {

            // Collect the arguments for the String.format call, beginning with the format string
            let formatString = htmlEncode(lineHtmlModel.text);
            let stringFormatArgs = Array<string>(); // [formatString];
            for (let index = 0; index < lineHtmlModel.placeholders.length; index++) {
                let currentPlaceholder = lineHtmlModel.placeholders[index];

                let htmlOutput = "";
                // Ensure that possible user input text is html encoded
                switch (currentPlaceholder.placeholderType) {
                    case PlaceholderTypes.Text: {
                        htmlOutput = htmlEncode(currentPlaceholder.text);
                        break;
                    }
                    case PlaceholderTypes.TextSpan: {
                        htmlOutput = "<span class=\"" + currentPlaceholder.cssClasses + "\">" + htmlEncode(currentPlaceholder.text) + "</span>";
                        break;
                    }
                    case PlaceholderTypes.Link: {
                        htmlOutput = "<a target=\"_blank\" href=\"" + (currentPlaceholder.url ? currentPlaceholder.url : "#" ) + "\" class=\"" + currentPlaceholder.cssClasses + "\">" + htmlEncode(currentPlaceholder.text) + "</a>";
                        break;
                    }
                    case PlaceholderTypes.LineBreak: {
                        htmlOutput = "<br />";
                        break;
                    }
                    case PlaceholderTypes.TextDiv: {
                        htmlOutput = "<div class=\"" + currentPlaceholder.cssClasses + "\">" + htmlEncode(currentPlaceholder.text) + "</div>";
                        break;
                    }
                }

                stringFormatArgs.push(htmlOutput);
            }
            bodyLineHtml =  formatString.format(...stringFormatArgs);
        }

        /* IMPORTANT: all text content above needs to be properly html encoded */
        return React.createElement("div", {"dangerouslySetInnerHTML" : {__html: bodyLineHtml } , "className": lineHtmlModel.cssClasses});
    }
}
