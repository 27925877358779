import { createStore } from "satcheljs";
import { DateMembers, ShiftStoreSchema } from "./schema/ShiftStoreSchema";
import { IShiftEntity } from "sh-models";
import { ObservableMap } from "mobx";

/**
 * Store for Shifts
 */
let ShiftStore = createStore<ShiftStoreSchema>(
    "shiftStore",
    {
        // map of all shifts using the shift id as the key
        shifts: new ObservableMap<string, IShiftEntity>(),
        // map of shift ids using the date as the key
        shiftIdsByDateAndMember: new ObservableMap<string, DateMembers>(),
        shiftsCacheStartTime: null,
        shiftsCacheEndTime: null
    });

export default ShiftStore;