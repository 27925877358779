import schedulesViewStateStore from "../store/store";
import { mutatorAction } from "satcheljs";

/**
 * The mutatorAction to set conflict progress state
 * @param inProgress - boolean - the inProgress state of calculation
 */
export default mutatorAction('SET_IS_CONFLICT_CALCULATION_IN_PROGRESS', function setIsConflictCalculationInProgress(
    inProgress: boolean
) {
    schedulesViewStateStore().isConflictCalculationInProgress = inProgress;
});