import { AppViewState } from "./schema/AppViewState";
import { createStore } from "satcheljs";
import { GlobalCommandBarTab } from "sh-application/store/schema/GlobalCommandBarViewState";
import { ITimeOffReasonEntity } from "sh-models";
import { LoginState } from "./schema/LoginViewState";
import { MessageBarType } from "@fluentui/react";
import { SaveStates } from "sh-application/components/common/SaveStatusCommandBarItem";
import { TeamSetupPickerMode } from "sh-application/components/teamsetuppicker";

let initialViewState: AppViewState = {

    /**
     * Add/edit custom field panel view state
     */
    addEditTeamCustomFieldViewState: {
        teamCustomField: null,                  // The team custom field which is to be edited
        panelOpen: false,                       // Indicates if the panel is open
        isSaving: false,                        // Indicates if the panel is in saving state
        messageBarError: null                   // The error mesage after saving custom field
    },

    teamSettingsViewState: {
        teamName: "",                   // Team name empty by default
        teamTimeZone: "",               // Team time zone setting to empty by default
        teamTimeZoneId: "",             // Team time zone Id setting to empty by default
        teamLocation: "",               // Team location set to empty by default
        startDayOfWeek: "",             // Start day of week when scheduleType is "Month"
        timeOffReasons: new Array<ITimeOffReasonEntity>(),            // Time off reasons list.
        timeClockEnabled: false,        // Time clock disabled by default
        timeClockLocations: null,       // Time clock location for the team
        isTimeClockDataAvailable: false, // Is time clock data available for export
        teamCustomFields: null,          // Custom field available to the team created by the tenant admin available publicly
        customFields: null,               // Custom field values specific to the team
        copyShiftActivitiesWithShiftsEnabled: null,     // Toggles whether, when copying shifts, shift activities should be copied or not
        hideOpenShifts: null,       // Toggles show/hide of open shifts for employees on mobile. Also influences appearance of open shifts on web
        swapShiftsRequestsEnabled: true,        // Toggles show/hide of swap shifts
        offerShiftRequestsEnabled: true,        // Toggles show/hide of offer shifts
        timeOffRequestsEnabled: true,           // Toggles show/hide of time off requests
        enableLastNameInitial: false,     // Toggles show/hide of last names for employees when union support is enabled
        saveState: SaveStates.Loaded,     // The SaveState
        hasTeamNameChanged: false,        // track whether the user has changed the team name since last save. We use this for deciding if we should overwrite their values on sync. This should be used for all text entry with autosave
        haveTimeOffReasonsChanged: false, // track whether the time off reasons have changed
        haveCustomFieldsChanged: false,    // track whether custom fields have changed
        errorGettingSettings: false       // Error getting settings.
    },
    teamPageViewState: {
        isPanelVisibile: false,         // flag that controls the visibility of the add/edit member panel
        isCurrentUserAdmin: false       // when true, the current user is an admin
    },
    addEditMemberPanelViewState: {
        firstName: "",                          // store variable that is mapped to first name in the add/edit member panel
        lastName: "",                           // store variable that is mapped to last name in the add/edit member panel
        email: "",                              // store variable that is mapped to email in the add/edit member panel
        phoneNumber: "",                        // store variable that is mapped to phone number in the add/edit member panel
        isPhoneNumberValid: true,               // when true, the current phone number in the store is valid
        isAdmin: false,                         // store variable that is mapped to admin toggle in the add/edit member panel
        roleIds: [],                            // store variable that keeps track of role ids for the member in add/edit member panel
        isSaving: false,                        // when true, add/edit panel is currently saving and shows the loading icon
        isNamePickerVisible: true,              // when true, add/edit panel is currently showing the name picker that gets members from GAL or Kronos
        namePickerQuery: "",                    // store variable to keep track of the current value in the name picker text field
        disablePickerInput: false,              // when true, text input in the name picker is disabled

        messageBarError: "",                    // message to display in fabric error message bar
        messageBarShowRefreshButton: false,     // whether to show the refresh button in the error MessageBar
        phoneNumberErrorMessage: "",            // store variable that stores the error message used by the TelInput component
        emailErrorMessage: "",                  // store variable that stores the error message used by the email text field in the add/edit member panel
        adminErrorMessage: "",                  // store variable that represents error message for the admin toggle

        currentlySelectedTeamMember: null,      // view state object that stores the team member object that the user has selected while adding user to a tag

        isKronosDialogOpen: false,              // when true, panel closes and kronos dialog shown - used when credentials have expired
        isKronosError: false,                   // when true, panel closes and error message shown - used in case of a generic kronos error
        isKronosSecondaryAdminError: false,     // when true, panel closes and error message shown - used when a secondary admin tries to add a member to a team
        kronosUsername: "",                     // store variable that represents kronos' username

        isSendEmailInviteViaMailto: false       // when true, use mailto to send an email invite
    },

    /**
     * Automation settings panel view state
     */
    automationSettingsPanelViewState: {
        panelOpen: false,                       // Indicates if the panel is open
        panelAlertText: "",                     // accessibility alert text, mainly used to read the panel's title. empty string for no alert.
        numTags: 10,                            // Number of tags in team for automation (only used if reuseExistingTags = false)
        numMembers: 150,                        // Number of members to add to a team (only used if reuseExistingMembers = false)
        shiftDensity: 0.8,                      // Likelihood that a member has a shift on a given date
        openShiftDensity: 0.03,                 // Likelihood that a shift is an open shift
        timeOffDensity: 0.1,                    // Likelihood that a shift is a timeoff
        dayNoteDensity: 0.8,                    // Likelihood that a day has a day note
        startDate: null,                        // The start date for creating shifts
        endDate: null,                          // The end date for creating shifts
        maxShiftActivitiesPerShift: 3,          // The maximum number of shift activities per shift
        shiftActivitiesProbability: 0.3,        // The probability that a shift/openshift contains activities
        shiftNoteProbability: 0.3,              // The probability that a shift/timeoff/openshift has a note
        shiftTitleProbability: 0.1,             // The probability that a shift/openshift has a title
        reuseExistingMembers: true,             // Whether to reuse existing team members instead of creating new ones
        reuseExistingTags: true                 // Whether to reuse existing team tags instead of creating new ones
    },

    /**
     * Message to display in the global message bar
     */
    globalMessageViewState: {
        messages: null,                         // message to display in global message bar
        actionButtonTitle: null,                // title to display on optional action button (must supply callback if there is a title)
        actionButtonCallback: null,             // callback function for optional action button
        autoDismiss: false,                     // set to true to automatically dimiss the message bar after a set amount of time
        messageBarType: MessageBarType.info,    // the type of message bar will change the icon that is displayed before the message
        isMultiline: false,                     // set to true to prevent clipping of message bar details
        isCenterAligned: false                  // set to true to have the message center aligned
    },

    /**
     * Command bar view state to display the items specific to the page
     */

    globalCommandBarViewState: {
        rightCommandBarItems: [],
        selectedTab: GlobalCommandBarTab.Schedules
    },

    /**
     * Schedule grid view state
     */
    scheduleGridViewState: {
        addGroupButtonDisabled: false          // set initially to false so user can add group with button
    },

    /**
     * Export shifts panel view state
     */
    enableTimeClockPanelViewState: {
        isSaving: false,                        // true, if we are currently saving the settings
        messageBarError: "",                    // error message to display in panel
        messageBarShowRefreshButton: false,     // whether to show the refresh button in the error MessageBar
        panelOpen: false,                       // Indicates if the panel is open
        timeClockFRECompleted: false,           // Indicates if FRE has been completed already
        timeClockEnabled: false,                // Indicates if the time clock feature is enabled
        includeLocationValue: false,            // Setting indicating whether the location check is included for time clock
        includeLocationOriginalValue: false,    // Current team setting indicating the location check is included for time clock
        locationValue: null,                    // Location address or coordinates to be verified for time clock
        locationOriginalValue: null,            // Current team setting for the location address or coordinates to be verified for time clock
        saveDisabled: false,                    // Indicates if the save option on the panel should be disabled
        panelAlertText: ""                      // accessibility alert text, mainly used to read the panel's title. empty string for no alert.
    },

    /**
     * ReorderTagsPanel view state
     */
    reorderTagsPanelViewState: {
        messageBarError: "",                    // error message to display in panel
        messageBarShowRefreshButton: false,     // whether to show the refresh button in the error MessageBar
        isSaving: false,                        // true, if we are currently saving the tags
        activeSortedTags: [],                   // array to store the current order of the tags
        currentSelectedTagIndex: -1,            // currently selected tag group. -1 for no selection
        panelAlertText: "",                     // accessibility alert text, mainly used to read the panel's title. empty string for no alert.
        isSqueezed: false                       // true when the content is squeezed and we need to modify so the view is usable (a11y requirement)
    },

    /**
     * Export time clocks panel view state
     */
    exportTimeClocksPanelViewState: {
        panelOpen: false,                       // Indicates if the panel is open
        startDate: null,                        // The start date for export
        endDate: null,                          // The end date for export
        exportDisabled: false,                  // Indicates if the export option on the panel should be disabled
        isSaving: false,                        // true, if we are currently exporting
        messageBarError: "",                    // error message to display in panel
        messageBarShowRefreshButton: false,     // whether to show the refresh button in the error MessageBar
        invalidDatesErrorMessage: "",           // error message shown below the date fields when the selected dates are invalid
        panelAlertText: ""                      // accessibility alert text, mainly used to read the panel's title. empty string for no alert.
    },

    /**
     * Share dialog (for sharing and withdrawing shifts with employees)
     */
    shareDialogViewState: {
        dialogOpen: false,                       // when true, the share panel is visible
        withdrawMode: false,                     // when true, panel is in withdraw mode (share mode when false)
        panelAlertText: ""                       // accessibility alert text, mainly used to read the panel's title. empty string for no alert.
    },

    /**
     * CurrentlySharedSchedulePanel view state
     */
    currentlySharedSchedulePanelViewState: {
        panelOpen: false                        // Indicates if the panel is open
    },

    /**
     * Tour guide view state
     */
    tourGuideViewState: {
        activeTour: null,
        dontShowChecked: false,
        showWelcome: false,
        activeTourStopIndex: 0
    },

    printViewState: {
        isPrinting: false,                      // true when printing begins
        controlKeyDown: false                   // true if the control key is down while printing
    },

    loginViewState: {
        loginState: LoginState.NotLoggedIn
    },

    /**
     * Team setup picker view state
     */
    teamSetupPickerViewState: {
        isOnboarding: false,                                    // Set to true if this UX was launched via the onboarding flow. false if this is for later actions such as switching schedules or creating a new team.
        showScheduleTeamPickerEntrypoints: false,               // Indicates whether to show schedule team picker (aka team switcher) UX entrypoints
        showCreateNewScheduleTeamEntrypoints: false,            // Indicates whether to show create new schedule team UX entrypoints
        isOpen: false,                                          // Indicates if the UX is open
        teamSetupPickerMode: TeamSetupPickerMode.SwitchTeams,   // Mode for TeamSetupPicker
        isUserDismissable: false,                               // Set to true to allow the user to dismiss the UX
        isTeamPickerPanel: false                                // Set to true to use the team switcher in the panel as opposed to the modal dialog
    },

    /**
     * Show welcome - set to true to show the welcome to StaffHub dialog
     */
    showWelcome: false,

    /**
     * Deep Link state
     */
    deepLinkState: {
        isDeepLinkMode: false,                      // true if the application was launched from a deep link
        deepLinkSource: null,                       // source of the deeplink
        isActivityFeedDeepLinkMode: false           // true if the application was launched from an activity feed deep link
    },

    globalSpinnerMessage: "",                        // setting this string will show the global spinner with the message - clear the message to close global spinner

    themeName: ""                        // themeName from MSTeamsContext
};

export let appViewState = createStore<AppViewState>("appViewState", initialViewState);

/* Actions */
export { setCurrentlySharedSchedulePanelOpen } from "./actions/setCurrentlySharedSchedulePanelOpen";
export { setHoverColumnHidden } from "./actions/setHoverColumnHidden";

export { default as launchTeamSetupPicker } from "./mutatorActions/launchTeamSetupPicker";
export { default as closeTeamSetupPicker } from "./mutatorActions/closeTeamSetupPicker";
export { default as setShowTeamSetupPickerEntrypoints } from "./mutatorActions/setShowTeamSetupPickerEntrypoints";
export { default as setStaffHubPanelAlertMessageText } from "./mutatorActions/setStaffHubPanelAlertMessageText";
export { default as setThemeName } from "./mutatorActions/setThemeName";
export { default as setGlobalCommandBarRightItems } from "./mutatorActions/setGlobalCommandBarRightItems";
export { default as setErrorGettingSettings } from "../components/teamsettings/lib/mutatorActions/setErrorGettingSettings";

export { default as updateTeamSetupPickerEntrypoints } from "./actions/updateTeamSetupPickerEntrypoints";