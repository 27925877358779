import { action } from "satcheljs/lib/legacy";
import ChangeStore from "../store";
import { IChangeEntity } from "sh-models";
import { checkChangeForOldShiftIds } from "../utils/checkChangeForOldShiftIds";
import { InstrumentationService } from "sh-services";

/**
 * Helper action for mapping previous shift ids to new shift ids for all changes in the ChangeStore
 * This is done because when a shift is deleted and readded, it's ID will change, and all changes associated with that
 * shift need access to the new id
 * @param oldShiftIds - one to one mapping to newShiftIds
 * @param newShiftIds - one to one mapping with oldShiftIds
 */
export let mapOldShiftIdsToNewShiftIds = action("mapOldShiftIdsToNewShiftIds")(
    function mapOldShiftIdsToNewShiftIds(oldShiftIds: Array<string>, newShiftIds: Array<string>) {
        let marker = "mapOldShiftIdsToNew";
        marker = InstrumentationService.perfMarkerStart(marker);

        ChangeStore().undoStack.forEach((changeList: Array<IChangeEntity>) => {
            changeList.forEach((change: IChangeEntity) => {
                checkChangeForOldShiftIds(change, oldShiftIds, newShiftIds);
            });
        });

        ChangeStore().redoStack.forEach((changeList: Array<IChangeEntity>) => {
            changeList.forEach((change: IChangeEntity) => {
                checkChangeForOldShiftIds(change, oldShiftIds, newShiftIds);
            });
        });

        InstrumentationService.perfMarkerEnd(marker);
    });