import resetTeamData from "../actions/resetTeamData";
import resetTeamStore from "../actions/resetTeamStore";
import { orchestrator } from "satcheljs";
import { resetAvailabilityStore } from "sh-availability-store";
import { resetChangeStore } from "sh-change-store";
import { resetConflictStore, resetDismissedConflictStore, resetShiftsByMemberAndDateStore } from "sh-conflict-store";
import { resetMyShiftStore, resetShiftStore } from "sh-shift-store";
import { resetNoteStore } from "sh-note-store";
import { resetOpenShiftsStore } from "sh-open-shift-store";
import { resetSchedulesViewStateStore } from "sh-application/components/schedules/lib";
import { resetShiftRequestsStore } from "sh-shiftrequests-store";
import { resetTagsStore } from "sh-tag-store";
import { resetTeamSettingsStore } from "sh-teamsettings-store";
import { resetTimeClockEntryStore } from "sh-timeclock-store";
import { resetTimeOffReasonsStore } from "sh-timeoffreasons-store";
import { resetUniqueShiftStore } from "sh-uniqueshift-store";
import { resetUniqueSubshiftStore } from "sh-uniquesubshift-store";
import { resetTeamMemberPermissions, resetTeamPermissions } from "../../sh-team-permissions-store";

export default orchestrator(resetTeamData, async actionMessage => {
    resetShiftRequestsStore();
    resetShiftStore();
    resetMyShiftStore();
    resetOpenShiftsStore();
    resetNoteStore();
    resetAvailabilityStore();
    resetTagsStore();
    resetChangeStore();
    resetUniqueSubshiftStore();
    resetUniqueShiftStore();
    resetTeamSettingsStore();
    resetTimeOffReasonsStore();
    resetTeamStore();
    resetSchedulesViewStateStore();
    resetTimeClockEntryStore();
    resetConflictStore();
    resetDismissedConflictStore();
    resetShiftsByMemberAndDateStore();
    resetTeamPermissions();
    resetTeamMemberPermissions();
});