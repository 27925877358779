import CurrentTeamDataProvider from "sh-services/dataproviders/CurrentTeamDataProvider";
import RestClient from "sh-rest-client";
import { ITeamDatabase } from "sh-services/data/ITeamDatabase";
import { ITeamSettingEntity } from "sh-models";
import { setTeamSettings, TeamSettingsStore } from "sh-teamsettings-store";

/**
 * Team Setting Data Provider for the current team
 */
export class TeamSettingDataProvider extends CurrentTeamDataProvider<ITeamSettingEntity> {

    protected teamDatabase: ITeamDatabase;
    protected tenantId: string;
    protected teamSettingId: string;

    constructor(teamDatabase: ITeamDatabase, tenantId: string, teamId: string, teamSettingId: string) {
        super(teamId);
        this.teamDatabase = teamDatabase;
        this.tenantId = tenantId;
        this.teamSettingId = teamSettingId;
    }

    /**
     * Return data if it's found in memory (otherwise return undefined)
     */
    async getDataFromMemoryForCurrentTeam() {
        for (const setting of Object.values(TeamSettingsStore())) {
            if (setting.id === this.teamSettingId) {
                return setting as ITeamSettingEntity;
            }
        }
        return undefined;
    }

    /**
     * Return data if it's found in the database (otherwise return undefined)
     */
    async getDataFromDatabase() {
        return await this.teamDatabase.getTeamSetting(this.teamId, this.teamSettingId);
    }

    /**
     * Make an HTTP request to fetch the data from the network
     */
    async getDataFromNetwork(): Promise<ITeamSettingEntity> {
        throw new Error("TeamSettingDataProvider.getDataFromNetwork not implemented");
    }

    /**
     * Set data in memory
     */
    async setDataInMemoryForCurrentTeam(data: ITeamSettingEntity) {
        setTeamSettings([data]);
    }

    /**
     * Set data in the database
     */
    async setDataInDatabase(data: ITeamSettingEntity) {
        if (data) {
            return await this.teamDatabase.setTeamSettings([data]);
        }
    }

    /**
     * Make a network call to update the data
     */
    async saveDataToNetwork(data: ITeamSettingEntity): Promise<ITeamSettingEntity> {
        return await RestClient.setTeamSettingsItem(this.tenantId, this.teamId, data);

    }
}