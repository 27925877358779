import { mutator } from "satcheljs";

import { clearFilters } from "../..";
import { ClearFiltersActionMessage } from "../../actions/filter/ClearFiltersActionMessage";
import schedulesViewStateStore from "../../store/store";

/**
 * Clears the schedule view filters.
 */
export const clearFiltersMutator = mutator<ClearFiltersActionMessage, void>(clearFilters, ({ shouldClearLabel }) => {
    schedulesViewStateStore().filteredMembers.clear();
    schedulesViewStateStore().filteredTags.clear();
    schedulesViewStateStore().selectedShiftFilters.clear();
    schedulesViewStateStore().showFilterError = false;

    if (shouldClearLabel) {
        schedulesViewStateStore().filterHeaderLabel = "";
    }
});
