import { conflictRequestStore } from "..";
import { IBaseShiftEntity } from "sh-models";
import { mutatorAction } from "satcheljs";

export default mutatorAction('SET_REQUEST_SHIFT_IN_CONFLICT_STORE', function setRequestShiftInConflictStore(requestShiftId: string, requestShift: IBaseShiftEntity) {
    if (!requestShiftId && !requestShift) {
        return;
    }
    if (!conflictRequestStore().requestShift.has(requestShiftId)) {
        conflictRequestStore().requestShift.set(requestShiftId, requestShift);
    }
});