import { IBaseShiftEntity, ICopyScheduleJobEntity, IBaseConflictEntity } from "sh-models";

/**
 * These enum strings are used as the instrumentation strings as well
 */
export enum ChangeSource {
    ShiftPanel = "ShiftPanel",
    TimeOffPanel = "TimeOffPanel",
    ShiftLookup = "ShiftLookup",
    Paste = "Paste",
    CopyDateRange = "CopyDateRange",
    ContextualMenu = "ContextualMenu",
    Default = "Default",
    Keyboard = "Keyboard",
    PasteActivity = "PasteActivity",
    DeleteActivity = "DeleteActivity",
    DragAndDrop = "DragAndDrop",
    Undo = "Undo",
    Redo = "Redo",
    ConflictDismsiss = "ConflictDismiss"
}

/**
 * Interface that represents Change entity model
 */
export default interface IChangeEntity {
    /* previous shifts are the value of the shifts before the change. Can be null for added shifts. */
    previousShifts: Array<IBaseShiftEntity>;
    /* resulting shifts are the value of the shifts after the change.  Can be null for deleted shifs */
    resultingShifts: Array<IBaseShiftEntity>;
    /* This is to distinguish what caused the change.  It is used for instrumentation and the undo button. */
    changeSource: ChangeSource;
    /* This function returns a function which returns a promise that reverts this change*/
    getRevertTask?: Function;
    /* These are the values of the copied schedule that is being changed. */
    copiedSchedule?: ICopyScheduleJobEntity;
    /* previous conflict that was dismissed */
    previousConflict?: IBaseConflictEntity;
}