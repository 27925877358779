import DateUtils from "sh-application/utility/DateUtils";
import TagUtils from "sh-application/utility/TagUtils";
import { DataFilter, ECSConfigKey, ECSConfigService } from "sh-services";
import { DataInDateRangeDataProvider } from "./DataInDateRangeDataProvider";
import { IDataInDateRangeWithSyncState, IDataWithSyncState } from "sh-models";
import { INoteDatabase } from "../data/INoteDatabase";
import { INoteEntity, IOpenShiftEntity, IShiftEntity } from "sh-models";
import { IOpenShiftDatabase } from "../data/IOpenShiftDatabase";
import { IShiftDatabase } from "../data/IShiftDatabase";
import { MobxUtils } from "sh-application";
import { Moment } from "moment";
import {
    ProgressiveStore,
    resetStore,
    updateNotes,
    updateOpenShifts,
    updateShifts
    } from "sh-stores/sh-progressive-store";
import { ShiftStoreTypes } from "sh-application/../StaffHubConstants";
import { TeamStore } from "sh-team-store";
import { trace } from "owa-trace";

const ALL_OPEN_SHIFTS = "all";

/**
 * Shifts/Note/OpenShifts in DateRange Data Provider for the current team
 */
export class ProgressiveDataInDateRangeDataProvider extends DataInDateRangeDataProvider {

    private static _loadedFilteredData: Set<number> = new Set<number>();    // Set of dataFilters and their fetch ranges that have been loaded
    private currentFilter: DataFilter = null;                              // the current data filter
    private _index: number = -1;

    private notesFromDb: INoteEntity[] = [];

    private hasReadFromDb: boolean = false;
    private shiftsInSync = false;
    private openShiftsInSync = false;
    private notesInSync = false;

    private shiftsByTagMap: Map<string, IShiftEntity[]>;
    private openShiftsByTagMap: Map<string, IOpenShiftEntity[]>;
    private shiftsByMemberMap: Map<string, IShiftEntity[]>;
    private openShiftsByMemberMap: Map<string, IOpenShiftEntity[]>;

    constructor(index: number, shiftDatabase: IShiftDatabase, noteDatabase: INoteDatabase, openShiftDatabase: IOpenShiftDatabase, tenantId: string, teamId: string, sessionId: string, fetchStartTime: Moment, fetchEndTime: Moment, dontClearCache: boolean) {
        super(shiftDatabase, noteDatabase, openShiftDatabase, tenantId, teamId, sessionId, fetchStartTime, fetchEndTime, dontClearCache, ShiftStoreTypes.ShiftStore);
        this._index = index;

        // If there is no sync state (first render or team switch), we should clear _loadedFilteredData.
        // Check if the new fetch range is contiguous within our current cache range. If it is not,
        // we need to reset _loadedFilteredData.
        const syncStateStartTime = this.shiftsDateRangeDataProvider.getSyncCacheStartTimeFromMemory();
        const syncStateEndTime = this.shiftsDateRangeDataProvider.getSyncStateEndTimeFromMemory();

        if ((!syncStateStartTime && !syncStateEndTime)
            || syncStateStartTime && syncStateEndTime && !DateUtils.isRangeContiguous(syncStateStartTime, syncStateEndTime, this.fetchStartTime, this.fetchEndTime)) {
            this.clearFiltersLoadedInMemory();
        }

        resetStore();
    }

    /**
     * Returns the index of this instance of ProgressiveDataInDateRangeDataProvider. The index is passed in
     * during object creation.
     */
    public get index() {
        return this._index;
    }

    // https://stackoverflow.com/questions/7616461/generate-a-hash-from-string-in-javascript
    public hash(val: string): number {
        return val.split('').reduce((a, b) => {
            a = ((a << 5) - a) + b.charCodeAt(0);
            return a & a;
        }, 0);
    }

    /**
     * Creates a new unique key using the current fetch range and the information in the dataFilter.
     * @param {DataFilter} dataFilter filter for fetched data.
     */
    private getFilterAndRangeKey(dataFilter: DataFilter) {
        let filterByRangeString =  `${this.fetchStartTime.valueOf()}+${this.fetchEndTime.valueOf()}`;
        if (dataFilter) {
            if (dataFilter.tagIds) {
                filterByRangeString = `${filterByRangeString}+${dataFilter.tagIds.toString()}`;
            }
            if (dataFilter.memberIds) {
                filterByRangeString += `+${dataFilter.memberIds.toString()}`;
            }
        }
        return this.hash(filterByRangeString);
    }

    /**
     * Clears the global _loadedFilteredData context. Should be called when switching teams or during unit tests to
     * reset for each test.
     */
    public clearFiltersLoadedInMemory() {
        ProgressiveDataInDateRangeDataProvider._loadedFilteredData.clear();
    }

    /**
     * Generates a unique key for the current fetch range and passed in dataFilter and then stores it in the
     * global context of ProgressiveDataInDateRangeDataProvider.
     * @param {DataFilter} dataFilter filter for fetched data.
     */
    private setFilterLoadedInMemory(dataFilter: DataFilter) {
        ProgressiveDataInDateRangeDataProvider._loadedFilteredData.add(this.getFilterAndRangeKey(dataFilter));
    }

    /**
     * Returns true if the passed in dataFilter has already been loaded into memory. This is used to determine
     * if a specific filter within a given range has already been loaded. We can't just rely on looking at the
     * cache range like we did when not progressively rendering.
     * @param {DataFilter} dataFilter filter for fetched data.
     */
    public isFilterLoadedInMemory(dataFilter: DataFilter) {
        return ProgressiveDataInDateRangeDataProvider._loadedFilteredData.has(this.getFilterAndRangeKey(dataFilter));
    }

    /**
     * Sets the current data filter that is being used for the progressive load.
     * @param {DataFilter} dataFilter filter for fetched data.
     */
    private setCurrentFilter(dataFilter: DataFilter) {
        this.currentFilter = dataFilter;
    }

    /**
     * Keeps track of all items that have been progressively loaded by this data provider. This is used to correctly
     * handle hard deletes once all items have been loaded and to write all the loaded items back into the local DB.
     * @param data - data (with sync state) that has been loaded either from memory or is being written to memory after
     * loading from the DB or the Network.
     */
    private logProgressiveItems(data: IDataInDateRangeWithSyncState) {
        if (data) {
            const { shiftsWithSyncState, notesWithSyncState, openShiftsWithSyncState } = data;

            if (shiftsWithSyncState && shiftsWithSyncState.data && shiftsWithSyncState.data.length) {
                updateShifts(shiftsWithSyncState.data);
            }
            if (openShiftsWithSyncState && openShiftsWithSyncState.data && openShiftsWithSyncState.data.length) {
                updateOpenShifts(openShiftsWithSyncState.data);
            }
            if (notesWithSyncState && notesWithSyncState.data && notesWithSyncState.data.length) {
                updateNotes(notesWithSyncState.data);
            }

            // always update the item data providers with the list of progressive items because the stores could have
            // been updated asynchronously due to the user making updates in the UI or sync mechanism. This will ensure
            // that once progressive rendering is completed, the item data providers will know of all items that were
            // either loaded from the network or local db and those items which have been modified (added/edited/deleted).
            this.shiftsDateRangeDataProvider.setProgressiveItems(MobxUtils.MapKeysToArray(ProgressiveStore().shifts));
            this.openShiftsInDateRangeDataProvider.setProgressiveItems(MobxUtils.MapKeysToArray(ProgressiveStore().openShifts));
            this.notesDateRangeDataProvider.setProgressiveItems(MobxUtils.MapKeysToArray(ProgressiveStore().notes));
        }
    }

    /**
     * If we are filtering by tags and fetching ungrouped shifts, add the DEFAULT_TAG_ID tag to the dataFilter. The DEFAULT_TAG_ID
     * is our client side virtual tag used to place ungrouped shifts into in the UI.
     * @param dataFilter filter for fetched data
     */
    maybeAddDefaultTagToFilter(dataFilter: DataFilter) {
        // if ungrouped shifts are being requested, we must add the DEFAULT_TAG_ID to the filter
        if (dataFilter && dataFilter.tagIds && dataFilter.networkFetchOptions && dataFilter.networkFetchOptions.includeUngroupedShifts) {
            if (!dataFilter.tagIds.includes(TagUtils.DEFAULT_TAG_ID)) {
                dataFilter.tagIds.push(TagUtils.DEFAULT_TAG_ID);
            }
        }
    }

    /**
     * Return data for the current team if it's found in memory (otherwise return undefined)
     * @param {DataFilter} dataFilter (optional) filter for fetched data. Only data within the passed filter is fetched.
     * @param {boolean} isInSync When filtering, pass true if this is the final data set and all other sets have been fetched.
     */
    async getDataFromMemoryForCurrentTeam(dataFilter?: DataFilter, isInSync?: boolean): Promise<IDataInDateRangeWithSyncState> {
        if (dataFilter && (dataFilter.tagIds || dataFilter.memberIds)) {
            this.maybeAddDefaultTagToFilter(dataFilter);
            this.setCurrentFilter(dataFilter);

            // check if the tag has already been loaded into memory - return undefined if it hasn't
            if (this.isFilterLoadedInMemory(dataFilter)) {
                const results = await super.getDataFromMemoryForCurrentTeam(dataFilter, isInSync);

                // we have to make sure we keep track of all items loaded within this provider so that once
                // all data is loaded (we are in sync), we correctly hard delete items. If we end up loading
                // some items from memory and others from the network, there will be a mismatch and items will
                // incorrectly get marked as deleted.
                this.logProgressiveItems(results);

                return results;
            }
        }

        return undefined;
    }

    /**
     * Return data if found in memory store for the current team
     * If this dataprovider is not targeted for the current team, then no data is returned.
     * @param {DataFilter} dataFilter (optional) filter for fetched data. Only data within the passed filter is fetched.
     * @param {boolean} isInSync When filtering, pass true if this is the final data set and all other sets have been fetched.
     */
    async getDataFromMemory(dataFilter?: DataFilter, isInSync?: boolean): Promise<IDataInDateRangeWithSyncState> {
        if (this.isDataProviderForCurrentTeam()) {
            return this.getDataFromMemoryForCurrentTeam(dataFilter, isInSync);
        } else {
            trace.info(`${this.constructor.name} getDataFromMemory: Current team is different. TeamStore().teamId: ${TeamStore().teamId}, teamId: ${this.teamId}`);
            return undefined;
        }
    }

    /**
     * Make an HTTP request to fetch the data from the network
     * @param {DataFilter} dataFilter (optional) filter for fetched data. Only data within the passed filter is fetched.
     * @param {boolean} isInSync When filtering, pass true if this is the final data set and all other sets have been fetched.
     */
    async getDataFromNetwork(dataFilter?: DataFilter, isInSync?: boolean): Promise<IDataInDateRangeWithSyncState> {
        if (dataFilter && (dataFilter.tagIds || dataFilter.memberIds) && this.isDataProviderForCurrentTeam()) {
            this.maybeAddDefaultTagToFilter(dataFilter);
            this.setCurrentFilter(dataFilter);
            return super.getDataFromNetwork(dataFilter, isInSync);
        }

        return undefined;
    }

    /**
     * Sorts and maps all the data from the local db so it can be quickly retrieved with different data filters.
     * @param data data loaded from the local db
     * @param {DataFilter} dataFilter filter used to sort data
     */
    public sortDataFromDatabase(data: IDataInDateRangeWithSyncState, dataFilter: DataFilter) {
        if (data && dataFilter && (dataFilter.tagIds || dataFilter.memberIds)) {
            const { notesWithSyncState, shiftsWithSyncState, openShiftsWithSyncState } = data;

            this.sortShiftsFromDatabase(dataFilter, shiftsWithSyncState);
            this.sortOpenShiftsFromDatabase(dataFilter, openShiftsWithSyncState);
            this.sortNotesFromDatabase(notesWithSyncState);
        }
    }

    /**
     * Sort and map the shifts from the local db according to the current dataFilter.
     * @param {DataFilter} dataFilter filter used to sort data
     * @param shiftsWithSyncState shift data fectched from local db
     */
    private sortShiftsFromDatabase(dataFilter: DataFilter, shiftsWithSyncState: IDataWithSyncState<IShiftEntity[]>) {
        const filterTags = dataFilter.tagIds;
        const filterMembers = dataFilter.memberIds;

        this.shiftsByTagMap = filterTags ? new Map<string, IShiftEntity[]>() : null;
        this.shiftsByMemberMap = filterMembers ? new Map<string, IShiftEntity[]>() : null;

        if (shiftsWithSyncState && shiftsWithSyncState.data) {
            // for each shift, sort them into maps by tagId and memberId
            shiftsWithSyncState.data.forEach(shift => {
                const memberId = shift.memberId;
                if (filterTags) {
                    if (shift.tagIds && shift.tagIds.length) {
                        shift.tagIds.forEach(tagId => {
                            let shiftArray = this.shiftsByTagMap.get(tagId);
                            if (!shiftArray) {
                                // this tag is not yet in the map, set it with a blank array
                                shiftArray = [];
                                this.shiftsByTagMap.set(tagId, shiftArray);
                            }
                            // add to the correct array
                            shiftArray.push(shift);
                        });
                    } else {
                        // ungrouped shift
                        let shiftArray = this.shiftsByTagMap.get(TagUtils.DEFAULT_TAG_ID);
                        if (!shiftArray) {
                            // this tag is not yet in the map, set it with a blank array
                            shiftArray = [];
                            this.shiftsByTagMap.set(TagUtils.DEFAULT_TAG_ID, shiftArray);
                        }
                        // add to the correct array
                        shiftArray.push(shift);
                    }
                } else if (filterMembers && memberId) {
                    let memberArray = this.shiftsByMemberMap.get(memberId);
                    if (!memberArray) {
                        // this member is not yet in the map, set it with a blank array
                        memberArray = [];
                        this.shiftsByMemberMap.set(memberId, memberArray);
                    }
                    // add to the correct array
                    memberArray.push(shift);
                }
            });
        }
    }

    /**
     * Sort and map the open shifts from the local db according to the current dataFilter.
     * @param {DataFilter} dataFilter filter used to sort data
     * @param openShiftsWithSyncState open shift data fectched from local db
     */
    private sortOpenShiftsFromDatabase(dataFilter: DataFilter, openShiftsWithSyncState: IDataWithSyncState<IOpenShiftEntity[]>) {
        const filterTags = dataFilter.tagIds;
        const filterMembers = dataFilter.memberIds;

        this.openShiftsByTagMap = filterTags ? new Map<string, IOpenShiftEntity[]>() : null;
        this.openShiftsByMemberMap = filterMembers ? new Map<string, IOpenShiftEntity[]>() : null;

        if (openShiftsWithSyncState && openShiftsWithSyncState.data) {
            // for each open shift, sort them into a map with the tagId as the key
            openShiftsWithSyncState.data.forEach(openShift => {
                if (filterTags) {
                    if (openShift.tagIds && openShift.tagIds.length) {
                        openShift.tagIds.forEach(tagId => {
                            let openShiftArray = this.openShiftsByTagMap.get(tagId);
                            if (!openShiftArray) {
                                // this tag is not yet in the map, set it with a blank array
                                openShiftArray = [];
                                this.openShiftsByTagMap.set(tagId, openShiftArray);
                            }
                            // add to the correct array
                            openShiftArray.push(openShift);
                        });
                    } else {
                        // ungrouped open shift
                        let openShiftArray = this.openShiftsByTagMap.get(TagUtils.DEFAULT_TAG_ID);
                        if (!openShiftArray) {
                            // this tag is not yet in the map, set it with a blank array
                            openShiftArray = [];
                            this.openShiftsByTagMap.set(TagUtils.DEFAULT_TAG_ID, openShiftArray);
                        }
                        // add to the correct array
                        openShiftArray.push(openShift);
                    }
                } else if (filterMembers) {
                    // open shifts aren't filtered by member id so we need to include all the data
                    let openShiftArray = this.openShiftsByMemberMap.get(ALL_OPEN_SHIFTS);
                    if (!openShiftArray) {
                        openShiftArray = [];
                        this.openShiftsByMemberMap.set(ALL_OPEN_SHIFTS, openShiftArray);
                    }
                    // add to the correct array
                    openShiftArray.push(openShift);
                }
            });
        }
    }

    /**
     * Stores all the notes from the db into a class array.
     * @param notesWithSyncState notes data fectched from local db
     */
    private sortNotesFromDatabase(notesWithSyncState: IDataWithSyncState<INoteEntity[]>) {
        if (notesWithSyncState && notesWithSyncState.data) {
            notesWithSyncState.data.forEach(note => {
                this.notesFromDb.push(note);
            });
        }
    }

    /**
     * Returns filtered shift data that was loaded from the local db. The data must have previously been
     * loaded and sorted.
     * @param {DataFilter} dataFilter data filter for the data you want returned
     */
    public getFilteredShiftsDataFromDatabase(dataFilter: DataFilter): IShiftEntity[] {
        let filteredShiftsData: IShiftEntity[] = [];

        if (this.shiftsByTagMap || this.shiftsByMemberMap) {
            const filterTags = dataFilter.tagIds;
            const filterMembers = dataFilter.memberIds;

            if (filterTags) {
                for (let tagIndex = 0; tagIndex < filterTags.length; tagIndex++) {
                    const shiftsForTag = this.shiftsByTagMap.get(filterTags[tagIndex]);
                    if (shiftsForTag && shiftsForTag.length) {
                        filteredShiftsData = filteredShiftsData.concat(shiftsForTag);
                    }
                }
            } else if (filterMembers) {
                for (let memberIndex = 0; memberIndex < filterMembers.length; memberIndex++) {
                    const shiftsForMember = this.shiftsByMemberMap.get(filterMembers[memberIndex]);
                    if (shiftsForMember && shiftsForMember.length) {
                        filteredShiftsData = filteredShiftsData.concat(shiftsForMember);
                    }
                }
            }
        }

        return filteredShiftsData;
    }

    /**
     * Returns filtered open shift data that was loaded from the local db. The data must have previously been
     * loaded and sorted.
     * @param {DataFilter} dataFilter data filter for the data you want returned
     */
    public getFilteredOpenShiftsDataFromDatabase(dataFilter: DataFilter): IOpenShiftEntity[] {
        let filteredOpenShiftsData: IOpenShiftEntity[] = [];

        if (this.openShiftsByTagMap || this.openShiftsByMemberMap) {
            const filterTags = dataFilter.tagIds;
            const filterMembers = dataFilter.memberIds;

            if (filterTags) {
                for (let tagIndex = 0; tagIndex < filterTags.length; tagIndex++) {
                    const openShiftsForTag = this.openShiftsByTagMap.get(filterTags[tagIndex]);
                    if (openShiftsForTag && openShiftsForTag.length) {
                        filteredOpenShiftsData = filteredOpenShiftsData.concat(openShiftsForTag);
                    }
                }
            } else if (filterMembers) {
                // open shifts aren't filtered by member so include all the data
                const openShiftsForMember = this.openShiftsByMemberMap.get(ALL_OPEN_SHIFTS);
                if (openShiftsForMember) {
                    filteredOpenShiftsData = filteredOpenShiftsData.concat(openShiftsForMember);
                }
            }
        }

        return filteredOpenShiftsData;
    }

    /**
     * Return data if it's found in the local indexed DB (otherwise return undefined)
     * @param {DataFilter} dataFilter (optional) filter for fetched data. Only data within the passed filter is fetched.
     * @param {boolean} isInSync When filtering, pass true if this is the final data set and all other sets have been fetched.
     */
    async getDataFromDatabase(dataFilter?: DataFilter, isInSync?: boolean): Promise<IDataInDateRangeWithSyncState> {
        if (dataFilter && (dataFilter.tagIds || dataFilter.memberIds) && this.isDataProviderForCurrentTeam()) {
            this.maybeAddDefaultTagToFilter(dataFilter);
            this.setCurrentFilter(dataFilter);
        } else {
            return undefined;
        }

        // if we have not yet loaded the items from the local db, do so now and then sort and cache
        // the results. We will use the sorted results to provide the data for each progressive render loop.
        if (!this.hasReadFromDb) {
            this.hasReadFromDb = true;

            const startTime = new Date().getTime();
            // we only want to load the data from the DB once
            const dbData = await super.getDataFromDatabase();

            this.shiftsInSync = dbData && dbData.shiftsWithSyncState && dbData.shiftsWithSyncState.isDataInSync;
            this.openShiftsInSync = dbData && dbData.openShiftsWithSyncState && dbData.openShiftsWithSyncState.isDataInSync;
            this.notesInSync = dbData && dbData.notesWithSyncState && dbData.notesWithSyncState.isDataInSync;

            if (dbData) {
                trace.info("PR: db read: " + ((new Date().getTime() - startTime) / 1000) + " seconds");
                this.sortDataFromDatabase(dbData, dataFilter);
            }
        }

        if ((this.shiftsByTagMap && this.openShiftsByTagMap) || (this.shiftsByMemberMap && this.openShiftsByMemberMap)) {
            return {
                shiftsWithSyncState: {
                    data: this.getFilteredShiftsDataFromDatabase(dataFilter),
                    isDataInSync: isInSync ? this.shiftsInSync : false,     // we use this.shiftsInSync because it has additional important logic to determine sync state
                    isPartialInSync: !isInSync
                },
                openShiftsWithSyncState: {
                    data: this.getFilteredOpenShiftsDataFromDatabase(dataFilter),
                    isDataInSync: isInSync ? this.openShiftsInSync : false, // we use this.openShiftsInSync because it has additional important logic to determine sync state
                    isPartialInSync: !isInSync
                },
                notesWithSyncState: {
                    data: this.notesFromDb,                                 // we always send all the notes as they are not filtered by tag or member
                    isDataInSync: this.notesInSync,
                    isPartialInSync: false
                }
            };
        }

        return undefined;
    }

    /**
     * Set data in memory
     */
    async setDataInMemoryForCurrentTeam(data: IDataInDateRangeWithSyncState) {
        this.logProgressiveItems(data);

        /**
         * if data is in sync
         * at this point we need to have the all the dateRangeDataProviders know about all the items we are
         * working with. That includes items that have been progressively loaded and items that have been
         * modified (added/deleted/updated). This is needed because when we we call setDataInMemoryForCurrentTeam,
         * it will attempt to hard delete items that it doesn't know about.
         */
        await super.setDataInMemoryForCurrentTeam(data);

        this.setFilterLoadedInMemory(this.currentFilter);
    }

    async setDataInDatabase(data: IDataInDateRangeWithSyncState) {
        if (data) {
            const { shiftsWithSyncState, notesWithSyncState, openShiftsWithSyncState } = data;

            /*
                DATASERVICE
                    updateDataInCache
                        - provider.setDataInMemory
                        - setTimeout -> provider.setDataInDataBase
                            - DataInDateRangeDateProvider.setDataInDatabase
                                - shiftsProvider.setDataInDataBase
                                - openShifts.setDataInDataBase
                                - notes.setDataInDataBase
                                    - ** for PR we SHOULD NOT write to DB until we have all data in sync
                                    - ** need to keep track of all items progressively fetched so they can be written to DB once in sync
            */

            if (shiftsWithSyncState.isDataInSync || notesWithSyncState.isDataInSync || openShiftsWithSyncState.isDataInSync) {
                // Convert the maps into arrays and store in local variables before calling any of the async setDataInDatabase() methods.
                // It is possible for the data in the ProgressiveStore to be cleared while waiting for the promises to complete.
                // livesite bug 1193767
                const shiftsData: IShiftEntity[] = shiftsWithSyncState.isDataInSync ? MobxUtils.MapToArray(ProgressiveStore().shifts) : [];
                const notesData: INoteEntity[] = notesWithSyncState.isDataInSync ? MobxUtils.MapToArray(ProgressiveStore().notes) : [];
                const openShiftsData: IOpenShiftEntity[] = openShiftsWithSyncState.isDataInSync ? MobxUtils.MapToArray(ProgressiveStore().openShifts) : [];

                if (shiftsWithSyncState.isDataInSync) {
                    trace.info("PR: **** SHIFTS IN SYNC - WRITING TO LOCAL DB ****");

                    // set shifts in db
                    await this.shiftsDateRangeDataProvider.setDataInDatabase({
                        data: shiftsData,
                        isDataInSync: true,
                        isPartialInSync: false
                    });
                }

                if (notesWithSyncState.isDataInSync) {
                    trace.info("PR: **** NOTES IN SYNC - WRITING TO LOCAL DB ****");

                    // set notes in db
                    await this.notesDateRangeDataProvider.setDataInDatabase({
                        data: notesData,
                        isDataInSync: true,
                        isPartialInSync: false
                    });
                }

                if (openShiftsWithSyncState.isDataInSync) {
                    trace.info("PR: **** OPEN SHIFTS IN SYNC - WRITING TO LOCAL DB ****");

                    // set openshifts in db
                    await this.openShiftsInDateRangeDataProvider.setDataInDatabase({
                        data: openShiftsData,
                        isDataInSync: true,
                        isPartialInSync: false
                    });
                }
            }
        }
    }

    /**
     * Whether to skip refreshing data from network if it was only in the database and not in memory.
     * This method gets called when we don't find the data in memory, and find something in the database.
     * Because we can't always trust that the data from the database was in-sync and not missing changes from the service,
     * this method allows the provider to inspect the state of data from the db and
     * determine if we need to make a network call to refresh this data asynchronously.
     */
    public async skipRefreshFromNetworkIfNotInMemory(dataFromDatabase: IDataInDateRangeWithSyncState): Promise<boolean> {
        if (!ECSConfigService.isECSFeatureEnabled(ECSConfigKey.EnableSyncStateInIndexedDb)) {
            // force refresh from network if the sync state isn't allowed to be stored in IndexedDb
            return false;
        }

        // check if all the items are in sync
        const shiftsCanSkipNetworkRefresh = dataFromDatabase && dataFromDatabase.shiftsWithSyncState && (dataFromDatabase.shiftsWithSyncState.isDataInSync || dataFromDatabase.shiftsWithSyncState.isPartialInSync);
        const notesCanSkipNetworkRefresh = dataFromDatabase && dataFromDatabase.notesWithSyncState && (dataFromDatabase.notesWithSyncState.isDataInSync || dataFromDatabase.notesWithSyncState.isPartialInSync);
        const openShiftsCanSkipNetworkRefresh = dataFromDatabase && dataFromDatabase.openShiftsWithSyncState && (dataFromDatabase.openShiftsWithSyncState.isDataInSync || dataFromDatabase.openShiftsWithSyncState.isPartialInSync);

        return shiftsCanSkipNetworkRefresh && notesCanSkipNetworkRefresh && openShiftsCanSkipNetworkRefresh;
    }

    // the following XXXIdCount getters are for unit testing
    public get shiftIdCount() {
        return ProgressiveStore().shifts.size;
    }

    public get shiftProviderIdCount() {
        return this.shiftsDateRangeDataProvider.progressiveItemCount;
    }

    public get openShiftIdCount() {
        return ProgressiveStore().openShifts.size;
    }

    public get openShiftProviderIdCount() {
        return this.openShiftsInDateRangeDataProvider.progressiveItemCount;
    }

    public get noteIdCount() {
        return ProgressiveStore().notes.size;
    }

    public get noteProviderIdCount() {
        return this.notesDateRangeDataProvider.progressiveItemCount;
    }
}