import { action } from "satcheljs";
import { IShiftEntity } from "sh-models";
import { ShiftStoreTypes } from "sh-application/../StaffHubConstants";

const updateShifts = action(
    "UPDATE_SHIFTS",
    (
        shiftsUpdated: IShiftEntity[],
        isOptimisticUpdate: boolean,
        shiftStoreType: ShiftStoreTypes
    ) => ({
        shiftsUpdated: shiftsUpdated || [],
        isOptimisticUpdate: isOptimisticUpdate,
        shiftStoreType: shiftStoreType
    })
);

export default updateShifts;
