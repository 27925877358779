import * as React from "react";
import DateUtils from "sh-application/utility/DateUtils";
import KeyboardUtils from "sh-application/utility/KeyboardUtils";
import StringsStore from "sh-strings/store";
import { getGenericEventPropertiesObject } from "sh-instrumentation";
import { IconButton } from "@fluentui/react";
import { InstrumentationService } from "sh-services";
import { Moment } from "moment";
import { observer } from "mobx-react";
import { ScheduleCalendarType, ScheduleCalendarTypes } from "sh-models";

const classNames = require("classnames/bind");
const styles = require("./DateRangeArrows.scss");

export interface DateRangeArrowsProps {
    // Whether the user can press the arrow to go further back
    disablePreviousDateArrow?: boolean;
    // The schedule calendar type : Day/Week/Month
    scheduleCalendarType: ScheduleCalendarType;
    // The callback function when user clicks left arrow for previous date range
    onPreviousDateRangeClicked: Function;
    // The callback function when user clicks right arrow for next date range
    onNextDateRangeClicked: Function;
    // Override default style class for arrow icon (.dateRangeArrow)
    arrowStyle?: string;
    id?: string;
}

/**
 * UI control to change date range. Renders left and right arrow buttons.
 */
@observer
export default class DateRangeArrows extends React.Component<DateRangeArrowsProps, {}> {
    private _schedulesStrings: Map<string, string>;

    constructor(props: DateRangeArrowsProps) {
        super(props);
        this._schedulesStrings = StringsStore().registeredStringModules.get("schedulePage").strings;
    }

    /**
     * Calculate the next date range relative to the specified date
     * @param currentDateRangeDate current date range start datetime
     * @param calendarType calendar type for date navigation
     * @param numDateRangeUnits number of date range units to add/subtract
     */
    public static calculateNextDateRange(currentDateRangeDate: Moment, calendarType: ScheduleCalendarType, numDateRangeUnits: number): Moment {
        let newDateRangeStart: Moment = null;
        if (calendarType && currentDateRangeDate) {
            let units: any = null;
            switch (calendarType) {
                case ScheduleCalendarTypes.Day:
                    units = 'days';
                    break;
                case ScheduleCalendarTypes.Week:
                    units = 'weeks';
                    break;
                case ScheduleCalendarTypes.Month:
                    units = 'months';
                    break;
            }
            if (units) {
                newDateRangeStart = DateUtils.startOfIncrementedDayTimeUnit(currentDateRangeDate, numDateRangeUnits, units);
            }
        }
        return newDateRangeStart;
    }

    /**
     * Gets the aria text for the arrow button for navigating to previous date/week/month
     */
    private getPreviousDateRangeAriaForOverviewtype(): string {
        switch (this.props.scheduleCalendarType) {
            case ScheduleCalendarTypes.Day:
                return this._schedulesStrings.get("goToPreviousDayAria");
            case ScheduleCalendarTypes.Week:
                return this._schedulesStrings.get("goToPreviousWeekAria");
            case ScheduleCalendarTypes.Month:
                return this._schedulesStrings.get("goToPreviousMonthAria");
        }
    }

    /**
     * Gets the aria text for the arrow button for navigating to next date/week/month
     */
    private getNextDateRangeAriaForOverviewtype(): string {
        switch (this.props.scheduleCalendarType) {
            case ScheduleCalendarTypes.Day:
                return this._schedulesStrings.get("goToNextDayAria");
            case ScheduleCalendarTypes.Week:
                return this._schedulesStrings.get("goToNextWeekAria");
            case ScheduleCalendarTypes.Month:
                return this._schedulesStrings.get("goToNextMonthAria");
        }
    }

    /**
     * For accessiblity support, on enter key press trigger previous date range selection.
     * @param keyPressEvent The keyboard event which has the key name which was pressed.
     */
    previousDateRangeKeyPress = (keyPressEvent: React.KeyboardEvent<HTMLDivElement>) => {
        if (KeyboardUtils.isActionKeyPressed(keyPressEvent)) {
            InstrumentationService.logEvent(InstrumentationService.events.ScheduleNavigation,
                [getGenericEventPropertiesObject(
                    InstrumentationService.properties.TypeOfNavigation,
                    InstrumentationService.values.PreviousView
                )]);
        }
    }

    /**
     * For accessiblity support, on enter key press trigger next date range selection.
     * @param keyPressEvent The keyboard event which has the key name which was pressed.
     */
    nextDateRangeKeyPress = (keyPressEvent: React.KeyboardEvent<HTMLDivElement>) => {
        if (KeyboardUtils.isActionKeyPressed(keyPressEvent)) {
            InstrumentationService.logEvent(InstrumentationService.events.ScheduleNavigation,
                [getGenericEventPropertiesObject(
                    InstrumentationService.properties.TypeOfNavigation,
                    InstrumentationService.values.NextView
                )]);
        }
    }

    previousDateRangeClicked = () => {
        if (this.props.onPreviousDateRangeClicked) {
            InstrumentationService.logEvent(InstrumentationService.events.ScheduleNavigation,
                [getGenericEventPropertiesObject(
                    InstrumentationService.properties.TypeOfNavigation,
                    InstrumentationService.values.PreviousView
                )]);
            this.props.onPreviousDateRangeClicked();
        }
    }

    nextDateRangeClicked = () => {
        if (this.props.onNextDateRangeClicked) {
            InstrumentationService.logEvent(InstrumentationService.events.ScheduleNavigation,
                [getGenericEventPropertiesObject(
                    InstrumentationService.properties.TypeOfNavigation,
                    InstrumentationService.values.NextView
                )]);
            this.props.onNextDateRangeClicked();
        }
    }

    render() {
        const arrowStyle = this.props.arrowStyle || styles.dateRangeArrow;
        return (
            // TODO (flwsPrivacySettings): fix CSS for buttons
            <div id={ this.props.id } className={ classNames(styles.dateRangeArrows, "no-print") }>
                <IconButton
                    title={ this.getPreviousDateRangeAriaForOverviewtype() }
                    ariaLabel={ this.getPreviousDateRangeAriaForOverviewtype() }
                    onKeyDown={ this.previousDateRangeKeyPress }
                    disabled={ this.props.disablePreviousDateArrow ?? false }
                    onClick={ this.previousDateRangeClicked }
                    className={ arrowStyle }
                    iconProps={ { styles: { root: styles.panelCloseButtonIcon }, iconName: "ChevronLeft" } } />
                <IconButton
                    title={ this.getNextDateRangeAriaForOverviewtype() }
                    ariaLabel={ this.getNextDateRangeAriaForOverviewtype() }
                    onKeyDown={ this.nextDateRangeKeyPress }
                    onClick={ this.nextDateRangeClicked }
                    className={ arrowStyle }
                    iconProps={ { styles: { root: styles.panelCloseButtonIcon }, iconName: "ChevronRight" } } />
            </div>
        );
    }
}