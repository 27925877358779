import schedulesViewStateStore from "sh-application/components/schedules/lib/store/store";
import { orchestrator } from "satcheljs";
import { shiftsModified } from "sh-shift-store";
import { updateShifts } from "../";

/**
 * When updates to shifts are triggered via the UI or via sync notifications, this orchestrator will be triggered.
 * It is used to keep track of all the shifts during progressive rendering.
 */
export default orchestrator(shiftsModified, async actionMessage => {
    if (schedulesViewStateStore().isProgressiveRendering) {
        if (actionMessage.shiftsAdded && actionMessage.shiftsAdded.length) {
            updateShifts(actionMessage.shiftsAdded);
        }

        if (actionMessage.shiftsUpdated && actionMessage.shiftsUpdated.length) {
            updateShifts(actionMessage.shiftsUpdated);
        }

        if (actionMessage.shiftsDeleted && actionMessage.shiftsDeleted.length) {
            updateShifts(actionMessage.shiftsDeleted);
        }
    }
});
