import schedulesViewStateStore from "sh-application/components/schedules/lib/store/store";
import { orchestrator } from "satcheljs";
import { updateOpenShiftsInStore } from "sh-open-shift-store";
import { updateOpenShifts } from "../";

/**
 * When updates to open shifts are triggered via the UI or via sync notifications, this orchestrator will be triggered.
 * It is used to keep track of all the open shifts during progressive rendering.
 */
export default orchestrator(updateOpenShiftsInStore, async actionMessage => {
    if (schedulesViewStateStore().isProgressiveRendering) {
        if (actionMessage.updatedOpenShifts && actionMessage.updatedOpenShifts.length) {
            updateOpenShifts(actionMessage.updatedOpenShifts);
        }
    }
});
