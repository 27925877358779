import IUniqueSubshiftEntity from "./IUniqueSubshiftEntity";
import ModelUtils from "../ModelUtils";
import ShiftrObjectModelBase from "../ShiftrObjectModelBase";
import { DEFAULT_ACTIVITY_THEME } from "sh-application/../StaffHubConstants";
import { SubshiftEntity, SubshiftStates, SubshiftType } from "sh-models";

const uniqueSubshiftIdPrefix = "USBSHFT_";

/**
 * Unique Subshift entity
 */
export default class UniqueSubshiftEntity extends ShiftrObjectModelBase implements IUniqueSubshiftEntity {
     // id - inherit from ShiftrObjectModelBase
     // eTag - inherit from ShiftrObjectModelBase
     title: string;
     theme: string;
     code: string;
     subshiftType: SubshiftType;

    constructor(
        id: string,
        eTag: string,
        title: string,
        theme: string,
        code: string,
        subshiftType: SubshiftType) {

        let modelId = id || UniqueSubshiftEntity.generateNewUniqueSubshiftId();
        super(modelId, eTag);

        this.id = modelId;
        this.title = title;
        this.theme = (!!theme) ? theme : DEFAULT_ACTIVITY_THEME;
        this.code = code;
        this.subshiftType = subshiftType;
    }

    /**
     * Generate new uniqueShiftId used by StaffHub App
     * @returns {string} uniqueShiftId in form of USHFT_<uuid>
     */
    public static generateNewUniqueSubshiftId(): string {
        return ModelUtils.generateUUIDWithPrefix(uniqueSubshiftIdPrefix);
    }

    /**
     * Function that creates an UniqueSubshiftEntity from a json object
     * @param {jsonData}
     */
    public static fromJson(jsonData: any): UniqueSubshiftEntity {
        if (!jsonData) {
            return null;
        }

        return new UniqueSubshiftEntity(
            jsonData.id,
            jsonData.eTag,
            jsonData.title,
            jsonData.theme,
            jsonData.code,
            jsonData.subshiftType);
    }

    /**
     * Function that creates and returns a SubshiftEntity from an IUniqueSubshiftEntity
     * @param uniqueSubshift
     */
    public static uniqueToSubshiftClientModel(uniqueSubshift: IUniqueSubshiftEntity): SubshiftEntity {
        return new SubshiftEntity("", "", uniqueSubshift.subshiftType, null, null, SubshiftStates.Active, uniqueSubshift.title, uniqueSubshift.theme, uniqueSubshift.code);
    }

    /**
     * Function that creates and returns an IUniqueSubshiftEntity from a SubshiftEntity
     * @param subshift
     */
    public static subshiftToUniqueSubshiftEntity(subshift: SubshiftEntity): UniqueSubshiftEntity {
        return new UniqueSubshiftEntity(
            "", // id
            "", // etag
            subshift.title,
            subshift.theme,
            subshift.code,
            subshift.subshiftType);
    }
}
