import IUserSettingsEntity from "./IUserSettingsEntity";
import ModelUtils from "../ModelUtils";
import ShiftrObjectModelBase from "../ShiftrObjectModelBase";
import { AvailabilityEntity, IAvailabilityEntity } from "sh-models";

const userSettingsIdPrefix = "USERSET_";

/**
 * User settings entity
 */
export default class UserSettingsEntity extends ShiftrObjectModelBase implements IUserSettingsEntity {
    userId: string;
    doNotNotifyBeforeShift: boolean;
    minutesToNotifyBeforeShift: number;
    doNotDisturbWhenWorking: boolean;
    doNotDisturbWhenNotWorking: boolean;
    hideOpenShifts: boolean;
    hideConflictManagement: boolean;
    overrideTeamTimeZoneWithUserTimeZone: boolean;
    fresShownSoFar: Array<string>;
    hideScheduleAvailability: boolean;
    availabilities: Array<IAvailabilityEntity>;

    constructor(
        id: string,
        eTag: string,
        userId: string,
        doNotNotifyBeforeShift: boolean,
        minutesToNotifyBeforeShift: number,
        doNotDisturbWhenWorking: boolean,
        doNotDisturbWhenNotWorking: boolean,
        hideOpenShifts: boolean,
        overrideTeamTimeZoneWithUserTimeZone: boolean,
        fresShownSoFar: Array<string>,
        hideScheduleAvailability: boolean,
        availabilities: Array<IAvailabilityEntity>,
        hideConflictManagement: boolean) {
        let modelId = id || UserSettingsEntity.generateNewUserSettingId();
        super(modelId, eTag);

        this.userId = userId;
        this.doNotNotifyBeforeShift = doNotNotifyBeforeShift;
        this.minutesToNotifyBeforeShift = minutesToNotifyBeforeShift;
        this.doNotDisturbWhenWorking = doNotDisturbWhenWorking;
        this.doNotDisturbWhenNotWorking = doNotDisturbWhenNotWorking;
        this.hideOpenShifts = hideOpenShifts;
        this.overrideTeamTimeZoneWithUserTimeZone = overrideTeamTimeZoneWithUserTimeZone;
        this.fresShownSoFar = fresShownSoFar && [...fresShownSoFar];
        this.hideScheduleAvailability = hideScheduleAvailability;
        this.availabilities = availabilities;
        this.hideConflictManagement = hideConflictManagement;
    }

    /**
     * Generate new userId used by Shiftr App
     * @returns {string} userSettingsId in form of USERSET_<uuid>
     */
    public static generateNewUserSettingId(): string {
        return ModelUtils.generateUUIDWithPrefix(userSettingsIdPrefix);
    }

    /**
     * Function that creates an UserSettingsEntity from a json object
     * @param {jsonData}
     */
    public static fromJson(jsonData: any): IUserSettingsEntity {
        if (!jsonData) {
            return null;
        }

        const availabilities = jsonData.availabilities
            ? jsonData.availabilities.map((availability: IAvailabilityEntity) => AvailabilityEntity.fromJson(availability))
            : null;
        return new UserSettingsEntity(
            jsonData.id,
            jsonData.eTag,
            jsonData.userId,
            jsonData.doNotNotifyBeforeShift,
            jsonData.minutesToNotifyBeforeShift,
            jsonData.doNotDisturbWhenWorking,
            jsonData.doNotDisturbWhenNotWorking,
            jsonData.hideOpenShifts,
            jsonData.overrideTeamTimeZoneWithUserTimeZone,
            jsonData.fresShownSoFar,
            jsonData.hideScheduleAvailability,
            availabilities,
            jsonData.hideConflictManagement
        );
    }

    /**
     * Function that creates service JSON from userSettingsEntity
     * @param userSettingsEntity - user settings entity that needs to be sent over the wire
     */
    static toJson(userSettingsEntity: IUserSettingsEntity): IUserSettingsEntity {
        return {
            id: userSettingsEntity.id,
            eTag: userSettingsEntity.eTag,
            userId: userSettingsEntity.userId,
            doNotNotifyBeforeShift: userSettingsEntity.doNotNotifyBeforeShift,
            minutesToNotifyBeforeShift: userSettingsEntity.minutesToNotifyBeforeShift,
            doNotDisturbWhenWorking: userSettingsEntity.doNotDisturbWhenWorking,
            doNotDisturbWhenNotWorking: userSettingsEntity.doNotDisturbWhenNotWorking,
            hideOpenShifts: userSettingsEntity.hideOpenShifts,
            overrideTeamTimeZoneWithUserTimeZone: userSettingsEntity.overrideTeamTimeZoneWithUserTimeZone,
            fresShownSoFar: userSettingsEntity.fresShownSoFar,
            hideScheduleAvailability: userSettingsEntity.hideScheduleAvailability,
            availabilities: userSettingsEntity.availabilities,
            hideConflictManagement: userSettingsEntity.hideConflictManagement
        };
    }

    /**
     * Function that clones a user settings entity object
     * @param userSettingsEntity - user settings that needs to be cloned
     */
    public static clone(userSettingsEntity: IUserSettingsEntity): IUserSettingsEntity {
        if (!userSettingsEntity) {
            return null;
        }

        const availabilities: IAvailabilityEntity[] = userSettingsEntity.availabilities ? userSettingsEntity.availabilities.map(availability => AvailabilityEntity.clone(availability)) : [];
        return new UserSettingsEntity(
            userSettingsEntity.id,
            userSettingsEntity.eTag,
            userSettingsEntity.userId,
            userSettingsEntity.doNotNotifyBeforeShift,
            userSettingsEntity.minutesToNotifyBeforeShift,
            userSettingsEntity.doNotDisturbWhenWorking,
            userSettingsEntity.doNotDisturbWhenNotWorking,
            userSettingsEntity.hideOpenShifts,
            userSettingsEntity.overrideTeamTimeZoneWithUserTimeZone,
            userSettingsEntity.fresShownSoFar,
            userSettingsEntity.hideScheduleAvailability,
            availabilities,
            userSettingsEntity.hideConflictManagement);
    }
}
