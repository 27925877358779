import IMemberDataService from "./IMemberDataService";
import MemberUtils from "sh-application/utility/MemberUtils";
import RestClient from "sh-rest-client";
import { DataService } from "./DataService";
import { IMemberEntity } from "sh-models";
import { MembersDataProvider } from "sh-services/dataproviders/MembersDataProvider";
import { TeamStore } from "sh-team-store";
import { TestDataIDConstant } from "sh-application/../StaffHubConstants";

class MemberDataService extends DataService implements IMemberDataService {

    /**
     * Get a member by the memberId.  Look only in the currently logged in team
     * @param memberId
     */
    public async getMemberByIdFromCurrentTeam(memberId: string): Promise<IMemberEntity> {
        let member: IMemberEntity = null;
        if (memberId) {
            let teamStore = TeamStore();
            // enforce that the member's team is currently in store
            if (teamStore && teamStore.team) {
                if (teamStore.members && teamStore.members.has(memberId)) {
                    member = teamStore.members.get(memberId);
                } else {
                    member = await RestClient.getMemberById(teamStore.team.tenantId, teamStore.team.id, memberId);
                    this.onUpdateMembers([member]);
                }
            }
        }
        return member;
    }

    /**
     * Get the member by id from store, or null if not found (This function is deprecated)
     * @param memberId
     */
    public getMemberByIdFromStore(memberId: string): IMemberEntity {
        let member: IMemberEntity = null;
        if (memberId) {
            let teamStore = TeamStore();
            if (teamStore.members && teamStore.members.has(memberId)) {
                member = teamStore.members.get(memberId);
            }
        }
        return member;
    }

    /**
     * Update members in the current team
     * @param members List of members to update
     */
    public async updateMembers(members: IMemberEntity[]): Promise<IMemberEntity[]> {
        const teamId = members && members.length ? members[0].teamId : null;
        const membersDataProvider = new MembersDataProvider(this.teamDatabase, this.tenantId, teamId);
        return this.updateData(members, membersDataProvider);
    }

    /**
     * Handles an update to members in the team
     * @param members List of members that were updated
     */
    public async onUpdateMembers(members: IMemberEntity[]): Promise<IMemberEntity[]> {
        const teamId = members && members.length ? members[0].teamId : null;
        const membersDataProvider = new MembersDataProvider(this.teamDatabase, this.tenantId, teamId);
        return this.updateDataInCache(members, membersDataProvider);
    }

    /**
     * Delete member in the current team
     * @param member Member to delete
     */
    public async deleteMember(member: IMemberEntity): Promise<IMemberEntity> {
        const deletedMember: IMemberEntity = await RestClient.deleteTeamMember(member.tenantId, member.teamId, member);
        // members are only soft deleted, so just update the member in storage
        await this.onUpdateMembers([deletedMember]);
        return deletedMember;
    }

    /**
     * Create/Update a member
     * @param member Member to create/update
     * @param isNewUser Whether this is a new or existing member
     */
    public async saveMember(member: IMemberEntity, isNewUser: boolean): Promise<IMemberEntity> {
        let apiCall = isNewUser ? RestClient.addTeamMember : RestClient.updateTeamMember;

        // clean up the request object to avoid service error
        if (member.index === null) {
            delete member.index;
        }

        member.firstName = member.firstName || null;
        member.lastName = member.lastName || null;
        member.phoneNumber = member.phoneNumber || null;
        member.email = member.email || null;

        let updatedMember = await apiCall(TeamStore().tenantId, TeamStore().teamId, member);
        await this.onUpdateMembers([updatedMember]);
        return updatedMember;
    }

    /**
     * FOR TESTING PURPOSES ONLY
     * Deletes all members found in the store that have ids containing the test data string constant.
     */
    public async deleteTestMembers(): Promise<IMemberEntity[]> {
        let membersToDelete: IMemberEntity[] = [];
        TeamStore().members.forEach((member: IMemberEntity) => {
            if (member.id.startsWith(`MBER_${TestDataIDConstant}`) && !MemberUtils.isMemberDeletedFromTeam(member)) {
                membersToDelete.push(member);
            }
        });

        let memberDeletionPromises: Promise<IMemberEntity>[] = [];
        for ( let i = 0; i < membersToDelete.length; i++) {
            memberDeletionPromises.push(this.deleteMember(membersToDelete[i]));
        }
        return Promise.all(memberDeletionPromises);
    }

    /**
     * Resets the sync state
     */
    public async resetSyncState() {
    }
}

const service: IMemberDataService = new MemberDataService();
export default service;