import { ICommandBarProps } from "@fluentui/react";
import { useCallback, useEffect, useState } from "react";

import { FilterableCommandBarProps } from "./FilterableCommandBar";

/**
 * Gets extra properties for the 'CommandBar' component.
 * @param props The 'FilterableCommandBar' properties.
 * @returns The extra properties for the 'CommandBar' component.
 */
export const useCommandBarExtraProps = (props: FilterableCommandBarProps): Partial<ICommandBarProps> => {
    const { onDidRender } = props;
    const [didRender, setDidRender] = useState(false);

    const dataDidRender = useCallback((): void => {
        setDidRender(true);
    }, []);

    useEffect(() => {
        if (didRender == true) {
            onDidRender?.();
        }
    }, [didRender, onDidRender]);

    return { dataDidRender };
};