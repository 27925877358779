import { FILE_DOWNLOAD_WHITELIST_URL_PATTERNS } from "../../StaffHubConstants";
import { InstrumentationService } from "sh-services";

/**
 * Utilities for downloading a file
 */
export default class FileDownloadUtils {

    /**
     * Verify that the URL is safe to allow file downloads
     * @param fileDownloadUrl - The URL to download the file from
     */
    public static IsUrlSafeForDownload(fileDownloadUrl: string) {
        if (!fileDownloadUrl) {
            return false;
        }

        let urlParser = document.createElement('a');
        urlParser.href = fileDownloadUrl.toLowerCase();

        // Only allow downloads from StaffHub Website or azure blobs and Service URLs
        return (this.endsWithAny(FILE_DOWNLOAD_WHITELIST_URL_PATTERNS, urlParser.hostname) && urlParser.protocol == "https:");
    }

    /**
     * Checks if the given hostname endswith any of the strings provided in the list
     * @param suffixes whitelisted suffixes for hostname
     * @param inputStr hostName
     */
    private static endsWithAny(suffixes: string[], inputStr: string) {
        for (let suffix of suffixes) {
            if (inputStr.endsWith(suffix)) {
                return true;
            }
        }
        return false;
    }

    /**
     * Util to get the file name from the file download URL
     * @param fileDownloadUrl
     */
    public static getFileNameFromFileDownloadUrl(fileDownloadUrl: string) {
        return fileDownloadUrl ? fileDownloadUrl.split('/').pop().split('#').shift().split('?').shift() : "";
    }

    /**
     * Triggers a file download by loading it into an invisible iframe
     * @param {string} fileDownloadUrl - The URL to download the file from
     * @param {number} timeoutInMinutes - The maximum amount of time to allow for the download to complete
     */
    public static DownloadFile(fileDownloadUrl: string, timeoutInMinutes: number = 5) {
        try {
            if (!FileDownloadUtils.IsUrlSafeForDownload(fileDownloadUrl)) {
                throw new Error('File downloads are not allowed from this URL: ' + fileDownloadUrl);
            }

            let invisibleIframe = document.createElement("iframe") as HTMLIFrameElement;
            invisibleIframe.style.visibility = 'hidden';
            invisibleIframe.src = fileDownloadUrl;
            document.body.appendChild(invisibleIframe);
            setTimeout(() => {
                // clean up the iframe after the timeout period
                document.body.removeChild(invisibleIframe);
            }, timeoutInMinutes * 60 * 1000);
        } catch (error) {
            InstrumentationService.trackException(error, "FileDownload", "FileDownloadUtils");
            throw error;
        }
    }
}