import { mutator } from "satcheljs";

import { toggleMemberFilter } from "../..";
import { ToggleMemberFilterActionMessage } from "../../actions/filter/ToggleMemberFilterActionMessage";
import schedulesViewStateStore from "../../store/store";

/**
 * Toggles a member in the schedule view filters.
 */
export const toggleMemberFilterMutator = mutator<ToggleMemberFilterActionMessage, void>(toggleMemberFilter, ({ member }) => {
    if (!schedulesViewStateStore() || !schedulesViewStateStore().filteredMembers) {
        return;
    }

    if (schedulesViewStateStore().filteredMembers.has(member.id)) {
        schedulesViewStateStore().filteredMembers.delete(member.id);
    } else {
        schedulesViewStateStore().filteredMembers.set(member.id, member);
    }
});
