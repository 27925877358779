import { action } from "satcheljs";

import { IActionShiftRequestParams } from "../common/IActionShiftRequestParams";

/**
 * Fires an action to approve or deny given shift request.
 */
export const actionShiftRequest = action(
    "ACTION_SHIFT_REQUEST",
    (params: IActionShiftRequestParams) => params
);
