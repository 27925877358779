/**
 * Returns true is this is a production build (Daily, R0, Release)
 */
export function isProdBuild() {
        if (process.env.NODE_ENV === "production") {
                return true;
        } else {
                return false;
        }
}

/**
 * Returns true if this is a Release build (not Daily or R0)
 */
export function isReleaseBuild() {
        if (window.sa.buildEnvironment === "Release") {
                return true;
        } else {
                return false;
        }
}

/**
 * Returns true if this is a Local running build
 */
export function isLocalBuild() {
        return ASPNETCORE_IS_LOCAL;
}

/**
 * Generate new UUID
 * @returns {string} UUID
 */
export function generateUUID() {
        let d = new Date().getTime();
        const uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function generateUUID$replace(c: string) {
                const r = (d + Math.random() * 16) % 16 | 0;
                d = Math.floor(d / 16);
                return (c == "x" ? r : (r & 0x3 | 0x8)).toString(16);
        });
        return uuid;
}

/**
 * Generate UUID with prefix.
 * @param {} prefix
 * @returns {string} UUID with Prefix
 */
export function generateUUIDWithPrefix(prefix: string) {
        return prefix + generateUUID();
}

/**
 * Return Duration between to two timestamps in ms
 * @param {number} startTimeStamp
 * @param {number} endTimeStamp
 * @returns {Number}
 */
export function getDurationInMs(startTimeStamp: number, endTimeStamp: number) {
        return endTimeStamp - startTimeStamp;
}

/**
 * Combine and return the prefix with the id.
 * @param prefix
 * @param id
 */
export function getPrefixedId(prefix: string, id: string): string {
        return prefix + "-" + id;
}

/**
 * Append a start string to the given id and return the result.
 * @param id
 */
export function getStartId(id: string): string {
        return getPrefixedId("start", id);
}

/**
 * Append an end string to the given id and return the result.
 * @param id
 */
export function getEndId(id: string): string {
        return getPrefixedId("end", id);
}

/**
 * Append a duration string to the given id and return the result.
 * @param id
 */
export function getDurationId(id: string): string {
        return getPrefixedId("duration", id);
}

/**
 * Utility function for checking if the platform is a mac.  Also checks for iPad.
 */
export function isMacLike(): boolean {
        return Boolean(navigator.platform.match(/(Mac|iPad|iPhone)/i));
}

/**
 * Adds format method to the global String object. This allows you to format strings using
 * the {n} notation.
 * Example:
 * let myString = "{0} {1}";
 * myString.format("Hello", "World"); // myString now equals "Hello World"
 *
 * @param {...Strings} strings - list of strings to replace
 * @returns {String} formatted string or "" if an exception is thrown
 */
if (!String.prototype.format) {
        String.prototype.format = function() {
                let args = arguments;
                try {
                        return this.replace(/{(\d+)}/g, function(match: string, index: number) {
                                return typeof args[index] != 'undefined'
                                        ? args[index]
                                        : match;
                        });
                } catch (e) {
                        return "";
                }
        };
}