import { mutatorAction } from "satcheljs";

import { ChangeStore } from 'sh-stores/sh-change-store';

/**
 * Removes the ability to redo copy schedule by removing it from the stack
 */
export default mutatorAction("SET_DISABLE_REDO_COPY_SCHEDULE_BUTTON",
    function setDisableRedoCopyScheduleButton() {
        ChangeStore().redoStack.pop();
    }
);