import AvailabilityUtils from "sh-application/utility/AvailabilityUtils";
import ChangeUtils from "sh-application/utility/ChangeUtils";
import ConflictUtils from "sh-application/utility/ConflictUtils";
import ShiftUtils from "sh-application/utility/ShiftUtils";
import { action } from "satcheljs/lib/legacy";
import {
    addShiftAvailabilityConflict,
    addShiftConflict,
    DismissedConflictsStore,
    incrementConflictCount,
    removeDismissedConflictEntity
    } from "sh-conflict-store";
import { AvailabilityStore } from "sh-availability-store";
import {
    ChangeEntity,
    ConflictType,
    FlightKeys,
    IBulkShiftResponseEntity,
    IChangeEntity,
    IShiftEntity,
    IShiftResponseMultipleEntity,
    ShiftStates,
    IConflictDismissEntity
    } from "sh-models";
import {
    ConflictDismissDataService,
    ECSConfigKey,
    ECSConfigService,
    FlightSettingsService,
    InstrumentationService,
    ShiftDataService,
    TeamDataService
    } from "sh-services";
import { getGenericEventPropertiesObject } from "sh-instrumentation";
import { mapOldShiftIdsToNewShiftIds } from "./mapOldShiftIdsToNewShiftIds";
import { refreshConflictsInView } from "sh-application/components/schedules/lib";
import { StaffHubHttpError } from "sh-application";
import { TeamStore } from "sh-team-store";
import { trace } from "owa-trace";

/**
 * revert the most recently added set of changes
 * @param changes Array<IChangeEntity>
 * @param fromUndo boolean - used to indicate if changes are from undo
 * @param fromRedo boolean - used to indicate if changes are from redo
 * @returns Promise<Array<IChangeEntity>>
 */
export let revertChanges = action("revertChanges")(
    function revertChanges(changes: Array<IChangeEntity>, fromUndo?: boolean, fromRedo?: boolean) {
        let promises = Array<any>();
        const copyScheduleAsyncAPIsEnabled = ECSConfigService.isECSFeatureEnabled(ECSConfigKey.EnableCopyScheduleAsyncAPIs);
        const instrumentationEventNameUndo = InstrumentationService.events.Undo_Action;
        const instrumentationEventNameRedo = InstrumentationService.events.Redo_Action;
        if (FlightSettingsService.isFlightEnabled(FlightKeys.EnableShiftUndoWeb) && !!changes) {
            changes.forEach(async (change: IChangeEntity) => {
                if (change.getRevertTask) {
                    promises.push(change.getRevertTask());
                } else if (ChangeUtils.isCopyDateRange(change) && fromUndo && copyScheduleAsyncAPIsEnabled) {
                    if (change.copiedSchedule) {
                        const jobId = change.copiedSchedule.jobId;
                        const teamId = change.copiedSchedule.teamId;
                        promises.push(TeamDataService.undoJob(teamId, jobId)
                            .then(() => {
                                InstrumentationService.logEvent( instrumentationEventNameUndo,
                                    [getGenericEventPropertiesObject(InstrumentationService.properties.JobSubmittedSuccessfully, true)]);
                                // persist the change source for messaging to the user on the redo button
                                return new ChangeEntity (null, null, change.changeSource , null, change.copiedSchedule);
                            })
                        );
                    }
                } else if (ChangeUtils.isCopyDateRange(change) && fromRedo && copyScheduleAsyncAPIsEnabled) {
                    if (change.copiedSchedule) {
                        let copiedScheduleChange = change.copiedSchedule;
                        promises.push(TeamDataService.bulkCopySchedule(copiedScheduleChange.teamId, copiedScheduleChange.sourceStartTime,
                                    copiedScheduleChange.sourceEndTime, copiedScheduleChange.destinationOffsetInDays, copiedScheduleChange.copyNotes, copiedScheduleChange.copyTimeOffShifts,
                                    copiedScheduleChange.copyActivities, copiedScheduleChange.copyOpenShifts, copiedScheduleChange.scheduleRepetition, copiedScheduleChange.dataFilter)
                            .then(() => {
                                InstrumentationService.logEvent( instrumentationEventNameRedo,
                                    [getGenericEventPropertiesObject(InstrumentationService.properties.JobSubmittedSuccessfully, true)]);
                                // persist the change source for messaging to the user on the redo button
                                return new ChangeEntity (null, null, change.changeSource , null, change.copiedSchedule);
                            })
                        );
                    }
                } else if (ChangeUtils.isAdd(change)) {
                    const shiftsToDelete = ShiftUtils.cloneShiftList(change.resultingShifts);
                    promises.push(ShiftDataService.deleteShifts(shiftsToDelete, true /* optimistic */)
                        .then((deletedShiftsAndAlerts: IShiftResponseMultipleEntity) => {
                            // persist the change source for messaging to the user on the redo button
                            return new ChangeEntity(deletedShiftsAndAlerts.shifts, null, change.changeSource);
                        })
                    );
                } else if (ChangeUtils.isDelete(change)) {
                    const tenantId = change.previousShifts[0].tenantId;
                    const teamId = change.previousShifts[0].teamId;
                    // TODO: service made changes to support delete -> active state PUT calls for individual shifts. When they make this possible for bulk, update here
                    // generate new ids for the shifts. Service does not allow updating the shift state from Deleted to Active so we
                    // create a new shift instead
                    const shiftsToAdd = ShiftUtils.cloneShiftList(change.previousShifts, true /* generateNewIds */);
                    shiftsToAdd.forEach(shift => shift.state = ShiftStates.Active);
                    promises.push(ShiftDataService.bulkAddShifts(tenantId, teamId, shiftsToAdd, null /* openShifts */, true /* optimistic */)
                        .then((shiftsAndAlerts: IBulkShiftResponseEntity) => {
                            let addedShifts = shiftsAndAlerts.shifts;
                            mapOldShiftIdsToNewShiftIds(change.previousShifts.map((shift: IShiftEntity) => shift.id), addedShifts.map((shift: IShiftEntity) => shift.id));
                            return new ChangeEntity(null, addedShifts, change.changeSource);
                        })
                    );
                } else if (ChangeUtils.isUpdate(change)) {
                    const shiftsToUpdate = ShiftUtils.cloneShiftList(change.previousShifts);
                    promises.push(ShiftDataService.updateShifts(shiftsToUpdate, true /* optimistic */)
                        .then((updatedShiftsAndAlerts: IShiftResponseMultipleEntity) => {
                            return new ChangeEntity(change.resultingShifts, updatedShiftsAndAlerts.shifts, change.changeSource);
                        })
                    );
                } else if (ChangeUtils.isConflictDismiss(change) && fromUndo) {
                    const conflictDismissId = DismissedConflictsStore().conflictIdToDismissIdMap.get(change.previousConflict.id);
                    const conflictDismissEntity: IConflictDismissEntity = conflictDismissId && DismissedConflictsStore().entityIdToDismissedConflictEntities.get(conflictDismissId);
                    const teamId = TeamStore().teamId;
                    const conflictType = change.previousConflict.conflictType;
                    const shiftId = change.previousConflict.shiftId;
                    const previousConflict = change.previousConflict;

                    // undo-ing the dismiss
                    // increment back the conflict count
                    incrementConflictCount(previousConflict.memberId, conflictType);

                    const shift = ShiftUtils.getShiftByShiftId(shiftId);

                    // add the conflict back to conflict store
                    if (conflictType === ConflictType.ShiftAvailabilityConflict) {
                        // this is a shift-availability conflict
                        let availId = ConflictUtils.getConflictingEntityId(previousConflict, shiftId);
                        const availability = AvailabilityUtils.getAvailabilityFromId(AvailabilityStore().membersAvailabilities, availId, change.previousConflict.memberId);
                        addShiftAvailabilityConflict(shift, availability);
                    } else {
                        // this is a overlapping shift or timeoff shift conflict
                        const conflictingShiftId = ConflictUtils.getConflictingEntityId(previousConflict, shiftId);
                        const conflictingShift = ShiftUtils.getShiftByShiftId(conflictingShiftId);
                        addShiftConflict(shift, conflictingShift, conflictType);
                    }

                    // undo dismiss/delete the dismiss entity in service
                    promises.push(ConflictDismissDataService.undoDismissConflict(teamId, conflictDismissEntity).then(() => {
                        // we need to remove the conflict dismiss entity from the dismissed conflict store
                        removeDismissedConflictEntity(conflictDismissEntity);
                        // refresh the ui to show new conflict count and add back conflict icons
                        refreshConflictsInView();
                        return new ChangeEntity(null, null, change.changeSource, null, null, change.previousConflict);
                    }));
                }
            });
        }

        return Promise.all(promises)
            .catch((error: StaffHubHttpError) => {
                let errorMessage = error && error.staffHubTopLevelErrorMessage ? error.staffHubTopLevelErrorMessage : "error";
                trace.warn("revertChanges: undo partially failed: " + errorMessage);
            });
    });