import { mutatorAction } from "satcheljs";
import { ReorderTagsPanelViewState } from "sh-application/store/schema/ReorderTagsPanelViewState";

/**
 * Sets current selected tag index for the reorder tags panel
 * @param viewState - Tags Panel view state
 * @param newTagIndex - new tag index. -1 for no selection
 */
export default mutatorAction("SET_CURRENT_SELECTED_TAG_INDEX_FOR_REORDER_TAGS_PANEL",
    function setCurrentSelectedTagIndexForReorderTagsPanel(viewState: ReorderTagsPanelViewState, newTagIndex: number) {
        viewState.currentSelectedTagIndex = newTagIndex;
    }
);