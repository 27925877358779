import { trace } from "owa-trace";
import { UserStorageKey } from "sh-models";
import { UserStore } from "sh-stores";

import { UserStorageServiceOptions } from "./UserStorageServiceOptions";
import { UserStorageServiceParams } from "./UserStorageServiceParams";
import { UserStorageServiceScope } from "./UserStorageServiceScope";

/**
 * UserStorageService provides browser-based storage. It currently wraps the native local storage object.
 * TODO: Rename 'UserStorageService' to 'LocalStorageService' since not all stored values are user specific.
 */
export class UserStorageService {
    private readonly defaultScope = UserStorageServiceScope.CurrentUser;
    private readonly getTenantId: () => string | undefined;
    private readonly getUserId: () => string | undefined;
    private readonly storage: Storage;

    /**
     * Instantiates a new UserStorageService.
     * @param params The parameters.
     */
    constructor(params: UserStorageServiceParams) {
        const { getTenantId, getUserId, storage } = params;

        this.getTenantId = getTenantId;
        this.getUserId = getUserId;
        this.storage = storage;
    }

    /**
     * Sets a key value pair in local storage. Returns the value if this operation was successful, else null.
     *
     * Note: Date times values are saved as the actual time with no info about the timezone (JSON.stringify will output these times as
     * strings adjusted to UTC), so if the persisted date time needs to be the same regardless of current timezone (eg, we want to save
     * some date at midnight, regardless of the timezone), the date time value needs to be "flattened" to be independent of timezone.
     *
     * This can be done by either representing everything in terms of UTC (see DateUtils.cloneTimezoneMomentToUtcMoment()), or saving all
     * date time components (eg, year, month, date, hour, minute, seconds, milliseconds) separately.
     * @param key The key to store the value at.
     * @param value The value to store.
     * @param options The options.
     * @returns The value if success otherwise null.
     */
    public setItem<TValue = string>(key: UserStorageKey, value: TValue, options?: UserStorageServiceOptions): TValue {
        let val: TValue = value;
        const scope = this.getScope(options);
        const scopedKey = this.getScopedKey(key, scope);

        if (!scopedKey) {
            return null;
        }

        // localStorage.setItem() can throw an exception when there is not enough memory
        try {
            this.storage.setItem(scopedKey, JSON.stringify(value));
        } catch (e) {
            trace.warn(`localStorage: setItem() has thrown an exception: ${e}`);
            val = null;
        }
        return val;
    }

    /**
     * Gets the item stored with the given key. May return null.  Takes in the type of the value.
     *
     * Note: Date times values are saved as the actual time with no info about the timezone (JSON.stringify will output these times as
     * strings adjusted to UTC), so if the persisted date time needs to be the same regardless of current timezone (eg, we want to save
     * some date at midnight, regardless of the timezone), the date time value needs to be "flattened" to be independent of timezone.
     *
     * This can be done by either representing everything in terms of UTC (see DateUtils.cloneTimezoneMomentToUtcMoment()), or saving all
     * date time components (eg, year, month, date, hour, minute, seconds, milliseconds) separately.
     * @param key The key to get the value from.
     * @param options The options.
     * @returns The value if success otherwise null.
     */
    public getItem<TValue = string>(key: UserStorageKey, options?: UserStorageServiceOptions): TValue {
        const scope = this.getScope(options);
        const scopedKey = this.getScopedKey(key, scope);

        if (!scopedKey) {
            return null;
        }

        // localStorage.getItem() can throw an exception in ie noAccess
        let value;

        try {
            value = JSON.parse(this.storage.getItem(scopedKey)) as TValue;
        } catch (e) {
            trace.warn(`localStorage: getItem() has thrown an exception: ${e}`);
            value = null;
        }
        return value;
    }

    /**
     * Removes the item stored with the given key from local storage.
     * @param key The key to remove the value from.
     * @param options The options.
     */
    public removeItem(key: UserStorageKey, options?: UserStorageServiceOptions): void {
        const scope = this.getScope(options);
        const scopedKey = this.getScopedKey(key, scope);

        if (!scopedKey) {
            return;
        }

        try {
            this.storage.removeItem(scopedKey);
        } catch (e) {
            trace.warn(`localStorage: removeItem() has thrown an exception: ${e}`);
        }
    }

    /**
     * Returns whether a value is set at given key.
     * @param key The key where the value is stored at.
     * @param options The options.
     * @returns Whether a value is set at given key.
     */
    public hasItem(key: UserStorageKey, options?: UserStorageServiceOptions): boolean {
        const scope = this.getScope(options);
        const scopedKey = this.getScopedKey(key, scope);

        if (!scopedKey) {
            return null;
        }

        let hasItem = false;

        try {
            hasItem = this.storage.getItem(scopedKey) != null;
        } catch (e) {
            trace.warn(`localStorage: hasItem() has thrown an exception: ${e}`);
        }

        return hasItem;
    }

    private getScope(options?: UserStorageServiceOptions): UserStorageServiceScope {
        return options?.scope ?? this.defaultScope;
    }

    private getScopedKey(key: UserStorageKey, scope: UserStorageServiceScope): string | undefined {
        switch (scope) {
            case UserStorageServiceScope.CurrentUser:
                return this.getCurrentUserKey(key);
            case UserStorageServiceScope.CurrentTenant:
                return this.getCurrentTenantKey(key);
            default:
                return key;
        }
    }

    private getCurrentUserKey(key: UserStorageKey): string | undefined {
        const userId = this.getUserId();

        if (userId) {
            return `${key}_${userId}`;
        } else {
            trace.warn("UserStorageService: this.getUserId() returned undefined.");
            return undefined;
        }
    }

    private getCurrentTenantKey(key: UserStorageKey): string | undefined {
        const tenantId = this.getTenantId();

        if (tenantId) {
            return `${key}_${tenantId}`;
        } else {
            trace.warn("UserStorageService: this.getTenantId() returned undefined.");
            return undefined;
        }
    }
}

const service = new UserStorageService({
    getTenantId: (): string | undefined => UserStore().user?.tenantId,
    getUserId: (): string | undefined => UserStore().user?.id,
    storage: window.localStorage
});

export default service as UserStorageService;
