import { action } from "satcheljs";
import { IShiftEntity } from "sh-models";
import { ShiftStoreTypes } from "sh-application/../StaffHubConstants";

const deleteShifts = action(
    "DELETE_SHIFTS",
    (
        shiftsDeleted: IShiftEntity[],
        shiftStoreType: ShiftStoreTypes
    ) => ({
        shiftsDeleted: shiftsDeleted || [],
        shiftStoreType: shiftStoreType
    })
);

export default deleteShifts;
