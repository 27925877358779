import { memberEditorViewState } from "../";
import { action } from "satcheljs/lib/legacy";

/**
 * Generic action that updates a isSaving boolean on a view state
 * @param isSaving - value for isSaving view state property
 */
export default action("setIsSaving")(
    function setIsSaving(isSaving: boolean) {
        memberEditorViewState().isSaving = isSaving;
    }
);