import { importScheduleDialogViewState } from "../";
import { mutatorAction } from "satcheljs";

/**
 * Sets the error message to display it on dialog box.
 * @param message - The error message to display. Set to empty string to clear the error.
 */
export default mutatorAction("setDialogErrorMessage", function setDialogErrorMessage(
    message: string
) {
    importScheduleDialogViewState().dialogErrorMessage = message;
});