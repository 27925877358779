import TimeClockEvent from "./TimeClockEvent";
import { ITimeClockEvent } from "./IBaseTimeClockEvent";
import { ModelUtils } from "..";

const BreakIdPrefix = "BRK_";

export default class TimeClockBreakEvent {
    breakId: string;
    breakStart: ITimeClockEvent;
    breakEnd: ITimeClockEvent;
    notes?: string;

    /**
     * Generate new breakId used by StaffHub App
     * @returns {string} breakId in form of BRK_<uuid>
     */
    static generateNewBreakId(): string {
        return ModelUtils.generateUUIDWithPrefix(BreakIdPrefix);
    }

    static fromJson(jsonData: any): TimeClockBreakEvent {
        if (!jsonData) {
            return null;
        }

        let timeClockBreakEvent: TimeClockBreakEvent = new TimeClockBreakEvent();
        timeClockBreakEvent.breakId = jsonData.breakId ? jsonData.breakId : this.generateNewBreakId();
        timeClockBreakEvent.breakStart = (jsonData.breakStart) ? TimeClockEvent.fromJson(jsonData.breakStart) : null;
        timeClockBreakEvent.breakEnd = (jsonData.breakEnd) ? TimeClockEvent.fromJson(jsonData.breakEnd) : null;
        timeClockBreakEvent.notes = jsonData.notes ? jsonData.notes : null;

        return timeClockBreakEvent;
    }

    static clone(timeClockBreakEvent: TimeClockBreakEvent): TimeClockBreakEvent {
        return TimeClockBreakEvent.fromJson(timeClockBreakEvent);
    }

    /**
     * Function that converts breakStart and breakEnd objects in a timeclock break event to timeclock event db models
     * @param timeClockBreakEvent - time clock break event
     */
    static toDbModel(timeClockBreakEvent: TimeClockBreakEvent): any {
        if (!timeClockBreakEvent) {
            return null;
        }

        return {
            breakId: timeClockBreakEvent.breakId,
            breakStart: TimeClockEvent.toDbModel(timeClockBreakEvent.breakStart),
            breakEnd: TimeClockEvent.toDbModel(timeClockBreakEvent.breakEnd),
            notes: timeClockBreakEvent.notes
        };

    }
}