import ILocateUserResponseEntity from 'sh-models/user/ILocateUserResponseEntity';
import IUserRegionEntity from 'sh-models/user/IUserRegionEntity';
import UserRegionEntity from 'sh-models/user/UserRegionEntity';

export default class LocateUserResponseEntity implements ILocateUserResponseEntity {
    userId: string;
    email: string;
    region: IUserRegionEntity;

    constructor(
        userId: string,
        email: string,
        region: IUserRegionEntity
    ) {
        this.userId = userId;
        this.email = email;
        this.region = UserRegionEntity.fromJson(region);
    }

    public static fromJson(jsonData: ILocateUserResponseEntity): ILocateUserResponseEntity {
        if (!jsonData) {
            return null;
        }
        return new LocateUserResponseEntity(jsonData.userId, jsonData.email, UserRegionEntity.fromJson(jsonData.region));
    }
}