import { HighContrastSelector, IRawStyle } from "@fluentui/react";

export const TeamsContrastSelector = ".shifts_theme_contrast &";

/**
 * Returns an IRawStyle to generate contrast only overrides.
 * Useful for deeply nested styling.
 */
export function generateContrastStyles(styles: IRawStyle): IRawStyle {
    return {
        [HighContrastSelector]: {
            ...styles
        },
        [TeamsContrastSelector]: {
            ...styles
        }
    };
}
