import * as React from "react";
import BrowserUtils from "sh-application/utility/BrowserUtils";
import setControlKeyDown from "../../actions/print/setControlKeyDown";
import setIsPrinting from "../../actions/print/setIsPrinting";
import { observer } from "mobx-react";

export interface PrintListener {
    onBeforePrint: () => void;
    onAfterPrint: () => void;
}

interface PrintWatcherProps {
    listeners?: Array<PrintListener>;
}

/**
 * Cross browser printing component that maintains the print view state and notifies PrintComponents
 * when it is time to print.
 */
@observer
export default class PrintWatcher extends React.Component<PrintWatcherProps, {}> {
    private _mediaQuery: MediaQueryList;

    componentDidMount() {
        if (BrowserUtils.isChrome()) {
            if ("matchMedia" in window && !this._mediaQuery) {
                // add a listener for the "print" media query - we use this to update the timestamp of the print
                // Note: this does not work in any browser except for Chrome - for those we use onbeforeprint and onafterprint
                this._mediaQuery = window.matchMedia("print");
                this._mediaQuery.addListener(this.onPrintMediaQuery);
            }
        } else {
            if (!window.onbeforeprint) {
                // this is for browsers that don't support the print matchMedia query
                window.onbeforeprint = (e: any) => {
                    this.onBeforePrint();
                };
                window.onafterprint = (e: any) => {
                    this.onAfterPrint();
                };
            }
        }
    }

    componentWillUnmount() {
        if (this._mediaQuery) {
            // remove the media query listener
            this._mediaQuery.removeListener(this.onPrintMediaQuery);
            this._mediaQuery = null;
        }

        window.onbeforeprint = null;
        window.onafterprint = null;
    }

    /**
     * Event listener for the print media query. This is called whenever a user initiates print either from
     * clicking the print button or using the Ctrl+P shortcut. It will be called when print starts (media.matches === true)
     * and when print completes (media.matches === false).
     * Note: Firefox and IE don't support this.
     * @param media
     */
    private onPrintMediaQuery = (ev: MediaQueryListEvent) => {
        const isPrinting = !!(ev && ev.media && ev.matches);

        if (isPrinting) {
            this.onBeforePrint();
        } else {
            this.onAfterPrint();
        }
    }

    /**
     * Event listener for onbeforeprint. This is called whenever a user initiates print either from
     * clicking the print button or using the Ctrl+P shortcut. It will be called when print starts.
     */
    private onBeforePrint = (mediaQuery: boolean = false) => {
        setIsPrinting(true);

        if (this.props.listeners) {
            this.props.listeners.forEach((listener) => listener.onBeforePrint());
        }
    }

    /**
     * Event listener for onafterprint. It will be called when print completes.
     */
    private onAfterPrint = () => {
        setIsPrinting(false);
        setControlKeyDown(false);

        if (this.props.listeners) {
            this.props.listeners.forEach((listener) => listener.onAfterPrint());
        }
    }

    render() {
        const { children } = this.props;

        if (!children) {
            return null;
        }

        return <>{ children }</>;
    }
}