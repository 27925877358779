import * as React from "react";
import { DelayedRender } from "@fluentui/react";
import { SHOW_DYNAMIC_BUNDLE_SPINNER_DELAY } from "sh-application/../StaffHubConstants";
import Spinner, { SpinnerSize } from "sh-application/components/common/Spinner";

const styles = require("./DynamicRouteSpinner.scss");

/**
 * A spinner that shows up (after a delay) while dynamically loading bundles for the
 * next navigation route. The delay helps to only show the spinner if it is taking
 * a while to download the bundle.
 */
export default class DynamicRouteSpinner extends React.Component<{}, {}> {

    render() {
        return (
            <DelayedRender delay={ SHOW_DYNAMIC_BUNDLE_SPINNER_DELAY }>
                <div className={ styles.loadingScreen }>
                    <div className={ styles.loading }>
                        <Spinner size={ SpinnerSize.small } />
                    </div>
                </div>
            </DelayedRender>
        );
    }
}