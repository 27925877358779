import FilterableCommandBarItem, { DisplayOption } from "../commandbar/FilterableCommandBarItem";
import { AriaRoles } from "owa-accessibility";

const styles = require("./SaveStatusCommandBarItem.scss");
const classNames = require("classnames/bind");

export enum SaveStates {
    Loaded = 1, // Loaded for the first time
    Saving, // Being saved state
    Saved, // Successfully saved state
    SaveError // Failure while saving
}

const menuItemKeys = {
    savingMessage: "savingMessageMenuItemKey",
    successMessage: "successMessageMenuItemKey",
    failureMessage: "failureMessageMenuItemKey"
};

/**
 * It hold CommandBar Items for SaveStates saving, success and failure
 * getCommandBarItemForState method return CommandBar item for given SaveState
 */
export class SaveStatusCommandBarItem {
    private _savingCommandBarItem: FilterableCommandBarItem = null;
    private _savedCommandBarItem: FilterableCommandBarItem = null;
    private _saveErrorCommandBarItem: FilterableCommandBarItem = null;

    public constructor(
        savingMessage: string,
        successMessage: string,
        failureMessage: string,
        disablePointerEvents: boolean = true,
        enableAriaNotification: boolean = false
    ) {
        const filterableCommandBar = FilterableCommandBarItem;
        this._savingCommandBarItem = filterableCommandBar.create({
            name: savingMessage,
            key: menuItemKeys.savingMessage,
            icon: "teams-sync-svg", // teams svg
            iconOnly: false /* iconOnly */,
            className: classNames(styles.savingCommandBarItem, {
                [`${styles.disabledPointerEvents}`]: disablePointerEvents
            }),
            displayType: DisplayOption.Right,
            role: enableAriaNotification ? AriaRoles.alert : null, // role
            ariaLabel: enableAriaNotification && savingMessage // Aria Label
        });

        this._savedCommandBarItem = filterableCommandBar.create({
            name: successMessage,
            key: menuItemKeys.successMessage,
            icon: "teams-check-svg", // teams svg
            iconOnly: false /* iconOnly */,
            className: classNames({ [`${styles.disabledPointerEvents}`]: disablePointerEvents }),
            displayType: DisplayOption.Right,
            role: enableAriaNotification ? AriaRoles.alert : null, // role
            ariaLabel: enableAriaNotification && successMessage // Aria Label
        });

        this._saveErrorCommandBarItem = filterableCommandBar.create({
            name: failureMessage,
            key: menuItemKeys.failureMessage,
            icon: "teams-warning-svg", // teams svg
            iconOnly: false /* iconOnly */,
            className: classNames({
                [`${styles.disabledPointerEvents}`]: disablePointerEvents
            }),
            displayType: DisplayOption.Right,
            role: enableAriaNotification ? AriaRoles.alert : null, // role
            ariaLabel: enableAriaNotification && failureMessage // Aria Label
        });
    }

    /**
     * Returns the appropriate CommandBar Items for the sync state.
     * This returns null if there is no command bar item
     * @param saveState The sync state
     */
    public getCommandBarItemForState(saveState: SaveStates): FilterableCommandBarItem {
        switch (saveState) {
            case SaveStates.Loaded:
                // No item for the loaded state
                return null;
            case SaveStates.Saving:
                // Being saved state
                return this._savingCommandBarItem;
            case SaveStates.Saved:
                // Successfully saved state
                return this._savedCommandBarItem;
            case SaveStates.SaveError:
                // Failure while saving
                return this._saveErrorCommandBarItem;
        }
    }
}
