import * as React from "react";
import AutomationUtil from "sh-application/utility/AutomationUtil";
import AvailabilityUtils from "sh-application/utility/AvailabilityUtils";
import classNames from "classnames/bind";
import ConflictUtils from "sh-application/utility/ConflictUtils";
import DateTimeFormatter from "sh-application/utility/DateTimeFormatter";
import DateUtils from "sh-application/utility/DateUtils";
import MemberUtils from "sh-application/utility/MemberUtils";
import ShiftUtils from "sh-application/utility/ShiftUtils";
import StringsStore from "sh-strings/store";
import { AvailabilityStore } from "sh-availability-store";
import { conflictRequestStore } from "sh-stores/sh-requestconflict-store";
import {
    ConflictType,
    IAvailabilityEntity,
    IBaseConflictEntity,
    IBaseShiftEntity,
    IShiftEntity,
    IShiftRequestEntity,
    ShiftRequestTypes,
    ShiftTypes
    } from "sh-models";
import { fireAccessibilityAlert } from "sh-application/components/accessibilityAlert";
import { Icon, List } from "@fluentui/react";
import { TeamStore } from "sh-stores/sh-team-store";

const styles = require("./RequestConflict.scss");
export interface RequestConflictProps {
    conflictEntities: IBaseConflictEntity[]; // list of conflicting entities for a request shift
    shiftRequest: IShiftRequestEntity;       // the shift request entity having conflicts
    shiftWithConflict: IBaseShiftEntity;     // request shift which has conflict
    memberId: string;                        // receiver or sender member Id for whom conflicts are calculated
    hideConflictMessage?: boolean;           // Boolean to hide conflicts message and icon
    hasConflicts?: boolean;                  // Boolean to indicate is the request has a conflict
}

/**
 * RequestConflict - Displays all the conflicts a request has
 */
export default class RequestConflict extends React.PureComponent<RequestConflictProps, any> {

    private _conflictStrings: Map<string, string>;
    private _shiftDay: string;
    private _shiftEndDay: string;

    constructor(props: RequestConflictProps) {
        super(props);
        this._conflictStrings = StringsStore().registeredStringModules.get("conflictManagement").strings;

        this._shiftDay = this.props.shiftWithConflict ? DateUtils.getMonthAndDateForDisplay(this.props.shiftWithConflict.startTime) : "";
        this._shiftEndDay = this.props.shiftWithConflict ?  DateUtils.getMonthAndDateForDisplay(this.props.shiftWithConflict.endTime) : "";
    }

    /**
     * Returns description strings for different types of conflicts
     * @param conflictType conflict type
     * @param conflictingShift the conflicting shift
     * @param conflictingAvailability the conflicting availability
     */
    private getConflictDetailsString(conflictType: ConflictType, conflictingShift?: IShiftEntity, conflictingAvailability?: IAvailabilityEntity): string {
        const memberDisplayName = this.getMemberNameFromShift();
        const groupName = conflictingShift ? ConflictUtils.getGroupNameFromShift(conflictingShift) : "";
        const shiftTimeRange = conflictingShift ? this.getShiftTimeRange(conflictingShift) : "";
        const conflictingShiftDay = conflictingShift ?  DateUtils.getMonthAndDateForDisplay(conflictingShift.startTime) : "";
        const shiftType = conflictingShift && conflictingShift.shiftType;

        switch (conflictType) {
            case ConflictType.ShiftAvailabilityConflict: {
                if (conflictingAvailability) {
                    return this.getAvailabilityForDayString(memberDisplayName, conflictingAvailability);
                } else {
                    return "";
                }
            }
            case ConflictType.OverlappingShiftConflict:
            case ConflictType.ShiftTimeOffConflict: {
                if (conflictingShift) {
                    if (shiftType && shiftType === ShiftTypes.Working) {
                        return this._conflictStrings.get("requestShiftConflictDetails").format(memberDisplayName, groupName, conflictingShiftDay , shiftTimeRange);
                    }
                    return this._conflictStrings.get("requestTimeOffConflictDetails").format(memberDisplayName, this._shiftDay);
                } else {
                    return "";
                }
            }
            default: {
                return "";
            }
        }
    }

    /**
     * Returns true if the availability conflict is coming from the next day for crossover and 24hr shifts
     * @param shift conflicting shift
     * @param conflictingAvailability availability conflict
     */
    private isAvailabilityFromNextDay(shift: IShiftEntity, conflictingAvailability: IAvailabilityEntity): boolean {
        const shiftDayOfWeek = shift.startTime && ShiftUtils.getShiftDayOfWeek(shift.startTime);
        const availabilityDayOfWeek = AvailabilityUtils.getConflictingAvailabilityDayOfWeek(conflictingAvailability);
        // for case of shifts that span two dates the availability date will be coming from the shift end date
        return (shiftDayOfWeek.length > 0 && availabilityDayOfWeek.length > 0 && shiftDayOfWeek !== availabilityDayOfWeek);
    }

    /**
     * Returns the string in which mentions member availability
     * @param memberDisplayName Member name who has the availability conflicts
     * @param conflictingAvailability the conflicting availabilty
     */
    private getAvailabilityForDayString(memberDisplayName: string, conflictingAvailability?: IAvailabilityEntity): string {
        if (!conflictingAvailability) {
            return "";
        }
        const isAvailabilityFromNextDay = this.props.shiftWithConflict && this.isAvailabilityFromNextDay(this.props.shiftWithConflict, conflictingAvailability);
        const availabilityDate = isAvailabilityFromNextDay ? this._shiftEndDay : this._shiftDay;
        if (AvailabilityUtils.isUnavailable(conflictingAvailability)) {
            return this._conflictStrings.get("availabilityConflict").format(memberDisplayName, availabilityDate);
        } else if (AvailabilityUtils.hasTimeSlots(conflictingAvailability)) {
            const timeSlots = ConflictUtils.getAvailabilityTimeSlotString(conflictingAvailability);
            return this._conflictStrings.get("requestAvailabilityDetails").format(memberDisplayName, availabilityDate, timeSlots.toString());
        }
        return "";
    }

    /**
     * Returns the time range of conflicting shift
     * @param conflictingShift - the conflicting shift
     */
    private getShiftTimeRange(conflictingShift: IShiftEntity) {
        if ( !conflictingShift ) {
            return "";
        }
        return DateTimeFormatter.getEventTimeRangeAsString(conflictingShift.startTime, conflictingShift.endTime);
    }

    /**
     * Returns the Member name for the conflicting shifts
     */
    private getMemberNameFromShift(): string {
        const { memberId } = this.props;
        if (memberId) {
            return MemberUtils.getDisplayNameFromMemberId(memberId);
        }
        return "";
    }

    /**
     * Every conflict is rendered with its details as description title and conflicting shift
     */
    private renderListItem = (baseConflictEntity: IBaseConflictEntity, index: number | undefined): JSX.Element => {
        const { shiftRequest, memberId } = this.props;

        let shiftId = shiftRequest && shiftRequest.shiftId;
        if (shiftRequest.requestType === ShiftRequestTypes.Swap) {
            // In case of swap senderMember needs to check conflicts with receiver member shifts and vice versa
            shiftId = memberId === shiftRequest.senderMemberId ? shiftRequest.otherShiftInfo.shiftId : shiftRequest.shiftId;
        }
        let conflictingAvailability: IAvailabilityEntity;
        let conflictingShift: IShiftEntity;
        // Get type of conflictEntity object and its corresponding conflicting shift id.
        const conflictingId: string = ConflictUtils.getConflictingEntityId(baseConflictEntity, shiftId);
        let conflictType = baseConflictEntity.conflictType;

        if (baseConflictEntity.conflictType === ConflictType.OverlappingShiftConflict || baseConflictEntity.conflictType === ConflictType.ShiftTimeOffConflict) {
            // We look up the conflicting shift in the conflicts requests store because the request date range can be in future or past, and the shifts might not be in ShiftStore cache range
            conflictingShift = conflictRequestStore().memberShifts && conflictRequestStore().memberShifts.get(conflictingId);
            // in case of grouped open shifts, the same openshift is conficting with multiple member shifts
            // hence need memeber ID check here to display conflict only from member with conflicting shits
            if (shiftRequest.requestType === ShiftRequestTypes.Open && conflictingShift && conflictingShift.memberId !== memberId) { return null; }
        } else {
            conflictingAvailability = AvailabilityUtils.getAvailabilityFromId(AvailabilityStore().membersAvailabilities, conflictingId, memberId);
        }
        return (
            <div className={ styles.conflictDetails } data-automation-id={ AutomationUtil.getAutomationId("conflictCallout", "QAIDConflictTypeTitle") } >
                { this.getConflictDetailsString(conflictType, conflictingShift, conflictingAvailability) }
            </div>);
    }

    /**
     * Render List of conflicts for a shift
     * @param conflictEntities The list of conflicts for a shift
     */
    private renderConflictTypeList(conflictEntities: IBaseConflictEntity[]): JSX.Element {
        fireAccessibilityAlert(this._conflictStrings.get("requestShiftConflict"));
        return (
            <List
                items={ conflictEntities }
                onRenderCell={ this.renderListItem }
                data-automation-id={ AutomationUtil.getAutomationId("conflictCallout", "QAIDConflictList") }
            />);
    }

    render() {
        const { conflictEntities, hideConflictMessage, hasConflicts } = this.props;
        const currentUser = TeamStore() && TeamStore().me;
        const isAdmin = MemberUtils.isAdmin(currentUser);

        if (!conflictEntities || conflictEntities.length === 0) {
            return null;
        }
        return (
            (hasConflicts && isAdmin) &&
            <div className={ styles.requestConflictContainer }>
                     { !hideConflictMessage &&
                        <div className={ styles.requestConflictMessage } data-automation-id={ AutomationUtil.getAutomationId("conflictCallout", "QAIDRequestConflictMesssage") }>
                            <Icon className={ classNames(styles.conflictIcon, styles.red) } iconName={ "teams-conflict-icon" }></Icon>
                            { this._conflictStrings.get("requestShiftConflict") }
                        </div>
                    }
                { this.renderConflictTypeList(conflictEntities) }
            </div>
        );
    }
}