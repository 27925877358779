import {
    IShiftEntity,
    IShiftResponseEntity,
    IShiftResponseMultipleEntity
    } from "sh-models";

/**
 * Utilities for parsing rule violations
 */
export default class RuleViolationUtils {
    public static createShiftResponseMultipleEntity(shiftsAndAlerts: IShiftResponseEntity[]): IShiftResponseMultipleEntity {
        let shifts: Array<IShiftEntity> = [];
        let alerts: Array<string> = [];
        shiftsAndAlerts.forEach((shiftAndAlert: IShiftResponseEntity) => {
            shifts.push(shiftAndAlert.shift);
            alerts.concat(shiftAndAlert.alerts);
        });
        return {
            shifts: shifts,
            alerts: alerts
        };
    }
}
