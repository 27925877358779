import { action } from "satcheljs";

const showWelcome = action(
    "SHOW_WELCOME",
    (
        visible: boolean
    ) => ({
        visible: visible
    })
);

export default showWelcome;
