import ChangeStore from "../store";
import { action } from "satcheljs/lib/legacy";
import { FlightKeys } from "sh-models";
import { FlightSettingsService } from "sh-services";

/**
 * Clear all the currently tracked changes
 */
export let clearTrackedChanges = action("clearTrackedChanges")(
    function clearTrackedChanges() {
        if (!FlightSettingsService.isFlightEnabled(FlightKeys.EnableShiftUndoWeb)) {
            return;
        }
        ChangeStore().undoStack.clear();
        ChangeStore().redoStack.clear();
    });
