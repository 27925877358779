import { BaseConflictEntity, IShiftConflictEntity } from "sh-models";
import { ConflictType } from "..";

export default class ShiftConflictEntity extends BaseConflictEntity implements IShiftConflictEntity {
    // conflicting shift entity id for overlapping shifts
    conflictingShiftId: string;

    constructor(
        memberId: string,
        shiftId: string,
        conflictType: ConflictType,
        isDismissed: boolean,
        conflictingShiftId: string
    ) {
        super(memberId,
                shiftId,
                conflictType,
                isDismissed);
        this.conflictingShiftId = conflictingShiftId;
    }
}