import { mutator } from "satcheljs";

import { setSearchError } from "../actions/setSearchError";
import memberEditorViewState from "../store";

/**
 * Sets search error in the state.
 */
export const setSearchErrorMutator = mutator(setSearchError, (action) => {
    const { searchError } = action;

    memberEditorViewState().searchError = searchError;
});
