import { action } from "satcheljs";

import { LoadUserPictureAction } from "./LoadUserPictureAction";

/**
 * Loads the user profile picture.
 */
export const loadUserPicture = action<LoadUserPictureAction, (userId: string) => LoadUserPictureAction>(
    "LOAD_USER_PICTURE",
    (userId: string) => ({ userId })
);
