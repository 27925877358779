import { AuthenticationProvider, ClientOptions, Client as GraphClient } from "@microsoft/microsoft-graph-client";
import { Document, ODataResponse, UserProfile } from "@microsoft/shifts-models-data";
import { trace } from "owa-trace";
import { getGenericEventPropertiesObject } from "sh-instrumentation";
import { InstrumentationService } from "sh-services";

import { GraphAuthenticationProvider } from "./GraphAuthenticationProvider";
import { ProfilePhotoSize } from "./ProfilePhotoSize";
import { SearchGroupMembersParams } from "./SearchGroupMembersParams";

/**
 * The graph service client.
 */
export class GraphService {
    /**
     * The Graph authentication provider.
     */
    private authenticationProvider: AuthenticationProvider;

    /**
     * The Graph service client.
     */
    private graphClient: GraphClient;

    /**
     * Initializes a new instance of the GraphService class.
     * @param authenticationProvider The authentication provider.
     * @param graphClient The graph client.
     * @returns An instance of GraphService.
     */
    constructor(authenticationProvider: AuthenticationProvider, graphClient: GraphClient) {
        if (graphClient == null) {
            throw new Error("'graphClient' can't be null.");
        }

        this.authenticationProvider = authenticationProvider;
        this.graphClient = graphClient;
    }

    /**
     * Initializes a new instance of the GraphService class.
     * @param authenticationProvider The authentication provider to get the Graph service access token.
     * @returns An instance of GraphService.
     */
    public static createWithAuthenticationProvider(authenticationProvider: AuthenticationProvider): GraphService {
        if (authenticationProvider == null) {
            throw new Error("'authenticationProvider' can't be null.");
        }

        const clientOptions: ClientOptions = {
            authProvider: authenticationProvider
        };

        return new GraphService(authenticationProvider, GraphClient.initWithMiddleware(clientOptions));
    }

    /**
     * Gets users profiles from given users ids.
     * @param usersIds The users ids.
     * @returns The users profiles.
     */
    public async getUsersProfiles(usersIds: string[]): Promise<UserProfile[]> {
        // Can only request 15 users profiles in one request: https://learn.microsoft.com/en-us/graph/filter-query-parameter?tabs=http#operators-and-functions-supported-in-filter-expressions
        const maxUsersIdsPerRequest = 15;
        const requestsCount = Math.ceil(usersIds.length / maxUsersIdsPerRequest);
        const requests = [];

        for (let index = 0; index < requestsCount; ++index) {
            const startIndex = index * maxUsersIdsPerRequest;
            const endIndex = (index + 1) * maxUsersIdsPerRequest;
            const usersIdsBatch = usersIds.slice(startIndex, endIndex);

            requests.push(this.getUsersProfilesBatch(usersIdsBatch));
        }

        const results = await Promise.allSettled(requests);

        const users = results.reduce<UserProfile[]>((users, result) => {
            if (result.status == "fulfilled") {
                return users.concat(result.value);
            } else if (result.status == "rejected") {
                throw new Error(result.reason);
            }

            return users;
        }, []);

        return users;
    }

    /**
     * Gets users profiles from given users ids.
     * @param usersIds The users ids.
     * @returns The users profiles.
     */
    private async getUsersProfilesBatch(usersIds: string[]): Promise<UserProfile[]> {
        const graphClient = this.getGraphClient();
        const startTimeStamp = InstrumentationService.getCurrentTimeStamp();
        const ids = usersIds.map(id => `'${encodeRFC3986URIComponent(id)}'`).join(",");

        const url = [
            "/users?$count=true",
            "&$select=displayName,givenName,id,surname,userPrincipalName",
            `&$filter=id in (${ids})`
        ].join("");

        try {
            const response: ODataResponse<UserProfile[]> = await graphClient.api(url).get();

            InstrumentationService.logPerfEvent(
                InstrumentationService.events.GraphApiRequestCompleted,
                [getGenericEventPropertiesObject(InstrumentationService.properties.RequestURL, url)],
                { duration: InstrumentationService.getCurrentTimeStamp() - startTimeStamp }
            );

            trace.info(`Graph API call completed: ${url}`);

            return response.value;
        } catch (error) {
            InstrumentationService.logPerfEvent(
                InstrumentationService.events.GraphApiRequestFailed,
                [getGenericEventPropertiesObject(InstrumentationService.properties.RequestURL, url)],
                { duration: InstrumentationService.getCurrentTimeStamp() - startTimeStamp }
            );

            throw error;
        }
    }

    /**
     * Gets the user profile photo url (Browser blob url).
     * @param userId The user id.
     * @param size The profile photo size.
     * @returns The user profile photo url.
     */
    public async getUserProfilePhotoBlobUrl(userId: string, size: ProfilePhotoSize = ProfilePhotoSize.Size_48x48): Promise<string> {
        const relativeUrl = `/users/${userId}/photos/${size}/$value`;
        const startTimeStamp = InstrumentationService.getCurrentTimeStamp();

        try {
            const image = await this.graphClient.api(relativeUrl).get();

            InstrumentationService.logPerfEvent(
                InstrumentationService.events.GraphApiRequestCompleted,
                [getGenericEventPropertiesObject(InstrumentationService.properties.RequestURL, relativeUrl)],
                { duration: InstrumentationService.getCurrentTimeStamp() - startTimeStamp }
            );

            const url = window.URL ? window.URL : window.webkitURL;
            const blobUrl = url.createObjectURL(image);

            return blobUrl;
        } catch (error) {
            InstrumentationService.logPerfEvent(
                InstrumentationService.events.GraphApiRequestFailed,
                [getGenericEventPropertiesObject(InstrumentationService.properties.RequestURL, relativeUrl)],
                { duration: InstrumentationService.getCurrentTimeStamp() - startTimeStamp }
            );

            return null;
        }
    }

    /**
     * TODO: Move this to a PersonaManager using Graph service client.
     * Searches group members from given group id and filters.
     * @param params The search parameters.
     * @returns The list of group members matching filters.
     */
    public async searchGroupMembers(params: SearchGroupMembersParams): Promise<UserProfile[]> {
        const { filters, groupId } = params;
        const graphClient = this.getGraphClient(params.abortController);
        const startTimeStamp = InstrumentationService.getCurrentTimeStamp();

        const url = [
            `/groups/${groupId}/members/microsoft.graph.user?$count=true&$orderby=displayName`,
            "&$select=displayName,givenName,id,surname,userPrincipalName",
            `&$filter=startswith(displayName, '${encodeRFC3986URIComponent(filters.displayNameStartsWith)}')`
        ].join("");

        try {
            const response: ODataResponse<UserProfile[]> = await graphClient.api(url).header("ConsistencyLevel", "eventual").get();

            InstrumentationService.logPerfEvent(
                InstrumentationService.events.GraphApiRequestCompleted,
                [getGenericEventPropertiesObject(InstrumentationService.properties.RequestURL, url)],
                { duration: InstrumentationService.getCurrentTimeStamp() - startTimeStamp }
            );

            trace.info(`Graph API call completed: ${url}`);

            return response.value;
        } catch (error) {
            InstrumentationService.logPerfEvent(
                InstrumentationService.events.GraphApiRequestFailed,
                [getGenericEventPropertiesObject(InstrumentationService.properties.RequestURL, url)],
                { duration: InstrumentationService.getCurrentTimeStamp() - startTimeStamp }
            );

            throw error;
        }
    }

    /**
     * Lists given group members.
     * @param groupId The group unique identifier.
     * @returns The list of group members.
     */
    public async listGroupMembers(groupId: string): Promise<UserProfile[]> {
        const MAX_USERS_COUNT_PER_REQUEST = 999;
        const graphClient = this.graphClient;
        const startTimeStamp = InstrumentationService.getCurrentTimeStamp();

        const baseUrl = [
            `/groups/${groupId}/members/microsoft.graph.user?$count=true`,
            "&$select=displayName,givenName,id,surname,userPrincipalName&$orderby=displayName",
            `&$top=${MAX_USERS_COUNT_PER_REQUEST}`
        ].join("");

        try {
            let users: UserProfile[];
            let response: ODataResponse<UserProfile[]> = await graphClient.api(baseUrl).header("ConsistencyLevel", "eventual").get();
            const count = response["@odata.count"];

            users = response.value;

            while (response["@odata.nextLink"]) {
                response = await graphClient
                    .api(`${baseUrl}&$skiptoken=${response["@odata.nextLink"]}`)
                    .header("ConsistencyLevel", "eventual")
                    .get();

                users = users.concat(response.value);
            }

            InstrumentationService.logPerfEvent(
                InstrumentationService.events.GraphApiRequestCompleted,
                [
                    getGenericEventPropertiesObject(InstrumentationService.properties.RequestURL, baseUrl),
                    getGenericEventPropertiesObject(InstrumentationService.properties.ResponseValueCount, count)
                ],
                { duration: InstrumentationService.getCurrentTimeStamp() - startTimeStamp }
            );

            trace.info(`Graph API call completed: ${baseUrl} with total value count: ${count}`);

            return users;
        } catch (error) {
            InstrumentationService.logPerfEvent(
                InstrumentationService.events.GraphApiRequestFailed,
                [getGenericEventPropertiesObject(InstrumentationService.properties.RequestURL, baseUrl)],
                { duration: InstrumentationService.getCurrentTimeStamp() - startTimeStamp }
            );

            throw error;
        }
    }

    /**
     * Lists given group owners unique identifiers.
     * @param groupId The group unique identifier.
     * @returns The group owners unique identifiers.
     */
    public async listGroupOwnerIds(groupId: string): Promise<string[]> {
        const graphClient = this.graphClient;
        const startTimeStamp = InstrumentationService.getCurrentTimeStamp();
        const url = `/groups/${groupId}/owners/microsoft.graph.user?$select=id`;

        try {
            const response: ODataResponse<Document[]> = await graphClient.api(url).get();
            const usersIds = response.value.map(({ id }) => id);

            InstrumentationService.logPerfEvent(
                InstrumentationService.events.GraphApiRequestCompleted,
                [
                    getGenericEventPropertiesObject(InstrumentationService.properties.RequestURL, url),
                    getGenericEventPropertiesObject(InstrumentationService.properties.ResponseValueCount, usersIds.length)
                ],
                { duration: InstrumentationService.getCurrentTimeStamp() - startTimeStamp }
            );

            trace.info(`Graph API call completed: ${url} with total value count: ${usersIds.length}`);
            return usersIds;
        } catch (error) {
            InstrumentationService.logPerfEvent(
                InstrumentationService.events.GraphApiRequestFailed,
                [getGenericEventPropertiesObject(InstrumentationService.properties.RequestURL, url)],
                { duration: InstrumentationService.getCurrentTimeStamp() - startTimeStamp }
            );

            throw error;
        }
    }

    /**
     * Gets existing Graph service client if no abort controller is given, else create new one.
     * @param abortController The abort controller to allow aborting graph requests.
     * @returns The Graph service client.
     */
    private getGraphClient(abortController?: AbortController): GraphClient {
        if (!abortController) {
            return this.graphClient;
        }

        const clientOptions: ClientOptions = {
            authProvider: this.authenticationProvider,
            fetchOptions: {
                signal: abortController.signal
            }
        };

        return GraphClient.initWithMiddleware(clientOptions);
    }
}

const service = GraphService.createWithAuthenticationProvider(new GraphAuthenticationProvider());

export default service as GraphService;

/**
 * Encodes given string for RFC3986 to escape special characters (e.g. !'()*) not covered by 'encodeURIComponent' from JavaScript API.
 * In our case, we want to escape the single quote character to ensure search query from user input is not conflicting with the filter string quotes.
 * @example encodeRFC3986URIComponent("(test)") == "%28test%29"
 * @param str The value to encode.
 * @returns The encoded value.
 */
function encodeRFC3986URIComponent(str: string): string {
    return encodeURIComponent(str).replace(/[!'()*]/g, c => `%${c.charCodeAt(0).toString(16).toUpperCase()}`);
}
