import MemberUtils from "sh-application/utility/MemberUtils";
import { ConflictStore } from "sh-conflict-store";
import { ConflictType } from "sh-models";
import { mutatorAction } from "satcheljs";

export default mutatorAction('DECREMENT_CONFLICT_COUNT', function decrementConflictCount(memberId: string, conflictType: ConflictType) {
   ConflictStore().conflictCount.totalConflictCount = ConflictStore().conflictCount.totalConflictCount - 1 > 0
               ? ConflictStore().conflictCount.totalConflictCount - 1
               : 0;

   const isCurrentUser = MemberUtils.getCurrentUserMemberId() === memberId;
   if (isCurrentUser) {
      ConflictStore().myConflictCount.totalConflictCount = ConflictStore().myConflictCount.totalConflictCount - 1 > 0
                  ? ConflictStore().myConflictCount.totalConflictCount - 1
                  : 0;
   }

   switch (conflictType) {
      case ConflictType.OverlappingShiftConflict:
         ConflictStore().conflictCount.overlappingShiftConflictCount = ConflictStore().conflictCount.overlappingShiftConflictCount - 1 > 0
                     ? ConflictStore().conflictCount.overlappingShiftConflictCount - 1
                     : 0;
         if (isCurrentUser) {
            ConflictStore().myConflictCount.overlappingShiftConflictCount = ConflictStore().myConflictCount.overlappingShiftConflictCount - 1 > 0
                        ? ConflictStore().myConflictCount.overlappingShiftConflictCount - 1
                        : 0;
         }
         break;
      case ConflictType.ShiftAvailabilityConflict:
         ConflictStore().conflictCount.shiftAvailabilityConflictCount = ConflictStore().conflictCount.shiftAvailabilityConflictCount - 1 > 0
                     ? ConflictStore().conflictCount.shiftAvailabilityConflictCount - 1
                     : 0;
         if (isCurrentUser) {
            ConflictStore().myConflictCount.shiftAvailabilityConflictCount = ConflictStore().myConflictCount.shiftAvailabilityConflictCount - 1 > 0
                        ? ConflictStore().myConflictCount.shiftAvailabilityConflictCount - 1
                        : 0;
         }
         break;
      case ConflictType.ShiftTimeOffConflict:
         ConflictStore().conflictCount.shiftTimeoffConflictCount = ConflictStore().conflictCount.shiftTimeoffConflictCount - 1 > 0
                     ? ConflictStore().conflictCount.shiftTimeoffConflictCount - 1
                     : 0;
         if (isCurrentUser) {
            ConflictStore().myConflictCount.shiftTimeoffConflictCount = ConflictStore().myConflictCount.shiftTimeoffConflictCount - 1 > 0
                        ? ConflictStore().myConflictCount.shiftTimeoffConflictCount - 1
                        : 0;
         }
         break;
      default: break;
   }
});
