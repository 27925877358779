import * as MemoizeUtils from "sh-application/utility/MemoizeUtils";
import * as React from "react";
import AccessibilityUtils from "sh-application/utility/AccessibilityUtils";
import AutomationUtil from "sh-application/utility/AutomationUtil";
import AvailabilityUtils from "sh-application/utility/AvailabilityUtils";
import confirm from "sh-confirm-dialog/lib/actions/confirm";
import ConflictUtils from "sh-application/utility/ConflictUtils";
import DateUtils from "sh-application/utility/DateUtils";
import InstrumentationUtils from "sh-application/utility/InstrumentationUtils";
import MemberUtils from "sh-application/utility/MemberUtils";
import OpenShiftTitleCell from "sh-application/components/schedule/cells/OpenShiftTitleCell";
import ScheduleCell from "sh-application/components/schedule/cells/ScheduleCell";
import ScheduleMemberCell from "sh-application/components/schedule/cells/ScheduleMemberCell";
import schedulesViewStateStore from "sh-application/components/schedules/lib/store/store";
import SelectableContentWrapper from "sh-application/components/common//SelectableContentWrapper";
import ShiftUtils from "sh-application/utility/ShiftUtils";
import showConfirmDialog from "sh-confirm-dialog/lib/components/ConfirmDialog";
import StringsStore from "sh-strings/store";
import TagHeader from "sh-application/components/schedule/rows/TagHeader";
import TagUtils from "sh-application/utility/TagUtils";
import triggerShiftMove from "sh-application/actions/triggerShiftMove";
import { AriaProperties, AriaRoles, generateDomPropertiesForAria } from "owa-accessibility";
import { calculateScheduleHeaderCellElementId } from "sh-application/components/schedules/header/ScheduleHeaderRow";
import {
    ChangeSource,
    EmployeeViewType,
    EmployeeViewTypes,
    FlightKeys,
    IAvailabilityEntity,
    IBaseShiftEntity,
    IGroupedOpenShiftRequestEntity,
    IMemberEntity,
    IOpenShiftEntity,
    IShiftEntity,
    IShownAvailabilitiesMap,
    ISubshiftEntity,
    ITagEntity,
    ITimeOffReasonEntity,
    OpenShiftEntity,
    ScheduleCalendarType,
    ScheduleCalendarTypes,
    ScheduleOverViewTypes,
    ShiftEntity,
    ShiftFilterType,
    ShiftFilterTypes,
    ShiftMoveActionTypes,
    ShiftRequestState,
    ShiftRequestType,
    TagStates
    } from "sh-models";
import { computed } from "mobx";
import {
    DayViewFormatCellInterval,
    DayViewFormatEndHours,
    DayViewFormatStartHours,
    DUMMY_SELECTION_CELL_KEY,
    ScheduleCellRenderSize,
    SHIFT_CELL_CONTEXT_MENU_CALLOUT
    } from "sh-application/../StaffHubConstants";
import {
    ECSConfigKey,
    ECSConfigService,
    FlightSettingsService,
    InstrumentationService
    } from "sh-services";
import { EmptyCellInvokeFunction, PeopleViewGridProps, ShiftCellInvokeFunction } from "sh-application/components/schedule/grids/PeopleViewGrid";
import {
    FLEXICELL_CONTENT_WRAPPER_CLASS,
    FlexiGridRowSettings,
    FlexiGroupedGridGroupSettings,
    FlexiRowCellSettings
    } from "sh-flexigrid";
import {
    GenerateMemberShiftsDataGroupsFunction,
    GroupedShiftsData,
    GroupedTagData,
    MemberShiftsData,
    MemberShiftsDataGroup,
    ScheduleData,
    TemporalItem,
    TemporalItemTypes
    } from "sh-application/components/schedule/ScheduleData";
import { getAvailability } from "sh-availability-store";
import { GetFlexItemSelectedFunction, SetFlexItemSelectedFunction, SetGridCellSelectedFunction } from "sh-application/components/activity/ActivityFlexContainer";
import { getGenericEventPropertiesObject } from "sh-instrumentation";
import { InstrumentScheduleEventFunction } from "sh-application/components/schedules/Schedules";
import { IObjectWithKey, Selection } from "@fluentui/react";
import {
    IScheduleGridCallbacks,
    IScheduleGridSettings,
    IScheduleSelectionData,
    IScheduleViewSettings
    } from "sh-application/components/schedule/grids/lib/ScheduleGridSettings";
import { Moment } from "moment";
import { ScheduleCellActionCallbacks } from "sh-application/components/schedule/cells/ScheduleCell";
import { ScheduleGridSelection } from "sh-application/components/schedule/selection/ScheduleGridSelection";
import { setfeatureClickedBeforeDataFetched } from "..";
import { StaffHubHttpError } from "sh-application";
import { TeamStore } from "sh-team-store";
import { trace } from "owa-trace";
import { UserStore, UserStoreSchema } from "sh-stores";
import { ScheduleGroupPeopleCountRowContainer } from "../../../schedule/rows/ScheduleGroupPeopleCountRowContainer";
import { getClassNames } from "../../../schedule/rows/ScheduleGroupPeopleCountRow.styles";

const styles = require("sh-application/components/schedule/grids/ScheduleGrid.scss");
const classNames = require("classnames/bind");

const DayViewStandardCellUnitSize = 1;
const DayViewHalfCellUnitSize = .5;

export enum ActivitySelectionItemType {
    Activity = 0,
    Empty = 1
}

export interface ActivitySelectionItem {
    shift: IBaseShiftEntity;
    itemType: ActivitySelectionItemType;
    startTime: Moment;
    endTime: Moment;
    activity?: ISubshiftEntity;
}

export enum ScheduleCellType {
    Empty = 0,
    Shift = 1,
    StaffPerThirty = 2
}

/**
 * Simple type that wraps a number. Used to increment indices so that changes to the value persist for the caller when they are edited by function calls.
 */
export interface Index {
    value: number;
}

/**
 * Info specific to schedule grid cells that will be assigned to FlexiRowCellSettings info for each flexigrid cell
 */
export interface ScheduleCellContentsInfo {
    cellType: ScheduleCellType;                     // The type of the cell (Empty or Shift)
    shift?: IBaseShiftEntity;                       // The shift in the cell. Undefined if the cell is empty
    startTime: Moment;                              // The start time of the cell (not the start time of the shift in the cell). Used during copy and paste
    endTime: Moment;                                // The end time of the cell (not the end time of the shift in the cell). Used during copy and paste
    colIdxVal: number;                              // Represents which column of the schedule grid the cell is in. Used for copy and paste logic. Can be a non-integer in day view cases where half-sized 15 min cells are created.
    cellUnitSize: number;                           // Number of grid cells that this schedule cell occupies in a row
    member: IMemberEntity;                          // The member associated with the cell (the cell is in this member's rows). Null for open shifts
    tagId: string;                                  // The tagId associated with the cell (the cell is in this group). Null in ungrouped view
    isViewGrouped: boolean;                         // True in grouped view
    dataSelectionIndex: number;                     // The cell's selection index index, used by Fabric Selection to manage selection state
    scheduleCalendarType: ScheduleCalendarType;     // The type of grid the cell is in (day, week, month)
    viewStart: Moment;                              // The start of the view the cell is in. Used in Day View to adjust overnight shifts.
    availability?: IAvailabilityEntity;             // The availability of the user
    inOpenShiftRow: boolean;                        // True if the cell is in an open shift row
    hasConflicts?: boolean;                         // True if shift has conflicts
    cellInvokeCallback: () => void;                 // The callback fired on double click of the cell
}

interface MemberNameCellContentsItem {
    tagId: string;
    hasActiveShifts: boolean;
    member: IMemberEntity;
    hours: number;
    isDraggable: boolean;
    isOtherGroup: boolean;
    isMemberRemovedFromTag: boolean;
    isAdmin: boolean;
    isViewGrouped: boolean;

}

interface MemberDeleteCellContentsItem {
    hasActiveShifts: boolean;
    member: IMemberEntity;
    tagId: string;
}

export type CalculateGroupedGridSettingsForScheduleFunction =
    (scheduleGridSettings: IScheduleGridSettings) => FlexiGroupedGridGroupSettings[];

// Current cell indices while iterating through cells in the Schedule Grid
export interface IScheduleGridIndices {
    numCellColumns: number;         // Number of cell columns in the schedule grid
    dataSelectionIndex: number;     // Current data selection index. Used to assign unique selection indexes for each selectable cell.
    selectableCellIndex: number;    // Current selectable cell index. This is used to calculate the row + column positions of selected items in the grid.
    nthRowSet: number;              // Tracks the current number of members and open shift row sets rendered in the grid. If a member has two rows in the same group, this index is only incremented once
                                    // The same holds for open shift rows. This number is used to assign keys to the rows
}

// CSS classes used in the schedule grid
export enum ScheduleGridCssClasses {
    moreIcon = "moreIcon",              // More icon context menu button
    iconContainer = "iconContainer"     // Icon container for cells
}

/**
 * Utilities for ScheduleGrids
 * // TODO: these are not utils functions, these are render helpers and this shouldn't be living under utils folder
 */
export class ScheduleGridUtils {
    // Rendering settings for spacer rows between groups
    public static rowSettingsSpacer: FlexiGridRowSettings = {
        rowClass: styles.scheduleGroupSpacerRow,
        isDraggable: false,
        rowHeight: parseInt(styles.groupSpacerRowHeight)
    };

    public static cellSettingsSpacer: FlexiRowCellSettings[] = [
        {
            onRenderCellContents: (cellContentsItem: any) => {
                // Emit empty div for spacer row cell
                return (
                    <div></div>
                );
            }
        }
    ];

    private static _commonStrings: Map<string, string>;
    private static _schedulePageStrings: Map<string, string>;
    private static _otherGroupName: string;

    private static getCommonStrings(): Map<string, string> {
        if (!ScheduleGridUtils._commonStrings) {
            ScheduleGridUtils._commonStrings = StringsStore().registeredStringModules.get("common").strings;
        }

        return ScheduleGridUtils._commonStrings;
    }

    private static getSchedulePageStrings(): Map<string, string> {
        if (!ScheduleGridUtils._schedulePageStrings) {
            ScheduleGridUtils._schedulePageStrings = StringsStore().registeredStringModules.get("schedulePage").strings;
        }

        return ScheduleGridUtils._schedulePageStrings;
    }

    public static getOtherGroupName(): string {
        if (!ScheduleGridUtils._otherGroupName) {
            ScheduleGridUtils._otherGroupName = ScheduleGridUtils.getSchedulePageStrings().get("otherTagGroupName");
        }

        return ScheduleGridUtils._otherGroupName;
    }

    private static isAvailabilitiesEnabled(): boolean {
        return ECSConfigService.isECSFeatureEnabled(ECSConfigKey.EnableScheduleAvailability)
            && FlightSettingsService.isFlightEnabled(FlightKeys.EnableScheduleAvailability);
    }

    /**
     * Helper function that returns the count of cells that are currently selected.
     * Returns 0 if selection is not prensent
     * @param selection
     */
    public static getSelectedCount(selection: Selection) {
        return selection ? selection.getSelectedCount() : 0;
    }

    /**
     * Calculate the schedule cell size type to use for rendering schedule cells
     */
    public static calculateScheduleCellSizeType(scheduleCalendarType: ScheduleCalendarType): ScheduleCellRenderSize {
        switch (scheduleCalendarType) {
            case ScheduleCalendarTypes.Month:
                return ScheduleCellRenderSize.Small;
            case ScheduleCalendarTypes.Week:
                return ScheduleCellRenderSize.Normal;
            case ScheduleCalendarTypes.Day:
                return ScheduleCellRenderSize.Large;
        }
    }

    /**
     * Returns true if this is a small cell (example: month view cell)
     * @param scheduleCellRenderSize the cell render size
     */
    private static isRenderSmallCell(scheduleCellRenderSize: ScheduleCellRenderSize): boolean {
        return scheduleCellRenderSize === ScheduleCellRenderSize.Small;
    }

    /**
     * Returns true if this is a normal sized cell (example: week view cell)
     * @param scheduleCellRenderSize the cell render size
     */
    private static isRenderNormalCell(scheduleCellRenderSize: ScheduleCellRenderSize): boolean {
        return scheduleCellRenderSize === ScheduleCellRenderSize.Normal;
    }

    /**
     * Returns true if this is a large sized cell (example: day view cell)
     * @param scheduleCellRenderSize the cell render size
     */
    private static isRenderLargeCell(scheduleCellRenderSize: ScheduleCellRenderSize): boolean {
        return scheduleCellRenderSize === ScheduleCellRenderSize.Large;
    }

    /**
     * Calculate member shift rows data using grouped shifts data
     * This arranges grouped shifts data into data that represents groups that contain rows of members with their shifts.
     */
    public static calculateMemberShiftRowsData(groupedShiftsData: GroupedShiftsData,
        viewStartDate: Moment,
        viewEndDate: Moment,
        tags: Array<ITagEntity>,
        filteredTags: Array<ITagEntity>,
        timeOffReasons: Array<ITimeOffReasonEntity>,
        members: Array<IMemberEntity>,
        filteredMembers: Array<IMemberEntity>,
        scheduleCalendarType: ScheduleCalendarType,
        isViewGrouped: boolean): MemberShiftsDataGroup[] {

        const datesInRange: Moment[] = DateUtils.fastGetDatesInRange(viewStartDate, viewEndDate);

        let viewStartDateAndTime = viewStartDate.clone();
        let viewEndDateAndTime = viewEndDate.clone();
        if (ScheduleGridUtils.isDayView(scheduleCalendarType)) {
            viewStartDateAndTime = DateUtils.getDayViewStartDateAndTime(datesInRange[0], DayViewFormatStartHours);
            viewEndDateAndTime = DateUtils.getDayViewEndDateAndTime(datesInRange[0], DayViewFormatEndHours);
        }

        let generateMemberShiftsDataGroupFunction: GenerateMemberShiftsDataGroupsFunction = isViewGrouped ? ScheduleData.generateSortedMemberShiftsDataGroups : ScheduleData.generateUngroupedMemberShiftsDataGroups;
        return generateMemberShiftsDataGroupFunction(
            datesInRange,
            groupedShiftsData,
            tags,
            filteredTags,
            timeOffReasons,
            members,
            filteredMembers,
            scheduleCalendarType,
            viewStartDateAndTime,
            viewEndDateAndTime);
    }

    /**
     * function returns the Dialog title for dialog when loading fails
     */
    public static getLoadingFailedErrorDialogTitle() {
        return ScheduleGridUtils.getCommonStrings().get("dataLoadingErrorDialogTitle");
    }

    /**
     * function returns the Dialog subtitle for dialog when loading fails
     */
    public static getLoadingFailedErrordialogSubTitle() {
        return ScheduleGridUtils.getCommonStrings().get("dataLoadingErrorDialogSubTitle");
    }

    /**
     * function returns the Dialog refresh button text for dialog when loading fails
     */
    public static getRefreshButtonText() {
        return ScheduleGridUtils.getCommonStrings().get("refresh");
    }

    /**
     * function returns the string that is displayed in the banner when schedule completes loading
     */
    public static getScheduleMessageLoadedMessage() {
        return ScheduleGridUtils.getCommonStrings().get("scheduleLoadedMessage");
    }

    /**
     * function returns the Dialog title for dialog to notify user that feature is disabled while progressively loading
     * @param feature the name of the feature being disabled
     */
    private static getFeatureDisabledTitle = (feature: string): string => {
        return ScheduleGridUtils.getCommonStrings().get("featureDisabledNotificationTitle").format(feature);
    }

    /**
     * function returns the Dialog subtitle for dialog to notify user that feature is disabled while progressively loading
     * @param feature the name of the feature being disabled
     */
    private static getFeatureDisabledSubTitle = (feature: string): string => {
        return ScheduleGridUtils.getCommonStrings().get("featureDisabledNotificationSubTitle").format(feature);
    }

    /**
     * function returns true if atleast one of the shifts in the shifts entity array has conflicts
     * @param shiftEntities group or member shifts
     */
    public static shiftsHaveConflicts(shiftEntities: IShiftEntity[]): boolean {
        for (let shiftIndex = 0; shiftIndex < shiftEntities.length; shiftIndex++) {
            if (this.hasConflicts(shiftEntities[shiftIndex])) {
                return true;
            }
        }
        return false;
    }

    /**
     * Wrapper for confirm dialog that notify users that data is not loaded
     * @param scheduleCalendarType this is used to instrument current view
     * @param feature the feature string that needs to be displayed to the user
     */
    public static renderDisabledNotificationFromFeature(scheduleCalendarType: ScheduleCalendarType, feature: string) {
        ScheduleGridUtils.renderFeatureDisabledNotification(InstrumentationService.values.DataNotLoadedError, scheduleCalendarType, feature);
        setfeatureClickedBeforeDataFetched(true);
    }

    /**
     * renders Dialog to notify user that feature is disabled while progressively loading
     * @param typeOfErrorToInstrument type of error to instrument, for ex. dataloadederror
     * @calenderType the currentview that needs to be instrumented
     * @param feature the name of the feature being disabled
     * @disabledTitle if you dont pass the feature, you can pass your own title for the confirm dialog
     * @disabledSubTitle if dont pass the feature, you can pass your own subtitle for the confirm dialog
     * @eventToInstrument if dont pass the feature, you can pass what event to instrument
     */
    public static renderFeatureDisabledNotification = (typeOfErrorToInstrument: string, calenderType: ScheduleCalendarType, feature?: string, disabledTitle?: string, disabledSubTitle?: string,  eventToInstrument?: string) => {
        const featureTitle: string = feature ? ScheduleGridUtils.getFeatureDisabledTitle(feature) : disabledTitle;
        const featureSubTitle: string = feature ? ScheduleGridUtils.getFeatureDisabledSubTitle(feature) :  disabledSubTitle;

        showConfirmDialog(
            featureTitle,
            featureSubTitle,
            {
                okText: ScheduleGridUtils.getCommonStrings().get("close"), // OK button is primary button
                hideCancelButton: true,
                isBlocking: true
            },
            null,
            null,
            false /* should disable ok button */,
            false /* show close button on the top right corner  */,
            false /* call cancel callback on dismiss */);

            InstrumentationService.logEvent(InstrumentationService.events.LargeTeamsErrors,
                [getGenericEventPropertiesObject(InstrumentationService.properties.Error, typeOfErrorToInstrument),
                 getGenericEventPropertiesObject(InstrumentationService.properties.CurrentView, InstrumentationUtils.getCurrentViewForInstrumentation(calenderType)),
                 getGenericEventPropertiesObject(InstrumentationService.properties.TriggeredByFeature, feature || eventToInstrument)]);
    }

    /**
     * Utility function which determines if we should progressively render, depending on the flight settings
     * and the number of members in a schedule.
     */
    public static isProgressiveRenderingEnabled(): boolean {
        return ECSConfigService.isECSFeatureEnabled(ECSConfigKey.EnableProgressiveRendering) && ScheduleGridUtils.isLargeTeam();
    }

    /**
     * This function determines if we need to progressively render depending on number of members in the schedule,
     * the lower limit for a large team is not defined, and is possible that it could change often, hence added it as a ecs flag.
     * The idea is that we will enable progressive rendering for everyone, but only do it for teams that really need it.
     */
    public static isLargeTeam(): boolean {
        return (TeamStore() && TeamStore().members && TeamStore().members.size) > ECSConfigService.getECSFeatureSetting(ECSConfigKey.TeamSizeToEnableLargeTeamFeatures);
    }

    /**
     * Returns false if the scheduleGridSettings are invalid
     * @param scheduleGridSettings
     */
    public static isValidScheduleGridSettings(scheduleGridSettings: IScheduleGridSettings): boolean {
        const { scheduleViewSettings, scheduleSelectionData, scheduleGridCallbacks, scheduleGridData } = scheduleGridSettings;
        return Boolean(scheduleViewSettings && scheduleSelectionData && scheduleGridCallbacks && scheduleGridData && scheduleGridData.sortedMemberShiftsDataGroups);
    }

    /**
     * Calculate the grid settings for the schedule view
     * @param scheduleGridSettings contains settings determining how grid is to be layed out
     */
    public static calculateGroupedGridSettingsForSchedule: CalculateGroupedGridSettingsForScheduleFunction =
        (scheduleGridSettings: IScheduleGridSettings): FlexiGroupedGridGroupSettings[] => {
        const { scheduleViewSettings, scheduleSelectionData, scheduleGridCallbacks, scheduleGridData } = scheduleGridSettings;
        const { viewStartDate = null, viewEndDate = null } = scheduleViewSettings || {};

        if (!ScheduleGridUtils.isValidScheduleGridSettings(scheduleGridSettings)) {
                return null;
        }

        let gridGroupsSettings: FlexiGroupedGridGroupSettings[] = [];

        const datesInRange: Moment[] = DateUtils.fastGetDatesInRange(viewStartDate, viewEndDate);

        let scheduleGridIndices: IScheduleGridIndices = {
            numCellColumns: datesInRange.length,
            dataSelectionIndex: 0,
            selectableCellIndex: 0,
            nthRowSet: 0
        };

        // Initialize selection data used for the Office Fabric Selection component
        // For the Selection component:
        // 1.  We need to collect all the selectable items into an array and pass them to the Selection via Selection.setItems().
        // 2.  Each of these selectable items have an "data-selection-index" attribute whose value is the item's index in the Selection items array.
        // 3.  Each selectable item needs to have a unique selection key.  See the getCellSelectionKey/Selection.getKey callback setup.
        scheduleSelectionData.selectionCellItems = new Array<FlexiRowCellSettings>();
        let officeFabricSelection = scheduleSelectionData.scheduleGridSelection ? scheduleSelectionData.scheduleGridSelection.getOfficeFabricSelection() : null;

        // In your shifts view, ignore the tags in which the current user is not a member of.
        const isYourShiftsView = scheduleViewSettings.employeeViewType === EmployeeViewTypes.YourShiftsView;
        let groups = scheduleGridData.sortedMemberShiftsDataGroups;
        if (isYourShiftsView) {
            const currentUserId = MemberUtils.getCurrentUserMemberId();
            if (currentUserId) {
                groups = groups.filter(group => {
                    return group.members.findIndex(member => member.id === currentUserId) >= 0;
                });
            }
        }

        /*
            [PERFORMANCE] May 2021 - This loop takes up nearly 25% of all the rendering time
            during progressive rendering.
        */
        // Calculate grid settings for each tag group
        for (let groupIndex = 0; groupIndex < groups.length; groupIndex++) {
            const groupData = groups[groupIndex];
            ScheduleGridUtils.addGridSettingsForGroup(
                gridGroupsSettings,
                datesInRange,
                groupData,
                scheduleSelectionData,
                scheduleViewSettings,
                scheduleGridCallbacks,
                scheduleGridIndices,
                groupIndex
            );
        }

        // Add all selectable schedule cell items to the Office Fabric Selection
        if (officeFabricSelection && scheduleSelectionData.scheduleGridSelection) {
            // Disable selection change events while we update the selection
            officeFabricSelection.setChangeEvents(false /* isEnabled */);

            const cachedSelectionIndices: number[] = scheduleSelectionData.scheduleGridSelection.getSelectionIndicesCache();

            // If we are rebuilding a cached selection, we assume that something in the selection was changed, so
            // clear out the existing selection first so we can ensure that the selection has the latest shift data.
            // This is a workaround for detecting changes in selected shifts. Currently we don't do full hashing of shift data
            // when calculating selection item keys (Selection.getKey()), and so shift data changes (like changing a shift's times)
            // may not trigger selection updates and thus not get reflected during copy/paste.
            const shouldClear = cachedSelectionIndices && cachedSelectionIndices.length > 0;
            officeFabricSelection.setItems(scheduleSelectionData.selectionCellItems as IObjectWithKey[], shouldClear);

            cachedSelectionIndices.map((index: number) => {
                officeFabricSelection.setIndexSelected(index, true /* isSelected */, false /* shouldAnchor */);
            });

             // Reenable selection change events
             officeFabricSelection.setChangeEvents(true /* isEnabled */);
        }

        return gridGroupsSettings;
    }

    /**
     * Calculate the grid settings for a tag group
     * @param gridGroupsSettings grid groups settings. the new settings for this group will be added to this.
     * @param datesInRange array of dates for the current view
     * @param groupData grouped shifts data for the group
     * @param scheduleSelectionData objects used to track and setup selection
     * @param scheduleViewSettings settings describing the current view
     * @param scheduleGridCallbacks callbacks used by components of the grid
     * @param scheduleGridIndices indices used to track grid cells
     */
    public static addGridSettingsForGroup(
        gridGroupsSettings: FlexiGroupedGridGroupSettings[],
        datesInRange: Moment[],
        groupData: MemberShiftsDataGroup,
        scheduleSelectionData: IScheduleSelectionData,
        scheduleViewSettings: IScheduleViewSettings,
        scheduleGridCallbacks: IScheduleGridCallbacks,
        scheduleGridIndices: IScheduleGridIndices,
        groupIndex: number) {

        const { isAdmin = false, isViewGrouped = false, simpleTagHeaders = false, collapsedTags = [], selectedShiftFilters = [] } = scheduleViewSettings || {};

        const tagGroup = groupData.tag;
        const tagId = tagGroup && tagGroup.id;
        const isOtherGroup = TagUtils.isDefaultTag(tagId);
        const isValidGroup = tagGroup || isOtherGroup;

        if (isValidGroup || !tagId) {
            // Setup for rows.
            // IMPORTANT: do not reassign an array to these variables within any of the following functions
            // or allow them to reference new memory locations. Doing so will break the link with these original references
            // ScheduleGridUtils.getGridGroupSettings() uses these arrays and expects them to be filled with row and cell settings
            // afterwards.
            // IMPORTANT: calculated row and cells settings for any type of row are added to rowSettingsForRows and
            // cellSettingsForRows in the order that they are calculated. This is to ensure the scheduleGridIndices remain propely ordered
            let rowSettingsForRows: FlexiGridRowSettings[] = [];
            let cellSettingsForRows: FlexiRowCellSettings[][] = [];

            const tagGroupName: string = isOtherGroup ? ScheduleGridUtils.getOtherGroupName() : (tagGroup ? tagGroup.name : "");
            let totalGroupHours = 0;
            let groupHasShifts = false;
            let groupHasActiveShifts = false;
            let groupHasConflicts = false;
            let groupHasOpenShifts = false;
            let groupHasTimeOffs = false;
            let isTagCollapsed = false;
            let isTagDeleted = false;

            const member = TeamStore() && TeamStore().me;
            const conflictFilteringEnabled = ConflictUtils.isConflictEnabledForAdminInDateRange(MemberUtils.isAdmin(member), scheduleViewSettings.viewEndDate) && ECSConfigService.isECSFeatureEnabled(ECSConfigKey.EnableConflictFiltering);

            // For grouped view, tags can be collapsed and tags could be deleted
            if (isViewGrouped) {
                isTagCollapsed = collapsedTags.indexOf(tagId) >= 0;
                isTagDeleted = tagGroup && tagGroup.state === TagStates.Deleted;
            }

            // Determine if at least one member in the group has shifts.
            const numMembers = groupData.members.length;
            for (let i = 0; i < numMembers; i++) {
                const memberShiftsData: MemberShiftsData = groupData.memberShiftsData.get(groupData.members[i].id);
                if (memberShiftsData) {
                    // Iterate through each date in the current view, adding paid hours
                    let totalHoursForMemberInView: number = 0;
                    let dateIndex = 0;
                    for (let j = 0; j < datesInRange.length; j++) {
                        const currentDate = datesInRange[j];
                        dateIndex = DateUtils.fastCalculateDateIndex(currentDate);
                        totalHoursForMemberInView += memberShiftsData.hoursByDate.get(dateIndex);
                    }
                    totalGroupHours += totalHoursForMemberInView;
                    // consider only assigned Shifts and exclude any time-offs. if tag is deleted, we dont need to render this group.
                    const memberHasShifts = memberShiftsData.memberShifts.length > 0;
                    const memberHasTimeOffs = memberShiftsData.memberTimeOffs.length > 0;
                    groupHasActiveShifts = groupHasActiveShifts || memberHasShifts;
                    groupHasTimeOffs = groupHasTimeOffs || memberHasTimeOffs;

                    if ((memberHasShifts || memberHasTimeOffs) && conflictFilteringEnabled) {
                        groupHasConflicts = this.shiftsHaveConflicts(memberShiftsData.memberShifts) || this.shiftsHaveConflicts(memberShiftsData.memberTimeOffs);
                    }
                }
            }
            // If open shifts are enabled, the group is also considered to have shifts if there are open shifts for the group in the date range
            // This is so that we render the group and display an open shift row for managers to interact with if there are open shifts but no other shifts
            groupHasOpenShifts = this.isOpenShiftsEnabled() && groupData.openShiftsByDate.size > 0;
            groupHasShifts = groupHasActiveShifts || groupHasOpenShifts;

            // hide entire group or open shifts row based on filters selected
            const shouldHideGroupBasedOnFilters = ScheduleGridUtils.shouldFilterBasedOnSelectedShiftFilters(selectedShiftFilters, groupHasShifts, groupHasTimeOffs /* hasTimeOffs */, groupHasConflicts /* hasConflictS */);
            const shouldHideOpenShiftsRowBasedOnFilters = ScheduleGridUtils.shouldFilterBasedOnSelectedShiftFilters(selectedShiftFilters, groupHasOpenShifts, false /* hasTimeOffs */, false /* hasConflicts */);
            const shouldHideAllRowsBasedOnFilters = ScheduleGridUtils.shouldFilterBasedOnSelectedShiftFilters(selectedShiftFilters, groupHasActiveShifts, groupHasTimeOffs /* hasTimeOffs */, groupHasConflicts /* hasConflicts */);
            const openShiftSettingEnabled = !TeamStore().team?.hideOpenShifts;

            // Don't show a tag in grouped view if tag is deleted and has no shifts or if we are trying to show other group without any shifts
            // Other group will be shown if there are shifts that dont have tag or if timeoff that is not rendered in any of the group
            // Also hide the tag if there is no data available post applying shift filters
            if ((isViewGrouped &&
                (!groupHasShifts && ((tagGroup && isTagDeleted))) || (!(groupHasShifts || groupHasTimeOffs) && isOtherGroup)) || shouldHideGroupBasedOnFilters) {
                    return;
            }

            const { scheduleCalendarType, staffPerIntervalEnabled } = schedulesViewStateStore();

            if (staffPerIntervalEnabled && scheduleCalendarType == ScheduleCalendarTypes.Week) {
                // Add "Staff per N hours" row
                const classNames = getClassNames();

                rowSettingsForRows.push({
                    ariaPropsForRow: {
                        role: AriaRoles.row
                    },
                    groupId: tagId,
                    isDraggable: false,
                    rowClass: classNames.scheduleGroupPeopleCountRow,
                    rowHeight: 26
                });

                // Add "Staff per N hours" cell
                cellSettingsForRows.push([{
                    cellClass: classNames.scheduleGroupPeopleCountRowCell,
                    onRenderCellContents: (): React.ReactNode => (
                        <ScheduleGroupPeopleCountRowContainer
                            memberShiftsData={groupData.memberShiftsData}
                        />
                    )
                }]);
            }

            // Setup the grid group settings item and add it to the accumulated groups settings
            const gridGroupSettingsItem: FlexiGroupedGridGroupSettings = ScheduleGridUtils.getGridGroupSettings(
                rowSettingsForRows,
                cellSettingsForRows,
                tagId,
                tagGroup,
                tagGroupName,
                isViewGrouped,
                isTagCollapsed,
                groupHasShifts,
                isOtherGroup,
                isAdmin,
                simpleTagHeaders,
                totalGroupHours,
                groupIndex,
                scheduleViewSettings.scheduleCalendarType,
                scheduleViewSettings.isScheduleGroupOrMemberFilterApplied,
                scheduleViewSettings.employeeViewType);
            gridGroupsSettings.push(gridGroupSettingsItem);

            // For collapsed tags, nothing more is added to rowSettingsForRows and cellSettingsForRows
            if (isTagCollapsed) {
                return;
            }

            const currentUser: IMemberEntity = TeamStore() && TeamStore().me;
            const tagGroupNameAria: string = ScheduleGridUtils.calculateTagGroupNameForAria(tagGroupName, isViewGrouped);

            // show the open shift row only if open shift is enabled and also based on shift filters data
            if (ScheduleGridUtils.showOpenShiftsForTag(tagGroup, currentUser) && !shouldHideOpenShiftsRowBasedOnFilters && openShiftSettingEnabled) {
               ScheduleGridUtils.addOpenShiftsRows(
                   scheduleGridCallbacks,
                   scheduleViewSettings,
                   currentUser,
                   rowSettingsForRows,
                   cellSettingsForRows,
                   scheduleSelectionData.selectionCellItems,
                   groupData.openShiftsByDate,
                   scheduleGridIndices,
                   !(TeamStore().team && TeamStore().team.hideOpenShifts) /*showUnsharedStatus*/,
                   ScheduleGridUtils.roundShiftTemporalItemTimeByDay,
                   tagGroupNameAria,
                   tagId);
            }

            // Don't render the group, if there is no data based on filters selected
            if (shouldHideAllRowsBasedOnFilters) {
                return;
            }

            let shownAvailabilitesMap: IShownAvailabilitiesMap = {};
            const isYourShiftsView = scheduleViewSettings.employeeViewType === EmployeeViewTypes.YourShiftsView;
            const isMoveMemberEnabled = !isYourShiftsView && !scheduleViewSettings.isScheduleGroupOrMemberFilterApplied;
            const currentUserId = MemberUtils.getCurrentUserMemberId();

            // Iterate through the members in the tag, adding rows for them
            for (let memberRowIndex = 0; memberRowIndex < numMembers; memberRowIndex++) {
                const hideInYourShiftsView = isYourShiftsView && groupData.members[memberRowIndex].id !== currentUserId;

                if (hideInYourShiftsView) {
                    continue;
                }

                const memberShiftsData: MemberShiftsData = groupData.memberShiftsData.get(groupData.members[memberRowIndex].id);
                if (memberShiftsData) {
                    let totalHoursForMemberInView: number = 0;
                    let memberHasShifts = memberShiftsData.memberShifts.length > 0 || memberShiftsData.memberTimeOffs.length > 0;
                    let memberHasConflicts = conflictFilteringEnabled && this.shiftsHaveConflicts(memberShiftsData.memberShifts) || this.shiftsHaveConflicts(memberShiftsData.memberTimeOffs);

                    // Do not show the member if tag is deleted and member has no shifts.
                    let hideMemberRow = isTagDeleted && !memberHasShifts;

                    // hide member row if no data is available based on shift filters selected
                    hideMemberRow = hideMemberRow || ScheduleGridUtils.shouldFilterBasedOnSelectedShiftFilters(selectedShiftFilters, memberShiftsData.memberShifts.length > 0, memberShiftsData.memberTimeOffs.length > 0, memberHasConflicts);

                    if (!hideMemberRow) {
                        ScheduleGridUtils.addGridRowSettingsForGroupMember(
                            scheduleViewSettings,
                            scheduleSelectionData,
                            scheduleGridCallbacks,
                            scheduleGridIndices,
                            tagGroupName,
                            tagGroupNameAria,
                            rowSettingsForRows,
                            cellSettingsForRows,
                            datesInRange,
                            totalHoursForMemberInView,
                            memberShiftsData,
                            memberRowIndex,
                            isOtherGroup,
                            shownAvailabilitesMap,
                            isMoveMemberEnabled);
                    }
                } else {
                    trace.error("ScheduleGrid.addGridSettingsForGroup(): MemberShiftsData not found in MemberShiftsGroupData.");
                }
            }
        } else {
            trace.error("ScheduleGrid.addGridSettingsForGroup(): Tag not found in store.");
        }
    }

    /**
     * Return grouped grid group settings for a group
     * @param rowSettingsForRows array of row settings that will be mutated by other functions to create grid rows
     * @param cellSettingsForRows array of cell settings that will be mutated by other functions to create grid cells
     * @param tagId tagId for the group
     * @param tagGroup model for the group
     * @param tagGroupName name for the group
     * @param isViewGrouped true in grouped view
     * @param isTagCollapsed true if the tag should be collapsed
     * @param groupHasShifts true if there are shifts in the group
     * @param isOtherGroup true for the default tag
     * @param isAdmin true if edit enabled
     * @param simpleTagHeaders true if the tag should be rendered in simple mode
     * @param totalGroupHours the total hours for the group
     * @param groupIndex the index of the group
     */
    public static getGridGroupSettings(
        rowSettingsForRows: FlexiGridRowSettings[],
        cellSettingsForRows: FlexiRowCellSettings[][],
        tagId: string,
        tagGroup: ITagEntity,
        tagGroupName: string,
        isViewGrouped: boolean,
        isTagCollapsed: boolean,
        groupHasShifts: boolean,
        isOtherGroup: boolean,
        isAdmin: boolean,
        simpleTagHeaders: boolean,
        totalGroupHours: number,
        groupIndex: number,
        scheduleCalendarType: ScheduleCalendarType,
        isScheduleGroupOrMemberFilterApplied: boolean,
        employeeViewType: EmployeeViewType): FlexiGroupedGridGroupSettings {

        const ariaPropsForHeaderRow: AriaProperties = {
            role: AriaRoles.row
        };

        const isViewFiltered = isScheduleGroupOrMemberFilterApplied || ScheduleGridUtils.isYourShiftsView(employeeViewType);
        const isAddMemberDisabled = isViewFiltered;
        const isReorderGroupDisabled = isViewFiltered;
        const isDeleteGroupDisabled = isViewFiltered;

        // Setup for tag group header row
        const rowSettingsHeader: FlexiGridRowSettings = {
            rowElementKey: "group-header-row-" + tagId,
            rowClass: classNames(styles.scheduleGroupHeaderRow, { [styles.first]: groupIndex === 0 }, styles.scheduleGroupHeaderRowFRE),
            rowDataAutomationId: AutomationUtil.getAutomationId("group", "QAIDGroupHeaderRow"),
            rowHeight: parseInt(groupIndex === 0 ? styles.firstGroupHeaderRowHeight : styles.groupHeaderRowHeight),
            ariaPropsForRow: ariaPropsForHeaderRow
        };

        const cellSettingsHeader: FlexiRowCellSettings[] = [
            {
                cellClass: classNames(styles.scheduleGroupHeaderTitleCellBox, styles.scheduleGroupHeaderTitleCellBoxNew),
                onRenderCellContents: (cellContentsItem: any) => {
                    return (
                        isViewGrouped ?
                                <TagHeader tag={ tagGroup ? tagGroup : null }
                                    otherGroupTagName={ isOtherGroup ? tagGroupName : '' }
                                    otherGroupTagId={ isOtherGroup ? tagId : null }
                                    hours={ totalGroupHours }
                                    isTagCollapsed={ isTagCollapsed }
                                    hasShifts={ groupHasShifts }
                                    editEnabled={ isAdmin && !isOtherGroup }
                                    hideGroupTotal={ simpleTagHeaders }
                                    hideCollapseButton={ simpleTagHeaders }
                                    scheduleOverViewType={ ScheduleOverViewTypes.PeopleView }
                                    scheduleCalendarType={ scheduleCalendarType }
                                    disableAddMember={ isAddMemberDisabled }
                                    disableDeleteGroup={ isDeleteGroupDisabled }
                                    disableReorderGroup={ isReorderGroupDisabled }
                                    employeeViewType={ employeeViewType }/>
                            : null
                    );
                }
            }
        ];

        const cellSettingsFooter: FlexiRowCellSettings[] = [
            {
                cellClass: styles.scheduleGroupHeaderTitleCellBox
            }
        ];

        const gridGroupSettingsItem: FlexiGroupedGridGroupSettings = {
            groupId: tagId,
            rowSettingsBody: rowSettingsForRows,
            rowSettingsHeader: isViewGrouped ? rowSettingsHeader : undefined,
            cellSettingsBody: cellSettingsForRows,
            cellSettingsHeader: cellSettingsHeader,
            cellSettingsFooter: cellSettingsFooter
        };

        return gridGroupSettingsItem;
    }

    /**
     * Add the member header row
     *
     * @param rowSettingsForRows array of row settings that will be mutated by other functions to create grid rows
     * @param cellSettingsForRows array of cell settings that will be mutated by other functions to create grid cells
     * @param tagId tagId for the group
     */
    public static getMemberHeaderRow(
        rowSettingsForRows: FlexiGridRowSettings[],
        cellSettingsForRows: FlexiRowCellSettings[][],
        tagId: string) {

        const ariaPropsForHeaderRow: AriaProperties = {
            role: AriaRoles.row
        };

        const cellSettingsHeader: FlexiRowCellSettings[] = [
            {
                cellClass: styles.scheduleMemberHeaderCellBox,
                onRenderCellContents: (cellContentsItem: any) => {
                    return (
                            <>
                                <div className={ "peopleColumnCell" }>{ ScheduleGridUtils.getSchedulePageStrings().get("members") }</div>
                                <div className={ styles.blankSpace }></div>
                            </>
                    );
                }
            }
        ];

        // Setup for tag group header row
        const rowSettingsHeader: FlexiGridRowSettings = {
            rowElementKey: "member-header-row-" + tagId,
            rowClass: styles.scheduleMemberHeaderRow,
            rowDataAutomationId: AutomationUtil.getAutomationId("group", "QAIDScheduleMemberHeaderRow"),
            rowHeight: parseInt(styles.groupMemberHeaderRowHeight),
            ariaPropsForRow: ariaPropsForHeaderRow
        };

        rowSettingsForRows.push(rowSettingsHeader);
        cellSettingsForRows.push(cellSettingsHeader);
    }

    /**
     * Adds the appropriate number of open shift rows for the given tag (or for the grid, in ungrouped views). Renders empty cells and open shift
     * schedule cells. Used for both day view and week/month views.
     * @param scheduleGridCallbacks callbacks used by components of the grid
     * @param scheduleViewSettings settings describing the current view
     * @param scheduleGridIndices indices used to track grid cells
     * @param currentUser the current user
     * @param rowSettingsForRows array of row settings that constitute the rows for a group for the grid
     * @param cellSettingsForRows array of cell settings that comprise the cells in a group for the grid
     * @param openShiftsByDate open shifts data for the member. these shift events should be active and thus displayable.
     * @param scheduleGridIndices current indices used for iterating through cells in the schedule grid
     * @param showUnsharedStatus if this is false, open shift cells will not display unshared status
     * @param roundMomentForTemporalItem function applied to temporal items to round them for the view
     * @param tagGroupNameAria name for the group. used for Aria
     * @param tagId tagId for the group the open shift rows are being added in. May be null in ungrouped view
     */
    public static addOpenShiftsRows(
        scheduleGridCallbacks: IScheduleGridCallbacks,
        scheduleViewSettings: IScheduleViewSettings,
        currentUser: IMemberEntity,
        rowSettingsForRows: FlexiGridRowSettings[],
        cellSettingsForRows: FlexiRowCellSettings[][],
        selectionCellItems: FlexiRowCellSettings[],
        openShiftsByDate: Map<number, IOpenShiftEntity[]>,
        scheduleGridIndices: IScheduleGridIndices,
        showUnsharedStatus: boolean,
        roundMomentForTemporalItem: (moment: Moment, isStartTime: boolean) => Moment,
        tagGroupNameAria: string,
        tagId?: string
    ) {
        const { scheduleCalendarType = null, viewStartDate = null, viewEndDate = null } = scheduleViewSettings || {};

        const isDayView: boolean = ScheduleGridUtils.isDayView(scheduleCalendarType);

        // Get a list of the temporal items
        let temporalItems: TemporalItem[] = ScheduleData.getTemporalItemsForOpenShiftRows(
            currentUser,
            openShiftsByDate,
            scheduleViewSettings,
            roundMomentForTemporalItem,
            !isDayView /*groupByDays*/);
        let temporalItemsByRow: TemporalItem[][] = ScheduleData.getNonOverlappingTemporalItemLists(temporalItems);
        const numOpenShiftsForRows: number = temporalItems.length;

        // Iterate through each row of temporal items, rendering open shift cells and empty cells where appropriate
        temporalItemsByRow.forEach((temporalItemsInRow: TemporalItem[], rowIndex: number) => {

            temporalItemsInRow = temporalItemsInRow.sort((firstItem: TemporalItem, secondItem: TemporalItem) => {
                return ShiftUtils.shiftComparator(firstItem.shiftEvent, secondItem.shiftEvent);
            });

            temporalItemsInRow = ScheduleData.getOrderedTemporalItemsForRowInView(
                viewStartDate,
                viewEndDate,
                temporalItemsInRow,
                roundMomentForTemporalItem);

            // rowKey should be unique, and will be used by UX control when rendering and uses it to see if row is already rendered.
            // if it is not unique, duplicate row might not get rendered
            const openShiftRowKey: string = `open-shift-row-${tagId}-${rowIndex}`;

            const openShiftRowClasses: string = styles.scheduleOpenShiftRow;
            const openShiftRowId: string = ScheduleGridUtils.calculateOpenShiftRowElementId(tagId, rowIndex);

            const ariaPropsForRow: AriaProperties = {
                role: AriaRoles.row
            };

            // Setup row settings for the open shifts row
            const openShiftRowSettings: FlexiGridRowSettings = {
                groupId: tagId,
                rowElementId: openShiftRowId,
                rowElementKey: openShiftRowKey,
                rowHeight: parseInt(styles.scheduleOpenShiftRowHeight),
                rowClass: openShiftRowClasses,
                rowDataAutomationId: AutomationUtil.getAutomationId("scheduler", "QAIDScheduleOpenShiftRow"),
                isDraggable: false,
                ariaPropsForRow: ariaPropsForRow
            };

            // Setup cell settings for the open shift row
            let openShiftRowCellSettings: FlexiRowCellSettings[] = [];

            // create open shift title cell for first row, spacer cells for the other rows
            if (rowIndex === 0) {
                const titleCellKey: string = `ostc-${tagId}-${rowIndex}`;
                openShiftRowCellSettings.push(ScheduleGridUtils.generateOpenShiftRowTitleCellSettings(numOpenShiftsForRows, titleCellKey, tagGroupNameAria, rowIndex, temporalItemsByRow.length));
            } else {
                const cellKey: string = `open-shift-row-spacer-cell-${tagId}-${rowIndex}`;
                const cellClass: string = classNames(styles.openShiftTitleCellBox, "peopleColumnCell");
                const spacerCellContentClass: string = "openShiftsTitleSpacerCell";

                const ariaLabel: string = AccessibilityUtils.getOpenShiftRowAriaLabel(tagGroupNameAria, numOpenShiftsForRows, rowIndex, temporalItemsByRow.length);
                // Setup a rowheader to provide context for accessibility users when they navigate through shift cells within the open shift row.
                const ariaProps: AriaProperties = {
                    role: AriaRoles.rowheader,
                    label: ariaLabel
                };
                openShiftRowCellSettings.push(ScheduleGridUtils.generateSpacerCellSettings(cellKey, cellClass, spacerCellContentClass, ariaProps));
            }

            // Helper for adding a shift cell to the row
            const addCellHelper = (shiftCellSettings: FlexiRowCellSettings, shiftCellUnitSize: number) => {
                if (shiftCellSettings.cellContentsItem && shiftCellSettings.cellContentsItem.cellType === ScheduleCellType.Shift) {
                    shiftCellSettings.cellClass = classNames(shiftCellSettings.cellClass, "openShiftCellBox");
                }

                openShiftRowCellSettings.push(shiftCellSettings);

                // Add the current schedule cell as a selectable item
                selectionCellItems.push(shiftCellSettings);
                this.updateDataSelectionIndex(scheduleGridIndices, shiftCellUnitSize, selectionCellItems);
                scheduleGridIndices.selectableCellIndex += shiftCellUnitSize;
            };

            // Use the ordered temporal items list to compute schedule cell data for the row
            temporalItemsInRow.forEach((temporalItem: TemporalItem) => {
                switch (temporalItem.type) {
                    case TemporalItemTypes.openShift:
                        const cellUnitSize: number = this.isDayView(scheduleCalendarType) ?
                                            this.getCellUnitSizeForSingleDayView(temporalItem.startTime, temporalItem.endTime, roundMomentForTemporalItem) :
                                            ScheduleGridUtils.fastGetCellUnitSizeForMultiDayViews(temporalItem.startTime, temporalItem.endTime);

                        ScheduleGridUtils.processShiftItemForCellRendering(
                            temporalItem,
                            tagId,
                            rowIndex,
                            cellUnitSize,
                            scheduleCalendarType,
                            scheduleGridIndices,
                            scheduleGridCallbacks,
                            scheduleViewSettings,
                            addCellHelper,
                            undefined, // cellCountIncrementer not needed
                            showUnsharedStatus,
                            null /* member */);
                        break;
                    case TemporalItemTypes.empty:
                    default: {
                        ScheduleGridUtils.processEmptyTemporalItemForCellRendering(
                            temporalItem,
                            tagId,
                            rowIndex,
                            scheduleCalendarType,
                            scheduleGridIndices,
                            scheduleGridCallbacks,
                            scheduleViewSettings,
                            addCellHelper,
                            undefined, // cellCountIncrementer not needed
                            null /* member */,
                            Number(DateUtils.getDifferenceInHoursFromMoments(temporalItem.startTime, temporalItem.endTime)),
                            true /*inOpenShiftRow*/);
                        break;
                    }
                }
            });

            rowSettingsForRows.push(openShiftRowSettings);

            cellSettingsForRows.push(openShiftRowCellSettings);
        });

        this.incrementNthRowSet(scheduleGridIndices);
    }

    public static incrementNthRowSet(scheduleGridIndices: IScheduleGridIndices) {
        scheduleGridIndices.nthRowSet++;
    }

    /**
     * Calculate the cell settings for an open shift row title cell.
     * @param numOpenShifts
     * @param cellElementKey
     * @param tagGroupName
     * @param openShiftRowIndex
     * @param numOpenShiftRows
     */
    public static generateOpenShiftRowTitleCellSettings(
        numOpenShifts: number,
        cellElementKey: string,
        tagGroupNameAria: string,
        openShiftRowIndex: number,
        numOpenShiftRows: number
        ): FlexiRowCellSettings {
        const openShiftRowTitleCellSettings: FlexiRowCellSettings = {
            cellElementKey: cellElementKey,
            cellClass: classNames(styles.openShiftTitleCellBox, "peopleColumnCell"),
            onRenderCellContents: (cellContentsItem: any) => {
                return (
                    <OpenShiftTitleCell
                        numOpenShifts={ numOpenShifts }
                        key={ cellElementKey + "-persona" }
                        tagGroupNameAria={ tagGroupNameAria }
                        openShiftRowIndex={ openShiftRowIndex }
                        numOpenShiftRows={ numOpenShiftRows } />
                );
            }
        };
        return openShiftRowTitleCellSettings;
    }

    /**
     * Calculate the cell settings for a spacer cell
     */
    public static generateSpacerCellSettings(
        cellElementKey: string,
        cellClass: string,
        spacerCellContentClass: string,
        ariaProps: AriaProperties = null): FlexiRowCellSettings {
        const spacerCellSettings: FlexiRowCellSettings = {
            cellElementKey: cellElementKey,
            cellClass: cellClass,
            onRenderCellContents: (cellContentsItem: any) => {
                return (
                    ariaProps
                    ?
                        <div className={ spacerCellContentClass } { ...generateDomPropertiesForAria(ariaProps) }>
                        </div>
                    :
                        <div className={ spacerCellContentClass }>
                        </div>
            );
            }
        };
        return spacerCellSettings;
    }

    /**
     * Helper for rounding temporal item times to day boundaries
     * This is used for Schedule week and month views, where shift events occupy whole day cells even
     * if they are partial day shifts.
     * @param momentTime time to round
     * @param isStartTime true if this time is used for the start of an event or range. false if for the end of an event or range.
     */
    public static roundShiftTemporalItemTimeByDay(momentTime: Moment, isStartTime: boolean): Moment {
        return ScheduleGridUtils.fastRoundTimeByDay(momentTime, isStartTime);
    }

    /**
     * Rounds moments to quarter hours. Used for Day view rendering
     * @param momentTime
     * @param isStartTime
     */
    public static roundTemporalItemToQuarterHours(momentTime: Moment, isStartTime: boolean): Moment {
        return DateUtils.roundMomentToQuarterHours(momentTime);
    }

    /**
     * Based on the start and end time, return a cell unit size. Used in week and month views where 1 unit represents a day
     * @param temporalItem
     */
    public static getCellUnitSizeForMultiDayViews(startTime: Moment, endTime: Moment): number {
        // Temporal events for the schedule view are rounded to fill up schedule day cells, where temporal items are setup such that their
        // start time is at midnight and the end time is just before midnight of the next day. Thus we need to round up when calculating the
        // number of days for a temporal event.
        return Math.ceil(endTime.diff(startTime, 'days', true /* precise */));
    }
    private static _cellUnitSizeCache: {[key: string]: number} = {};

    public static fastGetCellUnitSizeForMultiDayViews = MemoizeUtils.memoizeUtility(
        ScheduleGridUtils.getCellUnitSizeForMultiDayViews,
        ScheduleGridUtils._cellUnitSizeCache,
        (args: any[]) => {
            return `${args[0].valueOf()}:${args[1].valueOf()}`;
        }
    );

    /**
     * Based on the start and end time, return a cell unit size. Used in day view where cell unit size scales with shift duration
     * @param temporalItem
     */
    public static getCellUnitSizeForSingleDayView(startTime: Moment, endTime: Moment, roundMomentForTemporalItem: (moment: Moment, isStartTime: boolean) => Moment): number {
        return DateUtils.getDifferenceInHoursFromMoments(roundMomentForTemporalItem(startTime, true /*isStartTime*/), roundMomentForTemporalItem(endTime, false /*isStartTime*/)) / DayViewFormatCellInterval;
    }

    /**
     * Round the specified time by day boundaries
     * @param momentTime time to round
     * @param isStartTime true if this time is used for the start of an event or range. false if for the end of an event or range.
     * @param isEndOfDayAtMidnight true if the end of days should be represented as midnight of the next day. false if the end of the day should be just before midnight of the next day.
     */
    public static roundTimeByDay(momentTime: Moment, isStartTime: boolean, isEndOfDayAtMidnight: boolean = false): Moment {
        let roundedTime = momentTime.clone();
        if (isStartTime) {
            // Start time handling
            // Ensure that start times are set to the beginning of a day (ie, midnight)
            if (roundedTime.isSame(roundedTime.clone().endOf("day"))) {
                roundedTime = DateUtils.startOfNextDay(roundedTime);
            } else {
                roundedTime.startOf("day");
            }
        } else {
            // End time handling
            if (roundedTime.isSame(roundedTime.clone().startOf("day"))) {
                // End time is at midnight, which means this is the end of the previous day
                if (!isEndOfDayAtMidnight) {
                    roundedTime.subtract(1, "days").endOf("day");
                }
            } else {
                // Round end time up to the end of the day
                if (isEndOfDayAtMidnight) {
                    // Round up to midnight of the next day
                    roundedTime = DateUtils.startOfNextDay(roundedTime);
                } else {
                    roundedTime.endOf("day");
                }
            }
        }

        return roundedTime;
    }

    private static _fastRoundTimeByDayCache: {[key: string]: Moment} = {};

    public static fastRoundTimeByDay = MemoizeUtils.memoizeUtility(
        ScheduleGridUtils.roundTimeByDay,
        ScheduleGridUtils._fastRoundTimeByDayCache,
        (args: any[]) => {
            return `${args[0].valueOf()}:${args[1] ? "1" : "0"}:${args[2] ? "1" : "0"}`;
        }
    );

    /**
     * Update the dataSelectionIndex value. For cells that span more than one column, shiftCellUnitSize will be greater than one. In these cases
     * we add a list of dummy cells to the Fabric Ui selection set, such that the number of data selection indices remains constant and we can use them
     * to cache the selection.
     * @param scheduleGridIndices
     * @param shiftCellUnitSize
     * @param selectionCellItems
     */
    public static updateDataSelectionIndex(scheduleGridIndices: IScheduleGridIndices, shiftCellUnitSize: number, selectionCellItems: FlexiRowCellSettings[]) {
        scheduleGridIndices.dataSelectionIndex++;
        // When the data selection index is incremented by a number larger than one, which occurs when an event spans more than one column cell
        // in the grid, we must create a set of dummy IObjectWithKey items to fill the otherwise unused indices. Doing so allows us to use the
        // dataSelectionIndex values for each cell as mappings for each cell. In this way we can cache the selected indices and recreate the selection
        // after a paste event
        if (shiftCellUnitSize > 1) {
            for (let i = 0; i < shiftCellUnitSize - 1; i++) {
                const dummySelectionItem: FlexiRowCellSettings = {
                    cellElementKey: DUMMY_SELECTION_CELL_KEY,
                    dataSelectionIndex: scheduleGridIndices.dataSelectionIndex++
                };
                selectionCellItems.push(dummySelectionItem);
            }
        }
    }

    /**
     * Calculate the settings for the shift cells for a row for a given team member
     * @param scheduleViewSettings settings describing the current view
     * @param scheduleSelectionData objects used to track and setup selection
     * @param scheduleGridCallbacks callbacks used by components of the grid
     * @param scheduleGridIndices indices used to track grid cells
     * @param member team member
     * @param shiftTemporalItemsForMemberRow array of shift events for this member's current row
     * @param memberRowIndex index for this member's shift row (eg, 0 = member's first row of shifts, 1 = member's second row of shifts, etc)
     * @param groupTagId id for the current group
     * @param shownAvailabilitesMap - map used to track which availabilities have already been shown for a group
     */
    private static generateMemberShiftCellsSettings(
        scheduleViewSettings: IScheduleViewSettings,
        scheduleSelectionData: IScheduleSelectionData,
        scheduleGridCallbacks: IScheduleGridCallbacks,
        scheduleGridIndices: IScheduleGridIndices,
        member: IMemberEntity,
        shiftTemporalItemsForMemberRow: TemporalItem[],
        memberRowIndex: number,
        groupTagId: string,
        shownAvailabilitesMap: IShownAvailabilitiesMap): FlexiRowCellSettings[] {
        const { isAdmin = false, scheduleCalendarType = null, viewStartDate = null, viewEndDate = null } = scheduleViewSettings || {};

        let memberShiftCellsSettings: FlexiRowCellSettings[] = [];

        // Ensure that shift items are sorted by time, as required by ScheduleData.getOrderedTemporalItemsForRowInView().
        // Sometimes these items may not yet be completely sorted since timeoff events will be added before shift events so that
        // timeoffs are placed first when a team member has multiple rows of shifts.
        let sortedShiftTemporalItems = shiftTemporalItemsForMemberRow.sort((firstItem: TemporalItem, secondItem: TemporalItem) => {
            return ShiftUtils.shiftComparator(firstItem.shiftEvent, secondItem.shiftEvent);
        });

        // Calculate the ordered list of temporal items for this row, including both shift events and empty cell ranges
        let orderedTemporalItems: TemporalItem[] = ScheduleData.getOrderedTemporalItemsForRowInView(
            viewStartDate,
            viewEndDate,
            sortedShiftTemporalItems,
            (momentTime: Moment, isStartTime: boolean) => {
                return ScheduleGridUtils.roundShiftTemporalItemTimeByDay(momentTime, isStartTime);
            });

        let cellCountForRow = 0;
        const cellCounter = (incrementBy: number) => {
            cellCountForRow += incrementBy;
        };

        // Helper for adding a shift cell to the row
        let addCellHelper = (shiftCellSettings: FlexiRowCellSettings, shiftCellUnitSize: number, cellCountIncrementer?: (incrementBy: number) => void) => {
            memberShiftCellsSettings.push(shiftCellSettings);

            // Add the current schedule cell as a selectable item
            scheduleSelectionData.selectionCellItems.push(shiftCellSettings);
            this.updateDataSelectionIndex(scheduleGridIndices, shiftCellUnitSize, scheduleSelectionData.selectionCellItems);
            scheduleGridIndices.selectableCellIndex += shiftCellUnitSize;
            if (cellCountIncrementer) {
                cellCountIncrementer(shiftCellUnitSize);
            }
        };

        let numShiftTemporalItems = 0;
        let numEmptyTemporalItems = 0;

        // Use the ordered temporal items list to compute schedule cell data for the row
        for (let i = 0; i < orderedTemporalItems.length; i++) {
            const currentTemporalItem = orderedTemporalItems[i];
            const cellUnitSize = ScheduleGridUtils.fastGetCellUnitSizeForMultiDayViews(currentTemporalItem.startTime, currentTemporalItem.endTime);
            switch (currentTemporalItem.type) {
                case TemporalItemTypes.shift:
                case TemporalItemTypes.timeOff:
                case TemporalItemTypes.timeOffRequest:
                    numShiftTemporalItems++;
                    ScheduleGridUtils.processShiftItemForCellRendering(
                        currentTemporalItem,
                        groupTagId,
                        memberRowIndex,
                        cellUnitSize,
                        scheduleCalendarType,
                        scheduleGridIndices,
                        scheduleGridCallbacks,
                        scheduleViewSettings,
                        addCellHelper,
                        cellCounter,
                        isAdmin /* showUnsharedStatus */,
                        member);
                    break;
                case TemporalItemTypes.empty:
                default: {
                    numEmptyTemporalItems++;
                    ScheduleGridUtils.processEmptyTemporalItemForCellRendering(
                        currentTemporalItem,
                        groupTagId,
                        memberRowIndex,
                        scheduleCalendarType,
                        scheduleGridIndices,
                        scheduleGridCallbacks,
                        scheduleViewSettings,
                        addCellHelper,
                        cellCounter,
                        member,
                        undefined /* durationInHours */,
                        false /* in openShiftsRow */,
                        shownAvailabilitesMap);
                    break;
                }
            }
        }

        if (cellCountForRow !== scheduleGridIndices.numCellColumns
            || scheduleGridIndices.numCellColumns !== scheduleSelectionData.scheduleGridSelection.getColumnsPerRow()) {
            // robv 8/20/2020 - This is a condition that we have seen a number of times over the past couple years, but have been unable to get a consistant repro
            // to figure it out. Somehow, we render more cells than we have columns for and that completely breaks the rendering of the scheduler. This location is my best
            // guess of where we might be able to catch the condition and at least add some instrumentation to figure out how common it is (and if this location will correctly catch the condition).

            // RowCellCount - the number of actual cells that are being added for this member row
            // CellColumnCount - the number of cell columns as reported by the scheduleGridIndices object that was passed in
            // MemberShiftCellCount - the number of cells that were pushed onto the memberShiftCellsSettings array that is returned by this method
            // ColumnsPerRowCount - the number of cell columns as reported by the schedule select data object that was passed in
            // ShiftTemporalItemCount - the number of shift temporal items for this row
            // EmptyTemporalItemCount - the number of empty temporal items for this row

            trace.warn(`Error calculating number of cells for row:
                memberRowIndex: ${memberRowIndex}
                TemporalItemCount: ${orderedTemporalItems.length}
                ShiftTemporalItemCount: ${numShiftTemporalItems}
                EmptyTemporalItemCount: ${numEmptyTemporalItems}
                RowCellCount: ${cellCountForRow}
                CellColumnCount: ${scheduleGridIndices.numCellColumns}
                MemberShiftCellCount: ${memberShiftCellsSettings.length}
                ColumnsPerRowCount: ${scheduleSelectionData.scheduleGridSelection.getColumnsPerRow()}`);
            // robv 5/5/2021 - Removing the logging of this in Aria - keeping it around in case we need to turn it back on. Most of these conditions are not
            // actually errors and it ends up recording a ton of data that is not needed.
            // InstrumentationService.logPerfEvent(InstrumentationService.events.GridCalcError,
            //     [
            //         getGenericEventPropertiesObject(InstrumentationService.properties.TemporalItemCount, orderedTemporalItems.length),
            //         getGenericEventPropertiesObject(InstrumentationService.properties.ShiftTemporalItemCount, numShiftTemporalItems),
            //         getGenericEventPropertiesObject(InstrumentationService.properties.EmptyTemporalItemCount, numEmptyTemporalItems),
            //         getGenericEventPropertiesObject(InstrumentationService.properties.RowCellCount, cellCountForRow),
            //         getGenericEventPropertiesObject(InstrumentationService.properties.CellColumnCount, scheduleGridIndices.numCellColumns),
            //         getGenericEventPropertiesObject(InstrumentationService.properties.MemberShiftCellCount, memberShiftCellsSettings.length),
            //         getGenericEventPropertiesObject(InstrumentationService.properties.ColumnsPerRowCount, scheduleSelectionData.scheduleGridSelection.getColumnsPerRow())
            //     ]);
        }
        return memberShiftCellsSettings;
    }

    /**
     * Return true if the shift temporal items for this row contains any active shifts
     * @param shiftTemporalItemsForMemberRow array of shift events for this member's current row
     */
    private static doesMemberHaveActiveShifts(shiftTemporalItemsForMemberRow: TemporalItem[]): boolean {
        let hasActiveShifts = false;
        for (let i = 0; i < shiftTemporalItemsForMemberRow.length; i++) {
            const shiftItem: TemporalItem = shiftTemporalItemsForMemberRow[i];
            const isRegularShift: boolean = shiftItem.type === TemporalItemTypes.shift || shiftItem.type === TemporalItemTypes.timeOff || shiftItem.type === TemporalItemTypes.timeOffRequest; // consider shift or timeoff as regular entities to render
            if (isRegularShift) {
                hasActiveShifts = true;
                break;
            }
        }

        return hasActiveShifts;
    }

    /**
     * Computed value representing whether or not schedule availability is toggled off
     */
    public static isScheduleAvailabilityHidden = computed(() => {
        const userStore: UserStoreSchema = UserStore();
        return userStore && userStore.userSettings && userStore.userSettings.hideScheduleAvailability;
    });

    /**
     * Process the shift temporal item. This is used to generate shift cells for member rows and open shift rows, in both
     * grouped and ungrouped view.
     * @param temporalItem The temporal item to process.
     * @param tagId The tag unique identifier for which shift belongs to. May be null.
     * @param rowNum The index of the row, when multiple rows for a member or open shifts section are rendered.
     * @param cellUnitSize The num of columns the cell should span in the view.
     * @param scheduleCalendarType The type of view.
     * @param scheduleGridIndices Indices used to track selection & position of cells.
     * @param scheduleGridCallbacks Callbacks used by components of the grid.
     * @param scheduleViewSettings The schedule view settings.
     * @param addCellHelper Helper function that adds the cell to the list of cells for the grid and updates indices.
     * @param cellCountIncrementer Optional (pass null or undefined if not needed) helper function that will be passed into the addCellHelper that can be used for counting the number of cells added.
     * @param showUnsharedStatus If this is false, open shift cells will not display unshared status.
     * @param member The optional member.
     */
    public static processShiftItemForCellRendering(
        temporalItem: TemporalItem,
        tagId: string,
        rowNum: number,
        cellUnitSize: number,
        scheduleCalendarType: ScheduleCalendarType,
        scheduleGridIndices: IScheduleGridIndices,
        scheduleGridCallbacks: IScheduleGridCallbacks,
        scheduleViewSettings: IScheduleViewSettings,
        addCellHelper: (cellSettings: FlexiRowCellSettings, cellUnitSize: number, cellCountIncrementer?: (incrementBy: number) => void) => void,
        cellCountIncrementer: (incrementBy: number) => void,
        showUnsharedStatus: boolean = true,
        member?: IMemberEntity): void {
        const { isAdmin = false, isViewGrouped = false, currentUserMemberId = "", viewStartDate = null, viewEndDate = null, isSharedScheduleView } = scheduleViewSettings || {};

        const shift = temporalItem.shiftEvent;

        const scheduleCellRenderSize = ScheduleGridUtils.calculateScheduleCellSizeType(scheduleCalendarType);

        const isSmallCell = this.isRenderSmallCell(scheduleCellRenderSize);

        const isNormalCell = this.isRenderNormalCell(scheduleCellRenderSize);

        const isLargeCell = this.isRenderLargeCell(scheduleCellRenderSize);

        const isOpenShift = ShiftUtils.isOpenShift(shift);

        const doShowIcons = !isSmallCell;

        const doShowNotes = (isNormalCell || isLargeCell) && !(isOpenShift && !isViewGrouped);

        /* show activities only in large cells */
        const doShowActivities = isLargeCell;

        /* show the slot bar on open shifts for non admins */
        const showSlotBar = isAdmin && isOpenShift;

        const doShowShiftRequestsIndicator = this.doShowShiftRequestsIndicator(shift, isAdmin);

        /* enable the contextual menu when edits are allowed*/
        const enableContextualMenu = isAdmin;

        /* enable the shift details callout for non-admins. Ensure that it does not collide with an enabled contextual menu*/
        const doShowShiftDetails = !isAdmin && !enableContextualMenu;

        const cellKey = ScheduleGridUtils.getCellKey(scheduleGridIndices, rowNum);

        const is24ShiftEnabled = ECSConfigService.isECSFeatureEnabled(ECSConfigKey.Enable24hShift);

        const shiftCellSettings = ScheduleGridUtils.generateShiftCellSettings(
            shift,
            temporalItem.startTime,
            temporalItem.endTime,
            member,
            isAdmin,
            currentUserMemberId,
            tagId,
            cellKey,
            cellUnitSize,
            scheduleGridIndices,
            scheduleGridCallbacks,
            scheduleCalendarType,
            scheduleCellRenderSize,
            isViewGrouped,
            showUnsharedStatus,
            doShowIcons,
            doShowNotes,
            doShowShiftRequestsIndicator,
            doShowActivities,
            showSlotBar,
            enableContextualMenu,
            doShowShiftDetails,
            isAdmin,
            viewStartDate,
            viewEndDate,
            is24ShiftEnabled,
            isSharedScheduleView);
        addCellHelper(shiftCellSettings, cellUnitSize, cellCountIncrementer);
    }

    /**
     * Returns a key based on the row and col of the cell
     */
    public static getCellKey(gridIndices: IScheduleGridIndices, nthRowInSet: number): string {
        const row: string = this.getRowKey(gridIndices, nthRowInSet);
         // modding the index of the cell by the number of columns in a row yields the 0-based column of the cell
         // EX: index is 45, num Columns is 30 -> 45 % 30 = 15
        const col: number = gridIndices.selectableCellIndex % gridIndices.numCellColumns;
        return `${row}c${col}`;
    }

    /**
     * Returns a key based on the row
     */
    public static getRowKey(gridIndices: IScheduleGridIndices, nthRowInSet: number): string {
        const row: number = gridIndices.nthRowSet;
        let key: string = `r${row}`;
        if (nthRowInSet) {
            key = key + `-${nthRowInSet}`;
        }

        return key;
    }

    /**
     * Parse out the current values and augment the current key
     * @param currentKey
     * @param delta
     */
    public static getAugmentedRowKey(currentKey: string, delta: number): string {
        const setRegExp: RegExp = /r(\d*?)(-|$)/;
        const rowInSetRegExp: RegExp = /-(\d*?)$/;
        if (currentKey && delta) {
            const setMatchArray: RegExpMatchArray = currentKey.match(setRegExp);
            if (setMatchArray && setMatchArray.length > 1) {
                // the first result is inclusive of the r and the -, the second result is exclusive
                const currentSetNum: string = setMatchArray[1];
                const newSetNum: number = parseInt(currentSetNum) + delta;
                let newKey: string = `r${newSetNum}`;
                const rowInSetMatchArray: RegExpMatchArray = currentKey.match(rowInSetRegExp);
                if (rowInSetMatchArray && rowInSetMatchArray.length > 1) {
                    const currentRowInSet: string = rowInSetMatchArray[1];
                    newKey = newKey + `-${currentRowInSet}`;
                }
                return newKey;
            }
        }
        // if unable to augment, return the current key
        return currentKey;
    }

    /**
     * Process the empty temporal item. This is used to generate empty cells for member rows and open shift rows, in both
     * grouped and ungrouped view.
     * @param temporalItem
     * @param tagId
     * @param rowNum
     * @param scheduleCalendarType
     * @param scheduleGridIndices
     * @param scheduleGridCallbacks callbacks used by components of the grid
     * @param isAdmin
     * @param isViewGrouped
     * @param addCellHelper
     * @param cellCountIncrementer - optional (pass null or undefined if not needed) helper function that will be passed into the addCellHelper that can be used for counting the number of cells added
     * @param member - optional
     * @param durationInHours - optional
     * @param inOpenShiftRow
     * @param shownAvailabilitesMap - map used to track which availabilities have already been shown for a group
     */
    public static processEmptyTemporalItemForCellRendering(
        temporalItem: TemporalItem,
        tagId: string,
        rowNum: number,
        scheduleCalendarType: ScheduleCalendarType,
        scheduleGridIndices: IScheduleGridIndices,
        scheduleGridCallbacks: IScheduleGridCallbacks,
        scheduleViewSettings: IScheduleViewSettings,
        addCellHelper: (cellSettings: FlexiRowCellSettings, cellUnitSize: number, cellCountIncrementer?: (incrementBy: number) => void) => void,
        cellCountIncrementer: (incrementBy: number) => void,
        member?: IMemberEntity,
        durationInHours?: Number,
        inOpenShiftRow?: boolean,
        shownAvailabilitesMap?: IShownAvailabilitiesMap) {
        // Default to displaying empty cells for unhandled temporal item types
        if (temporalItem.type !== TemporalItemTypes.empty) {
            trace.warn(`ScheduleGridUtils.processEmptyTemporalItemForCellRendering: Unhandled temporal item type. type = ${temporalItem.type}, startTime = ${temporalItem.startTime ? temporalItem.startTime.format() : ""}`);
        }

        const { isAdmin = false, isViewGrouped = false, currentUserMemberId = "" } = scheduleViewSettings || {};

        const emptyCellAriaLabel: string = ScheduleGridUtils.getSchedulePageStrings().get("emptyCellLabel");

        /** In Day view, empty temporal items require special handling */
        if (ScheduleGridUtils.isDayView(scheduleCalendarType)) {
            ScheduleGridUtils.processEmptyTemporalItemForDayViewCellRendering(
                temporalItem,
                tagId,
                rowNum,
                scheduleCalendarType,
                scheduleGridIndices,
                scheduleGridCallbacks,
                scheduleViewSettings,
                emptyCellAriaLabel,
                addCellHelper,
                cellCountIncrementer,
                durationInHours,
                member,
                inOpenShiftRow,
                shownAvailabilitesMap);
            return;
        }

        // For an empty temporal item, construct a set of empty cells that span the duration of the temporal item
        const datesInEmptyTemporalItemRange = DateUtils.fastGetDatesInRange(temporalItem.startTime, temporalItem.endTime);
        for (let i = 0; i < datesInEmptyTemporalItemRange.length; i++) {
            const currentEmptyCellDate = datesInEmptyTemporalItemRange[i];
            const cellKey = this.getCellKey(scheduleGridIndices, rowNum);

            const emptyShiftCellSettings: FlexiRowCellSettings = ScheduleGridUtils.generateEmptyShiftCellSettings(
                1 /* cellUnitSize */,
                cellKey,
                styles.scheduleEmptyShiftCellBox,
                emptyCellAriaLabel,
                currentEmptyCellDate.clone().startOf("date"),
                currentEmptyCellDate.clone().endOf("date"),
                member,
                scheduleCalendarType,
                tagId,
                scheduleGridIndices,
                scheduleGridCallbacks,
                isAdmin,
                currentUserMemberId,
                isViewGrouped,
                isAdmin /*enableContextualMenu*/,
                inOpenShiftRow,
                durationInHours,
                shownAvailabilitesMap);
            addCellHelper(emptyShiftCellSettings, 1 /* shiftCellUnitSize */, cellCountIncrementer);
        }
    }

    /**
     * Process the empty temporal item for day view. This is used to generate empty cells for member rows and open shift rows, in both
     * grouped and ungrouped view.
     * @param temporalItem
     * @param tagId
     * @param rowNum
     * @param scheduleCalendarType
     * @param scheduleGridIndices
     * @param scheduleGridCallbacks callbacks used by components of the grid
     * @param emptyCellAriaLabel
     * @param isAdmin
     * @param addCellHelper
     * @param cellCountIncrementer - optional (pass null or undefined if not needed) helper function that will be passed into the addCellHelper that can be used for counting the number of cells added
     * @param durationInHours
     * @param member - optional
     * @param inOpenShiftRow
     * @param shownAvailabilitesMap - map used to track which availabilities have already been shown for a group
     */
    private static processEmptyTemporalItemForDayViewCellRendering(
        temporalItem: TemporalItem,
        tagId: string,
        rowNum: number,
        scheduleCalendarType: ScheduleCalendarType,
        scheduleGridIndices: IScheduleGridIndices,
        scheduleGridCallbacks: IScheduleGridCallbacks,
        scheduleViewSettings: IScheduleViewSettings,
        emptyCellAriaLabel: string,
        addCellHelper: (cellSettings: FlexiRowCellSettings, cellUnitSize: number, cellCountIncrementer: (incrementBy: number) => void) => void,
        cellCountIncrementer: (incrementBy: number) => void,
        durationInHours: Number,
        member?: IMemberEntity,
        inOpenShiftRow?: boolean,
        shownAvailabilitesMap?: IShownAvailabilitiesMap) {
        const { isAdmin = false, isViewGrouped = false, currentUserMemberId = "" } = scheduleViewSettings || {};

        let cellMoment = temporalItem.startTime.clone();
        let startMin = cellMoment.minutes();
        let endMin = temporalItem.endTime.minutes();
        // Add a half-sized empty cell if the empty interval starts on :15 or :45 min marks
        if (startMin === 15 || startMin === 45) {
            this.addHalfSizeEmptyCell(
                tagId,
                rowNum,
                scheduleGridIndices,
                scheduleGridCallbacks,
                emptyCellAriaLabel,
                cellMoment,
                durationInHours,
                scheduleCalendarType,
                isAdmin,
                currentUserMemberId,
                isViewGrouped,
                addCellHelper,
                cellCountIncrementer,
                member,
                inOpenShiftRow,
                shownAvailabilitesMap);
            // Increment the cellMoment start time so that the rest of the empty cells start at the appropriate time
            cellMoment.add(DayViewFormatCellInterval * 60 / 2, "minutes");
            // Decrement the duration of the empty interval to account for the empty cell we just added
            durationInHours = durationInHours.valueOf() - DayViewFormatCellInterval / 2;
        }

        // If the empty interval ends on :15 or :45 min marks, we'll decrement the duration of the empty cells by 15 so that the main for loop doesn't account
        // for this last portion. Then, we'll append a half sized cell after the for loop is finished
        if (endMin === 15 || endMin === 45) {
            durationInHours = durationInHours.valueOf() - DayViewFormatCellInterval / 2;
        }

        // Using .valueOf() to convert the durationInHours from 'Number' to 'number'
        for (let hoursOffset = 0; hoursOffset < durationInHours.valueOf(); hoursOffset += DayViewFormatCellInterval) {
            // We use the start time of the empty block to generate its index and key
            const cellKey = this.getCellKey(scheduleGridIndices, rowNum);
            const shiftCellSettings = ScheduleGridUtils.generateEmptyShiftCellSettings(
                DayViewStandardCellUnitSize /* this cell is full length */,
                cellKey,
                styles.scheduleEmptyDayViewShiftCellBox,
                emptyCellAriaLabel,
                cellMoment.clone(),
                cellMoment.clone().add(DayViewFormatCellInterval * 60, "minutes"),
                member,
                scheduleCalendarType,
                tagId,
                scheduleGridIndices,
                scheduleGridCallbacks,
                isAdmin,
                currentUserMemberId,
                isViewGrouped,
                isAdmin /*enableContextualMenu*/,
                inOpenShiftRow,
                durationInHours,
                shownAvailabilitesMap);

            addCellHelper(shiftCellSettings, DayViewStandardCellUnitSize, cellCountIncrementer);
            // Increment the cellMoment start time so that the rest of the empty cells start at the appropriate time
            cellMoment.add(DayViewFormatCellInterval * 60, "minutes");
        }
        // Append a half sized cell if the empty interval ends on :15 or :45 min marks
        if (endMin === 15 || endMin === 45) {
            this.addHalfSizeEmptyCell(
                tagId,
                rowNum,
                scheduleGridIndices,
                scheduleGridCallbacks,
                emptyCellAriaLabel,
                cellMoment,
                durationInHours,
                scheduleCalendarType,
                isAdmin,
                currentUserMemberId,
                isViewGrouped,
                addCellHelper,
                cellCountIncrementer,
                member,
                inOpenShiftRow,
                shownAvailabilitesMap);
        }
    }

    /**
     * Adds a half sized empty cell to the provided memberShiftCellsSettings list
     * @param tagId
     * @param rowNum
     * @param scheduleGridIndices
     * @param scheduleGridCallbacks callbacks used by components of the grid
     * @param emptyCellAriaLabel
     * @param isAdmin
     * @param addCellHelper
     * @param cellCountIncrementer - optional (pass null or undefined if not needed) helper function that will be passed into the addCellHelper that can be used for counting the number of cells added
     * @param durationInHours
     * @param member - optional
     * @param inOpenShiftRow
     * @param shownAvailabilitesMap - map used to track which availabilities have already been shown for a group
     */
    private static addHalfSizeEmptyCell(
        tagId: string,
        rowNum: number,
        scheduleGridIndices: IScheduleGridIndices,
        scheduleGridCallbacks: IScheduleGridCallbacks,
        emptyCellAriaLabel: string,
        cellMoment: Moment,
        durationInHours: Number,
        scheduleCalendarType: ScheduleCalendarType,
        isAdmin: boolean,
        currentUserMemberId: string,
        isViewGrouped: boolean,
        addCellHelper: (cellSettings: FlexiRowCellSettings, cellUnitSize: number, cellCountIncrementer: (incrementBy: number) => void) => void,
        cellCountIncrementer: (incrementBy: number) => void,
        member?: IMemberEntity,
        inOpenShiftRow?: boolean,
        shownAvailabilitesMap?: IShownAvailabilitiesMap) {
        const cellKey = this.getCellKey(scheduleGridIndices, rowNum);

        const shiftCellSettings = ScheduleGridUtils.generateEmptyShiftCellSettings(
            DayViewHalfCellUnitSize /* this 15 min cell is half-length */,
            cellKey,
            styles.scheduleEmptyDayViewShiftCellBox,
            emptyCellAriaLabel,
            cellMoment.clone(),
            cellMoment.clone().add(DayViewFormatCellInterval * 60 / 2, "minutes"),
            member,
            scheduleCalendarType,
            tagId,
            scheduleGridIndices,
            scheduleGridCallbacks,
            isAdmin,
            currentUserMemberId,
            isViewGrouped,
            isAdmin /*enableContextualMenu*/,
            inOpenShiftRow,
            durationInHours,
            shownAvailabilitesMap);

        addCellHelper(shiftCellSettings, DayViewHalfCellUnitSize, cellCountIncrementer);
    }

    /**
     * Calculate temporal items for a member's shift events
     *
     * Temporal items are used to designate what time ranges each shift event actually occupies in the schedule view.
     * For example, if we have a night shift from Wednesday 10pm to 8am of the following day, we'll setup a temporal item that begins
     * at midnight on Wednesday and ends right before Thursday midnight, so that the shift fully occupies the Wednesday schedule day cell.
     */
    public static calculateTemporalItemsForMember(viewStartDate: Moment, viewEndDate: Moment, memberShiftsData: MemberShiftsData): TemporalItem[] {
        let temporalItemsForShifts: TemporalItem[] = [];
        let memberShiftEvents: IShiftEntity[] = [];

        // First add the member's timeoffs, since we want to display those first before regular shifts in case the member needs multiple rows to
        // render their shift events
        if (memberShiftsData.memberTimeOffs) {
            memberShiftEvents = memberShiftsData.memberTimeOffs.slice(0);
        }

        // Add the member's shifts
        if (memberShiftsData.memberShifts) {
            memberShiftEvents = memberShiftEvents.concat(memberShiftsData.memberShifts);
        }

        memberShiftEvents.forEach((shift: IShiftEntity) => {
            const temporalItemType: TemporalItemTypes = ScheduleData.getTemporalTypeForShiftEvent(shift);
            let temporalItemStartTime = shift.startTime.clone();
            let temporalItemEndTime = shift.endTime.clone();
            let isShiftEventInView = false;

            switch (temporalItemType) {
                case TemporalItemTypes.shift: {
                    temporalItemStartTime = ScheduleGridUtils.roundShiftTemporalItemTimeByDay(temporalItemStartTime, true /* isStartTime */);
                    // Shifts are always rendered as a single day schedule cell that is placed on the starting day,
                    // so here we set the shift's end time to end of the shift's starting day.
                    temporalItemEndTime = ScheduleGridUtils.roundShiftTemporalItemTimeByDay(temporalItemStartTime.clone().endOf("day"), false /* isStartTime */);
                    // Shifts should be visible in the current schedule view only if their start time begins within the current view range
                    isShiftEventInView = shift.startTime.isSameOrAfter(viewStartDate) && shift.startTime.isBefore(viewEndDate);
                    break;
                }
                case TemporalItemTypes.timeOff:
                case TemporalItemTypes.timeOffRequest: {
                    // Timeoffs occupy the full date range of the timeoff events
                    temporalItemStartTime = ScheduleGridUtils.roundShiftTemporalItemTimeByDay(temporalItemStartTime, true /* isStartTime */);
                    temporalItemEndTime = ScheduleGridUtils.roundShiftTemporalItemTimeByDay(temporalItemEndTime, false /* isStartTime */);
                    // Timeoffs should be visible in the current schedule view if any portion of a timeoff's date range falls within the current view range
                    isShiftEventInView = ShiftUtils.shiftOverlapsStartsOrEndsBetween(shift, viewStartDate, viewEndDate);
                    break;
                }
            }

            if (isShiftEventInView) {
                temporalItemsForShifts.push({
                    startTime: temporalItemStartTime,
                    endTime: temporalItemEndTime,
                    type: temporalItemType,
                    shiftEvent: shift
                });
            }
        });

        return temporalItemsForShifts;
    }

    /**
     * Function that returns the css class name for a member row.
     * This is a separate function as this logic needs to be replicated in DayViewGrid
     * @param isMemberInReorderMode true, if this member is in reorder mode
     * @param isMemberDeletedFromTeam true, if this member has been deleted from the team
     * @param isMemberRemovedFromTag true, if this member has been removed from the tag
     * @return { string } string that has all CSS class names for the member
     */
    public static getClassNamesForMemberRows(isMemberInReorderMode: boolean, isMemberDeletedFromTeam: boolean, isMemberRemovedFromTag: boolean) {
        // Add a special class for the last member in the group.
        // This class is used for border rendering.
        return classNames(
            "sh-member-row", // sh-member-row is used by child components/elements to detect hover states
            styles.scheduleMemberRow,
            {
                [styles.inReorderMode]: isMemberInReorderMode,
                [styles.scheduleMemberDeleted]: isMemberDeletedFromTeam,
                [styles.scheduleMemberRemoved]: isMemberRemovedFromTag
            });
    }

    /**
     * Calculate the grid row settings for a given team member
     * @param scheduleViewSettings settings describing the current view
     * @param scheduleSelectionData objects used to track and setup selection
     * @param scheduleGridCallbacks callbacks used by components of the grid
     * @param scheduleGridIndices indices used to track grid cells
     * @param tagGroupName string of the tag's group name
     * @param tagGroupNameAria string of the tag's group name for Aria
     * @param rowSettingsForMemberRows accumulated row settings for member rows. the current member's settings will be added here.
     * @param cellSettingsForMemberRows accumulated row cell settings for member rows. the current member's settings will be added here
     * @param datesInRange array of dates for the current view
     * @param totalHours the total hours by the member in the group
     * @param memberShiftsData shifts data for the member. these shift events should be active and thus displayable.
     * @param memberRowIndexInGroup position of the member in the group
     * @param isLastMemberRowInGroup true, if this is the last member in the group
     * @param isOtherGroup - true if the current member is part of the other group
     * @param shownAvailabilitesMap - map of date and member to has shown availabilities
     * @param truncateLastName - true in case of union support if last name should be truncated to initial
     */
    public static addGridRowSettingsForGroupMember(
        scheduleViewSettings: IScheduleViewSettings,
        scheduleSelectionData: IScheduleSelectionData,
        scheduleGridCallbacks: IScheduleGridCallbacks,
        scheduleGridIndices: IScheduleGridIndices,
        tagGroupName: string,
        tagGroupNameAria: string,
        rowSettingsForMemberRows: FlexiGridRowSettings[],
        cellSettingsForMemberRows: FlexiRowCellSettings[][],
        datesInRange: Moment[],
        totalHours: number,
        memberShiftsData: MemberShiftsData,
        memberRowIndexInGroup: number,
        isOtherGroup: boolean,
        shownAvailabilitesMap: IShownAvailabilitiesMap,
        isMoveMemberEnabled: boolean) {

        const { isAdmin = false, isViewGrouped = false, truncateLastName = false, rowKeyForMemberInReorderMode = "", viewStartDate = null, viewEndDate = null } = scheduleViewSettings || {};

        const member = memberShiftsData.member;
        if (member) {
            // A member may have multiple shifts whose time ranges may overlap with each other. If this happens, we will render multiple rows
            // of shift events for the member.
            // Before figuring out which shift events overlap, we first need to calculate the temporal items for the member's shift events.
            // This calculation rounds the time values for each shift event as needed for the current Schedule view, and also determines how many
            // cells each shift event occupies (eg, in month/week views, shifts are represented as single days, whereas timeoffs are represented as full timeoff date ranges).
            let temporalItemsForMember: TemporalItem[] = ScheduleGridUtils.calculateTemporalItemsForMember(viewStartDate, viewEndDate, memberShiftsData);
            let temporalItemsForMemberByRow: TemporalItem[][] = ScheduleData.getNonOverlappingTemporalItemLists(temporalItemsForMember);
            const tagId = memberShiftsData.tagId;
            const memberId = member.id;

            let isMemberInReorderMode = false;

            const isMemberDeletedFromTeam = MemberUtils.isMemberDeletedFromTeam(member);
            const isMemberRemovedFromTag = TagUtils.isMemberRemovedFromTag(tagId, memberId);

            for (let memberRowIndex = 0; memberRowIndex < temporalItemsForMemberByRow.length; memberRowIndex++) {
                const temporalItemsForRow = temporalItemsForMemberByRow[memberRowIndex];
                // rowKey should be unique, and will be used by UX control when rendering and uses it to see if row is already rendered.
                // if it is not unique, duplicate row might not get rendered
                const memberRowKey: string = this.getRowKey(scheduleGridIndices, memberRowIndex);
                const memberRowId: string = ScheduleGridUtils.calculateMemberRowElementId(memberId, tagId);

                isMemberInReorderMode = isMemberInReorderMode || (memberRowKey === rowKeyForMemberInReorderMode);
                // 777 remove QAIDMemberRow if unused
                let dataAutomationId = AutomationUtil.getAutomationId("scheduler", "QAIDMemberRow") + scheduleGridIndices.nthRowSet;
                const memberRowClasses = ScheduleGridUtils.getClassNamesForMemberRows(isMemberInReorderMode, isMemberDeletedFromTeam, isMemberRemovedFromTag);

                // Currently, only the first row is draggable
                // Once the row is dropped onto a location, the subsequent rows fall in place
                // TODO: need to work with design on how they want to treat the subsquent rows
                const isDraggable = memberRowIndex === 0;

                const ariaPropsForRow: AriaProperties = {
                    role: AriaRoles.row
                };

                // Setup row settings for the member row
                const memberRowSettings: FlexiGridRowSettings = {
                    groupId: tagId,
                    rowElementId: memberRowId,
                    rowElementKey: memberRowKey,
                    rowHeight: parseInt(isViewGrouped ? styles.scheduleRowHeight : styles.scheduleUngroupedRowHeight),
                    rowClass: memberRowClasses,
                    rowDataAutomationId: dataAutomationId,
                    dragIndex: memberRowIndexInGroup,
                    isDraggable: isDraggable,
                    memberRowIndex: memberRowIndex,
                    showBlockingOverlay: isMemberInReorderMode,
                    ariaPropsForRow: ariaPropsForRow
                };

                // Determine if the member has active shifts
                const hasActiveShifts: boolean = ScheduleGridUtils.doesMemberHaveActiveShifts(temporalItemsForRow);

                // show shifts if the member has not been deleted or removed or if the member has active shifts
                if (hasActiveShifts || (!isMemberDeletedFromTeam && !isMemberRemovedFromTag)) {
                    // Setup cell settings for the member row
                    let memberRowCellSettings: FlexiRowCellSettings[] = [];

                    // Setup for the shift cells for the member
                    const memberShiftCellsSettings = ScheduleGridUtils.generateMemberShiftCellsSettings(
                        scheduleViewSettings,
                        scheduleSelectionData,
                        scheduleGridCallbacks,
                        scheduleGridIndices,
                        member,
                        temporalItemsForRow,
                        memberRowIndex,
                        tagId,
                        shownAvailabilitesMap);

                    rowSettingsForMemberRows.push(memberRowSettings);
                    // Iterate through each date in the current view, adding paid hours
                    let totalHours: number = 0;
                    let dateIndex = 0;
                    datesInRange.forEach((currentDate: Moment) => {
                        dateIndex = DateUtils.fastCalculateDateIndex(currentDate);
                        totalHours += memberShiftsData.hoursByDate.get(dateIndex);
                    });

                    // setup for the member name cell, which is the first cell in a member shifts row
                    if (memberRowIndex === 0) {
                        // show the member cell only for the first row of shifts
                        memberRowCellSettings.push(ScheduleGridUtils.generateMemberNameCellSettings(
                            scheduleGridCallbacks,
                            member,
                            hasActiveShifts,
                            tagGroupName,
                            tagGroupNameAria,
                            tagId,
                            totalHours,
                            memberRowKey,
                            isAdmin,
                            isDraggable,
                            isMemberDeletedFromTeam,
                            isMemberRemovedFromTag,
                            isViewGrouped,
                            isOtherGroup,
                            isMoveMemberEnabled,
                            truncateLastName
                        ));
                    } else {
                        // Setup a rowheader for member spacer cell, where its label includes the member's name.
                        // This will provide context for accessibility users when they navigate through shift cells within each member row.
                        // Depending on the screen reader, the rowheader will be read for either each gridcell item or the first time a gridcell
                        // item is selected within a row.
                        const ariaProps: AriaProperties = {
                            role: AriaRoles.rowheader,
                            label: AccessibilityUtils.getMemberRowAriaLabel(member, tagGroupNameAria, totalHours)
                        };

                        const cellKey = `member-name-spacer-cell-${memberId}-${tagId}-${memberRowIndex}`;
                        const cellClass = classNames(styles.scheduleMemberCellBox, "peopleColumnCell");
                        const spacerCellContentClass = "memberNameSpacerCell";
                        memberRowCellSettings.push(ScheduleGridUtils.generateSpacerCellSettings(cellKey, cellClass, spacerCellContentClass, ariaProps));
                    }

                    // concatenate the shift cell settings
                    memberRowCellSettings = memberRowCellSettings.concat(memberShiftCellsSettings);
                    cellSettingsForMemberRows.push(memberRowCellSettings);
                }
            }

            if (temporalItemsForMemberByRow.length) {
                // increment the row set only once per member per group. This helps keeps keys stable across views where members have multiple rows in a group
                this.incrementNthRowSet(scheduleGridIndices);
            }
        } else {
            trace.error("ScheduleGrid.addGridRowSettingsForGroupMember(): Member not found in store.");
        }
    }

    /**
     * Calculate the cell settings for the member name cell
     * @param member - member for name cell settings need to be calculated
     * @param hasActiveShifts - boolean when true, means that the member has active shifts in the current view
     * @param tagGroupName - Name of the tag/group to which the member belongs to
     * @param tagGroupNameAria - Aria name of the tag/group to which the member belongs to
     * @param tagId - id of the tag/group to which the member belongs to
     * @param hours - number of hours the member is scheduled for
     * @param memberRowKey - row key for the member row
     * @param isAdmin - whether cell should expose edit functionality
     * @param isDraggable - true, if the row can be dragged
     * @param isMemberDeletedFromTeam - true, if the user has been deleted from the team
     * @param isMemberRemovedFromTag - true, if the user has been removed from the group
     * @param instrumentScheduleEvent - helper function to instrument shceduling events
     * @param isViewGrouped: is the schedule in grouped view (true) or ungrouped view (false)
     * @param isOtherGroup - true if the current member is part of the other group
     * @param onMemberMoveInUngroupedView -  Hanlder for keyboard based member move in ungrouped view
     * @param truncateLastName - true in case of union support if last name should be truncated to initial
     */
    public static generateMemberNameCellSettings(
        scheduleGridCallbacks: IScheduleGridCallbacks,
        member: IMemberEntity,
        hasActiveShifts: boolean,
        tagGroupName: string,
        tagGroupNameAria: string,
        tagId: string,
        hours: number,
        memberRowKey: string,
        isAdmin: boolean,
        isDraggable: boolean,
        isMemberDeletedFromTeam: boolean,
        isMemberRemovedFromTag: boolean,
        isViewGrouped: boolean,
        isOtherGroup: boolean,
        isMoveMemberEnabled: boolean,
        truncateLastName?: boolean): FlexiRowCellSettings {

        const cellContents: MemberNameCellContentsItem = {
            tagId: tagId,
            hasActiveShifts: hasActiveShifts,
            member: member,
            hours: hours,
            isDraggable: isDraggable,
            isOtherGroup: isOtherGroup,
            isMemberRemovedFromTag: isMemberRemovedFromTag,
            isAdmin: isAdmin,
            isViewGrouped: isViewGrouped
        };

        const memberNameCellSettings: FlexiRowCellSettings = {
            cellElementKey: "member-name-cell-" + member.id,
            cellClass: classNames(styles.scheduleMemberCellBox, "peopleColumnCell"),
            cellContentsItem: cellContents,
            onShouldComponentUpdate: ScheduleGridUtils.shouldMemberNameCellUpdate,
            onRenderCellContents: (cellContentsItem: MemberNameCellContentsItem) => {
                return (
                    <ScheduleMemberCell
                        tagId={ tagId }
                        tagName={ tagGroupName }
                        tagNameAria={ tagGroupNameAria }
                        hasActiveShifts={ hasActiveShifts }
                        member={ member }
                        memberRowKey={ memberRowKey }
                        hours={ hours }
                        isDraggable={ isDraggable }
                        isOtherGroup={ isOtherGroup }
                        isMemberDeletedFromTeam={ isMemberDeletedFromTeam }
                        isMemberRemovedFromTag={ isMemberRemovedFromTag }
                        editEnabled={ isAdmin }
                        isViewGrouped={ isViewGrouped }
                        instrumentScheduleEvent={ scheduleGridCallbacks.instrumentScheduleEvent }
                        onMemberMoveInUngroupedView={ scheduleGridCallbacks.onMemberMove }
                        isMoveMemberEnabled={ isMoveMemberEnabled }
                        truncateLastName={ truncateLastName } />
                );
            }
        };
        return memberNameCellSettings;
    }

    /**
     * Return true if a shift has any conflicting shift in schedulesViewStateStore
     * @param IShiftEntity shift entity to determine if shift has conflicts
     */
    public static hasConflicts = (shift: IShiftEntity): boolean => {
        if (!shift || !schedulesViewStateStore().showShiftConflicts || !ConflictUtils.isAtleastOneConflictTypeEnabled()) {
            // short-circuit
            return false;
        }
        const member = TeamStore() && TeamStore().me;
        if (!ConflictUtils.isConflictEnabledForAdminInDateRange(MemberUtils.isAdmin(member), schedulesViewStateStore().viewEndDate)) {
            return false;
        }
        const shiftIdToConflictsMap = schedulesViewStateStore().conflictsInView;
        const conflictEntities = shiftIdToConflictsMap && shiftIdToConflictsMap.get(shift.id) || [];
        return conflictEntities && conflictEntities.length > 0;
    }

    /**
     * Generate cell settings for a given shift
     * @param shift specified shift
     * @param startTime
     * @param endTime
     * @param member the member to whom the given shift is assigned to
     * @param isAdmin true if the current user is an admin
     * @param currentUserMemberId member id of the current user
     * @param tagId the tagId in which the current shift falls into.
     * @param shiftCellKey
     * @param shiftCellUnitSize cell unit size (eg, =1 occupies one date column, =2 occupies two date columns, etc)
     * @param isSelected
     * @param scheduleGridIndices current indices used for iterating through cells in the schedule grid
     * @param onShiftCellInvoke callback for when a schedule cell is invoked by the user (eg, double click on schedule cell)
     * @param scheduleCellActionCallbacks callbacks for schedule cell actions
     * @param scheduleCalendarType
     * @param scheduleCellRenderSize schedule cell size
     * @param instrumentScheduleEvent - helper function to instrument shceduling events
     * @param showUnsharedStatus if false, hide share status indicators
     * @param doShowIcons
     * @param doShowNotes
     * @param doShowShiftRequestsIndicator
     * @param doShowActivities
     * @param showSlotBar
     * @param enableContextualMenu
     * @param doShowShiftDetails
     * @param selectionEnabled
     * @param setFlexItemSelected
     * @param getFlexItemSelected
     * @param setGridCellSelected
     * @param viewStart
     * @param viewEnd
     */
    public static generateShiftCellSettings(
        shift: IBaseShiftEntity,
        startTime: Moment,
        endTime: Moment,
        member: IMemberEntity,
        isAdmin: boolean,
        currentUserMemberId: string,
        tagId: string,
        shiftCellKey: string,
        shiftCellUnitSize: number,
        scheduleGridIndices: IScheduleGridIndices,
        scheduleGridCallbacks: IScheduleGridCallbacks,
        scheduleCalendarType: ScheduleCalendarType,
        scheduleCellRenderSize: ScheduleCellRenderSize,
        isViewGrouped: boolean,
        showUnsharedStatus: boolean,
        doShowIcons?: boolean,
        doShowNotes?: boolean,
        doShowShiftRequestsIndicator?: boolean,
        doShowActivities?: boolean,
        showSlotBar?: boolean,
        enableContextualMenu?: boolean,
        doShowShiftDetails?: boolean,
        selectionEnabled?: boolean,
        viewStart?: Moment,
        viewEnd?: Moment,
        doShow24HrShift?: boolean,
        isSharedScheduleView?: boolean): FlexiRowCellSettings {

        /*
            PERFORMANCE - robv notes 5/28/2021
            Doing all this cloning is slow and has significant impact on render perf. I'm really curious if this is not needed because
            the shift is already cloned when it is opened for edit. This was written BEFORE the new shift editor was written.
        */
        // Pass a copy of the shift object into the schedule cell. This ensure that the schedule cell and all
        // components composed within it aren't working with a shift object with mobx observable arrays/fields,
        // which can cause problems when modifying the shift. EX: activity flex items contain references to
        // shift objects which are modified during activity copy/paste
        const cellShift = !ShiftUtils.isOpenShift(shift)
            ? ShiftEntity.clone(shift as ShiftEntity)
            : OpenShiftEntity.clone(shift as OpenShiftEntity);

        const shiftHasConflicts = ScheduleGridUtils.hasConflicts(cellShift);

        let cellContentsInfo: ScheduleCellContentsInfo = {
            cellType: ScheduleCellType.Shift,
            shift: cellShift,
            startTime: startTime,
            endTime: endTime,
            colIdxVal: scheduleGridIndices.selectableCellIndex,
            cellUnitSize: shiftCellUnitSize,
            member: member,
            tagId: tagId,
            isViewGrouped: isViewGrouped,
            dataSelectionIndex: scheduleGridIndices.dataSelectionIndex,
            scheduleCalendarType: scheduleCalendarType,
            viewStart: viewStart,
            inOpenShiftRow: ShiftUtils.isOpenShift(shift),
            hasConflicts: shiftHasConflicts,
            cellInvokeCallback: isAdmin
                ? () => { scheduleGridCallbacks.onShiftCellInvoke(cellShift, member, tagId); }
                : () => {}
        };

        const shiftCellElementId: string = ScheduleGridUtils.calculateScheduleGridCellElementId(scheduleGridIndices.selectableCellIndex);

        const ariaPropsForCell: AriaProperties = {
            role: AriaRoles.gridcell
        };

        const dataSelectionIndexVal: number = scheduleGridIndices.dataSelectionIndex;
        const isTimeOffRequest = ShiftUtils.isTimeOffRequestEvent(shift);

        const animationClasses = classNames(styles.animatedShiftContainer, { [styles.nonAdmin]: !isAdmin });

        const shiftCellSettings: FlexiRowCellSettings = {
            animateCellClass: animationClasses,
            cellElementId: shiftCellElementId,
            cellElementKey: shiftCellKey,
            key: shiftCellKey,
            dataSelectionIndex: dataSelectionIndexVal,
            cellClass: !ScheduleGridUtils.isDayView(scheduleCalendarType) ?  styles.scheduleShiftCellBox : styles.scheduleDayViewShiftCellBox,
            cellFlexGrow: shiftCellUnitSize,
            cellFlexBasis: "0",
            cellContentsItem: cellContentsInfo,
            ariaPropsForCell: ariaPropsForCell,
            onShouldComponentUpdate: ScheduleGridUtils.shouldScheduleCellUpdate,
            onRenderCellContents: (cellContentsItem: ScheduleCellContentsInfo, cellHasFocus: boolean) => {
                return (
                    <SelectableContentWrapper selectionEnabled={ selectionEnabled && !isTimeOffRequest } selectionKey={ shiftCellKey } isSelected={ ScheduleGridSelection.isCellKeySelected }>
                        <ScheduleCell
                            cellHasFocus={ cellHasFocus }
                            getNumCellsSelected={ ScheduleGridSelection.getNumCellsSelected }
                            member={ member }
                            isAdmin={ isAdmin }
                            currentUserMemberId={ currentUserMemberId }
                            scheduleCalendarType={ scheduleCalendarType }
                            tagId={ tagId }
                            selectionEnabled= { selectionEnabled }
                            enableContextualMenu={ enableContextualMenu }
                            scheduleCellActionCallbacks={ scheduleGridCallbacks.scheduleCellActionCallbacks }
                            instrumentScheduleEvent= { scheduleGridCallbacks.instrumentScheduleEvent }
                            showIcons={ doShowIcons }
                            showNotes={ doShowNotes }
                            show24HrShift={ doShow24HrShift }
                            showActivities={ doShowActivities }
                            showSlotBar={ showSlotBar }
                            showShiftRequestsIndicator={ doShowShiftRequestsIndicator }
                            showShiftDetails={ doShowShiftDetails }
                            setFlexItemSelected={ scheduleGridCallbacks.setFlexItemSelected }
                            getFlexItemSelected={ scheduleGridCallbacks.getFlexItemSelected }
                            setGridCellSelected={ scheduleGridCallbacks.setGridCellSelected }
                            viewStart={ viewStart }
                            dataSelectionIndexVal={ dataSelectionIndexVal }
                            shift={ cellShift }
                            isSharedScheduleView={ isSharedScheduleView }
                            hasConflicts={ shiftHasConflicts }
                            showUnsharedStatus={ showUnsharedStatus }
                            disableShare={ !showUnsharedStatus }
                            inOpenShiftRow={ ShiftUtils.isOpenShift(shift) }
                            isEntityNewlyEdited={ scheduleGridCallbacks.isEntityNewlyEdited }
                            viewEnd={ viewEnd }
                            scheduleCellRenderSize={ scheduleCellRenderSize }
                            isViewGrouped={ isViewGrouped }
                            getGroupedShiftRequestForShiftId={ scheduleGridCallbacks.getGroupedShiftRequestForShiftId }
                            navigateToRequestPage={ scheduleGridCallbacks.navigateToRequestPage }
                            hideTitleFor24HrShift={ scheduleCalendarType == ScheduleCalendarTypes.Month }/>
                    </SelectableContentWrapper>
                );
            }
        };

        return shiftCellSettings;
    }

    /**
     * Generate cell settings for an empty cell
     * @param shiftCellUnitSize the relative width of the cell, used for flex display
     * @param shiftCellKey unique element key for this cell. used for more efficient React rendering
     * @param cellClass
     * @param emptyCellAriaLabel
     * @param currentDate
     * @param member
     * @param isAdmin true if the current user is an admin
     * @param scheduleCalendarType
     * @param tagId
     * @param scheduleGridIndices current indices used for iterating through cells in the schedule grid
     * @param onEmptyCellInvoke callback for when a schedule cell is invoked by the user (eg, double click on schedule cell)
     * @param scheduleCellActionCallbacks callbacks for schedule cell actions
     * @param isAdmin true if editing is enabled for the schedule grid
     * @param currentUserMemberId member id of the current user
     * @param instrumentScheduleEvent
     * @param inOpenShiftRow true if the empty cell is in the open shift row. Affects how the onEmptyCellInvoke() callback is fired
     * @param isViewGrouped
     * @param onServiceError callback to handle service errors
     * @param enabledContextualMenu
     * @param inOpenShiftsRow
     * @param durationInHours - the duration of the empty time period that this cell is in
     * @param shownAvailabilitesMap - the map of availabilities that have been displayed
     */
    private static generateEmptyShiftCellSettings(
        cellUnitSize: number,
        shiftCellKey: string,
        cellClass: string,
        emptyCellAriaLabel: string,
        startTime: Moment,
        endTime: Moment,
        member: IMemberEntity,
        scheduleCalendarType: ScheduleCalendarType,
        tagId: string,
        scheduleGridIndices: IScheduleGridIndices,
        scheduleGridCallbacks: IScheduleGridCallbacks,
        isAdmin: boolean,
        currentUserMemberId: string,
        isViewGrouped: boolean,
        enableContextualMenu: boolean,
        inOpenShiftRow?: boolean,
        durationInHours?: Number,
        shownAvailabilitesMap?: IShownAvailabilitiesMap): FlexiRowCellSettings {

        const startOfDay = startTime.clone().startOf("day");
        const endOfDay = endTime.clone().endOf("day");
        const shouldShowAvailability = ScheduleGridUtils.isAvailabilitiesEnabled()
            && !ScheduleGridUtils.isScheduleAvailabilityHidden.get() // are availabilities toggled on
            && AvailabilityUtils.shouldShowAvailabilityForCell(shownAvailabilitesMap, member, startOfDay, isAdmin, scheduleCalendarType, durationInHours);
        let availability: IAvailabilityEntity = shouldShowAvailability ?
            getAvailability(member, startOfDay, endOfDay)
            : null;
        let ariaLabel: string = emptyCellAriaLabel;
        if (availability) {
            ariaLabel = AvailabilityUtils.getEmptyCellAriaLabel(availability);
        }

        const cellContentsInfo: ScheduleCellContentsInfo = {
            cellType: ScheduleCellType.Empty,
            startTime: startTime,
            endTime: endTime,
            colIdxVal: scheduleGridIndices.selectableCellIndex,
            cellUnitSize: cellUnitSize,
            member: member,
            tagId: tagId,
            viewStart: null,
            isViewGrouped: isViewGrouped,
            dataSelectionIndex: scheduleGridIndices.dataSelectionIndex,
            scheduleCalendarType: scheduleCalendarType,
            availability: availability,
            inOpenShiftRow: inOpenShiftRow,
            cellInvokeCallback: isAdmin
                ? () => { scheduleGridCallbacks.onEmptyCellInvoke(startTime, member, tagId, inOpenShiftRow); }
                : () => {}
        };

        const cellElementId: string = ScheduleGridUtils.calculateScheduleGridCellElementId(scheduleGridIndices.selectableCellIndex);
        const cellColumnIndex: number = scheduleGridIndices.selectableCellIndex % scheduleGridIndices.numCellColumns;
        const emptyCellAriaLabelledBy: string = `${calculateScheduleHeaderCellElementId(cellColumnIndex)} ${cellElementId}`;

        const ariaPropsForCell: AriaProperties = {
            role: AriaRoles.gridcell,
            label: ariaLabel,
            labelledBy: emptyCellAriaLabelledBy
        };

        const emptyShiftCellSettings: FlexiRowCellSettings = {
            cellElementId: cellElementId,
            cellElementKey: shiftCellKey,
            dataSelectionIndex: scheduleGridIndices.dataSelectionIndex,
            cellClass: cellClass,
            cellFlexGrow: cellUnitSize,
            cellFlexBasis: "0",
            cellContentsItem: cellContentsInfo,
            ariaPropsForCell: ariaPropsForCell,
            onShouldComponentUpdate: ScheduleGridUtils.shouldScheduleCellUpdate,
            onRenderCellContents: (cellContentsItem: ScheduleCellContentsInfo, cellHasFocus: boolean) => {
                return (
                    <SelectableContentWrapper selectionEnabled={ true } selectionKey={ shiftCellKey } isSelected={ ScheduleGridSelection.isCellKeySelected }>
                        <ScheduleCell
                            cellHasFocus={ cellHasFocus }
                            getNumCellsSelected={ ScheduleGridSelection.getNumCellsSelected }
                            member={ member }
                            isAdmin={ isAdmin }
                            currentUserMemberId={ currentUserMemberId }
                            scheduleCalendarType={ scheduleCalendarType }
                            tagId={ tagId }
                            selectionEnabled={ true }
                            enableContextualMenu={ enableContextualMenu }
                            scheduleCellActionCallbacks={ scheduleGridCallbacks.scheduleCellActionCallbacks }
                            instrumentScheduleEvent={ scheduleGridCallbacks.instrumentScheduleEvent }
                            inOpenShiftRow={ inOpenShiftRow }
                            startOfDay={ startOfDay }
                            availability={ availability }/>
                    </SelectableContentWrapper>
                );
            }
        };

        return emptyShiftCellSettings;
    }

    /**
     * This function is provided to FlexiCell components that render ScheduleCells to improve performance by preventing unnecesary renders, which
     * are very expensive.
     *
     * When shouldComponentUpdate() is run in those FlexiCells, they will call this function to compare their current and incoming cellContentsItems.
     * This function is responsible for detecting differences in these items and determining if an update (render) is necessary.
     *
     * IMPORTANT: When ScheduleCell props or generation behavior changes, this function needs to be updated
     * We also try to order these checks in order of most likely to trigger an update. This avoids extra checks.
     */
    private static shouldScheduleCellUpdate(oldCellContentsItem: ScheduleCellContentsInfo, newCellContentsItem: ScheduleCellContentsInfo): boolean {
        const shouldUpdate = ScheduleGridUtils.hasScheduleCellShiftChanged(oldCellContentsItem, newCellContentsItem) ||
                             (oldCellContentsItem.dataSelectionIndex !== newCellContentsItem.dataSelectionIndex) ||
                             (!DateUtils.isSameMoment(oldCellContentsItem.startTime ? oldCellContentsItem.startTime.clone().startOf("day") : null, newCellContentsItem.startTime ? newCellContentsItem.startTime.clone().startOf("day") : null)) ||
                             (oldCellContentsItem.scheduleCalendarType !== newCellContentsItem.scheduleCalendarType) ||
                             (oldCellContentsItem.tagId !== newCellContentsItem.tagId) ||
                             (!DateUtils.isSameMoment(oldCellContentsItem.viewStart, newCellContentsItem.viewStart)) ||
                             ScheduleGridUtils.hasScheduleCellMemberChanged(oldCellContentsItem.member, newCellContentsItem.member) ||
                             (oldCellContentsItem.isViewGrouped !== newCellContentsItem.isViewGrouped) ||
                             (oldCellContentsItem.cellUnitSize !== newCellContentsItem.cellUnitSize) || // this check handles the case where an empty cell is changed to a half sized empty cell in day view via shift add. None of our other checks will detect this
                             !AvailabilityUtils.areEqual(oldCellContentsItem.availability, newCellContentsItem.availability) ||
                             ScheduleGridUtils.hasConflictInfoChanged(oldCellContentsItem, newCellContentsItem);
        return shouldUpdate;
    }

    /**
     * This helper function returns true if the member for the old and new cellContentsItem has changed.
     */
    private static hasScheduleCellMemberChanged(oldCellContentsItemMember: IMemberEntity, newCellContentsItemMember: IMemberEntity): boolean {
        return !!((oldCellContentsItemMember && !newCellContentsItemMember || !oldCellContentsItemMember && newCellContentsItemMember) ||
                  (oldCellContentsItemMember && newCellContentsItemMember) && (oldCellContentsItemMember.id !== newCellContentsItemMember.id || oldCellContentsItemMember.eTag !== newCellContentsItemMember.eTag));
    }

    /**
     * This helper function returns true if the old and new cellContentsItem have differing shifts. Note: the check to isPublished is necessary because share actions do not update the eTag of the shift
     */
    private static hasScheduleCellShiftChanged(oldCellContentsItem: ScheduleCellContentsInfo, newCellContentsItem: ScheduleCellContentsInfo): boolean {
        return !!((oldCellContentsItem.shift && !newCellContentsItem.shift) ||
                 (!oldCellContentsItem.shift && newCellContentsItem.shift) ||
                 (oldCellContentsItem.shift && newCellContentsItem.shift && ShiftUtils.hasItemBeenModifiedByService(oldCellContentsItem.shift, newCellContentsItem.shift)));
    }

    /** This helper function is used to determine if the conflict information for the shifts has changed, ex if a new shift was added that is now conflicting with this shift */
    private static hasConflictInfoChanged(oldCellContentsItem: ScheduleCellContentsInfo, newCellContentsItem: ScheduleCellContentsInfo) {
        return newCellContentsItem.hasConflicts !== oldCellContentsItem.hasConflicts;
    }

    /**
     * This function is provided to FlexiCell components that render MemberNameCells to improve performance by preventing unnecesary renders, which
     * are very expensive.
     *
     * When shouldComponentUpdate() is run in those FlexiCells, they will call this function to compare their current and incoming cellContentsItems.
     * This function is responsible for detecting differences in these items and determining if an update (render) is necessary.
     *
     * IMPORTANT: When MemberNameCell props or generation behavior changes, this function needs to be updated
     * We also try to order these checks in order of most likely to trigger an update. This avoids extra checks.
     */
    private static shouldMemberNameCellUpdate(oldCellContentsItem: MemberNameCellContentsItem, newCellContentsItem: MemberNameCellContentsItem): boolean {
        return (ScheduleGridUtils.hasScheduleCellMemberChanged(oldCellContentsItem.member, newCellContentsItem.member) ||
                (oldCellContentsItem.tagId !== newCellContentsItem.tagId) ||
                (oldCellContentsItem.hours !== newCellContentsItem.hours) ||
                (oldCellContentsItem.isDraggable !== newCellContentsItem.isDraggable) ||
                (oldCellContentsItem.isOtherGroup !== newCellContentsItem.isOtherGroup) ||
                (oldCellContentsItem.isMemberRemovedFromTag !== newCellContentsItem.isMemberRemovedFromTag) ||
                (oldCellContentsItem.hasActiveShifts !== newCellContentsItem.hasActiveShifts) ||
                (oldCellContentsItem.isAdmin !== newCellContentsItem.isAdmin) ||
                (oldCellContentsItem.isViewGrouped !== newCellContentsItem.isViewGrouped));
    }

    /**
     * This function is provided to FlexiCell components that render MemberDeleteCells to improve performance by preventing unnecesary renders, which
     * are very expensive.
     *
     * When shouldComponentUpdate() is run in those FlexiCells, they will call this function to compare their current and incoming cellContentsItems.
     * This function is responsible for detecting differences in these items and determining if an update (render) is necessary.
     *
     * IMPORTANT: When MemberDeleteCells props or generation behavior changes, this function needs to be updated
     * We also try to order these checks in order of most likely to trigger an update. This avoids extra checks.
     */
    private static shouldMemberDeleteCellUpdate(oldCellContentsItem: MemberDeleteCellContentsItem, newCellContentsItem: MemberDeleteCellContentsItem): boolean {
        return (ScheduleGridUtils.hasScheduleCellMemberChanged(oldCellContentsItem.member, newCellContentsItem.member) ||
                (oldCellContentsItem.tagId !== newCellContentsItem.tagId) ||
                (oldCellContentsItem.hasActiveShifts !== newCellContentsItem.hasActiveShifts));
    }

    /**
     * Calculate the selection key for the given schedule cell item
     * This is called by the Office Fabric Selection component
     */
    public static getCellSelectionKey(
        item: IObjectWithKey & FlexiRowCellSettings,
        index?: number) {
        return item && item.cellElementKey ? item.cellElementKey : String(index);
    }

    /**
     * Return true if schedule cell selection is enabled.
     * If disabled, then clicking to select and marquee drag selection will be disabled.
     */
    public static isSelectionEnabled(selection: Selection): boolean {
        return !!selection;
    }

    /**
     * Helper function that returns true if the schedule is in day view
     */
    public static isDayView(scheduleCalendarType: ScheduleCalendarType): boolean {
        return scheduleCalendarType && scheduleCalendarType === ScheduleCalendarTypes.Day;
    }

    /**
     * Returns true if open shifts is enabled
     */
    public static isOpenShiftsEnabled() {
        return FlightSettingsService.isFlightEnabled(FlightKeys.EnableOpenShifts) && ECSConfigService.isECSFeatureEnabled(ECSConfigKey.EnableOpenShifts);
    }

    /**
     * Returns true if open shifts are hidden for user (admins can filter openShifts in Schedules view)
     * or for non-admin if they are hidden for team
     */
    public static isOpenShiftsHidden() {
        const userStore: UserStoreSchema = UserStore();
        const member = TeamStore() && TeamStore().me;
        return (userStore && userStore.userSettings && userStore.userSettings.hideOpenShifts)
            || ((!MemberUtils.isAdmin(member)) && TeamStore().team && TeamStore().team.hideOpenShifts);
    }

    /**
     * True if the shift requests indicator should be shown for the given shift
     * @param shift
     * @param isAdmin
     */
    public static doShowShiftRequestsIndicator(shift: IBaseShiftEntity, isAdmin: boolean): boolean {
        return shift && isAdmin && ShiftUtils.isWorkingShift(shift) && ShiftUtils.isOpenShift(shift) && this.isOpenShiftRequestsEnabled() && !ShiftUtils.sharedAndDraftTimesDiffer(shift) && !ShiftUtils.isUnsharedDeletedShift(shift);
    }

    /**
     * Returns true if open shift requests is enabled
     */
    public static isOpenShiftRequestsEnabled() {
        return FlightSettingsService.isFlightEnabled(FlightKeys.EnableOpenShiftRequestsOnWeb) && ECSConfigService.isECSFeatureEnabled(ECSConfigKey.EnableOpenShiftRequests);
    }

    /**
     * Returns true if the tag should dipslay open shifts
     * @param tag
     */
    public static showOpenShiftsForTag(tag: ITagEntity, currentUser: IMemberEntity): boolean {
        const openShiftsEnabledAndNotHidden: boolean = ScheduleGridUtils.isOpenShiftsEnabled() && !this.isOpenShiftsHidden();
        const enableOpenShiftRequests: boolean = ECSConfigService.isECSFeatureEnabled(ECSConfigKey.EnableOpenShiftRequests) && FlightSettingsService.isFlightEnabled(FlightKeys.EnableOpenShiftRequestsOnWeb);
        const isViewGrouped: boolean = !!tag;

        // All views: A tag should show the open shifts rows for the current user if open shifts is toggled on and not hidden
        if (!openShiftsEnabledAndNotHidden) {
            return false;
        }

        const member = TeamStore() && TeamStore().me;
        // Grouped View: User must be an admin to see the open shift rows for this tag, unless open shift
        // requests are enabled, in which case any member in the tag should see open shift rows for this tag. Open
        // shift rows in the default tag should also be visible in this case
        if (isViewGrouped) {
             return MemberUtils.isAdmin(member) || (enableOpenShiftRequests && TagUtils.containsMember(tag, currentUser));
        } else {
            // Ungrouped View: User must be an admin to see the open shift rows for this tag, unless open shift
            // requests are enabled, in which any member should see open shift rows
            return MemberUtils.isAdmin(member) || enableOpenShiftRequests;
        }
    }

    /**
     * Calculate the element id for a member row element.
     * This is used for Aria accessibility label references
     * @param memberId
     * @param tagId
     */
    public static calculateMemberRowElementId(memberId: string, tagId: string): string {
        return tagId ? `sh-mr-${tagId}-${memberId}` : `sh-mr-${memberId}`;
    }

    /**
     * Calculate the element id for an open shift row element.
     * This is used for Aria accessibility label references
     * @param tagId
     * @param rowIdx
     */
    public static calculateOpenShiftRowElementId(tagId: string, rowIdx: number): string {
        return tagId ? `sh-osr-${tagId}-${rowIdx}` : `sh-osr-${rowIdx}`;
    }

    /**
     * Returns true if focus is currently on or inside a selectable schedule grid cell or a shift cell contextual menu.
     */
    public static isFocusInScheduleGridCellOrShiftMenu(): boolean {
        const activeElement = document.activeElement;
        const closestFlexiCell = activeElement && activeElement.closest && activeElement.closest(`.${FLEXICELL_CONTENT_WRAPPER_CLASS}[data-selection-index]`);
        const closestMenuCallout = activeElement && activeElement.closest && activeElement.closest(`.${SHIFT_CELL_CONTEXT_MENU_CALLOUT}`);

        return closestFlexiCell != null || closestMenuCallout != null;
    }

    /**
     * Calculate the element id for a schedule cell element.
     * This is used for Aria accessibility label references
     * @param selectableCellIndex Selectable cell index for this schedule cell
     */
    private static calculateScheduleGridCellElementId(selectableCellIndex: number): string {
        return `sh-sgc-${selectableCellIndex}`;
    }

    /**
    * Returns true if the schedule view has changed in terms of the layout type or view range being displayed
    * @param currentProps Schedule view's current properties
    * @param nextProps Schedule view's incoming set of properties
    */
    public static isScheduleViewChanged(currentProps: PeopleViewGridProps, nextProps: PeopleViewGridProps): boolean {
        let isChanged = false;
        if (!currentProps.viewStartDate.isSame(nextProps.viewStartDate) ||
            !currentProps.viewEndDate.isSame(nextProps.viewEndDate) ||
            (currentProps.scheduleCalendarType !== nextProps.scheduleCalendarType) ||
            (currentProps.isAdmin !== nextProps.isAdmin) ||
            (currentProps.isViewGrouped !== nextProps.isViewGrouped)) {
                isChanged = true;
        }
        return isChanged;
    }

    /**
     * Calculate the aria name for a tag group name
     * @param tagGroupName
     */
    public static calculateTagGroupNameForAria(tagGroupName: string, isViewGrouped: boolean) {
        const tagGroupNameAria: string = tagGroupName ?
            tagGroupName :
            (isViewGrouped ? ScheduleGridUtils.getCommonStrings().get("unnamedGroup") : ScheduleGridUtils.getSchedulePageStrings().get("ungroupedViewGroupNameAria"));  // Use default descriptive names for unnamed groups
        return tagGroupNameAria;
    }

    /**
     * Move a shift to the open shifts
     * @param shift shift to be moved
     * @param shiftMember member for whom the shift is currently assigned to
     * @param targetDate open shifts target date
     * @param targetGroupTagId open shifts group tag id
     * @param changeSource change source for change tracking
     * @param groupedShiftsData grouped shifts data
     * @param onServiceError service error callback
     */
    public static moveShiftToOpenShifts(
        shift: IBaseShiftEntity,
        shiftMember: IMemberEntity,
        targetDate: Moment,
        targetGroupTagId: string,
        changeSource: ChangeSource,
        groupedShiftsData: GroupedShiftsData,
        scheduleCalendarType: ScheduleCalendarType,
        onServiceError: (error: StaffHubHttpError) => void) {

        // TODO:  Look into converting this into an orchestrator that raises an action to
        // display the confirmation dialog if needed.
        // mapa said:  In general we should avoid showing/mixing UI elements in Utils. Ideally this
        // functionality should be in orchestrator and that will raise action if it has to show confirm dialog.
        // That action will have callback methods that will be passed. Action listener should set UI state such
        // that dialog will be shown. Showing UI elements will make it difficult for us to abstract or test the
        // components in isolated way and not extensible

        if (!shift || !shiftMember || !targetDate || !groupedShiftsData) {
            return;
        }

        // If no group is specified, move the shift into the "Other" group
        if (!targetGroupTagId) {
            targetGroupTagId = TagUtils.DEFAULT_TAG_ID;
        }

        let groupedTagData: GroupedTagData = groupedShiftsData.groupedTagData.get(targetGroupTagId);
        if (groupedTagData) {
            // Add the shift as a new open shift, unless there's an existing similar open shift, in which case
            // we'll update the existing open shift to include this shift (eg, increase its count).

            let targetShift: IShiftEntity = null;
            const targetDateIndex = DateUtils.fastCalculateDateIndex(targetDate);
            let openShiftsForTargetDate: IOpenShiftEntity[] = groupedTagData.openShiftsByDate.get(targetDateIndex);
            if (openShiftsForTargetDate) {
                for (let i = 0; i < openShiftsForTargetDate.length; i++) {
                    let currentOpenShift: IOpenShiftEntity = openShiftsForTargetDate[i];
                    if (ShiftUtils.areSimilarShiftsForOpenShifts(shift, currentOpenShift)) {
                        targetShift = currentOpenShift;
                        break;
                    }
                }
            }

            // If adding a shift to an existing open shift, the source shift's notes and paid activities won't be preserved,
            // so we display a dialog asking the user if they want to proceed.
            // Otherwise, no dialog is needed and the shift move will be performed without confirmation.

            let showConfirmationDialog: boolean = false;
            if (targetShift) {
                showConfirmationDialog = !!shift.notes || ShiftUtils.shiftHasPaidActivities(shift);
            }

            confirm(
                ScheduleGridUtils.getSchedulePageStrings().get("moveToOpenShiftsConfirmDialogTitle"),
                ScheduleGridUtils.getSchedulePageStrings().get("moveToOpenShiftsConfirmDialogContentText"),
                !showConfirmationDialog, /* resolveImmediately */
                {
                    okText: ScheduleGridUtils.getCommonStrings().get("proceed"),
                    cancelText: ScheduleGridUtils.getCommonStrings().get("cancel"),
                    isBlocking: true
                }
            ).then(() => {
                // Perform the shift move action
                // A moved shift is either added as a new open shift or added to an existing open shift
                // TODO: Make parameters an object
                triggerShiftMove(
                    shift, /* shift */
                    ShiftMoveActionTypes.Add, /* shiftMoveActionType */
                    shift.startTime, /* sourceStartTime */
                    null, /* sourceEndTime */
                    targetGroupTagId, /* targetTagId */
                    targetShift, /* targetShift */
                    changeSource, /* changeSource */
                    scheduleCalendarType, /* scheduleCalendarType */
                    onServiceError /* onServiceError */
                );
            }).catch(() => {
                // Cancel clicked, do nothing
            });
        }
    }

     /**
     * Returns true if the shift view grid data is loaded and ready to be rendered
     */
    public static isShiftViewGridDataReadyForRender(groupedShiftsData: GroupedShiftsData): boolean {
        return !!(groupedShiftsData && groupedShiftsData.groupedTagData && groupedShiftsData.groupedTagData.size > 0);
    }

     /**
     * Generate the settings for the grid
     */
    public static getGridSettings(
        viewStartDate: Moment,
        viewEndDate: Moment,
        isAdmin: boolean,
        currentUserMemberId: string,
        scheduleCalendarType: ScheduleCalendarType,
        employeeViewType: EmployeeViewType,
        isViewGrouped: boolean,
        collapsedTags: string[],
        simpleTagHeaders: boolean,
        showStaffPer30Rows: boolean,
        rowKeyForMemberInReorderMode: string,
        truncateLastName: boolean,
        scheduleGridSelection: ScheduleGridSelection,
        selectionCellItems: FlexiRowCellSettings[],
        onShiftCellInvoke: ShiftCellInvokeFunction,
        onEmptyCellInvoke: EmptyCellInvokeFunction,
        scheduleCellActionCallbacks: ScheduleCellActionCallbacks,
        instrumentScheduleEvent: InstrumentScheduleEventFunction,
        onMemberMove: (memberId: string, delta: -1 | 1) => boolean,
        onServiceError: (error: StaffHubHttpError) => void,
        setFlexItemSelected: SetFlexItemSelectedFunction,
        getFlexItemSelected: GetFlexItemSelectedFunction,
        setGridCellSelected: SetGridCellSelectedFunction,
        isEntityNewlyEdited:  (id: string) => boolean,
        memberShiftsDataGroups: MemberShiftsDataGroup[],
        getGroupedShiftRequestForShiftId: (shiftId: string, shiftRequestState: ShiftRequestState) => IGroupedOpenShiftRequestEntity,
        isScheduleGroupOrMemberFilterApplied: boolean,
        selectedShiftFilters?: ShiftFilterType[],
        navigateToRequestPage?: (initialRequestId?: string, shiftId?: string, requestType?: ShiftRequestType) => void,
        isSharedScheduleView?: boolean): IScheduleGridSettings {
        return  {
            scheduleViewSettings: {
                viewStartDate: viewStartDate,
                viewEndDate: viewEndDate,
                isAdmin: isAdmin,
                currentUserMemberId: currentUserMemberId,
                scheduleCalendarType: scheduleCalendarType,
                employeeViewType: employeeViewType,
                isViewGrouped: isViewGrouped,
                collapsedTags: collapsedTags,
                simpleTagHeaders: simpleTagHeaders,
                showStaffPer30Rows: showStaffPer30Rows,
                rowKeyForMemberInReorderMode: rowKeyForMemberInReorderMode,
                truncateLastName: truncateLastName,
                isScheduleGroupOrMemberFilterApplied: isScheduleGroupOrMemberFilterApplied,
                selectedShiftFilters: selectedShiftFilters,
                isSharedScheduleView: isSharedScheduleView
            },
            scheduleSelectionData: {
                scheduleGridSelection: scheduleGridSelection,
                selectionCellItems: selectionCellItems
            },
            scheduleGridCallbacks: {
                onShiftCellInvoke: onShiftCellInvoke,
                onEmptyCellInvoke: onEmptyCellInvoke,
                scheduleCellActionCallbacks: scheduleCellActionCallbacks,
                instrumentScheduleEvent: instrumentScheduleEvent,
                onMemberMove: onMemberMove,
                onServiceError: onServiceError,
                setFlexItemSelected: setFlexItemSelected,
                getFlexItemSelected: getFlexItemSelected,
                setGridCellSelected: setGridCellSelected,
                isEntityNewlyEdited: isEntityNewlyEdited,
                getGroupedShiftRequestForShiftId: getGroupedShiftRequestForShiftId,
                navigateToRequestPage: navigateToRequestPage
            },
            scheduleGridData: {
                sortedMemberShiftsDataGroups: memberShiftsDataGroups
            }
        };
    }

    /**
     * Helper method to check if a filter is applied
     */
    public static isScheduleGroupOrMemberFilterApplied(filteredTags: Array<ITagEntity>, filteredMembers: Array<IMemberEntity>) {
        return !!((filteredTags && filteredTags.length > 0) || (filteredMembers && filteredMembers.length > 0));
    }

    /**
     * Helper method to check if a current view is YourShifts View
     */
    public static isYourShiftsView(employeeViewType: EmployeeViewType) {
        return employeeViewType && employeeViewType === EmployeeViewTypes.YourShiftsView;
    }

    /**
     * Helper method to identify if ActiveShifts shift filter is selected
     * @param selectedShiftFilters List of selected Shift Filters
     * @returns boolean - true if current selected filters contains ActiveShifts shift filter
     */
    public static isActiveShiftsShiftFilterSelected(selectedShiftFilters: ShiftFilterType[]): boolean {
        const currentFilters = selectedShiftFilters || [];
        return currentFilters.indexOf(ShiftFilterTypes.ActiveShifts) >= 0;
    }

    /**
     * Helper method to identify if Timeoff shift filter is selected
     * @param selectedShiftFilters List of selected Shift Filters
     * @returns boolean - true if current selected filters contains TimeOffShifts shift filter
     */
    public static isTimeoffsShiftFilterSelected(selectedShiftFilters: ShiftFilterType[]): boolean {
        const currentFilters = selectedShiftFilters || [];
        return currentFilters.indexOf(ShiftFilterTypes.TimeoffShifts) >= 0;
    }

     /**
     * Helper method to identify if conflict filter is selected
     * @param selectedShiftFilters List of selected Shift Filters
     * @returns boolean - true if current selected filters contains ConflictShifts shift filter
     */
    public static isConflictFilterSelected(selectedShiftFilters: ShiftFilterType[]): boolean {
        const currentFilters = selectedShiftFilters || [];
        return currentFilters.indexOf(ShiftFilterTypes.ConflictingShifts) >= 0;
    }

    /**
     * Helper method to hide row based on applied Shift Filters
     * @param selectedShiftFilters - List of Shift Filters applied
     * @param hasShifts - boolean to indicate if there are shifts
     * @param hasTimeoffs - boolean to indicate if there are timeoffs
     * @param hasConflics - boolean to indicate if there are conflicting shifts or time offs
     * @returns boolean - Return true if we should hide the row based on filters applied
     */
    public static shouldFilterBasedOnSelectedShiftFilters(selectedShiftFilters: ShiftFilterType[], hasShifts: boolean, hasTimeoffs: boolean, hasConflicts: boolean): boolean {
        // show the row if none of the filters are applied
        if (!selectedShiftFilters || !selectedShiftFilters.length) {
            return false;
        }
        // Hide the row if active shifts filter is applied, but row has no active shifts or if time off filter is applied, but row has no time off shifts or if the conflict filter is applied and row has no conflicts
        if ((hasShifts && ScheduleGridUtils.isActiveShiftsShiftFilterSelected(selectedShiftFilters)) || hasTimeoffs && ScheduleGridUtils.isTimeoffsShiftFilterSelected(selectedShiftFilters) ||
        hasConflicts && ScheduleGridUtils.isConflictFilterSelected(selectedShiftFilters)) {
            return false;
        }

        // otherwise hide the row as there is some shift filter applied, but no filtered shifts applicable
        return true;
    }

    public static showNoUserShiftError(groupedShiftsData: GroupedShiftsData, employeeViewType: EmployeeViewType, isViewGrouped: boolean): boolean {
        let numOfValidTags = groupedShiftsData && groupedShiftsData.groupedTagData.size;
        const groupHasShifts = groupedShiftsData && groupedShiftsData.shifts.length > 0;
        // If there are more then 0 tags, filter out the tags which have state as deleted with no shifts
        if (numOfValidTags > 0) {
            groupedShiftsData.groupedTagData.forEach((value: GroupedTagData, key: string) => {
                const currentTag = value.tag;
                const currentUser = TeamStore() && TeamStore().me;
                if (!TagUtils.isActiveTag(currentTag)) {
                    // if the tag state is not active check if the deleted tag is for current member
                    if (TagUtils.containsMember(currentTag, currentUser) && !groupHasShifts) {
                        numOfValidTags--;
                    }
                }
            });
        }
        // If total number of valid tags are equal to 0 , no shifts error is shown.
        return (isViewGrouped && employeeViewType === EmployeeViewTypes.YourShiftsView && numOfValidTags === 0);
    }

    /**
     * Helper method to check if any of the filtered members have at least one group.
     */
    public static filteredMembersHaveGroups(filteredMembers: IMemberEntity[], groupedShiftsData: GroupedShiftsData, isViewGrouped: boolean): boolean {
        // If the view is not grouped, then we don't need to check if the filtered members have groups
        if (!isViewGrouped) {
            return false;
        }

        return filteredMembers.some((member: IMemberEntity) => {
            const { groupedTagData } = groupedShiftsData;

            return Array.from(groupedTagData.values()).some(
                ({ tag }) => TagUtils.containsMember(tag, member)
            );
        });
    }
}