import { orchestrator } from "satcheljs";
import { GraphService } from "sh-services";

import { loadUserPicture } from "../actions/loadUserPicture";
import { setUserPictureLoadingState } from "../actions/setUserPictureLoadingState";
import { setUserPictureUrl } from "../actions/setUserPictureUrl";
import { LoadingState } from "../common/LoadingState";
import { UsersPicturesStore } from "../store/UsersPicturesStore";

/**
 * Loads the user profile picture.
 */
export const onLoadUserPicture = orchestrator(loadUserPicture, async (action) => {
    const { userId } = action;
    const loadingState = UsersPicturesStore().loadingStates.get(userId) ?? LoadingState.NotStarted;

    if (loadingState != LoadingState.NotStarted) {
        return;
    }

    setUserPictureLoadingState(userId, LoadingState.Loading);

    // TODO: Use Teams Middle Tier service.
    // TODO: Create PersonaManager using proper service clients.
    const pictureUrl = await GraphService.getUserProfilePhotoBlobUrl(userId);

    if (!pictureUrl) {
        setUserPictureLoadingState(userId, LoadingState.Failed);
    } else {
        setUserPictureLoadingState(userId, LoadingState.Succeed);
        setUserPictureUrl(userId, pictureUrl);
    }
});
