import * as moment from "moment";
import AvailabilityUtils from "./AvailabilityUtils";
import DateUtils from "sh-application/utility/DateUtils";
import MemberUtils from "sh-application/utility/MemberUtils";
import ShiftUtils from "./ShiftUtils";
import StringsStore from "sh-strings/store";
import TagUtils from "./TagUtils";
import {
    ConflictType,
    IAvailabilityConflictEntity,
    IAvailabilityEntity,
    IBaseConflictEntity,
    IBaseShiftEntity,
    IConflictDismissEntity,
    IMemberEntity,
    IShiftConflictEntity,
    IShiftEntity
    } from "sh-models";
import { DismissedConflictsStore, ShiftMemberDateInfo } from "sh-conflict-store";
import { ECSConfigKey, ECSConfigService } from "sh-services";
import { ObservableMap } from "mobx";
import { TeamStore } from "sh-stores/sh-team-store";

export default class ConflictUtils {
    private static _isFetchingDismissedConflicts = false;

    /**
     * Sets a flag that says we are currently fetching dimissed conflicts. This is used to prevent
     * multiple fetches.
     * @param isFetching pass true if we are currently fetching dismissed conflicts
     */
    public static setIsFetchingDismissedConflicts(isFetching: boolean): void {
        ConflictUtils._isFetchingDismissedConflicts = isFetching;
    }

    /**
     * Gets the value of the flag that says if we are fetching dimissed conflicts
     * @returns true if we are currently fetching dismissed conflicts
     */
    public static getIsFetchingDismissedConflicts(): boolean {
        return ConflictUtils._isFetchingDismissedConflicts;
    }

    /**
     * Returns the generated ShiftMemberDateInfo with the list of generated start of dates
     * @param shift the shift entity for which we have can have a list of ShiftMemberDateInfo
     * @returns ShiftMemberDateInfo: note only crossOver shift and multiday shifts can have more than
     * one startOfDates.
     */
    public static generateShiftMemberDateInfo(shift: IShiftEntity): ShiftMemberDateInfo {
        if (!shift) {
            return null;
        }

        // get the interval of the startDate of the shift
        const shiftDateInterval = DateUtils.getStartOfDay(shift.startTime);

        // its an overlapping or multiday shift
        let memberDateInfo: ShiftMemberDateInfo = {
            memberId: shift.memberId,
            startOfDates: new Set<number>()
        };
        // start of day, because we will have to account for the the end date
        let dayIntervalIterator = shiftDateInterval;
        const shiftEndTimeClone = shift.endTime.clone();

        while (dayIntervalIterator.isSameOrBefore(shiftEndTimeClone)) {
            memberDateInfo.startOfDates.add(dayIntervalIterator.valueOf());
            dayIntervalIterator = DateUtils.startOfNextDay(dayIntervalIterator);
        }
        return memberDateInfo;
    }

    /**
     * Returns true if the conflict entity belongs to the shiftId and conflicting shiftId
     * @param conflictEntity conflict entity in reference
     * @param shiftId shiftId of the supposed conflict entity
     * @param conflictingShiftId conflicting shiftId of the supposed conflict entity
     */
    public static isSameConflictEntity(conflictEntity: IShiftConflictEntity, shiftId: string, conflictingShiftId: string): boolean {
        if (conflictEntity.shiftId === shiftId && conflictEntity.conflictingShiftId === conflictingShiftId
            || conflictEntity.conflictingShiftId === shiftId && conflictEntity.shiftId === conflictingShiftId) {
               return true;
        }

        return false;
    }

    /**
     * Returns true if the availability conflict entity is already dismissed
     * @param dismissedEntities string[] already dismissed conflict entityIds
     * @param availabilityId conflicting shiftId of the supposed conflict entity
     */
    public static isAlreadyDismissedAvailabilityConflict(dismissedEntities: string[], availabilityId: string): boolean {
        const isDismissalEnabled = ECSConfigService.isECSFeatureEnabled(ECSConfigKey.EnableConflictsDismissal);
        if (!dismissedEntities || !availabilityId || !isDismissalEnabled) {
            return false;
        }

        let dismissedEntity: IConflictDismissEntity;
        let isConflicting: boolean = false;

        if (dismissedEntities) {
            dismissedEntities.forEach((entityId: string) => {
                if (DismissedConflictsStore().entityIdToDismissedConflictEntities.has(entityId)) {
                    dismissedEntity = DismissedConflictsStore().entityIdToDismissedConflictEntities.get(entityId);
                    if (dismissedEntity && dismissedEntity.entityOne && dismissedEntity.entityOne.id === availabilityId) {
                        isConflicting = true;
                        return ;
                    }

                    if (dismissedEntity && dismissedEntity.entityTwo && dismissedEntity.entityTwo.id === availabilityId) {
                        isConflicting = true;
                        return ;
                    }
                }
            });
        }

        return isConflicting;
    }

    /**
     * Returns true if the shift conflict entity is already dismissed
     * @param dismissedEntities string[] already dismissed conflict entity ids
     * @param conflictingShiftId conflicting shiftId of the supposed conflict entity
     */
    public static isAlreadyDismissedShiftConflict(dismissedEntities: string[], conflictingShiftId: string): boolean {
        const isDismissalEnabled = ECSConfigService.isECSFeatureEnabled(ECSConfigKey.EnableConflictsDismissal);
        if (!dismissedEntities || !conflictingShiftId || !isDismissalEnabled) {
            return false;
        }

        let dismissedEntity: IConflictDismissEntity;
        let isConflicting: boolean = false;

        if (dismissedEntities) {
            dismissedEntities.forEach((entityId: string) => {
                if (DismissedConflictsStore().entityIdToDismissedConflictEntities.has(entityId)) {
                    dismissedEntity = DismissedConflictsStore().entityIdToDismissedConflictEntities.get(entityId);
                    if (dismissedEntity && dismissedEntity.entityOne && dismissedEntity.entityOne.id === conflictingShiftId) {
                        isConflicting = true;
                        return ;
                    }

                    if (dismissedEntity && dismissedEntity.entityTwo && dismissedEntity.entityTwo.id === conflictingShiftId) {
                        isConflicting = true;
                        return ;
                    }
                }
            });
        }
       return isConflicting;
     }

    /**
     * Returns true if two date times are conflicting
     * @param startTime1 start time of the first date range
     * @param endTime1 end time of the first date range
     * @param startTime2 start time of the second date range
     * @param endTime2 end time of the second date range
     */
    public static areTwoDatetimesConflicting(startTime1: moment.Moment, endTime1: moment.Moment, startTime2: moment.Moment, endTime2: moment.Moment): boolean {
        if (!startTime1 || !endTime1 || !startTime2 || !endTime2) {
            return false;
        }

        if (startTime1.isBefore(endTime2) && (startTime2.isBefore(endTime1))) {
            return true;
        }

        return false;
    }

    /**
     * Get the conflicting shift Id
     * @param baseConflictEntity IBaseConflictEntity
     * @param shiftId the shiftId for which we are looking up conflicting shift
     */
    public static getConflictingEntityId(baseConflictEntity: IBaseConflictEntity, shiftId: string): string {
        if ( baseConflictEntity.conflictType === ConflictType.ShiftAvailabilityConflict ) {
            const conflictEntity = baseConflictEntity as IAvailabilityConflictEntity;
            return conflictEntity.availabilityId;
        } else {
            const conflictEntity = baseConflictEntity as IShiftConflictEntity;
            return this.getConflictinShiftIdForShift(conflictEntity, shiftId);
        }
    }

    /**
     * Given the conflicting entity and shift Id determine the conflicting shift id
     * @param conflictEntity IShiftConflictEntity
     * @param shiftId the shiftId for which we are looking up conflicting shift
     */
    public static getConflictinShiftIdForShift(conflictEntity: IShiftConflictEntity, shiftId: string): string {
        return conflictEntity.shiftId === shiftId ?  conflictEntity.conflictingShiftId : conflictEntity.shiftId;
    }

    /**
     * Return boolean if ecs is enabled for conflict management,
     * checks if conflict is enabled in the date range,
     * checks if user is the admin
     */
    public static isConflictEnabledForAdminInDateRange(isAdmin: boolean, viewStateEndDate?: moment.Moment): boolean {
        if (!isAdmin || !ECSConfigService.isECSFeatureEnabled(ECSConfigKey.EnableConflictManagement)) {
            return false;
        }
        return viewStateEndDate ? this.isConflictEnabledInDateRange(viewStateEndDate) : true;
    }

    public static isConflictEnabledInDateRange(viewStateEndDate?: moment.Moment): boolean {
        return ECSConfigService.isECSFeatureEnabled(ECSConfigKey.EnableConflictManagementInPast)
                ? true
                : DateUtils.fastIsDateNowOrFuture(viewStateEndDate);
    }

    /**
     * Return boolean if ecs is enabled for particular conflict type
     * @param conflictType type of conflict to check ECS
     */
    public static isConflictTypeEnabled(conflictType: ConflictType): boolean {
        if (!conflictType) {
            return false;
        }

        switch (conflictType) {
            case ConflictType.OverlappingShiftConflict:
                return ECSConfigService.isECSFeatureEnabled(ECSConfigKey.EnableShiftOverlapConflicts);
            case ConflictType.ShiftTimeOffConflict:
                return ECSConfigService.isECSFeatureEnabled(ECSConfigKey.EnableShiftTimeoffConflicts);
            case ConflictType.ShiftAvailabilityConflict:
                return ECSConfigService.isECSFeatureEnabled(ECSConfigKey.EnableShiftAvailabilityConflicts);
        }
    }

    /**
     * Return true if atleast one of the conflict type has ECS flag enabled true
     */
    public static isAtleastOneConflictTypeEnabled(): boolean {
        return ConflictUtils.isConflictTypeEnabled(ConflictType.OverlappingShiftConflict) ||
            ConflictUtils.isConflictTypeEnabled(ConflictType.ShiftTimeOffConflict) ||
            ConflictUtils.isConflictTypeEnabled(ConflictType.ShiftAvailabilityConflict);
    }

    /**
     * Returns boolean for rendering conflict icon
     * @param shift IShiftEntity Shift to render
     */
    public static showConflictIcon(shift: IShiftEntity): boolean {
        const currentUser: IMemberEntity = TeamStore() && TeamStore().me;
        // User should be admin and shift should have conflicts
        return shift && MemberUtils.isAdmin(currentUser);
    }

    /**
     * Returns the alert string to be fired when there is conflict
     * @param conflictCount total number of conflicts
     */
    public static getConflictCountAlertString(conflictCount: number) {
        const conflictString = StringsStore().registeredStringModules.get("conflictManagement").strings;
        let conflictCountString: string = "" ;

        if ( conflictCount === 0) {
            conflictCountString = conflictString.get("noConflictsAriaLabel");
        } else if ( conflictCount > 0) {
            conflictCountString = conflictString.get("numOfConflictsAriaLabel").format(conflictCount.toString());
        }
        return conflictCountString;
    }

    /**
     * Return true if a shift has any conflicting shift in schedulesViewStateStore
     * @param shiftIdshift entity to determine if shift has conflicts
     * @param conflictsInView the conflictsInView in scheduleViewStateStore
     */
    public static hasConflicts(shiftId: string, conflictsInView: ObservableMap<string>): boolean {
        if (!shiftId) {
            return;
        }
        const conflictEntities = conflictsInView && conflictsInView.get(shiftId);
        return conflictEntities && conflictEntities.length > 0;
    }

    /**
     * Return a list of conflictEntities by verifying if confilcts exists
     * @param shiftId id of the shift to find conflicts
     * @param conflictsInStore the requestShiftIdToConflictMap in conflictRequestStore
     */
    public static getRequestConflictEntities(shiftId: string, conflictsInStore: ObservableMap<string, IBaseConflictEntity[]>) {
        const currentUser: IMemberEntity = TeamStore() && TeamStore().me;
        if (!ConflictUtils.isConflictEnabledForAdminInDateRange(MemberUtils.isAdmin(currentUser)) || !shiftId || !ConflictUtils.isAtleastOneConflictTypeEnabled()) {
            return [];
        }
        const hasConflicts = ConflictUtils.hasConflicts(shiftId, conflictsInStore);
        let conflictEntities: IBaseConflictEntity[] = [];

        if (shiftId && hasConflicts) {
            conflictEntities = conflictsInStore && conflictsInStore.get(shiftId);
        }
        return conflictEntities;
    }

    /**
    * Retrieves the tagId from shifts and gets its Tag Name to display else displayes unnamed group
    * @param conflictingShift shift entity with conflicts
    */
    public static getGroupNameFromShift(conflictingShift: IBaseShiftEntity): string {
        if (!conflictingShift) {
            return null;
        }
        const tagId = conflictingShift && ShiftUtils.getTagIdFromShift(conflictingShift);
        const groupName = tagId && TagUtils.getTagNameFromId(tagId);
        // Returns the group name of where the user belongs to else return "Unnamed group" if its empty
        return groupName ? groupName : StringsStore().registeredStringModules.get("common").strings.get("unnamedGroup");
    }

    /**
     * Returns a string mentioning the members availability time slots
     * @param conflictingAvailability conflicting Availability
    */
    public static getAvailabilityTimeSlotString(conflictingAvailability: IAvailabilityEntity): string {
        if (!conflictingAvailability) {
            return null;
        }
        const shiftStrings = StringsStore().registeredStringModules.get("shiftRequests").strings;
        const memberAvailabilityTimeSlots = AvailabilityUtils.getUserFriendlyStrings(conflictingAvailability);
        const memberAvailabilityTimeSlotString: string = (memberAvailabilityTimeSlots && memberAvailabilityTimeSlots.length > 0) ? memberAvailabilityTimeSlots.join(shiftStrings.get("dataValueDelimiter")) : "";
        return memberAvailabilityTimeSlotString;
    }
}