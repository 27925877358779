import * as React from 'react';
import Getter from './types/Getter';
import LazyModule from './LazyModule';
import LazyImport from './LazyImport';

export interface StubComponentState {
    renderer(): JSX.Element;
}

export interface LazyComponent<TProps, TComponent> extends React.Component<TProps, {}> {
    getInnerComponent(): TComponent;
}

export interface LazyComponentClass<TProps, TComponent> {
    new (props?: TProps, context?: any): LazyComponent<TProps, TComponent>;
}

export interface CreateLazyComponentFunction {
    <TProps, TModule>(
        lazyModule: LazyModule<TModule>,
        getter: Getter<React.ComponentClass<TProps> | React.StatelessComponent<TProps>, TModule>,
        stubElement?: JSX.Element,
        importErrorHandler?: (reason: any) => void
    ): React.ComponentClass<TProps>;
    <TProps, TModule, TComponent extends React.Component<TProps, any>>(
        lazyModule: LazyModule<TModule>,
        getter: Getter<React.ComponentClass<TProps> | React.StatelessComponent<TProps>, TModule>,
        stubElement?: JSX.Element,
        importErrorHandler?: (reason: any) => void
    ): LazyComponentClass<TProps, TComponent>;
}

// For usage of this API, see the owa-bundling README
let createLazyComponent: CreateLazyComponentFunction = function createLazyComponent<
    TProps,
    TModule,
    TComponent extends React.Component<TProps, any>
>(
    lazyModule: LazyModule<TModule>,
    getter: Getter<React.ComponentClass<TProps>, TModule>,
    stubElement?: JSX.Element,
    importErrorHandler?: (reason: any) => void
) {
    let lazyImport = new LazyImport(lazyModule, getter);

    return class extends React.Component<TProps, StubComponentState> {
        private innerComponent: TComponent;

        private mounted: boolean;

        constructor(props: TProps) {
            super(props);
            this.state = { renderer: () => stubElement || null };
        }

        getInnerComponent() {
            return this.innerComponent;
        }

        componentDidMount() {
            this.mounted = true;

            let importPromise = lazyImport.import().then(ComponentClass => {
                if (this.mounted) {
                    this.setState({
                        renderer: () => {
                            return (
                                <ComponentClass
                                    ref={ ref => (this.innerComponent = ref as any) }
                                    {...this.props}
                                />
                            );
                        }
                    });
                }
            });

            if (importErrorHandler) {
                importPromise.catch(importErrorHandler);
            }
        }

        componentWillUnmount() {
            this.mounted = false;
        }

        render() {
            return this.state.renderer();
        }
    };
};

export default createLazyComponent;
