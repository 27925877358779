import { getStrings } from "@microsoft/shifts-experiences-shared";
import { AriaRoles, generateDomPropertiesForAria } from "owa-accessibility";
import { NavLinkProps } from "react-router-dom";
import { getGenericEventPropertiesObject } from "sh-instrumentation";
import { ECSConfigKey, ECSConfigService, InstrumentationService } from "sh-services";
import { TeamStore } from "sh-stores";

import { appViewState } from "../../store";
import setGlobalCommandBarSelectedTab from "../../store/mutatorActions/setGlobalCommandBarSelectedTab";
import { GlobalCommandBarTab } from "../../store/schema/GlobalCommandBarViewState";
import KeyboardUtils from "../../utility/KeyboardUtils";
import UrlFactory from "../../utility/UrlFactory";

const styles = require("./GlobalNavigationPivot.scss");

/**
 * Gets the 'Other open shifts' tab nav link properties or undefined if feature flag is disabled.
 * @returns The 'Other open shifts' tab nav link properties or undefined if feature flag is disabled.
 */
export const getOpenShiftsNavLinkProps = (): NavLinkProps | undefined => {
    const isFeatureEnabled = ECSConfigService.isECSFeatureEnabled(ECSConfigKey.CrossLocationMarketplaceFeatureEnabled);

    if (!isFeatureEnabled) {
        return undefined;
    }

    const { selectedTab } = appViewState().globalCommandBarViewState;
    const { teamId } = TeamStore();
    const isSelected = selectedTab == GlobalCommandBarTab.OtherOpenShifts;
    const pathname = UrlFactory.getOtherOpenShiftsUrl(teamId);

    const to = {
        pathname,
        search: window.location.search
    };

    const onClick = (): void => {
        setGlobalCommandBarSelectedTab(GlobalCommandBarTab.OtherOpenShifts);

        InstrumentationService.logEvent(InstrumentationService.events.OpenShiftsExperience, [
            getGenericEventPropertiesObject(
                InstrumentationService.properties.EventType,
                InstrumentationService.values.OtherOpenShiftsTabClicked
            )
        ]);
    };

    const onKeyDown = (event: React.KeyboardEvent<HTMLElement>): void => {
        if (KeyboardUtils.isActionKeyPressed(event)) {
            onClick();
        }
    };

    return {
        activeClassName: styles.pivotItemsSelected,
        className: styles.pivotItemsUnselected,
        onClick,
        onKeyDown,
        to,
        ...generateDomPropertiesForAria({
            role: AriaRoles.menuitem,
            label: getStrings("header", "otherOpenShiftsLinkText"),
            selected: isSelected
        })
    };
};
