import * as React from "react";
import IdleTimer from "react-idle-timer";
import { ECSConfigKey, ECSConfigService, SyncService } from "sh-services";

/**
 * The minimum allowed duration that the user has to be inactive for us to assume that they're idle
 * (used if ECS config is too low or invalid)
 */
const MIN_IDLE_STATE_DURATION = 10 * 1000;

/**
 * Prevents calling the onAction event too often, if multiple action happen during this duration
 */
const IDLE_ACTION_DEBOUNCE_DURATION = 250;

/**
 * Monitors user keyboard/mouse/touch interactions with the app to detect when the user is inactive
 */
export default class IdleWatcher extends React.PureComponent<any, any> {

    /**
     * Handle the status change to being active.
     */
    private onActive = (ev: Event): void => {
        SyncService.onUserIdleStateChange(false);
    }

    /**
     * Handle the status change to being idle.
     */
    private onIdle = (ev: Event): void => {
        SyncService.onUserIdleStateChange(true);
    }

    /**
     * The duration that the user has to be inactive for us to assume that they're idle (and the tab is inactive)
     */
    get UserIdleStateDurationMs(): number {
        // Enforce min 10 seconds, in case ECS configs are broken
        return Math.max(ECSConfigService.getECSFeatureSetting(ECSConfigKey.UserIdleStateDurationMs), MIN_IDLE_STATE_DURATION);
    }

    render() {
        return (
                <IdleTimer
                        element={ document }
                        onActive={ this.onActive }
                        onIdle={ this.onIdle }
                        debounce={ IDLE_ACTION_DEBOUNCE_DURATION }
                        timeout={ this.UserIdleStateDurationMs } />
            );
    }
}