import * as React from "react";
import ShiftUtils from "sh-application/utility/ShiftUtils";
import StringsStore from "sh-strings/store";
import { Icon } from "@fluentui/react";
import { ScheduleCellRenderSize } from "sh-application/../StaffHubConstants";
import { ShiftCellProps } from "./ShiftCellProps";
import { TimeOffReasonsStore } from "sh-timeoffreasons-store";
import { trace } from "owa-trace";

const styles = require("./TimeOffRequestShift.scss");
const classNames = require("classnames/bind");

/**
 * TimeOffRequestShift to render TimeOff Requests
 */
export default class TimeOffRequestShift extends React.Component<ShiftCellProps, {}> {
    private static _timeOffRequestTitle = "";
    private static _timeOffReasonFallbackName = "";

    constructor(props: ShiftCellProps) {
        super(props);
        if (!TimeOffRequestShift._timeOffRequestTitle) {
            TimeOffRequestShift._timeOffRequestTitle = StringsStore().registeredStringModules.get("shifts").strings.get("timeOffRequestTitle");
        }
        if (!TimeOffRequestShift._timeOffReasonFallbackName) {
            TimeOffRequestShift._timeOffReasonFallbackName = StringsStore().registeredStringModules.get("shifts").strings.get("timeOffReasonFallbackName");
        }
    }
    renderTimeOffRequestIcon(): JSX.Element {
        if (this.isRenderSmallSize()) {
            return <Icon iconName={ "Blocked" } aria-hidden="true"></Icon>;
        }

        return null;
    }

    renderTimeOffRequestTitleString(): JSX.Element {
        if (this.isRenderSmallSize()) {
            return null;
        }
        return (
            <div className={ styles.titleContainer }>
                <div className={ styles.dot } />
                <div className={ styles.title }>{ TimeOffRequestShift._timeOffRequestTitle }</div>
            </div>
        );
    }

    renderReason(): JSX.Element {
        const { shift } = this.props;

        if (this.isRenderSmallSize()) {
            return null;
        }

        if (!shift.timeOffReasonId) {
            trace.warn("Timeoff Shift request doesn't have time off reason!");
        }

        const timeOffReason = TimeOffReasonsStore().timeOffReasons.get(shift.timeOffReasonId);
        const timeOffReasonName = timeOffReason ? timeOffReason.name : TimeOffRequestShift._timeOffReasonFallbackName;
        return <div className={ styles.notes }>{ timeOffReasonName }</div>;
    }

    private isRenderSmallSize(): boolean {
        return this.props.scheduleCellRenderSize === ScheduleCellRenderSize.Small;
    }

    render() {
        const unsharedChangesLabel = ShiftUtils.shiftHasUnsharedEdits(this.props.shift) ? ShiftUtils.getUnsharedChangesShiftIndicatorAriaLabel() : "";
        const ariaLabel = unsharedChangesLabel + ShiftUtils.calculateShiftTitleAriaLabel(this.props.shift);

        return (
            <div className={ styles.shiftRequest }>
                <div className={ classNames(styles.left) } aria-label={ ariaLabel }>
                    { this.renderTimeOffRequestIcon() }
                    { this.renderTimeOffRequestTitleString() }
                    { this.renderReason() }
                </div>
            </div>
        );
    }
}