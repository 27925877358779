import schedulesViewStateStore from "../store/store";
import ShiftUtils from "sh-application/utility/ShiftUtils";
import { deleteOpenShiftsInStore } from "sh-open-shift-store";
import { deleteOpenShiftsInView, updateOpenShiftsInView } from "../index";
import { IOpenShiftEntity } from "sh-models";
import { Moment } from "moment";
import { orchestrator } from "satcheljs";

/**
 * When updates to open shifts are triggered via the UI or via sync notifications, this orchestrator will be triggered.
 * It will parse the updated open  and pass those that fit into the current view to the updateOpenShiftsInView() mutator, which
 * will update the map of open shifts in the local view store. Calls deleteOpenShiftsInView() on open shifts that are not
 * displayable for view and need to be removed from the store
 */
export default orchestrator(deleteOpenShiftsInStore, async actionMessage => {
    const viewStart: Moment = schedulesViewStateStore().viewStartDate;
    const viewEnd: Moment = schedulesViewStateStore().viewEndDate;
    let openShiftsToUpdate: IOpenShiftEntity[] = [];
    let openShiftsToDelete: IOpenShiftEntity[] = [];

    // push deleted shifts in range but not for display into a delete array
    // deleted shifts in range and for display get added to the update list
    for (let i = 0; i < actionMessage.deletedOpenShifts.length; i++) {
        const openShift: IOpenShiftEntity = actionMessage.deletedOpenShifts[i];
        const openShiftInRange: boolean = ShiftUtils.shiftOverlapsStartsOrEndsBetween(openShift, viewStart, viewEnd, false/*includeEdges*/);
        const openShiftDiplayableForView: boolean = ShiftUtils.isOpenShiftDisplayableForScheduleView(openShift);
        if (openShiftInRange) {
            if (openShiftDiplayableForView) {
                openShiftsToUpdate.push(openShift);
            } else {
                openShiftsToDelete.push(openShift);
            }
        }
    }

    if (openShiftsToUpdate.length) {
        updateOpenShiftsInView(openShiftsToUpdate);
    }
    if (openShiftsToDelete.length) {
        deleteOpenShiftsInView(openShiftsToDelete);
    }
});