import * as moment from "moment";
import BrowserUtils from "sh-application/utility/BrowserUtils";
import InstrumentationUtils from "sh-application/utility/InstrumentationUtils";
import schedulesViewStateStore from "../schedules/lib/store/store";
import { getGenericEventPropertiesObject } from "sh-instrumentation";
import { InstrumentationService } from "sh-services";
import { mutatorAction } from "satcheljs";
import {
    PRINT_IE_DEFAULT_FONT_SIZE,
    PRINT_NO_SCALE_ROOT_FONT_SIZE,
    PRINT_SCALE_TO_ONE_PAGE_ROOT_FONT_SIZE,
    PrintEntryPoint
    } from "../../../StaffHubConstants";
import { PrintListener } from "sh-application/components/common/PrintWatcher";

export function setDocumentFontForPrint() {
    const viewState = schedulesViewStateStore();

    if (BrowserUtils.isChrome()) {
        document.documentElement.style.fontSize = viewState.printScalingEnabled ? `${ PRINT_SCALE_TO_ONE_PAGE_ROOT_FONT_SIZE }px` : PRINT_NO_SCALE_ROOT_FONT_SIZE;
    } else {
        if (viewState.printScalingEnabled) {
            const scaledFont = PRINT_SCALE_TO_ONE_PAGE_ROOT_FONT_SIZE * viewState.printScale;
            document.documentElement.style.fontSize = viewState.printScale !== 1 ? `${ scaledFont }px` : `${ PRINT_SCALE_TO_ONE_PAGE_ROOT_FONT_SIZE }px`;
        } else if (BrowserUtils.isIE()) {
            document.documentElement.style.fontSize = PRINT_IE_DEFAULT_FONT_SIZE;
        }
    }
}

const setShouldInstrumentPrint = mutatorAction("setShouldInstrumentPrint", (shouldInstrument: boolean = true) => {
    schedulesViewStateStore().shouldInstrumentPrint = shouldInstrument;
});

function instrumentPrint(shortcut: boolean = false) {
    let {
        printSize,
        printOrientation,
        printEntryPoint,
        printScalingEnabled,
        printTeamSize,
        scheduleCalendarType,
        scheduleOverViewType,
        printInvokeTime,
        shouldInstrumentPrint
    } = schedulesViewStateStore();

    // onBeforePrint gets fired multiple times in Chrome (https://bugs.chromium.org/p/chromium/issues/detail?id=105743#c14)
    if (!shouldInstrumentPrint) {
        return;
    }

    setShouldInstrumentPrint(false);

    const entryPoint = shortcut ? PrintEntryPoint.Other : printEntryPoint;
    const pageSize = printScalingEnabled ? InstrumentationService.values[printSize] || InstrumentationService.values.Unknown : InstrumentationService.values.Unknown;
    const pageFormat = printScalingEnabled ? InstrumentationService.values[printOrientation] || InstrumentationService.values.Unknown : InstrumentationService.values.Unknown;
    const currentView = InstrumentationUtils.getCurrentViewForInstrumentation(scheduleCalendarType);
    // We can't measure Ctrl + P time taken since onBeforePrint is the 1st place it hits.
    const timeTaken = shortcut ? 0 : moment().diff(printInvokeTime, 'seconds');

    let properties = [
        getGenericEventPropertiesObject(InstrumentationService.properties.EventType, InstrumentationService.values.PrintGenerated),
        getGenericEventPropertiesObject(InstrumentationService.properties.EntryPoint, entryPoint),
        getGenericEventPropertiesObject(InstrumentationService.properties.ScaleToOnePage, printScalingEnabled),
        getGenericEventPropertiesObject(InstrumentationService.properties.ScaleToOnePageSize, pageSize),
        getGenericEventPropertiesObject(InstrumentationService.properties.ScaleToOnePageStyle, pageFormat),
        getGenericEventPropertiesObject(InstrumentationService.properties.PrintTeamSize, printTeamSize),
        getGenericEventPropertiesObject(InstrumentationService.properties.CurrentView, currentView),
        getGenericEventPropertiesObject(InstrumentationService.properties.PeopleOrShiftView, scheduleOverViewType),
        getGenericEventPropertiesObject(InstrumentationService.properties.TimeTaken, timeTaken)
    ];

    InstrumentationService.logEvent(InstrumentationService.events.Print, properties);
}

function resetRootFontSize() {
    if (schedulesViewStateStore().printScalingEnabled) {
        document.documentElement.style.fontSize = `${ PRINT_SCALE_TO_ONE_PAGE_ROOT_FONT_SIZE }px`;
    } else {
        document.documentElement.style.fontSize = `${ PRINT_NO_SCALE_ROOT_FONT_SIZE }px`;
    }
}

const resetPrintScale = mutatorAction("resetPrintScale", () => {
    schedulesViewStateStore().printScale = 1;
});

const resetPrintScalingPasses = mutatorAction("resetPrintScalingPasses", () => {
    schedulesViewStateStore().printScalingPasses = 0;
    schedulesViewStateStore().isPrintScaleComputed = false;
});

const setShouldRenderPrintDOM = mutatorAction("setShouldRenderPrintDOM", (render: boolean = true) => {
    schedulesViewStateStore().shouldRenderPrintDOM = render;
});

const resetPrintButtonPressed = mutatorAction("resetPrintButtonPressed", () => {
    schedulesViewStateStore().printButtonPressed = false;
});

const schedulesPrintListener: PrintListener = {
    onBeforePrint: () => {
        const viewState = schedulesViewStateStore();

        if (!viewState.printButtonPressed) {
            // Came from Ctrl + P
            setShouldInstrumentPrint();
            setDocumentFontForPrint();
        }

        instrumentPrint(!viewState.printButtonPressed);

        if (viewState.printScalingEnabled) {
            setShouldRenderPrintDOM();
        }
    },

    onAfterPrint: () => {
        setShouldRenderPrintDOM(false);
        resetPrintScalingPasses();
        resetPrintScale();
        resetRootFontSize();
        resetPrintButtonPressed();
    }
};

export default schedulesPrintListener;