import { orchestrator  } from "satcheljs";
import { TeamDataService } from "sh-services";
import { TeamStore } from "sh-team-store";
import { updateIsDataInDateRangeLoaded,
         getScheduleDataInDateRange } from "../index";
/**
 * Gets the schedule data (shifts and notes) for the current viewing range
 */
export const getScheduleDataInDateRangeOrchestrator = orchestrator(getScheduleDataInDateRange, async actionMessage => {
        // don't reload the data if we already have it loaded until and unless explicitly isReload passed as true
        const shouldDataRangeLoad = !actionMessage.viewState.isDataInDateRangeLoaded || actionMessage.forceLoad;
        if ( shouldDataRangeLoad && TeamStore() && TeamStore().team) {
            await TeamDataService.getDataInDateRange(TeamStore().teamId, actionMessage.viewState.viewStartDate, actionMessage.viewState.viewEndDate, actionMessage.forceLoad);
            updateIsDataInDateRangeLoaded(actionMessage.viewState, true);
        }
    }
);