import { mutatorAction } from "satcheljs";
import { appViewState } from "sh-application/store";
import TeamSettingUtils from "sh-application/utility/TeamSettingUtils";

export default mutatorAction('SET_GLOBAL_SPINNER_MESSAGE_STATE',
    function setGlobalSpinnerMessageState(message: string) {
        // The GlobalSpinner component is currently restricted only to work when workforce integration is enabled.
        // This is because some operations (like delete shift) take several seconds to complete and we need to block out
        // the UI, while also showing the user a message about what is happening
        if (TeamSettingUtils.isWorkforceIntegrationEnabled()) {
            appViewState().globalSpinnerMessage = message;
        }
    }
);
