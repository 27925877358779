import ITagDataService from "./ITagDataService";
import RestClient from "sh-rest-client";
import TagUtils from "sh-application/utility/TagUtils";
import { DataService } from "./DataService";
import { ITagEntity, TagEntity } from "sh-models";
import { setIsFocusedTag, TagStore } from "sh-tag-store";
import { TagDataProvider } from "sh-services/dataproviders/TagDataProvider";
import { TagsDataProvider } from "sh-services/dataproviders/TagsDataProvider";
import { TestDataIDConstant } from "sh-application/../StaffHubConstants";

/**
 * Functions for retrieving and editing information related to instances of IBaseShiftEntity.
 * Over time, we should move all of the legacy actions that are currently unders sh-shift-store to here.
 */
class TagDataService extends DataService implements ITagDataService {

    /**
     * Get Tags from the team
     */
    public async getTags(teamId: string): Promise<ITagEntity[]> {
        const tagsDataProvider = new TagsDataProvider(this.teamDatabase, this.tenantId, teamId);
        return await this.getData(tagsDataProvider);
    }

    /**
     * Deletes a tag. Does not remove the tag from the cache. Rather, updates the state to deleted.
     * This is because we still sometimes render deleted tags.
     * @param tag - tag object to delete
     */
    public async deleteTag(tag: ITagEntity): Promise<ITagEntity> {
        const deletedTag = await RestClient.deleteTag(tag.tenantId, tag.teamId, tag);
        await this.saveTag(deletedTag, /* isNewTag */ false, /* optimistic */ false, /* setIsFocused */ false, /* updateCacheOnly */ true);
        return deletedTag;
    }

    /**
     * Saves a tag in service. Either adds or updates the tag based on the isNewTag parameter. Updates local cache
     * after the service call completes and potentially stores this tag as the focused tag if setIsFocused is true.
     * @param tag - tag object to svae
     * @param isNewTag - true if the tag is new
     * @param optimistic - true if the tag should be immediately updated in the cache
     * @param setIsFocused - true if the tag should be set as focused (used in UI)
     * @param updateCacheOnly - Whether to skip making the network request (primary for updates through sync)
     */
    public async saveTag(tag: ITagEntity, isNewTag: boolean, optimistic: boolean = false, setIsFocused: boolean = false, updateCacheOnly: boolean = false, throwError?: boolean): Promise<ITagEntity> {
        const tagDataProvider = new TagDataProvider(this.teamDatabase, this.tenantId, tag.teamId, tag.id);
        let savePromise = isNewTag ? RestClient.addTag : RestClient.updateTag;
        let savedTag: ITagEntity = null;
        let originalTagCopy: ITagEntity = null;

        try {
            // Perform optimistic update if the optimistic flag is set, and the original tag is currently
            // available in the cache so we can save this original state in case we need to undo the update.
            if (optimistic) {
                const originalTag: ITagEntity = await tagDataProvider.getDataFromMemory();
                if (originalTag) {
                    originalTagCopy = TagEntity.clone(originalTag);
                    await tagDataProvider.setDataInMemory(tag);
                }
            }

            if (!updateCacheOnly) {
                savedTag = await savePromise(tag.tenantId, tag.teamId, tag);
            } else {
                savedTag = tag;
            }

            // Set the focus tag id in the store before updateDataInCache triggers render and the focus is lost to the add group button.
            if (setIsFocused) {
                setIsFocusedTag(savedTag.id);
            }

            await this.updateDataInCache(savedTag, tagDataProvider);

        } catch (error) {
            if (optimistic && originalTagCopy) {
                // Restore the original tag state in case of a failure
                await tagDataProvider.setDataInMemory(originalTagCopy);
            }

            if (throwError) {
                throw error;
            }
        }

        return savedTag;
    }

    /**
     * FOR TESTING PURPOSES ONLY
     * Deletes all tags found in the store that have ids containing the test data string constant.
     */
    public async deleteTestTags(): Promise<ITagEntity[]> {
        let tagsToDelete: ITagEntity[] = [];
        TagStore().tags.forEach((tag: ITagEntity) => {
            if (tag.id.startsWith(`TAG_${TestDataIDConstant}`) && TagUtils.isActiveTag(tag)) {
                tagsToDelete.push(tag);
            }
        });

        let tagDeletionPromises: Promise<ITagEntity>[] = [];
        for (let i = 0; i < tagsToDelete.length; i++) {
            tagDeletionPromises.push(this.deleteTag(tagsToDelete[i]));
        }
        return Promise.all(tagDeletionPromises);
    }

    /**
     * Resets the sync state
     */
    public async resetSyncState() {
    }
}

const service: ITagDataService = new TagDataService();
export default service;