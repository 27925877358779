import { default as FilterableCommandBarItem } from "sh-application/components/commandbar/FilterableCommandBarItem";

export enum GlobalCommandBarTab {
    Schedules,
    Settings,
    Requests,
    OtherOpenShifts
}

export interface GlobalCommandBarViewState {
    rightCommandBarItems: FilterableCommandBarItem[];
    selectedTab: GlobalCommandBarTab;
}
