import restartImport from "../actions/restartImport";
import showImportScheduleDialog from "sh-application/mutators/showImportScheduleDialog";
import { orchestrator } from "satcheljs";
import { setTryImportingAgainButton } from "../index";
/**
 * Restart importing schedule
 */
export default orchestrator(restartImport, async actionMessage => {
    showImportScheduleDialog(true);
    setTryImportingAgainButton(true);
});
