import RestClient from "sh-rest-client";
import { DataProvider } from "sh-services/dataproviders/DataProvider";
import { ECSConfigKey, ECSConfigService, IUserDataService } from "..";
import { IDataWithSyncState, ITeamInfoEntity, TeamManagedByTypes } from "sh-models";
import { ITeamDatabase } from "sh-services/data/ITeamDatabase";
import { MobxUtils } from "sh-application";
import { TeamInfoStore, teamsUpdated } from "sh-stores";

/**
 * Teams List Data Provider
 */
export class TeamsDataProvider extends DataProvider<IDataWithSyncState<ITeamInfoEntity[]>> {

    protected teamDatabase: ITeamDatabase;
    protected userDataService: IUserDataService;

    constructor(teamDatabase: ITeamDatabase, userDataService: IUserDataService) {
        super();
        this.teamDatabase = teamDatabase;
        this.userDataService = userDataService;
    }

    /**
     * Return data if it's found in memory (otherwise return undefined)
     */
    async getDataFromMemory() {
        if (TeamInfoStore().teams && TeamInfoStore().teams.size) {
            // Use the data from the memory store only if we have some teams cached
            // otherwise fallback to database/network
            return {
                data: MobxUtils.MapToArray(TeamInfoStore().teams),
                isDataInSync: true,
                isPartialInSync: false
            };
        } else {
            return undefined;
        }
    }

    /**
     * Return data if it's found in the database (otherwise return undefined)
     */
    async getDataFromDatabase() {
        const teams = await this.teamDatabase.getTeams();
        if (teams && teams.length) {
            // Use the database value only if there are some teams cached
            // otherwise refresh it from the network
            return {
                data: teams,
                isDataInSync: await this.userDataService.getIsTeamsListCached(),
                isPartialInSync: false
            };
        } else {
            return undefined;
        }
    }

    /**
     * Make an HTTP request to fetch the data from the network
     */
    async getDataFromNetwork() {
        // if Staffhub teams are enabled, we don't use a filter and get all Teams, otherwise we use the TeamManagedByTypes.Teams filter
        const managedByFilter = ECSConfigService.isECSFeatureEnabled(ECSConfigKey.EnableShowStaffhubTeams) ? undefined /* no filter */ : TeamManagedByTypes.Teams;
        // Data from the network is always assumed to be in sync
        return {
            data: await RestClient.getTeams(managedByFilter),
            isDataInSync: true,
            isPartialInSync: false
        };
    }

    /**
     * Set data in memory
     */
    async setDataInMemory(data: IDataWithSyncState<ITeamInfoEntity[]>) {
        // reset existing teams so we remove any deleted teams
        if (data && data.data) {
            teamsUpdated(data.data, /* resetExistingTeams*/ true);
        }
    }

    /**
     * Set data in the database
     */
    async setDataInDatabase(data: IDataWithSyncState<ITeamInfoEntity[]>) {
        if (data && data.data) {
            await this.teamDatabase.setTeams(data.data);
            if (data.isDataInSync) {
                await this.userDataService.setIsTeamsListCached(true);
            }
        }
    }

    /**
     * Make a network call to update the data
     */
    async saveDataToNetwork(data: IDataWithSyncState<ITeamInfoEntity[]>): Promise<IDataWithSyncState<ITeamInfoEntity[]>> {
        throw new Error("TeamsDataProvider.saveDataToNetwork not implemented");
    }

    /**
     * Whether to skip refreshing data from network if it was only in the database and not in memory
     * This method gets called when we don't find the data in memory, and find something in the database.
     * Because we can't always trust that the data from the database was in-sync and not missing changes from the service,
     * this method allows the provider to inspect the state of data from the db and
     * determine if we need to make a network call to refresh this data asynchronously.
     */
    public async skipRefreshFromNetworkIfNotInMemory(dataFromDatabase: IDataWithSyncState<ITeamInfoEntity[]>): Promise<boolean> {
        if (!ECSConfigService.isECSFeatureEnabled(ECSConfigKey.EnableSyncStateInIndexedDb)) {
            // force refresh from network if the sync state isn't allowed to be stored in IndexedDb
            return false;
        }
        // check if the data is in sync
        return dataFromDatabase && dataFromDatabase.isDataInSync;
    }
}