import { PeopleCountProps } from "../../common/peopleCount/PeopleCountProps";

/**
 * Gets the 'PeopleCount' component properties if applicable, else undefined.
 * @param count The people count for the associated date.
 * @returns The 'PeopleCount' component properties if applicable, else undefined.
 */
export const getPeopleCountProps = (count?: number): PeopleCountProps | undefined => {
    if (count != undefined) {
        return { count };
    }
};
