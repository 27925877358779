import * as moment from "moment";
import IGeneratedAlertEntity from "./IGeneratedAlertEntity";
import { Moment } from "moment";

/**
 * Class that sets up common aspects for Generated Alerts
 * Generated Alerts are generated when kronos rules are violated
 */
export default class GeneratedAlertEntity implements IGeneratedAlertEntity {
    date: Moment;
    message: string;
    alertLevel: string;
    memberDisplayName: string;

    constructor(
        date: Moment,  /** Date of violation */
        message: string, /** Description of violation */
        alertLevel: string, /** Alert Level of violation */
        memberDisplayName: string  /** Member related to the violation */) {

        this.date = moment(date);
        this.message = message;
        this.alertLevel = alertLevel;
        this.memberDisplayName = memberDisplayName;
    }

    /**
     * Function that clones a GeneratedAlertEntity object
     * @param generatedAlert - violation to clone
     */
    static clone(generatedAlert: IGeneratedAlertEntity): IGeneratedAlertEntity {
        return GeneratedAlertEntity.fromJson(generatedAlert);
    }

    /**
     * Function that converts json from service response to GeneratedAlertEntity
     * @param jsonData - response from service
     */
    static fromJson(jsonData: IGeneratedAlertEntity): IGeneratedAlertEntity {
        if (!jsonData) {
            return null;
        }

        return new GeneratedAlertEntity(
            jsonData.date,
            jsonData.message,
            jsonData.alertLevel,
            jsonData.memberDisplayName);
    }

    /**
     * Function that creates service JSON from GeneratedAlertEntity
     * @param generatedAlert
     */
    static toJson(generatedAlert: IGeneratedAlertEntity): IGeneratedAlertEntity {
        if (!generatedAlert) {
            return null;
        }
        const shiftJson: IGeneratedAlertEntity = {
            date: generatedAlert.date,
            message: generatedAlert.message,
            alertLevel: generatedAlert.alertLevel,
            memberDisplayName: generatedAlert.memberDisplayName
        };

        return shiftJson;
    }

}