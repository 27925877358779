import * as React from "react";
import MemberUtils from "sh-application/utility/MemberUtils";
import removeTagMember from "./utils/removeTagMember";
import StringsStore from "sh-strings/store";
import { AriaProperties, AriaRoles, generateDomPropertiesForAria } from "owa-accessibility";
import { ContextualMenu, DirectionalHint, IContextualMenuItem } from "@fluentui/react";
import { IMemberEntity } from "sh-models";
import { InstrumentationService } from "sh-services";
import { getGenericEventPropertiesObject } from "sh-instrumentation";
import { InstrumentScheduleEventFunction } from "sh-application/components/schedules/Schedules";
import { observer } from "mobx-react";

const styles = require("../shift/ShiftContextualMenu.scss");

/**
 * Type for observable props that needs to be passed down from the parent
 */
export interface ScheduleMemberContextualMenuObservableProps {
    /** Boolean, that determines if the context menu is visible or not */
    isVisible: boolean;

    /** The target element to position the contextual menu */
    targetElement: HTMLElement;
}

interface ScheduleMemberContextualMenuProps {
    observableProps: ScheduleMemberContextualMenuObservableProps;               // Observable props that gets passed down from the parent
    member: IMemberEntity;                                                      // Member for which the contextual menu is shown
    onDismissCallback: Function;                                                // Call back function when the menu is dismissed
    onCancelCallback: Function;                                                 // Call back function when the menu is dismissed
    hasActiveShifts: boolean;                                                   // Boolean, that states whether the member has active shifts for the current view
    tagName: string;                                                            // Name of the tag/group to which the member belongs to
    tagId: string;                                                              // Id of the tag/group to which the member belongs to
    instrumentScheduleEvent: InstrumentScheduleEventFunction;                   // Helper function to instrument scheduling events
    onMoveMemberClicked: (tagId: string, member: IMemberEntity) => void;        // Callback for move member click
    isViewGrouped: boolean;                                                     // Is the schedule in grouped view (true) or ungrouped view (false)
    isOtherGroup: boolean;
    isMoveMemberEnabled: boolean;                                               // true, if the current member is part of the other group
}

@observer
export default class ScheduleMemberContextualMenu extends React.Component<ScheduleMemberContextualMenuProps, {}> {
    private _strings: Map<string, string>;

    constructor(props: ScheduleMemberContextualMenuProps) {
        super(props);

        this._strings = StringsStore().registeredStringModules.get("schedulePage").strings;
    }

    private onMoveMemberClicked = (e: React.MouseEvent<HTMLElement>) => {
        const { member } = this.props;

        if (this.props.instrumentScheduleEvent) {
            this.props.instrumentScheduleEvent(InstrumentationService.events.ReorderGroupedMemberClicked);
        }

        this.props.onMoveMemberClicked(this.props.tagId, member);
    }

    private onRemoveMemberClicked = (e: React.MouseEvent<HTMLElement>) => {
        const { member, tagId, tagName, hasActiveShifts } = this.props;

        if (this.props.instrumentScheduleEvent) {
            this.props.instrumentScheduleEvent(InstrumentationService.events.MemberDeleteClicked, [
                getGenericEventPropertiesObject(InstrumentationService.properties.EntryPoint, InstrumentationService.entryPoints.Schedule),
                getGenericEventPropertiesObject(InstrumentationService.properties.UserState, member.state)
            ]);
        }

        removeTagMember(member, tagName, tagId, hasActiveShifts, this.onCancelRemoveMember);
    }

    onCancelRemoveMember = () => {
        if (this.props.onCancelCallback) {
            this.props.onCancelCallback();
        }
    }

    onDismissCallback = () => {
        if (this.props.onDismissCallback) {
            this.props.onDismissCallback();
        }
    }

    private getMenuItems(): IContextualMenuItem[] {
        const { member, tagName, isViewGrouped, isOtherGroup, isMoveMemberEnabled } = this.props;
        let items: IContextualMenuItem[] = [];

        if (isMoveMemberEnabled) {
            items.push({
                key: "moveTeamMember",
                name: this._strings.get("moveTeamMember"),
                title: this._strings.get("moveTeamMemberDesc"),
                iconProps: { iconName: "teams-drag-drop-offset" },
                onClick: this.onMoveMemberClicked,
                disabled: isOtherGroup,
                role: AriaRoles.menuitem
            });
        }

        if (isViewGrouped) {
            items.push({
                key: "removeFromGroup",
                name: this._strings.get("removeFromGroup"),
                title: this._strings.get("removeFromGroupDesc").format(MemberUtils.getDisplayNameForMember(member), tagName),
                iconProps: { iconName: "teams-close" },
                onClick: this.onRemoveMemberClicked,
                disabled: isOtherGroup,
                role: AriaRoles.menuitem
            });
        }

        return items;
    }

    /**
     * Renders the menu
     */
    private renderContextualMenu(): JSX.Element {
        let memberContextMenuAriaProps: AriaProperties = {
            role: AriaRoles.menu
        };

        return (
            <ContextualMenu
                { ...generateDomPropertiesForAria(memberContextMenuAriaProps) }
                shouldFocusOnMount={ true }
                onDismiss={ this.onDismissCallback }
                className={ styles.contextualMenu }
                styles={ { list: styles.contextualMenuList } }
                target={ this.props.observableProps.targetElement }
                gapSpace={ -11 } // This is the space between the target element and the menu. It's negative since it needs to overlap the target element.
                directionalHint={ DirectionalHint.rightTopEdge }
                items={ this.getMenuItems() } />
        );
    }

    render() {
        return (
            this.props.observableProps.isVisible &&
                this.renderContextualMenu()
        );
    }
}