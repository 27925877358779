import StringsStore from "sh-strings/store";
import { mutatorAction } from "satcheljs";
import { SchedulesViewStateStore } from "sh-application/components/schedules/lib/store/schema/SchedulesViewStateStore";
import { ShiftFilterTypes } from "sh-models";

/**
 * Toggle the shift conflicts view of the Schedules page
 */
export default mutatorAction("TOGGLE_SHIFT_CONFLICTS_VIEW",
    function toggleShiftConflictsView(viewState: SchedulesViewStateStore) {
        viewState.showShiftConflicts = !viewState.showShiftConflicts;
        if (!viewState.showShiftConflicts) {
            // if shift filter is selected and we toggle off show conflicts remove the filter
            if (viewState.selectedShiftFilters.has(ShiftFilterTypes.ConflictingShifts)) {
                viewState.selectedShiftFilters.delete(ShiftFilterTypes.ConflictingShifts);
                const schedulePageStrings = StringsStore().registeredStringModules.get("schedulePage").strings;
                const filterCount = viewState.filteredTags.size + viewState.filteredMembers.size + viewState.selectedShiftFilters.size;
                const filterCountString = schedulePageStrings.get("filterCommandBarCount").format(filterCount.toString());
                const filterLabelString = filterCount > 0 ? schedulePageStrings.get("filterCommandBarLabel").format(filterCountString) : schedulePageStrings.get("filterCommandBarLabelWithoutCount");
                viewState.filterHeaderLabel = filterLabelString;
            }
        }
    }
);