/* exports */
export { default as AppSettings } from "./AppSettings";
export { initializeApplication, preinitApplication } from "./initialize";
export { default as UrlFactory } from "./utility/UrlFactory";
export { createNewHttpError, mapHttpError, ShiftsWebErrorCodes, StaffHubError, StaffHubHttpError, Detail } from "./utility/StaffHubError";
export { getApiFromUrl } from "./utility/getApiFromUrl";
export { removeUrlTrailingSlash } from "../sh-application/utility/removeUrlTrailingSlash";
export { removeParamFromQueryString } from "../sh-application/utility/removeParamFromQueryString";
export { default as ShiftRequestUtils } from "./utility/ShiftRequestUtils";

export { default as MobxUtils } from "./utility/MobxUtils";

/* actions */
export { default as getDownloadSampleFile } from "./components/importSchedule/lib/actions/getDownloadSampleFile";
export { default as triggerShiftMove } from "./actions/triggerShiftMove";

export { default as showWelcome } from "./actions/showWelcome";

export { default as restartApp } from "./actions/restartApp";

export { default as setGlobalMessageFromException } from "./actions/setGlobalMessageFromException";
export { default as setGlobalMessages } from "./actions/setGlobalMessages";

export { default as setDeepLinkState } from "./mutatorActions/setDeepLinkState";

/* mutators */
export { default as setLoginState } from "./mutators/setLoginState";

export { default as showShareWithdrawDialog } from "./mutators/showShareWithdrawDialog";

export { default as showImportScheduleDialog } from "./mutators/showImportScheduleDialog";

/* Register and export orchestrators */
export { default as processShiftMove } from "./orchestrators/processShiftMove";
/* Register mutatorActions and orchestrators */

// Note:  These imports need to follow the imports above (such as AppSettings)
import "./mutatorActions/setShowWelcomeState";
import "./mutatorActions/setGlobalSpinnerMessageState";
import "./mutatorActions/resetActiveTour";
import "./orchestrators/onRestartApp";
import "./orchestrators/onSetGlobalMessageFromException";
import "./orchestrators/showWelcome";