import { action } from "satcheljs";
import { IOpenShiftEntity } from "sh-models";
const requestOpenShift = action(
    "REQUEST_OPEN_SHIFT",
    (
        openShift: IOpenShiftEntity, onFinish: VoidFunction
    ) => ({
        openShift: openShift, onFinish: onFinish
    })
);

export default requestOpenShift;