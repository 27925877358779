import { DataServices } from "sh-services";

class LoginService {

    /**
     * Reset the login session
     * Note: This does not log the user out, just resets the login session with the StaffHub service
     */
    public async resetCurrentUserLoginSession(): Promise<void> {

        // remove the device header from cache as we'll get a new one for the correct region from service in the login response
        await DataServices.UserDataService.clearDeviceHeader();
    }

    /**
     * Note: We dont have signout anymore. Leaving the code commented out, in case we want to reuse this in future for cleanup when user leaves the app
     * Send logout request to service and clear local session
     * @param {} entryPoint - how sign out is called (ex. sign out link, error dialog, silent authentication failure)
     * @returns {}
     */
    // public async signOut(entryPoint: string) {
    //     InstrumentationService.logEvent(InstrumentationService.events.Logout, [getGenericEventPropertiesObject(InstrumentationService.properties.EntryPoint, entryPoint)]);

    //     SyncService.stop();

    //     // do this after the logout api call otherwise cookies won't exist to make the logout api call
    //     await DataServices.setUserLoggedOut();

    //     // clear user context in instrumentation data for subsequent events
    //     InstrumentationService.clearUserContext();
    //     // end logging session in Aria logging. Subsequent login will create new session
    //     this._endAriaSession();
    //     // remove the event listener that would end the Aria session
    //     window.removeEventListener("beforeunload", this._endAriaSession);

    //     // clear everything from session storage
    //     sessionStorage.clear();
    // }
}

const service = new LoginService();
export default service as LoginService;