import * as moment from "moment";
import IConflictDismissDbEntity from "./IConflictDismissDbEntity";
import ShiftrObjectModelBase from "../ShiftrObjectModelBase";
import {
    DismissState,
    DismissType,
    IConflictDismissEntity,
    IDismissEntity
    } from "..";
import { ModelUtils } from "..";
import { Moment } from "moment";

const ConflictDismissIdPrefix = "CDIS_";

export class DismissEntity implements IDismissEntity {
    id: string;
    type: DismissType;

    constructor(id: string, type: DismissType) {
        this.id = id;
        this.type = type;
    }

    static fromJson(data: IDismissEntity) {
        return new DismissEntity(
            data.id,
            data.type
        ); }
}

export default class ConflictDismissEntity extends ShiftrObjectModelBase implements IConflictDismissEntity {
    /* tenant id associated with conflict dismissal entry */
    tenantId?: string;
    /* team id asssociated with conflict dismissal entry  */
    teamId?: string;
    /* user id associated with conflict dismissal entry  */
    userId?: string;
    /* member id associated with conflict dismissal entry  */
    memberId?: string;
    /* first entity of the conflict item to be dismissed */
    entityOne?: IDismissEntity;
    /* second entity of the conflict item to be dismissed */
    entityTwo?: IDismissEntity;
    /* state of the dismissed conflict entity */
    state?: DismissState;
    /* min start time of the conflicting entities */
    startTime?: Moment;
    /* max end time of the conflicting entities */
    endTime?: Moment;

    constructor(
        id: string,
        eTag?: string,
        tenantId?: string,
        teamId?: string,
        userId?: string,
        memberId?: string,
        entityOne?: IDismissEntity,
        entityTwo?: IDismissEntity,
        state?: DismissState,
        startTime?: Moment,
        endTime?: Moment) {

        const conflictDismissId = id || ConflictDismissEntity.generateNewConflictDismissId();
        super(conflictDismissId, eTag);
        this.teamId = teamId;
        this.tenantId = tenantId;
        this.memberId = memberId;
        this.userId = userId;
        this.entityOne = entityOne ? DismissEntity.fromJson(entityOne) : null;
        this.entityTwo = entityTwo ? DismissEntity.fromJson(entityTwo) : null;
        this.state = state;
        this.startTime = startTime;
        this.endTime = endTime;
    }

    /**
     * Function that creates a new empty conflict dismiss entity object
     * @param tenantId - tenant id for the conflict dismiss entity
     * @param teamId - team id for the conflict dismiss entity
     * @param memberId - member id for the conflict dismiss entity
     * @param userId - user id for the conflict dismiss entity
     * @param entityOne - first entity of the conflict item to be dismissed
     * @param entityTwo - second entity of the conflict item to be dismissed
     */
    static createEmptyObject(tenantId?: string, teamId?: string, memberId?: string, userId?: string, entityOne?: IDismissEntity, entityTwo?: IDismissEntity, state?: DismissState, startTime?: Moment, endTime?: Moment): ConflictDismissEntity {
        return ConflictDismissEntity.fromJson({
            id: null,
            eTag: null,
            tenantId: tenantId,
            teamId: teamId,
            userId: userId,
            memberId: memberId,
            entityOne: entityOne,
            entityTwo: entityTwo,
            state: state,
            startTime: startTime,
            endTime: endTime
        });
    }

    static fromJson(jsonData: IConflictDismissEntity | IConflictDismissDbEntity): IConflictDismissEntity {
        if (!jsonData) {
            return null;
        }

        return new ConflictDismissEntity(
            jsonData.id,
            jsonData.eTag,
            jsonData.tenantId,
            jsonData.teamId,
            jsonData.userId,
            jsonData.memberId,
            jsonData.entityOne ? DismissEntity.fromJson(jsonData.entityOne) : null,
            jsonData.entityTwo ? DismissEntity.fromJson(jsonData.entityTwo) : null,
            jsonData.state,
            jsonData.startTime && moment(jsonData.startTime),
            jsonData.endTime && moment(jsonData.endTime)
        );
    }

    /**
     * Function that creates IConflictDismissDbEntity from IConflictDismissEntity
     * @param conflictDismissal - IConflictDismissEntity client entity
     */
    static toDbModel(conflictDismissal: IConflictDismissEntity): IConflictDismissDbEntity {
        if (!conflictDismissal) {
            return null;
        }

        const conflictDismissalDbEntity: IConflictDismissDbEntity = {
            id: conflictDismissal.id,
            eTag: conflictDismissal.eTag,
            tenantId: conflictDismissal.tenantId,
            teamId: conflictDismissal.teamId,
            userId: conflictDismissal.userId,
            memberId: conflictDismissal.memberId,
            entityOne: conflictDismissal.entityOne ? DismissEntity.fromJson(conflictDismissal.entityOne) : null,
            entityTwo: conflictDismissal.entityTwo ? DismissEntity.fromJson(conflictDismissal.entityTwo) : null,
            state: conflictDismissal.state,
            startTime: conflictDismissal.startTime && conflictDismissal.startTime.valueOf && conflictDismissal.startTime.valueOf(),
            endTime: conflictDismissal.endTime && conflictDismissal.endTime.valueOf && conflictDismissal.endTime.valueOf()
        };

        return conflictDismissalDbEntity;
    }

     /**
     * Generate new conflictDismissId used by StaffHub App
     * @returns {string} conflictDismissId in form of CD_<uuid>
     */
    public static generateNewConflictDismissId(): string {
        return ModelUtils.generateUUIDWithPrefix(ConflictDismissIdPrefix);
    }
}