import schedulesViewStateStore from "sh-application/components/schedules/lib/store/store";
import { getScheduleDataInDateRange } from "../index";
import { orchestrator  } from "satcheljs";
import { TeamDataService } from "sh-services";
import { TeamStore } from "sh-team-store";
import refreshSchedule from "../actions/refreshSchedule";

/**
 * Refresh schedule data: This will bring all relevant data which is needed for showing latest updated schedule.
 */
export const refreshScheduleOrchestrator = orchestrator(refreshSchedule,
    function refreshSchedule() {
        // Refresh team data
        TeamDataService.getTeam(TeamStore().teamId, true /** isReload */ );
        // get latest shift related data
        getScheduleDataInDateRange(schedulesViewStateStore(), true /** isReload */);
    }
);