class AppSettings {
    private _appSettings: { [s: string]: any; } = {};

    constructor() {
        if (window.sa && window.sa.appSettingsJson) {
            this._appSettings = JSON.parse(window.sa.appSettingsJson);
        }
    }

    public getSetting(key: string) {
        return this._appSettings[key];
    }
}

const settings = new AppSettings();
export default settings as AppSettings;