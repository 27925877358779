import teamSetupPickerStore from "../store/store";
import { mutatorAction } from "satcheljs";
import { trace } from "owa-trace";

/**
 * Updates the current step in the team setup flow to the next state.
 */

export default mutatorAction('GOTO_NEXT_SETUP_STEP', function gotoNextSetupStep() {
    if ((teamSetupPickerStore.currentSetupStepIndex >= 0) &&
        teamSetupPickerStore.setupSequence &&
        (teamSetupPickerStore.currentSetupStepIndex < teamSetupPickerStore.setupSequence.length - 1)) {
            teamSetupPickerStore.currentSetupStepIndex++;
    } else {
        trace.warn("Team setup: error with gotoNextSetupStep occurred");
    }
});
