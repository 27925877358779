import * as React from "react";
import AccessibilityUtils from "sh-application/utility/AccessibilityUtils";
import DateUtils from "sh-application/utility/DateUtils";
import KeyboardUtils from "sh-application/utility/KeyboardUtils";
import MemberUtils from "sh-application/utility/MemberUtils";
import ScheduleMemberContextualMenu, { ScheduleMemberContextualMenuObservableProps } from "../ScheduleMemberContextualMenu";
import schedulesViewStateStore from "sh-application/components/schedules/lib/store/store";
import StringsStore from "sh-strings/store";
import TeamSettingUtils from "sh-application/utility/TeamSettingUtils";
import { action } from "satcheljs/lib/legacy";
import { appViewState } from "../../../store";
import { AriaProperties, AriaRoles, generateDomPropertiesForAria } from "owa-accessibility";
import { getGenericEventPropertiesObject } from "sh-instrumentation";
import { IMemberEntity } from "sh-models";
import { InstrumentationService } from "sh-services";
import { InstrumentScheduleEventFunction } from "sh-application/components/schedules/Schedules";
import { IUserPersonaProps } from "../../common/IUserPersonaProps";
import { UserPersona } from "../../common/UserPersona";
import { observable } from "mobx";
import { observer } from "mobx-react";
import { Icon, PersonaSize } from "@fluentui/react";
import { ScheduleGridCssClasses, setReorderMemberProps, setRowKeyForMemberInReorderMode } from "sh-application/components/schedules/lib";
import { SortableHandle } from "react-sortable-hoc";

const classNames = require("classnames/bind");
const styles = require("./ScheduleMemberCell.scss");

interface ScheduleMemberCellProps {
    member: IMemberEntity;                                               // Member to be displayed
    hours: number;                                                           // Number of hours for which the user is scheduled
    hasActiveShifts: boolean;                                                // Boolean, that states whether the member has active shifts for the current view
    tagName: string;                                                         // Name of the tag/group to which the member belongs to
    tagNameAria: string;                                                     // Aria name of the tag/group to which the member belongs to
    tagId: string;                                                           // Id of the tag/group to which the member belongs to
    memberRowKey: string;                                                    // row key for the member row
    editEnabled: boolean;                                                    // true, if in admin view
    isDraggable?: boolean;                                                   // true, if the row can be dragged
    instrumentScheduleEvent: InstrumentScheduleEventFunction;                // Helper function to instrument scheduling event
    isViewGrouped: boolean;                                                  // Is the schedule in grouped view (true) or ungrouped view (false)
    isMemberDeletedFromTeam: boolean;                                        // Is member deleted from the team
    isMemberRemovedFromTag: boolean;                                         // Is member removed from the tag
    isOtherGroup: boolean;                                                   // true, if the current member is part of the other group
    onMemberMoveInUngroupedView: (memberId: string, delta: -1 | 1) => boolean;  // Handler for keyboard based move
    isMoveMemberEnabled: boolean;                                            // true if admin and in Team Shifts employee View.
    truncateLastName?: boolean;                                              // true, in case of union support where last name should be truncated to just the initial
}

// TODO(showPictures): Remove custom div after passing React ref properly.
const DragHandle = SortableHandle((person: IUserPersonaProps) => <div className={styles.dragHandleContainer}><UserPersona className={ styles.dragHandle } { ...person }/></div>);

/**
 * MemberCell component
 * This renders the contents of a member cell with rounded left edge
 */
@observer
export default class ScheduleMemberCell extends React.Component<ScheduleMemberCellProps, {}> {
    private static _hoursShortFormatString: string;
    private static _deletedMemberPersonaString: string;
    private static _openMemberContextMenuButtonAriaLabel: string;

    private _iconContainerRef = React.createRef<HTMLDivElement>();

    // Indicates the visibility of the contextual menu
    @observable private contextMenuObservableProps: ScheduleMemberContextualMenuObservableProps = { isVisible: false, targetElement: null };

    // Indicates whether or not, the schedule member container has focus or not.
    @observable private isFocused: boolean = false;

    constructor(props: ScheduleMemberCellProps) {
        super(props);

        if (!ScheduleMemberCell._hoursShortFormatString) {
            ScheduleMemberCell._hoursShortFormatString = StringsStore().registeredStringModules.get("common").strings.get("hoursShortFormat");
            ScheduleMemberCell._deletedMemberPersonaString = StringsStore().registeredStringModules.get("schedulePage").strings.get("deletedMemberPersonaSecondaryText");
            ScheduleMemberCell._openMemberContextMenuButtonAriaLabel = StringsStore().registeredStringModules.get("schedulePage").strings.get("openMemberContextMenuButtonAriaLabel");
        }
    }

    /**
     * Function that renders a fluentui persona component, for a team member
     * @param {IMemberEntity} member - member for which persona object needs to be rendered
     * @return {JSX.Element} - rendered persona
     */
    private renderPersona(member: IMemberEntity, hours: number): JSX.Element {
        // if truncateLastName not set check if print is going on and set to true
        const isUnionSupportEnabled = TeamSettingUtils.isUnionSupportEnabledForTeam();
        let shouldTruncate = this.props.truncateLastName
            ? this.props.truncateLastName
            : isUnionSupportEnabled && appViewState().printViewState && appViewState().printViewState.isPrinting;

        let hasMemberDataBeenRedacted = MemberUtils.hasMemberDataBeenRedacted(member);
        let displayName = MemberUtils.getDisplayNameForMember(member, shouldTruncate);
        const { isDraggable, isOtherGroup , editEnabled} = this.props;

        const hoursString = ScheduleMemberCell._hoursShortFormatString.format(DateUtils.getHoursRoundedForDisplay(hours));

        let personaSecondaryText = hoursString;

        if (!hasMemberDataBeenRedacted && (this.isMemberDeletedOrRemoved())) {
            // add Deleted label
            personaSecondaryText = ScheduleMemberCell._deletedMemberPersonaString.format(hoursString);
        }

        const { showProfilePictures } = schedulesViewStateStore();

        const props: IUserPersonaProps = {
            size: PersonaSize.size32,
            text: displayName,
            hidePersonaDetails: false,
            showSecondaryText: true,
            secondaryText: personaSecondaryText,
            imageShouldFadeIn: true,
            initialsColor: MemberUtils.getCustomPersonaInitialColor(member),
            userId: member.userId,
            key: `${member.id}_persona`,
            hidePicture: !showProfilePictures
        };

        if (editEnabled && isDraggable && !isOtherGroup) {
            return <DragHandle {...props} ref={ null }/>;
        } else {
            return <UserPersona {...props} />;
        }
    }

    private updateIsContextMenuVisible = action("updateIsContextMenuVisible")((isContextMenuVisible: boolean) => {
        this.contextMenuObservableProps.isVisible = isContextMenuVisible;
    });

    private updateContextMenuTargetElement = action("updateContextMenuTargetElement")((targetElement: HTMLElement) => {
        this.contextMenuObservableProps.targetElement = targetElement;
    });

    private updateIsFocused = action("updateIsFocused")((isFocused: boolean) => {
        this.isFocused = isFocused;
    });

    /**
     * Activate the context menu
     */
    private showContextualMenu = () => {
        this.updateIsContextMenuVisible(true);

        if (this.props.instrumentScheduleEvent) {
            this.props.instrumentScheduleEvent(InstrumentationService.events.MemberContextMenuOpened, [
                getGenericEventPropertiesObject(InstrumentationService.properties.IsRightClick, false)
            ]);
        }
    }

    /**
     * Callback for when the context menu button is activated via keypress
     * @param key
     */
    private onMoreIconKeyPressed = (key: React.KeyboardEvent<HTMLElement>) => {
        if (KeyboardUtils.isActionKeyPressed(key)) {
            key.preventDefault();
            this.showContextualMenu();
        }
    }

    private hideContextualMenu = () => {
        this.updateIsContextMenuVisible(false);
        this.onFocusMoreIcon();
    }

    private onFocus = () => {
        // show the more icon on mouse enter
        this.updateIsFocused(true);
    }

    private onBlur = () => {
        // hide the more icon on mouse leave
        this.updateIsFocused(false);
    }

    private onFocusMoreIcon = () => {
        this.updateIsFocused(true);
        setTimeout(() => {
            // updateIsFocused will render the component which will set the this._iconContainerRef ref
            // we then use a setTimeout to make sure the render is completed before we try to set the focus to the more icon container
            this._iconContainerRef.current?.focus();
        }, 0);
    }

    private onMoveMemberClicked = () => {
        setReorderMemberProps(schedulesViewStateStore(), {
            member: this.props.member,
            tagId: this.props.tagId,
            instrumentScheduleEvent: this.props.instrumentScheduleEvent,
            onMemberMoveInUngroupedView: this.props.onMemberMoveInUngroupedView,
            onFinished: this.onFocusMoreIcon
        });
        setRowKeyForMemberInReorderMode(schedulesViewStateStore(), this.props.memberRowKey);
    }

    /**
     * Function that renders the member state (deleted) icon on right edge of the member cell
     * @param member
     */
    private renderIconForMemberState(member: IMemberEntity): JSX.Element {
        let icon: JSX.Element = null;

        if (this.isMemberDeletedOrRemoved()) {
            icon = <Icon iconName={ "Blocked" } />;
        }

        return icon && <div className={ styles.memberStateIcon }>{ icon }</div>;
    }

    private isMemberDeletedOrRemoved(): boolean {
        const { isMemberDeletedFromTeam, isMemberRemovedFromTag } = this.props;
        return isMemberDeletedFromTeam || isMemberRemovedFromTag;
    }

    private isInReorderMode() {
        return schedulesViewStateStore().rowKeyForMemberInReorderMode !== null;
    }

    /**
     * Returns the member cell ID given the member ID
     * @param memberId ID of the member
     */
    private getMemberCellId(memberId: string) {
        return `sh-mc-${memberId}`;
    }

    render() {
        const { member, tagNameAria, hours, hasActiveShifts, isMemberDeletedFromTeam, isMemberRemovedFromTag } = this.props;

        const memberContainerClasses = classNames(
            styles.memberCellContainer,
            {
                [styles.isFocused]: this.isFocused,
                [styles.deleted]: isMemberDeletedFromTeam,
                [styles.removed]: isMemberRemovedFromTag
            });

        const memberContainerProps = {
            className: memberContainerClasses,
            ref: (memberCell: HTMLElement) => this.updateContextMenuTargetElement(memberCell),
            onFocus: this.onFocus,
            onBlur: this.onBlur
        };

        // Setup a rowheader for each member row, where its label includes the member's name.
        // This will provide context for accessibility users when they navigate through shift cells within each member row.
        // Depending on the screen reader, the rowheader will be read for either each gridcell item or the first time a gridcell
        // item is selected within a row.
        const ariaProps: AriaProperties = {
            role: AriaRoles.rowheader,
            label: AccessibilityUtils.getMemberRowAriaLabel(member, tagNameAria, hours)
        };

        const memberLabel = ScheduleMemberCell._openMemberContextMenuButtonAriaLabel.format(MemberUtils.getDisplayNameForMember(member));
        return (
            <div {...memberContainerProps} data-is-focusable={ true }
                { ...generateDomPropertiesForAria(ariaProps) }
                data-automation-id={ this.getMemberCellId(member.id) }>
                <div className={ styles.printLine } />
                { this.renderPersona(member, hours) }
                {
                    this.props.editEnabled && !this.isInReorderMode() &&
                        <div
                            className={ classNames(ScheduleGridCssClasses.iconContainer, "no-print") }
                            onClick={ this.showContextualMenu }
                            onKeyDown={ this.onMoreIconKeyPressed }
                            data-is-focusable={ true }
                            ref={ this._iconContainerRef }
                            tabIndex={ 0 }
                            role={ "button" }
                            title={ memberLabel }
                            aria-label={ memberLabel }>
                            { this.renderIconForMemberState(member) }
                            <div className={ ScheduleGridCssClasses.moreIcon }>
                                <Icon className={ styles.moreIcon } iconName={ "teams-more-svg" } ></Icon>
                            </div>
                        </div>
                }
                {
                    this.props.editEnabled &&
                        <ScheduleMemberContextualMenu
                            observableProps={ this.contextMenuObservableProps }
                            tagId={ this.props.tagId }
                            tagName={ this.props.tagName }
                            hasActiveShifts={ hasActiveShifts }
                            isOtherGroup={ this.props.isOtherGroup }
                            member={ member }
                            onMoveMemberClicked={ this.onMoveMemberClicked }
                            onDismissCallback={ this.hideContextualMenu }
                            onCancelCallback={ this.onFocusMoreIcon }
                            instrumentScheduleEvent={ this.props.instrumentScheduleEvent }
                            isViewGrouped={ this.props.isViewGrouped }
                            isMoveMemberEnabled={ this.props.isMoveMemberEnabled }/>
                }
            </div>
        );
    }
}