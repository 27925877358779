import { action } from "satcheljs";
import { StaffHubHttpError } from "sh-application";

const approveDenyOpenShiftRequests = action(
    "APPROVE_DENY_OPENSHIFT_REQUEST",
    (
        isAccepting: boolean,
        onApproveDenyCallback?: (openShiftId: string, isAccepting: boolean) => void,
        onErrorCallback?: (exception: StaffHubHttpError) => void
    ) =>
    ({
        isAccepting: isAccepting,
        onApproveDenyCallback: onApproveDenyCallback,
        onErrorCallback: onErrorCallback
     })
);

export default approveDenyOpenShiftRequests;