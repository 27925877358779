import * as MemoizeUtils from "sh-application/utility/MemoizeUtils";
import * as moment from "moment";
import DateTimeFormatter, { DateTimeFormatType } from "sh-application/utility/DateTimeFormatter";
import StringsStore from "sh-strings/store";
import { DayOfWeek } from "@fluentui/react";
import { Moment } from "moment";

export interface TimeRange {
    startTime: Moment;
    endTime: Moment;
}

const ALL_DAY_END_DATE_THRESHOLD_MS = 1000;
const NUMBER_OF_MINUTES_IN_ONE_DAY = 1440;

export default class DateUtils {

    /**
     * Comparator for sorting objects with startTimes
     * @param firstItem
     * @param secondItem
     */
    public static startTimeComparator(firstItem: {startTime: Moment}, secondItem: {startTime: Moment}): number {
        return firstItem.startTime.diff(secondItem.startTime);
    }

    /**
     * Compute the last inclusive day for a date range.
     * @param {Moment} endDate The end date of a date range
     * @returns {Moment} Date representing the last day of the date range
     */
    public static getLastDayForEndDate(endDate: Moment) {
        // If the end date ends at midnight, then the last inclusive day is actually the previous day.
        let lastDayDate = endDate.clone().startOf("day");
        if (endDate.isSame(lastDayDate)) {
            lastDayDate.subtract(1, "day");
        }
        return lastDayDate;
    }

    /**
     * Get the start of the day.
     * @param {Moment} date the date from which you need to get the startOf day
     * @returns {Moment} Date representing the start of day
     */
    public static getStartOfDay(date: Moment): Moment {
        if (date) {
            return date.clone().startOf("day");
        }

        return null;
    }

    /**
     * Returns true if the specified datetime is at the start of the day (ie, midnight)
     */
    public static isStartOfDay(date: Moment): boolean {
        let isStartOfDay: boolean = false;
        if (date) {
            let startOfDay = date.clone().startOf("day");
            isStartOfDay = date.isSame(startOfDay);
        }
        return isStartOfDay;
    }

    /**
     * Returns true if the specified datetime is at the end of the day (not including midnight of the next day)
     */
    public static isEndOfDay(date: Moment): boolean {
        let isEndOfDay: boolean = false;
        if (date) {
            let endOfDayMaxMs = date.clone().endOf("day").valueOf();
            let endOfDayMinMs = endOfDayMaxMs - ALL_DAY_END_DATE_THRESHOLD_MS;
            let dateMs = date.valueOf();
            isEndOfDay = (endOfDayMinMs <= dateMs) && (dateMs <= endOfDayMaxMs);
        }
        return isEndOfDay;
    }

    /**
     * Returns true if the given time range is an All Day range (eg, 2/1 to 2/5), as opposed to a time range that
     * has specific start and end times (eg, 2/1 9am to 2/5 5pm).
     *
     * All Day events are stored in the service as events that begin at midnight and end slightly before the
     * midnight of a following day.
     *
     * @param {Moment} startDate start of range
     * @param {Moment} endDate end of range
     * @returns {boolean} true if we have an All Day range
     */
    public static isAllDayTimeRange(startDate: Moment, endDate: Moment) {
        let isAllDay = false;

        // Verify start occurs before the end
        if (startDate.isBefore(endDate)) {
            let allDayStart = startDate.clone().startOf("day");
            // For All Day events, the start time must be at the beginning of the day
            if (startDate.isSame(allDayStart)) {
                if (DateUtils.isStartOfDay(endDate)) {
                    // Check if the end time falls right on the begin/end boundary of its day (ie, midnight)
                    isAllDay = true;
                } else {
                    // Otherwise, check if the end time falls within a threshold of the day boundary
                    // Currently, All Day events begin and end at midnight, but older events may still end right before midnight, so
                    // we still need to handle that older data.
                    isAllDay = DateUtils.isEndOfDay(endDate);
                }
            }
        }
        return isAllDay;
    }

    /**
     * Determines if the specified time range spans a single day only
     * @param {Moment} startDate
     * @param {Moment} endDate
     * @returns {Boolean}
     */
    public static isTimeRangeForTheSameDay(startDate: Moment, endDate: Moment) {
        let isSameDay = startDate.isSame(endDate, "day");

        if (!isSameDay) {
            // If the end is midnight of the following day from the start, that still counts as the same day.
            let midnightAfterStartDay = DateUtils.startOfNextDay(startDate);
            isSameDay = endDate.isSame(midnightAfterStartDay);
        }

        return isSameDay;
    }

    /**
     * Generates an array of dates within the given date range (inclusive)
     */
    public static getDatesInRange(startDateTime: Moment, endDateTime: Moment): Moment[] {
        const numDays = DateUtils.getNumberOfDaysForTimeRangeInclusive(startDateTime, endDateTime);
        const datesInRange: Moment[] = DateUtils.getDatesForNumberOfDays(startDateTime, numDays);
        return datesInRange;
    }

    private static _datesInRangeCache: {[key: string]: Moment[]} = {};
    public static fastGetDatesInRange = MemoizeUtils.memoizeUtility(
        DateUtils.getDatesInRange,
        DateUtils._datesInRangeCache,
        <Moment>(args: Moment[]) => {
            return `${args[0].valueOf()}:${args[1].valueOf()}`;
        }
    );

    /**
     * Generates an array of dates for the specified number of days, starting with the specified start date
     */
    public static getDatesForNumberOfDays(startDateTime: Moment, numDays: number): Moment[] {
        const startDate = startDateTime.clone().startOf('day');
        let currDate = startDate;
        let datesInRange: Moment[] = [];
        for (let i = 0; i < numDays; i++) {
            datesInRange.push(currDate.clone());
            currDate = DateUtils.startOfNextDay(currDate);
        }
        return datesInRange;
    }

    /**
     * Get the inclusive number of days between two dates
     */
    public static getNumberOfDaysForTimeRangeInclusive(startDateTime: Moment, endDateTime: Moment): number {
        const startDate = startDateTime.clone().startOf('day');
        const endDate = endDateTime.clone().endOf('day');
        // Add one to the difference, because Moment.js rounds down, and we want an inclusive count
        const numDaysInclusive = endDate.diff(startDate, 'day') + 1;
        return numDaysInclusive;
    }

    /**
     * Convert a display version of a date in the specified timezone to the actual date.
     * The display version of the date is represented as a Javascript Date in the user's browser timezone.
     * @param {Date} displayDate - display version of a date in the specified timezone
     * @param {String} timezone - timezone that the specified date is displayed as
     * @returns {Date} actual date of the display date
     */
    public static convertDisplayTimeToTimezoneDateTime(displayDate: Date, timezone: string) {
        let date = null;
        if (displayDate) {
            // Use the passed in display date to create a Moment with that display time in the given timezone
            let timezoneMoment = moment.tz([displayDate.getFullYear(), displayDate.getMonth(), displayDate.getDate(), displayDate.getHours(), displayDate.getMinutes(), displayDate.getSeconds(), displayDate.getMilliseconds()], timezone);
            date = timezoneMoment.toDate();
        }
        return date;
    }

    /**
     * Create a Moment with the passed in display time in the given timezone
     * Convert a display version of a date in the specified timezone to the actual date.
     * The display version of the date is represented as a Javascript Date in the user's browser timezone.
     * @param {Date} displayDate - display version of a date in the specified timezone
     * @param {String} timezone - timezone that the specified date is displayed as
     * @returns {Moment} actual date of the display date
     */
    public static convertDisplayTimeToTimezoneMoment(displayDate: Date, timezone: string) {
        let timezoneMoment: Moment;
        if (displayDate) {
            // Use the passed in display date to create a Moment with that display time in the given timezone
            timezoneMoment = moment.tz([displayDate.getFullYear(), displayDate.getMonth(), displayDate.getDate(), displayDate.getHours(), displayDate.getMinutes(), displayDate.getSeconds(), displayDate.getMilliseconds()], timezone);
        }
        return timezoneMoment;
    }

    /**
     * Convert a date in the specified timezone to a display version of the date.
     * The display version of the date is represented as a Javascript Date in the user's browser timezone.
     * @param {Date} date - actual date to be converted to a display version
     * @param {String} timezone - timezone to use for the converted display date
     * @returns {Date} display version of the date that represents the date in the specified time zone
     */
    public static convertTimezoneDateTimeToDisplayTime(date: Date, timezone: string) {
        let displayDate = null;
        if (date) {
            // Create a Moment that represents the passed in date, which because it is a Javascript Date, it should be in the user's browser timezone
            let dateMoment = moment(date);
            // Adjust the Moment for the target timezone
            let timezoneMoment = dateMoment.tz(timezone);
            if (timezoneMoment) {
                // Create a display version of the timezone-adjusted time
                displayDate = new Date(timezoneMoment.year(), timezoneMoment.month(), timezoneMoment.date(), timezoneMoment.hour(), timezoneMoment.minute(), timezoneMoment.second(), timezoneMoment.millisecond());
            }
        }

        return displayDate;
    }

    /**
     * Returns a clone of the provided date, where the clone has the same local time as the provided date,
     * but within the current timezone configured for moment, regardless of the provided date's timezone.
     * Ex: the provided date is 3 pm with tz America/Los_Angeles. moment.tz.setDefault is configured with America/New_York
     * the returned value will be 3 pm wih tz America/New_York
     * @param date
     */
    public static cloneMomentToConfiguredTimezone(date: Moment): Moment {
        const clonedDate = moment().year(date.year()).month(date.month()).date(date.date()).hour(date.hour()).minutes(date.minutes()).seconds(date.seconds()).milliseconds(date.milliseconds());
        return clonedDate;
    }

    /**
     * Returns a clone of the provided date, where the clone is in UTC but has the same date time values as the provided date,
     * regardless of its timezone
     * @param date
     */
    public static cloneTimezoneMomentToUtcMoment(date: Moment): Moment {
        const clonedDateUtc = moment.utc().year(date.year()).month(date.month()).date(date.date()).hour(date.hour()).minutes(date.minutes()).seconds(date.seconds()).milliseconds(date.milliseconds());
        return clonedDateUtc;
    }

    /**
     * Get the duration in days between the provided start and end time
     * @param {Moment} startTime
     * @param {Moment} endTime
     * @returns {number}
     */
    public static getDifferenceInDaysFromMoments(startTime: moment.Moment, endTime: moment.Moment, precise: boolean = true) {
        return endTime.diff(startTime, 'days', precise /* precise: true so we get floating point decimal */);
    }

    /**
     * Get the duration in hours between the provided start and end time
     * @param {Moment} startTime
     * @param {Moment} endTime
     * @param {boolean} precise
     * @returns {number}
     */
    public static getDifferenceInHoursFromMoments(startTime: moment.Moment, endTime: moment.Moment, precise: boolean = true) {
        return endTime.diff(startTime, 'hours', precise);
    }

    /**
     * Get the duration in minutes between the provided start and end time
     * @param {Moment} startTime
     * @param {Moment} endTime
     * @returns {number}
     */
    public static getDifferenceInMinutesFromMoments(startTime: moment.Moment, endTime: moment.Moment, precise: boolean = true) {
        return endTime.diff(startTime, 'minutes', precise /* precise: true so we get floating point decimal */);
    }

    /**
     * Return a rounded clone of the given moment. Round the minutes as follows:
     * 0-7 ->   0 min
     * 8-22 ->  15 min
     * 23-37 -> 30 min
     * 38-52 -> 45 min
     * 53-59 -> 60 min (minutes will be 0 and the hour will be incremented)
     * @param mom
     */
    public static roundMomentToQuarterHours(mom: Moment) {
        let adjustedMoment = mom.clone();
        let minutes = mom.minutes();
        if (0 <= minutes && minutes <= 7) {
            minutes = 0;
        } else if ( 8 <= minutes && minutes <= 22 ) {
            minutes = 15;
        } else if ( 23 <= minutes && minutes <= 37 ) {
            minutes = 30;
        } else if ( 38 <= minutes && minutes <= 52 ) {
            minutes = 45;
        } else if (53 <= minutes && minutes <= 59 ) {
            minutes = 60;
        }
        adjustedMoment.minutes(minutes);
        adjustedMoment.seconds(0); // ignore seconds
        adjustedMoment.milliseconds(0); // ignore miliseconds
        return adjustedMoment;
    }

    /**
     * Return true if the two moments are on the same date.
     * @param {Moment} date
     * @param {Moment} otherDate
     * @returns {boolean}
     */
    public static areSameDates(date: Moment, otherDate: Moment) {
        return date.isSame(otherDate, 'day');
    }

    /**
     * Returns true if the given dates are the same or if both are null
     * @param date
     * @param otherDate
     */
    public static isSameMoment(date: Moment, otherDate: Moment): boolean {
        let isSameMoment: boolean = false;
        if ((date && otherDate && date.isSame(otherDate)) || (!date && !otherDate)) {
            isSameMoment = true;
        }

        return isSameMoment;
    }

    /**
     * Returns true if the event overlaps, ends, or begins within time range provided
     * @param eventStart
     * @param eventEnd
     * @param rangeStart
     * @param rangeEnd
     * @param includeStartEdge If true, the event can end at the start of the range and still count as overlapping. defaults to true.
     * @param includeEndEdge If true, the event can start at the end of the range and still count as overlapping. defaults to true.
     */
    public static overlapsStartsOrEndsBetween(eventStart: Moment, eventEnd: Moment, rangeStart: Moment, rangeEnd: Moment, includeStartEdge: boolean = true, includeEndEdge: boolean = true) {
        let endValid = includeStartEdge ? eventEnd.isSameOrAfter(rangeStart) : eventEnd.isAfter(rangeStart);
        let startValid = includeEndEdge ? eventStart.isSameOrBefore(rangeEnd) : eventStart.isBefore(rangeEnd);
        return endValid && startValid;
    }

    /**
     * Returns true if the start time is within the view. Start time of the view is inclusive, end time is exclusive.
     * @param startTime
     * @param rangeStart
     * @param rangeEnd
     */
    public static isStartTimeInView(startTime: Moment, rangeStart: Moment, rangeEnd: Moment): boolean {
        return rangeStart.isSameOrBefore(startTime) && startTime.isBefore(rangeEnd);
    }

    /**
     * Return adjusted moment that reconciles the selected date with specified day view start hour time.
     * @param selectedDate
     * @param dayViewStartHr
     */
    public static getDayViewStartDateAndTime(selectedDate: Moment, dayViewStartHr: number) {
        // Clone the current date and set it to the start of the team's day view.
        let viewStartDateAndTime = selectedDate.clone().startOf('day').add(dayViewStartHr, 'hours');
        return viewStartDateAndTime;
    }

    /**
     * Return adjusted moment that reconciles the selected date with specified day view end hour time.
     * @param selectedDate
     * @param dayViewEndHr
     */
    public static getDayViewEndDateAndTime(selectedDate: Moment, dayViewEndHr: number) {
        // Build a moment that represents the end of the team's day view
        // We convert the desired end in hours to the desired end in a day offset in order to handle DST boundaries
        // Moment.add is handled differently for days and hours in this case. Adding hours will drop or add an hour for DST boundaries
        let viewEndDateAndTime = selectedDate.clone().startOf('day').add(dayViewEndHr / 24, 'days');
        return viewEndDateAndTime;
    }

    /**
     * Calculate a date's index value used as a key for data lookups for shifts that fall on the date.
     * PERFORMANCE - This method is slow if called many, many times. You should use fastCalculateDateIndex.
     */
    private static calculateDateIndex(date: Moment): number {
        const startOfDate = date.clone().startOf('day');
        return startOfDate.valueOf();
    }

    /**
     * PERFORMANCE - uses memoize to prevent the over-use of moment clone() and startOf().
     * The dates are repeated as either the dates of the empty cells or the start dates of shifts. They
     * are for the most part constant (they aren't the current time which is always changing) so we can memoize.
     */
     private static _fastCalculateDateIndexCache: {[key: string]: number} = {};

     public static fastCalculateDateIndex = MemoizeUtils.memoizeUtility(
         DateUtils.calculateDateIndex,
         DateUtils._fastCalculateDateIndexCache,
         <Moment>(args: Moment) => {
             return `${args.valueOf()}`;
         }
     );

    /**
     * Returns the hours rounded to atmost 2 decimal places for displaying to users
     */
    public static getHoursRoundedForDisplay(hours: number): string {
        const hoursRounded: number = Math.round(hours * 100) / 100;
        return hoursRounded.toLocaleString(StringsStore().currentLocale);
    }

    /**
     * Convert an Excel date number value to its equivalent UTC Date
     * @param {number} excelDate
     * @returns {Date} UTC date
     */
    public static convertExcelDateToJavascriptUtcDate(excelDate: number): Date {
        if (!excelDate) {
            return null;
        }

        /**
         * Using javascript Date instead of moment
         * Moment converts date to the user's local timezone while we need the date to be read as is
         * The UTC function offered by moment allows us to print the UTC time as a string
         * We however, need the date object in the right time
         */

        // 86400 = Seconds in a day
        // 25569 = Days between 1970/01/01 and 1900/01/01 (min date in Windows Excel)
        let utcDate = new Date((excelDate - 25569) * 86400 * 1000);
        let date = new Date(utcDate.getUTCFullYear(), utcDate.getUTCMonth(), utcDate.getUTCDate(), utcDate.getUTCHours(), utcDate.getUTCMinutes(), utcDate.getUTCSeconds(), utcDate.getUTCMilliseconds());
        return date;
    }

    /**
     * Compute the Date representing the end of the day for the specified date
     * @param {Moment} date
     * @returns {Moment} end of day date
     */
    public static getEndOfDayForDate(date: Moment): Moment {
        // End of the day is midnight of the following day
        let endOfDay: Moment = DateUtils.startOfNextDay(date);
        return endOfDay;
    }

    /**
     * Compute the start or end of the week that contains the specified date, taking into account the specified start day of week.
     * @param dateInWeek specified date that the containing week will be computed for
     * @param startDayOfWeek start day of week. The computed week will begin on this day of week.
     * @param doGetWeekStart true to return the start datetime of the week. false to return the end datetime of the week
     * @returns start or end datetime of the week
     */
    public static getWeekStartEndForDate(dateInWeek: Moment, startDayOfWeek: DayOfWeek, doGetWeekStart: boolean): Moment {
        let weekStartEnd: Moment = null;
        if (dateInWeek) {
            const weekLength: number = 7;
            let startDayOfWeekValue: number = startDayOfWeek;
            let dateInWeekDayOfWeek: number = dateInWeek.day();

            // Compute the day of week index to be passed to moment().day().
            // moment().day() computes the day of week based on Sunday-Saturday weeks.  So if we are dealing with weeks that start on a day
            // other than Sunday, we may need to make adjustments.
            let dayOfWeekValue: number = (dateInWeekDayOfWeek < startDayOfWeekValue) ?
                (startDayOfWeekValue - weekLength) :    // If the specified date's day of week comes before the start day of week, then we want to fetch the previous week's day of week.
                startDayOfWeekValue;                    // Otherwise the specified date's day of week is either current or upcoming.

            // Compute the end of the week if specified
            if (!doGetWeekStart) {
                dayOfWeekValue += (weekLength - 1);
            }

            weekStartEnd = dateInWeek.clone().day(dayOfWeekValue);
            // A week begins at midnight of the start date and ends at the end of day for the end date
            if (doGetWeekStart) {
                weekStartEnd.startOf("day");
            } else {
                weekStartEnd.endOf("day");
            }
        }
        return weekStartEnd;
    }

    /** Apply the specified time of day to the specified date.
     * This is used for calculating datetimes using the values from separate date and time picker controls.
     * @param date date
     * @param time time of day to apply to the date
     */
    public static applyTimeOfDayToDate(date: Moment, time: Moment): Moment {
        let resultDateTime = date.clone();
        resultDateTime.set('hour', time.get('hour'));
        resultDateTime.set('minute', time.get('minute'));
        resultDateTime.set('second', time.get('second'));
        resultDateTime.set('millisecond', time.get('millisecond'));
        return resultDateTime;
    }

    /**
     * Returns true if the times are identical. Accounts for null and undefined moments/dates.
     * @param first
     * @param second
     */
    public static areTimesIdentical(first: Moment, second: Moment) {
        // if a date is null or undefined, return true if both are null or undefined
        if (!first || !second) {
            return !first && !second;
        } else {
            return moment(first).isSame(moment(second));
        }
    }

    /**
     * Return the number of slots in a time range, given the range and the hours per slot
     * @param startTime
     * @param endTime
     * @param hoursPerSlot
     */
    public static getNumberOfSlotsInRange(startTime: Moment, endTime: Moment, hoursPerSlot: number): number {
        return endTime.diff(startTime, "days") * 24 / hoursPerSlot; // days * hours/day * slots/hour = slots
    }

    /**
     * Add/subtract day-based time units (ie, days, weeks, months) to the specified date, and return the beginning of the calculated time unit.
     * This can be used to calculate things like the start of the following day, and handles DST boundaries.
     * @param date
     * @param incrementAmount number of time units to add/subtract to the specified date
     * @param dayBasedUnits Moment day-based time unit (eg, 'days', 'weeks', 'months')
     */
    public static startOfIncrementedDayTimeUnit(date: Moment, incrementAmount: number, dayBasedUnits: any): Moment {
        // Handle the special case for timezones (eg, Brazil) where the DST transition occurs around midnight.
        // Moment has bugs where trying to compute the next day returns the same day instead.  Here we workaround the
        // issue by adding a few hours to ensure that we really get the next day.
        //
        // Problem scenario:
        // In Brazil's timezone, query for the end of day of Oct 14 2017 12am, which is the day before Brazil's DST transition,
        // where 12am->1am  For some reason Moment's add(1, day) to Oct 14 12am will incorrectly return Oct 14 11pm
        // instead of midnight the next day, Oct 15.
        const nextDayNudgeHours: number = 3;
        let nextTimeUnit: Moment = date.clone().startOf(dayBasedUnits).add(incrementAmount, dayBasedUnits).add(nextDayNudgeHours, 'hours');
        nextTimeUnit.startOf(dayBasedUnits);
        return nextTimeUnit;
    }

    /**
     * Calculate the start of the following day for the specified date
     * @param date
     * @returns start of next day
     */
    public static startOfNextDay(date: Moment): Moment {
        return DateUtils.startOfIncrementedDayTimeUnit(date, 1, 'day');
    }

    /**
     * Function that checks to see if the fetch range is contiguous with the cache range
     */
    public static isRangeContiguous(cacheStart: Moment, cacheEnd: Moment, fetchStart: Moment, fetchEnd: Moment): boolean {
        // adjust the end times (both fetch and cache) to end of day to account for the -1 millisecond we use to signify end of day
        const adjustedFetchEndTime = DateUtils.getEndOfDayForDate(fetchEnd);
        const adjustedCacheEndTime = DateUtils.getEndOfDayForDate(cacheEnd);

        return fetchStart.isSameOrBefore(adjustedCacheEndTime) && adjustedFetchEndTime.isSameOrAfter(cacheStart);
    }

    /**
     * Used to ensure that the time that is returned is boxed in by a lower bound
     * @param time
     * @param earliestTime
     */
    public static getTimeLimitedByLowerBound(time: Moment, earliestTime: Moment): Moment {
        if (!time) {
            return null;
        }

        let timeLimitedByBound: Moment = time.clone();

        if (earliestTime && time.isBefore(earliestTime)) {
            timeLimitedByBound = earliestTime.clone();
        }

        return timeLimitedByBound;
    }

    /**
     * Used to ensure that the time that is returned is boxed in by an upper bound
     * @param time
     * @param latestTime
     */
    public static getTimeLimitedByUpperBound(time: Moment, latestTime: Moment): Moment {
        if (!time) {
            return null;
        }
        let timeLimitedByBound: Moment = time.clone();

        if (latestTime && time.isAfter(latestTime)) {
            timeLimitedByBound = latestTime.clone();
        }

        return timeLimitedByBound;
    }

    /**
     * Check whether shift time has crossed over a day
     * @param startDateTime
     * @param endDateTime
     */
    public static isCrossOverDay(startDateTime: Moment, endDateTime: Moment): boolean {
        if (!startDateTime || !endDateTime) {
            return false;
        }

        return startDateTime.date() !== endDateTime.date() && (endDateTime.isAfter(startDateTime, "day"));
    }

    /**
     * Check whether datetime is same or after the current date,
     * precision is in date
     * @param dateTime
     */
    public static isDateNowOrFuture(dateTime: Moment): boolean {
        if (!dateTime) {
            return false;
        }

        return (dateTime.isSameOrAfter(moment.now(), "day"));
    }

    private static _isNowOrFutureCache: {[key: string]: boolean} = {};
    public static fastIsDateNowOrFuture = MemoizeUtils.memoizeUtility(
        DateUtils.isDateNowOrFuture,
        DateUtils._isNowOrFutureCache,
        <Moment>(args: Moment) => {
            return `${args.valueOf()}`;
        }
    );

    /**
     * Check whether a given shift is exactly a 24hr shift
     * For example, 8AM to 8AM shift
     * This method measures the difference in minutes to be equal to 1440.
     * @param startDateTime
     * @param endDateTime
     */
    public static is24HrShift(startDateTime: Moment, endDateTime: Moment): boolean {
        if (!startDateTime || !endDateTime) {
            return false;
        }

        return endDateTime.diff(startDateTime, "minutes") == NUMBER_OF_MINUTES_IN_ONE_DAY;
    }

    /**
    * Get month and Date in the format of May 9, Jan 10
    * @param date date for the shift
    */
    public static getMonthAndDateForDisplay(date: Moment): string {
        // Get Use 3-letter abbreviations for months with no period: Jan, Feb, Mar, Apr, May, Jun, Jul, Aug, Sep, Oct, Nov, and Dec
        return date ? DateTimeFormatter.getDateTimeAsString(date, DateTimeFormatType.Date_DateMonthShort) : "";
    }
}