import { createLazyComponent, LazyModule } from "owa-bundling";

const lazyModule = new LazyModule(() =>
    import(/* webpackChunkName: "memberEditorDialog" */ "./lazyIndex")
);

export const MemberEditorDialog = createLazyComponent(lazyModule, m => m.MemberEditorDialog);

/* Store */
export { default as memberEditorViewState } from "./store";

/* Schema */
export { default as MemberEditorViewStateSchema } from "./store/schema/MemberEditorViewStateSchema";

/* Register Actions */
export { default as addMember } from "./actions/addMember";
export { setSearchError } from "./actions/setSearchError";

/* Register Mutators */
export { setSearchErrorMutator } from "./mutators/setSearchErrorMutator";
export { default as resetMemberEditorViewState } from "./mutators/resetMemberEditorViewState";
export { default as setCurrentlySelectedTeamMember } from "./mutators/setCurrentlySelectedTeamMember";
export { default as setIsSaving } from "./mutators/setIsSaving";
export { default as setMemberDialogOpen } from "./mutators/setMemberDialogOpen";
export { default as setMemberOpenDialogTriggerButton } from "./mutators/setMemberOpenDialogTriggerButton";
export { default as setTeamMemberToAdd } from "./mutators/setTeamMemberToAdd";
export { default as setShowInlineError } from "./mutators/setShowInlineError";
export { default as setTeamsChannelId } from "./mutators/setTeamsChannelId";

/* Register Orchestrators */
import "./orchestrators/onAddMember";