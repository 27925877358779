import * as moment from "moment";
import { Moment } from "moment";
import ModelUtils from "../ModelUtils";
import ShiftrObjectModelBase from "../ShiftrObjectModelBase";
import {
    IShiftInfo,
    IShiftRequestEntity,
    IShiftRequestMemberReadResponse,
    ShiftRequestState,
    ShiftRequestType
} from "./IShiftRequestEntity";

// unique prefix for shift request id
const shiftRequestIdPrefix = "SREQ_";

// ShiftRequestMemberReadResponseModel will hold member responses in shift request
export class ShiftRequestMemberReadResponseModel implements IShiftRequestMemberReadResponse {
    shiftRequestId: string;
    memberId: string;
    userLastReadTime: Moment;

    constructor(shiftRequestId: string, memberId: string, userLastReadTime: Moment) {
        this.shiftRequestId = shiftRequestId;
        this.memberId = memberId;
        this.userLastReadTime = userLastReadTime ? userLastReadTime.clone() : null;
    }

    static fromJson(data: IShiftRequestMemberReadResponse) {
        return new ShiftRequestMemberReadResponseModel(data.shiftRequestId, data.memberId, moment(data.userLastReadTime));
    }
}

// ShiftInfo holds shift information within Shift Request
export class ShiftInfo implements IShiftInfo {
    shiftId: string;
    startTime: Moment;
    endTime: Moment;

    constructor(shiftId: string, startTime: Moment, endTime: Moment) {
        this.shiftId = shiftId;
        this.startTime = startTime ? startTime.clone() : null;
        this.endTime = endTime ? endTime.clone() : null;
    }

    static fromJson(data: IShiftInfo) {
        return new ShiftInfo(data.shiftId, data.startTime ? moment(data.startTime) : null, data.endTime ? moment(data.endTime) : null);
    }
}

/**
 * Base class that sets up common aspects for the Shift Request client and service models
 */
export default class ShiftRequestEntity extends ShiftrObjectModelBase implements IShiftRequestEntity {
    tenantId: string;
    teamId: string;
    shiftId: string;
    requestType: ShiftRequestType;
    startTime: Moment;
    endTime: Moment;
    groupId: string;
    state: ShiftRequestState;
    senderMessage: string;
    receiverMessage: string;
    managerMessage: string;
    managerMemberId: string;
    receiverMemberId: string;
    senderMemberId: string;
    timeOffReasonId: string;
    creationTime: Moment;
    lastModifiedTime: Moment;
    receiverResponseTime: Moment;
    memberReads: Array<ShiftRequestMemberReadResponseModel>;
    otherShiftInfo: ShiftInfo;
    senderTeamId: string;
    senderManagerActor: string;
    senderManagerMessage: string;
    targetTeamId: string;

    constructor(props: IShiftRequestEntity) {
        const {
            id,
            eTag,
            tenantId,
            teamId,
            shiftId,
            requestType,
            groupId,
            state,
            senderMessage,
            receiverMessage,
            managerMessage,
            senderMemberId,
            receiverMemberId,
            managerMemberId,
            timeOffReasonId,
            startTime,
            endTime,
            creationTime,
            lastModifiedTime,
            receiverResponseTime,
            memberReads,
            otherShiftInfo,
            senderManagerActor,
            senderManagerMessage,
            senderTeamId,
            targetTeamId
        } = props;

        let modelId = id || ShiftRequestEntity.generateNewShiftRequestId();
        super(modelId, eTag);

        this.tenantId = tenantId;
        this.teamId = teamId;
        this.shiftId = shiftId;
        this.requestType = requestType;
        this.startTime = startTime ? startTime.clone() : null;
        this.endTime = endTime ? endTime.clone() : null;
        this.groupId = groupId;
        this.state = state;
        this.senderMessage = senderMessage;
        this.receiverMessage = receiverMessage;
        this.managerMessage = managerMessage;
        this.senderMemberId = senderMemberId;
        this.receiverMemberId = receiverMemberId;
        this.managerMemberId = managerMemberId;
        this.timeOffReasonId = timeOffReasonId;
        this.creationTime = creationTime ? creationTime.clone() : null;
        this.lastModifiedTime = lastModifiedTime ? lastModifiedTime.clone() : null;
        this.receiverResponseTime = receiverResponseTime ? receiverResponseTime.clone() : null;
        this.memberReads = [];
        if (memberReads && memberReads.length) {
            memberReads.map(memberReadResponseModel =>
                this.memberReads.push(ShiftRequestMemberReadResponseModel.fromJson(memberReadResponseModel))
            );
        }
        this.otherShiftInfo = otherShiftInfo ? ShiftInfo.fromJson(otherShiftInfo) : null;
        this.senderManagerActor = senderManagerActor;
        this.senderManagerMessage = senderManagerMessage;
        this.senderTeamId = senderTeamId;
        this.targetTeamId = targetTeamId;
    }

    static fromJson(jsonInput: IShiftRequestEntity): IShiftRequestEntity {
        const jsonData = { ...jsonInput };
        const { memberReads, startTime, endTime, creationTime, lastModifiedTime, receiverResponseTime, otherShiftInfo } = jsonData;

        if (memberReads?.length) {
            jsonData.memberReads = memberReads.map(memberRead => ShiftRequestMemberReadResponseModel.fromJson(memberRead));
        }

        jsonData.otherShiftInfo = otherShiftInfo ? ShiftInfo.fromJson(otherShiftInfo) : null;

        // Convert date strings to moment objects
        jsonData.startTime = startTime ? moment(startTime) : null;
        jsonData.endTime = endTime ? moment(endTime) : null;
        jsonData.creationTime = creationTime ? moment(creationTime) : null;
        jsonData.lastModifiedTime = lastModifiedTime ? moment(lastModifiedTime) : null;
        jsonData.receiverResponseTime = receiverResponseTime ? moment(receiverResponseTime) : null;

        return new ShiftRequestEntity(jsonData);
    }

    /**
     * Generate new shiftId used by StaffHub App
     * @returns {string} shiftId in form of SHFT_<uuid>
     */
    public static generateNewShiftRequestId(): string {
        return ModelUtils.generateUUIDWithPrefix(shiftRequestIdPrefix);
    }
}
