import { app as teamsApp, logs as teamsLogs } from "@microsoft/teams-js";
import { AppSettings } from "sh-application";
import { generateUUID } from "sh-application/utility/utility";
import { InstrumentationService, LoggingService } from "sh-services";

import { APP_NAME } from "../../StaffHubConstants";

import { HostContext } from "./HostContext";
import { IHostContainer, InitializeCallback } from "./IHostContainer";

/**
 * The Microsoft Teams host container.
 */
export class TeamsHostContainer implements IHostContainer {
    /**
     * TODO: Create platform agnostic context and use it here instead of Teams.Context.
     * Gets the host context.
     * @returns The host context.
     */
    public async getContext(): Promise<HostContext> {
        // return a promise that resolves to teams context
        return new Promise((resolve, reject) => {
            const marker = InstrumentationService.perfMarkerStart("getTeamsContext");

            // TODO: Remove unnecessary try/catch because Promise do it already
            try {
                teamsApp.getContext().then(teamsContext => {
                    resolve(this.formatContext(teamsContext));
                });
            } catch (e) {
                reject(e);
            } finally {
                // TODO: This is always called before resolve, investigate if moving this right before resolve will impact any monitor
                InstrumentationService.perfMarkerEnd(marker);
            }
        });
    }

    /**
     * Initializes the host container.
     * @returns The initialization callback to call whenever application has been initialized successfully.
     */
    public async initialize(): Promise<InitializeCallback> {
        return new Promise(resolve => {
            teamsApp.initialize().then(() => {
                // Register the log handler with Teams Client SDK
                if (AppSettings.getSetting("EnableAppLogging")) {
                    teamsLogs.registerGetLogHandler(
                        // Appending the app name to log file sent to brb logs
                        (): string => LoggingService.getLogFileString(APP_NAME)
                    );
                }

                resolve((): void => teamsApp.notifySuccess());
            });
        });
    }

    /**
     * Reports given error to the host.
     * @param error The error.
     */
    public reportError(error: Error): void {
        teamsApp.notifyFailure({
            reason: teamsApp.FailedReason.Other,
            message: error.message
        });
    }

    /**
     * Formats the Teams context to platform agnostic context.
     * @param teamsContext Teams context.
     * @returns The platform agnostic context.
     */
    private formatContext(teamsContext: teamsApp.Context): HostContext {
        const { app, user } = teamsContext;
        const { host } = app;

        return {
            app: {
                locale: app.locale,
                theme: app.theme
            },
            host: {
                clientType: host.clientType,
                name: host.name,
                ringId: host.ringId,
                sessionId: host.sessionId ?? generateUUID()
            },
            tenant: {
                id: user?.tenant?.id ?? ""
            },
            user: {
                id: user?.id ?? ""
            }
        };
    }
}
