import getImportScheduleJobStatus from "./getImportScheduleJobStatus";
import importSchedule from "../actions/importSchedule";
import importScheduleDialogViewState from "../store/store";
import setDialogErrorMessage from "../mutators/setDialogErrorMessage";
import setErrorMessage from "../mutators/setErrorMessage";
import setImportScheduleInProgress from "../mutators/setImportScheduleInProgress";
import setImportScheduleJob from "../mutators/setImportScheduleJob";
import setImportScheduleJobResult from "../mutators/setImportScheduleJobResult";
import StaffHubHttpErrorUtils from "sh-application/utility/StaffHubHttpErrorUtils";
import { getGenericEventPropertiesObject, InstrumentationEventPropertyInterface } from "sh-instrumentation";
import { IJobEntity, ImportScheduleJobResult, JobStatus } from "sh-models";
import { InstrumentationService, TeamDataService } from "sh-services";
import { orchestrator } from "satcheljs";
import { StaffHubHttpError } from "sh-application";
import { trace } from "owa-trace";

export default orchestrator(importSchedule, async actionMessage => {
    // enforce only one job in progress from a client
    if (importScheduleDialogViewState().importScheduleInProgress) {
        return;
    }

    setImportScheduleInProgress(true); // job submitted
    let isFileUploadSuccess: boolean = false;
    let eventProperties: Array<InstrumentationEventPropertyInterface> = [];

    try {
        let importScheduleJob: IJobEntity = await TeamDataService.importScheduleFromExcel(actionMessage.teamId, actionMessage.file);
        setImportScheduleJob(importScheduleJob);
        if (importScheduleJob && importScheduleJob.result) {
            setImportScheduleJobResult(ImportScheduleJobResult.fromJson(importScheduleJob.result));
        }

        if (importScheduleJob && importScheduleJob.status === JobStatus.Queued) {
            const teamJobData = {
                teamId : actionMessage.teamId,
                jobId : importScheduleJob.jobId
            };
            // keep polling for job status until success or failure
            getImportScheduleJobStatus(teamJobData);
        }
        isFileUploadSuccess = true;
    } catch (error) {
        setImportScheduleInProgress(false); // import schedule failed, So unblock a client for new job
        trace.warn(`Import Schedule API Call Failed: ${ error }`);
        const httpError: StaffHubHttpError = error;
        const showRefreshButton = StaffHubHttpErrorUtils.isConcurrencyConflictError(error);

        if (httpError.staffHubInnerErrorCode === "FileWithBothScheduleAndShiftsFilled") {
            setDialogErrorMessage(httpError.staffHubError.message); // error message on dialog
        } else if (httpError.staffHubError && httpError.staffHubError.message) {
            setDialogErrorMessage(httpError.staffHubError.message);
            setErrorMessage(httpError.staffHubError.message, showRefreshButton); // error message on panel messagebar
        } else {
            const errorMessage = StaffHubHttpErrorUtils.getErrorMessage(error);
            setErrorMessage(errorMessage, showRefreshButton); // show generic error
        }
    }

    // log events for Import Excel Data file loaded and upload success
    eventProperties.push(getGenericEventPropertiesObject(InstrumentationService.properties.EventType, InstrumentationService.values.ImportExcelDataFileLoaded));
    eventProperties.push(getGenericEventPropertiesObject(InstrumentationService.properties.UploadSuccess, isFileUploadSuccess));
    InstrumentationService.logEvent(InstrumentationService.events.ImportExcel, eventProperties);

});