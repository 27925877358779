import { trace } from "owa-trace";
import { MEMBER_ID_PREFIX } from "sh-application/../StaffHubConstants";
import { getGenericEventPropertiesObject } from "sh-instrumentation";
import { InstrumentationService } from "sh-services";

import { UserProfile } from "../../../sh-services/lib/graph/dataModels/UserProfile";
import IMemberEntity from "../IMemberEntity";
import MemberEntity from "../MemberEntity";
import MemberStates from "../MemberStates";

import { IMemberMapper } from "./IMemberMapper";
import { MergeUserProfileWithMemberEntityParams } from "./MergeUserProfileWithMemberEntityParams";
import { MergeUsersProfilesWithMembersEntitiesParams } from "./MergeUsersProfilesWithMembersEntitiesParams";

/**
 * The member mapper.
 */
export class MemberMapper implements IMemberMapper {
    /**
     * Note: Until we remove unnecessary 'IMemberEntity' properties, merging Graph members properties with synced Shifts service members properties.
     * Merges in-place the given list of users profiles with members entities.
     * - If a user profile doesn't exist in the members list, it will be added as a new member to the members list.
     * - If a member doesn't exist in the users profiles list, its 'state' property will be set to 'Deleted'.
     * - Using in-place merge to avoid cloning members entities since in some edge cases, a team can have up to 12k members.
     * @param params The parameters.
     * @returns The merged members with users profiles.
     */
    // TODO: After cleaning up 'IMemberEntity' properties:
    // 1. Remove merging members with Shifts service and only map Graph data model to view model.
    //   a. If necessary, merge deleted members returned by Shifts service for historical data.
    public mergeUsersProfilesWithMembersEntities(params: MergeUsersProfilesWithMembersEntitiesParams): IMemberEntity[] {
        const { adminRoleId, ownersIds, teamId, tenantId, users } = params;
        const startTime = InstrumentationService.getCurrentTimeStamp();

        const usersByUserId = users.reduce((acc, user) => {
            acc.set(user.id, user);
            return acc;
        }, new Map<string, UserProfile>());

        const remainingUserIds = new Set(usersByUserId.keys());
        const mergedMembers = this.mergeMembers(params, usersByUserId, remainingUserIds);

        trace.info(`MemberMapper.mergeUsersProfilesWithMembersEntities: ${mergedMembers.length} members, ${users.length} users, ${remainingUserIds.size} remaining users`);

        remainingUserIds.forEach(userId => {
            const user = usersByUserId.get(userId);
            const newMember = this.mergeUserProfileWithMemberEntity({ adminRoleId, ownersIds, teamId, tenantId, user });

            mergedMembers.push(newMember);
        });

        InstrumentationService.logPerfEvent(
            InstrumentationService.events.GraphMembersMerged,
            [getGenericEventPropertiesObject(InstrumentationService.properties.MembersReturned, mergedMembers.length)],
            { duration: InstrumentationService.getCurrentTimeStamp() - startTime }
        );

        return mergedMembers;
    }

    /**
     * Merges existing members with users profiles and
     * updates 'remainingUserIds' with the users not found in given members list.
     * @param params The parameters.
     * @param usersByUserId The users by their corresponding user unique identifier.
     * @param remainingUserIds The remaining users ids not found in given members list.
     * @returns The merged members with users profiles.
     */
    private mergeMembers(
        params: MergeUsersProfilesWithMembersEntitiesParams,
        usersByUserId: Map<string, UserProfile>,
        remainingUserIds: Set<string>
    ): IMemberEntity[] {
        const { adminRoleId, members, ownersIds, teamId, tenantId } = params;

        return members.reduce((mergedMembers: IMemberEntity[], member) => {
            if (usersByUserId.has(member.userId)) {
                const user = usersByUserId.get(member.userId);

                const mergedMember = this.mergeUserProfileWithMemberEntity({
                    adminRoleId,
                    member,
                    ownersIds,
                    teamId,
                    tenantId,
                    user
                });

                remainingUserIds.delete(member.userId);
                mergedMembers.push(mergedMember);
            } else {
                member.state = MemberStates.Deleted;
                mergedMembers.push(member);
            }

            return mergedMembers;
        }, []);
    }

    /**
     * Merges given user profile with member entity.
     * If no 'member' property is given, it will create a new member entity to merge with.
     * @param params The parameters.
     * @returns The member entity merged with user profile.
     */
    private mergeUserProfileWithMemberEntity(params: MergeUserProfileWithMemberEntityParams): IMemberEntity {
        const { adminRoleId, ownersIds, teamId, tenantId, user } = params;
        const isAdmin = ownersIds.has(user.id);
        let member = params.member;

        if (!member) {
            member = MemberEntity.createEmptyObject();
            member.id = MEMBER_ID_PREFIX + user.id;
        }

        member.displayName = user.displayName;
        member.email = user.userPrincipalName;
        member.firstName = user.givenName;
        member.isAdmin = isAdmin;
        member.lastName = user.surname;
        member.roleIds = isAdmin ? [adminRoleId] : [];
        member.teamId = teamId;
        member.tenantId = tenantId;
        member.userId = user.id;
        return member;
    }
}
