import { INoteEntity } from "sh-models";
import { mutatorAction } from "satcheljs";
import { NoteStore } from "sh-note-store";

export default mutatorAction('DELETE_NOTES_IN_STORE', function deleteNotesInStore(notesToDelete: INoteEntity[]) {
    notesToDelete.map((noteModel) => {
        // if we have this note in the store
        if (NoteStore().notes.has(noteModel.id)) {
            // delete it
            NoteStore().notes.delete(noteModel.id);
        }
    });
});
