import RestClient from "sh-rest-client";
import { DataProvider } from "sh-services/dataproviders/DataProvider";
import { deleteTimeClockEntry, TimeClockEntryStore, updateTimeClockEntry } from "sh-stores/sh-timeclock-store";
import { ITeamDatabase } from "sh-services/data/ITeamDatabase";
import { ITimeClockEntity } from "sh-models";
import { TeamStore } from "sh-stores/sh-team-store";

/**
 * TimeClock Latest Entry Data Provider
 */
export class TimeClockLatestEntryDataProvider extends DataProvider<ITimeClockEntity> {

    protected teamDatabase: ITeamDatabase;
    protected teamId: string;

    constructor(teamDatabase: ITeamDatabase, teamId: string) {
        super();
        this.teamDatabase = teamDatabase;
        this.teamId = teamId;
    }

    /**
     * Return data if it's found in memory (otherwise return undefined)
     */
    async getDataFromMemory() {
        return TimeClockEntryStore().timeClockEntry  || undefined;
    }

    /**
     * Return data if it's found in the database (otherwise return undefined)
     */
    async getDataFromDatabase() {
        return await this.teamDatabase.getLatestTimeClockEntry(this.teamId);
    }

    /**
     * Make an HTTP request to fetch the data from the network
     */
    async getDataFromNetwork() {
        const memberId = TeamStore().me && TeamStore().me.id;
        const latestTimeClockEntryResponse = await RestClient.getLatestTimeClockEntryForMember(this.teamId, memberId);
        return latestTimeClockEntryResponse;
    }

    /**
     * Set data in memory.
     */
    async setDataInMemory(data: ITimeClockEntity) {
        updateTimeClockEntry(data);
    }

    /**
     * Set data in the database.
     */
    async setDataInDatabase(data: ITimeClockEntity) {
        if (data) {
            return await this.teamDatabase.setLatestTimeClockEntry(data);
        }
    }

    /**
     * Make a network call to update the data.
     */
    async saveDataToNetwork(data: ITimeClockEntity): Promise<ITimeClockEntity> {
        throw new Error("TimeClockDataProvider.saveDataToNetwork not implemented");
    }

    /**
     * Delete data if it's found in memory
     */
    async hardDeleteDataFromMemory(): Promise<void> {
        deleteTimeClockEntry(this.teamId);
    }

    /**
     * Delete data if it's found in the database
     */
    async hardDeleteDataFromDatabase(): Promise<void> {
        return await this.teamDatabase.deleteLatestTimeClockEntry(this.teamId);
    }
}