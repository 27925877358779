import schedulesViewStateStore from "../../store/store";
import { IOpenShiftEntity } from "sh-models";
import { mutatorAction } from "satcheljs";

export default mutatorAction('SET_OPEN_SHIFTS_IN_VIEW', function setOpenShiftsInView(
    openShiftsInView: IOpenShiftEntity[]
) {
    schedulesViewStateStore().openShiftsInView.clear();
    if (openShiftsInView && openShiftsInView.length) {
        for (let i = 0; i < openShiftsInView.length; i++) {
            schedulesViewStateStore().openShiftsInView.set(openShiftsInView[i].id, openShiftsInView[i]);
        }
    }
});
