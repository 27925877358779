import { IChangeEntity, IBaseShiftEntity } from "sh-models";

/**
 * Helper function for replacing the id of a shift if it's id should be mapped.
 * @param oldShiftIds
 * @param newShiftIds
 * @param shift
 */
let replaceShiftId = function(oldShiftIds: Array<string>, newShiftIds: Array<string>, shift: IBaseShiftEntity) {
    let indexOfId = oldShiftIds.indexOf(shift.id);
    if (indexOfId >= 0) {
        shift.id = newShiftIds[indexOfId];
    }
};

/**
 * Helper function for checking a change for old shift ids that need to be replaced
 * @param change
 * @param oldShiftIds
 * @param newShiftIds
 */
export let checkChangeForOldShiftIds = function(change: IChangeEntity, oldShiftIds: Array<string>, newShiftIds: Array<string>) {
    if (change.previousShifts) {
        change.previousShifts.forEach((shift: IBaseShiftEntity) => {
            replaceShiftId(oldShiftIds, newShiftIds, shift);
        });
    }
    if (change.resultingShifts) {
        change.resultingShifts.forEach((shift: IBaseShiftEntity) => {
            replaceShiftId(oldShiftIds, newShiftIds, shift);
        });
    }
};