import schedulesViewStateStore from "../../store/store";
import { IShiftEntity } from "sh-models";
import { mutatorAction } from "satcheljs";

export default mutatorAction('SET_SHIFTS_IN_VIEW', function setShiftsInView(
    shiftsInView: IShiftEntity[]
) {
    schedulesViewStateStore().shiftsInView.clear();
    if (shiftsInView && shiftsInView.length) {
        for (let i = 0; i < shiftsInView.length; i++) {
            schedulesViewStateStore().shiftsInView.set(shiftsInView[i].id, shiftsInView[i]);
        }
    }
});
