import * as microsoftTeams from "@microsoft/teams-js";
import { UrlFactory } from "sh-application";
import { trace } from "owa-trace";
import { ECSConfigKey, ECSConfigService } from "sh-services";
import { replaceTargetUrlPlaceholder } from "./ConnectorAuthServiceHelper";
import { InstrumentationService } from "sh-services";
import { getGenericEventPropertiesObject } from "sh-instrumentation";
import setGlobalSpinnerMessageState from "../../../sh-application/mutatorActions/setGlobalSpinnerMessageState";
import {
    resetConnectorAuthDialogTriesCount,
    connectorAuthDialogStore,
    increaseConnectorAuthDialogTriesCount,
    setIsConnectorAuthDialogOpen
} from "../../../sh-application/components/common/ConnectorAuthDialog/lib";
import { ConnectorAuthSettings } from "./types/ConnectorAuthSettings";
import { AuthFailureReason } from "./types/AuthFailureReason";

export class ConnectorAuthService {
    private ssoUrl: string | null = null;
    private resolveAuth: (value?: string) => void | null = null;
    private cancelAuth: (value?: AuthFailureReason) => void | null = null;

    /**
     * Call AuthenticateWithConnector with the parsed URL from Connector Service + target URL on Shifts App
     */
    public launchPopup(): void {
        this.authenticateWithConnector(
            UrlFactory.getConnectorRedirectUrl(
                replaceTargetUrlPlaceholder(
                    this.ssoUrl,
                    ECSConfigService.getECSFeatureSetting(
                        ECSConfigKey.ConnectorAuthenticationDoneURL
                    )
                )
            )
        );
    }

    /**
     * Sets ConnectorAuthService settings
     * @param {Record} settings
     */
    public setSettings(settings: ConnectorAuthSettings) {
        this.ssoUrl = settings.ssoUrl;
        this.resolveAuth = settings.resolveAuth;
        this.cancelAuth = settings.cancelAuth;
    }

    /**
     * Cancels Authentication Flow
     */
    public cancelAuthenticationFlow() {
        this.cancelAuth?.(AuthFailureReason.Unknown);
    }

    public failureCallback(reason?: string) {
        setGlobalSpinnerMessageState("");
        increaseConnectorAuthDialogTriesCount(connectorAuthDialogStore());
        setIsConnectorAuthDialogOpen(connectorAuthDialogStore(), true);
        trace.warn(
            `ConnectorAuthService: AuthenticateWithConnector failure, reason: ${reason}, tries: ${
                connectorAuthDialogStore().triesCount
            }`
        );
        InstrumentationService.logEvent(InstrumentationService.events.RequestWFMWeb, [
            getGenericEventPropertiesObject(
                InstrumentationService.properties.ConnectorAuthenticationSuccess,
                false
            )
        ]);
        if (connectorAuthDialogStore().triesCount > 3) {
            this.cancelAuth?.(AuthFailureReason.Unknown);
        }
    }

    public successCallback(result?: string) {
        setGlobalSpinnerMessageState("");
        resetConnectorAuthDialogTriesCount(connectorAuthDialogStore());
        this.resolveAuth?.(result);
        trace.info("ConnectorAuthService: AuthenticateWithConnector success");
        InstrumentationService.logEvent(InstrumentationService.events.RequestWFMWeb, [
            getGenericEventPropertiesObject(
                InstrumentationService.properties.ConnectorAuthenticationSuccess,
                true
            )
        ]);
    }

    /**
     * Uses MS Teams SDK in order to Launch a popup with the url passed
     * @param {string} url
     */
    private authenticateWithConnector(url: string) {
        if (ECSConfigService.isECSFeatureEnabled(ECSConfigKey.EnableConnectorAuthenticationFlow)) {
            microsoftTeams.authentication.authenticate({
                url,
                successCallback: (result?: string) => this.successCallback(result),
                failureCallback: (reason?: string) => this.failureCallback(reason)
            });
        }
    }
}

const connectorAuthService: ConnectorAuthService = new ConnectorAuthService();
export default connectorAuthService;
