import { mutatorAction } from "satcheljs";
import { ObservableMap } from "mobx";

/**
 * Set items in map
 * @param models - array of models that needs to be set
 * @param map - observable map to set in
 */
export default mutatorAction("SET_ITEMS_IN_OBSERVABLE_MAP",
    function setItemsInObservableMap(models: any[], map: ObservableMap<string, any>) {
        for (let i = 0; i < models.length; i++) {
            let model = models[i];
            if (model && model.id) {
                map.set(model.id, model);
            }
        }
    });