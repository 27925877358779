import { mutatorAction } from "satcheljs";
import { ConnectorAuthDialogStoreSchema } from "../store/schema/ConnectorAuthDialogStoreSchema";

/**
 * Increase the user tries count for connector authentication
 * @param connectorAuthDialogStore -Connector Auth Dialog Store
 */
export const increaseConnectorAuthDialogTriesCount = mutatorAction(
    "INCREASE_CONNECTOR_AUTH_DIALOG_TRIES_COUNT",
    (connectorAuthDialogStore: ConnectorAuthDialogStoreSchema) => {
        connectorAuthDialogStore.triesCount = connectorAuthDialogStore.triesCount + 1;
    }
);
