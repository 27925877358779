import { mutatorAction } from "satcheljs";
import { ChangeStore } from 'sh-stores/sh-change-store';

/**
 * Removes the ability to redo copy schedule by removing it from the stack
 */
export default mutatorAction("SET_DISABLE_UNDO_COPY_SCHEDULE_BUTTON",
    function setDisableUndoCopyScheduleButton() {
        ChangeStore().undoStack.pop();
    }
);