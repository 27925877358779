import * as moment from "moment";
import ModelUtils from "../ModelUtils";
import ShiftrObjectModelBase from "../ShiftrObjectModelBase";
import ThemeUtils from "sh-application/utility/ThemeUtils";
import {
    IBaseSubshiftEntity,
    ISubshiftEntity,
    SubshiftState,
    SubshiftStates,
    SubshiftType,
    SubshiftTypes
    } from "./ISubshiftEntity";
import { Moment } from "moment";

const subshiftIdPrefix = "SBSHFT_";

/**
 * Entity class that represents Subshift models
 */
export default class SubshiftEntity extends ShiftrObjectModelBase implements ISubshiftEntity {
    startTime: Moment;
    endTime: Moment;
    state: SubshiftState;
    title: string;
    theme: string;
    code: string;
    subshiftType: SubshiftType;

    constructor(
        id: string,
        eTag: string,
        subshiftType: SubshiftType,
        startTime: Moment,
        endTime: Moment,
        state: SubshiftState,
        title: string,
        theme: string,
        code: string) {

        let modelId = id || SubshiftEntity.generateNewSubshiftId();
        super(modelId, eTag);

        this.subshiftType = subshiftType;
        this.startTime = startTime;
        this.endTime = endTime;
        this.state = state;
        this.title = title;
        this.theme = theme;
        this.code = code;
    }

    /**
     * Generate new shiftId used by StaffHub App
     * @returns {string} shiftId in form of SHFT_<uuid>
     */
    static generateNewSubshiftId(): string {
        return ModelUtils.generateUUIDWithPrefix(subshiftIdPrefix);
    }

    /**
     * Function that creates a new subshift object with default values for properties
     * @param subshiftType Subshift type
     * @param theme theme for the subshift
     * @param title title for subshift
     * @param code subshift code
     */
    static createEmptyObject(subshiftType: SubshiftType = SubshiftTypes.PaidWork, theme: string = ThemeUtils.shiftDefaultTheme, title: string = "", code: string = ""): SubshiftEntity {
        return new SubshiftEntity(
            null /* id */,
            null /* eTag */,
            subshiftType,
            null /* startTime */,
            null /* endTime */,
            SubshiftStates.Active,
            title,
            theme,
            code);
    }

    /**
     * Return a clone of the subshift entity
     * @param subshiftEntity
     */
    static clone(subshiftEntity: ISubshiftEntity): ISubshiftEntity {
        return new SubshiftEntity(
            subshiftEntity.id,
            subshiftEntity.eTag,
            subshiftEntity.subshiftType,
            subshiftEntity.startTime && moment(subshiftEntity.startTime),
            subshiftEntity.endTime && moment(subshiftEntity.endTime),
            subshiftEntity.state,
            subshiftEntity.title,
            subshiftEntity.theme,
            subshiftEntity.code);
    }

    /**
     * Function that creates a SubshiftEntity from service JSON
     * @param jsonData - service response
     */
    static fromJson(jsonData: IBaseSubshiftEntity<string | number>): ISubshiftEntity {
        if (!jsonData) {
            return null;
        }

        return new SubshiftEntity(
            jsonData.id,
            jsonData.eTag,
            jsonData.subshiftType,
            jsonData.startTime && moment(jsonData.startTime),
            jsonData.endTime && moment(jsonData.endTime),
            jsonData.state,
            jsonData.title,
            jsonData.theme,
            jsonData.code);
    }

    /**
     * Function that creates a list of SubshiftEntities from a service/db JSON list
     * @param jsonDataList - List of service/db entities
     */
    static fromJsonList(jsonDataList: IBaseSubshiftEntity<string | number>[]): ISubshiftEntity[] {

        const subshifts: ISubshiftEntity[] = [];
        if (jsonDataList && jsonDataList.length) {
            for (let i = 0; i < jsonDataList.length; i++) {
                subshifts.push(SubshiftEntity.fromJson(jsonDataList[i]));
            }
        }
        return subshifts;
    }

    /**
     * Function that creates service JSON from SubshiftEntity
     * @param subshiftEntity - subshift entity that needs to be sent over the wire
     */
    static toJson(subshiftEntity: ISubshiftEntity): IBaseSubshiftEntity<string> {
        if (!subshiftEntity) {
            return null;
        }

        return {
            id: subshiftEntity.id,
            eTag: subshiftEntity.eTag,
            subshiftType: subshiftEntity.subshiftType,
            startTime: subshiftEntity.startTime && subshiftEntity.startTime.toISOString && subshiftEntity.startTime.toISOString(), // Service uses an ISO string datetime format,
            endTime: subshiftEntity.endTime && subshiftEntity.endTime.toISOString && subshiftEntity.endTime.toISOString(), // Service uses an ISO string datetime format,
            state: subshiftEntity.state,
            title: subshiftEntity.title,
            theme: subshiftEntity.theme,
            code: subshiftEntity.code
        };
    }

    /**
     * Function that creates db ISubshiftEntity<number> from client ISubshiftEntity<Moment>
     * @param shift - shift client entity
     */
    static toDbModel(subshiftEntity: ISubshiftEntity): IBaseSubshiftEntity<number> {
        if (!subshiftEntity) {
            return null;
        }

        return {
            id: subshiftEntity.id,
            eTag: subshiftEntity.eTag,
            subshiftType: subshiftEntity.subshiftType,
            startTime: subshiftEntity.startTime && subshiftEntity.startTime.valueOf && subshiftEntity.startTime.valueOf(), // Db uses a numeric timestamp format,
            endTime: subshiftEntity.endTime && subshiftEntity.endTime.valueOf && subshiftEntity.endTime.valueOf(), // Db uses a numeric timestamp format,
            state: subshiftEntity.state,
            title: subshiftEntity.title,
            theme: subshiftEntity.theme,
            code: subshiftEntity.code
        };
    }
}