import * as React from "react";
import ScheduleTeamPickerUtils from "./ScheduleTeamPickerUtils";
import Spinner, { SpinnerSize } from "sh-application/components/common/Spinner";
import StringsStore from "sh-strings/store";
import TeamSetupPickerUtils from "../TeamSetupPickerUtils";
import { computed } from "mobx";
import { fireAccessibilityAlert } from "sh-application/components/accessibilityAlert";
import { FocusZone, FocusZoneDirection, IFocusZone, List, PrimaryButton } from "@fluentui/react";
import { ITeamInfoEntity } from "sh-models";
import { MobxUtils } from "sh-application";
import { observer } from "mobx-react";
import { ScheduleTeamPickerProps } from "./ScheduleTeamPickerUtils";
import { TeamInfoStore } from "sh-stores";

const styles = require("./ScheduleTeamPickerPane.scss");

/**
 * React component that renders the schedule team picker UX
 */
@observer
export default class ScheduleTeamPickerPane extends React.Component<ScheduleTeamPickerProps, any> {
    private _strings: Map<string, string>;
    private _createNewScheduleLink = React.createRef<HTMLAnchorElement>();
    private _focusZoneRef = React.createRef<IFocusZone>();
    private _hasListRendered = false;

    constructor(props: ScheduleTeamPickerProps) {
        super(props);
        this._strings = StringsStore().registeredStringModules.get("scheduleTeamPicker").strings;
        this.onPagesUpdated = this.onPagesUpdated.bind(this);
    }

    async componentDidMount() {
        // Fire accessibility alert because the heading content doesn't get read via focus.
        // The team setup dialog is reused between steps and only its inner content is changed
        fireAccessibilityAlert(this._strings.get("scheduleTeamPickerDialogAriaAlert"));
        await ScheduleTeamPickerUtils.loadInitialData(this.props.instrumentationEventName);

        if (this._createNewScheduleLink?.current) {
            this._createNewScheduleLink.current.focus();
        }
    }

    /**
     * Calculate the schedule teams list data which will be used for rendering
     */
    @computed
    private get calculateScheduleTeamsList(): ITeamInfoEntity[] {
        let teamInfoArray: ITeamInfoEntity[] = [];
        const teamInfos = TeamInfoStore().teams;
        if (teamInfos) {
            teamInfoArray = MobxUtils.MapToArray(teamInfos);
            teamInfoArray = teamInfoArray.sort(ScheduleTeamPickerUtils.scheduleTeamInfoComparator);
        }
        return teamInfoArray;
    }

    /**
     * Renders loading progress state
     */
    private renderLoadingProgress(): JSX.Element {
        return (
            <div className={ styles.progressIndicatorContainer }>
                <Spinner className={ styles.spinner } size={ SpinnerSize.small } label={ this._strings.get("loadingTeamsMessage") } />
            </div>
        );
    }

    /**
     * Render empty state when no teams are available to be listed
     */
    private renderEmptyTeamListState(): JSX.Element {
        // Display nothing. Entrypoints for the schedule team picker should have been disabled in the first place.
        return (
            <></>
        );
    }

    /**
     * Handler for when a View team button is clicked
     */
    private handleViewTeamButtonClicked(teamInfo: ITeamInfoEntity) {
        if (this.props.onTeamSelected) {
            this.props.onTeamSelected(teamInfo);
        }
    }

    /**
     * Render the schedule teams picker list contents
     */
    private renderScheduleTeamsPickerListContents(): JSX.Element {
        const teamInfoList: ITeamInfoEntity[] = this.calculateScheduleTeamsList;
        return (
            ScheduleTeamPickerUtils.isScheduleTeamsDataLoading()
            ?
            this.renderLoadingProgress()
            :
            (
                (teamInfoList && teamInfoList.length > 0)
                ?
                this.renderScheduleTeamsPickerList(teamInfoList)
                :
                this.renderEmptyTeamListState()
            )
        );
    }

    /**
     * Handler for when the Create new schedule button is clicked
     */
    private handleCreateNewScheduleButtonClicked = () => {
        if (this.props.onCreateNewScheduleClicked) {
            this.props.onCreateNewScheduleClicked();
        }
    }

    /**
     * Render the header subtitle
     */
    private renderHeaderSubtitle(): JSX.Element {
        let result: JSX.Element = null;
        if (this.props.onCreateNewScheduleClicked) {
            result = (
                <div className={ styles.headerSubtitle } >
                    <span>{ this._strings.get("createNewScheduleMessage") }</span>
                    {" "}
                    <a
                        ref={ this._createNewScheduleLink }
                        tabIndex={ 0 }
                        className={ styles.headerSubtitleLink }
                        href="#"
                        onClick={ this.handleCreateNewScheduleButtonClicked } >
                            { this._strings.get("createNewScheduleLinkText") }
                    </a>
                </div>
            );
        }
        // Otherwise no header subtitle will be rendered

        return result;
    }

    private onPagesUpdated() {
        if (!this._hasListRendered) {
            this._hasListRendered = true;
            // Call .focus() on the next frame so List
            // has rendered
            requestAnimationFrame(() => {
                this._focusZoneRef.current.focus(true);
            });
        }
    }

    /**
     * Render the schedule teams picker list UX
     */
    private renderScheduleTeamsPickerList(teamInfoList: ITeamInfoEntity[]): JSX.Element {
        return (
            <FocusZone componentRef={this._focusZoneRef} direction={ FocusZoneDirection.vertical }>
                <div className={ styles.pickerList }>
                    <div className={ styles.header }>
                        <h3 className={ styles.headerTitle }>
                            <span>{ this._strings.get("scheduleTeamPickerTitle") }</span>
                        </h3>
                        { this.renderHeaderSubtitle() }
                    </div>
                    <div className={ styles.itemList }>
                            <List
                                onPagesUpdated={this.onPagesUpdated}
                                className={ styles.teamList }
                                items={ teamInfoList }
                                onRenderCell={ this._onRenderContentCell }
                            />
                    </div>
                </div>
            </FocusZone>
        );
    }

    /**
     * Render the left side of a list item for schedule teams associated with MS Teams
     */
    private renderScheduleTeamListItemLeft(teamInfo: ITeamInfoEntity): JSX.Element {
        return (
            <span className={ styles.listItemName }>{ teamInfo.name }</span>
        );
    }

    /**
     * Render the left side of a list item for StaffHub teams
     */
    private renderStaffHubTeamListItemLeft(teamInfo: ITeamInfoEntity) {
        return (
            <>
                <div className={ styles.listItemName }>{ teamInfo.name }</div>
                <div className={ styles.listItemSubtitle }>{ this._strings.get("staffHubRetiredMessage") }</div>
            </>
        );
    }

    /**
     * Calculate the aria label for the view team schedule button
     */
    private getViewButtonAriaLabel(teamInfo: ITeamInfoEntity): string {
        return this._strings.get("viewTeamScheduleButtonAriaLabel").format(teamInfo.name);
    }

    /**
     * Render a list item for the schedule teams list
     */
    private _onRenderContentCell = (teamInfo: ITeamInfoEntity, index: number | undefined): JSX.Element => {
        const handleViewButtonClicked = () => { this.handleViewTeamButtonClicked(teamInfo); };

        // We prevent StaffHub teams from being opened from within the MS Teams application.
        // Users need to visit the old StaffHub Web site in order to view those teams.
        const isStaffHubTeam: boolean = TeamSetupPickerUtils.isStaffHubTeam(teamInfo);

        const reactItemKey = index.toString();

        return (
            <div className={ styles.teamListItem } key={ reactItemKey }>
                <div className={ styles.left } >
                    {
                        isStaffHubTeam
                        ?
                        this.renderStaffHubTeamListItemLeft(teamInfo)
                        :
                        this.renderScheduleTeamListItemLeft(teamInfo)
                    }
                </div>
                <div className={ styles.right } >
                    <PrimaryButton
                        disabled={ isStaffHubTeam }
                        onClick={ handleViewButtonClicked }
                        ariaLabel={ this.getViewButtonAriaLabel(teamInfo) } >
                        { this._strings.get("viewScheduleButtonLabel") }
                    </PrimaryButton>
                </div>
            </div>
        );
    }

    render() {
        return (
            <div className={ styles.content }>
                {
                    this.renderScheduleTeamsPickerListContents()
                }
            </div>
        );
    }
}