import CurrentTeamDataProvider from "sh-services/dataproviders/CurrentTeamDataProvider";
import RestClient from "sh-rest-client";
import { IShiftDatabase } from "sh-services/data/IShiftDatabase";
import { IShiftEntity } from "sh-models";
import { updateShifts, ShiftStore } from "sh-shift-store";
import { TeamStore } from "sh-team-store";
import { ShiftStoreTypes } from "sh-application/../StaffHubConstants";

/**
 * Shift Data Provider for the current team
 */
export class ShiftDataProvider extends CurrentTeamDataProvider<IShiftEntity> {

    protected shiftDatabase: IShiftDatabase;
    protected tenantId: string;
    protected shiftId: string;

    constructor(shiftDatabase: IShiftDatabase, tenantId: string, teamId: string, shiftId: string) {
        super(teamId);
        this.shiftDatabase = shiftDatabase;
        this.tenantId = tenantId;
        this.shiftId = shiftId;
    }

    /**
     * Return data if it's found in memory (otherwise return undefined)
     */
    async getDataFromMemoryForCurrentTeam() {
        return (TeamStore().team && TeamStore().team.id == this.teamId && ShiftStore().shifts ? ShiftStore().shifts.get(this.shiftId) : undefined);
    }

    /**
     * Return data if it's found in the database (otherwise return undefined)
     */
    async getDataFromDatabase() {
        return await this.shiftDatabase.getShift(this.teamId, this.shiftId);
    }

    /**
     * Make an HTTP request to fetch the data from the network
     */
    async getDataFromNetwork(): Promise<IShiftEntity> {
        return await RestClient.getShift(TeamStore().tenantId, this.teamId, this.shiftId);
    }

    /**
     * Set data in memory
     */
    async setDataInMemoryForCurrentTeam(data: IShiftEntity) {
        updateShifts([data], false /* isOptimisticUpdate */, ShiftStoreTypes.ShiftStore);
    }

    /**
     * Set data in the database
     */
    async setDataInDatabase(data: IShiftEntity) {
        if (data) {
            return await this.shiftDatabase.setShifts([data]);
        }
    }

    /**
     * Make a network call to update the data
     */
    async saveDataToNetwork(data: IShiftEntity): Promise<IShiftEntity> {
        throw new Error("ShiftDataProvider.saveDataToNetwork not implemented");
    }
}