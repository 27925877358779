import { action } from "satcheljs";

const setECSConfig = action(
    "SET_ECS_CONFIG",
    (
        config: any
    ) => ({
        config: config
    })
);

export default setECSConfig;
