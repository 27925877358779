import * as React from "react";
import classNames from "classnames/bind";

interface EditStatusIndicatorProps {
    isNewlyEdited?: boolean;
    className?: string;
    ariaLabel?: string;
    themeClass?: string;
    dataAutomationId?: string;
}

/**
 * EditStatusIndicator component
 * This renders an edit status indicator.
 */
const EditStatusIndicator = React.memo((props: EditStatusIndicatorProps) => {
    const { className, themeClass, dataAutomationId } = props;
    const indicatorClasses = classNames(
        className,
        themeClass
    );

    return (
        <span
            aria-hidden="true"
            className={ indicatorClasses }
            data-automation-id={ dataAutomationId }>*</span>
    );
});

export default EditStatusIndicator;