import StaffHubHttpErrorUtils from "sh-application/utility/StaffHubHttpErrorUtils";
import { DataProcessingHelpers } from "sh-services";
import {
    IBaseShiftEntity,
    IMemberEntity,
    IOpenShiftEntity,
    IShiftRequestEntity
    } from "sh-models";
import { IndividualOpenShiftRequestDetails } from "../store/schema/GroupedRequestDetailsStoreSchema";
import { MemberDataService, ShiftDataService } from "sh-services";
import { orchestrator } from "satcheljs";
import {
    setGroupedRequestInStore,
    setIsDataReadyInStore,
    setOpenShiftInStore,
    updateOpenShiftRequestDetailsInStore,
    loadDataForGroupedRequest
    } from "../store/store";
import { setShiftRequestRead } from "../../";
import { setRequestShiftInConflictStore } from "sh-requestconflict-store";
import { trace } from "owa-trace";

export const loadDataForGroupedRequestoOrchestrator = orchestrator(loadDataForGroupedRequest, async actionMessage => {
        const { groupedRequest } = actionMessage;
        try {
            // Load the open shift
            try {
                const shift: IBaseShiftEntity = await ShiftDataService.getOpenShiftById(groupedRequest.shiftId);
                const openShift = shift as IOpenShiftEntity;
                setOpenShiftInStore(openShift);
                // udpate request open shift in conflict request store
                setRequestShiftInConflictStore(openShift.id, openShift);
            } catch (error) {
                if (StaffHubHttpErrorUtils.isNotFoundError(error)) {
                    // if the open shift cannot be found, set the open shift to null but don't error
                    setOpenShiftInStore(null);
                } else {
                    throw(error);
                }
            }

            // Load the requests information. The sender/manager members should already be available in the team store
            let individualRequestDetails: IndividualOpenShiftRequestDetails[] = [];
            if (groupedRequest.shiftRequests) {

                const shiftRequests: IShiftRequestEntity[] = DataProcessingHelpers.getArrayFromMap<string, IShiftRequestEntity>(groupedRequest.shiftRequests);

                for (let i = 0; i < shiftRequests.length; i++) {
                    const shiftRequest: IShiftRequestEntity = shiftRequests[i];
                    if (shiftRequest) {

                        let senderMember: IMemberEntity = null;
                        if (shiftRequest.senderMemberId) {
                            senderMember = await MemberDataService.getMemberByIdFromCurrentTeam(shiftRequest.senderMemberId);
                        }

                        let managerMember: IMemberEntity = null;
                        if (shiftRequest.managerMemberId) {
                            managerMember = await MemberDataService.getMemberByIdFromCurrentTeam(shiftRequest.managerMemberId);
                        }

                        individualRequestDetails.push(
                            {
                                request: shiftRequest,
                                isSelected: false,
                                senderMember: senderMember,
                                managerMember: managerMember
                            }
                        );
                    }
                }
                updateOpenShiftRequestDetailsInStore(individualRequestDetails);
            }

            setGroupedRequestInStore(groupedRequest);
            setIsDataReadyInStore(true);
            setShiftRequestRead(groupedRequest);
        } catch (error) {
            trace.warn("loadDataForGroupedRequest: Error in getting details for openshift request");
            throw error;
        }
    }
);