import ImportScheduleDialogViewState from "./schema/ImportScheduleDialogViewState";
import { createStore } from "satcheljs";

const initialImportScheduleViewState: ImportScheduleDialogViewState = {
    job: null,
    jobResult: null,
    errorMessage: null,
    dialogErrorMessage: null,
    importScheduleInProgress: false,
    dialogOpen: false,
    hideTryImportAgainButton: true,
    reachedMaxRetries: false
};

const importScheduleDialogViewState = createStore("importScheduleDialogViewState", initialImportScheduleViewState);
export default importScheduleDialogViewState;
