import * as React from "react";
import ConflictIconButton from "./conflictDetails/ConflictIconButton";
import DateTimeFormatter, { DateTimeFormatType } from "sh-application/utility/DateTimeFormatter";
import DateUtils from "sh-application/utility/DateUtils";
import ShiftUtils from "sh-application/utility/ShiftUtils";
import StringsStore from "sh-strings/store";
import { appViewState } from "../../store/index";
import { ScheduleCellRenderSize } from "sh-application/../StaffHubConstants";
import { ShiftCellProps } from "./ShiftCellProps";
import { TimeOffReasonsStore } from "sh-timeoffreasons-store";
let styles = require("./Shift.scss");
const classNames = require("classnames/bind");

interface TimeOffShiftProps extends ShiftCellProps {
    /* true if this shift should include the date in the time off */
    showDate?: boolean;
}
/**
 * TimeOffShift
 */
export default class TimeOffShift extends React.Component<TimeOffShiftProps, {}> {
    private _ariaLabel = "";
    private static _timeOffReasonFallback = "";

    constructor(props: TimeOffShiftProps) {
        super(props);
        if (!TimeOffShift._timeOffReasonFallback) {
            TimeOffShift._timeOffReasonFallback = StringsStore().registeredStringModules.get("shifts").strings.get("timeOffReasonFallbackName");
        }
    }

    renderSecondSubtitle(): JSX.Element {
        const { shift } = this.props;

        if (this.isRenderCompactSize()) {
            return null;
        }
        let subtitle: string = "";
        if (this.shouldShowDateOnSeperateLine()) {
            // time range gets moved down because the date takes up the first subtitleDateUtils.
            subtitle = DateTimeFormatter.fastGetEventDateTimeRangeAsString(shift.startTime, shift.endTime);
        } else {
            subtitle = shift.notes;
        }
        if (subtitle) {
            return <div className={ styles.subtitle } aria-hidden={true}>{ subtitle }</div>;
        }

        return null;
    }

    renderFirstSubtitle(): JSX.Element {
        const { shift } = this.props;

        if (this.isRenderCompactSize()) {
            return null;
        }
        let subtitle: string = "";
        if (this.shouldShowDateOnSeperateLine()) {
            subtitle = DateTimeFormatter.getDateTimeAsString(shift.startTime, DateTimeFormatType.Date_DateMonthYearLong);
        } else {
            subtitle = DateTimeFormatter.fastGetEventDateTimeRangeAsString(shift.startTime, shift.endTime);
        }

        return <div className={ styles.subtitle } aria-hidden={true}>{ subtitle }</div>;
    }

    renderReason(): JSX.Element {
        const { shift, doShowConflictIcon } = this.props;
        const timeOffReason = TimeOffReasonsStore().timeOffReasons.get(shift.timeOffReasonId);
        let timeOffReasonName = timeOffReason ? timeOffReason.name : TimeOffShift._timeOffReasonFallback;

        if (timeOffReasonName && this.isRenderCompactSize()) {
            const isPrinting = appViewState().printViewState && appViewState().printViewState.isPrinting;
            // for extra small shifts we only render the first 3 characters of the title when printing
            // TODO: Need to find a way to prevent overlap with conflict icon on high zoom.
            timeOffReasonName = isPrinting ? timeOffReasonName.substring(0, 3) : timeOffReasonName;
        }
        return (
            <div className={ classNames(styles.title, { [styles.conflictIconButton]: doShowConflictIcon }) } aria-hidden={true}>{ timeOffReasonName }
                { doShowConflictIcon &&
                    <ConflictIconButton
                        onConfictIconBadgeClick={ this.props.onConfictIconBadgeClick } /> }
            </div> );
    }

    /**
     * Check if the date of the shift should show on it's own line
     */
    private shouldShowDateOnSeperateLine(): boolean {
        const { shift, showDate } = this.props;
        return showDate && DateUtils.isTimeRangeForTheSameDay(shift.startTime, shift.endTime);
    }

    private isRenderCompactSize(): boolean {
        return this.props.scheduleCellRenderSize === ScheduleCellRenderSize.Small;
    }

    render() {
        const { shift, isNewlyEdited } = this.props;

        if (!this._ariaLabel || isNewlyEdited) {
            this._ariaLabel = ShiftUtils.calculateShiftTitleAriaLabel(shift);
        }

        const unsharedChangesLabel = ShiftUtils.shiftHasUnsharedEdits(shift) ? ShiftUtils.getUnsharedChangesShiftIndicatorAriaLabel() : "";
        this._ariaLabel = unsharedChangesLabel + this._ariaLabel; // TODO - use either a mobx store or React component state to store ariaLabel

        const shiftStyles = classNames(styles.shift,
            { [styles.small]: this.isRenderCompactSize() });

        const contentStyles = classNames(styles.left,
                { [styles.published]: !ShiftUtils.shiftHasUnsharedEdits(shift) });
        return (
            <div className={ shiftStyles }>
                <div className={ contentStyles } aria-label={ this._ariaLabel }>
                    { this.renderReason() }
                    { this.renderFirstSubtitle() }
                    { this.renderSecondSubtitle() }
                </div>
            </div>
        );
    }
}