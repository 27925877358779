import { Icon, Stack, Text } from "@microsoft/shifts-ui-core";
import * as React from "react";

import { getClassNames } from "./PeopleCount.styles";
import { PeopleCountProps } from "./PeopleCountProps";

/**
 * Renders the people count (e.g., for a day or a schedule group).
 * @param props The properties.
 * @returns The people count component.
 */
export const PeopleCount: React.FC<PeopleCountProps> = (props) => {
    const classNames = getClassNames();

    return <Stack horizontal tokens={{ childrenGap: 5 }}>
        <Stack.Item align="center">
            <Icon className={classNames.icon} iconName="teams-people" />
        </Stack.Item>
        <Stack.Item>
            <Text className={classNames.text} variant="small">{props.count}</Text>
        </Stack.Item>
    </Stack>;
};
