import CurrentTeamDataProvider from "sh-services/dataproviders/CurrentTeamDataProvider";
import { ITeamDatabase } from "sh-services/data/ITeamDatabase";
import { ITimeOffReasonEntity } from "sh-models";
import { setTimeOffReasons } from "sh-timeoffreasons-store";

/**
 * Time Off Reasons Data Provider for the current team
 */
export class TimeOffReasonsDataProvider extends CurrentTeamDataProvider<ITimeOffReasonEntity[]> {

    protected teamDatabase: ITeamDatabase;
    protected tenantId: string;

    constructor(teamDatabase: ITeamDatabase, tenantId: string, teamId: string) {
        super(teamId);
        this.teamDatabase = teamDatabase;
        this.tenantId = tenantId;
    }

    /**
     * Return data if it's found in memory (otherwise return undefined)
     */
    async getDataFromMemoryForCurrentTeam(): Promise<ITimeOffReasonEntity[]> {
        // This data provider is only used for updating the DB/Memory with time off reasons, so this method is not implemented
        throw new Error("TimeOffReasonsDataProvider.getDataFromMemoryForCurrentTeam not implemented");
    }

    /**
     * Return data if it's found in the database (otherwise return undefined)
     */
    async getDataFromDatabase(): Promise<ITimeOffReasonEntity[]> {
        // This data provider is only used for updating the DB/Memory with time off reasons, so this method is not implemented
        throw new Error("TimeOffReasonsDataProvider.getDataFromDatabase not implemented");
    }

    /**
     * Make an HTTP request to fetch the data from the network
     */
    async getDataFromNetwork(): Promise<ITimeOffReasonEntity[]> {
        // This data provider is only used for updating the DB/Memory with time off reasons, so this method is not implemented
        throw new Error("TimeOffReasonsDataProvider.getDataFromNetwork not implemented");
    }

    /**
     * Set data in memory
     */
    async setDataInMemoryForCurrentTeam(data: ITimeOffReasonEntity[]) {
        setTimeOffReasons(data);
    }

    /**
     * Set data in the database
     */
    async setDataInDatabase(data: ITimeOffReasonEntity[]) {
        if (data) {
            return await this.teamDatabase.setTimeOffReasons(data);
        }
    }

    /**
     * Make a network call to update the data
     */
    async saveDataToNetwork(data: ITimeOffReasonEntity[]): Promise<ITimeOffReasonEntity[]> {
        // We can't support save here because this API needs the Added/Edited/Deleted elements to be supplied in separate lists
        throw new Error("TimeOffReasonsDataProvider.saveDataToNetwork not implemented");
    }
}