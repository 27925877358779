import * as React from "react";
import Spinner, { SpinnerSize } from "sh-application/components/common/Spinner";
import StringsStore from "sh-strings/store";
import teamSetupPickerStore from "../store/store";
import TeamSetupPickerUtils from "../TeamSetupPickerUtils";
import TimezonePicker from "../timezonepicker/TimezonePicker";
import { default as TimeZoneUtils } from "sh-application/utility/TimeZoneUtils";
import { fireAccessibilityAlert } from "sh-application/components/accessibilityAlert";
import { getDurationInMs } from "sh-application/utility/utility";
import { getGenericEventPropertiesObject, logPageView } from "sh-instrumentation";
import { gotoPrevSetupStep, setIsProvisionTeamInProgress } from "../store/store";
import { InstrumentationService } from "sh-services";
import { observer } from "mobx-react";
import { setFeatureFlag } from "sh-feature-flags";
import { setTeamSetupTimezone } from "../store/store";
import { SetupNavigation } from "../SetupNavigation";
import { TeamSetupPickerStepProps } from "../TeamSetupPickerStepProps";
import { UrlFactory } from "sh-application";

const styles = require("./TeamSetupTimezonePicker.scss");

/**
 * React component for the time zone picker step in the new team onboarding flow
 * In this setup step, the user can choose a time zone for the schedule team they are creating.
 * This step also initiates provisioning of the new schedule team after user confirmation.
 */
@observer
@logPageView("Setup.SelectTimeZone")
export default class TeamSetupTimezonePicker extends React.Component<TeamSetupPickerStepProps, {}> {
    private _teamSetupPickerStrings: Map<string, string>;
    private _teamSetupTimezonePickerStrings: Map<string, string>;
    private _didUserChangeSelection: boolean;

    constructor(props: TeamSetupPickerStepProps) {
        super(props);
        this._teamSetupPickerStrings = StringsStore().registeredStringModules.get("teamSetupPicker").strings;
        this._teamSetupTimezonePickerStrings = StringsStore().registeredStringModules.get("teamSetupTimezonePicker").strings;
        this._didUserChangeSelection = false;

        if (!teamSetupPickerStore.teamSetupInfo.timeZoneOlsonCode) {
            const defaultTimezoneOlsonCode: string = TimeZoneUtils.getBrowserOlsonCode();
            setTeamSetupTimezone(defaultTimezoneOlsonCode);
        }

        this.instrumentLoadScreen();
    }

    componentDidMount() {
        // Fire accessibility alert because the heading content doesn't get read via focus.
        // The team setup dialog is reused between steps and only its inner content is changed
        if (teamSetupPickerStore.teamSetupInfo && teamSetupPickerStore.teamSetupInfo.msTeamInfo) {
            const timezonePickerDialogAriaAlert: string = this._teamSetupTimezonePickerStrings.get("timezonePickerDialogAriaAlert").format(teamSetupPickerStore.teamSetupInfo.msTeamInfo.teamName);
            fireAccessibilityAlert(timezonePickerDialogAriaAlert);
        }
    }

    /**
     * Instrument load of this view
     */
    private instrumentLoadScreen() {
        InstrumentationService.logEvent(
            this.getInstrumentationEventName(),
            [getGenericEventPropertiesObject(InstrumentationService.properties.EventType, InstrumentationService.values.TimeZoneSetupViewed)]);
    }

    /**
     * Handler for Confirm button click
     */
    private handleConfirmClicked = () => {
        InstrumentationService.logEvent(
            this.getInstrumentationEventName(),
            [
                getGenericEventPropertiesObject(InstrumentationService.properties.EventType, InstrumentationService.values.ConfirmTimeZone),
                getGenericEventPropertiesObject(InstrumentationService.properties.ChangedDefault, !!this._didUserChangeSelection)
            ]);

        this.setupNewScheduleTeam();
    }

    /**
     * Handler for Back button click
     */
    private handleBackClicked = () => {
        gotoPrevSetupStep();
    }

    /**
     * Handler for when the user chooses a different time zone
     */
    private handleTimezoneSelected = (timezoneOlsonCode: string) => {
        setTeamSetupTimezone(timezoneOlsonCode);
        this._didUserChangeSelection = true;
    }

    /**
     * Provision a new schedule team
     */
    private async setupNewScheduleTeam() {
        let createdScheduleTeamId: string = null;
        setIsProvisionTeamInProgress(true /* isProvisioning */);

        let provisionDuration: number = 0;
        try {
            const startTimeStamp: number = InstrumentationService.getCurrentTimeStamp();
            fireAccessibilityAlert(this._teamSetupPickerStrings.get("createTeamStartingAriaMessage"));
            createdScheduleTeamId = await TeamSetupPickerUtils.provisionScheduleTeam(teamSetupPickerStore.teamSetupInfo.msTeamInfo, teamSetupPickerStore.teamSetupInfo.timeZoneOlsonCode);
            const endTimeStamp: number = InstrumentationService.getCurrentTimeStamp();

            if (createdScheduleTeamId) {
                provisionDuration = getDurationInMs(startTimeStamp, endTimeStamp);

                // Set newteam status so that the Tour code will run the appropriate handling for new teams
                setFeatureFlag("newteam", true);

                // TODO DCoh:
                // - Look into using React Router support for navigating. This will require some investigation since currently switching teams or starting
                // the new team creation flow involves clearing our stores, which currently results in a lot of crashes since certain components still attempt to access
                // that store data during the transitions.
                // - There's also an issue where routing from the Schedule view for one team to another team will not trigger the Tour, because the Tour check
                // occurs in Schedule's componentWillMount.
                // - Also, hook up the navigationHandler once Teams exposes it in their SDK so that forward/back navigating within Teams works properly.
                // - Note: Also verify navigating from the Team Settings page. For some reason using React Router navigation results in a security error.
                window.location.href = UrlFactory.getSchedulesUrl(createdScheduleTeamId);

                this.props.onClose();
            }
        } catch (error) {
            TeamSetupPickerUtils.handleStaffHubServiceError(error);
        } finally {
            // Stop the progress indicator only if the new team provisioning failed.
            // If it succeeded, continue to display the progress indicator so that there is no rendering flashing when switching to the new team.
            if (!createdScheduleTeamId) {
                setIsProvisionTeamInProgress(false /* isProvisioning */);
            }

            InstrumentationService.logEvent(
                this.getInstrumentationEventName(),
                [
                    getGenericEventPropertiesObject(InstrumentationService.properties.EventType, InstrumentationService.values.ScheduleTeamProvisioned),
                    getGenericEventPropertiesObject(InstrumentationService.properties.ProvisionScheduleTeamSuccess, !!createdScheduleTeamId),
                    getGenericEventPropertiesObject(InstrumentationService.properties.Duration, provisionDuration)
                ]);
            this.setFocusOnClose();
        }
    }

    private getInstrumentationEventName(): string {
        return TeamSetupPickerUtils.getInstrumentationEventNameForProvisionScheduleTeam(this.props.isOnboarding);
    }

    /**
     * Renders progress indicator
     */
    private renderProgressIndicator(): JSX.Element {
        return (
            <div className={ styles.progressIndicatorContainer }>
                <Spinner className={ styles.spinner } size={ SpinnerSize.small } label={ this._teamSetupPickerStrings.get("createTeamProgressIndicatorMessage") } />
            </div>
        );
    }

    /**
     * Handle the closing of the dialog. Return focus to team picker icon element.
     */
    private setFocusOnClose() {
        const teamPickerIconElement = document.getElementById("teamPickerIcon");
        if (teamPickerIconElement) {
            teamPickerIconElement.focus();
        }
    }

    /**
     * Returns true if a new schedule team is being provisioned
     */
    private isProvisionTeamInProgress(): boolean {
        return teamSetupPickerStore.isProvisionTeamInProgress;
    }

    /**
     * Render the time zone picker controls
     */
    private renderTimezonePickerUX(): JSX.Element {
        const teamName: string = (teamSetupPickerStore.teamSetupInfo && teamSetupPickerStore.teamSetupInfo.msTeamInfo) ?
            teamSetupPickerStore.teamSetupInfo.msTeamInfo.teamName : "";
        const timezonePickerTitle: string = this._teamSetupTimezonePickerStrings.get("timezonePickerTitleFormat").format(teamName);
        return (
            <div className={ styles.teamSetupTimezonePicker } role="region" aria-label={ timezonePickerTitle } >
                <TimezonePicker
                    initialTimezoneOlsonCode={ teamSetupPickerStore.teamSetupInfo.timeZoneOlsonCode }
                    teamName={ teamName }
                    autofocus={ true }
                    onTimezoneSelected={ this.handleTimezoneSelected } />
                <SetupNavigation
                    onOk={ this.handleConfirmClicked }
                    okButtonLabel={ this._teamSetupPickerStrings.get("confirmButtonLabel") }
                    onCancel={ this.handleBackClicked }
                    showStepIndicator={ true }
                    currentStepIndex={ teamSetupPickerStore.currentSetupStepIndex }
                    stepIndicatorSize={ teamSetupPickerStore.setupSequence && teamSetupPickerStore.setupSequence.length }
                    />
            </div>
        );
    }

    render() {
        return (
            this.isProvisionTeamInProgress()
            ?
            this.renderProgressIndicator()
            :
            this.renderTimezonePickerUX()
        );
    }
}