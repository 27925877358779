import MemberUtils from "../../../sh-application/utility/MemberUtils";
import setEntitiesInMap from "sh-stores/setEntitiesInMap";
import setTeamDetails from "../actions/setTeamDetails";
import TeamStore from "../store";
import { UserStore } from "sh-stores";
import { mutator } from "satcheljs";

/**
 * Reset the team store
 */
export default mutator(setTeamDetails, (actionMessage) => {
    const teamDetails = actionMessage.teamDetails;
    TeamStore().team = teamDetails.team;
    TeamStore().teamId = teamDetails.team.id;
    TeamStore().groupId = teamDetails.team.groupId;
    TeamStore().tenantId = teamDetails.team.tenantId;
    TeamStore().name = teamDetails.team.name;

    // clear and set members
    TeamStore().members.clear();
    setEntitiesInMap(teamDetails.members, TeamStore().members);
    TeamStore().me = MemberUtils.getMemberWithUserId(UserStore().user.id);

    // clear and set roles
    TeamStore().roles.clear();
    setEntitiesInMap(teamDetails.roles, TeamStore().roles);
    TeamStore().adminRoleId = teamDetails.adminRoleId;
});