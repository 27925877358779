import getImportScheduleJobStatus from "../actions/getImportScheduleJobStatus";
import setDialogErrorMessage from "../mutators/setDialogErrorMessage";
import setImportScheduleInProgress from "../mutators/setImportScheduleInProgress";
import setImportScheduleJob from "../mutators/setImportScheduleJob";
import setImportScheduleJobResult from "../mutators/setImportScheduleJobResult";
import setReachedMaxRetries from "../mutators/setReachedMaxRetries";
import StringsStore from "sh-strings/store";
import { getGenericEventPropertiesObject, InstrumentationEventPropertyInterface } from "sh-instrumentation";
import { IJobEntity, ImportScheduleJobResult, JobStatus } from "sh-models";
import { InstrumentationService, JobDataService, ECSConfigService, ECSConfigKey } from "sh-services";
import { orchestrator } from "satcheljs";
import { refreshSchedule } from "sh-application/components/schedules/lib";

export default orchestrator(getImportScheduleJobStatus, actionMessage => {
    const importScheduleStrings: Map<string, string> = StringsStore().registeredStringModules.get("importScheduleDialog").strings;
    const intervalDuration = ECSConfigService.getECSFeatureSetting(ECSConfigKey.ImportSchedulePollIntervalInMilliseconds);
    const maxRetryCount = ECSConfigService.getECSFeatureSetting(ECSConfigKey.ImportScheduleMaxRetry);
    const onMaxRetry = () => {
        setImportScheduleInProgress(false);
        // set to true so we can stop polling for job status and rely on sync notification
        setReachedMaxRetries(true);
        setDialogErrorMessage(importScheduleStrings.get("maxRetiresDialogSubtext")); // error message on dialog
        return;
    };

    const onJobResponse = (importScheduleJob: IJobEntity) => {
        setImportScheduleJob(importScheduleJob);
        if (importScheduleJob) {
            if (importScheduleJob.result) {
                setImportScheduleJobResult(ImportScheduleJobResult.fromJson(importScheduleJob.result));
                setDialogErrorMessage(importScheduleJob.result.message);
            }
            if ([JobStatus.Success, JobStatus.Failure].indexOf(importScheduleJob.status) !== -1) {
                let eventProperties: Array<InstrumentationEventPropertyInterface> = [];
                const isImportSuccess: boolean = (importScheduleJob.status === JobStatus.Success);
                // import schedule failed or succeed, So unblock a client for new job
                setImportScheduleInProgress(false);
                // setting to false allows to ignore sync notification because we got a job status
                setReachedMaxRetries(false);
                // Import Schedule Feature is processed by Worker role in service.
                // There is possibility that it will get error after inserting few rows of schedule.
                // So Schedule data needs to be reload in success as well as failure scenario.
                refreshSchedule();

                // log events for Import Schedule Completed and import success
                eventProperties.push(getGenericEventPropertiesObject(InstrumentationService.properties.EventType, InstrumentationService.values.ImportExcelCompleted));
                eventProperties.push(getGenericEventPropertiesObject(InstrumentationService.properties.ImportSuccess, isImportSuccess));
                InstrumentationService.logEvent(InstrumentationService.events.ImportExcel, eventProperties);
            }
        }
    };

    JobDataService.initPollingForJob(actionMessage.teamId, actionMessage.jobId, onJobResponse, onMaxRetry, intervalDuration, maxRetryCount);
});