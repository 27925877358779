import ITeamSettingDataService from "./ITeamSettingDataService";
import { DataService } from "./DataService";
import { ITeamSettingEntity } from "sh-models";
import { TeamSettingDataProvider } from "sh-services/dataproviders/TeamSettingDataProvider";

/**
 * Functions for retrieving and editing information related to instances of IBaseShiftEntity.
 * Over time, we should move all of the legacy actions that are currently unders sh-shift-store to here.
 */
class TeamSettingDataService extends DataService implements ITeamSettingDataService {

    /**
     * Get Team Setting for the team
     */
    public async getTeamSettings(teamId: string, teamSettingId: string): Promise<ITeamSettingEntity> {
        const teamSettingsDataProvider = new TeamSettingDataProvider(this.teamDatabase, this.tenantId, teamId, teamSettingId);
        return await this.getData(teamSettingsDataProvider);
    }

    /**
     * Saves a teamSetting in service and updates the local cache
     * @param teamSetting Team setting to save
     */
    public async saveTeamSetting(teamSetting: ITeamSettingEntity): Promise<ITeamSettingEntity> {
        const teamSettingDataProvider = new TeamSettingDataProvider(this.teamDatabase, this.tenantId, teamSetting.teamId, teamSetting.id);
        return await this.updateData(teamSetting, teamSettingDataProvider);
    }

    /**
     * Handles an update to team settings from sync
     * @param teamSetting The team setting that was updated
     */
    public async onUpdateTeamSetting(teamSetting: ITeamSettingEntity): Promise<ITeamSettingEntity> {
        if (teamSetting && teamSetting.id && teamSetting.teamId) {
            const teamSettingDataProvider = new TeamSettingDataProvider(this.teamDatabase, this.tenantId, teamSetting.teamId, teamSetting.id);
            return await this.updateDataInCache(teamSetting, teamSettingDataProvider);
        }
    }

    /**
     * Resets the sync state
     */
    public async resetSyncState() {
    }
}

const service: ITeamSettingDataService = new TeamSettingDataService();
export default service;