import * as moment from "moment";
import * as React from "react";
import AutomationUtil from "sh-application/utility/AutomationUtil";
import DateTimeFormatter, { DateTimeFormatType } from "sh-application/utility/DateTimeFormatter";
import StringsStore from "sh-strings/store";
import timezonePickerStore, { setLocalTime, setTimezoneOlsonCode } from "./store/store";
import { default as TimeZoneUtils } from "sh-application/utility/TimeZoneUtils";
import { Dropdown, IDropdown, IDropdownOption } from "@fluentui/react";
import { observer } from "mobx-react";
import { ECSConfigKey, ECSConfigService } from "sh-services";
import { getDropdownStyles } from "../../../component-styling";

const styles = require("./TimezonePicker.scss");

export interface TimezonePickerProps {
    initialTimezoneOlsonCode: string;
    teamName: string;
    autofocus?: boolean;
    onTimezoneSelected: (timezone: string) => void;
}

/**
 * React component that renders the timezone picker UX
 */
@observer
export default class TimezonePicker extends React.Component<TimezonePickerProps, any> {
    private _strings: Map<string, string>;

    private _localTimeUpdateTimer: any;
    private _refreshDuration = 30000;

    private _timezoneDropdownRef = React.createRef<IDropdown>();
    private _enableDialogTimezonePicker: boolean;

    constructor(props: TimezonePickerProps) {
        super(props);
        this._strings = StringsStore().registeredStringModules.get("teamSetupTimezonePicker").strings;
        this.initSettings();
    }

    componentDidMount() {
        if (this.props.autofocus && this._timezoneDropdownRef && this._timezoneDropdownRef.current) {
            // Set initial focus on the timezone dropdown
            this._timezoneDropdownRef.current.focus();
        }
    }

    componentWillUnmount() {
        // Clean up timer
        clearInterval(this._localTimeUpdateTimer);
    }

    /**
     * Update the local time display using the specified time zone
     */
    private updateLocalTime(timezoneOlsonCode: string) {
        const currentLocalTime: moment.Moment = moment().tz(timezoneOlsonCode);
        setLocalTime(DateTimeFormatter.getDateTimeAsString(currentLocalTime, DateTimeFormatType.Time));
    }

    /**
     * Initialize settings for the time picker UX
     */
    private initSettings() {
        // Apply the initial time zone
        setTimezoneOlsonCode(this.props.initialTimezoneOlsonCode);
        this.updateLocalTime(this.props.initialTimezoneOlsonCode);

        // Setup a timer to update the displayed local time
        this._localTimeUpdateTimer = setInterval(() => {
            this.updateLocalTime(timezonePickerStore.timezoneOlsonCode);
        }, this._refreshDuration);

        this._enableDialogTimezonePicker = ECSConfigService.isECSFeatureEnabled(ECSConfigKey.EnableDialogTimezonePicker);
    }

    /**
     * Handle when the timezone selection has changed
     */
    private handleTeamTimezoneChanged = (event: React.FormEvent<HTMLDivElement>, teamTimezone?: IDropdownOption, index?: number) => {
        // Updates team timezone based on the selected zone. The first region in the zone is selected by default.
        const zone = TimeZoneUtils.getZoneFromId(teamTimezone.key.toString());
        if (zone) {
            let timezoneOlsonCode: string = TimeZoneUtils.getFirstOrDefaultOlsonCodeFromZone(zone);
            this.handleTimezoneChanged(timezoneOlsonCode);
        }
    }

    /**
     * Handle when the location selection has changed
     */
    private handleTeamLocationChanged = (event: React.FormEvent<HTMLDivElement>, teamLocation?: IDropdownOption, index?: number) => {
        // Update team timezone based on the timezone region selected
        const timezoneOlsonCode: string = teamLocation.key.toString();
        this.handleTimezoneChanged(timezoneOlsonCode);
    }

    /**
     * Handle when the time zone is changed
     */
    private handleTimezoneChanged = (timezoneOlsonCode: string) => {
        setTimezoneOlsonCode(timezoneOlsonCode);
        this.updateLocalTime(timezoneOlsonCode);
        if (this.props.onTimezoneSelected) {
            this.props.onTimezoneSelected(timezoneOlsonCode);
        }
    }

    /**
     * Gets the list of closest regions (as input to a DropDown) for the current timezone in the view state.
     */
    private getRegions(timeZoneId: string): IDropdownOption[] {
        return TimeZoneUtils.getRegions(timeZoneId);
    }

    render() {
        const timeZoneOlsonCode: string = timezonePickerStore.timezoneOlsonCode;
        const timeZoneId: string = TimeZoneUtils.getTimeZoneIdFromOlsonCode(timeZoneOlsonCode);
        const regions: IDropdownOption[] = this.getRegions(timeZoneId);

        const timezonePickerTitle: string = this._strings.get("timezonePickerTitleFormat").format(this.props.teamName);

        return (
            <div className={ styles.content }>
                <div className={ styles.header }>
                    <h3 className={ styles.headerTitle }>{ timezonePickerTitle }</h3>
                    <div className={ styles.headerSubtitle }>{ this._strings.get("timezonePickerDescription") }</div>
                </div>
                <div className={ styles.teamSetupTimezone }>
                    <div>
                        <div className={ styles.localTimeLabel } >
                            { this._strings.get("localTimeLabel") }
                        </div>
                        <div className={ styles.localTime } >
                            { timezonePickerStore.localTime }
                        </div>
                    </div>
                    <div>
                        <div className={ styles.timezoneLabel } >
                            { this._strings.get("timezoneLabel") }
                        </div>
                        <Dropdown tabIndex={ 0 } className={ styles.teamTimeZoneDropDown }
                            componentRef={ this._timezoneDropdownRef }
                            data-automation-id={ AutomationUtil.getAutomationId("team", "QAIDTeamTimeZoneDropDown") }
                            ariaLabel={ this._strings.get("timezonePickerAria") }
                            selectedKey={ timeZoneId }
                            options={ TimeZoneUtils.getZonesAsDropdownOptions() }
                            onChange={ this.handleTeamTimezoneChanged }
                            styles={getDropdownStyles}
                        />
                    </div>
                    { this._enableDialogTimezonePicker &&
                        <div className={ styles.timezoneDialogueLabel }>{ this._strings.get("timezoneDialogueLabel") }</div>
                    }
                    <div>
                        <div className={ styles.closestCityLabel } >
                            { this._strings.get("closestCityLabel") }
                        </div>
                        <Dropdown className={ styles.teamLocationDropDown }
                            ariaLabel={ this._strings.get("closestCityPickerAria") }
                            selectedKey={ timeZoneOlsonCode }
                            options={ regions }
                            onChange={ this.handleTeamLocationChanged }
                            styles={getDropdownStyles}
                        />
                    </div>
                </div>
            </div>
        );
    }
}