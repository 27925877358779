import deleteShiftsInView from "../mutators/shifts/deleteShiftsInView";
import schedulesViewStateStore from "../store/store";
import ShiftUtils from "sh-application/utility/ShiftUtils";
import updateShiftsInView from "../mutators/shifts/updateShiftsInView";
import { DataProcessingHelpers } from "sh-services";
import { IBaseShiftEntity, IShiftEntity } from "sh-models";
import { Moment } from "moment";
import { orchestrator } from "satcheljs";
import { setupConflicts } from "../index";
import { shiftsModified } from "sh-shift-store";

/**
 * When updates to shifts are triggered via the UI or via sync notifications, this orchestrator will be triggered.
 * It will parse the updated shifts and pass those that fit into the current view to the updateShiftsInView() mutator, which
 * will update the map of shifts in the local view store
 */
export default orchestrator(shiftsModified, async actionMessage => {
    const viewStart: Moment = schedulesViewStateStore().viewStartDate;
    const viewEnd: Moment = schedulesViewStateStore().viewEndDate;

    const addedAndUpdatedShifts: IBaseShiftEntity[] = actionMessage.shiftsAdded.concat(actionMessage.shiftsUpdated);
    let shiftsToUpdate: IBaseShiftEntity[] = DataProcessingHelpers.getDisplayableShiftsInScheduleRange(addedAndUpdatedShifts, viewStart, viewEnd);
    let shiftsToDelete: IBaseShiftEntity[] = [];

    // push deleted shifts in range but not for display into a delete array
    // deleted shifts in range and for display get added to the update list
    if (actionMessage.shiftsDeleted) {
        for (let i = 0; i < actionMessage.shiftsDeleted.length; i++) {
            const shift: IShiftEntity = actionMessage.shiftsDeleted[i];
            const shiftInRange: boolean = ShiftUtils.shiftOverlapsStartsOrEndsBetween(shift, viewStart, viewEnd, false /*includeEdges*/);
            const shiftDisplayableForView: boolean = ShiftUtils.isShiftDisplayableForScheduleView(shift);
            if (shiftInRange) {
                if (shiftDisplayableForView) {
                    shiftsToUpdate.push(shift);
                } else {
                    shiftsToDelete.push(shift);
                }
            }
        }
    }

    // We need to handle the special case where updated shifts that were previously in the view have been moved out of the view. These shifts will not count as
    // displayable shifts in the schedule range, so we detect them by checking if their ids are present in the view cache
    if (actionMessage.shiftsUpdated) {
        for (let i = 0; i < actionMessage.shiftsUpdated.length; i++) {
            const shift: IShiftEntity = actionMessage.shiftsUpdated[i];
            if (schedulesViewStateStore().shiftsInView.has(shift.id) && !ShiftUtils.isShiftInList(shiftsToUpdate, shift)) {
                shiftsToUpdate.push(shift);
            }
        }
    }

    // If needed, launch actions to update and delete shifts in the local view store
    if (shiftsToUpdate.length) {
        updateShiftsInView(shiftsToUpdate);
    }
    if (shiftsToDelete.length) {
        deleteShiftsInView(shiftsToDelete);
    }

    setupConflicts(actionMessage.shiftsAdded, actionMessage.shiftsDeleted, actionMessage.shiftsUpdated, schedulesViewStateStore().viewStartDate, schedulesViewStateStore().viewEndDate, false, schedulesViewStateStore().isProgressiveRendering, schedulesViewStateStore().showShiftConflicts);
});