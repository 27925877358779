import Getter from './types/Getter';
import LazyImport from './LazyImport';
import LazyModule from './LazyModule';

export interface RawAction {
    (...args: any[]): void | Promise<void>;
}

// For usage of this API, see the owa-bundling README
export default class LazyAction<TAction extends RawAction, TModule> extends LazyImport<
    TAction,
    TModule
> {
    public importAndExecute: TAction;

    constructor(lazyModule: LazyModule<TModule>, getter: Getter<TAction, TModule>) {
        super(lazyModule, getter);

        let self = this;
        this.importAndExecute = <TAction>function importAndExecute() {
            let args = arguments;
            return self.import().then(concreteAction => {
                return concreteAction.apply(null, args);
            });
        };
    }
}
