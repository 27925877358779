import { ObservableMap } from "mobx";
import { mutatorAction } from "satcheljs";
import { TagStore } from "sh-tag-store";

/**
 * Resets people count for all tags.
 */
export const resetTagsPeopleCount = mutatorAction(
    "RESET_TAGS_PEOPLE_COUNT",
    (): void => {
        TagStore().tagIdToPeopleCount = new ObservableMap<string, number>();
    }
);
