import teamSetupPickerStore from "../store/store";
import { mutatorAction } from "satcheljs";
import { trace } from "owa-trace";

/**
 * Updates the current step in the team setup flow to the prev state
 */
export default mutatorAction('GOTO_PREV_SETUP_STEP', function gotoPrevSetupStep() {
    if (teamSetupPickerStore.setupSequence &&
        (teamSetupPickerStore.currentSetupStepIndex > 0)) {
        teamSetupPickerStore.currentSetupStepIndex--;
    } else {
        trace.warn("Team setup: error with gotoPrevSetupStep occurred");
    }
});
