import ClockStates, { ClockStatesType } from "./ClockState";
import ITimeClockDbEntity from "./ITimeClockDbEntity";
import ITimeClockEntity from "./ITimeClockEntity";
import ShiftrObjectModelBase from "../ShiftrObjectModelBase";
import TimeClockBreakEvent from "./TimeClockBreakEvent";
import {
    IShiftEntity,
    ModelUtils,
    ShiftEntity,
    TimeClockEvent
    } from "..";
import { ITimeClockEvent, ITimeClockEventDb } from "./IBaseTimeClockEvent";

const TimeClockIdPrefix = "TCK_";

export default class TimeClockEntity extends ShiftrObjectModelBase implements ITimeClockEntity {
    tenantId: string;
    teamId: string;
    userId: string;
    memberId: string;
    clockInEvent: ITimeClockEvent;
    clockOutEvent: ITimeClockEvent;
    breakEvents: Map<string, TimeClockBreakEvent>;
    relevantShifts?: Array<IShiftEntity>;
    clockState: ClockStatesType;
    isConfirmed?: boolean;
    notes?: string;

    constructor(
        id: string,
        eTag: string,
        tenantId: string,
        teamId: string,
        userId: string,
        memberId: string,
        clockInEvent: ITimeClockEvent | ITimeClockEventDb,
        clockOutEvent: ITimeClockEvent | ITimeClockEventDb,
        breakEvents: Map<string, TimeClockBreakEvent>,
        clockState: ClockStatesType,
        isConfirmed: boolean,
        notes: string,
        relevantShifts: Array<IShiftEntity>) {

        const timeClockId = id && id.length > 0 ? id : TimeClockEntity.generateNewTimeClockId();
        super(timeClockId, eTag);
        this.tenantId = tenantId;
        this.teamId = teamId;
        this.userId = userId;
        this.memberId = memberId;
        this.clockInEvent = TimeClockEvent.clone(clockInEvent);
        this.clockOutEvent = TimeClockEvent.clone(clockOutEvent);
        this.breakEvents = breakEvents;
        this.relevantShifts = relevantShifts ? relevantShifts.map((relevantShift: any) => ShiftEntity.clone(relevantShift)) : [];

        this.clockState = clockState;
        this.isConfirmed = isConfirmed;
        this.notes = notes;
    }

    /**
     * Generate new timeClockId used by StaffHub App
     * @returns {string} timeClockId in form of TCK_<uuid>
     */
    static generateNewTimeClockId(): string {
        return ModelUtils.generateUUIDWithPrefix(TimeClockIdPrefix);
    }

    /**
     * Function that creates a new empty time clock entity entity object
     * @param tenantId - tenant id for the time clock entry
     * @param teamId - team id for the time clock entry
     * @param memberId - member id for the time clock entry
     * @param userId - user id for the time clock entry
     */
    static createEmptyObject(tenantId: string, teamId: string, memberId: string, userId: string): TimeClockEntity {
        return TimeClockEntity.fromJson({
            id: this.generateNewTimeClockId(),
            eTag: null,
            tenantId,
            teamId,
            memberId,
            userId,
            clockInEvent: null,
            clockOutEvent: null,
            breakEvents: null,
            clockState: ClockStates.Unknown,
            isConfirmed: false,
            notes: null,
            relevantShifts: null
        });
    }

    static fromJson(jsonData: ITimeClockEntity | ITimeClockDbEntity): TimeClockEntity {
        if (!jsonData) {
            return null;
        }

        return new TimeClockEntity(
            jsonData.id,
            jsonData.eTag,
            jsonData.tenantId,
            jsonData.teamId,
            jsonData.userId,
            jsonData.memberId,
            jsonData.clockInEvent,
            jsonData.clockOutEvent,
            jsonData.breakEvents,
            jsonData.clockState,
            jsonData.isConfirmed,
            jsonData.notes,
            jsonData.relevantShifts
           );
    }

    /**
     * Function that creates ITimeClockDbEntity from ITimeClockEntity
     * @param timeClockEntry - time clock client entity
     */
    static toDbModel(timeClockEntry: ITimeClockEntity): ITimeClockDbEntity {
        if (!timeClockEntry) {
            return null;
        }

        // convert time clock events to DB models with number vs moment time
        const clockInEvent = timeClockEntry.clockInEvent ? TimeClockEvent.toDbModel(timeClockEntry.clockInEvent) : null;
        const clockOutEvent = timeClockEntry.clockOutEvent ? TimeClockEvent.toDbModel(timeClockEntry.clockOutEvent) : null;
        const dbBreakEvents = new Map<string, TimeClockBreakEvent>();
        if (timeClockEntry.breakEvents && timeClockEntry.breakEvents.size > 0) {
            timeClockEntry.breakEvents.forEach((breakEvent: TimeClockBreakEvent) => {
                dbBreakEvents.set(breakEvent.breakId, TimeClockBreakEvent.toDbModel(breakEvent));
            });
        }
        const breakEvents = dbBreakEvents;

        // creat db entity
        const timeClockDbEntity: ITimeClockDbEntity = {
            id: timeClockEntry.id,
            eTag: timeClockEntry.eTag,
            tenantId: timeClockEntry.tenantId,
            teamId: timeClockEntry.teamId,
            userId: timeClockEntry.userId,
            memberId: timeClockEntry.memberId,
            clockInEvent: clockInEvent,
            clockOutEvent: clockOutEvent,
            breakEvents: breakEvents,
            clockState: timeClockEntry.clockState,
            isConfirmed: timeClockEntry.isConfirmed,
            notes: timeClockEntry.notes,
            relevantShifts: timeClockEntry.relevantShifts
        };

        return timeClockDbEntity;
    }

 }