const Cookies = require("universal-cookie");

class CookieService {
    private _cookies: any;

    constructor() {
        this._cookies = new Cookies();
    }

    /**
     * Set a cookie with key
     * @param key
     * @param value
     * @param path the path at which the cookie is accessible, defaults to current path
     */
    public set<V = string>(key: string, value: V, path?: string): V {
        if (path) {
            this._cookies.set(key, JSON.stringify(value), { path: path });
        } else {
            this._cookies.set(key, JSON.stringify(value));
        }
        return value;
    }

    /**
     * Get a cookie by key
     * @param key
     * @returns value or null
     */
    public get<V = string>(key: string): V {
        let rawCookie = this._cookies.get(key);
        if (rawCookie) {
            return JSON.parse(rawCookie) as V;
        } else {
            return null;
        }
    }

    /**
     * Remove a cookie by key and optionally path
     * @param key
     * @param path
     */
    public remove(key: string, path?: string) {
        if (path) {
            this._cookies.remove(key, { path: path });
        } else {
            this._cookies.remove(key);
        }
    }
}

const service = new CookieService();
export default service as CookieService;