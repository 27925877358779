/**
 * The possible scope to store a value for.
 */
export enum UserStorageServiceScope {
    /**
     * The value is stored for the current tenant only.
     */
    CurrentTenant = "CurrentTenant",

    /**
     * The value is stored for the current user only.
     */
    CurrentUser = "CurrentUser",

    /**
     * The value is shared across all users from any tenants.
     */
    Shared = "Shared"
}