import TeamStore from "../store";
import { action } from "satcheljs/lib/legacy";
import { ITeamInfoEntity } from "sh-models";
import { teamUpdated } from "sh-team-store";

/**
 * Sets the users current team client model
 */
export let setTeamData = action("setTeamData")(
    function setTeamData(team: ITeamInfoEntity) {
        TeamStore().team = team;
        TeamStore().teamId = team && team.id;
        TeamStore().groupId = team && team.groupId;
        TeamStore().name = team && team.name;
        teamUpdated(team);
    });