import * as moment from "moment";
import BaseShiftEntity from "./BaseShiftEntity";
import ModelUtils from "../ModelUtils";
import TagUtils from "sh-application/utility/TagUtils";
import ThemeUtils from "sh-application/utility/ThemeUtils";
import {
    BreakEntity,
    IBaseSubshiftEntity,
    IBreakEntity,
    IOpenShiftEntity,
    IShiftDbEntity,
    IShiftEntity,
    IShiftServiceEntity,
    ISubshiftEntity,
    ShiftState,
    ShiftStates,
    ShiftType,
    ShiftTypes,
    SubshiftEntity
    } from "sh-models";
import { SHIFT_ID_PREFIX } from "sh-application/../StaffHubConstants";
import { Moment } from "moment";

/**
 * Base class that sets up common aspects for the Shift client and service models
 */
export default class ShiftEntity extends BaseShiftEntity implements IShiftEntity {

    sharedChanges?: IShiftEntity;

    constructor(
        id: string,
        eTag: string,
        tenantId: string,
        teamId: string,
        memberId: string,
        shiftType: ShiftType,
        startTime: Moment,
        endTime: Moment,
        state: ShiftState,
        title: string,
        notes: string,
        theme: string,
        tagIds: Array<string>,
        subshifts: Array<ISubshiftEntity>,
        breaks: Array<IBreakEntity>,
        shiftRequestId: string,
        timeOffReasonId: string,
        isPublished: boolean,
        sharedChanges: IShiftEntity) {

        let modelId = id || ShiftEntity.generateNewShiftId();
        super(modelId,
                eTag,
                tenantId,
                teamId,
                memberId,
                shiftType,
                startTime,
                endTime,
                state,
                title,
                notes,
                theme,
                tagIds,
                subshifts,
                breaks,
                shiftRequestId,
                timeOffReasonId,
                isPublished);

        this.sharedChanges = ShiftEntity.clone(sharedChanges);
        if (!theme) {
            this.theme = ThemeUtils.getDefaultShiftTheme(shiftType === ShiftTypes.Absence);
        }
        // workaround for default theme of Absence shifts
        if (shiftType === ShiftTypes.Absence && theme === "themeGray") {
            // if this is a time off shift with the default themeGray theme, update
            // the theme to the new themeTimeOffGray
            this.theme = "themeTimeOffGray";
        }
    }

    /**
     * Generate new shiftId used by StaffHub App
     * @returns {string} shiftId in form of SHFT_<uuid>
     */
    static generateNewShiftId(): string {
        return ModelUtils.generateUUIDWithPrefix(SHIFT_ID_PREFIX);
    }

    /**
     * Function that creates a new empty shift entity object
     * @param tenantId - tenant id for the shift
     * @param teamId - team id for the shift
     * @param memberId - member id for the shift
     * @param shiftType - shift type for the shift
     * @param theme - theme for the shift
     */
    static createEmptyObject(tenantId: string, teamId: string, memberId: string, shiftType: ShiftType = ShiftTypes.Working, theme: string = ThemeUtils.shiftDefaultTheme): ShiftEntity {
        return ShiftEntity.fromJson({
            id: null,
            eTag: null,
            tenantId,
            teamId,
            memberId,
            shiftType: shiftType,
            state: ShiftStates.Active,
            startTime: null,
            endTime: null,
            title: null,
            theme,
            tagIds: [],
            subshifts: [],
            breaks: [],
            isPublished: false,
            sharedChanges: null
        } as IShiftServiceEntity);
    }

    /**
     * Function that clones a ShiftEntity object
     * @param shift - shift to be clone
     */
    static clone(shift: IShiftEntity): IShiftEntity {
        if (!shift) {
            return null;
        }

        const breaks = shift.breaks ? shift.breaks.map(breakEntity => BreakEntity.clone(breakEntity)) : [];
        const subshifts = shift.subshifts ? shift.subshifts.map(subshiftEntity => SubshiftEntity.clone(subshiftEntity)) : [];

        return new ShiftEntity(
            shift.id,
            shift.eTag,
            shift.tenantId,
            shift.teamId,
            shift.memberId,
            shift.shiftType,
            shift.startTime && moment(shift.startTime),
            shift.endTime && moment(shift.endTime),
            shift.state,
            shift.title,
            shift.notes,
            shift.theme,
            shift.tagIds,
            subshifts,
            breaks,
            shift.shiftRequestId,
            shift.timeOffReasonId,
            shift.isPublished,
            ShiftEntity.clone(shift.sharedChanges));
    }

    /**
     * Function that converts json from IShiftServiceEntity or IShiftDbEntity to client ShiftEntity
     * @param jsonData - response from service or db
     */
    static fromJson(jsonData: IShiftServiceEntity | IShiftDbEntity): IShiftEntity {
        if (!jsonData) {
            return null;
        }

        return new ShiftEntity(
            jsonData.id,
            jsonData.eTag,
            jsonData.tenantId,
            jsonData.teamId,
            jsonData.memberId,
            jsonData.shiftType,
            jsonData.startTime && moment(jsonData.startTime),
            jsonData.endTime && moment(jsonData.endTime),
            jsonData.state,
            jsonData.title,
            jsonData.notes,
            jsonData.theme,
            jsonData.tagIds,
            SubshiftEntity.fromJsonList(jsonData.subshifts),
            BreakEntity.fromJsonList(jsonData.breaks),
            jsonData.shiftRequestId,
            jsonData.timeOffReasonId,
            jsonData.isPublished,
            ShiftEntity.fromJson(jsonData.sharedChanges));
    }

    /**
     * Function that creates service JSON from ShiftEntity
     * @param shiftEntity - shift entity that needs to be sent over the wire
     */
    static toJson(shift: IShiftEntity): IShiftServiceEntity {
        if (!shift) {
            return null;
        }

        const breaks = shift.breaks ? shift.breaks.map(breakEntity => BreakEntity.toJson(breakEntity)) : null;
        const subshifts = shift.subshifts ? shift.subshifts.map(subshiftEntity => SubshiftEntity.toJson(subshiftEntity)) : null;

        // Remove other group tag id from the list of tagIds
        let tagIds: string[] = [];
        if (shift.tagIds && shift.tagIds.length) {
            tagIds = shift.tagIds.filter(tagId => !TagUtils.isDefaultTag(tagId));
        }

        // skipping sharedChanges here as it is read-only
        const shiftJson: IShiftServiceEntity = {
            id: shift.id,
            eTag: shift.eTag,
            tenantId: shift.tenantId,
            teamId: shift.teamId,
            title: shift.title,
            shiftType: shift.shiftType,
            startTime: shift.startTime && shift.startTime.toISOString && shift.startTime.toISOString(), // Service uses an ISO string datetime format
            endTime: shift.endTime && shift.endTime.toISOString && shift.endTime.toISOString(),         // Service uses an ISO string datetime format
            memberId: shift.memberId,
            state: shift.state,
            notes: shift.notes,
            theme: shift.theme,
            breaks: breaks,
            subshifts: subshifts,
            timeOffReasonId: shift.timeOffReasonId,
            tagIds: tagIds,
            isPublished: shift.isPublished
        };

        return shiftJson;
    }

    /**
     * Function that creates IShiftDbEntity from IShiftEntity
     * @param shift - shift client entity
     */
    static toDbModel(shift: IShiftEntity): IShiftDbEntity {
        if (!shift) {
            return null;
        }

        const breaks = shift.breaks ? shift.breaks.map(breakEntity => BreakEntity.toDbModel(breakEntity)) : null;
        const subshifts: IBaseSubshiftEntity<number>[] = shift.subshifts ? shift.subshifts.map(subshiftEntity => SubshiftEntity.toDbModel(subshiftEntity)) : null;

        // skipping sharedChanges here as it is read-only
        const shiftDbEntity: IShiftDbEntity = {
            id: shift.id,
            eTag: shift.eTag,
            tenantId: shift.tenantId,
            teamId: shift.teamId,
            title: shift.title,
            shiftType: shift.shiftType,
            startTime: shift.startTime && shift.startTime.valueOf && shift.startTime.valueOf(),
            endTime: shift.endTime && shift.endTime.valueOf && shift.endTime.valueOf(),
            memberId: shift.memberId,
            state: shift.state,
            notes: shift.notes,
            theme: shift.theme,
            breaks: breaks,
            subshifts: subshifts,
            timeOffReasonId: shift.timeOffReasonId,
            tagIds: shift.tagIds,
            isPublished: shift.isPublished,
            sharedChanges: ShiftEntity.toDbModel(shift.sharedChanges)
        };

        return shiftDbEntity;
    }

    /**
     * Transform an open shift to an assigned shift
     * @param openShift open shift to transform
     * @param memberId memberId for the new assigned shift
     */
    public static fromOpenShift(openShift: IOpenShiftEntity, memberId: string): IShiftEntity {
        if (!openShift) {
            return null;
        }

        let json: IShiftEntity = openShift as IShiftEntity;
        json.id = ""; // clear out the existing id so that we generate one appropriate for ShiftEntity
        json.memberId = memberId;

        return ShiftEntity.clone(json);
    }
}