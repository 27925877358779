import teamSetupPickerStore from "../store/store";
import { mutatorAction } from "satcheljs";
import { TeamSetupPickerStepState } from "../store/schema/TeamSetupPickerStore";

export default mutatorAction('SET_TEAM_SETUP_PICKER_SEQUENCE', function setTeamSetupPickerSequence(steps: Array<TeamSetupPickerStepState>) {
    if (!steps || steps.length === 0) {
        teamSetupPickerStore.setupSequence = [];
        teamSetupPickerStore.currentSetupStepIndex = -1;
    } else {
        teamSetupPickerStore.setupSequence = [...steps];
        teamSetupPickerStore.currentSetupStepIndex = 0; // Start with the first step in sequence
    }
});
