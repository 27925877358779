import { action } from "satcheljs/lib/legacy";
import { IUserSettingsEntity, UserSettingsEntity } from "sh-models";
import { UserStore } from "sh-stores";

/**
 * userSettingsUpdated action - adds / updates user store
 * @param {IUserSettingsEntity} userSettings - updated user settings from sync service
 */
export let userSettingsUpdated = action("userSettingsUpdated")(
    function userSettingsUpdated(userSettings: IUserSettingsEntity) {
        const updatedUserSettings = UserSettingsEntity.fromJson(userSettings);
        UserStore().userSettings = updatedUserSettings;
    }
);