import { action } from "satcheljs";
import { ConflictType } from "sh-models";

const dismissConflict = action(
    "DISMISS_CONFLICT",
    (
        conflictId: string,
        conflictingShift: string,
        conflictType: ConflictType,
        memberId: string,
        otherConflictingShift?: string,
        conflictingAvailability?: string
    ) => ({
        conflictId: conflictId,
        conflictingShift: conflictingShift,
        conflictType: conflictType,
        memberId: memberId,
        otherConflictingShift: otherConflictingShift,
        conflictingAvailability: conflictingAvailability
    })
);

export default dismissConflict;