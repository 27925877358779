import * as React from "react";
import GroupedRequestDetails from "sh-application/components/shiftrequest/groupedrequestdetails/GroupedRequestDetails";
import KeyboardUtils from "../../../utility/KeyboardUtils";
import loadDataForGroupedRequest from "sh-application/components/shiftrequest/groupedrequestdetails/actions/loadDataForGroupedRequest";
import resetGroupedRequestDetailsStore from "sh-application/components/shiftrequest/groupedrequestdetails/mutatorActions/resetGroupedRequestDetailsStore";
import setCloseCallback from "sh-application/components/shiftrequest/groupedrequestdetails/mutatorActions/setCloseCallback";
import StringsStore from "sh-strings/store";
import { DirectionalHint, FocusTrapCallout, IconButton } from "@fluentui/react";
import { IGroupedOpenShiftRequestEntity, ShiftRequestType } from "sh-models";
import { setGlobalMessageFromException, StaffHubHttpError } from "sh-application";

const classNames = require("classnames/bind");
const styles = require("./OpenShiftRequestApprovalCallout.scss");

export interface OpenShiftRequestApprovalCalloutProps {
    /* Call back function when the callout is dismissed. Hide the callout in this function. */
    onDismissCallback: VoidFunction;

    groupedRequest: IGroupedOpenShiftRequestEntity;

    /* target element for the callout */
    targetElement: HTMLElement;

    /* optional - className to use for the callout*/
    className?: string;

    /* optional- positions the callout. Defaults to right center */
    directionalHint?: DirectionalHint;

    navigateToRequestPage?: (initialRequestId: string, shiftId: string, requestType: ShiftRequestType) => void;  // Navigate to Requests page
}

/**
 * OpenShiftRequestApprovalCallout - Callout that wraps a GroupedRequestDetails component
 */
export default class OpenShiftRequestApprovalCallout extends React.Component<OpenShiftRequestApprovalCalloutProps, any> {
    private _strings: Map<string, string>;

    constructor(props: OpenShiftRequestApprovalCalloutProps) {
        super(props);
        this._strings = StringsStore().registeredStringModules.get("openShiftRequestApprovalCallout").strings;
    }

    componentDidMount() {
        loadDataForGroupedRequest(this.props.groupedRequest);
        setCloseCallback(this.props.onDismissCallback);
    }

    componentWillUnmount() {
        resetGroupedRequestDetailsStore();
    }

    /**
     * Callback when there is an error on Approve/Deny for OpenShift Requests
     * @param exception HTTP exception from StaffHub service
     */
    private onOpenShiftRequestErrorCallback(exception: StaffHubHttpError) {
        setGlobalMessageFromException(exception, false /* AutoDismiss */);
    }

    /* Redirect to Requests call out along with the request id needed to scroll to this request */
    expandCallOut = () => {
        const { groupedRequest } = this.props;
        if (this.props.navigateToRequestPage && groupedRequest && groupedRequest.id) {
            this.props.navigateToRequestPage(groupedRequest.id, null /*shiftId*/, null /*shiftRequestTime*/);
        }
    }

    /* Call back for keypress to open call out  */
    onKeyDownOnexpandCallout = (e: React.KeyboardEvent<HTMLElement>) => {
        if (KeyboardUtils.isActionKeyPressed(e)) {
            this.expandCallOut();
        }
    }

    render() {
        const { onDismissCallback, targetElement, className, directionalHint } = this.props;

        return (
            <FocusTrapCallout
                role={ "dialog" }
                focusTrapProps={ {
                    isClickableOutsideFocusTrap: true,
                    elementToFocusOnDismiss: targetElement
                } }
                setInitialFocus={ true }
                preventDismissOnScroll={ true }
                onDismiss={ onDismissCallback }
                doNotLayer={ true }
                target={ targetElement }
                className={ classNames(styles.callout, className) }
                directionalHint={ directionalHint || DirectionalHint.rightCenter }>
                <>
                    <div className={ styles.expandIconContainer } >
                        <IconButton
                            iconProps={ { styles: { root: styles.panelCloseButtonIcon }, iconName: "teams-icons-expand" } }
                            onClick={ this.expandCallOut }
                            onKeyDown={ this.onKeyDownOnexpandCallout }
                            title={ this._strings.get("navigateToRequestsPageLabel") }
                            className={ styles.expandIcon } />
                    </div>
                    <GroupedRequestDetails isCalloutMode={ true } onApproveDenyCallback={ onDismissCallback } onErrorCallback={ this.onOpenShiftRequestErrorCallback }/>
                </>
            </FocusTrapCallout>
        );
    }
}