import ITeamsShiftPolicyEntity from "./ITeamShiftPolicyEntity";

/**
 *  Service returns a JSON object to determine if the user is eligible for Bing Map Location rendering.
 */
export default class ShiftPolicyEntity implements ITeamsShiftPolicyEntity {
    allowTimeClockLocationDetection: boolean;

    constructor(allowTimeClockLocationDetection: boolean) {
        this.allowTimeClockLocationDetection = allowTimeClockLocationDetection;
    }

    static fromJson(jsonData: ITeamsShiftPolicyEntity): ShiftPolicyEntity {
        if (!jsonData) {
            return null;
        }

        return new ShiftPolicyEntity(jsonData.allowTimeClockLocationDetection);
    }
}