export enum RequestTypes {
    SwapRequest = "SwapRequest",
    OfferShiftRequest = "OfferShiftRequest",
    TimeOffReason = "TimeOffReason"
}

type EligibilityFilteringEnabledEntities = "eligibilityFilteringEnabledEntities";

interface IExtraTeamSettings {
    EligibilityFilteringEnabledEntities: EligibilityFilteringEnabledEntities;
}

export const ExtraTeamSettingsKeys: IExtraTeamSettings = {
    EligibilityFilteringEnabledEntities: "eligibilityFilteringEnabledEntities"
};

export type ExtraTeamSettingsKey = EligibilityFilteringEnabledEntities;
export type ExtraTeamSetting = RequestTypes[];

export interface ExtraTeamSettings {
    eligibilityFilteringEnabledEntities: RequestTypes[];
}
