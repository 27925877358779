import * as React from "react";
import handleWorkforceIntegrationError from "../actions/handleWorkforceIntegrationError";
import showConfirmDialog from "sh-confirm-dialog/lib/components/ConfirmDialog";
import StringsStore from "sh-strings/store";
import { default as ExternalErrorDetails, ExternalErrorDetailsProps } from "../components/ExternalErrorDetails";
import { errorWatcherStore, setShowWorkforceIntError } from "../../";
import { orchestrator } from "satcheljs";
import { StaffHubError, StaffHubHttpError } from "sh-application";
import { ECSConfigKey, ECSConfigService, InstrumentationService } from "sh-services";
import { getGenericEventPropertiesObject } from "sh-instrumentation";

/**
 * Shows the dialog with the workforce integration (WFI) error
 * Will be shown only when there isn't another WFI error showing. This is to avoid the multiple messages that can show up when doing bulk operations
 */
export default orchestrator(handleWorkforceIntegrationError, actionMessage => {
    const staffHubHttpError: StaffHubHttpError = actionMessage.staffHubHttpError;

    if (ECSConfigService.isECSFeatureEnabled(ECSConfigKey.EnableWFIErrorHandling) && staffHubHttpError && staffHubHttpError.staffHubError) {

        const staffHubError: StaffHubError = staffHubHttpError.staffHubError;

        // mark this exception as being handled
        staffHubHttpError.isHandled = true;

        if (errorWatcherStore().showWorkforceIntError) {
            // Already showing a workforce integration error. This can happen for copy-paste (or bulk) scenarios
            // We need to show only the first error and ignore any additional pop-up error dialogs
            InstrumentationService.logEvent(
                InstrumentationService.events.WFIError,
                [getGenericEventPropertiesObject(InstrumentationService.properties.MessageShown, false)]);
        } else {
            setShowWorkforceIntError(true);

            InstrumentationService.logEvent(
                InstrumentationService.events.WFIError,
                [getGenericEventPropertiesObject(InstrumentationService.properties.MessageShown, true)]);

            const commonStrings = StringsStore().registeredStringModules.get("common").strings;

            // Create the aria alert string for the dialog on initial render
            // It should read out the 4 items shown on UI. title, message, external source name and external error message
            // Strings are localized on service, just need to concatenate them here and pass to the dialog
            let staffhubErrorAriaAlert: string = commonStrings.get("ariaLabelMultipleLines").format(staffHubError.title, staffHubError.message);
            staffhubErrorAriaAlert = commonStrings.get("ariaLabelMultipleLines").format(staffhubErrorAriaAlert, staffHubError.externalSource);
            staffhubErrorAriaAlert = commonStrings.get("ariaLabelMultipleLines").format(staffhubErrorAriaAlert, staffHubError.externalErrorMessage);

            showConfirmDialog(
                staffHubError.title /* title */,
                staffHubError.message /* subText */,
                {
                    hideCancelButton: true,
                    okText: commonStrings.get("gotIt"),
                    isBlocking: true,
                    initialAriaAlert: staffhubErrorAriaAlert,
                    bodyElement: React.createElement(ExternalErrorDetails, { staffHubError: staffHubError } as ExternalErrorDetailsProps),
                    containerClassName: "workforceIntegrationErrorContainer"
                },
                () => { setShowWorkforceIntError(false); } /* onOkCallback */,
                null    /* onCancelCallback */,
                false,  /* okButtonDisabled */
                true    /* showCloseButton */
            );
        }
    }
});
