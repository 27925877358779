import * as moment from "moment";
import StringsStore from "sh-strings/store";
import TimeClockBreakEvent from "sh-models/timeclock/TimeClockBreakEvent";
import TimeZoneUtils from "./TimeZoneUtils";
import { TeamStore } from "sh-stores/sh-team-store";

/**
 * Utility class for time clock
 */
export default class TimeClockUtils {

    /**
    * Returns a string of the elapsed time in the format 1h 3m
    * @param duration - the duration since elapsed since clock in or break event
    */
    public static getTimeElapsedString(duration: moment.Duration): string {
        if (duration) {
            const timeClockStrings = StringsStore().registeredStringModules.get("timeClockDialogs").strings;
            const hours = Math.floor(duration.asHours());
            const mins  = Math.floor(duration.asMinutes()) - hours * 60;

            const minStr = mins.toLocaleString(moment().locale(), { minimumIntegerDigits: 2, useGrouping: false });
            const hourStr = hours.toLocaleString(moment().locale(), { minimumIntegerDigits: 2, useGrouping: false });
            return timeClockStrings.get("timeElapsedString").format(hourStr, minStr);
        }
        return "";
    }

    /**
    * Returns the time elapsed since clock in or break event
    * @param clockInOrBreakEventTime - moment time when user clocked in or went on break
    * @param currentTime - the current time
    */
    public static getTimeElapsed(clockInOrBreakEventTime: moment.Moment, currentTime: moment.Moment): moment.Duration {
        let duration: moment.Duration;
        if (clockInOrBreakEventTime && currentTime) {
            // get the duration of time since the user clocked in or started break
           if (currentTime.isAfter(moment(clockInOrBreakEventTime))) {
                duration = moment.duration(currentTime.diff(clockInOrBreakEventTime));
           } else {
                // we assume current time will always be after clock in time
                // in the rare occasion that current time is before clock in time by some milliseconds, diff will be a negative value so we find the absolute value
                duration = moment.duration(Math.abs(currentTime.diff(clockInOrBreakEventTime)));
           }
            return duration;
        }
        return null;
    }

    /**
    * Returns the moment object of the latest break event time
    * @param breakEvents - all the break events for a time clock entry
    */
    public static getLatestBreakEvent(breakEvents: Map<string, TimeClockBreakEvent>):  moment.Moment {
        if (breakEvents) {
            let breakEventsCopy = new Map<string, TimeClockBreakEvent>(Object.entries(breakEvents));
            const breakTimeStampsArray: moment.Moment[] = [];
            breakEventsCopy.forEach((breakEvent) => {
                if (breakEvent && breakEvent.breakStart) {
                    breakTimeStampsArray.push(breakEvent.breakStart.time);
                }
            });
            const sortedTimeStamps = breakTimeStampsArray.sort((a: moment.Moment, b: moment.Moment) => a.valueOf() - b.valueOf());
            return sortedTimeStamps[sortedTimeStamps.length - 1];
        }
        return null;
    }

    /**
    * Returns compares two time clock entry and determines if the new time clock entry is the latest one
    * @param currentTimeClockEntryTime - the current time clock entry clock in event time
    * @param newTimeClockEntryTime - the new time clock entry clock in event time
    */
    public static isLatestTimeClockEntry(currentTimeClockEntryTime: moment.Moment, newTimeClockEntryTime: moment.Moment): boolean {
        if (!currentTimeClockEntryTime || !newTimeClockEntryTime) {
            return false;
        }
        return !(currentTimeClockEntryTime.isAfter(newTimeClockEntryTime));
    }

    public static isTimeClockEnabledForTeam(): boolean {
        return TeamStore().team && TeamStore().team.timeClockEnabled;
    }

    /**
    * Returns the current time that should be displayed in the dialog
    */
    public static getCurrentTime() {
        const userTimeZoneOlsonCode = TimeZoneUtils.getBrowserOlsonCode();
        return  !userTimeZoneOlsonCode || userTimeZoneOlsonCode.length < 0 ? moment() : moment().tz(userTimeZoneOlsonCode);
    }

}