import IShiftrObjectModelBase from "../IShiftrObjectModelBase";

export type TenantCustomFieldType = "Selection" | "Text" ;
export const TenantCustomFieldTypes = {
    Selection: "Selection" as TenantCustomFieldType,
    Text: "Text" as TenantCustomFieldType
};

export interface TenantCustomFieldSelectionOption {
    id: string;
    name: string;
}

/**
 * The iterface for a custom field in a tenant.
 * These are created by the tenant admin and are available publicly by the public tenant settings API.
 */
export default interface IITenantCustomFieldEntity extends IShiftrObjectModelBase {
    id: string;
    name: string;
    description: string;
    type: string;
    options: TenantCustomFieldSelectionOption[];
}