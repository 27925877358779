import * as moment from "moment";
import ShiftEditorViewStateSchema from "./schema/ShiftEditorViewStateSchema";
import { createStore } from "satcheljs";
import { EditorContentShiftSchema, EditorContentTimeoffSchema } from "..";
import { getNewShiftHintDatePickerStore } from "sh-application/components";
import { Moment } from "moment";
import { ObservableMap } from "mobx";

const initialEditorShiftViewState: EditorContentShiftSchema = {
    activitiesListOverflowing: false,
    activityInfoCalloutVisible: false,
    fullDayInfoCalloutVisible: false,
    isActivitiesDialogOpen: false,
    showShiftTimeUpdatedNotification: false,
    selectedTimeMap: new ObservableMap<string, Moment>(),
    timeValidityMap: new ObservableMap<string, boolean>()
};

const initialEditorTimeoffViewState: EditorContentTimeoffSchema = {
    selectedTimeMap: new ObservableMap<string, Moment>(),
    timeValidityMap: new ObservableMap<string, boolean>(),
    startDatePickerStore: getNewShiftHintDatePickerStore(),
    endDatePickerStore: getNewShiftHintDatePickerStore()
};

const initialShiftEditorViewState: ShiftEditorViewStateSchema = {
    isOpen: false,
    busyDeleting: false,
    busySaving: false,
    busySharing: false,
    selectedPanelType: null,
    scheduleCalendarType: null,
    memberId: "",
    accessibilityAlertMessage: "",
    shift: null,
    openShift: null,
    shiftGroupTagId: "",
    shiftStartDate: moment(),
    isEditShift: false,
    isOpenShift: false,
    timeoff: null,
    isEditTimeOff: false,
    timeOffStartTime: moment(),
    timeOffEndTime: moment(),
    timeOffStartDate: moment(),
    timeOffEndDate: moment(),
    isAllDay: false,
    shiftFormErrors: 0,
    openShiftFormErrors: 0,
    timeoffFormErrors: 0,
    disableSave: false,
    disableShare: false,
    is24HrShift: false,
    elementToFocusOnDismiss: null
};

const editorContentShiftViewState = createStore("editorContentShiftViewState", initialEditorShiftViewState);
const editorContentTimeoffViewState = createStore("editorContentTimeoffViewState", initialEditorTimeoffViewState);
const shiftEditorViewState = createStore("shiftEditorViewState", initialShiftEditorViewState);

export default shiftEditorViewState;
export { editorContentShiftViewState, editorContentTimeoffViewState };