/**
 * Instrumentation Attribute Properties ("Key")
 */
export const instrumentationProperties = {
    ScenarioType: "Action.ScenarioType",
    Scenario: "Action.Scenario",
    PanelType: "Panel.Type",
    GetStarted: "GetStarted",
    LoginStage: "LoginStage",
    LoginErrorType: "LoginErrorType",
    ValidEmail: "ValidEmail",
    LoginFlow: "LoginFlow",
    NumberOfTeams: "NumberOfTeams",
    NumArrowKeysPressed: "NumArrowKeysPressed",
    AutoSelected: "AutoSelected",
    TeamNameLength: "TeamNameLength",
    StartDayOfWeek: "StartDayOfWeek",
    Color: "Color",
    Start: "Start",
    End: "End",
    Unpaid: "Unpaid",
    Paid: "Paid",
    IsGroupByRoles: "IsGroupByRoles",
    IsGroupByTags: "IsGroupByTags",
    IsGroupByShifts: "IsGroupByShifts",
    NumCellsSelected: "NumCellsSelected",
    IsKeyboardShortcut: "IsKeyboardShortcut",
    BootstrapType: "BootstrapType",
    BootstrapCount: "BootstrapCount",
    PageURL: "PageURL",
    PageName: "PageName",
    LandingPageMode: "LandingPageMode",
    IsWarmBootup: "IsWarmBootup",
    TimeToLoadPage: "TimeToLoadPage",
    TimeToBootstrap: "TimeToBootstrap",
    TimeToInteractive: "TimeToInteractive",
    NetworkLatency: "NetworkLatency",
    ProgressiveRenderCount: "ProgressiveRenderCount",
    DidProgressiveRenderComplete: "DidProgressiveRenderComplete",
    ClientSideLoad: "ClientSideLoad",
    Duration: "Duration",
    DurationSincePageLoadStart: "DurationSincePageLoadStart",
    EntryPoint: "EntryPoint",
    NumberOfOpenShifts: "NumberOfOpenShifts",
    NumberOfAssignedShifts: "NumberOfAssignedShifts",
    CopiedWithNotes: "CopiedWithNotes",
    CopiedWithTimeOffs: "CopiedWithTimeOffs",
    CopiedWithActivities: "CopiedWithActivities",
    CopiedWithOpenShifts: "CopiedWithOpenShifts",
    ScheduleRepetition: "ScheduleRepetition",
    CopyScheduleNotificationRecieved: "CopyScheduleNotificationRecieved",
    CopyScheduleSyncShiftsWithoutNotification: "CopyScheduleSyncShiftsWithoutNotification",
    JobSubmittedSuccessfully: "JobSubmittedSuccessfully",
    CompletedSuccessfully: "CompletedSuccessfully",
    UndoSyncNotificationRecieved: "UndoSyncNotificationRecieved",
    CopyScheduleShortCopy: "CopyScheduleShortCopy",
    MultiPagePdf: "MultiPagePdf",
    TeamSettingKey: "TeamSettingKey",
    ContextMenuItem: "ContextMenuItem",
    ContextMenu: "ContextMenu",
    ConnectionState: "ConnectionState",
    ReconnectRetryCount: "ReconnectRetryCount",
    IsTimeOff: "IsTimeOff",
    IsAllDayEvent: "IsAllDayEvent",
    TimeOffReason: "TimeOffReason",
    DurationDays: "DurationDays",
    ShiftType: "ShiftType",
    NoteLength: "NoteLength",
    ViewType: "ViewType",
    CellType: "CellType",
    ActionType: "ActionType",
    IsFilteredItemSelected: "IsFilteredItemSelected",
    HasPhoneNumber: "HasPhoneNumber",
    HasEmailAddress: "HasEmailAddress",
    HasFirstName: "HasFirstName",
    HasLastName: "HasLastName",
    HasPermission: "HasPermission",
    IsAdmin: "IsAdmin",
    ReorderType: "ReorderType",
    ReorderExitType: "ReorderExitType",
    ContactInfo: "ContactInfo",
    SelectionType: "SelectionType",
    IsNewEvent: "IsNewEvent",
    WasApproved: "WasApproved",
    WasReasonProvided: "WasReasonProvided",
    DoIncludeDailyHours: "DoIncludeDailyHours",
    DoIncludeTimeOffs: "DoIncludeTimeOffs",
    DoIncludeSwaps: "DoIncludeSwaps",
    DoIncludeShiftNotes: "DoIncludeShiftNotes",
    IsDateRangeChanged: "IsDateRangeChanged",
    SubmitType: "SubmitType",
    IsWeekSchedulePattern: "IsWeekSchedulePattern",
    DidSchedulePatternChangeStartOfWeek: "DidSchedulePatternChangeStartOfWeek",
    IsFirstRun: "IsFirstRun",
    SchedulePatternLengthIncreased: "SchedulePatternLengthIncreased",
    AlreadyPublished: "AlreadyPublished",
    TeamNameChanged: "TeamNameChanged",
    LocationChanged: "LocationChanged",
    TeamStartingDayOfWeekChanged: "TeamStartingDayOfWeekChanged",
    TimeOffReasonsChanged: "TimeOffReasonsChanged",
    TeamTimeZoneChanged: "TeamTimeZoneChanged",
    TeamCountryCodeChanged: "TeamCountryCodeChanged",
    OpenShiftsChanged: "OpenShiftsChanged",
    OpenShiftsNewValue: "OpenShiftsNewValue",
    CopyShiftActivitiesWithShiftsEnabledChanged: "CopyShiftActivitiesWithShiftsEnabledChanged",
    SwapShiftsRequestsEnabledChanged: "SwapShiftsRequestsEnabledChanged",
    OfferShiftsRequestsEnabledChanged: "OfferShiftsRequestsEnabledChanged",
    TimeOffRequestsEnabledChanged: "TimeOffRequestsEnabledChanged",
    HideLastNameChanged: "HideLastNameChanged",
    HideLastNameNewValue: "HideLastNameNewValue",
    CurrentGroupsCount: "CurrentGroupsCount",
    CurrentView: "CurrentView",
    TypeOfConflict: "TypeOfConflict",
    RequestType: "RequestType",
    RequestURL: "RequestURL",
    RequestEndpoint: "RequestEndpoint",
    ResponseValueCount: "ResponseValueCount",
    Resource: "Resource",
    ClientSessionId: "ClientSessionId",
    ClientRequestId: "ClientRequestId",
    ResponseHttpStatusCode: "ResponseHttpStatusCode",
    ResponseErrorReason: "ResponseErrorReason",
    ResponseContentLengthBytes: "ResponseContentLengthBytes",
    AuthTokenFetchDurationMS: "AuthTokenFetchDurationMS",
    ServiceDurationMS: "ServiceDurationMS",
    NetworkTransitTimeMS: "NetworkTransitTimeMS",
    ClientDurationMS: "ClientDurationMS",
    DurationFromSessionStartMS: "DurationFromSessionStartMS",
    ServiceAPIRegionId: "ServiceAPIRegionId",
    // Action: "Action", // Do not use Action as it causes issues for userbi events that begin with 'Action.' https://domoreexp.visualstudio.com/Teamspace/_wiki/wikis/NorthStar%20Docs/5180/user-bi-schema
    FileType: "FileType",
    FileSizeKB: "FileSizeKB",
    GroupProvisioned: "GroupProvisioned",
    DriveProvisioned: "DriveProvisioned",
    ErrorPhone: "ErrorPhone",
    ErrorEmail: "ErrorEmail",
    ErrorFirstName: "ErrorFirstName",
    ErrorLastName: "ErrorLastName",
    ErrorRoleId: "ErrorRoleId",
    IsFromService: "IsFromService",
    IsAddMember: "IsAddMember",
    IsOkPressed: "IsOkPressed",
    TourStopName: "TourStopName",
    TourStopDuration: "TourStopDuration",
    TourStopAttempts: "TourStopAttempts",
    TourDuration: "TourDuration",
    TypeOfNavigation: "TypeOfNavigation",
    Enabled: "IsEnabled",
    NumberOfExtensibilityLinks: "NumberOfExtensibilityLinks",
    NumberOfPowerAppsLinks: "NumberOfPowerAppsLinks",
    NumberOfWebLinks: "NumberOfWebLinks",
    IsCombinedShiftLookup: "IsCombinedShiftLookup",
    TimeToRespondSeconds: "TimeToRespondSeconds",
    ContentGroupNotProvisioned: "Content_GroupNotProvisioned",
    ContentDriveNotProvisioned: "Content_DriveNotProvisioned",
    StatusSucceeded: "StatusSucceeded",
    TitleUpdated: "TitleUpdated",
    TeamTimeZone: "TeamTimeZone",
    DeviceTimeZone: "DeviceTimeZone",
    IsDefault: "IsDefault",
    ToggleOn: "ToggleOn",
    PreviewRetrieved: "PreviewRetrieved",
    BreakAdded: "BreakAdded",
    ActivityTitleLength: "ActivityTitleLength",
    ActivityCodeEntered: "ActivityCodeEntered",
    ActivityCodeLength: "ActivityCodeLength",
    ActivityColor: "ActivityColor",
    ActivityStartTime: "ActivityStartTime",
    ActivityEndTime: "ActivityEndTime",
    ActivityDuration: "ActivityDuration",
    ActivityPaid: "ActivityPaid",
    DeleteActionType: "DeleteActionType",
    ScreenName: "ScreenName",
    IsUserAdmin: "IsUserAdmin",
    StaffHubExceptionCategory: "StaffHubExceptionCategory",
    ErrorHandledAt: "ErrorHandledAt",
    ErrorMessage: "ErrorMessage",
    ErrorStacktrace: "ErrorStacktrace",
    IsLocationEnabled: "IsLocationEnabled",
    IsLocationToggled: "IsLocationToggled",
    UserState: "UserState",
    TeamType: "TeamType",
    IsRightClick: "IsRightClick",
    IsAddress: "IsAddress",
    IsAddressChanged: "IsAddressChanged",
    IsCrossLocationOpenShift: "IsCrossLocationOpenShift",
    IsTimeClockEnabled: "IsTimeClockEnabled",
    IsTurnOffClicked: "IsTurnOffClicked",
    IsChangeCanceled: "IsChangeCanceled",
    NumberOfActivitiesCopied: "NumberOfActivitiesCopied",
    NumberOfActivitiesPasted: "NumberOfActivitiesPasted",
    ExportedDaysInterval: "ExportedDaysInterval",
    ExportedDaysInPast: "ExportedDaysInPast",
    HasActivities: "HasActivities",
    ShiftColor: "ShiftColor",
    ActivitiesCopied: "ActivitiesCopied",
    NumActivities: "NumActivities",
    NumberOfWarnings: "NumberOfWarnings",
    NumberOfMembersAdded: "NumberOfMembersAdded",
    NumberOfGroupsAdded: "NumberOfGroupsAdded",
    NumberOfGroupsUpdated: "NumberOfGroupsUpdated",
    NumberOfShiftsAdded: "NumberOfShiftsAdded",
    ErrorType: "ErrorType",
    ErrorTitle: "ErrorTitle",
    ErrorPrimaryText: "ErrorPrimaryText",
    IsFieldEmpty: "IsFieldEmpty",
    CustomFieldChanged: "CustomFieldChanged",
    CustomFieldAction: "CustomFieldAction",
    Name: "Name",
    Description: "Description",
    Type: "Type",
    CurrentNumDropdownItems: "CurrentNumDropdownItems",
    DropdownItemAction: "DropdownItemAction",
    CurrentNumCustomFields: "CurrentNumCustomFields",
    HaveCustomFields: "HaveCustomFields",
    AreaClicked: "AreaClicked",
    CurrentScreen: "CurrentScreen",
    ArticleTitle: "ArticleTitle",
    HasScreenshot: "HasScreenshot",
    Error: "Error",
    ServiceAccountUpdated: "ServiceAccountUpdated",
    ADFSAuthEnabled: "ADFSAuthEnabled",
    ADFSUrlUpdated: "ADFSUrlUpdated",
    ChangeSource: "ChangeSource",
    ChangeType: "ChangeType",
    NumberShiftsChanged: "NumberShiftsChanged",
    FallbackUsed: "FallbackUsed",
    WaffleClicked: "WaffleClicked",
    Reason: "Reason",
    TypeOfShift: "TypeOfShift",
    EventType: "EventType",
    DaysBetweenRanges: "DaysBetweenRanges",
    DurationInDays: "DurationInDays",
    DialogAction: "DialogAction",
    FlowPicked: "FlowPicked",
    DidUserChangeClassification: "DidUserChangeClassification",
    Status: "Status",
    MembersReturned: "MembersReturned",
    MembersSelected: "MembersSelected",
    MembersImportAll: "MembersImportAll",
    WorkEmailsEntered: "WorkEmailsEntered",
    PhoneNumbersEntered: "PhoneNumbersEntered",
    IsOpenShift: "IsOpenShift",
    TeamSize: "TeamSize",
    ScheduleGridRowCount: "ScheduleGridRowCount",
    IsViewGrouped: "IsViewGrouped",
    ScheduleCalendarType: "ScheduleCalendarType",
    ScheduleOverViewType: "ScheduleOverViewType",
    ViewStartDate: "ViewStartDate",
    ViewEndDate: "ViewEndDate",
    NumOfUsersMissingContactInfo: "NumOfUsersMissingContactInfo",
    TimeToFirstPublish: "TimeToFirstPublish",
    NumOpenShiftsShared: "NumOpenShiftsShared",
    NumAssignedShiftsShared: "NumAssignedShiftsShared",
    NumWhitelistedSenders: "NumWhitelistedSenders",
    ScaleToOnePage: "ScaleToOnePage",
    ScaleToOnePageSize: "ScaleToOnePageSize",
    ScaleToOnePageStyle: "ScaleToOnePageStyle",
    PrintTeamSize: "TeamSize",
    PeopleOrShiftView: "PeopleOrShiftView",
    TimeTaken: "TimeTaken",
    ReferralSource: "ReferralSource",
    MenuItem: "MenuItem",
    IsRead: "IsRead",
    ViewedBy: "ViewedBy",
    RequestState: "RequestState",
    UploadSuccess: "UploadSuccess",
    ImportSuccess: "ImportSuccess",
    IsSuccess: "IsSuccess",
    ImportExcelSampleFileDownloaded: "ImportExcelSampleFileDownloaded",
    ImportExcelFailedWeb: "ImportExcelFailedWeb",
    ImportExcelErrorsDownloaded: "ImportExcelErrorsDownloaded",
    ImportExcelStartImportAgainClicked: "ImportExcelStartImportAgainClicked",
    ImportExcelTryAgainClicked: "ImportExcelTryAgainClicked",
    ImportExcelSyncNotificationReceived: "ImportExcelSyncNotificationReceived",
    ImportExcelBannerMessageReceived: "ImportExcelBannerMessageReceived",
    ImportExcelErrorsDownloadedFromBanner: "ImportExcelErrorsDownloadedFromBanner",
    ReorderMemberFailed: "ReorderMemberFailed",
    EnablingTimeClockFailed: "EnablingTimeClockFailed",
    SavingTimeClockSettingsFailed: "SavingTimeClockSettingsFailed",
    RenderDuration: "RenderDuration",
    RenderCount: "RenderCount",
    IsDataAvailable: "IsDataAvailable",
    TeamsCount: "TeamsCount",
    TeamSetupOption: "TeamSetupOption",
    TeamSetupAreCustomFieldsSetup: "TeamSetupAreCustomFieldsSetup",
    TeamSetupHasGroupClassification: "TeamSetupHasGroupClassification",
    TeamSetupCustomFieldsCount: "TeamSetupCustomFieldsCount",
    ActedOnBy: "ActedOnBy",
    ActionTaken: "ActionTaken",
    NumberSlot: "NumberSlot",
    HasDifferentDraftChanges: "HasDifferentDraftChanges",
    HoursFromOpenShiftRequested: "HoursFromOpenShiftRequested",
    ViewRequestClicked: "ViewRequestClicked",
    AllDaySelected: "AllDaySelected",
    DateRange: "DateRange",
    DaysFromRequestSent: "DaysFromRequestSent",
    RecipientHasSamePosition: "RecipientHasSamePosition",
    SenderShiftType: "SenderShiftType",
    RecipientShiftType: "RecipientShiftType",
    IsSameTimeOffTypeSwapped: "IsSameTimeOffTypeSwapped",
    NumSharedShifts: "NumSharedShifts",
    NumDraftShifts: "NumDraftShifts",
    NumTimeOffRequests: "NumTimeOffRequests",
    NumOpenShifts: "NumOpenShifts",
    NumShiftsWithActivities: "NumShiftsWithActivities",
    NumPrevEmpty: "NumPrevEmpty",
    NumPrevShared: "NumPrevShared",
    NumPrevDraft: "NumPrevDraft",
    NumAdd: "NumAdd",
    NumDelete: "NumDelete",
    NumUpdate: "NumUpdate",
    NumPrevHasActivities: "NumPrevHasActivities",
    NumPostHasActivities: "NumPostHasActivities",
    NumPrevTimeOffRequests: "NumPrevTimeOffRequests",
    NumPrevOpenShifts: "NumPrevOpenShifts",
    NumPostOpenShifts: "NumPostOpenShifts",
    TotalNumTeams: "TotalNumTeams",
    NumStaffHubTeams: "NumStaffHubTeams",
    NumProvisionableTeams: "NumProvisionableTeams",
    ChangedDefault: "ChangedDefault",
    ProvisionScheduleTeamSuccess: "ProvisionScheduleTeamSuccess",
    Location: "Location",
    MessageShown: "MessageShown",
    OwnShift: "OwnShift",
    NumberRequests: "NumberRequests",
    HoursFromOpenShiftApproved: "HoursFromOpenShiftApproved",
    IsManager: "IsManager",
    HasConflicts: "HasConflicts",
    FPS: "FPS",
    Availability: "Availability",
    RetryCount: "RetryCount",
    IsSilent: "IsSilent",
    NumPages: "NumPages",
    NumOfItems: "NumOfItems",
    NumOfItemsByPage: "NumOfItemsByPage",
    NumOfTagFilters: "NumOfTagFilters",
    NumOfMemberFilters: "NumOfMemberFilters",
    EmployeeViewType: "EmployeeViewType",
    FilterType: "FilterType",
    NumberOfGroupsSelected: "NumberOfGroupsSelected",
    NumberOfMembersSelected: "NumberOfMembersSelected",
    IsActiveShiftFilterSelected: "IsActiveShiftFilterSelected",
    IsTimeoffFilterSelected: "IsTimeoffFilterSelected",
    IsConflictFilterSelected: "IsConflictFilterSelected",
    NoCopyFiltersApplied: "NoCopyFiltersApplied",
    MultiTeamApiForMyShiftCalled: "MultiTeamApiForMyShiftCalled",
    TriggeredByFeature: "TriggeredByFeature",
    HasExternalErrorMessage: "HasExternalErrorMessage",
    Is24HrShiftEnabled: "Is24HrShiftEnabled",
    Is24HrShift: "Is24HrShift",
    IsSenderShift24h: "IsSenderShift24h",
    isRecipientShift24h: "isRecipientShift24h",
    TotalConflictCount: "TotalConflictCount",
    TotalShiftAvailabilityConflictCount: "TotalShiftAvailabilityConflictCount",
    TotalShiftTimeConflictCount: "TotalShiftTimeConflictCount",
    TotalOverlappingShiftConflictCount: "TotalOverlappingShiftConflictCount",
    DidConflictCountExceedLimit: "DidConflictCountExceedLimit",
    DidIgnoreConflictsOnLongShifts: "DidIgnoreConflictsOnLongShifts",
    IsDeepLink: "IsDeepLink",
    DeepLinkSource: "DeepLinkSource",
    CellColumnCount: "CellColumnCount",
    MemberShiftCellCount: "MemberShiftCellCount",
    ColumnsPerRowCount: "ColumnsPerRowCount",
    TemporalItemCount: "TemporalItemCount",
    ShiftTemporalItemCount: "ShiftTemporalItemCount",
    EmptyTemporalItemCount: "EmptyTemporalItemCount",
    RowCellCount: "RowCellCount",
    ConnectorAuthenticationOkClicked: "ConnectorAuthenticationOkClicked",
    ConnectorAuthenticationCancelClicked: "ConnectorAuthenticationCancelClicked",
    ConnectorAuthenticationSuccess: "ConnectorAuthenticationSuccess",
    IsClaimsChallenge: "IsClaimsChallenge",
    HasRequestableTimeOffReasonsEnabled: "HasRequestableTimeOffReasonsEnabled",
    TeamSettingsName: "TeamSettingsName",
    TeamSettingsValue: "TeamSettingsValue",
    TeamSettingsSuccess: "TeamSettingsSuccess",
    ResourceId: "ResourceId"
};