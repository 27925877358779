import groupedRequestDetailsStore from "../store/store";
import { IndividualOpenShiftRequestDetails } from "../store/schema/GroupedRequestDetailsStoreSchema";
import { mutatorAction } from "satcheljs";
import { ObservableMap } from "mobx";

export default mutatorAction('UPDATE_OPEN_SHIFT_REQUEST_DETAILS_IN_STORE', function updateOpenShiftRequestDetailsInStore(requestDetailsList: IndividualOpenShiftRequestDetails[]) {
    if (groupedRequestDetailsStore && requestDetailsList) {
        if (!groupedRequestDetailsStore.individualRequestDetails) {
            groupedRequestDetailsStore.individualRequestDetails = new ObservableMap<string, IndividualOpenShiftRequestDetails>();
        }

        requestDetailsList.forEach(requestDetails => {
            const shiftRequestId: string = requestDetails.request && requestDetails.request.id;
            if (shiftRequestId) {
                groupedRequestDetailsStore.individualRequestDetails.set(shiftRequestId, requestDetails);
            }
        });
    }
});