import DateUtils from "sh-application/utility/DateUtils";
import {
    addDismissedConflictEntity,
    addShiftIdToEntityIdMap,
    DismissedConflictsStore,
    setDismissConflicts
    } from "sh-conflict-store";
import { IConflictDismissEntity } from "sh-models";
import { InstrumentationService } from "sh-services";
import { orchestrator } from "satcheljs";
import { trace } from "owa-trace";

export default orchestrator(setDismissConflicts, actionMessage => {
    let marker = "setDismissedConflicts";
    marker = InstrumentationService.perfMarkerStart(marker);

    try {
        if (actionMessage.dismissedConflict) {
            actionMessage.dismissedConflict.forEach((conflictDismissEntity: IConflictDismissEntity) => {
                // check whether the dimiss entity is in the cache range of store
                const conflictInRange = DateUtils.overlapsStartsOrEndsBetween(conflictDismissEntity.startTime, conflictDismissEntity.endTime, DismissedConflictsStore().fetchStartTime, DismissedConflictsStore().fetchEndTime, true, true);

                if (conflictInRange) {
                    addDismissedConflictEntity(conflictDismissEntity);

                    if (conflictDismissEntity.entityOne) {
                        addShiftIdToEntityIdMap(conflictDismissEntity.id, conflictDismissEntity.entityOne);
                    }

                    if (conflictDismissEntity.entityTwo) {
                        addShiftIdToEntityIdMap(conflictDismissEntity.id, conflictDismissEntity.entityTwo);
                    }
                }
            });
        }
    } catch (error) {
        trace.warn("dimissedConflictEntities: Error in setting dismissed conflict entities in store");
    } finally {
        InstrumentationService.perfMarkerEnd(marker);
    }
});