import { mutatorAction } from "satcheljs";
import { ConnectorAuthDialogStoreSchema } from "../store/schema/ConnectorAuthDialogStoreSchema";

/**
 * Updates the visibility of the Connector Auth dialog
 * @param connectorAuthDialogStore -Connector Auth Dialog Store
 * @param isOpen - boolean determining the visibility of the dialog
 */
export const setIsConnectorAuthDialogOpen = mutatorAction(
    "SET_IS_CONNECTOR_AUTH_DIALOG_OPEN",
    (connectorAuthDialogStore: ConnectorAuthDialogStoreSchema, isOpen: boolean) => {
        connectorAuthDialogStore.isOpen = isOpen;
    }
);
