import { Moment } from "moment";
import { mutatorAction } from "satcheljs";
import { SchedulesViewStateStore } from "sh-application/components/schedules/lib/store/schema/SchedulesViewStateStore";
import DateUtils from '../../../../utility/DateUtils';

/**
 * Sets the current date range
 */
export default mutatorAction("SET_SCHEDULE_SELECTED_RANGE",
    function setScheduleSelectedRange(viewState: SchedulesViewStateStore, viewStartDate: Moment, viewEndDate: Moment) {
        if (viewState && viewStartDate && viewEndDate) {
            // During team switch timezones can change. Though the data is cleared and the timezone is initialized once team data is loaded,
            // it is possible that this function is called with a moment object containing an outdated timezone stored in a function-level local variable
            // within updateScheduleViewDimensions, which is optimistically launched before team data is finished loading for performance reasons.
            // Because of this we can't use a normal moment.clone() call, as that will preserve the old timezone. Instead we use our utility function cloneMomentToConfiguredTimezone()
            viewState.viewStartDate = DateUtils.cloneMomentToConfiguredTimezone(viewStartDate);
            viewState.viewEndDate = DateUtils.cloneMomentToConfiguredTimezone(viewEndDate);
        }
    }
);