type IndexType = { index: number };

/**
 * Generic index based comparator for types that have index property in them.
 * Index values are defaulted to -1 if no index is present
 * @param a object to compare
 * @param b object to compare
 * @return number - negative if the first object is smaller, 0 if both objects are equal and positive if the second object is larger
 */
export default function (a: IndexType, b: IndexType): number {
    const indexA = a && typeof a.index === "number" ? a.index : -1;
    const indexB = b && typeof b.index === "number" ? b.index : -1;

    return indexA - indexB;
}
