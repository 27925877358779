import RestClient from "sh-rest-client";
import UniqueShiftStore from "../store";
import { action } from "satcheljs/lib/legacy";
import { IUniqueShiftEntity } from "sh-models";
import { setUniqueShifts } from "./setUniqueShifts";
import { InstrumentationService } from "sh-services";

/**
 * getUniqueShifts action - gets shifts
 *
 * @param {string} tenantId
 * @param {string} teamId
 * @param {count} number (optional)
 * @returns {Promise}
 */
export let getUniqueShifts = action("getUniqueShifts")(
    async function getUniqueShifts(tenantId: string, teamId: string, count?: number) {
        let marker = "getUniqueShifts";
        marker = InstrumentationService.perfMarkerStart(marker);
        try {
            // these don't need to update mid run.  If there are any already fetched, resolve with the ones in store
            if (UniqueShiftStore().hasFetchedUniqueShifts.get()) {
                return UniqueShiftStore().uniqueShifts;
            } else {
                const uniqueShifts: Array<IUniqueShiftEntity> = await RestClient.getUniqueShifts(tenantId, teamId);
                // update unique shifts
                setUniqueShifts(uniqueShifts);
                return uniqueShifts;
            }
        } finally {
            InstrumentationService.perfMarkerEnd(marker);
        }
    });