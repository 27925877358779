import { mutatorAction } from "satcheljs";
import { SchedulesViewStateStore } from "sh-application/components/schedules/lib/store/schema/SchedulesViewStateStore";
import { UserStorageService } from "sh-services";
import { UserStorageKeys } from "sh-models";

/**
 * Toggle the grouped overview type of the Schedules page and store the updated value in the UserStorageService
 */
export default mutatorAction("TOGGLE_GROUPED_VIEW",
    function toggleGroupedView(viewState: SchedulesViewStateStore) {
        viewState.isViewGrouped = !viewState.isViewGrouped;
        UserStorageService.setItem<boolean>(UserStorageKeys.ScheduleGrouped, viewState.isViewGrouped);
    }
);