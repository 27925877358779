import { DataProvider } from "sh-services/dataproviders/DataProvider";
import { ILocalClientData } from "sh-models";
import { IUserDatabase } from "sh-services/data/IUserDatabase";

/**
 * Local Client Data Provider
 */
export class LocalClientDataProvider extends DataProvider<ILocalClientData> {

    protected userDatabase: IUserDatabase;
    protected tenantId: string;
    protected userId: string;
    // An in memory cache of LocalClientData
    private static localClientDataInMemoryCache: ILocalClientData;

    constructor(userDatabase: IUserDatabase, tenantId: string, userId: string) {
        super();
        this.userDatabase = userDatabase;
        this.tenantId = tenantId;
        this.userId = userId;
    }

    /**
     * Return data if it's found in memory (otherwise return undefined)
     */
    async getDataFromMemory() {
        return LocalClientDataProvider.localClientDataInMemoryCache;
    }

    /**
     * Return data if it's found in the database (otherwise return undefined)
     */
    async getDataFromDatabase() {
        return this.userDatabase.getLocalClientData(this.userId);
    }

    /**
     * Make an HTTP request to fetch the data from the network
     */
    async getDataFromNetwork(): Promise<ILocalClientData> {
        // Since this is local data only, we assume
        // this will only be called for a cold boot when data is not in memory or the DB
        return {
            userId: this.userId,
            isWarmBootup: false
        };
    }

    /**
     * Set data in memory
     */
    async setDataInMemory(data: ILocalClientData) {
        LocalClientDataProvider.localClientDataInMemoryCache = data;
    }

    /**
     * Set data in the database
     */
    async setDataInDatabase(data: ILocalClientData) {
        return await this.userDatabase.setLocalClientData(data);
    }

    /**
     * Make a network call to update the data
     */
    async saveDataToNetwork(data: ILocalClientData): Promise<ILocalClientData> {
        // This is local data only
        throw new Error("LocalClientDataProvider.saveDataToNetwork not implemented");
    }

    /**
     * Whether to skip refreshing data from network if it was only in the database and not in memory
     * This method gets called when we don't find the data in memory, and find something in the database.
     * Because we can't always trust that the data from the database was in-sync and not missing changes from the service,
     * this method allows the provider to inspect the state of data from the db and
     * determine if we need to make a network call to refresh this data asynchronously.
     */
    async skipRefreshFromNetworkIfNotInMemory(data: ILocalClientData): Promise<boolean> {
        // Don't refresh if this data is found in the db
        return true;
    }
}