import {
    addShiftAvailabilityConflict,
    ConflictStore,
    createShiftAvailabilityConflict,
    incrementConflictCount
    } from "sh-conflict-store";
import { IAvailabilityConflictEntity, IAvailabilityEntity, IShiftEntity, ConflictType } from "sh-models";
import { orchestrator } from "satcheljs";
import { setRequestAvailabilityConflictInStore } from "sh-requestconflict-store";

export default orchestrator(createShiftAvailabilityConflict, actionMessage => {
    const shift: IShiftEntity = actionMessage.shift;
    const conflictingAvailability: IAvailabilityEntity = actionMessage.conflictingAvailability;
    const isRequestShift: boolean = actionMessage.isRequestShift;

    if (isRequestShift) {
        // if conflicts are calculated for request Shift are are temporary to convey to admin about future conflicts, hence they wont be store in ConflictStore
        // They will be be updated in memory conflictRequestStore
        setRequestAvailabilityConflictInStore(shift, conflictingAvailability, actionMessage.memberId);
    } else {
        let alreadyAdded: boolean = false;

        // in a crossover shift scenario a conflict entity is already added for the shift, verify we are not adding twice
        const conflictData = ConflictStore().shiftIdToConflictsMap.get(shift.id);

        // TODO: argahttu, optimize lookup, by changing the structure of the
        // conflictStore shiftsByConflicts to ObservableMap<string, Map<string, IBaseConflictEntity>>()
        if (conflictData && conflictData.length > 0) {
            conflictData.forEach((conflict: IAvailabilityConflictEntity) => {
                if (conflict.availabilityId === conflictingAvailability.id) {
                    // already exists
                    alreadyAdded = true;
                }
            });
        }

        if (!alreadyAdded) {
            // add conflict to conflict store
            addShiftAvailabilityConflict(shift, conflictingAvailability);
            // increment conflict count
            incrementConflictCount(shift.memberId, ConflictType.ShiftAvailabilityConflict);
        }
    }
});