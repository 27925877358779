import * as microsoftTeams from "@microsoft/teams-js";
import * as React from "react";
import Spinner, { SpinnerSize } from "sh-application/components/common/Spinner";
import StringsStore from "sh-strings/store";
import TeamSetupPickerUtils from "../TeamSetupPickerUtils";
import { fireAccessibilityAlert } from "sh-application/components/accessibilityAlert";
import { IButton, Image, PrimaryButton } from "@fluentui/react";
import { MSTeamPickerPaneStore } from "./store/schema/MSTeamPickerPaneStore";
import { observer } from "mobx-react";
import { setShowStepIndicator } from "../store/store";

const styles = require("./MSTeamPickerPane.scss");
const emptyMessageImage = require("sh-assets/images/optimized-svg/Shifts_EmptyState_desktop_NoSchedules.svg");

export interface MSTeamPickerPaneProps {
    instrumentationEventName: string;
    onTeamSelected: (msTeamInfo: microsoftTeams.TeamInformation) => void;
    store: MSTeamPickerPaneStore;
    loadInitialData: () => void;
    autofocus?: boolean;
}

/**
 * React component that renders the MS team picker UX.
 * In this step, the user chooses an MS Team to create a schedule team for.
 */
@observer
export default class MSTeamPickerPane extends React.Component<MSTeamPickerPaneProps, any> {
    private _strings: Map<string, string>;

    private _firstSelectableItemRef = React.createRef<IButton>();  // ref for the first selectable team item. used for setting initial focus.

    constructor(props: MSTeamPickerPaneProps) {
        super(props);

        this._strings = StringsStore().registeredStringModules.get("msTeamPicker").strings;
    }

    async componentDidMount() {
        await this.props.loadInitialData();

        // Fire accessibility alert because the heading content doesn't get read via focus.
        // The team setup dialog is reused between steps and only its inner content is changed
        fireAccessibilityAlert(this._strings.get("msTeamPickerDialogAriaAlert"));

        if (this.props.autofocus && this._firstSelectableItemRef && this._firstSelectableItemRef.current) {
            // Set initial focus on the first selectable team item
            this._firstSelectableItemRef.current.focus();
        }
    }

    /**
     * Returns true if the teams data is currently being fetched
     */
    private isTeamsDataLoading(): boolean {
        return this.props.store.isDataLoading;
    }

    /**
     * Renders loading progress state
     */
    private renderLoadingProgress(): JSX.Element {
        return (
            <div className={ styles.progressIndicatorContainer }>
                <Spinner className={ styles.spinner } size={ SpinnerSize.small } label={ this._strings.get("loadingTeamsMessage") } />
            </div>
        );
    }

    /**
     * Render empty state when no teams are available to be listed
     */
    private renderEmptyTeamListState(): JSX.Element {
        // Display empty state message
        return (
            <div className={ styles.emptyStateContainer } aria-live="polite">
                <Image alt="" className={ styles.messageImage } src={ emptyMessageImage } />
                <div className={ styles.title }>{ this._strings.get("noTeamsToCreateScheduleTitle") }</div>
                <div className={ styles.subtitle }>{ this._strings.get("noTeamsToCreateScheduleSubtitle") }</div>
            </div>
        );
    }

    /**
     * Handler for when a View team button is clicked
     */
    private handleViewTeamButtonClicked(msTeamInfo: microsoftTeams.TeamInformation) {
        if (this.props.onTeamSelected) {
            this.props.onTeamSelected(msTeamInfo);
        }
    }

    /**
     * Render the MS teams picker list contents
     */
    private renderMSTeamsPickerListContents(): JSX.Element {
        const teamInfoList: microsoftTeams.TeamInformation[] = this.props.store.msTeamInfos;
        let hasSchedulesToCreate = false;
        const hasTeams = teamInfoList && teamInfoList.length > 0;
        if (hasTeams) {
            for (let i = 0; i < teamInfoList.length; i++) {
                // the user must be admin for at least 1 team to create a schedule
                if (TeamSetupPickerUtils.isUserTeamAdmin(teamInfoList[i])) {
                    hasSchedulesToCreate = true;
                    // show the step indicator
                    setShowStepIndicator(true);
                    break;
                }
            }
        }

        return (
            this.isTeamsDataLoading()
            ?
            this.renderLoadingProgress()
            :
            (
                hasTeams && hasSchedulesToCreate
                ?
                this.renderMSTeamsPickerList(teamInfoList)
                :
                this.renderEmptyTeamListState()
            )
        );
    }

    /**
     * Render the MS teams picker list UX
     */
    private renderMSTeamsPickerList(teamInfoList: microsoftTeams.TeamInformation[]): JSX.Element {
        return (
            <div className={ styles.pickerList }>
                <div className={ styles.header }>
                    <h3 className={ styles.headerTitle }>{ this._strings.get("msTeamPickerTitle") }</h3>
                    <div className={ styles.headerSubtitle }>{ this._strings.get("msTeamPickerDescription") }</div>
                </div>
                <div className={ styles.itemList } >
                    { this.renderMSTeamsListItems(teamInfoList) }
                </div>
            </div>
        );
    }

    /**
     * Render the list items for the schedule teams list
     */
    private renderMSTeamsListItems(teamInfoList: microsoftTeams.TeamInformation[]): JSX.Element {
        let teamInfoRenderItems: JSX.Element[] = [];
        if (teamInfoList) {
            let foundFirstSelectableItem: boolean = false;
            for (let i = 0; i < teamInfoList.length; i++) {
                if (teamInfoList[i]) {
                    const isDisabled: boolean = !TeamSetupPickerUtils.isUserTeamAdmin(teamInfoList[i]);
                    let isFirstSelectableItem: boolean = false;
                    if (!isDisabled && !foundFirstSelectableItem) {
                        isFirstSelectableItem = true;
                        foundFirstSelectableItem = true;
                    }
                    teamInfoRenderItems.push(this.renderMSTeamListItem(teamInfoList[i], i.toString(), isDisabled, isFirstSelectableItem));
                }
            }
        }

        // TODO (DCoh): Investigate whether we can use a List component instead of creating a list from scratch,
        // which may give us more consistent support for things like accessibility, focus handling, etc.
        return (
            <ul className={ styles.teamList }>
                { teamInfoRenderItems }
            </ul>
        );
    }

    /**
     * Render the left side of a list item for MS teams
     */
    private renderMSTeamListItemLeft(teamInfo: microsoftTeams.TeamInformation): JSX.Element {
        return (
            <span className={ styles.listItemName }>{ teamInfo.teamName }</span>
        );
    }

    /**
     * Calculate the aria label for the create team schedule button
     */
    private getCreateButtonAriaLabel(teamInfo: microsoftTeams.TeamInformation): string {
        return this._strings.get("createTeamScheduleButtonAriaLabel").format(teamInfo.teamName);
    }

    /**
     * Render a list item for the MS teams list
     * @param teamInfo
     * @param reactItemKey key for react list item rendering
     * @param isDisabled true if this item is disabled
     * @param isFirstSelectableItem true if this is the first item in the list
     */
    private renderMSTeamListItem(teamInfo: microsoftTeams.TeamInformation, reactItemKey: string, isDisabled: boolean, isFirstSelectableItem: boolean): JSX.Element {
        const handleViewButtonClicked = () => { this.handleViewTeamButtonClicked(teamInfo); };
        const btnDescription: string = isDisabled ? this._strings.get("disabledScheduleButtonTooltip") : this.getCreateButtonAriaLabel(teamInfo); // Display a tooltip explaining why the button is disabled
        const buttonClassName: string = isDisabled ? styles.disabledMSTeamButton : "msTeamSelectButton";

        return (
            <li className={ styles.teamListItem } key={ reactItemKey }>
                <div className={ styles.left } >
                    {
                        this.renderMSTeamListItemLeft(teamInfo)
                    }
                </div>
                <div className={ styles.right } >
                    <PrimaryButton
                        componentRef={ isFirstSelectableItem ? this._firstSelectableItemRef : null }
                        disabled={ isDisabled }
                        allowDisabledFocus={!isDisabled}
                        className={ buttonClassName }
                        onClick={ handleViewButtonClicked }
                        ariaDescription={ btnDescription } >
                        { this._strings.get("selectTeamButtonLabel") }
                    </PrimaryButton>
                </div>
            </li>
        );
    }

    render() {
        return (
            <div className={ styles.content }>
                {
                    this.renderMSTeamsPickerListContents()
                }
            </div>
        );
    }
}