import { action } from "satcheljs";
import { IShiftEntity } from "sh-models";

const calculateShiftConflicts = action(
    "CALCULATE_SHIFT_CONFLICTS",
    (
        currentShift: IShiftEntity,
        shiftsInRow: IShiftEntity[],
        isRequestShift: boolean,
        memberId?: string
    ) => ({
        currentShift: currentShift,
        shiftsInRow: shiftsInRow,
        isRequestShift: isRequestShift,
        memberId: memberId
    })
);

export default calculateShiftConflicts;