import * as React from "react";
import StringsStore from "sh-strings/store";
import { appViewState } from "sh-application/store";
import { Image, PrimaryButton } from "@fluentui/react";
import { REFRESH_BUTTON_TEXT_FALLBACK } from "sh-application/../StaffHubConstants";
import { restartApp } from "sh-application";

const styles = require("./ErrorView.scss");
const classNames = require("classnames/bind");
const defaultErrorImage = require("sh-assets/images/optimized-svg/Shifts_desktop_error.svg");

export interface ErrorViewProps {
    title?: string;
    primaryText: string;
    secondaryText?: string;
    alternateImageSrc?: string;
    useAppRefreshAction?: boolean;
    actionButtonText?: string;
    onActionClick?: (ev?: React.SyntheticEvent<HTMLElement>) => void;
    isFullScreen?: boolean;
}

/**
 * ErrorView - Displays an error and (optionally) an action button
 */
export default class ErrorView extends React.Component<ErrorViewProps, {}> {
    // This view needs to handle the possiblity that the strings are not initialized
    private _defaultActionButtonText = StringsStore().registeredStringModules.get("common") && StringsStore().registeredStringModules.get("common").strings ?
        StringsStore().registeredStringModules.get("common").strings.get("refresh") : REFRESH_BUTTON_TEXT_FALLBACK;

    render() {
        let { isFullScreen, title, primaryText, secondaryText, actionButtonText, alternateImageSrc, onActionClick, useAppRefreshAction } = this.props;
        // for deep links from Teams activity feed, we don't want error views to be actionable
        let actionable = !appViewState().deepLinkState.isActivityFeedDeepLinkMode && (useAppRefreshAction || (onActionClick && actionButtonText)) ? true : false;

        const errorClasses = classNames(
            styles.error,
            { [`${styles.fullScreen}`]: !!isFullScreen }
        );

        return (
            <div className={ errorClasses }  tabIndex={ 0 }>
                <Image src={ alternateImageSrc || defaultErrorImage } />
                <div className={ styles.errorText }>
                    {
                        title &&
                            <h2 className={ styles.title }>
                                { title }
                            </h2>
                    }
                    <div className={ styles.primaryText }>
                        { primaryText }
                    </div>
                </div>
                {
                        actionable &&
                            <PrimaryButton
                                onClick={ useAppRefreshAction ? this.onAppRefresh : this.onActionClick }>
                                { useAppRefreshAction ? this._defaultActionButtonText : actionButtonText }
                            </PrimaryButton>
                }
                {
                    secondaryText &&
                        <div className={ styles.secondaryText }>
                            { secondaryText }
                        </div>
                }
            </div>
        );
    }

    private onAppRefresh(ev: React.MouseEvent<HTMLButtonElement>): void {
        restartApp();
    }

    private onActionClick = (ev: React.MouseEvent<HTMLButtonElement>): void => {
        let { onActionClick } = this.props;

        if (onActionClick) {
            onActionClick(ev);
        }
    }
}