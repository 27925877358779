import { action } from "satcheljs";
import { IMemberEntity, ITagEntity } from "src/client/react/src/staffhub/sh-models";

const addMember = action(
    "addMember",
    (
        tag: ITagEntity,
        member: IMemberEntity
    ) => ({
        tag: tag,
        member: member
    })
);

export default addMember;