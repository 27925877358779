import * as React from "react";
import SpotlightOverlay from "../common/SpotlightOverlay";
import StringsStore from "sh-strings/store";
import { action } from "satcheljs/lib/legacy";
import { appViewState } from "../../store";
import { computed, observable } from "mobx";
import { getGenericEventPropertiesObject } from "sh-instrumentation";
import { IButtonProps, Layer } from "@fluentui/react";
import { InstrumentationService } from "sh-services";
import { ITourStop } from "sh-application/components/tour/ITour";
import { observer } from "mobx-react";
import { setFREsShownSoFar } from "sh-stores";
import { TeamInfoEntity } from "sh-models";
import { TeamStore } from "sh-team-store";
import { TourBubble } from "./TourBubble";
import { TourGuideViewState } from "../../store/schema/TourGuideViewState";
import { trace } from "owa-trace";

/**
 * TourGuide
 */
@observer
export default class TourGuide extends React.Component<{}, {}> {
    @observable private _hasCurrentBeforeShowFinished = false;
    private _strings: Map<string, string>;
    private _lastTourStop: ITourStop = null;

    constructor(props: any) {
        super(props);
        this._strings = StringsStore().registeredStringModules.get("tour-guide").strings;
    }

    @computed
    private get viewState(): TourGuideViewState {
        return appViewState().tourGuideViewState;
    }

    private finishTour = action("finishTour")(() => {
        const activeTour = this.viewState.activeTour;

        trace.info(`Tour: That was the last stop in the ${activeTour.tourKey} tour. Finishing up.`);
        if (activeTour.supportsDontShowAgain) {
            if (this.viewState.dontShowChecked) {
                setFREsShownSoFar([activeTour.tourKey]);
            }
        } else {
            setFREsShownSoFar([activeTour.tourKey]);
        }

        if (activeTour.onDismiss) {
            activeTour.onDismiss();
        }

        this.setDontShowChecked(false);
        this.viewState.activeTour = null;
        this.setCurrentIndex(0);
    });

    private setCurrentIndex = action("setCurrentIndex")((index: number) => {
        if (!ENV_PROD) {
            if (index < 0 || (this.viewState.activeTour && this.viewState.activeTour.tourStops && index >= this.viewState.activeTour.tourStops.length)) {
                trace.error("Tour: setCurrentIndex - current index out of range");
            }
        }
        this.viewState.activeTourStopIndex = index;
    });

    /**
     * Has the beforeShow callback for the current tourStop finished running
     * @param hasCurrentBeforeShowFinished
     */
    private setHasCurrentBeforeShowFinished = action("setHasCurrentBeforeShowFinished")((hasCurrentBeforeShowFinished: boolean) => {
        this._hasCurrentBeforeShowFinished = hasCurrentBeforeShowFinished;
    });

    private shouldShowTourStop(index: number): boolean {
        const activeTour = this.viewState.activeTour;

        if (index >= activeTour.tourStops.length) {
            trace.error("Tour: shouldShowTourStop - index out of range");
            return false;
        }

        const tourStop = activeTour.tourStops[index];

        // if there are required or excluded team types, check if we should run this tour stop for this team
        if (tourStop.requireTeamTypes || tourStop.excludeTeamTypes) {
            const teamType = TeamInfoEntity.getTeamType(TeamStore().team);

            if (tourStop.requireTeamTypes) {
                if (tourStop.requireTeamTypes.indexOf(teamType) === -1) {
                    // this is not a required team type so skip this tour stop
                    trace.info(`Tour: stop ${tourStop.markerKey} will be skipped because it is not the required team type`);
                    return false;
                }
            }

            if (tourStop.excludeTeamTypes) {
                if (tourStop.excludeTeamTypes.indexOf(teamType) !== -1) {
                    // this team type has been excluded so skip this tour stop
                    trace.info(`Tour: stop ${tourStop.markerKey} will be skipped because this team type is excluded`);
                    return false;
                }
            }
        }

        return true;
    }

    private getNextTourStopIndex(fromIndex: number): number {
        const activeTour = this.viewState.activeTour;

        let nextIndex = fromIndex + 1;
        if (nextIndex >= activeTour.tourStops.length) {
            return -1;
        }

        if (!this.shouldShowTourStop(nextIndex)) {
            return this.getNextTourStopIndex(nextIndex);
        }

        return nextIndex;
    }

    private goToNextStop = () => {
        const activeTour = this.viewState.activeTour;

        if (activeTour) {
            if (this.viewState.activeTourStopIndex === activeTour.tourStops.length - 1) {
                // we are at the last stop
                this.finishTour();
            } else {
                const nextIndex = this.getNextTourStopIndex(this.viewState.activeTourStopIndex);
                if (nextIndex === -1) {
                    // if the next stop is the end, just finish the tour
                    this.finishTour();
                } else {
                    this.setCurrentIndex(nextIndex);
                }
            }
        }
    }

    /**
     * Try to find the target (tour marker). First look for the first element with the class of markeyKey,
     * if not found, look for an element with the id of markerKey. Return null if not found.
     */
    private getTarget(): HTMLElement {
        let target = null;
        if (this.viewState && this.viewState.activeTour) {
            const currentStop = this.viewState.activeTour.tourStops[this.viewState.activeTourStopIndex];

            // first check for the element by id of markerKey
            target = document.getElementById(currentStop.markerKey);

            if (!target) {
                // if no target found, use the markerKey as a class name and find the first element with that class
                target = document.getElementsByClassName(currentStop.markerKey)[0] as HTMLElement;
                if (!target) {
                    // target was not found
                    trace.info(`Tour: Can't find TourMarker ${currentStop.markerKey}. Will keep looking.`);
                    return null;
                }
            }
        }

        return target;
    }

    private triggerRender = () => {
        this.forceUpdate();
    }

    setDontShowChecked = action("setDontShowChecked")((checked: boolean) => {
        this.viewState.dontShowChecked = checked;
    });

    onChangeDisableTourCheckbox = (e: React.FormEvent<HTMLElement>, checked: boolean) => {
        this.setDontShowChecked(checked);
    }

    isLastTourStop() {
        return this.viewState.activeTourStopIndex === this.viewState.activeTour.tourStops.length - 1;
    }

    /**
     * If a custom primary button was supplied, we execute the supplied `onClick()` followed by `goToNextStop()`
     * @param e Click event
     */
    onPrimaryButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        const currentStop = this.viewState.activeTour.tourStops[this.viewState.activeTourStopIndex];

        if (this.isLastTourStop() && currentStop.primaryButtonProps) {
            currentStop.primaryButtonProps.onClick(e);
        }

        if (currentStop.onDismiss) {
            currentStop.onDismiss();
        }

        this.goToNextStop();
    }

    /**
     * Callback fired when the TourBubble receives new props. Fires the currentStop's onShow property if
     * it has one, and marks the currentStops freKey as seen, if it has one
     */
    private onShowStop = () => {
        const currentStop = this.viewState.activeTour.tourStops[this.viewState.activeTourStopIndex];
        if (!currentStop) {
            return;
        }

        if (currentStop.onShow) {
            currentStop.onShow();
        }

        if (currentStop.freKey) {
            setFREsShownSoFar([currentStop.freKey]);
        }
    }

    render() {
        if (!this.viewState.activeTour) {
            return null;
        }

        const currentStop = this.viewState.activeTour.tourStops[this.viewState.activeTourStopIndex];
        if (!currentStop) {
            trace.error("Tour: Current tour stop should not be null");
            return null;
        }

        if (!this.shouldShowTourStop(this.viewState.activeTourStopIndex)) {
            this.goToNextStop();
            // we need to manually re-render because updating the current index here will not cause render() to get called again
            this.triggerRender();
            return null;
        }

        // if we are trying to render a new tourStop, first check for a beforeShow callback and run it
        if (this._lastTourStop !== currentStop) {
            this._lastTourStop = currentStop;
            if (currentStop.beforeShow) {
                this.setHasCurrentBeforeShowFinished(false);
                currentStop.beforeShow(() => {
                    this.setHasCurrentBeforeShowFinished(true);
                });
            }
        }

        const target = this.getTarget();
        if (!target) {
            // if we can't find the target, it probably hasn't been rendered yet. We begin syncing with
            // the animations (more modern design than using setTimeout) and refresh at the end of each
            // animation frame to check if our target is now available. We don't render anything until
            // the target is found.
            window.requestAnimationFrame(this.triggerRender);
            return null;
        }

        let primaryButtonProps: IButtonProps = {
            children: StringsStore().registeredStringModules.get("common").strings.get("okGotIt"),
            onClick: this.onPrimaryButtonClick
        };

        let checkboxProps = null;

        // In last stop, we give the option to have an optional checkbox and a button.
        if (this.isLastTourStop()) {
            if (this.viewState.activeTour.supportsDontShowAgain) {
                checkboxProps = {
                    label: this.viewState.activeTour.dontShowAgainLabel || this._strings.get("dontShowMeAgain"),
                    onChange: this.onChangeDisableTourCheckbox,
                    checked: this.viewState.dontShowChecked
                };
            }

            if (currentStop.primaryButtonProps) {
                primaryButtonProps = {
                    ...currentStop.primaryButtonProps,
                    onClick: this.onPrimaryButtonClick
                };
            }
        }

        if (currentStop.instrumentationEventKey) {
            const instrumentationEventProperties = currentStop.instrumentationEventProperties || [
                getGenericEventPropertiesObject(InstrumentationService.properties.TeamType, TeamInfoEntity.getTeamType(TeamStore().team))
            ];
            InstrumentationService.logEvent(currentStop.instrumentationEventKey, instrumentationEventProperties);
        }

        trace.info(`Tour: ${this.viewState.activeTour.tourKey} is now playing tour stop ${currentStop.markerKey}`);

        return (
            <Layer key={currentStop.markerKey}>
                {(this._hasCurrentBeforeShowFinished || !currentStop.beforeShow) && (
                    <SpotlightOverlay target={target}>
                        <TourBubble
                            customImageHeader={currentStop.customImageHeader}
                            illustrationImage={currentStop.imageProps}
                            imageWrapperClass={currentStop.imageWrapperClass}
                            localizationTuples={currentStop.localizationTuples}
                            targetElement={target}
                            primaryButtonProps={primaryButtonProps}
                            onDismiss={this.finishTour}
                            calloutProps={{ directionalHint: currentStop.directionalHint, gapSpace: 8 }}
                            checkboxProps={checkboxProps}
                            onShow={this.onShowStop}
                            dismissOnOutsideClick={this.viewState.activeTour.dismissOnOutsideClick}>
                            {currentStop.renderDescription && currentStop.renderDescription()}
                        </TourBubble>
                    </SpotlightOverlay>
                )}
            </Layer>
        );
    }
}