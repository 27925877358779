import ITimeOffReasonEntity from "./ITimeOffReasonEntity";
import TimeOffReasonEntity from "./TimeOffReasonEntity";

/**
 * Bulk TimeOff Reasons Update model
 */
export default class TimeOffReasonsUpdateResponse {
    timeOffReasonsAdded: ITimeOffReasonEntity[];
    timeOffReasonsUpdated: ITimeOffReasonEntity[];
    timeOffReasonsDeleted: ITimeOffReasonEntity[];
    idToTimeOffReasonsSavedInPreviousRequests: {[timeOffReasonId: string]: ITimeOffReasonEntity }; // Items saved in a previous queued request

    constructor(
        timeOffReasonsAdded: ITimeOffReasonEntity[],
        timeOffReasonsUpdated: ITimeOffReasonEntity[],
        timeOffReasonsDeleted: ITimeOffReasonEntity[],
        idToTimeOffReasonsSavedInPreviousRequests: {[timeOffReasonId: string]: ITimeOffReasonEntity }) {
        this.timeOffReasonsAdded = timeOffReasonsAdded;
        this.timeOffReasonsUpdated = timeOffReasonsUpdated;
        this.timeOffReasonsDeleted = timeOffReasonsDeleted;
        this.idToTimeOffReasonsSavedInPreviousRequests = idToTimeOffReasonsSavedInPreviousRequests;
    }

    /**
     * Parse the response entity from the network response
     * @param jsonData Network data
     * @param idToTimeOffReasonsSavedInPreviousRequests Map of TOR_ID to items saved in previous chained requests
     */
    static fromJson(jsonData: any, idToTimeOffReasonsSavedInPreviousRequests: {[timeOffReasonId: string]: ITimeOffReasonEntity }): TimeOffReasonsUpdateResponse {
        if (!jsonData) {
            return null;
        }

        const timeOffReasonsAdded: ITimeOffReasonEntity[] = [];
        const timeOffReasonsUpdated: ITimeOffReasonEntity[] = [];
        const timeOffReasonsDeleted: ITimeOffReasonEntity[] = [];
        if (jsonData && jsonData.timeOffReasonsAdded) {
            for (let i = 0; i < jsonData.timeOffReasonsAdded.length; i++) {
                timeOffReasonsAdded.push(TimeOffReasonEntity.fromJson(jsonData.timeOffReasonsAdded[i]));
            }
        }
        if (jsonData.timeOffReasonsUpdated) {
            for (let i = 0; i < jsonData.timeOffReasonsUpdated.length; i++) {
                timeOffReasonsUpdated.push(TimeOffReasonEntity.fromJson(jsonData.timeOffReasonsUpdated[i]));
            }
        }
        if (jsonData.timeOffReasonsDeleted) {
            for (let i = 0; i < jsonData.timeOffReasonsDeleted.length; i++) {
                timeOffReasonsDeleted.push(TimeOffReasonEntity.fromJson(jsonData.timeOffReasonsDeleted[i]));
            }
        }
        return new TimeOffReasonsUpdateResponse(
            timeOffReasonsAdded,
            timeOffReasonsUpdated,
            timeOffReasonsDeleted,
            idToTimeOffReasonsSavedInPreviousRequests);
    }
}