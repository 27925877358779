import { action } from "satcheljs";

const teamDeleted = action(
    "teamDeleted",
    (
        teamId: string
    ) => ({
        teamId: teamId
    })
);

export default teamDeleted;