import { action } from "satcheljs";

const timeZoneChanged = action(
    "TIME_ZONE_CHANGED", (
        timeZone: string
    ) => ({
        timeZone: timeZone
    })
);

export default timeZoneChanged;
