import * as moment from "moment";
import IBaseShiftEntity from "../shift/IShiftEntity";
import IUniqueShiftEntity from "./IUniqueShiftEntity";
import ModelUtils from "../ModelUtils";
import ShiftrObjectModelBase from "../ShiftrObjectModelBase";
import { BreakEntity, IBreakEntity } from "../";

const uniqueShiftIdPrefix = "USHFT_";

/**
 * Unique Shift Entity
 */
export default class UniqueShiftEntity extends ShiftrObjectModelBase implements IUniqueShiftEntity {
    // id
    // eTag -- comes from the ShiftrObjectModelBase
    title: string;
    startTime: moment.Moment;
    endTime: moment.Moment;
    theme: string;
    breaks: Array<IBreakEntity>;

    constructor(
        id: string,
        eTag: string,
        title: string,
        startTime: moment.Moment,
        endTime: moment.Moment,
        theme: string,
        breaks: Array<IBreakEntity>) {

        let modelId = id || UniqueShiftEntity.generateNewUniqueShiftId();
        super(modelId, eTag);

        this.id = modelId;
        this.title = title;
        this.startTime = startTime ? startTime.clone() : null;
        this.endTime = endTime ? endTime.clone() : null;
        this.theme = theme;

        this.breaks = null;
        if (breaks) {
            this.breaks = breaks.map((currentBreak: IBreakEntity) => BreakEntity.clone(currentBreak));
        }
    }

    /**
     * Generate new uniqueShiftId used by StaffHub App
     * @returns {string} uniqueShiftId in form of USHFT_<uuid>
     */
    public static generateNewUniqueShiftId(): string {
        return ModelUtils.generateUUIDWithPrefix(uniqueShiftIdPrefix);
    }

    static createEmptyObject(id: string,
        eTag: string,
        title: string,
        theme: string,
        breaks: Array<IBreakEntity>): UniqueShiftEntity {
        return new UniqueShiftEntity(
            id,
            eTag,
            title,
            null,   // start time - date is ignored for uniqueness
            null,   // end time - date is ignored for uniqueness
            theme,
            breaks
        );
    }

    static fromJson(jsonData: any): UniqueShiftEntity {
        if (!jsonData) {
            return null;
        }

        return new UniqueShiftEntity(
            jsonData.id,
            jsonData.eTag,
            jsonData.title,
            moment(jsonData.startTime),
            moment(jsonData.endTime),
            jsonData.theme,
            BreakEntity.fromJsonList(jsonData.breaks));
    }

    static toJson(uniqueShift: IUniqueShiftEntity): IUniqueShiftEntity {
        if (!uniqueShift) {
            return null;
        }

        let jsonData: any = {
            theme: uniqueShift.theme,
            title: uniqueShift.title,
            startTime: uniqueShift.startTime,
            endTime: uniqueShift.endTime
        };

        if (uniqueShift.breaks && uniqueShift.breaks.length > 0) {
            // don't send breaks if it is not set.  Service wrote the API to expect "shiftBreaks" instead of "breaks"
            jsonData.shiftBreaks = uniqueShift.breaks.map((breakEntity: IBreakEntity) => BreakEntity.toJson(breakEntity));
        }

        return jsonData;
    }

    /**
     * Generate a Unique Shift from a shift.  Ignore fields associated with non-unique shifts
     * @param shift
     * @returns IUniqueShiftEntity
     */
    static fromShift(shift: IBaseShiftEntity): IUniqueShiftEntity {
        const breaks = shift.breaks ? shift.breaks.map((breakEntity: IBreakEntity) => BreakEntity.clone(breakEntity)) : [];
        return new UniqueShiftEntity(
            null /* id */,
            null /* eTag */,
            shift.title,
            shift.startTime.clone(),
            shift.endTime.clone(),
            shift.theme,
            breaks
        );
    }
}