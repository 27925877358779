import { action } from "satcheljs";
import { IShiftRequestEntity } from "sh-models";

const approveSwapHandoffRequest = action(
    "APPROVE_SWAP_HANDOFF_REQUEST",
    (
        shiftRequest: IShiftRequestEntity,
        isReceiver: boolean,
        responseMessage: string,
        onFinish?: VoidFunction
    ) => ({
        shiftRequest: shiftRequest,
        isReceiver: isReceiver,
        responseMessage: responseMessage,
        onFinish: onFinish
    })
);

export default approveSwapHandoffRequest;
