import ShiftrObjectModelBase from "../ShiftrObjectModelBase";
import { FlightSettings, ExtraTeamSettings } from "sh-models";
/**
 * Base class that sets up common aspects for the App Settings client and service models
 */
export default class AppSettingsModelBase extends ShiftrObjectModelBase {
    tenantId: string;
    teamId: string;
    flightSettings: FlightSettings;
    extraTeamSettings: ExtraTeamSettings;

    constructor(
        id: string,
        eTag: string,
        tenantId: string,
        teamId: string,
        flightSettings: FlightSettings,
        extraTeamSettings: ExtraTeamSettings) {

        super(id, eTag);

        this.tenantId = tenantId;
        this.teamId = teamId;
        this.flightSettings = AppSettingsModelBase.cloneFlightSettings(flightSettings);
        this.extraTeamSettings = extraTeamSettings;
    }

    public static cloneFlightSettings(flightSettings: FlightSettings): FlightSettings {
        let result: FlightSettings;
        if (flightSettings) {
            result = { ...flightSettings };
        }
        return result;
    }
}
