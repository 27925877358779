import schedulesViewStateStore from "../../store/store";
import { mutatorAction } from "satcheljs";
import { ShiftFilterType } from "sh-models";

/**
 * Toggle Shift Filter in filter
 */
export default mutatorAction("TOGGLE_SHIFT_FILTER_IN_VIEW", function toggleShiftFilter(
    shiftFilter: ShiftFilterType
) {
    if (!schedulesViewStateStore() || !schedulesViewStateStore().selectedShiftFilters) {
        return;
    }

    if (schedulesViewStateStore().selectedShiftFilters.has(shiftFilter)) {
        schedulesViewStateStore().selectedShiftFilters.delete(shiftFilter);
    } else {
        schedulesViewStateStore().selectedShiftFilters.set(shiftFilter, shiftFilter);
    }
});
