import { mutatorAction } from "satcheljs";
import { ConnectorAuthDialogStoreSchema } from "../store/schema/ConnectorAuthDialogStoreSchema";

/**
 * Resets the user tries count for connector authentication
 * @param connectorAuthDialogStore -Connector Auth Dialog Store
 */
export const resetConnectorAuthDialogTriesCount = mutatorAction(
    "RESET_CONNECTOR_AUTH_DIALOG_TRIES_COUNT",
    (connectorAuthDialogStore: ConnectorAuthDialogStoreSchema) => {
        connectorAuthDialogStore.triesCount = 0;
    }
);
