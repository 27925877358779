import { action } from "satcheljs";
import { ITeamSettingEntity } from "sh-models";

const setTeamSettings = action(
    "SET_TEAM_SETTINGS", (
        teamSettings: ITeamSettingEntity[]
    ) => ({
        teamSettings: teamSettings
    })
);

export default setTeamSettings;
