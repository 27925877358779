import PollHelper from "sh-application/utility/PollHelper";
import { IJobEntity, JobStatus } from "sh-models";
import { trace } from "owa-trace";

/**
 * JobPollUtil: It will register with poll helper to poll until Job is success or failed or max retry reached
 */
export class JobPollUtils {
    private pollCount: number;
    private jobId: string;
    private maxRetry: number;
    private checkJobStatus: (jobId: string) => Promise<IJobEntity>;
    private onJobResponse: (importScheduleJob: IJobEntity) => void;
    private onMaxRetry: () => void;
    private pollHelper: PollHelper;

    public initPolling(jobId: string, intervalDurationMs: number, maxRetryCount: number, checkJobStatus: (jobId: string) => Promise<IJobEntity>, onJobResponse: (importScheduleJob: IJobEntity) => void, onMaxRetry: () => void) {
        this.jobId = jobId;
        this.checkJobStatus = checkJobStatus;
        this.onJobResponse = onJobResponse;
        this.onMaxRetry = onMaxRetry;
        this.maxRetry = maxRetryCount;
        this.pollCount = 0; // reset pollcount before starting polling

        // instantiating poll helper
        this.pollHelper = new PollHelper(
            intervalDurationMs,
            intervalDurationMs /* minimumPollDuration */,
            () => this.jobPollCallback()
        );
    }

    async jobPollCallback() {
        if (this.pollCount > this.maxRetry) { // callback on maxRetry reached
            this.pollHelper.stopPolling();
            this.onMaxRetry();
            return;
        }

        try {
            this.pollCount++;
            const jobStatus: IJobEntity = await this.checkJobStatus(this.jobId);
            // callback on job response
            this.onJobResponse(jobStatus);

            // stop polling if job status is success or failure
            if ( [JobStatus.Failure, JobStatus.Success].indexOf(jobStatus.status) !== -1) {
                this.pollHelper.stopPolling();
            }

        } catch (error) {
            trace.error(`Job status failed ${error.message}`);
        }
    }
}