import { action } from "satcheljs";
import { IConflictDismissEntity } from "sh-models";

const setDismissConflicts = action(
    "SET_DISMISSED_CONFLICTS",
    (
        dismissedConflict: IConflictDismissEntity[]
    ) => ({
        dismissedConflict: dismissedConflict
    })
);

export default setDismissConflicts;