import { mutatorAction } from "satcheljs";
import { SchedulesViewStateStore } from "sh-application/components/schedules/lib/store/schema/SchedulesViewStateStore";
import IMemberEntity from 'sh-models/member/IMemberEntity';

/**
 * Function to update the member panel values.  If no valid values are sent in, this will set all values to null
 * tagId is required, or all values will be set to null
 */
export default mutatorAction("SET_MEMBER_PANEL_VALUES",
    function setMemberPanelValues(viewState: SchedulesViewStateStore, tagId?: string, member?: IMemberEntity) {
        if (viewState) {
            if (tagId) {
                // the values are valid. Set the panel to open
                viewState.memberPanelValues = {
                    tagId: tagId,
                    member: member
                };
            } else {
                viewState.memberPanelValues = null;
            }
        }
    }
);