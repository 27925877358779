import * as bowser from "bowser";

export default class BrowserUtils {
    public static isChrome(): boolean {
        return !!bowser.chrome;
    }

    public static isIE(): boolean {
        return !!bowser.msie;
    }

    public static isEdgeOrIE(): boolean {
        return !!bowser.msedge || !!bowser.msie;
    }

    public static isEdge(): boolean {
        return !!bowser.msedge;
    }

    public static isFirefox(): boolean {
        return !!bowser.firefox;
    }

    public static isMobile(): boolean {
        return !!bowser.mobile;
    }

    public static isIOS(): boolean {
        return !!bowser.ios;
    }

    public static isAndroid(): boolean {
        return !!bowser.android;
    }

    public static isMac(): boolean {
        return !!bowser.mac;
    }
}