import { mutatorAction } from "satcheljs";
import { TagStore } from "sh-tag-store";

/**
 * Sets people count for a given tag.
 */
export const setTagPeopleCount = mutatorAction(
    "SET_TAG_PEOPLE_COUNT",
    (tagId: string, peopleCount: number) => {
        const { tagIdToPeopleCount, tags } = TagStore();

        if (tagId && tags.has(tagId)) {
            tagIdToPeopleCount.set(tagId, peopleCount);
        }
    }
);
