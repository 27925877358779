import * as microsoftTeams from "@microsoft/teams-js";

/**
 * Viewstate for the New Team Setup UX
 */

/**
 * Details needed to create a new team
 */
export interface TeamSetupInfo {
    msTeamInfo: microsoftTeams.TeamInformation; // MS Team to create a schedule team for
    timeZoneOlsonCode: string; // Team timezone
}

export enum TeamSetupPickerStepState {
    Invalid = -1,
    ScheduleTeamPicker,
    MSTeamPicker,
    TimeZonePicker,
    StartTour
}

export interface TeamSetupPickerStore {
    setupSequence: Array<TeamSetupPickerStepState>; // The sequence of steps to complete the team setup
    currentSetupStepIndex: number; // The current step index in the sequence of steps
    teamSetupInfo: TeamSetupInfo; // Details entered by user to create the team
    isProvisionTeamInProgress: boolean; // true if a new schedule team is being provisioned
    errorMessage: string; // Error message string to display. Set to null for no error message.
    showStepIndicator: boolean; // true shows the step indicator component in the setup dialog
}
