import StringsStore from "sh-strings/store";
import { IWorkBook, IWorkSheet, IWorkSheetCell } from "./XLSXTypes";
import { lazyXLSX } from "./xlsx";
import { NetworkService } from "sh-services";
import { saveAs } from "file-saver";
import { trace } from "owa-trace";

/**
 * Utilities for Exporting data to excel
 */
export default class ExportDataUtils {
    // Cell widths in number of characters
    private MinCellWidth = 15;

    // service api path to save files
    private SaveFileProxy = "/api/content/SaveFileProxy";

    private _stringsExcel: Map<string, string> = StringsStore().registeredStringModules.get("dateTimeFormatsExcel").strings;

    /**
     * Cell data item for export
     */
    public OutputCell(cellValue: any, doWrapText?: boolean, bold?: boolean, cellColor?: string, cellWidth?: number, showBorder?: boolean, cellSpanHeight?: number, cellSpanWidth?: number, cellFormat?: string): any {
        let result: any = {};

        result.cellValue = cellValue;                          // Cell contents
        result.cellFormat = cellFormat;                        // Cell content format. Used for dates

        result.cellColor = cellColor;                          // Cell fill color "AARRGGBB"
        result.cellWidth = cellWidth;                          // Suggested cell width in number of characters
        result.doWrapText = doWrapText ? doWrapText : false;   // Wrap cell text
        result.bold = bold ? bold : false;                     // bold text

        result.showBorder = showBorder;                        // bottom border black line
        result.cellSpanHeight = cellSpanHeight;                // merge cells height - data should only be in top left cell while merging
        result.cellSpanWidth = cellSpanWidth;                  // merge cells width - data should only be in top left cell while merging

        return result;
    }

    /**
     * Save export cell data as an XLSX file
     * @param {Array} cellDataArray - Array of arrays of cell data rows. Each item contains the row data for an Excel worksheet.
     * @param {Array} workSheetNamesArray - Array of names for Excel worksheets
     * @param {String} xlsxFileName
     */
    public async saveDataAsXlsx(cellDataArray: any[], workSheetNamesArray: string[], xlsxFileName: string) {
        // lazy load the XLSX library before proceeding with any export operations
        const XLSX = await lazyXLSX.import();

        let workbook: IWorkBook = {
            SheetNames: [],
            Sheets: {},
            Props: {}
          };

        // Add worksheets to workbook
        for (let workSheetIndex = 0; workSheetIndex < cellDataArray.length; workSheetIndex++) {
            const currentWorkSheetName = workSheetNamesArray[workSheetIndex];
            workbook.SheetNames.push(currentWorkSheetName);
            const currentWorkSheet = this.generateWorksheetFromData(cellDataArray[workSheetIndex], XLSX.instance);
            workbook.Sheets[currentWorkSheetName] = currentWorkSheet;
        }

        let workbookOut = XLSX.instance.write(workbook, { bookType: 'xlsx', bookSST: true, type: 'binary' });

        let xlsxFileData = new Blob([this.s2ab(workbookOut)], {type: "application/octet-stream"});

        this.saveAsFileName(xlsxFileData, xlsxFileName);
    }

    public static getDateFormatForFileName(): string {
        // Use non-localizable ISO date format for dates in file names.
        // This is helpful for interoperability between users using different UI languages and also helps for
        // file name sorting.
        return "YYYY-MM-DD";
    }

    private s2ab(s: string) {
        let buf = new ArrayBuffer(s.length);
        let view = new Uint8Array(buf);
        for (let i = 0; i != s.length; ++i) {
            view[i] = s.charCodeAt(i) & 0xFF;
        }
        return buf;
    }

    private saveAsFileName(blob: Blob, fileName: string) {
        // test to figure out if saveAs is natively supported - same as in Kendo/FileSaver
        const elem = document.createElement("a");
        const saveAsSupported = "download" in elem;

        if (saveAsSupported) {
            saveAs(blob, fileName);
        } else {
            let fileReader = new FileReader();
            fileReader.onload = (ev) => { this.saveFileViaProxy(fileReader.result as string, fileName); };
            fileReader.readAsDataURL(blob);
        }
    }

    private saveFileViaProxy(fileDataUri: string, fileName: string) {

        trace.info("SaveFileViaProxy: file-name=${fileName}");

        // create a transient form with file contents and submit to service

        let form = document.createElement("form");
        form.setAttribute("method", "post");
        form.setAttribute("action", NetworkService.GetServiceAPIEndPointUrl() + this.SaveFileProxy);

        const parts = fileDataUri.split(";base64,");
        const contentType = parts[0].replace("data:", "");
        const base64 = parts[1];

        const map = new Map<string, string>();
        // IE bug: set values in map separately. Initializing in Map constructor doesn't work
        map.set("fileName", fileName);
        map.set("contentType", contentType);
        map.set("base64", base64);

        map.forEach((value: string, key: string) => {
            let hiddenField = document.createElement("input");
            hiddenField.setAttribute("type", "hidden");
            hiddenField.setAttribute("name", key);
            hiddenField.setAttribute("value", value);

            form.appendChild(hiddenField);
        });

        document.body.appendChild(form);
        form.submit();
        document.body.removeChild(form);
    }

    /**
     * Generate date number value for representing a date in XSLX
     * @param {Date} dateValue
     * @param {Boolean} date1904
     * @returns {Number} date number value
     */
    private datenumForXslxOutput(dateValue: Date) {
        let epoch = Date.parse(dateValue.toString());
        return (epoch - Date.UTC(1899, 11, 30)) / (24 * 60 * 60 * 1000);
    }

    /**
     * Generate XSLX worksheet object from cell data
     * @param {Array} outputCellData - array of rows of cell data
     * @returns {Object} worksheet object
     */
    private generateWorksheetFromData(outputCellData: any[], XLSX: any) {
        let worksheet: IWorkSheet = {};

        const maxIndex = 10000000;
        let range = { s: { c: maxIndex, r: maxIndex }, e: { c: 0, r: 0 } };
        let maxColumnWidths = [];
        let mergeCells: any[] = [];

        for (let rowIndex = 0; rowIndex != outputCellData.length; ++rowIndex) {
            for (let columnIndex = 0; columnIndex != outputCellData[rowIndex].length; ++columnIndex) {
                if (range.s.r > rowIndex) {
                    range.s.r = rowIndex;
                }
                if (range.s.c > columnIndex) {
                    range.s.c = columnIndex;
                }
                if (range.e.r < rowIndex) {
                    range.e.r = rowIndex;
                }
                if (range.e.c < columnIndex) {
                    range.e.c = columnIndex;
                }

                let currentOutputCell = outputCellData[rowIndex][columnIndex];

                if (currentOutputCell.cellSpanHeight || currentOutputCell.cellSpanWidth) {
                    const cellSpanHeight = (currentOutputCell.cellSpanHeight) ? currentOutputCell.cellSpanHeight : 1;
                    const cellSpanWidth = (currentOutputCell.cellSpanWidth) ? currentOutputCell.cellSpanWidth : 1;

                    mergeCells.push(
                        {
                            s: {c: columnIndex, r: rowIndex },
                            e: {c: columnIndex + cellSpanWidth - 1, r: rowIndex + cellSpanHeight - 1 }
                        }
                    );
                }

                let cell: IWorkSheetCell = { v: currentOutputCell.cellValue, t: null, s: null, z: null };
                if (cell.v == null) {
                    continue;
                }
                let cell_ref = XLSX.utils.encode_cell({ c: columnIndex, r: rowIndex });

                if (typeof cell.v === 'number') {
                    cell.t = 'n';
                } else if (typeof cell.v === 'boolean') {
                    cell.t = 'b';
                } else if (cell.v instanceof Date) {
                    // Convert the specified datetime into a time that will look like the same time when displayed as UTC.
                    // eg, convert "3pm PST" such that it still looks like 3pm when displayed as UTC
                    // Excel doesn't support timezones for datetimes, so we need to convert our datetimes for display without local timezone adjustments.

                    let displayDateAsUtc = this.getLocalDateAsUtcDisplay(cell.v);
                    cell.t = 'n';
                    if (currentOutputCell.cellFormat) {
                        cell.z = currentOutputCell.cellFormat;
                    } else {
                        cell.z = this._stringsExcel.get("excelDateCellDateFormat");
                    }
                    cell.v = this.datenumForXslxOutput(displayDateAsUtc);
                } else {
                    cell.t = 's';
                }

                cell.s = {
                    alignment: {
                        vertical: 'top',
                        wrapText: currentOutputCell.doWrapText
                    },
                    font: {
                        bold: currentOutputCell.bold
                    },
                    fill: (currentOutputCell.cellColor) && { fgColor: { rgb: currentOutputCell.cellColor } },
                    border: (currentOutputCell.showBorder === true) && { bottom: { style: "thin", color: { rgb: "00000000" } } }
                };

                // Determine the column widths by using the max width of the cells for each column
                const currentMaxColumnWidth: number = maxColumnWidths[columnIndex] || this.MinCellWidth;
                const currentCellWidth = currentOutputCell.cellWidth || 0;
                maxColumnWidths[columnIndex] = Math.max(currentMaxColumnWidth, currentCellWidth);

                worksheet[cell_ref] = cell;
            }
        }

        if (range.s.c < maxIndex) {
            worksheet['!ref'] = XLSX.utils.encode_range(range.s, range.e);
        }

        if (maxColumnWidths.length) {
            let wscols = [];
            for (let colIndex = 0; colIndex < maxColumnWidths.length; colIndex++) {
                wscols.push({ wch: maxColumnWidths[colIndex] });
            }
            worksheet['!cols'] = wscols;
        }
        if (mergeCells.length > 0) {
            worksheet['!merges'] = mergeCells;
        }

        return worksheet;
    }

    /**
     * Convert the local Javascript Date into a Date that is represented in UTC with the same time values
     * This is used for exporting dates for things like Excel which don't use timezones and just represent date times in UTC.
     * eg, Local time 8am PST -> 8am UTC
     * @param {Date} date
     * @returns {Date} UTC time
     */
    private getLocalDateAsUtcDisplay(date: Date): Date {
        let resultUtc: Date;
        if (date) {
            resultUtc = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds()));
        }
        return resultUtc;
    }
}
