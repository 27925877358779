/**
 * The profile photo size options.
 */
export enum ProfilePhotoSize {
    Size_48x48 = "48x48",
    Size_64x64 = "64x64",
    Size_96x96 = "96x96",
    Size_120x120 = "120x120",
    Size_240x240 = "240x240",
    Size_360x360 = "360x360",
    Size_432x432 = "432x432",
    Size_504x504 = "504x504",
    Size_648x648 = "648x648"
}