import { createStore } from "satcheljs";
import { INoteEntity, IOpenShiftEntity, IShiftEntity } from "sh-models";
import { ObservableMap } from "mobx";
import { ProgressiveStoreSchema } from "./schema/ProgressiveStoreSchema";

import "../orchestrators/onIsProgressiveRendering";

/**
 * ProgressiveStore - Maintains a cache of intermediate data to render as it is being progressively
 * paginated in from the network
 */
const ProgressiveStore = createStore<ProgressiveStoreSchema>(
    "progressiveStore",
    {
        shifts: new ObservableMap<string, IShiftEntity>(),
        openShifts: new ObservableMap<string, IOpenShiftEntity>(),
        notes: new ObservableMap<string, INoteEntity>()
    });

export default ProgressiveStore;