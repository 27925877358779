import { createStore } from "satcheljs";
import ShiftDetailsStoreSchema from "./schema/ShiftDetailsStoreSchema";

const initialShiftDetailsStore: ShiftDetailsStoreSchema = {
    isSaving: false
};

const shiftDetailsStore = createStore("shiftDetailsStore", initialShiftDetailsStore)();
export default shiftDetailsStore;

export { default as setIsSavingInStore } from "./mutatorActions/setIsSavingInStore";

import "./orchestrators/requestOpenShift";