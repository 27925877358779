import AvailabilityStore from "../store";
import resetAvailabilityStore from "../actions/resetAvailabilityStore";
import { mutator } from "satcheljs";

/**
 * Reset the availability store
 */
export default mutator(resetAvailabilityStore, ({ }) => {
    AvailabilityStore().membersAvailabilities.clear();
    AvailabilityStore().hasFetchedAvailabilities.set(false);
});
