import { mutatorAction } from "satcheljs";

import { ScheduleComponents } from "../store/ScheduleComponents";
import schedulesViewStateStore from "../store/store";

/**
 * Adds given component to the rendered components set.
 */
export const addRenderedComponent = mutatorAction("ADD_RENDERED_COMPONENT", (component: ScheduleComponents) => {
    schedulesViewStateStore().renderedComponents.add(component);
});
