import ShiftUtils from "sh-application/utility/ShiftUtils";
import { extendObservable } from "mobx";
import {
    IBaseShiftEntity,
    ISubshiftEntity,
    ITeamInfoEntity,
    ShiftEntity,
    ShiftTypes
    } from "sh-models";
import { orchestrator } from "satcheljs";
import { setShiftInStore, initializeLocalObservableShift } from "../../lib";
import { TeamDataService } from "sh-services";
import { UniqueShiftStore } from "sh-uniqueshift-store";

/**
 * Initializes a shift object in the store and marks it and all of its subshifts as observable so that the panel UI
 * reacts to changes in it. If the panel was passed a shift in the externally modified ShiftEditor,
 * this orchestrator will use this shift. Otherwise a new shift is created.
 */
export const initializeLocalObservableShiftOrchestrator = orchestrator(initializeLocalObservableShift, actionMessage => {
    const { shiftEditorViewState, shift, memberId, groupTagId, startDate } = actionMessage;
    const team: ITeamInfoEntity = TeamDataService.getCurrentTeam();
    const teamId = team ? team.id : "";
    const tenantId = team ? team.tenantId : "";

    let observableShift: IBaseShiftEntity = null;
    // When editing an existing shift, just make all the existing properties observable
    // If we are editing a unshared deleted shift (title string will still say Add), the panel
    // behaves as though this was the add of a new shift. But when changes are saved, we will edit the existing
    // deleted shift
    if (shift && !ShiftUtils.isUnsharedDeletedShift(shift)) {
        observableShift = ShiftEntity.clone(shift);
        observableShift.subshifts.map((activity: ISubshiftEntity, index: number) => extendObservable(activity, {...shift.subshifts[index]})); // do the same for the subshifts within the shift, as each is an object with its own properties
    // When creating a new shift, create a new shift
    } else {
        observableShift = ShiftEntity.createEmptyObject(
            tenantId,
            teamId,
            memberId || memberId,
            ShiftTypes.Working);

        // When creating a new shift we use the last unique shift created/modified in the app. If we don't have such a shift,
        // we instead use default values
        if (UniqueShiftStore().uniqueShifts && UniqueShiftStore().uniqueShifts.length > 0) {
            const uniqueShift = UniqueShiftStore().uniqueShifts[0];
            ShiftUtils.setShiftValuesFromUniqueShift(uniqueShift, observableShift, startDate);
        } else {
            ShiftUtils.setDefaultShiftValues(observableShift, startDate);
        }

        // If we are editing a unshared deleted shift, we'll want to update it at the end, so we use its
        // id, eTag, and sharedChanges for our local observable shift object.
        if (ShiftUtils.isUnsharedDeletedShift(shift)) {
            observableShift.id = shift.id;
            observableShift.eTag = shift.eTag;
            observableShift.sharedChanges = shift.sharedChanges;
        }

        if (groupTagId) {
            observableShift.tagIds.push(groupTagId);
        }
    }

    observableShift.subshifts.sort(ShiftUtils.shiftComparator);

    // extendObservable (target, props) copies all props value pairs onto target and marks them as observable.
    // In this panel we need the Shift component to react to changes to properties of the shift, so we mark every property of the shift as observable
    setShiftInStore(shiftEditorViewState, extendObservable(observableShift, {...observableShift}));
});
