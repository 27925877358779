import { AxiosError } from "axios";
import { StaffHubHttpError } from "../../../sh-application";
import { StaffHubErrorCodes } from "../StaffHubErrorCodes";
import { AuthFailureReason } from "./types/AuthFailureReason";

interface DeferredPromise {
    cancel: (reason?: any) => void;
    done: (value?: any) => void;
    promise?: Promise<any>;
}

interface DataSelector {
    type: string;
    incomingKey: string;
    targetKey: string;
}

interface ConnectorAuthRequest {
    ssoUrl: string;
    dataSelectors: DataSelector[];
    additionalData: string;
}

const TARGET_URL_PLACEHOLDER = "<targetUrlPlaceholder>";

/**
 * Creates a promise, and returns it, as well as it's corresponding resolve, reject methods in order to be excecuted later
 * Works as a way to wait for user action before resolving a promise, resolve/reject should be called on user action
 * @param
 * @returns {DeferredPromise}
 */
export const createDeferredPromise = (): DeferredPromise => {
    const throwError = () => {
        throw new Error("Deferred promise 'cancel' or 'done' is undefined");
    };
    const deferredPromise: DeferredPromise = {
        cancel: throwError,
        done: throwError
    } as DeferredPromise;

    /* tslint:disable-next-line */
    deferredPromise.promise = new Promise((resolve, reject) => {
        deferredPromise.done = resolve;
        deferredPromise.cancel = reject;
    });

    return {
        done: deferredPromise.done,
        cancel: deferredPromise.cancel,
        promise: deferredPromise.promise
    };
};

/**
 * Checks if the staffHubHttpError is a Connector Auth Request and if flag is enabled
 * @param {StaffHubHttpError} staffHubHttpError
 * @returns {boolean}
 */
export const isConnectorAuthErrorResponse = (staffHubHttpError: StaffHubHttpError): boolean =>
    staffHubHttpError.httpErrorCode === 401 &&
    staffHubHttpError.staffHubTopLevelErrorCode === StaffHubErrorCodes.ConnectorAuthRequest;

/**
 * Encodes obj into base64 string
 * @param {Record} data
 * @returns {string}
 */
export const encodeHeaderForConnectorAuth = (data: Record<string, any>): string =>
    btoa(JSON.stringify(data));

/**
 * Maps httpError response into Connector Auth Request
 * @param {AxiosError} httpError
 * @returns {ConnectorAuthRequest}
 */
export const mapConnectorAuthRequestResponse = (httpError: AxiosError<any, any>) => {
    const result = {} as ConnectorAuthRequest;
    let externalData;
    // data is a string JSON obj
    const rawExternalData = httpError.response?.data?.error?.externalData;
    if (typeof rawExternalData === "string") {
        externalData = JSON.parse(rawExternalData);
        result.ssoUrl = externalData.data?.redirectionUrl;
        result.dataSelectors = externalData.data?.dataSelectors;
        result.additionalData = externalData.data?.additionalData;
    }
    return result;
};

/**
 * Maps Connector SSO Auth info response with the data selectors
 * @param {Record} connectorAuthInfo
 * @param {DataSelector[]} dataSelectors
 * @returns {Record}
 */
export const mapConnectorAuthResponseWithDataSelectors = (
    connectorAuthInfo: Record<string, any>,
    dataSelectors: DataSelector[]
) => {
    const mappedAuthData: Record<string, any> = {} as Record<string, any>;
    dataSelectors.forEach((selector: DataSelector) => {
        mappedAuthData[selector.targetKey] = connectorAuthInfo[selector.incomingKey] || null;
    });
    return mappedAuthData;
};

/**
 * Replaces target url placeholder with corresponding target url from Shifts app
 * @param {string} ssoUrl
 * @param {string} targetUrl
 * @returns {string}
 */
export const replaceTargetUrlPlaceholder = (ssoUrl: string, targetUrl: string) =>
    ssoUrl.replace(TARGET_URL_PLACEHOLDER, targetUrl);

export const isFailedConnectorAuthentication = (reason: any): boolean =>
    reason === AuthFailureReason.Unknown;
