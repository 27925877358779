import * as React from "react";
import AvailabilityUtils from "sh-application/utility/AvailabilityUtils";
import classNames from "classnames/bind";
import { IAvailabilityEntity, ScheduleCalendarType, ScheduleCalendarTypes } from "sh-models";

const styles = require("./EmptyCellAvailability.scss");

interface EmptyCellAvailabilityProps {
    availability: IAvailabilityEntity;
    scheduleCalendarType: ScheduleCalendarType;
}

/**
 * This class represents the availabilities for a user visually
 */
const EmptyCellAvailability = React.memo((props: EmptyCellAvailabilityProps) => {
    const availabilities = AvailabilityUtils.getUserFriendlyStrings(props.availability);
    return (
        <div className={ classNames(styles.container, "no-print", {[styles.dayView]: props.scheduleCalendarType === ScheduleCalendarTypes.Day}) }>
            {
                availabilities.map((availabilityString: string, index: number) => {
                    return <span key={ "availability_" + index } className={ styles.textSpan } title={ availabilityString }>{ availabilityString }</span>;
                })
            }
        </div>
    );
});

export default EmptyCellAvailability;