import { mutatorAction } from "satcheljs";
import { SchedulesViewStateStore } from "sh-application/components/schedules/lib/store/schema/SchedulesViewStateStore";

/**
 * Sets the boolean to indicate the copy schedule button is disabled
 * @param viewState - Schedule view state
 * @param isDisabled - boolean determining if copy schedule button should be disabled
 */
export default mutatorAction("SET_DISABLE_COPY_SCHEDULE_BUTTON",
    function setDisableCopyScheduleButton(viewState: SchedulesViewStateStore, isDisabled: boolean) {
        viewState.isCopyScheduleButtonDisabled = isDisabled;
    }
);