import AvailabilityStore from "../store";
import { IAvailabilityEntity } from "sh-models";
import { mutatorAction } from "satcheljs";

export default mutatorAction("setAvailabilities", function setAvailabilities(
    availabilities: Map<string, IAvailabilityEntity[]>
) {
    if (availabilities) {
        AvailabilityStore().membersAvailabilities.clear();
        AvailabilityStore().hasFetchedAvailabilities.set(false);
        availabilities.forEach((availabilities: IAvailabilityEntity[], memberId: string) => {
            AvailabilityStore().membersAvailabilities.set(memberId, availabilities);
        });
        AvailabilityStore().hasFetchedAvailabilities.set(true);
    }
});