import schedulesViewStateStore from "../../store/store";
import { ITagEntity } from "sh-models";
import { mutatorAction } from "satcheljs";

/**
 *  Update group in filter
 */
export default mutatorAction("UPDATE_FILTERED_TAGS", function updateFilteredTags(
    group: ITagEntity
) {
    if (!schedulesViewStateStore() || !schedulesViewStateStore().filteredTags) {
        return;
    }

    if (schedulesViewStateStore().filteredTags.has(group.id)) {
        schedulesViewStateStore().filteredTags.set(group.id, group);
    }
});
