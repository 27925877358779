import * as moment from "moment";
import { IBaseTimeClockEvent, ITimeClockEvent, ITimeClockEventDb} from "./IBaseTimeClockEvent";
import { Moment } from "moment";

export default class TimeClockEvent implements ITimeClockEvent {
    time: Moment;
    isAtApprovedLocation?: boolean;

    constructor(
        time: Moment,
        isAtApprovedLocation?: boolean) {

        this.time = moment(time);
        this.isAtApprovedLocation = isAtApprovedLocation;

    }

    static fromJson(jsonData: TimeClockEvent | ITimeClockEventDb): TimeClockEvent {
        if (!jsonData) {
            return null;
        }
        let timeClockEvent: TimeClockEvent = new TimeClockEvent(moment(jsonData.time), jsonData.isAtApprovedLocation);

        return timeClockEvent;
    }

    static clone(timeClockEvent: TimeClockEvent | ITimeClockEventDb): TimeClockEvent {
        return TimeClockEvent.fromJson(timeClockEvent);
    }

    /**
     * Function that creates db TimeClockEvent<number> from client TimeClockEvent<Moment>
     * @param timeClockEvent timeclock event client entity
     */
    static toDbModel(timeClockEvent: ITimeClockEvent): IBaseTimeClockEvent<number> {
        if (!timeClockEvent) {
            return null;
        }

        return {
            time: timeClockEvent.time && timeClockEvent.time.valueOf(), // Db uses a numeric timestamp format
            isAtApprovedLocation: timeClockEvent.isAtApprovedLocation
        };
    }
}