import schedulesViewStateStore from "sh-application/components/schedules/lib/store/store";
import shiftsModified from "sh-stores/sh-shift-store/actions/shiftsModified";
import ShiftUtils from "sh-application/utility/ShiftUtils";
import updateShiftInStore from "sh-stores/sh-shift-store/mutatorActions/updateShiftInStore";
import updateShifts from "sh-stores/sh-shift-store/actions/updateShifts";
import { DataProcessingHelpers } from "sh-services";
import { IBaseShiftEntity, IShiftEntity, ShiftEntity } from "sh-models";
import { Moment } from "moment";
import { MyShiftStore, ShiftStore } from "..";
import { orchestrator } from "satcheljs";
import { ShiftStoreSchema } from "../store/schema/ShiftStoreSchema";
import { ShiftStoreTypes } from "sh-application/../StaffHubConstants";
import { trace } from "owa-trace";
import { updateShiftsInView } from "sh-application/components/schedules/lib";

export default orchestrator(updateShifts, async actionMessage => {
    let shiftsAdded: Array<IShiftEntity> = [];
    let shiftsUpdated: Array<IShiftEntity> = [];
    let shiftsDeleted: Array<IShiftEntity> = [];
    let shiftsMaybeAddToView: Array<IShiftEntity> = [];

    let shiftStoreEntity: ShiftStoreSchema;

    actionMessage.shiftStoreType === ShiftStoreTypes.MyShiftStore ?
        shiftStoreEntity = MyShiftStore()
        :
        shiftStoreEntity = ShiftStore();

    const cacheStartTime: Moment = shiftStoreEntity.shiftsCacheStartTime;
    const cacheEndTime: Moment = shiftStoreEntity.shiftsCacheEndTime;

    // We use clones of the argument entities. This is because setting the entities in the satchel stores will mark them as observables
    // and any callers who still hold references to these entities will not be able to mutate them (in event of an error, for example) without
    // triggering mobx exceptions
    const shiftsToProcess: Array<IShiftEntity> = actionMessage.shiftsUpdated.map(shift => ShiftEntity.clone(shift));

    shiftsToProcess.forEach((shiftModel: IShiftEntity) => {
        let setShift = true;
        const shiftInStore: IShiftEntity = shiftStoreEntity.shifts.get(shiftModel.id);
        if (!shiftInStore) {
            // if this shiftModel is not already in the cache, this is an add.
            // validate that it fits within our cache range
            if (!ShiftUtils.shiftOverlapsStartsOrEndsBetween(shiftModel, cacheStartTime, cacheEndTime, false /*includeEdges*/)) {
                // this shift is not within our cache range, show a debug error
                trace.info("New shift is outside our cache date range");

                // if shift is not within cache range do not set shift in cache
                setShift = false;
            } else {
                // keep track of all the shifts added
                shiftsAdded.push(shiftModel);
            }
        } else {
            // Check if the shift has been modified by the service (or if it's an optimistic update where shift data has changed but etag hasn't)
            if (ShiftUtils.hasItemBeenModifiedByService(shiftInStore, shiftModel) || actionMessage.isOptimisticUpdate) {
                // keep track of all the shifts updated/deleted
                if (ShiftUtils.isDeletedShift(shiftModel)) {
                    shiftsDeleted.push(shiftModel);
                } else {
                    shiftsUpdated.push(shiftModel);
                }
            } else {
                // this is the case where the shift is already in the memory cache, but it may not actually be in
                // the "in view" cache. To handle these shifts, we first check that they are not currently in the view cache
                // and later we will add them if they are in the viewable range.
                if (!schedulesViewStateStore().shiftsInView.has(shiftModel.id)) {
                    shiftsMaybeAddToView.push(shiftModel);
                }
            }
        }

        // if the shift is already in the cache, we always allow the update

        // set the shift in the cache (this will either add a new entry or update an existing one)
        if (setShift) {
            updateShiftInStore(shiftModel, shiftStoreEntity);
        }
    });

    if (shiftsAdded.length || shiftsUpdated.length || shiftsDeleted.length) {
        // new stachel v3 action
        shiftsModified(shiftsAdded, shiftsDeleted, shiftsUpdated);
    }

    if (shiftsMaybeAddToView.length) {
        // we have shifts that may need to be added to the in view memory cache. Check if they are in our current view
        // range and if so, add them to the in view cache.
        const shiftsInRange: IBaseShiftEntity[] = DataProcessingHelpers.getDisplayableShiftsInScheduleRange(
            shiftsMaybeAddToView,
            schedulesViewStateStore().viewStartDate,
            schedulesViewStateStore().viewEndDate);
        if (shiftsInRange && shiftsInRange.length) {
            updateShiftsInView(shiftsInRange);
        }
    }
});