import { action  } from "satcheljs";

const setIsProgressiveRendering = action(
    "SET_IS_PROGRESSIVE_RENDERING",
    (
        inProgress: boolean,
        didComplete: boolean = true
    ) => ({
        inProgress: inProgress,
        didComplete: didComplete
    })
);

export default setIsProgressiveRendering;