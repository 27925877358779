import { action } from "satcheljs/lib/legacy";
import { isReleaseBuild } from "sh-application/utility/utility";
import { TeamDataService } from "sh-services";
import { UserSettingsEntity } from "sh-models";

/**
 * Clears all fresShownSoFar in user settings
 */
export let clearFREsShownSoFar = action("clearFREsShownSoFar")(
    async function clearFREsShownSoFar() {
        /* non-release builds only (for now) */
        if (!isReleaseBuild()) {
            const userSettings: UserSettingsEntity = await TeamDataService.getUserSettings();
            userSettings.fresShownSoFar = [];
            return await TeamDataService.saveUserSettings(userSettings);
        } else {
            return Promise.reject("Should not be calling clearFREsShownSoFar in release builds");
        }
    });
