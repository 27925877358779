import { ExportScheduleDataOptions } from "sh-application/utility/ExportShiftsReportUtils";
import { action  } from "satcheljs";
import { Moment } from "moment";
import { INoteEntity, IShiftEntity } from "sh-models";

const exportShiftsReport = action(
    "EXPORT_SHIFTS_REPORT",
    (
        startDate: Moment,
        endDate: Moment,
        exportOptions: ExportScheduleDataOptions,
        shifts: IShiftEntity[],
        notes: INoteEntity[]
    ) =>
    ({
        startDate: startDate,
        endDate: endDate,
        exportOptions: exportOptions,
        shifts: shifts,
        notes: notes
    })
);

export default exportShiftsReport;