import { mutatorAction } from "satcheljs";
import { PrintOrientation } from "sh-application/../StaffHubConstants";
import { SchedulesViewStateStore } from "sh-application/components/schedules/lib/store/schema/SchedulesViewStateStore";

/**
 * Sets the print orientation (portrait/landscape).
 */
export default mutatorAction("SET_PRINT_ORIENTATION",
    function setPrintOrientation(
        viewState: SchedulesViewStateStore,
        orientation: PrintOrientation) {

        viewState.printOrientation = orientation;
    }
);