import ConflictUtils from "sh-application/utility/ConflictUtils";
import DateUtils from "sh-application/utility/DateUtils";
import ShiftUtils from "sh-application/utility/ShiftUtils";
import {
    addShiftsToStoreMap,
    calculateConflicts,
    ConflictStore,
    deleteShiftsInStoreMap,
    handleConflictManagement,
    resetConflictStore,
    setConflictStoreDateRange,
    setIgnoredConflictsOnLongShifts
    } from "sh-conflict-store";
import { ECSConfigKey, ECSConfigService, InstrumentationService } from "sh-services";
import { IShiftEntity } from "sh-models";
import { Moment } from "moment";
import { orchestrator } from "satcheljs";

/**
 * When updates to shifts are triggered via the UI or via sync notifications, this orchestrator will be triggered.
 * It will parse the updated shifts and update the ShiftsByMemberAndDateStore maps consequently
 * It also calculates shift-overlapping conflicts, shift-availability and timeoff conflicts.
 */
export default orchestrator(handleConflictManagement, (actionMessage) => {
    let marker = "handleConflictManagement";
    marker = InstrumentationService.perfMarkerStart(marker);

    const shiftsAdded: IShiftEntity[] = actionMessage.shiftsAdded || [];
    const shiftsDeleted: IShiftEntity[] = actionMessage.shiftsDeleted || [];
    const shiftsUpdated: IShiftEntity[] = actionMessage.shiftsUpdated || [];
    const viewStartDatetime = actionMessage.viewStartDatetime;
    const viewEndDatetime = actionMessage.viewEndDatetime;

    // Maximum length of a shift (working/absence) that we will calculate conflicts on (shifts longer than this are ignored).
    const maxShiftLengthForConflictCalculationInDays: number = ECSConfigService.getECSFeatureSetting(ECSConfigKey.ConflictCalcMaxShiftLengthInDays);

    // find the earliest shift modified start date,
    // initialize this to the latest date to find the earliest of the shift dates for comparison
    let shiftsModifiedStartDate: Moment = viewEndDatetime && viewEndDatetime.clone();
    // find the latest shift modified end date
    // initialize this to the earliest date to find the latest of the shift dates for comparison
    let shiftsModifiedEndDate: Moment = viewStartDatetime && viewStartDatetime.clone();

    // this flag is used to keep in track if the shifts that were modified is the current date range
    // if not for this flag, we will be re-computing conflicts for shifts in view unnecessarily,
    // even when no shifts were modified in the view date range.
    let shiftsInViewRangeModified: boolean = false;

    // Phase 1 -- shift bucketing
    // shifts may not be in the date time range, we don't calculate conflicts for them,
    // but we have to add them to ShiftByMemberAndDateStore
    if (shiftsDeleted.length > 0) {
        shiftsDeleted.forEach((shiftDeleted: IShiftEntity) => {
            // update in both maps of the store
            deleteShiftsInStoreMap(shiftDeleted);
            if (DateUtils.getDifferenceInDaysFromMoments(shiftDeleted.startTime, shiftDeleted.endTime, false) <= maxShiftLengthForConflictCalculationInDays) {
                if (ShiftUtils.shiftOverlapsStartsOrEndsBetween(shiftDeleted, viewStartDatetime, viewEndDatetime, true /*includeEdges*/)) {
                    shiftsInViewRangeModified = true;
                    shiftsModifiedStartDate = shiftDeleted.startTime.isBefore(shiftsModifiedStartDate) ? shiftDeleted.startTime : shiftsModifiedStartDate;
                    shiftsModifiedEndDate = shiftDeleted.endTime.isAfter(shiftsModifiedEndDate) ? shiftDeleted.endTime : shiftsModifiedEndDate;
                }
            } else {
                setIgnoredConflictsOnLongShifts(true);
            }
        });
    }

    // add shifts to the shiftsbyMemberAndDateStore, only if its an active shift
    if (shiftsAdded.length > 0) {
        shiftsAdded.forEach((shiftAdded: IShiftEntity) => {
            if (DateUtils.getDifferenceInDaysFromMoments(shiftAdded.startTime, shiftAdded.endTime, false) <= maxShiftLengthForConflictCalculationInDays) {
                // update in both maps of the store
                addShiftsToStoreMap(shiftAdded);
                if (ShiftUtils.shiftOverlapsStartsOrEndsBetween(shiftAdded, viewStartDatetime, viewEndDatetime, true /*includeEdges*/)) {
                    shiftsInViewRangeModified = true;
                    shiftsModifiedStartDate = shiftAdded.startTime.isBefore(shiftsModifiedStartDate) ? shiftAdded.startTime : shiftsModifiedStartDate;
                    shiftsModifiedEndDate = shiftAdded.endTime.isAfter(shiftsModifiedEndDate) ? shiftAdded.endTime : shiftsModifiedEndDate;
                }
            } else {
                setIgnoredConflictsOnLongShifts(true);
            }
        });
    }

    // if shifts are updated, delete it and then add it
    if (shiftsUpdated.length > 0) {
        shiftsUpdated.forEach((shiftAddOrUpdate: IShiftEntity) => {
            // update in both maps of the store
            deleteShiftsInStoreMap(shiftAddOrUpdate);
            if (DateUtils.getDifferenceInDaysFromMoments(shiftAddOrUpdate.startTime, shiftAddOrUpdate.endTime, false) <= maxShiftLengthForConflictCalculationInDays) {
                addShiftsToStoreMap(shiftAddOrUpdate);
                if (ShiftUtils.shiftOverlapsStartsOrEndsBetween(shiftAddOrUpdate, viewStartDatetime, viewEndDatetime, true /*includeEdges*/)) {
                    shiftsInViewRangeModified = true;
                    shiftsModifiedStartDate = shiftAddOrUpdate.startTime.isBefore(shiftsModifiedStartDate) ? shiftAddOrUpdate.startTime : shiftsModifiedStartDate;
                    shiftsModifiedEndDate = shiftAddOrUpdate.endTime.isAfter(shiftsModifiedEndDate) ? shiftAddOrUpdate.endTime : shiftsModifiedEndDate;
                }
            } else {
                setIgnoredConflictsOnLongShifts(true);
            }
        });
    }

    // adjust start date and end date, incase they are not populated correctly
    if (shiftsModifiedStartDate && shiftsModifiedEndDate && shiftsModifiedEndDate.isBefore(shiftsModifiedStartDate)) {
        shiftsModifiedStartDate = viewStartDatetime;
        shiftsModifiedEndDate = viewEndDatetime;
    }

    // make sure the modified dates have the start day of their respective dates
    shiftsModifiedStartDate = DateUtils.getStartOfDay(shiftsModifiedStartDate);
    shiftsModifiedEndDate = DateUtils.getStartOfDay(shiftsModifiedEndDate);

    const firstRender = !ConflictStore().startTime || !ConflictStore().endTime;
    // determine if the date range changed and conflicts need to be recalculated
    const conflictDateRangeChanged = (ConflictStore().startTime && ConflictStore().endTime)
                                    && (!ConflictStore().startTime.isSame(DateUtils.getStartOfDay(viewStartDatetime)) || !ConflictStore().endTime.isSame(DateUtils.getStartOfDay(viewEndDatetime)));

    const forceCalculate = actionMessage.forceCalculate || false;

    // Phase 2 -- conflict calculation and management
    // do not calculate if we are in progressive rendering mode, even if we forceCalculate
    // at no point do we want to forcecalculate without having all the shifts in the shiftsByMemberAndDateStore
    if (viewStartDatetime && viewEndDatetime && actionMessage.calculateConflicts) {
        // reset conflict store only if the conflict date range changed or we want to force calculate
        if (!!conflictDateRangeChanged || !!forceCalculate || (!ConflictStore().startTime || !ConflictStore().endTime)) {
            resetConflictStore();
            setConflictStoreDateRange(viewStartDatetime, viewEndDatetime);
        }

        // calculate only if the shifts in the view date range were modified
        // As per PM requirement, we will show conflicts if the conflict is in the past, only if
        // the start or end time of the shifts fall in view date time range in present or future.
        if (ConflictUtils.isConflictEnabledInDateRange(viewEndDatetime)) {
            if ((!!conflictDateRangeChanged || !!forceCalculate) || firstRender) {
                calculateConflicts(viewStartDatetime, viewEndDatetime, actionMessage.availabilities, actionMessage.refreshConflictsCallback);
            } else if (shiftsInViewRangeModified) {
                calculateConflicts(shiftsModifiedStartDate, shiftsModifiedEndDate, actionMessage.availabilities, actionMessage.refreshConflictsCallback);
            }
        }
    }

    InstrumentationService.perfMarkerEnd(marker);
});