import handleBlockingBootstrapError from "../actions/handleBlockingBootstrapError";
import setBlockingError from "../mutators/setBlockingError";
import StringsStore from "sh-strings/store";
import UrlFactory from "sh-application/utility/UrlFactory";
import { AppSettings, setLoginState } from "sh-application";
import { ErrorViewDetails } from "../store/schema/ErrorWatcherViewStateStore";
import { getGenericEventPropertiesObject } from "sh-instrumentation";
import { InstrumentationService, StaffHubErrorCodes, UserStorageService } from "sh-services";
import { LoginState } from "sh-application/store/schema/LoginViewState";
import { orchestrator } from "satcheljs";
import { REFRESH_BUTTON_TEXT_FALLBACK, UNKNOWN_ERROR_MESSAGE_FALLBACK, UNKNOWN_ERROR_TITLE_FALLBACK } from "sh-application/../StaffHubConstants";
import { trace } from "owa-trace";
import { UserStorageKeys } from "sh-models";

/**
 * When there is a blocking bootstrap/login error that prevents the app from being useable
 */
export default orchestrator(handleBlockingBootstrapError, actionMessage => {
    if (actionMessage.staffHubHttpError && actionMessage.staffHubHttpError.isHandled) {
        // this exception is already handled
        return;
    }

    // This orchestrator should handle the strings not being initialized
    // It'll fall back to the generic error if that happens
    const accessBlockedStrings = StringsStore().registeredStringModules.get("accessBlocked") ? StringsStore().registeredStringModules.get("accessBlocked").strings : undefined;
    const commonStrings = StringsStore().registeredStringModules.get("common") ? StringsStore().registeredStringModules.get("common").strings : undefined;

    // we default to a generic something went wrong message
    // and default to using a sign out button and action
    let accessBlockedError: ErrorViewDetails = {
        title: accessBlockedStrings ? accessBlockedStrings.get("unknownErrorTitle") : UNKNOWN_ERROR_TITLE_FALLBACK,
        primaryText: accessBlockedStrings ? accessBlockedStrings.get("unknownErrorMessage") : UNKNOWN_ERROR_MESSAGE_FALLBACK,
        actionButton: {
            useAppRestartAction: true,
            actionButtonText: commonStrings ? commonStrings.get("refresh") : REFRESH_BUTTON_TEXT_FALLBACK
        }
    };

    try {
        if (actionMessage.staffHubHttpError && actionMessage.staffHubHttpError.staffHubInnerErrorCode) {
            if (actionMessage.staffHubHttpError.staffHubInnerErrorCode === StaffHubErrorCodes.UserNotLicensed) {
                // enterprise license missing
                actionMessage.staffHubHttpError.isHandled = true;
                accessBlockedError.title = accessBlockedStrings.get("title");
                accessBlockedError.primaryText = accessBlockedStrings.get("enterpriseLicenseMissing");
                accessBlockedError.actionButton = {
                    useUrlNavigationAction: true,
                    urlNavigationActionPath: AppSettings.getSetting("StaffHubLicenseUrl"),
                    actionButtonText: commonStrings.get("learnMore")
                };
            } else if (actionMessage.staffHubHttpError.staffHubInnerErrorCode === StaffHubErrorCodes.LicenseNotAllowed) {
                // government license not allowed
                actionMessage.staffHubHttpError.isHandled = true;
                accessBlockedError.title = accessBlockedStrings.get("title");
                accessBlockedError.primaryText = accessBlockedStrings.get("govNotSupported");
                accessBlockedError.actionButton = {
                    useUrlNavigationAction: true,
                    urlNavigationActionPath: AppSettings.getSetting("StaffHubLicenseUrl"),
                    actionButtonText: commonStrings.get("learnMore")
                };
            } else if (actionMessage.staffHubHttpError.staffHubInnerErrorCode === StaffHubErrorCodes.StaffHubDisabled) {
                // staffhub disabled
                actionMessage.staffHubHttpError.isHandled = true;
                accessBlockedError.title = accessBlockedStrings.get("title");
                accessBlockedError.primaryText = accessBlockedStrings.get("staffHubDisabled");
            } else if (actionMessage.staffHubHttpError.staffHubInnerErrorCode === StaffHubErrorCodes.TeamNotFound) {
                // team not found

                // Clear team because there was a bootstrap error
                if (UserStorageService) {
                    UserStorageService.removeItem(UserStorageKeys.MostRecentTeamId);
                }

                // mark this exception as being handled
                actionMessage.staffHubHttpError.isHandled = true;
                // don't show any error in this case
                accessBlockedError = null;

                // set the login state to NeedBootstrap because the bootstrap for the missing team failed
                setLoginState(LoginState.LoggedInNeedBootstrap);

                // redirect to team picker
                if (actionMessage.history) {
                    actionMessage.history.replace(UrlFactory.getBaseUrl());
                }
            } else if (actionMessage.staffHubHttpError.staffHubTopLevelErrorMessage) {
                // if there is no custom handling, show error message sent from Service
                actionMessage.staffHubHttpError.isHandled = true;
                accessBlockedError.title = accessBlockedStrings.get("unknownErrorTitle");
                accessBlockedError.primaryText = actionMessage.staffHubHttpError.staffHubTopLevelErrorMessage;
            } else {
                // unknown error
                InstrumentationService.trackException(actionMessage.staffHubHttpError, InstrumentationService.events.UnknownError, "handleBlockingBootstrapError", [getGenericEventPropertiesObject(InstrumentationService.properties.ResponseErrorReason, actionMessage.staffHubHttpError.staffHubInnerErrorCode)]);
            }
        } else if (actionMessage.staffHubHttpError && actionMessage.staffHubHttpError.isNetworkError) {
            // This was a network error, so there must be connectivitiy issues
            actionMessage.staffHubHttpError.isHandled = true;
            if (commonStrings) {
                accessBlockedError.title = commonStrings.get("networkErrorTitle");
                accessBlockedError.primaryText = commonStrings.get("networkError");
            }
            // unknown error
            InstrumentationService.trackException(actionMessage.staffHubHttpError, InstrumentationService.events.NetworkError, "handleBlockingBootstrapError");
        } else {
            // unknown error
            InstrumentationService.trackException(actionMessage.staffHubHttpError, InstrumentationService.events.UnknownError, "handleBlockingBootstrapError");
        }
    } catch (err) {
        trace.error("Error handling bootstrap exception", err);
    } finally {
        setBlockingError(accessBlockedError);
    }
});