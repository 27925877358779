import { compareShiftsForConflicts, conflictRequestStore, fetchAndCompareShiftsForConflicts, setRequestShiftInConflictStore } from "..";
import { IBaseShiftEntity, ShiftRequestTypes } from "sh-models";
import { orchestrator } from "satcheljs";
import { ShiftDataService } from "sh-services";
import { trace } from "owa-trace";

/**
 * This orchestrator retrive the shift/Openshift and updates them in the conflict store
 * This will later trigger the conflict calculations
 */
export default orchestrator(fetchAndCompareShiftsForConflicts, async actionMessage => {
    const { shiftId, shiftRequest, memberId } = actionMessage;

    if (shiftId && shiftRequest && memberId) {
        try {
            // Request date range can be in future or past and hence the shifts might not be in ShiftStore cache range
            // We fetch the shifts using the shiftId and update them in conflict request store if they are already not loaded from loadDataRequest call

            const requestShift = conflictRequestStore().requestShift && conflictRequestStore().requestShift.get(shiftId);
            if (!requestShift) {
                let getShiftPromise: Promise<IBaseShiftEntity>;
                if (shiftRequest.requestType === ShiftRequestTypes.Open) {
                    getShiftPromise = ShiftDataService.getOpenShiftById(shiftId);
                } else {
                    getShiftPromise = ShiftDataService.getShiftById(shiftId);
                }

                await getShiftPromise.then((shift: IBaseShiftEntity) => {
                    // Update the shift in conflict request store as this may be out of schedule cache range and may not be available in ShiftStore/OpenShiftstore
                    // Hence add this shift in conflictRequestStore for future reference
                    setRequestShiftInConflictStore(shiftId, shift);
                });
            }

            // Trigger confict calculation for specified member and its shiftsRequest
            compareShiftsForConflicts(shiftRequest, memberId);
        } catch (error) {
            trace.warn("fetchAndCompareShiftsForConflicts failed ");
        }
    }
});