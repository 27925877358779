import { createStore } from "satcheljs";
import { IUniqueSubshiftEntity } from "sh-models";
import { observable } from "mobx";
import { UniqueSubshiftStoreSchema } from "./schema/UniqueSubshiftStoreSchema";

/**
 * Store for Unique Subshifts
 */
let UniqueSubshiftStore = createStore<UniqueSubshiftStoreSchema>(
    "uniqueSubshiftStore",
    {
        // list of unique subshifts
        uniqueSubshifts: observable<IUniqueSubshiftEntity>([]),
        hasFetchedUniqueSubshifts: observable.box(false)
    });

export default UniqueSubshiftStore;