import { mutatorAction } from "satcheljs";
import { SchedulesViewStateStore } from "sh-application/components/schedules/lib/store/schema/SchedulesViewStateStore";

/**
 * Updates the visibility of the Tags panel
 * @param viewState - Schedule view state
 * @param isReorderTagsPanelOpen - boolean determining the visibility of the Tags panel
 */
export default mutatorAction("SET_IS_REORDER_TAGS_PANEL_OPEN",
    function setIsReorderTagsPanelOpen(viewState: SchedulesViewStateStore, isReorderTagsPanelOpen: boolean) {
        viewState.isReorderTagsPanelOpen = isReorderTagsPanelOpen;
    }
);