import { action } from "satcheljs";
import { AppSettingsClientModel } from "sh-models";

/**
 * Fire an action that sets the app settings for a team
 */
const setAppSettingsForTeam = action(
    "SET_APP_SETTINGS_FOR_TEAM",
    (
        appSettingsClientModel: AppSettingsClientModel
    ) => ({
        appSettingsClientModel: appSettingsClientModel
    })
);

export default setAppSettingsForTeam;
