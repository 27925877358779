import toggleHideScheduleAvailability from "../actions/toggleHideScheduleAvailability";
import { orchestrator } from "satcheljs";
import { setDisableHideScheduleAvailabilityToggle } from "../";
import { setGlobalMessageFromException } from "sh-application";
import { TeamDataService } from "sh-services";
import { UserSettingsEntity } from "sh-models";

/**
 * Toggles the hide state of schedule availabilities
 */
export default orchestrator(toggleHideScheduleAvailability, async actionMessage => {
        const userSettings: UserSettingsEntity = await TeamDataService.getUserSettings();
        if (userSettings) {
            setDisableHideScheduleAvailabilityToggle(true);
            try {
                const updatedUserSettings: UserSettingsEntity = UserSettingsEntity.clone(userSettings);
                updatedUserSettings.hideScheduleAvailability = !(updatedUserSettings.hideScheduleAvailability);
                await TeamDataService.saveUserSettings(updatedUserSettings);
            } catch (error) {
                setGlobalMessageFromException(error, false /* AutoDismiss */);
            } finally {
                setDisableHideScheduleAvailabilityToggle(false);
            }
        }
    });
