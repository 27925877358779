import { ObservableMap } from "mobx";
import { createStore } from "satcheljs";

import { LoadingState } from "../common/LoadingState";

import { UsersPicturesStoreSchema } from "./UsersPicturesStoreSchema";

/* Register Orchestrators */
import "../mutators/setUserPictureLoadingStateMutator";
import "../mutators/setUserPictureUrlMutator";
import "../orchestrators/onLoadUserPicture";

/**
 * The users pictures store.
 */
export const UsersPicturesStore = createStore<UsersPicturesStoreSchema>("UsersPicturesStore", {
    loadingStates: new ObservableMap<string, LoadingState>(),
    pictureUrls: new ObservableMap<string, string>()
});
