import * as microsoftTeams from "@microsoft/teams-js";
import StaffHubHttpErrorUtils from "sh-application/utility/StaffHubHttpErrorUtils";
import StringsStore from "sh-strings/store";
import StringUtils from "sh-application/utility/StringUtils";
import UserUtils from "sh-application/utility/UserUtils";
import { InstrumentationService } from "sh-services";
import { ITeamInfoEntity, TeamManagedByTypes, UserPolicySettingsEntity } from "sh-models";
import { setErrorMessage } from "./store/store";
import { StaffHubErrorCodes } from "sh-services";
import { TeamDataService } from "sh-services";
import { trace } from "owa-trace";
import { UserStore } from "sh-stores";

/**
 * Callback used for processMSTeamsData().  This is called for each MS Team processed.
 * @param msTeamInfo The current MS Team being processed
 * @param scheduleTeamInfo The schedule team associated with the current MS Team. If the MS Team has no Schedule team, then this will be undefined.
 */
export type OnMSTeamCallbackFunction = (msTeamInfo: microsoftTeams.TeamInformation, scheduleTeamInfo: ITeamInfoEntity) => void;

export interface ITeamSetupPickerEntrypointsState {
    showTeamSwitcherEntrypoint: boolean; // true if schedule team switcher UX can be shown to the user
    showCreateNewScheduleEntrypoint: boolean; // true if create new schedule UX can be shown to the user
    msTeamInfos: microsoftTeams.TeamInformation[]; // user's joined MS Teams
    msAdminTeamInfosWithoutSchedules: microsoftTeams.TeamInformation[]; // user's MS Teams where they are admin and has no schedules. ie, user can create schedules for these teams
    scheduleTeamsManagedByMSTeams: ITeamInfoEntity[]; // user's schedule teams that are managed by MS Teams
}

export default class TeamSetupPickerUtils {
    /**
     * Helper for processing MS Teams data and determining whether they have associated Schedule teams
     *
     * A schedule team may be associated with an MS Team if:
     * a) It is a legacy StaffHub-managed schedule team that had an associated O365 group ID for chat service, sharepoint, etc.
     * b) It is a MS Team-managed schedule team that was provisioned by the Shifts app to serve as the schedule for an MS Team.
     *
     * An MS Team with any schedule team provisioned cannot have another one provisioned.
     * @param scheduleTeamInfos all schedule teams for the user
     * @param msTeamInfos all MS Teams for the user
     * @param onMSTeamCallback callback that is run for each MS Team
     */
    public static processMSTeamsData(scheduleTeamInfos: ITeamInfoEntity[], msTeamInfos: microsoftTeams.TeamInformation[], onMSTeamCallback: OnMSTeamCallbackFunction) {
        if (msTeamInfos && msTeamInfos.length) {
            // For each MS Team, find its associated Schedule team if one exists
            const msTeamSchedulesMap: Map<string, ITeamInfoEntity> = TeamSetupPickerUtils.generateMSTeamSchedulesMap(scheduleTeamInfos);
            for (let msTeamIndex = 0; msTeamIndex < msTeamInfos.length; msTeamIndex++) {
                const currentMSTeamInfo: microsoftTeams.TeamInformation = msTeamInfos[msTeamIndex];
                if (currentMSTeamInfo.groupId) {
                    const scheduleTeamForCurrentMSTeam: ITeamInfoEntity = msTeamSchedulesMap.get(currentMSTeamInfo.groupId);
                    onMSTeamCallback(currentMSTeamInfo, scheduleTeamForCurrentMSTeam);
                }
            }
        }
    }

    /**
     * Generate a map of Schedule teams that are associated with MS Teams. Schedule teams are indexed by group ID.
     * A schedule team may be associated with an MS Team if:
     * a) It is a legacy StaffHub-managed schedule team that had an associated O365 group ID for chat service, sharepoint, etc.
     * b) It is a MS Team-managed schedule team that was provisioned by the Shifts app to serve as the schedule for an MS Team.
     */
    private static generateMSTeamSchedulesMap(scheduleTeamInfos: ITeamInfoEntity[]): Map<string, ITeamInfoEntity> {
        let scheduleMap: Map<string, ITeamInfoEntity> = new Map();
        if (scheduleTeamInfos) {
            for (let scheduleIndex = 0; scheduleIndex < scheduleTeamInfos.length; scheduleIndex++) {
                const scheduleTeamInfo: ITeamInfoEntity = scheduleTeamInfos[scheduleIndex];
                // If the schedule team already has a group ID, then it already has an associated MS Team/O365 group.
                if (scheduleTeamInfo.groupId) {
                    scheduleMap.set(scheduleTeamInfo.groupId, scheduleTeamInfo);
                }
            }
        }
        return scheduleMap;
    }

    /**
     * Returns the schedule teams that are managed by MS Teams (ie, not StaffHub teams)
     */
    public static getScheduleTeamsManagedByMSTeams(scheduleTeamInfos: ITeamInfoEntity[]): ITeamInfoEntity[] {
        let scheduleTeamsManagedByMSTeams: ITeamInfoEntity[] = [];
        for (let teamIndex = 0; teamIndex < scheduleTeamInfos.length; teamIndex++) {
            const currentScheduleTeam: ITeamInfoEntity = scheduleTeamInfos[teamIndex];
            if (currentScheduleTeam.managedBy === TeamManagedByTypes.Teams) {
                scheduleTeamsManagedByMSTeams.push(currentScheduleTeam);
            }
        }
        return scheduleTeamsManagedByMSTeams;
    }

    /**
     * Team name comparator
     */
    public static teamNameComparator(firstTeamName: string, secondTeamName: string): number {
        return StringUtils.genericStringComparator(firstTeamName, secondTeamName);
    }

    /**
     * Returns true if the user is an admin for the given team
     */
    public static isUserTeamAdmin(msTeamInfo: microsoftTeams.TeamInformation): boolean {
        // check if a user is a schedule owner
        const currentUserPolicy = UserStore() && UserStore().userPolicySettings;
        return (msTeamInfo && msTeamInfo.userTeamRole !== undefined) && (msTeamInfo.userTeamRole === microsoftTeams.UserTeamRole.Admin) || UserUtils.isScheduleOwnerPermissionsEnabled(currentUserPolicy);
    }

    /**
     * Returns true if the team is a StaffHub team (ie, not associated with a schedule team created from MS Teams and is managed by the StaffHub Web site)
     */
    public static isStaffHubTeam(teamInfo: ITeamInfoEntity): boolean {
        return !teamInfo.managedBy ||  // Assume teams without the managedBy property set are older StaffHub teams
            (teamInfo.managedBy === TeamManagedByTypes.StaffHub);
    }

    /**
     * Provision a schedule team for the specified MS Team
     */
    public static async provisionScheduleTeam(msTeamInfo: microsoftTeams.TeamInformation, timezoneOlsonCode: string) {
        let scheduleTeamId: string = null;
        const scheduleTeamInfo: ITeamInfoEntity = await TeamDataService.scheduleProvision(msTeamInfo, timezoneOlsonCode);
        if (scheduleTeamInfo) {
            scheduleTeamId = scheduleTeamInfo.id;
        }
        return scheduleTeamId;
    }

    /**
     * Extract the error message from an error object
     */
    private static getErrorMessageFromError(error: any): string {
        let errorMessage: string;
        if (error && error.code && error.code === StaffHubErrorCodes.InsufficientPermissions) {
            // For our custom error returned by TeamDataService.getMSTeamsUserJoinedTeams()
            const strings = StringsStore().registeredStringModules.get("teamSetupPicker").strings;
            errorMessage = strings.get("genericLoadingTeamsErrorMessage");
        } else {
            errorMessage = StaffHubHttpErrorUtils.getErrorMessage(error);
        }
        return errorMessage;
    }

    /**
     * Display an error message in the team setup UX for a service failure
     */
    public static handleStaffHubServiceError(error: any) {
        const errorMessage: string = TeamSetupPickerUtils.getErrorMessageFromError(error);

        trace.error(errorMessage, error);
        setErrorMessage(errorMessage);
    }

    /**
     * Calculate the states of the entrypoints for the TeamSetupPicker
     */
    public static async calculateTeamSetupPickerEntrypointsState(throwOnError?: boolean): Promise<ITeamSetupPickerEntrypointsState> {
        let result: ITeamSetupPickerEntrypointsState = {
            showTeamSwitcherEntrypoint: false,
            showCreateNewScheduleEntrypoint: false,
            msTeamInfos: [],
            msAdminTeamInfosWithoutSchedules: [],
            scheduleTeamsManagedByMSTeams: []
        };

        try {
            const [scheduleTeams, msUserJoinedTeams] = await Promise.all([
                TeamDataService.getTeams(),
                TeamDataService.getMSTeamsUserJoinedTeams()
            ]);

            if (scheduleTeams && msUserJoinedTeams && msUserJoinedTeams.userJoinedTeams) {
                const scheduleTeamInfos: ITeamInfoEntity[] = scheduleTeams;
                const msTeamInfos: microsoftTeams.TeamInformation[] = msUserJoinedTeams.userJoinedTeams;

                // Get the schedule teams that are managed by MS Teams
                const scheduleTeamsManagedByMSTeams: ITeamInfoEntity[] = TeamSetupPickerUtils.getScheduleTeamsManagedByMSTeams(scheduleTeamInfos);

                // Get the MS Teams that the user can create a schedule for
                // ie, MS Teams that have no schedule teams associated with them, and the user is an admin for the MS Team
                let msAdminTeamInfosWithoutSchedules: microsoftTeams.TeamInformation[] = [];
                const handleMSTeamCallback: OnMSTeamCallbackFunction = (msTeamInfo: microsoftTeams.TeamInformation, scheduleTeamInfo: ITeamInfoEntity) => {
                    if (TeamSetupPickerUtils.isUserTeamAdmin(msTeamInfo) && !scheduleTeamInfo) {
                        msAdminTeamInfosWithoutSchedules.push(msTeamInfo);
                    }
                };
                TeamSetupPickerUtils.processMSTeamsData(scheduleTeamInfos, msTeamInfos, handleMSTeamCallback);

                result.showTeamSwitcherEntrypoint = scheduleTeamsManagedByMSTeams.length > 0;

                const userPolicy: UserPolicySettingsEntity = UserStore() && UserStore().userPolicySettings;
                result.showCreateNewScheduleEntrypoint = msAdminTeamInfosWithoutSchedules.length > 0 || UserUtils.isScheduleOwnerPermissionsEnabled(userPolicy);
                result.msTeamInfos = msTeamInfos;
                result.msAdminTeamInfosWithoutSchedules = msAdminTeamInfosWithoutSchedules;
                result.scheduleTeamsManagedByMSTeams = scheduleTeamsManagedByMSTeams;
            }
        } catch (error) {
            const errorMessage: string = TeamSetupPickerUtils.getErrorMessageFromError(error);
            trace.error(errorMessage, error);
            // TODO (DCoh):  This should really always throw so that the error can get bubbled up to the UX layers, but the exception seems to get
            // eaten by either the low level await or action handling code when being called from within Satchel actions.  I don't see the exception
            // get bubbled up past the actions that call this helper.
            // For now, this won't throw since otherwise some calling UXes will end up in a bad state (eg, toolbar is missing in Schedule view)
            // with no error messaging. These calls are unlikely to fail since getTeams() data should already be fetched during bootstrap, and
            // the MS Teams SDK user joined teams call should never fail except for the dev builds that are run without the proper MS Teams container.
            if (throwOnError) {
                throw error;
            }
        }

        return result;
    }

    /**
     * Get instrumentation event name for the Schedule Team Switcher UX
     */
    public static getInstrumentationEventNameForScheduleTeamSwitcher(isOnboarding: boolean): string {
        return isOnboarding ? InstrumentationService.events.ConvergedOnboarding : InstrumentationService.events.ScheduleTeamPicker;
    }

    /**
     * Get instrumentation event name for the provision schedule team UX flow
     */
    public static getInstrumentationEventNameForProvisionScheduleTeam(isOnboarding: boolean): string {
        return isOnboarding ? InstrumentationService.events.ConvergedOnboarding : InstrumentationService.events.ProvisionScheduleTeam;
    }
}
