import * as moment from "moment";
import LoginComponent from "./components/LoginComponent";
import { DataServices, InstrumentationService, StaffHubErrorCodes } from "sh-services";
import { InitializationStage, unblockInitQueue } from "owa-delayed-initialization";
import { ITeamInfoEntity, TeamManagedByTypes } from "sh-models";
import { LoginState } from "./store/schema/LoginViewState";
import { setLoginState, UrlFactory } from "sh-application";
import { StaffHubHttpError } from "./utility/StaffHubError";
import { TeamSettingEntity } from "sh-models";
import { TeamSettingsStore } from "sh-teamsettings-store";
import { TeamStore } from "sh-team-store";
import { trace } from "owa-trace";

/**
 * The bootstrap parameters.
 */
export interface BootstrapConfig {
    /**
     * The group id the current user is viewing.
     */
    groupId: string;

    /**
     * The navigation location path.
     */
    location: string;

    /**
     * The team id the current user is viewing.
     */
    teamId: string;

    /**
     * The current user tenant id.
     */
    tenantId: string;
}

/**
 * Bootstrap the application. Requires a logged in user.
 * @param config The configuration.
 */
export async function bootstrap(config: BootstrapConfig): Promise<void> {
    let bootstrapType = "";
    const { groupId, location, teamId, tenantId } = config;

    try {
        InstrumentationService.setBootstrapStarted();

        // Fire off user and team-related promises in parallel to retrieve team, team settings, time off reasons for the team, and tags for the team
        const userAndTeamPromises: Promise<any>[] = [];

        const getTeamsMarker = InstrumentationService.perfMarkerStart("getTeams");

        userAndTeamPromises.push(DataServices.TeamDataService.getTeams()
            .then(async (teams: ITeamInfoEntity[]): Promise<void> => {
                InstrumentationService.perfMarkerEnd(getTeamsMarker);
                // TODO(Performance): Investigate if we could leverage the previously used team here to reduce branching and simplifying logic only if no impact on TTI.
                if (!groupId && !teamId && teams?.length > 0) {
                    // Check the user only has a single schedule team, select it and load its data
                    const scheduleTeams = teams.filter((team) => team.managedBy == TeamManagedByTypes.Teams);
                    if (scheduleTeams.length == 1) {
                        const selectedTeam = scheduleTeams[0];
                        config.teamId = selectedTeam.id;
                        config.groupId = selectedTeam.groupId;
                        bootstrapType = InstrumentationService.boostrapTypes.TeamPage;
                        await bootstrapSelectedTeam(selectedTeam.tenantId, selectedTeam.groupId, selectedTeam.id, selectedTeam.name);
                    }
                }
            })
        );

        const getUserSettingsMarker = InstrumentationService.perfMarkerStart("getUserSettings");

        userAndTeamPromises.push(DataServices.TeamDataService.getUserSettings()
            .catch(() => {
                trace.warn("bootstrap for get user settings failed");
                // not raising error
            }).then(() => {
                InstrumentationService.perfMarkerEnd(getUserSettingsMarker);
            })
        );

        // now that we got the teams, check if the team the user is requesting is actually one
        // of their teams.
        if (groupId || teamId) {
            bootstrapType = InstrumentationService.boostrapTypes.TeamPage;
            userAndTeamPromises.push(bootstrapSelectedTeam(tenantId, groupId, teamId));
        } else {
            if (UrlFactory.isLandingPageLocation(location)) {
                bootstrapType = InstrumentationService.boostrapTypes.LandingPage;
            } else {
                throwNoTeamError();
            }
        }

        // Promise.all will resolve when all promises have resolved or will reject with the value of the first rejected promise
        await Promise.all(userAndTeamPromises);
        setLoginState(LoginState.LoggedInBootstrapComplete);
    } catch (error) {
        trace.warn("bootstrap failed: " + error);
        // we don't want to set login state to LoggedInBootstrapComplete if an error was thrown while trying to bootstrap
    } finally {
        InstrumentationService.logDataBootstrapComplete(bootstrapType);
    }
}

/**
 * Bootstrap the data needed for a selected team.
 * @param tenantId TenantId of the selected team
 * @param groupId GroupId of the selected team. Present when the user navigates to Shifts from deeplink or using notification.
 * @param teamId TeamId of the selected team. Not present, when the user navigates to Shifts from deeplink or using notification.
 * @param name Name of the selected team
 */
export async function bootstrapSelectedTeam(tenantId: string, groupId: string, teamId: string, name: string = ""): Promise<void> {
    let bootstrapTeamMarker = "bootstrapSelectedTeam";
    bootstrapTeamMarker = InstrumentationService.perfMarkerStart(bootstrapTeamMarker);
    try {
        // Store the team and tenant id within the team store to unblock the loading of the schedules page
        DataServices.TeamDataService.setBasicTeamData(tenantId, groupId, teamId, name);
        let teamPermissionCalled = false;

        // Firing the permission call earlier when user navigat from Team, but not waiting for it to complete so it won't impact TTI.
        if (!!teamId) {
            teamPermissionCalled = true;
            DataServices.TeamPermissionsDataService.getTeamPermissions(tenantId, teamId);
            }

        // Call getTeam to load it into the TeamStore (if we didn't already fetch it by groupId)
        const fetchedTeam = !!teamId ? await DataServices.TeamDataService.getTeam(teamId) : await DataServices.TeamDataService.getGroup(groupId);
        teamId = fetchedTeam.id;
        groupId = fetchedTeam.groupId;

        // TO DO: Add data service to lookup for groupId using teamId.
        // Firing the permission call after the getTeam call when user navigates from the deeplink.
        if (teamPermissionCalled == false && !!teamId) {
            DataServices.TeamPermissionsDataService.getTeamPermissions(tenantId, teamId);
        }

        const storedTeam = TeamStore();
        let teamSize = 0;

        if (storedTeam.team.id === fetchedTeam.id) {
            teamSize = storedTeam.members ? storedTeam.members.size : 0;
        }

        InstrumentationService.setCustomDimensionForCurrentTeam(fetchedTeam, teamSize);

        // Set the team's timezone as the default timezone for Moment.js
        // All moment datetimes that are created _after_ this will use this timezone for formatted output and datetime calculations (eg, startOf()).
        //
        // NOTE:  Avoid creating any moment objects before this call (such as in constructors that execute before the team timezone is loaded),
        // since they will _not_ be automatically updated to use the team timezone.
        //
        // NOTE:  We should use moment for all date time representations so that we get the benefits of this time zone handling.
        // For external modules (such as Office Fabric's calendar picker) that use Javascript Date, date values must be converted to/from team timezone.
        const teamTimeZone: string = TeamSettingEntity.getValueOrDefault(TeamSettingsStore().timeZoneOlsonCode);
        if (teamTimeZone) {
            const currentTimezone = moment().tz();
            if (currentTimezone !== teamTimeZone) {
                moment.tz.setDefault(teamTimeZone);
            }
        }

        // if we have a teamId, save it as the most recent used
        LoginComponent.setMostRecentTeamId(teamId);
        if (teamId) {
            // This unblocks the queue of callbacks that can't run until bootstrap is complete
            unblockInitQueue(InitializationStage.Bootstrap);
            // This unblocks the queue of lazy loaded bundles.
            // Without it, no lazy or on demand bundles (that use owa-bundling lib) will not load.
            unblockInitQueue(InitializationStage.InitialRender);
        }
    } catch (error) {
        trace.warn("bootstrap for getTeam failed: " + error);
        // rethrow the error so that bootstrap knows there was an error bootstraping the selected team and doesn't incorrectly set
        // the login state to bootstrap complete
        throw error;
    } finally {
        InstrumentationService.perfMarkerEnd(bootstrapTeamMarker);
    }
}

function throwNoTeamError() {
    let noTeamError = {
        staffHubInnerErrorCode: StaffHubErrorCodes.TeamNotFound
    } as StaffHubHttpError;

    throw noTeamError;
}