import { ComponentClassNames, mergeStyleSets } from "@microsoft/shifts-ui-core";

/**
 * Gets the 'PeopleCount' component CSS class names.
 * @returns The 'PeopleCount' component CSS class names.
 */
export const getClassNames = (): ComponentClassNames<"icon" | "text"> => {
    // TODO: Use 'Theme' from Fluent instead of CSS variables.
    const textColor = "var(--app-gray-03)";

    return mergeStyleSets({
        icon: {
            // Without display flex, the height is larger than the icon size so it's not centered.
            display: "flex",
            height: "1rem",
            marginLeft: "0.5rem",
            width: "1rem"
        },
        text: {
            color: textColor
        }
    });
};