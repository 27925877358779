import * as moment from "moment";
import StringsStore from "../store";
import { action } from "satcheljs/lib/legacy";

export let setCurrentLocale = action("setCurrentLocale")(
    function setCurrentLocale(locale: string) {
        // convert so that moment understands the locale (maybe we can have the localizers do this as
        // these are the locals that are listed on the string file names)
        locale = locale.toLowerCase();
        if (locale === "zh-hant") {
            locale = "zh-tw";
        } else if (locale === "zh-hk") { // Chinese (Hong Kong) falls back to Taiwanese Mandarin
            locale = "zh-tw";
        }else if (locale === "zh-mo") {  // Chinese (Macau) falls back to Taiwanese Mandarin
            locale = "zh-tw";
        } else if (locale === "zh-sg") { // Chinese (Singapore) falls back to Chinese
            locale = "zh-cn";
        } else if (locale === "zh-hans") {
            locale = "zh-cn";
        } else if (locale === "fil") {
            // moment has fil has tl-ph
            locale = "tl-ph";
        } else if (locale === "nb-no") {
            locale = "nb";
        }

        StringsStore().currentLocale = locale;

        // set the locale for moment
        moment.locale(locale);
    });