import { createLazyComponent, LazyModule } from "owa-bundling";

const lazyModule = new LazyModule(() =>
    import(/* webpackChunkName: "ImportScheduleDialog" */ './lazyIndex')
);

/* lazy components */
export let ImportScheduleDialog = createLazyComponent(lazyModule, m => m.ImportScheduleDialog);

/* Register Mutator */
export { default as setDialogErrorMessage } from "./mutators/setDialogErrorMessage";
export { default as setErrorMessage } from "./mutators/setErrorMessage";
export { default as setImportScheduleJob } from "./mutators/setImportScheduleJob";
export { default as setImportScheduleJobResult } from "./mutators/setImportScheduleJobResult";
export { default as setTryImportingAgainButton } from "./mutators/setTryImportingAgainButton";
export { default as resetStore } from "./mutators/resetStore";
export { default as setImportScheduleInProgress } from "./mutators/setImportScheduleInProgress";
export { default as setReachedMaxRetries } from "./mutators/setReachedMaxRetries";

/* Register Orchestrators */
export { default as getDownloadErrorFile } from "./orchestrators/getDownloadErrorFile";
export { default as getImportScheduleJobStatus } from "./orchestrators/getImportScheduleJobStatus";
export { default as importSchedule } from "./orchestrators/importSchedule";
export { default as restartImport } from "./orchestrators/restartImport";
export { default as getDownloadSampleFile } from "./orchestrators/getDownloadSampleFile";

/* View state */
export { default as importScheduleDialogViewState } from "./store/store";