import { Moment } from "moment";

const memoize = require('fast-memoize');

type MemoizeFunc = (...args: any[]) => any;
type MemoizeStore = { [key: string]: number | boolean | string | Moment | Moment[] };
type MemoizeSerializer = <T>(args: T[] | T) => string;

const _memoizestores_: Array<MemoizeStore> = [];

/**
 * Used to clear all the memoize memory stores (caches).
 */
export function clearMemoizeStores() {
    _memoizestores_.forEach((store) => {
        for (const prop of Object.getOwnPropertyNames(store)) {
            delete store[prop];
        }
    });
}

/**
 * Memoize a expensive function that is called many times.
 * @param fn function to memoize
 * @param memStore reference to a MemoizeStore object (simple javascript key/value objec)
 * @param serializer serializer object used to serialize the function parameters into the "key" used in the memStore
 * @returns memoized function that can be called just the original function
 */
export function memoizeUtility(fn: MemoizeFunc, memStore: MemoizeStore, serializer: MemoizeSerializer) {
    return memoize(fn, {
        cache: {
            create() {
                const store = memStore;
                _memoizestores_.push(store);
                return {
                    has(key: string) { return (key in store); },
                    get(key: string) { return store[key]; },
                    set(key: string, value: number) { store[key] = value; }
                };
            }
        },
        serializer: (args: any[]) => {
            return serializer(args);
        }
    });
}

const CLEARMEMEMOIZESTORES_TIMER_INTERVAL = 10 * 60 * 1000; // 10 minutes

function periodicallyClearMemoizeStores() {
    // don't set the interval during unit tests - this prevents leaving an open handle
    if (!UNIT_TEST_MODE) {
        setInterval(() => {
            clearMemoizeStores();
        }, CLEARMEMEMOIZESTORES_TIMER_INTERVAL);
    }
}

// Start the interval for auto clearing the memoize cache
// This is to prevent a run-away memory issue. We also clear the stores if the user refreshes
// the app or changes timezone.
// The memoize cache is here to help with performance. Clearing it every now and again will have
// little impact on the user.
periodicallyClearMemoizeStores();