import { mutatorAction } from "satcheljs";
import { TeamSettingsViewState } from "../../../../store/schema/TeamSettingsViewState";

/**
 * Sets the flag that there was an error in getting team settings.
 */
export default mutatorAction("SET_ERROR_GETTING_SETTINGS",
    function setErrorGettingSettings(
        viewState: TeamSettingsViewState,
        erroGettingSettings: boolean) {
        viewState.errorGettingSettings = erroGettingSettings;
    });