import { orchestrator } from "satcheljs";
import AvailabilityUtils from "sh-application/utility/AvailabilityUtils";
import ConflictUtils from "sh-application/utility/ConflictUtils";
import { DismissedConflictsStore, calculateShiftAvailabilityConflict, createShiftAvailabilityConflict } from "sh-conflict-store";
import { ECSConfigKey, ECSConfigService } from "sh-services";

import { TeamSettingsStore } from "../../sh-teamsettings-store";

/**
 * This orchestrator is calculates shift-availability conflicts
 * It populates the ConflictStore() with the conflict entities,
 * as well as maintains the true conflict count.
 */
export default orchestrator(calculateShiftAvailabilityConflict, (actionMessage) => {
    const shift = actionMessage.shift;
    const isRequestShift = actionMessage.isRequestShift;
    const shiftStartTime = shift.startTime;
    const shiftEndTime = shift.endTime;
    const availabilities = actionMessage.availabilities;
    const memberAvailabilities = availabilities && availabilities.get(actionMessage.memberId);

    const conflictDismissEnabled = ECSConfigService.isECSFeatureEnabled(ECSConfigKey.EnableConflictsDismissal);

    let dismissedConflicts;
    if (conflictDismissEnabled) {
        dismissedConflicts = DismissedConflictsStore().shiftIdToEntityIdMap.get(shift.id);
    }
    // if availabilities is set for this member
    if (memberAvailabilities && memberAvailabilities.length > 0) {
        const scheduleTimeZone = TeamSettingsStore().timeZoneOlsonCode.value;

        // if this date range spans across multi-days, so we need to calculate the availability conflict for each day in the recurrence pattern, hence this is an array
        const conflictingAvailabilities = AvailabilityUtils.getConflictingAvailabilities(memberAvailabilities, shiftStartTime, shiftEndTime, scheduleTimeZone);

        for (const conflictingAvailability of conflictingAvailabilities) {
            const alreadyDismissed = conflictDismissEnabled ? ConflictUtils.isAlreadyDismissedAvailabilityConflict(dismissedConflicts, conflictingAvailability.id) : false;

            if (!alreadyDismissed) {
                // update ConflictStore with the conflict, and increase the count
                createShiftAvailabilityConflict(shift, conflictingAvailability, isRequestShift, actionMessage.memberId);
            }
        }
    }
});
