import * as React from "react";
import { Dialog, DialogFooter, DialogType, PrimaryButton, DefaultButton } from "@fluentui/react";
import { observer } from "mobx-react";
import {
    connectorAuthDialogStore,
    setIsConnectorAuthDialogOpen,
    resetConnectorAuthDialogTriesCount
} from "./lib";
import ConnectorAuthService from "sh-services/lib/ConnectorAuthService/ConnectorAuthService";
import StringsStore from "sh-strings/store";
import { InstrumentationService } from "sh-services";
import { getGenericEventPropertiesObject } from "sh-instrumentation";
import setGlobalSpinnerMessageState from "../../../mutatorActions/setGlobalSpinnerMessageState";

const styles = require("./ConnectorAuthDialog.scss");

export const ConnectorAuthDialog: React.FunctionComponent = observer(() => {
    const strings: Map<string, string> =
        StringsStore().registeredStringModules.get("connectorAuthDialog").strings;

    const connectorAuthentication = () => {
        setIsConnectorAuthDialogOpen(connectorAuthDialogStore(), false);
        setGlobalSpinnerMessageState(strings.get("spinnerMessage"));
        ConnectorAuthService.launchPopup();
        InstrumentationService.logEvent(InstrumentationService.events.RequestWFMWeb, [
            getGenericEventPropertiesObject(
                InstrumentationService.properties.ConnectorAuthenticationOkClicked,
                true
            )
        ]);
    };

    const cancelAndClose = () => {
        setIsConnectorAuthDialogOpen(connectorAuthDialogStore(), false);
        resetConnectorAuthDialogTriesCount(connectorAuthDialogStore());
        ConnectorAuthService.cancelAuthenticationFlow();
        InstrumentationService.logEvent(InstrumentationService.events.RequestWFMWeb, [
            getGenericEventPropertiesObject(
                InstrumentationService.properties.ConnectorAuthenticationCancelClicked,
                true
            )
        ]);
    };
    return (
        <>
            <Dialog
                hidden={
                    !connectorAuthDialogStore().isOpen || connectorAuthDialogStore().triesCount > 0
                }
                dialogContentProps={{
                    type: DialogType.normal,
                    title: strings.get("dialogTitle")
                }}
                onDismiss={cancelAndClose}
            >
                <div className={styles.connectorDialogContent}>{strings.get("dialogContent")}</div>
                <DialogFooter className={styles.connectorAuthDialogFooter}>
                    <DefaultButton onClick={cancelAndClose} data-testid="connector-cancel-button">
                        {strings.get("cancelButton")}
                    </DefaultButton>
                    <PrimaryButton
                        onClick={connectorAuthentication}
                        data-testid="connector-auth-button"
                    >
                        {strings.get("authButton")}
                    </PrimaryButton>
                </DialogFooter>
            </Dialog>

            <Dialog
                hidden={
                    !connectorAuthDialogStore().isOpen ||
                    connectorAuthDialogStore().triesCount < 1 ||
                    connectorAuthDialogStore().triesCount > 3
                }
                dialogContentProps={{
                    type: DialogType.normal,
                    title: strings.get("tryAgainTitle")
                }}
                onDismiss={cancelAndClose}
            >
                <div className={styles.connectorDialogContent}>
                    {strings.get("tryAgainContent")}
                </div>
                <DialogFooter className={styles.connectorAuthDialogFooter}>
                    <DefaultButton onClick={cancelAndClose} data-testid="connector-cancel-button">
                        {strings.get("cancelButton")}
                    </DefaultButton>
                    <PrimaryButton
                        onClick={connectorAuthentication}
                        data-testid="connector-auth-try-again-button"
                    >
                        {strings.get("tryAgainButton")}
                    </PrimaryButton>
                </DialogFooter>
            </Dialog>

            <Dialog
                hidden={
                    !connectorAuthDialogStore().isOpen || connectorAuthDialogStore().triesCount < 4
                }
                dialogContentProps={{
                    type: DialogType.normal,
                    title: strings.get("failAuthTitle")
                }}
                onDismiss={cancelAndClose}
            >
                <div className={styles.connectorDialogContent}>
                    {strings.get("failAuthcontent")}
                </div>
                <DialogFooter className={styles.connectorAuthDialogFooter}>
                    <PrimaryButton
                        onClick={cancelAndClose}
                        data-testid="connector-auth-got-it-button"
                    >
                        {strings.get("failAuthButton")}
                    </PrimaryButton>
                </DialogFooter>
            </Dialog>
        </>
    );
});
