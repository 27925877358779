import TeamStore from "sh-stores/sh-team-store/store";
import teamsUpdated from "sh-stores/sh-teaminfo-store/actions/teamsUpdated";
import { orchestrator } from "satcheljs";
import { setTeamData } from "sh-stores/sh-team-store/actions/setTeamData";

export default orchestrator(teamsUpdated, async actionMessage => {
    for (let i = 0; i < actionMessage.teams.length; i++) {
        const team = actionMessage.teams[i];
        // Update the team store when we get a list of updated teams
        if (team  && TeamStore().team && team.id == TeamStore().team.id) {
            setTeamData(team);
        }
    }
});