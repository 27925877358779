import DateUtils from "sh-application/utility/DateUtils";
import ShiftUtils from "sh-application/utility/ShiftUtils";
import ThemeUtils from "sh-application/utility/ThemeUtils";
import TimeOffUtils from "sh-application/utility/TimeOffUtils";
import { extendObservable } from "mobx";
import {
    IBaseShiftEntity,
    ITeamInfoEntity,
    ShiftEntity,
    ShiftTypes,
    TimeOffReasonStatus
    } from "sh-models";
import {
    initializeLocalObservableTimeoff,
    setIsAllDayInStore,
    setTimeoffInStore,
    setTimeOffReasonIdTimeoff,
    updateEndDateTimeoff,
    updateEndTimeTimeoff,
    updateStartDateTimeoff,
    updateStartTimeTimeoff,
    updateThemeTimeoff
    } from "../index";
import { MobxUtils } from "sh-application";
import { Moment } from "moment";
import { orchestrator } from "satcheljs";
import { TeamDataService } from "sh-services";
import { TimeOffReasonsStore } from "sh-stores/sh-timeoffreasons-store";

/**
 * Initializes a timeoff object in the store and marks it. If the panel was passed a timeoff in the
 * externally modified ShiftEditor, this orchestrator will use this shift. Otherwise a new shift is created.
 */
export const initializeLocalObservableTimeofforchestrator =  orchestrator(initializeLocalObservableTimeoff, actionMessage => {
    const { shiftEditorViewState, timeoff, memberId, newTimeOffStartTime, isEditTimeOff } = actionMessage;

    const team: ITeamInfoEntity = TeamDataService.getCurrentTeam();
    const teamId = team ? team.id : "";
    const tenantId = team ? team.tenantId : "";

    let observableShift: IBaseShiftEntity = null;
    let originalTimeoff: IBaseShiftEntity = ShiftEntity.clone(timeoff);

    // When editing an existing shift, just make all the existing properties observable
    // If we are editing a unshared deleted shift (title string will still say Add), the panel
    // behaves as though this was the add of a new shift. But when changes are saved, we will edit the existing
    // deleted shift
    const isUnsharedDeletedShift = ShiftUtils.isUnsharedDeletedShift(timeoff);
    if (timeoff) {
        // for a time off that is deleted and unshared, treat it as a new timeoff but re-use the same cloned object instead of the old one.
        observableShift = ShiftEntity.clone(timeoff);
    // When creating a new shift, create a new shift or open shift
    } else {
            observableShift = ShiftEntity.createEmptyObject(
                tenantId,
                teamId,
                memberId || memberId,
                ShiftTypes.Absence);
    }

    // extendObservable (target, props) copies all props value pairs onto target and marks them as observable.
    // In this panel we need the Shift component to react to changes to properties of the shift, so we mark every property of the shift as observable
    setTimeoffInStore(shiftEditorViewState, extendObservable(observableShift, {...observableShift}));

    let startDateTime: Moment;
    let endDateTime: Moment;
    let isAllDayRange: boolean;

    if (originalTimeoff && isEditTimeOff) {
        // Edit Time Off:  Initialize times from the existing Time Off shift
        startDateTime = originalTimeoff.startTime.clone();
        endDateTime = originalTimeoff.endTime.clone();

        isAllDayRange = DateUtils.isAllDayTimeRange(startDateTime, endDateTime);
    } else {
        // New Time Off:  Initialize times from the passed in New Time Off properties
        startDateTime = newTimeOffStartTime.clone();
        // BUG FIX 670705: End time will be specified for add time off panel,
        // Set a default time during the start day
        // so that it can be adjusted by the all day handling below.
        endDateTime = startDateTime.clone();
        if (DateUtils.isStartOfDay(endDateTime)) {
            endDateTime.add(1, "millisecond");
        }
        isAllDayRange = true;
    }

    if (TimeOffUtils.shouldDisableAllDayTimeOff()) {
        // set all day range to false for the teams which do not have Multi-day time offs applicable
        isAllDayRange = false;

        updateStartDateTimeoff(TimeOffUtils.normalizeStartEndDateValue(startDateTime));
        updateEndDateTimeoff(TimeOffUtils.normalizeStartEndDateValue(endDateTime));

        // set default time in time pickers
        if (!isEditTimeOff) {
            // this is the doubtful area
            updateStartTimeTimeoff(TimeOffUtils.calculateDefaultStartTimeForDate(startDateTime));
            updateEndTimeTimeoff(TimeOffUtils.calculateDefaultEndTimeForDate(endDateTime, true));
        } else {
            updateStartTimeTimeoff(startDateTime.clone());
            updateEndTimeTimeoff(endDateTime.clone());
        }
    } else {
        // For All Day ranges, normalize the end datetime to be the end of day for the last day
        // of the timeoff date range.
        // From older comments, it looks like we may have older TimeOff data such that the end datetime for All Day
        // ranges is represented differently.  So sometimes the end datetime is set to midnight of the following day, and
        // sometimes it is set to the end of day (ie, right before midnight).  So in this initialization code, we check
        // for both cases and normalize to the end of day.
        if (isAllDayRange) {
            // End time may be at midnight of the following day
            let lastDayInRange = DateUtils.getLastDayForEndDate(endDateTime);
            endDateTime = lastDayInRange.endOf("day");
        }

        updateStartTimeTimeoff(startDateTime.clone());
        updateEndTimeTimeoff(endDateTime.clone());

        updateStartDateTimeoff(TimeOffUtils.normalizeStartEndDateValue(startDateTime));
        updateEndDateTimeoff(TimeOffUtils.normalizeStartEndDateValue(endDateTime));
    }

    setIsAllDayInStore(isAllDayRange);

    // Time off reason
    if (!isUnsharedDeletedShift && originalTimeoff && originalTimeoff.timeOffReasonId) {
        setTimeOffReasonIdTimeoff(originalTimeoff.timeOffReasonId);
    } else if (TimeOffReasonsStore().timeOffReasons && TimeOffReasonsStore().timeOffReasons.size) {
        // find the time of reason which is active, and then assign as default. Bug: TASK 424808
        const timeoffReason = MobxUtils.MapKeysToReadonlyArray(TimeOffReasonsStore().timeOffReasons).find(key => TimeOffReasonsStore().timeOffReasons.get(key).state === TimeOffReasonStatus.Active);
        setTimeOffReasonIdTimeoff(timeoffReason);
    } else {
        setTimeOffReasonIdTimeoff("");
    }

    // Theme
    if (!isUnsharedDeletedShift && originalTimeoff && originalTimeoff.theme) {
        updateThemeTimeoff(originalTimeoff.theme);
    } else {
        updateThemeTimeoff(ThemeUtils.timeOffDefaultTheme); // Default theme
    }
});
