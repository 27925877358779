import { mutator } from "satcheljs";

import { setUserPictureLoadingState } from "../actions/setUserPictureLoadingState";
import { UsersPicturesStore } from "../store/UsersPicturesStore";

/**
 * Sets user profile picture loading state.
 */
export const setUserPictureLoadingStateMutator = mutator(setUserPictureLoadingState, (action) => {
    const { loadingState, userId } = action;

    UsersPicturesStore().loadingStates.set(userId, loadingState);
});
