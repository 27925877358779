import { action } from "satcheljs";
import { ITimeOffReasonEntity } from "sh-models";

const setTimeOffReasons = action(
    "SET_TIME_OFF_REASONS", (
        timeOffReasons: ITimeOffReasonEntity[]
    ) => ({
        timeOffReasons: timeOffReasons
    })
);

export default setTimeOffReasons;
