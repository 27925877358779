import { DataProvider } from "sh-services/dataproviders/DataProvider";
import RestClient from "sh-rest-client";
import { ITeamDatabase } from "sh-services/data/ITeamDatabase";
import { ITeamInfoEntity } from "sh-models";
import { teamDeleted, TeamInfoStore, teamsUpdated } from "sh-stores";

/**
 * Team Data Provider for an individual team
 */
export class TeamDataProvider  extends DataProvider<ITeamInfoEntity> {

    protected teamDatabase: ITeamDatabase;
    protected tenantId: string;
    protected teamId: string;

    constructor(teamDatabase: ITeamDatabase, tenantId: string, teamId: string) {
        super();
        this.teamDatabase = teamDatabase;
        this.tenantId = tenantId;
        this.teamId = teamId;
    }

    /**
     * Return data if it's found in memory (otherwise return undefined)
     */
    async getDataFromMemory() {
        return TeamInfoStore().teams && TeamInfoStore().teams.get(this.teamId) || undefined;
    }

    /**
     * Return data if it's found in the database (otherwise return undefined)
     */
    async getDataFromDatabase() {
        return await this.teamDatabase.getTeam(this.teamId);
    }

    /**
     * Make an HTTP request to fetch the data from the network
     */
    async getDataFromNetwork() {
        const teamDetailsResponse = await RestClient.getTeam(this.tenantId, this.teamId);
        return teamDetailsResponse && teamDetailsResponse.team;
    }

    /**
     * Set data in memory
     */
    async setDataInMemory(data: ITeamInfoEntity) {
        teamsUpdated([data]);
    }

    /**
     * Set data in the database
     */
    async setDataInDatabase(data: ITeamInfoEntity) {
        if (data) {
            return await this.teamDatabase.setTeam(data);
        }
    }

    /**
     * Make a network call to update the data
     */
    async saveDataToNetwork(data: ITeamInfoEntity): Promise<ITeamInfoEntity> {
        return await RestClient.setTeam(data);
    }

    /**
     * Delete data if it's found in memory
     */
    async hardDeleteDataFromMemory(): Promise<void> {
        teamDeleted(this.teamId);
    }

    /**
     * Delete data if it's found in the database
     */
    async hardDeleteDataFromDatabase(): Promise<void> {
        return await this.teamDatabase.deleteTeam(this.teamId);
    }
}