import * as React from "react";
import DataServiceContext from "./DataServiceContext";
import IAllDataServices from "../../sh-services/dataservice/IAllDataServices";
import { IDataServiceContextProps } from "./IDataServiceContextProps";

/**
 * This inserts a Higher-Order Component (HOC) around the wrapped component. It will pass through all the props from the parent component
 * and also add the dataservices as a prop. This should allow us to use the DataServices within the WrappedComponent but allow us to easily mock it out for testing.
 * The PropsType of WrappedComponent needs to extend IDataServiceContextProps
 * This HOC extracts the dataservices from the React context, it assumes that a parent component has injected the dataServices into the React context tree.
 * We have done this using <DataServiceContext.Provider value={ DataServices } > in the index.ts page, so it should be available to any child of our <App>
 * @param WrappedComponent The wrapped component that needs access to the dataservices
 */
const withDataServices = <PropsType extends IDataServiceContextProps>(WrappedComponent: React.ComponentClass<PropsType>): React.ComponentClass<PropsType> =>
   class WithDataServices extends React.Component<PropsType> {
      // Specify a display name to ease debugging
      public static displayName = `WithDataServices(${ WrappedComponent.displayName || WrappedComponent.name || 'Component' })`;

      // Specify defaultProps so TypeScript doesn't force the consumers of the Component to specify dataServices prop
      public static defaultProps = {
          dataServices: null as IAllDataServices
      } as Partial<PropsType>;

      public render() {
         return (
            <DataServiceContext.Consumer>
               { dataServices => <WrappedComponent { ...this.props as PropsType } dataServices={ dataServices } /> }
            </DataServiceContext.Consumer>
         );
      }
   };

export default withDataServices;