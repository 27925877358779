import { mutatorAction } from "satcheljs";
import { shiftEditorViewState } from "..";

/**
 * Sets the value of isOpenShift in the shift editor view state.
 * If isOpenShift is false, also sets isCrossLocationOpenShift to false.
 * @param isOpenShift - The new value for isOpenShift.
 */
export default mutatorAction("setIsOpenShift", function setIsOpenShift(isOpenShift: boolean) {
    shiftEditorViewState().isOpenShift = isOpenShift;

    if (!isOpenShift) {
        shiftEditorViewState().openShift.isCrossLocationOpenShift = false;
    }
});