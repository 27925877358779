import { ANDOPERATORTYPE, OROPERATORTYPE } from "sh-application/../StaffHubConstants";
import { IUserQuery, UserPolicySettingsEntity } from "sh-models";
import { ECSConfigKey, ECSConfigService } from "sh-services";
import { UsersPicturesStore } from "sh-stores/sh-users-pictures-store";

/**
 * Utilities for Users
 */
export default class UserUtils {
    /**
     * Returns true if schedule owner permissions are enabled for user, else false
     */
    public static isScheduleOwnerPermissionsEnabled(userPolicySettings: UserPolicySettingsEntity): boolean {
        const scheduleOwnerECSFlagEnabled = ECSConfigService.isECSFeatureEnabled(ECSConfigKey.EnableScheduleOwnerPermission);
        return scheduleOwnerECSFlagEnabled && userPolicySettings && userPolicySettings.scheduleOwnerPermissionEnabled;
    }

    /**
     * Gets the user profile picture URL from state.
     * @param userId The user unique identifier.
     * @returns The user profile picture URL else empty string.
     */
    public static getProfilePictureUrl(userId: string): string {
        return UsersPicturesStore().pictureUrls.get(userId) ?? "";
    }

    /**
     * Break down a user search.
     * Splits a query string at the first space and assigns the first token to firstNameStartsWith and second to lastNameStartsWith
     * If not space, use the query for firstNameStartsWith, lastNameStartsWith, and emailStartsWith
     * @param query the query string
     * @returns {firstNameStartsWith: string, lastNameStartsWith: string, emailStartsWith: string, operatorType: string}
     */
    public static breakDownUserSearch(query: string): IUserQuery {
        let operatorType: string;
        let firstNameStartsWith = "";
        let lastNameStartsWith = "";
        let emailStartsWith = "";
        // if the query has a space, split it and assign the first word to firstName and the second word to lastname
        let splitIndex = query.indexOf(" ");
        if (splitIndex === -1) {
            // no space, set query to all filter properties
            firstNameStartsWith = lastNameStartsWith = emailStartsWith = query;
            operatorType = OROPERATORTYPE;
        } else {
            firstNameStartsWith = query.slice(0, splitIndex);
            lastNameStartsWith = query.slice(splitIndex + 1);
            // User entered multiple words
            operatorType = ANDOPERATORTYPE;
        }
        return { firstNameStartsWith: firstNameStartsWith,
            lastNameStartsWith: lastNameStartsWith,
            emailStartsWith: emailStartsWith,
            operatorType: operatorType };
    }
}
