import * as H from "history";
import { action } from "satcheljs";
import { StaffHubHttpError } from "sh-application";

/**
 * Triggers handlers for errors that block login/bootstrap
 */
export default action(
    "HANDLE_BLOCKING_BOOTSTRAP_ERROR",
    (
        staffHubHttpError: StaffHubHttpError,
        history: H.History
    ) => ({
        staffHubHttpError: staffHubHttpError,
        history: history
    })
);