import setErrorMessageFromException from "../actions/setErrorMessageFromException";
import StaffHubHttpErrorUtils from "sh-application/utility/StaffHubHttpErrorUtils";
import { orchestrator } from "satcheljs";
import { setErrorMessageInStore } from "../";

/**
 * This orchestrator will call the api to approve or decline a timeoff request
 */
export default orchestrator(setErrorMessageFromException, async actionMessage => {
    const { error } = actionMessage;
    const errorMessage = StaffHubHttpErrorUtils.getErrorMessage(error);
    const showRefreshButton = StaffHubHttpErrorUtils.isConcurrencyConflictError(error);
    setErrorMessageInStore(errorMessage, showRefreshButton);
});
