import groupedRequestDetailsStore from "../groupedrequestdetails/store/store";
import { IGroupedOpenShiftRequestEntity, IShiftRequestEntity } from "sh-models";
import { loadDataForGroupedRequest } from "../groupedrequestdetails/store/store";
import { loadDataForRequest } from "../requestdetails/store/store";
import { orchestrator } from "satcheljs";
import { setSelectedRequest, ShiftRequestPanelSelectedItemType, shiftRequestsPanelStore } from "../";
import { trace } from "owa-trace";
import { updatedRegularAndGroupedShiftRequests } from "sh-stores/sh-shiftrequests-store";

/**
 * This orchestrator listens to updated shift request items.
 * If there is an update to the currently selected item, all new data related to the request (regular or grouped) is loaded
 */
export default orchestrator(updatedRegularAndGroupedShiftRequests, async actionMessage => {
    const { shiftRequests, groupedOpenShiftRequests } = actionMessage;
    const selectedRequestId: string = shiftRequestsPanelStore().selectedRequestId;

    if (shiftRequests && shiftRequestsPanelStore().selectedItemType === ShiftRequestPanelSelectedItemType.RegularShiftRequest) {
        const updatedVersionOfSelectedShiftRequest: IShiftRequestEntity = shiftRequests.find((shiftRequest) => shiftRequest.id === selectedRequestId);
        if (updatedVersionOfSelectedShiftRequest) {
            loadDataForRequest(updatedVersionOfSelectedShiftRequest);
        }
    } else if (groupedOpenShiftRequests && shiftRequestsPanelStore().selectedItemType === ShiftRequestPanelSelectedItemType.GroupedOpenShift) {
        if (groupedOpenShiftRequests.has(selectedRequestId)) {
            const groupedOpenShiftRequest: IGroupedOpenShiftRequestEntity = groupedOpenShiftRequests.get(selectedRequestId);
            if (groupedOpenShiftRequest) {
                loadDataForGroupedRequest(groupedOpenShiftRequest);
            } else {
                // The state of the selected item was changed (InProgress to Completed)
                // Show the unselected state
                setSelectedRequest(null);
            }
        }
    } else if (groupedRequestDetailsStore.groupedOpenShiftRequest && groupedOpenShiftRequests.has(groupedRequestDetailsStore.groupedOpenShiftRequest.id)) {
        const updatedVersionOfGroupedShiftRequest: IGroupedOpenShiftRequestEntity = groupedOpenShiftRequests.get(groupedRequestDetailsStore.groupedOpenShiftRequest.id);
        if (updatedVersionOfGroupedShiftRequest) {
            loadDataForGroupedRequest(updatedVersionOfGroupedShiftRequest);
        } else {
            trace.info("Updated grouped open shift request entity not found while syncing open shift approval callout.");
            // Close the callout because a sync occured that resulted in the invalidation of the current grouped open shift request.
            // This could happen when the only request for a shift is cancelled while the callout is open
            if (groupedRequestDetailsStore.closeCallback) {
                groupedRequestDetailsStore.closeCallback();
            }
        }
    }
});