import AuthService from "./AuthService";
import axios, {
    AxiosError,
    AxiosInstance,
    AxiosRequestConfig,
    AxiosResponse,
    Method
    } from "axios";
import ClientVersionService from "./ClientVersionService";
import ConnectorAuthService from "./ConnectorAuthService/ConnectorAuthService";
import StringsStore from "sh-strings/store";
import {
    ACCEPTLANGUAGEHEADER,
    APIVERSIONHEADER,
    AUTHORIZATIONKEY,
    BEARERKEY,
    CLIENTPLATFORMHEADER,
    ClientPlatformTypes,
    CLIENTREQUESTIDHEADER,
    CLIENTSESSIONIDHEADER,
    CLIENTVERSIONHEADER,
    CONNECTOR_AUTH_DATA_HEADER,
    CONTENTTYPEHEADER,
    DEVICEHEADERNAME,
    FIRSTPARTYHEADER,
    NetworkErrorCodes,
    RETRY_COUNT,
    RETRYAFTERHEADER,
    TOKEN_RESOURCES
    } from "../../StaffHubConstants";
import {
    AppSettings,
    createNewHttpError,
    mapHttpError,
    ShiftsWebErrorCodes,
    StaffHubHttpError,
    UrlFactory
    } from "sh-application";
import { ConnectorAuthSettings } from "./ConnectorAuthService/types/ConnectorAuthSettings";
import { CONTENTLENGTHHEADER, SERVICEPROCESSINGTIMEHEADER } from "../../StaffHubConstants";
import {
    createDeferredPromise,
    encodeHeaderForConnectorAuth,
    isConnectorAuthErrorResponse,
    isFailedConnectorAuthentication,
    mapConnectorAuthRequestResponse,
    mapConnectorAuthResponseWithDataSelectors
    } from "./ConnectorAuthService/ConnectorAuthServiceHelper";
import { ECSConfigKey, ECSConfigService, InstrumentationService } from "sh-services";
import { generateUUID, getDurationInMs } from "sh-application/utility/utility";
import { getGenericEventPropertiesObject, InstrumentationEventPropertyInterface, InstrumentationPerfScenario } from "sh-instrumentation";
import { handleConnectorAuthRequest, handleStaffHubServiceError } from "sh-application/components/errorWatcher";
import { StaffHubErrorCodes } from "../lib/StaffHubErrorCodes";
import { trace } from "owa-trace";

// unfortunately the axios-retry package doesn't support ES6 imports properly
let axiosRetry = require("axios-retry");

interface ServiceAPIEndPoint {
    id: string;
    serviceUrl: string;
}

export interface HttpRequestEntity {
    data?: {};
    headers?: {[key: string]: string};
    method: Method;
    silent?: boolean;
    url: string;
    urlSignature: string;
}

/**
 * Interface for a DeviceHeader provider
 */
export interface IDeviceHeaderProvider {
    /**
     * Set the device header for the session.
     * @param {string} deviceHeader
     * @returns {}
     */
    setDeviceHeader(deviceHeader: string): Promise<void>;

    /**
     * Get the device header for the session.
     */
    getDeviceHeader(): Promise<string>;
}
export class NetworkServiceClass {

    private axiosClient: AxiosInstance = null;
    private _deviceHeaderProvider: IDeviceHeaderProvider;
    private _clientPlatform: ClientPlatformTypes = ClientPlatformTypes.TeamsWeb;
    private _clientUICultureCode: string = null;

    private _apiEndPoint: ServiceAPIEndPoint = {
        id: "",
        serviceUrl: ""
    };

    public constructor(axiosClient: AxiosInstance = null, uiCultureCode: string = null, networkTimeout: number = 60000) {
        this.axiosClient = axiosClient || axios.create();
        this._setupDefaultCommonHeaders();
        this._setupRetryMechanism();
        this.setNetworkTimeOut(networkTimeout);
        if (window && window.sa && window.sa.currentUICulture) {
            this._clientUICultureCode = window.sa.currentUICulture;
        }
        if (!!uiCultureCode) {
            this._clientUICultureCode = uiCultureCode;
        }
    }

    /**
     * Override the existing timeOut value with new value
     * @param timeOut network timeout value
     */
    public setNetworkTimeOut(timeOut: number) {
        if (timeOut) {
            this.axiosClient.defaults.timeout = timeOut;
        }
    }

    /**
     * Register a provider for the DeviceHeader
     * @param deviceHeaderProvider DeviceHeader provider
     */
    public setDeviceHeaderProvider(deviceHeaderProvider: IDeviceHeaderProvider) {
        this._deviceHeaderProvider = deviceHeaderProvider;
    }

    /**
     * Sets the url of the service end point
     * @param {ServiceAPIEndPoint} apiEndPoint @nullable
     */
    public setServiceAPIEndPoint(apiEndPoint?: ServiceAPIEndPoint) {
        if (!apiEndPoint) {
            this._clearServiceAPIEndPoint();
        } else {
            this._apiEndPoint = apiEndPoint;
            this.axiosClient.defaults.baseURL = apiEndPoint.serviceUrl;
        }
    }

    /**
     * set clientPlatformType
     * @param clientPlatform Client Platform Type
     */
    public setClientPlatform(clientPlatform: ClientPlatformTypes) {
        this._clientPlatform = clientPlatform;
    }

    /**
     * Sends an http request to the Website's endpoint
     * @param {HttpRequestEntity} requestConfig
     * @returns {Promise<AxiosResponse>}
     * @throws {StaffHubHttpError}
     */
    public async sendHttpRequestToStaffHubWeb(requestConfig: HttpRequestEntity): Promise<AxiosResponse> {
        const endpoint = UrlFactory.getSiteUrl();

        try {
            return await this.sendHttpRequest(endpoint, requestConfig);
        } catch (httpError) {
            // Log the error
            InstrumentationService.logHttpError(httpError, "StaffHubWebHttpException", requestConfig.urlSignature);

            // map this error to a StaffHubHttpError
            const staffHubHttpError: StaffHubHttpError = mapHttpError(httpError);

            throw staffHubHttpError;
        }
    }

    private async updateDeviceHeader(updateDeviceHeaderFromResponse: boolean, response: AxiosResponse) {
        if (updateDeviceHeaderFromResponse && response?.headers) {
            // Update the device header from the response
            const updatedDeviceHeader = response.headers[DEVICEHEADERNAME];
            if (updatedDeviceHeader) {
                if (this._deviceHeaderProvider) {
                    await this._deviceHeaderProvider.setDeviceHeader(updatedDeviceHeader);
                } else {
                    console.warn('deviceHeaderProvider not set');
                }
            }
        }
    }

    private logAndMapHttpError(httpError: AxiosError, urlSignature: string): StaffHubHttpError {
        InstrumentationService.logHttpError(httpError, "StaffHubServiceHttpException", urlSignature);
        return mapHttpError(httpError);
    }

    private async getConnectorAuthInfoAndRetry(
        endpoint: string,
        requestConfig: HttpRequestEntity,
        httpError: AxiosError
    ) {
        const userAction = createDeferredPromise();
        const responseData = mapConnectorAuthRequestResponse(httpError);
        ConnectorAuthService.setSettings({
            ssoUrl: responseData.ssoUrl,
            resolveAuth: userAction.done,
            cancelAuth: userAction.cancel
        } as ConnectorAuthSettings);
        // triggers popup
        handleConnectorAuthRequest();
        // waits for user to interact with the dialog

        try {
            const connectorAuthResponse = await userAction.promise;
            const connectorAuthDataSelected = mapConnectorAuthResponseWithDataSelectors(
                connectorAuthResponse,
                responseData.dataSelectors
            );
            connectorAuthDataSelected.additionalData = responseData.additionalData;
            // add header with token, refreshToken, user email, token type and try same request again
            requestConfig.headers[CONNECTOR_AUTH_DATA_HEADER] = encodeHeaderForConnectorAuth(connectorAuthDataSelected);

            return await this.sendAuthenticatedHttpRequest(
                endpoint,
                requestConfig,
                TOKEN_RESOURCES.ShiftsService
            );
        } catch (error) {
            if (isFailedConnectorAuthentication(error)) {
                // fetching token failed, or was canceled
                trace.info("Connector authentication failed or cancelled");
                return null;
            }
            // failed again
            throw error;
        }
    }

    /**
     * Sends an HTTP request to Shifts Service.
     * @template TResponse The type of the HTTP response body. Defaults to any if not provided to be backward compatible.
     * @param requestConfig The request configuration.
     * @param includeDeviceHeader Whether to include the device header in the HTTP request headers. Defaults to true.
     * @param updateDeviceHeaderFromResponse Whether the update the device header with the value returned from the HTTP response headers. Defaults to true.
     * @param absoluteUrl Whether to use the iframe URL as base URL for this request. Defaults to false which uses Shifts Service URL.
     * @returns The HTTP response.
     */
    public async sendHttpRequestToStaffHubService<TResponse = any>(requestConfig: HttpRequestEntity, includeDeviceHeader: boolean = true, updateDeviceHeaderFromResponse: boolean = true, absoluteUrl: boolean = false): Promise<AxiosResponse<TResponse>> {

        if (ClientVersionService.isUpdatePending()) {
            // return a promise that's never resolved if an update is pending
            // this is so that we prevent any API calls from going on in the background
            const shouldResolve = false;    // added the if() clause to prevent Lint errors
            return new Promise((resolve, reject) => { if (shouldResolve) { resolve({} as AxiosResponse); } });
        }

        // Set endpoint to null to make sure that interceptors dont set the auth header based on this endpoint
        // Having the endpoint url in both endpoint here and the requestconfig.url doesn't break. Axios uses the url in config
        const endpoint = (absoluteUrl) ? null : this.GetServiceAPIEndPointUrl();

        // Setup StaffHub Common Headers
        await this._setupStaffHubServiceHeaders(requestConfig, includeDeviceHeader);
        try {
            const response: AxiosResponse<TResponse> = await this.sendAuthenticatedHttpRequest(endpoint, requestConfig, TOKEN_RESOURCES.ShiftsService);
            await this.updateDeviceHeader(updateDeviceHeaderFromResponse, response);
            return response;
        } catch (err) {
            // Auth token errors are already converted to StaffHubHttpError and are not in AxiosError format. And are logged separately
            if (err && err.staffHubInnerErrorCode === ShiftsWebErrorCodes.AuthTokenFetchFailed) {
                throw err;
            } else {
                const httpError: AxiosError = err;
                let staffHubHttpError: StaffHubHttpError = this.logAndMapHttpError(httpError, requestConfig.urlSignature);

                if (isConnectorAuthErrorResponse(staffHubHttpError)) {
                    try {
                        const retryResponse = await this.getConnectorAuthInfoAndRetry(endpoint, requestConfig, httpError);
                        if (retryResponse) {
                            await this.updateDeviceHeader(updateDeviceHeaderFromResponse, retryResponse);
                            return retryResponse;
                        } else {
                            return null;
                        }
                    } catch (error) {
                        // we get an error even with a Connector Token
                        staffHubHttpError = this.logAndMapHttpError(error, requestConfig.urlSignature);
                    }
                }
                // Notify the global StaffHub HTTP error handler about exception
                handleStaffHubServiceError(staffHubHttpError);

                // throw the exception
                throw staffHubHttpError;
            }
        }
    }

    /**
     * Sends an http request, and fetches auth token and then skype token
     * @param {string} endpoint - The endpoint to make a request to
     * @param {HttpRequestEntity} requestConfig
     * @returns {Promise<AxiosResponse>}
     * @throws {AxiosError}
     */
    public async sendHttpRequestToAuthSvc(): Promise<string> {
        const url = AppSettings.getSetting("AuthServiceURL");
        const requestConfig: HttpRequestEntity = {
            method: "POST",
            url: url,
            urlSignature: url
        };
        try {
            const response: AxiosResponse = await this.sendAuthenticatedHttpRequest(url, requestConfig, TOKEN_RESOURCES.TeamsSpaces);
            if (response && response.data && response.data.tokens) {
                return response.data.tokens.skypeToken;
            } else {
                trace.info("Fetch Skype Token has no response data");
                return null;
            }
        } catch (err) {
            trace.info(`Fetch skypeToken failure ${InstrumentationService.perfScenarios.TeamSkypeTokenFailure}`);
            const httpError: AxiosError = err;
            InstrumentationService.logHttpError(httpError, "SkypeTokenServiceError", requestConfig.urlSignature);
        }
    }

    /**
     * Sends an http request to a CAE (Continuous Access Evaluation) enabled service that uses AAD authentication
     * These services may throw a 401 error with a www-authenticate header when a claims challenge needs to be handled by the client
     * @param {string} endpoint - The endpoint to make a request to
     * @param {HttpRequestEntity} requestConfig
     * @param {string} tokenResource - The resource to get a token for (this is a key whose value is looked up from appsettings)
     * @returns {Promise<AxiosResponse>}
     * @throws {AxiosError}
     */
    private async sendAuthenticatedHttpRequest(endpoint: string, requestConfig: HttpRequestEntity, tokenResource: string): Promise<AxiosResponse> {
        let eventDataArray = [];

        // Set the authorization header
        let getAuthHeaderStartTimeStamp = InstrumentationService.getCurrentTimeStamp();
        await this.setAuthorizationHeader(requestConfig, tokenResource);
        let tokenFetchDuration = getDurationInMs(getAuthHeaderStartTimeStamp, InstrumentationService.getCurrentTimeStamp());

        // Make the HTTP request
        try {
            eventDataArray.push(getGenericEventPropertiesObject(InstrumentationService.properties.AuthTokenFetchDurationMS, tokenFetchDuration.toString()));
            return await this.sendHttpRequest(endpoint, requestConfig, eventDataArray);
        } catch (err) {
            const httpError: AxiosError<any, any> = err;

            // Check if we need to handle a CAE authentication challenge
            // This can happen if a long-lived auth token has been revoked, and the service decided to start
            // rejecting the token.
            const caeClaims = AuthService.getClaimsIfCAEChallengeResponse(httpError?.response);
            if (caeClaims) {
                // Request a new auth token with the claims challenge
                // This will forward the claims challenge to the Teams SDK to request a new token
                let getAuthHeaderStartTimeStamp = InstrumentationService.getCurrentTimeStamp();
                await this.setAuthorizationHeader(requestConfig, tokenResource, caeClaims);
                let tokenFetchDuration = getDurationInMs(getAuthHeaderStartTimeStamp, InstrumentationService.getCurrentTimeStamp());

                // Retry the http request once
                let eventDataArray = [];
                eventDataArray.push(getGenericEventPropertiesObject(InstrumentationService.properties.AuthTokenFetchDurationMS, tokenFetchDuration.toString()));
                eventDataArray.push(getGenericEventPropertiesObject(InstrumentationService.properties.IsClaimsChallenge, true));
                return await this.sendHttpRequest(endpoint, requestConfig, eventDataArray);
            }

            throw err;
        }
    }

    /**
     * Sets the Authorization header by fetching the token from the Teams SDK
     * @param {HttpRequestEntity} requestConfig
     * @param {string} tokenResource - The resource to get a token for (this is a key whose value is looked up from appsettings)
     * @param {string} claims - optional claims to pass to AAD when requesting the token (Sent when handling a CAE authentication challenge)
     * @returns {Promise<AxiosResponse>}
     * @throws {AxiosError}
     */
    private async setAuthorizationHeader(requestConfig: HttpRequestEntity, tokenResource: string, claims: string = ""): Promise<void> {

        const perfScenarioLogSessionId = InstrumentationService.startPerfScenarioLogSession(InstrumentationService.logLevel.P1);
        try {
            const token = await AuthService.getToken(tokenResource, claims);
            if (token) {
                requestConfig.headers = requestConfig.headers || {};
                requestConfig.headers[AUTHORIZATIONKEY] = BEARERKEY + token;
            }
        } catch (acquireTokenError) {
            // Auth token fetch could fail if the app is offline or a user pwd has changed
            // For these cases, Teams would be showing an message on top (which is not specific to extension tab)
            // For all other cases, we should have user retry the workflow
            // We should be showing a generic error message in this case and logging the error received from auth service
            let shiftsError = createNewHttpError();
            shiftsError.staffHubInnerErrorCode = ShiftsWebErrorCodes.AuthTokenFetchFailed;
            // StringsStore is not initialized when the NetworkService is created. Get the common error string when needed
            shiftsError.staffHubTopLevelErrorMessage = StringsStore().registeredStringModules.get("common").strings.get("genericError");
            shiftsError.httpErrorMessage = acquireTokenError.message; // Teams is only sending the error message currently. Not receiving error code
            InstrumentationService.logPerfScenarioEvent(perfScenarioLogSessionId, InstrumentationService.perfScenarios.TeamsAuthFailures, this._getPerfInstrumentationEventDataForAuthErrorResponse(tokenResource, perfScenarioLogSessionId, requestConfig, !!claims, acquireTokenError.message));
            throw shiftsError;
        }
    }

    /**
     * Sends an http request
     * @param {string} endpoint - The endpoint to make a request to
     * @param {HttpRequestEntity} requestConfig
     * @param {InstrumentationEventPropertyInterface[]} eventDataArray - (optional) Additional event data
     * @returns {Promise<AxiosResponse>}
     * @throws {AxiosError}
     */
    private async sendHttpRequest(endpoint: string, requestConfig: HttpRequestEntity, eventDataArray: InstrumentationEventPropertyInterface[] = []): Promise<AxiosResponse> {
        let request: AxiosRequestConfig = {
            baseURL: endpoint,
            method: requestConfig.method,
            url: requestConfig.url,
            withCredentials: false
        };

        // add a payload if there is one
        if (requestConfig.data) {
            request.data = requestConfig.data;
        }

        if (requestConfig.headers) {
            request.headers = requestConfig.headers;
        }

        const perfScenarioLogSessionId = InstrumentationService.startPerfScenarioLogSession(InstrumentationService.logLevel.P1);

        try {
            const response = await this.axiosClient.request(request);

            InstrumentationService.logPerfScenarioEvent(perfScenarioLogSessionId, InstrumentationService.perfScenarios.ServiceAPICall, this._getPerfInstrumentationEventDataForResponse(endpoint, perfScenarioLogSessionId, requestConfig, response, eventDataArray));

            return response;
        } catch (err) {
            const httpError: AxiosError<any, any> = err;
            InstrumentationService.logPerfScenarioEvent(perfScenarioLogSessionId, InstrumentationService.perfScenarios.ServiceAPICall, this._getPerfInstrumentationEventDataForErrorResponse(endpoint, perfScenarioLogSessionId, requestConfig, httpError, eventDataArray));
            let method = "";
            let url = "";
            let statusCode = 0;
            let statusText = "";
            if (httpError) {
                if (httpError.config) {
                    if (httpError.config.method) {
                        method = httpError.config.method;
                    }
                    if (httpError.config.url) {
                        url = httpError.config.url;
                    }
                }

                if (httpError.response && (httpError.response.status || httpError.response.status === 0)) {
                    statusCode = httpError.response.status;
                }

                if (httpError.response && httpError.response.statusText) {
                    statusText = httpError.response.statusText;
                }
            }

            if (statusCode === 401) {
                trace.warn(`REST Call ERROR: ${ method } ${ url }
                    - Http Error Code: ${ statusCode }
                    - Http Error Text: ${ statusText }`);
            } else {
                trace.warn(`REST Call ERROR: ${ method } ${ url }
                    - Http Error Code: ${ statusCode }
                    - Http Error Text: ${ statusText }
                    - Shiftr Error Code: ${ httpError.response && httpError.response.data && httpError.response.data.error ? httpError.response.data.error.code : "none" }
                    - Shiftr Error Message: ${ httpError.response && httpError.response.data && httpError.response.data.error ? httpError.response.data.error.message : "none" }`);
            }

            throw httpError;
        } finally {
            InstrumentationService.endPerfScenarioLogSession(perfScenarioLogSessionId);
        }
    }

    /**
     * Sets the common headers that are sent with every request
     */
    private _setupDefaultCommonHeaders() {
        this.axiosClient.defaults.headers.common[CONTENTTYPEHEADER] = "application/json";
    }

    /**
     * Sets the common headers for requests to the StaffHub Service
     * @param config - Http Request Entity
     * @param includeDeviceHeader - Whether to include the StaffHub Service DeviceHeader
     */
    private async _setupStaffHubServiceHeaders(config: HttpRequestEntity, includeDeviceHeader: boolean = true): Promise<void> {
        // if API Version Header is already set, use the same. Else use latest API Version Header
        config.headers = config.headers || {};
        config.headers[APIVERSIONHEADER] = config.headers[APIVERSIONHEADER] || AppSettings.getSetting("APIVersion");
        config.headers[CLIENTPLATFORMHEADER] = this._clientPlatform;
        config.headers[CLIENTVERSIONHEADER] = BUILD_VERSION;
        config.headers[CLIENTSESSIONIDHEADER] = InstrumentationService.getClientSessionId();
        config.headers[CLIENTREQUESTIDHEADER] = generateUUID();
        if (this._clientUICultureCode) {
            config.headers[ACCEPTLANGUAGEHEADER] = this._clientUICultureCode;
        }

        // Add the header to tell the StaffHub service to use 1st party AAD auth
        const firstpartyHeaderValue = "True";
        config.headers[FIRSTPARTYHEADER] = firstpartyHeaderValue;

        // Add the device header
        if (includeDeviceHeader) {
            if (this._deviceHeaderProvider) {
                const deviceHeader = await this._deviceHeaderProvider.getDeviceHeader();
                if (deviceHeader) {
                    config.headers[DEVICEHEADERNAME] = deviceHeader;
                }
            } else {
                console.warn('deviceHeaderProvider not set');
            }
        }
    }

    /**
     * Enable axios retry mechanism
     */
     private _setupRetryMechanism() {
        // Add an exponential delay between retries and reset the timeout each time
        axiosRetry(this.axiosClient, {
            retries: RETRY_COUNT,
            retryDelay: this.retryDelay,
            shouldResetTimeout: true,
            retryCondition: (error: any) =>
                (axiosRetry.isRetryableError(error) || this.isRetryableNetworkError(error)) // allow retry on network error (this should be safe since our requests are idempotent)
                && !isConnectorAuthErrorResponse(mapHttpError(error)) // exclude retry for connector auth errors
        });
    }

    /**
     * Returns the retry delay in milli-seconds. Default is exponentialDelay setup in axiosRetry
     * For 429 (TooManyRequests) it returns the Retry-After value in the header if available
     */
    private retryDelay(retryCount: number, error: any) {
        if (error.response && error.response.status === 429) {
            // This header item is getting returned in lower case, so checking both expected and lower case
            const retryDelayInHeader = error.response.headers[RETRYAFTERHEADER] || error.response.headers[RETRYAFTERHEADER.toLowerCase()];
            if (retryDelayInHeader) {
                return Number(retryDelayInHeader) * 1000; // Retry-After header value is seconds for 429 response
            }
        }
        return axiosRetry.exponentialDelay(retryCount);
    }
    /**
     * Whitelisted error codes that are retryable
     * @param errorCode error code
     */
    private isRetryableNetworkError(error: any) {
        if (!error) {
            return true;
        }

        // 429 (Too many requests) should be retried with specified delay
        if (error.response && error.response.status === 429) {
            return true;
        }

        return error.code === NetworkErrorCodes.ECONNABORTED
            || error.code === NetworkErrorCodes.ECONNREFUSED
            || error.code === NetworkErrorCodes.ECONNRESET
            || error.code === NetworkErrorCodes.ETIMEDOUT
            || error.code === NetworkErrorCodes.EADDRINUSE
            || error.code === NetworkErrorCodes.ESOCKETTIMEDOUT
            || error.code === NetworkErrorCodes.EPIPE
            || error.code === NetworkErrorCodes.EHOSTUNREACH
            || error.code === NetworkErrorCodes.EAI_AGAIN;
    }

    /**
     * Clears the url of the service end point
     */
    private _clearServiceAPIEndPoint() {
        this._apiEndPoint = {
            id: "",
            serviceUrl: ""
        };
    }

    /**
     * Returns cached Service API Endpoint Url
     * @returns {String} apiEndPoint
     */
    public GetServiceAPIEndPointUrl(): string {
        return this._apiEndPoint.serviceUrl || this.GetDefaultServiceAPIEndPointUrl();
    }

    /**
     * Get Default Service API endpoint URL
     * @returns {String} apiEndPoint
     */
    public GetDefaultServiceAPIEndPointUrl(): string {
        const defaultServiceEndpoint: string = ECSConfigService.getECSFeatureSetting(ECSConfigKey.DefaultServiceEndpoint);
        return defaultServiceEndpoint ? defaultServiceEndpoint : AppSettings.getSetting("ServiceEndpoint");
    }

    /**
     * Returns cached Service API Endpoint ID
     * @returns {String} apiEndPoint
     */
    private _getServiceAPIEndPointId() {
        return this._apiEndPoint.id || "";
    }

    /**
     * Return Perf Instrumentation Event data for given http response
     * @param {string} endpoint - The endpoint to make a request to
     * @param {String} sessionId
     * @param {Object} requestConfig
     * @param {Object} response
     * @param {InstrumentationEventPropertyInterface[]} eventDataArray - (optional) Additional event data
     * @returns {Array} eventDataArray
     */
    private _getPerfInstrumentationEventDataForResponse(endpoint: string, sessionId: string, requestConfig: HttpRequestEntity, response: AxiosResponse, eventDataArray: InstrumentationEventPropertyInterface[]  = []) {

        // log request type
        eventDataArray.push(getGenericEventPropertiesObject(InstrumentationService.properties.RequestType, (requestConfig && requestConfig.method) ? requestConfig.method : ""));

        // log request URL
        eventDataArray.push(getGenericEventPropertiesObject(InstrumentationService.properties.RequestURL, requestConfig && requestConfig.urlSignature ? requestConfig.urlSignature.toLowerCase() : ""));

        // log request endpoint
        eventDataArray.push(getGenericEventPropertiesObject(InstrumentationService.properties.RequestEndpoint, endpoint));

        // log response Status code
        eventDataArray.push(getGenericEventPropertiesObject(InstrumentationService.properties.ResponseHttpStatusCode, response ? response.status.toString() : "0"));

        // log response content length
        const responseContentLength = response.headers && response.headers[CONTENTLENGTHHEADER] ? response.headers[CONTENTLENGTHHEADER] : 0;
        eventDataArray.push(getGenericEventPropertiesObject(InstrumentationService.properties.ResponseContentLengthBytes, responseContentLength));

        // log client duration
        const scenarioSessionStartTimeStamp = InstrumentationPerfScenario.getPerfScenarioSessionStartTimeStamp(sessionId);
        const currentTimeStamp = InstrumentationService.getCurrentTimeStamp();
        const clientDuration = getDurationInMs(scenarioSessionStartTimeStamp, currentTimeStamp);
        eventDataArray.push(getGenericEventPropertiesObject(InstrumentationService.properties.ClientDurationMS, clientDuration.toString()));

        // log retry count
        let responseConfig: AxiosRequestConfig = response.config || {};
        const retryCount = responseConfig && (responseConfig as any)["axios-retry"] ? (responseConfig as any)["axios-retry"].retryCount : -1;
        eventDataArray.push(getGenericEventPropertiesObject(InstrumentationService.properties.RetryCount, retryCount));

        // log isSilent
        eventDataArray.push(getGenericEventPropertiesObject(InstrumentationService.properties.IsSilent, !!requestConfig.silent));

        if (response.headers && response.headers[SERVICEPROCESSINGTIMEHEADER]) {
            // log service processing time
            const serviceProcessingTimeMS = +(response.headers[SERVICEPROCESSINGTIMEHEADER]) || 0;
            eventDataArray.push(getGenericEventPropertiesObject(InstrumentationService.properties.ServiceDurationMS, serviceProcessingTimeMS));

            // log network transit time
            eventDataArray.push(getGenericEventPropertiesObject(InstrumentationService.properties.NetworkTransitTimeMS, (clientDuration - serviceProcessingTimeMS).toString()));
        }

         // log custom request headers
         if (requestConfig && requestConfig.headers) {
            if (requestConfig.headers[CLIENTSESSIONIDHEADER]) {
                eventDataArray.push(getGenericEventPropertiesObject(InstrumentationService.properties.ClientSessionId, requestConfig.headers[CLIENTSESSIONIDHEADER]));
            }

            if (requestConfig.headers[CLIENTREQUESTIDHEADER]) {
                eventDataArray.push(getGenericEventPropertiesObject(InstrumentationService.properties.ClientRequestId, requestConfig.headers[CLIENTREQUESTIDHEADER]));
            }
        }

        // log service API Region ID
        eventDataArray.push(getGenericEventPropertiesObject(InstrumentationService.properties.ServiceAPIRegionId, this._getServiceAPIEndPointId() || InstrumentationService.values.None));

        return eventDataArray;
    }

    /**
     * Return Perf Instrumentation event data for given http error response
     * @param {string} endpoint - The endpoint to make a request to
     * @param {String} sessionId
     * @param {Object} requestConfig - request configuration object
     * @param {InstrumentationEventPropertyInterface[]} eventDataArray - (optional) Additional event data
     * @param {httpError} httpError
     * @returns {Array} eventDataArray
     */
    private _getPerfInstrumentationEventDataForErrorResponse(endpoint: string, sessionId: string, requestConfig: HttpRequestEntity, httpError: AxiosError, eventDataArray: InstrumentationEventPropertyInterface[]  = []) {
        // log request type
        eventDataArray.push(getGenericEventPropertiesObject(InstrumentationService.properties.RequestType, (requestConfig && requestConfig.method) ? requestConfig.method : ""));

        // log request URL
        eventDataArray.push(getGenericEventPropertiesObject(InstrumentationService.properties.RequestURL, requestConfig && requestConfig.urlSignature ? requestConfig.urlSignature.toLowerCase() : ""));

        // log request endpoint
        eventDataArray.push(getGenericEventPropertiesObject(InstrumentationService.properties.RequestEndpoint, endpoint));

        // log custom request headers
        if (requestConfig && requestConfig.headers) {
            if (requestConfig.headers[CLIENTSESSIONIDHEADER]) {
                eventDataArray.push(getGenericEventPropertiesObject(InstrumentationService.properties.ClientSessionId, requestConfig.headers[CLIENTSESSIONIDHEADER]));
            }

            if (requestConfig.headers[CLIENTREQUESTIDHEADER]) {
                eventDataArray.push(getGenericEventPropertiesObject(InstrumentationService.properties.ClientRequestId, requestConfig.headers[CLIENTREQUESTIDHEADER]));
            }
        }

        // log response Status code
        eventDataArray.push(getGenericEventPropertiesObject(InstrumentationService.properties.ResponseHttpStatusCode, this._getErrorStatusCodeAsString(httpError)));

        // log response content length
        const responseContentLength = httpError && httpError.response && httpError.response.headers ? httpError.response.headers[CONTENTLENGTHHEADER] || 0 : 0;
        eventDataArray.push(getGenericEventPropertiesObject(InstrumentationService.properties.ResponseContentLengthBytes, responseContentLength));

        // log client duration
        const scenarioSessionStartTimeStamp = InstrumentationPerfScenario.getPerfScenarioSessionStartTimeStamp(sessionId);
        const currentTimeStamp = InstrumentationService.getCurrentTimeStamp();
        const clientDuration = getDurationInMs(scenarioSessionStartTimeStamp, currentTimeStamp);
        eventDataArray.push(getGenericEventPropertiesObject(InstrumentationService.properties.ClientDurationMS, clientDuration.toString()));

        // log retry count
        // AXIOS return the axios-retry config as part of error payload.
        // error.config or error.response.config is set with the config information based on the type of error.
        // If there is a proper service error, you will see the config in error.response.config. Otherwise ( in cases like timeout etc.,) it will be in error.config object
        let responseConfig: AxiosRequestConfig = httpError && httpError.config || httpError && httpError.response && httpError.response.config || {};
        const retryCount = responseConfig && (responseConfig as any)["axios-retry"] ? (responseConfig as any)["axios-retry"].retryCount : -1;
        eventDataArray.push(getGenericEventPropertiesObject(InstrumentationService.properties.RetryCount, retryCount));

        // log isSilent
        eventDataArray.push(getGenericEventPropertiesObject(InstrumentationService.properties.IsSilent, !!requestConfig.silent));

        if (httpError && httpError.response && httpError.response.headers && httpError.response.headers[SERVICEPROCESSINGTIMEHEADER]) {
            // log service processing time
            const serviceProcessingTimeMS = +(httpError.response.headers[SERVICEPROCESSINGTIMEHEADER]) || 0;
            eventDataArray.push(getGenericEventPropertiesObject(InstrumentationService.properties.ServiceDurationMS, serviceProcessingTimeMS));

            // log network transit time
            eventDataArray.push(getGenericEventPropertiesObject(InstrumentationService.properties.NetworkTransitTimeMS, (clientDuration - serviceProcessingTimeMS).toString()));
        }

        // log service API Region ID
        eventDataArray.push(getGenericEventPropertiesObject(InstrumentationService.properties.ServiceAPIRegionId, this._getServiceAPIEndPointId() || InstrumentationService.values.None));

        // log error data
        const httpErrorData = mapHttpError(httpError);
        const errorReason = httpErrorData.staffHubInnerErrorCode ? httpErrorData.staffHubInnerErrorCode : (httpErrorData.staffHubTopLevelErrorCode ? httpErrorData.staffHubTopLevelErrorCode : httpErrorData.httpErrorMessage || httpErrorData.message);
        eventDataArray.push(getGenericEventPropertiesObject(InstrumentationService.properties.ResponseErrorReason, errorReason));

        if (httpErrorData.staffHubInnerErrorCode === StaffHubErrorCodes.WorkforceIntegrationError) {
            const hasExternalError: boolean = !!(httpErrorData.staffHubError && httpErrorData.staffHubError.externalErrorMessage);
            eventDataArray.push(getGenericEventPropertiesObject(InstrumentationService.properties.HasExternalErrorMessage, hasExternalError));
        }
        return eventDataArray;
    }

    /**
     * Return Perf Instrumentation event data for given auth service error response
     * @param {string} tokenResource - The resource we are fetching a token for
     * @param {String} sessionId
     * @param {Object} requestConfig - request configuration object
     * @param {boolean} isClaimsChallenge - if claims were included in the request
     * @param {string} errorMessage
     * @returns {Array} eventDataArray
     */
    private _getPerfInstrumentationEventDataForAuthErrorResponse(tokenResource: string, sessionId: string, requestConfig: HttpRequestEntity, isClaimsChallenge: boolean, errorMessage: string) {
        let eventDataArray = [];

        // log request type
        eventDataArray.push(getGenericEventPropertiesObject(InstrumentationService.properties.RequestType, (requestConfig && requestConfig.method) ? requestConfig.method : ""));

        // log request URL
        eventDataArray.push(getGenericEventPropertiesObject(InstrumentationService.properties.RequestURL, requestConfig && requestConfig.urlSignature ? requestConfig.urlSignature.toLowerCase() : ""));

        // log token resource
        eventDataArray.push(getGenericEventPropertiesObject(InstrumentationService.properties.Resource, tokenResource));

        // log if claims were sent
        eventDataArray.push(getGenericEventPropertiesObject(InstrumentationService.properties.IsClaimsChallenge, isClaimsChallenge));

        // log custom request headers
        if (requestConfig && requestConfig.headers) {
            if (requestConfig.headers[CLIENTSESSIONIDHEADER]) {
                eventDataArray.push(getGenericEventPropertiesObject(InstrumentationService.properties.ClientSessionId, requestConfig.headers[CLIENTSESSIONIDHEADER]));
            }

            if (requestConfig.headers[CLIENTREQUESTIDHEADER]) {
                eventDataArray.push(getGenericEventPropertiesObject(InstrumentationService.properties.ClientRequestId, requestConfig.headers[CLIENTREQUESTIDHEADER]));
            }
        }

        // log client duration
        const scenarioSessionStartTimeStamp = InstrumentationPerfScenario.getPerfScenarioSessionStartTimeStamp(sessionId);
        const currentTimeStamp = InstrumentationService.getCurrentTimeStamp();
        const clientDuration = getDurationInMs(scenarioSessionStartTimeStamp, currentTimeStamp);
        eventDataArray.push(getGenericEventPropertiesObject(InstrumentationService.properties.ClientDurationMS, clientDuration.toString()));

        // log error data
        eventDataArray.push(getGenericEventPropertiesObject(InstrumentationService.properties.ResponseErrorReason, errorMessage));
        return eventDataArray;
    }

    /**
     * Get Http Status Code For Error response
     * @param httpError Axios Error Object
     */
    private _getErrorStatusCodeAsString(httpError: AxiosError): string {
        return httpError?.response?.status ? httpError.response.status.toString() : "0"; // return HTTP 0 if there is no response code sent by service
    }
}

const service = new NetworkServiceClass();
export default service as NetworkServiceClass;
