import * as React from "react";
import StringsStore from "sh-strings/store";

export interface PageStepIndicatorTextProps {
    /** Number of steps to show */
    stepIndicatorSize: number;

    /** Current step index in the workflow */
    currentStepIndex: number;
}

/**
 * React component for showing the step indicator in a workflow - text version (eg, "1 of 5")
 */
export default class PageStepIndicatorText extends React.PureComponent<PageStepIndicatorTextProps, {}> {
    private _commonStrings: Map<string, string>;

    constructor(props: PageStepIndicatorTextProps) {
        super(props);
        this._commonStrings = StringsStore().registeredStringModules.get("common").strings;
    }

    /**
     * Renders the step indicator
     */
    render() {
        const { stepIndicatorSize, currentStepIndex } = this.props;

        if (!stepIndicatorSize || currentStepIndex < 0 || currentStepIndex >= stepIndicatorSize) {
            return null;
        }

        return (
            <>
                { this._commonStrings.get("pageStepIndicatorTextFormat").format(String(currentStepIndex + 1), String(stepIndicatorSize)) }
            </>
        );
    }
}