import FileDownloadUtils from "sh-application/utility/FileDownloadUtils";
import getDownloadSampleFile from "../actions/getDownloadSampleFile";
import RestClient from "sh-rest-client";
import StringsStore from "sh-strings/store";
import { ECSConfigKey, ECSConfigService, InstrumentationService } from "sh-services";
import { orchestrator } from "satcheljs";
import { TeamStore } from "sh-team-store";

/**
 * Action to download sample file.
 */
export default orchestrator(getDownloadSampleFile, async actionMessage => {
    const teamId = TeamStore().teamId;
    const strings: Map<string, string> = StringsStore().registeredStringModules.get("common").strings;
    try {
        let authenticatedDownloadUrl = "";
        if (ECSConfigService.isECSFeatureEnabled(ECSConfigKey.EnableV2FileDownloadAPIs)) {
            authenticatedDownloadUrl = await RestClient.getImportScheduleSampleFileUrlV2(teamId);
        } else {
            authenticatedDownloadUrl = await RestClient.getImportScheduleSampleFileUrl(teamId);
        }

        if (!authenticatedDownloadUrl) {
            throw new Error(strings.get("genericError"));
        } else {
            FileDownloadUtils.DownloadFile(authenticatedDownloadUrl);
        }
    } catch (e) {
        InstrumentationService.trackException(e, "getDownloadSampleFile");
    }
});