import { action } from "satcheljs";
import { ITeamInfoEntity } from "sh-models";

const teamUpdated = action(
    "TEAM_CHANGED", (
        team: ITeamInfoEntity
    ) => ({
        team: team
    })
);

export default teamUpdated;
