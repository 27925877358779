import ExportShiftsReportUtils from "sh-application/utility/ExportShiftsReportUtils";
import { DataProcessingHelpers } from "sh-services";
import { orchestrator } from "satcheljs";
import { ScheduleData } from "sh-application/components/schedule/ScheduleData";
import { TagStore } from "sh-tag-store";
import { TeamStore } from "sh-team-store";
import { TimeOffReasonsStore } from "sh-timeoffreasons-store";
import { exportShiftsReport } from "../index";

/**
 * orchestrator to export Shifts data.
 */

export const exportShiftsReportOrchestrator = orchestrator(exportShiftsReport, actionMessage => {
        const { startDate, endDate, exportOptions, shifts, notes } = actionMessage;
        // deep copy of shift and notes array
        // Note: do not change the .slice() functionality here. If we pass in a mobx observable array as the shifts or notes
        // this slice call is necessary to convert the mobx version of the array to a regular array.
        // ref: https://github.com/mobxjs/mobx/blob/gh-pages/docs/best/pitfalls.md#arrayisarrayobservable123--false
        let shiftsCopy = shifts ? shifts.slice() : [];
        let notesCopy = notes ? notes.slice() : [];
        const groupedShiftsData = ScheduleData.groupShiftsDataForScheduleOutput(
            startDate,
            endDate,
            shiftsCopy,
            null, // openshifts. will be supported in future release
            notesCopy,
            DataProcessingHelpers.getArrayFromMap(TagStore().tags),
            [],                 /* Filtered tags */
            DataProcessingHelpers.getArrayFromMap(TimeOffReasonsStore().timeOffReasons),
            DataProcessingHelpers.getArrayFromMap(TeamStore().members),
            [],                 /* Filtered members */
            [],                 /* selectedShiftFilters */
            false,              /* Shifts view */
            true,               /* doIncludeSummaryData */
            false               /* doIncludeUniqueSubshiftData */);

        const exportShiftsReportUtils = new ExportShiftsReportUtils;

        const fileName = exportShiftsReportUtils.getExportShiftsReportFileName(startDate, endDate, TeamStore().teamId);

        let allRows: any[] = [];
        let allWorksheetNames: string[] = [];

        // Schedule worksheet
        allWorksheetNames.push(exportShiftsReportUtils.getShiftsDataWorksheetName());
        allRows = exportShiftsReportUtils.generateDataForScheduleExport(groupedShiftsData, notes, exportOptions, startDate, endDate);

        let allWorksheetDataArray = [];
        allWorksheetDataArray.push(allRows); // All data for the Schedule tab on the spreadsheet

        // Totals worksheet
        allWorksheetNames.push(exportShiftsReportUtils.getTotalsDataWorksheetName());
        const totalsExportData = exportShiftsReportUtils.generateDataForTotalsExport(groupedShiftsData, exportOptions, startDate, endDate);
        allWorksheetDataArray.push(totalsExportData); // All data for the Totals tab on the spreadsheet

        exportShiftsReportUtils.saveDataAsXlsx(allWorksheetDataArray, allWorksheetNames, fileName);
    });