import ShiftrObjectModelBase from "../ShiftrObjectModelBase";
import { IMemberEntity, ModelUtils } from "sh-models";
import { MEMBER_ID_PREFIX } from "sh-application/../StaffHubConstants";
import { PhoneNumberSignInStatusesType } from "./PhoneNumberSignInStatuses";

/**
 * Member entity model
 */
export default class MemberEntity extends ShiftrObjectModelBase implements IMemberEntity {
    tenantId: string;
    teamId: string;
    userId: string;
    displayName: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    email: string;
    pictureUrl: string;
    thumbnailUrl: string;
    roleIds: Array<string>;
    state: string;
    index: number;
    isAdmin: boolean;
    phoneNumberSignInStatus: PhoneNumberSignInStatusesType;

    constructor(
        id: string,
        eTag: string,
        tenantId: string,
        teamId: string,
        userId: string,
        displayName: string,
        firstName: string,
        lastName: string,
        phoneNumber: string,
        email: string,
        pictureUrl: string,
        thumbnailUrl: string,
        roleIds: Array<string>,
        state: string,
        index: number,
        phoneNumberSignInStatus: PhoneNumberSignInStatusesType = null) {

        let modelId = id || MemberEntity.generateNewMemberId();
        super(modelId, eTag);

        this.tenantId = tenantId;
        this.teamId = teamId;
        this.userId = userId;
        this.displayName = displayName;
        this.firstName = firstName;
        this.lastName = lastName;
        this.phoneNumber = phoneNumber;
        this.email = email;
        this.pictureUrl = pictureUrl || "";
        this.thumbnailUrl = thumbnailUrl || "";
        // deep copy of roleIds
        // Note: do not change the .slice() functionality here. If we pass in a mobx observable array as the roleIds
        // this slice call is necessary to convert the mobx version of the array to a regular array.
        // ref: https://github.com/mobxjs/mobx/blob/gh-pages/docs/best/pitfalls.md#arrayisarrayobservable123--false
        this.roleIds = roleIds ? roleIds.slice() : [];
        this.state = state;
        this.index = index;
        // TODO: Fetch isAdmin from the service when the field is exposed in the API
        this.isAdmin = this.roleIds && this.roleIds.length > 0; // We are assuming the manager is an admin if there are any roles assigned.
        this.phoneNumberSignInStatus = phoneNumberSignInStatus;
    }

    /**
     * Generate new member ID used by Shiftr App
     * @returns {string} teamId in form of MBER_<uuid>
     */
    public static generateNewMemberId(): string {
        return ModelUtils.generateUUIDWithPrefix(MEMBER_ID_PREFIX);
    }

    static createEmptyObject(): MemberEntity {
        return MemberEntity.fromJson({
            id: null,
            eTag: null,
            tenantId: null,
            teamId: null,
            userId: null,
            displayName: "",
            firstName: "",
            lastName: "",
            phoneNumber: "",
            email: "",
            pictureUrl: null,
            thumbnailUrl: null,
            roleIds: [],
            state: null,
            index: null,
            phoneNumberSignInStatus: null});
    }

    /**
     * Function that clones a MemberEntity object
     * @param member - member to be cloned
     */
    static clone(member: IMemberEntity): IMemberEntity {
        return MemberEntity.fromJson(member);
    }

    static fromJson(jsonData: any): IMemberEntity {

        if (!jsonData) {
            return null;
        }

        return new MemberEntity(
            jsonData.id,
            jsonData.eTag,
            jsonData.tenantId,
            jsonData.teamId,
            jsonData.userId,
            jsonData.displayName,
            jsonData.firstName,
            jsonData.lastName,
            jsonData.phoneNumber,
            jsonData.email,
            jsonData.pictureUrl,
            jsonData.thumbnailUrl,
            jsonData.roleIds,
            jsonData.state,
            jsonData.index,
            jsonData.phoneNumberSignInStatus);
    }

    static toJson(clientModel: IMemberEntity): any {

        if (!clientModel) {
            return null;
        }

        let jsonData = {
            id: clientModel.id,
            eTag: clientModel.eTag,
            tenantId: clientModel.tenantId,
            teamId: clientModel.teamId,
            userId: clientModel.userId,
            displayName: clientModel.displayName,
            firstName: clientModel.firstName || null,
            lastName: clientModel.lastName || null,
            phoneNumber: clientModel.phoneNumber || null,
            email: clientModel.email || null,
            pictureUrl: clientModel.pictureUrl,
            thumbnailUrl: clientModel.thumbnailUrl,
            roleIds: clientModel.roleIds,
            state: clientModel.state,
            index: clientModel.index,
            phoneNumberSignInStatus: clientModel.phoneNumberSignInStatus
        };

        // clean up the request object to avoid service error
        if (jsonData.index === null) {
            delete jsonData.index;
        }

        return jsonData;
    }
}
