import { trace } from "owa-trace";

export default class PollHelper {
    private pollTimerId: number;
    private minimumPollDuration: number;
    private isPolling: boolean;

    /**
     * Contructor
     * @param {number} intervalDurationMs Time in milliseconds between poll calls
     * @param {number} minimumPollDuration The minumum amount of time in milliseconds to wait between poll calls (overrides any values from the service)
     * @param {callback} onPollCallback callback triggered on next poll timeout
     */
    constructor(intervalDurationMs: number, minimumPollDuration: number, onPollCallback: () => Promise<void>) {
        // Start the poll
        this.isPolling = true;
        this.minimumPollDuration = minimumPollDuration;

        this.startNextPollTimer(intervalDurationMs, onPollCallback);
    }

    /**
     * Start the timer for the next poll call
     * @param {callback} intervalDurationMs Time in milliseconds between poll calls
     * @param {callback} onPollCallback callback triggered on next poll timeout
     */
    private startNextPollTimer(intervalDurationMs: number, onPollCallback: () => Promise<void>) {
        if (intervalDurationMs < this.minimumPollDuration) {
            trace.warn("Poll duration $(intervalDurationMs) was less than the minumum duration $(this.minimumPollDuration)");
            intervalDurationMs = this.minimumPollDuration;
        }

        // clear any existing timer
        this.resetTimer();

        this.pollTimerId = window.setTimeout(async () => {
            trace.info("Poll Callback triggered");

            // Call the callback and wait for it to complete (before we start the next timer)
            await onPollCallback();

            // Restart the next poll timer (if it wasn't stopped during the onPollCallback)
            if (this.isPolling) {
                this.startNextPollTimer(intervalDurationMs, onPollCallback);
            }

        }, intervalDurationMs);
    }

    /**
     * Stop polling
     */
    public stopPolling() {
        this.isPolling = false;
        this.resetTimer();
    }

    /**
     * Reset any pending poll timer
     */
    private resetTimer() {
        if (this.pollTimerId) {
            // clear any existing timer
            clearTimeout(this.pollTimerId);
            this.pollTimerId = undefined;
        }
    }
}