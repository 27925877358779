import { createStore } from "satcheljs";
import { ShiftMemberDateInfo, ShiftsByDate, ShiftsByMemberAndDateStoreSchema } from "./schema/ConflictStoreSchema";

let ShiftsByMemberAndDateStore = createStore<ShiftsByMemberAndDateStoreSchema>(
    "shiftsByMemberAndDateStore",
    {
        memberIdToShiftsByDate: new Map<string, ShiftsByDate>(),

        shiftIdToMemberDateIdsMap: new Map<string, ShiftMemberDateInfo>()
    });

export default ShiftsByMemberAndDateStore;
