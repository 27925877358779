import { action  } from "satcheljs";
import { ShiftEditorViewStateSchema } from "../index";
import { Moment } from "moment";
import { IOpenShiftEntity } from "sh-models";

const initializeLocalObservableOpenShift = action(
    'initializeLocalObservableOpenShift',
    (
        shiftEditorViewState: ShiftEditorViewStateSchema,
        openShift: IOpenShiftEntity,
        memberId: string,
        groupTagId: string,
        startDate: Moment
    ) =>
    ({
        shiftEditorViewState: shiftEditorViewState,
        openShift: openShift,
        memberId: memberId,
        groupTagId: groupTagId,
        startDate: startDate
    })
);

export default initializeLocalObservableOpenShift;