import { createStore } from "satcheljs";
import { TimeClockEntryStoreSchema } from "./schema/TimeClockEntryStore";

/**
 * Store for latest time clock entry
 */

const TimeClockEntryStore = createStore<TimeClockEntryStoreSchema>(
    "timeClockEntryStore",
    {
        timeClockEntry: null
    });

export default TimeClockEntryStore;