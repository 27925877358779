import { AuthenticationProvider } from "@microsoft/microsoft-graph-client";
import { AuthService } from "sh-services";
import { trace } from "owa-trace";

/**
 * The Graph service authentication provider.
 */
export class GraphAuthenticationProvider implements AuthenticationProvider {

    /**
     * Gets the access token to access the Graph service.
     * @returns {Promise<string>} The access token.
     */
    public async getAccessToken(): Promise<string> {
        trace.info("Requesting access token for the Graph API.");
        return await AuthService.getToken("GraphServiceResourceUri");
    }
}