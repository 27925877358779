import schedulesViewStateStore from "../store/store";
import { addOpenShiftsInStore } from "sh-open-shift-store";
import { DataProcessingHelpers } from "sh-services";
import { IOpenShiftEntity } from "sh-models";
import { Moment } from "moment";
import { orchestrator } from "satcheljs";
import { updateOpenShiftsInView } from "../index";

/**
 * When updates to open shifts are triggered via the UI or via sync notifications, this orchestrator will be triggered.
 * It will parse the updated open  and pass those that fit into the current view to the updateOpenShiftsInView() mutator, which
 * will update the map of open shifts in the local view store
 */
export default orchestrator(addOpenShiftsInStore, async actionMessage => {
    const viewStart: Moment = schedulesViewStateStore().viewStartDate;
    const viewEnd: Moment = schedulesViewStateStore().viewEndDate;
    const openShifts: IOpenShiftEntity[] = DataProcessingHelpers.getDisplayableShiftsInScheduleRange(actionMessage.addedOpenShifts, viewStart, viewEnd) as IOpenShiftEntity[];

    if (openShifts.length) {
        updateOpenShiftsInView(openShifts);
    }
});