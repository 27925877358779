import { action } from "satcheljs";
import { IMemberEntity } from "sh-models";

import { ToggleMemberFilterActionMessage } from "./ToggleMemberFilterActionMessage";

/**
 * Creates the action to toggle a member in the schedule view filters.
 */
export const toggleMemberFilter = action<ToggleMemberFilterActionMessage, (member: IMemberEntity) => ToggleMemberFilterActionMessage>(
    "TOGGLE_MEMBER_FILTER",
    (member: IMemberEntity) => ({ member })
);
