import resetTeamSettingsStore from "../actions/resetTeamSettingsStore";
import TeamSettingsStore from "../store";
import { mutator } from "satcheljs";

/**
 * Reset the team settings store
 */
export default mutator(resetTeamSettingsStore, ({ }) => {
    TeamSettingsStore().startingDayOfWeek = null;
    TeamSettingsStore().timeZoneOlsonCode = null;
    TeamSettingsStore().scheduleStartDate = null;
    TeamSettingsStore().scheduleType = null;
    TeamSettingsStore().scheduleLength = null;
    TeamSettingsStore().timeClockEnabled = null;
    TeamSettingsStore().hideOpenShifts = null;
});