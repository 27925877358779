import { action } from "satcheljs";
import { IGroupedOpenShiftRequestEntity, IShiftRequestEntity } from "sh-models";

const updatedRegularAndGroupedShiftRequests = action(
    "UPDATED_REGULAR_AND_GROUPED_REQUESTS",
    (
        shiftRequests: IShiftRequestEntity[],
        groupedOpenShiftRequests: Map<string, IGroupedOpenShiftRequestEntity>
    ) => ({
        shiftRequests: shiftRequests || [],
        groupedOpenShiftRequests: groupedOpenShiftRequests || new Map<string, IGroupedOpenShiftRequestEntity>()
    })
);

export default updatedRegularAndGroupedShiftRequests;
