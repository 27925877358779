import { mutatorAction } from "satcheljs";
import { SchedulesViewStateStore } from "sh-application/components/schedules/lib/store/schema/SchedulesViewStateStore";

/**
 * Updates the visibility of the schedule toggles menu
 * @param viewState - Schedule view state
 * @param isOpen - boolean determining the visibility of the toggles menu
 */
export default mutatorAction("SET_IS_SCHEDULE_TOGGLES_MENU_OPEN",
    function setIsScheduleTogglesMenuOpen(viewState: SchedulesViewStateStore, isOpen: boolean) {
        viewState.isScheduleTogglesMenuOpen = isOpen;
    }
);