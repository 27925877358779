import FeatureFlagsStore from "./schema/FeatureFlagsStore";
import { createStore } from "satcheljs";
import { ObservableMap } from "mobx";

/**
 * TODO(Performance): Remove unused 'featureFlagsStore' to improve performance.
 */
let featureFlagsStore: FeatureFlagsStore = {
    // TODO(Performance): Remove all test-specific code from production code.
    featureFlags: new ObservableMap<string, boolean>([
        ["verbose", false],              // turns on verbose logging in Release builds - enabled by adding ?features=verbose query param
        ["newteam", false],              // gets set for new teams when they navigate to the scheduler
        ["availability", false],         // show availabilities of users
        ["disablevlist", false],         // if enabled, the virtual list will no longer virtualize
        ["mockmsteams", false]           // For Automation: Mocks the MicrosoftTeams SDK if this is enabled.
    ])
};

export default createStore<FeatureFlagsStore>('featureFlags', featureFlagsStore);
