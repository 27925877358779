import IImportScheduleJobResult from "./IImportScheduleJobResult";
import { ImportJobStatus } from "./IJobEntity";

/**
 * The result object for import schedule
 * Result object comes with a file name and url to mark any issues
 */
export default class ImportScheduleJobResult implements IImportScheduleJobResult {
    fileUri: string;
    errorFileUri: string;
    fileName: string;
    message: string;
    importStatus: ImportJobStatus;

    constructor(fileUri: string, fileName: string, message: string, importStatus: ImportJobStatus, errorFileUri?: string) {
        this.fileUri = fileUri;
        this.fileName = fileName;
        this.message = message;
        this.importStatus = importStatus;
        this.errorFileUri = errorFileUri;
    }

    static fromJson(jsonData: IImportScheduleJobResult): ImportScheduleJobResult {
        if (!jsonData) {
            return null;
        }

        return new ImportScheduleJobResult(jsonData.fileUri, jsonData.fileName, jsonData.message, jsonData.importStatus, jsonData.errorFileUri);
    }
}