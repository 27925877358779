import { action } from "satcheljs";
import { IJobEntity } from 'sh-models';

const processCopyScheduleSyncNotification = action(
    "processCopyScheduleSyncNotification",
    (
        copyJobResponse: IJobEntity
    ) => ({
        copyJobResponse: copyJobResponse
    })
);

export default processCopyScheduleSyncNotification;