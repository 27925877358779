import { action  } from "satcheljs";
import { Moment } from "moment";

const exportMatchShiftsReport = action(
    "EXPORT_MATCH_SHIFTS_REPORT",
    (
        fileName: string,
        startDate: Moment,
        endDate: Moment
    ) =>
    ({
        fileName: fileName,
        startDate: startDate,
        endDate: endDate
    })
);

export default exportMatchShiftsReport;