import { createStore, getRootStore } from "satcheljs";
import { TagHeaderViewStateStore } from "./schema/TagHeaderViewStateStoreSchema";
import setTagName from "../mutators/setTagName";
import setTagNameError from "../mutators/setTagNameError";

export default function createtagHeaderViewStateStore(tagId: string): TagHeaderViewStateStore {
    const tagHeaderViewStateStoreKey = 'tagHeaderViewStateStore_' + tagId;
    let tagHeaderViewStateStoreCurrent = getRootStore().get(tagHeaderViewStateStoreKey);
    if (tagHeaderViewStateStoreCurrent) {
        setTagName(tagHeaderViewStateStoreCurrent, "");
        setTagNameError(tagHeaderViewStateStoreCurrent, "");
    } else {
        tagHeaderViewStateStoreCurrent = createStore<TagHeaderViewStateStore>(tagHeaderViewStateStoreKey, {
            tagName: "",
            tagNameError: ""
        })();
    }
    return tagHeaderViewStateStoreCurrent;
}