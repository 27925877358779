import * as moment from "moment";
import TimeOffInputStoreSchema from "./schema/TimeOffInputStoreSchema";
import { createStore } from "satcheljs";
import { getNewShiftHintDatePickerStore } from "sh-application/components";
import "../orchestrators/onInitializeTimeoffRequest";

/* register orchestrators */

/**
 * --------------------------------------------------------
 * Don't forget to reinitialize these in resetStore mutator
 * --------------------------------------------------------
*/
const initialTimeOffInputStore: TimeOffInputStoreSchema = {

    startDate: moment(),
    endDate: moment(),
    startTime: moment(),
    endTime: moment(),
    isAllDay: false,
    timeOffReasonId: "",
    notes: "",
    member: null,
    groupTagId: null,
    theme: null,
    startDatePickerStore: getNewShiftHintDatePickerStore(),
    endDatePickerStore: getNewShiftHintDatePickerStore()
};

const timeOffInputStore = createStore("timeOffInputStore", initialTimeOffInputStore)();
export default timeOffInputStore;

/* export actions and mutator actions for ease of import */
export { default as initializeTimeoffRequest } from "../actions/initializeTimeoffRequest";
export { default as resetTimeOffInputStore } from "../mutatorActions/resetTimeOffInputStore";
export { default as setEndDateInStore } from "../mutatorActions/setEndDateInStore";
export { default as setEndTimeInStore } from "../mutatorActions/setEndTimeInStore";
export { default as setIsAllDayInStore } from "../mutatorActions/setIsAllDayInStore";
export { default as setMemberInStore } from "../mutatorActions/setMemberInStore";
export { default as setNotesInStore } from "../mutatorActions/setNotesInStore";
export { default as setStartDateInStore } from "../mutatorActions/setStartDateInStore";
export { default as setStartTimeInStore } from "../mutatorActions/setStartTimeInStore";
export { default as setThemeInStore } from "../mutatorActions/setThemeInStore";
export { default as setTimeOffReasonIdInStore } from "../mutatorActions/setTimeOffReasonIdInStore";
