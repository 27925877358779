import { action } from "satcheljs";
import { INoteEntity } from "sh-models";

const updateNotes = action(
    "UPDATE_NOTES",
    (
        notesUpdated: INoteEntity[],
        isOptimisticUpdate: boolean
    ) => ({
        notesUpdated: notesUpdated || [],
        isOptimisticUpdate: isOptimisticUpdate
    })
);

export default updateNotes;
