import DateUtils from "sh-application/utility/DateUtils";
import { AvailabilityStore } from "sh-availability-store";
import { calculateShiftAvailabilityConflict, calculateShiftConflicts, ShiftsByMemberAndDateStore } from "sh-conflict-store";
import { compareShiftsForConflicts, conflictRequestStore } from "..";
import { ECSConfigKey, ECSConfigService } from "sh-services";
import { IShiftEntity, ShiftRequestTypes } from "sh-models";
import { orchestrator } from "satcheljs";

/**
 * This orchestrator will do the request conflict calculations
 */
export default orchestrator(compareShiftsForConflicts, actionMessage => {
    const { shiftRequest, memberId } = actionMessage;
    const isSwapRequest = shiftRequest && shiftRequest.requestType === ShiftRequestTypes.Swap;

     const isShiftAvailabilityConflictsEnabled = ECSConfigService.isECSFeatureEnabled(ECSConfigKey.EnableShiftAvailabilityConflicts);
     const isShiftOverlapConflictsEnabled = ECSConfigService.isECSFeatureEnabled(ECSConfigKey.EnableShiftOverlapConflicts);
     const isShiftTimeoffConflictsEnabled = ECSConfigService.isECSFeatureEnabled(ECSConfigKey.EnableShiftTimeoffConflicts);

    // Retrieve the shifts for memberId
    const memberDateIdToShiftMap = memberId && ShiftsByMemberAndDateStore().memberIdToShiftsByDate.get(memberId);
    // go through each map of dateIntervals
    // iterate over each dateinterval recorded for this member, to look up shifts in the view start date and end date
    let viewStartDatetime = shiftRequest.startTime.clone();
    let viewEndDatetime = shiftRequest.endTime.clone();
    if ( isSwapRequest ) {
        viewStartDatetime = memberId === shiftRequest.senderMemberId ? shiftRequest.otherShiftInfo.startTime.clone() : viewStartDatetime;
        viewEndDatetime = memberId === shiftRequest.senderMemberId ? shiftRequest.otherShiftInfo.endTime.clone() : viewEndDatetime;
    }

    let dateIntervalIterator = viewStartDatetime;
    // iterate over all the days in the date range for this member
    while (memberDateIdToShiftMap && dateIntervalIterator.isSameOrBefore(viewEndDatetime)) {
        const timestampIterator = DateUtils.getStartOfDay(dateIntervalIterator).valueOf();
        let shifts: IShiftEntity[] = memberDateIdToShiftMap.shiftsByDateMap && memberDateIdToShiftMap.shiftsByDateMap.get(timestampIterator);

        // Fetch member shifts for the current timeStamp
        if (shifts && shifts.length > 0) {
            let shiftId = shiftRequest.shiftId;
            if (isSwapRequest) {
                // For swap request, sender member needs to find conflicts with otherInfo shift( receivers shift ) and vice versa
                // Also filter out the sender and receiver shifts from list of shifts to find conflicts from.
                if (memberId === shiftRequest.receiverMemberId) {
                    shiftId = shiftRequest.shiftId;
                    shifts = shifts.filter((shift) => shift.id !== shiftRequest.otherShiftInfo.shiftId);
                } else {
                    shiftId = shiftRequest.otherShiftInfo.shiftId;
                    shifts = shifts.filter((shift) => shift.id !== shiftRequest.shiftId);
                }
            }
            // retrive the request shifts from conflict request store
            const requestShift = conflictRequestStore().requestShift && conflictRequestStore().requestShift.get(shiftId);

            // if request exists in store, do availability and shift conflict calculations
            if (requestShift) {
                if (isShiftOverlapConflictsEnabled || isShiftTimeoffConflictsEnabled) {
                    calculateShiftConflicts(requestShift, shifts, true /* isRequestShift */, memberId);
                }
            }
        }

        const shiftId = shiftRequest && shiftRequest.shiftId;
        const requestShift = conflictRequestStore().requestShift && conflictRequestStore().requestShift.get(shiftId);
        // do not compare Time off Request for availability conflict
        if (requestShift && isShiftAvailabilityConflictsEnabled && shiftRequest.requestType !== ShiftRequestTypes.TimeOff) {
            calculateShiftAvailabilityConflict(memberId, requestShift, true /* isRequestShift */, AvailabilityStore().membersAvailabilities);
        }
        // go to next day
        dateIntervalIterator.add(1, "day");
    }
});