export enum JobStatus {
    Failure = "Failure",
    Success = "Success",
    Queued = "Queued",
    InProgress = "InProgress",
    PartialSuccess = "PartialSuccess"
}

export enum ImportJobStatus {
    Failure = "Failure",
    Success = "Success",
    PartialSuccess = "PartialSuccess"
}

export default interface IJobEntity {
    status: JobStatus;     // Current status of submitted job
    jobId: string;         // A unique jobId is assigned to each request which will help to get status of submitted job
    result: any;
}