import { DismissedConflictsStore } from "sh-conflict-store";
import { mutatorAction } from "satcheljs";

export default mutatorAction('RESET_DISMISSED_CONFLICT_STORE', function resetDismissedConflictStore() {
   DismissedConflictsStore().entityIdToDismissedConflictEntities.clear();
   DismissedConflictsStore().shiftIdToEntityIdMap.clear();
   DismissedConflictsStore().fetchStartTime = null;
   DismissedConflictsStore().fetchEndTime = null;
   DismissedConflictsStore().conflictIdToDismissIdMap.clear();
});
