import { computed } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";

import { appViewState } from "../../store";
import { PrintViewState } from "../../store/schema/PrintViewState";

/**
 * The 'PrintComponent' properties.
 */
interface PrintComponentProps {
    /**
     * Whether the print preview is enabled.
     * Defaults to false.
     */
    isPrintPreview?: boolean;
}

/**
 * Used to dynamically render components for printing.
 * Requires a mounted PrintWatcher component.
 *
 * Examples:
 *      <PrintWatcher />
 *
 *      <PrintComponent>
 *          <div>Hello I only render while printing</div>
 *      </PrintComponent>
 *
 */
@observer
export default class PrintComponent extends React.Component<PrintComponentProps, {}> {

    @computed
    private get viewState(): PrintViewState {
        return appViewState().printViewState;
    }

    render() {
        const { children, isPrintPreview } = this.props;

        if (!children || !(this.viewState.isPrinting || isPrintPreview)) {
            return null;
        }

        return (
                !Array.isArray(children) || children.length
                    ? React.Children.only(children)
                    : null
        );
    }
}
