import { IShiftEntity } from "sh-models";
import { mutatorAction } from "satcheljs";
import { observable, ObservableMap } from "mobx";
import { ShiftIds, ShiftStoreSchema } from "sh-shift-store";
import { trace } from "owa-trace";

export default mutatorAction('UPDATE_SHIFT_IN_STORE', function updateShiftInStore(shiftToUpdate: IShiftEntity, shiftStoreEntity: ShiftStoreSchema) {
    shiftStoreEntity.shifts.set(shiftToUpdate.id, shiftToUpdate);

    // now update the mapping of shift id to date and member id
    // first check if the date mapping exists
    let dateMembers = shiftStoreEntity.shiftIdsByDateAndMember.get(shiftToUpdate.startTime.clone().startOf("day").toString());
    if (!dateMembers) {
        // no date map exists so we need to create it
        dateMembers = {
            members: new ObservableMap<string, ShiftIds>()
        };

        // set the date to members map
        shiftStoreEntity.shiftIdsByDateAndMember.set(shiftToUpdate.startTime.clone().startOf("day").toString(), dateMembers);
    }

    if (shiftToUpdate.memberId) {
        // next check if the member exists in the date map
        let shiftIds = dateMembers.members.get(shiftToUpdate.memberId);
        if (!shiftIds) {
            // no member map exists so we need to create it
            shiftIds = {
                ids: observable.array([shiftToUpdate.id], { deep: false })
            };

            // set the member to shift id map (the new shift id is added above when creating the observable array of shift ids)
            dateMembers.members.set(shiftToUpdate.memberId, shiftIds);
        } else {
            // a member map already exists so now we just need to add the new shift id (if it doesn't exist)
            // only add the shift id if it doesn't already exist in the array
            if (shiftIds.ids.indexOf(shiftToUpdate.id) === -1) {
                shiftIds.ids.push(shiftToUpdate.id);
            }
        }
    } else {
        trace.warn(`updateShifts: shift with empty member id! shift.id = ${shiftToUpdate.id}, shiftType = ${shiftToUpdate.shiftType}`);
    }
});
