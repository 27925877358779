import setMonth from "../actions/setMonth";
import { DataProcessingHelpers, ECSConfigKey, ECSConfigService, ShiftDataService, ShiftRequestDataService, TeamDataService } from "sh-services";
import { ITeamInfoEntity } from "sh-models";
import { orchestrator } from "satcheljs";
import { setAreShiftsFetchedInStore, updateShiftsForMemberInStore } from "../store";
import { trace } from "owa-trace";

/**
 * on change of the month, fetch the shifts for the this member for that month
 */
export default orchestrator(setMonth, async actionMessage => {
    const { shiftHintDatePickerStore, month, member  } = actionMessage;
    if (month && shiftHintDatePickerStore && member) {
        setAreShiftsFetchedInStore(shiftHintDatePickerStore, false /* areShiftsFetched */);
        const team: ITeamInfoEntity = await TeamDataService.getCurrentTeam();
        if (team) {
            try {
                // Fetch the member's shifts
                // Note: We are rolling out the change to switch from ShiftDataService.getShiftsInDateRange() to ShiftRequestDataService.getShiftsInDateRangeForUserId()
                //       so we only fetch the user's shifts not all team shifts.
                const thisMonthsShifts = ECSConfigService.isECSFeatureEnabled(ECSConfigKey.UseUserSpecificApiForShiftHintDatePicker) ?
                    await ShiftRequestDataService.getShiftsInDateRangeForUserId(member.id, team.id, month.clone().startOf('month'), month.clone().endOf('month')) :
                    await ShiftDataService.getShiftsInDateRange(team.tenantId, team.id, month.clone().startOf('month'), month.clone().endOf('month'), true /* neverClearCache */);
                const shiftsForMemberForMonth = DataProcessingHelpers.getActiveWorkingShiftsForMember(thisMonthsShifts, member.id);
                updateShiftsForMemberInStore(shiftHintDatePickerStore, shiftsForMemberForMonth);
            } catch {
                trace.warn("onSetMonth: Error in getting active shifts for month");
            } finally {
                setAreShiftsFetchedInStore(shiftHintDatePickerStore, true /* areShiftsFetched */);
            }
        }
    }
});