import groupedRequestDetailsStore from "../store/store";
import { IndividualOpenShiftRequestDetails } from "../store/schema/GroupedRequestDetailsStoreSchema";
import { mutatorAction } from "satcheljs";
import { ObservableMap } from "mobx";

export default mutatorAction('RESET_GROUPED_REQUEST_DETAILS_STORE', function resetGroupedRequestDetailsStore() {
    groupedRequestDetailsStore.groupedOpenShiftRequest = null;
    groupedRequestDetailsStore.individualRequestDetails = new ObservableMap<string, IndividualOpenShiftRequestDetails>();
    groupedRequestDetailsStore.openShift = null;
    groupedRequestDetailsStore.selectedRequestsCount = 0;
    groupedRequestDetailsStore.userResponseMessage = null;
    groupedRequestDetailsStore.isDataReady = false;
    groupedRequestDetailsStore.isSaving = false;
    groupedRequestDetailsStore.closeCallback = null;
});