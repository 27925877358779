import AvailabilityStore from "../store";
import getAvailabilities from "../actions/getAvailabilities";
import setAvailabilities from "../mutators/setAvailabilities";
import { IAvailabilityEntity } from "sh-models";
import { TeamDataService } from "sh-services";
import { orchestrator } from "satcheljs";

export default orchestrator(getAvailabilities, async actionMessage => {

    // if availabilities haven't yet been fetched, fetch them from service
    if (!AvailabilityStore().hasFetchedAvailabilities.get()) {
            const availabilities: Map<string, IAvailabilityEntity[]> = await TeamDataService.getAvailabilities(actionMessage.tenantId, actionMessage.teamId);
            setAvailabilities(availabilities);

    }
});