import { createStore } from "satcheljs";

import { MemberEditorViewStateSchema } from ".";

const initialMemberEditorViewState: MemberEditorViewStateSchema = {
    isOpen: false,
    isSaving: false,
    teamMemberToAdd: null,
    currentlySelectedTeamMember: null,
    showInlineError: false,
    teamsChannelId: null,
    openDialogTriggerButton: null,
    searchError: undefined
};

const memberEditorViewState = createStore("memberEditorViewState", initialMemberEditorViewState);
export default memberEditorViewState;