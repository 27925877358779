import { IShiftRequestEntity, ShiftRequestTypes } from "sh-models";
import { ECSConfigKey, ECSConfigService } from "sh-services";

import { conflictRequestStore, loadStoreForConflictCalculations } from "../..";
import DateUtils from "../../../../sh-application/utility/DateUtils";

/**
 * Fetch the shifts for the member sending/receiving request on the request date range and update stores accordingly.
 * @param shiftRequest The shift request.
 */
export const fetchShiftRequestConflicts = (shiftRequest: IShiftRequestEntity): void => {
    conflictRequestStore().loadedRequestsIds.add(shiftRequest.id);

    switch (shiftRequest.requestType) {
        case ShiftRequestTypes.Swap: {
            // Fetch sender member Shifts and update the store with the data
            loadStoreForConflictCalculations(shiftRequest.receiverMemberId, shiftRequest.startTime, shiftRequest.endTime);
            // if its a swap request, the receiver member Id shifts also need to be fetched along with receiver shifts details
            loadStoreForConflictCalculations(shiftRequest.senderMemberId, shiftRequest.otherShiftInfo.startTime, shiftRequest.otherShiftInfo.endTime);
            return;
        }

        case ShiftRequestTypes.HandOff: {
            // if its a handoff request, the receiver member Id shifts also need to be fetched along with sender shifts details
            loadStoreForConflictCalculations(shiftRequest.receiverMemberId, shiftRequest.startTime, shiftRequest.endTime);
            return;
        }

        case ShiftRequestTypes.TimeOff: {
            const { endTime, startTime } = shiftRequest;
            const maxDays: number = ECSConfigService.getECSFeatureSetting(ECSConfigKey.ConflictCalcMaxShiftLengthInDays) ?? 65;

            if (DateUtils.getDifferenceInDaysFromMoments(startTime, endTime) < maxDays) {
                // Fetch sender member Shifts and update the store with the data
                loadStoreForConflictCalculations(shiftRequest.senderMemberId, startTime, endTime);
            }
            return;
        }

        default: {
            // Fetch sender member Shifts and update the store with the data
            loadStoreForConflictCalculations(shiftRequest.senderMemberId, shiftRequest.startTime, shiftRequest.endTime);
            return;
        }
    }
};