import { action } from "satcheljs";
import { IBaseShiftRequestEntity } from "sh-models";

const setShiftRequestRead = action(
    "SET_SHIFT_REQUEST_READ",
    (
        shiftRequest: IBaseShiftRequestEntity
    ) => ({
        shiftRequest: shiftRequest
    })
);

export default setShiftRequestRead;