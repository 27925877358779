import { AppSettings } from "sh-application";
import { LoggingService } from "sh-logging-service";

/**
 * Creates instance of logging service using the app settings
 */
function createLoggingService(): LoggingService {
    const appLogSize: number = AppSettings.getSetting("AppLogSize");
    const appLogMessageSize: number = AppSettings.getSetting("AppLogMessageSize");
    return new LoggingService(appLogSize, appLogMessageSize);
}

const loggingService = createLoggingService();
export default loggingService as LoggingService;
