import { ICopyScheduleJobEntity } from "sh-models";
import { DataFilter } from "sh-services";
import { Moment } from "moment";

/**
 * Entity of a copied schedule. Used for operations performed on a copied schedule such as undo/redo
 */
export default class CopyScheduleJobEntity implements ICopyScheduleJobEntity {
    jobId: string;
    teamId: string;
    sourceStartTime: Moment;
    sourceEndTime: Moment;
    destinationOffsetInDays: number;
    copyNotes: boolean;
    copyTimeOffShifts: boolean;
    copyActivities: boolean;
    copyOpenShifts: boolean;
    scheduleRepetition: number;
    dataFilter?: DataFilter;

    constructor(jobId: string, teamId: string, sourceStartTime: Moment, sourceEndTime: Moment, destinationOffsetInDays: number, copyNotes: boolean,
        copyTimeOffShifts: boolean, copyActivities: boolean, copyOpenShifts: boolean, scheduleRepetition: number, dataFilter?: DataFilter) {
        this.jobId = jobId;
        this.teamId = teamId;
        this.sourceStartTime = sourceStartTime;
        this.sourceEndTime = sourceEndTime;
        this.destinationOffsetInDays = destinationOffsetInDays;
        this.copyNotes = copyNotes;
        this.copyTimeOffShifts = copyTimeOffShifts;
        this.copyActivities = copyActivities;
        this.copyOpenShifts = copyOpenShifts;
        this.scheduleRepetition = scheduleRepetition;
        this.dataFilter = dataFilter;
    }

    static fromJson(jsonData: ICopyScheduleJobEntity): CopyScheduleJobEntity {
        if (!jsonData) {
            return null;
        }

        return new CopyScheduleJobEntity(jsonData.jobId, jsonData.teamId, jsonData.sourceStartTime, jsonData.sourceEndTime, jsonData.destinationOffsetInDays, jsonData.copyNotes,
            jsonData.copyTimeOffShifts, jsonData.copyActivities, jsonData.copyOpenShifts, jsonData.scheduleRepetition);
    }
}