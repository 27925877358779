
export enum LogLevel { // Add the levels in order to use for filtering
    None = 0,
    Verbose = 1,
    Debug = 2,
    Info = 3,
    Warn = 4,
    Error = 5,
    Max
}

export default class LogMessage {
    public levelName: string;

    public constructor(public message: string, public level: LogLevel, public timeStamp: Date) {
        this.levelName =  LogLevel[this.level];
    }
}