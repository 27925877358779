import { IShiftEntity } from "sh-models";
import { mutatorAction } from "satcheljs";
import { ProgressiveStore } from "../";

export default mutatorAction('UPDATE_PROGRESSIVE_SHIFTS', function updateShifts(shiftsToUpdate: IShiftEntity[]) {
    if (shiftsToUpdate && shiftsToUpdate.length) {
        for (let i = 0; i < shiftsToUpdate.length; i++) {
            const shift = shiftsToUpdate[i];
            ProgressiveStore().shifts.set(shift.id, shift);
        }
    }
});
