import { memberEditorViewState } from "../";
import { action } from "satcheljs/lib/legacy";
import { IMemberEntity } from "sh-models";

/**
 * Action to set the currently selected team member on the view state (this happens as part of add member to group flow)
 * @param {IMemberEntity} member - the team member that the user has currently selected from the name picker
 */
export default action("setCurrentlySelectedTeamMember")(
    function setCurrentlySelectedTeamMember(member: IMemberEntity) {
      memberEditorViewState().currentlySelectedTeamMember = member;
    }
);