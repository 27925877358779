import { ConflictRequestStoreSchema } from "./ConflictRequestStoreSchema";
import { createStore } from "satcheljs";
import { IBaseConflictEntity, IBaseShiftEntity } from "sh-models";
import { ObservableMap, ObservableSet } from "mobx";

const conflictRequestStore = createStore<ConflictRequestStoreSchema>(
    "conflictRequestStore",
    {
        loadedRequestsIds: new ObservableSet<string>(),
        requestShift: new ObservableMap<string, IBaseShiftEntity>(),
        memberShifts: new ObservableMap<string, IBaseShiftEntity>(),
        requestShiftIdToConflictsMap: new ObservableMap<string, Array<IBaseConflictEntity>>()
    });

export default conflictRequestStore;