import IUserDataService from "sh-services/dataservice/IUserDataService";
import NetworkService, { IDeviceHeaderProvider } from "sh-services/lib/NetworkService";
import RestClient from "sh-rest-client";
import { ClientTypes } from "StaffHubConstants";
import { DataService } from "sh-services/dataservice/DataService";
import {
    ILocalClientData,
    ILocateUserResponseEntity,
    ILoginUserEntity,
    ISessionClientData,
    IUserEntity,
    IUserPolicySettingsEntity
    } from "sh-models";
import { LocalClientDataProvider } from "sh-services/dataproviders/LocalClientDataProvider";
import { LocateUserResponseDataProvider } from "sh-services/dataproviders/LocateUserResponseDataProvider";
import { LoggedInUserDataProvider } from "sh-services/dataproviders/LoggedInUserDataProvider";
import { SessionClientDataProvider } from "sh-services/dataproviders/SessionClientDataProvider";
import { UserPolicySettingsDataProvider } from "sh-services/dataproviders/UserPolicySettingsDataProvider";

class UserDataService extends DataService implements IUserDataService, IDeviceHeaderProvider {

    /**
     * Determines the location/region of the User's data
     */
    public async locateUserRegion(): Promise<ILocateUserResponseEntity> {
        const locateUserResponseDataProvider = new LocateUserResponseDataProvider(this.loggedInUserId, this.userDatabase);
        let locateUserRegionResponse = await this.getData(locateUserResponseDataProvider);
        return locateUserRegionResponse;
    }

    /**
     * Log the user into the StaffHub (Shifts) service
     */
    public async loginToService(clientType: ClientTypes): Promise<IUserEntity> {
        // Try to load the device header from cache
        let deviceHeader = await this.getDeviceHeader();
        // Try to load the logged in user from cache
        let loggedInUserEntity: IUserEntity = await this.getLoggedInUser();
        // If the user has a device header, we'll assume they have logged into the StaffHub service
        if (!deviceHeader || !loggedInUserEntity ) {
            // remove the device header from storage before locate and login calls as we'll get the correct one from service in the login response
            await this.clearDeviceHeader();

            const { loggedInUser, userPolicySettings }: ILoginUserEntity = await RestClient.login(clientType);
            // set the user policy settings in cache
            await this.setUserPolicyInCache(userPolicySettings);

            // set the loggedin user in
            await this.setLoggedInUserInCache(loggedInUser);

            loggedInUserEntity = loggedInUser;
        }

        return loggedInUserEntity;
    }

    /**
     * Clear the device header for the session
     */
    public async clearDeviceHeader(): Promise<void> {
        await this.setDeviceHeader(null);
    }

    /**
     * Set the device header for the session.
     * @param {string} deviceHeader
     * @returns {}
     */
    public async setDeviceHeader(deviceHeader: string): Promise<void> {
        const sessionClientData: ISessionClientData = await this.getSessionClientData();
        if (sessionClientData.deviceHeader !== deviceHeader) {
            sessionClientData.deviceHeader = deviceHeader;
            await this.setSessionClientData(sessionClientData);
        }
    }

    /**
     * Get the device header for the session.
     */
    public async getDeviceHeader(): Promise<string> {
        const sessionClientData: ISessionClientData = await this.getSessionClientData();
        return sessionClientData.deviceHeader;
    }

    /**
     * Get the sync key for the session.
     */
    public async getSessionSyncKey(): Promise<string> {
        const sessionClientData: ISessionClientData = await this.getSessionClientData();
        return sessionClientData.syncKey;
    }

    /**
     * Set the sync key for the session.
     * @param {string} syncKey
     * @returns {}
     */
    public async setSessionSyncKey(syncKey: string): Promise<void> {
        const sessionClientData: ISessionClientData = await this.getSessionClientData();
        if (sessionClientData.syncKey !== syncKey) {
            sessionClientData.syncKey = syncKey;
            await this.setSessionClientData(sessionClientData);
        }
    }

    /**
     * Get if the teams list is cached for the session.
     */
    public async getIsTeamsListCached(): Promise<boolean> {
        const sessionClientData: ISessionClientData = await this.getSessionClientData();
        return sessionClientData.isTeamsListCached;
    }

    /**
     * Set if the teams list is cached for the session.
     * @param {boolean} isTeamsListCached
     * @returns {}
     */
    public async setIsTeamsListCached(isTeamsListCached: boolean): Promise<void> {
        const sessionClientData: ISessionClientData = await this.getSessionClientData();
        if (sessionClientData.isTeamsListCached !== isTeamsListCached) {
            sessionClientData.isTeamsListCached = isTeamsListCached;
            await this.setSessionClientData(sessionClientData);
        }
    }

    /**
     * Fetches the session client data from DB or in memory
     */
    private async getSessionClientData() {
        const sessionClientDataProvider = new SessionClientDataProvider(this.userDatabase, this.tenantId, this.loggedInUserId, this.sessionId);
        return await this.getData(sessionClientDataProvider);
    }

    /**
     * Set a user's SessionClientData in Database
     * @param sessionClientData User Session Client Data
     */
    private async setSessionClientData(sessionClientData: ISessionClientData) {
        const sessionClientDataProvider = new SessionClientDataProvider(this.userDatabase, this.tenantId, this.loggedInUserId, this.sessionId);
        return await this.updateDataInCache(sessionClientData, sessionClientDataProvider);
    }

    /**
     * Set if the bootstrap data has been cached in the db
     * @param {string} isWarmBootup
     * @returns {}
     */
    public async setIsWarmBootup(isWarmBootup: boolean): Promise<void> {
        const localClientData: ILocalClientData = await this.getLocalClientData();
        if (localClientData.isWarmBootup !== isWarmBootup) {
            localClientData.isWarmBootup = isWarmBootup;
            await this.setLocalClientData(localClientData);
        }
    }

    /**
     * Get if the bootstrap data has been cached in the DB (warm reload).
     */
    public async getIsWarmBootup(): Promise<boolean> {
        const localClientData: ILocalClientData = await this.getLocalClientData();
        return !!localClientData.isWarmBootup;
    }

    /**
     * Fetches the local client data from DB or in memory
     */
    private async getLocalClientData(): Promise<ILocalClientData> {
        const localClientDataProvider = new LocalClientDataProvider(this.userDatabase, this.tenantId, this.loggedInUserId);
        return await this.getData(localClientDataProvider);
    }

    /**
     * Set a user's LocalClientData in Database
     * @param localClientData User's Local Client Data
     */
    private async setLocalClientData(localClientData: ILocalClientData) {
        const localClientDataProvider = new LocalClientDataProvider(this.userDatabase, this.tenantId, this.loggedInUserId);
        return await this.updateDataInCache(localClientData, localClientDataProvider);
    }

    /**
     * Returns a list of old sessionIds that need to be deleted
     */
    public async getOldSessionIdsToDelete(): Promise<string[]> {
        return await this.userDatabase.getOldSessionIdsToDelete();
    }

    /**
     * Delete the old sessions
     * @param sessionIds List of sessionIds to delete
     */
    public async deleteSessionData(sessionIds: string[]) {
        await this.userDatabase.deleteSessionData(sessionIds);
    }

    /**
     * Return the logged in user
     */
    public async getLoggedInUser(): Promise<IUserEntity> {
        const userDataProvider = new LoggedInUserDataProvider(this.userDatabase, this.tenantId, this.loggedInUserId);
        const loggedInUser: IUserEntity = await this.loadDataFromCache(userDataProvider);
        return loggedInUser;
    }

    /**
     * Save the logged in user in the cache
     * @param user
     */
    private async setLoggedInUserInCache(user: IUserEntity): Promise<IUserEntity> {
        const userDataProvider = new LoggedInUserDataProvider(this.userDatabase, this.tenantId, this.loggedInUserId);
        return await this.updateDataInCache(user, userDataProvider);
    }

    /**
     * Save user policy in the cache
     * @param userPolicySettings
     */
    private async setUserPolicyInCache(userPolicySettings: IUserPolicySettingsEntity): Promise<IUserPolicySettingsEntity> {
        const userDataProvider = new UserPolicySettingsDataProvider(this.loggedInUserId, this.userDatabase);
        return await this.updateDataInCache(userPolicySettings, userDataProvider);
    }

    /**
     * Resets the sync state
     */
    public async resetSyncState() {
        const sessionClientData: ISessionClientData = await this.getSessionClientData();
        if (!!sessionClientData.syncKey || sessionClientData.isTeamsListCached) {
            sessionClientData.syncKey = "";
            sessionClientData.isTeamsListCached = false;
            await this.setSessionClientData(sessionClientData);
        }
    }
}

const userDataService: IUserDataService = new UserDataService();
NetworkService.setDeviceHeaderProvider(userDataService);
export default userDataService;