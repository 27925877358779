import LogMessage from "./LogMessage";

/**
 * This class is a queue of log messages. logMessages added to the queue are throttled and have a max capacity declared.
 * When items are enqueued and over capacity, items are dequeued automatically
 */
export default class LogMessageQueue {

    /**
     * All log messages held in this queue.
     */
    private _logMessages: LogMessage[] = [];

    /**
     * A set of log messages held from throttling. To be added to the logMessages queue once size limit is reached
     */
    private _logMessagesBuffer: LogMessage[] = [];
    private _timeoutPromise: Promise<any>;
    private _maxBufferLength: number = 50;

    public constructor(private maxQueueLength: number = 100) {}

    /**
     * Returns the log messages in the queue
     */
    public getLogMessages(): Array<LogMessage> {
        return this._logMessages;
    }

    /**
     * Adds the log message to the buffer/queue as needed
     */
    public enqueLogMessage(logMessage: LogMessage): void {
        this._logMessagesBuffer.unshift(logMessage);

        // Buffer reached max capacity so empty it
        if (this._logMessagesBuffer.length === this._maxBufferLength) {
            this.emptyBufferIntoQueue();
        }
    }

    /**
     * Moves the buffer data into queue
     */
    public flush(): void {
        this.emptyBufferIntoQueue();
    }

    /**
     * Clears the buffer and queue
     */
    public empty(): void {
        this._logMessagesBuffer = [];
        this._logMessages = [];
    }

    /**
     * Removes the number of messages from the queueand buffer as needed
     * @param dequeueCount the number of messages to remove
     * @returns {Array<LogMessage>} the array of removed log messages
     */
    private dequeueLogMessages(dequeueCount: number): LogMessage[] {

        let dequeueFromBufferCount: number = 0;
        let itemsDequeued: LogMessage[];
        let currentLength = this._logMessages.length;

        // We are dequing items that are in the buffer and have not yet been added to the queue.
        if (dequeueCount > currentLength) {
            dequeueFromBufferCount = Math.min(dequeueCount - currentLength, this._logMessagesBuffer.length);
            dequeueCount = currentLength;
        }

        itemsDequeued = this._logMessages.splice(currentLength - dequeueCount, dequeueCount);

        if (dequeueFromBufferCount > 0) {
            itemsDequeued.unshift.apply(itemsDequeued, this._logMessagesBuffer.splice(this._logMessagesBuffer.length - dequeueFromBufferCount, dequeueFromBufferCount));
        }

        return itemsDequeued;
    }

    /**
     * Moves the buffer data into the queue
     */
    private emptyBufferIntoQueue(): void {

        if (this._logMessagesBuffer.length > 0) {
            this.truncateMessagesToFitBuffer();

            // Insert the buffered messages
            this._logMessages.unshift.apply(this._logMessages, this._logMessagesBuffer);

            this._logMessagesBuffer = [];
        }
    }

    /**
     * This function truncates the current messages creating space of the given length under the max length.
     */
    private truncateMessagesToFitBuffer(): LogMessage[] {
        let totalMessagesLength = this._logMessages.length + this._logMessagesBuffer.length;
        let dequeuedMessages: LogMessage[] = [];

        if (totalMessagesLength > this.maxQueueLength) {
            let differenceFromMax = totalMessagesLength - this.maxQueueLength;
            dequeuedMessages = this.dequeueLogMessages(differenceFromMax);
        }

        return dequeuedMessages;
    }
}