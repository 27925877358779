import RestClient from "sh-rest-client";
import { DataProvider } from "sh-services/dataproviders/DataProvider";
import { IUserDatabase } from "sh-services/data/IUserDatabase";
import { IUserSettingsEntity } from "sh-models";
import { setUserSettings, UserStore } from "sh-stores";

/**
 * UserSettings Data Provider
 */
export class UserSettingsDataProvider extends DataProvider<IUserSettingsEntity> {

    protected loggedInUserId: string;
    protected userDatabase: IUserDatabase;

    constructor(loggedInUserId: string, userDatabase: IUserDatabase) {
        super();
        this.loggedInUserId = loggedInUserId;
        this.userDatabase = userDatabase;
    }

    /**
     * Return data if it's found in memory (otherwise return undefined)
     */
    async getDataFromMemory() {
        return (UserStore() && UserStore().userSettings ? UserStore().userSettings : undefined);
    }

    /**
     * Return data if it's found in the database (otherwise return undefined)
     */
    async getDataFromDatabase() {
        return await this.userDatabase.getUserSettings(this.loggedInUserId);
    }

    /**
     * Make an HTTP request to fetch the data from the network
     */
    async getDataFromNetwork() {
        return await RestClient.getUserSettings();
    }

    /**
     * Set data in memory
     */
    async setDataInMemory(data: IUserSettingsEntity) {
        setUserSettings(data);
    }

    /**
     * Set data in the database
     */
    async setDataInDatabase(data: IUserSettingsEntity) {
        return await this.userDatabase.setUserSettings(data);
    }

    /**
     * Make a network call to update the data
     */
    async saveDataToNetwork(data: IUserSettingsEntity): Promise<IUserSettingsEntity> {
        return await RestClient.updateUserSettings(data);
    }
}