import { action } from "satcheljs";

const getShiftRequests = action(
    "GET_SHIFT_REQUESTS",
    (
        cursor: string
    ) => ({
        cursor: cursor
    })
);

export default getShiftRequests;
