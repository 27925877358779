import * as moment from "moment";
import { action } from "satcheljs";
import { IAvailabilityEntity } from "sh-models";

const calculateConflicts = action(
    "CALCULATE_CONFLICTS",
    (
        viewStartDatetime: moment.Moment,
        viewEndDatetime: moment.Moment,
        availabilities?: Map<string, IAvailabilityEntity[]>,
        refreshConflictsCallback?: Function
    ) => ({
        viewStartDatetime: viewStartDatetime,
        viewEndDatetime: viewEndDatetime,
        availabilities: availabilities,
        refreshConflictsCallback: refreshConflictsCallback
    })
);

export default calculateConflicts;