import setEntitiesInMap from "sh-stores/setEntitiesInMap";
import { ITagEntity, TagEntity } from "sh-models";
import { mutator } from "satcheljs";
import { setTeamDetails } from "sh-team-store";
import { TagStore } from "sh-tag-store";

/**
 * Reset the team store
 */
export default mutator(setTeamDetails, (actionMessage) => {
    const teamDetails = actionMessage.teamDetails;

    // clear and set tags
    TagStore().tags.clear();
    // We use clones of the argument entities. This is because setting the entities in the satchel stores will mark them as observables
    // and any callers who still hold references to these entities will not be able to mutate them (in event of an error, for example) without
    // triggering mobx exceptions
    if (teamDetails && teamDetails.tags) {
        setEntitiesInMap<ITagEntity>(teamDetails.tags.map(tag => TagEntity.clone(tag)), TagStore().tags);
    }
});