import * as React from "react";
import App from "./App";
import UrlFactory from "../utility/UrlFactory";
import { errorWatcherStore as errorViewStateStore, handleBlockingBootstrapError } from "sh-application/components/errorWatcher";
import { getGenericEventPropertiesObject } from "sh-instrumentation";
import { initializeApplication, removeParamFromQueryString, setDeepLinkState } from "sh-application";
import { InstrumentationService } from "sh-services";
import { LoginState } from "../store/schema/LoginViewState";
import { LoginViewState } from "../store/schema/LoginViewState";
import { observer } from "mobx-react";
import { ORIGIN_QUERYSTRING_PARAMETER_NAME } from "../../StaffHubConstants";
import { Redirect, RouteComponentProps } from "react-router-dom";
import { trace } from "owa-trace";
import { UserStorageKeys } from "sh-models";
import { UserStorageService } from "sh-services";
import { UserStore } from "../../sh-stores";

interface LoginComponentProps extends RouteComponentProps<any> {
    viewState: LoginViewState;
}

@observer
export default class LoginComponent extends React.Component<LoginComponentProps, {}> {

    public static hasMostRecentTeam(): boolean {
        return !!UserStorageService.getItem(UserStorageKeys.MostRecentTeamId);
    }

    public static getMostRecentTeamId(): string | null {
        return UserStorageService.getItem(UserStorageKeys.MostRecentTeamId);
    }

    public static setMostRecentTeamId(teamId: string): void {
        if (teamId) {
            UserStorageService.setItem(UserStorageKeys.MostRecentTeamId, teamId);
        }
    }

    /**
     * Returns the original path that triggered the login or the apps base url if the login page was requested
     * directly.
     */
    private getEntryLocation = () => {
        const { from } = this.props.location.state ?? { from: { pathname: UrlFactory.getBaseUrl() } };

        return from;
    }

    async componentDidMount(): Promise<void> {
        const { history, location } = this.props;
        const deepLinkContext = UrlFactory.getDeepLinkContext(location);
        const groupId = deepLinkContext?.groupId ?? location?.state?.groupId;
        const locationPath =  location?.state?.from?.pathname;
        const teamId = location?.state?.teamId;
        const tenantId = UserStore().user?.tenantId;

        // we have an adal user
        try {
            // If any blocking error is set, we need not initialize the application
            if (errorViewStateStore().blockingError) {
                return;
            }
            try {
                if (!!deepLinkContext) {
                    // we are being launched with a deep link
                    setDeepLinkState({
                        isDeepLinkMode: true,
                        deepLinkSource: deepLinkContext.source,
                        isActivityFeedDeepLinkMode: UrlFactory.isActivityFeedDeepLink(deepLinkContext)
                    });
                    await initializeApplication(tenantId, deepLinkContext.groupId, /*teamId*/ null, UrlFactory.getRedirectUrlFromDeepLink(deepLinkContext, true /* includeQueryString */), history);
                } else {
                    // if we have a recent team handle and we are navigating to the base url (team switcher),
                    // bootstrap the team and redirect to schedules page
                    if (LoginComponent.hasMostRecentTeam() && locationPath === UrlFactory.getBaseUrl()) {
                        const mostRecentTeamId = LoginComponent.getMostRecentTeamId();
                        // we call initializeApplication knowing that we were doing to redirect the user to the most recent team
                        try {
                            await initializeApplication(tenantId, /*groupId*/ null, mostRecentTeamId, UrlFactory.getSchedulesUrl(mostRecentTeamId), history);
                        } catch (error) {
                            trace.warn("Error initializing the application during team redirect");
                            // would be nice to send user to error page here
                        }
                    } else {
                        // login to service and bootstrap if needed
                        await initializeApplication(tenantId, groupId, teamId, locationPath, history);
                    }
                }
            } catch (error) {
                trace.warn("Error initializing the application");
                handleBlockingBootstrapError(error, this.props.history);
            }
        } catch (error) {
            trace.warn("No valid ADAL token while trying to load application: " + error.message);
            handleBlockingBootstrapError(error, this.props.history);
        } finally {
            const eventDataArray = [];
            eventDataArray.push(getGenericEventPropertiesObject(InstrumentationService.properties.IsDeepLink, !!deepLinkContext));
            eventDataArray.push(getGenericEventPropertiesObject(InstrumentationService.properties.DeepLinkSource, deepLinkContext && deepLinkContext.source));

            InstrumentationService.perfMarkerEnd(InstrumentationService.perfScenarios.AppBootstrap, eventDataArray);
            App.hideLoadingScreen();
        }
    }

    render() {
        const from = this.getEntryLocation();

        if (from && this.props.viewState.loginState === LoginState.LoggedInBootstrapComplete) {
            // if we have a most recent team and user is navigating to base URL, redirect to the schedules page, otherwise navigate to the requested path
            const deepLinkContext = UrlFactory.getDeepLinkContext(location);
            const isDeepLink = !!deepLinkContext;

            const redirectUserToSchedules = !isDeepLink && LoginComponent.hasMostRecentTeam() && from.pathname === UrlFactory.getBaseUrl();
            let filteredSearchString = from.search;
            if (isDeepLink) {
                // if we are redirecting from a deep link, get the calculated/sanitized query param search string
                filteredSearchString = UrlFactory.getQueryParamStringFromDeepLink(deepLinkContext);
            } else if (from.search) {
                filteredSearchString = removeParamFromQueryString(ORIGIN_QUERYSTRING_PARAMETER_NAME, from.search);
            }

            return <Redirect
                        to={ {
                            pathname: redirectUserToSchedules
                                ? UrlFactory.getSchedulesUrl(UserStorageService.getItem(UserStorageKeys.MostRecentTeamId), null, null, false /* includeQueryString */)  // do not include the query string or it will fail the route matching
                                : isDeepLink
                                    ? UrlFactory.getRedirectUrlFromDeepLink(deepLinkContext, false /* includeQueryString */)    // do not include the query string or it will fail the route matching
                                    : from.pathname,
                            search: filteredSearchString,
                            state: this.props.location.state
                        } } />;
        }

        return null;
    }
}