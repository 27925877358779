import { IImportJobEntity } from "../../sh-models";
import { ImportJobsDataProvider } from "../dataproviders/ImportJobsDataProvider";

import { DataService } from "./DataService";
import { IImportJobsDataService } from "./IImportJobsDataService";

/**
 * Interface for ImportJobsDataService.
 */
export class ImportJobsDataService extends DataService implements IImportJobsDataService {
    /**
     * Gets the import jobs for the given teamId.
     * @param teamId Id for the team.
     * @param forceFetchFromNetwork If true, fetches data from network.
     */
    public async getImportJobs(teamId: string, forceFetchFromNetwork?: boolean): Promise<IImportJobEntity[]> {
        const importJobsDataProvider = new ImportJobsDataProvider(teamId);
        return this.getData(importJobsDataProvider, forceFetchFromNetwork);
    }

    /**
    * Resets the sync state.
    */
    public async resetSyncState() {}
}

const service: IImportJobsDataService = new ImportJobsDataService();
export default service;