import { action } from "satcheljs";
import { IShiftRequestEntity } from "sh-models";

const approveDeclineTimeOffRequest = action(
    "APPROVE_DECLINE_TIMEOFF_REQUEST",
    (
        shiftRequest: IShiftRequestEntity,
        isAccepting: boolean,
        responseMessage: string,
        onFinish?: VoidFunction
    ) => ({
        shiftRequest: shiftRequest,
        isAccepting: isAccepting,
        responseMessage: responseMessage,
        onFinish: onFinish
    })
);

export default approveDeclineTimeOffRequest;
