import { action } from "satcheljs";
import { INoteEntity } from "sh-models";

const deleteNotes = action(
    "DELETE_NOTES",
    (
        notesDeleted: INoteEntity[]
    ) => ({
        notesDeleted: notesDeleted || []
    })
);

export default deleteNotes;
