import { mutatorAction } from "satcheljs";
import { PrintSize } from "sh-application/../StaffHubConstants";
import { SchedulesViewStateStore } from "sh-application/components/schedules/lib/store/schema/SchedulesViewStateStore";

/**
 * Sets the print page size (Letter/Tabloid/A4/A5/etc..)
 */
export default mutatorAction("SET_PRINT_SIZE",
    function setPrintSize(
        viewState: SchedulesViewStateStore,
        size: PrintSize) {

        viewState.printSize = size;
    }
);