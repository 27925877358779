import ITeamSettingEntity from "./ITeamSettingEntity";
import ModelUtils from "../ModelUtils";
import ShiftrObjectModelBase from "../ShiftrObjectModelBase";
import { TEAM_SETTING_ID_PREFIX } from "sh-application/../StaffHubConstants";

/**
 * Entity class for TeamSettings
 */
export default class TeamSettingEntity extends ShiftrObjectModelBase implements ITeamSettingEntity {
    defaultValue: string;
    tenantId: string;
    teamId: string;
    value: string;
    key: string;

    constructor(
        id: string,
        eTag: string,
        defaultValue: string,
        tenantId: string,
        teamId: string,
        value: string,
        key: string) {

        let modelId = id && id.length > 0 ? id : TeamSettingEntity.generateNewTeamSettingsItemId();
        super(modelId, eTag);

        this.defaultValue = defaultValue;
        this.tenantId = tenantId;
        this.teamId = teamId;
        this.value = value;
        this.key = key;
    }

    /**
     * Return the value for the given TeamSettingsItem, or return the item's default value if it has no value.
     * @param teamSettingsItem
     */
    static getValueOrDefault(teamSettingsItem: ITeamSettingEntity): string {
        return teamSettingsItem ? (teamSettingsItem.value || teamSettingsItem.defaultValue) : null;
    }

    /**
     * Generate new team detting Id used by the app
     * @returns {string} teamSetting Id in form of TMST_<uuid>
     */
    public static generateNewTeamSettingsItemId(): string {
        return ModelUtils.generateUUIDWithPrefix(TEAM_SETTING_ID_PREFIX);
    }

    public static fromJson(jsonData: ITeamSettingEntity): ITeamSettingEntity {
        if (!jsonData) {
            return null;
        } else {
            return TeamSettingEntity.clone(jsonData);
        }
    }

    public static toJson(teamSetting: ITeamSettingEntity): ITeamSettingEntity {
        if (!teamSetting) {
            return null;
        } else {
            return TeamSettingEntity.clone(teamSetting);
        }
    }

    public static clone(teamSetting: ITeamSettingEntity): ITeamSettingEntity {
        return new TeamSettingEntity(
            teamSetting.id,
            teamSetting.eTag,
            teamSetting.defaultValue,
            teamSetting.tenantId,
            teamSetting.teamId,
            teamSetting.value,
            teamSetting.key);
    }
}
