import * as moment from "moment";
import * as React from "react";
import AutomationUtils from "sh-application/utility/AutomationUtil";
import DateUtils from "sh-application/utility/DateUtils";
import StartOfWeekPicker from "./StartOfWeekPicker";
import StringsStore from "sh-strings/store";
import { DatePicker, DayOfWeek, IDatePickerStrings, IDatePicker } from "@fluentui/react";
import { Moment } from "moment";

const styles = require("./LocalizedDatePicker.scss");
const classNames = require("classnames/bind");

interface LocalizedDatePickerProps {
    title?: string;
    datePickerClassName?: string;
    value: moment.Moment;
    timeZoneOlsonCode: string;
    isMonthPickerVisible?: boolean;
    showStartDayOfWeek?: boolean;
    onDateSelectedCallBack:  (date: moment.Moment) => void;
    startDayOfWeek?: DayOfWeek;
    onStartDayOfWeekChanged?: (dayOfWeekIndex: number) => void;
    ariaLabelDate: string;
    ariaLabelStartOfWeek?: string;
    disableAutoFocus?: boolean;
    isRequired?: boolean;
}

export default class LocalizedDatePicker extends React.Component<LocalizedDatePickerProps, any> {
    private _strings: Map<string, string>;
    private _dayPickerStrings: IDatePickerStrings;
    private _currDateFormat: string;
    private _isEmpty: boolean;
    private _datepicker = React.createRef<IDatePicker>();

    public constructor(props: LocalizedDatePickerProps) {
        super(props);
        this._datepicker = React.createRef<IDatePicker>();

        this._strings = StringsStore().registeredStringModules.get("teamsettings").strings;

        this._dayPickerStrings = {
            months: [], shortMonths: [], days: [], shortDays: [],
            goToToday: this._strings.get("goToToday"),
            prevMonthAriaLabel: this._strings.get("goToPreviousMonth"),
            nextMonthAriaLabel: this._strings.get("goToNextMonth"),
            prevYearAriaLabel: this._strings.get("goToPreviousYear"),
            nextYearAriaLabel: this._strings.get("goToNextYear"),
            invalidInputErrorMessage: this._strings.get("invalidDateErrorMessage")
        };

        // Use moment's data to initialize strings.
        const localeData = moment.localeData();
        this._dayPickerStrings.days = localeData.weekdays();
        this._dayPickerStrings.months = localeData.months();
        this._dayPickerStrings.shortMonths = localeData.monthsShort();
        this._dayPickerStrings.shortDays = localeData.weekdaysShort();
        this._dayPickerStrings.isRequiredErrorMessage = this._strings.get("invalidDateErrorMessage");

        this._currDateFormat = localeData.longDateFormat('L');
    }

    // this is executed onblur event for datepicker
    private onLostFocus = () => {
        if (this.props.isRequired && this._isEmpty) {
            // this reset is needed since onchenge do not update state value when the datepicker is empty
            // but datepickers allow been empty causing a disconnection of the datepicker and its value
            // ex. datepicker is empty but state value has the last value that was choose on datepicker
            // with this event everytime that dataepicker lost function if datepicker is empty will force
            // the component to return to state value.
            this._datepicker.current.reset();
            this.onDateChanged(this.props.value.toDate());
        }
    }

    public render() {

        // The DatePicker component uses native Javascript Date, so we need to convert our date time values, which are in the specified timezone,
        // to display versions for the user's browser.  Any date values that are returned from the component need to be converted back to the
        // timezone.
        let displayDateValue: Date = this.props.value ? this.props.timeZoneOlsonCode ? DateUtils.convertTimezoneDateTimeToDisplayTime(this.props.value.toDate(), this.props.timeZoneOlsonCode) : this.props.value.toDate() : null;

        return (
            <div className={ classNames(styles.datePickerContainer, AutomationUtils.getAutomationId("components", "QAIDLocalizedDatePicker")) }>
                <DatePicker
                    componentRef = { this._datepicker }
                    className={ this.props.datePickerClassName ? this.props.datePickerClassName : "" }
                    allowTextInput={ true }
                    label={ this.props.title ? this.props.title : ""  }
                    ariaLabel={ this.props.ariaLabelDate }
                    value={ displayDateValue }
                    strings={ this._dayPickerStrings }
                    onSelectDate={ this.onDateChanged }
                    placeholder={ moment().tz(this.props.timeZoneOlsonCode).format(this._currDateFormat) }
                    parseDateFromString={ (dateStr: string) => this.parseDateFromString(dateStr) }
                    formatDate={ this.formatDate }
                    isMonthPickerVisible={ false }
                    disableAutoFocus={ !!this.props.disableAutoFocus }
                    firstDayOfWeek={ this.props.startDayOfWeek }
                    onBlur = { this.onLostFocus }>
                </DatePicker>
                {
                    this.props.showStartDayOfWeek && this.props.showStartDayOfWeek === true &&
                    <div className={ styles.startDayOfWeekBox }>
                        <div className={ styles.datePickerLabel }>
                            { this._strings.get("startOfWeek") }
                        </div>
                        <div>
                            <StartOfWeekPicker
                                startDayOfWeek={ this.props.startDayOfWeek }
                                className={ styles.startOfWeekDropDown }
                                ariaLabelStartOfWeek={ this.props.ariaLabelStartOfWeek }
                                onChanged={ this.onStartOfWeekChanged } />
                        </div>
                    </div>
                }
            </div>
        );
    }

    onDateChanged = (newDate: Date) => {
        this._isEmpty = !newDate;
        if (newDate && this.props.onDateSelectedCallBack) {
            newDate.setHours(0, 0, 0, 0); // GoToToday can select the Now value. Clear the time

            // The DatePicker component uses native Javascript Date, so we need to convert our date time values, which are in the specified timezone,
            // to display versions for the user's browser.  Any date values that are returned from the component need to be converted back to the
            // timezone.
            const dateSelectedInTimeZone = DateUtils.convertDisplayTimeToTimezoneDateTime(newDate, this.props.timeZoneOlsonCode);
            this.props.onDateSelectedCallBack(moment(dateSelectedInTimeZone).tz(this.props.timeZoneOlsonCode));
        }
    }

    onStartOfWeekChanged = (index: number) => {
        if (this.props.onStartDayOfWeekChanged) {
            this.props.onStartDayOfWeekChanged(index);
        }
    }

    formatDate = (date: Date): string => {
        return date
               ? DateUtils.convertDisplayTimeToTimezoneMoment(date, this.props.timeZoneOlsonCode).format(this._currDateFormat)
               : "";
    }

    /**
     * Parses the display date string in the current format
     * @param dateAsString Display string of the date
     * @returns {Date}
     */
    parseDateFromString(dateAsString: string): Date {
        // When an invalid date value is entered manually, return the previously selected date value here
        // If we return the invalid date, DatePicker will show the error message for it but the value displayed will be the previously selected date
        let returnDate: Moment = (this.props.value) ? this.props.value.clone() : moment();

        if (dateAsString && dateAsString.length > 0) {
            const parsedDate: Moment = moment(dateAsString, this._currDateFormat);
            if (parsedDate && !isNaN(parsedDate.valueOf())) {
                returnDate = parsedDate.clone();
            }
        }
        // The DatePicker component uses native Javascript Date, so we need to convert our date time values, which are in the specified timezone,
        // to display versions for the user's browser.  Any date values that are returned from the component need to be converted back to the
        // timezone.
        const displayDateValue: Date = this.props.timeZoneOlsonCode && returnDate ? DateUtils.convertTimezoneDateTimeToDisplayTime(returnDate.toDate(), this.props.timeZoneOlsonCode) : returnDate.toDate();
        return displayDateValue;
    }
}