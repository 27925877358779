import { ChangeStoreSchema } from "./schema/ChangeStoreSchema";
import { createStore } from "satcheljs";
import { IChangeEntity } from "sh-models";
import { observable } from "mobx";

let ChangeStore = createStore<ChangeStoreSchema>(
    "changeStore",
    {
        undoStack: observable(new Array<Array<IChangeEntity>>()),
        redoStack: observable(new Array<Array<IChangeEntity>>())
    }
);

export default ChangeStore;