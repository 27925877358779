import schedulesViewStateStore from "../store/store";
import { mutatorAction } from "satcheljs";

/**
 * Sets a member (member id) that has been progressive loaded. Consumers can use this
 * to know if the data for a specific member has been loaded yet. This data is reset
 * if the user changes the schedule view (date range, view type, etc.)
 * @param memberId - string - the groupTagId
 */
export default mutatorAction('SET_MEMBER_IN_VIEW', function setMemberInView(
    memberId: string
) {
    schedulesViewStateStore().membersInView.push(memberId);
});
