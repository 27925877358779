import { action } from "satcheljs";

const importSchedule = action(
    "importSchedule",
    (
        teamId: string,
        file: File
    ) => ({
        teamId: teamId,
        file: file
    })
);

export default importSchedule;