import { IShiftEntity } from "sh-models";
import { mutatorAction } from "satcheljs";
import { ShiftStoreSchema } from "sh-shift-store";

export default mutatorAction('DELETE_SHIFTS_IN_STORE', function deleteShiftsInStore(shiftsToDelete: IShiftEntity[], shiftStoreEntity: ShiftStoreSchema) {
    shiftsToDelete.map((shiftModel) => {
        // if we have this shift in the store
        if (shiftStoreEntity.shifts.has(shiftModel.id)) {
            // delete it
            shiftStoreEntity.shifts.delete(shiftModel.id);
            const dateKey = shiftModel.startTime.clone().startOf("day").toString();

            // and remove it from the date/member map
            let dateMembers = shiftStoreEntity.shiftIdsByDateAndMember.get(dateKey);
            if (dateMembers) {
                let shiftIds = dateMembers.members.get(shiftModel.memberId);
                if (shiftIds) {
                    shiftIds.ids.remove(shiftModel.id);

                    // if there are no more shifts for this member, remove them from the member map
                    if (!shiftIds.ids.length) {
                        dateMembers.members.delete(shiftModel.memberId);
                    }

                    // if there are no more members in the date map, delete the map
                    if (!dateMembers.members.size) {
                        shiftStoreEntity.shiftIdsByDateAndMember.delete(dateKey);
                    }
                }
            }
        }
    });
});
