import { IShiftRequestEntity, ShiftRequestStates } from "sh-models";

import { conflictRequestStore } from "../..";
import DateUtils from "../../../../sh-application/utility/DateUtils";

/**
 * Checks whether given shift request conflicts should be loaded.
 * @param shiftRequest The shift request.
 * @returns Whether shift request conflicts should be loaded.
 */
export const shouldLoadShiftRequestConflicts = (shiftRequest: IShiftRequestEntity): boolean => {
    const pendingShiftRequestStates = new Set([
        ShiftRequestStates.WaitingOnManager,
        ShiftRequestStates.WaitingOnReceiver
    ]);

    const isPending = pendingShiftRequestStates.has(shiftRequest.state);
    const isLoaded = conflictRequestStore().loadedRequestsIds.has(shiftRequest.id);

    return isPending && !isLoaded && DateUtils.isDateNowOrFuture(shiftRequest.endTime);
};
