import { createStore } from "satcheljs";
import { ScheduleTeamPickerPaneStore } from "./schema/ScheduleTeamPickerPaneStore";

/**
 * Team picker pane view state
 */
const initialScheduleTeamPickerPaneStore: ScheduleTeamPickerPaneStore = {
    isDataLoading: false                                              // true if data is currently being loaded
};

const scheduleTeamPickerPaneStore = createStore("scheduleTeamPickerPaneStore", initialScheduleTeamPickerPaneStore)();
export default scheduleTeamPickerPaneStore;

/* export actions and mutator actions for ease of import */
export { default as setIsTeamPickerPaneDataLoading } from "../mutatorActions/setIsTeamPickerPaneDataLoading";
