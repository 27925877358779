import { trace } from "owa-trace";
import { orchestrator } from "satcheljs";
import { StaffHubHttpError } from "sh-application";
import { InstrumentationEventPropertyInterface, getGenericEventPropertiesObject } from "sh-instrumentation";
import { InstrumentationService, TeamDataService } from "sh-services";

import { getImportJobs } from "../actions/getImportJobs";
import { importJobSchedule } from "../actions/importJobSchedule";
import { setImportDialogErrorMessage } from "../mutatorActions/setImportDialogErrorMessage";
import { setImportJobInProgress } from "../mutatorActions/setImportJobInProgress";
import { showImportDialog } from "../mutatorActions/showImportDialog";
import { ImportJobsStore } from "../store/ImportJobsStore";

/**
 * Creates the import job and update the store with latest import jobs.
 */
export const onImportJobSchedule = orchestrator(importJobSchedule, async action => {
    // Check if the import job creation is in progress
    if (ImportJobsStore().jobInProgress) {
        return;
    }

    setImportJobInProgress(true);
    const eventProperties: Array<InstrumentationEventPropertyInterface> = [];
    let isFileUploadSuccess = false;

    try {
        // Submit import job
        const importScheduleJob = await TeamDataService.importScheduleFromExcel(action.teamId, action.file);

        // After import job submitted, get import jobs again to update the UI with the latest import job
        if (importScheduleJob?.jobId) {
            // Close the dialog
            showImportDialog(false);
            getImportJobs(action.teamId);
        }
        isFileUploadSuccess = true;
    } catch (error) {
        const httpError: StaffHubHttpError = error;
        setImportDialogErrorMessage(httpError.staffHubError.message);
        trace.error("Import Schedule API Call Failed", error);
    } finally {
        setImportJobInProgress(false);
        // log events for Import Excel Data file loaded and upload success
        eventProperties.push(
            getGenericEventPropertiesObject(
                InstrumentationService.properties.EventType,
                InstrumentationService.values.ImportExcelDataFileLoaded
            )
        );
        eventProperties.push(getGenericEventPropertiesObject(InstrumentationService.properties.IsSuccess, isFileUploadSuccess));
        InstrumentationService.logEvent(InstrumentationService.events.ImportExcel, eventProperties);
    }
});
