import ChangeUtils from "sh-application/utility/ChangeUtils";
import DateUtils from "sh-application/utility/DateUtils";
import ShiftUtils from "sh-application/utility/ShiftUtils";
import { isMoment, Moment } from "moment";
import { orchestrator } from "satcheljs";
import { ShiftDataService } from "sh-services";
import { StaffHubHttpError, triggerShiftMove } from "sh-application";
import { trace } from "owa-trace";
import { trackChanges } from "sh-change-store";
import {
    ChangeEntity,
    IBaseShiftEntity,
    IChangeEntity,
    IMemberEntity,
    IOpenShiftEntity,
    IShiftEntity,
    ISubshiftEntity,
    OpenShiftEntity,
    ShiftMoveActionTypes,
    IAssignOpenShiftResponseEntity,
    ShiftEntity,
    ChangeSource,
    IUpdateShiftResponseEntity,
    ScheduleCalendarType,
    ScheduleCalendarTypes,
    } from "sh-models";

/**
 * Handles shift move actions for shift drag and drop, moving shifts between assigned shifts and open shifts, etc.
 * Parses the input data and then uses handleAssignedShiftMove and handleOpenShiftMove
 * to shoot off a sequence of API calls that add, update, and delete assigned and open shifts
 */
export default orchestrator(triggerShiftMove, async (actionMessage) => {
    const { sourceShift, targetShift, scheduleCalendarType, slotStartTime, slotTagId, slotMember, moveType, changeSource, onServiceError } = actionMessage;

    if (!sourceShift) {
        trace.warn("Shift move happened with no or incomplete shift information");
        return;
    }

    // Determine if the source and target shifts are open shifts, and if the target cell is in an open shift row
    const sourceShiftIsOpen: boolean = ShiftUtils.isOpenShift(sourceShift);
    const targetShiftIsOpen: boolean = ShiftUtils.isOpenShift(targetShift);
    const targetIsInOpenShiftRow: boolean = targetShiftIsOpen || !slotMember;

    // Determine if the target shift is an unshared deleted shift. This changes the behavior of drag and drop, as we will
    // update the target shift and delete the source shift. This is done so that employees will receive an update notification for
    // that deleted shift rather than a delete notification and an add notification
    const targetShiftIsAnUnsharedDeletedShift: boolean = ShiftUtils.isUnsharedDeletedShift(targetShift);

    // create a copy of the original source shift for undo
    const originalShift: IBaseShiftEntity = sourceShiftIsOpen ? OpenShiftEntity.clone(sourceShift as IOpenShiftEntity) : ShiftEntity.clone(sourceShift as IShiftEntity);
    // create a copy of the original source shift to potentially modify
    let mutatedSourceShift: IBaseShiftEntity = sourceShiftIsOpen ? OpenShiftEntity.clone(sourceShift as IOpenShiftEntity) : ShiftEntity.clone(sourceShift as IShiftEntity);

    // Drops can be replacements, meaning the existing entities in the cell are deleted,
    // or adds, meaning the existing entities are unchanged
    const isAddMove: boolean = moveType === ShiftMoveActionTypes.Add;

    const sourceAndTargetAreSimilarShifts: boolean = ShiftUtils.areSimilarShiftsForOpenShifts(sourceShift, targetShift);

    const teamId: string = sourceShift.teamId;
    const tenantId: string = sourceShift.tenantId;

    // get the offset (in days or hours) we are moving the shift by
    const offset: number = getOffset(sourceShift.startTime.clone(), slotStartTime, scheduleCalendarType);

    // If the source shift is an assigned shift, we will always update the source shift's time.
    // If the source shift is an open shifts and is being dropped onto an open shift row, we will update the source shift. This is because dropping it onto
    // a regular member row will result in an assignOpenShift() api call, in which the service update the open shift model.
    if (!sourceShiftIsOpen || targetIsInOpenShiftRow) {
        // Update the source shift's start and end date
        adjustTimeByOffset(mutatedSourceShift.startTime, offset, scheduleCalendarType);
        adjustTimeByOffset(mutatedSourceShift.endTime, offset, scheduleCalendarType);

        // For assigned shift sources, update the source shift's member id
        mutatedSourceShift.memberId = (slotMember && !sourceShiftIsOpen) ? slotMember.id : null;

        // For all sources, update the group
        if (slotTagId) {
            mutatedSourceShift.tagIds = [slotTagId];
        }

        // update the activities
        // Note: breaks currently don't use start time and we aren't updating the breaks.
        // Note: We don't copy activities when we copy paste shifts. But with drag and drop, we do copy activities over as we lose the original shift
        if (mutatedSourceShift.subshifts) {
            for (let i = 0; i < mutatedSourceShift.subshifts.length; i++) {
                let activity: ISubshiftEntity = mutatedSourceShift.subshifts[i];
                if (isMoment(activity.startTime) && isMoment(activity.endTime)) {
                    adjustTimeByOffset(activity.startTime, offset, scheduleCalendarType);
                    adjustTimeByOffset(activity.endTime, offset, scheduleCalendarType);
                }
            }
        }
    }
    try {
        let trackedChanges: ChangeEntity[] = [];
        //  we are dropping an open shift
        if (sourceShiftIsOpen) {
            await handleOpenShiftMove(
                trackedChanges,
                isAddMove,
                targetIsInOpenShiftRow,
                sourceAndTargetAreSimilarShifts,
                targetShiftIsAnUnsharedDeletedShift,
                tenantId,
                teamId,
                slotTagId,
                slotMember,
                mutatedSourceShift as IOpenShiftEntity,
                targetShift,
                originalShift as IOpenShiftEntity,
                changeSource,
                onServiceError,
                offset,
                scheduleCalendarType);
        // we are dropping an assigned shift, update the source shift with the new date & tag & member
        } else {
            await handleAssignedShiftMove(
                trackedChanges,
                isAddMove,
                targetIsInOpenShiftRow,
                sourceAndTargetAreSimilarShifts,
                targetShiftIsAnUnsharedDeletedShift,
                tenantId,
                teamId,
                mutatedSourceShift as IShiftEntity,
                targetShift,
                originalShift,
                changeSource,
                onServiceError);
        }
        trackChanges(trackedChanges);
    } catch (error) {
        const errorMessage = error && error.staffHubTopLevelErrorMessage ? error.staffHubTopLevelErrorMessage : "error";
        trace.warn("onShiftDrop failed: " + errorMessage);
        onServiceError(error);
    }
});

/**
 * Called when an assigned shift is dropped/moved onto a schedule cell
 * @param trackedChanges - list of changes to track. this function adds to the list
 * @param isAddMove - true if the drop should add to the existing content in the cell, otherwise it should replace
 * @param targetIsInOpenShiftRow - true if the drop occured on a cell in an open shift row
 * @param sourceAndTargetAreSimilarShifts - true if the source and target shifts are similar, which means adds should increment slot counts for open shifts
 * @param targetShiftIsAnUnsharedDeletedShift - true if the target shift is an unshared deleted shift.
 * @param tenantId
 * @param teamId
 * @param mutatedSourceShift - a copy of the source shift that has been adjusted to the date/tag of the target cell
 * @param targetShift - null if drop is on an empty cell, otherwise this is the existing shift in the target cell
 * @param originalShift - copy of original source shift
 * @param changeSource - ChangeSource type to record for tracked changes handling
 * @param onServiceError - callback fired on error
 */
async function handleAssignedShiftMove(
    trackedChanges: IChangeEntity[],
    isAddMove: boolean,
    targetIsInOpenShiftRow: boolean,
    sourceAndTargetAreSimilarShifts: boolean,
    targetShiftIsAnUnsharedDeletedShift: boolean,
    tenantId: string,
    teamId: string,
    mutatedSourceShift: IShiftEntity,
    targetShift: IBaseShiftEntity,
    originalShift: IBaseShiftEntity,
    changeSource: ChangeSource,
    onServiceError: (error: StaffHubHttpError) => void) {
    // When dropping an assigned shift onto an open shift row, delete the assigned shift. For add type drops, then either create a new open shift, or
    // update the target open shift's slot count. For replace drops, we will delete the target open shift
    if (targetIsInOpenShiftRow) {
        await ShiftDataService.deleteShift(originalShift, true /* optimistic*/, false/*isPublished*/);
        trackedChanges.push(new ChangeEntity([originalShift], null, changeSource));

        // If source and target shift are similar and this is an add drop, dropping the assigned source shift onto the target open shift will increment the target shift's slot count
        // If the target shift is deleted and has shared changes, we will update it to share the source shift properties and change its deleted state
        if (shouldUpdateTargetOpenShift(sourceAndTargetAreSimilarShifts, isAddMove, targetShiftIsAnUnsharedDeletedShift)) {
            const shiftToUpdate: IOpenShiftEntity = getUpdatedTargetOpenShift(mutatedSourceShift, targetShift as IOpenShiftEntity, targetShiftIsAnUnsharedDeletedShift);
            const updatedOpenShift: IOpenShiftEntity = await ShiftDataService.updateOpenShift(tenantId, teamId, shiftToUpdate as IOpenShiftEntity, true /*optimistic*/, false /*isPublished*/);
            trackedChanges.push(new ChangeEntity([targetShift], [updatedOpenShift], changeSource, ChangeUtils.getRevertUpdateOpenShiftsTask(tenantId, teamId, [targetShift as IOpenShiftEntity], [updatedOpenShift], changeSource)));
        } else {
            // If they are not similar or this is a replace drop, we'll just add a new open shift
            const newOpenShift = OpenShiftEntity.fromAssignedShift(mutatedSourceShift);
            const addedOpenShift: IOpenShiftEntity = await ShiftDataService.addOpenShift(tenantId, teamId, newOpenShift, true /*optimistic*/, false /*isPublished*/);
            trackedChanges.push(new ChangeEntity([null], [addedOpenShift], changeSource, ChangeUtils.getRevertAddOpenShiftsTask(tenantId, teamId, [addedOpenShift], changeSource)));
        }

        // For replace drops, we will also delete the open shift already in the row
        if (!isAddMove) {
            const deletedOpenShift: IOpenShiftEntity = await ShiftDataService.deleteOpenShift(tenantId, teamId, targetShift as IOpenShiftEntity, true /*optimistic*/, false /*isPublished*/);
            trackedChanges.push(new ChangeEntity([targetShift], null, changeSource, ChangeUtils.getRevertDeleteOpenShiftsTask(tenantId, teamId, [deletedOpenShift], changeSource)));
        }
    } else {
        const shiftToUpdate: IBaseShiftEntity = getUpdatedTargetAssignedShift(mutatedSourceShift, targetShift, targetShiftIsAnUnsharedDeletedShift);
        const shiftResponseEntity: IUpdateShiftResponseEntity = await ShiftDataService.updateShift(shiftToUpdate, true);
        const updatedShift = shiftResponseEntity.shift;
        trackedChanges.push(new ChangeEntity([originalShift], [updatedShift], changeSource));

        // If the drop is a replace drop, we will also delete the target shift
        const shiftToDelete: IBaseShiftEntity = getShiftToDeleteForAssignedDroppedOntoAssigned(mutatedSourceShift, targetShift, isAddMove, targetShiftIsAnUnsharedDeletedShift);
        if (shiftToDelete) {
            // delete the existing shift and return the promise which will resolve with updated tracked changes
            await ShiftDataService.deleteShift(shiftToDelete, true /* isOptimistic */);
            trackedChanges.push(new ChangeEntity([shiftToDelete], null, changeSource));
        }
    }
}

/**
 * Called when an assigned shift is dropped/moved onto a schedule cell
 * @param trackedChanges - list of changes to track. this function adds to the list
 * @param isAddMove - true if the drop should add to the existing content in the cell, otherwise it should replace
 * @param targetIsInOpenShiftRow - true if the drop occured on a cell in an open shift row
 * @param sourceAndTargetAreSimilarShifts - true if the source and target shifts are similar, which means adds should increment slot counts for open shifts
 * @param targetShiftIsAnUnsharedDeletedShift - true if the target shift is an unshared deleted shift.
 * @param tenantId
 * @param teamId
 * @param slotTagId - tagId of the group the target cell is in
 * @param slotMember - member of the cell in the target row
 * @param mutatedSourceShift - a copy of the source shift that has been adjusted to the date/tag of the target cell
 * @param targetShift - null if drop is on an empty cell, otherwise this is the existing shift in the target cell
 * @param originalShift - copy of original source shift
 * @param changeSource - ChangeSource type to record for tracked changes handling
 * @param onServiceError - callback fired on error
 * @param offset - offset of the target slot and the source shift in days
 */
async function handleOpenShiftMove(
    trackedChanges: IChangeEntity[],
    isAddMove: boolean,
    targetIsInOpenShiftRow: boolean,
    sourceAndTargetAreSimilarShifts: boolean,
    targetShiftIsAnUnsharedDeletedShift: boolean,
    tenantId: string,
    teamId: string,
    slotTagId: string,
    slotMember: IMemberEntity,
    mutatedSourceShift: IOpenShiftEntity,
    targetShift: IBaseShiftEntity,
    originalShift: IOpenShiftEntity,
    changeSource: ChangeSource,
    onServiceError: (error: StaffHubHttpError) => void,
    offset: number,
    scheduleCalendarType: ScheduleCalendarType) {
    // When dropping on an open shift cell, either update the source shift with new date & tag, or delete the source shift and increment the slot count of the
    // target shift and delete the target open shift if this is a replace drop
    if (targetIsInOpenShiftRow) {
        // If source and target shift are similar and this is an add drop, dropping the source open shift onto the target open shift will increment the target shift's slot count
        // and delete the source open shift
        // If the target shift is deleted and has shared changes, we will update it to share the source shift properties and change its deleted state
        if (shouldUpdateTargetOpenShift(sourceAndTargetAreSimilarShifts, isAddMove, targetShiftIsAnUnsharedDeletedShift)) {
            // Update target open shift
            let shiftToUpdate: IOpenShiftEntity = getUpdatedTargetOpenShift(mutatedSourceShift, targetShift as IOpenShiftEntity, targetShiftIsAnUnsharedDeletedShift);
            const updatedOpenShift: IOpenShiftEntity = await ShiftDataService.updateOpenShift(tenantId, teamId, shiftToUpdate as IOpenShiftEntity, true /*optimistic*/, false /*isPublished*/);
            trackedChanges.push(new ChangeEntity([targetShift], [updatedOpenShift], changeSource, ChangeUtils.getRevertUpdateOpenShiftsTask(tenantId, teamId, [targetShift as IOpenShiftEntity], [updatedOpenShift], changeSource)));
            // Delete the source open shift
            const deletedOpenShift: IOpenShiftEntity = await  ShiftDataService.deleteOpenShift(tenantId, teamId, originalShift as IOpenShiftEntity, true /*optimistic*/, false /*isPublished*/);
            trackedChanges.push(new ChangeEntity([originalShift], null, changeSource, ChangeUtils.getRevertDeleteOpenShiftsTask(tenantId, teamId, [deletedOpenShift], changeSource)));
        } else {
            // If they are not similar or this was a replace, we'll simply update the source open shift
            const updatedOpenShift: IOpenShiftEntity = await ShiftDataService.updateOpenShift(tenantId, teamId, mutatedSourceShift as IOpenShiftEntity, true /*optimistic*/, false /*isPublished*/);
            trackedChanges.push(new ChangeEntity([originalShift], [updatedOpenShift], changeSource, ChangeUtils.getRevertUpdateOpenShiftsTask(tenantId, teamId, [originalShift as IOpenShiftEntity], [updatedOpenShift], changeSource)));
        }

        // replace drops will delete the target open shift
        if (!isAddMove) {
            const deletedOpenShift: IOpenShiftEntity = await ShiftDataService.deleteOpenShift(tenantId, teamId, targetShift as IOpenShiftEntity, true /*optimistic*/, false /*isPublished*/);
            trackedChanges.push(new ChangeEntity([targetShift], null, changeSource, ChangeUtils.getRevertDeleteOpenShiftsTask(tenantId, teamId, [deletedOpenShift], changeSource)));
        }
    // When dropping on a member row, we will assign this open shift to the member and maybe delete the resulting assigned shift or the target shift
    } else if (slotMember) {
        const destinationSlotStartTime: Moment = mutatedSourceShift.startTime.clone();
        const destinationSlotEndTime: Moment = mutatedSourceShift.endTime.clone();
        adjustTimeByOffset(destinationSlotStartTime, offset, scheduleCalendarType);
        adjustTimeByOffset(destinationSlotEndTime, offset, scheduleCalendarType);

        const assignOpenShiftResponse: IAssignOpenShiftResponseEntity = await ShiftDataService.assignOpenShift(
            tenantId,
            teamId,
            mutatedSourceShift,
            slotMember.id,
            slotTagId,
            destinationSlotStartTime,
            destinationSlotEndTime);

        trackedChanges.push(new ChangeEntity(
            [originalShift],
            [assignOpenShiftResponse.openShift],
            changeSource,
            ChangeUtils.getRevertUpdateOpenShiftsTask(tenantId, teamId, [originalShift as IOpenShiftEntity], [assignOpenShiftResponse.openShift], changeSource))
        );

        // If we are dropping onto an unshared deleted shift, we'll delete the assigned shift created via the assignOpenShift api and instead
        // update the target shift with the properties of that same assigned shift created via assignOpenShift.
        // This way the user receives an update notification rather than an add notification
        if (shouldUpdateTargetShiftForAssignOpenShiftAPI(targetShiftIsAnUnsharedDeletedShift)) {
            const updatedTargetShift: IBaseShiftEntity = getUpdatedTargetShiftForAssignOpenShiftAPI(mutatedSourceShift, targetShift, destinationSlotStartTime, destinationSlotEndTime);
            const shiftResponseEntity: IUpdateShiftResponseEntity = await ShiftDataService.updateShift(updatedTargetShift, true);
            trackedChanges.push(new ChangeEntity([originalShift], [ shiftResponseEntity.shift], changeSource));
        }

        const shiftToDelete: IBaseShiftEntity = getShiftToDeleteForAssignOpenShiftAPI(targetShift, assignOpenShiftResponse.assignedShift, isAddMove, targetShiftIsAnUnsharedDeletedShift);
        if (shiftToDelete) {
            await ShiftDataService.deleteShift(shiftToDelete, true /*optimistic*/);
            trackedChanges.push(new ChangeEntity([shiftToDelete], null, changeSource));
        }
    }
}

/**
 * Returns the shift to delete after an assigned shift is dropped onto another target assigned shift. May be the source shift,
 * the target shift, or null.
 * @param mutatedSourceShift
 * @param targetShift
 * @param isAddMove
 * @param targetShiftIsAnUnsharedDeletedShift
 */
function getShiftToDeleteForAssignedDroppedOntoAssigned(mutatedSourceShift: IBaseShiftEntity, targetShift: IBaseShiftEntity, isAddMove: boolean, targetShiftIsAnUnsharedDeletedShift: boolean): IBaseShiftEntity {
    let shiftToDelete: IBaseShiftEntity = null;
    // Case one: Because the target shift is an unshared deleted shift, we will delete the source shift instead
    // This is because the target shift will be the one updated.
    if (targetShiftIsAnUnsharedDeletedShift) {
        shiftToDelete = mutatedSourceShift;
    // Case two: Because the target shift is a regular assigne shift, we will delete the target assigned shift if the drop was not an add drop
    } else if (!isAddMove && targetShift) {
        shiftToDelete = targetShift;
    }

    return shiftToDelete;
}

/**
 * Get the updated target shift after dropping an assigned shift onto another assigned shift.
 * @param mutatedSourceShift
 * @param targetShift
 * @param targetShiftIsAnUnsharedDeletedShift
 */
function getUpdatedTargetAssignedShift(mutatedSourceShift: IBaseShiftEntity, targetShift: IBaseShiftEntity, targetShiftIsAnUnsharedDeletedShift: boolean): IBaseShiftEntity {
    let updatedTargetShift: IBaseShiftEntity = ShiftEntity.clone(mutatedSourceShift);
    // In the case that we have dropped the shift onto an unshared deleted shift, we'll use the properties
    // of the mutated source shift but keep the id and eTag of the target shift. This way the user will see an update
    // notification for the target shift.
    if (targetShiftIsAnUnsharedDeletedShift) {
        updatedTargetShift.id = targetShift.id;
        updatedTargetShift.eTag = targetShift.eTag;
    }

    return updatedTargetShift;
}

/**
 * When updating the target shift after an assign open shift API call (shouldUpdateTargetShiftForAssignOpenShiftAPI is true), we will take the properties of the source shift and
 * apply them to the target shift. We'll keep the id and eTag of the target shift, and we'll overwrite the start and end time with values calculated by the caller.
 * @param mutatedSourceShift
 * @param targetShift
 */
function getUpdatedTargetShiftForAssignOpenShiftAPI(mutatedSourceShift: IOpenShiftEntity, targetShift: IBaseShiftEntity, startTime: Moment, endTime: Moment): IBaseShiftEntity {
    let updatedTargetShift = ShiftEntity.fromOpenShift(mutatedSourceShift, targetShift.memberId);
    updatedTargetShift.id = targetShift.id;
    updatedTargetShift.eTag = targetShift.eTag;
    updatedTargetShift.startTime = startTime;
    updatedTargetShift.endTime = endTime;

    return updatedTargetShift;
}

/**
 * When dropping an open shift onto an assigned shift, we may update the target shift after the assign open shift API is completed.
 * @param targetShiftIsAnUnsharedDeletedShift
 */
function shouldUpdateTargetShiftForAssignOpenShiftAPI(targetShiftIsAnUnsharedDeletedShift: boolean): boolean {
    // If we are dropping onto an unshared deleted shift, we'll delete the assigned shift created via the assignOpenShift api and instead
    // update the target shift with the properties of that same assigned shift created via assignOpenShift.
    // This way the user receives an update notification rather than an add notification
    return targetShiftIsAnUnsharedDeletedShift;
}

/**
 * Usually when assigning to a member, we will delete any targetShift in the destination slot if this is not an add drop.
 * In the case that we are dropping onto an unshared deleted shift, we will actually delete the shift created via assign shift,
 * as we will instead update the target shift.
 */
function getShiftToDeleteForAssignOpenShiftAPI(targetShift: IBaseShiftEntity, createdAssignedShift: IBaseShiftEntity, isAddMove: boolean, targetShiftIsAnUnsharedDeletedShift: boolean): IBaseShiftEntity {
    let shiftToDelete: IBaseShiftEntity = null;
    if (shouldDeleteAssignedShiftFromAssignOpenShiftAPI(targetShiftIsAnUnsharedDeletedShift)) {
        shiftToDelete = createdAssignedShift;
    } else if (shouldDeleteTargetShiftForAssignOpenShiftAPI(isAddMove)) {
        shiftToDelete = targetShift;
    }

    return shiftToDelete;
}

/**
 * When dropping an open shift onto a target assigned shift, we launch an assign open shift API call.
 * Unless this an an add drop, we will delete the target shift.
 * @param isAddMove
 */
function shouldDeleteTargetShiftForAssignOpenShiftAPI(isAddMove: boolean): boolean {
    return !isAddMove;
}

/**
 * When dropping an open shift onto a target assigned shift, we launch an assign open shift API call.
 * If the target shift is an unshared deleted shift, we'll actually delete the shift created via the assign shift api
 * and instead update the target shift.
 * @param targetShiftIsAnUnsharedDeletedShift
 */
function shouldDeleteAssignedShiftFromAssignOpenShiftAPI(targetShiftIsAnUnsharedDeletedShift: boolean): boolean {
    return targetShiftIsAnUnsharedDeletedShift;
}

/**
 * When dropping onto an open shift, we update that target shift if either the source and target shift are similar and this is an add drop
 * or if the target shift is an unshared deleted shift.
 * @param sourceAndTargetAreSimilarShifts
 * @param isAddMove
 * @param targetShiftIsAnUnsharedDeletedShift
 */
function shouldUpdateTargetOpenShift(sourceAndTargetAreSimilarShifts: boolean, isAddMove: boolean, targetShiftIsAnUnsharedDeletedShift: boolean): boolean {
    return (sourceAndTargetAreSimilarShifts && isAddMove) || targetShiftIsAnUnsharedDeletedShift;
}

/**
 * When dropping onto an open shift in the destination slot, once we determine that we will update the target shift (shouldUpdateTargetOpenShift is true),
 * we usually increment its open slot count. In the case where the target shift is deleted and has shared changes, we'll replace its properties with those
 * of the source, but keep the id and eTag of the target shift.
 * @param mutatedSourceShift
 * @param targetShift
 * @param targetShiftIsAnUnsharedDeletedShift
 */
function getUpdatedTargetOpenShift(mutatedSourceShift: IBaseShiftEntity, targetShift: IOpenShiftEntity, targetShiftIsAnUnsharedDeletedShift: boolean): IOpenShiftEntity {
    let shiftToUpdate: IOpenShiftEntity = null;
    // If the target shift is deleted and has shared changes, we will update it to share the source shift properties and change its deleted state
    if (targetShiftIsAnUnsharedDeletedShift) {
        shiftToUpdate = OpenShiftEntity.fromAssignedShift(mutatedSourceShift);
        shiftToUpdate.id = targetShift.id;
        shiftToUpdate.eTag = targetShift.eTag;
    } else {
        // Otherwise dropping the source shift onto the target open shift will increment the target shift's slot count
        // If the source shift was an open shift, we increment the slot count by the num slots in the source
        shiftToUpdate = OpenShiftEntity.clone(targetShift);
        const slotIncrement: number = ShiftUtils.isOpenShift(mutatedSourceShift) ? (mutatedSourceShift as IOpenShiftEntity).openSlots : 1;
        shiftToUpdate.openSlots += slotIncrement;
    }

    return shiftToUpdate;
}

/**
 * Apply an offset to the provided time. Will add the offset as hours in day view and in days for other views. Mutates
 * the provided time.
 * @param time
 * @param offset
 * @param scheduleCalendarType
 */
function adjustTimeByOffset(time: Moment, offset: number, scheduleCalendarType: ScheduleCalendarType) {
    const offsetTypeName = scheduleCalendarType === ScheduleCalendarTypes.Day ? "hours" : "days";
    time.add(offset, offsetTypeName);
}

/**
 * Get the offset in days or hours that we will apply to the times of the shift and its subshifts. This corresponds to the
 * time difference between the source and target columns
 * @param shiftStartTime
 * @param slotStartTime
 * @param scheduleCalendarType
 */
function getOffset(shiftStartTime: Moment, slotStartTime: Moment, scheduleCalendarType: ScheduleCalendarType): number {
    return scheduleCalendarType === ScheduleCalendarTypes.Day ? DateUtils.getDifferenceInHoursFromMoments(shiftStartTime, slotStartTime, true /* precise */) : Math.ceil(DateUtils.getDifferenceInDaysFromMoments(shiftStartTime, slotStartTime, true /* precise */));
}