import * as moment from "moment";
import { AppDb } from "sh-services/data/AppDb";
import { ECSConfigKey, ECSConfigService, InstrumentationService } from "sh-services";
import {
    IItemsInTimeRangeSyncState,
    IOpenShiftDbEntity,
    IOpenShiftEntity,
    OpenShiftEntity
    } from "sh-models";
import { IOpenShiftDatabase } from "./IOpenShiftDatabase";
import { Moment } from "moment";

/**
 * OpenShifts Database Accessor
 */
export class OpenShiftDatabase implements IOpenShiftDatabase {
    private db: AppDb;

    constructor(db: AppDb) {
        if (OpenShiftDatabase.IsShiftsIndexedDbEnabled()) {
            this.db = db;
        }
    }

    /**
     * Fetch openShifts in a team
     */
    public async getOpenShifts(teamId: string, fetchStartTime: Moment, fetchEndTime: Moment): Promise<IOpenShiftEntity[]> {
        if (!this.db) {
            return null;
        }
        let openShifts: IOpenShiftEntity[] = null;
        const fetchStartTimeStamp: number = fetchStartTime.valueOf();
        const fetchEndTimeStamp: number = fetchEndTime.valueOf();

        try {
            const openShiftsFromDb: IOpenShiftDbEntity[] = await this.db.openShifts
                .where("[startTime+endTime+teamId]")
                // Use the key to do a more performant exclusion of items that don't overlap with the current fetch range.
                // With compound indexes, we can't really do complex logic filters as the filter() handler below, but we still
                // use the indexes here to partially weed out items that should excluded.
                .below([fetchEndTimeStamp, fetchEndTimeStamp, teamId]) // Only include items where (item.startTime < fetchEndTimeStamp)
                // Filter out items that do not overlap with the current fetch range
                .filter((openShift: IOpenShiftDbEntity) => { return openShift.startTime < fetchEndTimeStamp && openShift.endTime > fetchStartTimeStamp && openShift.teamId === teamId; })
                .toArray();
            openShifts = openShiftsFromDb.map((openShift: IOpenShiftDbEntity) => OpenShiftEntity.fromJson(openShift));
        } catch (error) {
            InstrumentationService.trackException(error, "getOpenShiftsFromDb");
        }
        return openShifts;
    }

    /**
     * Set OpenShifts in Database
     * @param teams teams list
     */
    public async setOpenShifts(openShifts: IOpenShiftEntity[]): Promise<void> {
        if (!this.db || !openShifts || !openShifts.length) {
            return;
        }

        try {
            await this.db.openShifts.bulkPut(openShifts.map( (openShift: IOpenShiftEntity) => OpenShiftEntity.toDbModel(openShift)));
        } catch (error) {
            InstrumentationService.trackException(error, "setOpenShiftsInDb");
        }
    }

    /**
     * Delete openShifts in a team
     * @param teamId ID of the team
     * @param openShiftIds IDs of openShifts to delete
     */
    public async deleteOpenShifts(teamId: string, openShiftIds: string[]): Promise<void> {
        if (!this.db || !openShiftIds || !openShiftIds.length) {
            return;
        }

        try {
            await this.db.transaction("rw", this.db.openShifts, async () => {
                for (let i = 0; i < openShiftIds.length; i++) {
                    // delete openShifts
                    await this.db.openShifts.where({teamId: teamId, id: openShiftIds[i]}).delete();
                }
            });
        } catch (error) {
            InstrumentationService.trackException(error, "deleteOpenShiftsInDb");
        }
    }

    /**
     * Atomically set OpenShifts (and the time range that's in sync for the session) in the Database
     * @param teamId Team ID
     * @param sessionId Current Session ID
     * @param updatedOpenShifts List of updated openShifts
     * @param deletedOpenShiftIds List of IDs of openShifts that were hard deleted
     * @param sessionOpenShiftsCacheStartTimestamp Start of time range that's cached in current session
     * @param sessionOpenShiftsCacheEndTimestamp End of timestamp that's cached in current session
     */
    public async setOpenShiftsInTimeRange(teamId: string, sessionId: string, updatedOpenShifts: IOpenShiftEntity[], deletedOpenShiftIds: string[], sessionOpenShiftsCacheStartTimestamp: number, sessionOpenShiftsCacheEndTimestamp: number): Promise<void> {
        if (!this.db) {
            return;
        }

        try {
            await this.db.transaction("rw", [this.db.openShifts, this.db.openShiftsSyncState], async () => {
                await this.setOpenShifts(updatedOpenShifts);
                await this.deleteOpenShifts(teamId, deletedOpenShiftIds);
                await this.setOpenShiftsCacheTimeRange(teamId, sessionId, sessionOpenShiftsCacheStartTimestamp, sessionOpenShiftsCacheEndTimestamp);
            });
        } catch (error) {
            InstrumentationService.trackException(error, "setOpenShiftsInTimeRangeInDb");
        }
    }

    /**
     * Set OpenShifts cache timerange in Database
     * @param teamId Team ID
     * @param sessionId Session ID
     * @param syncStateStartTimestamp Start time of the contiguous timerange that's in sync
     * @param syncStateEndTimestamp End time of the contiguous timerange that's in sync
     */
    public async setOpenShiftsCacheTimeRange(teamId: string, sessionId: string, syncStateStartTimestamp: number, syncStateEndTimestamp: number): Promise<void> {
        if (!this.db) {
            return;
        }

        const openShiftsSyncState: IItemsInTimeRangeSyncState = {
            sessionId: sessionId,
            teamId: teamId,
            syncStateStartTimestamp: syncStateStartTimestamp,
            syncStateEndTimestamp: syncStateEndTimestamp,
            lastUpdateTimestamp: moment().valueOf()
        };

        try {
            await this.db.openShiftsSyncState.put(openShiftsSyncState);
        } catch (error) {
            InstrumentationService.trackException(error, "setOpenShiftsCacheTimeRangeInDb");
        }
    }

    /**
     * Get the open shifts sync state for a team in the current session
     * @param teamId Team ID
     * @param sessionId  Session ID
     */
    public async getOpenShiftsSyncState(teamId: string, sessionId: string): Promise<IItemsInTimeRangeSyncState> {
        if (!this.db) {
            return null;
        }

        try {
            return await this.db.openShiftsSyncState
                .where({sessionId: sessionId, teamId: teamId}).first();
        } catch (error) {
            InstrumentationService.trackException(error, "getOpenShiftsSyncStateFromDb");
        }
        return null;
    }

    /**
     * Delete the open shifts sync state for the current session
     * @param sessionId  Session ID
     */
    public async deleteOpenShiftsSyncState(sessionId: string): Promise<void> {
        if (!this.db) {
            return null;
        }

        try {
            await this.db.openShiftsSyncState.where({sessionId: sessionId}).delete();
        } catch (error) {
            InstrumentationService.trackException(error, "deleteOpenShiftsSyncStateInDb");
        }
    }

    /**
     * Returns true if storing & retrieving of shifts/notes/openshifts from IndexedDb is enabled
     */
    public static IsShiftsIndexedDbEnabled() {
        return ECSConfigService.isECSFeatureEnabled(ECSConfigKey.EnableShiftsIndexedDb);
    }

    /**
     * Delete the sessions
     * @param sessionIds List of sessionIds to delete
     */
    public async deleteSessionData(sessionIds: string[]) {
        if (!this.db || !sessionIds || !sessionIds.length) {
            return;
        }
        try {
            for (const sessionId of sessionIds) {
                await this.db.openShiftsSyncState.where({sessionId: sessionId}).delete();
            }
        } catch (error) {
            InstrumentationService.trackException(error, "deleteOpenShiftsSessionDataFromDb");
        }
    }
}