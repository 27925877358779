import { createStore } from "satcheljs";
import { ErrorWatcherViewStateStore } from "./schema/ErrorWatcherViewStateStore";

/* register orchestrators */
import "../orchestrators/handleStaffHubServiceError";
import "../orchestrators/handleBlockingBootstrapError";
import "../orchestrators/handleWorkforceIntegrationError";
import "../orchestrators/handleConnectorAuthRequest";

const initialStore: ErrorWatcherViewStateStore = {
    blockingError: null,              // A high level error that blocks all functionality in the app
    showWorkforceIntError: false      // Indicates if there is a workforce integration error currently displayed
};

const errorViewStateStore = createStore<ErrorWatcherViewStateStore>('errorViewStateStore', initialStore);
export default errorViewStateStore;