import * as React from "react";
import FlexiGrid, { FlexiGridProps, FlexiGridRowSettings } from "./FlexiGrid";
import SkeletonGrid from "./SkeletonGrid";
import SortableFlexiGrid from "./SortableFlexiGrid";
import { AriaProperties } from "owa-accessibility";
import { FlexiRowCellSettings } from "./FlexiRow";
import { observer } from "mobx-react";
import { SortEnd } from "react-sortable-hoc";
import { trace } from "owa-trace";

const SKELETON_GROUP_PREFIX: string = "skeleton_group";

/**
 * Properties interface for the FlexiGroupedGridProps component
 *
 * To enable reactive rendering updates of cells, the groupSettings data that is passed to this FlexiGrid component
 * should be designated as observable.
 */
interface FlexiGroupedGridProps {
    gridClass?: string; // CSS classes for the grid
    rowBaseClass?: string; // Base CSS classes for rows in the grid
    cellBaseClass?: string; // Base CSS classes for cells in the grid
    groupSettings: FlexiGroupedGridGroupSettings[]; // Settings for the groups. [groupIndex]
    rowSettingsSpacer?: FlexiGridRowSettings; // Row settings for spacer rows between groups. Pass null for no spacer rows.
    cellSettingsSpacer?: FlexiRowCellSettings[]; // Settings for cells in spacer rows. [columnIndex]
    rowSettingsPrintHeader?: FlexiGridRowSettings; // Row settings for print header between groups. Pass null for no rows.
    cellSettingsPrintHeader?: FlexiRowCellSettings[]; // Settings for cells in print header rows. [columnIndex]
    doDisplayHeaderForEmptyGroups: boolean; // Set to true to display the header row for empty groups
    doDisplayFooterForEmptyGroups: boolean; // Set to true to display the footer row for empty groups
    virtualizedListRenderingThreshold?: number; // Virtualized list threshold:  Mininum number of rows required to enable virtualized list rendering. null or -1 to disable.
    virtualRowsPerPage?: number; // the number of rows to render per virtual page
    virtualRowHeight?: number; // the height (in px) of each row height - if not provided, each FlexiRow must provide the rowHeight value in their FlexiGridRowSettings
    allowReorder?: boolean; // true, if the grid allows for drag and drop reordering
    onRowDragStart?: () => void; // Callback that gets invoked when the user begins dragging a row
    onRowDrop?: (sortEnd: SortEnd) => void; // Callback that gets invoked when the user drops the row
    ariaPropsForGrid?: AriaProperties; // Aria accessibility properties to assign for this grid
    useSkeletonGrid?: boolean; // flag thats tells if we are in progressive rendering mode
    groupsInView?: string[]; // the array which maintains which group has loaded
    numberOfSkeletonCells?: number; // number of skeleton cells to render in view, ex: 30/31 in month view, 7 in week view etc.
}

/**
 * Settings for a group within the context of the grid
 */
export interface FlexiGroupedGridGroupSettings {
    groupId?: string; // ID of the group
    rowSettingsBody: FlexiGridRowSettings[]; // Row settings for body rows in the group [rowIndex]
    rowSettingsHeader?: FlexiGridRowSettings; // Row settings for header row - header rows are the first row in each group. Pass null for no header row.
    rowSettingsFooter?: FlexiGridRowSettings; // Row settings for footer row - footer rows are the last row in each group. Pass null for no footer row.
    cellSettingsBody: FlexiRowCellSettings[][]; // Settings for the body cells in all rows of the group [rowIndex][columnIndex]
    cellSettingsHeader?: FlexiRowCellSettings[]; // Settings for cells in the header row of the group. Pass null for no header row. [columnIndex]
    cellSettingsFooter?: FlexiRowCellSettings[]; // Settings for cells in the footer row of the group. Pass null for no footer row. [columnIndex]
}

/**
 * FlexiGroupedGrid component
 * This component renders a grid of grouped rows of cells with customizable contents.
 */
@observer
export default class FlexiGroupedGrid extends React.Component<FlexiGroupedGridProps, {}> {
    /**
     * Returns the number of groups
     */
    private numGroups(): number {
        return this.props.groupSettings.length;
    }

    /**
     * Returns the group settings for the specified group index
     * @param groupIndex
     */
    private groupSettingsForGroup(groupIndex: number): FlexiGroupedGridGroupSettings {
        if (groupIndex < this.numGroups()) {
            return this.props.groupSettings[groupIndex];
        } else {
            return null;
        }
    }

    /**
     * Generate row and cell settings for the specified group. These are passed to FlexiGrid to render the group as a series of rows in the grid.
     * @param rowSettingsForGroup
     * @param cellSettingsForGroupRows
     * @param groupIndex
     */
    private generateRowAndCellSettingsForGroup(
        rowSettingsForGroup: FlexiGridRowSettings[],
        cellSettingsForGroupRows: FlexiRowCellSettings[][],
        groupIndex: number
    ) {
        const { doDisplayHeaderForEmptyGroups, doDisplayFooterForEmptyGroups } = this.props;

        const groupSettingsForGroup = this.groupSettingsForGroup(groupIndex);
        if (groupSettingsForGroup) {
            const hasBodyRows = groupSettingsForGroup.cellSettingsBody.length > 0;

            // Add header row if specified
            if (
                (doDisplayHeaderForEmptyGroups || hasBodyRows) &&
                groupSettingsForGroup.rowSettingsHeader &&
                groupSettingsForGroup.cellSettingsHeader
            ) {
                rowSettingsForGroup.push(groupSettingsForGroup.rowSettingsHeader);
                cellSettingsForGroupRows.push(groupSettingsForGroup.cellSettingsHeader);
            }

            // Add body rows
            if (hasBodyRows) {
                groupSettingsForGroup.cellSettingsBody.forEach((currentBodyRowCellSettings: FlexiRowCellSettings[], rowIndex: number) => {
                    if (rowIndex < groupSettingsForGroup.rowSettingsBody.length) {
                        rowSettingsForGroup.push(groupSettingsForGroup.rowSettingsBody[rowIndex]);
                        cellSettingsForGroupRows.push(currentBodyRowCellSettings);
                    } else {
                        trace.error(
                            "FlexiGroupedGrid.generateRowAndCellSettingsForGroup(): rowIndex exceeds number of rows in rowSettingsBody."
                        );
                    }
                });
            }

            // Add footer row if specified
            if (
                (doDisplayFooterForEmptyGroups || hasBodyRows) &&
                groupSettingsForGroup.rowSettingsFooter &&
                groupSettingsForGroup.cellSettingsFooter
            ) {
                rowSettingsForGroup.push(groupSettingsForGroup.rowSettingsFooter);
                cellSettingsForGroupRows.push(groupSettingsForGroup.cellSettingsFooter);
            }
        } else {
            trace.error("FlexiGroupedGrid.generateRowAndCellSettingsForGroup(): groupIndex exceeds number of groups in cellSettings.");
        }

        return rowSettingsForGroup;
    }

    /**
     * Callback that gets called by the sortable-hoc when the drop happens
     * sortable-hoc components treats every mouse click and release as a drop and would fire this event.
     * The onus is on the consumer to make sure it checks for the new index and old index being different.
     * @param sortEnd - object bag that contains the details of the drag and drop. It contains the old index, new index and the collection in which the reordering happened
     */
    private onRowDrop = (sortEnd: SortEnd) => {
        if (typeof this.props.onRowDrop === "function") {
            this.props.onRowDrop(sortEnd);
        }
    };

    /**
     * Callback that gets called by the sortable-hoc when the drag begins
     */
    private onRowDragStart = () => {
        if (typeof this.props.onRowDragStart === "function") {
            this.props.onRowDragStart();
        }
    };

    render() {
        const {
            gridClass,
            rowBaseClass,
            cellBaseClass,
            groupSettings,
            rowSettingsSpacer,
            cellSettingsSpacer,
            rowSettingsPrintHeader,
            cellSettingsPrintHeader,
            useSkeletonGrid,
            groupsInView
        } = this.props;

        const doAddSpacerRows = rowSettingsSpacer != null && cellSettingsSpacer != null;
        const doAddPrintHeaderRows = rowSettingsPrintHeader != null && cellSettingsPrintHeader != null;

        let gridRowSettings: FlexiGridRowSettings[] = [];
        let gridRowCellSettings: FlexiRowCellSettings[][] = [];

        if (groupSettings && groupSettings.length) {
            // Groups are "flattened" into rows (header row + body rows + footer rows) that will be rendered via the FlexiGrid component.
            // This will allow us to incrementally render rows for virtualized list scrolling for large numbers of groups and rows.
            groupSettings.forEach((settingsForGroup: FlexiGroupedGridGroupSettings, groupIndex: number) => {
                // add only the groups that are in the groupsInView array when progressively rendering, if not progressively rendering add it all the time
                if (!useSkeletonGrid || (useSkeletonGrid && groupsInView && groupsInView.indexOf(groupSettings[groupIndex].groupId) > -1)) {
                    let groupRowSettings: FlexiGridRowSettings[] = [];
                    let groupRowCellSettings: FlexiRowCellSettings[][] = [];
                    this.generateRowAndCellSettingsForGroup(groupRowSettings, groupRowCellSettings, groupIndex);
                    gridRowSettings = gridRowSettings.concat(groupRowSettings);
                    gridRowCellSettings = gridRowCellSettings.concat(groupRowCellSettings);

                    // Add a spacer row between groups
                    if (
                        doAddSpacerRows &&
                        groupRowSettings.length > 0 && // Only show the spacer if the current group has rows to render (body rows/header/footer)
                        groupIndex !== this.numGroups() - 1
                    ) {
                        // Don't render a spacer for the last group
                        gridRowSettings.push(rowSettingsSpacer);
                        gridRowCellSettings.push(cellSettingsSpacer);
                    }

                    // Add a print header row between groups
                    if (
                        doAddPrintHeaderRows &&
                        groupRowSettings.length > 0 && // Only show the spacer if the current group has rows to render (body rows/header/footer)
                        groupIndex !== this.numGroups() - 1
                    ) {
                        // Don't render a spacer for the last group
                        gridRowSettings.push(rowSettingsPrintHeader);
                        gridRowCellSettings.push(cellSettingsPrintHeader);
                    }
                }
            });
        }

        const gridProps: FlexiGridProps = {
            gridClass,
            rowBaseClass,
            cellBaseClass,
            rowSettings: gridRowSettings,
            cellSettings: gridRowCellSettings,
            allowReorder: this.props.allowReorder,
            ariaPropsForGrid: this.props.ariaPropsForGrid,
            virtualizedListRenderingThreshold: this.props.virtualizedListRenderingThreshold,
            virtualRowsPerPage: this.props.virtualRowsPerPage,
            virtualRowHeight: this.props.virtualRowHeight
        };

        let skeletonGrid: JSX.Element[] = [];

        if (useSkeletonGrid && groupsInView) {
            // add skeleton grid, as many as the number of groups that still has not been fetched yet
            for (let i = 0; i < groupSettings.length - groupsInView.length; i++) {
                skeletonGrid.push(
                    <SkeletonGrid numberofSkeletonCells={this.props.numberOfSkeletonCells} key={SKELETON_GROUP_PREFIX + i}></SkeletonGrid>
                );
            }
        }

        let flexiGrid: JSX.Element = null;

        if (this.props.allowReorder) {
            flexiGrid = (
                <SortableFlexiGrid
                    {...gridProps}
                    useWindowAsScrollContainer={true}
                    useDragHandle={true}
                    onSortStart={this.onRowDragStart}
                    onSortEnd={this.onRowDrop}
                    helperClass={"scheduleRowDragContainer"}
                />
            );
        } else {
            flexiGrid = <FlexiGrid {...gridProps} />;
        }

        return <>{flexiGrid}</>;
    }
}
