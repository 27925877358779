import { memberEditorViewState } from "../";
import { action } from "satcheljs/lib/legacy";

/**
 * Action that resets member editor dialog view state
 */
export default action("resetMemberEditorViewState")(
  function resetMemberEditorViewState() {
    memberEditorViewState().isOpen = false;
    memberEditorViewState().isSaving = false;
    memberEditorViewState().teamMemberToAdd = null;
    memberEditorViewState().currentlySelectedTeamMember = null;
    memberEditorViewState().showInlineError = false;
    memberEditorViewState().openDialogTriggerButton = null;
    memberEditorViewState().searchError = undefined;
  }
);