import { EmployeeViewType } from "./EmployeeViewType";
import { IScheduleFiltersEntity } from "./IScheduleFiltersEntity";
import { ScheduleCalendarType } from "./ScheduleCalendarType";
import { ScheduleFiltersViewShow } from "./ScheduleFiltersViewShow";
import { ScheduleOverViewType } from "./ScheduleOverViewType";
import { ShiftFilterType } from "./ShiftFilterType";

export class ScheduleFiltersEntity implements IScheduleFiltersEntity {
    filters: {
        groups?: string[];
        members?: string[];
        shifts?: ShiftFilterType[];
    };
    name: string;
    scheduleView: ScheduleCalendarType;
    teamId: string;
    view: {
        quickAccess: EmployeeViewType;
        show: ScheduleFiltersViewShow;
        viewBy: ScheduleOverViewType;
    };

    constructor(
        filters: {
            groups?: string[];
            members?: string[];
            shifts?: ShiftFilterType[];
        },
        name: string,
        scheduleView: ScheduleCalendarType,
        teamId: string,
        view: {
            quickAccess: EmployeeViewType;
            show: ScheduleFiltersViewShow;
            viewBy: ScheduleOverViewType;
        }) {
        this.filters = filters;
        this.name = name;
        this.scheduleView = scheduleView;
        this.teamId = teamId;
        this.view = view;
    }

    /**
     * Creates a ScheduleFiltersEntity from a JSON object.
     * @param jsonData the JSON data.
     * @returns the created ScheduleFiltersEntity.
     */
    static fromJson(jsonData: any): ScheduleFiltersEntity {
        return new ScheduleFiltersEntity(
            jsonData.filters,
            jsonData.name,
            jsonData.scheduleView,
            jsonData.teamId,
            jsonData.view
        );
    }
}