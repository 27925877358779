import { ICalendarProps } from "@fluentui/react";
import * as moment from "moment";
import { IMemberEntity, ITeamPermissionEntity } from "sh-models";

import { PastDateLimitManager } from "../../../../../managers/pastDate";
import { GetPastDateLimitParams } from "../GetPastDateLimitParams";

import { DateNavigatorProps } from "./DateNavigator";
import { DateRangeArrowsProps } from "./DateRangeArrows";

/**
 * Enforces the past date limit for current schedule view by forcing selection of limit date if select schedule date is past the limit.
 * @param props The properties.
 * @param setDate The callback to set the date if user is past the limit date.
 * @param permissions The current user permissions for selected team.
 * @param currentUser The current user info.
 */
export const enforcePastDateLimit = (props: DateNavigatorProps, setDate: (date: Date) => void, permissions?: ITeamPermissionEntity, currentUser?: IMemberEntity): void => {
    if (!isPastDateLimitEnabled(permissions, currentUser)) {
        return;
    }

    const pastDateLimit = getPastDateLimit({
        date: moment(),
        pastDateLimitInDays: permissions.pastScheduleDataLimitInDays,
        startDayOfWeek: props.firstDayOfWeek,
        timeZone: props.timeZoneOlsonCode,
        view: props.scheduleCalendarType
    });

    // Ensures that when calendar type is change, past date limit is still respected
    if (pastDateLimit.valueOf() > props.viewStartDate.valueOf()) {
        setDate(pastDateLimit.toDate());
    }
};

/**
 * Gets the Calendar component extra properties with computed minimum date user can pick in the date picker or go to using previous button.
 * @param props The properties.
 * @param permissions The current user permissions for selected team.
 * @param currentUser The current user info.
 * @returns The Calendar component extra properties with computed minimum date user can pick in the date picker or go to using previous button.
 */
export const getCalendarProps = (props: DateNavigatorProps, permissions?: ITeamPermissionEntity, currentUser?: IMemberEntity): Partial<ICalendarProps> | undefined => {
    if (!isPastDateLimitEnabled(permissions, currentUser)) {
        return undefined;
    }

    const minDate = getPastDateLimit({
        date: moment(),
        pastDateLimitInDays: permissions.pastScheduleDataLimitInDays,
        startDayOfWeek: props.firstDayOfWeek,
        timeZone: props.timeZoneOlsonCode,
        view: props.scheduleCalendarType
    });

    return { minDate: minDate.toDate() };
};

/**
 * Gets the DateRangeArrows component extra properties.
 * @param props The DateNavigator component properties.
 * @param permissions The current user permissions for selected team.
 * @param currentUser The current user info.
 * @returns The DateRangeArrows component extra properties.
 */
export const getDateRangeArrowsProps = (props: DateNavigatorProps, permissions?: ITeamPermissionEntity, currentUser?: IMemberEntity): Partial<DateRangeArrowsProps> | undefined => {
    if (!isPastDateLimitEnabled(permissions, currentUser)) {
        return undefined;
    }

    const pastDateLimit = getPastDateLimit({
        date: moment(),
        pastDateLimitInDays: permissions.pastScheduleDataLimitInDays,
        startDayOfWeek: props.firstDayOfWeek,
        timeZone: props.timeZoneOlsonCode,
        view: props.scheduleCalendarType
    });

    const disablePreviousDateArrow = pastDateLimit.valueOf() >= props.viewStartDate.valueOf();

    return {
        disablePreviousDateArrow
    };
};

/**
 * Gets the past date limit for current schedule view.
 * Due to a limitation with the current UI state library, we need to pass each prop as a parameter from the UI component.
 * @param params The parameters.
 * @returns The past date limit.
 */
const getPastDateLimit = (params: GetPastDateLimitParams): moment.Moment => {
    const { timeZone } = params;

    const pastDateLimitManager = new PastDateLimitManager();
    const timestamp = pastDateLimitManager.getTimestampLimitForView(params);

    return moment.tz(timestamp, timeZone);
};

/**
 * Gets whether the past date limit is enabled for current user.
 * @param permissions The current user permissions for selected team.
 * @param currentUser The current user info.
 * @returns Whether the past date limit is enabled for current user.
 */
const isPastDateLimitEnabled = (permissions?: ITeamPermissionEntity, currentUser?: IMemberEntity): boolean => {

    if ( !permissions || !currentUser) {
        return false;
    }

    const { pastScheduleDataLimitInDays, canSeeUnlimitedPastScheduleData } = permissions;
    const isAdmin = currentUser.isAdmin;

    return !isAdmin && !canSeeUnlimitedPastScheduleData && !!pastScheduleDataLimitInDays;
};