import ConflictUtils from "sh-application/utility/ConflictUtils";
import { conflictRequestStore } from "..";
import { ConflictType, IShiftConflictEntity, IShiftEntity, ShiftConflictEntity } from "sh-models";
import { mutatorAction } from "satcheljs";

export default mutatorAction('SET_REQUEST_SHIFT_CONFLICTS_IN_STORE', function setRequestShiftConflictsInStore(shift: IShiftEntity, conflictingShift: IShiftEntity, conflictType: ConflictType, memberId?: string) {
    let conflictsArrayForRequestShift = conflictRequestStore().requestShiftIdToConflictsMap.get(shift.id) || [];
    let alreadyAdded = false;
    // check if conflict already exists in conflictsArrayForRequestShift
    conflictsArrayForRequestShift.forEach((conflictEntity: IShiftConflictEntity) => {
        if (ConflictUtils.isSameConflictEntity(conflictEntity, shift.id, conflictingShift.id)) {
            alreadyAdded = true;
        }
    });

    if (!alreadyAdded) {
        // create Shift conflict entity
        const conflictShiftEntity: IShiftConflictEntity = new ShiftConflictEntity(
            memberId, // for openshift request no member Id is present in shift
            shift.id,
            conflictType,
            false,
            conflictingShift.id
        );

        conflictsArrayForRequestShift.push(conflictShiftEntity);
        conflictRequestStore().requestShiftIdToConflictsMap.set(shift.id, conflictsArrayForRequestShift);
    }
});