import { memberEditorViewState } from "../";
import { action } from "satcheljs/lib/legacy";
import { IMemberEntity } from "sh-models";

/**
 * Action to set the currently selected team member to add on the view state
 * @param {IMemberEntity} member - the team member that the user has currently selected from the name picker
 */
export default action("setTeamMemberToAdd")(
    function setTeamMemberToAdd(member: IMemberEntity) {
      memberEditorViewState().teamMemberToAdd = member;
    }
);