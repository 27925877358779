import { ObservableMap } from "mobx";
import { Moment } from "moment";
import { ShiftStoreTypes } from "sh-application/../StaffHubConstants";
import { resetConflictStore, resetDismissedConflictStore, resetShiftsByMemberAndDateStore } from "sh-conflict-store";
import { IItemsInTimeRangeSyncState, IShiftEntity } from "sh-models";
import RestClient from "sh-rest-client";
import { DataFilter, InstrumentationService } from "sh-services";
import { IShiftDatabase } from "sh-services/data/IShiftDatabase";
import {
    MyShiftStore,
    ShiftStore,
    ShiftStoreSchema,
    deleteShifts,
    resetMyShiftStore,
    resetShiftStore,
    setShiftsCacheEndTimeInStore,
    setShiftsCacheStartTimeInStore,
    updateShifts
} from "sh-shift-store";

import { InstrumentationEventPropertyInterface, getGenericEventPropertiesObject } from "../../../framework/sh-instrumentation";
import { GetDataInDateRangeOptions } from "../../sh-rest-client/GetDataInDateRangeOptions";

import { ItemsInDateRangeDataProvider } from "./ItemsInDateRangeDataProvider";

/**
 * Data Provider to fetch Shifts in DateRange for the current team
 */
export class ShiftsInDateRangeDataProvider extends ItemsInDateRangeDataProvider<IShiftEntity> {
    protected shiftDatabase: IShiftDatabase;
    protected shiftStoreEntity: ShiftStoreSchema;
    protected shiftStoreType: ShiftStoreTypes;

    /**
     * The maximum number of pages of shifts to fetch from the Service.
     */
    private readonly maxPages = 50;

    constructor(
        shiftDatabase: IShiftDatabase,
        tenantId: string,
        teamId: string,
        sessionId: string,
        fetchStartTime: Moment,
        fetchEndTime: Moment,
        dontClearCache: boolean,
        shiftStoreType: ShiftStoreTypes
    ) {
        super(tenantId, teamId, sessionId, fetchStartTime, fetchEndTime, dontClearCache);
        this.shiftDatabase = shiftDatabase;
        this.shiftStoreType = shiftStoreType;
        // TODO: Spike on using another data provider for 'MyShiftStore' scenario to reduce code complexity and have explicit dependencies.
        shiftStoreType === ShiftStoreTypes.MyShiftStore ? (this.shiftStoreEntity = MyShiftStore()) : (this.shiftStoreEntity = ShiftStore());
    }

    /**
     * Fetch all items that are in memory
     */
    getAllItemsFromMemory(): ObservableMap<string, IShiftEntity> {
        return this.shiftStoreEntity.shifts;
    }

    /**
     * Fetch the sync start time from memory
     */
    getSyncCacheStartTimeFromMemory(): Moment {
        return this.shiftStoreEntity.shiftsCacheStartTime;
    }

    /**
     * Fetch the sync end time from memory
     */
    getSyncStateEndTimeFromMemory(): Moment {
        return this.shiftStoreEntity.shiftsCacheEndTime;
    }

    /**
     * Set sync cache start time in memory
     */
    setSyncCacheStartTimeInMemory(syncStateStartTime: Moment): void {
        setShiftsCacheStartTimeInStore(syncStateStartTime, this.shiftStoreEntity);
    }

    /**
     * Set sync cache end time in memory
     */
    setSyncStateEndTimeInMemory(syncStateEndTime: Moment): void {
        setShiftsCacheEndTimeInStore(syncStateEndTime, this.shiftStoreEntity);
    }

    /**
     * Set list of items in memory
     */
    setItemsInMemory(items: IShiftEntity[]): void {
        updateShifts(items, false /* isOptimisticUpdate */, this.shiftStoreType);
    }

    /**
     * Hard delete items in memory
     */
    hardDeleteItemsInMemory(items: IShiftEntity[]): void {
        deleteShifts(items, this.shiftStoreType);
    }

    /**
     * Reset the items and sync state in memory
     */
    resetDataInMemory(): void {
        resetShiftStore();
        resetMyShiftStore();
        resetConflictStore();
        resetDismissedConflictStore();
        resetShiftsByMemberAndDateStore();
    }

    /**
     * Returns true if this item matches the data filter.
     * @param item the shift
     * @param dataFilter data filter
     */
    isItemInDataFilter(item: IShiftEntity, dataFilter: DataFilter): boolean {
        let isInTagFilter = false;
        let isInMemberFilter = false;

        if (dataFilter && item) {
            // check if this item is in the tag filter
            isInTagFilter = this.isItemInTagDataFilter(item, dataFilter);

            // check if this item is in the member filter
            // if it's already in the tag filter, we don't need to check if it matches the member filter
            if (!isInTagFilter && dataFilter.memberIds && dataFilter.memberIds.length && item.memberId) {
                if (dataFilter.memberIds.indexOf(item.memberId) !== -1) {
                    // this item is in our data filter
                    isInMemberFilter = true;
                }
            }
        }

        return isInTagFilter || isInMemberFilter;
    }

    /**
     * Set Items and the sync state timerange in the Database
     */
    async setItemsAndSyncStateInDatabase(
        updatedItems: IShiftEntity[],
        deletedItemIds: string[],
        syncStateStartTimestamp: number,
        syncStateEndTimestamp: number
    ): Promise<void> {
        return await this.shiftDatabase.setShiftsInTimeRange(
            this.teamId,
            this.sessionId,
            updatedItems,
            deletedItemIds,
            syncStateStartTimestamp,
            syncStateEndTimestamp
        );
    }

    /**
     * Get items in the fetch time range from the Database
     */
    async getItemsInDateRangeFromDatabase(): Promise<IShiftEntity[]> {
        return await this.shiftDatabase.getShifts(this.teamId, this.fetchStartTime, this.fetchEndTime);
    }

    /**
     * Get sync state from the Database
     */
    async getItemsSyncStateFromDatabase(): Promise<IItemsInTimeRangeSyncState> {
        return await this.shiftDatabase.getShiftsSyncState(this.teamId, this.sessionId);
    }

    /**
     * Gets the list of shifts within given date-time range from network.
     * @returns The list of shifts within given date-time range from network.
     */
    async getItemsInDateRangeFromNetwork(): Promise<IShiftEntity[]> {
        let currentPage = 0;
        let skipToken: string;
        let itemsPerPage = 0;
        let shifts: IShiftEntity[] = [];

        const options: Readonly<GetDataInDateRangeOptions> = {
            networkFetchOptions: {
                includeNotes: false,
                includeOpenShifts: false,
                includeTimeOffs: false,
                includeUngroupedShifts: false
            }
        };

        const marker = InstrumentationService.perfMarkerStart("getDataInDateRangePaginated");

        while (currentPage < this.maxPages && (!currentPage || skipToken)) {
            const data = await RestClient.getDataInDateRangePaginated(
                this.teamId,
                this.fetchStartTime,
                this.fetchEndTime,
                options,
                skipToken
            );

            if (!itemsPerPage) {
                itemsPerPage = data.shifts.length;
            }

            shifts = shifts.concat(data.shifts);
            skipToken = data.skipToken;
            ++currentPage;
        }

        InstrumentationService.perfMarkerEnd(marker);
        this.logPerfEvent(currentPage, itemsPerPage, shifts.length);

        return shifts;
    }

    /**
     * Logs a 'GetDataInDateRangePage' performance event.
     * @param numPages The total number of page fetched from the Service.
     * @param itemsPerPage The number of items per page.
     * @param shiftsLength The total number of shifts.
     */
    private logPerfEvent(numPages: number, itemsPerPage: number, shiftsLength: number): void {
        const eventData: InstrumentationEventPropertyInterface[] = [];

        eventData.push(getGenericEventPropertiesObject(InstrumentationService.properties.MultiTeamApiForMyShiftCalled, false));
        eventData.push(getGenericEventPropertiesObject(InstrumentationService.properties.ViewStartDate, this.fetchStartTime.toISOString()));
        eventData.push(getGenericEventPropertiesObject(InstrumentationService.properties.ViewEndDate, this.fetchEndTime.toISOString()));
        eventData.push(getGenericEventPropertiesObject(InstrumentationService.properties.NumPages, numPages));
        eventData.push(getGenericEventPropertiesObject(InstrumentationService.properties.NumOfItems, shiftsLength));
        eventData.push(getGenericEventPropertiesObject(InstrumentationService.properties.NumOfItemsByPage, itemsPerPage));
        eventData.push(getGenericEventPropertiesObject(InstrumentationService.properties.NumOfTagFilters, 0));
        eventData.push(getGenericEventPropertiesObject(InstrumentationService.properties.NumOfMemberFilters, 0));

        InstrumentationService.logPerfEvent(InstrumentationService.events.GetDataInDateRangePage, eventData);
    }
}
