import { TenantCustomFieldType, TenantCustomFieldTypes } from "sh-models";

/**
 * When sending custom fields as part of our HTTP requests, the custom field
 * entity contains:
 * id : The ID of the team custom field from Tenant Settings
 * value : The value of the custom field for this team - If it is a text custom field, it's textual value.
 * If it is a dropdown type custom field, it's dropdown option id.
 */
interface ICustomFieldRequestEntity {
    id: string;
    value: string;
}

/**
 * When receiving custom fields as part of our team entities, the custom field entity
 * has a different shape that includes a name, type, valueText, and valueId property.
 * We mark these as optional here so that our ICustomFieldEntity can extend this interface
 * and be used for both request and response entities
 */
interface ICustomFieldResponseEntity {
    id: string;
    name?: string;
    type?: TenantCustomFieldType;
    valueText?: string;
    valueId?: string;
}

export interface ICustomFieldEntity extends ICustomFieldResponseEntity, ICustomFieldRequestEntity {}

/**
 * Class for a custom field in a Team.
 * id : The ID of the team custom field from Tenant Settings
 * value : The value of the custom field for this team - If it is a text custom field, it's textual value.
 *         If it is a dropdown type custom field, it's dropdown option id.
 */
export default class CustomFieldEntity implements ICustomFieldEntity {
    id: string;
    value: string;

    constructor(id: string, value: string) {
        // This does not have an etag.
        this.id = id;
        this.value = value;
    }

    /**
     * Transform the custom field from its response entity form to its request entity form
     */
    static fromJson(jsonData: ICustomFieldEntity): ICustomFieldRequestEntity {
        return new CustomFieldEntity(
            jsonData.id,
            CustomFieldEntity.getRequestEntityValue(jsonData));
    }

    /**
     * Transform the custom field to its request entity form
     */
    static toJson(option: ICustomFieldEntity): ICustomFieldRequestEntity {
        const optionJson: ICustomFieldRequestEntity = {
            id: option.id,
            value: CustomFieldEntity.getRequestEntityValue(option)
        };

        return optionJson;
    }

    /**
     * Returns a clone of the custom field
     * @param tenantsetting
     */
    static clone(tenantsetting: ICustomFieldEntity): ICustomFieldEntity {
        return CustomFieldEntity.fromJson(tenantsetting);
    }

    private static getRequestEntityValue(option: ICustomFieldEntity): string {
        return option && option.value
            ? option.value
            : option.type === TenantCustomFieldTypes.Selection.toString()
                ? option.valueId : option.valueText;
    }
}