import { AccessibilityAlertViewStateStore } from "./schema/AccessibilityAlertViewStateStore";
import { createStore } from "satcheljs";
import { ObservableMap } from "mobx";
import "../orchestrators/fireAccessibilityAlert";

const initialStore: AccessibilityAlertViewStateStore = {
    alertMessages: new ObservableMap<string, string>() // Alert texts to be read to accessibility screen reader users via Aria live region
};

const accessibilityAlertViewStateStore = createStore<AccessibilityAlertViewStateStore>('accessibilityAlertViewStateStore', initialStore);
export default accessibilityAlertViewStateStore;