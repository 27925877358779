import StringsStore from "sh-strings/store";

export type Theme = "themeBlue" | "themeGreen" | "themePurple" | "themePink" | "themeWhite" | "themeGray" | "themeYellow" | "themeDarkGreen" | "themeDarkBlue" | "themeDarkYellow" | "themeDarkPurple" | "themeDarkPink" | "themeLime" | "themeTimeOffGray";
export const Themes = {
    /**
     * Note - some themes have been mapped to new colors in the V2 scheduler. The "themeXXXX" values are stored on service and should not be changed.
     */
    White: "themeWhite" as Theme,
    Blue: "themeBlue" as Theme,
    Green: "themeGreen" as Theme,
    Purple: "themePurple" as Theme,
    Pink: "themePink" as Theme,
    Yellow: "themeYellow" as Theme,
    Gray: "themeGray" as Theme,
    DarkBlue: "themeDarkBlue" as Theme,
    DarkGreen: "themeDarkGreen" as Theme,
    DarkPurple: "themeDarkPurple" as Theme,
    DarkPink: "themeDarkPink" as Theme,
    DarkYellow: "themeDarkYellow" as Theme,
    TimeOffGray: "themeTimeOffGray" as Theme,
    Lime: "themeLime" as Theme // deprecated
};

export const ThemeNames = {
    themeWhite: "white",
    themeBlue: "blue",
    themeGreen: "green",
    themePurple: "purple",
    themePink: "pink",
    themeYellow: "yellow",
    themeGray: "gray",
    themeDarkBlue: "darkblue",
    themeDarkGreen: "darkgreen",
    themeDarkPurple: "darkpurple",
    themeDarkPink: "darkpink",
    themeDarkYellow: "darkyellow",
    themeTimeOffGray: "gray",
    themeLime: "" // deprecated
};

/**
 * Utilities for Themes
 */
export default class ThemeUtils {
    /*
     * Get Localized Themes map
     * @param (boolean) isTimeOff : indicates if themes should be mapped for Timeoffs
     */
    public static getLocalizedThemeNames(isTimeOff: boolean = false): Map<string, string> {
        let themeNames: Map<string, string> = new Map<string, string>();

        const themeStrings = StringsStore().registeredStringModules.get("themes").strings;

        themeNames.set(themeStrings.get("white"), Themes.White);
        themeNames.set(themeStrings.get("blue"), Themes.Blue);
        themeNames.set(themeStrings.get("green"), Themes.Green);
        themeNames.set(themeStrings.get("purple"), Themes.Purple);
        themeNames.set(themeStrings.get("pink"), Themes.Pink);
        themeNames.set(themeStrings.get("yellow"), Themes.Yellow);
        themeNames.set(themeStrings.get("gray"), isTimeOff ? Themes.TimeOffGray : Themes.Gray);
        themeNames.set(themeStrings.get("darkblue"), Themes.DarkBlue);
        themeNames.set(themeStrings.get("darkgreen"), Themes.DarkGreen);
        themeNames.set(themeStrings.get("darkpurple"), Themes.DarkPurple);
        themeNames.set(themeStrings.get("darkpink"), Themes.DarkPink);
        themeNames.set(themeStrings.get("darkyellow"), Themes.DarkYellow);

        return themeNames;
    }

    public static getLocalizedThemeName(theme: string): string {
        return StringsStore().registeredStringModules.get("themes").strings.get(theme);
    }

     /**
     * Get className that represents theme for timeOffRequest shifts
     */
    public static getTimeOffRequestThemeClass() {
        return "themeTimeOffRequest";
    }

    /**
     * Default theme for work Shifts
     */
    public static shiftDefaultTheme = Themes.Blue;

    /**
     * Default theme for Open Shifts
     */
    public static openShiftDefaultTheme = Themes.Blue;

    /**
     * Default theme for TimeOffs
     */
    public static timeOffDefaultTheme = Themes.TimeOffGray;

    public static getDefaultShiftTheme(isTimeOff: boolean): string {
        return isTimeOff ? this.timeOffDefaultTheme : this.shiftDefaultTheme;
    }

    public static getValidShiftTheme(shiftTheme: string, isTimeOff: boolean): string {
        let resultTheme = shiftTheme;
        if (!shiftTheme || shiftTheme.indexOf("theme") < 0) {
            // Determine a default theme if missing or invalid theme value
            resultTheme = this.getDefaultShiftTheme(isTimeOff);
        } else {
            // Maps deprecated theme values to valid values.  Remove this if we migrate old values on the service.
            switch (shiftTheme) {
                case Themes.Lime:
                    resultTheme = Themes.Gray;
                    break;
            }
        }
        return resultTheme;
    }

    public static getShiftsViewSlotBarTheme(): string {
        return Themes.TimeOffGray;
    }

}
