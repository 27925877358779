import { action } from "satcheljs";
import { IShiftEntity } from "sh-models";

const addShiftsToStoreMap = action(
    "ADD_SHIFT_TO_STORE_MAP",
    (
        shift: IShiftEntity
    ) => ({
        shift: shift
    })
);

export default addShiftsToStoreMap;