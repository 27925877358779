import * as React from "react";
import UrlFactory from "../utility/UrlFactory";
import { Redirect } from "react-router-dom";

export const HomeRedirect = () => (
    <Redirect
        to={ {
            pathname: UrlFactory.getBaseUrl()
        } }
    />
);
