import * as React from "react";
import AutomationUtil from "sh-application/utility/AutomationUtil";
import setGlobalMessage from "../../actions/setGlobalMessage";
import StringsStore from "sh-strings/store";
import { appViewState } from "../../store";
import { GlobalMessageViewState } from "../../store/schema/GlobalMessageViewState";
import { Async, MessageBar } from "@fluentui/react";
import { observer } from "mobx-react";

const styles = require("./GlobalMessageBar.scss");
const classNames = require("classnames/bind");

const AUTO_DISMISS_DELAY_MS = 2000;
const AUTO_DISMISS_ANIMATION_TIME_MS = 2000;

interface GlobalMessageBarProps {
    viewState?: GlobalMessageViewState;
}

interface GlobalMessageBarState {
    startAutoDismiss: boolean;
}

/**
 * GlobalMessageBar - MessageBar displayed in the StaffHub header area.
 */
@observer
export default class GlobalMessageBar extends React.Component<GlobalMessageBarProps, GlobalMessageBarState> {
    private _isDimissing = false;
    private _autoDismissDelayTimeout: number = 0;
    private _autoDismissAnimationTimeout: number = 0;
    private _currentMessage: Array<string> = [];
    private _async = new Async(this);

    constructor(props: GlobalMessageBarProps) {
        super(props);
        this.state = {
            startAutoDismiss: false
        };
    }

    /**
     * Clear the global message and reset state.
     */
    private dismissMessageBar() {
        setGlobalMessage(appViewState().globalMessageViewState);
        this.resetAutoDimiss();
    }

    /**
     * Calculate and return the message bar props.
     */
    private getMessageBarProps() {
        let { viewState } = this.props;

        // setup the MessageBar props (MessageBar only shows if the viewState has a message)
        let messageBarProps = {
            messageBarType: viewState.messageBarType,
            onDismiss: viewState.autoDismiss ? null : () => this.dismissMessageBar(),   // don't show the close button if this is auto dimiss
            dismissButtonAriaLabel: viewState.autoDismiss ? null : StringsStore().registeredStringModules.get("common").strings.get("closeMessageAriaLabel"),
            actions: null as JSX.Element,
            isMultiline: viewState.isMultiline
        };

        // if there is an action, add it
        if (viewState.actionButtonTitle) {
            messageBarProps.actions =
                <div onClick={ () => viewState.actionButtonCallback() } className={ styles.messageBarLink }>
                    { viewState.actionButtonTitle }
                </div>;
        }

        return messageBarProps;
    }

    /**
     * Starts the auto dismiss process. We first delay for a set amount of time, then set the
     * state to autoDismiss (which kicks off the hide animation) and finally clean up by
     * calling dimissMessageBar once the animation is complete.
     */
    private autoDismiss() {
        if (!this._isDimissing) {
            this._isDimissing = true;
            this._autoDismissDelayTimeout = this._async.setTimeout(() => {
                this.setState({ startAutoDismiss: true });
                this._autoDismissAnimationTimeout = this._async.setTimeout(() => {
                    this.dismissMessageBar();
                }, AUTO_DISMISS_ANIMATION_TIME_MS);
            }, AUTO_DISMISS_DELAY_MS);
        }
    }

    /**
     * Resets all auto dismiss state.
     */
    private resetAutoDimiss() {
        this._async.clearTimeout(this._autoDismissAnimationTimeout);
        this._async.clearTimeout(this._autoDismissDelayTimeout);
        this.setState({ startAutoDismiss: false });
        this._isDimissing = false;
    }

    /**
     * Cleans up timer when component is unmounted.
     */
    componentWillUnmount() {
        // clear all intervals
        this._async.dispose();
    }

    /**
     * Called when the GlobalMessageViewState is updated with property values. If the message has been updated we cancel any existing
     * auto dismiss delays and if autoDismiss is true, we start the auto dismiss process over.
     * @param nextProps
     */
    componentDidUpdate(nextProps: GlobalMessageBarProps) {
        if (nextProps && nextProps.viewState.messages) {
            if (!(nextProps.viewState.messages.length === this._currentMessage.length && nextProps.viewState.messages.every((v, i) => v === this._currentMessage[i]))) {
                // this is a new message so clear the auto dimiss process in case one is running
                this.resetAutoDimiss();
                // we only store _currentMessage so that we can check if new incoming message in the view state is new so we can reset any auto dismiss timers
                this._currentMessage = nextProps.viewState.messages;
            }

            if (nextProps.viewState.autoDismiss) {
                this.autoDismiss();
            }
        }
    }

    render() {
        let { viewState } = this.props;
        const { startAutoDismiss } = this.state;

        if (!viewState.messages) {
            return null;
        }

        return (
            <MessageBar
                data-automation-id={ AutomationUtil.getAutomationId("layout", "QAIDGlobalMessageBar") }
                aria-live={ "assertive" } // Assertive to make sure that it is read. Default is polite
                className={ classNames(styles.globalMessageBar, "ms-slideDownIn20", styles.messageBarTop, { center: viewState.isCenterAligned }, { autoDismiss: startAutoDismiss }) }
                { ...this.getMessageBarProps() } >
                { this.renderMessages() }
            </MessageBar>
        );
    }

    renderMessages(): JSX.Element[] {
        let { viewState } = this.props;
        let index = 0;
        if (viewState.messages) {
            let messages = viewState.messages.map((msg) => {
                return (
                    <span key={ index++ }>
                        { msg }
                        {
                            viewState.isMultiline &&
                                <br/>
                        }
                    </span>
                    );
                });
            return messages;
        } else {
            return null;
        }
    }
}