import { ObservableMap } from "mobx";
import { createStore } from "satcheljs";
import { ITagEntity } from "sh-models";

import { TagStoreSchema } from "./schema/TagStoreSchema";

/**
 * The tag store.
 */
const TagStore = createStore<TagStoreSchema>("tagStore", {
    focusedTagId: "",
    tagIdToPeopleCount: new ObservableMap<string, number>(),
    tags: new ObservableMap<string, ITagEntity>()
});

export default TagStore;
