/**
 * The schedule components.
 * Using this to track which components were rendered and log TTI accurately.
 */
export enum ScheduleComponents {
    /**
     * The header global command bar which includes team name, tabs, clock in, copy schedule, etc.
     */
    HeaderGlobalCommandBar = "GlobalCommandBar",

    /**
     * The header schedule command bar which includes date navigation, schedule filters, etc.
     */
    HeaderScheduleCommandBar = "ScheduleCommandBar"
}