import * as React from "react";
import LayoutWrapper from "./LayoutWrapper";
import LoginComponent from "./LoginComponent";
import NoMatch404 from "./NoMatch404";
import { AppSettings } from "sh-application";
import { appViewState } from "../store";
import { AuthorizedRoute } from "./AuthorizedRoute";
import { DataServiceContext } from "sh-application/context";
import { DataServices } from "sh-services";
import { ErrorWatcher } from "sh-application/components/errorWatcher";
import { HomeRedirect } from "./HomeRedirect";
import { hot } from "react-hot-loader";
import { IdleWatcher } from "./idleWatcher";
import { Route, Switch } from "react-router-dom";

if (!UNIT_TEST_MODE) {
    // include the main application styles
    require("../styles");
}

class App extends React.PureComponent<{}, {}> {

    private appPathFolder: string;

    constructor(props: any) {
        super(props);
        this.appPathFolder = AppSettings.getSetting("AppPathFolder");
    }

    /**
     * remove the loading screen that is rendered in BaseWebAppLayout
     */
    public static hideLoadingScreen() {
        const loading = document.getElementById("loadingScreen");
        if (loading) {
            document.body.removeChild(loading);
        }
    }

    render() {
        return (
                <DataServiceContext.Provider value={ DataServices } >
                    <ErrorWatcher />
                    <IdleWatcher />
                    <Switch>
                        <Route exact path="/" component={ HomeRedirect } />
                        <Route exact path= { `/${this.appPathFolder}/login` } render={ (props) => <LoginComponent {...props} viewState={ appViewState().loginViewState } /> } />

                        <AuthorizedRoute exact path={ `/${this.appPathFolder}` } component={ LayoutWrapper } />
                        <AuthorizedRoute path={ `/${this.appPathFolder}/groups/:groupId` } component={ LayoutWrapper } />
                        <AuthorizedRoute path={ `/${this.appPathFolder}/teams/:teamId` } component={ LayoutWrapper } />
                        <AuthorizedRoute path={ `/${this.appPathFolder}/:teamId` } component={ LayoutWrapper } /> { /* This is for backwards compatibility with old URLs, we can deprecate this after a few release */ }

                        <Route component={ NoMatch404 } />
                    </Switch>
                </DataServiceContext.Provider>
        );
    }
}

export default hot(module)(App);