import { DataProvider } from "sh-services/dataproviders/DataProvider";
import { TeamStore } from "sh-team-store";
import { trace } from "owa-trace";

/**
 * Derived DataProvider for DataProviders that are specific for the current team.
 *
 * This ensures that data updates associated with a team are only applied if those updates
 * are for the current team. This is currently needed since many of our local stores only
 * maintain a single set of data for the current team, so attempting to save the data for another
 * team would overwrite the current team's data.
 *
 * This is needed for scenarios such as team switching, where quick switching between teams could result
 * in overlapping requests to update the current team with the data from multiple teams.
 */
export default abstract class CurrentTeamDataProvider<T> extends DataProvider<T> {

    protected teamId: string;

    constructor(teamId: string) {
        super();
        this.teamId = teamId;
    }

    // Note:
    // Only local stores don't currently have support to maintain data for multiple
    // teams, so we just manage handling for local storages.  Service does handle data for multiple teams,
    // so we still allow network operations.
    // Also by still allowing network operations, this means that all get+set operations can still succeed,
    // so that callers don't need to implement special handling to check whether they succeeded or not.

    /**
     * Return data if it's found in memory for the current team (otherwise return undefined)
     */
    abstract getDataFromMemoryForCurrentTeam(): Promise<T>;

    /**
     * Set data in memory for the current team
     */
    abstract setDataInMemoryForCurrentTeam(data: T): Promise<void>;

    /**
     * Return data if found in memory store for the current team
     * If this dataprovider is not targeted for the current team, then no data is returned.
     */
    async getDataFromMemory(): Promise<T> {
        if (this.isDataProviderForCurrentTeam()) {
            return this.getDataFromMemoryForCurrentTeam();
        } else {
            trace.info(`${this.constructor.name} getDataFromMemory: Current team is different. TeamStore().teamId: ${TeamStore().teamId}, teamId: ${this.teamId}`);
            return undefined;
        }
    }

    /**
     * Set data in memory store for the current team
     * If this dataprovider is not targeted for the current team, then the data store is not updated.
     */
    async setDataInMemory(data: T): Promise<void> {
        if (this.isDataProviderForCurrentTeam()) {
            return this.setDataInMemoryForCurrentTeam(data);
        } else {
            trace.info(`${this.constructor.name} setDataInMemory: Current team is different. TeamStore().teamId: ${TeamStore().teamId}, teamId: ${this.teamId}`);
            return undefined;
        }
    }

    /**
     * Returns true if this data provider's associated team is the current team
     */
    public isDataProviderForCurrentTeam(): boolean {
        return TeamStore().teamId === this.teamId;
    }
}