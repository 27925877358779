import IScheduleProvisionResponse from 'sh-models/job/IScheduleProvisionResponse';

/**
 * The response from a request to start provisioning a Shifts (StaffHub) team from a Teams team
 */
export default class ScheduleProvisionResponse implements IScheduleProvisionResponse {
    teamId: string;
    jobId: string;

    constructor(
        teamId: string,
        jobId: string) {
        this.teamId = teamId;
        this.jobId = jobId;
    }

    /**
     * Function that converts json from service response to TeamDetailsEntity
     * @param jsonData - response from service
     */
    static fromJson(jsonData: IScheduleProvisionResponse): ScheduleProvisionResponse {
        if (!jsonData) {
            return null;
        }
        return new ScheduleProvisionResponse(
            jsonData.teamId,
            jsonData.jobId);
    }
}