import * as React from "react";
import { Overlay } from "@fluentui/react";
import Spinner, { SpinnerSize } from "sh-application/components/common/Spinner";

const styles = require("./GlobalSpinner.scss");

export interface GlobalSpinnerProps {
    label: string;
}

/**
 * GlobalSpinner
 */
export default class GlobalSpinner extends React.PureComponent<GlobalSpinnerProps, {}> {
    render() {
        const { label } = this.props;

        return (
            <Overlay className={ styles.globalSpinner }>
                <Spinner size={ SpinnerSize.small } label={ label } className={ styles.spinner } />
            </Overlay>
        );
    }
}
