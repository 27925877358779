import { INoteEntity } from "sh-models";
import { mutatorAction } from "satcheljs";
import { ProgressiveStore } from "../";

export default mutatorAction('UPDATE_PROGRESSIVE_NOTES', function updateNotes(notesToUpdate: INoteEntity[]) {
    if (notesToUpdate && notesToUpdate.length) {
        for (let i = 0; i < notesToUpdate.length; i++) {
            const note = notesToUpdate[i];
            ProgressiveStore().notes.set(note.id, note);
        }
    }
});
