import { mutatorAction } from "satcheljs";
import { appViewState } from "../../store";

const setControlKeyDown = mutatorAction(
    "setControlKeyDown",
    (controlKeyDown: boolean = true) => {
        const viewState = appViewState().printViewState;
        viewState.controlKeyDown = controlKeyDown;
    }
);

export default setControlKeyDown;