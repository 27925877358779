import { IOpenShiftEntity } from "sh-models";
import { mutatorAction } from "satcheljs";
import { ProgressiveStore } from "../";

export default mutatorAction('UPDATE_PROGRESSIVE_OPEN_SHIFTS', function updateOpenShifts(shiftsToUpdate: IOpenShiftEntity[]) {
    if (shiftsToUpdate && shiftsToUpdate.length) {
        for (let i = 0; i < shiftsToUpdate.length; i++) {
            const openShift = shiftsToUpdate[i];
            ProgressiveStore().openShifts.set(openShift.id, openShift);
        }
    }
});
