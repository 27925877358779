import { mutatorAction } from "satcheljs";
import { appViewState } from "../../store";

const setIsPrinting = mutatorAction(
    "setIsPrinting",
    (isPrinting: boolean = true) => {
        const viewState = appViewState().printViewState;
        viewState.isPrinting = isPrinting;
    }
);

export default setIsPrinting;