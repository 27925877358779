import IJobEntity, { JobStatus } from "./IJobEntity";

/**
 *  Few operations are heavy that can't be performed by web role, So service is running those operations in worker role asnchronously.
 *  Service web role will give us jobId (generated by job framework) when we are doing API call for those heavy operation.
 *  That JobId will be used later by client to get status of submitted operation.
 */
export default class JobEntity implements IJobEntity {
    status: JobStatus;
    jobId: string;
    result: any;

    constructor(status: JobStatus, jobId: string, result: any) {
        this.status = status;
        this.jobId = jobId;
        this.result = result;
    }

    static fromJson(jsonData: IJobEntity): JobEntity {
        if (!jsonData) {
            return null;
        }

        return new JobEntity(jsonData.status, jsonData.jobId, jsonData.result);
    }
}