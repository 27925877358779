import { Detail, StaffHubError } from "sh-application";

/**
 * The Network Queue uses instances of this class to reject requests when the requests are rejected
 * due to the rejection of a preceding request.
 */
export default class NetworkQueueAutoRejectError implements StaffHubError {
    public message: string = "Preceding request in the queue for this object was rejected.";
    public code: string = "800"; // TODO: determine an appropriate code
    public details: Array<Detail>; // TODO: determine how to log - "This request has been rejected without executing because a preceding request modifiying the same object was rejected, and we cannot guarantee that the service will be handle this request given that a preceding request for the object ws rejected."
    public innererror: Detail;
}