import { mutatorAction } from "satcheljs";
import { ScheduleCalendarType } from "sh-models";
import { SchedulesViewStateStore } from "sh-application/components/schedules/lib/store/schema/SchedulesViewStateStore";

/**
 * Sets the calendar type of the Schedules page.
 */
export default mutatorAction("SET_SCHEDULE_CALENDAR_TYPE",
    function setScheduleCalendarType(
        viewState: SchedulesViewStateStore,
        newScheduleCalendarType: ScheduleCalendarType) {

        viewState.scheduleCalendarType = newScheduleCalendarType;
    }
);