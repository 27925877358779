import arrayMove from "sh-application/utility/arrayMove";
import setReorderMemberIndex from "../mutators/setReorderMemberIndex";
import { IMemberEntity } from "sh-models";
import { orchestrator  } from "satcheljs";
import { setMembers } from "sh-team-store";
import { trace } from "owa-trace";
import reorderTeamMember from "../actions/reorderTeamMember";

const logTag = "[REORDER_TEAM_MEMBER]";

/**
 * orchestrator that reorders members in team store based on indices
 * Used by drag and drop hander when a member is moved around in the ungrouped view
 * @param currentlyRenderedMembers   - members who are currently rendered on the schedule
 * @param sourceIndex                - Index of the member being moved
 * @param targetIndex                - Index to which the member is being moved to
 */
export const reorderTeamMemberOrchestrator = orchestrator(reorderTeamMember, actionMessage => {
    const { currentlyRenderedMembers , sourceIndex, targetIndex } = actionMessage;
        if (!currentlyRenderedMembers || currentlyRenderedMembers.length === 0) {
            trace.warn(`${logTag} currently rendered member list is empty`);
            return;
        }

        if (targetIndex < 0 || targetIndex >= currentlyRenderedMembers.length) {
            // trying to move the first or last member in the team, do nothing
            trace.info(`Trying to move first or last member. targetIndex: ${targetIndex}`);
            return;
        } else {
            arrayMove(currentlyRenderedMembers, sourceIndex, targetIndex);
        }

        // make sure that member objects have index set correctly
        currentlyRenderedMembers.forEach((renderedMember: IMemberEntity, index: number) => {
            if (renderedMember.index !== index) {
                setReorderMemberIndex(renderedMember, index);
            }
        });

        // update the member objects on the store as this would trigger the UI refresh
        // TODO: This is a bad pattern and these changes should ideally happen in a view model only
        //      (instead of affecting the global store)
        setMembers(currentlyRenderedMembers);
    });