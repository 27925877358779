import * as React from "react";
import { computed } from "mobx";
import { observer } from "mobx-react";

const classNames = require("classnames/bind");
const styles = require("./SelectableContentWrapper.scss");

interface SelectableContentWrapperProps {
    /* true if selection should be enabled */
    selectionEnabled: boolean;

    /**
     * isSelected() must be a function that queries a satchel store so that the render function of this component is called on updates.
     * If it returns true, a "selected" class is added to the wrapping div
     */
    isSelected: (key: string) => boolean;

    /* a unique key used to query for selection state of the wrapped content */
    selectionKey: string;
}

/**
 * This component wraps other components with a div with height and width at 100%, and with a "selected" class if
 * the isSelected() callback returns true. isSelected() must be a function that queries a satchel store so that the
 * render function of this component is called on updates.
 */
@observer
export default class SelectableContentWrapper extends React.Component<SelectableContentWrapperProps, {}> {

    @computed get isSelected(): boolean {
        const { isSelected, selectionKey, selectionEnabled} = this.props;
        let isContentSelected: boolean = false;

        if (selectionEnabled && isSelected && selectionKey) {
              isContentSelected = this.props.isSelected(this.props.selectionKey);
        }

        return isContentSelected;
    }

    @computed get selectableContentWrapperClasses(): string {
        return classNames(
            styles.selectableContentWrapper,
            { selected: this.isSelected }
        );
    }

    render() {
        return (
            <div className={ this.selectableContentWrapperClasses }>
                { this.props.children }
            </div>
        );
    }
}