import {
    ConflictStore,
    decrementConflictCount,
    deleteShiftIdFromConflictList,
    removeConflictFromConflictStore
    } from "..";
import { ConflictType } from "sh-models";
import { orchestrator } from "satcheljs";

export default orchestrator(removeConflictFromConflictStore, actionMessage => {
    const conflictingShiftId: string = actionMessage.conflictingShiftId;
    const conflictType: ConflictType = actionMessage.conflictType;
    const memberId: string = actionMessage.memberId;
    const otherConflictingShiftId: string = actionMessage.otherConflictingShiftId;
    const conflictingAvailabilityId: string = actionMessage.conflictingAvailability;
    const conflictEntitiesList = ConflictStore().shiftIdToConflictsMap.get(conflictingShiftId);

    // delete any conflicts associated to this shift in ConflictStore
    if (conflictEntitiesList) {
        // adjust conflict count appropriately - decrement as much as there are conflicts in the conflict map for this shift
        decrementConflictCount(memberId, conflictType);

        if (conflictType === ConflictType.ShiftAvailabilityConflict) {
            deleteShiftIdFromConflictList(conflictEntitiesList /* conflict entities */, conflictingShiftId /* conflicting shiftId */, null /* other conflicting shift id */, conflictingAvailabilityId /* conflicting availability id */);
        } else {
            deleteShiftIdFromConflictList(conflictEntitiesList /* conflict entities */, conflictingShiftId /* conflicting shiftId */, otherConflictingShiftId /* other conflicting shift id */);
            const otherConflictingShiftList = ConflictStore().shiftIdToConflictsMap.get(otherConflictingShiftId);
            deleteShiftIdFromConflictList(otherConflictingShiftList /* conflict entities */, otherConflictingShiftId /* conflicting shiftId */, conflictingShiftId /* other conflicting shift id */);
        }
    }
});