import { createStore } from "satcheljs";
import { IUniqueShiftEntity } from "sh-models";
import { observable } from "mobx";
import { UniqueShiftStoreSchema } from "./schema/UniqueShiftStoreSchema";

/**
 * Store for Unique Shifts
 */
let UniqueShiftStore = createStore<UniqueShiftStoreSchema>(
    "uniqueShiftStore",
    {
        // list of all unique shifts
        uniqueShifts: observable<IUniqueShiftEntity>([]),
        hasFetchedUniqueShifts: observable.box(false)
    });

export default UniqueShiftStore;