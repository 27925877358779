import schedulesViewStateStore from "../store/store";
import { IBaseConflictEntity } from "sh-models";
import { mutatorAction } from "satcheljs";
import { ObservableMap } from "mobx";

export default mutatorAction('SET_CONFLICTS_IN_VIEW', function setConflictsInView(
    conflictsInView: ObservableMap<string, IBaseConflictEntity[]>
) {
    schedulesViewStateStore().conflictsInView = conflictsInView;
});
