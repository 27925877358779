import * as React from "react";
import { DayPickerRangeController, DayPickerRangeControllerShape } from "react-dates";

export interface StaffhubDayPickerRangeControllerProps extends DayPickerRangeControllerShape {
    autofocus?: boolean;  // Set to true to automatically set initial focus within the date picker component when it is mounted
}

/**
 * Wrapper component for AirBnb's DayPickerRangeController component with initial focus handling
 */
export default class StaffhubDayPickerRangeController extends React.Component<StaffhubDayPickerRangeControllerProps, {}> {
    componentDidMount() {
        if (this.props.autofocus) {
            // Set focus on the previous month button of the date picker comoponent.
            // For accessibility, we need to set initial focus on the first interactive element, and this needs to
            // be done here since the AirBnb datepicker component doesn't seem to have initial focus handling that
            // works here.
            setTimeout(() => {
                // Wait for the AirBnb component to be rendered
                const elements = document.getElementsByClassName("DayPickerNavigation_leftButton__horizontal");
                if (elements && elements.length) {
                    let dayPickerLeftButton: HTMLElement = elements[0] as HTMLElement;
                    if (dayPickerLeftButton) {
                        // Setting focus on the AirBnb component's buttons don't work unless we wait again here
                        setTimeout(() => {
                            dayPickerLeftButton.focus();
                        }, 100);
                    }
                }
            }, 100);
        }
    }

    render() {
        let {autofocus, ...restProps} = this.props;  // Pass on all props except autofocus, which is only used for this component
        return (
            <DayPickerRangeController {...restProps} />
        );
    }
}
