import * as moment from "moment";
import INoteDbEntity from "./INoteDbEntity";
import INoteEntity from "./INoteEntity";
import INoteServiceEntity from "./INoteServiceEntity";
import ModelUtils from "../ModelUtils";
import NoteStates, { NoteStatesType } from "./NoteStates";
import NoteTypes, { NoteTypesType } from "./NoteTypes";
import ShiftrObjectModelBase from "../ShiftrObjectModelBase";
import { Moment } from "moment";
import { NOTE_ID_PREFIX } from "sh-application/../StaffHubConstants";

/**
 * Note Entity class
 */
export default class NoteEntity extends ShiftrObjectModelBase implements INoteEntity {
    tenantId: string;
    teamId: string;
    noteType: NoteTypesType;
    text: string;
    state: NoteStatesType;
    startTime: Moment;
    endTime: Moment;
    isPublished: boolean;
    sharedChanges: INoteEntity;

    constructor(
        id: string,
        tenantId: string,
        teamId: string,
        noteType: NoteTypesType,
        text: string,
        state: NoteStatesType,
        isPublished: boolean,
        startTime: Moment,
        endTime: Moment,
        eTag: string,
        sharedChanges: INoteEntity) {

        let modelId = id || NoteEntity.generateNoteId();
        super(modelId, eTag);

        this.tenantId = tenantId;
        this.teamId = teamId;
        this.noteType = noteType;
        this.text = text;
        this.state = state;
        this.isPublished = isPublished;
        this.sharedChanges = NoteEntity.clone(sharedChanges);

        if (startTime) {
            startTime = moment(startTime);
        } else {
            // defaults to today's date if startTime is missing
            startTime = moment();
        }
        // set start time to 0 hours
        startTime.startOf("day");
        this.startTime = startTime;

        if (endTime) {
            endTime = moment(endTime);
        } else {
            // Set endTime to 1 day later than the startTime, as we currently support only Day notes
            endTime = moment(startTime).add(1, "days");
        }

        endTime.startOf("day");
        this.endTime = endTime;
    }

    /**
     * Generate new noteId used by the app
     * @returns {string} noteId in form of Note<uuid>
     */
    private static generateNoteId(): string {
        return ModelUtils.generateUUIDWithPrefix(NOTE_ID_PREFIX);
    }

    /**
     * Static method to create a new note entity model
     */
    static createEmptyObject(startTime: Moment, endTime: Moment, text?: string, teamId?: string, tenantId?: string): INoteEntity {
        return NoteEntity.fromJson({
            id: null,
            tenantId: tenantId || null,
            teamId: teamId || null,
            noteType: NoteTypes.Day,
            text: text || "",
            state: NoteStates.Active,
            startTime: startTime && startTime.toISOString(),
            endTime: endTime && endTime.toISOString(),
            eTag: null,
            isPublished: false,
            sharedChanges: null
        });
    }

    /**
     * Static method to compare 2 notes object, this checks to see if ids, text, startTime, endTime, state and types are same
     */
    static areNotesSame(note1: INoteEntity, note2: INoteEntity): boolean {
        if (note1 === null && note2 === null) {
            return true;
        }

        if (!note1 || !note2) {
            return false;
        }

        return note1.id === note2.id
                && note1.text === note2.text
                && moment(note1.startTime).isSame(note2.startTime)
                && moment(note1.endTime).isSame(note2.endTime)
                && note1.state === note2.state;
    }

    /**
     * Function that clones a note entity object
     * @param note - note object that needs to be cloned
     */
    static clone(note: INoteEntity): INoteEntity {
        if (!note) {
            return null;
        }

        return new NoteEntity(
            note.id,
            note.tenantId,
            note.teamId,
            note.noteType,
            note.text,
            note.state,
            note.isPublished,
            note.startTime && moment(note.startTime),
            note.endTime && moment(note.endTime),
            note.eTag,
            NoteEntity.clone(note.sharedChanges));
    }

    /**
     * Function that converts a note Service or DB response into a note entity
     * @param jsonData response from client or db
     */
    static fromJson(jsonData: INoteServiceEntity | INoteDbEntity): INoteEntity {
        if (!jsonData) {
            return null;
        }

        return new NoteEntity(
            jsonData.id,
            jsonData.tenantId,
            jsonData.teamId,
            jsonData.noteType,
            jsonData.text,
            jsonData.state,
            jsonData.isPublished,
            jsonData.startTime && moment(jsonData.startTime),
            jsonData.endTime && moment(jsonData.endTime),
            jsonData.eTag,
            NoteEntity.fromJson(jsonData.sharedChanges));
    }

    /**
     * Function that converts a note entity into a JSON that can be sent over wire
     * @param clientModel note entity object that needs to be sent over the wire
     */
    static toJson(clientModel: INoteEntity): INoteServiceEntity {
        // skipping sharedChanges here as it is read-only

        if (!clientModel) {
            return null;
        }

        let note: INoteServiceEntity = {
            id: clientModel.id,
            tenantId: clientModel.tenantId,
            teamId: clientModel.teamId,
            noteType: clientModel.noteType,
            startTime: clientModel.startTime && clientModel.startTime.toISOString(),    // Service uses an ISO string datetime format
            endTime: clientModel.endTime && clientModel.endTime.toISOString(),          // Service uses an ISO string datetime format
            text: clientModel.text,
            isPublished: clientModel.isPublished,
            state: clientModel.state,
            eTag: clientModel.eTag
        };

        return note;
    }

    /**
     * Function that creates INoteDbEntity from INoteEntity
     * @param clientModel - note client entity
     */
    static toDbModel(clientModel: INoteEntity): INoteDbEntity {
            if (!clientModel) {
                return null;
            }

            let note: INoteDbEntity = {
                id: clientModel.id,
                tenantId: clientModel.tenantId,
                teamId: clientModel.teamId,
                noteType: clientModel.noteType,
                startTime: clientModel.startTime && clientModel.startTime.valueOf(),
                endTime: clientModel.endTime && clientModel.endTime.valueOf(),
                text: clientModel.text,
                isPublished: clientModel.isPublished,
                state: clientModel.state,
                eTag: clientModel.eTag,
                sharedChanges: NoteEntity.toDbModel(clientModel.sharedChanges)
            };

            return note;
    }
}
