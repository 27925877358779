import { IRawStyle } from "@fluentui/react";

export const visuallyHidden: IRawStyle = {
    position: "absolute",
    clip: "rect(0 0 0 0)",
    border: 0,
    width: 1,
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0
};
