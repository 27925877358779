import resetUniqueSubshiftStore from "../actions/resetUniqueSubshiftStore";
import UniqueSubshiftStore from "../store";
import { mutator } from "satcheljs";

/**
 * Reset the unique subshift store
 */
export default mutator(resetUniqueSubshiftStore, ({ }) => {
    UniqueSubshiftStore().uniqueSubshifts.clear();
    UniqueSubshiftStore().hasFetchedUniqueSubshifts.set(false);
});