import schedulesViewStateStore from "../../store/store";
import { ITagEntity } from "sh-models";
import { mutatorAction } from "satcheljs";

/**
 * Toggle group in filter
 */
export default mutatorAction("TOGGLE_GROUP_FILTER_IN_VIEW", function toggleGroupFilter(
    group: ITagEntity
) {
    if (!schedulesViewStateStore() || !schedulesViewStateStore().filteredTags) {
        return;
    }

    if (schedulesViewStateStore().filteredTags.has(group.id)) {
        schedulesViewStateStore().filteredTags.delete(group.id);
    } else {
        schedulesViewStateStore().filteredTags.set(group.id, group);
    }
});
