import { ConflictStore } from "sh-conflict-store";
import { IBaseConflictEntity, IShiftConflictEntity, IAvailabilityConflictEntity, ConflictType } from "sh-models";
import { mutatorAction } from "satcheljs";

export default mutatorAction('DELETE_SHIFTID_FROM_CONFLICT_LIST',
   function deleteShiftIdFromConflictList(conflictList: IBaseConflictEntity[], conflictingShiftId: string, otherConflictingShiftId?: string, availabilityId?: string) {
   for (let index = 0; index < conflictList.length; index++) {
      const conflictEntity: IBaseConflictEntity = conflictList[index];
      if (conflictEntity.conflictType === ConflictType.ShiftAvailabilityConflict) {
         const availabilityConflictEntity = <IAvailabilityConflictEntity>conflictEntity;
         if (availabilityId && availabilityConflictEntity.availabilityId === availabilityId) {
            conflictList.splice(index, 1);
            break;
         }
      } else {
         const shiftConflictEntity = <IShiftConflictEntity>conflictEntity;
         if (otherConflictingShiftId && (shiftConflictEntity.shiftId === otherConflictingShiftId || shiftConflictEntity.conflictingShiftId === otherConflictingShiftId)) {
            conflictList.splice(index, 1);
            break;
         }
      }
   }

   // todo - if confloctList is null, delete entity
   if (conflictList.length === 0) {
      ConflictStore().shiftIdToConflictsMap.delete(conflictingShiftId);
   } else {
      ConflictStore().shiftIdToConflictsMap.set(conflictingShiftId, conflictList);
   }
});
