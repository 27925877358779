import schedulesViewStateStore from "../../store/store";
import { IOpenShiftEntity, OpenShiftEntity } from "sh-models";
import { mutatorAction } from "satcheljs";
import { ObservableMap } from "mobx";

export default mutatorAction('UPDATE_OPEN_SHIFTS_IN_VIEW', function updateOpenShiftsInView(
    openShiftsInView: IOpenShiftEntity[]
) {
    let openShifts: ObservableMap<string, IOpenShiftEntity> = schedulesViewStateStore().openShiftsInView;
    for (let i = 0; i < openShiftsInView.length; i++) {
        openShifts.set(openShiftsInView[i].id, OpenShiftEntity.clone(openShiftsInView[i]));
    }
});
