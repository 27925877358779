import AvailabilityStore from "../store/index";
import AvailabilityUtils from "sh-application/utility/AvailabilityUtils";
import { IAvailabilityEntity, IMemberEntity } from "sh-models";
import { Moment } from "moment";
import { TeamSettingsStore } from "../../sh-teamsettings-store";

/**
 * Gets the availability for a given member and a given date.
 * @param member The member to get availability for.
 * @param startDate The start date to get availability for.
 * @param endDate The end date to get availability for.
 * @returns The availability for the given member and date.
 */
export function getAvailability(member: IMemberEntity, startDate: Moment, endDate?: Moment): IAvailabilityEntity {
    if (!member) {
        return null;
    }

    const memberAvailabilities = AvailabilityStore().membersAvailabilities.get(member.id) ?? [];
    const scheduleTimeZone = TeamSettingsStore().timeZoneOlsonCode.value;

    if (endDate) {
        return AvailabilityUtils.mergeAvailabilitiesForDateRange(memberAvailabilities, startDate, endDate, scheduleTimeZone);
    } else {
        return AvailabilityUtils.getAvailabilityForDate(memberAvailabilities, startDate, scheduleTimeZone);
    }
}