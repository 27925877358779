import resetUniqueShiftStore from "../actions/resetUniqueShiftStore";
import UniqueShiftStore from "../store";
import { mutator } from "satcheljs";

/**
 * Reset the unique shift store
 */
export default mutator(resetUniqueShiftStore, ({ }) => {
    UniqueShiftStore().uniqueShifts.clear();
    UniqueShiftStore().hasFetchedUniqueShifts.set(false);
});