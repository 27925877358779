import { action } from "satcheljs";

const updateRequestsNextCursorInStore = action(
    "UPDATE_NEXT_CURSOR_IN_STORE",
    (
        nextCursor: string
    ) => ({
        nextCursor: nextCursor
    })
);

export default updateRequestsNextCursorInStore;
