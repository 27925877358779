import * as moment from "moment";
import AppSettingsClientModel from "./AppSettingsClientModel";
import AppSettingsModelBase from "./AppSettingsModelBase";
import IShiftrObjectServiceModel from "../IShiftrObjectServiceModel";
import { FlightSettings, ExtraTeamSettings } from "sh-models";

/**
 * App Settings model
 */
export default class AppSettingsServiceModel extends AppSettingsModelBase {
    nextPollTime: string;

    constructor(id: string,
        eTag: string,
        tenantId: string,
        teamId: string,
        flightSettings: FlightSettings,
        extraTeamSettings: ExtraTeamSettings,
        nextPollTime: string) {
        super(id, eTag, tenantId, teamId, flightSettings, extraTeamSettings);
        this.nextPollTime  = nextPollTime;
    }
    static fromJson(jsonData: any): AppSettingsServiceModel {
        if (!jsonData) {
            return null;
        }
        return new AppSettingsServiceModel(
            jsonData.id,
            jsonData.eTag,
            jsonData.tenantId,
            jsonData.teamId,
            jsonData.flightSettings,
            jsonData.extraTeamSettings,
            jsonData.nextPollTime);
    }

    static fromClientModel(clientModel: AppSettingsClientModel): AppSettingsServiceModel {
        if (!clientModel) {
            return null;
        }
        return new AppSettingsServiceModel(
            clientModel.id,
            clientModel.eTag,
            clientModel.tenantId,
            clientModel.teamId,
            clientModel.flightSettings,
            clientModel.extraTeamSettings,
            clientModel.nextPollTime.toISOString());
    }

    static toClientModel(serviceModel: AppSettingsServiceModel): AppSettingsClientModel {
        if (!serviceModel) {
            return null;
        }
        return new AppSettingsClientModel(
            serviceModel.id,
            serviceModel.eTag,
            serviceModel.tenantId,
            serviceModel.teamId,
            serviceModel.flightSettings,
            serviceModel.extraTeamSettings,
            moment(serviceModel.nextPollTime));
    }
}

// Verify that this class adheres to the contract defined by our interface.
// This needs to be done separately since Typescript does not allow static methods in interfaces.
// tslint:disable-next-line:no-unused-declaration
let __verifyInterface: IShiftrObjectServiceModel<AppSettingsServiceModel, AppSettingsClientModel> = AppSettingsServiceModel;