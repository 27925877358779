import IShiftrObjectModelBase from "./IShiftrObjectModelBase";

/**
 * Base properties that are common for all Shiftr models
 */
export default class ShiftrObjectModelBase implements IShiftrObjectModelBase {
    id: string;
    eTag: string;

    constructor(id: string, eTag: string) {
        this.id = id;
        this.eTag = eTag;
    }
}
