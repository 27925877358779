import { AppSettingsStore, getAppSettingsForTeam } from "sh-appsettings-store";
import { FlightKey, FlightSetting, FlightSettings, ExtraTeamSettings, ExtraTeamSettingsKey, ExtraTeamSetting } from "sh-models";
import { TenantDataService } from "sh-services";

/**
 * TO DO: Deprecate this as it is pre-ECS, and use the new ECSConfigService instead.
 * FlightSettingsService provides access to flight settings. It currently checks the teamSettings and tenantSettings objects living
 * in the AppSettingsStore
 */
class FlightSettingsService {

    /**
     * NOTE: Use only for boolean Team flights. For other flights, use getTenantFlightSettings or getTeamFlightSetting directly.
     * Returns true if the flightKey is enabled. Checks team settings.
     * @param flightKey
     */
    public isFlightEnabled(flightKey: FlightKey): boolean {
        let enabled = this.getFlightSetting(flightKey) as boolean;
        return !!enabled;
    }

    /**
     * Returns the flight associated with the flightKey at the team level, or null if none is found.
     * @param flightKey
     */
    public getFlightSetting(flightKey: FlightKey): FlightSetting {
        let setting: FlightSetting = null;
        const teamFlightSettings: FlightSettings = this.getFlightSettings();

        if (teamFlightSettings && flightKey) {
            setting = teamFlightSettings[flightKey];
        }

        return setting;
    }

    /**
     * Checks for existence of and then returns the flightSettings object for teamSettings. If the flightSettings don't exist, will trigger the getAppSettingsForTeam action
     */
    private getFlightSettings(): FlightSettings {
        const flightSettings: FlightSettings = AppSettingsStore && AppSettingsStore().teamSettings && AppSettingsStore().teamSettings.flightSettings;

        if (!flightSettings && AppSettingsStore && AppSettingsStore().teamSettings) {
            getAppSettingsForTeam(AppSettingsStore().teamSettings.tenantId, AppSettingsStore().teamSettings.teamId);
        }

        return flightSettings;
    }

    /**
     * Returns the setting associated with the extraTeamSetting Key at the team level, or null if none is found.
     * @param key
     */
    public getExtraTeamSettingsValues(key: ExtraTeamSettingsKey): ExtraTeamSetting {
        let setting: ExtraTeamSetting = null;
        const extraTeamSettings: ExtraTeamSettings = this.getExtraTeamsSettings();

        if (extraTeamSettings && key) {
            setting = extraTeamSettings[key];
        }

        return setting;
    }

    /**
     * Checks for existence of and then returns the extraTeamSettings object for teamSettings
     */
    private getExtraTeamsSettings(): ExtraTeamSettings {
        return AppSettingsStore && AppSettingsStore().teamSettings && AppSettingsStore().teamSettings.extraTeamSettings;
    }

    /**
     * Returns true if the Tenant flightKey is enabled.
     * @param flightKey
     */
    public async isTenantFlightEnabled(flightKey: FlightKey): Promise<boolean> {
        let enabled = await this.getTenantFlightSetting(flightKey) as boolean;
        return !!enabled;
    }

    /**
     * Returns the flight associated with the flightKey at the tenant level, or null if none is found.
     * @param flightKey
     */
    private async getTenantFlightSetting(flightKey: FlightKey): Promise<FlightSetting> {
        let setting: FlightSetting = null;
        const tenantAppSettings = await TenantDataService.getAppSettingsForTenant();
        const tenantFlightSettings: FlightSettings = tenantAppSettings ? tenantAppSettings.flightSettings : null;

        if (tenantFlightSettings && flightKey) {
            setting = tenantFlightSettings[flightKey];
        }

        return setting;
    }
}

const flightSettingsService = new FlightSettingsService();
export default flightSettingsService as FlightSettingsService;
