import { IDataProvider } from "./IDataProvider";

/**
 * Abstract Base class for data providers
 */
export abstract class DataProvider<T> implements IDataProvider<T> {
    /**
     * Return data if it's found in memory (otherwise return undefined)
     */
    abstract getDataFromMemory(): Promise<T>;

    /**
     * Return data if it's found in the database (otherwise return undefined)
     */
    abstract getDataFromDatabase(): Promise<T>;

    /**
     * Make an HTTP request to fetch the data from the network
     */
    abstract getDataFromNetwork(): Promise<T>;

    /**
     * Returns undefined by default. Override this method to return default data
     * @returns {T} data.
     */
    async getDefaultData(): Promise<T> {
        return undefined;
    }

    /**
     * Set data in memory
     */
    abstract setDataInMemory(data: T): Promise<void>;

    /**
     * Set data in the database
     */
    abstract setDataInDatabase(data: T): Promise<void>;

    /**
     * Make a network call to update the data
     */
    abstract saveDataToNetwork(data: T): Promise<T>;

    /**
     * Hard Delete data if it's found in memory
     */
    async hardDeleteDataFromMemory(): Promise<void> {
        throw new Error("hardDeleteDataFromMemory not implemented");
    }

    /**
     * Delete data if it's found in the database
     */
    async hardDeleteDataFromDatabase(): Promise<void> {
        throw new Error("hardDeleteDataFromDatabase not implemented");
    }

    /**
     * Whether to skip refreshing data from network if it was only in the database and not in memory
     * This method gets called when we don't find the data in memory, and find something in the database.
     * Because we can't always trust that the data from the database was in-sync and not missing changes from the service,
     * this method allows the provider to inspect the state of data from the db and
     * determine if we need to make a network call to refresh this data asynchronously.
     */
    async skipRefreshFromNetworkIfNotInMemory(data: T): Promise<boolean> {
        // By default, we refresh any data we found in the DB with a network request
        return false;
    }
}