import approveDeclineTimeOffRequest from "../actions/approveDeclineTimeOffRequest";
import ShiftRequestUtils from "sh-application/utility/ShiftRequestUtils";
import StringsStore from "sh-strings/store";
import { fireAccessibilityAlert } from "sh-application/components/accessibilityAlert";
import { orchestrator } from "satcheljs";
import { setErrorMessageFromException, setIsSavingInStore } from "../";
import { ShiftRequestDataService } from "sh-services";
import { ShiftRequestTypes } from "sh-models";
import { trace } from "owa-trace";

/**
 * This orchestrator will call the api to approve or decline a timeoff request
 */
export default orchestrator(approveDeclineTimeOffRequest, async actionMessage => {
    const { shiftRequest, isAccepting, responseMessage, onFinish } = actionMessage;
    if (!shiftRequest) {
        trace.warn("approveDeclineTimeOffRequest: request not specified");
        return;
    } else if (shiftRequest.requestType !== ShiftRequestTypes.TimeOff) {
        trace.warn("approveDeclineTimeOffRequest: timeOffRequest not specified");
        return;
    }

    try {
        setIsSavingInStore(true);
        // send the request to approve/decline
        await ShiftRequestDataService.approveDeclineTimeOffRequest(shiftRequest.tenantId, shiftRequest.teamId, shiftRequest.id, shiftRequest.eTag, isAccepting, responseMessage);
        if (onFinish) {
            onFinish();
        }

        // Accessibility message that the request was approved/declined
        const shiftRequestType: string = ShiftRequestUtils.getTypeForAccessibility(shiftRequest);
        if (isAccepting) {
            fireAccessibilityAlert(StringsStore().registeredStringModules.get("shiftRequests").strings.get("shiftRequestApproved").format(shiftRequestType));
        } else {
            fireAccessibilityAlert(StringsStore().registeredStringModules.get("shiftRequests").strings.get("shiftRequestDeclined").format(shiftRequestType));
        }
    } catch (error) {
        // workflow integration error would be handled earlier
        if (error && !error.isHandled) {
            setErrorMessageFromException(error);
        }
    } finally {
        setIsSavingInStore(false);
    }
});
