import DateUtils from "sh-application/utility/DateUtils";
import NoteUtils from "sh-application/utility/NoteUtils";
import ShiftUtils from "sh-application/utility/ShiftUtils";
import {
    IBaseShiftEntity,
    INoteEntity,
    IOpenShiftEntity,
    IShiftEntity
    } from "sh-models";
import { MobxUtils } from "sh-application";
import { Moment } from "moment";
import { ObservableMap } from "mobx";

export default class DataProcessingHelpers {

    public static getArrayFromMap<K, V>(map: ObservableMap<K, V>): V[] {
        return map ? MobxUtils.MapToArray(map) : [];
    }

    /**
     * Returns the shifts with shared changes in the given range
     * @param shifts
     * @param rangeStart
     * @param rangeEnd
     */
    public static getSharedShiftsInRange(shifts: Array<IBaseShiftEntity>, rangeStart: Moment, rangeEnd: Moment): IShiftEntity[] {
        const filterFunction = (shift: IShiftEntity) => {
            return ShiftUtils.isDisplayableNonTimeoffRequestShiftInRangeWithSharedChanges(shift, rangeStart, rangeEnd);
        };
        return shifts ? shifts.filter(filterFunction) : [];
    }

    /**
     * Returns the number of shifts with shared changes in the given range
     * @param shifts
     * @param rangeStart
     * @param rangeEnd
     */
    public static getNumOfSharedAssignedShiftsInRange(shifts: Array<IBaseShiftEntity>,  rangeStart: Moment, rangeEnd: Moment): number {
        return this.getSharedShiftsInRange(shifts, rangeStart, rangeEnd).length;
    }

    /**
     * Returns the shifts with shared changes in the given range
     * @param shifts
     * @param rangeStart
     * @param rangeEnd
     */
    public static getShiftsWithUnsharedEditsRange(shifts: Array<IBaseShiftEntity>, rangeStart: Moment, rangeEnd: Moment): IShiftEntity[] {
        const filterFunction = (shift: IShiftEntity) => {
            return ShiftUtils.isDisplayableNonTimeoffRequestShiftInRangeWithUnsharedEdits(shift, rangeStart, rangeEnd);
        };
        return shifts ? shifts.filter(filterFunction) : [];
    }

    /**
     * Returns the number of shifts with unshared edits in the given range
     * @param shifts
     * @param rangeStart
     * @param rangeEnd
     */
    public static getNumOfUnsharedEditsToShiftsInRange(shifts: Array<IBaseShiftEntity>, rangeStart: Moment, rangeEnd: Moment): number {
        return DataProcessingHelpers.getShiftsWithUnsharedEditsRange(shifts, rangeStart, rangeEnd).length;
    }

    /**
     * Returns the open shifts with shared changes in the given range
     * @param openShifts
     * @param rangeStart
     * @param rangeEnd
     */
    public static getSharedOpenShiftsInRange(openShifts: Array<IOpenShiftEntity>, rangeStart: Moment, rangeEnd: Moment): IOpenShiftEntity[] {
        const filterFunction = (shift: IOpenShiftEntity) => {
            return ShiftUtils.isDisplayableNonTimeoffRequestShiftInRangeWithSharedChanges(shift, rangeStart, rangeEnd);
        };
        return openShifts ? openShifts.filter(filterFunction) : [];
    }

    /**
     * Returns the number of open shifts with shared changes in the given range
     * @param openShifts
     * @param rangeStart
     * @param rangeEnd
     */
    public static getNumOfSharedOpenShiftsInRange(openShifts: Array<IOpenShiftEntity>, rangeStart: Moment, rangeEnd: Moment): number {
        return DataProcessingHelpers.getSharedOpenShiftsInRange(openShifts, rangeStart, rangeEnd).length;
    }

    /**
     * Returns the number of open shifts with unshared edits in the given range
     * @param openShifts
     * @param rangeStart
     * @param rangeEnd
     */
    public static getOpenShiftsWithUnsharedEditsInRange(openShifts: Array<IOpenShiftEntity>, rangeStart: Moment, rangeEnd: Moment): IOpenShiftEntity[] {
        const filterFunction = (shift: IOpenShiftEntity) => {
            return ShiftUtils.isDisplayableNonTimeoffRequestShiftInRangeWithUnsharedEdits(shift, rangeStart, rangeEnd);
        };
        return openShifts ? openShifts.filter(filterFunction) : [];
    }

    /**
     * Returns the number of open shifts with unshared edits in the given range
     * @param openShifts
     * @param rangeStart
     * @param rangeEnd
     */
    public static getNumOfUnsharedEditsToOpenShiftsInRange(openShifts: Array<IOpenShiftEntity>, rangeStart: Moment, rangeEnd: Moment): number {
        return this.getOpenShiftsWithUnsharedEditsInRange(openShifts, rangeStart, rangeEnd).length;
    }

    /**
     * Returns a map of the number of shared entities on each date in the provided range.
     * These entities must fit in the range and be valid for schedule view.
     * @param shifts
     * @param openShifts
     * @param notes
     * @param rangeStart
     * @param rangeEnd
     */
    public static getNumSharedEntitiesByDate(
        shifts: Array<IBaseShiftEntity>,
        openShifts: Array<IOpenShiftEntity>,
        notes: Array<INoteEntity>,
        rangeStart: Moment,
        rangeEnd: Moment): Map<number, number>  {
        let numSharedEntitiesByDate: Map<number, number> = new Map<number, number>();
        // We examine all the open shifts, notes, and shifts currently in the cache. Those that fall into the range,
        // are valid for display on the schedule, and have shared changes will be used to count the number of shared
        /// entities on the date of their start time
        if (openShifts) {
            openShifts.forEach((openShift: IOpenShiftEntity) => {
                if (ShiftUtils.isOpenShiftDisplayableForScheduleView(openShift) &&
                    ShiftUtils.hasSharedChanges(openShift) &&
                    !ShiftUtils.isTimeOffRequestEvent(openShift) &&
                    DateUtils.isStartTimeInView(openShift.startTime, rangeStart, rangeEnd)) {
                    const dateIndex = DateUtils.fastCalculateDateIndex(ShiftUtils.getSharedChanges(openShift).startTime);
                    let numSharedEntitiesForDate = numSharedEntitiesByDate.get(dateIndex) || 0;
                    numSharedEntitiesByDate.set(dateIndex, ++numSharedEntitiesForDate);
                }
            });
        }

        if (notes) {
            notes.forEach((note: INoteEntity) => {
                if (NoteUtils.getSharedChangesForNote(note) &&
                    NoteUtils.isNoteDisplayableForScheduleView(note) &&
                    NoteUtils.isNoteInRange(note, rangeStart, rangeEnd)) {
                    const dateIndex = DateUtils.fastCalculateDateIndex(NoteUtils.getSharedChangesForNote(note).startTime);
                    let numSharedEntitiesForDate = numSharedEntitiesByDate.get(dateIndex) || 0;
                    numSharedEntitiesByDate.set(dateIndex, ++numSharedEntitiesForDate);
                }
            });
        }

        if (shifts) {
            shifts.forEach((shift: IShiftEntity) => {
                if (ShiftUtils.hasSharedChanges(shift) &&
                    !ShiftUtils.isTimeOffRequestEvent(shift) &&
                    ShiftUtils.isShiftDisplayableForScheduleView(shift) &&
                    DateUtils.isStartTimeInView(shift.startTime, rangeStart, rangeEnd)) {
                    const dateIndex = DateUtils.fastCalculateDateIndex(ShiftUtils.getSharedChanges(shift).startTime);
                    let sharedShiftsForDate = numSharedEntitiesByDate.get(dateIndex) || 0;
                    numSharedEntitiesByDate.set(dateIndex, ++sharedShiftsForDate);
                }
            });
        }

        return numSharedEntitiesByDate;
    }

    /**
     * Returns a map of the number of shared entities on each date in the provided range.
     * These entities must fit in the range and be valid for schedule view.
     *  @param shifts
     * @param openShifts
     * @param notes
     * @param rangeStart
     * @param rangeEnd
     * @param unsharedEditsIgnoreOpenShifts - if true, unshared edits to open shifts will not be added to any of the unshareEdits maps
     */
    public static getUnsharedEditsByDate(
        shifts: Array<IBaseShiftEntity>,
        openShifts: Array<IOpenShiftEntity>,
        notes: Array<INoteEntity>,
        rangeStart: Moment,
        rangeEnd: Moment,
        unsharedEditsIgnoreOpenShifts: boolean = false): Map<number, number>  {
        let unsharedEditsByDate: Map<number, number> = new Map<number, number>();

        if (!unsharedEditsIgnoreOpenShifts && openShifts) {
            openShifts.forEach((openShift: IOpenShiftEntity) => {
                if (ShiftUtils.isOpenShiftDisplayableForScheduleView(openShift) &&
                    ShiftUtils.shiftHasUnsharedEdits(openShift) &&
                    !ShiftUtils.isTimeOffRequestEvent(openShift) &&
                    DateUtils.isStartTimeInView(openShift.startTime, rangeStart, rangeEnd)) {
                    const dateIndex = DateUtils.fastCalculateDateIndex(openShift.startTime);
                    let numSharedEntitiesForDate = unsharedEditsByDate.get(dateIndex) || 0;
                    unsharedEditsByDate.set(dateIndex, ++numSharedEntitiesForDate);
                }
            });
        }

        if (notes) {
            notes.forEach((note: INoteEntity) => {
                if (NoteUtils.noteHasUnsharedEdits(note) &&
                    NoteUtils.isNoteDisplayableForScheduleView(note) &&
                    DateUtils.isStartTimeInView(note.startTime, rangeStart, rangeEnd)) {
                    const dateIndex = DateUtils.fastCalculateDateIndex(note.startTime);
                    let numSharedEntitiesForDate = unsharedEditsByDate.get(dateIndex) || 0;
                    unsharedEditsByDate.set(dateIndex, ++numSharedEntitiesForDate);
                }
            });
        }

        if (shifts) {
            shifts.forEach((shift: IShiftEntity) => {
                if (ShiftUtils.shiftHasUnsharedEdits(shift) &&
                    !ShiftUtils.isTimeOffRequestEvent(shift) &&
                    ShiftUtils.isShiftDisplayableForScheduleView(shift) &&
                    DateUtils.isStartTimeInView(shift.startTime, rangeStart, rangeEnd)) {
                    const dateIndex = DateUtils.fastCalculateDateIndex(shift.startTime);
                    let sharedShiftsForDate = unsharedEditsByDate.get(dateIndex) || 0;
                    unsharedEditsByDate.set(dateIndex, ++sharedShiftsForDate);
                }
            });
        }

        return unsharedEditsByDate;
    }

    /**
     * Return the shifts from the list that are Working shifts and are Active, and are for a specific member
     * @param shifts
     * @param memberId
     */
    public static getActiveWorkingShiftsForMember(shifts: IBaseShiftEntity[], memberId: string): IBaseShiftEntity[] {
        const filterFunction = (shift: IBaseShiftEntity) => {
            return shift.memberId === memberId && ShiftUtils.isActiveWorkingShift(shift);
        };
        return shifts ? shifts.filter(filterFunction) : [];
    }

    /**
     * Return the shared shifts from the list that are Active (Working and Absence),
     * and are for a specific member or for all team members except the member
     * @param shifts
     * @param memberId
     * @param excludeMember True if the list should exclude the current member's shifts. False if the list should be returned for the current member
     */
    public static getActiveSharedShiftsForMemberOrTeam(shifts: IBaseShiftEntity[], memberId: string, excludeMember: boolean): IBaseShiftEntity[] {
        let filterFunction: (shift: IBaseShiftEntity) => boolean;
        if (excludeMember) {
            filterFunction = (shift: IBaseShiftEntity) => {
                return shift.memberId !== memberId && ShiftUtils.isActiveShift(shift) && ShiftUtils.hasSharedChanges(shift);
            };
        } else {
            filterFunction = (shift: IBaseShiftEntity) => {
                return shift.memberId === memberId && ShiftUtils.isActiveShift(shift) && ShiftUtils.hasSharedChanges(shift);
            };
        }

        const filteredShifts: IBaseShiftEntity[] = shifts ? shifts.filter(filterFunction) : [];
        const mapFunction = (shift: IBaseShiftEntity) => {
            return ShiftUtils.getSharedChanges(shift);
        };
        return filteredShifts ? filteredShifts.map(mapFunction) : [];
    }

    /**
     * Returns subset of provided notes that are displayable in the provided range.
     * @param notes
     * @param rangeStart
     * @param rangeEnd
     */
    public static getDisplayableNotesInScheduleRange(notes: INoteEntity[], rangeStart: Moment, rangeEnd: Moment): INoteEntity[] {
        const filterFunction = (note: INoteEntity) => {
            return NoteUtils.isDisplayableNoteInScheduleRange(note, rangeStart, rangeEnd);
        };
        return notes ? notes.filter(filterFunction) : [];
    }

    /**
     * Returns subset of provided shifts that are displayable in the provided range. Supports assigned
     * and open shifts
     * @param shifts
     * @param rangeStart
     * @param rangeEnd
     */
    public static getDisplayableShiftsInScheduleRange(shifts: IBaseShiftEntity[], rangeStart: Moment, rangeEnd: Moment): IBaseShiftEntity[] {
        const filterFunction = (shift: IBaseShiftEntity) => {
            return ShiftUtils.isOpenShift(shift) ? ShiftUtils.isDisplayableOpenShiftInScheduleRange(shift as IOpenShiftEntity, rangeStart, rangeEnd) : ShiftUtils.isDisplayableShiftInScheduleRange(shift, rangeStart, rangeEnd);
        };
        return shifts ? shifts.filter(filterFunction) : [];
    }
}