import * as React from "react";
import AutomationUtil from "sh-application/utility/AutomationUtil";
import KeyboardUtils from "sh-application/utility/KeyboardUtils";
import { IconButton } from "@fluentui/react";
import StringsStore from "sh-strings/store";

const styles = require("../Shift.scss");

export interface ConflictIconButtonProps {
    /**
     * Callback invoked when conflict badge icon is clicked
     */
    onConfictIconBadgeClick?: VoidFunction;
}

/**
 * ConflictIconButton - conflict Icon button
 *
 */
export default class ConflictIconButton extends React.PureComponent<ConflictIconButtonProps, any> {

    private _conflictStrings: Map<string, string>;

    constructor(props: ConflictIconButtonProps) {
        super(props);
        this._conflictStrings = StringsStore().registeredStringModules.get("conflictManagement").strings;
    }

    /**
     * Callback invoked when conflict icon button is clicked
     */
    private onConfictIconBadgeClick = () => {
        if (this.props.onConfictIconBadgeClick) {
            this.props.onConfictIconBadgeClick();
        }
    }

    /**
     * Callback invoked when conflict icon key pressed
     */
    private onConflictIconBadgeKeyDown = (key: React.KeyboardEvent<HTMLDivElement>) => {
        if (KeyboardUtils.isActionKeyPressed(key)) {
            key.preventDefault();
            key.stopPropagation();
            this.onConfictIconBadgeClick();
        }
    }

    render() {

        return (
            <IconButton
                ariaLabel={ this._conflictStrings.get("showShiftConflict") }
                onClick={ this.onConfictIconBadgeClick }
                onKeyDown= { this.onConflictIconBadgeKeyDown }
                data-automation-id={ AutomationUtil.getAutomationId("scheduler", "QAIDConflictIconButton") }
                iconProps={ { className: styles.conflictIcon, iconName: "teams-conflict-icon" } }/>
        );
    }
}