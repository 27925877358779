import { mutatorAction } from "satcheljs";
import { SchedulesViewStateStore } from "sh-application/components/schedules/lib/store/schema/SchedulesViewStateStore";

/**
 * Sets the force header expand boolean
 */
export default mutatorAction("SET_FORCE_HEADER_EXPAND",
    function setForceHeaderExpand(schedulesViewStateStore: SchedulesViewStateStore, forceHeaderExpand: boolean) {
        schedulesViewStateStore.forceHeaderExpand = forceHeaderExpand;
    }
);