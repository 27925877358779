import { ConflictStore } from "sh-conflict-store";
import {
    ConflictType,
    IShiftConflictEntity,
    IShiftEntity,
    ShiftConflictEntity
    } from "sh-models";
import { mutatorAction } from "satcheljs";

export default mutatorAction('ADD_SHIFT_CONFLICT', function addShiftConflict(shift: IShiftEntity, conflictingShift: IShiftEntity, conflictType: ConflictType) {
    let conflictsArrayForShift = ConflictStore().shiftIdToConflictsMap.get(shift.id) || [];
    let conflictsArrayForConflictingShift = ConflictStore().shiftIdToConflictsMap.get(conflictingShift.id) || [];

    // we create one conflict entity and link it to both the shift Id to keep track for both.
    const conflictShiftEntity: IShiftConflictEntity = new ShiftConflictEntity(shift.memberId,
        shift.id,
        conflictType,
        false,
        conflictingShift.id
    );

    conflictsArrayForShift.push(conflictShiftEntity);
    ConflictStore().shiftIdToConflictsMap.set(shift.id, conflictsArrayForShift);

    conflictsArrayForConflictingShift.push(conflictShiftEntity);
    ConflictStore().shiftIdToConflictsMap.set(conflictingShift.id, conflictsArrayForConflictingShift);
});
