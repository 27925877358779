import CurrentTeamDataProvider from "sh-services/dataproviders/CurrentTeamDataProvider";
import RestClient from "sh-rest-client";
import { ITagEntity } from "sh-models";
import { ITeamDatabase } from "sh-services/data/ITeamDatabase";
import { setTags, TagStore } from "sh-tag-store";
import { TeamStore } from "sh-team-store";

/**
 * Tag Data Provider for the current team
 */
export class TagDataProvider extends CurrentTeamDataProvider<ITagEntity> {

    protected teamDatabase: ITeamDatabase;
    protected tenantId: string;
    protected tagId: string;

    constructor(teamDatabase: ITeamDatabase, tenantId: string, teamId: string, tagId: string) {
        super(teamId);
        this.teamDatabase = teamDatabase;
        this.tenantId = tenantId;
        this.tagId = tagId;
    }

    /**
     * Return data if it's found in memory (otherwise return undefined)
     */
    async getDataFromMemoryForCurrentTeam() {
        return (TeamStore().team && TeamStore().team.id == this.teamId && TagStore().tags ? TagStore().tags.get(this.tagId) : undefined);
    }

    /**
     * Return data if it's found in the database (otherwise return undefined)
     */
    async getDataFromDatabase() {
        return await this.teamDatabase.getTag(this.teamId, this.tagId);
    }

    /**
     * Make an HTTP request to fetch the data from the network
     */
    async getDataFromNetwork(): Promise<ITagEntity> {
        throw new Error("TagDataProvider.getDataFromNetwork not implemented");
    }

    /**
     * Set data in memory
     */
    async setDataInMemoryForCurrentTeam(data: ITagEntity) {
        setTags([data]);
    }

    /**
     * Set data in the database
     */
    async setDataInDatabase(data: ITagEntity) {
        if (data) {
            return await this.teamDatabase.setTags([data]);
        }
    }

    /**
     * Make a network call to update the data
     */
    async saveDataToNetwork(data: ITagEntity): Promise<ITagEntity> {
        return await RestClient.updateTag(this.tenantId, this.teamId, data);
    }
}