import { orchestrator } from "satcheljs";
import { MobxUtils } from "sh-application";
import { IShiftRequestEntity } from "sh-models";
import { shiftRequestStore } from "sh-shiftrequests-store";

import { setupSelectedRequestConflicts } from "..";
import { shiftRequestsPanelStore } from "../../../sh-application/components/shiftrequest";

import { fetchShiftRequestConflicts } from "./common/fetchShiftRequestConflicts";
import { isConflictsFeatureEnabled } from "./common/isConflictsFeatureEnabled";
import { shouldLoadShiftRequestConflicts } from "./common/shouldLoadShiftRequestConflicts";

/**
 * This orchestrator is called within request tab when a request is selected to setup data for doing request conflict calculations.
 * It will pass the pending request to orchestrators to load member shifts in stores and do conflict calculations.
 */
export const onSetupSelectedRequestConflicts = orchestrator(setupSelectedRequestConflicts, () => {
    const shiftRequests = shiftRequestStore().shiftRequests;
    const selectedRequestId = shiftRequestsPanelStore().selectedRequestId;

    if (!isConflictsFeatureEnabled() || !selectedRequestId || !shiftRequests.size) {
        return;
    }

    const selectedRequest = MobxUtils.MapToReadonlyArray<IShiftRequestEntity>(shiftRequests).find(
        shiftRequest => shiftRequest.id == selectedRequestId
    );

    if (selectedRequest && shouldLoadShiftRequestConflicts(selectedRequest)) {
        fetchShiftRequestConflicts(selectedRequest);
    }
});
