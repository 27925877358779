import { action  } from "satcheljs";
import { SchedulesViewStateStore } from "sh-application/components/schedules/lib/store/schema/SchedulesViewStateStore";

const initializeSchedulesViewState = action(
    "INITIALIZE_SCHEDULES_VIEW_STATE",
    (
        viewState: SchedulesViewStateStore
    ) => ({
        viewState: viewState
    })
);

export default initializeSchedulesViewState;