import gridSelectionViewStateStore from "../store/store";
import { mutatorAction } from "satcheljs";

export default mutatorAction('setSelectedCells', function setScheduleSaveState(
    gridCellKeys: string[],
    isSelected: boolean
) {
    let selectedCellKeys = gridSelectionViewStateStore().selectedCellKeys;
    selectedCellKeys.clear();
    const numKeys: number = gridCellKeys.length;
    for (let i = 0; i < numKeys; i++) {
       selectedCellKeys.set(gridCellKeys[i], isSelected);
    }
});
