import * as moment from "moment";
import CurrentTeamDataProvider from "sh-services/dataproviders/CurrentTeamDataProvider";
import DateUtils from "../../sh-application/utility/DateUtils";
import ShiftUtils from "sh-application/utility/ShiftUtils";
import {
    BaseShiftEntity,
    IDataWithSyncState,
    IItemsInTimeRangeSyncState,
    INoteEntity
    } from "sh-models";
import { DataInDateRangeDataProvider } from "./DataInDateRangeDataProvider";
import { ECSConfigKey, ECSConfigService } from "..";
import { DataFilter } from "sh-services";
import { Moment } from "moment";
import { ObservableMap, transaction } from "mobx";

/**
 * Data Provider to Fetch Items in DateRange for the Current Team
 */
export abstract class ItemsInDateRangeDataProvider<T extends BaseShiftEntity | INoteEntity> extends CurrentTeamDataProvider<IDataWithSyncState<T[]>> {

    protected tenantId: string;
    protected sessionId: string;
    protected fetchStartTime: Moment;
    protected fetchEndTime: Moment;
    private itemIdsReadFromDb: string[];
    private syncStateReadFromDb: IItemsInTimeRangeSyncState;
    private dontClearCache: boolean;

    // progressive loading support
    private itemIdsReadFromNetwork: Set<string>;

    constructor(tenantId: string, teamId: string, sessionId: string, fetchStartTime: Moment, fetchEndTime: Moment, dontClearCache: boolean) {
        super(teamId);
        this.tenantId = tenantId;
        this.sessionId = sessionId;
        this.fetchStartTime = fetchStartTime;
        this.fetchEndTime = fetchEndTime;
        this.dontClearCache = dontClearCache;
    }

    /**
     * Fetch all items that are in memory
     */
    abstract getAllItemsFromMemory(): ObservableMap<string, T>;

    /**
     * Fetch the sync start time from memory
     */
    abstract getSyncCacheStartTimeFromMemory(): Moment;

    /**
     * Fetch the sync end time from memory
     */
    abstract getSyncStateEndTimeFromMemory(): Moment;

    /**
     * Set sync cache start time in memory
     */
    abstract setSyncCacheStartTimeInMemory(syncStateStartTime: Moment): void;

    /**
     * Set sync cache end time in memory
     */
    abstract setSyncStateEndTimeInMemory(syncStateEndTime: Moment): void;

    /**
     * Set list of items in memory
     */
    abstract setItemsInMemory(items: T[]): void;

    /**
     * Hard delete items in memory
     */
    abstract hardDeleteItemsInMemory(items: T[]): void;

    /**
     * Reset the items and sync state in memory
     */
    abstract resetDataInMemory(): void;

    /**
     * Set Items and the sync state timerange in the Database
     */
    abstract setItemsAndSyncStateInDatabase(updatedItems: T[], deletedItemIds: string[], syncStateStartTimestamp: number, syncStateEndTimestamp: number): Promise<void>;

    /**
     * Get items in the fetch time range from the Database
     */
    abstract getItemsInDateRangeFromDatabase(): Promise<T[]>;

    /**
     * Get sync state from the Database
     */
    abstract getItemsSyncStateFromDatabase(): Promise<IItemsInTimeRangeSyncState>;

    /**
     * Get list of items within time range from network
     */
    abstract getItemsInDateRangeFromNetwork(): Promise<T[]>;

    /**
     * Return true if this item matches the data filter.
     * @param item the shift
     * @param dataFilter data filter
     */
    abstract isItemInDataFilter(item: T, dataFilter: DataFilter): boolean;

    public setProgressiveItems(items: string[]): void {
        if (!this.itemIdsReadFromNetwork) {
            this.itemIdsReadFromNetwork = new Set(items);
        } else {
            if (items && items.length) {
                for (let i = 0; i < items.length; i++) {
                    this.itemIdsReadFromNetwork.add(items[i]);
                }
            }
        }
    }

    // used with unit tests
    public get progressiveItemCount() {
        return this.itemIdsReadFromNetwork ? this.itemIdsReadFromNetwork.size : 0;
    }

    /**
     * Returns true if this item matches the tag data filter.
     * @param item base shift entity
     * @param dataFilter data filter
     */
    isItemInTagDataFilter(item: BaseShiftEntity, dataFilter: DataFilter): boolean {
        let isInTagFilter = false;

        if (dataFilter && item) {
            // check if this item is in the tag filter
            if (dataFilter.tagIds && dataFilter.tagIds.length && item.tagIds) {
                const filteredTags = dataFilter.tagIds;

                for (let tagIndex = 0; tagIndex < filteredTags.length; tagIndex++) {
                    const filteredTagId = filteredTags[tagIndex];
                    if (item.tagIds.indexOf(filteredTagId) !== -1) {
                        // this item is in our data filter
                        isInTagFilter = true;
                        break;
                    }
                }
            }
        }

        return isInTagFilter;
    }

    /**
     * Return data if it's found in memory (otherwise return undefined)
     */
    async getDataFromMemoryForCurrentTeam(dataFilter?: DataFilter, isInSync?: boolean): Promise<IDataWithSyncState<T[]>> {
        if (this.isFetchDateRangeWithinCacheRange()) {
            let items: Array<T> = [];
            this.getAllItemsFromMemory().forEach((item: T) => {
                if (DataInDateRangeDataProvider.isEntityInFetchRange(item, this.fetchStartTime, this.fetchEndTime)) {
                    if (dataFilter) {
                        if (!this.isItemInDataFilter(item, dataFilter)) {
                            // this shift is not in the data filter
                            return;
                        }
                    }

                    items.push(item);
                }
            });

            return {
                data: items,
                isDataInSync: !!dataFilter ? isInSync : true,
                isPartialInSync: !!dataFilter
            };
        } else {
            return undefined;
        }
    }

    /**
     * Return data if it's found in the database (otherwise return undefined)
     */
    async getDataFromDatabase(): Promise<IDataWithSyncState<T[]>> {
        const items: T[] = await this.getItemsInDateRangeFromDatabase();
        const syncState: IItemsInTimeRangeSyncState = await this.getItemsSyncStateFromDatabase();

        // Cache the IDs of the items read from the database, we'll use these when updating the DB to determine if items were deleted
        this.itemIdsReadFromDb = items ? items.map(item => item.id) : [];
        this.syncStateReadFromDb = syncState;

        if (items && syncState && syncState.syncStateStartTimestamp && syncState.syncStateEndTimestamp) {
            const isFetchRangeWithinDbSyncState = this.fetchStartTime.isSameOrAfter(syncState.syncStateStartTimestamp) && this.fetchEndTime.isSameOrBefore(syncState.syncStateEndTimestamp);
            if (items.length || isFetchRangeWithinDbSyncState) {
                // We found any item in the time range OR data in the DB is in sync for the time range requested
                return {
                    data: items,
                    isDataInSync: isFetchRangeWithinDbSyncState,
                    isPartialInSync: false
                };
            }
        }
        return undefined;
    }

    /**
     * Make an HTTP request to fetch the data from the network
     */
    async getDataFromNetwork(): Promise<IDataWithSyncState<T[]>> {
        const itemsFromNetwork: T[] = await this.getItemsInDateRangeFromNetwork();
        return {
            data: itemsFromNetwork,
            isDataInSync: true,
            isPartialInSync: false
        };
    }

    /**
     * Set data in memory
     */
    async setDataInMemoryForCurrentTeam(itemsInDateRange: IDataWithSyncState<T[]>) {
        if (!itemsInDateRange || !itemsInDateRange.data) {
            return;
        }

        let syncStateStartTime: Moment = null;
        let syncStateEndTime: Moment = null;

        let isTimeRangeContiguous: boolean = false;
        let needsToClearCacheToUpdate: boolean = false;
        let shouldUpdateCache: boolean = false;

        if ((!itemsInDateRange.isDataInSync && itemsInDateRange.isPartialInSync) || itemsInDateRange.isDataInSync) {
            // The itemsInDateRange.syncStateStartTime  and itemsInDateRange.syncStateEndTime will be valid if we fetched from network (or DB within sync range)
            syncStateStartTime = this.getSyncCacheStartTimeFromMemory() && this.getSyncCacheStartTimeFromMemory().clone();
            syncStateEndTime = this.getSyncStateEndTimeFromMemory()  && this.getSyncStateEndTimeFromMemory().clone();

            if (!syncStateStartTime || !syncStateEndTime) {
                // If there's no syncCacheTime in memory, set the fetch time from the network/db as the sync time range
                this.setSyncCacheStartTimeInMemory(this.fetchStartTime);
                this.setSyncStateEndTimeInMemory(this.fetchEndTime);
            } else {
                // check that new fetched range is contiguous with our existing cache range.
                // It's contiguous if the fetched range is within the cache range or
                // directly adjacent to it.

                isTimeRangeContiguous = DateUtils.isRangeContiguous(syncStateStartTime, syncStateEndTime, this.fetchStartTime, this.fetchEndTime);
                needsToClearCacheToUpdate = !isTimeRangeContiguous;
                shouldUpdateCache = !(needsToClearCacheToUpdate && this.dontClearCache);

                if (shouldUpdateCache) {
                    if (!isTimeRangeContiguous) {
                        // the range is not contiguous so we purge our existing items cache
                        this.resetDataInMemory();
                    }

                    // grow the paged items date range if needed
                    this.setSyncCacheStartTimeInMemory(isTimeRangeContiguous ? moment.min(syncStateStartTime, this.fetchStartTime) : this.fetchStartTime);
                    this.setSyncStateEndTimeInMemory(isTimeRangeContiguous ? moment.max(syncStateEndTime, this.fetchEndTime) : this.fetchEndTime);
                }
            }
        }

        if ((!itemsInDateRange.isDataInSync && !itemsInDateRange.isPartialInSync)
            || (!syncStateStartTime || !syncStateEndTime)
            || (!itemsInDateRange.isDataInSync && itemsInDateRange.isPartialInSync && shouldUpdateCache)) {
            // The itemsInDateRange is not in sync (e.g. fetch from DB but not in session's sync range)
            // update shifts cache for a fast render but we don't change the sync state in memory because we don't trust this data
            this.setItemsInMemory(itemsInDateRange.data);
        } else {
            if (shouldUpdateCache) {

                // detete existing items within time range have been hard deleted and are no longer in the network response
                let hardDeletedItems: T[] = [];
                const itemIdsFromNetwork = this.itemIdsReadFromNetwork || new Set(itemsInDateRange.data.map(shift => shift.id));
                this.getAllItemsFromMemory().forEach((itemInMemory: T) => {
                    if (ShiftUtils.shiftOverlapsStartsOrEndsBetween(itemInMemory, this.fetchStartTime, this.fetchEndTime, false /*includeEdges*/) && !itemIdsFromNetwork.has(itemInMemory.id)) {
                        const hardDeletedItem = ShiftUtils.markItemAsHardDeleted(itemInMemory) as T;
                        hardDeletedItems.push(hardDeletedItem);
                    }
                });

                transaction(() => {
                    // remove hard deleted shifts from memory
                    if (hardDeletedItems.length) {
                        this.hardDeleteItemsInMemory(hardDeletedItems);
                    }

                    // update shifts cache
                    this.setItemsInMemory(itemsInDateRange.data);
                });
            }
        }
    }

    /**
     * Set data in the database
     */
    async setDataInDatabase(itemsInDateRange: IDataWithSyncState<T[]>) {
        if (!itemsInDateRange || !itemsInDateRange.data) {
            return;
        }

        if (!this.itemIdsReadFromDb) {
            // If items have not yet been read from the db (e.g. for a force refresh from network)
            // read the data so we can update the db correctly (handle hard deletes and update the sync time range)
            await this.getDataFromDatabase();
        }

        // determine if any items that were previously read from the DB are now missing
        // This handles deleting shifts that were in the DB but were hard deleted on the service,
        // so we track the shift IDs when we read it from the DB and delete items we don't find in the network response.
        let deletedItemsIds: string[] = null;
        if (this.itemIdsReadFromDb && this.itemIdsReadFromDb.length) {
            const itemIdsFromNetwork = new Set(itemsInDateRange.data.map(item => item.id));
            deletedItemsIds = this.itemIdsReadFromDb.filter(itemId => !itemIdsFromNetwork.has(itemId));
        }

        // Extend the sync time range in the DB to include the fetch times
        const originalSyncStartTime = this.syncStateReadFromDb && this.syncStateReadFromDb.syncStateStartTimestamp && moment(this.syncStateReadFromDb.syncStateStartTimestamp);
        const originalSyncEndTime = this.syncStateReadFromDb && this.syncStateReadFromDb.syncStateEndTimestamp && moment(this.syncStateReadFromDb.syncStateEndTimestamp);
        const { syncStartTime, syncEndTime } = this.getNewSyncTimeRange(originalSyncStartTime, originalSyncEndTime);
        await this.setItemsAndSyncStateInDatabase(itemsInDateRange.data, deletedItemsIds, syncStartTime && syncStartTime.valueOf(), syncEndTime && syncEndTime.valueOf());
    }

    /**
     * Gets the updated sync timerange based on the original sync time and the timerange of data fetched by the provider
     * @param originalSyncStartTime Original Sync Start Time
     * @param originalSyncEndTime Original Sync End Time
     */
    private getNewSyncTimeRange(originalSyncStartTime: Moment, originalSyncEndTime: Moment) {
        if (!originalSyncStartTime || !originalSyncEndTime) {
            // If there's no original sync time, use the fetch time
            return {syncStartTime: this.fetchStartTime, syncEndTime: this.fetchEndTime};
        } else {
            const isTimeRangeContiguous = DateUtils.isRangeContiguous(originalSyncStartTime, originalSyncEndTime, this.fetchStartTime, this.fetchEndTime);
            const needsToClearCacheToUpdate: boolean = !isTimeRangeContiguous;
            const shouldUpdateCache: boolean = !(needsToClearCacheToUpdate && this.dontClearCache);
            if (shouldUpdateCache) {
                // Extend the original sync time to include the fetch times
                return {
                    syncStartTime: (isTimeRangeContiguous ? moment.min(originalSyncStartTime, this.fetchStartTime) : this.fetchStartTime),
                    syncEndTime: isTimeRangeContiguous ? moment.max(originalSyncEndTime, this.fetchEndTime) : this.fetchEndTime
                };
            } else {
                // keep the original sync time
                return {syncStartTime: originalSyncStartTime, syncEndTime: originalSyncEndTime};
            }
        }
    }

    /**
     * Make a network call to update the data
     */
    async saveDataToNetwork(itemsInDateRange: IDataWithSyncState<T[]>): Promise<IDataWithSyncState<T[]>> {
        throw new Error("IItemsInDateRangeDataProvider.saveDataToNetwork not implemented");
    }

    /**
     * Return true if the given fetch range is within the cache range of the shift stores.
     */
    public isFetchDateRangeWithinCacheRange(): boolean {
        const syncStateStartTime = this.getSyncCacheStartTimeFromMemory();
        const syncStateEndTime = this.getSyncStateEndTimeFromMemory();
        return (!!syncStateStartTime && !!syncStateEndTime && this.fetchStartTime.isSameOrAfter(syncStateStartTime) && this.fetchEndTime.isSameOrBefore(syncStateEndTime));
    }

    /**
     * Whether to skip refreshing data from network if it was only in the database and not in memory
     * This method gets called when we don't find the data in memory, and find something in the database.
     * Because we can't always trust that the data from the database was in-sync and not missing changes from the service,
     * this method allows the provider to inspect the state of data from the db and
     * determine if we need to make a network call to refresh this data asynchronously.
     */
    public async skipRefreshFromNetworkIfNotInMemory(dataFromDatabase: IDataWithSyncState<T[]>): Promise<boolean> {
        if (!ECSConfigService.isECSFeatureEnabled(ECSConfigKey.EnableSyncStateInIndexedDb)) {
            // force refresh from network if the sync state isn't allowed to be stored in IndexedDb
            return false;
        }
        // check if the data is in sync
        return dataFromDatabase && dataFromDatabase.isDataInSync;
    }
}