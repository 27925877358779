
import { action } from "satcheljs";

import { SetUserPictureUrlAction } from "./SetUserPictureUrlAction";

type SetUserPictureUrlActionSignature = (userId: string, pictureUrl: string) => SetUserPictureUrlAction;

/**
 * Sets the user profile picture URL.
 */
export const setUserPictureUrl = action<SetUserPictureUrlAction, SetUserPictureUrlActionSignature>(
    "SET_USER_PICTURE_URL",
    (userId: string, pictureUrl: string) => ({ pictureUrl, userId })
);
