import ChangeUtils from "sh-application/utility/ChangeUtils";
import clearSelectedCells from "./lib/mutators/clearSelectedCells";
import DateUtils from "sh-application/utility/DateUtils";
import gridSelectionViewStateStore from "./lib/store/store";
import InstrumentationUtils from "sh-application/utility/InstrumentationUtils";
import numCellsSelected from "./lib/computed/numCellsSelected";
import setGlobalMessages from "sh-application/actions/setGlobalMessages";
import setGlobalSpinnerMessageState from "sh-application/mutatorActions/setGlobalSpinnerMessageState";
import setSelectedCells from "./lib/mutators/setSelectedCells";
import ShiftUtils from "sh-application/utility/ShiftUtils";
import StringsStore from "sh-strings/store";
import StringUtils from "sh-application/utility/StringUtils";
import { appViewState } from "../../../store";
import {
    ChangeEntity,
    ChangeSource,
    IBaseShiftEntity,
    IBulkShiftResponseEntity,
    IChangeEntity,
    IMemberEntity,
    IOpenShiftEntity,
    IShiftEntity,
    IShiftResponseMultipleEntity,
    ISubshiftEntity,
    IUniqueShiftEntity,
    OpenShiftEntity,
    ScheduleCalendarType,
    ScheduleCalendarTypes,
    ShiftEntity,
    ShiftStates,
    SubshiftEntity
} from "sh-models";
import { DUMMY_SELECTION_CELL_KEY } from "sh-application/../StaffHubConstants";
import { fireAccessibilityAlert } from "sh-application/components/accessibilityAlert";
import { FlexiRowCellSettings } from "sh-flexigrid";
import { getGenericEventPropertiesObject, InstrumentationEventPropertyInterface } from "sh-instrumentation";
import { InstrumentationService, ShiftDataService } from "sh-services";
import { InstrumentScheduleEventFunction } from "sh-application/components/schedules/Schedules";
import { IObjectWithKey, MessageBarType, Selection } from "@fluentui/react";
import { Moment, unitOfTime } from "moment";
import { ScheduleCellContentsInfo, ScheduleCellType } from "sh-application/components/schedules/lib";
import { StaffHubHttpError } from "sh-application";
import { TeamStore } from "sh-team-store";
import { trace } from "owa-trace";
import { trackChanges } from "sh-change-store";

export type ProcessShiftCRUDListsFunction = (
    shiftsToAdd: IBaseShiftEntity[],
    shiftsToUpdate: IBaseShiftEntity[],
    shiftsToDelete: IBaseShiftEntity[],
    originalUpdatedShifts: IBaseShiftEntity[],
    onServiceError: (error: StaffHubHttpError) => void,
    changeSource: ChangeSource,
    doTrackChanges?: boolean) => Promise<boolean>;

export interface ScheduleGridSelectionSettings {
    numColumnsPerRow: number;                                               // Total number of columns per row in the current view of the Schedule Grid
    viewEnd: Moment;                                                        // End of the current view
    scheduleCalendarType: ScheduleCalendarType;                             // The current view for the scheduler
    onSelectionChanged?: () => void;                                        // Callback for when the selection has been changed
    getSelectionKey: (item: IObjectWithKey, index?: number) => string;      // Callback for computing the selection key for the given schedule cell item
    roundMomentToScheduleCellDurationFunction: RoundMomentToScheduleCellDurationFunction;
}

export type RoundMomentToScheduleCellDurationFunction = (moment: Moment, isStartTime: boolean) => Moment;

/**
 * Move selection direction enum
 */
export enum MoveSelectionDirection {
    Up = 0,
    Down = 1,
    Left = 2,
    Right = 3
}

/**
 * SelectionBox represents a rectangular selection area
 */
interface SelectionBox {
    rowRange: SelectionIndexRange;
    columnRange: SelectionIndexRange;
}

/**
 * Selection cell index range
 */
interface SelectionIndexRange {
    firstIndex: number;
    lastIndex: number;
}

/**
 * Info about the cell range info for selected rows
 */
interface SelectedRowRangeInfo {
    columnRange: SelectionIndexRange;               // Overall selected columns range for the row
    firstCellColumnRange: SelectionIndexRange;      // Cell range of the first cell in the selected row
    lastCellColumnRange: SelectionIndexRange;       // Cell range of the last cell in the selected row
}

/**
 * Range info for the cells in a selection
 */
interface SelectedCellRangeInfo {
    rowRange: SelectionIndexRange;                              // Range of rows in the selection
    columnRangesForRows: Map<number, SelectedRowRangeInfo>;     // Column ranges for the rows in a selection. Indexed by row index.
}

/**
 * ScheduleGrid selection handling
 *
 * Maintains selection state for a schedule grid component, and has support for performing selection-related
 * behaviors such as copy paste and keyboard navigation.
 *
 * To add selection support for a ScheduleGrid component, instantiate a ScheduleGridSelection and hookup calls such as
 * pasteGridSelection() for paste, deleteSelection(), and moveSelection() for performing actions with the current selection.
 */
export class ScheduleGridSelection {
    private _settings: ScheduleGridSelectionSettings;
    private _officeFabricSelection: Selection;
    private _clipboardGridSelection: FlexiRowCellSettings[] = [];   // Cached list of cells comprising the copied selection
    private _clipboardViewTotalColumns: number;                           // Cached number indicating the total number of columns in the view the copied selection originated in
    private _selectionAnchorCell: FlexiRowCellSettings;
    private _cachedSelectionIndices: number[] = [];
    private _strings: Map<string, string>;

    constructor(settings: ScheduleGridSelectionSettings) {
        this.setSettings(settings);

        this._officeFabricSelection = new Selection({
            onSelectionChanged: this.selectionChangedHandler,
            getKey: settings.getSelectionKey
        });

        this._selectionAnchorCell = null;

        // When the selection is initialized, we clear the map of selected cells
        clearSelectedCells();
        this._strings = StringsStore().registeredStringModules.get("schedulePage").strings;
    }

    /**
     * Clears the selected grid cells. Does not clear the stored clibpoard data, as this clear() function is used
     * to clear the currently selected cells when switching date ranges, but in those cases we want to preserve the clipboard
     * data so that users can paste across different date ranges/views. Does not call directly into our clearSelectedCells()
     * action. The action will be called by the selectionChangedHandler()
     */
    public clear() {
        this.clearSelectionIndicesCache();
        const officeFabricSelection = this.getOfficeFabricSelection();
        if (officeFabricSelection) {
            officeFabricSelection.setAllSelected(false);
        }
    }

    /**
     * Sets the stored settomgs for this class.
     * @param settings
     */
    public setSettings(settings: ScheduleGridSelectionSettings) {
        this._settings = settings;
    }

    /**
     * Returns the number of columns per row.
     * Used for trying to track down a difficult bug.
     */
    public getColumnsPerRow(): number {
        if (this._settings && this._settings.numColumnsPerRow) {
            return this._settings.numColumnsPerRow;
        }

        return 0;
    }

    /**
     * Sets the clipboard grid selection. May also truncate the shifts in the selection to fit into the current view
     * @param clipboardViewTotalColumns total num columns in clipboard view
     * @param limitToView if true, truncates shift to fit into view
     * @param viewStart
     * @param viewEnd
     */
    public copyGridSelection(clipboardViewTotalColumns: number, limitToView: boolean = false, viewStart?: Moment, viewEnd?: Moment) {
        const officeFabricSelection = this.getOfficeFabricSelection();
        if (officeFabricSelection) {
            this._clipboardGridSelection = officeFabricSelection.getSelection();
            // We may want to limit the considered portion of the shift, such as in copy paste of overnight shifts in day view, or in copy paste of multi-day time off shifts
            // in week and month view. If we don't limit the considered portion, users may get unexpectedly long shifts after they paste what appears to be a small chunk of a shift.
            // Regardless of the limitToView param, time off shifts that span multiple days (as opposed to overnight shifts) will always be truncated (desired behavior from design)
            // Note: overnight shifts from before the view will be truncated for day view, where limitToView is true. In week and month view such shifts are not displayed.
            for (let i = 0; i < this._clipboardGridSelection.length; i++) {
                const cellSettings: FlexiRowCellSettings = this._clipboardGridSelection[i];
                if (ScheduleGridSelection.doesCellSettingContainValidShift(cellSettings)) {
                    const clipboardShift: IBaseShiftEntity = cellSettings.cellContentsItem.shift;
                    if ((limitToView && viewStart && viewEnd) || (ShiftUtils.isTimeOffEvent(clipboardShift) && ShiftUtils.isMultiDayShift(clipboardShift))) {
                        let shiftToAdjust: IBaseShiftEntity = ShiftUtils.isOpenShift(clipboardShift) ? OpenShiftEntity.clone(clipboardShift as IOpenShiftEntity) : ShiftEntity.clone(clipboardShift as IShiftEntity);
                        // this function will only adjust the shift if it spills outside the provided view bounds
                        ShiftUtils.adjustShiftToFitInRange(shiftToAdjust, viewStart, viewEnd);
                        cellSettings.cellContentsItem.shift = shiftToAdjust;
                    }
                }
            }
            this._clipboardViewTotalColumns = clipboardViewTotalColumns;

            // Fire accessibility alert to notify screen readers of the copy action
            const numCellsCopied: number = this._clipboardGridSelection && this._clipboardGridSelection.length;
            if (numCellsCopied) {
                const formatString: string = numCellsCopied > 1 ? this._strings.get("copyAriaAlertPlural") : this._strings.get("copyAriaAlertSingular");
                fireAccessibilityAlert(formatString.format(String(numCellsCopied)));
            }
        }
    }

    /**
     * Returns the clipboard grid selection.
     */
    public getClipboardGridSelection(): FlexiRowCellSettings[] {
        return this._clipboardGridSelection;
    }

    /**
     * Get the Office Fabric Selection object for the schedule grid
     */
    public getOfficeFabricSelection(): Selection {
        return this._officeFabricSelection;
    }

    /**
     * Clear out the list of cached, selected indices
     */
    public clearSelectionIndicesCache() {
        this._cachedSelectionIndices = [];
    }

    /**
     * Cache the data selection indices included in the current selection.
     * @param selection
     */
    private cacheSelectionIndices(selection: FlexiRowCellSettings[]) {
        this.clearSelectionIndicesCache();
        selection.map((cellSetting: FlexiRowCellSettings) => {
            this._cachedSelectionIndices.push(cellSetting.dataSelectionIndex);
            if (cellSetting.cellContentsItem && cellSetting.cellContentsItem.cellUnitSize) {
                for (let i = 1; i < cellSetting.cellContentsItem.cellUnitSize; i++) {
                    this._cachedSelectionIndices.push(cellSetting.dataSelectionIndex + i);
                }
            }
        });
    }

    /**
     * Returns the list of cached selected indices
     */
    public getSelectionIndicesCache(): number[] {
        return this._cachedSelectionIndices;
    }

    /**
     * Returns true if the given cell key is currently selected
     * @param key
     */
    public static isCellKeySelected(key: string): boolean {
        return !!gridSelectionViewStateStore().selectedCellKeys.get(key);
    }

    /**
     * Returns the number of selected cells
     */
    public static getNumCellsSelected(): number {
        return numCellsSelected.get();
    }

    /**
     * Returns a new selection, where the selection has extended downwards by adding rows. May not add enough to reach the number of desired rows if the bottom
     * of the grid is hit.
     * @param selection
     * @param desiredRows
     * @param numColumnsPerRow
     * @param officeFabricSelection
     */
    private extendSelectionRows(selection: FlexiRowCellSettings[], desiredRows: number, numColumnsPerRow: number, officeFabricSelection: Selection): FlexiRowCellSettings[] {
        let extendedSelection: FlexiRowCellSettings[] = [];
        const orderedSelectableCells: FlexiRowCellSettings[] = ScheduleGridSelection.filterOutDummySelectableItems(officeFabricSelection.getItems());

        // Calculate new row selection range
        const existingSelectionRows: FlexiRowCellSettings[][] = ScheduleGridSelection.splitSelectionIntoRows(selection, numColumnsPerRow);
        const numRowsInSelection: number = existingSelectionRows.length;
        const numRowsToAdd: number = desiredRows - numRowsInSelection;

        // Calculate column selection range
        const firstRow: FlexiRowCellSettings[] = existingSelectionRows[0];
        const firstCellInFirstRow: FlexiRowCellSettings = firstRow[0];
        const lastCellInFirstRow: FlexiRowCellSettings = firstRow[firstRow.length - 1];
        const firstCellInFirstRowColIdxVal: number = firstCellInFirstRow.cellContentsItem.colIdxVal;
        const lastCellInFirstRowColIdxVal: number = lastCellInFirstRow.cellContentsItem.colIdxVal;
        const firstRowIndex: number = ScheduleGridSelection.getCellRowFromIndex(firstCellInFirstRowColIdxVal, numColumnsPerRow);
        const firstRowStartCellColumn: number = ScheduleGridSelection.getCellColumnFromIndex(firstCellInFirstRowColIdxVal, numColumnsPerRow);
        const firstRowEndCellColumn: number = ScheduleGridSelection.getCellColumnFromIndex(lastCellInFirstRowColIdxVal, numColumnsPerRow);

        const updatedSelectionBox: SelectionBox = {
            rowRange: {
                firstIndex: firstRowIndex,
                lastIndex: firstRowIndex + numRowsToAdd
            },
            columnRange: {
                firstIndex: firstRowStartCellColumn,
                lastIndex: firstRowEndCellColumn
            }
        };

        // Repopulate the selection with schedule cells that intersect the updated selection box.
        orderedSelectableCells.forEach((selectableCell: FlexiRowCellSettings) => {
            let shouldSelectCell: boolean = this.isCellOverlapWithSelectionBox(selectableCell, updatedSelectionBox);
            if (shouldSelectCell) {
                extendedSelection.push(selectableCell);
            }
        });

        return extendedSelection;
    }

    /**
     * Paste over the current grid selection
     *
     * @param onServiceError callback for handling error responses for Service API calls
     * @param includeActivities if true, pasted shifts will include the activities from the source
     */
    public pasteGridSelection(
        processShiftCRUDLists: ProcessShiftCRUDListsFunction,
        onServiceError: (error: StaffHubHttpError) => void,
        isKeyboardShortcut: boolean,
        instrumentScheduleEvent: InstrumentScheduleEventFunction,
        includeActivities: boolean): Promise<boolean> {
        let officeFabricSelection = this.getOfficeFabricSelection();
        let destSelection: FlexiRowCellSettings[] = officeFabricSelection ? ScheduleGridSelection.filterOutDummySelectableItems(officeFabricSelection.getSelection()) : null;
        const numCellsSelected: number = destSelection ? destSelection.length : 0;
        let orderedSelectableCells: FlexiRowCellSettings[] = officeFabricSelection ? ScheduleGridSelection.filterOutDummySelectableItems(officeFabricSelection.getItems()) : null;
        const sourceSelection: FlexiRowCellSettings[] = this.getClipboardGridSelection();

        // Only perform paste if both the source and destination selections aren't empty
        if (destSelection && destSelection.length && sourceSelection && sourceSelection.length && this._clipboardViewTotalColumns) {
            // Keep a list of added, updated, and deleted shifts to modify at the end of paste logic.
            let shiftsToAdd: IShiftEntity[] = [];
            let shiftsToUpdate: IShiftEntity[] = [];
            let originalShifts: IShiftEntity[] = [];
            let shiftsToDelete: IShiftEntity[] = [];

            // Determine number of rows in the source selection and in the destination selection. The number of rows that will be
            // attempted to paste is whichever is greater
            const numRowsInSource: number = ScheduleGridSelection.getNumRowsInSelection(sourceSelection, this._clipboardViewTotalColumns);
            let numRowsInDestination: number = ScheduleGridSelection.getNumRowsInSelection(destSelection, this.getColumnsPerRow());
            let numRowsToPaste: number = Math.max(numRowsInSource, numRowsInDestination);

            // If the number of rows in the destination selection is less than in the source, we will extend the destination selection
            // to include the same number of rows
            if (numRowsToPaste > numRowsInDestination) {
                destSelection = this.extendSelectionRows(destSelection, numRowsToPaste, this.getColumnsPerRow(), officeFabricSelection);
                numRowsInDestination = ScheduleGridSelection.getNumRowsInSelection(destSelection, this.getColumnsPerRow());
                numRowsToPaste = numRowsInDestination;
            }

            // Split the selections up into rows so that we can perform pastes row by row
            const sourceSelectionRows: FlexiRowCellSettings[][] = ScheduleGridSelection.splitSelectionIntoRows(sourceSelection, this._clipboardViewTotalColumns);
            const destinationSelectionRows: FlexiRowCellSettings[][] = ScheduleGridSelection.splitSelectionIntoRows(destSelection, this.getColumnsPerRow());

            // Examine the number of rows in the source selection and the destinationSelection
            // - Paste the source selection rows over the destination selection rows in a repeated, linear manner.
            // - Rows can overflow past the selected destination rows, which are expanded in extendSelectionRows()
            //   Ex:   1 source row,  4 destination rows ->  1 1 1 1 1,  2 source rows, 4 destination rows  ->  1 2 1 2
            //   Ex:   2 source rows, 5 destination rows  ->  1 2 1 2 1,  3 source rows, 5 destination rows ->  1 2 3 1 2
            //   Ex:   5 source rows, 2 destination rows  ->  1 2 3 4 5

            // Traverse rows in the source selection, pasting the appropriate row from the clipboard onto each destination row
            for (let i = 0; i < numRowsToPaste; i++) {
                const sourceRow = sourceSelectionRows[i % numRowsInSource];
                const destinationRow = destinationSelectionRows[i % numRowsInDestination];
                this.pasteClipboardSelectionOntoDestinationSelection(
                    orderedSelectableCells,
                    sourceRow,
                    destinationRow,
                    shiftsToAdd,
                    shiftsToUpdate,
                    originalShifts,
                    shiftsToDelete,
                    onServiceError,
                    includeActivities);
            }

            // Save the current selection indices so we can restore the current selection after the shift changes have been made.
            // This works around an issue where the selection gets cleared after performing schedule changes.
            this.cacheSelectionIndices(destSelection);

            this.instrumentPaste(shiftsToAdd, originalShifts /* preUpdatedShifts */, shiftsToUpdate /* postUpdatedShifts */, shiftsToDelete, numCellsSelected, isKeyboardShortcut, instrumentScheduleEvent);

            // Fire accessibility alert to notify screen readers of the paste action
            const numAdds: number = shiftsToAdd.length;
            const numUpdates: number = shiftsToUpdate.length;
            const numDeletes: number = shiftsToDelete.length;
            if (numAdds || numUpdates || numDeletes) {
                const formatString: string = this._strings.get("pasteAriaAlertFormatString");
                // only include the alert fragments for add/update/delete if those actions happened, i.e. those arrays have length > 0
                const addsFragmentString: string =
                    numAdds ?
                        StringUtils.usePluralForm(numAdds) ? this._strings.get("pasteAddedShiftsAriaAlertFragmentPlural").format(String(numAdds)) : this._strings.get("pasteAddedShiftsAriaAlertFragmentSingular").format(String(numAdds))
                            :
                        "";
                const updatesFragmentString: string =
                    numUpdates ?
                        StringUtils.usePluralForm(numUpdates) ? this._strings.get("pasteUpdatedShiftsAriaAlertFragmentPlural").format(String(numUpdates)) : this._strings.get("pasteUpdatedShiftsAriaAlertFragmentSingular").format(String(numUpdates))
                            :
                        "";
                const deletesFragmentString: string =
                    numDeletes ?
                        StringUtils.usePluralForm(numDeletes) ? this._strings.get("pasteDeletedShiftsAriaAlertFragmentPlural").format(String(numDeletes)) : this._strings.get("pasteDeletedShiftsAriaAlertFragmentSingular").format(String(numDeletes))
                            :
                        "";
                const alertString: string = formatString.format(addsFragmentString, updatesFragmentString, deletesFragmentString);
                fireAccessibilityAlert(alertString);
            }
            return processShiftCRUDLists(shiftsToAdd, shiftsToUpdate, shiftsToDelete, originalShifts, onServiceError, ChangeSource.Paste);
        }
    }

    /**
     * Receives lists of shifts to add, update, and delete. Parses these into lists of assigned and open shifts and uses the appropriate API
     * for each. Also receives a list of the original, pre update lists. Tracks these changes for undo and redo unless
     * doTrackChanges is false
     * @param shiftsToAdd
     * @param shiftsToUpdate
     * @param shiftsToDelete
     * @param originalUpdateShifts
     * @param onServiceError
     * @param changeSource
     * @returns Promise that resolves with true if shift changes are successful, and false if an error occurred
     */
    public processShiftCRUDLists: ProcessShiftCRUDListsFunction = async (
        shiftsToAdd: IBaseShiftEntity[],
        shiftsToUpdate: IBaseShiftEntity[],
        shiftsToDelete: IBaseShiftEntity[],
        originalUpdatedShifts: IBaseShiftEntity[],
        onServiceError: (error: StaffHubHttpError) => void,
        changeSource: ChangeSource,
        doTrackChanges: boolean = true): Promise<boolean> => {
        // Build list of add, update, and delete promises
        let promises: Array<Promise<IChangeEntity[]>> = new Array<Promise<IChangeEntity[]>>();

        // for workforce integration, we display a blocking global spinner and message while performing CRUD operations
        let globalSpinnerMessage = "";
        const panelStrings = StringsStore().registeredStringModules.get("addEditShiftTimeOffPanelFullPage").strings;

        // Shifts that meant to be pasted were added to the shiftsToAdd list. Add them all now.
        if (shiftsToAdd && shiftsToAdd.length) {
            globalSpinnerMessage = panelStrings.get("savingSpinnerDesc");

            const tenantId: string = shiftsToAdd[0].tenantId;
            const teamId: string = shiftsToAdd[0].teamId;

            // Both open shifts and regular shifts have been added to this list. We split them into separate arrays and make separate API calls to handle them
            const { assignedShifts, openShifts } = ShiftUtils.splitShiftListIntoOpenAndAssigned(shiftsToAdd);
            if ((openShifts && openShifts.length) && (assignedShifts && !assignedShifts.length)) {
                // we only have open shifts in this operation
                globalSpinnerMessage = panelStrings.get("savingOpenShiftSpinnerDesc");
            }

            if ((assignedShifts && assignedShifts.length) || (openShifts && openShifts.length)) {
                promises.push(
                    ShiftDataService.bulkAddShifts(tenantId, teamId, assignedShifts, openShifts, true /* optimistic */)
                        .then((addedShiftsAndAlerts: IBulkShiftResponseEntity) => {
                            const addedShifts: IShiftEntity[] = addedShiftsAndAlerts.shifts;
                            const addedOpenShifts: IOpenShiftEntity[] = addedShiftsAndAlerts.openShifts;
                            if (addedShiftsAndAlerts.alerts && addedShiftsAndAlerts.alerts.length > 0) {
                                // display rule violations
                                setGlobalMessages(appViewState().globalMessageViewState, addedShiftsAndAlerts.alerts, MessageBarType.error, null /* action button title */, null /* action button callback */, false /* auto dismiss */, true /* is multiline */);
                            }
                            return [new ChangeEntity(null, addedShifts, changeSource), new ChangeEntity(null, addedOpenShifts, changeSource, ChangeUtils.getRevertAddOpenShiftsTask(tenantId, teamId, addedOpenShifts, changeSource))];
                        })
                );
            }
        }

        if (shiftsToUpdate && shiftsToUpdate.length) {
            globalSpinnerMessage = panelStrings.get("savingSpinnerDesc");

            const tenantId: string = shiftsToUpdate[0].tenantId;
            const teamId: string = shiftsToUpdate[0].teamId;

            // Both open shifts and regular shifts have been added to this list. We split them into separate arrays and make separate API calls to handle them
            const { assignedShifts, openShifts } = ShiftUtils.splitShiftListIntoOpenAndAssigned(shiftsToUpdate);
            if ((openShifts && openShifts.length) && (assignedShifts && !assignedShifts.length)) {
                // we only have open shifts in this operation
                globalSpinnerMessage = panelStrings.get("savingOpenShiftSpinnerDesc");
            }

            // We need to handle the list of original shifts similarly
            const splitLists = ShiftUtils.splitShiftListIntoOpenAndAssigned(originalUpdatedShifts);
            const originalAssignedShifts: IShiftEntity[] = splitLists.assignedShifts;
            const originalOpenShifts: IOpenShiftEntity[] = splitLists.openShifts;

            if (assignedShifts && assignedShifts.length) {
                promises.push(
                    ShiftDataService.updateShifts(assignedShifts, true /* optimistic */)
                        .then((updatedShifts: IShiftResponseMultipleEntity) => {
                            if (updatedShifts.alerts && updatedShifts.alerts.length > 0) {
                                // display rule violations
                                setGlobalMessages(appViewState().globalMessageViewState, updatedShifts.alerts, MessageBarType.error, null /* action button title */, null /* action button callback */, false /* auto dismiss */, true /* is multiline */);
                            }
                            return [new ChangeEntity(originalAssignedShifts, updatedShifts.shifts, changeSource)];
                        })
                );
            }
            if (openShifts && openShifts.length) {
                promises.push(
                    ShiftDataService.updateOpenShifts(tenantId, teamId, openShifts, true /* optimistic */)
                        .then((updatedOpenShifts: IOpenShiftEntity[]) => {
                            return [new ChangeEntity(originalOpenShifts, updatedOpenShifts, changeSource, ChangeUtils.getRevertUpdateOpenShiftsTask(tenantId, teamId, originalOpenShifts, updatedOpenShifts, changeSource))];
                        })
                );
            }
        }

        if (shiftsToDelete && shiftsToDelete.length) {
            globalSpinnerMessage = panelStrings.get("deletingSpinnerDesc");

            for (let k = 0; k < shiftsToDelete.length; k++) {
                this.logActivityDeletionForShift(shiftsToDelete[k]);
            }

            const tenantId: string = shiftsToDelete[0].tenantId;
            const teamId: string = shiftsToDelete[0].teamId;

            // Both open shifts and regular shifts have been added to this list. We split them into separate arrays and make separate API calls to handle them
            const { assignedShifts, openShifts } = ShiftUtils.splitShiftListIntoOpenAndAssigned(shiftsToDelete);

            if (assignedShifts && assignedShifts.length) {
                promises.push(
                    ShiftDataService.deleteShifts(assignedShifts, true /* optimistic */)
                        .then((deletedShifts: IShiftResponseMultipleEntity) => {
                            if (deletedShifts.alerts && deletedShifts.alerts.length > 0) {
                                // display rule violations
                                setGlobalMessages(appViewState().globalMessageViewState, deletedShifts.alerts, MessageBarType.error, null /* action button title */, null /* action button callback */, false /* auto dismiss */, true /* is multiline */);
                            }
                            return [new ChangeEntity(deletedShifts.shifts, null, changeSource)];
                        })
                );
            }
            if (openShifts && openShifts.length) {
                promises.push(
                    ShiftDataService.deleteOpenShifts(tenantId, teamId, openShifts, true /* optimistic */)
                        .then((deletedOpenShifts: IOpenShiftEntity[]) => {
                            return [new ChangeEntity(deletedOpenShifts, null, changeSource, ChangeUtils.getRevertDeleteOpenShiftsTask(tenantId, teamId, deletedOpenShifts, changeSource))];
                        })
                );
            }
        }

        // note - the global spinner is only visible when workforce integration is enabled for this team
        if (globalSpinnerMessage) {
            setGlobalSpinnerMessageState(globalSpinnerMessage);
        }

        // wait for all actions to finish before tracking them as one undoable set of changes
        return Promise.all(promises)
            .then((results: IChangeEntity[][]) => {
                if (doTrackChanges && results && results.length) {
                    // Flatten list of change lists
                    trackChanges(results.reduce((accumulator: IChangeEntity[], currentChangeList: IChangeEntity[]) => {
                        return accumulator.concat(currentChangeList);
                    }));
                }
                return true;
            })
            .catch((error: StaffHubHttpError) => {
                let errorMessage = error && error.staffHubTopLevelErrorMessage ? error.staffHubTopLevelErrorMessage : "error";
                trace.warn("processShiftCRUDLists failed: " + errorMessage);
                onServiceError(error);
                return false;
            }).finally(() => setGlobalSpinnerMessageState("")); // make sure any global spinner messages are cleared
    }

    /**
     * Given the number of cols in a row and the selection index of cell, returns the zero-indexed row that the cell is in.
     * @param idx
     * @param totalColumns
     */
    private static getCellRowFromIndex(idx: number, totalColumns: number): number {
        let row = 0;
        if (totalColumns > 0) {
            row = Math.floor(idx / totalColumns);
        }
        return row;
    }

    /**
     * Return the column index for a specified schedule cell, indexed by the cell's overall index
     * @param idx
     * @param totalColumns
     */
    private static getCellColumnFromIndex(idx: number, totalColumns: number): number {
        let column = 0;
        if (totalColumns > 0) {
            column = idx % totalColumns;
        }
        return column;
    }

    /**
     * Given a list of FlexiRowCellSettings and the number of cols in a row, returns the number of rows in the selection.
     * Assumes the selection parameter is in data selection index order (Fabric controls support this), and that the selection is continuous.
     * @param selection
     * @param totalColumns
     */
    private static getNumRowsInSelection(selection: FlexiRowCellSettings[], totalColumns: number): number {
        let numRows = 0;
        if (selection && selection.length && totalColumns) {
            const startRow = ScheduleGridSelection.getCellRowFromIndex(selection[0].cellContentsItem.colIdxVal, totalColumns);
            const endRow = ScheduleGridSelection.getCellRowFromIndex(selection[selection.length - 1].cellContentsItem.colIdxVal, totalColumns);
            numRows = endRow - startRow + 1; // start at row 1, end on row 1 should return 1. start on row 5 end on row 7 should return three.
        }

        return numRows;
    }

    /**
     * Split selection array into an array of arrays, where each sub array is composed of the selection items for a different row in the schedule grid
     * @param selection
     * @param totalColumns
     */
    private static splitSelectionIntoRows(selection: FlexiRowCellSettings[], totalColumns: number): FlexiRowCellSettings[][] {
        let selectionRows: FlexiRowCellSettings[][] = [[]];
        if (selection && selection.length && totalColumns) {
            let selectionClone = selection.slice();
            let currActualRowIdx = ScheduleGridSelection.getCellRowFromIndex(selectionClone[0].cellContentsItem.colIdxVal, totalColumns); // actual idx in grid
            let currPlacementIdx = 0; // idx to place items into selectionRows array
            while (selectionClone.length) {
                const item = selectionClone.shift();
                const rowIdx = ScheduleGridSelection.getCellRowFromIndex(item.cellContentsItem.colIdxVal, totalColumns);
                if (rowIdx !== currActualRowIdx) {
                    currPlacementIdx++;
                    selectionRows.push([]);
                }
                selectionRows[currPlacementIdx].push(item);
                currActualRowIdx = rowIdx;
            }
        }

        return selectionRows;
    }

    /**
     * Handler for Selection changed callback from Office Fabric's Selection object
     */
    private selectionChangedHandler = () => {
        // Check if the selection anchor needs to updated based on the updated selection
        this.maybeUpdateSelectionAnchor();

        if (this._settings.onSelectionChanged) {
            this._settings.onSelectionChanged();
        }
        // Clear the cached indices so that they don't perpetually get reselected. At this point their values have already been added to the selection
        this.clearSelectionIndicesCache();

        // Refresh the UX to reflect the updated selection state
        const selectedCells: FlexiRowCellSettings[] = this.getOfficeFabricSelection().getSelection();
        const selectedKeys: string[] = selectedCells.map((cell: FlexiRowCellSettings) => cell.cellElementKey);
        setSelectedCells(selectedKeys, true);
    }

    /**
     * Update the selection anchor based on the current selection state
     */
    private maybeUpdateSelectionAnchor() {
        let officeFabricSelection = this.getOfficeFabricSelection();
        if (officeFabricSelection) {
            const selectionCount = officeFabricSelection.getSelectedCount();
            if (selectionCount === 0) {
                // Clear the anchor if the selection is empty
                this._selectionAnchorCell = null;
            } else if (selectionCount === 1) {
                // Whenever the user starts a new selection via marquee drag or keyboard navigation, we'll start with
                // a single selection item, which we need to remember as the anchor for the new selection.
                let selectionItems = officeFabricSelection.getSelection();
                this._selectionAnchorCell = (selectionItems && selectionItems.length) ? selectionItems[0] : null;

                // Set focus on the new anchor.
                // This way the selection and anchor are in sync so that using both tab and arrow navigation interchangeably
                // behaves more intuitively.
                if (this._selectionAnchorCell && this._selectionAnchorCell.cellElementId) {
                    const selectionElement = document.getElementById(this._selectionAnchorCell.cellElementId);
                    if (selectionElement) {
                        selectionElement.focus();
                    }
                }
            }
        } else {
            this._selectionAnchorCell = null;
        }
    }

    /**
     * Returns the item in the selection at the given index.
     * Caller should check for null return value.
     * @param selection
     * @param index
     */
    private static getItemOfSelectionAtIndex(selection: FlexiRowCellSettings[], index: number): FlexiRowCellSettings {
        let item = null;
        if (selection && selection[index]) {
            item = selection[index];
        }

        return item;
    }

    /**
     * Return either the start or the end time of the given selection item, based on getStart. Optionally rounded using optional function parameter.
     * Caller should check for null return.
     * @param item
     * @param getStart
     * @param roundingFn
     */
    private static getStartOrEndTimeOfSelectionItem(item: FlexiRowCellSettings, getStart: boolean, roundingFn?: (moment: Moment, isStartTime: boolean) => Moment): Moment {
        let time = null;
        if (item && item.cellContentsItem) {
            if (getStart) {
                time = item.cellContentsItem.startTime;
            } else {
                time = item.cellContentsItem.endTime;
            }
            if (roundingFn) {
                time = roundingFn(time, getStart /* isStartTime */);
            }
        }

        return time;
    }

    /**
     * Return the start time of the given selection item. Optionally rounded using optional function parameter.
     * Caller should check for null return.
     * @param item
     * @param roundingFn
     */
    private static getStartTimeOfSelectionItem(item: FlexiRowCellSettings, roundingFn?: (moment: Moment, isStartTime: boolean) => Moment): Moment {
        return this.getStartOrEndTimeOfSelectionItem(item, true /* getStart */, roundingFn);
    }

    /**
     * Return the end time of the given selection item. Optionally rounded using optional function parameter.
     * Caller should check for null return.
     * @param item
     * @param roundingFn
     */
    private static getEndTimeOfSelectionItem(item: FlexiRowCellSettings, roundingFn?: (moment: Moment, isStartTime: boolean) => Moment): Moment {
        return this.getStartOrEndTimeOfSelectionItem(item, false /* getStart */, roundingFn);
    }

    /**
     * Returns the startTime or endTime of the first or last item in the selection, based on the getStart parameter. Optionally rounded using optional function parameter.
     * @param selection
     * @param getStart
     * @param roundingFn
     */
    private static getStartOrEndTimeOfSelection(selection: FlexiRowCellSettings[], getStart: boolean, roundingFn?: (moment: Moment, isStartTime: boolean) => Moment): Moment {
        let time = null;
        if (selection && selection.length) {
            const index = getStart ? 0 : selection.length - 1;
            const item = this.getItemOfSelectionAtIndex(selection, index);
            if (getStart) {
                time = this.getStartTimeOfSelectionItem(item, roundingFn);
            } else {
                time = this.getEndTimeOfSelectionItem(item, roundingFn);
            }
        }

        return time;
    }

    /**
     * Return the startTime associated of the first item in the selection. Optionally rounded using optional function parameter.
     * Caller should check for null return value.
     * @param selection
     * @param roundingFn
     */
    private static getStartTimeOfSelection(selection: FlexiRowCellSettings[], roundingFn?: (moment: Moment, isStartTime: boolean) => Moment): Moment {
        return this.getStartOrEndTimeOfSelection(selection, true /* getStart */, roundingFn);
    }

    /**
     * Return the endTime of with the last item in the selection. Optionally rounded using optional function parameter.
     * Caller should check for null return value.
     * @param selection
     * @param roundingFn
     */
    private static getEndTimeOfSelection(selection: FlexiRowCellSettings[], roundingFn?: (moment: Moment, isStartTime: boolean) => Moment): Moment {
        return this.getStartOrEndTimeOfSelection(selection, false /* getStart */, roundingFn);
    }

    /**
     * Returns the duration of the selection in either days or hours. Returns difference in days if no inHours param is provided.
     * @param selection
     * @param inHours
     * @param roundingFn
     */
    private static getDurationOfSelection(selection: FlexiRowCellSettings[], inHours?: boolean, roundingFn?: (moment: Moment, isStartTime: boolean) => Moment): number {
        const selectionStart = this.getStartTimeOfSelection(selection, roundingFn);
        const selectionEnd = this.getEndTimeOfSelection(selection, roundingFn);
        return inHours ? DateUtils.getDifferenceInHoursFromMoments(selectionStart, selectionEnd) : DateUtils.getDifferenceInDaysFromMoments(selectionStart, selectionEnd);
    }

    /**
     * Returns true if the given cellSetting contains a valid shift. Deleted shifts are not valid unless the permitDeletedWithShared flag is true.
     * In that case, deleted shifts with shared changes will be considered valid.
     * @param cellSetting
     * @param permitDeletedWithShared - if true deleted shifts will not be ignored
     */
    private static doesCellSettingContainValidShift(cellSetting: FlexiRowCellSettings, permitDeletedWithShared: boolean = false): boolean {
        let contains = false;
        if (cellSetting && cellSetting.cellContentsItem && cellSetting.cellContentsItem.cellType && cellSetting.cellContentsItem.shift
            && !ShiftUtils.isTimeOffRequestEvent(cellSetting.cellContentsItem.shift)) {
            contains = cellSetting.cellContentsItem.cellType === ScheduleCellType.Shift;
            // Unless the permitDeletedWithShared flag is true, we invalidate deleted shifts
            if (!permitDeletedWithShared && ShiftUtils.isDeletedShift(cellSetting.cellContentsItem.shift) ) {
                contains = false;
            }
        }
        return contains;
    }

    /**
     * If the shift to be deleted has activites, log their deletion
     * @param shift
     */
    private logActivityDeletionForShift(shift: ShiftEntity) {
        const numActivities = shift.subshifts.length;
        if (numActivities > 0) {
            InstrumentationUtils.logActivityDeleted(this._settings.scheduleCalendarType, InstrumentationService.values.ShiftDeleted, numActivities, ShiftUtils.isOpenShift(shift));
        }
    }

    /**
     * Creates and returns an array of FlexiRowCellSettings in which the clipboard selection has been shifted to align with the destination selection
     * and repeated up to the end of the destination selection
     * @param clipboardSelection
     * @param destinationSelection
     * @param roundMomentFunction
     */
    private projectAndRepeatClipboardSelection(
        clipboardSelection: FlexiRowCellSettings[],
        destinationSelection: FlexiRowCellSettings[],
        includeActivities: boolean,
        roundMomentFunction?: RoundMomentToScheduleCellDurationFunction) {

        // Find the type of duration offset for this paste operation (day in week and month view, hour in day view)
        const durationOffsetTypeName: unitOfTime.Base = this.durationOffsetTypeName();

        // Calculate start, end, and durations for clipboard and destination rows
        const clipboardRowStart: Moment = ScheduleGridSelection.getStartTimeOfSelection(clipboardSelection, roundMomentFunction);
        const clipboardRowEnd: Moment = ScheduleGridSelection.getEndTimeOfSelection(clipboardSelection, roundMomentFunction);
        const clipboardRowDuration: number = this.getDurationDifferenceFromMoments(clipboardRowStart, clipboardRowEnd);
        const destinationRowStart: Moment = ScheduleGridSelection.getStartTimeOfSelection(destinationSelection, roundMomentFunction);
        const destinationRowEnd: Moment = ScheduleGridSelection.getEndTimeOfSelection(destinationSelection, roundMomentFunction);

        // Calculate the base offset for the shifted clipboard row. This will be used in conjunction with the # of paste iterations to
        // determine the net offset as we repeat the clipboard selection
        let baseDestinationOffsetDuration: number = this.getDurationDifferenceFromMoments(clipboardRowStart, destinationRowStart);

        // Gather data  from destination row to update pasted shifts with
        const destinationMember: IMemberEntity = destinationSelection[0].cellContentsItem.member;
        const destinationMemberId = destinationMember ? destinationMember.id : "";
        const destinationTagId: string =  destinationSelection[0].cellContentsItem.tagId;
        const tagIds = destinationTagId ? [destinationTagId] : [];

        // If we are pasting onto a single cell destination, we actually don't want to copy and repeat the clipboard selectinon
        const singleCellDestination = destinationSelection.length === 1 && ScheduleGridSelection.doesCellSettingContainValidShift(destinationSelection[0], true /*permitDeletedWithShared*/);

        // We will temporally shift & repeat the clipboard selection
        // This array will store the shifted cell settings
        let clipboardProjectionSelection: FlexiRowCellSettings[] = [];

        // Beginning at the destination selection's start point, clone clipboard items and shift them
        // Continue until the destination selection's end point is hit, but don't pass it
        let currTime = destinationRowStart.clone();
        let currIndex = 0;
        const clipboardLength = clipboardSelection.length;

        while (currTime.isBefore(destinationRowEnd)) {
            // Determine which iteration of clipboard copy we are on
            const iteration = Math.floor(currIndex / clipboardLength);
            const clipboardItem = clipboardSelection[currIndex % clipboardLength];

            // End this loop if we are projecting to match a single cell destination and we have finished the first iteration (iteration = 0)
            if (singleCellDestination && iteration >= 1) {
                break;
            }

            // Using the baseDestinationOffsetDuration, which comes from the difference in clipboard and destination selection start points, and
            // the iteration of clipboard copy that we are on, we create an offest duration. Clipboard might be shorter than the destination selection, so the clipboard
            // is copied multiple times, shifted further with each iteration
            const offset = baseDestinationOffsetDuration + iteration * clipboardRowDuration;

            // Use the offset to create a modified shift & modified start and end times for the new cell settings
            let projectedShift = null;
            if (ScheduleGridSelection.doesCellSettingContainValidShift(clipboardItem)) {
                projectedShift = this.cloneShift(clipboardItem.cellContentsItem.shift, destinationMemberId, tagIds, includeActivities, null /*durationOffsetName*/, offset);
            }
            const projectedItemStart = clipboardItem.cellContentsItem.startTime.clone().add(offset, durationOffsetTypeName);
            const projectedItemEnd = clipboardItem.cellContentsItem.endTime.clone().add(offset, durationOffsetTypeName);

            // If the shifted item ends after the destination row ends, we won't add it to the list and we'll break the loop
            if (projectedItemEnd.isAfter(destinationRowEnd)) {
                break;
            } else {
                // Create a cell settings with the projected start, end, and shift. Add it to the list.
                const projectedItem: FlexiRowCellSettings = {
                    cellContentsItem: {
                        cellType: clipboardItem.cellContentsItem.cellType,
                        shift: projectedShift,
                        startTime: projectedItemStart,
                        endTime: projectedItemEnd
                    }
                };
                clipboardProjectionSelection.push(projectedItem);
                // Advance the current time marker and increment the index. Eventually we will advance the curr time to the destination row dnd or beyond it, and then the loop will end
                currTime = projectedItem.cellContentsItem.endTime.clone();
                currIndex++;
            }
        }

        return clipboardProjectionSelection;
    }

    /**
     * Examines the contents of the clipboardSelection and copies projections of these contents onto the destinationSelection. Shifts to add, update, and delete are added to their respective lists.
     * clipboardSelection and destinationSelections are limited to one row each, so we are guaranteed that the destination tagId and memberId should be the same for each copied shift.
     * @param clipboardSelection
     * @param destinationSelection
     * @param shiftsToAdd
     * @param shiftsToUpdate
     * @param originalShifts
     * @param shiftsToDelete
     * @param onHandleServiceError
     * @param includeActivities
     * @param roundMomentFunction
     */
    public cloneClipboardSelectionOntoDestinationSelection(
        clipboardSelection: FlexiRowCellSettings[],
        destinationSelection: FlexiRowCellSettings[],
        shiftsToAdd: IShiftEntity[],
        shiftsToUpdate: IBaseShiftEntity[],
        originalShifts: IBaseShiftEntity[],
        shiftsToDelete: IBaseShiftEntity[],
        onHandleServiceError: (error: StaffHubHttpError) => void,
        includeActivities: boolean,
        roundMomentFunction?: RoundMomentToScheduleCellDurationFunction) {

        if (clipboardSelection && clipboardSelection.length && destinationSelection && destinationSelection.length) {
            // Find the type of duration offset for this paste operation (day in week and month view, hour in day view)
            const durationOffsetTypeName: unitOfTime.Base = this.durationOffsetTypeName();
            const destinationRowStart: Moment = ScheduleGridSelection.getStartTimeOfSelection(destinationSelection, roundMomentFunction);

            // Get a projection of the clipboard selection where it has been shifted to align with the destination selection, and where it has maybe been repeated
            // to extend up to (not beyond) the end of the destination selection
            const clipboardProjectionSelection: FlexiRowCellSettings[] = this.projectAndRepeatClipboardSelection(clipboardSelection, destinationSelection, includeActivities);

            // Destination selections provided to this function are all in the same row. We can determine if the destination selection is in the open shift row
            // by checking the cellContentsItem of the FlexiRowCellSettings. If this is the case, the pastes will be handled differently
            const pastedIntoOpenShiftRow: boolean =  destinationSelection[0].cellContentsItem && destinationSelection[0].cellContentsItem.inOpenShiftRow;
            const destinationRowMemberId: string = !pastedIntoOpenShiftRow && destinationSelection[0].cellContentsItem && destinationSelection[0].cellContentsItem.member && destinationSelection[0].cellContentsItem.member.id;

            // For each cell in the projected clipboard selection, we find all shifts in the destination selection that it overlaps
            // Then, if the cell has a shift, we will update shifts in the destination selection or add a new shift
            // We track the current pasted marker so we know where to get overlapping shifts. The current pasted maker is the end time of the paste so far
            let pastedMarker: Moment = destinationRowStart.clone();

            for (let i = 0; i < clipboardProjectionSelection.length; i++) {
                const clipboardCell: FlexiRowCellSettings = clipboardProjectionSelection[i];

                const clipboardCellHasShift: boolean = ScheduleGridSelection.doesCellSettingContainValidShift(clipboardCell);
                const clipboardCellShift: IBaseShiftEntity = clipboardCellHasShift ? clipboardCell.cellContentsItem.shift : null;

                // For now we ignore time off shifts pasted into open shift cells
                if (clipboardCellShift && ShiftUtils.isTimeOffEvent(clipboardCellShift) && pastedIntoOpenShiftRow) {
                    continue;
                }

                // Get a list of all the valid shifts in the destination selection that overlap with the clipboard cell
                const destinationShifts: IBaseShiftEntity[] = this.getValidSelectionShiftsWithinTimeWindow(
                    destinationSelection,
                    pastedMarker,
                    pastedMarker.clone().add(
                            ScheduleGridSelection.getDurationOfSelection([clipboardCell], this.isSelectionForHours(), roundMomentFunction),
                            durationOffsetTypeName),
                    true /* permitDeletedWithShared */);
                let clipboardShiftPasted: boolean = false;

                destinationShifts.forEach((destinationCellShift) => {

                    // If this clipboard shift has already been pasted, or if the clipboard cell does not contain a shift, the current destination shift will be deleted.
                    // But if this destination shift has already been added to the update or delete lists, don't add it to the delete list
                    if ((clipboardShiftPasted || !clipboardCellHasShift) &&
                        !ShiftUtils.isShiftInList(shiftsToUpdate, destinationCellShift) && !ShiftUtils.isShiftInList(shiftsToDelete, destinationCellShift)) {
                        shiftsToDelete.push(destinationCellShift);

                    // If the clipboard cell does have a shift, we may update a destination shift or add the clipboard shift
                    } else if (clipboardCellHasShift) {
                        /// update the destination cell shift if it hasn't been added to the update list already
                        if (!ShiftUtils.isShiftInList(shiftsToUpdate, destinationCellShift)) {
                            const originalShift = this.addSimpleCloneOfShiftToList(destinationCellShift, originalShifts);
                            const clonedShift = this.addSimpleCloneOfShiftToList(clipboardCellShift, shiftsToUpdate, destinationRowMemberId, pastedIntoOpenShiftRow /*forceCloneAsOpenShift*/, !pastedIntoOpenShiftRow /*forceCloneAsAssignedShift*/);
                            clonedShift.eTag = originalShift.eTag;
                            clonedShift.id = originalShift.id;
                            clipboardShiftPasted = true;

                            // If the shift was added to the delete list, we remove it from the delete list, as updating takes priority
                            const idx = ShiftUtils.getShiftIndexInList(shiftsToDelete, destinationCellShift);
                            if (idx > -1) {
                                shiftsToDelete.splice(idx, 1);
                            }

                        // If the destination shift has already been added to the update list, instead add the clipboard cell to the add shift list
                        // if it is not already in the add shift list
                        } else if (!ShiftUtils.isShiftInList(shiftsToAdd, clipboardCellShift)) {
                            this.addSimpleCloneOfShiftToList(clipboardCellShift, shiftsToAdd, destinationRowMemberId, pastedIntoOpenShiftRow /*forceCloneAsOpenShift*/);
                            clipboardShiftPasted = true;
                        }
                    }
                });

                // If there is a clipboard shift and it hasn't already been pasted while processing destination shifts, we will add it now
                if (clipboardCellHasShift && !clipboardShiftPasted) {
                    this.addSimpleCloneOfShiftToList(clipboardCellShift, shiftsToAdd, destinationRowMemberId, pastedIntoOpenShiftRow /*forceCloneAsOpenShift*/, !pastedIntoOpenShiftRow /*forceCloneAsAssignedShift*/);
                }

                // advance pasted time marker to clipboard cell end time
                pastedMarker.add(ScheduleGridSelection.getDurationOfSelection([clipboardCell], this.isSelectionForHours(), roundMomentFunction), durationOffsetTypeName);
            }
        }
    }

    /**
     * Clones the provided sourceShift, transforming it between assigned and open as required, and adds it to the provided list of shifts
     * @param sourceShift
     * @param shiftList
     * @param forceCloneAsOpenShift - if this is true, the cloned shift will be transformed into an open shift regardless of the sourceShift type
     * @param forceCloneAsAssignedShift - if this is true, the cloned shift will be transformed into an an shift regardless of the sourceShift type
     */
    private addSimpleCloneOfShiftToList(sourceShift: IBaseShiftEntity, shiftList: IBaseShiftEntity[], memberId?: string, forceCloneAsOpenShift: boolean = false, forceCloneAsAssignedShift: boolean = false): IBaseShiftEntity {
        let shiftToClone: IBaseShiftEntity = sourceShift;
        const isOpenShift = ShiftUtils.isOpenShift(sourceShift);

        // Tranform shift to open or assigned if necessary
        if (forceCloneAsOpenShift && !isOpenShift) {
            shiftToClone = OpenShiftEntity.fromAssignedShift(sourceShift);
        } else if (forceCloneAsAssignedShift && isOpenShift) {
            shiftToClone = ShiftEntity.fromOpenShift(shiftToClone as IOpenShiftEntity, memberId);
        }

        const shiftToAdd: IBaseShiftEntity = ShiftUtils.isOpenShift(shiftToClone) ? OpenShiftEntity.clone(shiftToClone as IOpenShiftEntity) : ShiftEntity.clone(shiftToClone);
        shiftList.push(shiftToAdd);

        return shiftToAdd;
    }

    /**
     * Returns a list of valid shifts within the selection that start before or after the given startTime and before the given endTime
     * @param selection
     * @param startTime
     * @param endTime
     * @param permitDeletedWithShared
     */
    private getValidSelectionShiftsWithinTimeWindow(selection: FlexiRowCellSettings[], startTime: Moment, endTime: Moment, permitDeletedWithShared: boolean = false): IBaseShiftEntity[] {
        let shifts: IBaseShiftEntity[] = [];
        if (selection && selection.length) {
            for (let i = 0; i < selection.length; i++) {
                const candidateItem = selection[i];
                const candidateItemStartTime = candidateItem.cellContentsItem.startTime;
                const candidateItemEndtime = candidateItem.cellContentsItem.endTime;

                if (candidateItem.cellContentsItem && candidateItem.cellContentsItem.shift &&
                    ScheduleGridSelection.doesCellSettingContainValidShift(candidateItem, permitDeletedWithShared) &&
                    DateUtils.overlapsStartsOrEndsBetween(candidateItemStartTime, candidateItemEndtime, startTime, endTime, true /* includeStartEdge */ , false /* includeEndEdge*/)) {
                    shifts.push(candidateItem.cellContentsItem.shift);
                }
            }
        }

        return shifts;
    }

    /**
     * Clone a specified shift
     * This is used for scenarios where we want to apply a shift to other schedule cells, such as copy paste and unique shifts.
     *
     * @param shiftToClone shift to clone
     * @param newShiftMemberId member id to assign to the cloned shift
     * @param newShiftGroupTagIds group tag ids to assign to the cloned shift
     * @param newShiftOffsetDuration duration offset to apply to time properties (eg, for the shift's start + end times, break times, etc) of the cloned shift
     * @param includeActivities if true, clone will include activities.
     * @param durationOffsetTypeName if present, this determines the type of offset to add. Otherwise, this is determined via this.durationOffsetTypeName()
     */
    private cloneShift(
        shiftToClone: IBaseShiftEntity,
        newShiftMemberId: string,
        newShiftGroupTagIds: string[],
        includeActivities: boolean,
        durationOffsetTypeName?: unitOfTime.Base,
        newShiftOffsetDuration?: number): IBaseShiftEntity {
        const cloneAsOpenShift = ShiftUtils.isOpenShift(shiftToClone);
        let newShift: IBaseShiftEntity = null;

        if (shiftToClone) {
            newShift = cloneAsOpenShift ? OpenShiftEntity.clone(shiftToClone as IOpenShiftEntity) : ShiftEntity.clone(shiftToClone as IShiftEntity);
            newShift.id = cloneAsOpenShift ? OpenShiftEntity.generateNewOpenShiftId() : ShiftEntity.generateNewShiftId();
            newShift.eTag = null;

            let shiftGroupTagIds: string[];
            // If no group tag ids were specified (occurs in ungrouped view), then use the tag ids from the source shift
            if ((!newShiftGroupTagIds) || newShiftGroupTagIds.length === 0) {
                shiftGroupTagIds = shiftToClone.tagIds && shiftToClone.tagIds.slice();
            } else {
                shiftGroupTagIds = newShiftGroupTagIds.slice();
            }
            newShift.tagIds = shiftGroupTagIds;

            newShift.memberId = newShiftMemberId;
            newShift.subshifts = [];

            const durationOffsetType: unitOfTime.Base = durationOffsetTypeName || this.durationOffsetTypeName();

            // Calculate adjusted times for the new shift
            newShift.startTime = shiftToClone.startTime.clone().add(newShiftOffsetDuration, durationOffsetType);
            newShift.endTime = shiftToClone.endTime.clone().add(newShiftOffsetDuration, durationOffsetType);

            if (includeActivities) {
                // Clone activities and then shift times
                newShift.subshifts = shiftToClone.subshifts.map(
                    (activity: ISubshiftEntity) => {
                        let newActivity = SubshiftEntity.clone(activity);
                        newActivity.id = SubshiftEntity.generateNewSubshiftId();
                        newActivity.startTime = activity.startTime.clone().add(newShiftOffsetDuration, durationOffsetType);
                        newActivity.endTime = activity.endTime.clone().add(newShiftOffsetDuration, durationOffsetType);
                        return newActivity;
                });
            }
        }
        return newShift;
    }

    /**
     * Returns a copy of the given selection, where the new selection has been extended up to, and maybe beyond, the desiredSelectionEnd
     * @param currentSelection
     * @param desiredSelectionEnd
     * @param orderedSelectableCells the list of all selectable cells. If we have multi-column cells in the view, we will end up with some spacer "dummy" cells in this list that
     * we have to watch out for. These cells allow us to keep the total number of indices the same & cache selection indices.
     * @param roundingFn
     */
    private extendSelectionToEndTime(currentSelection: FlexiRowCellSettings[], desiredSelectionEnd: Moment, orderedSelectableCells: FlexiRowCellSettings[], roundingFn?: (moment: Moment, isStartTime: boolean) => Moment): FlexiRowCellSettings[] {
        let extendedSelection: FlexiRowCellSettings[] = currentSelection.slice();
        if (currentSelection && currentSelection.length && desiredSelectionEnd && orderedSelectableCells) {
            // Ensure the desiredSelectionEnd does not pass beyond the current view
            desiredSelectionEnd = this.normalizeDestinationExtensionEndToView(desiredSelectionEnd, this._settings.viewEnd);

            let currSelectionEndTime = ScheduleGridSelection.getEndTimeOfSelection(currentSelection, roundingFn);

            // While our selection ends before the desired selection end
            // Examine items in selectable set that immediately follow the currentSelection
            // If the item ends at or before the desiredSelectionEnd, add the item & continue the loop
            // If the item ends after the desiredSelectionEnd, add the item (loop will end)
            // Otherwise add no more items and break the loop
            while (currSelectionEndTime.isBefore(desiredSelectionEnd)) {
                const lastSelectedItem = extendedSelection[extendedSelection.length - 1];
                if (lastSelectedItem) {
                    const lastSelectedIndex = orderedSelectableCells.indexOf(lastSelectedItem);
                    if (lastSelectedIndex > -1 && lastSelectedIndex + 1 < orderedSelectableCells.length) {
                        const candidateItem: FlexiRowCellSettings = orderedSelectableCells[lastSelectedIndex + 1];
                        if (candidateItem) {
                            const candidateEnd = candidateItem.cellContentsItem.endTime;
                            const candidateStart = candidateItem.cellContentsItem.startTime;

                            // There is a special case for empty cells that end after the desiredSelectionEnd, but have started before the desiredSelectionEnd.
                            // Because of the off-sized 15 min half cells, selection cells may not align perfectly on the schedule cell interval grid.
                            // In the above case, we create a new empty cell to span the gap and properly extend the selection
                            if (candidateEnd.isAfter(desiredSelectionEnd) && candidateStart.isBefore(desiredSelectionEnd) && candidateItem.cellContentsItem.cellType === ScheduleCellType.Empty ) {
                                const newItem: FlexiRowCellSettings = {
                                    cellContentsItem: {
                                        cellType: ScheduleCellType.Empty,
                                        startTime: currSelectionEndTime.clone(),
                                        endTime: desiredSelectionEnd.clone()
                                    }
                                };
                                extendedSelection.push(newItem);
                            } else {
                                extendedSelection.push(candidateItem);
                                currSelectionEndTime = candidateEnd.clone();
                            }
                        } else {
                            break;
                        }
                    } else {
                        break;
                    }
                } else {
                    break;
                }
            }
        }

        return extendedSelection;
    }

    /**
     * Entry point function for copying a clipboardSelection onto a destinationSelection
     *
     * @param orderedSelectableCells
     * @param clipboardSelection
     * @param destinationSelection
     * @param viewStart
     * @param viewEnd
     * @param shiftsToAdd
     * @param shiftsToUpdate
     * @param originalShifts - the updated shifts in their previous state
     * @param shiftsToDelete
     * @param onHandleServiceError
     * @param includeActivities
     */
    private pasteClipboardSelectionOntoDestinationSelection(
        orderedSelectableCells: FlexiRowCellSettings[],
        clipboardSelection: FlexiRowCellSettings[],
        destinationSelection: FlexiRowCellSettings[],
        shiftsToAdd: IShiftEntity[],
        shiftsToUpdate: IShiftEntity[],
        originalShifts: IShiftEntity[],
        shiftsToDelete: IShiftEntity[],
        onHandleServiceError: (error: StaffHubHttpError) => void,
        includeActivities: boolean) {

        const roundMomentFunction: RoundMomentToScheduleCellDurationFunction = this._settings.roundMomentToScheduleCellDurationFunction;

        // Only perform paste if both clipboard and destination selection rows are not null and have a non zero length
        if (clipboardSelection && clipboardSelection.length && destinationSelection && destinationSelection.length) {

            // Find the start, end, and durations of the clipboard and destination selection rows
            const clipboardRowStart: Moment = ScheduleGridSelection.getStartTimeOfSelection(clipboardSelection, roundMomentFunction);
            const clipboardRowEnd: Moment = ScheduleGridSelection.getEndTimeOfSelection(clipboardSelection, roundMomentFunction);
            const clipboardRowDuration: number = this.getDurationDifferenceFromMoments(clipboardRowStart, clipboardRowEnd);
            const destinationRowStart: Moment = ScheduleGridSelection.getStartTimeOfSelection(destinationSelection, roundMomentFunction);
            const destinationRowEnd: Moment = ScheduleGridSelection.getEndTimeOfSelection(destinationSelection, roundMomentFunction);
            const destinationRowDuration: number = this.getDurationDifferenceFromMoments(destinationRowStart, destinationRowEnd);

            // If the destination selection is longer than or equal to the clipboard selection  the clipboard selection
            // is pasted onto the destination selection, repeating the clipboard selection until the end of the destination selection is reached
            if (destinationRowDuration >= clipboardRowDuration) {
                this.cloneClipboardSelectionOntoDestinationSelection(
                    clipboardSelection,
                    destinationSelection,
                    shiftsToAdd,
                    shiftsToUpdate,
                    originalShifts,
                    shiftsToDelete,
                    onHandleServiceError,
                    includeActivities);

            // Otherwise the destinationSelection is smaller than the clipboard. We attempt to extend the clipboard selection up to the and maybe beyond the length of the destination selection
            // Then we paste the extended selection over the destination selection, overwriting what is there.
            } else {
                const desiredSelectionEnd: Moment = this.getDesiredDestinationExtensionEnd(destinationRowStart, clipboardRowDuration);
                const extendedSelection = this.extendSelectionToEndTime(destinationSelection, desiredSelectionEnd, orderedSelectableCells, roundMomentFunction);

                this.cloneClipboardSelectionOntoDestinationSelection(
                    clipboardSelection,
                    extendedSelection,
                    shiftsToAdd,
                    shiftsToUpdate,
                    originalShifts,
                    shiftsToDelete,
                    onHandleServiceError,
                    includeActivities);
            }
        }
    }

    /**
     * Get the valid end time for an extended destination row. This end time must fit into the current view and must not end on midnight of the day just after the projected destination
     * end day.
     * @param destinationRowStart
     * @param clipboardRowDuration
     */
    private getDesiredDestinationExtensionEnd(destinationRowStart: Moment, clipboardRowDuration: number): Moment {
        let desiredSelectionEnd = destinationRowStart.clone().add(clipboardRowDuration, this.durationOffsetTypeName());

        // For selections that end on day boundaries, we need desiredSelectionEnd to be end of day on the last projected destination day rather than midnight on the next day.
        // Otherwise, extendSelectionToEndTime will extend the selection too far due to its while (currSelectionEndTime.isBefore(desiredSelectionEnd)) loop and because the
        // last items in the selection rows end on .endOf() boundaries that will be before midnight of the next day
        if (!this.isSelectionForHours()) {
            desiredSelectionEnd.subtract(1, "day").endOf("day");
        }

        desiredSelectionEnd = this.normalizeDestinationExtensionEndToView(desiredSelectionEnd, this._settings.viewEnd);

        return desiredSelectionEnd;
    }

    /**
     * Ensure desiredSelectionEnd does not pass the end of the view
     * @param desiredSelectionEnd
     * @param viewEnd
     */
    private normalizeDestinationExtensionEndToView(desiredSelectionEnd: Moment, viewEnd: Moment): Moment {
        // For extended selections that would pass out of the current view we must truncate the selection to fit into the current view. Without doing this our extension logic
        // will add all subsequent cells to the extended selection, as none of them will be able to extend the selection to this erroneous end date which is outside the view.
        if (desiredSelectionEnd.isAfter(viewEnd) && !this.isSelectionForHours()) {
            return viewEnd.clone();
        } else {
            return desiredSelectionEnd.clone();
        }
    }

    /**
     * Delete the items in the selection
     * @param isKeyboardShortcut
     * @param onServiceError
     */
    public async deleteSelection(tenantId: string, teamId: string, isKeyboardShorcut: boolean, onServiceError?: (error: StaffHubHttpError) => void) {
        let officeFabricSelection = this.getOfficeFabricSelection();
        if (officeFabricSelection) {
            let itemCells = new Array<FlexiRowCellSettings>();
            let emptyCells = new Array<FlexiRowCellSettings>();
            ScheduleGridSelection.getSelectedCells(officeFabricSelection, itemCells, emptyCells);

            // Save the current selection indices so we can restore the current selection after the shift changes have been made.
            // This works around an issue where the selection gets cleared after performing schedule changes.
            this.cacheSelectionIndices(itemCells);

            let shiftsToDelete: IBaseShiftEntity[] = [];
            for (let i = 0; i < itemCells.length; i++) {
                const shiftCellSettings: FlexiRowCellSettings = itemCells[i];
                let currentShift: IBaseShiftEntity = shiftCellSettings.cellContentsItem.shift;
                // Do not allow deletion of time off requests or already deleted shifts
                if (currentShift && !ShiftUtils.isTimeOffRequestEvent(currentShift) && !ShiftUtils.isDeletedShift(currentShift)) {
                    shiftsToDelete.push(currentShift);
                    const numActivities = currentShift.subshifts.length;
                    if (numActivities > 0) {
                        InstrumentationUtils.logActivityDeleted(this._settings.scheduleCalendarType, InstrumentationService.values.ShiftDeleted, numActivities, ShiftUtils.isOpenShift(currentShift));
                    }
                }
            }

            const changeSource = isKeyboardShorcut ? ChangeSource.Keyboard : ChangeSource.ContextualMenu;
            let deleteSuccess: boolean = await this.processShiftCRUDLists(null /*shiftsToAdd*/, null /*shiftsToUpdate*/, shiftsToDelete, null /*originalUpdatedShifts*/,  onServiceError, changeSource);
            if (deleteSuccess) {
                fireAccessibilityAlert(this._strings.get("shiftSelectionDeletedAriaAlert"));
            }
        }
    }

    /**
     * Perform applying of a unique shift to a schedule cell selection.
     *
     * @param uniqueShift unique shift to apply
     * @param tenantId tenant id
     * @param teamId team id
     * @param onServiceError callback for when service errors occur
     */
    public applyUniqueShiftToSelection(
        uniqueShift: IUniqueShiftEntity,
        tenantId: string,
        teamId: string,
        onServiceError: (error: StaffHubHttpError) => void): void {
        if (!uniqueShift || !tenantId || !teamId) {
            trace.warn("applyUniqueShiftToSelection: params not all set");
            return;
        }

        const officeFabricSelection: Selection = this.getOfficeFabricSelection();
        let destSelection: FlexiRowCellSettings[] = officeFabricSelection ? officeFabricSelection.getSelection() : null;

        if (destSelection && destSelection.length) {
            // For the current destination selection, create lists of shifts to be updated and/or added to the current selection.
            // These updated/new shifts are generated from the unique shift's properties.
            let originalShifts: IShiftEntity[] = [];
            let shiftsToUpdate: IShiftEntity[] = [];
            let shiftsToAdd: IShiftEntity[] = [];
            destSelection.forEach((currentCellSettings: FlexiRowCellSettings) => {
                // Gather info for the current schedule cell in the selection
                const scheduleCellInfo: ScheduleCellContentsInfo = currentCellSettings.cellContentsItem;
                const scheduleCellShift: IBaseShiftEntity = scheduleCellInfo.shift;
                const currentCellTagIds: string[] = scheduleCellInfo.tagId ? [scheduleCellInfo.tagId] : null;
                const currentMemberId: string = scheduleCellInfo.member ? scheduleCellInfo.member.id : null;

                 // Adjust the shift time for the target selection cell. Calculate the destination offset in days so that moment accounts for the DST boundary correctly
                 let newShiftStartTime: Moment = DateUtils.applyTimeOfDayToDate(scheduleCellInfo.startTime, uniqueShift.startTime);
                 let destinationOffsetInDays: number = DateUtils.getDifferenceInDaysFromMoments(uniqueShift.startTime, newShiftStartTime);

                // Use the unique shift to compute the updated/new shift for the current schedule cell
                // Handle open shift cells
                if (scheduleCellInfo.inOpenShiftRow) {
                    let shiftToApply: IOpenShiftEntity = ShiftUtils.getOpenShiftFromUniqueShift(uniqueShift, tenantId, teamId, currentCellTagIds);
                    let newOpenShift: IOpenShiftEntity = this.cloneShift(shiftToApply, "", currentCellTagIds, TeamStore().team.copyShiftActivitiesWithShiftsEnabled, "days", destinationOffsetInDays) as IOpenShiftEntity;
                    // If the current selection cell already contains an open working shift or open time off shift (does not occur yet)
                    // update that existing shift with the properties from our new open shift
                    if (scheduleCellShift && (ShiftUtils.isWorkingShift(scheduleCellShift) || ShiftUtils.isTimeOffEvent(scheduleCellShift))) {
                        originalShifts.push(OpenShiftEntity.clone(scheduleCellShift as IOpenShiftEntity));
                        newOpenShift.id = scheduleCellShift.id;
                        newOpenShift.eTag = scheduleCellShift.eTag;
                        // If we are applyin a unique shift to a deleted open shift with shared changes, we need to set the state to active
                        if (ShiftUtils.isUnsharedDeletedShift(newOpenShift)) {
                            newOpenShift.state = ShiftStates.Active;
                        }
                        shiftsToUpdate.push(newOpenShift);
                    } else {
                        // Otherwise if the current selection cell doesn't contain a working shift, add a new open shift based on the unique shift.
                        shiftsToAdd.push(newOpenShift);
                    }
                // Handle regular schedule cells
                } else {
                    let shiftToApply: IShiftEntity = ShiftUtils.getShiftFromUniqueShift(uniqueShift);
                    shiftToApply.tenantId = tenantId;
                    shiftToApply.teamId = teamId;
                    shiftToApply.tagIds = currentCellTagIds;
                    shiftToApply.memberId = currentMemberId;

                    let newShift: IShiftEntity = this.cloneShift(shiftToApply, currentMemberId, currentCellTagIds, false /* includeActivities */, "days", destinationOffsetInDays);

                    // If the current selection cell already contains a working or time off shift, update that existing shift with the properties from our new shift
                    if (scheduleCellShift && (ShiftUtils.isWorkingShift(scheduleCellShift) || ShiftUtils.isTimeOffEvent(scheduleCellShift))) {
                        let updatedShift: IShiftEntity = ShiftEntity.clone(scheduleCellShift);
                        originalShifts.push(ShiftEntity.clone(updatedShift));
                        ShiftUtils.copyNonMemberFieldsFromShiftToShift(newShift, updatedShift);
                        // If we are applyin a unique shift to a deleted shift with shared changes, we need to set the state to active
                        if (ShiftUtils.isUnsharedDeletedShift(updatedShift)) {
                            updatedShift.state = ShiftStates.Active;
                        }
                        shiftsToUpdate.push(updatedShift);
                    } else {
                        // Otherwise if the current selection cell doesn't contain a working shift, add a new shift based on the unique shift.
                        shiftsToAdd.push(newShift);
                    }
                }
            });

            // Save the current selection indices so we can restore the current selection after the shift changes have been made.
            // This works around an issue where the selection gets cleared after performing schedule changes.
            this.cacheSelectionIndices(destSelection);

            this.processShiftCRUDLists(shiftsToAdd, shiftsToUpdate, null /* shiftsToDelete */, originalShifts, onServiceError, ChangeSource.ShiftLookup);
        }
    }

    /**
     * Fetch info about the current cell selection
     * @param itemCells item cells (ie, Shift cells) in the current selection
     * @param emptyCells empty cells in the current selection
     */
    private static getSelectedCells(selection: Selection, itemCells: FlexiRowCellSettings[], emptyCells: FlexiRowCellSettings[]) {
        const selectedCells: FlexiRowCellSettings[] = selection.getSelection();
        if (selectedCells) {
            selectedCells.forEach((selectedCell: FlexiRowCellSettings) => {
                const scheduleCellInfo: ScheduleCellContentsInfo = selectedCell.cellContentsItem;
                if (scheduleCellInfo) {
                    if (scheduleCellInfo.cellType === ScheduleCellType.Shift) {
                        itemCells.push(selectedCell);
                    } else if (scheduleCellInfo.cellType === ScheduleCellType.Empty) {
                        emptyCells.push(selectedCell);
                    }
                }
            });
        }
    }

    /**
     * Move the current cell selection in the specified direction.
     * Use this for keyboard navigation of the selection.
     *
     * When doExpandSelection is set, moving the selection will cause the selection head cell's position to be moved, resulting in the selection of all cells
     * between the fixed selection anchor cell and the selection head cell.
     *
     * @param direction selection direction
     * @param doExpandSelection true if the current selection should be expanded in the specified direction (ie, this is for keyboard navigation with Shift pressed)
     *      false to just move the selection to the cell in the specified direction.
     */
    public moveSelection(direction: MoveSelectionDirection, doExpandSelection: boolean) {
        let officeFabricSelection: Selection = this.getOfficeFabricSelection();
        let anchorCell: FlexiRowCellSettings = this._selectionAnchorCell;
        if (officeFabricSelection) {
            if (anchorCell) {
                const numColumnsPerRow: number = this.getColumnsPerRow();

                // Get info about the current selection anchor cell
                const anchorCellColIdxVal = anchorCell.cellContentsItem.colIdxVal;
                const anchorCellRow: number = ScheduleGridSelection.getCellRowFromIndex(anchorCellColIdxVal, numColumnsPerRow);
                const anchorCellStartCellColumn: number = ScheduleGridSelection.getCellColumnFromIndex(anchorCellColIdxVal, numColumnsPerRow);
                const anchorCellEndCellColumn: number = this.getEndColumnIndexForCell(anchorCellStartCellColumn, anchorCell.cellContentsItem.cellUnitSize);

                // Get info about the current cell selection
                let selectedCells: FlexiRowCellSettings[] = officeFabricSelection.getSelection();
                let selectedCellRangeInfo: SelectedCellRangeInfo = this.calculateSelectedCellRangeInfo(selectedCells);

                // Get info about the selection head, which will move around via the selection move.
                const selectionHeadRow = (selectedCellRangeInfo.rowRange.firstIndex === anchorCellRow) ? selectedCellRangeInfo.rowRange.lastIndex : selectedCellRangeInfo.rowRange.firstIndex;
                const selectionHeadRowColumnRangeInfo: SelectedRowRangeInfo = selectedCellRangeInfo.columnRangesForRows.get(selectionHeadRow);

                // Calculate the current selection box using the positions of the anchor and selection head
                const selectionBoxColumnRange: SelectionIndexRange = {
                    firstIndex: Math.min(anchorCellStartCellColumn, selectionHeadRowColumnRangeInfo.columnRange.firstIndex),
                    lastIndex: Math.max(anchorCellEndCellColumn, selectionHeadRowColumnRangeInfo.columnRange.lastIndex)
                };

                // Update the selection box's boundaries based on the selection move

                let nextSelectionRow: number = selectionHeadRow;
                let nextSelectionColumnRange: SelectionIndexRange = {
                    firstIndex: selectionBoxColumnRange.firstIndex,
                    lastIndex: selectionBoxColumnRange.lastIndex
                };
                let nextSelectionSingleSelectColumn: number = nextSelectionColumnRange.firstIndex;

                let orderedSelectableCells: FlexiRowCellSettings[] = ScheduleGridSelection.filterOutDummySelectableItems(officeFabricSelection.getItems());

                // Note:  Schedule cells may be wider than one unit width (eg, multi-day timeoffs).  So for horizontal selection moves, we need to
                // take schedule cell widths into account when computing how much the selection expands/shrinks horizontally.
                // If the anchor or selection head cells are wider than one unit, the selection region may expand to match these wider primary cells.
                switch (direction) {
                    case MoveSelectionDirection.Up: {
                        // Move/expand the selection up one row
                        nextSelectionRow = Math.max(0, selectionHeadRow - 1);
                        break;
                    }
                    case MoveSelectionDirection.Down: {
                        // Move/expand the selection down one row
                        let lastSelectableCell = orderedSelectableCells[orderedSelectableCells.length - 1];
                        let lastRow = ScheduleGridSelection.getCellRowFromIndex(lastSelectableCell.cellContentsItem.colIdxVal, numColumnsPerRow);
                        nextSelectionRow = Math.min(selectionHeadRow + 1, lastRow);
                        break;
                    }
                    case MoveSelectionDirection.Left: {
                        const minColumnIndexForRow = 0;
                        // Calculate the updated position for non-expanding, single cell selections
                        nextSelectionSingleSelectColumn = Math.max(minColumnIndexForRow, anchorCellStartCellColumn - 1);
                        const isSelectionHeadToLeftOfAnchor: boolean = (selectionHeadRowColumnRangeInfo.firstCellColumnRange.firstIndex < anchorCellStartCellColumn);
                        if (isSelectionHeadToLeftOfAnchor) {
                            // If the selection head is to the left of the anchor, expand the selection towards the left.
                            nextSelectionColumnRange.firstIndex = Math.max(minColumnIndexForRow, selectionHeadRowColumnRangeInfo.firstCellColumnRange.firstIndex - 1);
                        } else {
                            // If the selection head is to the right of the anchor, shrink the selection by moving its right edge towards the left.
                            const newSelectionHeadColumn = Math.max(minColumnIndexForRow, selectionHeadRowColumnRangeInfo.lastCellColumnRange.firstIndex - 1);
                            nextSelectionColumnRange.lastIndex = newSelectionHeadColumn;
                            nextSelectionColumnRange.firstIndex = Math.min(nextSelectionColumnRange.firstIndex, newSelectionHeadColumn);
                        }
                        break;
                    }
                    case MoveSelectionDirection.Right: {
                        const maxColumnIndexForRow = numColumnsPerRow - 1;
                        // Calculate the updated position for non-expanding, single cell selections
                        nextSelectionSingleSelectColumn = Math.min(anchorCellEndCellColumn + 1, maxColumnIndexForRow);
                        const isSelectionHeadToRightOfAnchor: boolean = (selectionHeadRowColumnRangeInfo.lastCellColumnRange.lastIndex > anchorCellStartCellColumn);
                        if (isSelectionHeadToRightOfAnchor) {
                            // If the selection head is to the right of the anchor, expand the selection towards the right.
                            nextSelectionColumnRange.lastIndex = Math.min(selectionHeadRowColumnRangeInfo.lastCellColumnRange.lastIndex + 1, maxColumnIndexForRow);
                        } else {
                            // If the selection head is to the left of the anchor, shrink the selection by moving its left edge towards the right.
                            const newSelectionHeadColumn = Math.min(selectionHeadRowColumnRangeInfo.firstCellColumnRange.lastIndex + 1, maxColumnIndexForRow);
                            nextSelectionColumnRange.firstIndex = newSelectionHeadColumn;
                            nextSelectionColumnRange.lastIndex = Math.max(nextSelectionColumnRange.lastIndex, newSelectionHeadColumn);
                        }
                        break;
                    }
                }

                // Calculate the updated selection box boundaries
                // If doExpandSelection is not set, the selection box is simply a single cell selection that moves around.
                let updatedSelectionBox: SelectionBox = {
                    rowRange: {
                        firstIndex: doExpandSelection ? Math.min(anchorCellRow, nextSelectionRow) : nextSelectionRow,
                        lastIndex: doExpandSelection ? Math.max(anchorCellRow, nextSelectionRow) : nextSelectionRow
                    },
                    columnRange: {
                        firstIndex: doExpandSelection ? Math.min(anchorCellStartCellColumn, nextSelectionColumnRange.firstIndex) : nextSelectionSingleSelectColumn,
                        lastIndex: doExpandSelection ? Math.max(anchorCellEndCellColumn, nextSelectionColumnRange.lastIndex) : nextSelectionSingleSelectColumn
                    }
                };

                // Disable selection change events while we update the selection
                officeFabricSelection.setChangeEvents(false /* isEnabled */);

                // Clear out the existing selected cells
                officeFabricSelection.setAllSelected(false /* isAllSelected */);

                // Repopulate the Office Fabric selection with schedule cells that intersect the updated selection box.
                orderedSelectableCells.forEach((selectableCell: FlexiRowCellSettings) => {
                    let shouldSelectCell = this.isCellOverlapWithSelectionBox(selectableCell, updatedSelectionBox);
                    if (shouldSelectCell) {
                        officeFabricSelection.setIndexSelected(selectableCell.dataSelectionIndex, true /* isSelected */, false /* shouldAnchor */);
                    }
                });

                // Re-enable selection change event handling. This will also trigger a single selection changed event.
                officeFabricSelection.setChangeEvents(true /* isEnabled */);
            } else {
                // If we have no current selection, start a new selection by selecting the first cell.
                officeFabricSelection.setIndexSelected(0, true /* isSelected */, false /* shouldAnchor */);
            }
        }
    }

    /**
     * Fire instrumentation event for a selection action (eg, copy, paste, delete)
     *
     * @param scheduleGridSelection schedule grid selection
     * @param instrumentEventName instrumentation event name
     * @param isKeyboardShortcut true if this action was initiated via a keyboard shortcut. false if initiated via UX.
     * @param instrumentScheduleEvent function for instrumenting schedule-related events
     * @param eventProperties Additional event properties to add to the event (optional)
     */
    public static instrumentSelectionAction(
        scheduleGridSelection: ScheduleGridSelection,
        instrumentEventName: string,
        isKeyboardShortcut: boolean,
        instrumentScheduleEvent: InstrumentScheduleEventFunction,
        eventProperties?: InstrumentationEventPropertyInterface[]) {
        let officeFabricSelection = scheduleGridSelection ? scheduleGridSelection.getOfficeFabricSelection() : null;
        if (officeFabricSelection) {
            const cellSelection: FlexiRowCellSettings[] = officeFabricSelection.getSelection();
            const numCellsSelected = cellSelection ? cellSelection.length : 0;
            let numTimeOffRequestsSelected = 0;
            let numShiftsWithActivities: number = 0;
            let numOpenShifts: number = 0;
            let numSharedShifts: number = 0;
            let numDraftShifts: number = 0;
            if (numCellsSelected > 0) {
                cellSelection.forEach((currentCellSettings: FlexiRowCellSettings) => {
                    const scheduleCellInfo: ScheduleCellContentsInfo = currentCellSettings.cellContentsItem;
                    const shift: IBaseShiftEntity = scheduleCellInfo && scheduleCellInfo.shift;
                    if (shift) {
                        ShiftUtils.shiftHasUnsharedEdits(shift)
                            ? numDraftShifts++
                            : numSharedShifts++;

                        if (shift.state !== ShiftStates.Deleted) {
                            if (ShiftUtils.isTimeOffRequestEvent(shift)) {
                                numTimeOffRequestsSelected++;
                            }

                            if (ShiftUtils.hasActivities(shift)) {
                                numShiftsWithActivities++;
                            }
                        }
                        if (ShiftUtils.isOpenShift(shift)) {
                            numOpenShifts++;
                        }
                    }
                });
            }

            eventProperties = eventProperties && eventProperties.length ? eventProperties : [];

            instrumentScheduleEvent(instrumentEventName, eventProperties.concat([
                getGenericEventPropertiesObject(InstrumentationService.properties.NumCellsSelected, numCellsSelected),
                getGenericEventPropertiesObject(InstrumentationService.properties.NumOpenShifts, numOpenShifts),
                getGenericEventPropertiesObject(InstrumentationService.properties.NumTimeOffRequests, numTimeOffRequestsSelected),
                getGenericEventPropertiesObject(InstrumentationService.properties.IsKeyboardShortcut, isKeyboardShortcut),
                getGenericEventPropertiesObject(InstrumentationService.properties.NumShiftsWithActivities, numShiftsWithActivities),
                getGenericEventPropertiesObject(InstrumentationService.properties.NumSharedShifts, numSharedShifts),
                getGenericEventPropertiesObject(InstrumentationService.properties.NumDraftShifts, numDraftShifts)
            ]));
        }
    }

    /**
     * Instrument a paste event.  Report the number of different types of shifts that will be added and deleted
     * @param shiftsToAdd
     * @param preUpdatedShifts shifts to update in their original pre-updated form.  There is a direct mapping between these and the postUpdatedShift
     * @param postUpdatedShifts shift to update in their resulting, updated form.
     * @param shiftsToDelete
     * @param numCellsSelected
     * @param isKeyboardShortcut
     * @param instrumentScheduleEvent
     */
    private instrumentPaste(shiftsToAdd: IBaseShiftEntity[],
        preUpdatedShifts: IBaseShiftEntity[],
        postUpdatedShifts: IBaseShiftEntity[],
        shiftsToDelete: IBaseShiftEntity[],
        numCellsSelected: number,
        isKeyboardShortcut: boolean,
        instrumentScheduleEvent: InstrumentScheduleEventFunction) {

        const numAdd: number = shiftsToAdd ? shiftsToAdd.length : 0;
        const numDelete: number = shiftsToDelete ? shiftsToDelete.length : 0;
        const numUpdate: number = postUpdatedShifts ? postUpdatedShifts.length : 0;

        let numPrevDraft: number = 0;
        let numPrevShared: number = 0;
        let numPrevHasActivities: number = 0;
        let numPrevOpenShifts: number = 0;

        let numPostHasActivities: number = 0;
        let numPostOpenShifts: number = 0;

        const postShiftForEachFunction = (shift: IBaseShiftEntity) => {
            if (shift) {
                if (ShiftUtils.isOpenShift(shift)) {
                    numPostOpenShifts++;
                }

                if (ShiftUtils.hasActivities(shift)) {
                    numPostHasActivities++;
                }
            }
        };
        if (shiftsToAdd) {
            shiftsToAdd.forEach(postShiftForEachFunction);
        }
        if (postUpdatedShifts) {
            postUpdatedShifts.forEach(postShiftForEachFunction);
        }
        const prevShiftForEachFunction = (shift: IBaseShiftEntity) => {
            if (shift) {
                if (ShiftUtils.isOpenShift(shift)) {
                    numPrevOpenShifts++;
                }
                ShiftUtils.shiftHasUnsharedEdits(shift)
                    ? numPrevDraft++
                    : numPrevShared++;

                if (ShiftUtils.hasActivities(shift)) {
                    numPrevHasActivities++;
                }
            }
        };
        if (shiftsToDelete) {
            shiftsToDelete.forEach(prevShiftForEachFunction);
        }
        if (preUpdatedShifts) {
            preUpdatedShifts.forEach(prevShiftForEachFunction);
        }
        instrumentScheduleEvent(InstrumentationService.events.Shift_Paste, [
            getGenericEventPropertiesObject(InstrumentationService.properties.NumCellsSelected, numCellsSelected),
            getGenericEventPropertiesObject(InstrumentationService.properties.IsKeyboardShortcut, isKeyboardShortcut),
            getGenericEventPropertiesObject(InstrumentationService.properties.NumPrevDraft, numPrevDraft),
            getGenericEventPropertiesObject(InstrumentationService.properties.NumPrevShared, numPrevShared),
            getGenericEventPropertiesObject(InstrumentationService.properties.NumAdd, numAdd),
            getGenericEventPropertiesObject(InstrumentationService.properties.NumDelete, numDelete),
            getGenericEventPropertiesObject(InstrumentationService.properties.NumUpdate, numUpdate),
            getGenericEventPropertiesObject(InstrumentationService.properties.NumPrevHasActivities, numPrevHasActivities),
            getGenericEventPropertiesObject(InstrumentationService.properties.NumPostHasActivities, numPostHasActivities),
            getGenericEventPropertiesObject(InstrumentationService.properties.NumPrevOpenShifts, numPrevOpenShifts),
            getGenericEventPropertiesObject(InstrumentationService.properties.NumPostOpenShifts, numPostOpenShifts)
        ]);
    }

    /**
     * Returns true if the specified cell intersects with the SelectionBox rectangle
     * @param cell schedule cell
     * @param selectionBox selection box
     */
    private isCellOverlapWithSelectionBox(cell: FlexiRowCellSettings, selectionBox: SelectionBox): boolean {
        let isOverlap = false;
        // The selection set may include  dummy items used to keep the total number of data selection indices constant between pastes
        // These items will not contain a cellContentsItem
        if (cell.cellContentsItem) {
            const cellColIdxVal: number = cell.cellContentsItem.colIdxVal;
            const cellRow: number = ScheduleGridSelection.getCellRowFromIndex(cellColIdxVal, this.getColumnsPerRow());
            // First check if the cell is inside the rows covered by the SelectionBox
            if ((selectionBox.rowRange.firstIndex <= cellRow) && (cellRow <= selectionBox.rowRange.lastIndex)) {
                // Check if the cell intersects with the selected range within the row
                const cellFirstColumn: number = ScheduleGridSelection.getCellColumnFromIndex(cellColIdxVal, this.getColumnsPerRow());
                const cellLastColumn: number = this.getEndColumnIndexForCell(cellFirstColumn, cell.cellContentsItem.cellUnitSize);

                const isCellColumnStartInRegion = (selectionBox.columnRange.firstIndex <= cellFirstColumn) && (cellFirstColumn <= selectionBox.columnRange.lastIndex);
                const isCellColumnEndInRegion = (selectionBox.columnRange.firstIndex <= cellLastColumn) && (cellLastColumn <= selectionBox.columnRange.lastIndex);
                const isCellColumnContainsRegion = (cellFirstColumn < selectionBox.columnRange.firstIndex) && (selectionBox.columnRange.lastIndex < cellLastColumn);
                isOverlap = isCellColumnStartInRegion || isCellColumnEndInRegion || isCellColumnContainsRegion;
            }
        }
        return isOverlap;
    }

    /**
     * Calculate selected cell ranges from the collection of selected cells
     * @param selectedCells array of selected cells
     */
    private calculateSelectedCellRangeInfo(selectedCells: FlexiRowCellSettings[]): SelectedCellRangeInfo {
        const numColumnsPerRow = this.getColumnsPerRow();

        let regionRowMin = 0;
        let regionRowMax = 0;
        let columnRangesForRows: Map<number, SelectedRowRangeInfo> = new Map();

        selectedCells.forEach((selectedCell: FlexiRowCellSettings, index: number) => {
            const colIdxVal = selectedCell.cellContentsItem.colIdxVal;
            const cellUnitSize = selectedCell.cellContentsItem.cellUnitSize;
            const cellRowIndex = ScheduleGridSelection.getCellRowFromIndex(colIdxVal, numColumnsPerRow);
            const cellStartColumnIndex = ScheduleGridSelection.getCellColumnFromIndex(colIdxVal, numColumnsPerRow);
            const cellEndColumnIndex = this.getEndColumnIndexForCell(cellStartColumnIndex, cellUnitSize);

            // Row range computation for the selection
            if (index === 0) {
                regionRowMin = cellRowIndex;
                regionRowMax = cellRowIndex;
            } else {
                regionRowMin = Math.min(regionRowMin, cellRowIndex);
                regionRowMax = Math.max(regionRowMax, cellRowIndex);
            }

            // Calculate the column ranges for each row in the selection
            let columnRangeForCellRow: SelectedRowRangeInfo | undefined = columnRangesForRows.get(cellRowIndex);
            if (columnRangeForCellRow) {
                if (cellStartColumnIndex < columnRangeForCellRow.columnRange.firstIndex) {
                    // Expand the calculated row range to the left
                    columnRangeForCellRow.columnRange.firstIndex = cellStartColumnIndex;
                    columnRangeForCellRow.firstCellColumnRange = {
                        firstIndex: cellStartColumnIndex,
                        lastIndex: cellEndColumnIndex
                    };
                } else if (cellEndColumnIndex > columnRangeForCellRow.columnRange.lastIndex) {
                    // Expand the calculated row range to the right
                    columnRangeForCellRow.columnRange.lastIndex = cellEndColumnIndex;
                    columnRangeForCellRow.lastCellColumnRange = {
                        firstIndex: cellStartColumnIndex,
                        lastIndex: cellEndColumnIndex
                    };
                }
            } else {
                // Create a fresh new row entry if needed
                columnRangeForCellRow = {
                    columnRange: {
                        firstIndex: cellStartColumnIndex,
                        lastIndex: cellEndColumnIndex
                    },
                    firstCellColumnRange: {
                        firstIndex: cellStartColumnIndex,
                        lastIndex: cellEndColumnIndex
                    },
                    lastCellColumnRange: {
                        firstIndex: cellStartColumnIndex,
                        lastIndex: cellEndColumnIndex
                    }
                };
            }
            columnRangesForRows.set(cellRowIndex, columnRangeForCellRow);
        });

        let selectedCellRangeInfo: SelectedCellRangeInfo = {
            rowRange: {
                firstIndex: regionRowMin,
                lastIndex: regionRowMax
            },
            columnRangesForRows: columnRangesForRows
        };

        return selectedCellRangeInfo;
    }

    /**
     * Calculate the end column index for a specified cell
     * @param cellStartColumnIndex start column index for the cell
     * @param cellUnitSize cell width
     */
    private getEndColumnIndexForCell(cellStartColumnIndex: number, cellUnitSize: number): number {
        return Math.min(cellStartColumnIndex + cellUnitSize - 1, this.getColumnsPerRow() - 1);
    }

    /**
     * Returns true if schedule cell selections are based on hours (eg, for Day view).
     * Returns false if schedule cell selections are based on days.
     * This difference is important when dealing with daylight savings transitions. For example, for Week/Month views when
     * calculating time differences between schedule cells, we should work in terms of days rather than hours, because the
     * number of hours may vary due to daylight savings transitions.
     */
    private isSelectionForHours(): boolean {
        return this._settings.scheduleCalendarType === ScheduleCalendarTypes.Day;
    }

    /**
     * Returns the unit of time type used for duration calculations
     */
    private durationOffsetTypeName(): unitOfTime.Base {
        return this.isSelectionForHours() ? "hours" : "days";
    }

    /**
     * Calculate the duration difference between two moments
     * The duration units depend on the type of time units being used for the selection.
     */
    private getDurationDifferenceFromMoments(startTime: Moment, endTime: Moment): number {
        let durationDifference: number =
            this.isSelectionForHours() ?
            DateUtils.getDifferenceInHoursFromMoments(startTime, endTime) :
            // Round to the nearest day. We do this because day difference calculations may not
            // yield whole number values because days actually end slightly before midnight.
            Math.round(DateUtils.getDifferenceInDaysFromMoments(startTime, endTime));
        return durationDifference;
    }

    /**
     * Returns a copy of the array where all dummy selection cells have been removed
     */
    private static filterOutDummySelectableItems(orderedSelectableCells: FlexiRowCellSettings[]): FlexiRowCellSettings[] {
        return orderedSelectableCells.filter((cell: FlexiRowCellSettings) => {
            return cell.cellElementKey !== DUMMY_SELECTION_CELL_KEY;
        });
    }

    /**
     * Sets the selection to the specified cell index.
     * @param selectedCellIndex the index of the cell to select
     */
    public static setSelectedCell(selectedCellIndex: string): void {
        setSelectedCells([selectedCellIndex], true);
    }
}
