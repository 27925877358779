import { IOpenShiftEntity } from "sh-models";
import { mutatorAction } from "satcheljs";
import { ShiftEditorViewStateSchema } from "..";

export default mutatorAction("setOpenShiftInStore", function setOpenShiftInStore(
    store: ShiftEditorViewStateSchema,
    openShift: IOpenShiftEntity
) {
    if (store) {
        store.openShift = openShift;
    }
});