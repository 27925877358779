import RestClient from "sh-rest-client";
import { DataProvider } from "sh-services/dataproviders/DataProvider";
import { ILocateUserResponseEntity } from "sh-models";
import { IUserDatabase } from "sh-services/data/IUserDatabase";
import { updateLocateUserResponse, UserStore } from "sh-stores";

/**
 * UserSettings Data Provider
 */
export class LocateUserResponseDataProvider extends DataProvider<ILocateUserResponseEntity> {

    protected loggedInUserId: string;
    protected userDatabase: IUserDatabase;

    /**
     * Constructor
     * @param loggedInUserId Logged in user ID
     * @param userDatabase User Database
     */
    constructor(loggedInUserId: string, userDatabase: IUserDatabase) {
        super();
        this.loggedInUserId = loggedInUserId;
        this.userDatabase = userDatabase;
    }

    /**
     * Return data if it's found in memory (otherwise return undefined)
     */
    async getDataFromMemory(): Promise<ILocateUserResponseEntity> {
        return UserStore().locateUserResponse;
    }

    /**
     * Return data if it's found in the database (otherwise return undefined)
     */
    async getDataFromDatabase(): Promise<ILocateUserResponseEntity> {
        return await this.userDatabase.getLocateUserResponse(this.loggedInUserId);
    }

    /**
     * Make an HTTP request to fetch the data from the network
     */
    async getDataFromNetwork(): Promise<ILocateUserResponseEntity> {
        return await RestClient.locateUserRegion();
    }

    /**
     * Set data in memory
     */
    async setDataInMemory(locateUserResponse: ILocateUserResponseEntity) {
        updateLocateUserResponse(locateUserResponse);
    }

    /**
     * Set data in the database
     */
    async setDataInDatabase(data: ILocateUserResponseEntity) {
        return await this.userDatabase.setLocateUserResponse(data);
    }

    /**
     * Make a network call to update the data
     */
    async saveDataToNetwork(data: ILocateUserResponseEntity): Promise<ILocateUserResponseEntity> {
        throw new Error("LocateUserResponseDataProvider.saveDataToNetwork not implemented");
    }

    /**
     * Delete data if it's found in memory
     */
    async hardDeleteDataFromMemory(): Promise<void> {
        updateLocateUserResponse(null);
    }

    /**
     * Delete data if it's found in the database
     */
    async hardDeleteDataFromDatabase(): Promise<void> {
        return await this.userDatabase.deleteLocateUserResponse(this.loggedInUserId);
    }
}