import * as moment from "moment";
import { IDropdownOption } from "@fluentui/react";
import { trace } from "owa-trace";
import StringsStore from "sh-strings/store";

const timeZones = require("./assets/timezoneMapping.json");
const defaultUserTimezone = "America/Los_Angeles";

/*
* TimeZoneUtils loads the timezone mapping from timezoneMapping.json.
* timezoneMapping uses the below format to store timezone.
* zoneId: {
*    name : TimeZone Name,
*    regions: {
*        olsonCode : regionId
*    }
*   }
* zoneId : key to read the localized value of Time Zone name from string.json
* name: its the unlocalized name of the timezone, passed to UI and stored in state.
* regions: sorted list key value pair of olsonCode/regionId
* olsonCode: olsonCode of the region which is passed to service in unlocalized format.
* regionId: the city name id reference to read localized value from string.json
*/
export interface TimeZoneItem {
    name: string;
    regions: any;
}

/**
 * Class for converting time zones between formats
 */
export default class TimeZoneUtils {

    /**
     * Helper function which gets the user friendly timezone name from the olson code.
     * For example: "(GMT-08:00) Pacific Time (US & Canada)" for Los_Angeles
     * For localization , the time zone is read by the key id
     * eg: "moroccoStandardTime" is key to read time zone from strings.json
     * @param olsonCode The OLSON code. Ex: Los_Angeles
     */
    public static getTimeZoneNameFromOlsonCode(olsonCode: string): string {
        let zoneId: string = this.getTimeZoneIdFromOlsonCode(olsonCode);
        return zoneId ? StringsStore().registeredStringModules.get("timeZoneMapping").strings.get(zoneId) : "";
    }

    /*
    * [ 'Asia/Kolkata','Asia/Chongqing', 'Asia/Harbin',
    * 'Asia/Kashgar', 'Antarctica/South_Pole', 'America/Shiprock' ]
    * Above timezones are not supported/depricated from unicode.org windowsZones.xml.
    * Hence configuring fallbacks for these specific set of timezones.
    * These fallbacks are chosen with respect to nearest city/country.
    *
    * These are the fall back for deprecated timezones
    * ['PST8PDT','MST7MDT','CST6CDT','EST5EDT']
    * https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
    */
    public static replaceInvalidOlsonCodes(olsonCode: string): string {
        switch (olsonCode) {
            case 'Asia/Kolkata':
                return 'Asia/Calcutta';
            case 'Asia/Chongqing':
                return 'Asia/Shanghai';
            case 'Asia/Harbin':
                 return 'Asia/Shanghai';
            case 'Asia/Kashgar':
                return 'Asia/Shanghai';
            case 'Antarctica/South_Pole':
                return 'Antarctica/McMurdo';
            case 'America/Shiprock':
                return 'America/Yellowknife';
            case 'PST8PDT':
                return 'America/Los_Angeles';
            case 'MST7MDT':
                return 'America/Denver';
            case 'CST6CDT':
                return 'America/Chicago';
            case 'EST5EDT':
                return 'America/New_York';
            default:
                return olsonCode;
        }
    }

    public static getTimeZoneIdFromOlsonCode(olsonCode: string): string {
        if (olsonCode) {
            olsonCode = this.replaceInvalidOlsonCodes(olsonCode);
            let zoneId: string = Object.keys(timeZones).find((zoneId) => {
                return this.getOlsonCodeListFromZone(timeZones[zoneId]).indexOf(olsonCode) > -1;
            });
            return zoneId ? zoneId : "";
        }
        return "";
    }

    /**
     * Gets the time zone object (of type TimeZoneItem) from the zone name
     * @param zoneName The Team timezone name
     */
    public static getZoneFromId(zoneId: string): TimeZoneItem {
        if (zoneId) {
            return timeZones[zoneId];
        }

        return null;
    }

    /**
     * Gets the list of all time zones as input to the DropDown (key, and text)
     * key is the unlocalised zone name, while text and title are localized.
     * For localization the time zone is read by the zone id
     * eg: "moroccoStandardTime" is the key to read time zone from strings.json
     */
    public static getZonesAsDropdownOptions(): IDropdownOption[] {
        return Object.keys(timeZones).map((zoneId) => {
            let localizedZoneName = StringsStore().registeredStringModules.get("timeZoneMapping").strings.get(zoneId);
            return {"key": zoneId, "text": localizedZoneName, "title": localizedZoneName};
         });
    }

    /**
     * @param zone
     * Returns list of Olson codes for a given zone.
     */
    private static getOlsonCodeListFromZone(zone: TimeZoneItem): any {
        return Object.keys(zone.regions).map((regionOlsonCode: string) => {return regionOlsonCode; });
    }

    /**
     * @param zone
     * Returns first or default Olson code for a given zone.
     */
    public static getFirstOrDefaultOlsonCodeFromZone(zone: TimeZoneItem): any {
        if (zone) {
            const regionsOlsonCode: string = Object.keys(zone.regions)[0];
            return regionsOlsonCode ? regionsOlsonCode : undefined;
        }
    }

    /*
     * Gets the list of closest regions (as input to a DropDown) for the current timezone in the view state.
     * For localization , the cities are read wth the key value of olsoncode
     * eg: for "Africa/Casablanca": "Casablanca" , "Casablanca" is key to read the city from strings.json
     * If new regions are added in timezoneMappings.json, they need to be added in sorted manner.
     */
    public static getRegions(timeZoneId: string): IDropdownOption[] {
        const zone = this.getZoneFromId(timeZoneId);
        if (zone) {
            let regions = Object.keys(zone.regions).map(
                (regionOlsonCode: string) => {
                const region: string = zone.regions[regionOlsonCode as keyof typeof zone.regions].toString();
                const localizedRegion = StringsStore().registeredStringModules.get("timeZoneMappingRegions").strings.get(region);
                return { key: regionOlsonCode , text: localizedRegion, title: localizedRegion};
                });
            // Sort alphabetically on the text of the time zone region drop down list.
            let sortedRegions = regions.sort((region1, region2) => { return region1.text > region2.text ? 1 : -1; });
            return sortedRegions;
        }
        return [];
    }

    /**
     * get the browser time zone olson code.  moment.tz.guess() uses a cached value from the first page load so
     * it will never update until page refreshes. resolvedOptions() will not get a timeZone property on internet explorer.
     */
    public static getBrowserOlsonCode(): string {
        let browserTimeZone;
        try {
            /* resolvedOptions() returns a new object with properties reflecting the locale and date and time formatting options
            computed during the initialization of the given DateTimeFormat object.
            Take from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/DateTimeFormat/resolvedOptions.
            Experimentally, it's up to date whereas moment.guess is taken from a value initialized once */
            let dateTimeFormat;
            let resolvedOptions;
            let timeZone;
            if (window.Intl
                && (dateTimeFormat = window.Intl.DateTimeFormat())
                && (resolvedOptions = dateTimeFormat.resolvedOptions())
                && (timeZone = resolvedOptions.timeZone)) {
                browserTimeZone = timeZone;
            }
        } catch (e) {
            trace.error("TimeZoneUtils: Intl.DateTimeFormat().resolvedOptions().timeZone errored");
            browserTimeZone = "";
        }
        if (!browserTimeZone) {
            try {
                browserTimeZone = moment.tz.guess();
            } catch (e) {
                trace.error("TimeZoneUtils: moment.tz.guess() failed");
                browserTimeZone = "";
            }
            if (!browserTimeZone) {
                browserTimeZone = defaultUserTimezone;
            }
        }
        return browserTimeZone;
    }
}