import { action } from "satcheljs";
import { IShiftRequestEntity } from "sh-models";

const deleteShiftRequest = action(
    "DELETE_SHIFT_REQUEST",
    (
        shiftRequest: IShiftRequestEntity,
        onFinish?: VoidFunction
    ) => ({
        shiftRequest: shiftRequest,
        onFinish: onFinish
    })
);

export default deleteShiftRequest;
