import * as moment from "moment";
import RestClient from "sh-rest-client";
import { AppSettingsClientModel } from "sh-models";
import { AppSettingsStore, setAppSettingsForTenant } from "sh-appsettings-store";
import { DataProvider } from "sh-services/dataproviders/DataProvider";
import { ITenantDatabase } from "sh-services/data/ITenantDatabase";

/**
 * Tenant AppSettings Data Provider
 */
export class TenantAppSettingsDataProvider extends DataProvider<AppSettingsClientModel> {

    protected tenantDatabase: ITenantDatabase;
    protected tenantId: string;

    constructor(tenantDatabase: ITenantDatabase, tenantId: string) {
        super();
        this.tenantDatabase = tenantDatabase;
        this.tenantId = tenantId;
    }

    /**
     * Return data if it's found in memory (otherwise return undefined)
     */
    async getDataFromMemory() {
        return AppSettingsStore().tenantSettings;
    }

    /**
     * Return data if it's found in the database (otherwise return undefined)
     */
    async getDataFromDatabase() {
        return await this.tenantDatabase.getTenantAppSettings(this.tenantId);
    }

    /**
     * Make an HTTP request to fetch the data from the network
     */
    async getDataFromNetwork() {
        return await RestClient.getAppSettingsForTenant(this.tenantId);
    }

    /**
     * Set data in memory
     */
    async setDataInMemory(data: AppSettingsClientModel) {
        setAppSettingsForTenant(data);
    }

    /**
     * Set data in the database
     */
    async setDataInDatabase(data: AppSettingsClientModel) {
        return await this.tenantDatabase.setTenantAppSettings(data);
    }

    /**
     * Make a network call to update the data
     */
    async saveDataToNetwork(data: AppSettingsClientModel): Promise<AppSettingsClientModel> {
        throw new Error("TenantAppSettingsDataProvider.saveDataToNetwork not implemented");
    }

    /**
     * Whether to skip refreshing data from network if it was only in the database and not in memory
     * This method gets called when we don't find the data in memory, and find something in the database.
     * Because we can't always trust that the data from the database was in-sync and not missing changes from the service,
     * this method allows the provider to inspect the state of data from the db and
     * determine if we need to make a network call to refresh this data asynchronously.
     */
    async skipRefreshFromNetworkIfNotInMemory(data: AppSettingsClientModel): Promise<boolean> {
        if (data && data.nextPollTime && data.nextPollTime.diff) {
            const durationUntilNextPollInMs: number = data.nextPollTime.diff(moment(), "milliseconds");
            // skip refresh from network if the next poll time is in the future
            return durationUntilNextPollInMs > 0;
        }
        return false;
    }
}