
import type {
    ApplicationInsights,
    IEventProperty,
    IExtendedConfiguration,
    IExtendedTelemetryItem,
    IPlugin,
  } from "@microsoft/1ds-shared-analytics-js";
import { ApplicationInsightsManager, ValueKind } from '@microsoft/1ds-shared-analytics-js';
import InstrumentationUtils from 'sh-application/utility/InstrumentationUtils';
import { UserStorageKeys } from 'sh-models';
import { InstrumentationService, UserStorageService } from 'sh-services';
import { UserStorageServiceScope } from 'sh-services/lib/UserStorageServiceScope';

import {
    getGenericEventPropertiesObject, IInstrumentationLogger, InstrumentationEventPropertyInterface,
    logLevel, PiiKind
} from '../..';
import { loadTelemetryRegion } from '../../loadTelemetryConfig';

import { OneDsLoggerInitConfig } from './OneDsLoggerInitConfig';

/**
 * Mapping of PiiKind to 1DS ValueKind.
 */
const piiKindToValueKind = {
    [PiiKind.Identity]: ValueKind.Pii_Identity,
    [PiiKind.DistinguishedName]: ValueKind.Pii_DistinguishedName,
    [PiiKind.Fqdn]: ValueKind.Pii_Fqdn,
    [PiiKind.GenericData]: ValueKind.Pii_GenericData,
    [PiiKind.IPV4Address]: ValueKind.Pii_IPV4Address,
    [PiiKind.IPV4AddressLegacy]: ValueKind.Pii_IPV4AddressLegacy,
    [PiiKind.IPv6Address]: ValueKind.Pii_IPv6Address,
    [PiiKind.MailSubject]: ValueKind.Pii_MailSubject,
    [PiiKind.NotSet]: ValueKind.NotSet,
    [PiiKind.PhoneNumber]: ValueKind.Pii_PhoneNumber,
    [PiiKind.QueryString]: ValueKind.Pii_QueryString,
    [PiiKind.SipAddress]: ValueKind.Pii_SipAddress,
    [PiiKind.SmtpAddress]: ValueKind.Pii_SmtpAddress,
    [PiiKind.Uri]: ValueKind.Pii_Uri
};

/**
 * One DS Logger.
 */
export class OneDsLogger implements IInstrumentationLogger {

    private oneDsManager: ApplicationInsightsManager = new ApplicationInsightsManager();
    private sharedConfig: IExtendedConfiguration = {};

    /**
     * Handles general usage instrumentation (UserBI, scenario) logging.
     */
    private userBiLogger: ApplicationInsights;

    /**
     * Handles performance instrumentation logging.
     */
    private perfLogger: ApplicationInsights;

    /**
     * Perf logging enabled flag.
     */
    private perfLoggingEnabled: boolean;

    /**
     * The global performance logging level.
     */
    private globalPerfLogLevel: logLevel;

    /**
     * Custom Dimensions that will be logged along with every event logged.
     */
    private commonCustomDimensions: { [key: string]: string | number | boolean | string[] | number[] | boolean[] | IEventProperty | object } = {};

    /**
     * Instrumentation Attribute Values.
     * See: https://domoreexp.visualstudio.com/Teamspace/_wiki/wikis/Teamspace.wiki/2933/User-BI-Schema.
     */
    private values = {
        App_Id_Shifts: "42f6c1da-a241-483a-a3cc-4f5be9185951",
        App_Name_Shifts: "Shifts",
        App_IsTenantApp: false,
        App_ScenarioCapability: 0,                                  // 0 represents "PersonalApp" 1 represents "Bot" 2 represents "Tab" 3 represents "MessagingExtension" 4 represents ""Connectors"
        App_Scope: 2,                                               // 0 represents "General" 1 represents "Team" 2 represents "Personal" 4 represents "Group" 8 represents "Meeting"
        AppInfo_Name_Shifts: "Teams.Shifts",                        // GDPRS related, should not be changed
        AppInfo_Platform_Web: "Web",                                // GDPRS related, should not be changed
        UserInfo_IdType_UserObjectId: "UserObjectId"                // GDPRS related, should not be changed
    };

    /**
     * Instrumentation Attribute Properties ("Key").
     */
    private properties = {
        App_Id: "App.Id",
        App_Name: "App.Name",
        App_IsTenantApp: "App.IsTenantApp",
        App_ScenarioCapability: "App.ScenarioCapability",
        App_Scope: "App.Scope",
        App_CapabilityId: "App.CapabilityId",
        AppInfo_Version: "AppInfo.Version",
        AppInfo_Language: "AppInfo.Language",
        AppInfo_Name: "AppInfo.Name",                       // GDPRS related, should not be changed
        AppInfo_Platform: "AppInfo.Platform",               // GDPRS related, should not be changed
        UserInfo_Id: "UserInfo.Id",                         // GDPRS related, should not be changed
        UserInfo_IdType: "UserInfo.IdType",                 // GDPRS related, should not be changed
        UserInfo_OMSTenantId: "UserInfo.OMSTenantId",       // GDPRS related, should not be changed
        UserInfo_TenantId: "UserInfo.TenantId"              // GDPRS related, should not be changed
    };

    /**
     * Initialize the logger.
     * @param config Configuration to initialize the logger.
     */
    public async init(config: OneDsLoggerInitConfig): Promise<void> {

        this.globalPerfLogLevel = config.globalPerfLogLevel;
        this.perfLoggingEnabled = config.perfLoggingEnabled;

        const collectorUri = await this.getCollectorUri(config);

        this.sharedConfig = {
            instrumentationKey: config.oneDsUserBiInstrumentationKey,
            endpointUrl: collectorUri,
            extensions: [
            ],
            extensionConfig: [],
            propertyConfiguration: {
              populateBrowserInfo: true,
              populateOperatingSystemInfo: true,
              sessionAsGuid: true
            },
            channelConfiguration: {
              payloadPreprocessor: undefined,
              eventsLimitInMem: undefined,
              autoFlushEventsLimit: undefined,
              ignoreMc1Ms0CookieProcessing: true,
              enableCompoundKey: true
            },
            maxMessageLimit: 0
        };

        // TODO: Configure privacy guard
        const sharedExtensions: IPlugin[] = [];

        // Initialize the log manager
        this.oneDsManager.create(this.sharedConfig, sharedExtensions);

        // Initialize the loggers
        this.userBiLogger = this.oneDsManager.newInst(config.oneDsUserBiInstrumentationKey);
        this.perfLogger  = this.oneDsManager.newInst(config.oneDsPerfInstrumentationKey);

        // set the app properties
        this.setCustomDimension(getGenericEventPropertiesObject(this.properties.App_Id, this.values.App_Id_Shifts));
        this.setCustomDimension(getGenericEventPropertiesObject(this.properties.App_Name, this.values.App_Name_Shifts));
        this.setCustomDimension(getGenericEventPropertiesObject(this.properties.App_IsTenantApp, this.values.App_IsTenantApp));
        this.setCustomDimension(getGenericEventPropertiesObject(this.properties.App_ScenarioCapability, this.values.App_ScenarioCapability));
        this.setCustomDimension(getGenericEventPropertiesObject(this.properties.App_Scope, this.values.App_Scope));
        this.setCustomDimension(getGenericEventPropertiesObject(this.properties.App_CapabilityId, this.values.App_Id_Shifts));

        // Set the extra custom dimensions that Aria needs for GDPR
        this.setCustomDimension(getGenericEventPropertiesObject(this.properties.AppInfo_Name, this.values.AppInfo_Name_Shifts));
        this.setCustomDimension(getGenericEventPropertiesObject(this.properties.AppInfo_Platform, this.values.AppInfo_Platform_Web));
        this.setCustomDimension(getGenericEventPropertiesObject(InstrumentationService.customDimensionProperties.AppInfo_ClientType, this.values.AppInfo_Platform_Web));
    }

    /**
     * Returns the collector URI based on the telemetry region code.
     * @param config Configuration to initialize the logger.
     * @returns The collector url.
     */
    private async getCollectorUri(config: OneDsLoggerInitConfig): Promise<string | undefined>  {
        const { eudbTelemetryConfig, collectorConfigs } = config;
        let collectorUri = "";
        const currentRegion = eudbTelemetryConfig.currentTelemetryRegionCode ?? await this.getCurrentTelemetryRegion(config.tenantId);

        let defaultCollectorConfig: string = undefined;

        for (const collectorConfig of collectorConfigs) {
            if (!collectorConfig.Region) {
                // If there is no region specified, this is the default CollectorUrl
                defaultCollectorConfig = collectorConfig.CollectorUrl;
            } else if (currentRegion?.toLowerCase() == collectorConfig.Region.toLowerCase()) {
                // If the current region matches the region specified in the config, use that CollectorUrl
                collectorUri = collectorConfig.CollectorUrl;
                break;
            }
        }

        if (!collectorUri && defaultCollectorConfig) {
            collectorUri = defaultCollectorConfig;
        }
        return collectorUri;
    }

    /**
     * Returns the current telemetry region for the tenant.
     * TODO: This should be moved to a data service + data provider.
     * @param tenantId Tenant Id.
     */
    private async getCurrentTelemetryRegion(tenantId: string): Promise<string> {
        const cachedRegion = UserStorageService.getItem(UserStorageKeys.TelemetryRegion, {
            scope: UserStorageServiceScope.CurrentTenant
        });

        if (cachedRegion) {
            setTimeout((): void => {
                this.updateCurrentTelemetryRegion(tenantId);
            }, 5000 /*updateTelemetryRegionCacheDelayMs */);
        }

        return cachedRegion ?? await this.updateCurrentTelemetryRegion(tenantId);
    }

    /**
     * Updates the current telemetry region for the tenant.
     * TODO: This should be moved to a data service + data provider.
     * @param tenantId Tenant Id.
     */
    private async updateCurrentTelemetryRegion(tenantId: string): Promise<string> {
        const telemetryRegion = await loadTelemetryRegion(tenantId);

        UserStorageService.setItem(UserStorageKeys.TelemetryRegion, telemetryRegion, {
            scope: UserStorageServiceScope.CurrentTenant
        });

        return telemetryRegion;
    }

    /**
     * Converts InstrumentationEventPropertyInterface to IEventProperty.
     * @param eventData Event data.
     * @returns The converted EventProperty.
     */
    public convertEventDataToEventProperty(eventData: InstrumentationEventPropertyInterface): IEventProperty | string | number | boolean {
        // OneDS logs null or undefined as raw strings, we just want empty strings.
        // This additionally aligns with webclient
        const modifiedValue = (eventData.value === null || eventData.value === undefined) ? '' : eventData.value;

        const eventProperty: IEventProperty | string | number | boolean = eventData.piiKind === PiiKind.NotSet ? modifiedValue : {
            value: modifiedValue,
            kind: piiKindToValueKind[eventData.piiKind]
        };

        return eventProperty;
    }

    /**
     * Set App Version as custom dimension.
     * @param version The app version.
     */
    public setAppVersion(version: string): void {
        if (version) {
            this.setCustomDimension(getGenericEventPropertiesObject(this.properties.AppInfo_Version, version));
            this.setCustomDimension(getGenericEventPropertiesObject(this.properties.AppInfo_Language, window.sa.currentUICulture));
        }
    }

    /**
     * Instrument a PanelAction UserBI event.
     * @param scenarioType Scenario type.
     * @param eventDataArray Event data array.
     * @param measurements Performance measurements.
     */
    public logEvent(scenarioType: string, eventDataArray?: InstrumentationEventPropertyInterface[], measurements?: { [name: string]: number; }): void {
        if (this.userBiLogger) {
            // event name is alyways userbi
            const eventName = InstrumentationService.events.UserBI;
            // event type is panelaction
            const baseType = InstrumentationService.eventTypes.PanelAction;
            eventDataArray = eventDataArray || [];

            // add all properties including common custom dimensions into databag
            const telemetryItem: IExtendedTelemetryItem = this.createTelemetryPayload(eventName, baseType, eventDataArray, measurements);

            // we need to log scenarioType and scenario as top level properties to distinguish the events.
            // From OneDsLogger consumers, there are some ARIA events that are being logged with eventName in format of scenarioType_scenario.
            // For those events, split eventName and log scenarioType and scenario separately.
            // There are few other events, that are being logged with eventName at top level and eventType property within databag.
            // For those events, set eventName as ScenarioType and eventType as Scenario

            // if eventType is being passed as properties, use that as Scenario.
            if (telemetryItem.data[InstrumentationService.properties.EventType]) {
                telemetryItem.data[InstrumentationService.properties.ScenarioType] = scenarioType;
                telemetryItem.data[InstrumentationService.properties.Scenario] = telemetryItem.data[InstrumentationService.properties.EventType];
                delete telemetryItem.data[InstrumentationService.properties.EventType];
            } else {
                // if scenarioType is in format scenarioType_scenario (e.g., Publish_ShareTeam etc.,), extract scenario info and set it. else use event name as scenario type
                const scenarios: string[] = scenarioType.split('_');
                telemetryItem.data[InstrumentationService.properties.ScenarioType] = scenarios.length > 0 ? scenarios[0] : scenarioType;
                telemetryItem.data[InstrumentationService.properties.Scenario] = scenarios.length > 1 ? scenarios[1] : InstrumentationService.values.Null;
            }

            // Log the event to 1DS
            this.userBiLogger.track(telemetryItem);
        }
    }

    /**
     * Insturmnet a PageView UserBI event.
     * @param pageName The page name.
     * @param currentPageUri The current page URI.
     * @param eventDataArray Event data array.
     * @param measurements Performance measurements.
     * @param duration Duration.
     */
    public logPageView(pageName: string, currentPageUri?: string, eventDataArray?: InstrumentationEventPropertyInterface[], measurements?: { [name: string]: number; }, duration?: number): void {
        if (this.userBiLogger) {
            // event name is alyways userbi
            const eventName = InstrumentationService.events.UserBI;
            // event type is panelView
            const baseType = InstrumentationService.eventTypes.PanelView;
            eventDataArray = eventDataArray || [];

            // set panel type
            eventDataArray.push(getGenericEventPropertiesObject(InstrumentationService.properties.PanelType, pageName));
            // set page URI and referral source
            eventDataArray.push(getGenericEventPropertiesObject(InstrumentationService.properties.PageURL, InstrumentationUtils.NormalizeUrl(currentPageUri)));
            eventDataArray.push(getGenericEventPropertiesObject(InstrumentationService.properties.ReferralSource, InstrumentationUtils.NormalizeUrl(document.referrer)));

            // add all properties including common custom dimensions into databag
            const telemetryItem: IExtendedTelemetryItem = this.createTelemetryPayload(eventName, baseType, eventDataArray, measurements);

            // Log the event to 1DS
            this.userBiLogger.track(telemetryItem);
        }
    }

    /**
     * Log Perf Event to 1DS.
     * @param eventName Name of the event.
     * @param eventDataArray Array of eventPropertyObjects (each object has key, value, piiKind).
     * @param measurements Metrics associated with this event, displayed in Metrics Explorer on the portal. Defaults to empty.
     * @param perfLoggingLevel Perf event logging level.
     */
    public logPerfEvent(eventName: string, eventDataArray: Array<InstrumentationEventPropertyInterface>, measurements?: { [name: string]: number }, perfLoggingLevel: logLevel = logLevel.P1): void {
        // log the perf event only if perfScenario logging level is equal or higher priority than global perf logging level
        if (this.perfLoggingEnabled && perfLoggingLevel <= this.globalPerfLogLevel && this.perfLogger) {
            const baseType = InstrumentationService.eventTypes.Custom;
            const telemetryItem: IExtendedTelemetryItem = this.createTelemetryPayload(eventName, baseType, eventDataArray, measurements);
            this.perfLogger.track(telemetryItem);
        }
    }

    protected createTelemetryPayload(
        eventName: string, baseType?: string, eventDataArray?: Array<InstrumentationEventPropertyInterface>, measurements?: { [name: string]: number }
      ): IExtendedTelemetryItem {

        // Add the common custom dimensions
        const oneDsData: {[key: string]: string | number | boolean | string[] | number[] | boolean[] | IEventProperty | object } = { ...{}, ...this.commonCustomDimensions };

        // Add properties from the eventDataArray
        if (eventDataArray) {
            for (const property of eventDataArray) {
                if (!property) {
                    continue;
                }

                oneDsData[property.key] = this.convertEventDataToEventProperty(property);
            }
        }

        // Add measurements
        if (measurements) {
          for (const key of Object.keys(measurements)) {
            oneDsData[key] = measurements[key];
          }
        }

        // Convert milliseconds into UTC date object with UTC Time Zone, then into ISO format
        const epochToISOString = new Date().toISOString();

        return {
          name: eventName,
          baseType: baseType,
          time: epochToISOString,
          data: oneDsData
        };
    }

    /**
     * Start a new logging session in 1DS.
     */
    public startLogSession(): void {
        // 1DS doesn't need an extra call to start its session
    }

    /**
     * End existing logging session in 1DS.
     */
    public endLogSession(): void {
        // 1DS doesn't need an extra call to end its session
    }

    /**
     * Set custom dimension for instrumentation events.
     * Note:  Calling this will just update the custom dimension state that will be passed along with subsequent instrumentation events.
     * This will not trigger its own instrumentation network request, so it should be ok to call this multiple times.
     * @param eventData EventData has key, value, piiKind for custom dimension.
     * @param isGlobal Indicates if the custom dimension needs to be logged as global dimension or within internal data bag.
     */
    public setCustomDimension(eventData: InstrumentationEventPropertyInterface, isGlobal?: boolean): void {
        // cache the shared custom dimension for logging it with every event
        this.commonCustomDimensions[eventData.key] = this.convertEventDataToEventProperty(eventData);
    }

    /**
     * Clear custom dimension for instrumentation events.
     * @param key Key for event data.
     */
    public clearCustomDimension(key: string): void {
        // delete any shared custom dimensions
        delete this.commonCustomDimensions[key];
    }

    /**
     * Set UserId & TenantId as Custom dimensions so that all events performed by user could be grouped together.
     * @param userId User Id.
     * @param tenantId Tenant Id.
     */
    public setUserContext(userId: string, tenantId: string): void {
        // Set the user and tenant context
        this.setCustomDimension(getGenericEventPropertiesObject(this.properties.UserInfo_Id, userId));
        this.setCustomDimension(getGenericEventPropertiesObject(this.properties.UserInfo_IdType, this.values.UserInfo_IdType_UserObjectId));
        this.setCustomDimension(getGenericEventPropertiesObject(this.properties.UserInfo_OMSTenantId, tenantId));
        this.setCustomDimension(getGenericEventPropertiesObject(this.properties.UserInfo_TenantId, tenantId));
    }

    /**
     * Clear the user context so that subsequent events will not be grouped with user.
     */
    public clearUserContext(): void {
        this.setUserContext("", "");
    }

    /**
     * These are used for unit testing to expose the UserBi logger object.
     * @returns The userBiLogger.
     */
    public get _logger(): ApplicationInsights { return UNIT_TEST_MODE ? this.userBiLogger : undefined; }

    /**
     * These are used for unit testing to expose the Perf logger object.
     * @returns The perfLogger.
     */
    public get _perfLogger(): ApplicationInsights { return UNIT_TEST_MODE ? this.perfLogger : undefined; }

    /**
     * These are used for unit testing to expose the OneDsManager object.
     * @returns The oneDsManager.
     */
    public get _oneDsManager(): ApplicationInsightsManager { return UNIT_TEST_MODE ? this.oneDsManager : undefined; }

    /**
     * These are used for unit testing to expose the commonCustomDimensions object.
     * @returns The oneDsManager.
     */
    public get _commonCustomDimensions(): { [key: string]: string | number | boolean | string[] | number[] | boolean[] | IEventProperty | object } { return UNIT_TEST_MODE ? this.commonCustomDimensions : undefined; }
}
