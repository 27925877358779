import DateUtils from "sh-application/utility/DateUtils";
import schedulesViewStateStore from "../store/store";
import { Moment } from "moment";
import { mutatorAction } from "satcheljs";

/**
 * Marks a list of dates as newly edited or not
 *
 * @param dates
 * @param hasNewEdit
 */
export default mutatorAction("SET_DATES_WITH_NEW_EDITS",
    function setDatesWithNewEdits(dates: Array<Moment>, hasNewEdit: boolean) {
        const store = schedulesViewStateStore();
        store.datesWithNewEdits.clear();
        for (let i = 0; i < dates.length; i++) {
            store.datesWithNewEdits.set(DateUtils.fastCalculateDateIndex(dates[i]).toString(), hasNewEdit);
        }
});
