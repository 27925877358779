import { memberEditorViewState } from "../";
import { action } from "satcheljs/lib/legacy";

/**
 * Generic action that updates a isOpen boolean on a view state
 * @param isOpen - value for isOpen view state property
 */
export default action("setMemberDialogOpen")(
    function setMemberDialogOpen(isOpen: boolean) {
      memberEditorViewState().isOpen = isOpen;
    }
);