export type UserStorageKey =
    "AuthToken" |
    "MicrosoftTeamsContext" |
    "MicrosoftTeamsUserJoinedTeamsInfo" |
    "LastViewedSchedule" |
    "MostRecentTeamId" |
    "InstallId" |
    "ShareWithTeamAssignedShifts" |
    "ShareWithTeamOpenShifts" |
    "ShareWithTeamPreference" |
    "ScheduleGrouped" |
    "ShowStaffPer30Rows" |
    "TelemetryRegion";
/**
 * These enums are used as keys for the UserStorageService
 */
export enum UserStorageKeys {
    AuthToken = "AuthToken",
    MicrosoftTeamsContext = "MicrosoftTeamsContext",
    MicrosoftTeamsUserJoinedTeamsInfo = "MicrosoftTeamsUserJoinedTeamsInfo",
    LastViewedSchedule = "LastViewedSchedule",
    MostRecentTeamId = "MostRecentTeamId",
    InstallId = "InstallId",
    ShareWithTeamAssignedShifts = "ShareWithTeamAssignedShifts",
    ShareWithTeamOpenShifts = "ShareWithTeamOpenShifts",
    ScheduleGrouped = "ScheduleGrouped",
    ShowStaffPer30Rows = "ShowStaffPer30Rows",

    /**
     * The telemetry region
     */
    TelemetryRegion = "TelemetryRegion"
}