import { extendObservable } from "mobx";
import { ISubshiftEntity, SubshiftEntity } from "sh-models";
import { mutatorAction } from "satcheljs";
import { ShiftEditorViewStateSchema } from "..";

export default mutatorAction("updateActivityInShift", function updateActivityInShift(
    store: ShiftEditorViewStateSchema,
    updatedActivity: ISubshiftEntity
) {
    if (store && store.shift && store.shift.subshifts && updatedActivity) {
        const index: number = store.shift.subshifts.findIndex((subshift: ISubshiftEntity) => subshift.id === updatedActivity.id);
        if (index > -1) {
            // extendObservable (target, props) copies all props value pairs onto target and marks them as observable.
            // In this panel we need the ActivityFlexContainer to react to changes to the subshifts, so we mark every property of the subshifts as observable
            store.shift.subshifts.splice(index, 1, extendObservable(SubshiftEntity.clone(updatedActivity), {...updatedActivity}));
        }
    }
});