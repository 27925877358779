import * as microsoftTeams from "@microsoft/teams-js";
import ITeamDataService, { FilteredDataLoadedCallback } from "sh-services/dataservice/ITeamDataService";
import RestClient from "sh-rest-client";
import ShiftUtils from "sh-application/utility/ShiftUtils";
import { DataFilter, JobDataService, StaffHubErrorCodes } from "sh-services";
import { DataInDateRangeDataProvider } from "../dataproviders/DataInDateRangeDataProvider";
import { DataService } from "./DataService";
import { DayOfWeek } from "@fluentui/react";
import { ECSConfigKey, ECSConfigService, InstrumentationService, NoteDataService, ShiftDataService, UserDataService } from "sh-services";
import {
    IAvailabilityEntity,
    IDataInDateRange,
    IDataInDateRangeWithSyncState,
    IDataWithSyncState,
    IDateRange,
    IJobEntity,
    IScheduleProvisionResponse,
    IShiftEntity,
    ITeamInfoEntity,
    TeamDetailsResponse,
    TeamSettingEntity,
    UserSettingsEntity
} from "sh-models";
import { IDataProvider } from "sh-services/dataproviders/IDataProvider";
import { initBasicTeamData, TeamStore } from "sh-team-store";
import { Moment } from "moment";
import { ProgressiveDataInDateRangeDataProvider } from "../dataproviders/ProgressiveDataInDateRangeDataProvider";
import { ShiftsInDateRangeDataProvider } from "sh-services/dataproviders/ShiftsInDateRangeDataProvider";
import { ShiftStoreTypes } from "sh-application/../StaffHubConstants";
import { StaffHubError, StaffHubHttpError } from "sh-application";
import { TeamDataProvider } from "sh-services/dataproviders/TeamDataProvider";
import { TeamDetailsDataProvider } from "sh-services/dataproviders/TeamDetailsDataProvider";
import { TeamInformation } from "@microsoft/teams-js";
import { TeamsDataProvider } from "sh-services/dataproviders/TeamsDataProvider";
import { TeamSettingsStore } from "sh-teamsettings-store";
import { trace } from "owa-trace";
import { transaction } from "mobx";
import { UserSettingsDataProvider } from "sh-services/dataproviders/UserSettingsDataProvider";
import { MemberMapper } from "../../sh-models/member/mappers/MemberMapper";
import { ShareWithdrawDataInDateRangeParams } from "./teamDataService/ShareWithdrawDataInDateRangeParams";

const TEAMS_SDK_CALL_TIMEOUT: number = 10000;

export declare type IsCanceledDelegate = () => boolean;

/**
 * Returns a Promise that is resolved using a setTimeout. This allows the rendering thread
 * a chance to draw the DOM updates before returning control back to the javascript.
 */
async function yieldToUIThread() {
    /* tslint:disable:no-string-based-set-timeout */
    await new Promise(resolve => setTimeout(resolve, 0));
    /* tslint:enable:no-string-based-set-timeout */
}

/**
 * TeamDataService provides a layer of abstraction over API calls and satchelJs store updates. It contains business logic
 * and many of its functions return promises that callers can await.
 * The TeamDataService service should use the following sequence when returning data:
 *      Is Data in in-memory SatchelJS global store?
 *      Yes:
 *          > Return data from in-memory store
 *      No:
 *          > Is Data in database?
 *          Yes:
 *              > Update in-memory store with the data from database
 *              > Trigger async call to refresh data in database/in-memory store from network (don't wait for result)
 *              > Return data from database
 *          No:
 *              > Make network call and wait for response
 *              > Cache response data in-memory and database
 *              > Return response data
 */
class TeamDataService extends DataService implements ITeamDataService {
    private progressiveDataIndex: number = -1;

    private incrementProgressiveIndex(): number {
        return ++this.progressiveDataIndex;
    }

    private get progessiveIndex() {
        return this.progressiveDataIndex;
    }

    /**
     * Gets the teams for the logged in user
     */
    public async getTeams(): Promise<ITeamInfoEntity[]> {
        const teamsDataProvider = new TeamsDataProvider(this.teamDatabase, UserDataService);
        const teamsDataWithSyncState = await this.getData(teamsDataProvider);
        return teamsDataWithSyncState && teamsDataWithSyncState.data;
    }

    /**
     * Get the team that is in store or null
     */
    public getCurrentTeam(): ITeamInfoEntity {
        let team: ITeamInfoEntity = null;
        const teamStore = TeamStore();
        if (teamStore && teamStore.team) {
            team = teamStore.team;
        }
        return team;
    }

    /**
     * Return the team with the given id. First check cache, then make call to service if
     * the team is not there.
     * @param teamId
     * @param forceLoadFromNetwork
     */
    public async getTeam(teamId: string, forceLoadFromNetwork: boolean = false): Promise<ITeamInfoEntity> {
        const teamDetailsDataProvider = new TeamDetailsDataProvider(
            this.teamDatabase,
            this.tenantId,
            null,
            teamId,
            this.sessionId,
            new MemberMapper()
        );
        const teamDetailsWithSyncState = await this.getData(teamDetailsDataProvider, forceLoadFromNetwork);
        return teamDetailsWithSyncState && teamDetailsWithSyncState.data && teamDetailsWithSyncState.data.team;
    }

    /**
     * Return the team with the given id. First check cache, then make call to service if
     * the team is not there.
     * @param teamId
     * @param forceLoadFromNetwork
     */
    public async getGroup(groupId: string, forceLoadFromNetwork: boolean = false): Promise<ITeamInfoEntity> {
        const teamDetailsDataProvider = new TeamDetailsDataProvider(
            this.teamDatabase,
            this.tenantId,
            groupId,
            null,
            this.sessionId,
            new MemberMapper()
        );
        const teamDetailsWithSyncState = await this.getData(teamDetailsDataProvider, forceLoadFromNetwork);
        return teamDetailsWithSyncState && teamDetailsWithSyncState.data && teamDetailsWithSyncState.data.team;
    }

    /**
     * Save the team to the service and in cache
     * @param team
     */
    public async setTeam(team: ITeamInfoEntity): Promise<ITeamInfoEntity> {
        const teamDataProvider = new TeamDataProvider(this.teamDatabase, team.tenantId, team.id);
        return await this.updateData(team, teamDataProvider);
    }

    /**
     * Create a Shifts (StaffHub) team from a Teams team
     * @param teamsTeam - The Microsoft Teams' team
     * @param {string} teamTimezone - The olson code that represents the teams time zone
     */
    public async scheduleProvision(teamsTeam: TeamInformation, teamTimezone: string): Promise<ITeamInfoEntity> {
        let scheduleProvisionResponse: IScheduleProvisionResponse = null;
        try {
            scheduleProvisionResponse = await RestClient.scheduleProvision(teamsTeam.groupId, teamsTeam.teamId, teamTimezone);
        } catch (error) {
            // check if the team has already been created by someone else
            const httpError = error as StaffHubHttpError;
            if (
                httpError &&
                httpError.staffHubInnerErrorCode &&
                httpError.staffHubInnerErrorCode === StaffHubErrorCodes.TeamsTeamAlreadyCreated
            ) {
                const existingTeamId =
                    httpError.staffHubError.details &&
                    httpError.staffHubError.details.length == 1 &&
                    httpError.staffHubError.details[0].code == StaffHubErrorCodes.TeamsTeamAlreadyCreated
                        ? httpError.staffHubError.details[0].target
                        : null;
                if (existingTeamId) {
                    httpError.isHandled = true;
                    return await this.tryGetTeam(existingTeamId);
                }
            }
            // rethrow the exception if we don't know how to handle it
            throw error;
        }

        if (scheduleProvisionResponse) {
            try {
                const provisionedTeamId = await JobDataService.pollUntilProvisionScheduleComplete(
                    scheduleProvisionResponse.teamId,
                    scheduleProvisionResponse.jobId
                );
                return await this.getTeam(provisionedTeamId);
            } catch (provisionTeamError) {
                // The service error is not useful here, so let's just try to fetch the team in case it was created by someone else
                const createdTeam = await this.tryGetTeam(scheduleProvisionResponse.teamId);
                if (createdTeam) {
                    return createdTeam;
                } else {
                    // rethrow the provision team error if we couldn't get the team
                    throw provisionTeamError;
                }
            }
        }
    }

    /**
     * Tries to get the team with the ID
     * Swallows and instruments any exceptions with fetching the team
     */
    private async tryGetTeam(teamId: string) {
        try {
            return await this.getTeam(teamId);
        } catch (err) {
            // instrument the error, and return null
            InstrumentationService.trackException(err, "");
            return null;
        }
    }

    /**
     * Handle a new team notification
     * @param teamDetails The team details
     */
    public async onNewTeam(teamDetails: TeamDetailsResponse): Promise<void> {
        const teamDataProvider = new TeamDetailsDataProvider(
            this.teamDatabase,
            teamDetails.team.tenantId,
            teamDetails.team.groupId,
            teamDetails.team.id,
            this.sessionId,
            new MemberMapper()
        );
        await this.updateDataInCache({ data: teamDetails, isDataInSync: true, isPartialInSync: false }, teamDataProvider);
    }

    /**
     * Handle a team update notification
     * @param team The Team
     */
    public async onUpdateTeam(team: ITeamInfoEntity): Promise<void> {
        const teamDataProvider = new TeamDataProvider(this.teamDatabase, team.tenantId, team.id);
        await this.updateDataInCache(team, teamDataProvider);
    }

    /**
     * Handle a team delete notification
     * @param teamId The Team ID
     */
    public async onDeleteTeam(teamId: string): Promise<void> {
        const teamDataProvider = new TeamDataProvider(this.teamDatabase, this.tenantId, teamId);
        await this.hardDeleteDataInCache(teamDataProvider);
    }

    /**
     * Get the availabilities for the whole team.  Only returns availabilities for users who have set their availabilities.
     * @param tenantId
     * @param teamId
     */
    public async getAvailabilities(tenantId: string, teamId: string): Promise<Map<string, IAvailabilityEntity[]>> {
        let marker = "getAvailabilities";
        marker = InstrumentationService.perfMarkerStart(marker);

        let availabilities: Promise<Map<string, IAvailabilityEntity[]>>;
        try {
            availabilities = RestClient.getAvailabilities(tenantId, teamId);
        } catch (httpError) {
            trace.warn("getAvailabilities: Error in getting availabilities");
        } finally {
            InstrumentationService.perfMarkerEnd(marker);
        }
        return availabilities;
    }

    /**
     * Get MS Teams that the user is a member of
     */
    public async getMSTeamsUserJoinedTeams(): Promise<microsoftTeams.UserJoinedTeamsInformation> {
        // Convert the microsoftTeams SDK callback pattern to a promise
        return new Promise<microsoftTeams.UserJoinedTeamsInformation>((resolve, reject) => {
            // MS Teams SDK getUserJoinedTeams() call requires special permissions. Without these permissions, the
            // SDK call will still return successfully, but then never executes the callback or any other error feedback.
            // To handle this, we use a timer to fail out if the SDK call does not complete in time.
            // Properly published apps (such as Shifts for R0/PROD) should be registered properly and should never run into
            // this issue.
            const sdkCallTimer = setTimeout(() => {
                const error: StaffHubError = {
                    // Note: We want to avoid adding string resource dependencies in the DataService layer, so this is not localizable.
                    // Callers should check for the error code and display a localized string if needed.
                    message: "There was an error getting your teams.",
                    code: StaffHubErrorCodes.InsufficientPermissions,
                    details: [],
                    innererror: null
                };
                reject(error);
            }, TEAMS_SDK_CALL_TIMEOUT);

            microsoftTeams.teams.fullTrust.joinedTeams.getUserJoinedTeams().then((userJoinedTeamsInfo: microsoftTeams.UserJoinedTeamsInformation) => {
                clearTimeout(sdkCallTimer);
                resolve(userJoinedTeamsInfo);
            });
        });
    }

    /**
     * Get the User Settings
     */
    public async getUserSettings(): Promise<UserSettingsEntity> {
        const userSettingsDataProvider = new UserSettingsDataProvider(this.loggedInUserId, this.userDatabase);
        return await this.getData(userSettingsDataProvider);
    }

    /**
     * Save the user settings to the network and update the global store and persistent db
     */
    public async saveUserSettings(userSettings: UserSettingsEntity): Promise<UserSettingsEntity> {
        const userSettingsDataProvider = new UserSettingsDataProvider(this.loggedInUserId, this.userDatabase);
        return await this.updateData(userSettings, userSettingsDataProvider);
    }

    /**
     * Handle copy Schedule
     * @param {String} teamId
     * @param {Moment} sourceStartTime - start of timerange for shifts to copy from
     * @param {Moment} sourceEndTime - end of timerange for shifts to copy from
     * @param {Number} destinationOffsetInDays - offset of days for each shift to copy to
     * @param {Boolean} copyNotes - copy notes from source to dest
     * @param {Boolean} copyTimeOffShifts - copy abscence shifts from source to dest
     * @param {Boolean} copyActivities - copy subshifts from source to dest
     * @param {Boolean} copyOpenShifts - copy open shifts from source to dest
     * @param {Number} scheduleRepetition - copy open shifts from source to dest
     * @param {DataFilter} dataFilter - data filter - if passed, service will only return objects that match
     * @returns {Object} jobEntity
     */
    public async bulkCopySchedule(
        teamId: string,
        sourceStartTime: Moment,
        sourceEndTime: Moment,
        destinationOffsetInDays: number,
        copyNotes: boolean,
        copyTimeOffShifts: boolean,
        copyActivities: boolean,
        copyOpenShifts: boolean,
        scheduleRepetition: number,
        dataFilter?: DataFilter
    ): Promise<IJobEntity> {
        let marker = "copySchedule";
        marker = InstrumentationService.perfMarkerStart(marker);
        try {
            const jobEntity: IJobEntity = await RestClient.bulkCopySchedule(
                teamId,
                sourceStartTime,
                sourceEndTime,
                destinationOffsetInDays,
                copyNotes,
                copyTimeOffShifts,
                copyActivities,
                copyOpenShifts,
                scheduleRepetition,
                dataFilter
            );
            return jobEntity;
        } catch (error) {
            throw error;
        } finally {
            InstrumentationService.perfMarkerEnd(marker);
        }
    }

    /**
     * Handle undo copy Schedule
     * @param {String} teamId
     * @param {String} jobId
     * @returns {Object} jobEntity
     */
    public async undoJob(teamId: string, jobId: string): Promise<IJobEntity> {
        let marker = "undoJob";
        marker = InstrumentationService.perfMarkerStart(marker);
        try {
            const jobEntity: IJobEntity = await RestClient.undoJob(teamId, jobId);
            return jobEntity;
        } catch (error) {
            throw error;
        } finally {
            InstrumentationService.perfMarkerEnd(marker);
        }
    }

    /**
     * Import Schedule from Excel
     */
    public async importScheduleFromExcel(teamId: string, importScheduleFile: File): Promise<IJobEntity> {
        let marker = "importScheduleFromExcel";
        marker = InstrumentationService.perfMarkerStart(marker);
        try {
            const jobEntity: IJobEntity = await RestClient.importScheduleFromExcel(teamId, importScheduleFile);
            return jobEntity;
        } finally {
            InstrumentationService.perfMarkerEnd(marker);
        }
    }

    /**
     * Get the start day of the week settings for the logged in team
     */
    public getStartDayOfWeek(): number {
        const startDayOfWeekStoreValue: string = TeamSettingEntity.getValueOrDefault(TeamSettingsStore().startingDayOfWeek);
        return DayOfWeek[startDayOfWeekStoreValue as keyof typeof DayOfWeek];
    }

    /**
     * Store the tenant and team ids and team name in memory. This unblocks large sections of the UI before we have fully loaded a team.
     * @param tenantId
     * @param teamId
     */
    public async setBasicTeamData(tenantId: string, groupId: string, teamId: string, name: string) {
        initBasicTeamData(tenantId, groupId, teamId, name);
    }

    /**
     * Gets all shifts, notes and schedules from within date range
     * @param {string} teamId
     * @param {Moment} startTime
     * @param {Moment} endTime
     * @param {boolean} forceLoadFromNetwork - when true, will force fetch from network
     * @returns {Promise}
     */
    public async getDataInDateRange(
        teamId: string,
        startTime: Moment,
        endTime: Moment,
        forceLoadFromNetwork: boolean = false
    ): Promise<IDataInDateRange> {
        const dateRangeDataProvider = new DataInDateRangeDataProvider(
            this.shiftDatabase,
            this.noteDatabase,
            this.openShiftDatabase,
            this.tenantId,
            teamId,
            this.sessionId,
            startTime,
            endTime,
            false /* dontClearCache for non contiguous date ranges */,
            ShiftStoreTypes.ShiftStore
        );
        const result = await this.getData(dateRangeDataProvider, forceLoadFromNetwork);

        const shifts =
            result && result.shiftsWithSyncState && result.shiftsWithSyncState.data && result.shiftsWithSyncState.data.length
                ? result.shiftsWithSyncState.data
                : [];
        const notes =
            result && result.notesWithSyncState && result.notesWithSyncState.data && result.notesWithSyncState.data.length
                ? result.notesWithSyncState.data
                : [];
        const openShifts =
            result && result.openShiftsWithSyncState && result.openShiftsWithSyncState.data && result.openShiftsWithSyncState.data.length
                ? result.openShiftsWithSyncState.data
                : [];

        if (ECSConfigService.isECSFeatureEnabled(ECSConfigKey.EnableMyShiftsApi)) {
            // while updating shifts, update myshifts with that range in cache and DB.
            const myShiftsDateRangeDataProvider = new ShiftsInDateRangeDataProvider(
                this.myShiftDatabase,
                this.tenantId,
                teamId,
                this.sessionId,
                startTime,
                endTime,
                false /*dontClearCache*/,
                ShiftStoreTypes.MyShiftStore
            );
            const myShiftsData = shifts.filter(shift => {
                return ShiftUtils.isCurrentUserShift(shift);
            });

            if (myShiftsData.length > 0) {
                const myShiftdataWithSyncState: IDataWithSyncState<IShiftEntity[]> = {
                    data: myShiftsData,
                    isDataInSync: true,
                    isPartialInSync: false
                };
                await this.updateDataInCache(myShiftdataWithSyncState, myShiftsDateRangeDataProvider);
            }
        }
        return { shifts, notes, openShifts };
    }

    /**
     * Gets all shifts, notes and schedules from within date range
     * @param {string} teamId
     * @param {Moment} startTime
     * @param {Moment} endTime
     * @param {boolean} forceLoadFromNetwork - when true, will force fetch from network
     * @returns {Promise}
     */
    public async getDataInDateRangeMyShifts(
        teamId: string,
        startTime: Moment,
        endTime: Moment,
        forceLoadFromNetwork: boolean = false
    ): Promise<IDataInDateRange> {
        const myShiftsdateRangeDataProvider = new DataInDateRangeDataProvider(
            this.myShiftDatabase,
            this.noteDatabase,
            this.openShiftDatabase,
            this.tenantId,
            teamId,
            this.sessionId,
            startTime,
            endTime,
            false /* dontClearCache for non contiguous date ranges */,
            ShiftStoreTypes.MyShiftStore
        );
        const result = await this.getData(myShiftsdateRangeDataProvider, forceLoadFromNetwork);

        const shifts =
            result && result.shiftsWithSyncState && result.shiftsWithSyncState.data && result.shiftsWithSyncState.data.length
                ? result.shiftsWithSyncState.data
                : [];
        const notes =
            result && result.notesWithSyncState && result.notesWithSyncState.data && result.notesWithSyncState.data.length
                ? result.notesWithSyncState.data
                : [];
        const openShifts =
            result && result.openShiftsWithSyncState && result.openShiftsWithSyncState.data && result.openShiftsWithSyncState.data.length
                ? result.openShiftsWithSyncState.data
                : [];
        return { shifts, notes, openShifts };
    }

    /**
     * Returns a data filter with network options to be used to optimize the network requests when
     * filtering by tags or members.
     * @param tagIds array of tagIds used to filter network data - cannot be combined with memberIds
     * @param memberIds array of memberIds to filter network data - cannot be combined with tagIds
     * @param isFirstRequest true if this is the first filtered request
     * @param isLastRequest true is this is the last filtered request
     */
    public getDataFilterWithNetworkOptions(
        tagIds: string[],
        memberIds: string[],
        isFirstRequest: boolean,
        isLastRequest: boolean
    ): DataFilter {
        // we currently DO NOT support filtering by tag and member at the same time
        const filterByTags = !!(tagIds && tagIds.length);
        const filterByMembers = !!(!filterByTags && memberIds && memberIds.length);

        const includeNotes = isFirstRequest;
        // when filtering by tags, we always get the open shifts since they are tied to the tag being fetched.
        // when filtering by members, we only get the open shifts on the first loop because the response will include all of them.
        const includeOpenShifts = filterByTags || (filterByMembers && isFirstRequest);
        // when filtering by tags, we only get the ungrouped shifts (shifts shown in "Other" group) on the first request to prevent duplication (and specifically first because it will include time offs which should be shown immediately)
        // when filtering by members, we always include the ungrouped shifts because they are tied to the member being fetched.
        const includeUngroupedShifts = filterByMembers || (filterByTags && isFirstRequest);
        // when filtering by tags, we only get the time off shifts on the first request to prevent duplication
        // when filtering by members, we always include the time off shifts
        const includeTimeOffs = filterByMembers || (filterByTags && isFirstRequest);

        return {
            tagIds: filterByTags ? tagIds : undefined,
            memberIds: filterByMembers ? memberIds : undefined,
            networkFetchOptions: {
                includeNotes: includeNotes,
                includeOpenShifts: includeOpenShifts,
                includeUngroupedShifts: includeUngroupedShifts,
                includeTimeOffs: includeTimeOffs
            }
        };
    }

    /**
     * Progressively get filtered data, shifts, open shifts and notes from within date range.
     * @param {string} teamId
     * @param {Moment} startTime
     * @param {Moment} endTime
     * @param {DataFilter} dataFilter - Data filter - filter used to determine what data is returned
     * @param {FilteredDataLoadedCallback} dataLoadedCallback - callback that is called for each data set that is loaded
     * @param {boolean} forceLoadFromNetwork - when true, will force fetch from network
     * @returns {Promise} - resolves the promise with true if data for all tags was loaded or false if the loop did not finish before all data was loaded
     */
    public async getDataInDateRangeFiltered(
        teamId: string,
        startTime: Moment,
        endTime: Moment,
        dataFilter: DataFilter,
        dataLoadedCallback: FilteredDataLoadedCallback,
        forceLoadFromNetwork: boolean = false
    ): Promise<boolean> {
        const marker = InstrumentationService.perfMarkerStart("getDataInDateRangeByTags");

        const dateRangeDataProvider = new ProgressiveDataInDateRangeDataProvider(
            this.incrementProgressiveIndex(), // we are starting a new get data loop - increase the progressive index - index is used to determine if a progressive loop was canceled
            this.shiftDatabase,
            this.noteDatabase,
            this.openShiftDatabase,
            this.tenantId,
            teamId,
            this.sessionId,
            startTime,
            endTime,
            false /* dontClearCache for non contiguous date ranges */
        );

        let didComplete = true;

        // we currently DO NOT support filtering by tag and member at the same time
        const filterByTags = !!(dataFilter && dataFilter.tagIds);
        const filterByMembers = !!(dataFilter && dataFilter.memberIds && !filterByTags);

        // =================================
        // number of tags to retrieve during each loop - NOTE: for the first loop we always get 1 tag to improve time to first render
        const NUM_TAGS_PER_LOOP = ECSConfigService.getECSFeatureSetting(ECSConfigKey.ProgressiveRenderingTagsPerLoop);
        const NUM_MEMBERS_PER_LOOP = 30;
        // =================================

        if (filterByTags || filterByMembers) {
            const filter = filterByTags ? dataFilter.tagIds : dataFilter.memberIds;
            let chunkAmount = filterByTags ? 1 /* first loop we get 1 */ : NUM_MEMBERS_PER_LOOP;

            // slice the array of ids we are using as a filter into chunks of n size and then
            // loop through getting the data until everything is fetched.
            for (let i = 0, j = filter.length; i < j; i += chunkAmount) {
                const isFirstRequest = i === 0;
                if (filterByTags && !isFirstRequest) {
                    // after the first loop, set the chunkAmount to the set amount
                    // remember, we only get 1 tag for the first loop to improve first render perf
                    chunkAmount = NUM_TAGS_PER_LOOP;
                }

                const chunkedArray = filter.slice(i, i + chunkAmount);
                const isLastRequest = i + chunkAmount >= j;

                const data = await this.getDataWithFilter(
                    dateRangeDataProvider,
                    this.getDataFilterWithNetworkOptions(
                        filterByTags ? chunkedArray : undefined,
                        filterByMembers ? chunkedArray : undefined,
                        isFirstRequest,
                        isLastRequest
                    ),
                    isLastRequest /* isInSync (the last item) */,
                    () => {
                        // isCanceled delegate returns true if this progressive loop has been canceled
                        const isCanceled = dateRangeDataProvider.index !== this.progessiveIndex;
                        if (isCanceled) {
                            trace.info(`PR: index: ${dateRangeDataProvider.index} was canceled - current index: ${this.progessiveIndex}`);
                        }

                        return isCanceled;
                    },
                    forceLoadFromNetwork
                );
                if (!data) {
                    // if nothing was returned, break out of the loop
                    didComplete = false;
                    break;
                } else {
                    if (dataLoadedCallback) {
                        dataLoadedCallback(dataFilter);

                        // ****** LET THE BROWSER DRAW *******
                        // this async stall of zero milliseconds will actually yield to the UI thread and allow the current
                        // loop to draw before getting the data for the next. Without this, the UI will remain showing the
                        // skeleton grid until all the data for each tag has been loaded (basically removing the entire benefit
                        // of progressive rendering).
                        await yieldToUIThread();
                    }
                }
            }
        } else {
            // NOT SUPPORTED
            trace.error("getDataInDateRangeFiltered unknown filtering type is not supported");
            InstrumentationService.perfMarkerEnd(marker);
            return true;
        }

        InstrumentationService.perfMarkerEnd(marker);

        return didComplete;
    }

    /**
     * Syncs all shifts, notes and schedules from within date range that overlaps with the current db range
     * This is used for sync calls when to optimize the fetch range
     * @param {string} teamId
     * @param {Moment} startTime
     * @param {Moment} endTime
     * @returns {Promise}
     */
    public async syncDataInDateRange(teamId: string, startTime: Moment, endTime: Moment): Promise<IDataInDateRange> {
        const dateRangeDataProvider = new DataInDateRangeDataProvider(
            this.shiftDatabase,
            this.noteDatabase,
            this.openShiftDatabase,
            this.tenantId,
            teamId,
            this.sessionId,
            startTime,
            endTime,
            false /* dontClearCache for non contiguous date ranges */,
            ShiftStoreTypes.ShiftStore
        );
        const updatedTimeRangeAllShifts: IDateRange = await dateRangeDataProvider.getTimeRangeCurrentlyInSync();

        const myShiftsdateRangeDataProvider = new DataInDateRangeDataProvider(
            this.myShiftDatabase,
            this.noteDatabase,
            this.openShiftDatabase,
            this.tenantId,
            teamId,
            this.sessionId,
            startTime,
            endTime,
            false /* dontClearCache for non contiguous date ranges */,
            ShiftStoreTypes.MyShiftStore
        );
        const updatedTimeRangeForMyShifts: IDateRange = await myShiftsdateRangeDataProvider.getTimeRangeCurrentlyInSync();

        /* The time range currently in sync for shifts and myShifts can be different. Hence we get the longest range and update that range in cache.
         * the getDataInDateRange call handles update of both shifts and myShifts.
         */
        if (ECSConfigService.isECSFeatureEnabled(ECSConfigKey.EnableMyShiftsApi) && updatedTimeRangeForMyShifts) {
            if (updatedTimeRangeAllShifts) {
                // Find the longest time range in cache including both shifts and myshifts
                const updatedStartTime = updatedTimeRangeAllShifts.startTime.isBefore(updatedTimeRangeForMyShifts.startTime)
                    ? updatedTimeRangeAllShifts.startTime
                    : updatedTimeRangeForMyShifts.startTime;
                const updatedEndTime = updatedTimeRangeAllShifts.endTime.isAfter(updatedTimeRangeForMyShifts.endTime)
                    ? updatedTimeRangeAllShifts.endTime
                    : updatedTimeRangeForMyShifts.endTime;
                return this.getDataInDateRange(teamId, updatedStartTime, updatedEndTime, true /* forceLoadFromNetwork */);
            } else if (updatedTimeRangeForMyShifts) {
                // if shifts are not yet loaded, just udpate myShifts
                return this.getDataInDateRangeMyShifts(
                    teamId,
                    updatedTimeRangeForMyShifts.startTime,
                    updatedTimeRangeForMyShifts.endTime,
                    true /* forceLoadFromNetwork */
                );
            } else {
                trace.info("Sync date range does not overlap with current range");
                return null;
            }
        } else if (updatedTimeRangeAllShifts) {
            return this.getDataInDateRange(
                teamId,
                updatedTimeRangeAllShifts.startTime,
                updatedTimeRangeAllShifts.endTime,
                true /* forceLoadFromNetwork */
            );
        } else {
            trace.info("Sync date range does not overlap with current range for myshifts");
            return null;
        }
    }

    /**
     * Shares or Withdraws all data in date range.
     * @param params The parameters.
     * @returns The shared or withdraw data.
     */
    public async shareWithdrawDataInDateRange(params: ShareWithdrawDataInDateRangeParams): Promise<IDataInDateRange> {
        const { endTime, isShare, notificationRecipients, startTime, teamId } = params;

        const marker = InstrumentationService.perfMarkerStart(isShare ? "shareDataInDateRange" : "withdrawDateInDateRange");
        // Make copies of the date range parameters in case anyone modifies them while this request is being executed
        const fetchStartDate = startTime.clone();
        const fetchEndDate = endTime.clone();

        try {
            const data = await RestClient.shareWithdrawDataInDateRange(
                {
                    endTime: fetchEndDate,
                    isShare,
                    notificationRecipients,
                    startTime: fetchStartDate,
                    teamId
                }
            );

            transaction(() => {
                if (data && data.shifts && data.openShifts && data.notes) {
                    // updateXXXInStorage will update both memory cache and database
                    ShiftDataService.updateShiftsInStorage(data.shifts, false /* isOptimisticUpdate */);
                    ShiftDataService.updateOpenShiftsInStorage(data.openShifts, false /* isOptimisticUpdate */);
                    NoteDataService.updateNotesInStorage(data.notes, false /* isOptimisticUpdate */);
                }
            });

            return data;
        } catch (error) {
            // on failure, it is possible that some data was still shared, get the most recent version for the shared date range
            // this will udpate shifts as well as myShifts
            await this.getDataInDateRange(teamId, fetchStartDate, fetchEndDate, true /*  forceLoadFromNetwork */);
            throw error;
        } finally {
            InstrumentationService.perfMarkerEnd(marker);
        }
    }

    /**
     * Resets the sync state
     */
    public async resetSyncState() {}

    /**
     * Delete the old sessions
     * @param sessionIds List of sessionIds to delete
     */
    public async deleteSessionData(sessionIds: string[]) {
        await this.teamDatabase.deleteSessionData(sessionIds);
    }

    /**
     * Fetches data based on our priority sequence:
     *      1. Fetch Data from Memory
     *      2. Fetch Data from Database
     *      3. Fetch Data from Network
     * If the data is not in the Memory, it triggers an async process to also update the DB and memory from the network.
     *
     * Note: This method is used by progressive rendering to progressively fetch data for all the tags (groups) within a given date
     * range.
     * @param {IDataProvider<T>} dataProvider Data provider
     * @param {string} tagId The tag id to filter the returned data. Only data within this tag will be fetched.
     * @param {boolean} isInSync pass true if this is the final tag within the fetch range to signal to the data layer that the data is now "in-sync"
     * @param {IsCanceledDelegate} isCanceled Delegate that should return true if this fetch has been canceled before completing
     * @param {boolean} forceLoadFromNetwork Forces data to load from the network even if it's already in cache
     */
    protected async getDataWithFilter(
        dataProvider: IDataProvider<IDataInDateRangeWithSyncState>,
        dataFilter: DataFilter,
        isInSync: boolean,
        isCanceled: IsCanceledDelegate,
        forceLoadFromNetwork: boolean = false
    ): Promise<IDataInDateRangeWithSyncState> {
        if (forceLoadFromNetwork) {
            return await this.loadDataFromNetworkWithFilter(dataProvider, dataFilter, isInSync, isCanceled);
        }

        // Check if the data is in cache, otherwise load it from the network
        const dataFromCache: IDataInDateRangeWithSyncState = await this.loadDataFromCacheWithFilter(
            dataProvider,
            dataFilter,
            isInSync,
            isCanceled
        );
        // we this fetch was canceled, return null instead of trying to load from network
        if (isCanceled()) {
            return null;
        }
        if (dataFromCache != undefined && dataFromCache != null) {
            return dataFromCache;
        } else {
            return await this.loadDataFromNetworkWithFilter(dataProvider, dataFilter, isInSync, isCanceled);
        }
    }

    /**
     * Gets data within a specific filter from the network and updates the memory and database before returning the result
     * @param {IDataProvider<T>} dataProvider Data provider
     * @param {DataFilter} dataFilter The filter to use for the returned data. Only data within this filter will be fetched.
     * @param {boolean} isInSync pass true if this is the final tag within the fetch range to signal to the data layer that the data is now "in-sync"
     * @param {IsCanceledDelegate} isCanceled Delegate that should return true if this fetch has been canceled before completing
     */
    protected async loadDataFromNetworkWithFilter(
        dataProvider: IDataProvider<IDataInDateRangeWithSyncState>,
        dataFilter: DataFilter,
        isInSync: boolean,
        isCanceled: IsCanceledDelegate
    ): Promise<IDataInDateRangeWithSyncState> {
        if (dataProvider instanceof ProgressiveDataInDateRangeDataProvider) {
            const dataFromNetwork: IDataInDateRangeWithSyncState = await dataProvider.getDataFromNetwork(dataFilter, isInSync);
            // call the isCanceled delegate to check if this fetch has been canceled
            if (isCanceled()) {
                return null;
            }

            return await this.updateDataInCache(dataFromNetwork, dataProvider);
        }
        return null;
    }

    /**
     * Gets the data from memory (otherwise tries to fetch it from the database and load it into memory)
     * @param {IDataProvider<T>} dataProvider Data provider
     * @param {DataFilter} dataFilter The filter to use for the returned data. Only data within this filter will be fetched.
     * @param {boolean} isInSync pass true if this is the final tag within the fetch range to signal to the data layer that the data is now "in-sync"
     * @param {IsCanceledDelegate} isCanceled Delegate that should return true if this fetch has been canceled before completing
     * @param {boolean} skipRefreshFromNetworkIfNotInMemory (optional) - Skip refreshing data from network if it was only in the database and not in memory
     */
    public async loadDataFromCacheWithFilter<T>(
        dataProvider: IDataProvider<T>,
        dataFilter: DataFilter,
        isInSync: boolean,
        isCanceled: IsCanceledDelegate,
        skipRefreshFromNetworkIfNotInMemory?: boolean
    ): Promise<IDataInDateRangeWithSyncState> {
        if (dataProvider instanceof ProgressiveDataInDateRangeDataProvider) {
            // Check if the data is already loaded in memory
            const dataFromMemory: IDataInDateRangeWithSyncState = await dataProvider.getDataFromMemory(dataFilter, isInSync);
            if (isCanceled()) {
                return null;
            }
            if (dataFromMemory !== undefined && dataFromMemory !== null) {
                trace.info("PR: returning data from memory");
                return dataFromMemory;
            } else {
                const dataFromDatabase: IDataInDateRangeWithSyncState = await dataProvider.getDataFromDatabase(dataFilter, isInSync);
                if (isCanceled()) {
                    return null;
                }

                if (dataFromDatabase != undefined && dataFromDatabase != null) {
                    // load the data into the global store
                    await dataProvider.setDataInMemory(dataFromDatabase);

                    // if we found the data in the database, check if we want to refresh the data from the network
                    if (skipRefreshFromNetworkIfNotInMemory === undefined) {
                        skipRefreshFromNetworkIfNotInMemory = await dataProvider.skipRefreshFromNetworkIfNotInMemory(dataFromDatabase);
                    }
                }

                if (skipRefreshFromNetworkIfNotInMemory && dataFromDatabase) {
                    trace.info("PR: returning data from database");
                } else if (dataFromDatabase) {
                    trace.info("PR: data was found in database and set in memory, but still need to load from network");
                } else {
                    trace.info("PR: no data in local cache, need to go to network");
                }

                return skipRefreshFromNetworkIfNotInMemory ? dataFromDatabase : null;
            }
        }
    }
}

const service = new TeamDataService();
export default service as TeamDataService;
