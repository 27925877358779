export enum ShiftRequestPanelSelectedItemType {
    GroupedOpenShift = "GroupedOpenShift",
    RegularShiftRequest = "RegularShiftRequest",
    NotSelected = "NotSelected"
}

interface ShiftRequestPanelStoreSchema {
    /* which type of item is selected */
    selectedItemType: ShiftRequestPanelSelectedItemType;
    /* error message to show in top of panel */
    errorMessage: string;
    /* whether to show the refresh button in the error MessageBar */
    messageBarShowRefreshButton: boolean;
    /* The id of the selected request in store */
    selectedRequestId: string;
    /* Is the panel currently saving */
    isSaving: boolean;
    /* Should the list scroll to the currently selected item */
    shouldScrollToSelected: boolean;
    /* Show or hide the create request dialog */
    showCreateRequestDialog: boolean;
}

export default ShiftRequestPanelStoreSchema;
