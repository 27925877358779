import { memberEditorViewState } from "..";
import { action } from "satcheljs/lib/legacy";
import { IButton } from "@fluentui/react";

/**
 * Generic action that updates a openDialogTriggerButton button on a view state
 * @param openDialogTriggerButton - Button ref that opens the dialog view
 */
export default action("setMemberOpenDialogTriggerButton")(
    function setMemberOpenDialogTriggerButton(openDialogTriggerButton: IButton | null) {
      memberEditorViewState().openDialogTriggerButton = openDialogTriggerButton;
    }
);
