import gridSelectionViewStateStore from "../store/store";
import { computed } from "mobx";

/**
 * computed value representing the number of selected cells
 */
let numCellsSelected = computed(function(): number {
    let numCellsSelected: number = 0;

    gridSelectionViewStateStore().selectedCellKeys.forEach((selected: boolean) => {
        if (selected) {
            numCellsSelected++;
        }
    });

    return numCellsSelected;
});

export default numCellsSelected;