import { createStore } from "satcheljs";
import { TeamSetupPickerStore } from "./schema/TeamSetupPickerStore";

/**
 * Team picker panel view state
 */
const initialTeamSetupPickerStore: TeamSetupPickerStore = {
    setupSequence: null,
    currentSetupStepIndex: -1,
    teamSetupInfo: {
        msTeamInfo: null,           // MS Team info
        timeZoneOlsonCode: null     // Team timezone
    },
    isProvisionTeamInProgress: false,   // true if the schedule team is currently being provisioned
    errorMessage: null,                 // Set with an error message string to display an error
    showStepIndicator: true             // true shows the step indicator component in the setup dialog
};

const teamSetupPickerStore = createStore("teamSetupPickerStore", initialTeamSetupPickerStore)();
export default teamSetupPickerStore;

/* export actions and mutator actions for ease of import */
export { default as setTeamSetupPickerSequence } from "../mutatorActions/setTeamSetupPickerSequence";
export { default as gotoNextSetupStep } from "../mutatorActions/gotoNextSetupStep";
export { default as gotoPrevSetupStep } from "../mutatorActions/gotoPrevSetupStep";
export { default as setMSTeamInfo } from "../mutatorActions/setMSTeamInfo";
export { default as setTeamSetupTimezone } from "../mutatorActions/setTeamSetupTimezone";
export { default as setErrorMessage } from "../mutatorActions/setErrorMessage";
export { default as setIsProvisionTeamInProgress } from "../mutatorActions/setIsProvisionTeamInProgress";
export { default as setShowStepIndicator } from "../mutatorActions/setShowStepIndicator";
