import setGlobalMessages from "sh-application/actions/setGlobalMessages";
import { action } from "satcheljs/lib/legacy";
import { GlobalMessageViewState } from "../store/schema/GlobalMessageViewState";
import { MessageBarType } from "@fluentui/react";

/**
 * Action to set the message to display in the global message bar. Setting to blank string will clear the message.
 * @param {AppViewState} AppViewState
 * @param {string} message
 * @param {string} actionButtonTitle - title of optional action button (if you include a button title, you must include a callback function)
 * @param {Function} actionButtonCallback - callback for optional action button
 * @param {boolean} autoDismiss - set to true to have the message bar auto dismiss
 * @param {boolean} isCenterAligned - set to true to have the message center aligned
 */
export default action("setGlobalMessage")(
    function setGlobalMessage(viewState: GlobalMessageViewState,
        message: string = null,
        messageBarType: MessageBarType = MessageBarType.info,
        actionButtonTitle: string = null,
        actionButtonCallback: Function = null,
        autoDismiss: boolean = false,
        isCenterAligned: boolean = false) {
            if (message) {
                setGlobalMessages(viewState, [message], messageBarType, actionButtonTitle, actionButtonCallback, autoDismiss, false /* isMultiline */, isCenterAligned);
            } else {
                // to clear the global message bar
                setGlobalMessages(viewState, null, messageBarType, actionButtonTitle, actionButtonCallback, autoDismiss, false /* isMultiline */, isCenterAligned);
            }
        });