import StringModule from "./schema/StringModule";
import { createStore } from "satcheljs";
import { StringStoreSchema } from "./schema/StringsStore";

/**
 * Store for localized strings
 */

let StringsStore = createStore<StringStoreSchema>(
    "stringsStore",
    {
        currentLocale: "en",
        registeredStringModules: new Map<String, StringModule>()
    });

export default StringsStore;