/******************************************************************************************************************************
 * When adding a new flight, create a type for it here, add it to the FlightKey union type, add it to the FlightKeys enum, and
 * add it to the FlightSettings interface
 *****************************************************************************************************************************/

type AllowShiftRequestSeenStates = "allowShiftRequestSeenStates";
type ForceUpgradeToAppStore = "forceUpgradeToAppStore";
type ForceUpgradeMsaToAdalAccount = "forceUpgradeMsaToAdalAccount";
type AllowSharedFiles = "allowSharedFiles";
type AllowShiftRequestUnreadCount = "allowShiftRequestUnreadCount";
type MinimumShiftRequestReadDate = "minimumShiftRequestReadDate";
type EnableContentSharing = "enableContentSharing";
type EnableWorkPreferences = "enableWorkPreferences";
type EnableExportToCsvOnWeb = "enableExportToCsvOnWeb";
type EnableYammerIntegration = "enableYammerIntegration";
type EnableCustomTimeOffReasons = "enableCustomTimeOffReasons";
type EnableExportToExcelOnWeb = "enableExportToExcelOnWeb";
type EnableImportFromExcelOnService = "enableImportFromExcelOnService";
type UseUserBasedConversations = "useUserBasedConversations";
type EnableFilesTabDiscoveryTour = "enableFilesTabDiscoveryTour";
type RevisedShiftLookup = "revisedShiftLookup";
type EnableImportFromExcelOnWeb = "enableImportFromExcelOnWeb";
type AllowActivityFeedFeature = "allowActivityFeedFeature";
type EnableSubshifts = "enableSubshifts";
type EnableSkypeTeams = "enableSkypeTeams";
type EnableAllExtensions = "enableAllExtensions";
type EnabledExtensionIds = "enabledExtensionIds";
type InstrumentationKey = "instrumentationKey";
type AllowAnnouncements = "allowAnnouncements";
type AllowGriffinAnnouncements = "allowGriffinAnnouncements";
type EnableFilesTabForNonAdmins = "enableFilesTabForNonAdmins";
type EnableNativeTimeClock = "enableNativeTimeClock"; // Used for Staffhub only
type EnableTeamsTimeClock  = "enableTeamsTimeClock ";
type EnableEditTimeClock = "enableEditTimeClock";
type EnableTenantAdminReporting = "enableTenantAdminReporting";
type EnableFlowExperiments = "enableFlowExperiments";
type EnableCustomFields = "enableCustomFields";
type EnablePublishV2 = "enablePublishV2";
type EnableShiftUndoWeb = "enableShiftUndoWeb";
type EnableOpenShifts = "enableOpenShifts";
type EnableScheduleAvailability = "enableScheduleAvailability";
type EnableShiftRequestsOnWeb = "enableShiftRequestsOnWeb";
type EnableSynchronousExport = "enableSynchronousExport";
type EnablePrintScaleToOnePage = "enablePrintScaleToOnePage";
type DisableSwapAndHandOffRequests = "disableSwapAndHandOffRequests";
type DisableAllDayTimeOff = "disableAllDayTimeOff";
type EnableLastNameInitial = "enableLastNameInitial";
type EnableTeamsConvergence = "enableTeamsConvergence";
type EnableExtensionImportSchedule = "enableExtensionImportSchedule";
type EnablePhoneAuth = "enablePhoneAuth";
type EnableOpenShiftRequestsOnWeb = "enableOpenShiftRequestsOnWeb";
type DefaultApprovalRadius = "DefaultApprovalRadius";

export type FlightKey = AllowShiftRequestSeenStates |
                        ForceUpgradeToAppStore |
                        ForceUpgradeMsaToAdalAccount |
                        AllowSharedFiles |
                        AllowShiftRequestUnreadCount |
                        MinimumShiftRequestReadDate |
                        EnableContentSharing |
                        EnableWorkPreferences |
                        EnableExportToCsvOnWeb |
                        EnableYammerIntegration |
                        EnableCustomTimeOffReasons |
                        EnableExportToExcelOnWeb |
                        UseUserBasedConversations |
                        EnableFilesTabDiscoveryTour |
                        RevisedShiftLookup |
                        EnableImportFromExcelOnWeb |
                        EnableImportFromExcelOnService |
                        AllowActivityFeedFeature |
                        EnableSubshifts |
                        EnableSkypeTeams |
                        EnableAllExtensions |
                        EnabledExtensionIds |
                        InstrumentationKey |
                        AllowAnnouncements |
                        AllowGriffinAnnouncements |
                        EnableFilesTabForNonAdmins |
                        EnableNativeTimeClock |
                        EnableTeamsTimeClock |
                        EnableEditTimeClock |
                        EnableTenantAdminReporting |
                        EnableFlowExperiments |
                        EnableCustomFields |
                        EnablePublishV2 |
                        EnableShiftUndoWeb |
                        EnableOpenShifts |
                        EnableScheduleAvailability |
                        EnableShiftRequestsOnWeb |
                        EnableSynchronousExport |
                        EnablePrintScaleToOnePage |
                        DisableSwapAndHandOffRequests |
                        DisableAllDayTimeOff |
                        EnableLastNameInitial |
                        EnableTeamsConvergence |
                        EnableExtensionImportSchedule |
                        EnablePhoneAuth |
                        EnableOpenShiftRequestsOnWeb |
                        DefaultApprovalRadius;

export const FlightKeys = {
    AllowShiftRequestSeenStates: "allowShiftRequestSeenStates" as AllowShiftRequestSeenStates,
    ForceUpgradeToAppStore: "forceUpgradeToAppStore" as ForceUpgradeToAppStore,
    ForceUpgradeMsaToAdalAccount: "forceUpgradeMsaToAdalAccount" as ForceUpgradeMsaToAdalAccount,
    AllowSharedFiles: "allowSharedFiles" as AllowSharedFiles,
    AllowShiftRequestUnreadCount: "allowShiftRequestUnreadCount" as AllowShiftRequestUnreadCount,
    MinimumShiftRequestReadDate: "minimumShiftRequestReadDate" as MinimumShiftRequestReadDate,
    EnableContentSharing: "enableContentSharing" as EnableContentSharing,
    EnableWorkPreferences: "enableWorkPreferences" as EnableWorkPreferences,
    EnableExportToCsvOnWeb: "enableExportToCsvOnWeb" as EnableExportToCsvOnWeb,
    EnableYammerIntegration: "enableYammerIntegration" as EnableYammerIntegration,
    EnableCustomTimeOffReasons: "enableCustomTimeOffReasons" as EnableCustomTimeOffReasons,
    EnableExportToExcelOnWeb: "enableExportToExcelOnWeb" as EnableExportToExcelOnWeb,
    UseUserBasedConversations: "useUserBasedConversations" as UseUserBasedConversations,
    EnableFilesTabDiscoveryTour: "enableFilesTabDiscoveryTour" as EnableFilesTabDiscoveryTour,
    RevisedShiftLookup: "revisedShiftLookup" as RevisedShiftLookup,
    EnableImportFromExcelOnWeb: "enableImportFromExcelOnWeb" as EnableImportFromExcelOnWeb,
    EnableImportFromExcelOnService: "enableImportFromExcelOnService" as EnableImportFromExcelOnService,
    AllowActivityFeedFeature: "allowActivityFeedFeature" as AllowActivityFeedFeature,
    EnableSubshifts: "enableSubshifts" as EnableSubshifts,
    EnableSkypeTeams: "enableSkypeTeams" as EnableSkypeTeams,
    EnableAllExtensions: "enableAllExtensions" as EnableAllExtensions,
    EnabledExtensionIds: "enabledExtensionIds" as EnabledExtensionIds,
    InstrumentationKey: "instrumentationKey" as InstrumentationKey,
    AllowAnnouncements: "allowAnnouncements" as AllowAnnouncements,
    AllowGriffinAnnouncements: "allowGriffinAnnouncements" as AllowGriffinAnnouncements,
    EnableFilesTabForNonAdmins: "enableFilesTabForNonAdmins" as EnableFilesTabForNonAdmins,
    EnableNativeTimeClock: "enableNativeTimeClock" as EnableNativeTimeClock,
    EnableTeamsTimeClock: "enableTeamsTimeClock" as EnableTeamsTimeClock,
    EnableEditTimeClock: "enableEditTimeClock" as EnableEditTimeClock,
    EnableTenantAdminReporting: "enableTenantAdminReporting" as EnableTenantAdminReporting,
    EnableFlowExperiments: "enableFlowExperiments" as EnableFlowExperiments,
    EnableCustomFields: "enableCustomFields" as EnableCustomFields,
    EnablePublishV2: "enablePublishV2" as EnablePublishV2,
    EnableShiftUndoWeb: "enableShiftUndoWeb" as EnableShiftUndoWeb,
    EnableOpenShifts: "enableOpenShifts" as EnableOpenShifts,
    EnableScheduleAvailability: "enableScheduleAvailability" as EnableScheduleAvailability,
    EnableShiftRequestsOnWeb: "enableShiftRequestsOnWeb" as EnableShiftRequestsOnWeb,
    EnableSynchronousExport: "enableSynchronousExport" as EnableSynchronousExport,
    EnablePrintScaleToOnePage: "enablePrintScaleToOnePage" as EnablePrintScaleToOnePage,
    DisableSwapAndHandOffRequests: "disableSwapAndHandOffRequests" as DisableSwapAndHandOffRequests,
    DisableAllDayTimeOff: "disableAllDayTimeOff" as DisableAllDayTimeOff,
    EnableLastNameInitial: "enableLastNameInitial" as EnableLastNameInitial,
    EnableTeamsConvergence: "enableTeamsConvergence" as EnableTeamsConvergence,
    EnableExtensionImportSchedule: "enableExtensionImportSchedule" as EnableExtensionImportSchedule,
    EnablePhoneAuth: "enablePhoneAuth" as EnablePhoneAuth,
    EnableOpenShiftRequestsOnWeb: "enableOpenShiftRequestsOnWeb" as EnableOpenShiftRequestsOnWeb,
    DefaultApprovalRadius: "defaultApprovalRadius" as DefaultApprovalRadius
};

export type FlightSetting = boolean | Date | number | string;
export interface FlightSettings {
    allowShiftRequestSeenStates: boolean;
    forceUpgradeToAppStore: boolean;
    forceUpgradeMsaToAdalAccount: boolean;
    allowSharedFiles: boolean;
    allowShiftRequestUnreadCount: boolean;
    minimumShiftRequestReadDate: Date;
    enableContentSharing: boolean;
    enableWorkPreferences: boolean;
    enableExportToCsvOnWeb: boolean;
    enableYammerIntegration: boolean;
    enableCustomTimeOffReasons: boolean;
    enableExportToExcelOnWeb: boolean;
    useUserBasedConversations: boolean;
    enableFilesTabDiscoveryTour: boolean;
    revisedShiftLookup: boolean;
    enableImportFromExcelOnWeb: boolean;
    enableImportFromExcelOnService: boolean;
    allowActivityFeedFeature: boolean;
    enableSubshifts: boolean;
    enableSkypeTeams: boolean;
    enableAllExtensions: boolean;
    enabledExtensionIds: string;
    instrumentationKey: string;
    allowAnnouncements: boolean;
    allowGriffinAnnouncements: boolean;
    enableFilesTabForNonAdmins: boolean;
    enableNativeTimeClock: boolean;
    enableTeamsTimeClock: boolean;
    enableEditTimeClock: boolean;
    enableTenantAdminReporting: boolean;
    enableFlowExperiments: boolean;
    enableCustomFields: boolean;
    enablePublishV2: boolean;
    enableShiftUndoWeb: boolean;
    enableOpenShifts: boolean;
    enableShiftRequestsOnWeb: boolean;
    enableScheduleAvailability: boolean;
    enableSynchronousExport: boolean;
    enablePrintScaleToOnePage: boolean;
    disableSwapAndHandOffRequests: boolean;
    disableAllDayTimeOff: boolean;
    enableLastNameInitial: boolean;
    enableTeamsConvergence: boolean;
    enableExtensionImportSchedule: boolean;
    enablePhoneAuth: boolean;
    enableOpenShiftRequestsOnWeb: boolean;
    defaultApprovalRadius: number;
    [key: string]: FlightSetting; // we create an index signature so that FlightSettingsService can index into flight settings
}