import { ObservableMap } from "mobx";
import * as moment from "moment";
import DateTimeFormatter from "sh-application/utility/DateTimeFormatter";
import * as MemoizeUtils from "sh-application/utility/MemoizeUtils";
import {
    AvailabilityEntity,
    IAvailabilityEntity,
    IMemberEntity,
    IShownAvailabilitiesMap,
    ScheduleCalendarType,
    ScheduleCalendarTypes
} from "sh-models";
import { IRecurrencePattern, IRecurrenceRange, ITime, ITimeSlot, RecurrencePatternTypes, RecurrenceRangeTypes, RecurringValues } from "sh-models/availability/IAvailabilityEntity";
import StringsStore from "sh-strings/store";
import { TeamSettingsStore } from "sh-teamsettings-store";

import { IsAvailableDuringTimeSlotsParams } from "./availability/IsAvailableDuringTimeSlotsParams";
import { IsConflictingAvailabilityParams } from "./availability/IsConflictingAvailabilityParams";
import { IsOverlappingAvailabilityParams } from "./availability/IsOverlappingAvailabilityParams";
import { BaseAvailabilityParams } from "./availability/BaseAvailabilityParams";

// 3.0 hours is enough space to display any availability in our minimum screen size.
// The width of the availability component is set to 132px which is 3 hours in our minimum screensize and any
// translated text that surpasses that length will be cut off with ellipsis.
const MinimumHoursNeededToShowAvailabilitiesInDayView: number = 3.0;

/**
 * Utils class for IAvailabilityEntity
 */
export default class AvailabilityUtils {
    private static strings: Map<string, string>;
    private static shiftStrings: Map<string, string>;
    private static schedulePageStrings: Map<string, string>;
    private static emptyCellAvailabilitiesLabel: string;

    private static ensureStringsAreInitialized() {
        if (!AvailabilityUtils.strings || !AvailabilityUtils.emptyCellAvailabilitiesLabel) {
            AvailabilityUtils.strings = StringsStore().registeredStringModules.get("availability").strings;
            AvailabilityUtils.shiftStrings = StringsStore().registeredStringModules.get("shiftRequests").strings;
            AvailabilityUtils.schedulePageStrings = StringsStore().registeredStringModules.get("schedulePage").strings;
            AvailabilityUtils.emptyCellAvailabilitiesLabel = StringsStore().registeredStringModules.get("schedulePage").strings.get("emptyCellAvailabilitiesLabel");
        }
    }

    private static orderedRecurringValues = [
        RecurringValues.sunday,
        RecurringValues.monday,
        RecurringValues.tuesday,
        RecurringValues.wednesday,
        RecurringValues.thursday,
        RecurringValues.friday,
        RecurringValues.saturday
    ];

    /**
     * Get the recurring value corresponding to a moment day of week (0 being sunday)
     */
    public static getRecurringValue(dayOfWeek: number): RecurringValues {
        return AvailabilityUtils.orderedRecurringValues[dayOfWeek];
    }

    /**
     * Gets the availability associated with the given date.
     * If the date is included in one availability per date range, it will return that entry.
     * Else if the date has the same weekday as a recurring availability, it will return that entry.
     * Else it will return undefined.
     * @param availabilities The member availabilities.
     * @param date The date for which we calculating the availability conflicts.
     * @param targetTimeZone The target time-zone.
     * @returns The availability for the date if there is any, otherwise the availability for that day of the week if there is any, otherwise undefined.
     */
    // TODO newAvailability: needs to be able to handle receiving multiple availabilities for a given date when timeZones cause overlap on two days
    public static getAvailabilityForDate(availabilities: IAvailabilityEntity[], date: moment.Moment, targetTimeZone: string): IAvailabilityEntity | undefined {
        return availabilities.reduce((availabilityForDate: IAvailabilityEntity | undefined, availability: IAvailabilityEntity) => {
            const { recurrencePattern, recurrenceRange } = availability;

            if (recurrenceRange) {
                const startDate = moment.tz(recurrenceRange.startDate, targetTimeZone);
                const endDate = moment.tz(recurrenceRange.endDate, targetTimeZone);

                if (date.isBetween(startDate, endDate, "minute", "[)")) {
                    return availability;
                }
            } else if (recurrencePattern && !availabilityForDate) {
                const recurringValue = AvailabilityUtils.getRecurringValue(date.day());

                if (recurrencePattern.recurringValues.includes(recurringValue)) {
                    return availability;
                }
            }

            return availabilityForDate;
        }, undefined);
    }

    /**
     * Filters the availabilities that overlaps with the given date range, and merges them into one availability entity.
     * Important: Only supports one day date range.
     * If 2 per dates overlap, will merge into a single per date.
     * If 2 per weekdays overlap, will merge into a single per weekday.
     * If 1 per date, and 1 per weekday overlap will merge into a single per date and discard the weekday one.
     * If none overlap, will return undefined.
     * @param availabilities The member availabilities.
     * @param startDate The start date for which to get the member availability.
     * @param endDate The end date for which to get the member availability.
     * @param targetTimeZone The target time-zone.
     * @returns The availability for the date if there is any, otherwise the availability for that day of the week if there is any, otherwise undefined.
     */
    public static mergeAvailabilitiesForDateRange(availabilities: IAvailabilityEntity[], startDate: moment.Moment, endDate: moment.Moment, targetTimeZone: string): IAvailabilityEntity | undefined {
        let merged: IAvailabilityEntity | undefined = undefined;

        const overlappingAvailabilities = availabilities?.filter(availability =>
            this.isOverlappingAvailability({
                availability,
                endDate,
                startDate,
                targetTimeZone
            })
        );

        const availabilitiesForDate = this.removePerWeekdayOverlappingWithPerDate(overlappingAvailabilities, startDate, endDate, targetTimeZone);

        if (availabilitiesForDate.length == 1) {
            const [availability] = availabilitiesForDate;

            merged = this.convertAvailability(availability, startDate, endDate, targetTimeZone);
        } else if (availabilitiesForDate.length > 1) {
            const [first, second] = availabilitiesForDate;

            merged = this.mergeAvailabilities(first, second, startDate, endDate, targetTimeZone);
        }

        return merged;
    }

    /**
     * Converts given availability to given time-zone within given date range boundaries.
     * @param availability The availability to convert.
     * @param startDate The start date for which to get the member availability.
     * @param endDate The end date for which to get the member availability.
     * @param targetTimeZone The target time-zone.
     * @returns The converted availability.
     */
    private static convertAvailability(availability: IAvailabilityEntity, startDate: moment.Moment, endDate: moment.Moment, targetTimeZone: string): IAvailabilityEntity {
        let convertedAvailability: IAvailabilityEntity | undefined = undefined;

        if (this.isAvailabilityPerWeekday(availability)) {
            convertedAvailability = this.convertAvailabilityPerWeekday(availability, startDate, endDate, targetTimeZone);
        } else if (this.isAvailabilityPerDate(availability)) {
            convertedAvailability = this.convertAvailabilityPerDate(availability, startDate, endDate, targetTimeZone);
        }

        return convertedAvailability;
    }

    /**
     * Merges two availabilities overlapping the date range into a single availability using the target time-zone.
     * If 2 per dates, will merge into a single per date.
     * If 2 per weekdays, will merge into a single per weekday.
     * If 1 per date, and 1 per weekday, will merge into a single per date and discard the weekday one.
     * @param first The first availability.
     * @param second The second availability.
     * @param startDate The date from which we're merging the availabilities.
     * @param endDate The date until which we're merging the availabilities.
     * @param targetTimeZone The target time-zone (olson code).
     * @returns The merged availability, 24 hours long, starting at startDate.
     */
    private static mergeAvailabilities(first: IAvailabilityEntity, second: IAvailabilityEntity, startDate: moment.Moment, endDate: moment.Moment, targetTimeZone: string): IAvailabilityEntity | undefined {
        let mergedAvailability: IAvailabilityEntity | undefined = undefined;

        const isFirstPerWeekday = this.isAvailabilityPerWeekday(first);
        const isFirstPerDate = this.isAvailabilityPerDate(first);
        const isSecondPerWeekday = this.isAvailabilityPerWeekday(second);
        const isSecondPerDate = this.isAvailabilityPerDate(second);

        if (isFirstPerWeekday && isSecondPerWeekday) {
            mergedAvailability = this.mergeAvailabilitiesPerWeekday(first, second, startDate, endDate, targetTimeZone);
        } else if (isFirstPerDate && isSecondPerDate) {
            mergedAvailability = this.mergeAvailabilitiesPerDate(first, second, startDate, endDate, targetTimeZone);
        } else if (isFirstPerDate) {
            mergedAvailability = this.convertAvailabilityPerDate(first, startDate, endDate, targetTimeZone);
        } else if (isSecondPerDate) {
            mergedAvailability = this.convertAvailabilityPerDate(second, startDate, endDate, targetTimeZone);
        } else if (isFirstPerWeekday) {
            mergedAvailability = this.convertAvailabilityPerWeekday(first, startDate, endDate, targetTimeZone);
        } else if (isSecondPerWeekday) {
            mergedAvailability = this.convertAvailabilityPerWeekday(second, startDate, endDate, targetTimeZone);
        }

        return mergedAvailability;
    }

    /**
     * Merges two availabilities per weekday into a single per weekday availability using target time-zone.
     * @param first The first availability.
     * @param second The second availability.
     * @param startDate The start date.
     * @param endDate The end date.
     * @param targetTimeZone The target time-zone.
     * @returns The merged availability per weekday using target time-zone.
     */
    public static mergeAvailabilitiesPerWeekday(first: IAvailabilityEntity, second: IAvailabilityEntity, startDate: moment.Moment, endDate: moment.Moment, targetTimeZone: string): IAvailabilityEntity {
        // TODO(Availability): find better way to create ID
        const id = first?.id + second?.id;
        const allDay = first?.allDay && second?.allDay;
        const day = startDate.day();
        let timeSlots: ITimeSlot[] = [];

        const recurrencePattern: IRecurrencePattern = {
            interval: 0,
            recurringValues: [this.orderedRecurringValues[day]],
            type: RecurrencePatternTypes.Weekly
        };

        if (!first?.allDay && this.hasTimeSlots(first)) {
            timeSlots = timeSlots.concat(this.convertAndFilterInRangeTimeSlots(first, startDate, endDate, targetTimeZone));
        }

        if (!second?.allDay && this.hasTimeSlots(second)) {
            timeSlots = timeSlots.concat(this.convertAndFilterInRangeTimeSlots(second, startDate, endDate, targetTimeZone));
        }

        // TODO(Availability): Merge overlapping time slots.
        return new AvailabilityEntity(allDay, id, timeSlots, targetTimeZone, recurrencePattern);
    }

    /**
     * Merges two availabilities per date into a single per date availability using target time-zone.
     * @param first The first availability.
     * @param second The second availability.
     * @param startDate The start date.
     * @param endDate The end date.
     * @param targetTimeZone The target time-zone.
     * @returns The merged availability per date using target time-zone.
     */
    private static mergeAvailabilitiesPerDate(first: IAvailabilityEntity, second: IAvailabilityEntity, startDate: moment.Moment, endDate: moment.Moment, targetTimeZone: string): IAvailabilityEntity {
        // TODO(Availability): Find better way to create ID
        const id = first.id + second.id;
        const allDay = first.allDay && second.allDay;
        let timeSlots: ITimeSlot[] = [];

        const recurrenceRange: IRecurrenceRange = {
            endDate: startDate.format(),
            recurrenceType: RecurrenceRangeTypes.EndDate,
            startDate: startDate.format()
        };

        if (!first?.allDay && this.hasTimeSlots(first)) {
            timeSlots = timeSlots.concat(this.convertAndFilterInRangeTimeSlots(first, startDate, endDate, targetTimeZone));
        }

        if (!second?.allDay && this.hasTimeSlots(second)) {
            timeSlots = timeSlots.concat(this.convertAndFilterInRangeTimeSlots(second, startDate, endDate, targetTimeZone));
        }

        return new AvailabilityEntity(allDay, id, timeSlots, targetTimeZone, null, recurrenceRange);
    }

    /**
     * Converts given availability per weekday to target time-zone.
     * @param availability The availability to convert.
     * @param startDate The start date.
     * @param endDate The end date.
     * @param targetTimeZone The target time-zone.
     * @returns The converted availability.
     */
    private static convertAvailabilityPerWeekday(availability: IAvailabilityEntity | undefined, startDate: moment.Moment, endDate: moment.Moment, targetTimeZone: string): IAvailabilityEntity | undefined {
        if (!availability) {
            return undefined;
        }

        // TODO(Availability): find better way to create ID
        const id = availability.id + targetTimeZone;
        const allDay = availability.allDay;
        const day = startDate.day();
        let timeSlots: ITimeSlot[] = [];

        const recurrencePattern: IRecurrencePattern = {
            interval: 0,
            recurringValues: [this.orderedRecurringValues[day]],
            type: RecurrencePatternTypes.Weekly
        };

        if (!availability.allDay && this.hasTimeSlots(availability)) {
            timeSlots = this.convertAndFilterInRangeTimeSlots(availability, startDate, endDate, targetTimeZone);
        }

        return new AvailabilityEntity(allDay, id, timeSlots, targetTimeZone, recurrencePattern, null);
    }

    /**
     * Converts given availability per date to target time-zone.
     * @param availability The availability to convert.
     * @param startDate The start date.
     * @param endDate The end date.
     * @param targetTimeZone The target time-zone.
     * @returns The converted availability.
     */
    private static convertAvailabilityPerDate(availability: IAvailabilityEntity | undefined, startDate: moment.Moment, endDate: moment.Moment, targetTimeZone: string): IAvailabilityEntity | undefined {
        if (!availability) {
            return undefined;
        }

        // TODO(Availability): find better way to create ID
        const id = availability.id + targetTimeZone;
        const allDay = availability.allDay;
        let timeSlots: ITimeSlot[] = [];

        const recurrenceRange: IRecurrenceRange = {
            endDate: startDate.toISOString(),
            recurrenceType: RecurrenceRangeTypes.EndDate,
            startDate: startDate.toISOString()
        };

        if (!availability.allDay && this.hasTimeSlots(availability)) {
            timeSlots = this.convertAndFilterInRangeTimeSlots(availability, startDate, endDate, targetTimeZone);
        }

        return new AvailabilityEntity(allDay, id, timeSlots, targetTimeZone, null, recurrenceRange);
    }

    /**
     * Converts given time slots to target time-zone. Removed time slots that are out of range.
     * @param availability The availability to get time slots from.
     * @param startDate The start date.
     * @param endDate The end date.
     * @param targetTimeZone The target time-zone.
     * @returns The converted and filtered time slots.
     */
    private static convertAndFilterInRangeTimeSlots(availability: IAvailabilityEntity, startDate: moment.Moment, endDate: moment.Moment, targetTimeZone: string): ITimeSlot[] {
        const { timeSlots } = availability;
        const availabilityStartDate = this.getAvailabilityStartDateInAvailabilityTimeZone(availability, startDate);

        return timeSlots.reduce((acc, timeSlot) => {
            const start = this.setTimeInDate(availabilityStartDate, timeSlot.startTime);
            const end = this.setTimeInDate(availabilityStartDate, timeSlot.endTime);
            const startTime = moment.tz(start, targetTimeZone);
            const endTime = moment.tz(end, targetTimeZone);

            // start is inclusive and end is exclusive
            if (startTime.isSameOrAfter(startDate) && endTime.isBefore(endDate)) {
                // Fully inclusive
                acc.push(this.createTimeSlot(startTime, endTime));
            } else if (startTime.isBefore(startDate) && endTime.isAfter(startDate)) {
                // Remove out of range time before start date
                acc.push(this.createTimeSlot(startDate, endTime));
            } else if (startTime.isBefore(endDate) && endTime.isAfter(endDate)) {
                // Remove out of range time after end date
                acc.push(this.createTimeSlot(startTime, endDate));
            } else if (startTime.isBefore(startDate) && endTime.isAfter(endDate)) {
                // Remove out of range before start date and after end date
                acc.push(this.createTimeSlot(startDate, endDate));
            }

            return acc;
        }, []);
    }

    /**
     * Creates a time slot from given start and end time.
     * @param startTime The start date-time.
     * @param endTime The end date-time.
     * @returns The created time slot.
     */
    private static createTimeSlot(startTime: moment.Moment, endTime: moment.Moment): ITimeSlot {
        return {
            endTime: {
                hour: endTime.hour(),
                minute: endTime.minute()
            },
            startTime: {
                hour: startTime.hour(),
                minute: startTime.minute()
            }
        };
    }

    /**
     * Sets given time in given date.
     * @param date The date.
     * @param time The time.
     * @returns The date copy with time set.
     */
    private static setTimeInDate(date: moment.Moment, time: ITime): moment.Moment {
        const dateTime = date.clone();

        dateTime.set("hours", time.hour);
        dateTime.startOf("hour");
        dateTime.set("minutes", time.minute);

        return dateTime;
    }

    /**
     * Prefixes given value with zero if it's less than 10.
     * @param value The value.
     * @returns The prefixed stringify value.
     */
    private static prefixWithZero(value: number): string {
        if (value < 10) {
            return `0${value}`;
        }

        return `${value}`;
    }

    /**
     * Gets given availability start date in availability time-zone.
     * If the availability is per date, it will return its start date.
     * If the availability is per weekday, it will return the start date of the week day in the availability time-zone by using given 'startDate' argument to be compliant with DST (Daylight savings).
     * @param availability The availability.
     * @param startDate The start date.
     * @returns The availability start date in availability time-zone.
     */
    private static getAvailabilityStartDateInAvailabilityTimeZone(availability: IAvailabilityEntity, startDate: moment.Moment): moment.Moment {
        if (this.isAvailabilityPerDate(availability)) {
            const { recurrenceRange } = availability;

            return moment.tz(recurrenceRange.startDate, availability.timeZoneOlsonCode);
        }

        const { recurrencePattern: { recurringValues }, timeZoneOlsonCode } = availability;
        const day = startDate.day();
        const availabilityDay = this.orderedRecurringValues.indexOf(recurringValues[0]);
        const year = startDate.year();
        const month = this.prefixWithZero(startDate.month() + 1);
        const dayOfMonth = this.prefixWithZero(startDate.date());
        const date = moment.tz(`${year}-${month}-${dayOfMonth} 00:00:00`, timeZoneOlsonCode);

        if (availabilityDay < day) {
            date.subtract(day - availabilityDay, "days");
        } else if (availabilityDay > day) {
            date.add(availabilityDay - day, "days");
        }

        return date;
    }

    /**
     * Get all conflicting availabilities for a date range.
     * @param availabilities All the availabilities of the member.
     * @param startDateTime The start date of the date range for which we calculating the availability conflicts.
     * @param endDateTime The end date of the date range for which we calculating the availability conflicts.
     * @param targetTimeZone The target time-zone.
     * @returns All conflicting availabilities.
     */
    public static getConflictingAvailabilities(availabilities: IAvailabilityEntity[], startDateTime: moment.Moment, endDateTime: moment.Moment, targetTimeZone: string): IAvailabilityEntity[] {
        const startDate = moment.tz(startDateTime.clone(), targetTimeZone);
        const endDate = moment.tz(endDateTime.clone(), targetTimeZone);
        const conflictingAvailabilities: IAvailabilityEntity[] = [];

        for (const availability of availabilities) {
            const isConflicting = this.isConflictingAvailability({
                availability,
                endDate,
                startDate,
                targetTimeZone
            });

            if (isConflicting) {
                conflictingAvailabilities.push(availability);
            }
        }

        return this.removePerWeekdayOverlappingWithPerDate(conflictingAvailabilities, startDate, endDate, targetTimeZone);
    }

    /**
     * Removes availabilities that are per weekday and overlap with availabilities per date because
     * availabilities per dates are prioritized over availabilities per weekdays.
     * @param availabilities The availabilities.
     * @param startDate The start date.
     * @param endDate The end date.
     * @param targetTimeZone The target time-zone.
     * @returns The availabilities without per weekday availabilities that overlap with per date ones.
     */
    private static removePerWeekdayOverlappingWithPerDate(availabilities: IAvailabilityEntity[], startDate: moment.Moment, endDate: moment.Moment, targetTimeZone: string): IAvailabilityEntity[] {
        const filteredAvailabilities: IAvailabilityEntity[] = [];
        const perDateWeekdays = new Set<RecurringValues>();

        for (const availability of availabilities) {
            if (this.isAvailabilityPerDate(availability)) {
                const availabilityWeekdays = this.getWeekdaysFromAvailabilityPerDate({
                    availability,
                    endDate,
                    startDate,
                    targetTimeZone
                });

                Array.from(availabilityWeekdays.values()).forEach((weekday) => {
                    perDateWeekdays.add(weekday);
                });
            }
        }

        for (const availability of availabilities) {
            let covered = false;

            if (this.isAvailabilityPerWeekday(availability)) {
                const availabilityWeekdays = this.getWeekdaysFromAvailabilityPerWeekday({
                    availability,
                    endDate,
                    startDate,
                    targetTimeZone
                });

                covered = Array.from(availabilityWeekdays.values()).some((weekday) => perDateWeekdays.has(weekday));
            }

            if (!covered) {
                filteredAvailabilities.push(availability);
            }
        }

        return filteredAvailabilities;
    }

    /**
     * Checks whether given availability overlaps with given date range times.
     * @param params The parameters.
     * @returns Whether given availability overlaps with given date range times.
     */
    private static isOverlappingAvailability(params: BaseAvailabilityParams): boolean {
        const { availability, endDate, startDate, targetTimeZone } = params;
        let isOverlapping = false;

        if (this.isAvailabilityPerDate(availability)) {
            isOverlapping = this.isOverlappingAvailabilityPerDate({
                availability,
                endDate,
                startDate,
                targetTimeZone
            });
        } else if (this.isAvailabilityPerWeekday(availability)) {
            isOverlapping = this.isOverlappingAvailabilityPerWeekday({
                availability,
                endDate,
                startDate,
                targetTimeZone
            });
        }

        return isOverlapping;
    }

    /**
     * Checks whether given availability conflicts with given date range times.
     * @param params The parameters.
     * @returns Whether given availability conflicts with given date range times.
     */
    private static isConflictingAvailability(params: IsConflictingAvailabilityParams): boolean {
        const { availability, endDate, startDate, targetTimeZone } = params;
        const isOverlapping = this.isOverlappingAvailability(params);

        if (!isOverlapping) {
            return false;
        }

        const isAvailable = this.isAvailableDuringTimeSlots({
            availability,
            endDate,
            startDate,
            targetTimeZone
        });

        return isAvailable ? false : true;
    }

    /**
     * Checks whether given availability per date overlaps with given date range times.
     * @param params The parameters.
     * @returns Whether given availability per date overlaps with given date range times.
     */
    private static isOverlappingAvailabilityPerDate(params: IsOverlappingAvailabilityParams): boolean {
        const { availability, endDate, startDate, targetTimeZone } = params;
        const { recurrenceRange } = availability;
        const availabilityStart = moment.tz(recurrenceRange.startDate, targetTimeZone);
        const availabilityEnd = moment.tz(recurrenceRange.endDate, targetTimeZone);
        const endDateMidnight = endDate.clone();

        // Given each schedule cell range is from midnight to 23:59 we round up end to midnight so that we don't overlap with availabilities starting/ending at midnight.
        if (endDate.hours() == 23 && endDate.minutes() == 59) {
            endDateMidnight.add(1, "minute");
        }

        return (
            startDate.isBetween(availabilityStart, availabilityEnd, "minute", "[)") ||
            endDateMidnight.isBetween(availabilityStart, availabilityEnd, "minute", "()")
        );
    }

    /**
     * Checks whether given availability is set for a date range.
     * @param availability The availability entry.
     * @returns Whether given availability is set for a date range.
     */
    private static isAvailabilityPerDate(availability: IAvailabilityEntity): boolean {
        return !!availability?.recurrenceRange?.startDate;
    }

    /**
     * Checks whether given availability is set for recurring weekdays.
     * @param availability The availability entry.
     * @returns Whether given availability is set for recurring weekdays.
     */
    private static isAvailabilityPerWeekday(availability: IAvailabilityEntity): boolean {
        return availability?.recurrencePattern?.recurringValues?.length > 0;
    }

    /**
     * Gets the weekdays covered by given availability per date.
     * @param params The parameters.
     * @returns The weekdays covered by given availability per date.
     */
    private static getWeekdaysFromAvailabilityPerDate(params: IsOverlappingAvailabilityParams): Set<RecurringValues> {
        const { availability, targetTimeZone } = params;
        const { recurrenceRange } = availability;
        const availabilityStart = moment.tz(recurrenceRange.startDate, targetTimeZone);
        const availabilityEnd = moment.tz(recurrenceRange.endDate, targetTimeZone);
        const startWeekday = AvailabilityUtils.getRecurringValue(availabilityStart.day());
        const endWeekday = AvailabilityUtils.getRecurringValue(availabilityEnd.day());

        return new Set([
            startWeekday,
            endWeekday
        ]);
    }

    /**
     * Gets the weekdays covered by given availability per weekday.
     * @param params The parameters.
     * @returns The weekdays covered by given availability per weekday.
     */
    private static getWeekdaysFromAvailabilityPerWeekday(params: IsOverlappingAvailabilityParams): Set<RecurringValues> {
        const { availability, endDate, startDate, targetTimeZone } = params;
        const { recurrencePattern, timeZoneOlsonCode } = availability;
        const availabilityTimeZone = !timeZoneOlsonCode ? targetTimeZone : timeZoneOlsonCode;
        const convertedStartDate = moment.tz(startDate.clone(), availabilityTimeZone);
        const convertedEndDate = moment.tz(endDate.clone(), availabilityTimeZone);
        const startWeekday = AvailabilityUtils.getRecurringValue(convertedStartDate.day());
        const endWeekday = AvailabilityUtils.getRecurringValue(convertedEndDate.day());
        const availabilityWeekdays = new Set(recurrencePattern.recurringValues);
        const weekdays = new Set<RecurringValues>();

        if (availabilityWeekdays.has(startWeekday)) {
            weekdays.add(startWeekday);
        }

        if (availabilityWeekdays.has(endWeekday)) {
            weekdays.add(endWeekday);
        }

        return weekdays;
    }

    /**
     * Checks whether given availability per weekday overlaps with given date range times.
     * @param params The parameters.
     * @returns Whether given availability per weekday overlaps with given date range times.
     */
    private static isOverlappingAvailabilityPerWeekday(params: IsOverlappingAvailabilityParams): boolean {
        const { availability, endDate, startDate, targetTimeZone } = params;
        const { recurrencePattern, timeZoneOlsonCode } = availability;
        const availabilityTimeZone = !timeZoneOlsonCode ? targetTimeZone : timeZoneOlsonCode;
        const convertedStartDate = moment.tz(startDate.clone(), availabilityTimeZone);
        const convertedEndDate = moment.tz(endDate.clone(), availabilityTimeZone);
        const startWeekday = AvailabilityUtils.getRecurringValue(convertedStartDate.day());
        const endWeekday = AvailabilityUtils.getRecurringValue(convertedEndDate.day());
        const availabilityWeekdays = new Set(recurrencePattern.recurringValues);

        return (
            availabilityWeekdays.has(startWeekday) ||
            availabilityWeekdays.has(endWeekday)
        );
    }

    /**
     * Checks whether availability time slots are fully covering given date range times.
     * @param params The parameters.
     * @returns Whether availability time slots are fully covering given date range times.
     */
    private static isAvailableDuringTimeSlots(params: IsAvailableDuringTimeSlotsParams): boolean {
        const { availability, endDate, startDate, targetTimeZone } = params;
        const { timeSlots } = availability;

        if (!timeSlots?.length || availability.allDay) {
            return availability.allDay;
        }

        const targetTimeSlots = AvailabilityUtils.getTimeSlotsInTimeZone(availability, startDate, targetTimeZone);

        for (const timeSlot of targetTimeSlots) {
            const [targetStartTime, targetEndTime] = timeSlot;

            if (startDate.isSameOrAfter(targetStartTime) && endDate.isSameOrBefore(targetEndTime)) {
                return true;
            }
        }

        return false;
    }

    /**
     * Get the time slots for an availability as a list of moments in a given time zone.
     * @param availability The availability to get the time slots for.
     * @param startDateTime The date range start for which we're getting the time slots. Only used for weekday recurring availabilities.
     * @param targetTimeZone The time zone to get the time slots in.
     * @returns The list of time slots dates converted to given target time-zone.
     */
    private static getTimeSlotsInTimeZone(availability: IAvailabilityEntity, startDateTime: moment.Moment, targetTimeZone: string): moment.Moment[][] {
        const targetTimeSlots: moment.Moment[][] = [];
        const availabilityTimeZone = availability.timeZoneOlsonCode;
        let startDate = startDateTime;

        if (this.isAvailabilityPerDate(availability)) {
            startDate = moment.tz(availability.recurrenceRange.startDate, availabilityTimeZone);
        }

        const availabilityStartTime = moment.tz(startDate, availabilityTimeZone);

        for (const timeSlot of availability.timeSlots) {
            const { startTime, endTime } = timeSlot;
            const targetStartTime = availabilityStartTime.clone();
            const targetEndTime = availabilityStartTime.clone();

            targetStartTime.set("hours", startTime.hour);
            targetStartTime.startOf("hour"); // Reset seconds and milliseconds to 0
            targetStartTime.set("minutes", startTime.minute);
            targetEndTime.set("hours", endTime.hour);
            targetEndTime.startOf("hour"); // Reset seconds and milliseconds to 0
            targetEndTime.set("minutes", endTime.minute);

            targetTimeSlots.push([
                targetStartTime.tz(targetTimeZone),
                targetEndTime.tz(targetTimeZone)
            ]);
        }

        return targetTimeSlots;
    }

    /**
     * Gets the aria label for persona cell on assign open shift picker based on shift and availability
     * @param availabilities - members availabilities
     * @param displayName - members name
     * @returns aria label for persona cell on assign open shift picker
     */
    public static getMemberAvailabiltiesAriaLabel(displayName: string, availabilities: IAvailabilityEntity[], displayDates: string [], isOverMultipleDays: boolean): string {
        let ariaLabel: string = "";
        AvailabilityUtils.ensureStringsAreInitialized();
        // if there is more one availability shift spans over multiple days, use cross over aria labels
        if (availabilities) {
            if (isOverMultipleDays) {
                return this.getCrossOverDayArialLabel(availabilities, displayName, displayDates);
            } else {
                // otherwise we assume there is only one availability
                const firstDayAvailabilities: IAvailabilityEntity = availabilities[0];
                const firstDayUserFriendlyAvailabilitesString = this.getUserFriendlyStrings(firstDayAvailabilities);
                const firstDate: string = displayDates[0];
                const firstDayAvailabilitesString: string = (firstDayUserFriendlyAvailabilitesString && firstDayUserFriendlyAvailabilitesString.length) ? firstDayUserFriendlyAvailabilitesString.join(this.shiftStrings.get("dataValueDelimiter")) : "";
                if ( firstDayAvailabilities && this.isUnavailable(firstDayAvailabilities)) {
                    ariaLabel =  this.schedulePageStrings.get("unavailableAriaLabel").format(displayName, firstDate);
                } else {
                    ariaLabel =  this.schedulePageStrings.get("availableAriaLabel").format(displayName, firstDate, firstDayAvailabilitesString);
                }
            }
        }
        return ariaLabel;
    }

    /**
     * Gets the aria label for persona cell on assign open shift picker when shift spans over two days
     * @param availabilities - members availabilities
     * @param displayName - members name
     * @returns aria label for persona cell on assign open shift picker
     */
    public static getCrossOverDayArialLabel(availabilities: IAvailabilityEntity[] , displayName: string, displayDates: string[]): string {
        AvailabilityUtils.ensureStringsAreInitialized();
        let ariaLabel: string = "";
        const firstDayAvailabilities: IAvailabilityEntity = availabilities[0];
        const firstDayUserFriendlyAvailabilitesString = this.getUserFriendlyStrings(firstDayAvailabilities);
        const firstDate: string = displayDates[0];
        const firstDayAvailabilitesString: string = (firstDayUserFriendlyAvailabilitesString && firstDayUserFriendlyAvailabilitesString.length) ? firstDayUserFriendlyAvailabilitesString.join(this.shiftStrings.get("dataValueDelimiter")) : "";

        // next day availabilites for shift, availabilities array second values will be for the next day
        const nextDayAvailabilities: IAvailabilityEntity = availabilities[1];
        const nextDate: string = displayDates[1];
        const nextDayUserFriendlyAvailabilitesString = this.getUserFriendlyStrings(nextDayAvailabilities);
        const nextDayAvailabilitesString: string = (nextDayUserFriendlyAvailabilitesString && nextDayUserFriendlyAvailabilitesString.length > 0) ? nextDayUserFriendlyAvailabilitesString.join(this.shiftStrings.get("dataValueDelimiter")) : "";
        if ( firstDayAvailabilities && nextDayAvailabilities) {
            if (this.isUnavailable(firstDayAvailabilities) && !this.isUnavailable(nextDayAvailabilities)) {
                ariaLabel = this.schedulePageStrings.get("crossOverFirstDayUnavailableAriaLabel").format(displayName, firstDate, nextDate, nextDayAvailabilitesString);
            } else if (!this.isUnavailable(firstDayAvailabilities) && this.isUnavailable(nextDayAvailabilities) ) {
                ariaLabel = this.schedulePageStrings.get("crossOverNextDayUnavailableAriaLabel").format(displayName, firstDate, firstDayAvailabilitesString, nextDate);
            } else if (this.isUnavailable(firstDayAvailabilities) && this.isUnavailable(firstDayAvailabilities)) {
                ariaLabel = this.schedulePageStrings.get("crossOverUnavailableAriaLabel").format(displayName, firstDate, nextDate );
            } else {
                ariaLabel = this.schedulePageStrings.get("crossOverAvailableAriaLabel").format(displayName, firstDate, firstDayAvailabilitesString, nextDate, nextDayAvailabilitesString );
            }
    }
        return ariaLabel;
    }

    /**
     * Does an availability entity represent that the user is unavailable during this slot
     * @param availability
     */
    public static isUnavailable(availability: IAvailabilityEntity): boolean {
        // it's not an allday shift and there are no associated timeslots
        return !availability.allDay && !AvailabilityUtils.hasTimeSlots(availability);
    }

    /**
     * Checks whether given availability entity has time slot(s).
     * @param availability The availability.
     * @returns Whether given availability has time slot(s).
     */
    public static hasTimeSlots(availability: IAvailabilityEntity): boolean {
        if (!availability) {
            return null;
        }
        return availability.timeSlots && availability.timeSlots.length > 0;
    }

    /**
     * Get the user friendly representation of a time slot that a user is available in.
     * For english it will look like 8:00 AM - 5:00 PM.
     * @param timeSlot The time slot.
     * @param olsonCode The time slot (source) time-zone.
     * @param targetTimeZone The target time-zone.
     * @returns The user friendly representation of the time slot in schedule time-zone.
     */
    public static getUserFriendlyTimeSlot(timeSlot: ITimeSlot, olsonCode: string, targetTimeZone: string): string {
        AvailabilityUtils.ensureStringsAreInitialized();
        const timeZoneSupported = !!moment.tz.zone(olsonCode);

        if (timeZoneSupported) {
            const startTime = moment().tz(olsonCode).hour(timeSlot.startTime.hour).minute(timeSlot.startTime.minute).tz(targetTimeZone);
            const endTime = moment().tz(olsonCode).hour(timeSlot.endTime.hour).minute(timeSlot.endTime.minute).tz(targetTimeZone);

            return DateTimeFormatter.getEventTimeRangeAsString(startTime, endTime);
        } else {
            return "";
        }
    }

    /**
     * Get the aria label for an empty cell that has availabilities
     * @param availability
     */
    public static getEmptyCellAriaLabel(availability: IAvailabilityEntity): string {
        AvailabilityUtils.ensureStringsAreInitialized();
        return AvailabilityUtils.emptyCellAvailabilitiesLabel.format(...AvailabilityUtils.getLocalizedAvailabilityStringsForScreenreader(availability));
    }

    /**
     * Get user friendly strings to fill screenreader label for empty cell
     * @param availability
     */
    public static getLocalizedAvailabilityStringsForScreenreader(availability: IAvailabilityEntity): string[] {
        let availabilityStrings = AvailabilityUtils.getUserFriendlyStrings(availability);
        // always return at least 3 strings to fill the screenreader format label
        while (availabilityStrings.length < 3) {
            availabilityStrings.push("");
        }
        return availabilityStrings;
    }

    /**
     * Get the user friendly list of strings that represent a user's availability
     * @param availability
     */
    public static getUserFriendlyStrings(availability: IAvailabilityEntity): string[] {
        AvailabilityUtils.ensureStringsAreInitialized();

        let availabilityStrings: string[] = [];
        if (!availability) {
            availabilityStrings = [];
        } else if (availability.allDay) {
            availabilityStrings = [AvailabilityUtils.strings.get("allDay")];
        } else if (AvailabilityUtils.isUnavailable(availability)) {
            availabilityStrings = [AvailabilityUtils.strings.get("unavailable")];
        } else if (AvailabilityUtils.hasTimeSlots(availability)) {
            const olsonCode = !!(availability.timeZoneOlsonCode)
                ? availability.timeZoneOlsonCode
                : TeamSettingsStore().timeZoneOlsonCode.value;
            for (let i = 0; i < availability.timeSlots.length; i++) {
                availabilityStrings.push(AvailabilityUtils.getUserFriendlyTimeSlot(availability.timeSlots[i], olsonCode, TeamSettingsStore().timeZoneOlsonCode.value));
            }
        }
        return availabilityStrings;
    }

    /**
     * Shallow comparison of objects
     */
    public static areEqual(availability1: IAvailabilityEntity, availability2: IAvailabilityEntity): boolean {
        return JSON.stringify(availability1) === JSON.stringify(availability2);
    }

    /**
     * Get the key for the shown availabilities map.
     * IMPORTANT - Make sure the passed in has already been set to startOfDay - we don't want to clone() and call startOf() for performance reasons.
     * @param dateSetToStartOfDay
     */
    public static getShownAvailabilitiesKey(member: IMemberEntity, dateSetToStartOfDay: moment.Moment): string {
        return `${member.id}:${dateSetToStartOfDay.valueOf()}`;
    }

    private static _showAvailabilitiesKeyCache: {[key: string]: string} = {};

    public static fastGetShownAvailabilitiesKey = MemoizeUtils.memoizeUtility(
        AvailabilityUtils.getShownAvailabilitiesKey,
        AvailabilityUtils._showAvailabilitiesKeyCache,
        (args: any[]) => {
            return `${args[0].id}:${args[1].valueOf()}`;
        }
    );

    /**
     * Helper function for determining if availabilities should render for a given empty cell.
     * The availabilities for the user is a string representation of when they are available to work.
     * We display availabilities in one empty cell per member, per group, per day
     * For day view, it should only show if there is enough space
     * @param shownAvailabilitesMap
     * @param member
     * @param date
     * @param editEnabled
     * @param scheduleCalendarType
     * @param durationInHours
     */
    public static shouldShowAvailabilityForCell(shownAvailabilitesMap: IShownAvailabilitiesMap,
        member: IMemberEntity,
        date: moment.Moment,
        editEnabled: boolean,
        scheduleCalendarType: ScheduleCalendarType,
        durationInHours: Number): boolean {

        let shouldShow = false;
        if (member // is there a member for this row
            && editEnabled // is this schedule editable
            && shownAvailabilitesMap // was a proper availability map passed in
            ) {
            const key = AvailabilityUtils.fastGetShownAvailabilitiesKey(member, date);
            // if we haven't shown availabilities for member, for date, check if we should
            if (!shownAvailabilitesMap[key]) {
                if (scheduleCalendarType === ScheduleCalendarTypes.Week) {
                    shouldShow = true;
                } else if (scheduleCalendarType === ScheduleCalendarTypes.Day) {
                    // if the duration of this empty span is big enough, show availabilites
                    if (durationInHours >= MinimumHoursNeededToShowAvailabilitiesInDayView) {
                        shouldShow = true;
                    }
                }
                shownAvailabilitesMap[key] = shouldShow;
            }
        }
        return shouldShow;
    }

    /**
     * Returns IAvailability entity object by mapping the conflicting availability id.
     * @param membersAvailabilities Map of member and the availabilities
     * @param availId conflicting availability id
     * @param memberId member id
     */
    public static getAvailabilityFromId(membersAvailabilities: ObservableMap<string, IAvailabilityEntity[]>, availId: string, memberId: string): IAvailabilityEntity {
        if (!membersAvailabilities || !availId || !memberId) {
            return null;
        }
        const availabilities = membersAvailabilities.get(memberId);
        if (availabilities) {
            for (const availability of availabilities) {
                if (availability.id === availId) {
                    return availability;
                }
            }
        }
        return null;
    }

    /**
     * Gets the day of the week that the conflicting availability is coming from.
     * @param availability The conflicting availability.
     * @returns The day of the week.
     * TODO: Rename to 'getAvailabilityDayOfWeek' as there is no conflicting logic here.
     * TODO: Confirm if this method needs to support time-zones.
     */
    public static getConflictingAvailabilityDayOfWeek(availability: IAvailabilityEntity): string {
        if (!availability) {
            return "";
        }

        const { recurrencePattern, recurrenceRange } = availability;

        if (recurrenceRange) {
            return moment(recurrenceRange.startDate).format("dddd").toLowerCase();
        } else if (recurrencePattern?.recurringValues?.length > 0) {
            // there will always be only one day of the week for each availability
            return recurrencePattern.recurringValues[0];
        }

        return "";
    }
}