import { createStore } from "satcheljs";
import { ITeamInfoEntity } from "sh-models";
import { ObservableMap } from "mobx";
import { TeamInfoStoreSchema } from "./schema/TeamInfoStore";

/* Register Orchestrators */
import "sh-stores/sh-teaminfo-store/orchestrators/deleteTeam";
import "sh-stores/sh-teaminfo-store/orchestrators/updateTeams";

/**
 * Store for Teams data
 */

let TeamInfoStore = createStore<TeamInfoStoreSchema>(
    "teaminfostore",
    {
        teams: new ObservableMap<string, ITeamInfoEntity>()
    });

export default TeamInfoStore;