import { action } from "satcheljs";
import { IShiftRequestEntity } from "sh-models";

const updateShiftRequestsInStore = action(
    "UPDATE_SHIFTREQUESTS_IN_STORE",
    (
        shiftRequests: IShiftRequestEntity[]
    ) => ({
        shiftRequests: shiftRequests || []
    })
);

export default updateShiftRequestsInStore;
