import { SecurityPolicyViolationError } from "./SecurityPolicyViolationError";

/**
 * The security policy violation manager.
 */
export class SecurityPolicyViolationManager {
    /**
     * Creates an error from given event.
     * The SecurityPolicyViolationEvent interface is defined by TypeScript DOM library and is supported by all modern browsers: https://developer.mozilla.org/en-US/docs/Web/API/SecurityPolicyViolationEvent.
     * @param event The event.
     * @returns The error.
     */
    public createErrorFromEvent(event: SecurityPolicyViolationEvent): SecurityPolicyViolationError {
        const { blockedURI, statusCode, type, violatedDirective } = event;
        const message = `${type}: '${violatedDirective}' violated by '${blockedURI}' with status code: '${statusCode}'.`;

        return new SecurityPolicyViolationError(message);
    }
}