import { AppSettings } from "sh-application";
import { authentication } from "@microsoft/teams-js";
import { AxiosResponse } from "axios";
import { BEARERKEY, TOKEN_RESOURCES, WWW_AUTHENTICATE_HEADER } from "../../StaffHubConstants";
import { trace } from "owa-trace";

class AuthService {
    private _cachedToken: Map<string, string> = new Map<string, string>();

    /**
     * Return cached BEARER token for the Shifts Service
     */
    public getCachedShiftsBearerToken(): string {
        const cachedShiftsToken = this.getCachedToken(TOKEN_RESOURCES.ShiftsService);
        return cachedShiftsToken ? BEARERKEY + cachedShiftsToken : undefined;
    }

    /**
     * Return cached token
     */
    public getCachedToken(resource: string): string {
        return this._cachedToken.get(resource);
    }

    /**
     * Get the auth token for the session
     * @param {string} resource - used to fetch AAD with different resource URL.
     * @param {string} claims - optional claims to pass to AAD when requesting the token (Sent when handling a CAE authentication challenge)
     * @returns {Object} promise.
     */
    public getToken(resource: string, claims: string = ""): Promise<string> {
        const promise = new Promise<string>((resolve, reject) => {
            // For Teams mode, we request the user's auth token from the Teams app via the Teams SDK
            const authTokenRequest: authentication.AuthTokenRequest = {
                successCallback: async (token: string) => {
                    this._cachedToken.set(resource, token);
                    resolve(token);
                },
                failureCallback: (error: any) => {
                    trace.warn("Error received when getting token from Teams Client SDK: " + (error ? JSON.stringify(error) : ""));
                    reject(new Error(error));
                },
                resources: [ AppSettings.getSetting(resource)  ],
                ...(claims ? { claims: [claims] } : {})
            };
            authentication.getAuthToken(authTokenRequest);
        });
        return promise;
    }

    /**
     * Checks if the error response is due to a CAE (Continuous Auth Evaluation) challenge and returns the claims
     * these claims will be sent to the Teams SDK to get a new token to retry the request
     * @param httpError - Axios error response from resource provider
     * @returns {string} - The claims if this was a CAE claims challenge error, undefined otherwise
     */
     public getClaimsIfCAEChallengeResponse(errorResponse: AxiosResponse): string {
        if (errorResponse?.status === 401 && errorResponse.headers[WWW_AUTHENTICATE_HEADER]) {
            return this.parseClaimsFromAuthenticateHeader(errorResponse.headers[WWW_AUTHENTICATE_HEADER]);
        }
        return undefined;
    }

    /**
     * Helper method to parse the claims challenge from the response header
     * @param wwwAuthenticateHeader - e.g. Bearer realm="", authorization_uri="https://login.microsoftonline.com/common/oauth2/authorize", error="insufficient_claims", claims="eyJhY2Nlc3NfdG9rZW4iOnsiYWNycyI6eyJlc3NlbnRpYWwiOnRydWUsInZhbHVlIjoiYzEifX19"
     */
     public parseClaimsFromAuthenticateHeader(wwwAuthenticateHeader: string): string {
        const keyValMap: Map<string, string> = this.parseAuthenticateHeader(wwwAuthenticateHeader);
        return keyValMap?.get("claims");
    }

    /**
     * Helper method to parse the www-authenticate header into its components
     * @param wwwAuthenticateHeader - e.g. Bearer realm="", authorization_uri="https://login.microsoftonline.com/common/oauth2/authorize", error="insufficient_claims", claims="eyJhY2Nlc3NfdG9rZW4iOnsiYWNycyI6eyJlc3NlbnRpYWwiOnRydWUsInZhbHVlIjoiYzEifX19"
     */
     public parseAuthenticateHeader(wwwAuthenticateHeader: string): Map<string, string> {

        // remove the Bearer token and split on ','
        const components = wwwAuthenticateHeader?.replace(BEARERKEY, "").split(",");

        const keyValMap: Map<string, string> = new Map<string, string>();
        if (components?.length) {
            for (let component of components) {
                // e.g. claims="eyJhY2Nlc3NfdG9rZW4iOnsiYWNycyI6eyJlc3NlbnRpYWwiOnRydWUsInZhbHVlIjoiYzEifX19"
                const keyVals = component.split("=");
                if (keyVals?.length === 2) {
                    const key = keyVals[0] ? keyVals[0].trim() : undefined;
                    const value = keyVals[1] ? keyVals[1].replace(/^"/, "").replace(/"$/, "") : undefined;
                    if (key && value !== undefined) {
                        keyValMap.set(key, value);
                    }
                }
            }
        }

        return keyValMap;
    }
}

const service = new AuthService();
export default service as AuthService;