import { AxiosError } from "axios";
import { trace } from "owa-trace";
import { AppSettings, StaffHubError } from "sh-application";
import { HostContext } from "sh-application/hostsContainers/HostContext";
import AdminPermissionChecker from "sh-application/utility/AdminPermissionChecker";
import InstrumentationUtils from "sh-application/utility/InstrumentationUtils";
import { generateUUID, getDurationInMs, isReleaseBuild } from "sh-application/utility/utility";
import { AppSettingsStore } from "sh-appsettings-store";
import {
    EUDBTelemetryConfig,
    ICollectorConfig,
    IInstrumentationLogger,
    InstrumentationEventPropertyInterface,
    InstrumentationPerfScenario,
    PiiKind,
    getGenericEventPropertiesObject,
    logLevel
} from "sh-instrumentation";
import { OneDsLogger } from "sh-instrumentation/1ds";
import { AriaLogger } from "sh-instrumentation/aria";
import { ITeamInfoEntity, UserStorageKeys } from "sh-models";
import { ECSConfigKey, ECSConfigService, UserDataService, UserStorageService } from "sh-services";

import { UserStorageServiceScope } from "../UserStorageServiceScope";

import { InstrumentationServiceInterface } from "./InstrumentationServiceInterface";
import {
    cleanMeasureMarker,
    clearMarks,
    clearMeasures,
    getCurrentTimeStamp as getCurrentTimeStampUtil,
    getMarkerEnd,
    getMarkerStart,
    getMeasureDuration,
    getTimeStampedMarker,
    mark,
    measure
} from "./InstrumentationServiceUtils";
import { Instrumentation } from "./lib";

/**
 * Instrumentation Service.
 */
export class InstrumentationService implements InstrumentationServiceInterface {
    private instrumentationSettings: InstrumentationServiceSettings;
    private ariaLoggingEnabled: boolean;
    private oneDsLoggingEnabled: boolean;
    private clientSessionId: string;
    private outputPerfDataToConsole: boolean;

    private activeLoggers: IInstrumentationLogger[] = [];

    /**
     * We call bootstrap multiple times. For initial load of the page and team switching.
     * This tracks how many times the app has been bootstrap and how long it took for each bootstrap.
     */
    private static bootstrapIndex: number = -1;
    private static bootstrapStartTime: Array<number> = [];
    private static bootstrapEndTime: Array<number> = [];

    /**
     * We progressive render multiple times.
     * This tracks how many times the app has run progressive render and how long it took for each.
     */
    private static progressiveRenderIndex: number = -1;
    private static progressiveRenderStartTime: Array<number> = new Array<number>(2);
    private static progressiveRenderEndTime: Array<number> = new Array<number>(2);

    /**
     * Default constructor
     */
    constructor(instrumentationSettings: InstrumentationServiceSettings) {
        this.instrumentationSettings = instrumentationSettings;
        this.ariaLoggingEnabled = this.instrumentationSettings.Aria.Enabled && !!this.instrumentationSettings.Aria.InstrumentationKeyUserBiTenant;
        this.oneDsLoggingEnabled = this.instrumentationSettings.OneDs.Enabled && !!this.instrumentationSettings.OneDs.InstrumentationKeyUserBiTenant;

        // generate a UUID for this session (a new session ID is generated per page load)
        this.clientSessionId = generateUUID();
    }

    /**
     * Initialize the instrumentation service
     * @param appVersion Shifts app version
     * @param hostContext Host app context
     * @param ecsConfig ECS config object that is passed from the AppService to the client. It can often be undefined and doesn't include the default ECS Configs in ecsConfig.Defaults.json.
     */
    public async initialize(appVersion: string, hostContext: HostContext, ecsConfig: any): Promise<void> {
        const tenantId = hostContext?.tenant?.id;

        this.perfMarkerStart(Instrumentation.perfScenarios.InitializeInstrumentationService);

        // Initialize Aria SDK for telemetry and performance metrics
        if (this.ariaLoggingEnabled) {
            const ariaEudbTelemetryConfig = this.loadEudbTelemetryConfigFromEcs(ecsConfig);
            const ariaLogger = new AriaLogger();
            await ariaLogger.init({
                ariaWebAppKey: this.instrumentationSettings.Aria.InstrumentationKeyUserBiTenant,
                ariaPerfWebAppKey: this.instrumentationSettings.Aria.InstrumentationKeyPerfTenant,
                perfLoggingEnabled: this.instrumentationSettings.Aria.PerfLoggingEnabled,
                globalPerfLogLevel: this.instrumentationSettings.Aria.PerfLoggingLevel,
                eudbTelemetryConfig: ariaEudbTelemetryConfig,
                collectorConfigs: this.instrumentationSettings.Aria.CollectorConfigs,
                tenantId
            });

            this.activeLoggers.push(ariaLogger);
        }

        // Initialize OneDS SDK for telemetry and performance metrics
        if (this.oneDsLoggingEnabled) {
            const oneDsEudbTelemetryConfig = this.loadEudbTelemetryConfigFromEcs(ecsConfig);
            const oneDsLogger = new OneDsLogger();
            await oneDsLogger.init({
                oneDsUserBiInstrumentationKey: this.instrumentationSettings.OneDs.InstrumentationKeyUserBiTenant,
                oneDsPerfInstrumentationKey: this.instrumentationSettings.OneDs.InstrumentationKeyPerfTenant,
                perfLoggingEnabled: this.instrumentationSettings.OneDs.PerfLoggingEnabled,
                globalPerfLogLevel: this.instrumentationSettings.OneDs.PerfLoggingLevel,
                eudbTelemetryConfig: oneDsEudbTelemetryConfig,
                collectorConfigs: this.instrumentationSettings.OneDs.CollectorConfigs,
                tenantId
            });

            this.activeLoggers.push(oneDsLogger);
        }

        this.initializeInstallId();
        this.setAppVersion(appVersion);
        this.setCustomDimensionsFromHostContext(hostContext);

        // Start logging session. this will send SessionID as a parameter with every event that gets logged into Aria in this session.
        // Will be useful to debug from Kusto about all user actions in that session
        this.startLogSession();

        // Log Page load time
        window.addEventListener("load", () => {
            // Set Screen resolution as custom dimension after page is loaded
            this.setScreenSizeInCustomDimensions();

            // log page event as async to ensure loading time is taken into consideration while calculating perceived duration
            setTimeout(function onPageLoaded$timeout() {
                // Track the load page event
                this.logPageLoadEvent();
            }, 0);
        });

        // Window resize handler
        window.addEventListener("resize", () => {
            // update screen context
            this.setScreenSizeInCustomDimensions();
        });

        this.perfMarkerEnd(Instrumentation.perfScenarios.InitializeInstrumentationService);
    }

    /**
     * Log Perf metric tracking PageLoad Event.
     */
    private logPageLoadEvent(): void {
        const eventDataArray = [];
        // window.performance.timing object provides basic network transistion related timing information
        // More info at https://www.w3.org/TR/navigation-timing/#processing-model
        const timing: PerformanceTiming = window.performance && window.performance.timing ? window.performance.timing : {} as PerformanceTiming;

        // Perceived duration
        const perceivedDuration = timing.loadEventEnd && timing.navigationStart ? timing.loadEventEnd - timing.navigationStart : 0;
        eventDataArray.push(getGenericEventPropertiesObject(Instrumentation.properties.Duration, perceivedDuration.toString()));

        // Log network Latency
        const networkLatency = (timing.responseEnd && timing.fetchStart) ? (timing.responseEnd - timing.fetchStart) : 0;
        eventDataArray.push(getGenericEventPropertiesObject(Instrumentation.properties.NetworkLatency, networkLatency.toString()));

        // Log client load time
        const clientSideLoadTime = (timing.loadEventEnd && timing.responseEnd) ? (timing.loadEventEnd - timing.responseEnd) : 0;
        eventDataArray.push(getGenericEventPropertiesObject(Instrumentation.properties.ClientSideLoad, clientSideLoadTime.toString()));

        // Log Page URL
        eventDataArray.push(getGenericEventPropertiesObject(Instrumentation.properties.PageURL, InstrumentationUtils.NormalizeStaffHubWebsiteUrl(window.location.href)));

        this.logPerfEvent(Instrumentation.events.PageLoadTime, eventDataArray);
    }

    /**
     * Custom Dimensions used to log Screen data
     */
    public screenCustomDimensions = {
        ScreenWidth: "ScreenWidth",
        ScreenHeight: "ScreenHeight",
        WindowWidth: "WindowWidth",
        WindowHeight: "WindowHeight"
    };

    /**
     * Set Screen resolution and window resolution as custom dimensions.
     * Note: This needs to be called after the page is loaded/resized to get the actual screen and window resolution.
     */
    public setScreenSizeInCustomDimensions(): void {
        // Log screen width and height from window.screen object
        this.setCustomDimension(getGenericEventPropertiesObject(this.screenCustomDimensions.ScreenWidth, screen ? screen.width.toString() : ""), false /* isGlobal */);
        this.setCustomDimension(getGenericEventPropertiesObject(this.screenCustomDimensions.ScreenHeight, screen ? screen.height.toString() : ""), false /* isGlobal */);

        // Calculate window width and height excluding toolbars and scroll bars to get actual view port
        const windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
        this.setCustomDimension(getGenericEventPropertiesObject(this.screenCustomDimensions.WindowWidth, windowWidth.toString()), false /* isGlobal */);
        this.setCustomDimension(getGenericEventPropertiesObject(this.screenCustomDimensions.WindowHeight, windowHeight.toString()), false /* isGlobal */);
    }

    /**
     * Telemetry config for EUDB.
     * @param ecsConfig ECS config object that is passed from the AppService to the client. It can often be undefined and doesn't include the default ECS Configs in ecsConfig.Defaults.json.
     * @param collectorConfigs The telemetry collector URLs for each region.
     * @returns The EUDBTelemetryConfig.
     */
    public loadEudbTelemetryConfigFromEcs(ecsConfig: any): EUDBTelemetryConfig {
        const useCompliantClient =
            ecsConfig?.[ECSConfigKey.EnableEUDBCompliantTelemetryClient] ??
            ECSConfigService.getBaseECSConfig()?.[ECSConfigKey.EnableEUDBCompliantTelemetryClient] ??
            true;
        const currentTelemetryRegionCode = ecsConfig?.[ECSConfigKey.CurrentTelemetryRegionCode];

        return {
            useCompliantClient,
            currentTelemetryRegionCode
        };
    }

    /**
     * Called by ECS when it has been updated
     */
    public onECSUpdated(): void {
        this.outputPerfDataToConsole = ECSConfigService.isECSFeatureEnabled(
            ECSConfigKey.EnableConsoleLogPerfData
        );
    }

    /**
     * Returns the current session ID
     */
    public getClientSessionId(): string {
        return this.clientSessionId;
    }

    /**
     * Get a monotonic (if supported) timestamp value in milliseconds
     */
    public getCurrentTimeStamp(): number {
        return getCurrentTimeStampUtil();
    }

    /**
     * Starts the bootstrap timer
     */
    public setBootstrapStarted(): void {
        InstrumentationService.bootstrapIndex++;
        if (
            InstrumentationService.bootstrapStartTime.length ===
            InstrumentationService.bootstrapIndex
        ) {
            InstrumentationService.bootstrapStartTime.push(this.getCurrentTimeStamp());
        }
    }

    /**
     * Stops the bootstrap timer
     */
    public setBootstrapEnded(): void {
        if (
            InstrumentationService.bootstrapEndTime.length === InstrumentationService.bootstrapIndex
        ) {
            InstrumentationService.bootstrapEndTime.push(this.getCurrentTimeStamp());
        }
    }

    /**
     * Starts the ProgressiveRender timer
     */
    public setProgressiveRenderStarted(): void {
        InstrumentationService.progressiveRenderIndex++;

        if (InstrumentationService.progressiveRenderIndex === 0) {
            // only need to do this once
            this.setCustomDimension(
                getGenericEventPropertiesObject(
                    Instrumentation.customDimensionProperties.IsProgressiveRendering,
                    true
                )
            );
        }

        InstrumentationService.progressiveRenderStartTime[
            InstrumentationService.progressiveRenderIndex % 2
        ] = this.getCurrentTimeStamp();
    }

    /**
     * Stops the ProgressiveRender timer
     */
    public setProgressiveRenderEnded(): void {
        InstrumentationService.progressiveRenderEndTime[
            InstrumentationService.progressiveRenderIndex % 2
        ] = this.getCurrentTimeStamp();
    }

    /**
     * Gets the duration of the last bootstrap
     * Returns -1 if the previous bootstrap hasn't completed
     */
    public getLastBootstrapDuration(): number {
        if (
            InstrumentationService.bootstrapIndex >= 0 &&
            InstrumentationService.bootstrapStartTime.length >
                InstrumentationService.bootstrapIndex &&
            InstrumentationService.bootstrapEndTime.length > InstrumentationService.bootstrapIndex
        ) {
            return (
                InstrumentationService.bootstrapEndTime[InstrumentationService.bootstrapIndex] -
                InstrumentationService.bootstrapStartTime[InstrumentationService.bootstrapIndex]
            );
        }
        return -1;
    }

    /**
     * Gets the duration of the last progressive render
     * Returns -1 if the previous render hasn't completed
     */
    public getLastProgressiveRenderDuration(): number {
        if (InstrumentationService.progressiveRenderIndex >= 0) {
            return (
                InstrumentationService.progressiveRenderEndTime[
                    InstrumentationService.progressiveRenderIndex % 2
                ] -
                InstrumentationService.progressiveRenderStartTime[
                    InstrumentationService.progressiveRenderIndex % 2
                ]
            );
        }
        return -1;
    }

    /**
     * Returns the installation ID (generates one if necessary).
     * All tabs share the same persisted installation ID.
     */
    public getInstallId(): string {
        let installId = UserStorageService.getItem(
            UserStorageKeys.InstallId,
            { scope: UserStorageServiceScope.Shared }
        );

        if (!installId) {
            installId = generateUUID();
            UserStorageService.setItem(
                UserStorageKeys.InstallId,
                installId,
                { scope: UserStorageServiceScope.Shared }
            );
        }

        return installId;
    }

    /**
     * Setup the installation ID as a custom property
     */
    private initializeInstallId(): void {
        // setup the installId
        this.setCustomDimension(
            getGenericEventPropertiesObject(
                Instrumentation.customDimensionProperties.InstallId,
                this.getInstallId()
            )
        );
    }

    /**
     * Set the app version.
     * @param appVersion The app version.
     */
    public setAppVersion(appVersion: string): void {
        this.activeLoggers.forEach(logger => logger.setAppVersion(appVersion));
    }

    /**
     * Set custom dimensions from the host context.
     * @param context The host context.
     */
    private setCustomDimensionsFromHostContext(context: HostContext): void {
        this.setCustomDimension(
            getGenericEventPropertiesObject(
                Instrumentation.customDimensionProperties.AppInfo_SessionId,
                context.host.sessionId
            )
        );

        this.setCustomDimension(
            getGenericEventPropertiesObject(
                Instrumentation.customDimensionProperties.InstrumentationSource,
                Instrumentation.values.FLW
            )
        );

        this.setCustomDimension(
            getGenericEventPropertiesObject(
                Instrumentation.customDimensionProperties.AppInfo_BuildType,
                window.sa.buildEnvironment ?? Instrumentation.values.Unknown
            )
        );

        this.setCustomDimension(
            getGenericEventPropertiesObject(
                Instrumentation.customDimensionProperties.AppInfo_Environment,
                isReleaseBuild()
                    ? Instrumentation.values.ProductionEnvironment
                    : Instrumentation.values.DevEnvironment
            )
        );

        if (context.host.clientType) {
            this.setCustomDimension(
                getGenericEventPropertiesObject(
                    Instrumentation.customDimensionProperties.AppInfo_ClientType,
                    context.host.clientType
                )
            );
        }

        this.setCustomDimension(
            getGenericEventPropertiesObject(
                Instrumentation.customDimensionProperties.AppInfo_HostName,
                context.host.name
            )
        );

        this.setCustomDimension(
            getGenericEventPropertiesObject(
                Instrumentation.customDimensionProperties.RingId,
                context.host.ringId
            )
        );

        this.setUserContext(context.user.id, context.tenant.id);
    }

    /**
     * Set ECS Context to all instrumented events. Will be used by Experimentation platform
     * @param ecsProjectName - current ECS Project Name
     * @param ecsConfig - ECS Config
     */
    public setECSContext(ecsProjectName: string, ecsConfig: { [s: string]: any }) {
        // get User ETag from ECS Config
        const userETag: string = ecsConfig?.Headers?.ETag || "";
        this.setCustomDimension(
            getGenericEventPropertiesObject(
                Instrumentation.customDimensionProperties.UserInfo_Etag,
                userETag
            )
        );

        // get configIds from ECS Config
        const configIds: string = (ecsProjectName && ecsConfig?.ConfigIDs?.[ecsProjectName]) || "";

        // separate Experiment Ids with Rollout Ids
        const expIds: string[] = [];
        const rollOutIds: string[] = [];
        const allConfigIds: string[] = configIds.toString().split(",");
        allConfigIds.forEach((id: string) => {
            if (id.startsWith("P-E")) {
                expIds.push(id);
            } else {
                rollOutIds.push(id);
            }
        });
        this.setCustomDimension(
            getGenericEventPropertiesObject(
                Instrumentation.customDimensionProperties.AppInfo_ExpIds,
                expIds.join()
            )
        );
        this.setCustomDimension(
            getGenericEventPropertiesObject(
                Instrumentation.customDimensionProperties.UserInfo_RolloutIds,
                rollOutIds.join()
            )
        );
    }

    /**
     * log event with specified options (attributes)
     * @param {String} eventName
     * @param {Array} eventDataArray - Array of eventPropertyObjects (each object has key, value, piiKind)
     * @param { [name: string]: number } measurements map[string, number] - metrics associated with this event, displayed in Metrics Explorer on the portal. Defaults to empty.
     * @returns {}
     */
    public logEvent(
        eventName: string,
        eventDataArray?: InstrumentationEventPropertyInterface[],
        measurements?: { [name: string]: number }
    ): void {
        this.activeLoggers.forEach(logger => logger.logEvent(eventName, eventDataArray, measurements));
    }

    /**
     * log a performance event with specified options (attributes)
     * @param {String} eventName
     * @param {Array} eventDataArray - Array of eventPropertyObjects (each object has key, value, piiKind)
     * @param { [name: string]: number } measurements map[string, number] - metrics associated with this event, displayed in Metrics Explorer on the portal. Defaults to empty.
     * @returns {}
     */
    public logPerfEvent(
        eventName: string,
        eventDataArray?: InstrumentationEventPropertyInterface[],
        measurements?: { [name: string]: number }
    ): void {
        // Log to perf tenant
        this.activeLoggers.forEach(logger => logger.logPerfEvent(eventName, eventDataArray, measurements));
    }

    /**
     * Logs a page view for given page name.
     * @param pageName The page name.
     * @param eventData The list of eventPropertyObjects (each object has key, value, piiKind).
     * @param measurements The metrics associated with this event, displayed in Metrics Explorer on the portal. Defaults to empty.
     * @param duration The number of milliseconds it took to load the page. Defaults to undefined. If set to default value, page load time is calculated internally.
     */
    public logPageView(
        pageName: string,
        eventData: InstrumentationEventPropertyInterface[] = [],
        // TODO(Performance): Remove unused 'measurements' and 'duration' parameters.
        measurements?: { [name: string]: number },
        duration?: number
    ): void {
        const normalizedCurrentPageUri = InstrumentationUtils.NormalizeStaffHubWebsiteUrl(
            window.location.href
        );

        // Log page views as screen event and pass in the page name as Name property in the event data
        this.activeLoggers.forEach(logger => logger.logPageView(pageName, normalizedCurrentPageUri, eventData));

        eventData.push(
            getGenericEventPropertiesObject(Instrumentation.properties.ScreenName, pageName)
        );
        this.activeLoggers.forEach(logger => logger.logEvent(Instrumentation.events.ScreenEvent, eventData));
    }

    /**
     * Log the time taken for bootstrap to complete.
     * @param bootstrapType: The type of data being bootstrapped
     */
    public async logDataBootstrapComplete(bootstrapType: string) {
        // Set the bootstrap as ended
        this.setBootstrapEnded();
        const bootstrapCount: number = InstrumentationService.bootstrapIndex + 1;

        // Bootstrap duration
        const measurements: { [name: string]: number } = {
            [Instrumentation.properties.Duration]: this.getLastBootstrapDuration()
        };
        if (window.performance?.now) {
            // Total duration since navigation began
            measurements[Instrumentation.properties.DurationSincePageLoadStart] =
                this.getCurrentTimeStamp();
        }

        const normalizedCurrentPageUri = InstrumentationUtils.NormalizeStaffHubWebsiteUrl(
            window.location.href
        );
        const isWarmBootup: boolean = await UserDataService.getIsWarmBootup();
        const eventDataArray: Array<InstrumentationEventPropertyInterface> = [];
        eventDataArray.push({
            key: Instrumentation.properties.BootstrapType,
            value: bootstrapType,
            piiKind: PiiKind.NotSet
        });
        eventDataArray.push({
            key: Instrumentation.properties.BootstrapCount,
            value: bootstrapCount,
            piiKind: PiiKind.NotSet
        });
        eventDataArray.push({
            key: Instrumentation.properties.PageURL,
            value: normalizedCurrentPageUri,
            piiKind: PiiKind.NotSet
        });
        eventDataArray.push({
            key: Instrumentation.properties.IsWarmBootup,
            value: isWarmBootup,
            piiKind: PiiKind.NotSet
        });

        this.logPerfEvent(Instrumentation.events.DataBootstrapped, eventDataArray, measurements);
    }

    /**
     * Log the time taken for progressive render to complete.
     * @param didComplete: Pass true if the progressive render was completed (not interrupted)
     */
    public async logDataProgressiveRenderEnded(didComplete: boolean) {
        // Set the bootstrap as ended
        this.setProgressiveRenderEnded();
        const progressiveRenderCount: number = InstrumentationService.progressiveRenderIndex + 1;

        // progressiveRender duration
        let measurements: { [name: string]: number } = {
            [Instrumentation.properties.Duration]: this.getLastProgressiveRenderDuration()
        };
        if (window.performance?.now) {
            // Total duration since navigation began
            measurements[Instrumentation.properties.DurationSincePageLoadStart] =
                this.getCurrentTimeStamp();
        }

        const isWarmBootup: boolean = await UserDataService.getIsWarmBootup();
        let eventDataArray: Array<InstrumentationEventPropertyInterface> = [];
        eventDataArray.push({
            key: Instrumentation.properties.IsWarmBootup,
            value: isWarmBootup,
            piiKind: PiiKind.NotSet
        });
        eventDataArray.push({
            key: Instrumentation.properties.ProgressiveRenderCount,
            value: progressiveRenderCount,
            piiKind: PiiKind.NotSet
        });
        eventDataArray.push({
            key: Instrumentation.properties.DidProgressiveRenderComplete,
            value: didComplete,
            piiKind: PiiKind.NotSet
        });

        this.logPerfEvent(
            Instrumentation.events.ProgressiveRenderComplete,
            eventDataArray,
            measurements
        );
    }

    /**
     * Log a perf event for when the app is ready to be used
     * @param {String} pageName
     * @param {Array} eventDataArray - Array of eventPropertyObjects (each object has key, value, piiKind)
     */
    public async logAppInteractive(
        pageName: string,
        eventDataArray: Array<InstrumentationEventPropertyInterface>
    ) {
        // End the perf marker for full page render but do not log this at the network level, as this function will do so itself
        this.perfMarkerEnd(
            Instrumentation.perfScenarios.TimeToFirstFullRender,
            null,
            false /*logAtNetworkLevel*/
        );

        const measurements: { [name: string]: number } = {};
        if (window.performance && window.performance.now && window.performance.timing) {
            // window.performance.timing object provides basic network transistion related timing information
            // More info at https://www.w3.org/TR/navigation-timing/#processing-model
            const timing: PerformanceTiming = window.performance.timing;

            // Log the time for the app to be interactive
            measurements[Instrumentation.properties.TimeToInteractive] = this.getCurrentTimeStamp();

            // Log the first bootstrap duration (Time for application to load)
            if (InstrumentationService.bootstrapEndTime.length > 0) {
                measurements[Instrumentation.properties.TimeToBootstrap] =
                    InstrumentationService.bootstrapEndTime[0];
            }

            // Page load duration (Time to First Paint)
            const pageLoadDuration =
                timing.loadEventEnd && timing.navigationStart
                    ? timing.loadEventEnd - timing.navigationStart
                    : -1;
            if (pageLoadDuration >= 0) {
                measurements[Instrumentation.properties.TimeToLoadPage] = pageLoadDuration;
            }

            // Log network Latency
            const networkLatency =
                timing.responseEnd && timing.fetchStart
                    ? timing.responseEnd - timing.fetchStart
                    : -1;
            if (networkLatency >= 0) {
                measurements[Instrumentation.properties.NetworkLatency] = networkLatency;
            }

            // Log client load time
            const clientSideLoadTime =
                timing.loadEventEnd && timing.responseEnd
                    ? timing.loadEventEnd - timing.responseEnd
                    : -1;
            if (clientSideLoadTime >= 0) {
                measurements[Instrumentation.properties.ClientSideLoad] = clientSideLoadTime;
            }
        }

        const isWarmBootup: boolean = await UserDataService.getIsWarmBootup();
        const normalizedCurrentPageUri = InstrumentationUtils.NormalizeStaffHubWebsiteUrl(
            window.location.href
        );
        eventDataArray.push({
            key: Instrumentation.properties.PageURL,
            value: normalizedCurrentPageUri,
            piiKind: PiiKind.NotSet
        });
        eventDataArray.push({
            key: Instrumentation.properties.PageName,
            value: pageName,
            piiKind: PiiKind.NotSet
        });
        eventDataArray.push({
            key: Instrumentation.properties.IsWarmBootup,
            value: isWarmBootup,
            piiKind: PiiKind.NotSet
        });
        this.logPerfEvent(Instrumentation.events.AppStartup, eventDataArray, measurements);

        if (!isWarmBootup) {
            // Set this to be true for the next page load
            await UserDataService.setIsWarmBootup(true);
        }
    }

    /**
     * Logs an exception.
     * @param exception The Error to log.
     * @param exceptionCategory The category of the exception.
     * @param handledAt The identifier on where the exception was handled.
     * @param eventDataArray The additional event data properties to set while logging.
     */
    public trackException(
        exception: Error,
        exceptionCategory: string,
        handledAt?: string,
        eventDataArray?: InstrumentationEventPropertyInterface[]
    ): void {
        const eventData = eventDataArray ?? [];

        eventData.push(getGenericEventPropertiesObject(
            this.properties.StaffHubExceptionCategory,
            exceptionCategory,
            PiiKind.NotSet
        ));

        if (ECSConfigService.isFeatureEnabled(ECSConfigKey.NewExceptionsPropertiesLoggingEnabled)) {
            // TODO: Remove all defensive code 'exception?.' by ensuring 'exception' is always an Error from consumers.
            eventData.push(getGenericEventPropertiesObject(
                this.properties.ErrorType,
                exception?.name ?? "",
                PiiKind.NotSet
            ));

            eventData.push(getGenericEventPropertiesObject(
                this.properties.ErrorMessage,
                exception?.message ?? "",
                PiiKind.NotSet
            ));

            eventData.push(getGenericEventPropertiesObject(
                this.properties.ErrorStacktrace,
                exception?.stack ?? "",
                PiiKind.NotSet
            ));

            eventData.push(getGenericEventPropertiesObject(
                this.properties.ErrorHandledAt,
                handledAt ?? "",
                PiiKind.NotSet
            ));
        }

        this.activeLoggers.forEach(logger => logger.logPerfEvent(Instrumentation.events.Exceptions, eventData));
    }

    /**
     * Logs the Http error
     * @param {AxiosError} httpError
     * @param {string} exceptionCategory - Category of the exception
     * @param {string} urlSignature - signature of the url
     * @returns {}
     */
    public logHttpError = (
        httpError: AxiosError<any, any>,
        exceptionCategory: string,
        urlSignature: string
    ) => {
        if (httpError) {
            // make sure the httpUrl that gets sent as an extra payload uses the PII scrubbed version

            let exceptionProperties: Array<InstrumentationEventPropertyInterface> = [];
            exceptionProperties.push({
                key: "HttpUrlName",
                value: urlSignature,
                piiKind: PiiKind.NotSet
            });

            let httpErrorConfig = httpError.config;
            if (httpErrorConfig) {
                exceptionProperties.push({
                    key: "HttpMethod",
                    value: httpErrorConfig.method,
                    piiKind: PiiKind.NotSet
                });
                exceptionProperties.push({
                    key: "HttpUrl",
                    value: InstrumentationUtils.NormalizeUrl(httpErrorConfig.url),
                    piiKind: PiiKind.NotSet
                });
            }

            if (httpError.response) {
                exceptionProperties.push({
                    key: "HttpStatus",
                    value: httpError.response.status,
                    piiKind: PiiKind.NotSet
                });
                exceptionProperties.push({
                    key: "HttpStatusText",
                    value: httpError.response.statusText,
                    piiKind: PiiKind.NotSet
                });

                if (httpError.response.data && httpError.response.data.error) {
                    const staffHubError = httpError.response.data.error as StaffHubError;
                    if (staffHubError) {
                        exceptionProperties.push({
                            key: "StaffHubErrorCode",
                            value: staffHubError.code,
                            piiKind: PiiKind.NotSet
                        });
                        exceptionProperties.push({
                            key: "StaffHubErrorMessage",
                            value: staffHubError.message,
                            piiKind: PiiKind.NotSet
                        });
                        if (staffHubError.innererror) {
                            exceptionProperties.push({
                                key: "StaffHubInnerErrorCode",
                                value: staffHubError.innererror.code,
                                piiKind: PiiKind.NotSet
                            });
                            exceptionProperties.push({
                                key: "StaffHubInnerErrorMessage",
                                value: staffHubError.innererror.message,
                                piiKind: PiiKind.NotSet
                            });
                        }
                    }
                }
            }
            this.trackException(
                httpError,
                exceptionCategory /* Exception category */,
                "HttpErrorHandler" /* handled at */,
                exceptionProperties
            );
        }
    };

    /**
     * Set custom dimension for instrumentation events.
     * Note:  Calling this will just update the custom dimension state that will be passed along with subsequent instrumentation events.
     * This will not trigger its own instrumentation network request, so it should be ok to call this multiple times.
     * @param eventData EventData has key, value, piiKind for custom dimension.
     * @param isGlobal Indicate if the custom dimension should be logged as top level column or within databag.
     */
    public setCustomDimension(
        eventData: InstrumentationEventPropertyInterface,
        isGlobal: boolean = true
    ): void {
        // set custom dimension to the active loggers
        this.activeLoggers.forEach(logger => logger.setCustomDimension(eventData, isGlobal));
    }

    /**
     * Clear the custom dimension for instrumentation events.
     * Note:  Calling this will just update the custom dimension state that will be passed along with subsequent instrumentation events.
     * This will not trigger its own instrumentation network request, so it should be ok to call this multiple times.
     * @param key the custom dimension key.
     */
    public clearCustomDimension(key: string): void {
        // clear custom dimension to the active loggers
        this.activeLoggers.forEach(logger => logger.clearCustomDimension(key));
    }

    /**
     * Start PerfScenario logging session.
     * A Perf Scenario session is associated with scenario log level and start of session.
     * While logging and perf event, it needs to be associated with a session, so that those events will contain duration from session start as a measure when they get logged.
     * @param scenarioLogLevel The scenario log level.
     * @returns The perf sessionId.
     */
    public startPerfScenarioLogSession(scenarioLogLevel: logLevel): string {
        const sessionId = InstrumentationPerfScenario.generateNewPerfScenarioId();
        InstrumentationPerfScenario.setPerfScenarioData(
            sessionId,
            scenarioLogLevel,
            this.getCurrentTimeStamp()
        );
        return sessionId;
    }

    /**
     * End PerfScenario logging session.
     * Ending a perf scenario session will remove all data associated with the session from memory.
     * @param sessionId The perf sessionId.
     */
    public endPerfScenarioLogSession(sessionId: string): void {
        InstrumentationPerfScenario.removePerfScenarioData(sessionId);
    }

    /**
     * Marks the provided marker using the User Timing API. Used together with perfMarkerEnd to measure the times between events in the application.
     * Mark them in the User Timings timeline, and send the results to the logging endpoing in the network.
     * This function should be called first, and the string returned by the marker should be used by perfMarkerEnd, unless appendTimestamp is set to false.
     * @param marker The perf marker.
     * @param appendTimestamp If true, appends a timestamp to the marker (defaults to true).
     * @returns The adjusted marker.
     */
    public perfMarkerStart(marker: string, appendTimestamp: boolean = true): string {
        let adjustedMaker = marker;
        if (appendTimestamp) {
            adjustedMaker = getTimeStampedMarker(marker);
        }
        mark(getMarkerStart(adjustedMaker));
        return adjustedMaker;
    }

    /**
     * Marks the provided marker using the User Timing API. Used together with perfMarkerStart to measure the times between events
     * in the application, mark them in the User Timings timeline, and send the results to the logging endpoing in the network. This
     * function should be called once the given scenario has finished.
     * @param marker The perf marker.
     * @param eventDataArray Array of additional data to log to the network endpoint (optional).
     * @param logAtNetworkLevel If specified as false, the marker will still be added to the User Timings timeline but not to the network endpoint (defaults to true).
     */
    public perfMarkerEnd(
        marker: string,
        eventDataArray?: InstrumentationEventPropertyInterface[],
        logAtNetworkLevel: boolean = true
    ): void {
        const cleanedMeasureMarker = cleanMeasureMarker(marker);
        mark(getMarkerEnd(marker));
        measure(
            cleanedMeasureMarker,
            getMarkerStart(marker),
            getMarkerEnd(marker),
            this.outputPerfDataToConsole
        );

        if (logAtNetworkLevel) {
            // Get duration of measure and log as perf event at the network level
            const duration: number = getMeasureDuration(cleanedMeasureMarker);
            if (duration > -1) {
                const measurements: { [name: string]: number } = {
                    [Instrumentation.properties.Duration]: duration
                };
                this.logPerfEvent(cleanedMeasureMarker, eventDataArray, measurements);
            } else {
                trace.warn(
                    `Warning: could not find a duration for mark ${cleanedMeasureMarker}. No logging completed.`
                );
            }
        }

        // Clean up marks and measures
        clearMarks(getMarkerStart(marker));
        clearMarks(getMarkerEnd(marker));
        clearMeasures(cleanedMeasureMarker);
    }

    /**
     * Log Perf Event. Events will be logged within a perf session with given id.
     * Every logged perf event needs to be associated with a perf session.
     * This method adds a property DurationFromSessionStart which indicates the time taken from session start to current time.
     * @param sessionId The perf sessionId.
     * @param eventName The event name.
     * @param eventDataArray Array of additional data to log.
     */
    public logPerfScenarioEvent(
        sessionId: string,
        eventName: string,
        eventDataArray: Array<InstrumentationEventPropertyInterface>
    ): void {

        const currentTimeStamp = this.getCurrentTimeStamp();
        const scenarioSessionStartTimeStamp =
            InstrumentationPerfScenario.getPerfScenarioSessionStartTimeStamp(sessionId);
        // Debug.assertParamStrict("scenarioSessionStartDate", scenarioSessionStartDate, Date);
        const scenarioLoggingLevel = InstrumentationPerfScenario.getPerfScenarioLoggingLevel(sessionId);

        // Add Duration from session start to eventData array
        const durationFromSessionStart = getDurationInMs(
            scenarioSessionStartTimeStamp,
            currentTimeStamp
        );
        const durationEvent = getGenericEventPropertiesObject(
            Instrumentation.properties.DurationFromSessionStartMS,
            durationFromSessionStart.toString()
        );
        eventDataArray = eventDataArray || [];
        eventDataArray.push(durationEvent);

        // log the event
        this.activeLoggers.forEach(logger => logger.logPerfEvent(eventName,
            eventDataArray,
            undefined /* measurements */,
            scenarioLoggingLevel));
    }

    /**
     * Set custom dimension to track the current team.
     * @param currentTeam The current team info.
     * @param currentTeamSize The current team size.
     */
    public setCustomDimensionForCurrentTeam(currentTeam: ITeamInfoEntity, currentTeamSize: number): void {
        if (currentTeam) {
            this.setCustomDimension(
                getGenericEventPropertiesObject(
                    Instrumentation.customDimensionProperties.TeamId,
                    currentTeam.id
                )
            );
            this.setCustomDimension(
                getGenericEventPropertiesObject(
                    Instrumentation.customDimensionProperties.TeamGroupId,
                    currentTeam.groupId
                )
            );
            this.setCustomDimension(
                getGenericEventPropertiesObject(
                    Instrumentation.customDimensionProperties.IsManager,
                    !!AdminPermissionChecker.isCurrentUserAdmin()
                )
            );
            if (currentTeamSize) {
                this.setCustomDimension(
                    getGenericEventPropertiesObject(
                        Instrumentation.customDimensionProperties.TeamSize,
                        currentTeamSize
                    )
                );
            }
            if (AppSettingsStore()?.teamSettings?.flightSettings?.instrumentationKey) {
                this.setCustomDimension(
                    getGenericEventPropertiesObject(
                        Instrumentation.customDimensionProperties.FlightSignature,
                        AppSettingsStore().teamSettings.flightSettings.instrumentationKey
                    ),
                    false /* isGlobal */
                );
            }
            // Log WorkforceIntegrationEnabled if teamInfo has any integrations
            this.setCustomDimension(
                getGenericEventPropertiesObject(
                    Instrumentation.customDimensionProperties.WorkforceIntegrationEnabled,
                    currentTeam.workforceIntegrationIds &&
                        currentTeam.workforceIntegrationIds.length > 0
                )
            );
        }
    }

    /**
     * Clears any custom dimensions tracking the current team.
     */
    public clearCustomDimensionsForCurrentTeam(): void {
        this.clearCustomDimension(Instrumentation.customDimensionProperties.TeamId);
        this.clearCustomDimension(Instrumentation.customDimensionProperties.TeamGroupId);
        this.clearCustomDimension(Instrumentation.customDimensionProperties.IsExternalTeam);
        this.clearCustomDimension(Instrumentation.customDimensionProperties.IsManager);
        this.clearCustomDimension(Instrumentation.customDimensionProperties.TeamSize);
        this.clearCustomDimension(Instrumentation.customDimensionProperties.FlightSignature);
        this.clearCustomDimension(
            Instrumentation.customDimensionProperties.WorkforceIntegrationEnabled
        );
    }

    /**
     * Set UserId & TenantId as Custom dimensions so that all events performed by user could be grouped together.
     * @param userId User ID.
     * @param tenantId Tenant ID.
     */
    public setUserContext(userId: string, tenantId: string): void {
        if (userId && tenantId) {
            this.setCustomDimension(
                getGenericEventPropertiesObject(
                    Instrumentation.customDimensionProperties.UserLocale,
                    window.sa.currentUICulture
                ),
                false /* isGlobal */
            );
            this.setCustomDimension(
                getGenericEventPropertiesObject(
                    Instrumentation.customDimensionProperties.ClientSessionId,
                    this.clientSessionId
                )
            );

            this.activeLoggers.forEach(logger => logger.setUserContext(userId, tenantId));
        }
    }

    /**
     * Clear the user context so that subsequent events will not be grouped with user.
     */
    public clearUserContext(): void {
        this.clearCustomDimension(Instrumentation.customDimensionProperties.ClientSessionId);
        this.clearCustomDimension(Instrumentation.customDimensionProperties.UserLocale);
        this.clearCustomDimensionsForCurrentTeam();

        this.activeLoggers.forEach(logger => logger.clearUserContext());
    }

    /**
     * Start a new logging session.
     */
    public startLogSession(): void {
        this.activeLoggers.forEach(logger => logger.startLogSession());
    }

    /**
     * End the current logging session.
     */
    public endLogSession(): void {
        this.activeLoggers.forEach(logger => logger.endLogSession());
    }

    public actionType = Instrumentation.actionTypes;
    public actionTypes = Instrumentation.actionTypes;
    public availabilityTypes = Instrumentation.availabilityTypes;
    public boostrapTypes = Instrumentation.bootstrapTypes;
    public customDimensionProperties = Instrumentation.customDimensionProperties;
    public entryPoints = Instrumentation.entryPoints;
    public events = Instrumentation.events;
    public eventTypes = Instrumentation.eventTypes;
    public filterTypes = Instrumentation.filterTypes;

    /**
     * Log Levels for performance events.
     * 1 indicates very important and has to be logged, 2 and 3 are lower priority.
     * Global setting PerformanceLogLevel indicates the lowest priority level that needs to be logged.
     */
    public logLevel = logLevel;
    public perfScenarios = Instrumentation.perfScenarios;
    public properties = Instrumentation.properties;
    public reorderTypes = Instrumentation.reorderTypes;
    public ShiftRequestActionTaken = Instrumentation.ShiftRequestActionTaken;
    public ShiftRequestTypes = Instrumentation.ShiftRequestType;
    public ShiftRequestUsertype = Instrumentation.ShiftRequestUserType;
    public shiftTypes = Instrumentation.ShiftType;
    public values = Instrumentation.values;
    public Views = Instrumentation.views;
}

/**
 * Instrumentation Service Settings.
 */
export interface InstrumentationServiceSettings {
    /** Aria SDK Instrumentation Settings. */
    Aria: SDKInstrumentationSettings;

    /** OneDs SDK Instrumentation Settings. */
    OneDs: SDKInstrumentationSettings;
}

/**
 * SDK Instrumentation Service Settings.
 */
export interface SDKInstrumentationSettings {
    /** Whether to enable sending instrumentation to the SDK. */
    Enabled: boolean;

    /** Whether to enable sending perf instrumentation. */
    PerfLoggingEnabled: boolean;

    /** The logging level for perf logs. */
    PerfLoggingLevel: logLevel;

    /** Region Specific Collector Configuration for the SDK. */
    CollectorConfigs: ICollectorConfig[];

    /** Instrumentation key to Aria Tenant for Perf/Engineering metrics  (Sonoma Web Perf Prod/INT tenant). */
    InstrumentationKeyPerfTenant: string;

    /** Instrumentation key to Aria Tenant for UserBI metrics (Teams FLW Web Prod/Test tenant). */
    InstrumentationKeyUserBiTenant: string;
}

// Read settings from AppSettings config and initialize the default instrumentation service

let instrumentationAppSettings: InstrumentationServiceSettings = AppSettings.getSetting("Instrumentation") as InstrumentationServiceSettings;
if (!instrumentationAppSettings) {
    // Fallback to old appsettings settings
    const perfLoggingEnabled = AppSettings.getSetting("PerfLoggingEnabled");
    const perfLoggingLevel = AppSettings.getSetting("PerfLoggingLevel");
    const ariaLoggingEnabled = AppSettings.getSetting("AriaLoggingEnabled");
    const ariaEuCollectorUrl = AppSettings.getSetting("AriaEuCollectorUrl") ?? "https://eu-teams.events.data.microsoft.com/Collector/3.0";
    const ariaInstrumentationKeyUserBiTenant = AppSettings.getSetting("AriaWebAppKey");
    const ariaInstrumentationKeyPerfTenant = AppSettings.getSetting("AriaPerfWebAppKey");
    instrumentationAppSettings = {
        Aria: {
            Enabled: ariaLoggingEnabled,
            PerfLoggingEnabled: perfLoggingEnabled,
            PerfLoggingLevel: perfLoggingLevel,
            CollectorConfigs: [
                {
                    Region: "EU",
                    CollectorUrl: ariaEuCollectorUrl
                },
                {
                    Region: "",
                    CollectorUrl: ""
                }
            ],
            InstrumentationKeyPerfTenant: ariaInstrumentationKeyPerfTenant,
            InstrumentationKeyUserBiTenant: ariaInstrumentationKeyUserBiTenant
        },
        OneDs: {
            Enabled: false,
            PerfLoggingEnabled: false,
            PerfLoggingLevel: perfLoggingLevel,
            CollectorConfigs: [
                {
                    Region: "EU",
                    CollectorUrl: "https://eu-teams.events.data.microsoft.com/OneCollector/1.0/"
                },
                {
                    Region: "",
                    CollectorUrl: "https://teams.events.data.microsoft.com/OneCollector/1.0/"
                }
            ],
            InstrumentationKeyPerfTenant: "",
            InstrumentationKeyUserBiTenant: ""
        }
    };
}

const instrumentationService = new InstrumentationService(instrumentationAppSettings);
export default instrumentationService;
