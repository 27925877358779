import * as moment from "moment";
import IShiftrObjectServiceModel from "../IShiftrObjectServiceModel";
import ScheduleClientModel from "./ScheduleClientModel";
import ScheduleModelBase from "./ScheduleModelBase";

/**
 * Schedule service model
 */
export default class ScheduleServiceModel  extends ScheduleModelBase {
    static fromJson(jsonData: any): ScheduleServiceModel  {
        return new ScheduleServiceModel (
            jsonData.id,
            jsonData.eTag,
            jsonData.tenantId,
            jsonData.teamId,
            moment(jsonData.startTime),
            moment(jsonData.endTime),
            jsonData.state);
    }

    static fromClientModel(clientModel: ScheduleClientModel): ScheduleServiceModel  {
        return new ScheduleServiceModel (
            clientModel.id,
            clientModel.eTag,
            clientModel.tenantId,
            clientModel.teamId,
            clientModel.startTime,
            clientModel.endTime,
            clientModel.state);
    }

    static toClientModel(serviceModel: ScheduleServiceModel): ScheduleClientModel {
        return new ScheduleClientModel(
            serviceModel.id,
            serviceModel.eTag,
            serviceModel.tenantId,
            serviceModel.teamId,
            serviceModel.startTime,
            serviceModel.endTime,
            serviceModel.state);
    }

    static jsonToClientModel(jsonData: any, isPublished?: boolean): ScheduleClientModel {
        const serviceModel = ScheduleServiceModel .fromJson(jsonData);
        let clientModel = ScheduleServiceModel .toClientModel(serviceModel);
        return clientModel;
    }
}

// Verify that this class adheres to the contract defined by our interface.
// This needs to be done separately since Typescript does not allow static methods in interfaces.
// tslint:disable-next-line:no-unused-declaration
let __verifyInterface: IShiftrObjectServiceModel<ScheduleServiceModel , ScheduleClientModel> = ScheduleServiceModel;