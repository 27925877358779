import schedulesViewStateStore from "sh-application/components/schedules/lib/store/store";
import { bootstrapSelectedTeam } from "sh-application/bootstrap";
import { initializeSchedulesViewState } from "sh-application/components/schedules/lib";
import { orchestrator } from "satcheljs";
import { resetTeamData, teamSelected } from "sh-team-store";

/**
 * Run when a team is selected from the team picker. Resets team data, bootstraps the new team, and initializes the schedule view state
 * unless instructed not to.
 */
export default orchestrator(teamSelected, async actionMessage => {
    const { team, doInitializeSchedulesViewState } = actionMessage;

    // NOTE: await doesn't appear to work with Satchel Orchestrators, so handling such as
    // initializeSchedulesViewState below may start executing before bootstrapSelectedTeam
    // fully completes due to its usage of orchestrators.
    //
    // This team switching handling should probably be reworked to ensure that the setup steps
    // (eg, such as team time zone change handling) are executed in a determinate order.

    // First reset all existing team data
    resetTeamData();

    // Bootstrap data needed for the selected team
    await bootstrapSelectedTeam(team.tenantId, team.groupId, team.id, team.name);

    // Then we initialize the schedules view state and load team data for the existing team, unless instructed not to.
    // This is the case when a team is selected from the picker and the schedule is not already mounted.
    // The scheduler calls initializeSchedulesViewState after mounting, so we do not wish to call it twice.
    if (doInitializeSchedulesViewState) {
        initializeSchedulesViewState(schedulesViewStateStore());
    }
});