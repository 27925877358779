import { createStore } from "satcheljs";
import { UserStoreSchema } from "./schema/UserStore";

/* Register Orchestrators */
import "sh-stores/sh-user-store/orchestrators/updateLocateUserResponse";
import "sh-stores/sh-user-store/orchestrators/onToggleHideOpenShiftRows";
import "sh-stores/sh-user-store/orchestrators/onToggleHideScheduleAvailability";
import "sh-stores/sh-user-store/orchestrators/onToggleHideConflictManagement";
import "sh-stores/sh-user-store/mutators/updateLocateUserResponse";

/**
 * Store for User data
 */

let UserStore = createStore<UserStoreSchema>(
    "userStore",
    {
        user: null,         // signed in StaffHub user
        userSettings: null, // user settings,
        userPolicySettings: null, // user policy settings
        locateUserResponse: null, // user location
        disableHideOpenShiftRowsToggle: false, // used to disable the toggle while saving any updates
        disableHideScheduleAvailabilityToggle: false, // used to disable the toggle while saving any updates
        disableHideConflictManagementToggle: false
    });

export default UserStore;