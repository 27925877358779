import ShiftRequestUtils from "sh-application/utility/ShiftRequestUtils";
import {
    GroupedOpenShiftRequestEntity,
    IGroupedOpenShiftRequestEntity,
    IShiftRequestEntity,
    ShiftRequestTypes
    } from "sh-models";
import { mutatorAction } from "satcheljs";
import { shiftRequestStore } from "../";

export default mutatorAction('ADD_OPEN_SHIFTREQUEST_TO_GROUP', function addOpenShiftRequestToGroupedItem(shiftRequest: IShiftRequestEntity) {

    if (shiftRequest && shiftRequest.requestType === ShiftRequestTypes.Open) {
        const groupedOpenShiftRequestId: string = ShiftRequestUtils.getGroupedEntityIdFromShiftIdAndState(shiftRequest.shiftId, shiftRequest.state);

        if (shiftRequestStore().groupedOpenShiftRequests.has(groupedOpenShiftRequestId)) {
            // Add the shift request to an existing grouped item
            const groupedOpenShiftRequest: IGroupedOpenShiftRequestEntity = shiftRequestStore().groupedOpenShiftRequests.get(groupedOpenShiftRequestId);
            if (groupedOpenShiftRequest) {
                groupedOpenShiftRequest.addShiftRequest(shiftRequest);
            }
        } else {
            // Create a new grouped item with the shift request
            const groupedOpenShiftRequest: IGroupedOpenShiftRequestEntity = new GroupedOpenShiftRequestEntity(shiftRequest);
            shiftRequestStore().groupedOpenShiftRequests.set(groupedOpenShiftRequestId, groupedOpenShiftRequest);
        }
    }
});
