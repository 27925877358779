import { action } from "satcheljs";
import { Moment } from "moment";

const createTimeOffRequest = action(
    "CREATE_TIMEOFF_REQUEST",
    (
        message: string,
        startTime: Moment,
        endTime: Moment,
        timeOffReasonId: string
    ) => ({
        message: message,
        startTime: startTime,
        endTime: endTime,
        timeOffReasonId: timeOffReasonId
    })
);

export default createTimeOffRequest;