import { ChangeSource, default as IChangeEntity } from "./IChangeEntity";
import { IBaseShiftEntity, ICopyScheduleJobEntity, IBaseConflictEntity} from "sh-models";
import { trace } from "owa-trace";

/**
 * Entity class that represents Change model
 */
export default class ChangeEntity implements IChangeEntity {
    previousShifts: Array<IBaseShiftEntity>;
    resultingShifts: Array<IBaseShiftEntity>;
    changeSource: ChangeSource;
    getRevertTask?: Function;
    copiedSchedule?: ICopyScheduleJobEntity;
    previousConflict?: IBaseConflictEntity;

    /**
     * Constructor for change entity.  Scrubs the paramaters.
     * TODO: When Adding in notes, take this code and move it to a static factory method, something like buildShiftChange.
     *
     * @param previousShifts - the shifts in the state they were in before the change. can be null for shifts add.
     * @param resultingShifts - the shifts in the state they are in after the change. can be null for shifts delete.
     * @param changeSource - the source of the change.  Used for instrumentation and undo button string
     * @param copiedSchedule - the copied schedule being changed
     */
    constructor(previousShifts: Array<IBaseShiftEntity>, resultingShifts: Array<IBaseShiftEntity>, changeSource: ChangeSource, getRevertTask?: Function, copiedSchedule?: ICopyScheduleJobEntity, previousConflict?: IBaseConflictEntity) {
        // if the incoming values are not themselves null, make sure that the list does not contain null values.
        if (previousShifts) {
            if (previousShifts.some((shift: IBaseShiftEntity) => !shift)) {
                previousShifts = null;
            }
        }
        if (resultingShifts) {
            if (resultingShifts.some((shift: IBaseShiftEntity) => !shift)) {
                resultingShifts = null;
            }
        }
        if ((previousShifts === null && resultingShifts === null && copiedSchedule === null && previousConflict === null)
            || (previousConflict && (previousShifts && resultingShifts && previousShifts.length !== resultingShifts.length))
            || !changeSource) {
            trace.warn("ChangeEntity: constructor: invalid inputs");
            return;
        }

        this.previousShifts = previousShifts;
        this.resultingShifts = resultingShifts;
        this.changeSource = changeSource;
        this.getRevertTask = getRevertTask;
        this.copiedSchedule = copiedSchedule;
        this.previousConflict = previousConflict;
    }
}