import ITimeOffReasonDataService from "./ITimeOffReasonDataService";
import RestClient from "sh-rest-client";
import { DataService } from "./DataService";
import { ITimeOffReasonEntity, TimeOffReasonsUpdateResponse } from "sh-models";
import { TeamStore } from "sh-team-store";
import { TimeOffReasonsDataProvider } from "sh-services/dataproviders/TimeOffReasonsDataProvider";

/**
 * Functions for retrieving and editing information related to instances of IBaseShiftEntity.
 * Over time, we should move all of the legacy actions that are currently unders sh-shift-store to here.
 */
class TimeOffReasonDataService extends DataService implements ITimeOffReasonDataService {
    /**
     * Saves the time off reasons of the team.
     * @param timeOffReasonsToBeAdded Time off reasons to add
     * @param timeOffReasonsToBeUpdated Time off reasons to update
     * @param timeOffReasonsToBeDeleted Time off reasons to delete
     */
    public async saveTimeOffReasons(
        timeOffReasonsToBeAdded: Array<ITimeOffReasonEntity>,
        timeOffReasonsToBeUpdated: Array<ITimeOffReasonEntity>,
        timeOffReasonsToBeDeleted: Array<ITimeOffReasonEntity>
        ): Promise<TimeOffReasonsUpdateResponse> {

        const response: TimeOffReasonsUpdateResponse = await RestClient.updateTimeOffReasons(
                this.tenantId,
                TeamStore().teamId,
                timeOffReasonsToBeAdded,
                timeOffReasonsToBeUpdated,
                timeOffReasonsToBeDeleted);
        if (response) {
            await this.onUpdateTimeOffReasons(response.timeOffReasonsAdded, response.timeOffReasonsUpdated, response.timeOffReasonsDeleted);
        }
        return response;
    }

    /**
     * Handles updating the DB and memory stores with time off reasons from network
     * @param timeOffReasonsAdded Added time off reasons
     * @param timeOffReasonsUpdated Updated time off reasons
     * @param timeOffReasonsDeleted Deleted time off reasons
     */
    public async onUpdateTimeOffReasons(
        timeOffReasonsAdded: Array<ITimeOffReasonEntity>,
        timeOffReasonsUpdated: Array<ITimeOffReasonEntity>,
        timeOffReasonsDeleted: Array<ITimeOffReasonEntity>): Promise<ITimeOffReasonEntity[]> {
        // Since time off reasons are only soft-deleted, we can just set all values
        let updatedTimeOffReasons: ITimeOffReasonEntity[] = [];
        if (timeOffReasonsAdded && timeOffReasonsAdded.length) {
            updatedTimeOffReasons = updatedTimeOffReasons.concat(timeOffReasonsAdded);
        }
        if (timeOffReasonsUpdated && timeOffReasonsUpdated.length) {
            updatedTimeOffReasons = updatedTimeOffReasons.concat(timeOffReasonsUpdated);
        }

        if (timeOffReasonsDeleted && timeOffReasonsDeleted.length) {
            updatedTimeOffReasons = updatedTimeOffReasons.concat(timeOffReasonsDeleted);
        }

        if (updatedTimeOffReasons && updatedTimeOffReasons.length) {
            // Update the DB and memory stores
            const timeOffReasonDataProvider = new TimeOffReasonsDataProvider(this.teamDatabase, this.tenantId, updatedTimeOffReasons[0].teamId);
            return await this.updateDataInCache(updatedTimeOffReasons, timeOffReasonDataProvider);
        }
    }

    /**
     * Resets the sync state
     */
    public async resetSyncState() {
    }
}

const service: ITimeOffReasonDataService = new TimeOffReasonDataService();
export default service;