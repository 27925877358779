import { action } from "satcheljs";

const setShowCreateRequestDialog = action(
    "SET_SHOW_CREATE_REQUEST_DIALOG",
    (
        visible: boolean
    ) => ({
        visible: visible
    })
);

export default setShowCreateRequestDialog;