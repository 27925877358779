import { conflictRequestStore } from "..";
import { IBaseConflictEntity, IBaseShiftEntity } from "sh-models";
import { mutatorAction } from "satcheljs";
import { ObservableMap, ObservableSet } from "mobx";

export default mutatorAction('RESET_REQUEST_CONFLICT_STORE', function resetRequestConflictStore() {
    conflictRequestStore().loadedRequestsIds = new ObservableSet<string>();
    conflictRequestStore().requestShiftIdToConflictsMap = new ObservableMap<string, Array<IBaseConflictEntity>>();
    conflictRequestStore().memberShifts = new ObservableMap<string, IBaseShiftEntity>();
    conflictRequestStore().requestShift = new ObservableMap<string, IBaseShiftEntity>();
});