import StringsStore from "sh-strings/store";
import { ShiftRequestType, ShiftRequestTypes } from "../../sh-models";

/**
 * Utilities for strings
 */
export default class StringUtils {
    /**
     * Returns true if the input number should be used with plural strings. This is the case for all inputs
     * other than 1
     * @param num
     */
    public static usePluralForm(num: number): boolean {
        return num !== 1;
    }

    /**
     * Convert a list of strings to a single string that is a comma separated list e.g. (a, b, c) or (a, b, and c)
     * @param items
     * @param useAnd - optionally use and "and" for the last item
     */
    public static getCommaSeparatedList(items: string[], useAnd?: boolean): string {
        let commaSeparatedList: string = "";
        if (items && items.length > 0) {
            const strings: Map<string, string> =
                StringsStore().registeredStringModules.get("stringUtils").strings;

            const size: number = items.length;
            if (size === 1) {
                commaSeparatedList = items[0];
            } else if (size === 2) {
                if (useAnd) {
                    commaSeparatedList = strings.get("andLastItem").format(items[0], items[1]);
                } else {
                    commaSeparatedList = strings.get("listSeparator").format(items[0], items[1]);
                }
            } else if (size > 2) {
                commaSeparatedList = items[0];
                for (let i = 1; i < size - 1; i++) {
                    commaSeparatedList = strings
                        .get("listSeparator")
                        .format(commaSeparatedList, items[i]);
                }
                if (useAnd) {
                    commaSeparatedList = strings
                        .get("listSeparatorAndLastItem")
                        .format(commaSeparatedList, items[size - 1]);
                } else {
                    commaSeparatedList = strings
                        .get("listSeparator")
                        .format(commaSeparatedList, items[size - 1]);
                }
            }
        }
        return commaSeparatedList;
    }

    /**
     * Generate random string of letters and digits
     * @param length
     */
    public static generateRandomString(length: number = 10): string {
        let text = "";
        const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

        for (let i = 0; i < length; i++) {
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }

        return text;
    }

    /**
     * Generic string comparator
     */
    public static genericStringComparator(firstString: string, secondString: string): number {
        if (!firstString && !secondString) {
            return 0;
        } else if (!secondString) {
            return -1;
        } else if (!firstString) {
            return 1;
        } else {
            return firstString.localeCompare(secondString);
        }
    }

    /**
     * Generate a localized string depending on the request type.
     * @param requestType
     * @returns string
     */
    public static localizeRequestType = (requestType: ShiftRequestType): string => {
        let localizedRequestType = "";

        switch (requestType) {
            case ShiftRequestTypes.HandOff:
                localizedRequestType = StringsStore()
                    .registeredStringModules.get("shiftRequests")
                    .strings.get("handOff");
                break;
            case ShiftRequestTypes.Swap:
                localizedRequestType = StringsStore()
                    .registeredStringModules.get("shiftRequests")
                    .strings.get("swap");
                break;
            case ShiftRequestTypes.TimeOff:
                localizedRequestType = StringsStore()
                    .registeredStringModules.get("shiftRequests")
                    .strings.get("timeOff");
                break;
            default:
                localizedRequestType = requestType.toString();
        }

        return localizedRequestType;
    };
}
