import { logLevel } from "sh-instrumentation";
import { InstrumentationService } from "sh-services";

/**
 * Interface for Scenario Data.
 */
interface IScenarioData {
    /**
     * Session start time stamp.
     */
    sessionStartTimeStamp: number;

    /**
     * Last known time stamp.
     */
    lastKnownTimeStamp: number;

    /**
     * Perf logging level.
     */
    perfLoggingLevel: number;
}

/**
 * Instrumentation Perf Scenario.
 * This class is used to store perf scenario data in memory and associate that data with unique Scenario Id.
 */
export class InstrumentationPerfScenario {
    /**
     * Underlying perf scenario data.
     */
    private perfScenarioData: {[scenarioId: string]: IScenarioData};

    /**
     * Generate new UUID.
     * @returns The UUID.
     */
    private generateUUID(): string {
        let d = new Date().getTime();
        const uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function generateUUID$replace(c: string) {
                const r = (d + Math.random() * 16) % 16 | 0;
                d = Math.floor(d / 16);
                return (c == "x" ? r : (r & 0x3 | 0x8)).toString(16);
        });
        return uuid;
    }

    /**
     * Generate UUID with prefix.
     * @param prefix The prefix.
     * @returns UUID with Prefix.
     */
    private generateUUIDWithPrefix(prefix: string): string {
        return prefix + this.generateUUID();
    }

    /**
     * Generate new PerfScenario Id used by Shiftr web app internally.
     * @returns The PERF_<uuid>.
     */
    public generateNewPerfScenarioId(): string {
        return this.generateUUIDWithPrefix("PERF_");
    }

    /**
     * Store perfScenario Data in memory and associate that data with unique Scenario Id.
     * This will be used to store loglevel, session start time for a scenario. This could be used later to calculate duration of event from session start etc.
     * @param scenarioId Perf scenario Id.
     * @param perfLoggingLevel Perf logging level.
     * @param timeStamp Optional timestamp to indicate last known event in a session. If sessionData is newly created, timestamp will be used as sessionStartDate.
     */
    public setPerfScenarioData(scenarioId: string, perfLoggingLevel: number, timeStamp: number): void {
        this.perfScenarioData = this.perfScenarioData || {};
        const currentTimeStamp = InstrumentationService.getCurrentTimeStamp();
        this.perfScenarioData[scenarioId] = this.perfScenarioData[scenarioId] || { sessionStartTimeStamp: timeStamp || currentTimeStamp } as IScenarioData;
        this.perfScenarioData[scenarioId].lastKnownTimeStamp = timeStamp || currentTimeStamp;
        this.perfScenarioData[scenarioId].perfLoggingLevel = perfLoggingLevel || logLevel.Default;
    }

    /**
     * Get PerfScenario data.
     * @param scenarioId Perf scenario Id.
     * @returns Scenario data.
     */
    public getPerfScenarioData(scenarioId: string): IScenarioData {
        return (this.perfScenarioData && this.perfScenarioData[scenarioId]) ? this.perfScenarioData[scenarioId] : {} as IScenarioData;
    }

    /**
     * Remove perf scenario data associated with given scenarioId.
     * @param scenarioId Perf scenario Id.
     */
    public removePerfScenarioData(scenarioId: string): void {
        this.perfScenarioData = this.perfScenarioData || {};
        delete this.perfScenarioData[scenarioId];
    }

    /**
     * Get PerfScenario session Start timeStamp.
     * @param scenarioId Perf scenario Id.
     * @returns The timestamp when the session started.
     */
    public getPerfScenarioSessionStartTimeStamp(scenarioId: string): number {
        return this.getPerfScenarioData(scenarioId).sessionStartTimeStamp;
    }

    /**
     * Get PerfScenario Logging level.
     * @param scenarioId Perf scenario Id.
     * @returns The perf logging level.
     */
    public getPerfScenarioLoggingLevel(scenarioId: string): number {
        return this.getPerfScenarioData(scenarioId).perfLoggingLevel;
    }
}
export const instrumentationPerfScenario = new InstrumentationPerfScenario();