import setECSConfig from "../actions/setECSConfig";
import setECSConfigInStore from "../mutators/setECSConfigInStore";
import {
    ClientVersionService,
    ECSConfigKey,
    ECSConfigService,
    InstrumentationService
    } from "sh-services";
import { orchestrator } from "satcheljs";

export default orchestrator(setECSConfig, (actionMessage) => {
    // Read the store values before the update
    const originalBuildVersion = ECSConfigService.getECSFeatureSetting(ECSConfigKey.BuildVersion);

    // Update the store before we notify any service that depend on this value
    setECSConfigInStore(actionMessage.config);

    // Notify the instrumenation service
    InstrumentationService.onECSUpdated();

    // Only inform the ClientVersionService that there was an update if the value changed (from a non-null value)
    // This is to prevent the scenario where the user may get stuck in an update-restart loop because the service is returning a
    // different version than what ECS specifies
    if (originalBuildVersion && originalBuildVersion !== ECSConfigService.getECSFeatureSetting(ECSConfigKey.BuildVersion)) {
        ClientVersionService.onBuildVersionUpdated();
    }
});