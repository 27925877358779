import * as moment from "moment";
import initializeTimeoffRequest from "../actions/initializeTimeoffRequest";
import timeOffInputStore, {
    resetTimeOffInputStore,
    setEndDateInStore,
    setEndTimeInStore,
    setIsAllDayInStore,
    setMemberInStore,
    setNotesInStore,
    setStartDateInStore,
    setStartTimeInStore,
    setTimeOffReasonIdInStore
    } from "../store/store";
import TimeOffUtils from "sh-application/utility/TimeOffUtils";
import { IMemberEntity } from "sh-models";
import { MobxUtils } from "sh-application";
import { Moment } from "moment";
import { orchestrator } from "satcheljs";
import { TeamStore } from "sh-team-store";
import { getRequestableTimeOffReasonsFromStore, TimeOffReasonsStore } from "sh-timeoffreasons-store";

export default orchestrator(initializeTimeoffRequest, async actionMessage => {

    resetTimeOffInputStore();
    const me: IMemberEntity = TeamStore() ? TeamStore().me : null;
    setMemberInStore(me);

    // Setup state for time range controls
    let startDateTime: Moment = moment().startOf('day');
    let endDateTime: Moment = moment().endOf('day');
    let isAllDayRange: boolean = !(TimeOffUtils.shouldDisableAllDayTimeOff());

    setStartDateInStore(TimeOffUtils.normalizeStartEndDateValue(startDateTime));
    setEndDateInStore(TimeOffUtils.normalizeStartEndDateValue(endDateTime));
    startDateTime = isAllDayRange ?  startDateTime.clone() : TimeOffUtils.calculateDefaultStartTimeForDate(timeOffInputStore.startDate);
    endDateTime = isAllDayRange ?  endDateTime.clone() : TimeOffUtils.calculateDefaultEndTimeForDate(timeOffInputStore.endDate, true);

    // Setup Store
    setStartTimeInStore(startDateTime);
    setEndTimeInStore(endDateTime);
    setIsAllDayInStore(isAllDayRange);
    const hasRequestableTimeOffReasons = TimeOffReasonsStore().requestableTimeOffReasonsIds?.length > 0;
    // Time off reason
    if (TimeOffReasonsStore().timeOffReasons && TimeOffReasonsStore().timeOffReasons.size && !hasRequestableTimeOffReasons) {
        setTimeOffReasonIdInStore(MobxUtils.MapKeysToArray(TimeOffReasonsStore().timeOffReasons)[0]);
    } else if (hasRequestableTimeOffReasons) {
        // If requestable TOR are in the store, we should init the dropdown here since the call won't be made again.
        const [first] = getRequestableTimeOffReasonsFromStore();
        setTimeOffReasonIdInStore(first.id);
    } else {
        setTimeOffReasonIdInStore("");
    }
    // Notes
    setNotesInStore("");
     // Don't need to set the theme for Shift Requests
});