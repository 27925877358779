import { ECSConfigKey, ECSConfigService, FlightSettingsService } from "sh-services";
import { FlightKeys } from "sh-models";
import { TeamStore } from "sh-team-store";
/**
 * Utilities for Team Setting related items
 */
export default class TeamSettingUtils {
    /**
     * Checks if union support is enabled for the team
     */
    public static isUnionSupportEnabledForTeam(): boolean {
        return this.isUnionSupportEnabled() && TeamStore().team.enableLastNameInitial;
    }

    /**
     * Checks if union support is turned on
     * i.e is flighted and config is on
     */
    public static isUnionSupportEnabled(): boolean {
        return ECSConfigService.isECSFeatureEnabled(ECSConfigKey.EnableUnionSupport) && FlightSettingsService.isFlightEnabled(FlightKeys.EnableLastNameInitial);
    }

    /**
     * Is workforce integration enabled for this team?
     * @returns {boolean} true if workforce integration is enabled
     */
    public static isWorkforceIntegrationEnabled(): boolean {
        const wfiIds = TeamStore().team.workforceIntegrationIds;
        return wfiIds && wfiIds.length > 0;
    }
}
