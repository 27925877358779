import IShiftrObjectClientModel from "../IShiftrObjectClientModel";
import ScheduleModelBase from "./ScheduleModelBase";

/**
 * Schedule client model
 */
export default class ScheduleClientModel extends ScheduleModelBase {
}

// Verify that this class adheres to the contract defined by our interface.
// This needs to be done separately since Typescript does not allow static methods in interfaces.
// tslint:disable-next-line:no-unused-declaration
let __verifyInterface: IShiftrObjectClientModel = ScheduleClientModel;