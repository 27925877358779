import { Moment } from "moment";

type PaidBreakType = "Paid";
type UnpaidBreakType = "Unpaid";
export type BreakType = PaidBreakType | UnpaidBreakType;
export const BreakTypes = {
    Paid: "Paid" as PaidBreakType,
    Unpaid: "Unpaid" as UnpaidBreakType
};

/**
 * Interface that represents Break entity model
 */
export interface IBaseBreakEntity<DateTimeType> {
    id: string;
    duration: number;
    breakType: BreakType;
    startTime: DateTimeType;
}

/**
 * The client Break entity type
 * Uses moment as the DateTimeType
 */
export type IBreakEntity = IBaseBreakEntity<Moment>;
export default IBreakEntity;
