import * as React from "react";
import AutomationUtils from "sh-application/utility/AutomationUtil";
import AvailabilityUtils from "sh-application/utility/AvailabilityUtils";
import BrowserUtils from "sh-application/utility/BrowserUtils";
import DateTimeFormatter, { DateTimeFormatType } from "sh-application/utility/DateTimeFormatter";
import DateUtils from "../../utility/DateUtils";
import InstrumentationUtils from "sh-application/utility/InstrumentationUtils";
import MemberUtils from "sh-application/utility/MemberUtils";
import ShiftUtils from "sh-application/utility/ShiftUtils";
import StringsStore from "sh-strings/store";
import TagUtils from "sh-application/utility/TagUtils";
import UniqueShift from "./UniqueShift";
import UserUtils from "sh-application/utility/UserUtils";
import { action } from "satcheljs/lib/legacy";
import { AriaProperties, AriaRoles } from "owa-accessibility";
import {
    Announced,
    ContextualMenu,
    ContextualMenuItemType,
    DirectionalHint,
    IContextualMenuItem,
    IContextualMenuListProps,
    IContextualMenuProps,
    IPopupRestoreFocusParams,
    IRenderFunction,
    ISearchBox,
    PersonaSize,
    SearchBox
    } from "@fluentui/react";
import {
    DataProcessingHelpers,
    ECSConfigKey,
    ECSConfigService,
    InstrumentationService
    } from "sh-services";
import { deleteUniqueShift, UniqueShiftStore } from "sh-uniqueshift-store";
import { fireAccessibilityAlert } from "sh-application/components/accessibilityAlert";
import {
    IAvailabilityEntity,
    IMemberAvailabilityForDisplayEntity,
    IMemberEntity,
    IOpenShiftEntity,
    IShiftEntity,
    IUniqueShiftEntity,
    IUserQuery,
    ScheduleCalendarType,
    ShiftTypes
    } from "sh-models";
import { getAvailability } from "sh-stores/sh-availability-store";
import { getGenericEventPropertiesObject } from "sh-instrumentation";
import { IShiftEditorData, launchShiftEditor, shiftEditorViewState } from "./lib";
import { IUserPersonaProps } from "../common/IUserPersonaProps";
import { UserPersona } from "../common/UserPersona";
import { Moment } from "moment-timezone";
import { observable } from "mobx";
import { observer } from "mobx-react";
import { OpenShiftStore } from "sh-open-shift-store";
import { ScheduleCellActionCallbacks } from "sh-application/components/schedule/cells/ScheduleCell";
import { SHIFT_CELL_CONTEXT_MENU_CALLOUT } from "sh-application/../StaffHubConstants";
import { TagStore } from "sh-tag-store";
import { TeamStore } from "sh-team-store";

const classNames = require("classnames/bind");
const styles = require("./ShiftContextualMenu.scss");

const maxNumberOfShiftsToShow = 2;
const defaultGapSpace = 0; // This is the space between the target element and the menu.

interface ShiftContextualMenuProps {
    member: IMemberEntity;          // Member to whom the shift belongs to
    shift: IShiftEntity;            // The shift whose menu this is
    date: Moment;                   // The date the contextual menu is opened for
    groupTagId: string;             // The group(id) in which the shift falls into
    target: HTMLElement;            // The target element to position the contextual menu. Fabric has their own choice of where to position it
    onDismissCallback: Function;    // Call back function when the menu is dismissed
    scheduleCellActionCallbacks: ScheduleCellActionCallbacks; // Callbacks for actions that can be performed for the schedule cell (eg, copy + paste, delete, etc) */
    scheduleCalendarType: ScheduleCalendarType; // needed for instrumentation and opening the addEditShiftPanel
    isShiftLookup: boolean;         // true if this is the shift lookup menu
    isOpenShift?: boolean;          // True if menu open on open shift cell, empty or not
    disableShare?: boolean;         // Optional-- if true, share should be overwritten as disabled, regardless of shift state
    isAssignOpenShiftMode?: boolean; // Optional -- if true, menu renders in assign open shift mode
    triggerKey?: string;            // Optional - the key that triggered the menu to open
}

/**
 * The contextual menu for Shift cells.
 */
@observer
export default class ShiftContextualMenu extends React.Component<ShiftContextualMenuProps, any> {
    private _commonStrings: Map<string, string>;
    private _membersFoundCount: number;
    private _shiftsStrings: Map<string, string>;
    private _shiftRequestStrings: Map<string, string>;
    private _shiftEditorStrings: Map<string, string>;
    private _startOfDate: Moment;
    private _searchBox = React.createRef<ISearchBox>();
    private _searchBoxInitialValue: string = undefined;

    // The map that stores availabilities so we dont fetch each time
    private _memberAvailabilites: Map<string, IMemberAvailabilityForDisplayEntity>;

    // The search box text string which holds the current value entered by the user.
    @observable private _searchString = "";
    private _lastSearchString = ""; // we use this to make sure we don't repeat the accessibility alert

    // Flag that determines if the menu should render in assignOpenShiftMode
    @observable private _isAssignOpenShiftMode: boolean;

    constructor(props: ShiftContextualMenuProps) {
        super(props);

        this._commonStrings = StringsStore().registeredStringModules.get("common").strings;
        this._shiftsStrings = StringsStore().registeredStringModules.get("schedulePage").strings;
        this._shiftRequestStrings = StringsStore().registeredStringModules.get("shiftRequests").strings;
        this._shiftEditorStrings = StringsStore().registeredStringModules.get("addEditShiftPanel").strings;
        this._memberAvailabilites = new Map<string, IMemberAvailabilityForDisplayEntity>();

        this._membersFoundCount = undefined;
        this._searchBoxInitialValue = this.props.triggerKey || undefined;

        this.initializeMenu();
    }

    private initializeMenu() {
        this._startOfDate = this.resetToStartOfDay(this.props.date);
        this.setAssignOpenShiftMode(!!this.props.isAssignOpenShiftMode);
    }

    /**
     * Used to dereference the unique shifts so the class will get registered with mobx as an observer of
     * the unique shifts
     */
    private observeUniqueShiftsStore(): boolean {
        return (UniqueShiftStore && UniqueShiftStore().uniqueShifts && UniqueShiftStore().uniqueShifts.length > 0);
    }

    private getSearchShiftLookupMenuItems(): IContextualMenuItem[] {
        let items: IContextualMenuItem[] = [];

        // make sure we are observing the unique shifts so we are called back to render when they change
        // regression bug #522411
        this.observeUniqueShiftsStore();

        items.push(
            this.getUniqueShiftsSearchBoxItem(),
            {
                // The search results or the top recent shifts (if the search string is empty)
                key: "searchResults",
                onRender: (item: any) => {
                    return this.renderShifts();
                }
            }
        );

        return items;
    }

    /**
     * Renders a search box for members
     */
    private onRenderSearchBar = (
        menuListProps: IContextualMenuListProps,
        defaultRender: IRenderFunction<IContextualMenuListProps>) => {
        return (
            <div>
                {this.renderSearchBox(this._shiftsStrings.get("searchForMembers"))}
                {defaultRender(menuListProps) /* Render the rest of the menu */}
            </div>
        );
    };

    private getMenuItems(): IContextualMenuItem[] {
        if (this.props.isShiftLookup) {
            return this.getSearchShiftLookupMenuItems();
        }

        let items: IContextualMenuItem[] = [];

        // The contextual menu renders in either Assign Open Shift mode or in regular mode, where it contains a set of action items
        if (this.shouldRenderMembersAndSearchBox()) {
            items = items.concat(this.getMemberSearchResultItems());
        } else {
            items.push({
                key: "addShift",
                name: this.getAddEditShiftLabel(false /*isEdit*/),
                iconProps: {
                    iconName: 'teams-add-svg'
                },
                onClick: this.onAddShiftFullPageClicked,
                className: AutomationUtils.getAutomationId("scheduler", "QAIDAddShift")
            });

            if (this.shouldRenderAddTimeOffItem()) {
                items.push({
                    key: "addTimeOff",
                    name: this._shiftsStrings.get("addTimeOff"),
                    iconProps: {
                        iconName: 'teams-add-svg'
                    },
                    onClick:  this.onAddTimeOffFullPageClicked,
                    className: AutomationUtils.getAutomationId("scheduler", "QAIDAddTimeOff")
                });
            }

            if (this.shouldRenderEditShiftItem()) {
                items.unshift({
                    key: "editShift",
                    name: ShiftUtils.isWorkingShift(this.props.shift)
                            ? this.getAddEditShiftLabel(true /*isEdit*/)
                            : this._shiftsStrings.get("editTimeOff"),
                    iconProps: {
                        iconName: 'teams-edit'
                    },
                    className: AutomationUtils.getAutomationId("scheduler", 'QAIDEditShift'),
                    onClick:  ShiftUtils.isWorkingShift(this.props.shift)
                                ? this.onEditShiftFullPageClicked
                                : this.onEditTimeOffFullPageClicked
                });
            }

            if (this.shouldRenderAssignOpenShiftItem()) {
                items.push({
                    key: "assignOpenShift",
                    name: this._shiftsStrings.get("assignOpenShift"),
                    iconProps: {
                        iconName: 'teams-add-member'
                    },
                    onClick: this.onAssignOpenShiftClicked,
                    disabled: !this.shouldEnableAssignOpenShiftItem(),
                    className: AutomationUtils.getAutomationId("scheduler", 'QAIDAssignOpenShift')
                });
            }

            if (this.shouldRenderMoveToOpenShiftsItem()) {
                items.push({
                    key: "moveToOpenShifts",
                    name: this._shiftsStrings.get("moveToOpenShiftsContextMenuItem"),
                    iconProps: {
                        iconName: 'teams-icons-shift-clock'
                    },
                    onClick: this.onMoveToOpenShiftsClicked,
                    className: AutomationUtils.getAutomationId("scheduler", 'QAIDMoveToOpenShift')
                });
            }

            if (this.props.scheduleCellActionCallbacks) {
                let additionalItems: IContextualMenuItem[] = [];

                if (this.props.scheduleCellActionCallbacks.cellCopyCallback) {
                    const isMac = BrowserUtils.isMac();
                    const copyShortcut = isMac ? this._shiftsStrings.get("copyShortcutMac") : this._shiftsStrings.get("copyShortcutWindows");
                    const copyAriaLabel = isMac ? this._shiftsStrings.get("copyAriaLabelMac") : this._shiftsStrings.get("copyAriaLabelWindows");
                    additionalItems.push({
                        key: "copyShift",
                        name: this._commonStrings.get("copy"),
                        iconProps: {
                            iconName: 'teams-copy-svg'
                        },
                        onClick: this.onCopyClicked,
                        ariaLabel: copyAriaLabel,
                        secondaryText: copyShortcut,
                        className: AutomationUtils.getAutomationId("scheduler", 'QAIDCopyShift')
                    });
                }

                if (this.props.scheduleCellActionCallbacks.cellPasteCallback) {
                    const isMac = BrowserUtils.isMac();
                    const pasteShortcut = isMac ? this._shiftsStrings.get("pasteShortcutMac") : this._shiftsStrings.get("pasteShortcutWindows");
                    const pasteAriaLabel = isMac ? this._shiftsStrings.get("pasteAriaLabelMac") : this._shiftsStrings.get("pasteAriaLabelWindows");
                    additionalItems.push({
                        key: "pasteShift",
                        name: this._commonStrings.get("paste"),
                        iconProps: {
                            iconName: 'teams-paste-svg'
                        },
                        onClick: this.onPasteClicked,
                        ariaLabel: pasteAriaLabel,
                        secondaryText: pasteShortcut,
                        disabled: !this.props.scheduleCellActionCallbacks.canPerformPasteCallback(),
                        className: AutomationUtils.getAutomationId("scheduler", 'QAIDPasteShift')
                    });
                }

                if (this.shouldRenderDeleteItem()) {
                    additionalItems.push({
                        key: "deleteShift",
                        name: this._commonStrings.get("delete"),
                        iconProps: {
                            iconName: 'teams-delete-svg'
                        },
                        onClick: this.onDeleteClicked,
                        className: AutomationUtils.getAutomationId("scheduler", 'QAIDDeleteShift')
                    });
                }

                // If we have additional menu items from above, add a divider to the top.
                if (additionalItems.length) {
                    additionalItems.unshift({
                        key: 'divider_1',
                        itemType: ContextualMenuItemType.Divider,
                        name: '-',
                        className: styles.contextualMenuDivider
                    });
                    items = items.concat(additionalItems);
                }
            }

            if (this.shouldRenderShareItem()) {
                items.push({
                    key: 'divider_2',
                    name: '-',
                    itemType: ContextualMenuItemType.Divider,
                    className: styles.contextualMenuDivider
                });
                items.push({
                    key: "share",
                    name: this._commonStrings.get("share"),
                    iconProps: {
                        iconName: 'teams-send'
                    },
                    onClick: this.onShareClicked,
                    className: AutomationUtils.getAutomationId("scheduler", 'QAIDShareShift')
                });
            }
        }

        return items;
    }

    /**
     * Returns true if open shifts is enabled on a flight and client app settings config level
     */
    private isOpenShiftsEnabled(): boolean {
        return ECSConfigService.isECSFeatureEnabled(ECSConfigKey.EnableOpenShifts);
    }

    /**
     * Computes and returns availabilities for member when assign open shift is open
     * @param member  - member for which persona object needs to be rendered
     * @return availabilities array with date and key and member availabilities for shift as value
     */
    private computeAssignOpenShiftAvailabilities(member: IMemberEntity): IMemberAvailabilityForDisplayEntity {
        let memberAvailabilitiesForDisplay: IMemberAvailabilityForDisplayEntity;
        const displayName = MemberUtils.getDisplayNameForMember(member);
        const allAvailabilities: string[] = [];
        let ariaLabel: string = "";
         // get availabilities from local map that stores member availabilities so we dont fetch them everytime
         if (this._memberAvailabilites.has(member.id)) {
            memberAvailabilitiesForDisplay = this._memberAvailabilites.get(member.id);
        } else {
            // calculate member availabilities and store in member avalabilities in local map
            const isOverMultipleDays: boolean = this.props.shift.startTime.day() !== this.props.shift.endTime.day();
            const currentDisplayDate: string = this.getDateValue(this.props.shift.startTime);
            const currentDateAvailabilityEntity: IAvailabilityEntity = getAvailability(member, this.props.shift.startTime);
            const currentDateAvailability: string[] = AvailabilityUtils.getUserFriendlyStrings(currentDateAvailabilityEntity);
            const currentDateAvailabilityString: string = this.generateAvailabilitiesStringHelper(currentDateAvailability);
            // calculate next day availabilities as well since shift spans over multiple days
            if (isOverMultipleDays && currentDateAvailabilityString) {
                allAvailabilities.push(this._shiftsStrings.get("memberAvailabilityInfoFormat").format(currentDisplayDate, currentDateAvailabilityString));
                const crossOverDisplayDate: string = this.getDateValue(this.props.shift.endTime);
                const crossOverDateAvailabilityEntity: IAvailabilityEntity = getAvailability(member, this.props.shift.endTime);
                const crossOverDateAvailability: string[] = AvailabilityUtils.getUserFriendlyStrings(getAvailability(member, this.props.shift.endTime));
                const crossOverDateAvailabilityString: string =  this.generateAvailabilitiesStringHelper(crossOverDateAvailability);
                ariaLabel = AvailabilityUtils.getMemberAvailabiltiesAriaLabel(displayName, [currentDateAvailabilityEntity, crossOverDateAvailabilityEntity], [currentDisplayDate, crossOverDisplayDate], isOverMultipleDays);
                if (crossOverDateAvailability) {
                    allAvailabilities.push(this._shiftsStrings.get("memberAvailabilityInfoFormat").format(crossOverDisplayDate, crossOverDateAvailabilityString));
                }
            } else {
                allAvailabilities.push(currentDateAvailabilityString);
                ariaLabel = AvailabilityUtils.getMemberAvailabiltiesAriaLabel(displayName, [currentDateAvailabilityEntity], [currentDisplayDate], isOverMultipleDays);
            }
            memberAvailabilitiesForDisplay = {  memberAvailabilitiesDisplayByDate : allAvailabilities, ariaLabel: ariaLabel };
            this._memberAvailabilites.set(member.id, memberAvailabilitiesForDisplay);
        }
        return memberAvailabilitiesForDisplay;
    }

    /**
     * Function that renders a member item containing a fabric persona component
     * @param {IMemberEntity} member - member for which persona object needs to be rendered
     * @return {JSX.Element} - rendered persona
     */
     private renderMemberItem(member: IMemberEntity): JSX.Element {
        const displayName = MemberUtils.getDisplayNameForMember(member);

        const props: IUserPersonaProps = {
            imageShouldFadeIn: true,
            key: `${member.id}_persona`,
            onRenderSecondaryText: () => this.renderMemberItemCurrentDateAvailabilities(member),
            size: PersonaSize.size40,
            text: displayName,
            userId: member.userId
        };

        return (
            <UserPersona {...props} />
        );
    }

    /**
     * Function that renders availabilities in user persona cell for assign open shift picker
     * @param {IMemberEntity} values - availabilties for member
     * @return {JSX.Element} - rendered persona
     */
    private renderMemberItemCurrentDateAvailabilities(member: IMemberEntity): JSX.Element {
        const memberAvailabilites = this.getMemberAvailabilities(member);

        if (memberAvailabilites && memberAvailabilites.memberAvailabilitiesDisplayByDate && memberAvailabilites.memberAvailabilitiesDisplayByDate.length > 0 ) {
            // if shift spans over one day availabilties will only have one value for that day
            const firstDayAvailabilties: string = memberAvailabilites.memberAvailabilitiesDisplayByDate[0];
            let nextDayAvailabilties: string;
            const isOverMultipleDays: boolean = memberAvailabilites.memberAvailabilitiesDisplayByDate.length > 1;
            if (isOverMultipleDays) {
                // if shift spans over two days availabilties will only have two values and second value will be for the next day
                nextDayAvailabilties = memberAvailabilites.memberAvailabilitiesDisplayByDate[1];
            }
            return (
            <div className={ styles.availabilityContainer }>
                <span className={ styles.availabilitySpan }>{ firstDayAvailabilties }</span>
                { isOverMultipleDays ?
                    // if shift only spans over two days show the next days availabilities as well
                    <span className={ styles.availabilitySpan }>{ nextDayAvailabilties }</span> : null
                }
            </div>);
        }
        return null;
    }

    /**
     * Function that returns concatnated string values of availabilities
     * @param availabilities - array of members availabilties
     * @return {string} - reformatted availabilities. Ex 5:30 PM - 5:45 PM , 6:00 PM - 7:00 PM
     */
    private generateAvailabilitiesStringHelper(availabilities: string[]): string {
        return (availabilities && availabilities.length > 0) ? availabilities.join(this._shiftRequestStrings.get("dataValueDelimiter")) : "";
    }

    /**
     * Function that returns reformated date value using datetime fomatter
     * @param date - date that needs to be reformatted
     * @return {string} - reformatted date time. Ex. 3/24
     */
    private getDateValue(date: Moment): string {
        return date
            ? DateTimeFormatter.getDateTimeAsString(date, DateTimeFormatType.Date_DateMonthNumeric)
            : "";
    }

    private getMemberSearchResultItems(): IContextualMenuItem[] {
        let items = this.getMemberItems(this._searchString);
        if (!items || !items.length) {
            this._membersFoundCount = 0;
            fireAccessibilityAlert(this._shiftsStrings.get("noMembersFound"));

            items.push({
                key: "noMembersFound",
                onRender: () => this.renderNoMembersFound()
            });
        } else if (this._membersFoundCount != items.length) {
            this._membersFoundCount = items.length;
            const alertMessage = this._membersFoundCount == 1 ? this._shiftsStrings.get("searchMembersFoundAlertSingle") : this._shiftsStrings.get("searchMembersFoundAlertPlural").format(this._membersFoundCount.toString());
            fireAccessibilityAlert(alertMessage);
        }

        return items;
    }

    /**
     * Returns a list of member items.
     * @param filterString string to filter members by. Empty by default
     */
    private getMemberItems(filterString: string = ""): IContextualMenuItem[] {
        let memberItems: IContextualMenuItem [] = [];
        let members: IMemberEntity[] = [];

        // For shifts in the default, "Other" group or for menus opened in ungrouped view,
        // the assign open shift menu will include all the members on the team
        if (TagUtils.tagIsEmptyOrDefault(this.props.groupTagId)) {
            members = DataProcessingHelpers.getArrayFromMap<string, IMemberEntity>(TeamStore().members);
        } else {
            // For shifts in regular groups, the menu will contain the members in that group
            const tagStore = TagStore();
            const tag = tagStore && tagStore.tags && tagStore.tags.get(this.props.groupTagId);
            if (tag) {
                const teamStore = TeamStore();
                members = teamStore && teamStore.members && tag.memberIds && tag.memberIds.map((memberId: string) => teamStore.members.get(memberId));
            }
        }

        // filter deleted members and any null/undefined members
        members = members.filter((member: IMemberEntity) => !!member && !MemberUtils.isMemberDeletedFromTeam(member));

        // Parse filter string and filter members
        const userQuery: IUserQuery = UserUtils.breakDownUserSearch(filterString);
        if (filterString && filterString.trim().length) {
            members = members.filter((member: IMemberEntity) => this.memberMatchesSearchString(userQuery, member, filterString));
        }

        memberItems = members.sort(
            (firstMember: IMemberEntity, secondMember: IMemberEntity) =>
                MemberUtils.memberComparator(firstMember, secondMember)
            )
            .map((member: IMemberEntity): IContextualMenuItem => {
                this.computeAssignOpenShiftAvailabilities(member);

                return {
                    key: member.id,
                    onRenderContent: () => this.renderMemberItem(member),
                    className: styles.memberItem,
                    ariaLabel: this.getAriaLabelForMember(member),
                    onClick: (): boolean => this.onMemberSelected(member)
                };
            });
        return memberItems;
    }

    /**
     * Returns the aria label associated with a given member.
     * @param {IMemberEntity} member - member to fetch the aria label of
     * @returns {string} the aria label associated with the given member
     */
    private getAriaLabelForMember(member: IMemberEntity): string {
        return this.getMemberAvailabilities(member).ariaLabel;
    }

    /**
     * Return the availability of a given member.
     * @param {IMemberEntity} member - member to fetch the availability of
     * @returns {IMemberAvailabilityForDisplayEntity} availabilities of member
     */
    private getMemberAvailabilities(member: IMemberEntity): IMemberAvailabilityForDisplayEntity {
        if (!this._memberAvailabilites.has(member.id)) {
            this.computeAssignOpenShiftAvailabilities(member);
        }
        return this._memberAvailabilites.get(member.id);
    }

    /**
     * Returns a context menu item that renders a search box for shifts
     */
    private getUniqueShiftsSearchBoxItem(): IContextualMenuItem {
        const searchBoxLabel: string = this._shiftsStrings.get("searchForShifts");

        return {
            key: "shiftsSearchBox",
            onRender: (item: any) => this.renderSearchBox(searchBoxLabel),
            role: "textbox",
            ariaLabel: searchBoxLabel
        };
    }

    /**
     * Renders a SearchBox Fabric component, applying the given label
     * @param searchBoxLabel
     */
    private renderSearchBox(searchBoxLabel: string) {
        return (
                <SearchBox
                    key={ "searchBox" }
                    defaultValue={ this._searchBoxInitialValue }
                    placeholder={ searchBoxLabel }
                    ariaLabel={ searchBoxLabel }
                    onChange={ this.handleSearchStringChanged }
                    onSearch={ this.updateSearchString }
                    onClear={ this.updateSearchString }
                    onEscape={ this.onDismissCallback }
                    componentRef={ this._searchBox } />
        );
    }

    /**
     * Returns an edit/add string for shifts. Varies based on if the shift is open or assigned.
     */
    private getAddEditShiftLabel(isEdit: boolean): string {
        const formatString = isEdit ? this._commonStrings.get("editFormatString") : this._commonStrings.get("addFormatString");
        const argumentString = this.props.isOpenShift ? this._commonStrings.get("openShiftTitleLowerCase") : this._commonStrings.get("shiftTitleLowerCase");
        return formatString.format(argumentString);
    }

    /**
     * Handler for when the context menu is opened
     * @param contextualMenu
     */
    private handleMenuOpened = (contextualMenu?: IContextualMenuProps): void => {
        // Set initial focus on the shifts searchbox
        setTimeout(() => {
            if (this._searchBox && this._searchBox.current) {
                this._searchBox.current.focus();
            }
        }, 0); // release thread and allow DOM to update, then ensure focus is set on the search box
    }

    /**
     * Returns true if the menu should render the assign open shift item
     */
    private shouldRenderAssignOpenShiftItem(): boolean {
        return this.isOpenShiftsEnabled() && this.props.shift && this.props.isOpenShift && ShiftUtils.isActiveWorkingShift(this.props.shift); // show only if non-deleted openShift;
    }

    /**
     * Returns true only if shift is openshift and has non zero slots and has members to assign open shift to
     */
    private shouldEnableAssignOpenShiftItem(): boolean {
        return this.props.shift && this.props.isOpenShift && ShiftUtils.isActiveWorkingShift(this.props.shift) &&  // enable only if non-deleted openShift
                (this.getMemberItems().length > 0) &&           // enable only if there are members in the group
                ((this.props.shift as IOpenShiftEntity).openSlots > 0); // enable only if there are openSlots in the group
    }

    /**
     * Returns true if the menu should render the move to open shifts item
     */
    private shouldRenderMoveToOpenShiftsItem(): boolean {
        const openShiftSettingEnabled = !TeamStore()?.team?.hideOpenShifts;
        const { shift } = this.props;

        if (!openShiftSettingEnabled || !shift) {
            return false;
        }

        const openShifts = DataProcessingHelpers.getArrayFromMap(OpenShiftStore().openShifts);
        let openSlots = 0;
        for (const openshift of openShifts) {
            if ((DateUtils.isSameMoment(shift.startTime, openshift.startTime) &&
            DateUtils.isSameMoment(shift.endTime, openshift.endTime))) {
                openSlots = openshift.openSlots;
                break;
            }
        }

        return Boolean(this.isOpenShiftsEnabled() && ShiftUtils.isActiveWorkingShift(this.props.shift) &&
                        !this.props.isOpenShift && this.props.scheduleCellActionCallbacks.cellMoveToOpenShiftsCallback && openSlots < 255);
    }

    /**
     * Returns true if the menu should include an add timeoff item. This is false for menus modifying open shift cells.
    */
    private shouldRenderAddTimeOffItem(): boolean {
        return !this.props.isOpenShift;
    }

    /**
     * Returns true if the share item should be rendered
     */
    private shouldRenderShareItem(): boolean {
        if (!this.props.shift || this.props.disableShare) {
            return false;
        }
        return ShiftUtils.shiftHasUnsharedEdits(this.props.shift);
    }

    /**
     * Returns true if we should render the delete item
     */
    private shouldRenderDeleteItem(): boolean {
        return this.props.scheduleCellActionCallbacks &&
            this.props.scheduleCellActionCallbacks.cellDeleteCallback &&
            this.props.shift &&
            !this.isDeletedShift();
    }

    /**
     * Returns true if the member search box and list should be rendered
     */
    private shouldRenderMembersAndSearchBox(): boolean {
        return this._isAssignOpenShiftMode;
    }

    /**
     * Returns true if the edit shift option should be rendered
     */
    private shouldRenderEditShiftItem(): boolean {
        return this.props.shift && !this.isDeletedShift();
    }

    /**
     * Returns true if the menu is opening on a deleted shift
     */
    private isDeletedShift(): boolean {
        return this.props.shift && ShiftUtils.isDeletedShift(this.props.shift);
    }

    private handleSearchStringChanged = (event?: React.ChangeEvent<HTMLInputElement>, newValue?: string) => {
        this.updateSearchString(newValue);
    }

    private updateSearchString = action("updateSearchString")((newValue?: string) => {
        this._searchString = newValue || '';
        this.forceUpdate();
    });

    private onDismissCallback = () => {
        if (this.props.onDismissCallback) {
            this.props.onDismissCallback();
        }
    }

    /**
     * Called Onclicking the add shift full page option in the contextual menu
     */
    private onAddShiftFullPageClicked = (ev?: React.MouseEvent<HTMLElement>, item?: IContextualMenuItem) => {
        const { shift, scheduleCalendarType, member, groupTagId, isOpenShift } = this.props;
        this.logContextMenuAction(InstrumentationService.values.AddShift);
        // For uncommitted deleted shifts we render an Add item, but when the panel launches it should be in edit mode
        const isEditShift: boolean = shift ? ShiftUtils.isUnsharedDeletedShift(shift) : false;
        const startDate: Moment = isEditShift ? null : this._startOfDate;
        InstrumentationUtils.logAddEditShiftPanelLaunch(scheduleCalendarType, true /* isNewEvent */, false /* alreadyPublished */, InstrumentationService.values.LookupMenu, isOpenShift);
        const shiftEditorData: IShiftEditorData = {
                                                    shift: isEditShift ? shift : null, /* shift */
                                                    openShift: isEditShift ? shift : null, /* openShift */
                                                    timeoff: isEditShift ? shift : null /* timeoff */
                                                  };

        // you need to be able to add a shift and time with the same panel, and with the same shift object, so passing the same for both shift and timeoff value incase users decides to change the panel type later
        launchShiftEditor(shiftEditorData /* shiftEditorData */, ShiftTypes.Working /* selectedPanelType */, member /* member */, groupTagId /* groupId to be assigned to */, scheduleCalendarType /* calendarType to be edited */, isEditShift /* isEdit Shift*/, isEditShift /* isEdit timeoff*/, shiftEditorViewState() /* viewstate */, startDate /*startDate*/, isOpenShift /* isOpenShift */);
    }

    /**
     * Called Onclicking the add time off full page option in the contextual menu
     */
    private onAddTimeOffFullPageClicked = (ev?: React.MouseEvent<HTMLElement>, item?: IContextualMenuItem) => {
        const { shift, scheduleCalendarType, member, groupTagId, isOpenShift } = this.props;
        this.logContextMenuAction(InstrumentationService.values.AddTimeOff);
        // For uncommitted deleted shifts the add of a time off should actually update the deleted shift
        const isEditTimeOff: boolean = shift ? ShiftUtils.isUnsharedDeletedShift(shift) : false;
        const startDate: Moment = isEditTimeOff ? null : this._startOfDate;
        // you need to be able to add a shift and time with the same panel, and with the same shift object, so passing the same for both shift and timeoff value incase users decides to change the panel type later
        const shiftEditorData: IShiftEditorData = {
                                                    shift: isEditTimeOff ? shift : null, /* shift */
                                                    openShift: isEditTimeOff ? shift : null, /* openShift */
                                                    timeoff: isEditTimeOff ? shift : null /* timeoff */
                                                  };

        launchShiftEditor(shiftEditorData /* shiftEditorData */, ShiftTypes.Absence /* selectedPanelType */, member /* member */, groupTagId /* groupId to be assigned to */, scheduleCalendarType /* calendarType to be edited */, isEditTimeOff /* isEdit Shift*/, isEditTimeOff /* isEdit timeoff */, shiftEditorViewState() /* viewstate */, startDate /*startDate*/, isOpenShift /* isOpenShift */);
    }

    /**
     * Called Onclicking the edit shift full page option in the contextual menu
     */
    private onEditShiftFullPageClicked = (ev?: React.MouseEvent<HTMLElement>, item?: IContextualMenuItem) => {
        const { shift, scheduleCalendarType, member, groupTagId, isOpenShift } = this.props;
        this.logContextMenuAction(InstrumentationService.values.EditShift);
        const shiftEditorData: IShiftEditorData = {
                                                    shift: shift, /* shift */
                                                    openShift: shift, /* openShift */
                                                    timeoff: null /* timeoff */
                                                  };

        launchShiftEditor(shiftEditorData /* shiftEditorData */, ShiftTypes.Working /* selectedPanelType */, member /* member */, groupTagId /* groupId to be assigned to */, scheduleCalendarType /* calendarType to be edited */, true /* isEdit Shift*/, false /* isEdit time off */, shiftEditorViewState() /* viewstate */, null /*startDate*/, isOpenShift /* isOpenShift */);
    }

    /**
     * Called Onclicking the edit time off full page option in the contextual menu
     */
    private onEditTimeOffFullPageClicked = (ev?: React.MouseEvent<HTMLElement>, item?: IContextualMenuItem) => {
        const { shift, scheduleCalendarType, member, groupTagId, isOpenShift } = this.props;
        this.logContextMenuAction(InstrumentationService.values.EditTimeOff);
        InstrumentationUtils.logAddEditShiftPanelLaunch(this.props.scheduleCalendarType, false /* isNewEvent */, this.props.shift.isPublished /* alreadyPublished */, InstrumentationService.values.LookupMenu, this.props.isOpenShift);
        const shiftEditorData: IShiftEditorData = {
                                                    shift: null, /* shift */
                                                    openShift: null, /* openShift */
                                                    timeoff: shift /* timeoff */
                                                  };

        launchShiftEditor(shiftEditorData /* shiftEditorData */, ShiftTypes.Absence /* selectedPanelType */, member /* member */, groupTagId /* groupId to be assigned to */, scheduleCalendarType /* calendarType to be edited */, false /* isEdit shift */, true /* isEdit Time Off*/, shiftEditorViewState() /* viewstate */, null /*startDate*/, isOpenShift /* isOpenShift */);
    }

    /**
     * Callback fired when share is clicked. Updates the shift with isPublished = true
     */
    private onShareClicked = (ev?: React.MouseEvent<HTMLElement>, item?: IContextualMenuItem) => {
        const { shift, scheduleCalendarType } = this.props;

        if (this.props.scheduleCellActionCallbacks.cellShareCallback) {
            const eventProperties = [
                getGenericEventPropertiesObject(InstrumentationService.properties.CurrentView, InstrumentationUtils.getCurrentViewForInstrumentation(scheduleCalendarType)),
                getGenericEventPropertiesObject(InstrumentationService.properties.EntryPoint, InstrumentationService.values.ContextMenu),
                getGenericEventPropertiesObject(InstrumentationService.properties.ShiftType, ShiftUtils.isWorkingShift(shift) ? InstrumentationService.values.ShiftTypeWorking : InstrumentationService.values.ShiftTypeAbsence),
                getGenericEventPropertiesObject(InstrumentationService.properties.Color, shift.theme),
                getGenericEventPropertiesObject(InstrumentationService.properties.IsOpenShift, ShiftUtils.isOpenShift(shift)),
                getGenericEventPropertiesObject(InstrumentationService.properties.Start, shift.startTime.format("HH:mm")),
                getGenericEventPropertiesObject(InstrumentationService.properties.End, shift.endTime.format("HH:mm")),
                getGenericEventPropertiesObject(InstrumentationService.properties.Unpaid, ShiftUtils.getDurationInHours(shift) - ShiftUtils.getPaidHoursForShift(shift)),
                getGenericEventPropertiesObject(InstrumentationService.properties.Paid, ShiftUtils.getPaidHoursForShift(shift)),
                getGenericEventPropertiesObject(InstrumentationService.properties.IsNewEvent, false),
                getGenericEventPropertiesObject(InstrumentationService.properties.AlreadyPublished, shift.isPublished),
                getGenericEventPropertiesObject(InstrumentationService.properties.NoteLength, shift.notes ? shift.notes.length : 0),
                getGenericEventPropertiesObject(InstrumentationService.properties.BreakAdded, !!ShiftUtils.getUnPaidShiftBreakDurationInShift(shift))
            ];
            InstrumentationService.logEvent(InstrumentationService.events.Publish_ShareShift, eventProperties);
            this.props.scheduleCellActionCallbacks.cellShareCallback(this.props.shift);
        }
    }

    private onCopyClicked = (ev?: React.MouseEvent<HTMLElement>, item?: IContextualMenuItem) => {
        if (this.props.scheduleCellActionCallbacks.cellCopyCallback) {
            this.logContextMenuAction(InstrumentationService.values.Copy);
            this.props.scheduleCellActionCallbacks.cellCopyCallback();
        }
    }

    private onPasteClicked = (ev?: React.MouseEvent<HTMLElement>, item?: IContextualMenuItem) => {
        if (this.props.scheduleCellActionCallbacks.cellPasteCallback) {
            this.logContextMenuAction(InstrumentationService.values.Paste);
            this.props.scheduleCellActionCallbacks.cellPasteCallback();
        }
    }

    onDeleteClicked = (ev?: React.MouseEvent<HTMLElement>, item?: IContextualMenuItem) => {
        if (ShiftUtils.isActiveWorkingShift(this.props.shift)) {
            fireAccessibilityAlert(this._shiftEditorStrings.get("accessibilityShiftDeletedAlert"));
        } else {
            fireAccessibilityAlert(this._shiftEditorStrings.get("accessibilityTimeoffDeletedAlert"));
        }

        if (this.props.scheduleCellActionCallbacks.cellDeleteCallback) {
            this.logContextMenuAction(InstrumentationService.values.Delete);
            this.props.scheduleCellActionCallbacks.cellDeleteCallback();
        }
    }

    /**
     * Clears the search string, sets the mode of the context menu to assignOpenShiftMode, and
     * ensures that menu opening handling occurs after the thread is released and the DOM updates
     * @param ev
     * @param item
     */
    private onAssignOpenShiftClicked = (ev?: React.MouseEvent<HTMLElement>, item?: IContextualMenuItem) => {
        this.logContextMenuAction(InstrumentationService.values.AssignPeople);
        this.updateSearchString("");
        this.setAssignOpenShiftMode(true);
        setTimeout(this.handleMenuOpened, 0); // release thread and allow DOM to update, then ensure focus is set on the search box
        ev.preventDefault(); // ensure contextual menu does not close itself
    }

    /**
     * Handler for when the move to open shifts menu item is clicked
     * @param ev
     * @param item
     */
    private onMoveToOpenShiftsClicked = (ev?: React.MouseEvent<HTMLElement>, item?: IContextualMenuItem) => {
        if (this.shouldRenderMoveToOpenShiftsItem()) {
            this.logContextMenuAction(InstrumentationService.values.MoveToOpenShifts);
            this.props.scheduleCellActionCallbacks.cellMoveToOpenShiftsCallback(this.props.shift, this.props.member, this.props.date, this.props.groupTagId);
            fireAccessibilityAlert(this._shiftsStrings.get("moveToOpenShiftsAccessibilityAlert"));
        }
    }

    private setAssignOpenShiftMode = action("setAssignOpenShiftMode")((isAssignMode: boolean) => {
        this._isAssignOpenShiftMode = isAssignMode;
    });

    onUniqueShiftSelected = (selectedUniqueShift: IUniqueShiftEntity, uniqueShiftEntryIndex: number) => {
        if (this.props.scheduleCellActionCallbacks.applyUniqueShiftCallback && selectedUniqueShift) {
            // Unique shift selection action name -> 'IndexZ', where 'Z' is 1-based index for the unique shift entry that was selected
            const contextMenuActionName: string = `${InstrumentationService.values.SelectIndex}${uniqueShiftEntryIndex + 1}`;
            this.logContextMenuAction(contextMenuActionName);
            const tenantId: string = this.props.member ? this.props.member.tenantId : TeamStore().tenantId;
            const teamId: string = this.props.member ? this.props.member.teamId : TeamStore().teamId;
            this.props.scheduleCellActionCallbacks.applyUniqueShiftCallback(
                selectedUniqueShift,
                tenantId,
                teamId
            );
        }

        this.onDismissCallback();
    }

    private onUniqueShiftDeleted = (uniqueShift: IUniqueShiftEntity, uniqueShiftEntryIndex: number) => {
        const tenantId: string = this.props.member ? this.props.member.tenantId : TeamStore().tenantId;
        const teamId: string = this.props.member ? this.props.member.teamId : TeamStore().teamId;
        deleteUniqueShift(tenantId, teamId, uniqueShift);
        const contextMenuActionName: string = `${InstrumentationService.values.DeleteIndex}${uniqueShiftEntryIndex + 1}`;
        this.logContextMenuAction(contextMenuActionName);
    }

    /**
     * Resets the hours, minutes and seconds to midnight.
     * @param date the input date
     */
    private resetToStartOfDay(date: Moment) {
        return date.clone().startOf('day');
    }

    // Renders the shifts matching the search string.
    // Renders the latest shifts if the search string is empty.
    private renderShifts(): JSX.Element[] {
        let uniqueShiftElementsToShow = [];
        if (UniqueShiftStore && UniqueShiftStore().uniqueShifts && UniqueShiftStore().uniqueShifts.length > 0) {
            let uniqueShiftEntryIndex: number = 0;
            for (let i = 0; i < UniqueShiftStore().uniqueShifts.length && uniqueShiftElementsToShow.length < maxNumberOfShiftsToShow; i++ ) {
                let uniqueShift = UniqueShiftStore().uniqueShifts[i];
                if (this.uniqueShiftMatchesSearchString(uniqueShift)) {
                    uniqueShiftElementsToShow.push(this.renderUniqueShift(uniqueShift, uniqueShiftEntryIndex));
                    uniqueShiftEntryIndex++;
                }
            }
        }

        if (uniqueShiftElementsToShow.length > 0) {
            if (this._searchString !== "" && (this._searchString !== this._lastSearchString)) {
                this._lastSearchString = this._searchString;    // we use this to make sure we don't repeat the accessibility alert
                fireAccessibilityAlert(uniqueShiftElementsToShow.length === 1
                    ? this._shiftsStrings.get("shiftFoundSingle")
                    : this._shiftsStrings.get("shiftFoundPlural").format(String(uniqueShiftElementsToShow.length)));
            }

            return uniqueShiftElementsToShow;
        } else {
            return this.renderNoShiftsFound();
        }
    }

    private onMemberSelected = (member: IMemberEntity): boolean => {
        const { shift, scheduleCellActionCallbacks } = this.props;
        if (scheduleCellActionCallbacks.cellAssignOpenShiftCallback) {
            scheduleCellActionCallbacks.cellAssignOpenShiftCallback(shift as IOpenShiftEntity, member);
            this.onDismissCallback();
        }

        // return true to close the menu
        return true;
    }

    /**
     * returns true if the uniqueShift matches the _searchString
     * @param uniqueShift shift to check search against
     */
    private uniqueShiftMatchesSearchString(uniqueShift: IUniqueShiftEntity): boolean {
        let searchStringNormalized = this._searchString.replace(/\s/g, "");
        if (searchStringNormalized && searchStringNormalized.length > 0) {
                // match the time string with search string.
                // In future, if we need to search based on other shift attributes, this is the place which can be updated
                let shiftTitleNormalized = uniqueShift.title || DateTimeFormatter.getEventTimeRangeAsString(uniqueShift.startTime, uniqueShift.endTime);
                shiftTitleNormalized = shiftTitleNormalized.replace(/\s/g, "");
                // checking if time string starts with search string
                if (shiftTitleNormalized && shiftTitleNormalized.toLowerCase().indexOf(searchStringNormalized.toLowerCase()) === 0) {
                    return true;
                }
        } else {
            // If the search string is empty, this shift matches
            return true;
        }
    }

    /**
     * Returns true if the member is a match for the current search string. If the search string is
     * all whitespace or an empty string, the member counts as a match.
     * @param userQuery
     * @param memberId
     * @param searchString
     */
    private memberMatchesSearchString = (userQuery: IUserQuery, member: IMemberEntity, searchString: string): boolean => {
        let matches = true;
        if (member) {
            matches = MemberUtils.filterMember(member, userQuery.firstNameStartsWith, userQuery.lastNameStartsWith, userQuery.emailStartsWith, userQuery.operatorType, searchString);
        }

        return matches;
    }

    /**
     * Renders the no shifts found experience.
     */
    private renderNoShiftsFound() {
        return [
            <div key={ "noShiftsFoundKey" } aria-label={ this._shiftsStrings.get("noShiftsFound") } className={ styles.noShiftsFound }>
                <Announced role="alert" id="nonShiftsFoundAnnounce" message={ this._shiftsStrings.get("noShiftsFound") }/>
                { this._shiftsStrings.get("noShiftsFound") }
            </div>
        ];
    }

    /**
     * Renders the no members found experience.
     */
    private renderNoMembersFound() {
        return <div data-is-focusable={ true } className={ styles.noMembersFound }> { this._shiftsStrings.get("noMembersFound") } </div>;
    }

    /**
     * Renders a unique shift entry for the context menu
     * @param uniqueShift
     * @param uniqueShiftEntryIndex index for the unique shift entry in the context menu
     */
    private renderUniqueShift(uniqueShift: IUniqueShiftEntity, uniqueShiftEntryIndex: number) {
        const ariaProps: AriaProperties = {
            role: AriaRoles.button,
            label: this._shiftsStrings.get("selectShift").format(ShiftUtils.calculateUniqueShiftTitleAriaLabel(uniqueShift))
        };
        return (<UniqueShift
                    key={ uniqueShift.id }
                    uniqueShift={ uniqueShift }
                    entryIndex={ uniqueShiftEntryIndex }
                    onSelectUniqueShiftCallback={ this.onUniqueShiftSelected }
                    ariaProps={ ariaProps }
                    onRemoveShiftCallback={ this.onUniqueShiftDeleted } />);
    }

    /**
 * Returns true if the unique shift list is currently being filtered
     */
    private isUniqueShiftListFiltered(): boolean {
        return this._searchString.length > 0;
    }

    /**
     * Called when the component is unmounting, and focus needs to be restored.
     * If this is provided, focus will not be restored automatically, and you'll need to call
     * params.originalElement.focus().
     * @param params IPopupRestoreFocusParams
     */
    private onRestoreFocus(params: IPopupRestoreFocusParams): void {
        const { originalElement } = params;
        if (document.activeElement.isEqualNode(originalElement as Node)) {
            return;
        }

        originalElement.focus();
    }

    /**
     * Fire an instrumentation event when a context menu action is initiated
     * @param contextMenuActionType context menu instrumentation action name
     */
    private logContextMenuAction(contextMenuActionType: string): void {
        InstrumentationService.logEvent(this.props.isShiftLookup ? InstrumentationService.events.ShiftLookupAction : InstrumentationService.events.ContextMenuItemSelected,
            [getGenericEventPropertiesObject(InstrumentationService.properties.CurrentView, this.props.scheduleCalendarType),
             getGenericEventPropertiesObject(InstrumentationService.properties.IsFilteredItemSelected, this.isUniqueShiftListFiltered()),
             getGenericEventPropertiesObject(InstrumentationService.properties.IsOpenShift, this.props.isOpenShift),
             getGenericEventPropertiesObject(InstrumentationService.properties.ActionType, contextMenuActionType),
             getGenericEventPropertiesObject(InstrumentationService.properties.CellType, InstrumentationUtils.getInstrumentationCellType(this.props.shift))]);
    }

    render() {
        fireAccessibilityAlert(this._commonStrings.get("contextMenuAriaLabel"));
        return <ContextualMenu
                    className={ classNames(styles.contextualMenu, { [styles.hasFixedSearchBox]: (this._isAssignOpenShiftMode && !this.props.isShiftLookup)}, { [styles.isShiftLookup]: this.props.isShiftLookup }) }
                    shouldFocusOnMount            // Autofocus is needed to set focus on the first item when there are no suggested suggested shifts
                    onMenuOpened={ this.handleMenuOpened }
                    onRenderMenuList={ this.shouldRenderMembersAndSearchBox() ? this.onRenderSearchBar : undefined }
                    calloutProps={
                        {
                            className: SHIFT_CELL_CONTEXT_MENU_CALLOUT
                        }
                    }
                    styles={ { list: styles.contextualMenuList } }
                    onDismiss={ this.onDismissCallback }
                    onRestoreFocus={this.onRestoreFocus}
                    target={ this.props.target }
                    gapSpace={ defaultGapSpace }
                    directionalHint={ DirectionalHint.bottomRightEdge }
                    items={ this.getMenuItems() } />;
    }
}