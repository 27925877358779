import MemberUtils from "sh-application/utility/MemberUtils";
import { ECSConfigKey, ECSConfigService } from "sh-services";
import { TeamStore } from "sh-team-store";

/**
 * Checks whether conflicts feature is enabled.
 * @returns Whether conflicts feature is enabled.
 */
export const isConflictsFeatureEnabled = (): boolean => {
    const isShiftRequestConflictsEnabled = ECSConfigService.isECSFeatureEnabled(ECSConfigKey.EnableShiftRequestConflicts);
    const isConflictManagementEnabled = ECSConfigService.isECSFeatureEnabled(ECSConfigKey.EnableConflictManagement);
    const isCurrentUserAdmin = MemberUtils.isAdmin(TeamStore()?.me);

    return isShiftRequestConflictsEnabled && isConflictManagementEnabled && isCurrentUserAdmin;
};
