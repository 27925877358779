import { IImportJobEntity } from "./IImportJobEntity";
import { IImportJobResult } from "./IImportJobResult";
import { JobStatus } from "./IJobEntity";
import { ImportJobResult } from "./ImportJobResult";

/**
 * Entity class for ImportJobEntity.
 */
export class ImportJobEntity implements IImportJobEntity {
    jobId: string;
    result: IImportJobResult;
    status: JobStatus;
    userId: string;

    /**
     * Constructor with parameters.
     * @param jobId A unique jobId is assigned to each request which will help to get status of submitted job.
     * @param result Result of the job.
     * @param status Current status of submitted job.
     * @param userId Id of the user who triggered the import.
     */
    constructor(jobId: string, result: IImportJobResult, status: JobStatus, userId: string) {
        this.jobId = jobId;
        this.result = ImportJobResult.fromJson(result);
        this.status = status;
        this.userId = userId;
    }

    /**
     * Convert json data to ImportJobEntity object.
     * @param jsonData Json data to be converted to ImportJobEntity object.
     * @returns ImportJobEntity object.
     */
    public static fromJson(jsonData: IImportJobEntity): ImportJobEntity {
        if (!jsonData) {
            return null;
        }

        return new ImportJobEntity(jsonData.jobId, jsonData.result, jsonData.status, jsonData.userId);
    }
}