
import { setTheme } from "@microsoft/shifts-experiences-shared";
import { getTeamsThemeType } from "@microsoft/shifts-ui-core";
import * as microsoftTeams from "@microsoft/teams-js";

import { setThemeName } from "./store";

/**
 * Initialize the theme.
 * @param theme The theme identifier.
 */
export function initializeThemes(theme: string): void {
    document.documentElement.className = `shifts_theme_${theme}`;
    setThemeName(theme);
    setTheme(getTeamsThemeType(theme));

    // TODO: Update TeamsHostContainer to register this theme handler in "initialize" method.
    microsoftTeams.registerOnThemeChangeHandler((teamsThemeName: string) => {
        document.documentElement.className = `shifts_theme_${teamsThemeName}`;
        setThemeName(teamsThemeName);
        setTheme(getTeamsThemeType(teamsThemeName));
    });
}
