// Require the polyfill before requiring or importing any other modules.
require("intersection-observer");

import * as React from "react";
import VirtualListPage, { PageConfig } from "./VirtualListPage";

export interface VirtualListState {
    pageConfigs: Array<PageConfig>; // array of pages - managed internally
    numItems: number; // total number of items contained in all the pages (not number of items per page)
    intersectionObserver?: IntersectionObserver; // instance of IntersectionObserver
    lastUpdate: number; // time stamp of when the last time we updated the virtual list state
}

type getRowHeightFunc = (item: any, index: number) => number;

const DEFAULT_ROWS_PER_PAGE = 10;

export interface VirtualListProps {
    /**
     * Array of items to be rendered as list rows. Items will be sliced into pages depending
     * on the 'rowsPerPage' property.
     */
    items: Array<any>;
    /**
     * The number of rows per page.
     */
    rowsPerPage: number;
    /**
     * A fixed row height (number) or null which means you must supply a callback for onGetRowHeight
     * that returns the height for each row.
     */
    defaultRowHeight: number | null;
    /**
     * Callback that returns the height of the row or null which means you must supply a defaultRowHeight
     */
    onGetRowHeight: getRowHeightFunc | null;
    /**
     * callback to render a row
     */
    onRenderRow: (index: number) => React.ReactNode;
    /**
     * time stamp of the last time the VirtualList was rendered. Used along with the lastUpdate state to optimize
     * recalcs of the pageConfigs (i.e. we don't want to keep recalculating the list if the user is just scrolling
     * and nothing else is changing)
     */
    lastRender: number;
    /**
     * Either a single number or an array of numbers which indicate at what percentage of the
     * target's visibility the observer's callback should be executed. If you only want to detect
     * when visibility passes the 50% mark, you can use a value of 0.5. If you want the callback
     * run every time visibility passes another 25%, you would specify the array [0, 0.25, 0.5, 0.75, 1].
     * The default is 0 (meaning as soon as even one pixel is visible, the callback will be run).
     * A value of 1.0 means that the threshold isn't considered passed until every pixel is visible.
     */
    threshold?: number | number[];
    /**
     * root property identifies the Element whose bounds are treated as the bounding box of the
     * viewport for the element which is the observer's target. If the root is null, then the
     * bounds of the actual document viewport are used.
     */
    root?: Element | null;
    /**
     * rootMargin property is a string with syntax similar to that of the CSS margin property.
     * Each side of the rectangle represented by rootMargin is added to the corresponding side
     * in the root element's bounding box before the intersection test is performed. This lets you,
     * for example, adjust the bounds outward so that the target element is considered 100% visible
     * even if a certain number of pixels worth of width or height is clipped away, or treat the
     * target as partially hidden if an edge is too close to the edge of the root's bounding box.
     * Defaults to 0 (no margin)
     */
    rootMargin?: string;
    /**
     * If true, this will force all items to render,
     * regardless if they are in the view or not. This can be used to force the rendering of
     * all items such as in the case of printing.
     */
    forceRender?: boolean;
}

/**
 * A virtualized list component that uses IntersectionObserver API to only render rows when
 * they intersect with the visible area (defaults to window viewport). Row items are sliced into
 * pages and the mounting/unmounting of page items is handled by the list. There is overhead for
 * each page so it is not recommended to create many small pages. However too many items within
 * a page can easily cause performance to drop because you could end up rendering many items into
 * the DOM.
 */
export default class VirtualList extends React.Component<VirtualListProps, VirtualListState> {
    constructor(props: VirtualListProps) {
        super(props);

        const { items, root, rootMargin, defaultRowHeight, onGetRowHeight, rowsPerPage, threshold, forceRender } = props;

        // initialize state
        this.state = {
            pageConfigs: VirtualList.initPages(items, rowsPerPage, defaultRowHeight, onGetRowHeight, !!forceRender), // slice the items into pages
            numItems: items.length, // store the number of items so we can tell if items were added/removed
            lastUpdate: props.lastRender, // use the lastRender as our lastUpdate state because the pageConfigs are updated in initPages()
            intersectionObserver: new IntersectionObserver(this.handleIntersection, { // create the Intersection Observer instance
                root: root || null,
                rootMargin: rootMargin || "0% 0%",
                threshold: threshold || 0
            })
        };
    }

    static getDerivedStateFromProps(nextProps: VirtualListProps, prevState: VirtualListState) {
        // we only need to recalculate the virtual list state if the number of items have changed, or the
        // list has been re-rendered
        if (nextProps.items.length !== prevState.numItems || nextProps.lastRender !== prevState.lastUpdate) {
            // the number of virtual list items has changed
            return VirtualList.updatePages(
                nextProps.items,
                nextProps.rowsPerPage,
                nextProps.defaultRowHeight,
                nextProps.onGetRowHeight,
                nextProps.lastRender,
                !!nextProps.forceRender,
                prevState.pageConfigs,
                prevState.intersectionObserver
            );
        }

        // no changes, just return null as the React 16 spec calls for
        return null;
    }

    /**
     * (static) Calculates the height of a page given the items and rowHeight (either fixed number or
     * callback that returns the height for the row)
     *
     * @param pageItems array of items in the page
     * @param defaultRowHeight fixed row height (in px) or null (if null, onGetRowHeight callback must be provided)
     * @param onGetRowHeight callback that returns the row height or null (if null, defaultRowHeight must be provided)
     */
    private static calculatePageHeight(
        pageItems: Array<any>,
        defaultRowHeight: number | null,
        onGetRowHeight: getRowHeightFunc | null
    ): number {
        let pageHeight = 0;

        // calculate the page height (in px) - for dynamic height rows, rowHeight prop should be passed
        // in as a function to fetch the row height for the given item
        if (defaultRowHeight) {
            // for fix row heights, just multiple the height by number of rows
            pageHeight = pageItems.length * defaultRowHeight;
        } else if (onGetRowHeight) {
            // for dynamic row heights, loop through each of the items in the page and call the rowHeight callback to get the height
            for (let pageItemIndex = 0; pageItemIndex < pageItems.length; pageItemIndex++) {
                pageHeight += onGetRowHeight(pageItems[pageItemIndex], pageItemIndex);
            }
        }

        return pageHeight;
    }

    /**
     * (static) Updates existing pages with item changes and recalculates the pages heights. Adds new pages
     * as needed and removes pages that are no longer needed (including tells the IntersectionObserver to
     * stop observing those removed elements).
     *
     * @param items array of items (rows) in the list
     * @param rowsPerPage number of requested rows (max) to be rendered in each page
     * @param defaultRowHeight fixed row height (in px) or null (if null, onGetRowHeight callback must be provided)
     * @param onGetRowHeight callback that returns the row height or null (if null, defaultRowHeight must be provided)
     * @param lastRender time stamp of the last time the list was rendered
     * @param forceRender true if new pages should force render
     * @param pageConfigs array of existing page configs
     * @param intersectionObserver reference to IntersectionObserver instance
     */
    private static updatePages(
        items: Array<any>,
        rowsPerPage: number,
        defaultRowHeight: number | null,
        onGetRowHeight: getRowHeightFunc | null,
        lastRender: number,
        forceRender: boolean,
        pageConfigs: Array<PageConfig>,
        intersectionObserver: IntersectionObserver
    ): VirtualListState | null {
        const maxRowsPerPage = rowsPerPage || DEFAULT_ROWS_PER_PAGE;

        // how many pages should there be
        const totalPages = Math.ceil(items.length / maxRowsPerPage);

        let sentinelPosition = 0;
        for (let pageCursor = 0, existingPageIndex = 0; pageCursor < items.length; pageCursor += maxRowsPerPage, existingPageIndex++) {
            let pageConfig = null;
            if (existingPageIndex < pageConfigs.length) {
                // get the existing page object
                pageConfig = pageConfigs[existingPageIndex];
            }

            // get the array of items that should be in this page
            const pageItems = items.slice(pageCursor, pageCursor + maxRowsPerPage);

            // calculate the page height
            const pageHeight = VirtualList.calculatePageHeight(pageItems, defaultRowHeight, onGetRowHeight);

            if (!pageConfig) {
                // no existing page so push a new one
                pageConfigs.push({
                    intersected: forceRender,
                    height: pageHeight,
                    items: pageItems,
                    sentinelElement: null,
                    sentinelPosition: sentinelPosition
                });
            } else {
                // modify the existing page height and items
                pageConfig.height = pageHeight;
                pageConfig.items = pageItems;
                pageConfig.sentinelPosition = sentinelPosition;
            }

            sentinelPosition += pageHeight;
        }

        // totalPages is the calculated number of pages we should have after this update. If our current
        // number of pages (pages.length) is greater than the total calculated pages, it means there are
        // pages that need to be removed.
        // Everything in the list is maintained (and calculated) in the order of the items that was passed in.
        // If the list is initially rendered with 100 items with 10 items per page, there will be 10 pages of
        // with 10 items each. If after an update there is only 75 items, then the new page total will be 8.
        // 7 of the pages will contain the full 10 items and the 8th page will only have 5. Because the page
        // count went down, those last 2 original 10 pages would be removed here.
        if (pageConfigs.length > totalPages) {
            // pages have been removed
            for (let i = totalPages; i < pageConfigs.length; i++) {
                // stop observing the element of the pages that are going away
                const pageConfig = pageConfigs[i];
                if (intersectionObserver) {
                    if (pageConfig.sentinelElement) {
                        intersectionObserver.unobserve(pageConfig.sentinelElement);
                    }
                }
            }
            // get rid of the old (existing) pages that went away
            pageConfigs = pageConfigs.slice(0, totalPages);
        }

        // return the new state values
        return {
            pageConfigs: pageConfigs,
            numItems: items.length,
            lastUpdate: lastRender
        };
    }

    public componentWillUnmount() {
        const { intersectionObserver } = this.state;
        // disconnect from the IntersectionObserver
        if (intersectionObserver) {
            intersectionObserver.disconnect();
        }
    }

    // robv note - there seems to be a bug in tslint where it complains I don't have spaces
    // after the curly braces in this code. I tried updating our tslint and that didn't fix it.
    /* tslint:disable:react-tsx-curly-spacing */
    public render() {
        const { onRenderRow, rowsPerPage, forceRender } = this.props;
        const { pageConfigs } = this.state;

        const renderedItems: JSX.Element[] = [];

        for (let index = 0; index < pageConfigs.length; index++) {
            const page = pageConfigs[index];
            renderedItems.push(
                <VirtualListPage
                    forceRender={!!forceRender}
                    key={`VLP-${index}`}
                    index={index}
                    onRenderRow={onRenderRow}
                    onSetPageRef={this.onSetPageRef}
                    maxRowsPerPage={rowsPerPage}
                    {...page}
                />
            );
        }

        return renderedItems;
    }
    /* tslint:enable:react-tsx-curly-spacing */

    /**
     * callback to set or remove the DOM element reference from the IntersectionObserver for a given page.
     *
     * @param element reference to DOM element for the page at passed in index
     * @param index the page index into the pages array
     */
    private onSetPageRef = (element: Element, index: number) => {
        const { pageConfigs, intersectionObserver } = this.state;

        if (index > pageConfigs.length - 1) {
            // if the index is out of range (probably because the page was removed), do nothing
            return;
        }

        const pageConfig = pageConfigs[index];

        if (pageConfig) {
            if (element) {
                // begin observing the page element
                if (intersectionObserver) {
                    intersectionObserver.observe(element);
                }

                pageConfig.sentinelElement = element;
            } else {
                // stop observing the page element
                try {
                    if (intersectionObserver) {
                        intersectionObserver.unobserve(pageConfig.sentinelElement);
                    }
                } catch {
                    // Edge throws when unobserving disconnected elements...
                    // https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/12577586/
                }
            }
        }
    };

    /**
     * IntersectionObserver API callback. Called when an observed element enters or leaves the intersect point.
     *
     * @param entries A list of IntersectionObserverEntry objects, each representing one threshold which was crossed,
     * either becoming more or less visible than the percentage specified by that threshold.
     * @param observer isntance of IntersectionObserver for which the callback is being invoked.
     */
    private handleIntersection = (entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {
        const { pageConfigs } = this.state;

        for (let i = 0; i < entries.length; i++) {
            const entry = entries[i];
            let index = -1;
            try {
                index = parseInt(entry.target.getAttribute("data-key"));
            } catch {
                index = -1;
            }

            if (index < 0 || index >= pageConfigs.length) {
                // index out of range
                continue;
            }
            let pageConfig = pageConfigs[index];

            // should this page be visible or not
            pageConfig.intersected = entry.isIntersecting;
        }

        this.setState({ pageConfigs });
    };

    /**
     * Slices the list items into pages.
     *
     * @param items array of list items that will be rendered within the pages
     * @param rowsPerPage the max number of rows per page
     * @param defaultRowHeight fixed row height (in px) or null (if null, onGetRowHeight callback must be provided)
     * @param onGetRowHeight callback that returns the row height or null (if null, defaultRowHeight must be provided)
     */
    private static initPages(
        items: Array<any>,
        rowsPerPage: number,
        defaultRowHeight: number | null,
        onGetRowHeight: getRowHeightFunc | null,
        forceRender: boolean
    ): Array<PageConfig> {
        const maxRowsPerPage = rowsPerPage || DEFAULT_ROWS_PER_PAGE;
        const pagesConfigs: Array<PageConfig> = [];

        if (items) {
            let sentinelPosition = 0;
            for (let pageCursor = 0; pageCursor < items.length; pageCursor += maxRowsPerPage) {
                const pageItems = items.slice(pageCursor, pageCursor + maxRowsPerPage);

                // calculate the page height
                const pageHeight = VirtualList.calculatePageHeight(pageItems, defaultRowHeight, onGetRowHeight);

                pagesConfigs.push({
                    intersected: forceRender,
                    height: pageHeight,
                    items: pageItems,
                    sentinelElement: null,
                    sentinelPosition: sentinelPosition
                });

                sentinelPosition += pageHeight;
            }
        }
        return pagesConfigs;
    }
}
