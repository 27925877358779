import ConflictUtils from "sh-application/utility/ConflictUtils";
import {
    ConflictStore,
    decrementConflictCount,
    deleteConflictsInConflictStore,
    deleteShiftIdConflictStore,
    deleteShiftIdFromConflictList
    } from "sh-conflict-store";
import { IShiftConflictEntity, IAvailabilityConflictEntity } from "sh-models";
import { orchestrator } from "satcheljs";

/**
 * This orchestrator is deletes the conflict in conflict store
 * It removes the conflict entities in ConflictStore(),
 * as well as maintains the true conflict count.
 */
export default orchestrator(deleteConflictsInConflictStore, actionMessage => {
    const shift = actionMessage.shift;
    const conflictEntitiesList = ConflictStore().shiftIdToConflictsMap.get(shift.id);

    // delete any conflicts associated to this shift in ConflictStore
    if (conflictEntitiesList) {
        conflictEntitiesList.forEach((entityList: IShiftConflictEntity | IAvailabilityConflictEntity) => {

            // adjust conflict count appropriately - decrement as much as there are conflicts in the conflict map for this shift
            decrementConflictCount(shift.memberId, entityList.conflictType);

            if (<IShiftConflictEntity>entityList !== undefined) {
                const otherConflictingShiftId = ConflictUtils.getConflictinShiftIdForShift(<IShiftConflictEntity>entityList, shift.id);

                let otherConflictingShiftList = ConflictStore().shiftIdToConflictsMap.get(otherConflictingShiftId);

                if (otherConflictingShiftList && otherConflictingShiftList.length === 1) {
                    deleteShiftIdConflictStore(otherConflictingShiftId);
                } else if (otherConflictingShiftList) {
                    deleteShiftIdFromConflictList(otherConflictingShiftList, otherConflictingShiftId, shift.id);
                }
            }
        });
    }

    deleteShiftIdConflictStore(shift.id);
});