import { mutatorAction } from "satcheljs";
import { SchedulesViewStateStore } from "sh-application/components/schedules/lib/store/schema/SchedulesViewStateStore";

/**
 * Set the grouped/ungrouped state of the Schedules page.
 */
export default mutatorAction("SET_GROUPED_VIEW",
    function setGroupedView(viewState: SchedulesViewStateStore, isViewGrouped: boolean) {
        viewState.isViewGrouped = isViewGrouped;
    }
);