import { createStore } from "satcheljs";

export enum LandingPageMode {
    NotInitialized = "NotInitialized",
    ShowCreateNewSchedule = "ShowCreateNewSchedule",
    ShowScheduleTeamSwitcher = "ShowScheduleTeamSwitcher",
    ShowEmptyMessageNoTeams = "ShowEmptyScreenNoTeams",
    ShowEmptyMessageNoSchedulesForTeams = "ShowEmptyMessageNoSchedulesForTeams"
}

export interface LandingPageViewState {
    landingPageMode: LandingPageMode;
}

let initialLandingPageViewState: LandingPageViewState = {
    landingPageMode: LandingPageMode.NotInitialized
};

export let landingPageViewState = createStore<LandingPageViewState>("landingPageViewState", initialLandingPageViewState);