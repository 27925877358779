import * as mobx from "mobx";

export default class MobxUtils {

    /**
     * Returns a mutable Array of values from a mobx observable map. If the array does not need to
     * be manipulated, you should use MapToReadonlyArray instead.
     * @param {mobx.ObservableMap} map - Mobx ObservableMap
     * @returns {Array<any>}
     */
    public static MapToArray(map: mobx.ObservableMap): Array<any> {
        let returnArray: Array<any> = null;
        if (map) {
            returnArray = mobx.values(map).slice();
        } else {
            returnArray = new Array<any>();
        }

        return returnArray;
    }

    /**
     * Returns a read only Array of values from a mobx observable map.
     * @param {mobx.ObservableMap} map - Mobx ObservableMap
     * @returns {ReadonlyArray<any>}
     */
     public static MapToReadonlyArray<T = any>(map: mobx.ObservableMap): ReadonlyArray<T> {
        let returnArray: readonly T[] = null;
        if (map) {
            returnArray = mobx.values(map);
        } else {
            returnArray = [];
        }

        return returnArray;
    }

    /**
     * Returns a mutable Array of keys from a mobx observable map. If the array does not need to
     * be manipulated, you should use MapKeysToReadonlyArray instead.
     * @param {mobx.ObservableMap} map - Mobx ObservableMap
     * @returns {Array<any>}
     */
    public static MapKeysToArray(map: mobx.ObservableMap): Array<any> {
        let returnArray: Array<any> = null;
        if (map) {
            returnArray = mobx.keys(map).slice();
        } else {
            returnArray = new Array<any>();
        }

        return returnArray;
    }

    /**
     * Returns a read only Array of keys from a mobx observable map.
     * @param {mobx.ObservableMap} map - Mobx ObservableMap
     * @returns {ReadonlyArray<any>}
     */
    public static MapKeysToReadonlyArray(map: mobx.ObservableMap): ReadonlyArray<any> {
        let returnArray: readonly any[] = null;
        if (map) {
            returnArray = mobx.keys(map);
        } else {
            returnArray = [];
        }

        return returnArray;
    }
}
