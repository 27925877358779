import AppSettingsServiceModel from "sh-models/appsettings/AppSettingsServiceModel";
import RestClient from "sh-rest-client";
import { action } from "satcheljs/lib/legacy";
import { AppSettingsClientModel } from "sh-models";
import { AxiosResponse } from "axios";
import { default as setAppSettingsForTeam } from "./setAppSettingsForTeam";
import { InstrumentationService } from "sh-services";
import { trace } from "owa-trace";

/**
 * getAppSettingsForTeam action - gets application settings for a specific team
 *
 * @param {string} tenantId
 * @param {string} teamId
 * @returns {Promise}
 */
export let getAppSettingsForTeam = action("getAppSettingsForTeam")(
    async function getAppSettingsForTeam(tenantId: string, teamId: string): Promise<AppSettingsClientModel> {
        let marker = "getAppSettingsForTeam";
        marker = InstrumentationService.perfMarkerStart(marker);

        try {
            const response: AxiosResponse = await RestClient.getAppSettingsForTeam(tenantId, teamId);
            // Parse settings info from service response JSON
            const appSettingsServiceModel = AppSettingsServiceModel.fromJson(response.data.appSettings);
            // Convert to client model object
            const appSettingsClientModel = AppSettingsServiceModel.toClientModel(appSettingsServiceModel);

            setAppSettingsForTeam(appSettingsClientModel);

            return appSettingsClientModel;
        } catch (httpError) {
            trace.warn("getAppSettingsForTeam failed");
            throw httpError;
        } finally {
            InstrumentationService.perfMarkerEnd(marker);
        }
    });