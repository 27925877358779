import ITenantCustomFieldEntity from "./ITenantCustomFieldEntity";
import ShiftrObjectModelBase from "../ShiftrObjectModelBase";
import { TenantCustomFieldSelectionOption } from "sh-models";

/**
 * The entity for a custom field in a tenant.
 */
export default class TenantCustomFieldEntity extends ShiftrObjectModelBase implements ITenantCustomFieldEntity {
    id: string;
    name: string;
    description: string;
    type: string;
    options: TenantCustomFieldSelectionOption[];

    constructor(id: string, name: string, description: string, type: string, options: Array<TenantCustomFieldSelectionOption>) {
        // This does not have an eTag.
        super(id, "");

        this.name = name;
        this.description = description;
        this.type = type;
        this.options = options ? options.map(option => { return { id: option.id, name: option.name }; }) : [];
    }

    static fromJson(jsonData: any): ITenantCustomFieldEntity {
        return new TenantCustomFieldEntity(
            jsonData.id,
            jsonData.name,
            jsonData.description,
            jsonData.type,
            jsonData.options);
    }

    static toJson(teamCustomField: ITenantCustomFieldEntity): ITenantCustomFieldEntity {
        let optionsJson = teamCustomField.options ? teamCustomField.options.map(option => { return { id: option.id, name: option.name }; }) : [];
        const teamCustomFieldJson: ITenantCustomFieldEntity = {
            id: teamCustomField.id,
            eTag: "",
            name: teamCustomField.name,
            description: teamCustomField.description,
            type: teamCustomField.type,
            options: optionsJson
        };

        return teamCustomFieldJson;
    }

    static clone(tenantsetting: ITenantCustomFieldEntity): ITenantCustomFieldEntity {
        return TenantCustomFieldEntity.fromJson(tenantsetting);
    }
}