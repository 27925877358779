import setShiftRequestRead from "../actions/setShiftRequestRead";
import ShiftRequestUtils from "sh-application/utility/ShiftRequestUtils";
import StaffHubHttpErrorUtils from "sh-application/utility/StaffHubHttpErrorUtils";
import { DataProcessingHelpers, ShiftRequestDataService } from "sh-services";
import { IGroupedOpenShiftRequestEntity, IShiftRequestEntity, ShiftRequestTypes } from "sh-models";
import { orchestrator } from "satcheljs";
import { TeamStore, TeamStoreSchema } from "sh-team-store";
import { trace } from "owa-trace";

/**
 * This orchestrator will call the api to set a shift request as read
 */
export default orchestrator(setShiftRequestRead, async actionMessage => {
    const { shiftRequest } = actionMessage;

    try {
        const teamStore: TeamStoreSchema = TeamStore();
        if (teamStore && teamStore.team) {
            if (shiftRequest.requestType === ShiftRequestTypes.GroupedOpenShifts) {
                const groupedRequest = shiftRequest as IGroupedOpenShiftRequestEntity;
                if (groupedRequest && groupedRequest.shiftRequests) {
                    await ShiftRequestDataService.setShiftRequestReadStatusBulk(teamStore.team.tenantId, teamStore.team.id, DataProcessingHelpers.getArrayFromMap(groupedRequest.shiftRequests));
                }
            } else {
                const shiftRequestEntity: IShiftRequestEntity = shiftRequest as IShiftRequestEntity;
                if (shiftRequest && !ShiftRequestUtils.isRequestReadByMe(shiftRequestEntity)) {
                    await ShiftRequestDataService.setShiftRequestReadStatus(teamStore.team.tenantId, teamStore.team.id, shiftRequest.id, shiftRequest.lastModifiedTime);
                }
            }
        }
    } catch (error) {
        const errorMessage = StaffHubHttpErrorUtils.getErrorMessage(error);
        trace.warn("setShiftRequestRead failed: " + errorMessage);
    }
});