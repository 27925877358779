/**
    * Link html model
    *      {   text: "message text",
    *          cssClasses: "css classes override for the line",
    *          placeholders: [
    *              { text: "placeholder text", type: "placeholder type", cssClasses: "placeholder CSS" },  // Text placeholders
    *              { type: InputTextField, cssClasses: "extra CSS", inputTextPlaceholder: "input placeholder text", maxlength: "max input length" },  // Input text field
    *          ]
    *      }
    *
 */
export type PlaceholderType = "Text" | "TextSpan" | "Link" | "LineBreak" | "TextDiv";
export const PlaceholderTypes = {
    Text: "Text" as PlaceholderType,
    TextSpan: "TextSpan" as PlaceholderType,
    Link: "Link" as PlaceholderType,
    LineBreak: "LineBreak" as PlaceholderType,
    TextDiv: "TextDiv" as PlaceholderType
};

export class LineHtmlPlaceholderModel {
    text: string;
    cssClasses: string;
    placeholderType: PlaceholderType;
    url?: string;

    constructor(
        text: string,
        cssClasses: string,
        placeholderType: PlaceholderType,
        url?: string) {

        this.text = text;
        this.cssClasses = cssClasses;
        this.placeholderType = placeholderType;
        this.url = url;
    }
}

export default interface LineHtmlModel {
    text: string;
    cssClasses: string;
    placeholders: Array<LineHtmlPlaceholderModel>;
}