import setGlobalMessage from "../actions/setGlobalMessage";
import StaffHubHttpErrorUtils from "sh-application/utility/StaffHubHttpErrorUtils";
import { appViewState } from "../store";
import { MessageBarType } from "@fluentui/react";
import { orchestrator } from "satcheljs";
import { setGlobalMessageFromException } from "sh-application";

/**
 * Set the message to display in the global message bar based on the exception thrown
 */
export default orchestrator(setGlobalMessageFromException, async actionMessage => {
    const { exception, autoDismiss, showRefreshAction } = actionMessage;
    // showRefreshAction is to force showing the refresh action used for scenarios like sync. Typically the actions should be shown based on exception type
    const errorMessage: string = StaffHubHttpErrorUtils.getErrorMessage(exception);
    const errorActionButtonTitle = StaffHubHttpErrorUtils.getErrorActionButtonTitle(exception, showRefreshAction);
    const onErrorActionButtonClick = StaffHubHttpErrorUtils.onErrorActionButtonClick(exception, showRefreshAction);

    // Show the error message from the service
    setGlobalMessage(
        appViewState().globalMessageViewState,
        errorMessage,
        MessageBarType.error,
        errorActionButtonTitle,
        onErrorActionButtonClick,
        autoDismiss
    );
});