import { IShiftrObjectModelBase } from "..";
import { Moment } from "moment";

export enum DismissType {
    "Shift" = "Shift",
    "Availability" = "Availability"
}

export enum DismissState {
    "Active" = "Active",
    "Deleted" = "Deleted",        // this is set to trueif we use delete api(undo) dismiss
    "AutoDeleted" = "AutoDeleted" // this is set to true if the service finds as breaking change and auto deletes a dismiss entity
}

export interface IDismissEntity {
    id: string;
    type: DismissType;
}

/* Conflict dismiss entity, get created when user dismisses conflict */
export default interface IConflictDismissEntity extends IShiftrObjectModelBase  {
    /* tenant id associated with the conflict dismissal entry */
    tenantId?: string;
    /* team id asssociated with the conflict dismissal entry*/
    teamId?: string;
    /* user id associated with the conflict dismissal entry*/
    userId?: string;
    /* member id associated with the conflict dismissal entry*/
    memberId?: string;
    /* first entity of the conflict item to be dismissed */
    entityOne?: IDismissEntity;
    /* second entity of the conflict item to be dismissed */
    entityTwo?: IDismissEntity;
    /* state of the dismissed conflict entity */
    state?: DismissState;
    /* min start time of the conflicting entities */
    startTime?: Moment;
    /* max end time of the conflicting entities */
    endTime?: Moment;
}

/* Conflict dismiss entity, get created when user dismisses conflict */
export interface IBaseConflictDismissEntity<DateTimeType> extends IShiftrObjectModelBase  {
    /* tenant id associated with the conflict dismissal entry */
    tenantId?: string;
    /* team id asssociated with the conflict dismissal entry*/
    teamId?: string;
    /* user id associated with the conflict dismissal entry*/
    userId?: string;
    /* member id associated with the conflict dismissal entry*/
    memberId?: string;
    /* first entity of the conflict item to be dismissed */
    entityOne?: IDismissEntity;
    /* second entity of the conflict item to be dismissed */
    entityTwo?: IDismissEntity;
    /* state of the dismissed conflict entity */
    state?: DismissState;
    /* min start time of the conflicting entities */
    startTime?: DateTimeType;
    /* max end time of the conflicting entities */
    endTime?: DateTimeType;
}

export interface IConflictDismissResponseEntity {
    conflictDismissals: IConflictDismissEntity[];
    nextLink?: string;
}