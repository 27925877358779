import { mutatorAction } from "satcheljs";
import { SchedulesViewStateStore } from "sh-application/components/schedules/lib/store/schema/SchedulesViewStateStore";

/**
 * Sets the boolean to indicate the header is expanded
 * @param viewState - Schedule view state
 * @param isHeaderExpanded - boolean determining if the header is expanded
 */
export default mutatorAction("SET_IS_HEADER_EXPANDED",
    function setIsHeaderExpanded(viewState: SchedulesViewStateStore, isHeaderExpanded: boolean) {
        viewState.isHeaderExpanded = isHeaderExpanded;
    }
);