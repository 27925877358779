import OpenShiftStore from "../store/store";
import resetOpenShiftsStore from "../actions/resetOpenShiftsStore";
import { mutator } from "satcheljs";

/**
 * Reset the open shift store
 */
export default mutator(resetOpenShiftsStore, ({ }) => {
    OpenShiftStore().openShifts.clear();
    OpenShiftStore().cacheStartTime = null;
    OpenShiftStore().cacheEndTime = null;
});
