import { action } from "satcheljs";
import { IShiftRequestEntity } from "sh-models";

const fetchAndCompareShiftsForConflicts = action(
    "FETCH_AND_COMPARE_SHIFTS_FOR_CONFLICTS",
    (
        shiftRequest: IShiftRequestEntity,
        shiftId: string,
        memberId: string
    ) => ({
        shiftRequest: shiftRequest,
        shiftId: shiftId,
        memberId: memberId
    })
);

export default fetchAndCompareShiftsForConflicts;