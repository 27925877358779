
enum EventTypes {
    Focus = "focus"
}

export default class EventUtils {

    public static isFocusEvent(event: any): boolean {
        return event && event.type && event.type === EventTypes.Focus;
    }
}