import NoteStore from "../store";
import resetNoteStore from "../actions/resetNoteStore";
import { mutator } from "satcheljs";

/**
 * Reset the note store
 */
export default mutator(resetNoteStore, ({ }) => {
    NoteStore().notes.clear();
    NoteStore().notesCacheStartTime = null;
    NoteStore().notesCacheEndTime = null;
});