import * as React from "react";

interface DynamicRouteImportState {
    component: any;
}

interface DynamicRouteImportProps {
    children: any;
    load: any;
}

/**
 * Used with React Router routes to on demand load a required bundle.
 */

// Example dynamic route:
// <Route exact path="/foo" component={ Foo } />
// ...
// const Foo = (props: any) => (
//     <DynamicRouteImport load={ () => import(/* webpackChunkName: "FooBundle" */ "./Foo") }>
//         { (Component: any) => Component === null
//         ? <SomeComponentToShowWhileFooIsBeingDownloaded />
//         : <Component {...props} /> }
//     </DynamicRouteImport>
// );

export default class DynamicRouteImport extends React.PureComponent<DynamicRouteImportProps, DynamicRouteImportState> {

    constructor(props: DynamicRouteImportProps) {
        super(props);
        this.state = { component: null };
    }

    componentDidMount() {
        this.props.load()
            .then((component: any) => {
                this.setState(() => ({
                    component: component.default ? component.default : component
                }));
            });
    }

    render() {
        return this.props.children(this.state.component);
    }
}