import automationIds from "sh-application/AutomationIds";
export default class AutomationUtil {

    /**
     * Function that is called to get the AutomationId value
     */
    public static getAutomationId(module: string, key: string): string {

        if (automationIds[module]) {
            // returns automationId based on the key value
            return automationIds[module][key] || "";
        }
        return "";
    }

    public static getAutomationFindString(id: string) {
        return '[data-automation-id="' + id + '"]';
    }

    public static getAriaLabelFindString(id: string) {
        return '[aria-label="' + id + '"]';
    }

    public static getAutomationClassName(id: string) {
        return "." + id;
    }
}