import FeatureFlagsStore from '../store/schema/FeatureFlagsStore';
import store from '../store/Store';
import { action, select } from "satcheljs/lib/legacy";
import { parse as parseQueryString } from 'querystring';

export const FEATURES_QUERYSTRING_PARAMETER_NAME = 'features';

export interface InitializeState {
    store: FeatureFlagsStore;
}

export default select<InitializeState>({
    store: () => store()
})(
    action('initializeFeatureFlags')(
        function initializeFeatureFlags(location: Location, state?: InitializeState) {
            if (state && state.store) {
                let featureFlags = state.store.featureFlags;
                let href = location.href.toLowerCase();
                let featureFlagsOptions: string[] = (parseQueryString(href.replace('?', '&'))[FEATURES_QUERYSTRING_PARAMETER_NAME] || '').split(',');

                featureFlags.forEach((value: boolean, key: string) => {
                    featureFlags.set(key, value || featureFlagsOptions.indexOf(key.toLowerCase()) !== -1);
                });
            }
        }
    ));
