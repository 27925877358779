import { action } from "satcheljs";
import { StaffHubHttpError } from "sh-application";

/**
 * Triggers handlers for StaffHub Service API errors
 */
export default action(
    "HANDLE_STAFFHUB_SERVICE_ERROR",
    (
        staffHubHttpError: StaffHubHttpError
    ) => ({
        staffHubHttpError: staffHubHttpError
    })
);