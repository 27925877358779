import { createStore } from "satcheljs";
import { ECSConfigStoreSchema } from "./schema/ECSConfigStoreSchema";

// orchestrators
import "../orchestrators/onSetECSConfig";

/**
 * Store for ECS config
 */
let ECSConfigStore = createStore<ECSConfigStoreSchema>(
    "ecsConfigStore",
    {
        config: null
    });

export default ECSConfigStore;