import toggleHideOpenShiftRows from "../actions/toggleHideOpenShiftRows";
import { orchestrator } from "satcheljs";
import { setDisableHideOpenShiftRowsToggle } from "../";
import { setGlobalMessageFromException } from "sh-application";
import { TeamDataService } from "sh-services";
import { UserSettingsEntity } from "sh-models";

/**
 * Toggles the collapsed state of the open shift rows
 */
export default orchestrator(toggleHideOpenShiftRows, async actionMessage => {
        const userSettings: UserSettingsEntity = await TeamDataService.getUserSettings();
        if (userSettings) {
            setDisableHideOpenShiftRowsToggle(true);
            try {
                const updatedUserSettings: UserSettingsEntity = UserSettingsEntity.clone(userSettings);
                updatedUserSettings.hideOpenShifts = !(updatedUserSettings.hideOpenShifts);
                await TeamDataService.saveUserSettings(updatedUserSettings);
            } catch (error) {
                setGlobalMessageFromException(error, false /* AutoDismiss */);
            } finally {
                setDisableHideOpenShiftRowsToggle(false);
            }
        }
    }
);
