import createTimeOffRequest from "../actions/createTimeOffRequest";
import setGlobalSpinnerMessageState from "sh-application/mutatorActions/setGlobalSpinnerMessageState";
import StringsStore from "sh-strings/store";
import { fireAccessibilityAlert } from "sh-application/components/accessibilityAlert";
import { IShiftRequestEntity, ShiftRequestEntity, ShiftRequestTypes } from "sh-models";
import { orchestrator } from "satcheljs";
import {
    setErrorMessageFromException,
    setIsSavingInStore,
    setSelectedRequest,
    setShouldScrollToSelected,
    setShowCreateRequestDialog
} from "../";
import { ShiftRequestDataService } from "sh-services";
import { TeamStore, TeamStoreSchema } from "sh-team-store";
import StringUtils from "../../../utility/StringUtils";

/**
 * This orchestrator will call the api to create time off request
 */
export default orchestrator(createTimeOffRequest, async (actionMessage) => {
    const { message, startTime, endTime, timeOffReasonId } = actionMessage;

    try {
        setIsSavingInStore(true);
        // the global spinner message is only displayed to teams with workforce management integration
        setGlobalSpinnerMessageState(
            StringsStore()
                .registeredStringModules.get("shiftRequests")
                .strings.get("savingRequestSpinnerLabel")
        );

        const teamStore: TeamStoreSchema = TeamStore();
        if (teamStore && teamStore.team) {
            const shiftRequestId: string = ShiftRequestEntity.generateNewShiftRequestId();
            const shiftRequest: IShiftRequestEntity =
                await ShiftRequestDataService.createTimeOffRequest(
                    teamStore.team.tenantId,
                    teamStore.team.id,
                    shiftRequestId,
                    message,
                    startTime,
                    endTime,
                    timeOffReasonId
                );
            setSelectedRequest(shiftRequest);
            setShowCreateRequestDialog(false);
            setShouldScrollToSelected(true);

            // Notify accessibility users that the request was created
            fireAccessibilityAlert(
                StringsStore()
                    .registeredStringModules.get("shiftRequests")
                    .strings.get("shiftRequestSent")
                    .format(StringUtils.localizeRequestType(ShiftRequestTypes.TimeOff))
            );
        }
    } catch (error) {
        // workflow integration error would be handled earlier
        if (error && !error.isHandled) {
            setErrorMessageFromException(error);
        }
    } finally {
        setIsSavingInStore(false);
        setGlobalSpinnerMessageState("");
    }
});
