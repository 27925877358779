import { AvailabilityStoreSchema } from "./schema/AvailabilityStoreSchema";
import { createStore } from "satcheljs";
import { IAvailabilityEntity } from "sh-models";
import { observable, ObservableMap } from "mobx";
import "../orchestrators/getAvailabilities";
/* register orchestraotrs */

let AvailabilityStore = createStore<AvailabilityStoreSchema>(
    "availabilityStore",
    {
        membersAvailabilities: new ObservableMap<string, IAvailabilityEntity[]>(),
        hasFetchedAvailabilities: observable.box(false)
    }
);

export default AvailabilityStore;