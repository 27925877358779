import * as React from "react";
import ErrorView from "sh-application/components/ErrorView";
import errorViewStateStore from "../store/store";
import { computed } from "mobx";
import { ErrorWatcherViewStateStore } from "../store/schema/ErrorWatcherViewStateStore";
import { getGenericEventPropertiesObject } from "sh-instrumentation";
import { InstrumentationService } from "sh-services";
import { FocusTrapZone, Layer } from "@fluentui/react";
import { observer } from "mobx-react";
import { restartApp } from "sh-application";

const styles = require("./ErrorWatcher.scss");

/**
 * Listens for the store to set a blocking error and shows a modal screen on top of
 * the app with an error message
 */
@observer
export default class ErrorWatcher extends React.Component<{}, {}> {

    /**
     * Gets the viewstate store
     */
    @computed
    private get viewState(): ErrorWatcherViewStateStore {
        return errorViewStateStore();
    }

    /**
     * Handle the click on the action button.
     */
    private onActionClick = async (ev?: React.SyntheticEvent<HTMLElement>): Promise<void> => {
        ev.stopPropagation();
        if (this.viewState.blockingError.actionButton) {
            const errorActionBtn = this.viewState.blockingError.actionButton;
            if (errorActionBtn.useAppRefreshAction) {
                InstrumentationService.logEvent(InstrumentationService.events.ErrorWatcherActionClicked, [getGenericEventPropertiesObject(InstrumentationService.properties.ActionType, "AppRefresh")]);
                // don't use window.location.reload because that breaks out the iframe on Edge
                window.location.replace(window.location.href);
            } else if (errorActionBtn.useAppRestartAction) {
                restartApp();
            } else if (errorActionBtn.useUrlNavigationAction) {
                InstrumentationService.logEvent(InstrumentationService.events.ErrorWatcherActionClicked, [getGenericEventPropertiesObject(InstrumentationService.properties.ActionType, "UrlNavigate")]);
                window.open(errorActionBtn.urlNavigationActionPath);
            }
        }
    }

    render() {
        if (this.viewState.blockingError) {
            let errorDetails = this.viewState.blockingError;
            InstrumentationService.logPageView("ErrorWatcherBlockedPage",
                [
                    getGenericEventPropertiesObject(InstrumentationService.properties.ErrorTitle, errorDetails.title),
                    getGenericEventPropertiesObject(InstrumentationService.properties.ErrorPrimaryText, errorDetails.primaryText)
                ]
            );
            return (
                <Layer>
                    <FocusTrapZone>
                        <div className={ styles.fillFullPage }>
                            <ErrorView
                                title = { errorDetails.title }
                                primaryText = { errorDetails.primaryText }
                                secondaryText = { errorDetails.secondaryText }
                                alternateImageSrc = { errorDetails.alternateImageSrc }
                                actionButtonText = { errorDetails.actionButton ? errorDetails.actionButton.actionButtonText : null }
                                onActionClick = { errorDetails.actionButton ? this.onActionClick : null }
                                isFullScreen={ true } />
                        </div>
                    </FocusTrapZone>
                </Layer>
            );

        } else {
            return null;
        }
    }
}