import ITagEntity from "./ITagEntity";
import ModelUtils from "../ModelUtils";
import ShiftrObjectModelBase from "../ShiftrObjectModelBase";
import TagStates, { TagStatesType } from "./TagStates";
import { TAG_ID_PREFIX } from "sh-application/../StaffHubConstants";

/**
 * Entity class for Tags
 */
export default class TagEntity extends ShiftrObjectModelBase implements ITagEntity {
    code: string | null;
    index: number;
    name: string;
    tenantId: string;
    teamId: string;
    memberIds: string[];
    state: TagStatesType;

    constructor(
        id: string,
        tenantId: string,
        teamId: string,
        name: string,
        memberIds: string[],
        state: TagStatesType,
        index: number,
        code: string | null,
        eTag: string
        ) {

        let modelId = id || TagEntity.generateTagId();
        super(modelId, eTag);

        this.tenantId = tenantId;
        this.teamId = teamId;
        // deep copy of memberIds
        // Note: do not change the .slice() functionality here. If we pass in a mobx observable array as the memberIds
        // this slice call is necessary to convert the mobx version of the array to a regular array.
        // ref: https://github.com/mobxjs/mobx/blob/gh-pages/docs/best/pitfalls.md#arrayisarrayobservable123--false
        this.memberIds = memberIds ? memberIds.slice() : [];
        this.state = state;
        this.name = name;
        this.index = index;
        this.code = code;
    }

    /**
     * Generate new tag Id used by the app
     * @returns {string} tag Id in form of Tag_<uuid>
     */
    public static generateTagId(): string {
        return ModelUtils.generateUUIDWithPrefix(TAG_ID_PREFIX);
    }

    public static fromJson(jsonData: ITagEntity): ITagEntity {
        if (!jsonData) {
            return null;
        } else {
            return TagEntity.clone(jsonData);
        }
    }

    public static toJson(tag: ITagEntity): ITagEntity {
        if (!tag) {
            return null;
        } else {
            return TagEntity.clone(tag);
        }
    }

    public static clone(tag: ITagEntity): ITagEntity {
        return new TagEntity(
            tag.id,
            tag.tenantId,
            tag.teamId,
            tag.name,
            tag.memberIds,
            tag.state,
            tag.index,
            tag.code,
            tag.eTag
        );
    }

    /**
     * Static method to create empty tag client model
     */
    public static createEmptyObject(): ITagEntity {
        return new TagEntity(
            null /* id */,
            null /* tenantId */,
            null /* teamId */,
            "" /* name */,
            [] /* memberIds */,
            TagStates.Active,
            null /* index */,
            null /* code */,
            null /* eTag */
        );
    }
}
