import ChangeStore from "../store";
import { computed } from "mobx";
import { IChangeEntity } from "sh-models";

/**
 * computed value representing the last set of changes or null
 */
export let lastUndoableChanges = computed(function(): Array<IChangeEntity> {
    if (ChangeStore().undoStack && ChangeStore().undoStack.length > 0) {
        return ChangeStore().undoStack[ChangeStore().undoStack.length - 1];
    } else {
        return null;
    }
});