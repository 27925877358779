import * as H from "history";
import * as React from "react";
import AdminPermissionChecker from "sh-application/utility/AdminPermissionChecker";
import AutomationUtils from "sh-application/utility/AutomationUtil";
import KeyboardUtils from "sh-application/utility/KeyboardUtils";
import setGlobalCommandBarSelectedTab from "sh-application/store/mutatorActions/setGlobalCommandBarSelectedTab";
import StringsStore from "sh-strings/store";
import { appViewState } from "sh-application/store";
import { AriaRoles, generateDomPropertiesForAria } from "owa-accessibility";
import { ECSConfigKey, ECSConfigService } from "sh-services";
import { GlobalCommandBarTab } from "sh-application/store/schema/GlobalCommandBarViewState";
import { match, NavLink } from "react-router-dom";
import { observer } from "mobx-react";
import { shiftRequestStore } from "sh-shiftrequests-store";
import { TeamStore } from "sh-team-store";
import { UrlFactory } from "sh-application";
import { getOpenShiftsNavLinkProps } from "./GlobalNavigationPivot.mappers";

const classNames = require("classnames/bind");
const styles = require("./GlobalNavigationPivot.scss");

@observer
export default class GlobalNavigationPivot extends React.Component<{}, {}> {
    private _strings: Map<string, string>;

    constructor(props: any) {
        super(props);
        this._strings = StringsStore().registeredStringModules.get("header").strings;
    }

    /**
     * React Router callback to determine if a NavLink is active, not relying on the viewstate as it might not be refreshed for back button.
     * @param match
     * @param location
     */
    private isActiveRoute = (match: match<any>, location: H.Location) => {
        return match && match.url === location.pathname;
    }

    private isRequestsNavLinkActive = (match: match<any>, location: H.Location) => {
        return this.isActiveRoute(match, location) || location.pathname.endsWith("/requests");
    }

    private onSchedulesTabSelected = () => {
        setGlobalCommandBarSelectedTab(GlobalCommandBarTab.Schedules);
    }

    private onSettingsTabSelected = () => {
        setGlobalCommandBarSelectedTab(GlobalCommandBarTab.Settings);
    }

    private onTabKeyDownSchedules = (e: React.KeyboardEvent<HTMLElement>) => {
        if (KeyboardUtils.isActionKeyPressed(e)) {
            this.onSchedulesTabSelected();
        }
    }

    private onTabKeyDownSettings = (e: React.KeyboardEvent<HTMLElement>) => {
        if (KeyboardUtils.isActionKeyPressed(e)) {
            this.onSettingsTabSelected();
        }
    }

    private onRequestsTabSelected = () => {
        setGlobalCommandBarSelectedTab(GlobalCommandBarTab.Requests);
    }

    private onTabKeyDownRequests = (e: React.KeyboardEvent<HTMLElement>) => {
        if (KeyboardUtils.isActionKeyPressed(e)) {
            this.onRequestsTabSelected();
        }
    }

    /**
     * Removes the 'requestId' URL params to avoid having previously selected request being selected
     * when user clicks on the "Requests" menu link. This will ensure most recent request will be selected.
     * @example
     * removeRequestIdUrlParam("?requestId=1&locale=en-US") returns "?locale=en-US"
     * removeRequestIdUrlParam("?locale=en-US&requestId=1") returns "?locale=en-US"
     * @param locationSearch The location search.
     * @returns The location search without 'requestId', 'shiftId', 'requestType' URL params.
     */
    private removeRequestIdUrlParam(locationSearch: string): string {
        return locationSearch.replace(/[\?&]requestId=[^&]+/, '').replace(/[\?&]requestType=[^&]+/, '').replace(/[\?&]shiftId=[^&]+/, '').replace(/^&/, '?');
    }

    render() {
        // robv 10/9/2019 - for now I'm just disabling the requests unread count because they are not always accurate. Service
        // is not going to continue making the calculations so we need a client side solution.
        const enableUnreadCounts = ECSConfigService.isECSFeatureEnabled(ECSConfigKey.RequestUnreadCountEnabled);

        const unreadRequests = enableUnreadCounts ? shiftRequestStore().unreadRequestsCount : 0;
        const requestsTitle = unreadRequests && enableUnreadCounts ? this._strings.get("requestsUnreadLinkTitle").format(String(unreadRequests)) : this._strings.get("requestsLinkTitle");
        const requestsAriaLabel = enableUnreadCounts ? this._strings.get("requestsTitleAriaLabel").format(String(unreadRequests)) : this._strings.get("requestsLinkTitle");

        const openShiftsNavLinkProps = getOpenShiftsNavLinkProps();

        return (
            <div className={ styles.globalNavigationPivot } role="none">
                    <NavLink
                        onKeyDown={ this.onTabKeyDownSchedules }
                        onClick={ this.onSchedulesTabSelected }
                        className={ classNames(styles.pivotItemsUnselected, AutomationUtils.getAutomationId("header", "QAIDSchedule")) }
                        activeClassName={ styles.pivotItemsSelected }
                        to={ {
                            pathname: UrlFactory.getSchedulesUrl(TeamStore().teamId, null, null, false /* includeQueryString */),
                            search: window.location.search
                        }}
                        isActive={ this.isActiveRoute }
                        {...generateDomPropertiesForAria({
                            role: AriaRoles.menuitem,
                            label: this._strings.get("scheduleLinkTitle"),
                            selected: appViewState().globalCommandBarViewState.selectedTab === GlobalCommandBarTab.Schedules
                            }) }
                        >
                        <span className={ styles.title }>
                            { this._strings.get("scheduleLinkTitle") }
                        </span>
                    </NavLink>

                    <NavLink
                        onKeyDown={ this.onTabKeyDownRequests }
                        onClick={ this.onRequestsTabSelected }
                        className={ classNames(styles.pivotItemsUnselected, AutomationUtils.getAutomationId("header", "QAIDRequests")) }
                        activeClassName={ styles.pivotItemsSelected }
                        to={ {
                            pathname: UrlFactory.getRequestsUrl(TeamStore().teamId, null /*requestId*/, null /*shiftId*/, null /*shiftRequestType*/, false /* includeQueryString */),
                            search: this.removeRequestIdUrlParam(window.location.search)
                        }}
                        isActive={ this.isRequestsNavLinkActive }
                        {...generateDomPropertiesForAria({
                            role: AriaRoles.menuitem,
                            label: requestsAriaLabel,
                            selected: appViewState().globalCommandBarViewState.selectedTab === GlobalCommandBarTab.Requests
                            }) }
                        >
                        <span className={ styles.title }>
                            { requestsTitle }
                        </span>
                    </NavLink>

                    {openShiftsNavLinkProps ? <NavLink {...openShiftsNavLinkProps}>
                        {this._strings.get("otherOpenShiftsLinkText")}
                    </NavLink> : null}

                {
                    AdminPermissionChecker.isCurrentUserAdmin() &&
                        <NavLink
                            onKeyDown={ this.onTabKeyDownSettings }
                            onClick={ this.onSettingsTabSelected }
                            className={ classNames(styles.pivotItemsUnselected, AutomationUtils.getAutomationId("header", "QAIDSettings")) }
                            activeClassName={ styles.pivotItemsSelected }
                            to={ {
                                pathname: UrlFactory.getTeamSettingsUrl(TeamStore().teamId, false /* includeQueryString */),
                                search: window.location.search
                            }}
                            isActive={ this.isActiveRoute }
                            {...generateDomPropertiesForAria({
                                role: AriaRoles.menuitem,
                                label: this._strings.get("teamSettings"),
                                selected: appViewState().globalCommandBarViewState.selectedTab === GlobalCommandBarTab.Settings
                             }) }
                            >
                            <span className={ styles.title }>
                                { this._strings.get("teamSettings") }
                            </span>
                        </NavLink>
                }
            </div>
        );
    }
}
