import ChangeStore from "../store";
import { action } from "satcheljs/lib/legacy";
import { FlightKeys, IChangeEntity } from "sh-models";
import { FlightSettingsService } from "sh-services";
import { lastRedoableChanges } from "../computed/lastRedoableChanges";
import { revertChanges } from "./revertChanges";
import { trackChanges } from "./trackChanges";

/**
 * Perform a redo if there is a change on the stack to redo
 */
export let performRedo = action("performRedo")(
    function performRedo() {
        return new Promise<Array<IChangeEntity>>((resolve, reject) => {
            // check for redoable action
            const hasRedoableChanges: boolean = !!lastRedoableChanges.get();
            if (hasRedoableChanges && FlightSettingsService.isFlightEnabled(FlightKeys.EnableShiftUndoWeb)) {
                // remove the last change from the redo stack and revert it
                revertChanges(ChangeStore().redoStack.pop(), false /* fromUndo */, true /* fromRedo */)
                    .then((changes: Array<IChangeEntity>) => {
                        // track this change.  It will go onto the undo stack so that it can be undid again
                        trackChanges(changes, false /* fromUndo */, true /* fromRedo */);
                        resolve(changes);
                    }).catch(reject);
            } else {
                // resolve with empty array. Nothing was redone
                resolve([]);
            }
        });
    });
