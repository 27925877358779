import { action } from "satcheljs";
import { IShiftEntity, IAvailabilityEntity } from "sh-models";

const createShiftAvaialibityConflict = action(
    "CREATE_SHIFT_AVAILABILITY_CONFLICT",
    (
        shift: IShiftEntity,
        conflictingAvailability: IAvailabilityEntity,
        isRequestShift: boolean,
        memberId?: string
    ) => ({
        shift: shift,
        conflictingAvailability: conflictingAvailability,
        isRequestShift: isRequestShift,
        memberId: memberId
    })
);

export default createShiftAvaialibityConflict;