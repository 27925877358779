import DateUtils from "sh-application/utility/DateUtils";
import OpenShiftStore from "../store/store";
import updateOpenShiftsInStore from "../actions/updateOpenShiftsInStore";
import { IOpenShiftEntity, OpenShiftEntity } from "sh-models";
import { mutator } from "satcheljs";

export default mutator(updateOpenShiftsInStore, ({ updatedOpenShifts }) => {
    updatedOpenShifts.forEach((openShift: IOpenShiftEntity) => {
        // update to OpenShiftStore if the shift falls within cached date range. Else ignore.
        if (DateUtils.overlapsStartsOrEndsBetween(openShift.startTime, openShift.endTime, OpenShiftStore().cacheStartTime, OpenShiftStore().cacheEndTime, true /* includeStartEdge */, true /* includeEndEdge */)) {
            // this will be treated as add, if OpenShift is not already present in store
            OpenShiftStore().openShifts.set(openShift.id, OpenShiftEntity.clone(openShift));
        }
    });
});