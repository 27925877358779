import { action } from "satcheljs";
import { IBaseShiftRequestEntity } from "sh-models";

const setSelectedRequest = action(
    "SET_SELECTED_REQUEST",
    (
        shiftRequest: IBaseShiftRequestEntity
    ) => ({
        shiftRequest: shiftRequest
    })
);

export default setSelectedRequest;