import * as React from "react";
import DateTimeFormatter from "sh-application/utility/DateTimeFormatter";
import StringsStore from "sh-strings/store";
import { action } from "satcheljs/lib/legacy";
import { ActivityFlexItemBaseProps } from "./ActivityFlexContainer";
import { ActivitySelectionItem, ActivitySelectionItemType } from "sh-application/components/schedules/lib";
import { AriaProperties, AriaRoles, generateDomPropertiesForAria } from "owa-accessibility";
import { ISubshiftEntity, SubshiftTypes } from "sh-models";
import { observable } from "mobx";
import { observer } from "mobx-react";
import { Tooltip } from "@fluentui/react";

const styles = require("./ActivityFlexItem.scss");
const classNames = require("classnames/bind");

interface ActivityFlexItemProps extends ActivityFlexItemBaseProps {
    /* The activity rendered by this component */
    activity: ISubshiftEntity;
    /* (optional) If true, the activity code will not be rendered above the activity */
    hideCode?: boolean;
}

/**
 * ActivityFlexItem
 */
@observer
export default class ActivityFlexItem extends React.Component<ActivityFlexItemProps, {}> {
    private _root = React.createRef<HTMLDivElement>();
    private _activitySelectionItem: ActivitySelectionItem;
    private _activityStrings: Map<String, String>;

    @observable private _showTooltip: boolean = false;

    constructor(props: ActivityFlexItemProps) {
        super(props);

        this._activityStrings = StringsStore().registeredStringModules.get("activities").strings;

        this._activitySelectionItem = {
            shift: this.props.shift,
            itemType: ActivitySelectionItemType.Activity,
            activity: this.props.activity,
            startTime: this.props.startTime,
            endTime: this.props.endTime
        };
    }

    /**
     * When the activity item is clicked on, we want to set this item as selected and we want to unselect the containing grid cell.
     * In addition, we want to stop the propagation of the click event so that the Fabric Selection event handlers don't reselect the grid cell.
     * @param e
     */
    private onActivityItemClicked = (e: any) => {
        if (this.props.setFlexItemSelected ) {
            this.props.setFlexItemSelected(this._activitySelectionItem, this.props.activity.id, !this.props.isSelected);
            if (this.props.setGridCellSelected) {
                this.props.setGridCellSelected(false /* isSelected */);
                e.stopPropagation();
            }
        }
    }

    /**
     * Return the code of the activity. If it has no code, return the first two letters of the title.
     */
    private getActivityCode(): string {
        let code = this.props.activity.code;
        if (!code && this.props.activity.title) {
            code = this.props.activity.title.substr(0, 2);
        }

        return code;
    }

    /**
     * Set the value of this._showTooltip to toggle the rendering of the tooltip
     */
    private updateShowTooltip = action("updateShowTooltip")((show: boolean) => {
        this._showTooltip = show;
    });

    /**
     * Callback fire onMouseEnter event. Displays the tooltip
     */
    private onMouseEnter = () => {
        this.updateShowTooltip(true);
    }

    /**
     * Callback fire onMouseExit event. Hides the tooltip
     */
    private onMouseLeave = () => {
        this.updateShowTooltip(false);
    }

    private getAriaProps = (): AriaProperties => {
        const activityType: string = (this.props.activity.subshiftType === SubshiftTypes.UnpaidBreak)
            ? this._activityStrings.get("unpaidAriaLabel").toString()
            : this._activityStrings.get("paidAriaLabel").toString();
        const timeRange: string = DateTimeFormatter.getEventTimeRangeAsString(this.props.startTime, this.props.endTime);
        const title: string = this.props.activity.title || "";
        const code: string = this.props.activity.code || "";
        const label = this._activityStrings.get("activityAriaLabelFormat").format(activityType, timeRange, title, code);

        return {
            role: AriaRoles.listitem,
            label: label
        };
    }

    render() {
        const { activity, isSelected, selectionEnabled, hideCode } = this.props;
        const classes = isSelected ? classNames(styles.activityFlexItem, styles.selectedFlexItem) : styles.activityFlexItem;
        const activityFlexContentClasses = selectionEnabled ? classNames(styles.activityFlexItemContent, styles[activity.theme], styles.flexItemContentEditable) : classNames(styles.activityFlexItemContent, styles[activity.theme]);

        return (
                <div
                    ref={ this._root }
                    data-is-focusable={ selectionEnabled }
                    onClick={ this.onActivityItemClicked }
                    onMouseEnter={ this.onMouseEnter }
                    onMouseLeave={ this.onMouseLeave }
                    className={ classes }
                    style={ {flexGrow: this.props.flexGrow} }
                    { ...generateDomPropertiesForAria(this.getAriaProps()) }>
                    {
                        !hideCode &&
                            <div className={ styles.activityFlexItemCode }> { this.getActivityCode() } </div>
                    }
                    <div className={ activityFlexContentClasses }></div>
                    {
                        this._showTooltip && activity.title &&
                            <Tooltip
                                targetElement={ this._root.current }
                                content={ activity.title } />
                    }
                </div>
        );
    }
}