import { TraceLevel, registerTracerListener } from "owa-trace";
import { AppSettings } from "sh-application";
import { LogLevel, LoggingService } from "sh-services";

/**
 * Registers a trace listener that will console log all traces to the console
 * if verboseLogging is true.
 */
export const initializeTracer = (): void => {
    // TODO(Performance): Spike to find if following logs are used and/or can be removed.
    if (AppSettings.getSetting("EnableAppLogging")) {
        registerTracerListener(
            function(trace: string, level: TraceLevel) {
                switch (level) {
                    case TraceLevel.Warning:
                        LoggingService.addLog(LogLevel.Warn, trace);
                        break;
                    case TraceLevel.Error:
                        LoggingService.addLog(LogLevel.Error, trace);
                        break;
                    case TraceLevel.Info:
                        LoggingService.addLog(LogLevel.Info, trace);
                        break;
                    default:
                        LoggingService.addLog(LogLevel.None, trace);
                        break;
                }
            }
        );
    }
};
