import { instrumentationActionType } from "./instrumentationActionType";
import { instrumentationAvailabilityTypes } from "./instrumentationAvailabilityType";
import { instrumentationBoostrapTypes } from "./instrumentationBoostrapTypes";
import { instrumentationCustomDimensionProperties } from "./instrumentationCustomDimensionProperties";
import { instrumentationEntryPoints } from "./instrumentationEntryPoints";
import { instrumentationEvents } from "./instrumentationEvents";
import { instrumentationEventTypes } from "./instrumentationEventTypes";
import { instrumentationFilterTypes } from "./instrumentationFilterTypes";
import { instrumentationPerfScenarios } from "./instrumentationPerfScenarios";
import { instrumentationProperties } from "./instrumentationProperties";
import { instrumentationReorderTypes } from "./instrumentationReorderTypes";
import { instrumentationShiftRequestActionTaken } from "./instrumentationShiftRequestActionTaken";
import { instrumentationShiftRequestType } from "./instrumentationShiftRequestType";
import { instrumentationShiftRequestUserType } from "./instrumentationShiftRequestUserType";
import { instrumentationShiftType } from "./instrumentationShiftType";
import { instrumentationValues } from "./instrumentationValues";
import { instrumentationViews } from "./instrumentationViews";

export const Instrumentation = {
    actionTypes: instrumentationActionType,
    availabilityTypes: instrumentationAvailabilityTypes,
    bootstrapTypes: instrumentationBoostrapTypes,
    customDimensionProperties: instrumentationCustomDimensionProperties,
    entryPoints: instrumentationEntryPoints,
    events: instrumentationEvents,
    eventTypes: instrumentationEventTypes,
    filterTypes: instrumentationFilterTypes,
    perfScenarios: instrumentationPerfScenarios,
    properties: instrumentationProperties,
    reorderTypes: instrumentationReorderTypes,
    ShiftRequestActionTaken: instrumentationShiftRequestActionTaken,
    ShiftRequestType: instrumentationShiftRequestType,
    ShiftRequestUserType: instrumentationShiftRequestUserType,
    ShiftType: instrumentationShiftType,
    values: instrumentationValues,
    views: instrumentationViews
};
