// ********************************************************
// *****                                              *****
// *****       DO NOT SORT IMPORTS IN THIS FILE       *****
// *****                                              *****
// ********************************************************

// the publicPath import must be the first line so that __webpack_public_path__
// will get initialized before any subsequent import
import "./publicPath";

// react-hot-loader must come before importing React
import "react-hot-loader";
import * as React from "react";
import { render } from "react-dom";
import { BrowserRouter } from 'react-router-dom';

// Import any services before App or UI components
import { InstrumentationService } from "sh-services";
import { TeamsHostContainer } from "sh-application/hostsContainers/TeamsHostContainer";

import App from "sh-application/components/App";
import { initializeFeatureFlags } from "sh-feature-flags";
import { preinitApplication } from "sh-application";
import './staffhub/sh-application/styles/_shiftsThemes.scss';

// Include the version.json so that the bundler knows to copy it to the dist folder
/* tslint:disable */
const version = require("../../version.json");
/* tslint:enable */

const rootEl = document.getElementById("root");

/**
 * >>>>>>>>>> APPLICATION ENTRY POINT <<<<<<<<<<<
 */
const startApplication = async () => {
    // **** Features (flighting) ****
    // enable/disable features
    // USAGE: ?features=[feature_name],[feature_name]
    // example: https://flwdev.teams.microsoft.com/app/sandbox?features=verbose
    // Note: This goes first because features that are off by default can
    // be enabled via query param and may be needed by other initialize calls (such as verbose logging in production).
    // This will only set defaults and features enabled by query string. Later we will get
    // feature info from the service for a specific team and initialize feature flags again.
    initializeFeatureFlags(window.location);

    InstrumentationService.perfMarkerStart(InstrumentationService.perfScenarios.AppBootstrap, false /*appendTimestamp*/);
    InstrumentationService.perfMarkerStart(InstrumentationService.perfScenarios.TimeToFirstFullRender, false /*appendTimestamp*/);
    let preInitMarker = "preinitApplication";
    preInitMarker = InstrumentationService.perfMarkerStart(preInitMarker);
    try {
        await preinitApplication(version.version, new TeamsHostContainer());
    } catch (e) {
        // set error
    } finally {
        InstrumentationService.perfMarkerEnd(preInitMarker);
    }

    render(
        <BrowserRouter>
            <App />
        </BrowserRouter>,
        rootEl
    );
};

startApplication();