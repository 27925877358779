import * as moment from "moment";
import {
    ACTIVITYFEED_DEEPLINK_SOURCE,
    END_QUERYSTRING_PARAMETER_NAME,
    REQUEST_ID_QUERY_PARAMETER_NAME,
    REQUEST_TYPE_QUERY_PARAMETER_NAME,
    RING_ID_QUERYSTRING_PARAMETER_NAME,
    SESSION_ID_QUERYSTRING_PARAMETER_NAME,
    SHIFT_ID_QUERY_PARAMETER_NAME,
    START_QUERYSTRING_PARAMETER_NAME,
    SUBENTITYID_QUERYSTRING_PARAMETER_NAME,
    TENANT_ID_QUERYSTRING_PARAMETER_NAME,
    THEME_QUERYSTRING_PARAMETER_NAME,
    UI_CULTURE_QUERYSTRING_PARAMETER_NAME,
    USER_ID_QUERYSTRING_PARAMETER_NAME
    } from "sh-application/../StaffHubConstants";
import { AppSettings } from "sh-application";
import {
    DeepLinkContext,
    DeepLinkViewType,
    DeepLinkViewTypes,
    ScheduleCalendarType,
    ScheduleCalendarTypes,
    ShiftRequestType
    } from "sh-models";
import { defaultScheduleCalendarType } from "sh-application/components/schedules/lib/orchestrators/initializeSchedulesViewState";
import { FEATURES_QUERYSTRING_PARAMETER_NAME } from "sh-feature-flags";
import { isReleaseBuild } from "sh-application/utility/utility";
import { parse as parseQueryString } from "querystring";
import { trace } from "owa-trace";

const SCHEDULES_PAGE = "schedules";
const REQUESTS_PAGE = "requests";

/**
 * A static class that returns URLs specific to StaffHub client routing.
 */
export default class UrlFactory {
    private static _appBaseUrl = `/${AppSettings.getSetting("AppPathFolder")}`;
    private static _loginUrl = `${UrlFactory._appBaseUrl}/login`;

    /**
     * Compute the base url with port and matching scheme: https://localhost:3000
     * @returns {string} the base url of the website.
     */
    public static getSiteUrl() {
        let uri = window.location.protocol + "//" + window.location.hostname;
        if (window.location.port && window.location.port.length > 0) {
            uri += ":" + window.location.port;
        }
        return uri;
    }

    /**
     * Returns the main app navigation URL. e.g. https://staffhub.office.com/app
     */
    public static getBaseAppNavigationUrl() {
        return this.getSiteUrl() + this.getBaseUrl();
    }

    /**
     * Returns the URL (not including protocol or host) for StaffHub.
     */
    public static getBaseUrl() {
        return UrlFactory._appBaseUrl;
    }

    /**
     * Parses the current window location search string and returns a javascript
     * object containing all parsed key/value pairs.
     * Example:
     *      {
     *          key: value,
     *          anotherKey: anotherValue
     *      }
     * @param location window.location object
     */
    public static getUrlParams(location: Location) {
        const obj = location && location.search
            ? parseQueryString(location.search.replace('?', '&'))
            : {};
        return obj;
    }

    /**
     * Returns the URL (not including protocol or host) for login interstitial page.
     */
    public static getLoginUrl() {
        return UrlFactory._loginUrl;
    }

    /**
     * Returns the relative URL for Schedules page or absolute URL for Schedules page. If both a startDate and endDate are passed, the schedule url
     * will be a deep link into the schedule page that will select the correct view type and date range.
     * @param {string} teamId - ID of the team that the user is logged in to.
     * @param {string} startDate - start of date range to include in display
     * @param {string} endDate - end of date range to include in display
     * @param {boolean} includeQueryString - Set to true to generate a query param string. false to return nothing, which is used for React router links.
     */
    public static getSchedulesUrl(teamId: string, startDate?: string, endDate?: string, includeQueryString: boolean = true) {
        return `${UrlFactory._appBaseUrl}/teams/${teamId}/${SCHEDULES_PAGE}${UrlFactory.getSchedulesUrlQueryString(startDate, endDate, includeQueryString)}`;
    }

    /**
     * Returns the URL for the 'Other open shifts' tab.
     * @param teamId The ID of the team that the user selected.
     * @returns The URL for the 'Other open shifts' tab.
     */
    public static getOtherOpenShiftsUrl(teamId: string): string {
        return `${UrlFactory._appBaseUrl}/teams/${teamId}/otheropenshifts`;
    }

    /**
     * Returns the relative URL for Schedules page or absolute URL for Schedules page. If both a startDate and endDate are passed, the schedule url
     * will be a deep link into the schedule page that will select the correct view type and date range.
     * @param {string} groupId - Group ID of the team that the user is logged in to.
     * @param {string} startDate - start of date range to include in display
     * @param {string} endDate - end of date range to include in display
     * @param {boolean} includeQueryString - Set to true to generate a query param string. false to return nothing, which is used for React router links.
     */
    public static getSchedulesUrlWithGroupId(groupId: string, startDate?: string, endDate?: string, includeQueryString: boolean = true) {
        return `${UrlFactory._appBaseUrl}/groups/${groupId}/${SCHEDULES_PAGE}${UrlFactory.getSchedulesUrlQueryString(startDate, endDate, includeQueryString)}`;
    }

    /**
     * Returns the query string for Schedules page. If both a startDate and endDate are passed, the schedule url
     * will be a deep link into the schedule page that will select the correct view type and date range.
     * @param {string} teamId - ID of the team that the user is logged in to.
     * @param {moment} startDate - start of date range to include in display
     * @param {moment} endDate - end of date range to include in display
     * @param {boolean} includeQueryString - Set to true to generate a query param string. false to return nothing, which is used for React router links.
     */
    private static getSchedulesUrlQueryString(startDate?: string, endDate?: string, includeQueryString: boolean = true) {
        let schedulesUrlSubPath = "";
        if (includeQueryString) {
            schedulesUrlSubPath = UrlFactory.calculateQueryParamStringForShiftsAppUrl(includeQueryString);
            if (startDate && endDate) {
                // append the deep link dates
                schedulesUrlSubPath = `${schedulesUrlSubPath}&${START_QUERYSTRING_PARAMETER_NAME}=${moment(startDate).toISOString()}&${END_QUERYSTRING_PARAMETER_NAME}=${moment(endDate).toISOString()}`;
            }
        }

        return schedulesUrlSubPath;
    }

    /**
     * Returns the relative URL for Team page.
     * @param {string} teamId - ID of the team that the user is logged in to.
     */
    public static getTeamUrl(teamId: string) {
        return `${UrlFactory._appBaseUrl}/teams/${teamId}/staff${UrlFactory.calculateQueryParamStringForShiftsAppUrl()}`;
    }

    /**
     * Returns the relative URL for Test page.
     * @param {string} teamId - ID of the team that the user is logged in to.
     */
    public static getTestUrl(teamId: string) {
        return `${UrlFactory._appBaseUrl}/teams/${teamId}/test${UrlFactory.calculateQueryParamStringForShiftsAppUrl()}`;
    }

    /**
     * Returns the relative URL for Team Settings page.
     * @param {string} teamId - ID of the team that the user is logged in to.
     * @param {boolean} includeQueryString Set to true to generate a query param string. false to return nothing, which is used for React router links.
     */
    public static getTeamSettingsUrl(teamId: string, includeQueryString: boolean = true) {
        return `${UrlFactory._appBaseUrl}/teams/${teamId}/team${UrlFactory.calculateQueryParamStringForShiftsAppUrl(includeQueryString)}`;
    }

    /**
     * Returns the relative URL for Requests page.
     * @param {string} teamId - ID of the team that the user is logged in to.
     * @param {boolean} includeQueryString Set to true to generate a query param string. false to return nothing, which is used for React router links.
     */
    public static getRequestsUrl(teamId: string, requestId?: string, shiftId?: string, shiftRequestType?: ShiftRequestType, includeQueryString: boolean = true) {
        return `${UrlFactory._appBaseUrl}/teams/${teamId}/${REQUESTS_PAGE}${UrlFactory.getRequestsUrlQueryString(requestId, shiftId, shiftRequestType, includeQueryString)}`;
    }

    /**
     * Returns the relative URL for Requests page.
     * @param {string} groupId - Group ID of the team that the user is logged in to.
     * @param {boolean} includeQueryString Set to true to generate a query param string. false to return nothing, which is used for React router links.
     */
    public static getRequestsUrlWithGroupId(groupId: string, requestId?: string, shiftId?: string, shiftRequestType?: ShiftRequestType, includeQueryString: boolean = true) {
        return `${UrlFactory._appBaseUrl}/groups/${groupId}/${REQUESTS_PAGE}${UrlFactory.getRequestsUrlQueryString(requestId, shiftId, shiftRequestType, includeQueryString)}`;
    }

    /**
     * Returns the relative URL for Import Schedule page.
     * @param teamId Team ID.
     * @returns URL for Import Schedule page.
     */
    public static getImportScheduleUrl(teamId: string) {
        return `${UrlFactory._appBaseUrl}/teams/${teamId}/importSchedule`;
    }

    /**
     * Returns the relative URL for Requests page.
     * @param {boolean} includeQueryString Set to true to generate a query param string. false to return nothing, which is used for React router links.
     */
    private static getRequestsUrlQueryString(requestId?: string, shiftId?: string, shiftRequestType?: ShiftRequestType, includeQueryString: boolean = true) {
        let requestUrlSubPath = "";
        if (includeQueryString) {
            requestUrlSubPath = `${UrlFactory.calculateQueryParamStringForShiftsAppUrl(includeQueryString)}`;
            if (requestId) {
                // append the deep link request id
                requestUrlSubPath = `${requestUrlSubPath}&${REQUEST_ID_QUERY_PARAMETER_NAME}=${requestId}`;
            } else if (shiftId) {
                // append the deep link shift id
                requestUrlSubPath = `${requestUrlSubPath}&${SHIFT_ID_QUERY_PARAMETER_NAME}=${shiftId}`;
                // append the deep link request type
                if (shiftRequestType) {
                    requestUrlSubPath = requestUrlSubPath + `&${REQUEST_TYPE_QUERY_PARAMETER_NAME}=${shiftRequestType.toString()}`;
                }
            }
        }
        return requestUrlSubPath;
    }

    /**
     * Returns the absolute URL for the Third Party Notice page.
     * @param {string} theme - The current theme, needed to update the colors in the third party notice.
     */
    public static getThirdPartyNoticeUrl(theme: string) {
        return `${UrlFactory.getBaseAppNavigationUrl()}/oss?theme=${theme}`;
    }

    /**
     * Returns the Connector redirect URL that will redirect to the Connector service to perform SSO
     */
    public static getConnectorRedirectUrl(connectorUrl: string) {
        return `${UrlFactory.getBaseAppNavigationUrl()}/RedirectToConnector?connectorUrl=` + encodeURIComponent(connectorUrl);
    }

    /**
     * Returns the relative URL for testing page.
     * @param {string} teamId - ID of the team that the user is logged in to. Omit for standalone test pages.
     * @param {string} subPath - The sub path to add after /test in the URL.
     */
    public static getTestingPage(teamId?: string, subPath: string = "") {
        if (!isReleaseBuild()) {
            return teamId ?
                `${UrlFactory._appBaseUrl}/teams/${teamId}/test${subPath}${UrlFactory.calculateQueryParamStringForShiftsAppUrl()}` :    // test pages that require a team
                `${UrlFactory._appBaseUrl}/test${subPath}${UrlFactory.calculateQueryParamStringForShiftsAppUrl()}`;                    // standalone test pages
        }

        return "";
    }

    /**
     * Validate if given relative URL is the landing page location
     */
    public static isLandingPageLocation(location: string) {
        // it is team switcher location if location matches either with /app or /app/
        return (location && location.search(new RegExp(UrlFactory._appBaseUrl + "\/?$")) == 0);
    }

    /**
     * Returns the contents of the "subEntityId" query string parameter, that is sent as part of
     * a Teams notification deep link. If the subEntityId parameter does not exist, or the contents
     * don't pass validation, null is returned.
     * See Deep Link Spec here: https://domoreexp.visualstudio.com/Teamspace/_wiki/wikis/Teamspace.wiki/14759/Shifts-Deep-Links
     * @param location window.location object
     */
    public static getDeepLinkContext(location: Location): DeepLinkContext {
        if (location?.search) {
            const urlParams = parseQueryString(location.search.replace('?', '&'));
            const subEntityId = urlParams[SUBENTITYID_QUERYSTRING_PARAMETER_NAME];
            let deepLinkContext: DeepLinkContext = undefined;

            try {
                deepLinkContext = subEntityId && JSON.parse(decodeURIComponent(subEntityId)) as DeepLinkContext;
            } catch (parseError) {
                trace.error(`Invalid subEntityId format: ${parseError}`);
            }

            if (UrlFactory.isValidDeepLink(deepLinkContext)) {
                deepLinkContext.view = UrlFactory.normalizeDeepLinkView(deepLinkContext.view);
                return deepLinkContext;
            }
        }

        return null;
    }

    /**
     * Returns true if the current window location includes a Teams notification deep link.
     * @param deepLinkViewType Deep link view type
     */
    private static normalizeDeepLinkView(deepLinkViewType: DeepLinkViewType): DeepLinkViewType {
        if (deepLinkViewType) {
            if (deepLinkViewType.toLowerCase() == DeepLinkViewTypes.Schedules.toLowerCase()) {
                return DeepLinkViewTypes.Schedules;
            } else if (deepLinkViewType.toLowerCase() == DeepLinkViewTypes.Requests.toLowerCase()) {
                return DeepLinkViewTypes.Requests;
            }
        }
        // default view type = "Schedules"
        return DeepLinkViewTypes.Schedules;
    }

    /**
     * Returns true if the current window location includes a Teams notification deep link.
     * @param location window location object (window.location)
     */
    public static isActivityFeedDeepLink(deepLinkContext: DeepLinkContext): boolean {
        return UrlFactory.isValidDeepLink(deepLinkContext)
            && deepLinkContext.source?.toLowerCase() == ACTIVITYFEED_DEEPLINK_SOURCE.toLowerCase();   // A deeplink should have a groupId to be considered valid
    }

    /**
     * Returns true if the current window location includes a Teams notification deep link.
     * @param location window location object (window.location)
     */
    private static isValidDeepLink(deepLinkContext: DeepLinkContext): boolean {
        return !!deepLinkContext && !!deepLinkContext.groupId;   // A deeplink should have a groupId to be considered valid
    }

    /**
     * Returns the deep link redirect Url if the window location includes a valid Teams notification deep link.
     * Return null if the deep link does not exist or is not valid.
     * @param location window location object (window.location)
     */
    public static getRedirectUrlFromDeepLink(deepLinkContext: DeepLinkContext, includeQueryString: boolean = false): string {
        if (UrlFactory.isValidDeepLink(deepLinkContext)) {
            if (deepLinkContext.view && deepLinkContext.view.toLowerCase() === REQUESTS_PAGE.toLowerCase()) {
                return UrlFactory.getRequestsUrlWithGroupId(deepLinkContext.groupId, deepLinkContext.requestId, deepLinkContext.shiftId, null /* requestType */, includeQueryString);
            } else {
                 // Default to SCHEDULES_PAGE view
                // if we are deep linking to the schedules page, the query string can include the publish start and end range. The range is then used
                // to calculate which view (day, week, month) the scheduler starts in and which dates are visible.
                return UrlFactory.getSchedulesUrlWithGroupId(deepLinkContext.groupId, deepLinkContext.startDateTime, deepLinkContext.endDateTime, includeQueryString);
            }
        }

        return null;
    }

    /**
     * Validate if given relative URL is the schedules page location
     */
    public static isSchedulesPageLocation(location: string) {
        return location && location.includes(`/${SCHEDULES_PAGE}`);
    }

    /**
     * Returns true if this is a schedules page deep link (start and end date ranges are included as parameters).
     * @param location - window.location object
     */
    public static isSchedulesPageDeepLink(location: Location): boolean {
        if (location && location.search) {
            const urlParams = UrlFactory.getUrlParams(location);
            const startDate = urlParams[START_QUERYSTRING_PARAMETER_NAME];
            const endDate = urlParams[END_QUERYSTRING_PARAMETER_NAME];
            if (startDate && endDate) {
                return true;
            }
        }

        return false;
    }

    /**
     * Returns the calculated ScheduleCalenderType from the date range in a schedule deep link. If
     * there is no valid deep link, the default schedule type is returned.
     * @param location - window.location object
     */
    public static getScheduleTypeFromDeepLink(location: Location): ScheduleCalendarType {
        if (UrlFactory.isSchedulesPageDeepLink(location)) {
            const urlParams = UrlFactory.getUrlParams(location);
            const startDate = moment(urlParams[START_QUERYSTRING_PARAMETER_NAME]);
            const endDate = moment(urlParams[END_QUERYSTRING_PARAMETER_NAME]);

            const daysDiff = endDate.diff(startDate, "days", true);
            if (daysDiff <= 1) {
                return ScheduleCalendarTypes.Day;
            } else if (daysDiff > 7) {
                return ScheduleCalendarTypes.Month;
            } else {
                return ScheduleCalendarTypes.Week;
            }
        }

        return defaultScheduleCalendarType;
    }

    /**
     * Returns the selected schedule date from the date range in the schedule deep link. If
     * there is no valid deep link, the current date is returned.
     * @param location - window.location object
     */
    public static getScheduleSelectedDateFromDeepLink(location: Location): moment.Moment {
        if (UrlFactory.isSchedulesPageDeepLink(location)) {
            const urlParams = UrlFactory.getUrlParams(location);
            return moment(urlParams[START_QUERYSTRING_PARAMETER_NAME]);
        }

        return moment();
    }

    /**
     * Returns the calculated/sanitized query param serach string for deep links
     * @param location - window.location object
     */
    public static getQueryParamStringFromDeepLink(deepLinkContext: DeepLinkContext): string {
        if (deepLinkContext) {
            try {
                let queryString = UrlFactory.calculateQueryParamStringForShiftsAppUrl();
                if (deepLinkContext.startDateTime) {
                    const startDateTime = moment(deepLinkContext.startDateTime);
                    if (startDateTime?.isValid()) {
                        // append the deep link start dateTime
                        queryString += `&${START_QUERYSTRING_PARAMETER_NAME}=${startDateTime.toISOString()}`;
                    }
                }
                if (deepLinkContext.endDateTime) {
                    const endDateTime = moment(deepLinkContext.endDateTime);
                    if (endDateTime?.isValid()) {
                        // append the deep link end dateTime
                        queryString += `&${END_QUERYSTRING_PARAMETER_NAME}=${endDateTime.toISOString()}`;
                    }
                }
                if (deepLinkContext.requestId) {
                    // append the deep link request id
                    queryString += `&${REQUEST_ID_QUERY_PARAMETER_NAME}=${deepLinkContext.requestId}`;
                }
                if (deepLinkContext.shiftId) {
                    // append the deep link shift id
                    queryString += `&${SHIFT_ID_QUERY_PARAMETER_NAME}=${deepLinkContext.shiftId}`;
                }
                return queryString;
            } catch (err) {
                trace.error(`Error generating querystring from deep link context: ${err}`);
            }
        }
        return null;
    }

    /**
     * Construct the query parameter string for urls that link to the Shifts app
     * @param includeQueryString Set to true to generate a query param string. false to return nothing, which is used for React router links.
     */
    private static calculateQueryParamStringForShiftsAppUrl(includeQueryString: boolean = true): string {

        if (!includeQueryString) {
            return "";
        }

        // Copy the core querystring parameters from the current query string
        // (These are necessary for the service to load data like ECS config)
        const coreQueryStringParameterNames = [
            UI_CULTURE_QUERYSTRING_PARAMETER_NAME,
            USER_ID_QUERYSTRING_PARAMETER_NAME,
            TENANT_ID_QUERYSTRING_PARAMETER_NAME,
            SESSION_ID_QUERYSTRING_PARAMETER_NAME,
            RING_ID_QUERYSTRING_PARAMETER_NAME,
            THEME_QUERYSTRING_PARAMETER_NAME,
            FEATURES_QUERYSTRING_PARAMETER_NAME
        ];

        // Copy the core query string parameters from the current URL into the new URL
        let queryParams: {[name: string]: string} = {};
        const currentUrlParams = new URLSearchParams(window.location.search);
        for (let parameterName of coreQueryStringParameterNames) {
            if (currentUrlParams.has(parameterName)) {
                const paramValue = currentUrlParams.get(parameterName);
                queryParams[parameterName] = paramValue;
            }
        }

        return "?" + UrlFactory.constructQueryParamString(queryParams);
    }

    /**
     * Returns the query string updated with the feature turned on/off
     * @param featureName Feature name
     * @param isOn If the feature is on/off
     */
    public static getQueryStringWithFeatureFlag(featureName: string, isOn: boolean) {
        // Copy the core query string parameters from the current URL into the new URL
        const queryString = window.location.search;
        let newQueryParams: {[name: string]: string} = {};
        const currentUrlParams = new URLSearchParams(queryString);
        let wasFeatureFlagFound: boolean = false;
        currentUrlParams.forEach((value: string, key: string, parent: URLSearchParams) => {
            if (key) {
                if (key.toLowerCase() == FEATURES_QUERYSTRING_PARAMETER_NAME) {
                    wasFeatureFlagFound = true;
                    // Add/remove the feature flag from the features param
                    let featureFlags: string[] = (value || '').split(',');
                    const isFeatureFlagInUrl: boolean = featureFlags.indexOf(featureName) >= 0;
                    if (isOn && !isFeatureFlagInUrl) {
                        // Add the feature flag
                        featureFlags.push(featureName);
                    } else if (!isOn && isFeatureFlagInUrl) {
                        // remove the feature flag
                        featureFlags = featureFlags.filter((value: string) => value != featureName);
                    }
                    if (featureFlags.length) {
                        newQueryParams[key] = featureFlags.join(',');
                    }
                } else {
                    // Copy all other params into the result
                    newQueryParams[key] = value;
                }
            }
        });
        if (!wasFeatureFlagFound && isOn) {
            newQueryParams[FEATURES_QUERYSTRING_PARAMETER_NAME] = featureName;
        }
        return "?" + UrlFactory.constructQueryParamString(newQueryParams);
    }

    /**
     * Construct a query parameters string given a dictionary of query param names and their values.
     * Query parameter values will be url encoded. "?" is not included so that the result may be combined
     * with other query parameter strings.
     * @param params query parameter name + value pairs
     * @returns query parameter string (eg, a=1&b=2&c=3)
     */
    public static constructQueryParamString(params: {[name: string]: string}): string {
        let queryParamsString = "";
        if (params) {
            queryParamsString = Object.keys(params).map((paramName: string) => {
                return paramName + "=" + encodeURIComponent(params[paramName]);
            }).join("&");
        }
        return queryParamsString;
    }
}