import * as React from "react";
import {
    css,
    Checkbox,
    DefaultButton,
    FocusTrapZone,
    Image,
    Link
    } from "@fluentui/react";
import { ILocalizationTuple } from "sh-application/components/tour/ITour";
import { TourBubbleProps } from "./TourBubble.props";

const styles = require("./TourBubble.scss");
const classNames = require("classnames/bind");

export class TourBubbleContent extends React.Component<TourBubbleProps, {}> {

    // Specify default props values
    public static defaultProps = {
        hasCondensedHeadline: false
    };

    componentDidMount() {
        this.applyLocalizationTuples(this.props);
    }

    componentDidUpdate() {
        this.applyLocalizationTuples(this.props);
    }

    /**
     * If the props containa a list of localization tuples, iterate through the tuples and apply the localized text to
     * the target element described by each tuple. When we want to localize the images used in TourBubbleContent, we provide
     * an SVG JSX.Element (customImageHeader) with the text divs marked with IDs. These are the ids used here in the localizationTuples to assign
     * localized text.
     * @param props
     */
    private applyLocalizationTuples(props: TourBubbleProps) {
        if (props && props.localizationTuples) {
            props.localizationTuples.forEach((localizationTuple: ILocalizationTuple) => {
                let target = document.getElementById(localizationTuple.id);
                if (target) {
                    target.textContent = localizationTuple.localizedContent;
                }
            });
        }
    }

    /**
     * Render an image. Will either render the customImageHeader if it is provided, the illustrationImage if provided, or null.
     * illustrationImage is an object containing an image source, which is a path to the asset we wish to render. It can be used when
     * we do not need to localize the content in the image. customImageHeader is a JSX.Element that will be rendered directly. It can
     * be used to localize images. To do so, use an SVG, mark the text divs in the SVG with ids, and supply localization tuples to this
     * component. These should contain the ids and the corresponding localized text.
     */
    private renderImage(): JSX.Element {
        const { illustrationImage, customImageHeader, hideImageShadow, imageWrapperClass } = this.props;
        const imgMarkup = customImageHeader ?
                            customImageHeader :
                                illustrationImage && illustrationImage.src ?
                                    <Image { ...illustrationImage } /> :
                                         null;

        if (imgMarkup) {
            return (
                <div className={ classNames(styles.headerImage, imageWrapperClass) }>
                    { imgMarkup }
                    <div className={ classNames({ [`${styles.imageShadow}`]: !hideImageShadow }) } ></div>
                </div>
            );
        }
    }

    private renderHeader(): JSX.Element {
        const { headline, hasCondensedHeadline } = this.props;

        if (!headline) {
            return null;
        }

        return (
            <div
                className={ css(
                    'ms-TourBubble-header',
                    hasCondensedHeadline ?
                        'ms-TourBubble-header--small ' + styles.headerIsSmall :
                        'ms-TourBubble-header--large ' + styles.headerIsLarge
                )}
            >
                <p className={ css('ms-TourBubble-headline', styles.headline) } >
                    { headline }
                </p>
            </div>
        );
    }

    private renderBody(): JSX.Element {
        if (!this.props.children) {
            return null;
        }

        return (
            <div className={ css('ms-TourBubble-body', styles.body) }>
                <p className={ css('ms-TourBubble-subText', styles.subText) }>
                    { this.props.children }
                </p>
            </div>
        );
    }

    private renderFooter(): JSX.Element {
        const { primaryButtonProps, primaryLinkButtonProps, secondaryButtonProps, checkboxProps } = this.props;

        if (primaryButtonProps || primaryLinkButtonProps || secondaryButtonProps || checkboxProps) {
            return (
                <div className={ css('ms-TourBubble-footer', styles.footer) }>
                    {
                        checkboxProps &&
                            <Checkbox
                                className={ classNames(styles.checkbox) }
                                ariaLabel={ checkboxProps.label }
                                checked={ checkboxProps.checked }
                                label={ checkboxProps.label }
                                onChange={ checkboxProps.onChange } />
                    }
                    {
                        primaryLinkButtonProps &&
                            <Link
                                { ...primaryLinkButtonProps }
                                className={ css('ms-TourBubble-primaryLinkButton', styles.primaryLinkButton, primaryLinkButtonProps.className) } >
                            </Link>
                    }
                    {
                        primaryButtonProps &&
                            <DefaultButton
                                { ...primaryButtonProps }
                                className={ css('ms-TourBubble-primaryButton', styles.primaryButton, primaryButtonProps.className) } />
                    }
                    {
                        secondaryButtonProps &&
                            <DefaultButton
                                { ...secondaryButtonProps }
                                className={ css('ms-TourBubble-secondaryButton', styles.secondaryButton, secondaryButtonProps.className) } />
                    }
                </div>
            );
        }

        return null;
    }

    public render() {
        const { dismissOnOutsideClick } = this.props;

        return (
            <div className={ css('ms-TourBubble-content') }>
                { this.renderImage() }
                { /* Provide a tabindex of -1 so that the Callout component that wraps this component can programmatically
                     set focus inside the callout when it opens. We also set data-is-focusable to true so that the Fabric component logic
                     correctly identifies this as a focusable element. This autofocus behavior is necessary for accessibility so that screen readers
                     read the content, and so that the callout is closable via the keyboard. */ }
                <div data-is-focusable={ true } tabIndex={ -1 } className={ css('ms-TourBubble-bodycontent', styles.bodyContent) }>
                    { this.renderHeader() }
                    <FocusTrapZone isClickableOutsideFocusTrap={ dismissOnOutsideClick }>
                        { this.renderBody() }
                        { this.renderFooter() }
                    </FocusTrapZone>
                </div>
            </div>
        );
    }
}