import TeamStore from "../store";
import { action } from "satcheljs/lib/legacy";

/**
 * Sets the hideOpenShifts property of the team
 */
export let setHideOpenShifts = action("setHideOpenShifts")(

    function setHideOpenShifts(hide: boolean) {
        TeamStore().team.hideOpenShifts = hide;
    });