import { mutatorAction } from "satcheljs";
import { TagHeaderViewStateStore } from "../store/schema/TagHeaderViewStateStoreSchema";

/**
 * Sets error for the tag name in the tag header viewstate
 */
export default mutatorAction("setTagNameError", function setTagNameError(
    viewState: TagHeaderViewStateStore,
    error: string
) {
    if (viewState) {
        viewState.tagNameError = error;
    }
});