import { action } from "satcheljs";
import { INoteEntity } from "sh-models";

const notesModified = action(
    "NOTES_MODIFIED",
    (
        notesAdded: INoteEntity[],
        notesDeleted: INoteEntity[],
        notesUpdated: INoteEntity[]
    ) => ({
        notesAdded: notesAdded || [],
        notesDeleted: notesDeleted || [],
        notesUpdated: notesUpdated || []
    })
);

export default notesModified;
