import { action } from "satcheljs";
import { IJobEntity } from 'sh-models';

const processUndoSyncNotification = action(
    "processUndoSyncNotification",
    (
        undoJobResponse: IJobEntity
    ) => ({
        undoJob: undoJobResponse
    })
);

export default processUndoSyncNotification;