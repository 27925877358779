import { AppSettings } from "sh-application";
import { default as ECSConfigKey } from "./ECSConfigKey";
import { default as FlightSettingsService } from "../FlightSettingsService";
import { ECSConfigStore, setECSConfig } from "sh-ecsconfig-store";
import { ECSHelper } from "./ECSHelper";
import { FlightKey } from "sh-models";
import { getGenericEventPropertiesObject } from "sh-instrumentation";
import { InstrumentationService } from "sh-services";
import { trace } from "owa-trace";

const baseECSConfig = require("ecsConfig.Default.json");

// Use this override similar to the Experimental settings in Teams
// This can be used for testing purposes to take priority over ECS settings in Dev/Local builds
const overrideECSConfig = require("ecsConfig.Override.json");

const GENERAL_RING_ID: string = "general";

/**
 * ECSConfigService
 */
class ECSConfigService {
    private ecsConfigCopy: { [s: string]: any; };

    /**
     * Loads the ECS config based on the ring
     * @param tenantId
     * @param ringId
     * @param userId
     * @param projectTeamEcsConfig
     */
    public initializeECSConfig(tenantId: string, ringId: string, userId: string, projectTeamEcsConfig: any): void {
        // Set the initial config based on the base config file (merged with projectTeamEcsConfig that comes embedded in the intial page payload)
        // This will get the app initialized and the specific features get enabled based on ECS config when received
        this.setECSConfig({[AppSettings.getSetting("ECSProjectTeam")]: projectTeamEcsConfig});

        if (!ringId) {
            trace.info("No ring id found for the user");
            ringId = GENERAL_RING_ID;
        }

        if (projectTeamEcsConfig) {
            // Set a timer to refresh the ECS config from the network, but don't await it.
            // This will also register us for config updates
            setTimeout(async() => { await ECSHelper.loadConfig(tenantId, ringId, userId, this.setECSConfig); }, 5000);
        } else {
            // if no config is passed in with the intial page load, call ECS to load the latest config right away
            // We don't block the app load on this
            ECSHelper.loadConfig(tenantId, ringId, userId, this.setECSConfig);
        }
    }

    /**
     * Sets the ECS config after merging it with the base config
     * @param ecsConfig { "MicrosoftTeamsVerticalsFLW": { [string: any] }}
     */
    public setECSConfig = (ecsConfig: { [s: string]: any; }): void => {
        const ecsProjectName = AppSettings.getSetting("ECSProjectTeam");
        // Set ECS Context with all telemetry events
        InstrumentationService.setECSContext(ecsProjectName, ecsConfig);

        this.ecsConfigCopy = ecsConfig; // keep a copy for logging purposes

        const isDefaultConfig: boolean = (!this.ecsConfigCopy);
        InstrumentationService.logPerfEvent(InstrumentationService.events.ECSConfigUpdated,
            [getGenericEventPropertiesObject(InstrumentationService.properties.IsDefault, isDefaultConfig)]
        );

        const mergedConfig = this.mergeConfigs(this.ecsConfigCopy && this.ecsConfigCopy[ecsProjectName], this.getBaseECSConfig());

        const mergedConfigWithOverride = this.mergeConfigs(this.getOverrideECSConfig(), mergedConfig);
        setECSConfig(mergedConfigWithOverride);
    }

    /**
     * Returns the base ECS config used in the build
     * This also helps in writing unit tests by adding a stub
     */
    public getBaseECSConfig(): any {
        return baseECSConfig;
    }

    /**
     * Returns the override ECS config used in the build
     * This also helps in writing unit tests by adding a stub
     */
    public getOverrideECSConfig(): any {
        return overrideECSConfig;
    }

    /**
     * Returns the merged config of the base and override config json objects
     * @param overrideConfig json object that takes priority over the base config in merge
     * @param baseConfig json object to be used as the base
     */
    private mergeConfigs(overrideConfig: any, baseConfig: any): any {
        let mergedConfig = Object.assign({}, baseConfig, overrideConfig);
        return mergedConfig;
    }

    /**
     * Returns true if the ECS feature is enabled.
     * @param configKey
     */
    public isECSFeatureEnabled(configKey: ECSConfigKey): boolean {
        const config: any = ECSConfigStore && ECSConfigStore().config;
        if (config && configKey) {
            const configValue = config[configKey];
            return !!(configValue);
        }
        return false;
    }

    /**
     * Returns the config value for the key, or null if none is found.
     * @param configKey
     */
    public getECSFeatureSetting(configKey: ECSConfigKey): any {
        const config: any = ECSConfigStore && ECSConfigStore().config;
        if (config && configKey) {
            return config[configKey];
        }
        return null;
    }

    /**
     * TODO(ECS): Remove this method and use isECSFeatureEnabled instead.
     * @deprecated If you want to check ECS feature flag, please use isECSFeatureEnabled instead.
     * Returns true if the feature is enabled for the App.
     * This considers the ecs config, flight settings, app settings
     * NOTE: For this to work, the feature flag name should be same across all three
     * @param configKey
     * @returns True if the feature is defined in atleast one of the settings and is not turned off in any of them
     */
    public isFeatureEnabled(configKey: string): boolean {
        if (!configKey) {
            return false;
        }
        const ecsFeatureSetting = this.getECSFeatureSetting(configKey as ECSConfigKey);
        const flightSetting = FlightSettingsService.getFlightSetting(configKey as FlightKey);
        const appSetting = AppSettings.getSetting(configKey);

        // If the feature flag is not added in any of them, then the feature should be considered turned off
        // checking for null for ecs and flight setting as those method return missing items as null
        if (ecsFeatureSetting === null && flightSetting === null && (typeof appSetting === "undefined")) {
            return false;
        }

        // The feature has been defined in atleast one of the settings
        // And is not turned off in any of them
        return !(ecsFeatureSetting === false || flightSetting === false || appSetting === false);
    }
}

const ecsConfigService = new ECSConfigService();
export default ecsConfigService as ECSConfigService;
