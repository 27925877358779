import {
    deleteConflictsInConflictStore,
    deleteShiftFromMemberIdToShiftsByDateStore,
    deleteShiftsInStoreMap,
    resetShiftIdInMemberDateIdsMap,
    ShiftMemberDateInfo,
    ShiftsByMemberAndDateStore
    } from "sh-conflict-store";
import { IShiftEntity } from "sh-models";
import { orchestrator } from "satcheljs";

/**
 * When updates to shifts are triggered via the UI or via sync notifications, this orchestrator will be triggered.
 * It will parse the deletes shifts and delete them in ShiftsByMemberAndDateStore maps, this also takes care of the conflict store
 */
export default orchestrator(deleteShiftsInStoreMap, actionMessage => {
    const shiftToDelete: IShiftEntity = actionMessage.shift;

    // fetch ShiftMemberDateInfo for that shift id
    const shiftMemberDateInfo: ShiftMemberDateInfo = ShiftsByMemberAndDateStore().shiftIdToMemberDateIdsMap.get(shiftToDelete.id);

    if (shiftMemberDateInfo) {
        // delete shift from ShiftsByMemberAndDateStore().memberIdToShiftsByDate
        deleteShiftFromMemberIdToShiftsByDateStore(shiftToDelete.id, shiftMemberDateInfo);
    }

    // remove all references of this shift id in shiftIdToMemberDateIdsMap
    resetShiftIdInMemberDateIdsMap(shiftToDelete.id);

    // remove this shift in conflict store, if present
    deleteConflictsInConflictStore(shiftToDelete);
});