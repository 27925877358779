import { action } from "satcheljs";
import { TeamDetailsResponse } from 'sh-models';

/**
 * Triggers the update of team details
 */
export default action(
    "SET_TEAM_DETAILS",
    (
        teamDetails: TeamDetailsResponse
    ) => ({
        teamDetails: teamDetails
    })
);