import deleteNotes from "sh-stores/sh-note-store/actions/deleteNotes";
import deleteNotesInStore from "sh-stores/sh-note-store/mutatorActions/deleteNotesInStore";
import notesModified from "sh-stores/sh-note-store/actions/notesModified";
import { orchestrator } from "satcheljs";

export default orchestrator(deleteNotes, async actionMessage => {
    // Delete the notes from the Note Store
    deleteNotesInStore(actionMessage.notesDeleted);

    // Fire the notes modified event so the UI knows to update
    notesModified(null, actionMessage.notesDeleted, null);
});