import { ITimeOffReasonEntity } from "sh-models";
import TimeOffUtils from "sh-application/utility/TimeOffUtils";
import TimeOffReasonsStore from "../store";

/**
 * Callback that returns a ITimeOffReasonEntity using the entity id as the search parameter.
 * @param {string} id - the id of the time off reason you want to search.
 * @returns {ITimeOffReasonEntity} The ITimeOffReasonEntity
 */
const getRequestableTimeOffReason = (id: string): ITimeOffReasonEntity =>
    TimeOffReasonsStore().timeOffReasons.get(id);

export default function getRequestableTimeOffReasonsFromStore() {
    const { requestableTimeOffReasonsIds } = TimeOffReasonsStore();
    if (requestableTimeOffReasonsIds.length === 0) {
        return [];
    }

    return requestableTimeOffReasonsIds
        .map(getRequestableTimeOffReason)
        .filter(TimeOffUtils.isActive);
}
