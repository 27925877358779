import { Moment } from "moment";
import { IBaseShiftEntity, IOpenShiftEntity, IShiftEntity } from "sh-models";
import IShiftrObjectModelBase from "../IShiftrObjectModelBase";

// Shift Request types
export type ShiftRequestType = "Unspecified" | "HandOff" | "Swap" | "TimeOff" | "Open" | "GroupedOpenShifts" | "CrossLocationOpen";
export const ShiftRequestTypes: Record<ShiftRequestType, ShiftRequestType> = {
    Unspecified: "Unspecified",
    HandOff: "HandOff",
    Swap: "Swap",
    TimeOff: "TimeOff",
    Open: "Open",
    GroupedOpenShifts: "GroupedOpenShifts", // Used for Grouping requests. Not available on service
    CrossLocationOpen: "CrossLocationOpen"
};

// Shift request states
export type ShiftRequestState = "Unknown" | "WaitingOnReceiver" | "WaitingOnManager" | "WaitingOnHomeManager" | "SenderDeclined" | "ReceiverDeclined" | "ManagerApproved" | "ManagerDeclined" | "HomeManagerDeclined" | "AutoDeclined" | "AnotherApproved";
export const ShiftRequestStates: Record<ShiftRequestState, ShiftRequestState> = {
    Unknown: "Unknown",
    WaitingOnReceiver: "WaitingOnReceiver",
    WaitingOnManager: "WaitingOnManager",
    WaitingOnHomeManager: "WaitingOnHomeManager",
    SenderDeclined: "SenderDeclined",
    ReceiverDeclined: "ReceiverDeclined",
    ManagerApproved: "ManagerApproved",
    ManagerDeclined: "ManagerDeclined",
    HomeManagerDeclined: "HomeManagerDeclined",
    AutoDeclined: "AutoDeclined",
    AnotherApproved: "AnotherApproved"
};

// Shift Info that gets passed along with shift request
export interface IShiftInfo {
    shiftId: string;
    startTime: Moment;
    endTime: Moment;
}

// Shift Request Member Read resposes that get passed with shift request
export interface IShiftRequestMemberReadResponse {
    shiftRequestId: string;
    memberId: string;
    userLastReadTime: Moment;
}

/**
 * Interface for common properties between ShiftRequestEntity and GroupedOpenShiftRequestEntity
 */
export interface IBaseShiftRequestEntity extends IShiftrObjectModelBase {
    shiftId: string;
    requestType: ShiftRequestType;
    startTime: Moment;
    endTime: Moment;
    state: ShiftRequestState;
    groupId: string;
    lastModifiedTime: Moment;
}

/**
 * Interface to represent an Shift Request Entity
 */
// TODO (CLM): Move CLM specific properties to a separate interface
export interface IShiftRequestEntity extends IBaseShiftRequestEntity {
    tenantId: string;
    teamId: string;
    senderMessage: string;
    receiverMessage: string;
    managerMessage: string;
    managerMemberId: string;
    receiverMemberId: string;
    /**
     * Team id of the person who requested open shift.
     */
    senderTeamId: string;
    /**
     * Id of manager of requesting user that approved the request.
     */
    senderManagerActor: string;
    /**
     * Storing any message that the home team manager wants to add to the request.
     */
    senderManagerMessage: string;
    /**
     * Team id that the open shift is located in.
     */
    targetTeamId: string;
    senderMemberId: string;
    timeOffReasonId: string;
    creationTime: Moment;
    receiverResponseTime: Moment;
    memberReads: Array<IShiftRequestMemberReadResponse>;
    otherShiftInfo: IShiftInfo;
}

/**
 * Response entity to an approveDeclineTimeOff call
 */
export interface IApproveDeclineTimeOffRequestResponseEntity {
    shift: IBaseShiftEntity;
    shiftRequest: IShiftRequestEntity;
    alerts: Array<string>;
    /* Unread Shift Requests count */
    unreadShiftRequests: number;
}

export interface IUserTimeOffReasonsReponseEntity {
    timeOffReasonIds?: string[] | null;
}

/**
 * Response entity containting a shift request and the unread shift request count
 */
export interface IShiftRequestAndUnreadCountResponseEntity {
    shiftRequest: IShiftRequestEntity;
    unreadShiftRequests: number;
}

/**
 * Response entity to an ApproveSwapHandoffRequest call
 */
export interface IApproveSwapHandOffRequestResponseEntity {
    deletedShifts: IBaseShiftEntity[];
    replacementShifts: IBaseShiftEntity[];
    updatedShiftRequests: IShiftRequestEntity[];
    unreadShiftRequests: number;
    alerts: string[];
}

/**
 * Response entity to a getShiftRequests call
 */
export interface IGetShiftRequestsResponseEntity {
    shiftRequests: IShiftRequestEntity[];
    unreadShiftRequests: number;
    nextCursor: string;
}

/**
 * Response entity to a createTimeOff call
 */
export interface ICreateTimeOffResponseEntity {
    shiftRequest: IShiftRequestEntity;
    shift: IBaseShiftEntity;
}

/**
 * Shift request details
 */
export interface IShiftRequestResponseEntity extends IShiftrObjectModelBase {
    isAccepting: boolean; // True if the manager has accepted the shift request
    message: string; // The message by the manager
}

/**
 * Response entity to bulk update open shift requests
 */
export interface IBulkOpenShiftRequestsResponseEntity {
    createdShifts: Array<IShiftEntity>;
    updatedOpenShift: IOpenShiftEntity;
    updatedOpenShiftRequests: IShiftRequestEntity[];
    unreadShiftRequestsCount: number;
    generatedAlerts: string[];
}
