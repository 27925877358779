import ShiftUtils from "sh-application/utility/ShiftUtils";
import UniqueSubshiftStore from "../store";
import { action } from "satcheljs/lib/legacy";
import { IUniqueSubshiftEntity } from "sh-models";

/**
 * prependUniqueSubshift action - prepends a unique subshift to the UniqueSubshiftStore, which lists unique subshifts in order of most recent use
 *
 * @param {IUniqueSubshiftEntity} newUniqueSubshift
 */
export let prependUniqueSubshift = action("prependUniqueSubshift")(
    function prependUniqueSubshift(newUniqueSubshift: IUniqueSubshiftEntity) {
        // find a similar unique subshift from the list or undefined
        const similarUniqueSubshift: IUniqueSubshiftEntity = UniqueSubshiftStore().uniqueSubshifts.find((uniqueSubshift: IUniqueSubshiftEntity) => {
            return ShiftUtils.areSimilarUniqueSubshifts(uniqueSubshift, newUniqueSubshift);
        });
        if (similarUniqueSubshift) {
            // If the unique subshift is already in the list, remove the subshift
            UniqueSubshiftStore().uniqueSubshifts.remove(similarUniqueSubshift);
        }
        // add the unique subshift to top
        UniqueSubshiftStore().uniqueSubshifts.unshift(newUniqueSubshift);
    });