import * as React from "react";
import FlexiGrid, { FlexiGridProps } from "./FlexiGrid";
import { SortableContainer } from "react-sortable-hoc";

/**
 * Wrapper for making the grid a draggable/sortable container
 */
const SortableFlexiGrid = SortableContainer<FlexiGridProps>((props: FlexiGridProps) => <FlexiGrid {...props} />);

export default SortableFlexiGrid;
