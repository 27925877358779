import * as React from "react";
import FlexiCell from "./FlexiCell";
import StringsStore from "sh-strings/store";
import { action } from "satcheljs/lib/legacy";
import { AriaRoles, AriaProperties, generateDomPropertiesForAria } from "owa-accessibility";
import { IObjectWithKey } from "@fluentui/react";
import { observable } from "mobx";
import { observer } from "mobx-react";
const classNames = require("classnames/bind");
const styles = require("./FlexiRow.scss");

/**
 * Properties interface for the FlexiRow component
 *
 * To enable reactive rendering updates of cells, the cellSettings data that is passed to this FlexiRow component
 * should be designated as observable.
 */
export interface FlexiRowProps {
    rowElementId?: string; // Element id for the row element
    rowElementKey?: string; // Unique key for the row element. Used for optimizing reactive rendering updates.
    rowClass?: string; // CSS classes for the row
    rowDataAutomationId?: string; // data-automation-id attribute value for the row element
    cellBaseClass?: string; // Base CSS classes for cells in the row
    cellSettings: FlexiRowCellSettings[]; // Settings for the cells in the row
    showBlockingOverlay?: boolean; // true, if a blocking overlay needs to be shown over the row
    rowStyle?: React.CSSProperties; // inline style properties for the row
    ariaPropsForRow?: AriaProperties; // Aria accessibility properties to assign for this row
    isSortable?: boolean; // gets automatically set if drag and drop is on and this row is draggable
    memberRowIndex?: number; // Member row index to help us determine if we should show reorder instructions
}

/**
 * Settings for a row cell within the context of the row.
 * Some settings are for the rendering of the cells themselves, and others are for the layout of the cells within the row.
 *
 * Cell widths settings using CSS Flexbox properties:
 * - To set a specific width for a cell, set the cellFlexBasis property with the desired CSS width (eg, "40px"),
 *      and omit the cellFlexGrow value.
 * - To set relative widths for cells that adjust based on the row's width, set the cellFlexGrow property
 *      width the relative cell width unit value, and set cellFlexBasis to "0".
 *      eg, Single unit cells should get assigned 1, and cells that need to be two units wide get assigned 2.
 *
 * To customize the contents of the cells, implement the onRenderCellContents callback and pass any supporting data
 * that is needed by the callback via the cellContentsItem property.
 */
export interface FlexiRowCellSettings extends IObjectWithKey {
    animateCellClass?: string; // CSS class to add if this cell supports animation
    cellElementId?: string; // Element id to assign for this cell
    cellElementKey?: string; // Unique key for the cell element. Used for optimizing reactive rendering updates.
    cellClass?: string; // CSS classes for the cell container
    dataSelectionIndex?: number | null | undefined; // Office Fabric Selection index. All selectable items should be assigned an index.
    isSelected?: boolean; // Set to true to mark the cell as selected
    cellFlexGrow?: number; // CSS flex-grow value for the cell
    cellFlexBasis?: string; // CSS flex-basis value for the cell
    cellContentsItem?: any; // Custom cell contents data
    onRenderCellContents?: (cellContentsItem: any, cellHasFocus: boolean) => React.ReactNode; // Custom rendering callback for the cell contents
    ariaPropsForCell?: AriaProperties; // Aria accessibility properties to assign for this cell
    onShouldComponentUpdate?: (oldCellContentsItem: any, newCellContentsItem: any) => boolean; // Custom callback provided at cell generation time. It will compare the cell contents items and return true if the FlexiCell should update
}

/**
 * FlexiRow component
 * This component renders a row of cells with customizable contents.
 */
@observer
export default class FlexiRow extends React.Component<FlexiRowProps, {}> {
    @observable isFocused: boolean = false;
    private _strings: Map<string, string>;
    private _blockingOverlay = React.createRef<HTMLDivElement>();

    constructor(props: FlexiRowProps) {
        super(props);
        this._strings = StringsStore().registeredStringModules.get("schedulePage").strings;
    }

    private setFocus = action("setFocus")(() => {
        this.isFocused = true;
    });

    private unsetFocus = action("unsetFocus")(() => {
        this.isFocused = false;
    });

    UNSAFE_componentWillUpdate(nextProps: FlexiRowProps) {
        if (this.props.rowElementKey !== nextProps.rowElementKey) {
            // row changed unset focus
            this.unsetFocus();
        }
    }

    componentDidUpdate() {
        // Set focus on the flexi row that is being re-ordered
        if (this.props.showBlockingOverlay && this._blockingOverlay.current) {
            this._blockingOverlay.current.focus();
        }
    }

    /**
     * Generate unique React list item key for the specified cell for more efficient React re-rendering.
     *
     * The goal is that the keys should remain stable while the layout remains the same, so that React can recycle the
     * item elements and just re-render their contents.  This helps to improve our rendering performance.
     *
     * However, if the layout changes (eg, number of items change, the items which span different widths change, etc), we may need to ensure
     * that the keys should are different from the previous layout.  Otherwise rendering issues may occur where old cells from the previous
     * layout don't get re-rendered or removed properly.
     *
     * Adding extra information such as the number of items in the row and cell widths to the keys would help ensure correct React rendering,
     * but from running performance tests, this degrades rendering performance by some amount, so we need to be careful if we find
     * specific scenarios where rendering issues occur that we want to fix.
     */
    private generateElementKeyId(cellElementKey: string, cellIndex: number): string {
        return cellElementKey || cellIndex.toString();
    }

    render() {
        const { rowElementId, rowClass, rowDataAutomationId, cellSettings, cellBaseClass, showBlockingOverlay, rowStyle, isSortable } =
            this.props;

        let cellsOutput = [];

        if (cellSettings && cellSettings.length) {
            for (let i = 0; i < cellSettings.length; i++) {
                const currentCellSettings = cellSettings[i];

                // Calculate CSS classes for each cell
                const currentCellClasses = classNames(cellBaseClass, currentCellSettings.cellClass);

                const currentCellStyle = {
                    flexGrow: currentCellSettings.cellFlexGrow,
                    flexBasis: currentCellSettings.cellFlexBasis
                };

                const elementKeyId = this.generateElementKeyId(currentCellSettings.cellElementKey, i);

                cellsOutput.push(
                    <FlexiCell
                        key={elementKeyId}
                        cellElementId={currentCellSettings.cellElementId}
                        cellStyle={currentCellStyle}
                        cellClass={currentCellClasses}
                        dataSelectionIndex={currentCellSettings.dataSelectionIndex}
                        animateCellClass={currentCellSettings.animateCellClass}
                        cellContentsItem={currentCellSettings.cellContentsItem}
                        ariaPropsForCell={currentCellSettings.ariaPropsForCell}
                        onRenderCellContents={currentCellSettings.onRenderCellContents}
                        onShouldComponentUpdate={currentCellSettings.onShouldComponentUpdate}
                    />
                );
            }
        }

        return (
            <div
                id={rowElementId}
                style={isSortable ? {} : rowStyle} // if the row is sortable, the rowStyle will have been written to the sortable wrapper component
                className={classNames(rowClass, { "is-focused": this.isFocused })}
                data-automation-id={rowDataAutomationId}
                onFocus={!showBlockingOverlay ? this.setFocus : null} // prevent the row from getting focused when the blocking overlay is visible (overlay used in keyboard reorder mode)
                onBlur={this.unsetFocus}
                {...generateDomPropertiesForAria(this.props.ariaPropsForRow)}>
                {cellsOutput}
                {showBlockingOverlay && (
                    <div
                        className={"blocking-overlay"}
                        ref={this._blockingOverlay}
                        alt=""
                        {...generateDomPropertiesForAria({ role: AriaRoles.row, hidden: true })}>
                        {this.props.memberRowIndex === 0 && (
                            <div className={styles.reorderMemberInstructionsContainer}>
                                <div className={styles.moveInstructions}>{this._strings.get("reorderMemberInstructions")}</div>
                                <div>{this._strings.get("reorderMemberExitInstructions")}</div>
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    }
}
