import MemberUtils from "sh-application/utility/MemberUtils";
import { ConflictStore } from "sh-conflict-store";
import { ConflictType } from "sh-models";
import { mutatorAction } from "satcheljs";

export default mutatorAction('INCREMENT_CONFLICT_COUNT', function incrementConflictCount(memberId: string, conflictType: ConflictType) {
   ConflictStore().conflictCount.totalConflictCount = ConflictStore().conflictCount.totalConflictCount + 1;

   const isCurrentUser = MemberUtils.getCurrentUserMemberId() === memberId;
   // populate your conflict count, if we calculating for current user
   if (isCurrentUser) {
      ConflictStore().myConflictCount.totalConflictCount = ConflictStore().myConflictCount.totalConflictCount + 1;
   }

   switch (conflictType) {
      case ConflictType.OverlappingShiftConflict:
         ConflictStore().conflictCount.overlappingShiftConflictCount = ConflictStore().conflictCount.overlappingShiftConflictCount + 1;
         if (isCurrentUser) {
            ConflictStore().myConflictCount.overlappingShiftConflictCount = ConflictStore().myConflictCount.overlappingShiftConflictCount + 1;
         }
         break;
      case ConflictType.ShiftAvailabilityConflict:
         ConflictStore().conflictCount.shiftAvailabilityConflictCount = ConflictStore().conflictCount.shiftAvailabilityConflictCount + 1;
         if (isCurrentUser) {
            ConflictStore().myConflictCount.shiftAvailabilityConflictCount = ConflictStore().myConflictCount.shiftAvailabilityConflictCount + 1;
         }
         break;
      case ConflictType.ShiftTimeOffConflict:
         ConflictStore().conflictCount.shiftTimeoffConflictCount = ConflictStore().conflictCount.shiftTimeoffConflictCount + 1;
         if (isCurrentUser) {
            ConflictStore().myConflictCount.shiftTimeoffConflictCount = ConflictStore().myConflictCount.shiftTimeoffConflictCount + 1;
         }
         break;
      default: break;
   }
});
