import { action } from "satcheljs";
import { IShiftEntity } from "sh-models";

const deleteConflictsInConflictStore = action(
    "DELETE_CONFLICT_IN_CONFLICT_STORE",
    (
        shift: IShiftEntity
    ) => ({
        shift: shift
    })
);

export default deleteConflictsInConflictStore;