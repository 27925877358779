import StringsStore from "sh-strings/store";
import { DateRangePickerPhrases, SingleDatePickerPhrases } from "react-dates";

/**
 * Utilities for Calendar
 */
export default class CalendarUtils {

    /**
     * Returns the phrases for DateRangePicker
     * airbnb component has the default strings in english and doesn't localize them
     * Also, if we need to override one of these strings, we need to pass the complete list. it will not take the default for a string if nothing is passed for it in the phrases here
     */
    public static getDateRangePickerPhrases(): any {
        const phrasesStrings: Map<string, string> = StringsStore().registeredStringModules.get("calendarDatePickerPhrases").strings;

        const phrases: DateRangePickerPhrases = {
            calendarLabel: phrasesStrings.get("calendarLabel"),
            closeDatePicker: phrasesStrings.get("closeDatePicker"),
            clearDates: phrasesStrings.get("clearDates"),
            focusStartDate: phrasesStrings.get("focusStartDate"),
            jumpToPrevMonth: phrasesStrings.get("jumpToPrevMonth"),
            jumpToNextMonth: phrasesStrings.get("jumpToNextMonth"),
            keyboardShortcuts: phrasesStrings.get("keyboardShortcuts"),
            showKeyboardShortcutsPanel: phrasesStrings.get("showKeyboardShortcutsPanel"),
            hideKeyboardShortcutsPanel: phrasesStrings.get("hideKeyboardShortcutsPanel"),
            openThisPanel: phrasesStrings.get("openThisPanel"),
            enterKey: phrasesStrings.get("enterKey"),
            leftArrowRightArrow: phrasesStrings.get("leftArrowRightArrow"),
            upArrowDownArrow: phrasesStrings.get("upArrowDownArrow"),
            pageUpPageDown: phrasesStrings.get("pageUpPageDown"),
            homeEnd: phrasesStrings.get("homeEnd"),
            escape: phrasesStrings.get("escape"),
            questionMark: phrasesStrings.get("questionMark"),
            selectFocusedDate: phrasesStrings.get("selectFocusedDate"),
            moveFocusByOneDay: phrasesStrings.get("moveFocusByOneDay"),
            moveFocusByOneWeek: phrasesStrings.get("moveFocusByOneWeek"),
            moveFocusByOneMonth: phrasesStrings.get("moveFocusByOneMonth"),
            moveFocustoStartAndEndOfWeek: phrasesStrings.get("moveFocustoStartAndEndOfWeek"),
            returnFocusToInput: phrasesStrings.get("returnFocusToInput"),
            keyboardNavigationInstructions: phrasesStrings.get("keyboardNavigationInstructions"),
            chooseAvailableStartDate: this.chooseAvailableStartDateMessage,
            chooseAvailableEndDate: this.chooseAvailableEndDateMessage,
            dateIsUnavailable: this.dateIsUnavailableMessage,
            dateIsSelected: this.dateIsSelectedMessage
        };
        return phrases;
    }

    /**
     * Returns the phrases for DateRangePicker
     * airbnb component has the default strings in english and doesn't localize them
     * Also, if we need to override one of these strings, we need to pass the complete list. it will not take the default for a string if nothing is passed for it in the phrases here
     */
    public static getSingleDatePickerPhrases(): any {
        const phrasesStrings: Map<string, string> = StringsStore().registeredStringModules.get("calendarDatePickerPhrases").strings;

        const phrases: SingleDatePickerPhrases = {
            calendarLabel: phrasesStrings.get("calendarLabel"),
            closeDatePicker: phrasesStrings.get("closeDatePicker"),
            clearDate: phrasesStrings.get("clearDate"),
            jumpToPrevMonth: phrasesStrings.get("jumpToPrevMonth"),
            jumpToNextMonth: phrasesStrings.get("jumpToNextMonth"),
            keyboardShortcuts: phrasesStrings.get("keyboardShortcuts"),
            showKeyboardShortcutsPanel: phrasesStrings.get("showKeyboardShortcutsPanel"),
            hideKeyboardShortcutsPanel: phrasesStrings.get("hideKeyboardShortcutsPanel"),
            openThisPanel: phrasesStrings.get("openThisPanel"),
            enterKey: phrasesStrings.get("enterKey"),
            leftArrowRightArrow: phrasesStrings.get("leftArrowRightArrow"),
            upArrowDownArrow: phrasesStrings.get("upArrowDownArrow"),
            pageUpPageDown: phrasesStrings.get("pageUpPageDown"),
            homeEnd: phrasesStrings.get("homeEnd"),
            escape: phrasesStrings.get("escape"),
            questionMark: phrasesStrings.get("questionMark"),
            selectFocusedDate: phrasesStrings.get("selectFocusedDate"),
            moveFocusByOneDay: phrasesStrings.get("moveFocusByOneDay"),
            moveFocusByOneWeek: phrasesStrings.get("moveFocusByOneWeek"),
            moveFocusByOneMonth: phrasesStrings.get("moveFocusByOneMonth"),
            moveFocustoStartAndEndOfWeek: phrasesStrings.get("moveFocustoStartAndEndOfWeek"),
            returnFocusToInput: phrasesStrings.get("returnFocusToInput"),
            keyboardNavigationInstructions: phrasesStrings.get("keyboardNavigationInstructions"),

            chooseAvailableDate: this.chooseAvailableDateMessage,
            dateIsUnavailable: this.dateIsUnavailableMessage,
            dateIsSelected: this.dateIsSelectedMessage
        };
        return phrases;
    }

    /**
     * Returns the aria label for a date that can be selected by user as start date of a date range on the calendar
     */
    private static chooseAvailableStartDateMessage(currentDate: any): string {
        if (currentDate && currentDate.date) {
            return StringsStore().registeredStringModules.get("calendarDatePickerPhrases").strings.get("chooseAvailableStartDate").format(currentDate.date);
        } else {
            return "";
        }
    }

    /**
     * Returns the aria label for a date that can be selected by user as end date of a date range on the calendar
     */
    private static chooseAvailableEndDateMessage(currentDate: any): string {
        if (currentDate && currentDate.date) {
            return StringsStore().registeredStringModules.get("calendarDatePickerPhrases").strings.get("chooseAvailableEndDate").format(currentDate.date);
        } else {
            return "";
        }
    }

    /**
     * Returns the aria label for a date that can be selected on a single date picker calendar
     * Return just the date which is the default behavior for airbnb component
     */
    private static chooseAvailableDateMessage(currentDate: any): string {
        if (currentDate && currentDate.date) {
            return currentDate.date;
        } else {
            return "";
        }
    }

    /**
     * Returns the aria label for a date that cannot be selected on the calendar
     */
    private static dateIsUnavailableMessage(currentDate: any): string {
        if (currentDate && currentDate.date) {
            return StringsStore().registeredStringModules.get("calendarDatePickerPhrases").strings.get("dateIsUnavailable").format(currentDate.date);
        } else {
            return "";
        }
    }

    /**
     * Returns the aria label for a selected date on the calendar
     */
    private static dateIsSelectedMessage(currentDate: any): string {
        if (currentDate && currentDate.date) {
            return StringsStore().registeredStringModules.get("calendarDatePickerPhrases").strings.get("dateIsSelected").format(currentDate.date);
        } else {
            return "";
        }
    }
}
