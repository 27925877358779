import * as React from "react";
import AutomationUtils from "sh-application/utility/AutomationUtil";
import { AriaProperties, AriaRoles, generateDomPropertiesForAria } from "owa-accessibility";

require("./Spinner.scss");
const classNames = require("classnames/bind");
const SpinnerSVG = require("-!svg-react-loader?name=SVGImage!sh-assets/images/optimized-svg/Teams-spinner.svg");

export enum SpinnerSize {
    small = 1,
    medium,
    large
}

export interface SpinnerProps {
    size?: SpinnerSize;
    label?: string;
    className?: string;
}

/**
 * Animated loading spinner based on Teams Design
 */
export default class Spinner extends React.PureComponent<SpinnerProps, any> {
    public static defaultProps: SpinnerProps = {
        size: SpinnerSize.small
    };

    render() {
        const { className, label, size } = this.props;
        const spinnerClasses = classNames("ts-spinner", className);
        const sizeClass = classNames("ts-spinner-internal",
            { size1x: size === SpinnerSize.small },
            { size2x: size === SpinnerSize.medium },
            { size3x: size === SpinnerSize.large });

        const ariaProps: AriaProperties = {
            role: AriaRoles.alert,
            live: "polite"
        };

        return (
            <div
                className={ spinnerClasses }
                data-automation-id={ AutomationUtils.getAutomationId("scheduler", "QAIDSpinner") }>
                <div className={ sizeClass }>
                    <div className="ts-spinner-container">
                        <SpinnerSVG />
                    </div>
                </div>
                { label && (
                    <div className={ "ts-spinner-label" } { ...generateDomPropertiesForAria(ariaProps) }>{ label }</div>
                ) }
            </div>
            );
        }
}