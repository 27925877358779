import setIsProgressiveRendering from "sh-application/components/schedules/lib/actions/setIsProgressiveRendering";
import { orchestrator } from "satcheljs";
import { resetStore } from "../";

export default orchestrator(setIsProgressiveRendering, async actionMessage => {
    if (!actionMessage.inProgress) {
        // if progressive rendering is ending, reset the progressive store so that it can be garbage collected
        resetStore();
    }
});
