import * as React from "react";
import DynamicRouteImport from "./DynamicRouteImport";
import DynamicRouteSpinner from "./DynamicRouteSpinner";
import GlobalCommandBar from "./commandbar/GlobalCommandBar";
import GlobalMessageBar from "./common/GlobalMessageBar";
import NoMatch404 from "./NoMatch404";
import PrintWatcher from "./common/PrintWatcher";
import schedulesPrintListener from "sh-application/components/schedules/SchedulesPrintListener";
import TourGuide from "./tour/TourGuide";
import { AccessibilityAlert } from "sh-application/components/accessibilityAlert";
import { appViewState, closeTeamSetupPicker } from "sh-application/store";
import { default as LandingPage } from "./LandingPage";
import { Fabric } from "@fluentui/react";
import { observer } from "mobx-react";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import { TeamSetupPicker } from "sh-application/components/teamsetuppicker";
import GlobalSpinner from "sh-application/components/common/GlobalSpinner";
import { ConnectorAuthDialog } from "./common/ConnectorAuthDialog/ConnectorAuthDialog";

const OtherOpenShifts = (props: any) => (
    <DynamicRouteImport load={() => import(/* webpackChunkName: "OtherOpenShiftsTab" */ "../routes/otherOpenShifts/OtherOpenShiftsTab")}>
        {(Component: any) => Component == null
        ? <DynamicRouteSpinner />
        : <><GlobalCommandBar /><Component {...props} /></>}
    </DynamicRouteImport>
);

const Schedules = (props: any) => (
    <DynamicRouteImport load={ () => import(/* webpackPreload: true */ "./schedules/Schedules") }>
        { (Component: any) => Component === null
        ? <DynamicRouteSpinner />
        : <><Component {...props} /></> }
    </DynamicRouteImport>
);

const Settings = (props: any) => (
    <DynamicRouteImport load={ () => import(/* webpackChunkName: "Settings" */ "./teamsettings/TeamSettings") }>
        { (Component: any) => Component === null
        ? <DynamicRouteSpinner />
        : <><GlobalCommandBar /><Component {...props} /></> }
    </DynamicRouteImport>
);

const Requests = (props: any) => (
    <DynamicRouteImport load={ () => import(/* webpackChunkName: "Requests" */ "./shiftrequest/ShiftRequestsPanel") }>
        { (Component: any) => Component === null
        ? <DynamicRouteSpinner />
        : <><GlobalCommandBar /><Component {...props} /></> }
    </DynamicRouteImport>
);

const ImportScheduleEntrypoint = (props: any) => (
    <DynamicRouteImport load={ () => import(/* webpackChunkName: "ImportSchedule" */ "../routes/ImportSchedulePage") }>
        { (Component: any) => Component === null
        ? <DynamicRouteSpinner />
        : <><GlobalCommandBar /><Component {...props} /></> }
    </DynamicRouteImport>
);

// uncomment this if you need to use test pages locally
// const Testing = (props: any) => (
//     <DynamicRouteImport load={ () => import(/* webpackChunkName: "Testing" */ "./TestPageDynamicRoutes") }>
//         { (Component: any) => Component === null
//         ? <DynamicRouteSpinner />
//         : <><Component {...props} /></> }
//     </DynamicRouteImport>
// );

export interface LayoutProps extends RouteComponentProps<any> {
    groupId?: string;
    teamId?: string;
}

/**
 * Top level React component that defines the layout of the application.
 */
@observer
export default class Layout extends React.Component<LayoutProps, any> {

    /**
     * Close the team picker
     */
    private closeTeamSetupPicker = () => {
        closeTeamSetupPicker(appViewState().teamSetupPickerViewState);
    }

    render() {
        const { match } = this.props;

        return (
            <Fabric>
                <PrintWatcher listeners={ [ schedulesPrintListener ] }/>
                <AccessibilityAlert />
                <ConnectorAuthDialog />
                <GlobalMessageBar viewState={ appViewState().globalMessageViewState } />
                <Switch>
                    { /* Schedules page */ }
                    <Route exact path={ `${match.url}/schedules` } component={ Schedules } />
                    { /* Settings page */ }
                    <Route exact path={ `${match.url}/team` } component={ Settings } />
                    { /* Requests page */ }
                    <Route exact path={ `${match.url}/requests` } component={ Requests } />
                    <Route exact path={`${match.url}/otheropenshifts`} component={OtherOpenShifts} />
                    {/* Import Schedule page */ }
                    <Route exact path={ `${match.url}/importSchedule` } component={ ImportScheduleEntrypoint } />
                    { /* Test pages - uncomment if you need to use these locally */ }
                    {/* <Route path={ `${match.url}/test` } component={ Testing } /> */}
                    { /* Landing Page */ }
                    <Route exact path={ `${match.url}` } component={ LandingPage } />
                    { /* Catch all - route not found */ }
                    <Route component={ NoMatch404 } />
                </Switch>

                <TourGuide />
                { /* TeamSetupPicker can be launched from any page with the command bar.
                     Note: We pass in the TeamSetupPicker's view state as a prop, since dereferencing its properties here would cause this
                     outer component to re-render for every change. */ }
                <TeamSetupPicker
                    teamSetupPickerViewState={ appViewState().teamSetupPickerViewState }
                    history={ this.props.history }
                    onClose={ this.closeTeamSetupPicker } />
                { appViewState().globalSpinnerMessage &&
                        <GlobalSpinner label={ appViewState().globalSpinnerMessage } /> }
            </Fabric>
        );
    }
}