import { action } from "satcheljs";
import { AppSettingsClientModel } from "sh-models";

/**
 * Fire an action that sets the app settings for a tenant
 */
const setAppSettingsForTenant = action(
    "SET_APP_SETTINGS_FOR_TENANT",
    (
        appSettingsClientModel: AppSettingsClientModel
    ) => ({
        appSettingsClientModel: appSettingsClientModel
    })
);

export default setAppSettingsForTenant;
