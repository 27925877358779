import * as React from "react";
import Layout from "./Layout";
import setGlobalMessage from "sh-application/actions/setGlobalMessage";
import { appViewState } from "../store";
import { RouteComponentProps } from "react-router-dom";

/**
 * Top level React component that defines the layout of the application.
 */
export default class LayoutWrapper extends React.PureComponent<RouteComponentProps<any>, any> {
    render() {
        const { match } = this.props;
        const groupId = match && match.params && match.params.groupId;
        const teamId = match && match.params && match.params.teamId;

        // clear any existing global messages
        setGlobalMessage(appViewState().globalMessageViewState);

        return (
            <Layout
                { ...this.props }
                groupId={ groupId || "" }
                teamId={ teamId || "" } />
        );
    }
}