import { mutatorAction } from "satcheljs";
import { SchedulesViewStateStore } from "sh-application/components/schedules/lib/store/schema/SchedulesViewStateStore";

/**
 * Function that updates the view state to indicate if the view is being dragged
 * @param viewState - Schedules view state
 * @param isRowBeingDragged - row key for the row that is being moved
 */
export default mutatorAction("SET_IS_ROW_BEING_DRAGGED",
    function setIsRowBeingDragged(viewState: SchedulesViewStateStore, isRowBeingDragged: boolean) {
        viewState.isRowBeingDragged = isRowBeingDragged;
    }
);