import addOpenShiftsInStore from "../actions/addOpenShiftsInStore";
import DateUtils from "sh-application/utility/DateUtils";
import { IOpenShiftEntity, OpenShiftEntity } from "sh-models";
import { mutator } from "satcheljs";
import { OpenShiftStore } from "../";

export default mutator(addOpenShiftsInStore, ({ addedOpenShifts }) => {
    addedOpenShifts.forEach((openShift: IOpenShiftEntity) => {
        // Add to OpenShiftStore if the shift falls within cached date range. Else ignore.
        if (DateUtils.overlapsStartsOrEndsBetween(openShift.startTime, openShift.endTime, OpenShiftStore().cacheStartTime, OpenShiftStore().cacheEndTime, true /* includeStartEdge */, true /* includeEndEdge */)) {
            OpenShiftStore().openShifts.set(openShift.id, OpenShiftEntity.clone(openShift));
        }
    });
});