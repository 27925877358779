import IUserRegionEntity from 'sh-models/user/IUserRegionEntity';

export default class UserRegionEntity implements IUserRegionEntity {
    id: string;
    serviceUrl: string;
    serviceDirectUrl: string;
    afdServiceUrl: string;
    constructor(
        id: string,
        serviceUrl: string,
        serviceDirectUrl: string,
        afdServiceUrl: string
    ) {
        this.id = id;
        this.serviceUrl = serviceUrl;
        this.serviceDirectUrl = serviceDirectUrl || serviceUrl;
        this.afdServiceUrl = afdServiceUrl || serviceUrl;
    }

    public static fromJson(jsonData: IUserRegionEntity): IUserRegionEntity {
        if (!jsonData) {
            return null;
        }

        return new UserRegionEntity(jsonData.id, jsonData.serviceUrl, jsonData.serviceDirectUrl, jsonData.afdServiceUrl);
    }
}