import { action } from "satcheljs";
import { IAvailabilityEntity, IShiftEntity } from "sh-models";

const calculateShiftAvailabilityConflicts = action(
    "CALCULATE_SHIFT_AVAILABILITY_CONFLICTS",
    (
        memberId: string,
        shift: IShiftEntity,
        isRequestShift: boolean,
        availabilities: Map<string, IAvailabilityEntity[]>
    ) => ({
        memberId: memberId,
        shift: shift,
        isRequestShift: isRequestShift,
        availabilities: availabilities
    })
);

export default calculateShiftAvailabilityConflicts;