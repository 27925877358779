import { action } from "satcheljs";
import { Moment } from "moment";
import { ShiftHintDatePickerStoreSchema } from "../store";
import { IMemberEntity } from 'sh-models';

const setMonth = action(
    "SET_MONTH",
    (
        shiftHintDatePickerStore: ShiftHintDatePickerStoreSchema,
        month: Moment,
        member: IMemberEntity
    ) => ({
        shiftHintDatePickerStore: shiftHintDatePickerStore,
        month: month,
        member: member
    })
);

export default setMonth;
