import deleteNotesInView from "../mutators/notes/deleteNotesInView";
import notesModified from "sh-stores/sh-note-store/actions/notesModified";
import NoteUtils from "sh-application/utility/NoteUtils";
import schedulesViewStateStore from "../store/store";
import updateNotesInView from "../mutators/notes/updateNotesInView";
import { DataProcessingHelpers } from "sh-services";
import { INoteEntity } from "sh-models";
import { Moment } from "moment";
import { orchestrator } from "satcheljs";

/**
 * When updates to notes are triggered via the UI or via sync notifications, this orchestrator will be triggered.
 * It will parse the updated notes and pass those that fit into the current view to the updateNotesInView() mutator, which
 * will update the map of notes in the local view store. It also passes a set of notes to delete to deleteNotesInView()
 */
export default orchestrator(notesModified, async actionMessage => {
    const viewStart: Moment = schedulesViewStateStore().viewStartDate;
    const viewEnd: Moment = schedulesViewStateStore().viewEndDate;

    const addedAndUpdatedNotes: INoteEntity[] = actionMessage.notesAdded.concat(actionMessage.notesUpdated);
    let notesToUpdate: INoteEntity[] = DataProcessingHelpers.getDisplayableNotesInScheduleRange(addedAndUpdatedNotes, viewStart, viewEnd);
    let notesToDelete: INoteEntity[] = [];

    // push deleted notes in range but not for display into a delete array
    // deleted notes in range and for display get added to the update list
    for (let i = 0; i < actionMessage.notesDeleted.length; i++) {
        const note: INoteEntity = actionMessage.notesDeleted[i];
        const noteInRange: boolean = NoteUtils.isNoteInRange(note, viewStart, viewEnd);
        const noteDisplayableForView: boolean = NoteUtils.isNoteDisplayableForScheduleView(note);
        if (noteInRange) {
            if (noteDisplayableForView) {
                notesToUpdate.push(note);
            } else {
                notesToDelete.push(note);
            }
        }
    }

    if (notesToUpdate.length) {
        updateNotesInView(notesToUpdate);
    }

    if (notesToDelete.length) {
        deleteNotesInView(notesToDelete);
    }
});