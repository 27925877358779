import * as React from "react";
import { Callout, DirectionalHint } from "@fluentui/react";
import { TourBubbleContent } from "./TourBubbleContent";
import { TourBubbleProps } from "./TourBubble.props";
import { observer } from "mobx-react";

const styles = require("./TourBubble.scss");

@observer
export class TourBubble extends React.Component<TourBubbleProps, {}> {
    constructor(props: TourBubbleProps) {
        super(props);
        this.onNewProps(props);
    }

    onNewProps(props: TourBubbleProps) {
        if (props.onShow) {
            props.onShow();
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps: TourBubbleProps) {
        this.onNewProps(nextProps);
    }

    // Specify default props values
    static defaultProps: TourBubbleProps = {
        calloutProps: {
            beakWidth: 16,
            gapSpace: 16,
            setInitialFocus: true,
            doNotLayer: false,
            directionalHint: DirectionalHint.bottomAutoEdge
        },
        dismissOnOutsideClick: false
    };

    public render() {
        let { calloutProps, targetElement, onDismiss, dismissOnScroll } = this.props;

        return (
            <Callout
                setInitialFocus
                className={ styles.root }
                target={ targetElement }
                onDismiss={ onDismiss }
                preventDismissOnScroll={ !dismissOnScroll }
                {...calloutProps}>
                <TourBubbleContent { ...this.props } />
            </Callout>
        );
    }
}