import * as moment from "moment";
import DateUtils from "sh-application/utility/DateUtils";
import MemberUtils from "sh-application/utility/MemberUtils";
import UrlFactory from "sh-application/utility/UrlFactory";
import {
    ECSConfigKey,
    ECSConfigService,
    FlightSettingsService,
    ShiftRequestDataService,
    TeamPermissionsDataService,
    TimeClockDataService,
    UserStorageService
    } from "sh-services";
import {
    EmployeeViewType,
    EmployeeViewTypes,
    FlightKeys,
    LastViewedSchedule,
    ScheduleCalendarType,
    ScheduleCalendarTypes,
    ScheduleOverViewType,
    ScheduleOverViewTypes,
    TeamSettingEntity,
    UserStorageKeys
    } from "sh-models";
import { DayOfWeek } from "@fluentui/react";
import { getAvailabilities } from "sh-availability-store";
import { GetPastDateLimitParams } from "../../GetPastDateLimitParams";
import { getUniqueShifts } from "sh-uniqueshift-store";
import { getUniqueSubshifts } from "sh-uniquesubshift-store";
import { initializeSchedulesViewState, setShowConflictsFromUserSetting, updateScheduleViewDimensions } from "../index";
import { loadAndSetTeamLatestUsedFilters } from "../common/loadAndSetTeamLatestUsedFilters";
import { orchestrator } from "satcheljs";
import { PastDateLimitManager } from "../../../../../../managers/pastDate";
import { SchedulesViewStateStore } from "sh-application/components/schedules/lib/store/schema/SchedulesViewStateStore";
import { TeamStore, TeamStoreSchema } from "sh-team-store";
import {TeamSettingsStore} from "../../../../../sh-stores/sh-teamsettings-store";
import { TimeClockEntryStore } from "sh-stores/sh-timeclock-store";
import { trace } from "owa-trace";
import { UserStore } from "sh-stores";

const DEFAULT_GROUPED_STATE: boolean = true;

// default schedule view settings
export const defaultScheduleCalendarType: ScheduleCalendarType = ScheduleCalendarTypes.Week; // The Schedules page displays in Week mode by default
export const defaultScheduleOverviewType: ScheduleOverViewType = ScheduleOverViewTypes.PeopleView; // The Schedules page displays Members view by default

/**
 * This orchestrator initializes the schedules view state.  It should be used when bootstrapping a team schedule,
 * either during app launch or when switching teams.
 * - Calculate current schedule view range
 * - Sets up schedule view state settings
 * - Triggers fetch of schedule data for the current schedule view as needed
 */

export const initializeSchedulesViewStateOrchestrator = orchestrator(initializeSchedulesViewState, async actionMessage => {
        if (!actionMessage.viewState) {
            return;
        }

        try {
            // We populate the view state and trigger requests for additional data
            populateViewState(actionMessage.viewState);

            saveAsMostRecentTeam();
        } catch (e) {
            trace.warn(`initializeSchedulesViewState is failing with error: ${e}`);
        }
    }
);

function populateViewState(viewState: SchedulesViewStateStore) {
    // Setup the schedule view and load schedule data
    // Note: This executes asynchronously via updateScheduleViewDimensions, so anything that needs to
    // run after schedule setup should be added in updateScheduleViewDimensions.
    populateScheduleView(viewState);

    // Trigger load of auxiliary schedule related data
    // These are triggered in parallel and their data will light up in the schedule view as they are loaded.
    updateUniqueShiftsData();
    updateUniqueSubshiftsData();
    updateAvailabilitiesData();
    updateShiftRequestsData();
    updateTimeClockEntryData();
    updateConflictHiddenUserSettingData();
    loadAndSetTeamLatestUsedFilters(viewState, TeamStore().teamId);
}

/**
 * Populate the schedule view with schedule data
 */
async function populateScheduleView(viewState: SchedulesViewStateStore) {
    let employeeViewType: EmployeeViewType;
    let scheduleCalendarType: ScheduleCalendarType;
    let scheduleOverViewType: ScheduleOverViewType;
    let selectedDate: moment.Moment;

    const lastViewedSchedule = UserStorageService.getItem<LastViewedSchedule>(UserStorageKeys.LastViewedSchedule);
    const currentUser = TeamStore() && TeamStore().me;
    const isMemberAdmin = MemberUtils.isAdmin(currentUser);

    if (UrlFactory.isSchedulesPageDeepLink(window.location)) {
        scheduleCalendarType = UrlFactory.getScheduleTypeFromDeepLink(window.location);
        selectedDate = UrlFactory.getScheduleSelectedDateFromDeepLink(window.location);
        scheduleOverViewType = defaultScheduleOverviewType;
    } else if (lastViewedSchedule) {
        // Use the last viewed schedule settings if they were saved

        // Convert the persisted LastViewedSchedule's selectedDate from UTC to the current team timezone
        // We want the current schedule view selection to be the same regardless of the team's timezone.
        // If the user was viewing a specific date (eg, Dec 6 12am) and switched to a team with a different timezone, the same
        // date (eg, Dec 6 12am, not converted to the new timezone) should still be used for viewing the other team.
        // This is accomplished by "flattening" the selectedDate to UTC when persisting to local storage.
        const selectedDateParsed = moment.utc(lastViewedSchedule.selectedDate);  // parse selectedDate string as a UTC date time
        const selectedDateInCurrentTimezone = DateUtils.cloneMomentToConfiguredTimezone(selectedDateParsed);  // Convert from UTC to the team timezone with the same time values

        // Ensures that pastdatelimit is respected in inital values
        const timezone = TeamSettingEntity.getValueOrDefault(TeamSettingsStore().timeZoneOlsonCode);
        const currentDate = moment();

        const { teamId, tenantId } = TeamStore();
        const { startingDayOfWeek } = TeamSettingsStore();
        const { canSeeUnlimitedPastScheduleData, pastScheduleDataLimitInDays } = await TeamPermissionsDataService.getTeamPermissions(tenantId, teamId, false);
        // TODO(flwPrivacySettings): Remove casting
        const startDayOfWeek = TeamSettingEntity.getValueOrDefault(startingDayOfWeek) as keyof typeof DayOfWeek | null;

        const pastDateLimit = getPastDateLimit({
            date: currentDate,
            pastDateLimitInDays: pastScheduleDataLimitInDays,
            startDayOfWeek: startDayOfWeek ? DayOfWeek[startDayOfWeek] : null,
            timeZone: timezone,
            view: lastViewedSchedule.calendarType
        });

        const isBeforeDateLimit =
            !isMemberAdmin &&
            !canSeeUnlimitedPastScheduleData &&
            selectedDateInCurrentTimezone.isValid() &&
            pastDateLimit.isValid() &&
            selectedDateInCurrentTimezone.valueOf() < pastDateLimit.valueOf();

        // set selected date to today if it is before pastLimit
        selectedDate = isBeforeDateLimit ? currentDate : selectedDateInCurrentTimezone;

        scheduleCalendarType = lastViewedSchedule.calendarType;
        scheduleOverViewType = null;
    } else {
        // Default schedule view settings

        scheduleCalendarType = defaultScheduleCalendarType;
        scheduleOverViewType = defaultScheduleOverviewType;
        selectedDate = moment(); // The Schedules page displays the current day's schedule by default.
    }

    // Uses viewType from the last time state was viewed, or defaults if null
    if (viewState.employeeViewType) {
        employeeViewType = viewState.employeeViewType;
    } else {
        // default based on whether or not user is an admin
        employeeViewType = isMemberAdmin ? EmployeeViewTypes.TeamShiftsView : EmployeeViewTypes.YourShiftsView;
    }

    updateScheduleViewDimensions(
        viewState,
        true, /* isTeamBootstrap */
        employeeViewType,
        scheduleCalendarType,
        scheduleOverViewType,
        selectedDate,
        getIsViewGroupedUserSetting()
    );
}

/**
 * Ensure that unique shifts are loaded
 */
function updateUniqueShiftsData() {
    const teamStore = TeamStore();
    if (teamStore) {
        getUniqueShifts(teamStore.tenantId, teamStore.teamId);
    }
}

/**
 * Ensure that unique shifts are loaded
 */
function updateConflictHiddenUserSettingData() {
    const userStore = UserStore();
    if (userStore && userStore.userSettings) {
        setShowConflictsFromUserSetting(!userStore.userSettings.hideConflictManagement);
    }
}

/**
 * Ensure that unique subshifts are loaded
 */
function updateUniqueSubshiftsData() {
    const teamStore = TeamStore();
    if (teamStore) {
        getUniqueSubshifts(teamStore.tenantId, teamStore.teamId);
    }
}

/**
 * Ensure that availability data is loaded
 */
function updateAvailabilitiesData() {
    if (isAvailabilitiesEnabled()) {
        const teamStore = TeamStore();
        if (teamStore) {
            getAvailabilities(teamStore.tenantId, teamStore.teamId);
        }
    }
}

/**
 * Load shift requests data
 */
function updateShiftRequestsData() {
    if (isOpenShiftRequestsEnabled()) {
        const teamStore = TeamStore();
        if (teamStore) {
            // all of the teams active shift requests will be returned in the first request.
            // If the number of active requests is greater than our page size (25), service will still
            // return all of the active records and a single inactive record. If the number of active requests is smaller than
            // our page size, service will return as many inactive records as it can to fill up the page. In the Requests UI, we
            // then show a "Load more" link for the user to continue loading the remaining inactive requests.
            ShiftRequestDataService.getShiftRequestsForTeam(teamStore.tenantId, teamStore.teamId);
        }
    }
}

/*
* Load time clock entry data
*/
function updateTimeClockEntryData() {
    const timeclockEnabledForTeam = TeamStore().team && TeamStore().team.timeClockEnabled;
    const teamStore = TeamStore();
    if (isTimeClockOnWebEnabled() && timeclockEnabledForTeam && teamStore) {
        const teamId = TeamStore().teamId;
        const timeClockEntryStore = TimeClockEntryStore();
        if (timeClockEntryStore && teamId) {
            TimeClockDataService.getLatestTimeClockEntryForMember(teamId);
        }
    }
}

/**
 * Instead of setting the mostRecentTeam on navigate, we do it here, on load of Schedules,
 * which won't ever happen if the team doesn't exist.
 */
function saveAsMostRecentTeam() {
    const teamStore: TeamStoreSchema = TeamStore();
    if (teamStore && teamStore.teamId) {
        UserStorageService.setItem(UserStorageKeys.MostRecentTeamId, teamStore.teamId);
    }
}

/**
 * Function to determine if availabilities are enabled
 */
function isAvailabilitiesEnabled(): boolean {
    return ECSConfigService.isECSFeatureEnabled(ECSConfigKey.EnableScheduleAvailability)
        && FlightSettingsService.isFlightEnabled(FlightKeys.EnableScheduleAvailability);
}

/**
 * Returns true if the open shift requests feature is enabled
 */
function isOpenShiftRequestsEnabled(): boolean {
    return ECSConfigService.isECSFeatureEnabled(ECSConfigKey.EnableOpenShiftRequests) && FlightSettingsService.isFlightEnabled(FlightKeys.EnableOpenShiftRequestsOnWeb); // TODO: flight check;
}

/**
 * Returns the current value for the Grouped View user setting
 */
function getIsViewGroupedUserSetting(): boolean {
    // If EnableOnlyGroupedViewForSchedule is true, then force the grouped view, otherwise get the user preference(grouped or individual view) from the store.
    const isOnlyGroupedViewEnabled = ECSConfigService.isECSFeatureEnabled(ECSConfigKey.EnableOnlyGroupedViewForSchedule);

    return isOnlyGroupedViewEnabled ? true : UserStorageService.hasItem(UserStorageKeys.ScheduleGrouped) ? UserStorageService.getItem<boolean>(UserStorageKeys.ScheduleGrouped) : DEFAULT_GROUPED_STATE;
}

/**
 * Returns true if the time clock on web feature is enabled
 */
function isTimeClockOnWebEnabled(): boolean {
    return ECSConfigService.isECSFeatureEnabled(ECSConfigKey.EnableTimeClockOnWeb);
}

/**
 * Returns past date limit based on current time
 */
function getPastDateLimit(params: GetPastDateLimitParams): moment.Moment {
    const pastDateLimitManager = new PastDateLimitManager();
    return moment(pastDateLimitManager.getTimestampLimitForView(params));
}
