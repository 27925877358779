import setShowCreateRequestDialog from "../actions/setShowCreateRequestDialog";
import { setShowCreateRequestDialogInStore } from "../";
import { resetTimeOffInputStore } from "sh-application/components/shift/timeoff/timeoffInput/store/store";
import { orchestrator } from "satcheljs";

/**
 * This orchestrator will handle the calls needed when the Create Request Dialog appears or dissapears
 */
export default orchestrator(setShowCreateRequestDialog, async actionMessage => {
    const { visible } = actionMessage;
     /* Reset the timeoff UI store and mark timeoff as the new selection */
    resetTimeOffInputStore();
    setShowCreateRequestDialogInStore(visible);
});