import { IConflictDismissEntity } from "sh-models";
import { action } from "satcheljs";

const syncCreateConflictDismissal = action(
    "SYNC_CREATE_CONFLICT_DISMISSAL",
    (
        conflictDismissEntity: IConflictDismissEntity
    ) => ({
        conflictDismissEntity: conflictDismissEntity
    })
);

export default syncCreateConflictDismissal;