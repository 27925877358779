import {
    IBaseShiftEntity,
    IMemberEntity,
    IOpenShiftEntity,
    IShiftEntity,
    OpenShiftEntity,
    ScheduleCalendarType,
    ShiftEntity,
    ShiftType
    } from "sh-models";
import { IShiftEditorData, ShiftEditorViewStateSchema } from "../index";
import { Moment } from "moment";
import { mutatorAction } from "satcheljs";

/**
 * launchAddEditShiftTimeOffPanel action - launches the shiftEditor (add edit shift/timeoff) panel
 * @param shiftEditorData -- data bag for the types of shift
 * @param selectedPanelType -- used for both shift and timeoff
 * @param member -- used for both shift and timeoff
 * @param groupTagId -- used for shift
 * @param scheduleCalendarType -- used in instrumentation for shift
 * @param isEditShift -- used for shift
 * @param isEditTimeOff -- used for timeoff
 * @param viewState -- used by both shift and timeoff
 * @param startDate -- used by both shift and timeoff
 * @param isOpenShift -- used by shifts
 * @param elementToFocusOnDismiss - element to focus on when shift editor has been dismissed
 */
export default mutatorAction("launchShiftEditor",
    function launchShiftEditor(shiftEditorData: IShiftEditorData, selectedPanelType: ShiftType, member: IMemberEntity, groupTagId: string, scheduleCalendarType: ScheduleCalendarType, isEditShift: boolean, isEditTimeoff: boolean, viewState: ShiftEditorViewStateSchema , startDate?: Moment, isOpenShift?: boolean, elementToFocusOnDismiss?: any) {
        /** Flag used to determine if panel should open */
        viewState.isOpen = true;

        /** Flag used to determine if shift or timeoff panel needs to render in ShiftEditor */
        viewState.selectedPanelType = selectedPanelType;

        /** For creating new shifts/ time off events: Member id the shift will be assigned to */
        viewState.memberId = member ? member.id : "";

        /** For creating new shifts events: groupId the shift will be assigned to */
        viewState.shiftGroupTagId = groupTagId;

        /** For shift: this is used in instrumentation */
        viewState.scheduleCalendarType = scheduleCalendarType;

        /** For creating new shifts/openShifts: start date the shift will be assigned to */
        viewState.shiftStartDate = getStartTime(shiftEditorData, isEditShift, isOpenShift, startDate);

        /** For editing existing shifts: true/false */
        viewState.isEditShift = isEditShift;

        /** For editing existing timeoff: true/false */
        viewState.isEditTimeOff = isEditTimeoff;

        /** Is Openshifts: true/false */
        viewState.isOpenShift = isOpenShift;

        /** For editing existing shift: Shift to be edited (if addshift panel clone the shift object, if time off panel use the same shift) */
        viewState.shift = getShiftForPanel(shiftEditorData.shift, isEditShift);

        /** For editing existing time off events */
        viewState.timeoff = ShiftEntity.clone(shiftEditorData.timeoff);

        /** For editing existing open shifts */
        viewState.openShift = isEditShift ? OpenShiftEntity.clone(shiftEditorData.openShift as IOpenShiftEntity) : null;

         /** For creating new timeoff: start date the timeoff will be assigned to */
        viewState.timeOffStartDate = isEditTimeoff ? null : startDate;

         /** For creating new timeoff: end date the timeoff will be assigned to */
        viewState.timeOffEndDate = null;

        /** set all form errors to 0 */
        viewState.shiftFormErrors = 0;
        viewState.openShiftFormErrors = 0;
        viewState.timeoffFormErrors = 0;

        /** reset save / share diabled */
        viewState.disableSave = false;
        viewState.disableShare = false;

        /** element to focus on after the shift editor is dismissed */
        viewState.elementToFocusOnDismiss = elementToFocusOnDismiss;
    }
);

function getShiftForPanel(shift: IBaseShiftEntity, isEdit: boolean) {
    return isEdit ? ShiftEntity.clone(shift as IShiftEntity) : null;
}

function getStartTime(shiftEditorData: IShiftEditorData, isEdit: boolean, isOpenShift: boolean, startDate: Moment) {
    return isEdit ? (isOpenShift ? shiftEditorData.openShift.startTime.clone().startOf('day') : shiftEditorData.shift.startTime.clone().startOf('day')) : startDate;
}