import FileDownloadUtils from "sh-application/utility/FileDownloadUtils";
import RestClient, { reportExcelType } from "sh-rest-client";
import StringsStore from "sh-strings/store";
import { exportMatchShiftsReport } from "../index";
import { orchestrator } from "satcheljs";
import { TeamStore } from "sh-team-store";
import { trace } from "owa-trace";
/**
 * Action to export Match Schedule Report.
 */
export default orchestrator(exportMatchShiftsReport, async actionMessage => {
    if (TeamStore() !== null) {
        const teamId = TeamStore().teamId;
        const strings: Map<string, string> = StringsStore().registeredStringModules.get("exportShiftsPanel").strings;
        let authenticatedDownloadUrl = await RestClient.getDownloadTimeReportFileUrlV2(teamId, actionMessage.startDate, actionMessage.endDate, reportExcelType.scheduleReport);
        if (!authenticatedDownloadUrl) {
            throw new Error(strings.get("genericError"));
        } else {
            FileDownloadUtils.DownloadFile(authenticatedDownloadUrl);
            // only show notifications for dialog, will remove check once panel code is removed
        }
        trace.info("Downloaded time report as file");
    }
});