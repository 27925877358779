/**
 * Instrumentation Perf scenario names
 */
export const instrumentationPerfScenarios = {
    AppBootstrap: "AppBootstrap",
    TimeToFirstFullRender: "TimeToFirstFullRender",
    ServiceAPICall: "ServiceAPICall",
    ScheduleRender: "ScheduleRender",
    ScrollTestFPSMeasurement: "ScrollTestFPSMeasurement",
    TeamsAuthFailures: "TeamsAuthFailures",
    TeamSkypeTokenFailure: "TeamSkypeTokenFailure",
    InitializeInstrumentationService: "InitializeInstrumentationService"
};
