import deleteOpenShiftsInStore from "../actions/deleteOpenShiftsInStore";
import { IOpenShiftEntity } from "sh-models";
import { mutator } from "satcheljs";
import { OpenShiftStore } from "../";

/**
 * Receives open shifts with states that has been marked as Deleted by the TeamDataService. Removes the open shift models from the open shifts store they
 * should no longer be visible in the UI. Otherwise updates the open shifts in the store.
 */
export default mutator(deleteOpenShiftsInStore, ({ deletedOpenShifts }) => {
    deletedOpenShifts.forEach((openShift: IOpenShiftEntity) => {
        // delete if openShift is present in the store and is not displayed by View
        if (OpenShiftStore().openShifts.has(openShift.id)) {
            OpenShiftStore().openShifts.delete(openShift.id);
        }
    });
});