/**
 * The security policy violation error.
 */
export class SecurityPolicyViolationError extends Error {
    /**
     * Instantiates a new security policy violation error.
     * @param message The error message.
     */
    constructor(message: string) {
        super(message);
        this.name = "SecurityPolicyViolationError";
    }
}