import * as React from "react";
import ScheduleTeamPickerPanel from "../scheduleteampicker/ScheduleTeamPickerPanel";
import StaffHubPanel from "sh-application/components/common/StaffHubPanel";
import StringsStore from "sh-strings/store";
import { ActionButton, IPanelProps, IRenderFunction, PanelType } from "@fluentui/react";
import { AriaProperties, AriaRoles, generateDomPropertiesForAria } from "owa-accessibility";
import { getGenericEventPropertiesObject } from "sh-instrumentation";
import { InstrumentationService } from "sh-services";
import { ITeamInfoEntity } from "sh-models";
import { teamSelected } from "sh-team-store";
import { TeamSetupPickerStepProps } from "../TeamSetupPickerStepProps";
import { UrlFactory } from "sh-application";

const styles = require("./TeamPickerPanel.scss");
const panelWidth = "320px";

export interface TeamPickerPanelProps extends TeamSetupPickerStepProps {
    isOpen: boolean;
    disableAutofocus?: boolean;    // set to true to prevent the team picker panel from trying to set initial focus
}

/**
* Fabric panel component that allows a user to pick a team schedule to switch to, or start the schedule
* provisioning flow
*/
export default class TeamPickerPanel extends React.Component<TeamPickerPanelProps, {}> {
    private _strings: Map<string, string>;
    private _commonStrings: Map<string, string>;

    constructor(props: TeamPickerPanelProps) {
        super(props);
        this._commonStrings = StringsStore().registeredStringModules.get("common").strings;
        this._strings = StringsStore().registeredStringModules.get("scheduleTeamPicker").strings;
    }

    /**
     * Handler for when the user selects a team to view
     */
    private handleTeamSelected = (team: ITeamInfoEntity) => {
        InstrumentationService.logEvent(
            InstrumentationService.events.ScheduleTeamPicker,
            [getGenericEventPropertiesObject(InstrumentationService.properties.EventType, InstrumentationService.values.ScheduleTeamSelectedClicked)]);

        // Send the user to the team page of the selected team
        // TODO DCoh: Also, hook up the navigationHandler once Teams exposes it in their SDK so that forward/back navigating within Teams works properly.
        teamSelected(team, UrlFactory.isSchedulesPageLocation(this.props.history.location.pathname) /*doInitializeSchedulesViewState*/);
        this.props.history.push(UrlFactory.getSchedulesUrl(team.id));
        this.props.onClose();
    }

    /**
     * Handler for when the user clicks on the Create New Schedule entrypoint
     */
    private handleCreateNewScheduleClicked = () => {
        InstrumentationService.logEvent(
            InstrumentationService.events.ScheduleTeamPicker,
            [getGenericEventPropertiesObject(InstrumentationService.properties.EventType, InstrumentationService.values.CreateNewScheduleButtonClicked)]);

        this.props.onClose();
        this.props.onGotoProvisionTeamFlow();
    }

    /**
     * Renders the custom panel header
     * @return {JSX.Element}
     */
    private onRenderPanelHeader = () => {
        // return an empty fragment to not render a custom header in the StaffHubPanel
        return (
            <></>
        );
    }

    /**
     * Renders the custom panel navigation component
     * @return {JSX.Element}
     */
    private onRenderNavigationContent = (props: IPanelProps, defaultRender: IRenderFunction<IPanelProps>): JSX.Element => {
        const ariaProps: AriaProperties = {
            role: AriaRoles.heading,
            label: this._strings.get("selectTeamPanelTitle")
        };
        return (
            <>
                <div { ...generateDomPropertiesForAria(ariaProps) } className={ styles.navTitle }>{ this._strings.get("selectTeamPanelTitle") }</div>
                { defaultRender(props) }
            </>
        );
    }

    /**
     * Renders the custom panel footer component
     * @return {JSX.Element}
     */
    private onRenderFooterContent = (): JSX.Element => {
        return (
            <ActionButton
                className={ styles.createButton }
                onClick={ this.handleCreateNewScheduleClicked }
                iconProps={ { iconName: 'Add' } }
                allowDisabledFocus
                disabled={ false }
                checked={ false } >
                { this._strings.get("createNewScheduleButtonLabel") }
            </ActionButton>
        );
    }

    render() {
        const { isOpen, disableAutofocus, onClose, onCancel } = this.props;

        return (
            <StaffHubPanel
                contentClass={ styles.content }
                childrenContainer={ styles.childrenContainer }
                panelClass={ styles.teamPickerPanel }
                onDismiss={ onClose }
                onCancel={ onCancel }
                hasCloseButton
                cancelButtonLabel={this._commonStrings.get("closePanelAriaLabel")}
                isOpen={ isOpen }
                panelWidth={ panelWidth }
                panelType={ PanelType.customNear }
                isBlocking={ false }
                hideFooter
                isFooterAtBottom
                onRenderNavigationContent={ this.onRenderNavigationContent }
                onRenderFooterContent={ this.onRenderFooterContent }
                onRenderHeader={ this.onRenderPanelHeader }>

                <ScheduleTeamPickerPanel
                    instrumentationEventName={ InstrumentationService.events.ScheduleTeamPicker }
                    onTeamSelected={ this.handleTeamSelected }
                    onCreateNewScheduleClicked={ this.props.showCreateNewScheduleTeamEntrypoints ? this.handleCreateNewScheduleClicked : null }
                    autofocus={ !disableAutofocus } />
            </StaffHubPanel>
        );
    }

}