/**
 * Utilities for UI related behaviors
 */

/**
 * setTimeout delay for setting initial focus within a UX component
 * For some components where we want to set initial focus on a child component,
 * we use setTimeout to ensure that focus is applied successfully.
 * Without setTimeout (not the timeout itself, but the fact that it causes the focus handling to run
 * later in the lifecycle), setting focus on a child component does not always succeed, probably
 * because of the timing where the child might be setup/rendered later than the parent component.
 * One alternative to investigate is to try having the child component do the actual setting of the focus,
 * although this would require some work to setup the parent to trigger this handling.
 */
export const SET_INITIAL_FOCUS_DELAY_MS = 10;

export default class UiUtils {
    /**
     * Update input field and error message elements for Aria
     * Note:  This kind of handling is already handled by Office Fabric's TextField, and this is needed for other custom input
     * field implementations such as the OSS IntlTelInput component, which has no built in accessibility support.
     * @param inputFieldId element ID for the input field
     * @param errorMessageId element ID for the error message that is associated with the input field
     * @param baseAriaDescribedBy base aria-describedby value for the input field
     * @param enableError true to enable the error, false to clear it (ie, no errors)
     */
    public static updateAriaErrorMessaging(inputFieldId: string, errorMessageId: string, baseAriaDescribedBy: string, enableError: boolean) {
        let inputFieldElement: HTMLElement = document.getElementById(inputFieldId);
        if (inputFieldElement) {
            // Note: This assumes that the same error message element is used for all error messages for the input field.  Scenarios where
            // the error message element is changed to another element are not supported.
            const isErrorAlreadySetup: boolean = inputFieldElement.hasAttribute("aria-invalid");
            if (enableError && !isErrorAlreadySetup) {
                // Setup error messaging if it's not yet present
                // Mark the input field as invalid
                inputFieldElement.setAttribute("aria-invalid", "true");
                // Set the input field to be described by the error message element
                const ariaDescribedBy: string = baseAriaDescribedBy ? (baseAriaDescribedBy + " " + errorMessageId) : errorMessageId;
                inputFieldElement.setAttribute("aria-describedby", ariaDescribedBy);
            } else if (!enableError && isErrorAlreadySetup) {
                // Clear error messaging setup if it's present
                inputFieldElement.removeAttribute("aria-invalid");
                if (baseAriaDescribedBy) {
                    inputFieldElement.setAttribute("aria-describedby", baseAriaDescribedBy);
                } else {
                    inputFieldElement.removeAttribute("aria-describedby");
                }
            }
        }
    }
}
