import { createStore } from "satcheljs";
import { DismissedConflictsStoreSchema } from "./schema/ConflictStoreSchema";
import { IConflictDismissEntity } from "sh-models";
import { ObservableMap } from "mobx";

let DismissedConflictsStore = createStore<DismissedConflictsStoreSchema>(
    "DismissedConflictsStore",
    {
        entityIdToDismissedConflictEntities: new ObservableMap<string, IConflictDismissEntity>(),
        shiftIdToEntityIdMap: new ObservableMap<string, Array<string>>(),
        fetchStartTime: null,
        fetchEndTime: null,
        conflictIdToDismissIdMap: new ObservableMap<string, string>()
    });

export default DismissedConflictsStore;
