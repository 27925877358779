import { action } from "satcheljs";
import { IShiftEntity } from "sh-models";

const createShiftConflict = action(
    "CREATE_SHIFT_CONFLICT",
    (
        shift: IShiftEntity,
        conflictingShift: IShiftEntity,
        isRequestShift: boolean,
        memberId?: string
    ) => ({
        shift: shift,
        conflictingShift: conflictingShift,
        isRequestShift: isRequestShift,
        memberId: memberId
    })
);

export default createShiftConflict;