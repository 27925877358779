import { Persona, PersonaSize } from "@fluentui/react";
import { AriaProperties, AriaRoles, generateDomPropertiesForAria } from "owa-accessibility";
import * as React from "react";
import AccessibilityUtils from "sh-application/utility/AccessibilityUtils";
import StringUtils from "sh-application/utility/StringUtils";
import StringsStore from "sh-strings/store";

import { StaffHubTourMarker } from "../../tour/sh-tour/StaffHubTourMarker";

const styles = require("./OpenShiftTitleCell.scss");
const openShiftImageFRE = require("sh-assets/images/optimized-svg/OpenShifts.svg");
const classNames = require("classnames/bind");

interface OpenShiftTitleCellProps {
    numOpenShifts: number;      // Number of open shifts in this tag
    key: string;                // key for the persona component
    tagGroupNameAria: string;   // Aria label text for the tag group that the open shifts row belongs to
    openShiftRowIndex: number;  // Row index for the open shift row
    numOpenShiftRows: number;   // Number of open shift rows. More than one row will be rendered if there are more than one open shifts for a day
}

/**
 * OpenShiftTitleCell component
 * This renders the contents of an open shift title cell with rounded left edge
 */
export default class OpenShiftTitleCell extends React.Component<OpenShiftTitleCellProps, {}> {
    private _strings: Map<string, string>;

    constructor(props: OpenShiftTitleCellProps) {
        super(props);
        this._strings = StringsStore().registeredStringModules.get("schedulePage").strings;
    }

    /**
     * Renders a Persona component using the open shift image and the number of open shifts
     * @param numShifts
     */
    private renderPersona(numShifts: number) {
        const secondaryTextFormatString: string = StringUtils.usePluralForm(numShifts) ?
                                        this._strings.get("openShiftTileCellNumberOfShiftsPlural") :
                                        this._strings.get("openShiftTileCellNumberOfShiftsSingular");
        return (
            <Persona
                size={PersonaSize.size32}
                imageUrl={openShiftImageFRE}
                hidePersonaDetails={false}
                showSecondaryText={true}
                imageShouldFadeIn={true}
                text={this._strings.get("openShiftTitleCellPrimaryText")}
                aria-label={this._strings.get("openShiftTitleCellAriaLabel")}
                secondaryText={secondaryTextFormatString.format(String(numShifts))}
            />
        );
    }

    render() {
        const { numOpenShifts, tagGroupNameAria, openShiftRowIndex, numOpenShiftRows } = this.props;

        const ariaLabel: string = AccessibilityUtils.getOpenShiftRowAriaLabel(tagGroupNameAria, numOpenShifts, openShiftRowIndex, numOpenShiftRows);

        // Setup a rowheader to provide context for accessibility users when they navigate through shift cells within the open shift row.
        const ariaProps: AriaProperties = {
            role: AriaRoles.rowheader,
            label: ariaLabel
        };

        return (
            <div
                className={classNames(
                    StaffHubTourMarker.openshifts,
                    styles.openShiftTitleCellContainer,
                    styles.openShiftTitleCellContainerFRE
                )}
                {...generateDomPropertiesForAria(ariaProps)}>
                <div className={styles.printLine} />
                {this.renderPersona(numOpenShifts)}
            </div>
        );
    }
}