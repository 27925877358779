import ShiftrObjectModelBase from "sh-models/ShiftrObjectModelBase";
import { ObservableMap, transaction } from "mobx";

/**
 * Generic function to set values in an observable map in a store with set an array of entites
 * @param {Array<T>} clientModels - array of client models that needs to be set in the store
 */

export default function setEntitiesInMap<T extends ShiftrObjectModelBase>(clientModels: Array<T>, storeMap: ObservableMap<string, T>) {
    transaction(() => {
        if (!clientModels || !clientModels.length) {
            return;
        }

        for (let i = 0; i < clientModels.length; i++) {
            const clientModel = clientModels[i];
            if (clientModel && clientModel.id) {
                // hack to force refresh of the UI
                storeMap.set(clientModel.id, null);
                storeMap.set(clientModel.id, clientModel);
            }
        }
    });
}