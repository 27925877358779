import showConfirmDialog from "../components/ConfirmDialog";
import { DialogResponse } from "./DialogResponse";

export interface ConfirmCustomizationOptions {
    bodyElement?: JSX.Element;
    initialAriaAlert?: string;
    okText?: string;
    disableOkayButton?: boolean /* To enable or disable OK button */;
    hideOkButton?: boolean;
    cancelText?: string;
    hideCancelButton?: boolean;
    delayCallbackAfterAnimation?: boolean;
    containerClassName?: string;
    isBlocking?: boolean;
    callCancelOnDismiss?: boolean;
    showCloseButton?: boolean;
}

/**
 * Wrapper to show a confirmation dialog before running an action (or can be immediately resolved
 * without showing dialog if needed)
 * @param{string} title - Title displayed at the top of dialog
 * @param{subText} subText (optional) - text displayed (in smaller font) below title
 * @param{boolean} resolveImmediately - if true, the dialog is not displayed and the OK callback is run
 * @param{ConfirmCustomizationOptions} customizationOptions (optional)
 */
export default function confirm(
    title: string,
    subText?: string,
    resolveImmediately?: boolean,
    customizationOptions?: ConfirmCustomizationOptions
): Promise<{}> {
    if (resolveImmediately) {
        // This is useful in cases where the user wants to display the
        // dialog only if a certain condition is met. It will allow
        // to keep the code simple for who uses it.
        return Promise.resolve(DialogResponse.ok);
    }

    return new Promise((resolve, reject) => {
        showConfirmDialog(
            title,
            subText,
            customizationOptions,
            () => {
                resolve(DialogResponse.ok);
            },
            () => {
                reject(DialogResponse.cancel);
            },
            customizationOptions?.disableOkayButton,
            customizationOptions?.showCloseButton,
            customizationOptions?.callCancelOnDismiss
        );
    });
}
