import { mutatorAction } from "satcheljs";
import { SchedulesViewStateStore } from "sh-application/components/schedules/lib/store/schema/SchedulesViewStateStore";

/**
 * Updates the visibility of the confirm copy date range dialog
 * @param viewState - Schedule view state
 * @param isOpen - boolean determining the visibility of the dialog
 */
export default mutatorAction("SET_IS_COPY_DATE_RANGE_DIALOG_OPEN",
    function setIsCopyDateRangeDialogOpen(viewState: SchedulesViewStateStore, isOpen: boolean) {
        viewState.isCopyDateRangeDialogOpen = isOpen;
    }
);