import { createStore } from "satcheljs";

import { TeamPermissionsStoreSchema } from "./TeamPermissionsStoreSchema";

/* Register Orchestrators */

/**
 * Store for Team Permissions data.
 */
export const TeamPermissionsStore = createStore<TeamPermissionsStoreSchema>(
    "TeamPermissionsStore",
    {
        permission: null
    });