import setEntitiesInMap from "sh-stores/setEntitiesInMap";
import { action } from "satcheljs/lib/legacy";
import { IMemberEntity } from "sh-models";
import { TeamStore } from "sh-team-store";
import { transaction } from "mobx";

export let setMembers = action("setMembers")(
    /**
     * Updates existing members with a new member object
     */
    function setMembers(members: IMemberEntity[]) {
        transaction(() => {
            setEntitiesInMap(members, TeamStore().members);

        });
    });