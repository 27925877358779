import * as React from "react";
import setLandingPageMode from "./setLandingPageMode";
import StaffHubHttpErrorUtils from "../utility/StaffHubHttpErrorUtils";
import StringsStore from "sh-strings/store";
import TeamSetupPickerUtils, { ITeamSetupPickerEntrypointsState } from "sh-application/components/teamsetuppicker/TeamSetupPickerUtils";
import { appViewState, launchTeamSetupPicker } from "sh-application/store";
import { getGenericEventPropertiesObject, InstrumentationEventPropertyInterface, logPageView } from "sh-instrumentation";
import { Image } from "@fluentui/react";
import { InstrumentationService } from "sh-services";
import { LandingPageMode, landingPageViewState } from "./LandingPageViewState";
import { observer } from "mobx-react";
import { setBlockingError } from "sh-application/components/errorWatcher";
import { setShowTeamSetupPickerEntrypoints } from "sh-application/store";
import { TeamSetupPickerMode } from "sh-application/components/teamsetuppicker";

const styles = require("./LandingPage.scss");

const emptyMessageImage = require("sh-assets/images/optimized-svg/Shifts_EmptyState_desktop_NoSchedules.svg");

/**
 * This is a blank landing page that just pops open the team picker component
 */
@logPageView("LandingPage")
@observer
export default class LandingPage extends React.Component<any, any> {
    private _strings: Map<string, string>;

    constructor(props: any) {
        super(props);
        this._strings = StringsStore().registeredStringModules.get("landingPage").strings;
    }

    /**
     * This function is called from PageViewLoggerDecorator to allow a page to specify custom instrumentation properties
     */
    public getPageViewInstrumentationData(): Array<InstrumentationEventPropertyInterface> {
        let eventDataArray: Array<InstrumentationEventPropertyInterface> = [];
        const landingPageMode: LandingPageMode = landingPageViewState().landingPageMode;
        const isDataAvailable: boolean = Boolean(landingPageMode !== LandingPageMode.NotInitialized);
        eventDataArray.push(getGenericEventPropertiesObject(InstrumentationService.properties.IsDataAvailable, isDataAvailable));
        eventDataArray.push(getGenericEventPropertiesObject(InstrumentationService.properties.LandingPageMode, landingPageMode));
        return eventDataArray;
    }

    async componentDidMount() {
        const landingPageMode: LandingPageMode = await this.getLandingPageMode();
        setLandingPageMode(landingPageMode);

        // Launch the schedule team switcher or create new schedule UX if needed
        if ((landingPageMode === LandingPageMode.ShowCreateNewSchedule) ||
            (landingPageMode === LandingPageMode.ShowScheduleTeamSwitcher)) {
            const teamSetupPickerMode: TeamSetupPickerMode = (landingPageMode === LandingPageMode.ShowCreateNewSchedule) ? TeamSetupPickerMode.ProvisionTeam : TeamSetupPickerMode.SwitchTeams;
            launchTeamSetupPicker(
                appViewState().teamSetupPickerViewState,
                true /* isOnboarding */,
                teamSetupPickerMode,
                false /* isUserDismissable */,
                false /* useTeamPickerPanel */);
        }
    }

    /**
     * Calculate the mode for the landing page
     */
    // TODO DCoh: Setup a unit test that verifies this logic
    private calculateLandingPageMode(teamSetupPickerEntrypointsState: ITeamSetupPickerEntrypointsState): LandingPageMode {
        let landingPageMode: LandingPageMode = LandingPageMode.ShowScheduleTeamSwitcher;
        if (teamSetupPickerEntrypointsState.scheduleTeamsManagedByMSTeams.length > 0) {
            // User has MS Team schedules they can choose from
            // Show the schedule team switcher UX
            landingPageMode = LandingPageMode.ShowScheduleTeamSwitcher;
        } else if (teamSetupPickerEntrypointsState.msAdminTeamInfosWithoutSchedules.length > 0) {
            // User has not schedules but is an admin for MS Teams and therefore can create schedules for them
            // Show the create new schedule UX
            landingPageMode = LandingPageMode.ShowCreateNewSchedule;
        } else if (teamSetupPickerEntrypointsState.msTeamInfos.length === 0) {
            // User has no schedules or teams
            // Show the empty state message - join or create some teams
            landingPageMode = LandingPageMode.ShowEmptyMessageNoTeams;
        } else {
            // User belongs to MS Teams, but they have no schedules and the user is not an admin and thus cannot create schedules for them either
            // Show the empty state message - no schedules created yet for your team
            landingPageMode = LandingPageMode.ShowEmptyMessageNoSchedulesForTeams;
        }
        return landingPageMode;
    }

    /**
     * Get the landing page mode based on the user's teams data
     */
    private async getLandingPageMode(): Promise<LandingPageMode> {
        let landingPageMode: LandingPageMode = LandingPageMode.ShowScheduleTeamSwitcher;

        try {
            const teamSetupPickerEntrypointsState: ITeamSetupPickerEntrypointsState = await TeamSetupPickerUtils.calculateTeamSetupPickerEntrypointsState(true /* throwOnError */);

            // Update the teamSetupPicker viewstate with the calculated entrypoint info, since various
            // team setup UX will use this info to show/hide UX.
            setShowTeamSetupPickerEntrypoints(
                appViewState().teamSetupPickerViewState,
                teamSetupPickerEntrypointsState.showTeamSwitcherEntrypoint,
                teamSetupPickerEntrypointsState.showCreateNewScheduleEntrypoint);

            landingPageMode = this.calculateLandingPageMode(teamSetupPickerEntrypointsState);
        } catch (error) {
            const commonStrings = StringsStore().registeredStringModules.get("common").strings;
            const errorMessage = StaffHubHttpErrorUtils.getErrorMessage(error);
            setBlockingError({
                title: "",
                primaryText: errorMessage,
                actionButton: {
                    useAppRestartAction: true,
                    actionButtonText: commonStrings.get("refresh")
                }
            });

            landingPageMode = LandingPageMode.NotInitialized;
        }

        return landingPageMode;
    }

    /**
     * Render message screen
     * @param title title text
     * @param subtitle subtitle text. null for no subtitle.
     * @param imageSrc image src. null for no image
     */
    private renderMessageScreen(title: string, subtitle: string, imageSrc: string): JSX.Element {
        return (
            <div className={ styles.messageContainer }>
                {
                    imageSrc &&
                        <Image className={ styles.messageImage } src={ imageSrc } />
                }
                <div className={ styles.title }>{ title }</div>
                {
                    subtitle &&
                        <div className={ styles.subtitle }>{ subtitle }</div>
                }
            </div>
        );
    }

    render() {
        const landingPageMode: LandingPageMode = landingPageViewState().landingPageMode;
        return (
            <div className={ styles.fillFullPage }>
            {
                (landingPageMode === LandingPageMode.ShowEmptyMessageNoTeams) &&
                    this.renderMessageScreen(this._strings.get("emptyMessageNoTeamsTitle"), this._strings.get("emptyMessageNoTeamsSubtitle"), emptyMessageImage /* imageSrc */)
            }
            {
                (landingPageMode === LandingPageMode.ShowEmptyMessageNoSchedulesForTeams) &&
                    this.renderMessageScreen(this._strings.get("emptyMessageNoSchedulesForTeamsTitle"), null /* subtitle */, emptyMessageImage /* imageSrc */)
            }
            </div>
        );
    }
}