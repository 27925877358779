import * as React from "react";
import ConflictIconButton from "./conflictDetails/ConflictIconButton";
import DateTimeFormatter, { DateTimeFormatType } from "sh-application/utility/DateTimeFormatter";
import DateUtils from "sh-application/utility/DateUtils";
import ShiftUtils from "sh-application/utility/ShiftUtils";
import StringsStore from "sh-strings/store";
import { appViewState } from "../../store";
import { Icon } from "@fluentui/react";
import { observer } from "mobx-react";
import { ScheduleCellRenderSize } from "sh-application/../StaffHubConstants";
import { ShiftCellProps } from "./ShiftCellProps";
import { TagStore } from "sh-tag-store";

const styles = require("./Shift.scss");
const classNames = require("classnames/bind");

export interface ShiftProps extends ShiftCellProps {
    /* true if the icons in the upper right of the shift should be rendered */
    doShowIcons?: boolean;

    /* true if the shift notes should be rendered */
    doShowNotes?: boolean;

    /* true if the group/tag name should be rendered */
    showGroupName?: boolean;

    /* optional paramater for distinguishing unique shifts from regular shifts */
    isUniqueShift?: boolean;

    /**
     * If false, do not show unshared indicators
     */
    showUnsharedStatus?: boolean;
    /**
     * If the date needs to be specified when rendering the shift, the whole shift changes to display this info
     */
    showDate?: boolean;

    /**
     * true if the 24 shift format should be displayed
     */
    doShow24HrShift?: boolean;

    /**
     * Boolean to hide the title for 24hr shift if needed. Title is hidden in the month view
     */
    hideTitleFor24HrShift?: boolean;
}

/**
 * Shift
 */
@observer
export default class Shift extends React.Component<ShiftProps, {}> {
    private static _notesAriaLabel = "";
    private static _breaksAriaLabel = "";
    private static _activitiesAriaLabel = "";

    // this is only used by optional params but the required params are included as well so this can use TS types
    public static defaultProps: ShiftProps = {
        doShowIcons: false,
        doShowNotes: false,
        showGroupName: false,
        isUniqueShift: false,
        showUnsharedStatus: false,
        showDate: false,
        shift: null,
        scheduleCellRenderSize: null,
        doShowConflictIcon: false
    };

    constructor(props: ShiftProps) {
        super(props);
        if (!Shift._notesAriaLabel) {
            Shift._notesAriaLabel = StringsStore().registeredStringModules.get("shifts").strings.get("hasNotesIndicatorAriaLabel");
        }
        if (!Shift._breaksAriaLabel) {
            Shift._breaksAriaLabel = StringsStore().registeredStringModules.get("shifts").strings.get("hasBreaksIndicatorAriaLabel");
        }
        if (!Shift._activitiesAriaLabel) {
            Shift._activitiesAriaLabel = StringsStore().registeredStringModules.get("shifts").strings.get("hasActivitiesIndicatorAriaLabel");
        }

    }

    private isRenderSmallCell(): boolean {
        return this.props.scheduleCellRenderSize === ScheduleCellRenderSize.Small;
    }

    private isRenderNormalCell(): boolean {
        return this.props.scheduleCellRenderSize === ScheduleCellRenderSize.Normal;
    }

    private isRenderLargeCell(): boolean {
        return this.props.scheduleCellRenderSize === ScheduleCellRenderSize.Large;
    }

    private shouldRenderBreaksIcon(): boolean {
        const { shift, doShowIcons } = this.props;
        let shouldRender = false;
        if (shift && doShowIcons) {
            shouldRender = ShiftUtils.getUnPaidShiftBreakDurationInShift(shift) > 0;
        }

        return shouldRender;
    }

    private shouldRenderActivityIcon(): boolean {
        const { shift, doShowIcons } = this.props;
        let shouldRender = false;
        if (shift && doShowIcons) {
            shouldRender = ShiftUtils.hasActivities(shift);
        }

        return shouldRender;
    }

    /**
     * Renders the icons in the upper right portion of the shift.
     */
    private renderIcons(): JSX.Element {
        const { shift, showGroupName, doShowConflictIcon } = this.props;

        const isUngrouped = !!showGroupName;
        const renderBreakIcon = this.shouldRenderBreaksIcon();
        const renderActivityIcon = this.shouldRenderActivityIcon();

        // never render icons in the small cell
        const renderElement = !isUngrouped && !this.isRenderSmallCell() && (renderBreakIcon || renderActivityIcon);

        const isOpenShift = ShiftUtils.isOpenShift(shift);
        const iconContainerClasses = classNames(styles.shiftIconContainer, {[styles.largeCell]: this.isRenderLargeCell()}, {[styles.openShift]: isOpenShift}, {
            [styles.conflictingShift]: this.isRenderLargeCell() && doShowConflictIcon});
        return renderElement ? (
            <div className={ iconContainerClasses }>
                {
                    renderBreakIcon &&
                        <Icon className={ styles.breakClockIcon } iconName={ "teams-cup-small" } ariaLabel={ Shift._breaksAriaLabel } ></Icon>
                }
                {
                    renderActivityIcon &&
                        <Icon className={ styles.activityIcon } iconName={ "teams-activities-small" } ariaLabel={ Shift._activitiesAriaLabel } ></Icon>
                }
                </div>
        ) : null;
    }

    private renderTitle(): JSX.Element {
        const { shift, showDate, doShow24HrShift, hideTitleFor24HrShift, doShowConflictIcon } = this.props;
        const is24HrShift = doShow24HrShift && DateUtils.is24HrShift(shift.startTime, shift.endTime);
        let output: JSX.Element;
        let title = "";
        const showConflict = doShowConflictIcon && !ShiftUtils.isOpenShift(shift);
        // If its month view for 24 hr shift with conflict, conflict Icon should replace the 24 Hr icon.
        const hide24HrShiftIconWhenConflict = showConflict && this.isRenderSmallCell() && is24HrShift;
        const showFullDayIcon = is24HrShift && !hide24HrShiftIconWhenConflict;

        // If its month view, title will be hidden for 24Hr shift, but else shown
        // But its 24hr shift with conflict, title and conflict icon are rendered
        const showTitleAndConflictBadge = !(is24HrShift && hideTitleFor24HrShift && !showConflict);

        if (shift) {
            if (showDate) {
                title = is24HrShift
                    ? DateTimeFormatter.get24HrShiftFormat(shift.startTime, DateTimeFormatType.Date_DateMonthYearLong)
                    : DateTimeFormatter.getDateTimeAsString(shift.startTime, DateTimeFormatType.Date_DateMonthYearLong);
            } else {
                if (shift.title) {
                    title = shift.title;
                } else {
                    title = is24HrShift
                        ? DateTimeFormatter.get24HrShiftFormat(shift.startTime)
                        : DateTimeFormatter.getEventTimeRangeAsString(shift.startTime, shift.endTime);
                }
            }

            if (title && this.isRenderSmallCell()) {
                // If we are printing we will show the substring and hide the icon for small cells
                // TODO: Need to find a way to prevent overlap with conflict icon on high zoom.
                const isPrinting = appViewState().printViewState && appViewState().printViewState.isPrinting;
                title = isPrinting ? title.substring(0, 3) : title;
            }

            output = <div className={ classNames(styles.titleIconContainer, { [styles.conflictIconButton]: showConflict }) } >
                { showFullDayIcon && <Icon className={ styles.fullDayIcon } iconName="teams-full-day-icon" /> }
                { showTitleAndConflictBadge && <div className={ styles.title } aria-hidden={true}>{ title }
                { showConflict && <ConflictIconButton onConfictIconBadgeClick ={ this.props.onConfictIconBadgeClick } /> }
                </div> }
            </div>;
        }

        return output;
    }

    private renderSecondSubtitle(): JSX.Element {
        const { shift, doShowNotes, showDate, showGroupName} = this.props;
        let output: JSX.Element;
        let subtitle: string = "";

        if (shift) {
            if (showDate && showGroupName) {
                // show group name in the second subtitle because the time range is taking up the first subtitle.
                const tagId = ShiftUtils.getTagIdFromShift(shift);
                const tag = TagStore().tags.get(tagId);
                subtitle = tag && tag.name;
            } else if (doShowNotes && !(ShiftUtils.isOpenShift(shift) && showGroupName) && (this.isRenderNormalCell() || this.isRenderLargeCell())) {
                // Render shift notes if the shift should show notes, and we are not rendering an open shift with a group name
                // The last case occurs in ungrouped view, where we do not have space to render both the group name and the notes
                subtitle = shift.notes;
            }

            output = subtitle ? <div className={ styles.subtitle } aria-hidden={true}>{ subtitle }</div> : null;
        }

        return output;
    }

    private renderFirstSubtitle(): JSX.Element {
        const { shift, showGroupName, showDate, doShowConflictIcon } = this.props;
        let output: JSX.Element;
        let subtitle: string;
        const showConflict = doShowConflictIcon && this.isRenderSmallCell();
        if (shift) {
            if (showDate) {
                // the date is in the title so make the first subtitle the hours
                subtitle = DateTimeFormatter.getEventTimeRangeAsString(shift.startTime, shift.endTime);
            } else if (showGroupName) {
                const tagId = ShiftUtils.getTagIdFromShift(shift);
                const tag = TagStore().tags.get(tagId);
                // If there is conflict, the icon will only be shown and no subtitle in small cell
                subtitle = showConflict ? "" : tag && tag.name;
            }
            output = subtitle ? <div className={ styles.subtitle } aria-hidden={true}>{ subtitle }</div> : null;
        }
         return output;
    }

    render() {
        const { shift, isUniqueShift } = this.props;

        const unsharedChangesLabel = ShiftUtils.shiftHasUnsharedEdits(shift) ? ShiftUtils.getUnsharedChangesShiftIndicatorAriaLabel() : "";
        const ariaLabel = isUniqueShift ? "" : unsharedChangesLabel + ShiftUtils.calculateShiftTitleAriaLabel(shift);

        const shiftStyles = classNames(styles.shift,
            { [styles.small]: this.isRenderSmallCell() });

        const contentStyles = classNames(
                styles.left,
                { [styles.published]: !ShiftUtils.shiftHasUnsharedEdits(shift) },
                { [styles.uniqueShift]: this.props.isUniqueShift },
                { [styles.suppressedEditStatus]: !this.props.showUnsharedStatus }
        );

        return (
            <div className={ shiftStyles }>
                <div className={ contentStyles }
                    aria-label={ariaLabel}>
                    { this.renderTitle() }
                    { this.renderFirstSubtitle() }
                    { this.renderSecondSubtitle() }
                </div>
                { this.renderIcons() }
            </div>
        );
    }
}