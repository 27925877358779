import indexComparator from "sh-application/utility/indexComparator";
import StringsStore from "sh-strings/store";
import StringUtils from "./StringUtils";
import { DataProcessingHelpers } from "sh-services";
import {
    IMemberEntity,
    IShiftEntity,
    ITagEntity,
    TagEntity,
    TagStates
    } from "sh-models";
import { MobxUtils } from "sh-application";
import { TagStore } from "sh-tag-store";
import { trace } from "owa-trace";

/**
 * Utilities for Tags/Groups
 */
export default class TagUtils {
    /**
     * Default Tag id when member is not assigned to any tags. This is also used to show shifts that has mismatched tags.
     * This is the tag id used for the "Other" group during rendering.
     */
    public static readonly DEFAULT_TAG_ID: string = "default_tag_id";         // Note:  DO NOT LOCALIZE.

    /**
     * Returns true if the given tagId is the default tag id.
     * @param tagId
     */
    public static isDefaultTag(tagId: string) {
        return tagId === this.DEFAULT_TAG_ID;
    }

    /**
     * Method that checks to see if a member has been removed from a tag
     * @param tagId - id of the tag
     * @param memberId - id of the member
     * @returns boolean, true if the member is not present in the tag's member ids
     */
    public static isMemberRemovedFromTag(tagId: string, memberId: string): boolean {
        if (!tagId) {
            // if there is no tag id, like in ungrouped view
            return false;
        }
        let isMemberRemovedFromTag = false;
        const tag = TagStore().tags.get(tagId);
        if (tag && tag.memberIds) {
            isMemberRemovedFromTag = tag.memberIds.indexOf(memberId) === -1;
        } else {
            trace.warn(`No tag or memberids found for tagId: ${tagId}`);
        }

        return isMemberRemovedFromTag;
    }

    /**
     * Returns true for active tags
     * @param tag
     */
    public static isActiveTag(tag: ITagEntity) {
        return tag && tag.state === TagStates.Active;
    }

    /**
     * Get the maximum index value of the Tags
     * @returns {Number} maximum index
     */
    public static getMaxTagIndex () {
        let maxIndex: number = 0;
        let tags: ITagEntity[] = MobxUtils.MapToArray(TagStore().tags);
        for (let i = 0; i < tags.length; i++) {
            let tag: ITagEntity = tags[i];
            if (tag.index != null && tag.index > maxIndex) {
                maxIndex = tag.index;
            }
        }
        // since we did not always enforce that all tags must have an index,
        // if there are more tags than the current max index+1, use the size-1 as the max index
        if (tags.length - 1 > maxIndex) {
            maxIndex = tags.length - 1;
        }
        return maxIndex;
    }

    public static tagIsEmptyOrDefault(tagId: string): boolean {
        return tagId === "" || tagId === TagUtils.DEFAULT_TAG_ID;
    }

    /**
     * Gets the tag names for member
     */
    public static getTagNamesForMember(member: IMemberEntity): string[] {
        let tagNames: string[] = [];
        const unnamedGroup: string = StringsStore().registeredStringModules.get("common").strings.get("unnamedGroup");

        if (member && TagStore && TagStore().tags) {
            TagStore().tags.forEach((tag: ITagEntity) => {
                if (TagUtils.isActiveTag(tag) && tag.memberIds) {
                    if (tag.memberIds.indexOf(member.id) !== -1) {
                        const tagName: string = !!tag.name ? tag.name : unnamedGroup;
                        tagNames.push(tagName);
                    }
                }
            });
        }
        return tagNames;
    }

    /**
     * Get all the tags out of the list that the member is on
     * @param tags
     * @param member
     * @param shifts
     */
    public static getTagsForMember(tags: ITagEntity[], member: IMemberEntity, shifts: IShiftEntity[]): ITagEntity[] {
        // Build a list of tag IDs based on the shifts assigned to a member
        let tagIdsOfAssignedShifts = new Set();
        shifts?.forEach(shift => {
            if (shift.memberId === member.id && shift.tagIds?.length) {
                shift.tagIds.forEach(tagId => {
                    tagIdsOfAssignedShifts.add(tagId);
                });
            }
        });

        function filterFunction(tag: ITagEntity) {
            return tagIdsOfAssignedShifts.has(tag.id) || tag.memberIds.includes(member.id);
        }
        return tags ? tags.filter(filterFunction) : [];
    }

    /**
     * Function that looks up a member in a tag array and moves it by -1 or +1
     * Used to handle keyboard based move in the schedules page
     * @param member - Member object that needs to be moved
     * @param tagId - Id of the tag in which the member needs to be moved
     * @param delta - delta by which the member needs to be moved ( -1 for moving up by 1, +1 for moving down by 1)
     * @return ITagEntity - cloned tag object with reordered member
     */
    public static moveTagMemberUpOrDown(member: IMemberEntity, tagId: string, delta: -1 | 1): ITagEntity {
        const tag = TagStore().tags.get(tagId);
        let updatedTag = TagEntity.clone(tag);
        const memberId = member.id;
        const currentIndex = tag.memberIds.indexOf(memberId);

        if (currentIndex > -1) {
            const targetIndex = currentIndex + delta;
            if (targetIndex < 0 || targetIndex >= tag.memberIds.length) {
                // trying to move the first or last member in the tag, do nothing
                trace.info(`Trying to move first or last member. targetIndex: ${targetIndex}`);
                updatedTag = null;
            } else {
                updatedTag.memberIds[currentIndex] = updatedTag.memberIds[targetIndex];
                updatedTag.memberIds[targetIndex] = memberId;
            }
        } else {
            updatedTag = null;
            trace.warn(`Unable to move member: ${memberId} not found in the member ids`);
        }
        return updatedTag;
    }

    /**
     * Returns true if the given tag contains the given member
     * @param tag
     * @param member
     */
    public static containsMember(tag: ITagEntity, member: IMemberEntity): boolean {
        if (!tag || !member) {
            return false;
        } else {
            return tag.memberIds.some(memberId => memberId === member.id);
        }
    }

    /**
     * Function that gets tag name from tagId
     * @param tagId - Id of the tag
     * @return return the tag name if found else return null
     */
    public static getTagNameFromId(tagId: string): string {
        let tag = null;
        if (TagStore().tags.has(tagId)) {
            tag = TagStore().tags.get(tagId);
        }
        return tag ? tag.name : null;
    }

    /*
     * Function that renames tags
     * @param tagId - Id of the tag which is being renamed
     * @param newTagName - The new name of the tag
     */
    public static createCloneWithNewTagName(tagId: string, newTagName: string): ITagEntity {
        let updatedTag: ITagEntity = null;
        if (TagStore().tags.has(tagId)) {
            const tag = TagStore().tags.get(tagId);
            updatedTag = TagEntity.clone(tag);
            updatedTag.name = newTagName;
        }
        return updatedTag;
    }

    /**
     * Gets all the active tags from the tag store.
     */
    public static getAllActiveTags(): ITagEntity[] {
        const tags = TagUtils.getAllTagsArray();
        return tags.filter( tag => tag.state == TagStates.Active);
    }

    /**
     * Gets all the tags (regardless of state) from the tag store.
     */
    public static getAllTagsArray(): ITagEntity[] {
        return DataProcessingHelpers.getArrayFromMap(TagStore().tags);
    }

    /**
     * Returns an array of tagIds for all tags (regardless of state). Optionally sorts by index.
     * @param {boolean} sortByIndex - if true, returned tagsIds are sorted by the index in their original tagEntity
     */
    public static getAllTagIds(sortByIndex?: boolean): string[] {
        let tags = TagUtils.getAllTagsArray();
        if (sortByIndex) {
            // Sort tags by their index
            tags = tags.sort(indexComparator);
        }
        // map the tags into ids
        return tags.map((tag) => { return tag && tag.id; });
    }

    /**
     * Comparator for sorting tags by their name alphabetically
     */
    public static tagNameComparator(firstTag: ITagEntity, secondTag: ITagEntity): number {
        return StringUtils.genericStringComparator(firstTag.name, secondTag.name);
    }

    /**
     * Returns an array of tagIds for all filtered tags
     * @param filteredTags - array or map
    */
    public static getFilteredTagIds(filteredTags: any): string [] {
        let tags = DataProcessingHelpers.getArrayFromMap(filteredTags);
        // map the tags into ids
        return tags.map((tag: ITagEntity) => { return tag && tag.id; });
    }

    /**
     * Returns an array of tagNames for all filtered tags
     * @param filteredTags - array or map
    */
    public static getFilteredTagNames(filteredTags: any): string [] {
        let tags = DataProcessingHelpers.getArrayFromMap(filteredTags);
        const unnamedGroup: string = StringsStore().registeredStringModules.get("common").strings.get("unnamedGroup");
        // map the tags into names
        return tags.map((tag: ITagEntity) => { return tag && !!tag.name ? tag.name : unnamedGroup;
        });
    }
}
