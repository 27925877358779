export enum InitializationStage {
    /**
     * After scripts are loaded and parsed, but before team data is loaded
     */
    Bootstrap = 0,
    /**
     * After initial render (even after a timeout, allowing a paint)
     */
    InitialRender = 1
}

interface DelayedInitializationQueue {
    unblocked: boolean;
    callbacks: (() => void)[];
}

function createEmptyQueue(): DelayedInitializationQueue {
    return {
        unblocked: false,
        callbacks: []
    };
}

let registeredCallbacks: DelayedInitializationQueue[] = [];
registeredCallbacks[InitializationStage.Bootstrap] = createEmptyQueue();
registeredCallbacks[InitializationStage.InitialRender] = createEmptyQueue();

function registerForDelayedInitialization(stage: InitializationStage, callback: () => void) {
    if (!callback) {
        return;
    }

    let queue = registeredCallbacks[stage];

    if (queue.unblocked) {
        callback();
    } else {
        queue.callbacks.push(callback);
    }
}

export function runAfterBootstrap(callback: () => void) {
    registerForDelayedInitialization(InitializationStage.Bootstrap, callback);
}

export function runAfterInitialRender(callback: () => void) {
    registerForDelayedInitialization(InitializationStage.InitialRender, callback);
}

export function unblockInitQueue(stage: InitializationStage) {
    let queue = registeredCallbacks[stage];

    if (queue.unblocked) {
        return;
    }

    queue.unblocked = true;

    for (let i = 0; i < queue.callbacks.length; i++) {
        queue.callbacks[i]();
    }

    queue.callbacks = [];
}

export function reset() {
    registeredCallbacks[InitializationStage.Bootstrap] = createEmptyQueue();
    registeredCallbacks[InitializationStage.InitialRender] = createEmptyQueue();
}