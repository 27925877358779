import groupedRequestDetailsStore from '../store/store';
import { approveDenyOpenShiftRequests, setIsSavingInStore } from '../store/store';
import { DataProcessingHelpers, ShiftRequestDataService } from 'sh-services';
import { IndividualOpenShiftRequestDetails } from '../store/schema/GroupedRequestDetailsStoreSchema';
import { IShiftRequestResponseEntity } from 'sh-models';
import { orchestrator } from 'satcheljs';
import { TeamStore, TeamStoreSchema } from 'sh-team-store';
import { trace } from 'owa-trace';

/**
 * This orchestrator will call the api to approve or deny the selected openshift requests
 * @param isAccepting True for Approve. False otherwise
 * @param onApproveDenyCallback Callback on Approve/Deny for OpenShift Requests after the action is successful
 * @param onErrorCallback Callback when there is an error on Approve/Deny for OpenShift Requests
 */
export const approveDenyOpenShiftRequestsOrchestrator = orchestrator(approveDenyOpenShiftRequests, async actionMessage => {
        const { isAccepting, onApproveDenyCallback, onErrorCallback } = actionMessage;
        // TODO fix when 'groupedRequestDetailsStore.openShift.id' is unexpectedly nulled elsewhere during execution and remove the 'openShiftId' intermediate variable which is a temporary mitigation.
        const openShiftId = groupedRequestDetailsStore.openShift?.id;
        if (!(groupedRequestDetailsStore && groupedRequestDetailsStore.individualRequestDetails && groupedRequestDetailsStore.openShift && groupedRequestDetailsStore.groupedOpenShiftRequest)) {
            return;
        }

        try {
            const teamStore: TeamStoreSchema = TeamStore();
            const message: string = groupedRequestDetailsStore.userResponseMessage;

            let shiftRequestResponses: IShiftRequestResponseEntity[] = [];
            const individualRequestDetailsList: IndividualOpenShiftRequestDetails[] = DataProcessingHelpers.getArrayFromMap<string, IndividualOpenShiftRequestDetails>(groupedRequestDetailsStore.individualRequestDetails);
            individualRequestDetailsList.forEach((individualRequestDetails: IndividualOpenShiftRequestDetails) => {
                if (individualRequestDetails.request && individualRequestDetails.isSelected) {
                    shiftRequestResponses.push(
                        {
                            id: individualRequestDetails.request.id,
                            eTag : individualRequestDetails.request.eTag,
                            isAccepting: isAccepting,
                            message: message
                        }
                    );
                }
            });

            setIsSavingInStore(true, isAccepting);

            // Checking again that there are requests to approve or deny. This should ideally never be an empty list
            // Even if the list is empty, we should continue to the callback to refresh the view
            if (shiftRequestResponses && shiftRequestResponses.length > 0) {
                await ShiftRequestDataService.approveDeclineOpenShiftRequestsBulk(teamStore.team.id, groupedRequestDetailsStore.openShift, shiftRequestResponses);
            }

            if (onApproveDenyCallback && openShiftId) {
                onApproveDenyCallback(openShiftId, isAccepting);
            }
        } catch (error) {
            trace.warn("approveDenyOpenShiftRequests: Error in bulk approve/deny shift requests");
            // workflow integration error would be handled earlier
            if (error && !error.isHandled) {
                // Error should be handled by the caller, as the message can be shown in different places based on whether it is panel or callout
                if (onErrorCallback) {
                    onErrorCallback(error);
                }
            }
        } finally {
            setIsSavingInStore(false, false);
        }
    }
);
