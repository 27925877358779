import * as React from "react";
import FlexiRow, { FlexiRowProps } from "./FlexiRow";
import { Fabric } from "@fluentui/react";
import { SortableElement } from "react-sortable-hoc";

/**
 * Wrapper to make a flexi row draggable/sortable
 */
const SortableFlexiRow = SortableElement<FlexiRowProps>((props: FlexiRowProps) => (
    <Fabric style={props.rowStyle} key={props.rowElementKey}>
        <FlexiRow {...props} />
    </Fabric>
));

export default SortableFlexiRow;
