import { action } from "satcheljs";

/**
 * Action to download sample file.
 */

const getDownloadSampleFile = action(
    "getDownloadSampleFile",
    () => ({})
);

export default getDownloadSampleFile;
