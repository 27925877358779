import { ITeamPermissionEntity } from "..";
import { VISIBILITY_DEFAULT_PERMISSIONS } from "../../StaffHubConstants";
import { ITeamPermissionEntityParams } from "./TeamPermissionEntityParams";

/**
 * Entity class from TeamPermissions.
 */
export class TeamPermissionEntity implements ITeamPermissionEntity {
    canModifyShiftRequestsCapabilities: boolean;
    canModifyTimeClockCapabilities: boolean;
    canModifyTimeClockGeoFencingSettings: boolean;
    canModifyTimeOffRequestsCapabilities: boolean;
    canModifyTimeOffReasons: boolean;
    canModifySchedulingGroups: boolean;
    canModifyCrossLocationShiftsSettings: boolean;
    canSeeShiftDetails: boolean;
    canSeeTimeOffDetails: boolean;
    canSeeUnlimitedPastScheduleData: boolean;
    pastScheduleDataLimitInDays: number;

    /**
     * Constructor with parameters.
     * @param params ITeamPermissionEntityParams.
     */
    constructor(
        params?: ITeamPermissionEntityParams
    ) {
        const {
            canModifyShiftRequestsCapabilities = true,
            canModifyTimeClockCapabilities = true,
            canModifyTimeClockGeoFencingSettings = true,
            canModifyTimeOffRequestsCapabilities = true,
            canModifyTimeOffReasons = true,
            canModifySchedulingGroups = true,
            canModifyCrossLocationShiftsSettings = false,
            canSeeShiftDetails = VISIBILITY_DEFAULT_PERMISSIONS.canSeeShiftDetails,
            canSeeTimeOffDetails = VISIBILITY_DEFAULT_PERMISSIONS.canSeeTimeOffDetails,
            canSeeUnlimitedPastScheduleData = VISIBILITY_DEFAULT_PERMISSIONS.canSeeUnlimitedPastScheduleData,
            pastScheduleDataLimitInDays = VISIBILITY_DEFAULT_PERMISSIONS.pastScheduleDataLimitInDays
        } = params ?? {}; // Empty object to prevent null reference exception

        this.canModifyShiftRequestsCapabilities = canModifyShiftRequestsCapabilities;
        this.canModifyTimeClockCapabilities = canModifyTimeClockCapabilities;
        this.canModifyTimeClockGeoFencingSettings = canModifyTimeClockGeoFencingSettings;
        this.canModifyTimeOffRequestsCapabilities = canModifyTimeOffRequestsCapabilities;
        this.canModifyTimeOffReasons = canModifyTimeOffReasons;
        this.canModifySchedulingGroups = canModifySchedulingGroups;
        this.canModifyCrossLocationShiftsSettings = canModifyCrossLocationShiftsSettings;
        this.canSeeShiftDetails = canSeeShiftDetails;
        this.canSeeTimeOffDetails = canSeeTimeOffDetails;
        this.canSeeUnlimitedPastScheduleData = canSeeUnlimitedPastScheduleData;
        this.pastScheduleDataLimitInDays = pastScheduleDataLimitInDays;
    }

    /**
     * FromJson method for TeamPermissionEntity.
     * @param jsonData ITeamPermissionEntityParams.
     * @returns ITeamPermissionEntity.
     */
    public static fromJson(jsonData: ITeamPermissionEntityParams): ITeamPermissionEntity {
        if (!jsonData) {
            return null;
        } else {
            return TeamPermissionEntity.clone(jsonData);
        }
    }

    /**
     * Clone the team permission entity.
     * @param teamPermissionEntity ITeamPermissionEntityParams.
     * @returns ITeamPermissionEntity.
     */
    public static clone(teamPermissionEntity: ITeamPermissionEntityParams): ITeamPermissionEntity {
        return new TeamPermissionEntity(teamPermissionEntity);
    }
}