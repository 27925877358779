import { legacyAddToActionQueue } from "sh-exception-handler/lib/middleware/addToActionQueue";
import { legacyApplyMiddleware, LegacyMiddleware } from "satcheljs/lib/legacy";
import { promiseMiddleware } from "satcheljs/lib/legacy/promise";
import { stitch } from "satcheljs/lib/legacy/stitch";
// import { addDatapoint } from "sh-instrumentation";

/**
 * Satchel Middleware
 *  addToActionQueue - keeps a queue of action names that can be added to logs
 *  addDatapoint (NYI) - creates a action perf datapoint for aria that is used to monitor the perf of satchel actions
 *  trace - logs all satchel actions to the console
 *  stitch - logs all satchel stiches to the console
 *
 * @param{boolean} verboseLogging - If true, will always log messages to the console
 */
export default function initializeSatchelMiddleware(verboseLogging: boolean) {
    // Initialize store middleware
    let middleware: LegacyMiddleware[] = [ legacyAddToActionQueue/*, addDatapoint*/, stitch, promiseMiddleware ];

    legacyApplyMiddleware.apply(null, middleware);
}