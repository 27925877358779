import { conflictRequestStore } from "..";
import { IBaseShiftEntity, IShiftEntity } from "sh-models";
import { mutatorAction } from "satcheljs";

export default mutatorAction('SET_MEMBER_SHIFTS_IN_CONFLICT_STORE', function setMemberShiftsInStore(memberShifts: IBaseShiftEntity[]) {
    if (!memberShifts) {
        return;
    }
    memberShifts.forEach((shiftToUpdate: IShiftEntity) => {
        if (!conflictRequestStore().memberShifts.has(shiftToUpdate.id)) {
            conflictRequestStore().memberShifts.set(shiftToUpdate.id, shiftToUpdate);
        }
    });
});