import * as microsoftTeams from "@microsoft/teams-js";
import * as React from "react";
import MSTeamPickerPane from "../msteampicker/MSTeamPickerPane";
import msTeamPickerPaneStore from "../../teamsetuppicker/msteampicker/store/store";
import StringsStore from "sh-strings/store";
import teamSetupPickerStore from "../store/store";
import TeamSetupPickerUtils, { OnMSTeamCallbackFunction } from "../TeamSetupPickerUtils";
import { getGenericEventPropertiesObject, logPageView } from "sh-instrumentation";
import { gotoNextSetupStep, setMSTeamInfo } from "../store/store";
import { InstrumentationService, TeamDataService } from "sh-services";
import { observer } from "mobx-react";
import { SetupNavigation } from "../SetupNavigation";
import { TeamSetupPickerStepProps } from "../TeamSetupPickerStepProps";
import { setIsTeamPickerPaneDataLoading, setMSTeamInfos } from "../msteampicker/store/store";
import { ITeamInfoEntity } from "sh-models";

/**
 * React component for the Microsoft Team picker step in the new team onboarding flow
 * In this step, the user chooses an MS Team to create a schedule team for.
 */
@observer
@logPageView("Setup.SelectMSTeam")
export default class TeamSetupMSTeamPicker extends React.Component<TeamSetupPickerStepProps, {}> {
    private _teamSetupPickerStrings: Map<string, string>;
    private _msTeamPickerStrings: Map<string, string>;
    private _commonCloseString: string;

    constructor(props: TeamSetupPickerStepProps) {
        super(props);
        this._teamSetupPickerStrings = StringsStore().registeredStringModules.get("teamSetupPicker").strings;
        this._msTeamPickerStrings = StringsStore().registeredStringModules.get("msTeamPicker").strings;
        this._commonCloseString = StringsStore().registeredStringModules.get("common").strings.get("close");
    }

    /**
     * Handler for when the user selects an MS team to provision a schedule for
     */
    private handleTeamSelected = (msTeamInfo: microsoftTeams.TeamInformation) => {
        InstrumentationService.logEvent(
            this.getInstrumentationEventName(),
            [getGenericEventPropertiesObject(InstrumentationService.properties.EventType, InstrumentationService.values.MSTeamSelectedClicked)]);

        setMSTeamInfo(msTeamInfo);
        gotoNextSetupStep();
    }

    /**
     * Handler for when the Back button is clicked
     */
    private handleBackClicked = () => {
        this.props.onCancel();
    }

    private getInstrumentationEventName(): string {
        return TeamSetupPickerUtils.getInstrumentationEventNameForProvisionScheduleTeam(this.props.isOnboarding);
    }

    /**
     * Instrument load of this view
     */
    private instrumentLoadScreen(msTeamInfosProvisionable: microsoftTeams.TeamInformation[]) {
        let numMSTeamsInView: number = 0;
        let numMSTeamsProvisionableByUser: number = 0;

        if (msTeamInfosProvisionable) {
            numMSTeamsInView = msTeamInfosProvisionable.length;

            const msTeamsProvisionableByUser: microsoftTeams.TeamInformation[] =
                msTeamInfosProvisionable.filter((msTeamInfo: microsoftTeams.TeamInformation) => { return TeamSetupPickerUtils.isUserTeamAdmin(msTeamInfo); });
            numMSTeamsProvisionableByUser = msTeamsProvisionableByUser.length;
        }

        InstrumentationService.logEvent(
            this.getInstrumentationEventName(),
            [
                getGenericEventPropertiesObject(InstrumentationService.properties.EventType, InstrumentationService.values.MSTeamPickerViewed),
                getGenericEventPropertiesObject(InstrumentationService.properties.TotalNumTeams, numMSTeamsInView),
                getGenericEventPropertiesObject(InstrumentationService.properties.NumProvisionableTeams, numMSTeamsProvisionableByUser)
            ]);
    }

    /**
     * Setup data needed for the Team Picker UX
     */
    private loadInitialData = async () => {
        setIsTeamPickerPaneDataLoading(true /* isDataLoading */);

        try {
            // Fetch both MS Teams and schedule teams data in parallel
            const [scheduleTeams, msUserJoinedTeams] = await Promise.all([
                TeamDataService.getTeams(),
                TeamDataService.getMSTeamsUserJoinedTeams()
            ]);

            let msUserJoinedTeamInfos: microsoftTeams.TeamInformation[] = [];
            let msTeamInfosProvisionable: microsoftTeams.TeamInformation[] = [];

            // Process the results from the teams data loading promises
            if (scheduleTeams &&
                msUserJoinedTeams && msUserJoinedTeams.userJoinedTeams) {
                msUserJoinedTeamInfos = msUserJoinedTeams.userJoinedTeams;
                msTeamInfosProvisionable = this.getMSTeamsWithoutSchedules(scheduleTeams, msUserJoinedTeamInfos);
                setMSTeamInfos(msTeamInfosProvisionable);
            }

            this.instrumentLoadScreen(msTeamInfosProvisionable);
        } catch (error) {
            // Handle service failures
            TeamSetupPickerUtils.handleStaffHubServiceError(error);
        } finally {
            setIsTeamPickerPaneDataLoading(false /* isDataLoading */);
        }
    }

    /**
     * Find all MS Teams that the user is a member of that don't have associated Schedule Teams
     * @param scheduleTeamInfos data for Schedule teams that the user belongs to
     * @param msTeamInfos data for MS teams that the user belongs to
     */
    private getMSTeamsWithoutSchedules(scheduleTeamInfos: ITeamInfoEntity[], msTeamInfos: microsoftTeams.TeamInformation[]): microsoftTeams.TeamInformation[] {
        // Find all MS Teams that don't have schedules associated with them
        let msTeamInfosWithoutSchedules: microsoftTeams.TeamInformation[] = [];
        const handleMSTeamCallback: OnMSTeamCallbackFunction = (msTeamInfo: microsoftTeams.TeamInformation, scheduleTeamInfo: ITeamInfoEntity) => {
            if (!scheduleTeamInfo) {
                msTeamInfosWithoutSchedules.push(msTeamInfo);
            }
        };
        TeamSetupPickerUtils.processMSTeamsData(scheduleTeamInfos, msTeamInfos, handleMSTeamCallback);

        // Sort the MS teams result
        return msTeamInfosWithoutSchedules.sort(TeamSetupMSTeamPicker.msTeamInfoComparator);
    }

    /**
     * Comparator for MS TeamInfo items
     */
    private static msTeamInfoComparator(firstTeamInfo: microsoftTeams.TeamInformation, secondTeamInfo: microsoftTeams.TeamInformation): number {
        // Sort order:
        // - MS Teams that the user is an Admin for
        // - Alphabetical order by team name

        if (!firstTeamInfo && !secondTeamInfo) {
            return 0;
        } else if (!secondTeamInfo) {
            return -1;
        } else if (!firstTeamInfo) {
            return 1;
        } else {
            // Teams that the user is an admin come before teams that the user is not an admin for
            const isUserAdminFirstTeam: boolean = TeamSetupPickerUtils.isUserTeamAdmin(firstTeamInfo);
            const isUserAdminSecondTeam: boolean = TeamSetupPickerUtils.isUserTeamAdmin(secondTeamInfo);

            if (!isUserAdminFirstTeam && isUserAdminSecondTeam) {
                return 1;
            } else if (isUserAdminFirstTeam && !isUserAdminSecondTeam) {
                return -1;
            } else {
                // Otherwise sort by team name
                return TeamSetupPickerUtils.teamNameComparator(firstTeamInfo.teamName, secondTeamInfo.teamName);
            }
        }
    }

    render() {
        const showCancelButton: boolean = this.props.isSetupUserDismissable || !this.props.isProvisionTeamOnlyMode;
        const cancelButtonLabel: string = !teamSetupPickerStore.showStepIndicator || (teamSetupPickerStore.showStepIndicator && teamSetupPickerStore.currentSetupStepIndex === 0) ? this._commonCloseString : this._teamSetupPickerStrings.get("backButtonLabel");
        return (
            <div className={ "stepWithNavbar" } role="region" aria-label={ this._msTeamPickerStrings.get("msTeamPickerTitle") }>
                <MSTeamPickerPane
                    instrumentationEventName={ this.getInstrumentationEventName() }
                    onTeamSelected={ this.handleTeamSelected }
                    store={ msTeamPickerPaneStore() }
                    loadInitialData={ this.loadInitialData }
                    autofocus={ true } />
                {
                    showCancelButton &&
                        <SetupNavigation
                            onCancel={ this.handleBackClicked }
                            cancelButtonLabel={ cancelButtonLabel }
                            showStepIndicator={ teamSetupPickerStore.showStepIndicator }
                            currentStepIndex={ teamSetupPickerStore.currentSetupStepIndex }
                            stepIndicatorSize={ teamSetupPickerStore.setupSequence && teamSetupPickerStore.setupSequence.length }
                            />
                }
            </div>
        );
    }
}
