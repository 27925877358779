import resetTimeOffReasonsStore from "../actions/resetTimeOffReasonsStore";
import TimeOffReasonsStore from "../store";
import { mutator } from "satcheljs";

/**
 * Reset the team settings store
 */
export default mutator(resetTimeOffReasonsStore, ({ }) => {
    TimeOffReasonsStore().timeOffReasons.clear();
    TimeOffReasonsStore().requestableTimeOffReasonsIds = [];
});