import schedulesViewStateStore from "../store/store";
import { mutatorAction } from "satcheljs";

/**
 * Sets a group (tag id) that has been progressive loaded. Consumers can use this
 * to know if the data for a specific group has been loaded yet. This data is reset
 * if the user changes the schedule view (date range, view type, etc.)
 * @param groupTagId - string - the groupTagId
 */
export default mutatorAction('SET_GROUP_IN_VIEW', function setGroupInView(
    groupTagId: string
) {
    schedulesViewStateStore().groupsInView.push(groupTagId);
});
