import handleStaffHubServiceError from "../actions/handleStaffHubServiceError";
import setBlockingError from "../mutators/setBlockingError";
import StringsStore from "sh-strings/store";
import { appViewState } from "sh-application/store";
import { handleWorkforceIntegrationError } from "../../";
import { LoginService, StaffHubErrorCodes, UserStorageService } from "sh-services";
import { LoginState } from "sh-application/store/schema/LoginViewState";
import { orchestrator } from "satcheljs";
import { setGlobalMessageFromException } from "sh-application";
import { UserStorageKeys } from "sh-models";

/**
 * When there is an error from the network layer, we look for errors that have common/global handling procedures
 * and mark the error as handled.
 * We always let the error propagate and some other component handle it, but they can look at the isHandled property.
 */
export default orchestrator(handleStaffHubServiceError, async actionMessage => {
    if (!actionMessage.staffHubHttpError.isHandled) {
        const accessBlockedStrings = StringsStore().registeredStringModules.get("accessBlocked").strings;
        const commonStrings = StringsStore().registeredStringModules.get("common").strings;
        const clientVersionServiceStrings = StringsStore().registeredStringModules.get("clientVersionService").strings;

        if (actionMessage.staffHubHttpError.staffHubInnerErrorCode === StaffHubErrorCodes.InvalidTenantRegion) {
            // Scenario "InvalidTenantRegion": Client is hitting an incorrect service region - this can happen if the tenant is migrated to a different region

            // mark this exception as being handled
            actionMessage.staffHubHttpError.isHandled = true;

            // Reset the user local settings
            await LoginService.resetCurrentUserLoginSession();

            // Show a message asking the user to refresh the app
            setBlockingError({
                title: clientVersionServiceStrings.get("title"),
                primaryText: clientVersionServiceStrings.get("message"),
                actionButton: {
                    useAppRestartAction: true,
                    actionButtonText: commonStrings.get("refresh")
                }
            });
        } else if (actionMessage.staffHubHttpError.staffHubInnerErrorCode === StaffHubErrorCodes.InvalidTicket) {
            // Scenario "InvalidTicket": The DeviceHeader is incorrect, the sync device queue was deleted on the service, we need to refresh and call login to get a new device header

            // mark this exception as being handled
            actionMessage.staffHubHttpError.isHandled = true;

            // Reset the user login session, so they'll get call login again and get a new device header
            await LoginService.resetCurrentUserLoginSession();

            // Show an unknown error message and ask the user to refresh the app
            // We don't auto-restart the app to avoid getting into a restart loop (if there's a service bug)
            setBlockingError({
                title: accessBlockedStrings.get("unknownErrorTitle"),
                primaryText: accessBlockedStrings.get("unknownErrorMessage"),
                actionButton: {
                    useAppRestartAction: true,
                    actionButtonText: commonStrings.get("refresh")
                }
            });
        } else if (actionMessage.staffHubHttpError.staffHubInnerErrorCode === StaffHubErrorCodes.MaintenanceMode) {
            // Scenario: The service is not accessible temporarily because it's in maintenance mode

            // mark this exception as being handled
            actionMessage.staffHubHttpError.isHandled = true;

            // Show a message telling the user that the app is in maintenance mode
            setBlockingError({
                title: "",
                primaryText: actionMessage.staffHubHttpError.staffHubTopLevelErrorMessage,
                actionButton: {
                    useAppRestartAction: true,
                    actionButtonText: commonStrings.get("refresh")
                }
            });
        } else if (actionMessage.staffHubHttpError.staffHubTopLevelErrorCode === StaffHubErrorCodes.ClientStale) {
            // Scenario: The service requires an API version higher than what the client is currently at
            // (We should not hit this, our version.json file should have made the client upgrade before the service increased this version)

            // mark this exception as being handled
            actionMessage.staffHubHttpError.isHandled = true;

            // Show a message telling the user that the app needs an upgrade
            setBlockingError({
                title: clientVersionServiceStrings.get("title"),
                primaryText: clientVersionServiceStrings.get("message"),
                actionButton: {
                    useAppRefreshAction: true,
                    actionButtonText: commonStrings.get("refresh")
                }
            });
        } else if (actionMessage.staffHubHttpError.staffHubInnerErrorCode === StaffHubErrorCodes.ReadOnlyMode) {
            // Scenario: The service is not allowing any data edits because it is in readonly mode

            // mark this exception as being handled
            actionMessage.staffHubHttpError.isHandled = true;

            // Show the readonly message in the Global Message Bar
            setGlobalMessageFromException(
                actionMessage.staffHubHttpError,
                true    // AutoDismiss
            );
        } else if (actionMessage.staffHubHttpError.staffHubInnerErrorCode === StaffHubErrorCodes.TeamNotFound) {
            // Scenario: The team was deleted

            // Clear team because it was deleted
            if (UserStorageService) {
                UserStorageService.removeItem(UserStorageKeys.MostRecentTeamId);
            }

            // mark this exception as being handled
            actionMessage.staffHubHttpError.isHandled = true;

            // Show a message telling the user that the team has been deleted
            setBlockingError({
                title: "",
                primaryText: accessBlockedStrings.get("teamNotFound"),
                actionButton: {
                    useAppRestartAction: true,
                    actionButtonText: commonStrings.get("refresh")
                }
            });
        } else if (actionMessage.staffHubHttpError.isNetworkError) {
            // Check if the app has completed bootstrapping successfully and we have a message bar to display errors
            // otherwise we may need to treat this as a bootstrap error
            if (appViewState().loginViewState.loginState == LoginState.LoggedInBootstrapComplete) {

                // mark this exception as being handled
                actionMessage.staffHubHttpError.isHandled = true;

                // Show the network error in the Global Message Bar
                setGlobalMessageFromException(
                    actionMessage.staffHubHttpError,
                    true    // AutoDismiss
                );
            }
        } else if (actionMessage.staffHubHttpError.staffHubInnerErrorCode === StaffHubErrorCodes.WorkforceIntegrationError) {
            handleWorkforceIntegrationError(actionMessage.staffHubHttpError);
        }
    }
});