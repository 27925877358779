import * as FormData from "form-data";
import StringsStore from "sh-strings/store";
import { AppSettings, createNewHttpError } from "sh-application";
import {
    AppSettingsClientModel,
    AppSettingsServiceModel,
    AvailabilityEntity,
    CopyOfShiftsResponse,
    GeneratedAlertEntity,
    IApproveDeclineTimeOffRequestResponseEntity,
    IApproveSwapHandOffRequestResponseEntity,
    IAssignOpenShiftRequestEntity,
    IAssignOpenShiftResponseEntity,
    IAvailabilityEntity,
    IBaseShiftEntity,
    IBaseShiftServiceEntity,
    IBulkOpenShiftRequestsResponseEntity,
    IBulkShiftResponseEntity,
    IConflictDismissResponseEntity,
    ICreateTimeOffResponseEntity,
    IDataInDateRange,
    IDataInDateRangePage,
    IGeneratedAlertEntity,
    IGetShiftRequestsResponseEntity,
    IJobEntity,
    ILocateUserResponseEntity,
    ILoginUserEntity,
    IMemberEntity,
    INoteEntity,
    INoteServiceEntity,
    IOpenShiftEntity,
    IOpenShiftServiceEntity,
    IScheduleProvisionResponse,
    IShiftEntity,
    IShiftRequestAndUnreadCountResponseEntity,
    IShiftRequestEntity,
    IShiftRequestResponseEntity,
    IShiftResponseEntity,
    IShiftrObjectModelBase,
    IShiftServiceEntity,
    ITagEntity,
    ITeamInfoEntity,
    ITeamMemberPermissionsEntity,
    ITeamPermissionEntity,
    ITeamSettingEntity,
    ITeamShiftPolicyEntity,
    ITimeClockEntity,
    ITimeOffReasonEntity,
    IUniqueShiftEntity,
    IUniqueSubshiftEntity,
    IUpdateShiftResponseEntity,
    IUserEntity,
    IUserSettingsEntity,
    JobEntity,
    LocateUserResponseEntity,
    MemberEntity,
    NoteEntity,
    OpenShiftEntity,
    ScheduleProvisionResponse,
    ShiftEntity,
    ShiftPolicyEntity,
    ShiftRequestEntity,
    ShiftRequestType,
    ShiftRequestTypes,
    TagEntity,
    TeamDetailsResponse,
    TeamInfoEntity,
    TeamManagedByType,
    TeamMemberPermissionsEntity,
    TeamPermissionEntity,
    TeamSettingEntity,
    TimeClockEntity,
    TimeOffReasonEntity,
    TimeOffReasonsUpdateResponse,
    UniqueShiftEntity,
    UniqueSubshiftEntity,
    UserEntity,
    UserPolicySettingsEntity,
    UserSettingsEntity
} from "sh-models";
import { AuthService, DataFilter, HttpRequestEntity, NetworkService } from "sh-services";
import { AxiosResponse } from "axios";
import { ClientTypes, CONTENTTYPEHEADER, GENERATED_ALERTS_DISPLAY_LIMIT, IMPORT_JOB_COUNT, SHIFT_REQUESTS_PAGE_SIZE } from "../StaffHubConstants";
import { ConflictDismissEntity, IConflictDismissEntity, IDismissEntity, IUserTimeOffReasonsReponseEntity } from "sh-models";
import { GetDataInDateRangeOptions } from "./GetDataInDateRangeOptions";
import { IServiceUserEntity } from "../sh-models/user/IUserEntity";
import { Moment } from "moment";
import { NetworkQueue } from "sh-network-queue";
import { ShareWithdrawRequestParams } from "./ShareWithdrawRequestParams";
import { toJS } from "mobx";
import { trace } from "owa-trace";
import { ShareWithdrawDataInDateRangeParams } from "./ShareWithdrawDataInDateRangeParams";
import { IImportJobEntity } from "../sh-models/job/IImportJobEntity";
import { ImportJobEntity } from "../sh-models/job/ImportJobEntity";

export enum reportExcelType {
    timeReport = "TimeReport",
    scheduleReport = "ScheduleReport"
}

interface Patchable<K> {
    Value: K;
}

export default class RestClient {
    public static readonly appStaticsFolder = AppSettings.getSetting("AppStaticsFolder");

    /**
     * Call Locate API on StaffHub service to locate user info and fetch service region endpoint to hit for user
     * This API should be called after authentication to identify the service endpoint
     * @returns {Promise} : Promise with the service response.
     */
    public static async locateUserRegion(): Promise<ILocateUserResponseEntity> {
        const urlSignature = "/api/account/locate";
        const url = NetworkService.GetDefaultServiceAPIEndPointUrl() + "/api/account/locate";

        // Don't send/update the device header for locate calls because they hit the global traffic manager
        const response: AxiosResponse = await NetworkService.sendHttpRequestToStaffHubService(
            {
                method: "GET",
                url: url,
                urlSignature: urlSignature
            },
            false /* includeDeviceHeader */,
            false /* updateDeviceHeaderFromResponse */,
            true /* absoluteUrl */
        );
        return this.parseLocateUserResponse(response);
    }

    // parse Locate User response from service response
    private static parseLocateUserResponse(response: AxiosResponse): ILocateUserResponseEntity {
        if (!response || !response.data || !response.data.region) {
            let error = createNewHttpError();
            error.message = "Locate user response has incomplete data";
            trace.warn(error.message);
            throw error;
        }
        return LocateUserResponseEntity.fromJson(response.data);
    }

    /**
     * Sends the login request to the service.
     * @param clientType The client type (e.g., Desktop, Web).
     * @returns The logged-in user info.
     */
    public static async login(clientType: ClientTypes): Promise<ILoginUserEntity> {
        const urlSignature = "/api/account/Login";
        const url = "/api/account/Login";

        // Don't send the previous device header when logging in, but update it from the response
        const response = await NetworkService.sendHttpRequestToStaffHubService(
            {
                method: "POST",
                url: url,
                urlSignature: urlSignature,
                data: {
                    deviceType: clientType
                }
            },
            false /* includeDeviceHeader */,
            true /* updateDeviceHeaderFromResponse */
        );

        const loggedInUser = response.data?.loggedInUser ?
            UserEntity.fromJson(response.data.loggedInUser) : null;

        const userPolicySettings = response.data?.userPolicySettings && loggedInUser ?
            UserPolicySettingsEntity.fromJson({
                ...response.data.userPolicySettings,
                userId: loggedInUser.userId
            }) : null;

        return { loggedInUser, userPolicySettings };
    }

    /**
     * Get Sync Notifications from Service
     * @param {String} syncKey  (Optional)
     * @param {boolean} resetSyncQueue  (Optional)
     * @returns {}
     */
    public static getSyncNotifications(syncKey?: string, resetSyncQueue?: boolean): Promise<AxiosResponse> {
        const urlSignature = "/api/sync/all";
        let url = "/api/sync/all";

        // check if we need to reset the queue
        if (resetSyncQueue) {
            url += "/?resetSyncQueue=true";
        } else if (syncKey) {
            // If optional sync key is provided, get notifications with provided key
            url += "/?key=" + syncKey;
        }

        return NetworkService.sendHttpRequestToStaffHubService({
            method: "GET",
            url: url,
            urlSignature: urlSignature,
            silent: true
        });
    }

    /**
     * Clear Device Sync Queue from Service
     * @returns {}
     */
    public static resetDeviceSyncQueue() {
        const urlSignature = "/api/sync/reset";
        const url = "/api/sync/reset";

        return NetworkService.sendHttpRequestToStaffHubService({
            method: "GET",
            url: url,
            urlSignature: urlSignature,
            silent: true
        });
    }

    /**
     * Get non-deleted shifts, schedules and notes with the provided date range
     * @param {String} tenantId
     * @param {String} teamId
     * @param {Moment} startTime - minimum start time of shifts
     * @param {Moment} endTime - max end time of shifts
     * @param {GetDataInDateRangeOptions} options - filters and fetch options to control what data is returned by service
     * @returns {} Promise that resolves to list of shifts assigned to given team
     */
    public static async getDataInDateRange(
        tenantId: string,
        teamId: string,
        startTime: Moment,
        endTime: Moment,
        options?: GetDataInDateRangeOptions,
        includeDeletedItems: boolean = false
    ): Promise<IDataInDateRange> {
        const urlSignature = "/api/tenants/tenantId/teams/teamId/bulk/getDataInDateRange";
        let url = "/api/tenants/" + tenantId + "/teams/" + teamId + "/bulk/getDataInDateRange";

        if (startTime || endTime || includeDeletedItems) {
            url += "?";
        }
        if (startTime) {
            url += "&startTime=" + encodeURIComponent(startTime.toISOString());
        }
        if (endTime) {
            url += "&endTime=" + encodeURIComponent(endTime.toISOString());
        }
        if (includeDeletedItems) {
            url += "&includeDeletedItems=true";
        }

        const hasFilter = !!(options && options.filters && (options.filters.tagIds || options.filters.memberIds));
        const hasFetchOptions = !!(hasFilter && options.networkFetchOptions);

        if (hasFilter) {
            if (options.filters.tagIds) {
                url += `&schedulingGroupsFilter=${options.filters.tagIds.toString()}`;
            }
            if (options.filters.memberIds) {
                url += `&memberIdsFilter=${options.filters.memberIds.toString()}`;
            }

            // set fetch options
            if (hasFetchOptions) {
                const fetchOptions = options.networkFetchOptions;
                url += `&includeNotes=${fetchOptions.includeNotes}`;
                url += `&includeOpenShifts=${fetchOptions.includeOpenShifts}`;
                url += `&includeUngroupedShifts=${fetchOptions.includeUngroupedShifts}`;
                /*
                 * robv note - livesite hotfix 1194666 - this is a temporary fix until service can rollout a fix to return
                 * time off shifts that have an associated tagId
                 */
                url += "&includeTimeOffs=true"; // `&includeTimeOffs=${fetchOptions.includeTimeOffs}`;
            }
        }

        const response: AxiosResponse = await NetworkService.sendHttpRequestToStaffHubService({
            method: "GET",
            url: url,
            urlSignature: urlSignature
        });

        // parse shifts from response
        const shifts = RestClient.parseShiftsFromResponse(response) || [];

        const notes = RestClient.parseNotesFromResponse(response) || [];

        const openShifts = RestClient.parseOpenShiftsFromResponse(response) || [];

        return { shifts, notes, openShifts } as IDataInDateRange;
    }

    /**
     * Gets the first page of shifts, openshifts and notes with the provided date range
     * service doc for the api: https://microsoft.sharepoint.com/%3Aw%3A/r/teams/ShiftrProject/Shared%20Documents/Service/Design/Pagination%20support%20in%20Single%20Team%20GDIDR.docx?d=w8bac6bce771d4817a119dafb2da48a48&csf=1
     * @param {String} teamId
     * @param {Moment} startTime - minimum start time of shifts
     * @param {Moment} endTime - max end time of shifts
     * @param {GetDataInDateRangeOptions} options - filters and fetch options to control what data is returned by service
     * @param {boolean} includeDeletedItems - True/false to include the deleted items
     * @returns {} Promise that resolves to list of shifts and notes assigned to given team
     */
    public static async getDataInDateRangePaginated(
        teamId: string,
        startTime: Moment,
        endTime: Moment,
        options?: GetDataInDateRangeOptions,
        cursor?: string,
        includeDeletedItems: boolean = false
    ): Promise<IDataInDateRangePage> {
        const urlSignature = "/api/teams/teamId/bulk/getDataInDateRange";
        const url = "/api/teams/" + teamId + "/bulk/getDataInDateRange?";
        const filters = options?.filters;
        const networkFetchOptions = options?.networkFetchOptions;

        const getOption = (value: boolean | undefined, defaultValue: boolean): boolean => {
            return value != undefined ? value : defaultValue;
        };

        const response: AxiosResponse = await NetworkService.sendHttpRequestToStaffHubService({
            method: "POST",
            url: url,
            urlSignature: urlSignature,
            data: {
                startTime: startTime.toISOString(),
                endTime: endTime.toISOString(),
                includeDeletedItems,
                includeShifts: true,
                includeNotes: getOption(networkFetchOptions?.includeNotes, true),
                includeOpenShifts: getOption(networkFetchOptions?.includeOpenShifts, true),
                includeUngroupedShifts: getOption(networkFetchOptions?.includeUngroupedShifts, true),
                /*
                 * robv note - livesite hotfix 1194666 - this is a temporary fix until service can rollout a fix to return
                 * time off shifts that have an associated tagId
                 */
                includeTimeOffs: true /* getOption(networkFetchOptions?.includeTimeOffs, true), */,
                includeDraftItems: true,
                schedulingGroupsFilter: filters?.tagIds,
                memberIdsFilter: filters?.memberIds,
                cursor: cursor
            }
        });

        // parse shifts from response
        const shifts = RestClient.parseShiftsFromResponse(response) ?? [];
        const notes = RestClient.parseNotesFromResponse(response) ?? [];
        const openShifts = RestClient.parseOpenShiftsFromResponse(response) ?? [];
        const skipToken = RestClient.parseSkipTokenFromResponse(response);

        return { shifts, notes, openShifts, skipToken };
    }

    /**
     * Gets the first page of user shifts, openshifts and notes with the provided date range
     * @param {String} teamId
     * @param {Moment} startTime - minimum start time of shifts
     * @param {Moment} endTime - max end time of shifts
     * @param {boolean} includeDeletedItems - True/false to include the deleted items
     * @returns {} Promise that resolves to list of shifts and notes assigned to given team
     */
    public static async getDataInDateRangePaginatedForMyShifts(
        teamId: string,
        startTime: Moment,
        endTime: Moment,
        includeDeletedItems: boolean = false
    ): Promise<IDataInDateRangePage> {
        const urlSignature = "/api/users/me/dataindaterange";
        let url = "/api/users/me/dataindaterange?teamIds=" + teamId;
        if (startTime) {
            url += "&startTime=" + encodeURIComponent(startTime.toISOString());
        }
        if (endTime) {
            url += "&endTime=" + encodeURIComponent(endTime.toISOString());
        }
        url += "&includeShifts=true&includeNotes=true&includeOpenShifts=true&includeDraft=true";

        if (includeDeletedItems) {
            url += "&includeDeleted=true";
        }

        const response: AxiosResponse = await NetworkService.sendHttpRequestToStaffHubService({
            method: "GET",
            url: url,
            urlSignature: urlSignature
        });

        // parse shifts from response
        const shifts = RestClient.parseShiftsFromResponse(response) || [];
        const notes = RestClient.parseNotesFromResponse(response) || [];
        const openShifts = RestClient.parseOpenShiftsFromResponse(response) || [];
        const nextLink = RestClient.parseNextLinkFromResponse(response);

        return { shifts, notes, openShifts, nextLink } as IDataInDateRangePage;
    }

    /**
     * Gets the shifts for specific user Id with the provided date range
     * @param {String} teamId teams id for user
     * @param {Moment} startTime - minimum start time of shifts
     * @param {Moment} endTime - max end time of shifts
     * @param {boolean} includeDeletedItems - True/false to include the deleted items
     * @returns {} Promise that resolves to list of shifts assigned to given team
     */
    public static async getShiftsInDateRangeForUser(
        userId: string,
        teamId: string,
        startTime: Moment,
        endTime: Moment
    ): Promise<IShiftEntity[]> {
        const urlSignature = "/api/users/userId/dataindaterange";
        let url = "/api/users/" + userId + "/dataindaterange?teamIds=" + teamId;
        if (startTime) {
            url += "&startTime=" + encodeURIComponent(startTime.toISOString());
        }
        if (endTime) {
            url += "&endTime=" + encodeURIComponent(endTime.toISOString());
        }
        // notes and open shifts are default false
        url += "&includeShifts=true&includeNotes=false&includeOpenShifts=false&includeDraft=true";

        const response: AxiosResponse = await NetworkService.sendHttpRequestToStaffHubService({
            method: "GET",
            url: url,
            urlSignature: urlSignature
        });

        // parse shifts from response
        const shifts = (response && response.data && RestClient.parseShiftsFromResponse(response)) || [];
        return shifts;
    }

    /**
     * Gets the time off reasons for the current user.
     * @param {String} teamId current user id.
     * @returns {} Promise that returns a filtered set of time off reasons that the current user is eligible to use.
     */
    public static async getRequestableTimeOffReasons(teamId: string): Promise<IUserTimeOffReasonsReponseEntity> {
        const method = "get";
        const urlSignature = "/api/teams/teamId/userTimeOffReasons";
        let url = `/api/teams/${teamId}/userTimeOffReasons`;

        const response = await NetworkService.sendHttpRequestToStaffHubService({
            method,
            url,
            urlSignature
        });

        return response.data;
    }

    /**
     * Gets the next page of shifts, openshifts and notes with the provided date range
     * @param {String} absoluteUrl - api url to fetch the next page of data
     * @returns {} Promise that resolves to list of shifts and notes assigned to given team
     */
    public static async executeGetDataInDateRangeLinkForMyShifts(absoluteUrl: string): Promise<IDataInDateRangePage> {
        // signature is same as the original request, adding "?nextLink" to indicate the difference for tracking
        const urlSignature = "/api/users/me/dataindaterange?nextLink";

        const response: AxiosResponse = await NetworkService.sendHttpRequestToStaffHubService(
            {
                method: "GET",
                url: absoluteUrl,
                urlSignature: urlSignature
            },
            true /* includeDeviceHeader */,
            true /* updateDeviceHeaderFromResponse */,
            true /* absoluteUrl */
        );

        // parse shifts from response
        const shifts = RestClient.parseShiftsFromResponse(response) || [];
        const notes = RestClient.parseNotesFromResponse(response) || [];
        const openShifts = RestClient.parseOpenShiftsFromResponse(response) || [];
        const nextLink = RestClient.parseNextLinkFromResponse(response);

        return { shifts, notes, openShifts, nextLink } as IDataInDateRangePage;
    }

    /**
     * Shares or withdraws data in given date range with selected audience.
     * @param params The parameters.
     * @return The impacted shifts, open shifts and notes.
     */
    public static async shareWithdrawDataInDateRange(
        params: ShareWithdrawDataInDateRangeParams
    ): Promise<IDataInDateRange> {
        const { teamId, startTime, endTime, notificationRecipients, isShare } = params;
        const urlSignature = isShare ? "/api/teams/teamId/schedules/share" : "/api/teams/teamId/schedules/withdraw";
        const url = isShare ? "/api/teams/" + teamId + "/schedules/share" : "/api/teams/" + teamId + "/schedules/withdraw";

        const request: HttpRequestEntity = {
            method: "POST",
            url,
            urlSignature
        };
        const data: ShareWithdrawRequestParams = {
            endTime: endTime.toISOString(),
            notifyAssignedShiftsRecipients: notificationRecipients.assignedShifts,
            notifyOpenShiftsRecipients: notificationRecipients.openShifts,
            startTime: startTime.toISOString()
        };

            request.data = data;

        const response = await NetworkService.sendHttpRequestToStaffHubService(request);

        // parse shifts from response
        const shifts = RestClient.parseShiftsFromResponse(response) || [];

        // parse notes from response
        const notes = RestClient.parseNotesFromResponse(response) || [];

        // parse open shifts from response
        const openShifts = RestClient.parseOpenShiftsFromResponse(response) || [];

        return { shifts, notes, openShifts } as IDataInDateRange;
    }

    /**
     * Retrieves the eTag from the parsed service response for a noteResponseEntity
     * @param shift
     */
    private static _getUpdatedNoteETagFromNoteResponse(noteResponse: INoteEntity): string {
        return noteResponse ? noteResponse.eTag : null;
    }

    /**
     * Retrieves the eTag from the parsed service response for a shiftResponseEntity
     * @param shift
     */
    private static _getUpdatedShiftETagFromShiftResponseEntity(shiftResponse: IShiftResponseEntity): string {
        return shiftResponse ? RestClient._getUpdatedShiftETag(shiftResponse.shift) : null;
    }

    /**
     * Retrieves the eTag from the parsed service response for an open shift
     * @param openShift
     */
    private static _getUpdatedShiftETagFromOpenShift(openShift: IOpenShiftEntity): string {
        return RestClient._getUpdatedShiftETag(openShift);
    }

    /**
     * Retrieves the eTag from the parsed service response for a shift
     * @param shift
     */
    private static _getUpdatedShiftETag(shift: IBaseShiftEntity): string {
        return shift ? shift.eTag : null;
    }

    /**
     * Retrieves the eTag from the parsed service response for bulk shift response
     */
    private static _getUpdatedBulkEntityETagFromBulkShiftResponse(entities: IBulkShiftResponseEntity, id: string): string {
        return RestClient._getUpdatedBulkEntityETag(entities.shifts.concat(entities.openShifts), id);
    }

    /**
     * Retrieves the eTag from the parsed service response for members
     */
    private static _getUpdatedBulkEntityETagFromMembers(entities: Array<IMemberEntity>, id: string): string {
        return RestClient._getUpdatedBulkEntityETag(entities, id);
    }

    /**
     * Retrieves the eTag from the parsed service response for bulk open shift requests response
     */
    private static _getUpdatedBulkEntityETagFromBulkOpenShiftsResponse(entities: IBulkOpenShiftRequestsResponseEntity, id: string): string {
        return RestClient._getUpdatedBulkEntityETag(entities.updatedOpenShiftRequests, id);
    }

    /**
     * Retrieves the eTag from the parsed service response for a list of entities requests call for the given id
     */
    private static _getUpdatedBulkEntityETag(entities: Array<IShiftrObjectModelBase>, id: string): string {
        let eTag = null;
        if (entities && entities.length) {
            const entity = entities.find(entity => entity.id === id);
            if (entity) {
                eTag = entity.eTag;
            }
        }

        return eTag;
    }

    /**
     * Helper function that parses bulk shift data along with generated alerts
     * @param response - axios response
     */
    private static parseShiftsAndAlertsFromResponse(response: AxiosResponse): IBulkShiftResponseEntity {
        let shifts: Array<IShiftEntity>;
        let openShifts: Array<IOpenShiftEntity>;
        let generatedAlerts: Array<string>;

        shifts = RestClient.parseShiftsFromResponse(response);
        openShifts = RestClient.parseOpenShiftsFromResponse(response);
        generatedAlerts = RestClient.parseAlertsFromResponse(response);

        let shiftsAndAlerts: IBulkShiftResponseEntity = {
            shifts: shifts,
            openShifts: openShifts,
            alerts: generatedAlerts
        };
        return shiftsAndAlerts;
    }

    /**
     * Helper function that parses shift request data along with generated alerts
     * @param response - axios response
     */
    private static parseShiftRequestAndAlertsFromResponse(response: AxiosResponse): IApproveDeclineTimeOffRequestResponseEntity {
        let updatedShiftRequest: IShiftRequestEntity = null;
        let updatedShiftClientModel: IShiftEntity = null;
        let generatedRuleViolations: Array<string>;
        let unreadShiftRequests: number = 0;
        if (response && response.data) {
            // response.data.updatedShift will have updated shift as an outcome of shift request approval
            // response.data.updatedShiftRequest will have udpated shift Request as an outcome of shift request approval
            updatedShiftClientModel = ShiftEntity.fromJson(response.data.updatedShift);
            updatedShiftRequest = ShiftRequestEntity.fromJson(response.data.updatedShiftRequest);
            generatedRuleViolations = RestClient.parseAlertsFromResponse(response);
            unreadShiftRequests = parseInt(response.data.unreadShiftRequests);
        }

        let updatedShiftRequestResponseEntity: IApproveDeclineTimeOffRequestResponseEntity = {
            shift: updatedShiftClientModel,
            shiftRequest: updatedShiftRequest,
            alerts: generatedRuleViolations,
            unreadShiftRequests: unreadShiftRequests
        };
        return updatedShiftRequestResponseEntity;
    }

    /**
     * Helper function that parses generated alerts from the response
     * @param response - axios response
     */
    private static parseAlertsFromResponse(response: AxiosResponse): string[] {
        let generatedAlerts: Array<IGeneratedAlertEntity> = [];
        let generatedRuleViolations;

        if (response && response.data && response.data.generatedAlerts) {
            generatedAlerts = (response.data.generatedAlerts as Array<IGeneratedAlertEntity>).map(generatedAlert =>
                GeneratedAlertEntity.fromJson(generatedAlert)
            );
        }
        generatedRuleViolations = RestClient.parseRuleViolations(generatedAlerts);

        return generatedRuleViolations;
    }

    /**
     * Helper function that parses rule violations strings from generated alerts
     * @param generatedAlerts - generated alerts array
     */
    private static parseRuleViolations(generatedAlerts: IGeneratedAlertEntity[]): string[] {
        let ruleViolations: Array<string> = [];
        const violationsDisplayLimit = GENERATED_ALERTS_DISPLAY_LIMIT;
        let kronosStrings = StringsStore().registeredStringModules.get("kronos").strings;
        let violationsToDisplay: number = generatedAlerts.length > violationsDisplayLimit ? violationsDisplayLimit : generatedAlerts.length;
        for (let i = 0; i < violationsToDisplay; i++) {
            let alert = generatedAlerts[i];
            // i.e. name: message
            ruleViolations.push(kronosStrings.get("violationFormat").format(alert.memberDisplayName, alert.message));
        }
        if (generatedAlerts.length > violationsDisplayLimit) {
            let count: string = generatedAlerts.length - violationsDisplayLimit + "";
            let moreStringsDisplayFormat = kronosStrings.get("moreViolationsString");
            ruleViolations.push(moreStringsDisplayFormat.format(count));
        }
        return ruleViolations;
    }

    /**
     * Helper function that parses bulk shift data from the network response
     * @param response - axios response
     */
    private static parseShiftsFromResponse(response: AxiosResponse): IShiftEntity[] {
        // Make sure the shift response has either:
        // shifts array (new API) -or-
        // publishedShifts and unpublishedShifts (old API)
        if (!response || !response.data || !((response.data.publishedShifts && response.data.unpublishedShifts) || response.data.shifts)) {
            let error = createNewHttpError();
            error.message = "shifts response has incomplete shifts data";
            trace.warn(error.message);
            throw error;
        }

        let shifts: Array<IShiftEntity>;

        if (response.data.shifts) {
            // new api
            // TODO: Fix this after service work is complete
            shifts = (response.data.shifts as Array<IShiftServiceEntity>).map(shift => ShiftEntity.fromJson(shift));
        } else if (response.data.publishedShifts && response.data.unpublishedShifts) {
            shifts = [];
            // old api
            // Parse publishedShifts shift info from service response JSON
            let publishedShifts: Array<IShiftServiceEntity> = response.data.publishedShifts;
            publishedShifts.forEach(publishedShift => {
                if (!publishedShift) {
                    return;
                }
                publishedShift.isPublished = true;
                let shiftEntity: IShiftEntity = ShiftEntity.fromJson(publishedShift);
                shifts.push(shiftEntity);
            });

            // Parse unpublishedShifts shift info from service response JSON
            let unpublishedShifts: Array<IShiftServiceEntity> = response.data.unpublishedShifts;
            unpublishedShifts.forEach(unpublishedShift => {
                if (!unpublishedShift) {
                    return;
                }
                unpublishedShift.isPublished = false;
                let shiftEntity: IShiftEntity = ShiftEntity.fromJson(unpublishedShift);
                shifts.push(shiftEntity);
            });
        }

        return shifts;
    }

    /**
     * Helper function that parses bulk notes data from the network response
     * @param response - axios response
     */
    private static parseNotesFromResponse(response: AxiosResponse): INoteEntity[] {
        // Make sure the notes response has either:
        // notes array (new API) -or-
        // publishedNotes and unpublishedNotes (old API)
        if (!response || !response.data || !((response.data.publishedNotes && response.data.unpublishedNotes) || response.data.notes)) {
            let error = createNewHttpError();
            error.message = "notes response has incomplete notes data";
            trace.warn(error.message);
            throw error;
        }

        let notes: Array<INoteEntity>;

        if (response.data.notes) {
            // new api
            notes = (response.data.notes as Array<INoteServiceEntity>).map(note => NoteEntity.fromJson(note));
        } else if (response.data.publishedNotes && response.data.unpublishedNotes) {
            // TODO: Remove this, once service work is complete
            notes = [];
            // old api
            // Parse published notes info from service response JSON
            let publishedNotes: Array<INoteServiceEntity> = response.data.publishedNotes;
            publishedNotes.forEach(publishedNote => {
                publishedNote.isPublished = true;
                notes.push(NoteEntity.fromJson(publishedNote));
            });

            // Parse unpublished notes info from service response JSON
            let unpublishedNotes: Array<INoteServiceEntity> = response.data.unpublishedNotes;
            unpublishedNotes.forEach(unpublishedNote => {
                unpublishedNote.isPublished = false;
                notes.push(NoteEntity.fromJson(unpublishedNote));
            });
        }

        return notes;
    }

    /**
     * Helper method that parses shift and generatedAlerts
     * @param response
     */
    private static parseShiftAndAlertResponse(response: AxiosResponse): IShiftResponseEntity {
        let shift = RestClient.parseShiftResponse(response);
        let generatedAlerts = RestClient.parseAlertsFromResponse(response);
        let shiftAndAlert: IShiftResponseEntity = {
            shift: shift,
            alerts: generatedAlerts
        };
        return shiftAndAlert;
    }

    /**
     * Helper method that parses shift and generatedAlerts
     * @param response
     */
    private static parseUpdatedShiftResponse(response: AxiosResponse): IUpdateShiftResponseEntity {
        let shift = RestClient.parseShiftResponse(response);
        let generatedAlerts = RestClient.parseAlertsFromResponse(response);
        let openShift = RestClient.parseOpenShiftFromResponse(response);
        let updatedShift: IUpdateShiftResponseEntity = {
            shift: shift,
            alerts: generatedAlerts,
            openShift: openShift
        };
        return updatedShift;
    }

    /**
     * Helper method that validates and parses the shift data
     * Throws an error if shift is missing
     * @param response
     */
    private static parseShiftResponse(response: AxiosResponse): IShiftEntity {
        if (!response || !response.data || !response.data.shift) {
            let error = createNewHttpError();
            error.message = "shift response has incomplete shift data";
            trace.warn(error.message);
            throw error;
        }

        return ShiftEntity.fromJson(response.data.shift);
    }

    /**
     * Helper method that validates and parses the tags data
     * Throws an error if tags are missing
     * @param response
     */
    private static parseTagsFromResponse(response: AxiosResponse): ITagEntity[] {
        let tags = [];
        if (!response || !response.data || !response.data.tags) {
            let error = createNewHttpError();
            error.message = "tag response has incomplete tags data";
            trace.warn(error.message);
            throw error;
        } else {
            for (let tag of response.data.tags) {
                tags.push(TagEntity.fromJson(tag));
            }
        }

        return tags;
    }

    /**
     * Helper method that validates and parses the tags data
     * Throws an error if tags are missing
     * @param response
     */
    private static parseTagFromResponse(response: AxiosResponse): ITagEntity {
        let tag: ITagEntity = null;
        if (!response || !response.data || !response.data.tag) {
            let error = createNewHttpError();
            error.message = "tag response has incomplete tag data";
            trace.warn(error.message);
            throw error;
        } else {
            tag = TagEntity.fromJson(response.data.tag);
        }

        return tag;
    }

    /**
     * Helper method that validates and parses unique shift data from api
     * throws an error if uniqueShift list is missing
     * @param response Helper method that
     */
    private static parseUniqueShiftsFromResponse(response: AxiosResponse): Array<IUniqueShiftEntity> {
        if (!response || !response.data || !response.data.uniqueShifts) {
            let error = createNewHttpError();
            error.message = "sh-rest-client: unique shift response does not have uniqueShift data";
            trace.warn(error.message);
            throw error;
        }
        let uniqueShifts: Array<IUniqueShiftEntity>;
        uniqueShifts = (response.data.uniqueShifts as Array<IUniqueShiftEntity>).map(uniqueShift =>
            UniqueShiftEntity.fromJson(uniqueShift)
        );
        return uniqueShifts;
    }

    /**
     * Helper method that validates and parses unique subshift data from api
     * throws an error if uniqueSubshift list is missing
     * @param response Helper method that
     */
    private static parseUniqueSubshiftsFromResponse(response: AxiosResponse): Array<IUniqueSubshiftEntity> {
        if (!response || !response.data || !response.data.uniqueSubshifts) {
            let error = createNewHttpError();
            error.message = "sh-rest-client: unique subshift response does not have uniqueSubshifts data";
            trace.warn(error.message);
            throw error;
        }
        let uniqueSubshifts: Array<IUniqueSubshiftEntity>;
        uniqueSubshifts = (response.data.uniqueSubshifts as Array<IUniqueSubshiftEntity>).map(uniqueShift =>
            UniqueSubshiftEntity.fromJson(uniqueShift)
        );
        return uniqueSubshifts;
    }

    /**
     * Helper method that validates and parses
     * Throws an error if note is missing
     * @param response
     */
    private static parseNoteResponse(response: AxiosResponse): INoteEntity {
        if (!response || !response.data || !response.data.note) {
            let error = createNewHttpError();
            error.message = "note response has incomplete note data";
            trace.warn(error.message);
            throw error;
        }

        return NoteEntity.fromJson(response.data.note);
    }

    /**
     * Add Shift to given team by sending AddShiftRequest
     * @param {String} tenantId
     * @param {String} teamId
     * @param {IShiftEntity} shift
     * @returns {} Promise of network request with CreateShift request
     */
    public static addShift(tenantId: string, teamId: string, shift: IShiftEntity): Promise<IShiftResponseEntity> {
        const urlSignature = "/api/tenants/tenantId/teams/teamId/shifts";
        const url = "/api/tenants/" + tenantId + "/teams/" + teamId + "/shifts";

        return NetworkQueue.addRequestWithUpdateEtag(
            shift.id,
            async (updatedETag?: string) => {
                const response: AxiosResponse = await NetworkService.sendHttpRequestToStaffHubService({
                    method: "POST",
                    url: url,
                    urlSignature: urlSignature,
                    data: ShiftEntity.toJson(shift)
                });
                return RestClient.parseShiftAndAlertResponse(response);
            },
            RestClient._getUpdatedShiftETagFromShiftResponseEntity
        );
    }

    /**
     * Update Shift to given team by sending UpdateShift request
     * @param {String} tenantId
     * @param {String} teamId
     * @param {IShiftEntity} shift
     * @returns {} Promise of network request with UpdateShift request
     */
    public static updateShift(tenantId: string, teamId: string, shift: IShiftEntity): Promise<IUpdateShiftResponseEntity> {
        const urlSignature = "/api/tenants/tenantId/teams/teamId/shifts/shftId";
        const url = "/api/tenants/" + tenantId + "/teams/" + teamId + "/shifts/" + shift.id;

        return NetworkQueue.addRequestWithUpdateEtag(
            shift.id,
            async (updatedETag?: string) => {
                // if there is an updatedETag, update our payload with the new
                // eTag so that we don't get a 409 conflict
                if (updatedETag) {
                    shift.eTag = updatedETag;
                }
                const response: AxiosResponse = await NetworkService.sendHttpRequestToStaffHubService({
                    method: "PUT",
                    url: url,
                    urlSignature: urlSignature,
                    data: ShiftEntity.toJson(shift)
                });
                return RestClient.parseUpdatedShiftResponse(response);
            },
            RestClient._getUpdatedShiftETagFromShiftResponseEntity
        );
    }

    /**
     * Delete Shift to given team by sending Delete Shift request
     * @param {String} tenantId
     * @param {String} teamId
     * @param {IShiftEntity} shift
     * @returns {} Promise of network request with DeleteShift request
     */
    public static deleteShift(tenantId: string, teamId: string, shift: IShiftEntity): Promise<IShiftResponseEntity> {
        const urlSignature = "/api/tenants/tenantId/teams/teamId/shifts/shftId";
        const url = "/api/tenants/" + tenantId + "/teams/" + teamId + "/shifts/" + shift.id;

        return NetworkQueue.addRequestWithUpdateEtag(
            shift.id,
            async (updatedETag?: string) => {
                // if there is an updatedETag, update our payload with the new
                // eTag so that we don't get a 409 conflict
                if (updatedETag) {
                    shift.eTag = updatedETag;
                }
                const response: AxiosResponse = await NetworkService.sendHttpRequestToStaffHubService({
                    method: "DELETE",
                    url: url,
                    urlSignature: urlSignature,
                    data: {
                        eTag: shift.eTag,
                        isPublished: shift.isPublished
                    }
                });
                return RestClient.parseShiftAndAlertResponse(response);
            },
            RestClient._getUpdatedShiftETagFromShiftResponseEntity
        );
    }

    /**
     * Bulk Copy Schedule
     * @param {String} teamId
     * @param {Moment} sourceStartTime - start of timerange for shifts to copy from
     * @param {Moment} sourceEndTime - end of timerange for shifts to copy from
     * @param {Number} destinationOffsetInDays - offset of days for each shift to copy to
     * @param {Boolean} copyNotes - copy notes from source to dest
     * @param {Boolean} copyTimeOffShifts - copy abscence shifts from source to dest
     * @param {Boolean} copyActivities - copy subshifts from source to dest
     * @param {Boolean} copyOpenShifts - copy open shifts from source to dest
     * @param {Number} scheduleRepetition - copy open shifts from source to dest
     * @param {DataFilter} dataFilter - data filter - if passed, service will only return objects that match
     * @returns {Object} jobEntity
     */
    public static async bulkCopySchedule(
        teamId: string,
        sourceStartTime: Moment,
        sourceEndTime: Moment,
        destinationOffsetInDays: number,
        copyNotes: boolean,
        copyTimeOffShifts: boolean,
        copyActivities: boolean,
        copyOpenShifts: boolean,
        scheduleRepetition: number,
        dataFilter?: DataFilter
    ): Promise<JobEntity> {
        const urlSignature = "/api/teams/teamId/bulk/copySchedule";
        const url = `/api/teams/${teamId}/bulk/copySchedule`;
        let copyScheduleJob: JobEntity = null;
        const copyScheduleResponse: AxiosResponse = await NetworkService.sendHttpRequestToStaffHubService({
            method: "POST",
            url: url,
            urlSignature: urlSignature,
            data: {
                sourceStartTime: sourceStartTime,
                sourceEndTime: sourceEndTime,
                destinationDaysOffset: destinationOffsetInDays,
                copyShiftNotes: copyNotes,
                copyTimeOffShifts: copyTimeOffShifts,
                copyActivities: copyActivities,
                copyOpenShifts: copyOpenShifts,
                scheduleRepetition: scheduleRepetition,
                schedulingGroupsFilter: dataFilter && dataFilter.tagIds,
                memberIdsFilter: dataFilter && dataFilter.memberIds
            }
        });

        if (copyScheduleResponse && copyScheduleResponse.data) {
            copyScheduleJob = JobEntity.fromJson(copyScheduleResponse.data);
        }

        return copyScheduleJob;
    }

    /**
     * Bulk Undo Copied Schedule
     * @param {String} teamId
     * @param {String} jobId
     * @returns {Object} jobEntity
     */
    public static async undoJob(teamId: string, jobId: string): Promise<JobEntity> {
        const urlSignature = "/api/teams/teamId/jobs/jobId/undo";
        const url = `/api/teams/${teamId}/jobs/${jobId}/undo`;

        let undoJob: JobEntity = null;
        const undoCopyScheduleResponse: AxiosResponse = await NetworkService.sendHttpRequestToStaffHubService({
            method: "PUT",
            url: url,
            urlSignature: urlSignature
        });

        if (undoCopyScheduleResponse && undoCopyScheduleResponse.data) {
            undoJob = JobEntity.fromJson(undoCopyScheduleResponse.data);
        }

        return undoJob;
    }

    /**
     * Bulk Add shifts
     * @param {String} tenantId
     * @param {String} teamId
     * @param {Array} shifts
     * @returns {Object} Promise that resolves to list of added shifts
     */
    public static bulkAddShifts(
        tenantId: string,
        teamId: string,
        shifts: Array<IShiftEntity>,
        openShifts: Array<IOpenShiftEntity>
    ): Promise<IBulkShiftResponseEntity> {
        const urlSignature = "/api/tenants/tenantId/teams/teamId/bulk";
        const url = `/api/tenants/${tenantId}/teams/${teamId}/bulk`;

        let shiftIds: string[] = [];
        if (shifts && shifts.length) {
            shiftIds = shifts.map(shift => shift.id);
        }
        if (openShifts && openShifts.length) {
            shiftIds = shiftIds.concat(openShifts.map(openShift => openShift.id));
        }

        return NetworkQueue.addBulkRequest(
            shiftIds,
            async (updatedETag?: string) => {
                const response: AxiosResponse = await NetworkService.sendHttpRequestToStaffHubService({
                    method: "POST",
                    url: url,
                    urlSignature: urlSignature,
                    data: {
                        shifts: shifts.map(shift => ShiftEntity.toJson(shift)),
                        openShifts: openShifts.map(openShift => OpenShiftEntity.toJson(openShift))
                    }
                });
                return RestClient.parseShiftsAndAlertsFromResponse(response);
            },
            RestClient._getUpdatedBulkEntityETagFromBulkShiftResponse
        );
    }

    /**
     * Get Shift
     * @param {String} tenantId
     * @param {String} teamId
     * @param {String} shiftId
     * @returns {Object} Promise that resolves to list of shifts assigned to given team
     */
    public static async getShift(tenantId: string, teamId: string, shiftId: string): Promise<IBaseShiftEntity> {
        const urlSignature = "/api/tenants/tenantId/teams/teamId/shifts/shftId";
        const url = "/api/tenants/" + tenantId + "/teams/" + teamId + "/shifts/" + shiftId;

        const response = await NetworkService.sendHttpRequestToStaffHubService({
            method: "GET",
            url: url,
            urlSignature: urlSignature
        });
        return RestClient.parseShiftResponse(response);
    }

    /**
     * Get Shifts (optionally fetch shifts between time range)
     * @param {String} tenantId
     * @param {String} teamId
     * @param {Moment} startTime - minimum start time of shifts
     * @param {Moment} endTime - max end time of shifts
     * @returns {} Promise that resolves to list of shifts assigned to given team
     */
    public static async getShifts(tenantId: string, teamId: string, startTime?: Moment, endTime?: Moment): Promise<IShiftEntity[]> {
        const urlSignature = "/api/tenants/tenantId/teams/teamId/shifts";
        let url = "/api/tenants/" + tenantId + "/teams/" + teamId + "/shifts";

        if (startTime || endTime) {
            url += "?";
        }
        if (startTime) {
            url += "&startTime=" + encodeURIComponent(startTime.toISOString());
        }
        if (endTime) {
            url += "&endTime=" + encodeURIComponent(endTime.toISOString());
        }

        const response: AxiosResponse = await NetworkService.sendHttpRequestToStaffHubService({
            method: "GET",
            url: url,
            urlSignature: urlSignature
        });
        return RestClient.parseShiftsFromResponse(response);
    }

    /**
     * Get CopyOfShifts when copied from given time duration to different
     * @param {String} tenantId
     * @param {String} teamId
     * @param {Moment} sourceStartTime - start of timerange for shifts to copy from
     * @param {Moment} sourceEndTime - end of timerange for shifts to copy from
     * @param {Number} destinationDaysOffset - offset of days for each shift to copy to
     * @param {Boolean} copyNotes - copy notes from source to dest
     * @param {Boolean} copyAbsenceShifts - copy abscence shifts from source to dest
     * @param {Boolean} copySubshifts - copy subshifts from source to dest
     * @param {Boolean} copyOpenShifts - copy open shifts from source to dest
     * @returns {Object} Promise that resolves to list of shifts assigned to given team
     */
    public static async getCopyOfShifts(
        tenantId: string,
        teamId: string,
        sourceStartTime: Moment,
        sourceEndTime: Moment,
        destinationDaysOffset: number,
        copyNotes: boolean,
        copyAbsenceShifts: boolean,
        copySubshifts: boolean,
        copyOpenShifts: boolean
    ): Promise<CopyOfShiftsResponse> {
        const urlSignature = "/api/tenants/tenantId/teams/teamId/shifts/CopyOfShifts";
        let url =
            "/api/tenants/" +
            tenantId +
            "/teams/" +
            teamId +
            "/shifts/CopyOfShifts?" +
            "sourceStartTime=" +
            encodeURIComponent(sourceStartTime.toISOString()) +
            "&sourceEndTime=" +
            encodeURIComponent(sourceEndTime.toISOString()) +
            "&destinationDaysOffset=" +
            destinationDaysOffset +
            "&copyNotes=" +
            copyNotes +
            "&copyAbsenceShifts=" +
            copyAbsenceShifts +
            "&copySubshifts=" +
            copySubshifts +
            "&copyOpenShifts=" +
            copyOpenShifts;

        const copyOfShiftsResponse: any = await NetworkService.sendHttpRequestToStaffHubService({
            method: "GET",
            url: url,
            urlSignature: urlSignature
        });

        return CopyOfShiftsResponse.fromJson(copyOfShiftsResponse.data);
    }

    /**
     * Get Team for current Loggedin user
     * @returns {Promise<TeamDetailsResponse>}
     */
    public static async getTeam(tenantId: string, teamId: string): Promise<TeamDetailsResponse> {
        const urlSignature = "/api/tenants/tenantId/teams/teamId";
        const url = "/api/tenants/" + tenantId + "/teams/" + teamId;

        const getTeamResponse: any = await NetworkService.sendHttpRequestToStaffHubService({
            method: "GET",
            url: url,
            urlSignature: urlSignature
        });

        return TeamDetailsResponse.fromJson(getTeamResponse.data);
    }

    /**
     * Get Team (from groupId) for current Loggedin user
     * @returns {Promise<TeamDetailsResponse>}
     */
    public static async getGroup(groupId: string): Promise<TeamDetailsResponse> {
        const urlSignature = "/api/groups/groupId";
        const url = "/api/groups/" + groupId;

        const getTeamResponse: any = await NetworkService.sendHttpRequestToStaffHubService({
            method: "GET",
            url: url,
            urlSignature: urlSignature
        });

        return TeamDetailsResponse.fromJson(getTeamResponse.data);
    }

    /**
     * Provisions a Shifts (StaffHub) team from a Teams team
     * @param {string} groupId - The Modern group ID
     * @param {string} teamsTeamId - The ID of the Microsoft Teams' team
     * @param {string} teamTimezone - The olson code that represents the teams time zone
     * @returns {Promise<ScheduleProvisionResponse>} Promise that resolves to the provision team job details
     */
    public static async scheduleProvision(groupId: string, teamsTeamId: string, teamTimezone: string): Promise<IScheduleProvisionResponse> {
        const urlSignature = "/api/teams/provision";
        const url = "/api/teams/provision";

        const scheduleProvisionResponse: any = await NetworkService.sendHttpRequestToStaffHubService({
            method: "POST",
            url: url,
            urlSignature: urlSignature,
            data: {
                groupId: groupId,
                teamsTeamId: teamsTeamId,
                timeZoneOlsonCode: teamTimezone
            }
        });

        return ScheduleProvisionResponse.fromJson(scheduleProvisionResponse.data);
    }

    /**
     * Check status of a  ScheduleProvision job
     * @param jobId A unique id for the job being checked.
     */
    public static async checkProvisionScheduleJobStatus(jobId: string): Promise<IJobEntity> {
        const urlSignature = "/api/jobs/teams/provision/jobId";
        const url = "/api/jobs/teams/provision/" + jobId;

        const response: AxiosResponse = await NetworkService.sendHttpRequestToStaffHubService({
            method: "GET",
            url: url,
            urlSignature: urlSignature
        });

        let job: IJobEntity = null;

        if (response.data) {
            job = JobEntity.fromJson(response.data);
        }

        return job;
    }

    /**
     * Adds a team where the signed in user is the manager for the team
     * @param {ITeamInfoEntity} team
     * @param {string} teamStartingDayOfWeek - The day of the week to start the calendars at for the team = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
     * @param {string} teamTimezone - The olson code that represents the teams time zone
     * @param {boolean} skipGroupProvisioning - Skip provisioning the modern group
     * @returns {Promise<TeamDetailsResponse>} Promise that resolves to team details
     */
    public static async addTeam(
        team: ITeamInfoEntity,
        teamStartingDayOfWeek: string,
        teamTimezone: string,
        skipGroupProvisioning: boolean
    ): Promise<TeamDetailsResponse> {
        const urlSignature = "/api/teams";
        const url = "/api/teams";

        const createTeamResponse: any = await NetworkService.sendHttpRequestToStaffHubService({
            method: "POST",
            url: url,
            urlSignature: urlSignature,
            data: {
                id: team.id,
                handle: team.handle,
                name: team.name,
                location: team.location,
                pictureUrl: team.pictureUrl,
                startingDayOfWeek: teamStartingDayOfWeek,
                timeZoneOlsonCode: teamTimezone,
                countryCode: team.countryCode || "",
                classification: team.classification,
                customFields: team.customFields,
                locationSettings: team.locationSettings,
                timeClockEnabled: team.timeClockEnabled,
                skipGroupProvisioning: skipGroupProvisioning
            }
        });

        return TeamDetailsResponse.fromJson(createTeamResponse.data);
    }

    /**
     * Sets the Team info.
     * @returns {Promise}
     */
    public static async setTeam(team: ITeamInfoEntity): Promise<ITeamInfoEntity> {
        const urlSignature = "/api/tenants/tenantId/teams/teamId";
        const url = "/api/tenants/" + team.tenantId + "/teams/" + team.id;

        function _getUpdatedETag(response: AxiosResponse) {
            return response && response.data && response.data.team ? response.data.team.eTag : null;
        }

        const response: AxiosResponse = await NetworkQueue.addRequestWithUpdateEtag(
            "setTeam",
            (updatedETag?: string) => {
                team.eTag = updatedETag ? updatedETag : team.eTag;

                return NetworkService.sendHttpRequestToStaffHubService({
                    method: "PUT",
                    url: url,
                    urlSignature: urlSignature,
                    data: TeamInfoEntity.toJson(team)
                });
            },
            _getUpdatedETag
        );

        return TeamInfoEntity.fromJson(response.data.team);
    }

    /**
     * Deletes a team
     * USE ONLY FOR TESTING
     * @param {Object} team is the team to be deleted
     * @returns {Object} Promise that resolves to a string of the teamId that was deleted
     */
    public static deleteTeam(team: ITeamInfoEntity): Promise<AxiosResponse> {
        let urlSignature = "/api/tenants/tenantId/teams/teamId";
        let url = "/api/tenants/" + team.tenantId + "/teams/" + team.id;

        return NetworkService.sendHttpRequestToStaffHubService({
            method: "DELETE",
            url: url,
            urlSignature: urlSignature,
            data: {
                eTag: team.eTag
            }
        });
    }

    /**
     * Get Teams information for current Loggedin user
     * @param {TeamManagedByType} managedByFilter - optional param to filter the results
     * @returns {Promise}
     */
    public static async getTeams(managedByFilter?: TeamManagedByType): Promise<ITeamInfoEntity[]> {
        const urlSignature = "/api/teams";
        let url = "/api/teams";

        if (managedByFilter) {
            url += `?managedBy=${managedByFilter}`;
        }
        let getTeamsResponse: AxiosResponse = await NetworkService.sendHttpRequestToStaffHubService({
            method: "GET",
            url: url,
            urlSignature: urlSignature
        });

        let teamClientModels: ITeamInfoEntity[] = [];
        getTeamsResponse.data.teams.map((currentTeamDataJson: any) => {
            teamClientModels.push(TeamInfoEntity.fromJson(currentTeamDataJson));
        });

        return teamClientModels;
    }

    /**
     * Get members
     * @param {String} tenantId
     * @param {String} teamId
     * @returns {Promise} Promise that resolves to list of members assigned to given team
     */
    public static async getMembers(tenantId: string, teamId: string): Promise<IMemberEntity[]> {
        const urlSignature = "/api/tenants/tenantId/teams/teamId/members";
        const url = "/api/tenants/" + tenantId + "/teams/" + teamId + "/members";

        const response: AxiosResponse = await NetworkService.sendHttpRequestToStaffHubService({
            method: "GET",
            url: url,
            urlSignature: urlSignature
        });

        let memberClientModels: IMemberEntity[] = [];
        if (response && response.data && response.data.members && response.data.members.length) {
            response.data.members.map((currentMemberDataJson: any) => {
                // Convert to client model object
                const memberClientModel = MemberEntity.fromJson(currentMemberDataJson);

                memberClientModels.push(memberClientModel);
            });
        }

        return memberClientModels;
    }

    /**
     * Get member by id
     * @param {String} tenantId
     * @param {String} teamId
     * @param {String} memberId
     * @returns {Promise} Promise that resolves to list of members assigned to given team
     */
    public static async getMemberById(tenantId: string, teamId: string, memberId: string): Promise<IMemberEntity> {
        const urlSignature = "/api/tenants/tenantId/teams/teamId/members/memberId";
        const url = "/api/tenants/" + tenantId + "/teams/" + teamId + "/members/" + memberId;

        const response = await NetworkService.sendHttpRequestToStaffHubService({
            method: "GET",
            url: url,
            urlSignature: urlSignature
        });
        return RestClient.parseMemberInfoFromResponse(response);
    }

    /**
     * Gets the latest time clock entry for a member
     * @param {String} teamId
     * @param {String} memberId
     * @returns {Promise} Promise that resolves to an array of time clock entity
     */
    public static async getLatestTimeClockEntryForMember(teamId: string, memberId: string): Promise<ITimeClockEntity> {
        const urlSignature = "/api/v2/teams/teamId/timeclock";
        let url = "/api/v2/teams/" + teamId + "/timeclock?";

        if (memberId) {
            url += "$filter=MemberId eq '" + memberId + "'&";
        }

        // we just want the latest entry so count will always be 1 and we will sort by the clock in time
        url += "$orderby=ClockInTime desc&count=1";

        const timeClocksResponse: any = await NetworkService.sendHttpRequestToStaffHubService({
            method: "GET",
            url: url,
            urlSignature: urlSignature
        });
        if (
            timeClocksResponse &&
            timeClocksResponse.data &&
            timeClocksResponse.data.timeClocks &&
            timeClocksResponse.data.timeClocks.length > 0
        ) {
            // service will return and array with one time clock entry so we will index the array to get the latest entry
            return timeClocksResponse.data.timeClocks[0];
        } else {
            return null;
        }
    }

    /**
     * Clock in for Time Clock
     * @param {Boolean} isAtApprovedLocation
     * @param {String} teamId
     * @returns {Promise} Promise that resolves to timeclock entity
     */
    public static async clockIn(isAtApprovedLocation: boolean, teamId: string): Promise<ITimeClockEntity> {
        const urlSignature = "/api/v2/teams/teamId/timeclock/clockin";
        const url = "/api/v2/teams/" + teamId + "/timeclock/clockin";
        const clockInResponse: any = await NetworkService.sendHttpRequestToStaffHubService({
            method: "POST",
            url: url,
            urlSignature: urlSignature,
            data: {
                isAtApprovedLocation: isAtApprovedLocation
            }
        });
        if (clockInResponse && clockInResponse.data && clockInResponse.data.timeClockEntry) {
            return TimeClockEntity.fromJson(clockInResponse.data.timeClockEntry);
        } else {
            return null;
        }
    }

    /**
     * Clock out for Time Clock
     * @param {Boolean} isAtApprovedLocation
     * @param {String} teamId
     * @param {String} timeClockId
     * @returns {Promise} Promise that resolves to timeclock entity
     */
    public static async clockOut(isAtApprovedLocation: boolean, teamId: string, timeClockId: string): Promise<ITimeClockEntity> {
        const urlSignature = "/api/v2/teams/teamId/timeclock/timeClockId/clockout";
        const url = "/api/v2/teams/" + teamId + "/timeclock/" + timeClockId + "/clockout";
        const clockOutResponse: any = await NetworkService.sendHttpRequestToStaffHubService({
            method: "POST",
            url: url,
            urlSignature: urlSignature,
            data: {
                isAtApprovedLocation: isAtApprovedLocation
            }
        });
        if (clockOutResponse && clockOutResponse.data && clockOutResponse.data.timeClockEntry) {
            return TimeClockEntity.fromJson(clockOutResponse.data.timeClockEntry);
        } else {
            return null;
        }
    }

    /**
     * Start break for Time Clock
     * @param {Boolean} isAtApprovedLocation
     * @param {String} teamId
     * @param {String} timeClockId
     * @returns {Promise} Promise that resolves to timeclock entity
     */
    public static async startBreak(isAtApprovedLocation: boolean, teamId: string, timeClockId: string): Promise<ITimeClockEntity> {
        const urlSignature = "/api/v2/teams/teamId/timeclock/timeClockId/startbreak";
        const url = "/api/v2/teams/" + teamId + "/timeclock/" + timeClockId + "/startbreak";
        const startBreakResponse: any = await NetworkService.sendHttpRequestToStaffHubService({
            method: "POST",
            url: url,
            urlSignature: urlSignature,
            data: {
                isAtApprovedLocation: isAtApprovedLocation
            }
        });
        if (startBreakResponse && startBreakResponse.data && startBreakResponse.data.timeClockEntry) {
            return TimeClockEntity.fromJson(startBreakResponse.data.timeClockEntry);
        } else {
            return null;
        }
    }

    /**
     * End break for Time Clock
     * @param {Boolean} isAtApprovedLocation
     * @param {String} teamId
     * @param {String} timeClockId
     * @returns {Promise} Promise that resolves to timeclock entity
     */
    public static async endBreak(isAtApprovedLocation: boolean, teamId: string, timeClockId: string): Promise<ITimeClockEntity> {
        const urlSignature = "/api/v2/teams/teamId/timeclock/timeClockId/endbreak";
        const url = "/api/v2/teams/" + teamId + "/timeclock/" + timeClockId + "/endbreak";
        const endBreakResponse: any = await NetworkService.sendHttpRequestToStaffHubService({
            method: "POST",
            url: url,
            urlSignature: urlSignature,
            data: {
                isAtApprovedLocation: isAtApprovedLocation
            }
        });
        if (endBreakResponse && endBreakResponse.data && endBreakResponse.data.timeClockEntry) {
            return TimeClockEntity.fromJson(endBreakResponse.data.timeClockEntry);
        } else {
            return null;
        }
    }

    /**
     * Set Team Settings Item
     * @param {String} tenantId
     * @param {ITeamSettingEntity} teamSettings
     * @returns {Promise} Promise that resolves to response from updating team settings
     */
    public static async setTeamSettingsItem(
        tenantId: string,
        teamId: string,
        teamSettingsItem: ITeamSettingEntity
    ): Promise<ITeamSettingEntity> {
        let urlSignature = "/api/tenants/tenantId/teams/teamId/teamsettings/tmstid";
        let url = "/api/tenants/" + tenantId + "/teams/" + teamId + "/teamsettings/" + teamSettingsItem.id;

        let teamSettingsClone = toJS(teamSettingsItem);

        function _getUpdatedETag(response: AxiosResponse) {
            return response && response.data && response.data.teamSetting ? response.data.teamSetting.eTag : null;
        }

        const response: AxiosResponse = await NetworkQueue.addRequestWithUpdateEtag(
            "setTeamSettingsItem",
            (updatedETag?: string) => {
                // if there is an updatedETag, update our teamSettingsClone payload with the new
                // eTag so that we don't get a 409 conflict
                if (updatedETag) {
                    teamSettingsClone.eTag = updatedETag;
                }

                return NetworkService.sendHttpRequestToStaffHubService({
                    method: "PUT",
                    url: url,
                    urlSignature: urlSignature,
                    data: {
                        eTag: teamSettingsItem.eTag,
                        value: teamSettingsItem.value,
                        key: teamSettingsItem.key
                    }
                });
            },
            _getUpdatedETag
        );
        return TeamSettingEntity.fromJson(response.data.teamSetting);
    }

    /**
     * The API to store the dismissed conflict entity
     * @param teamId string team Id of the dismiss entity
     * @param entityOne DismissedEntity belonging to the Conflict entity that is to be dismissed
     * @param entityTwo DismissedEntity belonging to the Conflict entity that is to be dismissed
     */
    public static async createDismissal(
        teamId: string,
        entityOne: IDismissEntity,
        entityTwo: IDismissEntity
    ): Promise<IConflictDismissEntity> {
        let urlSignature = "/api/teams/teamId/dismissConflict";
        let url = "/api/teams/" + teamId + "/dismissConflict";

        const response: any = await NetworkService.sendHttpRequestToStaffHubService({
            method: "POST",
            url: url,
            urlSignature: urlSignature,
            data: {
                entityOne: entityOne,
                entityTwo: entityTwo
            }
        });

        if (response.data && response.data && response.data.conflictDismissal) {
            return ConflictDismissEntity.fromJson(response.data.conflictDismissal);
        }
        return null;
    }

    /**
     * The API to undo dismiss entity in service
     * @param teamId string team Id of the dismiss entity
     * @param string conflict dismissal id to undo
     */
    public static async undoDismissConflict(
        teamId: string,
        conflictDismissEntity: IConflictDismissEntity
    ): Promise<IConflictDismissEntity> {
        if (!teamId || !conflictDismissEntity) {
            return null;
        }
        let urlSignature = "/api/teams/teamId/conflictdismissal/conflictDismissalId";
        let url = "/api/teams/" + teamId + "/conflictdismissal/" + conflictDismissEntity.id;

        const response: any = await NetworkService.sendHttpRequestToStaffHubService({
            method: "DELETE",
            url: url,
            urlSignature: urlSignature,
            data: {
                etag: conflictDismissEntity.eTag
            }
        });

        if (response.data && response.data && response.data.deletedConflictDismissal) {
            return ConflictDismissEntity.fromJson(response.data.deletedConflictDismissal);
        }
        return null;
    }

    /**
     * The API to fetch all the previously dismissed conflict entities from service
     * @param teamId teamId of shifts user
     * @param startTime startTime range of the entities to be fetched
     * @param endTime endTime range of the entities to be fetched
     */
    public static async getDismissedConflicts(
        teamId: string,
        startTime?: Moment,
        endTime?: Moment,
        nextLink?: string
    ): Promise<IConflictDismissResponseEntity> {
        let urlSignature = "/api/teams/teamId/conflictdismissals";
        let url = "/api/teams/" + teamId + "/conflictdismissals";
        let params = [];
        let paramsString;

        if (startTime) {
            params.push(`startTime=${encodeURIComponent(startTime.toISOString())}`);
        }

        if (endTime) {
            params.push(`endTime=${encodeURIComponent(endTime.toISOString())}`);
        }

        paramsString = params.join("&");

        if (paramsString) {
            url += "?" + paramsString;
        }

        if (nextLink) {
            url += "&nextLink=" + encodeURIComponent(nextLink);
        }

        const response: any = await NetworkService.sendHttpRequestToStaffHubService({
            method: "GET",
            url: url,
            urlSignature: urlSignature
        });

        const conflictDismissals: IConflictDismissEntity[] = RestClient.parseConflictsFromResponse(response);
        const nextLinkResponse: string = RestClient.parseNextLinkFromResponse(response);
        return { conflictDismissals, nextLinkResponse } as IConflictDismissResponseEntity;
    }

    /**
     * Helper function that parses bulk dismissed conflicts data from the network response
     * @param response - axios response
     */
    private static parseConflictsFromResponse(response: AxiosResponse): IConflictDismissEntity[] {
        if (!response || !response.data || !response.data.conflictDismissals) {
            let error = createNewHttpError();
            error.message = "dismissed conflicts response has incomplete data";
            trace.warn(error.message);
            throw error;
        }

        let conflicts: Array<IConflictDismissEntity>;

        if (response.data.conflictDismissals) {
            conflicts = (response.data.conflictDismissals as Array<IConflictDismissEntity>).map(conflict =>
                ConflictDismissEntity.fromJson(conflict)
            );
        }

        return conflicts;
    }

    /**
     * Get the localized strings file for the current culture.
     * @param {String} stringsFileUrl - Absolute URL to the strings file (defaults to users current locale)
     * @returns {} : Promise with the response.
     */
    public static getClientStrings(stringsFileUrl: string = window.sa.stringsFile): Promise<AxiosResponse> {
        const urlSignature = stringsFileUrl;
        const url = stringsFileUrl;

        return NetworkService.sendHttpRequestToStaffHubWeb({
            method: "GET",
            url: url,
            urlSignature: urlSignature
        });
    }

    /**
     * Gets the application settings for a specific team
     * @param {String} tenantId
     * @param {String} teamId
     */
    public static getAppSettingsForTeam(tenantId: string, teamId: string): Promise<AxiosResponse> {
        const urlSignature = "/api/tenants/tenantId/teams/teamId/appsettings";
        const url = "/api/tenants/" + tenantId + "/teams/" + teamId + "/appsettings";

        return NetworkService.sendHttpRequestToStaffHubService({
            method: "GET",
            url: url,
            urlSignature: urlSignature
        });
    }

    /**
     * Gets the application settings for a tenant
     * @param {String} tenantId
     */
    public static async getAppSettingsForTenant(tenantId: string): Promise<AppSettingsClientModel> {
        const urlSignature = "/api/tenants/tenantId/appsettings";
        const url = "/api/tenants/" + tenantId + "/appsettings";

        const response: AxiosResponse = await NetworkService.sendHttpRequestToStaffHubService({
            method: "GET",
            url: url,
            urlSignature: urlSignature
        });

        if (response && response.data && response.data.appSettings) {
            // Parse settings info from service response JSON
            const appSettingsServiceModel = AppSettingsServiceModel.fromJson(response.data.appSettings);
            // Convert to client model object
            const appSettingsClientModel = AppSettingsServiceModel.toClientModel(appSettingsServiceModel);
            return appSettingsClientModel;
        } else {
            return null;
        }
    }

    /**
     * Adds a team member
     * @param {String} tenantId
     * @param {String} teamId
     * @param {IMemberEntity} member
     * @returns {} - Promise with the response.
     */
    public static async addTeamMember(tenantId: string, teamId: string, member: IMemberEntity): Promise<IMemberEntity> {
        const urlSignature = "/api/tenants/tenantId/teams/teamId/members";
        const url = "/api/tenants/" + tenantId + "/teams/" + teamId + "/members";

        const response: any = await NetworkService.sendHttpRequestToStaffHubService({
            method: "POST",
            url: url,
            urlSignature: urlSignature,
            data: MemberEntity.toJson(member)
        });

        return MemberEntity.fromJson(response.data.member);
    }

    /**
     * Updates an existing team member
     * @param {String} tenantId
     * @param {String} teamId
     * @param {IMemberEntity} member
     * @returns {} - Promise with the response.
     */
    public static async updateTeamMember(tenantId: string, teamId: string, member: IMemberEntity): Promise<IMemberEntity> {
        const urlSignature = "/api/tenants/tenantId/teams/teamId/members/mberId";
        const url = "/api/tenants/" + tenantId + "/teams/" + teamId + "/members/" + member.id;

        const response: any = await NetworkService.sendHttpRequestToStaffHubService({
            method: "PUT",
            url: url,
            urlSignature: urlSignature,
            data: MemberEntity.toJson(member)
        });

        return MemberEntity.fromJson(response.data.member);
    }

    /**
     * Bulk update members
     * @param {String} tenantId
     * @param {String} teamId
     * @param {IMemberEntity[]} members
     * @returns {} - Promise of network service that posted MemberUpdate request
     */
    public static updateTeamMembers(tenantId: string, teamId: string, members: IMemberEntity[]): Promise<IMemberEntity[]> {
        if (!tenantId || !teamId || !members || !members.length) {
            trace.warn(`Bulk update members called with invalid input tenantId: ${tenantId}, teamId: ${teamId}, members: ${members}`);
            return;
        }

        const urlSignature = "/api/tenants/tenantId/teams/teamId/members/bulk";
        const url = `/api/tenants/${tenantId}/teams/${teamId}/members/bulk`;

        const memberIds = members.map(member => member.id);
        const membersToUpdateJson = members.map(member => MemberEntity.toJson(member));

        return NetworkQueue.addBulkRequest(
            memberIds,
            async (updatedETag?: string) => {
                const response: AxiosResponse = await NetworkService.sendHttpRequestToStaffHubService({
                    method: "PUT",
                    url: url,
                    urlSignature: urlSignature,
                    data: {
                        membersToAdd: [],
                        membersToUpdate: membersToUpdateJson,
                        membersToDelete: []
                    }
                });

                if (!response || !response.data || !response.data.membersUpdated) {
                    let error = createNewHttpError();
                    error.message = "Bulk update member response has incomplete members data";
                    trace.warn(error.message);
                    throw error;
                }

                const memberClientMembers: IMemberEntity[] = response.data.membersUpdated.map((memberDataJson: any): IMemberEntity => {
                    // Convert to client model object
                    return MemberEntity.fromJson(memberDataJson);
                });

                return memberClientMembers;
            },
            RestClient._getUpdatedBulkEntityETagFromMembers
        );
    }

    /**
     * Deletes an existing team member
     * @param {String} tenantId
     * @param {String} teamId
     * @param {IMemberEntity} member
     * @returns {} - Promise with the response.
     */
    public static async deleteTeamMember(tenantId: string, teamId: string, member: IMemberEntity): Promise<IMemberEntity> {
        const urlSignature = "/api/tenants/tenantId/teams/teamId/members/mberId";
        const url = "/api/tenants/" + tenantId + "/teams/" + teamId + "/members/" + member.id;

        const response: AxiosResponse = await NetworkService.sendHttpRequestToStaffHubService({
            method: "DELETE",
            url: url,
            urlSignature: urlSignature,
            data: {
                eTag: member.eTag
            }
        });
        return RestClient.parseMemberInfoFromResponse(response);
    }

    /**
     * Helper function that parses memberInfo from network response
     * @param response - axios response
     */
    private static parseMemberInfoFromResponse(response: AxiosResponse): IMemberEntity {
        if (response && response.data && response.data.member) {
            return MemberEntity.fromJson(response.data.member);
        } else {
            let error = createNewHttpError();
            error.message = "error retrieving Member Info";
            throw error;
        }
    }

    /**
     * Update Time Off Reasons
     *
     * @param {String} tenantId
     * @param {String} teamId
     * @param {Array} timeOffReasonsToAdd
     * @param {Array} timeOffReasonsToUpdate
     * @param {Array} timeOffReasonsToDelete
     * @returns {Promise} Promise that resolves to list of time off reasons updated for the given team
     */
    public static updateTimeOffReasons(
        tenantId: string,
        teamId: string,
        timeOffReasonsToAdd: Array<ITimeOffReasonEntity>,
        timeOffReasonsToUpdate: Array<ITimeOffReasonEntity>,
        timeOffReasonsToDelete: Array<ITimeOffReasonEntity>
    ): Promise<TimeOffReasonsUpdateResponse> {
        let urlSignature = "/api/tenants/tenantId/teams/teamId/timeoffreasons/update";
        let url = "/api/tenants/" + tenantId + "/teams/" + teamId + "/timeoffreasons/update";

        return NetworkQueue.addRequestWithPreviousResponse(
            "updateTimeOffReasons",
            async (previousResponse?: TimeOffReasonsUpdateResponse) => {
                // Create a map of all items saved in the previous chained requests
                // (the UI layer may request to create/update the same item multiple times if the network is slow and the user is making multiple changes)
                let idToPreviouslySavedItems: { [timeOffReasonId: string]: ITimeOffReasonEntity } = {};
                if (previousResponse) {
                    if (previousResponse.idToTimeOffReasonsSavedInPreviousRequests) {
                        idToPreviouslySavedItems = previousResponse.idToTimeOffReasonsSavedInPreviousRequests;
                    }
                    previousResponse.timeOffReasonsAdded.forEach(tor => {
                        idToPreviouslySavedItems[tor.id] = tor;
                    });
                    previousResponse.timeOffReasonsUpdated.forEach(tor => {
                        idToPreviouslySavedItems[tor.id] = tor;
                    });
                    previousResponse.timeOffReasonsDeleted.forEach(tor => {
                        idToPreviouslySavedItems[tor.id] = tor;
                    });
                }

                let postData = {
                    timeOffReasonsToAdd: [] as ITimeOffReasonEntity[],
                    timeOffReasonsToUpdate: [] as ITimeOffReasonEntity[],
                    timeOffReasonsToDelete: [] as ITimeOffReasonEntity[]
                };

                timeOffReasonsToAdd.forEach((tor: ITimeOffReasonEntity) => {
                    if (idToPreviouslySavedItems[tor.id]) {
                        // item already added
                        const savedTor = idToPreviouslySavedItems[tor.id];
                        if (TimeOffReasonEntity.containsVisibleChanges(tor, savedTor)) {
                            tor.eTag = savedTor.eTag;
                            postData.timeOffReasonsToUpdate.push(TimeOffReasonEntity.toJson(tor));
                        }
                    } else {
                        postData.timeOffReasonsToAdd.push(TimeOffReasonEntity.toJson(tor));
                    }
                });

                timeOffReasonsToUpdate.forEach((tor: ITimeOffReasonEntity) => {
                    if (idToPreviouslySavedItems[tor.id]) {
                        // item updated in previous call
                        const savedTor = idToPreviouslySavedItems[tor.id];
                        if (TimeOffReasonEntity.containsVisibleChanges(tor, savedTor)) {
                            tor.eTag = savedTor.eTag;
                            postData.timeOffReasonsToUpdate.push(TimeOffReasonEntity.toJson(tor));
                        }
                    } else {
                        postData.timeOffReasonsToUpdate.push(TimeOffReasonEntity.toJson(tor));
                    }
                });

                timeOffReasonsToDelete.forEach((tor: ITimeOffReasonEntity) => {
                    if (idToPreviouslySavedItems[tor.id]) {
                        // item updated/deleted in previous call
                        const savedTor = idToPreviouslySavedItems[tor.id];
                        if (TimeOffReasonEntity.containsVisibleChanges(tor, savedTor)) {
                            tor.eTag = savedTor.eTag;
                            postData.timeOffReasonsToDelete.push(TimeOffReasonEntity.toJson(tor));
                        }
                    } else {
                        postData.timeOffReasonsToDelete.push(TimeOffReasonEntity.toJson(tor));
                    }
                });

                // Only make the network call if there are some items that need to be updated
                if (
                    postData.timeOffReasonsToAdd.length ||
                    postData.timeOffReasonsToUpdate.length ||
                    postData.timeOffReasonsToDelete.length
                ) {
                    const axiosReponse = await NetworkService.sendHttpRequestToStaffHubService({
                        method: "POST",
                        url: url,
                        urlSignature: urlSignature,
                        data: postData
                    });

                    // return the network response
                    return TimeOffReasonsUpdateResponse.fromJson((axiosReponse && axiosReponse.data) || {}, idToPreviouslySavedItems);
                } else {
                    // return an empty response because nothing changed
                    TimeOffReasonsUpdateResponse.fromJson({}, idToPreviouslySavedItems);
                }
            }
        );
    }

    /**
     * Adds note to team
     * @param {String} tenantId
     * @param {String} teamId
     * @param {INoteEntity} note
     * @returns {Promise} - Promise with the response
     */
    public static async addNote(tenantId: string, teamId: string, note: INoteEntity): Promise<INoteEntity> {
        const urlSignature = "/api/tenants/tenantId/teams/teamId/notes";
        const url = "/api/tenants/" + tenantId + "/teams/" + teamId + "/notes";
        const response: AxiosResponse = await NetworkQueue.addRequestWithUpdateEtag(note.id, async () => {
            return NetworkService.sendHttpRequestToStaffHubService({
                method: "POST",
                url: url,
                urlSignature: urlSignature,
                data: NoteEntity.toJson(note)
            });
        });
        return RestClient.parseNoteResponse(response);
    }

    /**
     * Updates note for team
     * @param {String} tenantId
     * @param {String} teamId
     * @param {INoteEntity} note
     * @returns {Promise} - promise with the response
     */
    public static updateNote(tenantId: string, teamId: string, note: INoteEntity): Promise<INoteEntity> {
        const url = "/api/tenants/" + tenantId + "/teams/" + teamId + "/notes/" + note.id;
        const urlSignature = "/api/tenants/tenantId/teams/teamId/notes/noteId";

        return NetworkQueue.addRequestWithUpdateEtag(
            note.id,
            async (updatedETag?: string) => {
                // if there is an updatedETag, update our payload with the new
                // eTag so that we don't get a 409 conflict
                if (updatedETag) {
                    note.eTag = updatedETag;
                }
                const response: AxiosResponse = await NetworkService.sendHttpRequestToStaffHubService({
                    method: "PUT",
                    url: url,
                    urlSignature: urlSignature,
                    data: NoteEntity.toJson(note)
                });
                return RestClient.parseNoteResponse(response);
            },
            RestClient._getUpdatedNoteETagFromNoteResponse
        );
    }

    /**
     * Deletes note
     * @param {String} tenantId
     * @param {String} teamId
     * @param {INoteEntity} note
     * @returns {Promise} - promise with the response
     */
    public static deleteNote(tenantId: string, teamId: string, note: INoteEntity): Promise<INoteEntity> {
        const urlSignature = "/api/tenants/tenantId/teams/teamId/notes/noteId";
        const url = "/api/tenants/" + tenantId + "/teams/" + teamId + "/notes/" + note.id;

        return NetworkQueue.addRequestWithUpdateEtag("deleteNote", async () => {
            const response: AxiosResponse = await NetworkService.sendHttpRequestToStaffHubService({
                method: "DELETE",
                url: url,
                urlSignature: urlSignature,
                data: {
                    eTag: note.eTag
                }
            });
            return RestClient.parseNoteResponse(response);
        });
    }

    /**
     * Gets notes for team in the provided date range
     * @param {String} tenantId
     * @param {String} teamId
     * @param {Moment} startTime - The note start time (to only get notes that start on or after this time)
     * @param {Moment} endTime - The note end time (to only get notes that end on or before this time)
     * @returns {Promise} - promise with the response
     */
    public static getNotes(tenantId: string, teamId: string, startTime?: Moment, endTime?: Moment): Promise<Array<INoteEntity>> {
        const urlSignature = "/api/tenants/tenantId/teams/teamId/notes";
        let url = "/api/tenants/" + tenantId + "/teams/" + teamId + "/notes/";
        let params = [];
        let paramsString;

        if (startTime) {
            params.push(`startTime=${encodeURIComponent(startTime.toISOString())}`);
        }

        if (endTime) {
            params.push(`endTime=${encodeURIComponent(endTime.toISOString())}`);
        }

        paramsString = params.join("&");

        if (paramsString) {
            url += "?" + paramsString;
        }

        return NetworkQueue.addRequestWithUpdateEtag("getNotes", async () => {
            const response: AxiosResponse = await NetworkService.sendHttpRequestToStaffHubService({
                method: "GET",
                url: url,
                urlSignature: urlSignature
            });
            return RestClient.parseNotesFromResponse(response);
        });
    }

    /**
     * Function that queries the service for GAL users
     * @param tenantId - tenant id for which the query needs to be run
     * @param firstNameStartsWith - firstNameStartsWith filter
     * @param lastNameStartsWith - lastNameStartsWith filter, defaults to empty string
     * @param primaryEmailStartsWith - primaryEmailStartsWith filter, defaults to empty string
     * @param operatorType - type of operation, defaults to OR
     * @returns {} - Promise with the response.
     */
    public static async getUsersFromGAL(
        tenantId: string,
        firstNameStartsWith: string,
        lastNameStartsWith: string,
        primaryEmailStartsWith: string,
        operatorType: string
    ): Promise<IUserEntity[]> {
        const urlSignature = "/api/tenants/tenantId/users/searchGal";
        let url = `/api/tenants/${tenantId}/users/searchGal`;

        if (firstNameStartsWith || lastNameStartsWith || primaryEmailStartsWith) {
            let paramsArray = [];

            if (firstNameStartsWith) {
                paramsArray.push(`firstNameStartsWith=${encodeURIComponent(firstNameStartsWith)}`);
            }
            if (lastNameStartsWith) {
                paramsArray.push(`lastNameStartsWith=${encodeURIComponent(lastNameStartsWith)}`);
            }
            if (primaryEmailStartsWith) {
                paramsArray.push(`primaryEmailStartsWith=${encodeURIComponent(primaryEmailStartsWith)}`);
            }

            paramsArray.push(`operatorType=${encodeURIComponent(operatorType)}`);
            let paramsString = paramsArray.join("&");

            if (paramsString) {
                paramsString = "?" + paramsString;
            }

            url += paramsString;
        }

        const response: AxiosResponse = await NetworkService.sendHttpRequestToStaffHubService({
            method: "GET",
            url: url,
            urlSignature: urlSignature
        });
        if (!response || !response.data || !response.data.usersInfo) {
            throw new Error("Missing user info");
        }

        const usersInfoResponse: IServiceUserEntity[] = response.data.usersInfo || [];
        // map service usersInfo as UserEntities
        return usersInfoResponse.map((userInfoResponse: IServiceUserEntity) => {
            return UserEntity.fromJson(userInfoResponse);
        });
    }

    /**
     * Get Tags
     * @param {String} tenantId
     * @param {String} teamId
     * @returns {} Promise that resolves to list of tags assigned to given team
     */
    public static async getTags(tenantId: string, teamId: string): Promise<ITagEntity[]> {
        const urlSignature = "/api/tenants/tenantId/teams/teamId/tags";
        const url = `/api/tenants/${tenantId}/teams/${teamId}/tags`;

        return RestClient.parseTagsFromResponse(
            await NetworkService.sendHttpRequestToStaffHubService({
                method: "GET",
                url: url,
                urlSignature: urlSignature
            })
        );
    }

    /**
     * Add Tag to team by posting TagCreate request to service
     * @param {String} tenantId
     * @param {String} teamId
     * @param {ITagEntity} tag object
     * @returns - Promise of network service that posted TagCreate request
     */
    public static async addTag(tenantId: string, teamId: string, tag: ITagEntity): Promise<ITagEntity> {
        const urlSignature = "/api/tenants/tenantId/teams/teamId/tags";
        const url = "/api/tenants/" + tenantId + "/teams/" + teamId + "/tags";

        const response: AxiosResponse = await NetworkService.sendHttpRequestToStaffHubService({
            method: "POST",
            url: url,
            urlSignature: urlSignature,
            data: tag
        });

        return RestClient.parseTagFromResponse(response);
    }

    /**
     * Retrieves the eTag from the service response for a Tag
     * @param tagResponse
     */
    private static _getUpdatedTagETag(tagResponse: AxiosResponse): string {
        return tagResponse && tagResponse.data && tagResponse.data.tag ? tagResponse.data.tag.eTag : null;
    }

    /**
     * Update Tag to team by posting UpdateTag request to service
     * @param {String} tenantId
     * @param {String} teamId
     * @param {ITagEntity} tag object
     * @returns {} - Promise of network service that posted UpdateTag request
     */
    public static async updateTag(tenantId: string, teamId: string, tag: ITagEntity): Promise<ITagEntity> {
        const urlSignature = "/api/tenants/tenantId/teams/teamId/tags/tagId";
        const url = "/api/tenants/" + tenantId + "/teams/" + teamId + "/tags/" + tag.id;

        const response: AxiosResponse = await NetworkQueue.addRequestWithUpdateEtag(
            tag.id,
            (updatedETag?: string) => {
                tag.eTag = updatedETag || tag.eTag;
                return NetworkService.sendHttpRequestToStaffHubService({
                    method: "PUT",
                    url: url,
                    urlSignature: urlSignature,
                    data: tag
                });
            },
            RestClient._getUpdatedTagETag
        );

        return RestClient.parseTagFromResponse(response);
    }

    /**
     * Delete Tag to team by posting Delete Tag request to service
     * @param {String} tenantId
     * @param {String} teamId
     * @param {ITagEntity} tag object
     * @returns {} - Promise of network service that posted delete request
     */
    public static async deleteTag(tenantId: string, teamId: string, tag: ITagEntity): Promise<ITagEntity> {
        const urlSignature = "/api/tenants/tenantId/teams/teamId/tags/tagId";
        const url = "/api/tenants/" + tenantId + "/teams/" + teamId + "/tags/" + tag.id;

        const response: AxiosResponse = await NetworkService.sendHttpRequestToStaffHubService({
            method: "DELETE",
            url: url,
            urlSignature: urlSignature,
            data: {
                eTag: tag.eTag
            }
        });

        return RestClient.parseTagFromResponse(response);
    }

    /**
     * Get UniqueShifts (optionally fetch limited number of unique shifts (say top 5 unique shifts)
     * @param {String} tenantId
     * @param {String} teamId
     * @param {number} shiftCount - Number of unique shifts to fetch
     * @returns {} Promise that resolves to list of unique shifts assigned to given team
     */
    public static async getUniqueShifts(tenantId: string, teamId: string, shiftCount?: number): Promise<IUniqueShiftEntity[]> {
        let urlSignature = "/api/tenants/tenantId/teams/teamId/shifts/unique";
        let url = "/api/tenants/" + tenantId + "/teams/" + teamId + "/shifts/unique";

        if (shiftCount) {
            url += "?request.count=" + shiftCount;
        }

        const response: AxiosResponse = await NetworkService.sendHttpRequestToStaffHubService({
            method: "GET",
            url: url,
            urlSignature: urlSignature
        });
        return RestClient.parseUniqueShiftsFromResponse(response);
    }

    public static deleteUniqueShift(tenantId: string, teamId: string, uniqueShift: IUniqueShiftEntity): Promise<AxiosResponse> {
        let urlSignature = "/api/tenants/tenantId/teams/teamId/shifts/unique";
        let url = "/api/tenants/" + tenantId + "/teams/" + teamId + "/shifts/unique";

        return NetworkQueue.addRequestWithUpdateEtag("deleteUniqueShift", () => {
            return NetworkService.sendHttpRequestToStaffHubService({
                method: "DELETE",
                url: url,
                urlSignature: urlSignature,
                data: UniqueShiftEntity.toJson(uniqueShift)
            });
        });
    }

    /**
     * Get UniqueSubshifts (optionally fetch limited number of unique Subshifts (say top 5 unique Subshifts)
     * @param {String} tenantId
     * @param {String} teamId
     * @param {number} activityCount - Number of unique Subshifts to fetch
     * @returns {} Promise that resolves to list of unique Subshifts assigned to given team
     */
    public static async getUniqueSubshifts(tenantId: string, teamId: string, activityCount?: number): Promise<IUniqueSubshiftEntity[]> {
        let urlSignature = "/api/tenants/tenantId/teams/teamId/shifts/uniqueSubshifts";
        let url = "/api/tenants/" + tenantId + "/teams/" + teamId + "/shifts/uniqueSubshifts";

        if (activityCount) {
            url += "?request.count=" + activityCount;
        }

        const response: AxiosResponse = await NetworkService.sendHttpRequestToStaffHubService({
            method: "GET",
            url: url,
            urlSignature: urlSignature
        });
        return RestClient.parseUniqueSubshiftsFromResponse(response);
    }

    /**
     * Get User Settings
     * @returns {Promise} Promise that resolves to the user settings
     */
    public static async getUserSettings(): Promise<UserSettingsEntity> {
        const urlSignature = "/api/account/settings";
        const url = "/api/account/settings ";

        const response: AxiosResponse = await NetworkService.sendHttpRequestToStaffHubService({
            method: "GET",
            url: url,
            urlSignature: urlSignature
        });
        const userSettingsDataJson: IUserSettingsEntity = response && response.data && response.data.userSettings;
        return UserSettingsEntity.fromJson(userSettingsDataJson);
    }

    /**
     * Update User Settings
     * @param {IUserSettingsEntity} userSettings
     * @returns {Promise} Promise that resolves to the updated user settings
     */
    public static async updateUserSettings(userSettings: IUserSettingsEntity): Promise<UserSettingsEntity> {
        const urlSignature = "/api/account/settings";
        const url = "/api/account/settings ";

        const response: AxiosResponse = await NetworkService.sendHttpRequestToStaffHubService({
            method: "PUT",
            url: url,
            urlSignature: urlSignature,
            data: UserSettingsEntity.toJson(userSettings)
        });
        const userSettingsDataJson: IUserSettingsEntity = response && response.data && response.data.userSettings;
        return UserSettingsEntity.fromJson(userSettingsDataJson);
    }

    /**
     * Get the authenticated URL to download the Time Report file using the V2 API.
     * @param teamId - Team Id
     * @param startTime - Start time of report
     * @param endTime - end time of report
     */
    public static async getDownloadTimeReportFileUrlV2(
        teamId: string,
        startTime: Moment,
        endTime: Moment,
        reportType: reportExcelType = reportExcelType.timeReport
    ): Promise<string> {
        if (!teamId || !startTime || !endTime) {
            const message = `download time report is missing parameters - teamId:${teamId} startTime:${startTime} endTime:${endTime}`;
            trace.warn(message);
            return Promise.reject(message);
        }
        const urlFormatString = "/api/v2/teams/{0}/export/sync?reportType={3}&startDate={1}&endDate={2}";
        const urlSignature = `/api/v2/teams/teamId/export/sync?reportType=${reportType}`;
        const serviceEndPointUrl = urlFormatString.format(
            teamId,
            encodeURIComponent(startTime.toISOString()),
            encodeURIComponent(endTime.toISOString()),
            reportType
        );
        const response: AxiosResponse = await NetworkService.sendHttpRequestToStaffHubService({
            method: "GET",
            url: serviceEndPointUrl,
            urlSignature: urlSignature
        });

        if (response && response.data) {
            return response.data.url;
        }

        return "";
    }

    /**
     * Get the authenticated URL to download the Time Report file
     * @param teamId - Team Id
     * @param startTime - Start time of report
     * @param endTime - end time of report
     */
    public static getDownloadTimeReportFileUrl(teamId: string, startTime: Moment, endTime: Moment): string {
        const uiCulture: string = window.sa.currentUICulture ? window.sa.currentUICulture : "en-US";
        const urlFormatString =
            "/api/teams/{0}/export/sync?reportType=TimeReport&startDate={1}&endDate={2}&authorization={3}&Accept-Language={4}";
        return (
            NetworkService.GetServiceAPIEndPointUrl() +
            urlFormatString.format(
                teamId,
                encodeURIComponent(startTime.toISOString()),
                encodeURIComponent(endTime.toISOString()),
                encodeURIComponent(AuthService.getCachedShiftsBearerToken()),
                encodeURIComponent(uiCulture)
            )
        );
    }

    /**
     * Get Shift Requests for the team
     * @param {String} tenantId
     * @param {String} teamId
     * @param {string} cursor - cursor to fetch the next page
     * @returns {Promise} Promise that resolves to list of shift requests in the team
     */
    public static async getShiftRequests(tenantId: string, teamId: string, cursor?: string): Promise<IGetShiftRequestsResponseEntity> {
        let urlSignature = "/api/tenants/tenantId/teams/teamId/shiftrequests";
        let url = "/api/tenants/" + tenantId + "/teams/" + teamId + "/shiftrequests";

        url += "?count=" + SHIFT_REQUESTS_PAGE_SIZE;

        if (cursor) {
            url += "&cursor=" + encodeURIComponent(cursor);
        }

        const response: AxiosResponse = await NetworkService.sendHttpRequestToStaffHubService({
            method: "GET",
            url: url,
            urlSignature: urlSignature
        });
        return RestClient.parseShiftRequestMultipleFromResponse(response);
    }

    /**
     * Get a shift request by ID
     * @param tenantId - Tenant Id
     * @param teamId - Team Id
     * @param requestId - Shift request Id
     */
    public static async getShiftRequest(tenantId: string, teamId: string, requestId: string): Promise<IShiftRequestEntity> {
        let urlSignature = "/api/tenants/tenantId/teams/teamId/shiftrequests/shiftRequestId";
        let url = "/api/tenants/" + tenantId + "/teams/" + teamId + "/shiftrequests/" + requestId;

        const response: AxiosResponse = await NetworkService.sendHttpRequestToStaffHubService({
            method: "GET",
            url: url,
            urlSignature: urlSignature
        });
        return RestClient.parseShiftRequestFromResponse(response);
    }

    /**
     * Parse an IGetShiftRequestsResponse from the server response to getShiftRequests
     * @param response
     */
    private static parseShiftRequestMultipleFromResponse(response: AxiosResponse): IGetShiftRequestsResponseEntity {
        let getShiftRequestsResponse: IGetShiftRequestsResponseEntity = null;
        if (response.data) {
            const shiftRequests: IShiftRequestEntity[] = RestClient.parseShiftRequestsFromResponse(response);
            const unreadShiftRequests: number = RestClient.parseUnreadShiftRequestsFromResponse(response);
            const nextCursor: string = RestClient.parseNextCursorFromResponse(response);
            getShiftRequestsResponse = {
                shiftRequests: shiftRequests,
                unreadShiftRequests: unreadShiftRequests,
                nextCursor: nextCursor
            };
        }
        return getShiftRequestsResponse;
    }

    /**
     * Parse the nextCursor field from a server response
     * @param response
     */
    private static parseNextCursorFromResponse(response: AxiosResponse): string {
        return response && response.data && response.data.nextCursor ? response.data.nextCursor : null;
    }

    /**
     * Parse the skipToken field from a server response
     * @param response
     */
    private static parseSkipTokenFromResponse(response: AxiosResponse): string {
        return response && response.data && response.data.skipToken ? response.data.skipToken : null;
    }

    /**
     * Parse the nextLink field from a server response
     * @param response
     */
    private static parseNextLinkFromResponse(response: AxiosResponse): string {
        return response && response.data && response.data.nextLink ? response.data.nextLink : null;
    }
    /**
     * Parse the shiftRequests from a response
     * @param response
     */
    private static parseShiftRequestsFromResponse(response: AxiosResponse): IShiftRequestEntity[] {
        let shiftRequests: IShiftRequestEntity[] = [];
        if (response && response.data && response.data.shiftRequests) {
            shiftRequests = response.data.shiftRequests.map((shiftRequestJsonData: any) => {
                return ShiftRequestEntity.fromJson(shiftRequestJsonData);
            });
        }
        return shiftRequests;
    }

    /**
     * Parse the unreadShiftRequests field from a server response
     * @param response
     */
    private static parseUnreadShiftRequestsFromResponse(response: AxiosResponse): number {
        return response && response.data && response.data.unreadShiftRequests ? response.data.unreadShiftRequests : 0;
    }

    /**
     * Retrieves the eTag from the service response for a shift request
     * @param shiftRequestResponse
     */
    private static _getUpdatedShiftRequestETag(shiftRequestResponse: AxiosResponse): string {
        return shiftRequestResponse && shiftRequestResponse.data && shiftRequestResponse.data.shiftRequest
            ? shiftRequestResponse.data.shiftRequest.eTag
            : null;
    }

    /**
     * Approve or Decline TimeOff Request
     * @param {String} tenantId
     * @param {String} teamId
     * @param {String} shiftRequestId
     * @param {String} shiftRequestETag
     * @param {String} responseMessage
     * @param {boolean} isAccepting
     * @returns {Promise} Promise that resolves to the approve decline time off manager request
     */
    public static async approveDeclineTimeOffRequest(
        tenantId: string,
        teamId: string,
        shiftRequestId: string,
        shiftRequestETag: string,
        responseMessage: string,
        isAccepting: boolean
    ): Promise<IApproveDeclineTimeOffRequestResponseEntity> {
        let urlSignature = "/api/tenants/tenantId/teams/teamId/shiftrequests/sreqId/TimeOffManagerRequestComplete";
        let url = "/api/tenants/" + tenantId + "/teams/" + teamId + "/shiftrequests/" + shiftRequestId + "/TimeOffManagerRequestComplete";

        const response: AxiosResponse = await NetworkQueue.addRequestWithUpdateEtag(
            shiftRequestId,
            (updatedETag?: string) => {
                return NetworkService.sendHttpRequestToStaffHubService({
                    method: "POST",
                    url: url,
                    urlSignature: urlSignature,
                    data: {
                        eTag: updatedETag || shiftRequestETag,
                        isAccepting: isAccepting,
                        message: responseMessage || null
                    }
                });
            },
            RestClient._getUpdatedShiftRequestETag
        );
        return RestClient.parseShiftRequestAndAlertsFromResponse(response);
    }

    /**
     * Mark the shift request as read by the current user
     * @param tenantId
     * @param teamId
     * @param shiftRequestId
     * @param shiftRequestLastModifiedTime
     * @returns {Promise} Promise of network request
     */
    public static async setShiftRequestRead(
        tenantId: string,
        teamId: string,
        shiftRequestId: string,
        shiftRequestLastModifiedTime: Moment
    ): Promise<IShiftRequestAndUnreadCountResponseEntity> {
        let urlSignature = "/api/tenants/tenantId/teams/teamId/shiftrequests/sreqId/SetShiftRequestRead";
        let url = "/api/tenants/" + tenantId + "/teams/" + teamId + "/shiftrequests/" + shiftRequestId + "/SetShiftRequestRead";

        const response: AxiosResponse = await NetworkQueue.addRequestWithUpdateEtag(
            shiftRequestId,
            (updatedETag?: string) => {
                return NetworkService.sendHttpRequestToStaffHubService({
                    method: "POST",
                    url: url,
                    urlSignature: urlSignature,
                    data: {
                        userLastReadTime: shiftRequestLastModifiedTime.toISOString()
                    }
                });
            },
            RestClient._getUpdatedShiftRequestETag
        );
        return RestClient.parseShiftRequestAndUnreadCountFromResponse(response);
    }

    /**
     * Approve a swap or handoff request
     * @param {String} tenantId
     * @param {String} teamId
     * @param {String} shiftRequestId
     * @param {String} shiftRequestETag
     * @param {String} responseMessage
     * @param {boolean} isReceiver
     * @returns {Promise} Promise that resolves to the approve decline time off manager request
     */
    public static async declineSwapHandOffRequest(
        tenantId: string,
        teamId: string,
        shiftRequestId: string,
        shiftRequestETag: string,
        responseMessage: string,
        isReceiver: boolean
    ): Promise<IShiftRequestAndUnreadCountResponseEntity> {
        let urlSignature: string;
        let url: string;

        if (isReceiver) {
            urlSignature = "/api/tenants/tenantId/teams/teamId/shiftrequests/sreqId/SwapHandoffReceiverDecline";
            url = "/api/tenants/" + tenantId + "/teams/" + teamId + "/shiftrequests/" + shiftRequestId + "/SwapHandoffReceiverDecline";
        } else {
            urlSignature = "/api/tenants/tenantId/teams/teamId/shiftrequests/sreqId/SwapHandoffManagerDecline";
            url = "/api/tenants/" + tenantId + "/teams/" + teamId + "/shiftrequests/" + shiftRequestId + "/SwapHandoffManagerDecline";
        }

        const response: AxiosResponse = await NetworkQueue.addRequestWithUpdateEtag(
            shiftRequestId,
            (updatedETag?: string) => {
                return NetworkService.sendHttpRequestToStaffHubService({
                    method: "POST",
                    url: url,
                    urlSignature: urlSignature,
                    data: {
                        message: responseMessage,
                        eTag: updatedETag || shiftRequestETag
                    }
                });
            },
            RestClient._getUpdatedShiftRequestETag
        );
        return RestClient.parseShiftRequestAndUnreadCountFromResponse(response);
    }

    private static parseShiftRequestAndUnreadCountFromResponse(response: AxiosResponse): IShiftRequestAndUnreadCountResponseEntity {
        let shiftRequestResponse: IShiftRequestAndUnreadCountResponseEntity = null;
        if (response && response.data) {
            let shiftRequest: IShiftRequestEntity = response.data.shiftRequest
                ? ShiftRequestEntity.fromJson(response.data.shiftRequest)
                : null;
            let unreadShiftRequests: number = RestClient.parseUnreadShiftRequestsFromResponse(response);
            shiftRequestResponse = {
                shiftRequest: shiftRequest,
                unreadShiftRequests: unreadShiftRequests
            };
        }
        return shiftRequestResponse;
    }

    /**
     * Approve a swap or handoff request
     * @param {String} tenantId
     * @param {String} teamId
     * @param {String} shiftRequestId
     * @param {String} shiftRequestETag
     * @param {String} responseMessage
     * @param {boolean} isReceiver
     * @returns {Promise} Promise that resolves to the approve decline time off manager request
     */
    public static async approveSwapHandOffRequest(
        tenantId: string,
        teamId: string,
        shiftRequestId: string,
        shiftRequestETag: string,
        responseMessage: string,
        isReceiver: boolean
    ): Promise<IApproveSwapHandOffRequestResponseEntity> {
        let urlSignature: string;
        let url: string;

        if (isReceiver) {
            urlSignature = "/api/tenants/tenantId/teams/teamId/shiftrequests/sreqId/SwapHandoffReceiverAccept";
            url = "/api/tenants/" + tenantId + "/teams/" + teamId + "/shiftrequests/" + shiftRequestId + "/SwapHandoffReceiverAccept";
        } else {
            urlSignature = "/api/tenants/tenantId/teams/teamId/shiftrequests/sreqId/SwapHandoffManagerAccept";
            url = "/api/tenants/" + tenantId + "/teams/" + teamId + "/shiftrequests/" + shiftRequestId + "/SwapHandoffManagerAccept";
        }

        const response: AxiosResponse = await NetworkQueue.addRequestWithUpdateEtag(
            shiftRequestId,
            (updatedETag?: string) => {
                return NetworkService.sendHttpRequestToStaffHubService({
                    method: "POST",
                    url: url,
                    urlSignature: urlSignature,
                    data: {
                        message: responseMessage,
                        eTag: updatedETag || shiftRequestETag
                    }
                });
            },
            RestClient._getUpdatedShiftRequestETag
        );
        return RestClient.parseApproveSwapHandOffFromResponse(response);
    }

    private static parseApproveSwapHandOffFromResponse(response: AxiosResponse): IApproveSwapHandOffRequestResponseEntity {
        let approveDeclineSwapHandOff: IApproveSwapHandOffRequestResponseEntity = null;
        if (response && response.data) {
            let deletedShifts: IBaseShiftEntity[] = [];
            let replacementShifts: IBaseShiftEntity[] = [];
            let updatedShiftRequests: IShiftRequestEntity[] = [];
            let unreadShiftRequests: number = RestClient.parseUnreadShiftRequestsFromResponse(response);
            let alerts: string[] = RestClient.parseAlertsFromResponse(response);
            if (response.data.deletedShifts) {
                deletedShifts = (response.data.deletedShifts as Array<IShiftServiceEntity>).map(shift => ShiftEntity.fromJson(shift));
            }
            if (response.data.replacementShifts) {
                replacementShifts = (response.data.replacementShifts as Array<IShiftServiceEntity>).map(shift =>
                    ShiftEntity.fromJson(shift)
                );
            }
            if (response.data.updatedShiftRequests) {
                updatedShiftRequests = (response.data.updatedShiftRequests as Array<IShiftRequestEntity>).map(request =>
                    ShiftRequestEntity.fromJson(request)
                );
            }
            approveDeclineSwapHandOff = {
                deletedShifts: deletedShifts,
                replacementShifts: replacementShifts,
                updatedShiftRequests: updatedShiftRequests,
                unreadShiftRequests: unreadShiftRequests,
                alerts: alerts
            };
        }
        return approveDeclineSwapHandOff;
    }

    /**
     * Creates a timeoff request
     *
     * @param {String} tenantId
     * @param {String} teamId
     * @param {string} id - time off request id
     * @param {sring} message - message for the request
     * @param {Moment} startTime - start time of the request
     * @param {Moment} endTime - end time of the request
     * @param {string} timeOffReasonId
     * @returns {Promise} Promise with the response.
     */
    public static async createTimeOffRequest(
        tenantId: string,
        teamId: string,
        id: string,
        message: string,
        startTime: Moment,
        endTime: Moment,
        timeOffReasonId: string
    ): Promise<ICreateTimeOffResponseEntity> {
        const urlSignature = "/api/tenants/tenantId/teams/teamId/shiftrequests/CreateTimeOffRequest";
        const url = "/api/tenants/" + tenantId + "/teams/" + teamId + "/shiftrequests/CreateTimeOffRequest";

        const response: AxiosResponse = await NetworkService.sendHttpRequestToStaffHubService({
            method: "POST",
            url: url,
            urlSignature: urlSignature,
            data: {
                id: id,
                message: message,
                requestType: ShiftRequestTypes.TimeOff, // TODO: verify "TimeOff"
                startTime: startTime.toISOString(),
                endTime: endTime.toISOString(),
                timeOffReasonId: timeOffReasonId
            }
        });
        return RestClient.parseCreateTimeOffFromResponse(response);
    }

    /**
     * Parse a create time off response entity from an AxiosResponse
     * @param response
     */
    public static parseCreateTimeOffFromResponse(response: AxiosResponse): ICreateTimeOffResponseEntity {
        let createTimeOffResponse: ICreateTimeOffResponseEntity = null;
        if (response && response.data) {
            let shiftRequest: IShiftRequestEntity = null;
            let shift: IBaseShiftEntity = null;
            if (response.data.shift) {
                shift = ShiftEntity.fromJson(response.data.shift);
            }
            if (response.data.shiftRequest) {
                shiftRequest = ShiftRequestEntity.fromJson(response.data.shiftRequest);
            }
            createTimeOffResponse = {
                shiftRequest: shiftRequest,
                shift: shift
            };
        }
        return createTimeOffResponse;
    }

    /**
     * Moves the shift request into Cancelled state
     * @param {String} tenantId
     * @param {String} teamId
     * @param {String} shiftRequestId
     * @returns {} - Promise with the response.
     */
    public static async deleteRequest(tenantId: string, teamId: string, shiftRequestId: string): Promise<IShiftRequestEntity> {
        const urlSignature = "/api/tenants/tenantId/teams/teamId/shiftrequests/shiftRequestId/DeleteRequest";
        const url = "/api/tenants/" + tenantId + "/teams/" + teamId + "/shiftrequests/" + shiftRequestId + "/DeleteRequest";

        const response = await NetworkService.sendHttpRequestToStaffHubService({
            method: "DELETE",
            url: url,
            urlSignature: urlSignature
        });
        return RestClient.parseShiftRequestFromDeleteRequestResponse(response);
    }

    /**
     * Parse the deleted shift request from a delete request response
     * @param response
     */
    public static parseShiftRequestFromDeleteRequestResponse(response: AxiosResponse): IShiftRequestEntity {
        let deletedShiftRequest: IShiftRequestEntity = null;
        if (response && response.data && response.data.deletedShiftRequest) {
            deletedShiftRequest = ShiftRequestEntity.fromJson(response.data.deletedShiftRequest);
        }
        return deletedShiftRequest;
    }

    /**
     * Creates a swap or handoff request
     *
     * @param {String} tenantId
     * @param {String} teamId
     * @param {string} id - shift request id
     * @param {sring} shiftId - Id of the shift being offered or swapped
     * @param {sring} otherShiftId - Id of the other shift for swap
     * @param {string} receiverMemberId - Id of the member to send the request to
     * @param {string} message - Message from the sender to the receiver
     * @param {ShiftRequestType} requestType - The type of request: "Swap" or "Handoff"
     * @returns {Promise} Promise with the response.
     */
    public static async createSwapHandoffShiftRequest(
        tenantId: string,
        teamId: string,
        id: string,
        shiftId: string,
        otherShiftId: string,
        receiverMemberId: string,
        message: string,
        requestType: ShiftRequestType
    ): Promise<IShiftRequestEntity> {
        const urlSignature = "/api/tenants/tenantId/teams/teamId/shiftrequests/CreateSwapHandoffShift";
        const url = "/api/tenants/" + tenantId + "/teams/" + teamId + "/shiftrequests/CreateSwapHandoffShift";

        const response: AxiosResponse = await NetworkService.sendHttpRequestToStaffHubService({
            method: "POST",
            url: url,
            urlSignature: urlSignature,
            data: {
                id: id,
                shiftId: shiftId,
                otherShiftId: otherShiftId,
                receiverMemberId: receiverMemberId,
                message: message,
                requestType: requestType
            }
        });
        return RestClient.parseShiftRequestFromResponse(response);
    }

    /**
     * Parse the shift request from response
     * @param response
     */
    private static parseShiftRequestFromResponse(response: AxiosResponse): IShiftRequestEntity {
        let shiftRequest: IShiftRequestEntity = null;
        if (response && response.data && response.data.shiftRequest) {
            shiftRequest = ShiftRequestEntity.fromJson(response.data.shiftRequest);
        }
        return shiftRequest;
    }

    /**
     * Helper function that parses bulk openshift data from the network response
     * @param response - axios response
     */
    private static parseOpenShiftsFromResponse(response: AxiosResponse): IOpenShiftEntity[] {
        if (!response || !response.data) {
            let error = createNewHttpError();
            error.message = "openshifts response has incomplete openshifts data";
            trace.warn(error.message);
            throw error;
        }

        let openShiftsData: IOpenShiftServiceEntity[] = response.data.openShifts || [];
        return openShiftsData.map(openShiftJson => OpenShiftEntity.fromJson(openShiftJson));
    }

    /**
     * Helper function that parse openShift data from network response
     * @param response - axios respose
     */
    private static parseOpenShiftFromResponse(response: AxiosResponse): IOpenShiftEntity {
        if (!response || !response.data) {
            let error = createNewHttpError();
            error.message = "openshift response has incomplete openshift data";
            trace.warn(error.message);
            throw error;
        }

        if (!response.data.openShift) {
            return null;
        }

        let openShiftData: IOpenShiftServiceEntity = response.data.openShift;
        return OpenShiftEntity.fromJson(openShiftData);
    }

    /**
     * Helper function that parse assigned openShift data
     * @param response - axios repose
     */
    private static parseAssignedOpenShiftResponse(response: AxiosResponse): IAssignOpenShiftResponseEntity {
        if (!response || !response.data || !response.data.openShift || !response.data.assignedShift || !response.data.assignedShift.shift) {
            let error = createNewHttpError();
            error.message = "assign open shift response has incomplete shift or open shift data";
            trace.warn(error.message);
            throw error;
        }

        return {
            assignedShift: ShiftEntity.fromJson(response.data.assignedShift.shift),
            openShift: OpenShiftEntity.fromJson(response.data.openShift)
        };
    }

    /**
     * Get OpenShifts (optionally fetch between time range)
     * @param {string} tenantId
     * @param {string} teamId - Team for which OpenShifts need to be fetched
     * @param {Moment} startTime - optional StartTime from which openShifts need to be fetched
     * @param {Moment} endTime - optional endTime till which openShifts need to be fetched
     */
    public static async getOpenShifts(tenantId: string, teamId: string, startTime?: Moment, endTime?: Moment): Promise<IOpenShiftEntity[]> {
        const urlSignature = "/api/tenants/tenantId/teams/teamId/shifts/open";
        let url = `/api/tenants/${tenantId}/teams/${teamId}/shifts/open`;

        if (startTime || endTime) {
            url += "?";
        }
        if (startTime) {
            url += "&startTime=" + encodeURIComponent(startTime.toISOString());
        }
        if (endTime) {
            url += "&endTime=" + encodeURIComponent(endTime.toISOString());
        }

        const response: AxiosResponse = await NetworkService.sendHttpRequestToStaffHubService({
            method: "GET",
            url: url,
            urlSignature: urlSignature
        });
        return RestClient.parseOpenShiftsFromResponse(response);
    }

    /**
     * Get OpenShift by Id
     * @param tenantId
     * @param teamId - Team Id
     * @param openShiftId - OpenShift Id
     */
    public static async getOpenShiftById(tenantId: string, teamId: string, openShiftId: string): Promise<IOpenShiftEntity> {
        const urlSignature = "/api/tenants/tenantId/teams/teamId/shifts/open/openShiftId";
        const url = `/api/tenants/${tenantId}/teams/${teamId}/shifts/open/${openShiftId}`;

        const response: AxiosResponse = await NetworkService.sendHttpRequestToStaffHubService({
            method: "GET",
            url: url,
            urlSignature: urlSignature
        });
        return RestClient.parseOpenShiftFromResponse(response);
    }

    /**
     * Add Open Shift to given team
     * @param {String} tenantId
     * @param {String} teamId
     * @param {IOpenShiftEntity} openShift
     * @returns {} Promise of network request
     */
    public static addOpenShift(tenantId: string, teamId: string, openShift: IOpenShiftEntity): Promise<IOpenShiftEntity> {
        const urlSignature = "/api/tenants/tenantId/teams/teamId/shifts/open";
        const url = `/api/tenants/${tenantId}/teams/${teamId}/shifts/open`;
        return NetworkQueue.addRequestWithUpdateEtag(
            openShift.id,
            async (updatedETag?: string) => {
                const response: AxiosResponse = await NetworkService.sendHttpRequestToStaffHubService({
                    method: "POST",
                    url: url,
                    urlSignature: urlSignature,
                    data: OpenShiftEntity.toJson(openShift)
                });
                return RestClient.parseOpenShiftFromResponse(response);
            },
            RestClient._getUpdatedShiftETagFromOpenShift
        );
    }

    /**
     * Update existing OpenShift
     * @param tenantId
     * @param teamId - TeamId
     * @param openShift - OpenShiftEntity
     */
    public static updateOpenShift(tenantId: string, teamId: string, openShift: IOpenShiftEntity): Promise<IOpenShiftEntity> {
        const urlSignature = "/api/tenants/tenantId/teams/teamId/shifts/open/openShiftId";
        const url = `/api/tenants/${tenantId}/teams/${teamId}/shifts/open/${openShift.id}`;

        return NetworkQueue.addRequestWithUpdateEtag(
            openShift.id,
            async (updatedETag?: string) => {
                // if there is an updatedETag, update our payload with the new
                // eTag so that we don't get a 409 conflict
                if (updatedETag) {
                    openShift.eTag = updatedETag;
                }
                const response: AxiosResponse = await NetworkService.sendHttpRequestToStaffHubService({
                    method: "PUT",
                    url: url,
                    urlSignature: urlSignature,
                    data: OpenShiftEntity.toJson(openShift)
                });
                return RestClient.parseOpenShiftFromResponse(response);
            },
            RestClient._getUpdatedShiftETagFromOpenShift
        );
    }

    /**
     * Bulk update open shift requests with the status Accept/Decline
     * @param teamId - TeamId
     * @param shiftRequestDetails - The shift requests for which the manager is sending an accept/decline.
     * @param openShiftId - The shift Id for which the manager is accepting the shift requests
     */
    public static bulkAcceptDeclineOpenShifts(
        teamId: string,
        openShiftId: string,
        shiftRequestDetails: IShiftRequestResponseEntity[]
    ): Promise<IBulkOpenShiftRequestsResponseEntity> {
        const urlSignature = "/api/teams/teamId/shifts/open/requests/bulk";
        const url = `/api/teams/${teamId}/shifts/open/requests/bulk`;

        const shiftRequestIds = shiftRequestDetails.map(shiftRequest => shiftRequest.id);
        const shiftRequestDetailsWithManagerAction = shiftRequestDetails.map(shiftRequest => {
            return {
                id: shiftRequest.id,
                eTag: shiftRequest.eTag,
                managerAction: shiftRequest.isAccepting ? "Accept" : "Decline",
                message: shiftRequest.message
            };
        });

        return NetworkQueue.addBulkRequest(
            shiftRequestIds,
            async (updatedETag?: string) => {
                const response: AxiosResponse = await NetworkService.sendHttpRequestToStaffHubService({
                    method: "POST",
                    url: url,
                    urlSignature: urlSignature,
                    data: {
                        shiftRequestDetails: shiftRequestDetailsWithManagerAction,
                        openShiftId: openShiftId
                    }
                });
                return RestClient.parseBulkAcceptDeclineOpenShiftsResponse(response);
            },
            RestClient._getUpdatedBulkEntityETagFromBulkOpenShiftsResponse
        );
    }

    private static parseBulkAcceptDeclineOpenShiftsResponse(response: AxiosResponse): IBulkOpenShiftRequestsResponseEntity {
        if (!response || !response.data || !response.data.createdShifts || !response.data.updatedOpenShiftRequests) {
            // response.data.updatedOpenShift will be null when Declining requests
            let error = createNewHttpError();
            error.message = "bulk accept/decline open shift requests has failed";
            trace.warn(error.message);
            throw error;
        }

        return {
            createdShifts: (response.data.createdShifts as Array<IShiftServiceEntity>).map(shift => ShiftEntity.fromJson(shift)),
            updatedOpenShift: OpenShiftEntity.fromJson(response.data.updatedOpenShift),
            updatedOpenShiftRequests: (response.data.updatedOpenShiftRequests as Array<IShiftRequestEntity>).map(shiftRequest =>
                ShiftRequestEntity.fromJson(shiftRequest)
            ),
            unreadShiftRequestsCount: Number(response.data.unreadShiftRequestsCount),
            generatedAlerts: RestClient.parseAlertsFromResponse(response)
        };
    }

    /**
     * Delete OpenShift
     * @param tenantId
     * @param teamId - TeamId
     * @param openShift - OpenShiftEntity
     */
    public static deleteOpenShift(tenantId: string, teamId: string, openShift: IOpenShiftEntity): Promise<IOpenShiftEntity> {
        const urlSignature = "/api/tenants/tenantId/teams/teamId/shifts/open/openShiftId";
        const url = `/api/tenants/${tenantId}/teams/${teamId}/shifts/open/${openShift.id}`;

        return NetworkQueue.addRequestWithUpdateEtag(
            openShift.id,
            async (updatedETag?: string) => {
                // if there is an updatedETag, update our payload with the new
                // eTag so that we don't get a 409 conflict
                if (updatedETag) {
                    openShift.eTag = updatedETag;
                }
                const response: AxiosResponse = await NetworkService.sendHttpRequestToStaffHubService({
                    method: "DELETE",
                    url: url,
                    urlSignature: urlSignature,
                    data: {
                        eTag: openShift.eTag,
                        isPublished: openShift.isPublished
                    }
                });
                return RestClient.parseOpenShiftFromResponse(response);
            },
            RestClient._getUpdatedShiftETagFromOpenShift
        );
    }

    /**
     * Assign OpenShift to a member and optionally override group and dates for openShift
     * @param teamId - TeamId
     * @param openShift - Open Shift that is going to be assigned
     * @param memberId - MemberId to assign OpenShift to
     * @param tagId - Optional Group
     * @param startTime - Optional start date for openshift to be assigned
     */
    public static assignOpenShift(
        tenantId: string,
        teamId: string,
        openShift: IOpenShiftEntity,
        memberId: string,
        tagId?: string,
        startTime?: Moment,
        endTime?: Moment
    ): Promise<IAssignOpenShiftResponseEntity> {
        const urlSignature = "/api/tenants/tenantId/teams/teamId/shifts/open/openShiftId/assign";
        const url = `/api/tenants/${tenantId}/teams/${teamId}/shifts/open/${openShift.id}/assign`;

        let requestData: IAssignOpenShiftRequestEntity = {
            eTag: openShift.eTag,
            shiftOverrides: {} as IBaseShiftServiceEntity
        };

        // The assignOpenShift API takes shift properties to override the created assigned shift with. The format
        // for these properties is a "Patchable" and lools like so:
        // shiftOverrides.startTime = { Value: { date: "DateTime"} }
        // or shiftOverrides.tagIds = { Value: ["tagId"] }
        // this format is encapsulated in this.setAsPatchable

        // if memberId is present, pass it as shiftOverride
        if (memberId) {
            requestData.shiftOverrides.memberId = this.setAsPatchable<string>(memberId) as any;
        }

        // if tagId is present, pass it as shiftOverride
        if (tagId) {
            requestData.shiftOverrides.tagIds = this.setAsPatchable<string[]>([tagId]) as any;
        }
        // if startTime is present, pass it as shiftOverride to override day of openShift
        if (startTime) {
            requestData.shiftOverrides.startTime = this.setAsPatchable<string>(
                startTime && startTime.toISOString && startTime.toISOString()
            ) as any;
        }

        // if endTime is present, pass it as shiftOverride to override day of openShift
        if (endTime) {
            requestData.shiftOverrides.endTime = this.setAsPatchable<string>(
                endTime && endTime.toISOString && endTime.toISOString()
            ) as any;
        }

        return NetworkQueue.addRequestWithUpdateEtag(openShift.id, async (updatedETag?: string) => {
            // if there is an updatedETag, update our payload with the new
            // eTag so that we don't get a 409 conflict
            if (updatedETag) {
                openShift.eTag = updatedETag;
            }
            const response: AxiosResponse = await NetworkService.sendHttpRequestToStaffHubService({
                method: "POST",
                url: url,
                urlSignature: urlSignature,
                data: requestData
            });
            return RestClient.parseAssignedOpenShiftResponse(response);
        });
    }

    /**
     * Returns an object that wraps the input value in an object and assigns the input value to
     * the property "Value"
     * @param value
     */
    public static setAsPatchable<K>(value: K): Patchable<K> {
        const patchable: Patchable<K> = {
            Value: value
        };

        return patchable;
    }

    /**
     * Helper to parse server response to a list of AvailabilityEntity
     * @param response - response to get availabilities
     */
    private static parseAvailabilitiesFromResponse(response: AxiosResponse): Map<string, IAvailabilityEntity[]> {
        if (!response || !response.data) {
            let error = createNewHttpError();
            error.message = "availabilities response has invalid availabilities data";
            trace.warn(error.message);
            throw error;
        }

        if (!response.data.membersAvailabilities) {
            return null;
        }

        let membersAvailabilities: Map<string, IAvailabilityEntity[]> = new Map<string, IAvailabilityEntity[]>();
        for (let i = 0; i < response.data.membersAvailabilities.length; i++) {
            const memberId = response.data.membersAvailabilities[i].memberId;
            const jsonData = response.data.membersAvailabilities[i].availabilities;
            // jsonData is null for users whose availabilities are not specified.  Don't add them to list.
            if (jsonData) {
                let availabilities: IAvailabilityEntity[] = [];
                for (let j = 0; j < jsonData.length; j++) {
                    const availability = RestClient.parseMemberAvailability(jsonData[j]);

                    if (availability) {
                        availabilities.push(availability);
                    }
                }

                membersAvailabilities.set(memberId, availabilities);
            }
        }
        return membersAvailabilities;
    }

    /**
     * Helper to parse individual availabilities from server response
     * @param json - json object to parse
     * @returns IAvailabilityEntity | undefined
     */
    private static parseMemberAvailability(json: IAvailabilityEntity): IAvailabilityEntity | undefined {
        return AvailabilityEntity.fromJson(json);
    }

    /**
     * Get the availabilities for the whole team.  Only returns availabilities for users who have set their availabilities.
     * @param tenantId
     * @param teamId
     */
    public static async getAvailabilities(tenantId: string, teamId: string): Promise<Map<string, IAvailabilityEntity[]>> {
        const urlSignature = "/api/tenants/tenantId/teams/teamId/members/availabilities";
        const url = "/api/tenants/" + tenantId + "/teams/" + teamId + "/members/availabilities";

        const response: AxiosResponse = await NetworkService.sendHttpRequestToStaffHubService({
            method: "GET",
            url: url,
            urlSignature: urlSignature
        });
        return RestClient.parseAvailabilitiesFromResponse(response);
    }

    /**
     * Import Schedule
     * It uploads schedule file. Service is handling import schedule in async manner, So It returns jobId and status
     * We keep on polling with the help of jobId to retrieve status of uploaded schedule
     * @param teamId
     * @param importScheduleFile  A Excel File which contains team members schedule information
     */
    public static async importScheduleFromExcel(teamId: string, importScheduleFile: File): Promise<IJobEntity> {
        const urlSignature = "/api/teams/teamId/import";
        const url = "/api/teams/" + teamId + "/import";

        let importScheduleJob: IJobEntity = null;
        const formData = new FormData();
        formData.append("file", importScheduleFile);

        const response = await NetworkService.sendHttpRequestToStaffHubService({
            method: "POST",
            url: url,
            urlSignature: urlSignature,
            headers: {
                [CONTENTTYPEHEADER]: "multipart/form-data"
            },
            data: formData
        });

        if (response.data) {
            importScheduleJob = JobEntity.fromJson(response.data);
        }

        return importScheduleJob;
    }

    /**
     * Check Team Job Status (e.g. ImportSchedule)
     * @param teamId The team the job is specific to
     * @param jobId A unique id for the job being checked.
     */
    public static async checkTeamJobStatus(teamId: string, jobId: string): Promise<IJobEntity> {
        const url = "/api/teams/" + teamId + "/jobs/" + jobId;
        const urlSignature = "/api/teams/teamId/jobs/jobId";

        const response: AxiosResponse = await NetworkService.sendHttpRequestToStaffHubService({
            method: "GET",
            url: url,
            urlSignature: urlSignature
        });

        let teamJob: IJobEntity = null;

        if (response.data) {
            teamJob = JobEntity.fromJson(response.data);
        }

        return teamJob;
    }

    /**
     * Get Import Jobs for a team.
     * @param teamId Team Id.
     * @param top Top number of jobs to return.
     * @returns List of import jobs.
     */
    public static async getImportJobs(teamId: string, top?: number): Promise<IImportJobEntity[]> {
        let url = "/api/teams/" + teamId + "/import/jobs";
        const urlSignature = "/api/teams/teamId/import/jobs";

        url = url + "?top=" + (top ? top : IMPORT_JOB_COUNT);

        const response = await NetworkService.sendHttpRequestToStaffHubService({
            method: "GET",
            url: url,
            urlSignature: urlSignature
        });

        let importJobs: IImportJobEntity[] = [];

        if (response?.data) {
            importJobs = (response.data.jobs as Array<IImportJobEntity>).map(job => ImportJobEntity.fromJson(job));
        }

        return importJobs;
    }

    /**
     * Get the url to download import schedule sample file
     * @param teamId - Team Id
     */
    public static getImportScheduleSampleFileUrl(teamId: string): string {
        const uiCulture: string = window.sa.currentUICulture ? window.sa.currentUICulture : "en-US";
        const urlFormatString = "/api/teams/{0}/import/samplefile?&authorization={1}&Accept-Language={2}";
        return (
            NetworkService.GetServiceAPIEndPointUrl() +
            urlFormatString.format(teamId, encodeURIComponent(AuthService.getCachedShiftsBearerToken()), encodeURIComponent(uiCulture))
        );
    }

    /**
     * Get the url to download import schedule sample file with the new API enpoint (V2 endpoint)
     * @param teamId - Team Id
     */
    public static async getImportScheduleSampleFileUrlV2(teamId: string): Promise<string> {
        const urlFormatString = "/api/v2/teams/{0}/import/samplefile";
        const serviceEndPointUrl = urlFormatString.format(teamId);
        const urlSignature = "/api/v2/teams/teamId/import/samplefile";
        const response: AxiosResponse = await NetworkService.sendHttpRequestToStaffHubService({
            method: "GET",
            url: serviceEndPointUrl,
            urlSignature: urlSignature
        });

        if (response && response.data) {
            return response.data.url;
        }

        return "";
    }

    /**
     * Get the url to download import schedule error file
     * @param teamId - Team Id
     * @param jobId -  Job Id
     */
    public static getImportScheduleErrorFileUrl(teamId: string, jobId: string): string {
        const urlFormatString = "/api/teams/{0}/import/{1}/errorfile?&authorization={2}";
        return (
            NetworkService.GetServiceAPIEndPointUrl() +
            urlFormatString.format(teamId, jobId, encodeURIComponent(AuthService.getCachedShiftsBearerToken()))
        );
    }

    /**
     * Requests an exisiting open shift. Creates (and returns) the create shift request
     * @param tenantId The tenant id.
     * @param teamId The team id of the open shift.
     * @param openShiftId The open shift id.
     * @param shiftRequestId The id of the shift request that will be created.
     * @param message The message from the sender in requesting a shift.
     * @param senderTeamId The team id of the sender. This must be provided when current user is requesting a cross-location open-shift from an external team that the user is not part of.
     */
    public static async requestOpenShift(
        tenantId: string,
        teamId: string,
        openShiftId: string,
        shiftRequestId: string,
        message: string,
        senderTeamId?: string
    ): Promise<IShiftRequestEntity> {
        const urlSignature = "/api/tenants/tenantId/teams/teamId/shifts/open/openShiftId/request";
        const url = `/api/tenants/${tenantId}/teams/${teamId}/shifts/open/${openShiftId}/request`;

        const response = await NetworkQueue.addRequestWithUpdateEtag(
            shiftRequestId,
            async (updatedETag?: string) => {
                const data = {
                    message,
                    senderTeamId,
                    shiftRequestId
                };

                return await NetworkService.sendHttpRequestToStaffHubService<IShiftRequestEntity>({
                    method: "POST",
                    url,
                    urlSignature,
                    data
                });
            },
            RestClient._getUpdatedShiftRequestETag
        );
        return RestClient.parseShiftRequestFromResponse(response);
    }

    /**
     * Get teams policy settings for a user to check if Bing map is enabled.
     * @param userId - teamId
     */
    public static async getTeamPolicySettings(userId: string): Promise<ITeamShiftPolicyEntity> {
        if (!userId) {
            trace.warn("User Id is empty");
            return null;
        }
        const url = "/api/users/" + userId + "/teamsPolicySettings";
        const urlSignature = "/api/users/userId/teamsPolicySettings";
        const response: AxiosResponse = await NetworkService.sendHttpRequestToStaffHubService({
            method: "GET",
            url: url,
            urlSignature: urlSignature
        });
        let teamPolicySettings: ITeamShiftPolicyEntity = null;
        if (response && response.data && response.data.teamsShiftsAppPolicy) {
            teamPolicySettings = ShiftPolicyEntity.fromJson(response.data.teamsShiftsAppPolicy);
        }
        return teamPolicySettings;
    }

    /**
     * Get Requestable shifts for swap which are filtered on the shift send as param.
     * @param {String} teamId
     * @param {IShiftEntity} shift
     * @param {Moment} startTime - startTime from which shifts need to be fetched
     * @param {Moment} endTime - endTime till which shifts need to be fetched
     * @param {string} requestType - Swap or Offer
     * @returns {} Promise of network request with DeleteShift request
     */
    public static async getRequestableShifts(
        teamId: string,
        shift: IShiftEntity,
        startTime: Moment,
        endTime: Moment,
        requestType: string
    ): Promise<string[]> {
        const urlSignature = "api/teams/teamId/shifts/shiftId/requestableShifts";
        let url = "/api/teams/" + teamId + "/shifts/" + shift.id + "/requestableShifts";

        if (startTime || endTime || requestType) {
            url += "?";
        }
        if (startTime) {
            url += "&startTime=" + encodeURIComponent(startTime.toISOString());
        }
        if (endTime) {
            url += "&endTime=" + encodeURIComponent(endTime.toISOString());
        }
        if (requestType) {
            url += "&requestType=" + requestType;
        }

        const response: AxiosResponse = await NetworkService.sendHttpRequestToStaffHubService({
            method: "GET",
            url: url,
            urlSignature: urlSignature
        });

        if (response && response.data) {
            return response.data.shiftIds;
        }
    }

    /**
     * Get team permissions for current user using teamId.
     * @param {string} tenantId - The tenant ID
     * @param {string} teamId - The ID of the team to get permissions for
     * @returns {Promise<ITeamPermissions>} Promise that resolves to team permissions
     */
    public static async getTeamPermissions(tenantId: string, teamId: string): Promise<ITeamPermissionEntity> {
        const urlSignature = "/api/tenants/tenantId/teams/teamId/users/me/permissions";
        const url = "/api/tenants/" + tenantId + "/teams/" + teamId + "/users/me/permissions";

        const response: AxiosResponse = await NetworkService.sendHttpRequestToStaffHubService({
            method: "GET",
            url: url,
            urlSignature: urlSignature
        });

        let teamPermissions: ITeamPermissionEntity = null;
        if (response && response.data) {
            teamPermissions = TeamPermissionEntity.fromJson(response.data);
        }

        return teamPermissions;
    }

    /**
     * Get permissions for a member of a team.
     * @param {string} teamId - The ID of the team
     * @param {string} tenantId - The tenant ID
     * @returns {Promise<ITeamMemberPermissionsEntity>} Promise that resolves to team member permissions
     */
    public static async getTeamMemberPermissions(tenantId: string, teamId: string): Promise<ITeamMemberPermissionsEntity> {
        const urlSignature = "/api/tenants/tenantId/teams/teamId/schedule/permissions/member";
        const url = "/api/tenants/" + tenantId + "/teams/" + teamId + "/schedule/permissions/member";

        const response = await NetworkService.sendHttpRequestToStaffHubService({
            method: "GET",
            url: url,
            urlSignature: urlSignature
        });

        let teamPermissions: ITeamMemberPermissionsEntity = null;
        if (response?.data) {
            teamPermissions = TeamMemberPermissionsEntity.fromJson(response.data);
        }

        return teamPermissions;
    }

    /**
     * update permissions for a member of a team.
     * @param {string} teamId - The ID of the team
     * @param {string} tenantId - The tenant ID
     * @param {ITeamMemberPermissionsEntity} permissions list of team member permissions to be updated
     */
    public static async patchTeamMemberPermissions(tenantId: string, teamId: string, permissions: Partial<ITeamMemberPermissionsEntity>): Promise<void> {
        const urlSignature = "/api/tenants/tenantId/teams/teamId/schedule/permissions/member";
        const url = "/api/tenants/" + tenantId + "/teams/" + teamId + "/schedule/permissions/member";

        await NetworkService.sendHttpRequestToStaffHubService({
            method: "PATCH",
            url: url,
            urlSignature: urlSignature,
            data: permissions
        });
    }
}
