import { action } from "satcheljs";
import { ILocateUserResponseEntity } from 'sh-models';

const updateLocateUserResponse = action(
    "updateLocateUserResponse",
    (
        locateUserResponse: ILocateUserResponseEntity
    ) => ({
        locateUserResponse: locateUserResponse
    })
);

export default updateLocateUserResponse;