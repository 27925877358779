import { UserStore } from "sh-stores";
import { action } from "satcheljs/lib/legacy";
import { IUserPolicySettingsEntity } from "sh-models";

/**
 * Store user policy settings
 */

export let setUserPolicySettings = action("setUserPolicySettings")(
    function setUserPolicySettings(userPolicySettings: IUserPolicySettingsEntity) {
        UserStore().userPolicySettings = userPolicySettings;
    });
