import { mutatorAction } from "satcheljs";
import { ShiftMemberDateInfo, ShiftsByDate, ShiftsByMemberAndDateStore } from "sh-conflict-store";
import { IShiftEntity } from "sh-models";

export default mutatorAction(
    'DELETE_SHIFT_FROM_MEMBER_ID_TO_SHIFTS_BY_DATE_STORE',
    function deleteShiftFromMemberIdToShiftsByDateStore(
        shiftId: string,
        shiftMemberDateInfo: ShiftMemberDateInfo
    ) {
        const shiftMemberId: string = shiftMemberDateInfo.memberId;
        const shiftStartOfDates: Set<number> = shiftMemberDateInfo.startOfDates;

        // if the memberId exists in memberIdToShiftsByDate
        if (shiftMemberId && ShiftsByMemberAndDateStore().memberIdToShiftsByDate.has(shiftMemberId)) {

            // get the list which already has some shifts for that member
            let shiftByDatesForMember: ShiftsByDate = ShiftsByMemberAndDateStore().memberIdToShiftsByDate.get(shiftMemberId);

            // Iterate over all the dates the shift is on to clean up the ShiftsByMemberAndDateStore().memberIdToShiftsByDate store
            shiftStartOfDates.forEach((startOfDate: number) => {
                // if list for member id exists shiftDatesIntervalList && shiftDatesIntervalList.shiftsByDateMap will not possibly be null, still adding this check for extra safety
                if (shiftByDatesForMember && shiftByDatesForMember.shiftsByDateMap && shiftByDatesForMember.shiftsByDateMap.has(startOfDate)) {
                    let memberShiftsOnDate: IShiftEntity[] = shiftByDatesForMember.shiftsByDateMap.get(startOfDate);
                    const index = memberShiftsOnDate && memberShiftsOnDate.findIndex((shiftItem: IShiftEntity) => { return shiftItem && shiftItem.id === shiftId; });

                    // if the shift exists, remove the item
                    if (index > -1) {

                        // if there are no shifts in this date interval for members, remove the dateinterval itself
                        if (memberShiftsOnDate.length === 1) {
                            // update the nested maps of memberIdToShiftsByDate
                            shiftByDatesForMember.shiftsByDateMap.delete(startOfDate);
                        } else {
                            // remove just the shift
                            memberShiftsOnDate.splice(index, 1);
                            // update the nested maps of memberIdToShiftsByDate
                            shiftByDatesForMember.shiftsByDateMap.set(startOfDate, memberShiftsOnDate);
                        }
                    }
                }
            });

            if (shiftByDatesForMember.shiftsByDateMap.size === 0) {
                // no more shifts for this member
                ShiftsByMemberAndDateStore().memberIdToShiftsByDate.delete(shiftMemberId);
            } else {
                // Update the ShiftsByDate for the member
                ShiftsByMemberAndDateStore().memberIdToShiftsByDate.set(shiftMemberId, shiftByDatesForMember);
            }
        }
    }
 );