import resetShiftRequestsStore from "../actions/resetShiftRequestsStore";
import { IGroupedOpenShiftRequestEntity, IShiftRequestEntity } from "sh-models";
import { mutator } from "satcheljs";
import { ObservableMap } from "mobx";
import { shiftRequestStore } from "../";

/**
 * Reset the shift requests store
 */
export default mutator(resetShiftRequestsStore, ({ }) => {
    shiftRequestStore().shiftRequests = new ObservableMap<string, IShiftRequestEntity>();
    shiftRequestStore().groupedOpenShiftRequests = new ObservableMap<string, IGroupedOpenShiftRequestEntity>();
    shiftRequestStore().unreadRequestsCount = 0;
    shiftRequestStore().nextCursor = "";
    shiftRequestStore().areAllActiveRequestsLoaded = false;
    shiftRequestStore().deepLinkRequestLoaded = false;
});
