import { ITimeOffReasonEntity } from "sh-models";
import { mutatorAction } from "satcheljs";
import { TimeOffReasonsStore } from "..";

export default mutatorAction('SET_TIME_OFF_REASONS_IN_STORE', function setTimeOffReasonsInStore(timeOffReasons: ITimeOffReasonEntity[]) {
    if (timeOffReasons && timeOffReasons.length) {
        for (const timeoffReason of timeOffReasons) {
            TimeOffReasonsStore().timeOffReasons.set(timeoffReason.id, timeoffReason);
        }
    }
});
