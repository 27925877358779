import schedulesViewStateStore from "../store/store";
import ShiftUtils from "sh-application/utility/ShiftUtils";
import { DataProcessingHelpers } from "sh-services";
import { IOpenShiftEntity } from "sh-models";
import { Moment } from "moment";
import { orchestrator } from "satcheljs";
import updateOpenShiftsInView from "../mutators/openShifts/updateOpenShiftsInView";
import { updateOpenShiftsInStore } from "sh-open-shift-store";

/**
 * When updates to open shifts are triggered via the UI or via sync notifications, this orchestrator will be triggered.
 * It will parse the updated open  and pass those that fit into the current view to the updateOpenShiftsInView() mutator, which
 * will update the map of open shifts in the local view store
 */
export default orchestrator(updateOpenShiftsInStore, async actionMessage => {
    const viewStart: Moment = schedulesViewStateStore().viewStartDate;
    const viewEnd: Moment = schedulesViewStateStore().viewEndDate;
    const updatedOpenShiftsForView: IOpenShiftEntity[] = DataProcessingHelpers.getDisplayableShiftsInScheduleRange(actionMessage.updatedOpenShifts, viewStart, viewEnd) as IOpenShiftEntity[];

    // We need to handle the special case where updated open shifts that were previously in the view have been moved out of the view. These open shifts will not count as
    // displayable shifts in the schedule range, so we detect them by checking if their ids are present in the view store
    if (actionMessage.updatedOpenShifts) {
        for (let i = 0; i < actionMessage.updatedOpenShifts.length; i++) {
            const openShift: IOpenShiftEntity = actionMessage.updatedOpenShifts[i];
            if (schedulesViewStateStore().openShiftsInView.has(openShift.id) && !ShiftUtils.isShiftInList(updatedOpenShiftsForView, openShift)) {
                updatedOpenShiftsForView.push(openShift);
            }
        }
    }

    if (updatedOpenShiftsForView.length) {
        updateOpenShiftsInView(updatedOpenShiftsForView);
    }
});