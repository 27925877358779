import { IImportJobResult } from "./IImportJobResult";
import { ImportJobStatus } from "./IJobEntity";

/**
 * The import job result data model in Shifts service endpoint '/api/teams/{teamId}/import/jobs?top=x'.
 */
export class ImportJobResult implements IImportJobResult {
    errorFileUri: string;
    fileName: string;
    fileUri: string;
    importStatus: ImportJobStatus;
    importStartTime: string;
    message: string;

    /**
     * Constructor with parameters.
     * @param errorFileUri Error File URI.
     * @param fileName Imported file name.
     * @param fileUri Imported file URI.
     * @param importStatus Status of the import job.
     * @param importStartTime Start time of the import job.
     * @param message Error message.
     */
    constructor(errorFileUri: string, fileName: string, fileUri: string, importStatus: ImportJobStatus, importStartTime: string, message: string) {
        this.errorFileUri = errorFileUri;
        this.fileName = fileName;
        this.fileUri = fileUri;
        this.importStatus = importStatus;
        this.importStartTime = importStartTime;
        this.message = message;
    }

    /**
     * Convert json data to ImportJobResult object.
     * @param jsonData Json data to be converted to ImportJobResult object.
     * @returns ImportJobResult object.
     */
    public static fromJson(jsonData: IImportJobResult): ImportJobResult {
        if (!jsonData) {
            return null;
        }

        return new ImportJobResult(jsonData.errorFileUri, jsonData.fileName, jsonData.fileUri, jsonData.importStatus, jsonData.importStartTime, jsonData.message);
    }
}