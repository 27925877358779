import schedulesViewStateStore from "../../store/store";
import { INoteEntity } from "sh-models";
import { mutatorAction } from "satcheljs";

export default mutatorAction('SET_NOTES_IN_VIEW', function setNotesInView(
    notesInView: INoteEntity[]
) {
    schedulesViewStateStore().notesInView.clear();
    if (notesInView && notesInView.length) {
        for (let i = 0; i < notesInView.length; i++) {
            schedulesViewStateStore().notesInView.set(notesInView[i].id, notesInView[i]);
        }
    }
});
