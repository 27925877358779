import * as React from "react";
import AutomationUtil from "sh-application/utility/AutomationUtil";
import AvailabilityUtils from "sh-application/utility/AvailabilityUtils";
import classNames from "classnames/bind";
import ConflictDetails from "./ConflictDetails";
import ConflictUtils from "sh-application/utility/ConflictUtils";
import ShiftUtils from "sh-application/utility/ShiftUtils";
import StringsStore from "sh-strings/store";
import { AvailabilityStore } from "sh-availability-store";
import {
    ConflictType,
    IBaseConflictEntity,
    IShiftEntity,
    ScheduleCalendarType,
    ShiftTypes
    } from "sh-models";
import {
    DirectionalHint,
    FocusTrapCallout,
    FocusZone,
    FocusZoneDirection,
    IButton,
    IconButton,
    IRefObject,
    List,
    TooltipHost
    } from "@fluentui/react";
import { getGenericEventPropertiesObject } from "sh-instrumentation";
import { InstrumentationService } from "sh-services";
import { IShiftEditorData, launchShiftEditor, shiftEditorViewState } from "../lib";
import { observer } from "mobx-react";
import { TeamStore } from "sh-stores/sh-team-store";
const styles = require("./ConflictDetailsCallout.scss");

export interface ConflictDetailsCalloutState {
    conflictInfoCalloutVisible: boolean;
}

interface ConflictDetailsCalloutProps {
    onDismissCallback: VoidFunction;         // Call back function when the callout is dismissed
    targetElement: HTMLElement;              // target element for the callout
    shiftId: string;                         // shiftId of the shift for which the callout is called
    conflictEntities: IBaseConflictEntity[]; // List of conflicting entities for particular shift
    scheduleCalendarType: ScheduleCalendarType; // The view the cell is rendered in,
    elementToFocusOnDismiss?: IRefObject<IButton>; // The element to focus on after shift editor is dismissed
}

/**
 * Conflict Details callout - to view the type of conflict on a shift
 */
@observer
export default class ConflictDetailsCallout extends React.Component<ConflictDetailsCalloutProps, ConflictDetailsCalloutState> {

    private _conflictCalloutStrings: Map<string, string>;
    private _conflictInfoTourStop = React.createRef<HTMLInputElement>();

    constructor(props: ConflictDetailsCalloutProps) {
        super(props);
        this._conflictCalloutStrings = StringsStore().registeredStringModules.get("conflictManagement").strings;

        this.state = {
            conflictInfoCalloutVisible: false
        };
    }

    /**
     * Callback invoked when callout is dismissed
     */
    private onDismissCallback = () => {
        if (this.props.onDismissCallback) {
            this.props.onDismissCallback();
        }
    }

    /**
     * The callback for when the callout for info about conflict callout is dismissed.
     */
    private onConflictInfoCalloutDismissed = (ev: MouseEvent) => {
        ev.stopPropagation();
        this.setState({
            conflictInfoCalloutVisible: false
        });

        if (this._conflictInfoTourStop && this._conflictInfoTourStop.current) {
            this._conflictInfoTourStop.current.focus();
        }
    }

    /**
     * The callback for when the "i" button is clicked to show the callout about shift conflict.
     */
    private onConflictInfoCalloutClicked = () => {
        InstrumentationService.logEvent(InstrumentationService.events.ConflictsPopupMoreInfoClicked,
            [ getGenericEventPropertiesObject(InstrumentationService.properties.CurrentView, this.props.scheduleCalendarType)]);
        this.setState({
            conflictInfoCalloutVisible: true
        });
    }

    /**
     * Renders a div containing a localized title for the conflict callout title component
     * and a info icon with Tour bubble
     */
    private renderCalloutTitle(): JSX.Element {
        const { conflictInfoCalloutVisible } = this.state;
        return (
            <div className={ styles.conflictTitleContainer }>
                <div data-automation-id={ AutomationUtil.getAutomationId("conflictCallout", "QAIDConflictCalloutTitle") }
                    className={ styles.conflictDetailsTitle }>
                    { this._conflictCalloutStrings.get("shiftConflicts") }
                    <div className={ styles.iconContainer } ref={ this._conflictInfoTourStop } >
                        <TooltipHost id="conflictInfoTooltip" content={ this._conflictCalloutStrings.get("conflictTourText") } >
                            <IconButton
                                className={ classNames(styles.conflictInfoIcon, { [`${styles.selected}`]: conflictInfoCalloutVisible }) }
                                aria-describedby="conflictInfoTooltip"
                                ariaHidden={ false }
                                iconProps={ { iconName: "teams-info" } }
                                onClick={ this.onConflictInfoCalloutClicked }
                                data-automation-id={ AutomationUtil.getAutomationId("conflictCallout", "QAIDConflictInfoIcon") } />
                        </TooltipHost>
                    </div>
                </div>
            </div>);
    }

    /**
     * The onclick handler for clicking conflict list item.
     * This launches the shifteditor for shift/timeoff
     * @param conflictingShift IShiftEntity, the shift we open with the refernece to edit
     */
    private onConflictShiftItemClicked = (event: React.MouseEvent<HTMLDivElement>, conflictingShift: IShiftEntity) => {
        event.preventDefault();
        const { scheduleCalendarType } = this.props;
        if (conflictingShift) {
            const shiftEditorData: IShiftEditorData = {
                shift: conflictingShift, /* shift */
                openShift: conflictingShift, /* openShift */
                timeoff: conflictingShift /* timeoff */
            };
            const member = conflictingShift && TeamStore().members && TeamStore().members.get(conflictingShift.memberId);
            const isEditShift = conflictingShift.shiftType === ShiftTypes.Working;
            const tagId = conflictingShift && ShiftUtils.getTagIdFromShift(conflictingShift) || "";
            InstrumentationService.logEvent(InstrumentationService.events.ConflictsPopupShiftObjectClicked,
                [ getGenericEventPropertiesObject(InstrumentationService.properties.CurrentView, this.props.scheduleCalendarType),
                  getGenericEventPropertiesObject(InstrumentationService.properties.TypeOfConflict, isEditShift ? ConflictType.OverlappingShiftConflict : ConflictType.ShiftTimeOffConflict)]);
            launchShiftEditor(shiftEditorData /* shiftEditorData */, conflictingShift.shiftType /* selectedPanelType */, member /* member */, tagId /* tagId to be assigned to */, scheduleCalendarType /* calendarType to be edited */, isEditShift /* isEdit Shift*/, !isEditShift /* isEdit timeoff */, shiftEditorViewState() /* viewstate */, conflictingShift.startTime /*startDate*/, false /* isOpenShift */, this.props.elementToFocusOnDismiss);
        }
    }

    /**
     * Every conflict is rendered with its details as description title and conflicting shift
     */
    private renderListItems = (baseConflictEntity: IBaseConflictEntity, index: number | undefined): JSX.Element => {
        // Get type of conflictEntity object and its corresponding conflicting shift id.
        const conflictingId: string = ConflictUtils.getConflictingEntityId(baseConflictEntity, this.props.shiftId);
        let conflictingShift: IShiftEntity = null;
        let conflictingAvailability = null;
        let conflictListIndex = index + 1;
        const isECSConflictTypeEnabled = ConflictUtils.isConflictTypeEnabled (baseConflictEntity.conflictType);
        if (baseConflictEntity.conflictType === ConflictType.OverlappingShiftConflict || baseConflictEntity.conflictType === ConflictType.ShiftTimeOffConflict) {
            conflictingShift = isECSConflictTypeEnabled && ShiftUtils.getShiftByShiftId(conflictingId);
        } else {
            conflictingAvailability = isECSConflictTypeEnabled && AvailabilityUtils.getAvailabilityFromId(AvailabilityStore().membersAvailabilities, conflictingId, baseConflictEntity.memberId);
        }

        const isListSpill = this.props.conflictEntities.length > 1;
        return (
            <div>
                <ConflictDetails
                    conflictEntity={ baseConflictEntity }
                    conflictingShift={ conflictingShift }
                    conflictingAvailability={ conflictingAvailability }
                    shift ={ ShiftUtils.getShiftByShiftId(this.props.shiftId) }
                    onConflictShiftItemClicked={ this.onConflictShiftItemClicked }
                    onDismissCallback={ this.props.onDismissCallback }
                    conflictListIndex={ conflictListIndex }
                    conflictDetailsNum={ this.props.conflictEntities.length }
                />
                { isListSpill && (index != this.props.conflictEntities.length - 1 ) && <div className={ styles.divider } /> }
            </div>);
    }

    /**
     * Render List of conflicts for a shift
     * @param conflictEntities The list of conflicts for a shift
     */
    private renderConflictTypeList(): JSX.Element {
        const { conflictEntities } = this.props;

        if (!conflictEntities || conflictEntities.length === 0) {
            return null;
        }

        return (
                <List
                    items={ conflictEntities }
                    onRenderCell={ this.renderListItems }
                    data-automation-id={ AutomationUtil.getAutomationId("conflictCallout", "QAIDConflictList") }
                />
            );
    }

    /**
     * Renders the callout when a conflict Icon is clicked on a shift
     */
    render() {
        return <FocusTrapCallout
            role={ "dialog" }
            focusTrapProps={ {
                isClickableOutsideFocusTrap: true,
                elementToFocusOnDismiss: this.props.targetElement,
                firstFocusableSelector: styles.conflictInfoIcon
            } }
            preventDismissOnScroll={ true }
            onDismiss={ this.onDismissCallback }
            target={ this.props.targetElement }
            setInitialFocus={ true }
            doNotLayer={ true }
            directionalHint={ DirectionalHint.rightCenter }
            className={ styles.conflictDetailsCallOut }>
                <FocusZone direction={ FocusZoneDirection.vertical } handleTabKey={ 1 } defaultTabbableElement={ styles.conflictInfoIcon }>
                    <div className={ classNames(styles.conflictDetailsContainer) } >
                        { this.renderCalloutTitle() }
                        { this.renderConflictTypeList() }
                    </div>
                </FocusZone>
        </FocusTrapCallout>;
    }
}
