import { observable } from "mobx";
import { createStore } from "satcheljs";

import { IImportJobEntity } from "../../../sh-models";

import { ImportJobsStoreSchema } from "./ImportJobsStoreSchema";

/* register orchestraotrs */
import "../orchestrators/onGetImportJobs";
import "../orchestrators/onImportJobSchedule";

// TODO <ImportJobs>: Move this to shifts-experiences-importschedule package
/**
 * Store for ImportJobsStore.
 */
export const ImportJobsStore = createStore<ImportJobsStoreSchema>(
    "ImportJobsStore",
    {
        dialogErrorMessage: "",
        dialogOpen: false,
        jobs: observable<IImportJobEntity>([]),
        jobInProgress: false
    });
