import MemberUtils from "./MemberUtils";
import { IMemberEntity } from "sh-models";
import { TeamStore } from "sh-stores/sh-team-store";

export default class AdminPermissionChecker {

    public static isCurrentUserAdmin() {
        const currentUser: IMemberEntity = TeamStore() && TeamStore().me;
        return MemberUtils.isAdmin(currentUser);
    }
}