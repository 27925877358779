import { mutatorAction } from "satcheljs";
import { SchedulesViewStateStore } from "sh-application/components/schedules/lib/store/schema/SchedulesViewStateStore";

/**
 * Updates the data in date range (shifts and notes) loaded state
 */
export default mutatorAction("UPDATE_IS_DATA_IN_DATE_RANGE_LOADED",
    function updateIsDataInDateRangeLoaded(viewState: SchedulesViewStateStore, isDataInDateRangeLoaded: boolean) {
        viewState.isDataInDateRangeLoaded = isDataInDateRangeLoaded;
    }
);