import approveSwapHandoffRequest from "../actions/approveSwapHandoffRequest";
import ShiftRequestUtils from "sh-application/utility/ShiftRequestUtils";
import StringsStore from "sh-strings/store";
import { fireAccessibilityAlert } from "sh-application/components/accessibilityAlert";
import { orchestrator } from "satcheljs";
import { setErrorMessageFromException, setIsSavingInStore } from "../";
import { ShiftRequestDataService } from "sh-services";
import { ShiftRequestTypes } from "sh-models";
import { trace } from "owa-trace";

/**
 * This orchestrator will call the api to approve a swap or handoff request
 */
export default orchestrator(approveSwapHandoffRequest, async actionMessage => {
    const { shiftRequest, isReceiver, responseMessage, onFinish } = actionMessage;
    if (!shiftRequest) {
        trace.warn("approveSwapHandoffRequest: ShiftRequest not specified");
        return;
    } else if (shiftRequest.requestType !== ShiftRequestTypes.Swap && shiftRequest.requestType !== ShiftRequestTypes.HandOff) {
        trace.warn("approveSwapHandoffRequest: ShiftRequest type incorrect");
        return;
    }

    try {
        setIsSavingInStore(true);
        await ShiftRequestDataService.approveSwapHandoffRequest(shiftRequest.tenantId, shiftRequest.teamId, shiftRequest.id, shiftRequest.eTag, isReceiver, responseMessage);
        if (onFinish) {
            onFinish();
        }

        // Accessibility message that the request was approved
        const shiftRequestType: string = ShiftRequestUtils.getTypeForAccessibility(shiftRequest);
        if (isReceiver) {
            // Accept
            fireAccessibilityAlert(StringsStore().registeredStringModules.get("shiftRequests").strings.get("shiftRequestAccepted").format(shiftRequestType));
        } else {
            // Approve
            fireAccessibilityAlert(StringsStore().registeredStringModules.get("shiftRequests").strings.get("shiftRequestApproved").format(shiftRequestType));
        }
    } catch (error) {
        // workflow integration error would be handled earlier
        if (error && !error.isHandled) {
            setErrorMessageFromException(error);
        }
    } finally {
        setIsSavingInStore(false);
    }
});
