import * as React from "react";
import { AriaProperties, generateDomPropertiesForAria } from "owa-accessibility";

const classNames = require("classnames/bind");
const styles = require("./FlexiCell.scss");

/**
 * Properties interface for the FlexiCell component
 */
interface FlexiCellProps {
    animateCellClass?: string; // CSS class to add if this cell supports animation
    cellElementId?: string; // Element id to assign for this cell
    cellElementKey?: string; // Unique key for the cell element. Used to uniquely identify cell elements for optimizing reactive rendering updates, selection, etc.
    cellStyle?: any; // CSS styles for the cell
    cellClass?: string; // CSS classes for the cell
    dataSelectionIndex?: number; // Office Fabric Selection index. All selectable items should be assigned an index. This can be null if selection support is not needed.
    cellContentsItem?: any; // Custom cell contents data. This data is opaque to the FlexiCell and is passed to the rendering callback.
    onRenderCellContents?: (cellContentsItem: any, cellHasFocus: boolean) => React.ReactNode; // Custom rendering callback for the cell contents
    ariaPropsForCell?: AriaProperties; // Aria properties to assign for this cell
    onShouldComponentUpdate?: (oldCellContentsItem: any, newCellContentsItem: any) => boolean; // Custom callback provided at cell generation time. It will compare the cell contents items and return true if the FlexiCell should update
}

export const FLEXICELL_CONTENT_WRAPPER_CLASS = "sh-FlexiCell-cw";

const BaseCellContentWrapperClasses = classNames(styles.fcContentWrapper, FLEXICELL_CONTENT_WRAPPER_CLASS);

/**
 * FlexiCell component
 * This component renders a single cell with customizable contents.
 *
 * To customize the contents of the cell, implement the onRenderCellContents callback and pass any supporting data
 * that is needed by the callback via the cellContentsItem property.
 */
export default class FlexiCell extends React.Component<FlexiCellProps, {}> {
    private _currentFocusState: boolean = false;
    private _lastFocusState: boolean;

    shouldComponentUpdate(nextProps: FlexiCellProps, nextState: {}): boolean {
        // If this flexi cell has no defined onShouldComponentUpdate prop, we will default to rendering it every time
        if (!this.props.onShouldComponentUpdate) {
            return true;
            // Otherwise, we will call the onShouldComponentUpdate prop, passing it the old and new cellContentsItems, which contain the necessary data to determine if
            // an update is necessary. Focus handling is a special case. When a cell is focused, there exists a race condition
            // between the FlexiRow's render method, which will trigger shouldComponentUpdate on the focused cell, and mobx's direct call of the cell's render().
            // For this reason we track the last focus state in a regular private variable and the current focus state in an observable private variable.
            // If the focus state of this cell has changed since the last render, we will update the cell.
        } else {
            const shouldComponentUpdate =
                this.props.onShouldComponentUpdate(this.props.cellContentsItem, nextProps.cellContentsItem) ||
                this._currentFocusState !== this._lastFocusState;
            return shouldComponentUpdate;
        }
    }

    private onFocus = () => {
        this.setIsFocused(true);
    };

    private onBlur = () => {
        this.setIsFocused(false);
    };

    getFocusedState() {
        return this._currentFocusState;
    }

    setIsFocused = (isFocused: boolean) => {
        this._currentFocusState = isFocused;
        // this forceUpdate() is to explicitly cause render() to get called again. We should not use a mobx observable
        // for this because this class also overrides shouldComponentUpdate and using observable classes and shouldComponentUpdate
        // together is not recommened. https://github.com/mobxjs/mobx-react/issues/417
        this.forceUpdate();
    };

    render() {
        const { cellElementId, cellStyle, cellClass, cellContentsItem, onRenderCellContents, animateCellClass, dataSelectionIndex } =
            this.props;
        // Update the last focused state to the current one
        // Either we've already compared current and previous states in shouldComponentUpdate(), or this was a direct render from mobx and
        // the comparison was bypassed
        this._lastFocusState = this._currentFocusState;

        // A FlexiCell consists of the following element layers:
        //
        // - Cell element (outer wrapper):
        //      - This is where CSS Flexbox styles are applied to arrange FlexiCells within a FlexiRow.
        //      - Note: Do not apply border styles at this level. Borders will interfere with Flexbox's cell calculations (eg, box-sizing: border-box fails to work) and cause cells to be misaligned.
        // - Cell content wrapper (middle wrapper):
        //      - Apply borders, cell selection styles, animation styles, etc at this level.
        // - Cell contents:
        //      - Custom cell contents, rendered via the onRenderCellContents callback.
        //
        // Info about issues when using CSS Flexbox with borders:
        // https://stackoverflow.com/questions/21942183/multiline-flexbox-in-ie11-calculating-widths-incorrectly
        // https://github.com/philipwalton/flexbugs#7-flex-basis-doesnt-account-for-box-sizingborder-box
        // There is an alternate workaround of setting approximate widths using flex-basis or width instead of a wrapper element, but this approach doesn't work when we
        // have both fixed width (eg, pixel width specified for flex-basis) and variable width (eg, units specified for flex-grow) cells in a row.

        const cellContentWrapperClasses = classNames(animateCellClass, BaseCellContentWrapperClasses);

        // Cells are focusable if they are designated to be selectable.
        // Apply the data-is-focusable attribute to ensure selectable cells can get focus and also be selectable via click.
        // Apply the tabindex attribute so that selectable cells can be accessed by tab.
        const isFocusableCell: boolean = typeof dataSelectionIndex !== "undefined";

        return onRenderCellContents ? (
            <div style={cellStyle} className={cellClass}>
                <div
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    id={cellElementId}
                    className={cellContentWrapperClasses}
                    data-is-focusable={isFocusableCell ? true : null}
                    tabIndex={isFocusableCell ? 0 : null}
                    data-selection-index={dataSelectionIndex}
                    {...generateDomPropertiesForAria(this.props.ariaPropsForCell)}>
                    {onRenderCellContents(cellContentsItem, this._currentFocusState)}
                </div>
            </div>
        ) : null;
    }
}
