import { action } from "satcheljs";

/**
 * This action triggers the clearing of all local data and reloads the app
 */
const restartApp = action(
    "RESTART_APP",
    (
    ) => ({})
);

export default restartApp;
