import indexComparator from "sh-application/utility/indexComparator";
import TagUtils from "sh-application/utility/TagUtils";
import { ITagEntity, TagStates } from "sh-models";
import { mutatorAction } from "satcheljs";
import { ReorderTagsPanelViewState } from "sh-application/store/schema/ReorderTagsPanelViewState";

/**
 * Sets the tags on the tags panel view state
 * Sorts by index and filters by active tags
 * @param viewState - Tags Panel view state
 * @param tags - tags array
 */
export default mutatorAction("SET_ACTIVE_SORTED_TAGS",
    function setActiveSortedTags(viewState: ReorderTagsPanelViewState, tags: ITagEntity[]) {
        const otherGroupId = TagUtils.DEFAULT_TAG_ID;
        tags = tags || [];

        viewState.activeSortedTags =
            tags.filter((tag: ITagEntity) => tag.state !== TagStates.Deleted && tag.id !== otherGroupId)
            .sort(indexComparator);
    }
);