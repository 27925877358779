import { createStore } from "satcheljs";
import { TimezonePickerStore } from "./schema/TimezonePickerStore";

/**
 * Timezone picker view state
 */
const initialTimezonePickerStore: TimezonePickerStore = {
    timezoneOlsonCode: "",
    localTime: ""
};

const timezonePickerStore = createStore("timezonePickerStore", initialTimezonePickerStore)();
export default timezonePickerStore;

/* export actions and mutator actions for ease of import */
export { default as setTimezoneOlsonCode } from "../mutatorActions/setTimezoneOlsonCode";
export { default as setLocalTime } from "../mutatorActions/setLocalTime";
