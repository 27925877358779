import DateUtils from "sh-application/utility/DateUtils";
import ShiftUtils from "sh-application/utility/ShiftUtils";
import {
    calculateConflicts,
    calculateShiftAvailabilityConflict,
    calculateShiftConflicts,
    ConflictStore,
    setConflictCountExceededLimits,
    ShiftsByDate,
    ShiftsByMemberAndDateStore
    } from "sh-conflict-store";
import {
    ECSConfigKey,
    ECSConfigService,
    FlightSettingsService,
    InstrumentationService
    } from "sh-services";
import { FlightKeys, IShiftEntity, ShiftStates } from "sh-models";
import { orchestrator } from "satcheljs";
import { trace } from "owa-trace";
import { runInAction } from "mobx";

/**
 * This orchestrator is calculates shift overlapping, time off and shift-availability conflicts
 * It populates the ConflictStore() with the conflict entities,
 * as well as maintains the true conflict count.
 */
export default orchestrator(calculateConflicts, async (actionMessage) => {
    let marker = "CalculateConflicts";
    marker = InstrumentationService.perfMarkerStart(marker);

    const viewStartDatetime = actionMessage.viewStartDatetime;
    const viewEndDatetime = actionMessage.viewEndDatetime;

    // get the prepared map of memberId's mapped to dateintervals in day and the shifts for each dateinterval
    const memberIdToShiftsByDate: Map<string, ShiftsByDate> = ShiftsByMemberAndDateStore().memberIdToShiftsByDate;

    const isShiftAvailabilityConflictsEnabled = ECSConfigService.isECSFeatureEnabled(ECSConfigKey.EnableShiftAvailabilityConflicts);
    const isShiftOverlapConflictsEnabled = ECSConfigService.isECSFeatureEnabled(ECSConfigKey.EnableShiftOverlapConflicts);
    const isShiftTimeoffConflictsEnabled = ECSConfigService.isECSFeatureEnabled(ECSConfigKey.EnableShiftTimeoffConflicts);

    const isAvailabilityEnabled = ECSConfigService.isECSFeatureEnabled(ECSConfigKey.EnableScheduleAvailability)
        && FlightSettingsService.isFlightEnabled(FlightKeys.EnableScheduleAvailability);

    // Maximum length of a shift (working/absence) that we will calculate conflicts on (shifts longer than this are ignored).
    const maxConflictCount: number = ECSConfigService.getECSFeatureSetting(ECSConfigKey.ConflictCalcMaxConflictCount);

    const availabilities = actionMessage.availabilities;

    // go through each member and map of dateIntervals
    runInAction(() => {
        memberIdToShiftsByDate.forEach((dateIntervalMaps: ShiftsByDate, memberId: string) => {

                try {
                // iterate over each dateinterval recorded for this member, to look up shifts in the view start date and end date
                const dateIntervalIterator = viewStartDatetime.clone();

                // iterate over all the days in the date range for this member, do not block ui while calculating conflicts
                while (dateIntervalIterator.isSameOrBefore(viewEndDatetime)) {
                    const timestampIterator = DateUtils.getStartOfDay(dateIntervalIterator).valueOf();
                    let shifts: IShiftEntity[] = dateIntervalMaps.shiftsByDateMap && dateIntervalMaps.shiftsByDateMap.get(timestampIterator);
                    if (shifts && shifts.length > 0) {
                        // iterate over shifts in that date interval
                        for (const shift of shifts) {
                            // check whether the shift is active
                            if (shift.state === ShiftStates.Active) {
                                /**
                                 * calculate shift availability conflicts
                                 * check if this is a working shift, and not a time off shift. It doesn't matter if timeoff conflicts with availability
                                 * optmize to look for shift-availability conflicts only in the future.
                                */
                                if (isAvailabilityEnabled && isShiftAvailabilityConflictsEnabled && ShiftUtils.isWorkingShift(shift) && availabilities) {
                                    calculateShiftAvailabilityConflict(memberId, shift, false /* isRequestShift */, availabilities /** avaialabilities */);
                                }

                                if (isShiftOverlapConflictsEnabled || isShiftTimeoffConflictsEnabled) {
                                    calculateShiftConflicts(shift, shifts, false /* isRequestShift */);
                                }

                                if (ConflictStore().conflictCount.totalConflictCount >= maxConflictCount) {
                                    // Break out of the shifts loop
                                    setConflictCountExceededLimits(true);
                                    break;
                                }
                            }
                        }
                    }

                    if (ConflictStore().conflictCount.totalConflictCount >= maxConflictCount) {
                        // Break out of the dateIntervalIterator loop
                        setConflictCountExceededLimits(true);
                        break;
                    }

                    // go to next day
                    dateIntervalIterator.add(1, "day");
                }
            } catch (exception) {
                trace.error("There was an exception while calculating conflicts:" + exception);
            }
        });
        // once we are finished with all the calculations, call the refreshConflictsCallback and end the perf marker
        if (actionMessage.refreshConflictsCallback) {
            actionMessage.refreshConflictsCallback();
        }
        InstrumentationService.perfMarkerEnd(marker);
    });

});