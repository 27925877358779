import * as React from "react";
import PageStepIndicatorText from "sh-application/components/common/PageStepIndicatorText";
import StringsStore from "sh-strings/store";
import { DefaultButton, PrimaryButton } from "@fluentui/react";

const styles = require("./SetupNavigation.scss");

export interface SetupNavigationProps {
    /** (optional) Callback that is invoked when the user clicks cancel button */
    onCancel?: () => void;

    /** (optional) Callback that is invoked when the user clicks ok button */
    onOk?: () => void;

    /** (optional) Custom label to use on the cancel button in the footer - default is Back */
    cancelButtonLabel?: string;

    /** (optional) Custom label to use on the ok button in the footer - default is Next */
    okButtonLabel?: string;

    /** (optional) If true, the ok button is disabled */
    disableOk?: boolean;

    /** (optional) If true, the cancel button is disabled */
    disableCancel?: boolean;

    /** (optional) If true, the step indicator is shown */
    showStepIndicator?: boolean;

    /** Number of steps to show in the step indicator */
    stepIndicatorSize?: number;

    /** Current step index in the step indicator */
    currentStepIndex?: number;

    /** Image to be shown next to the primary button */
    primaryButtonImage?: string;
}

/**
 * React component for the navigation UX in the team setup workflow
 */
export class SetupNavigation extends React.Component<SetupNavigationProps, {}> {
    private _strings: Map<string, string>;

    constructor(props: SetupNavigationProps) {
        super(props);
        this._strings = StringsStore().registeredStringModules.get("teamSetupPicker").strings;
    }

    private handleOkPressed = () => {
        if (this.props.onOk) {
            this.props.onOk();
        }
    }

    private handleCancelPressed = () => {
        if (this.props.onCancel) {
            this.props.onCancel();
        }
    }

    /**
     * Renders team setup - name team page
     */
    render() {
        const { onCancel, cancelButtonLabel, disableCancel, onOk, okButtonLabel, disableOk, showStepIndicator, currentStepIndex, stepIndicatorSize, primaryButtonImage } = this.props;

        return (
            <div className={ styles.teamSetupNavigation }>
                <div className={ styles.stepIndicator }>
                    {
                        showStepIndicator &&
                            <PageStepIndicatorText currentStepIndex={ currentStepIndex } stepIndicatorSize={ stepIndicatorSize } />
                    }
                </div>
                <div className={ styles.navButtons }>
                    {
                        onCancel &&
                            <DefaultButton
                                className={ styles.defaultButton }
                                disabled={ disableCancel }
                                onClick={ this.handleCancelPressed } >
                                    { cancelButtonLabel || this._strings.get("backButtonLabel") }
                            </DefaultButton>
                    }
                    {
                        primaryButtonImage &&
                            <span className={ styles.buttonImageContainer }>
                                <img className={ styles.buttonImage } src={ primaryButtonImage }></img>
                            </span>
                    }
                    {
                        onOk &&
                            <PrimaryButton
                                disabled={ disableOk }
                                onClick={ this.handleOkPressed } >
                                    { okButtonLabel || this._strings.get("nextButtonLabel") }
                            </PrimaryButton>
                    }
                </div>
            </div>
        );
    }
}