import { mutatorAction } from "satcheljs";
import { SchedulesViewStateStore } from "sh-application/components/schedules/lib/store/schema/SchedulesViewStateStore";

/**
 * Sets the show date in header boolean
 */
export default mutatorAction("SET_SHOW_DATE_IN_HEADER",
    function setShowDateInHeader(schedulesViewStateStore: SchedulesViewStateStore, showDateInHeader: boolean) {
        schedulesViewStateStore.showDateInHeader = showDateInHeader;
    }
);