import setAppSettingsForTeam from "../actions/setAppSettingsForTeam";
import { AppSettingsStore } from "sh-appsettings-store";
import { mutator } from "satcheljs";

/**
 * Update the Team AppSettings in the store
 */
export default mutator(setAppSettingsForTeam, ({ appSettingsClientModel }) => {
    AppSettingsStore().teamSettings = appSettingsClientModel;
});
