import * as moment from "moment";
import { DataProvider } from "sh-services/dataproviders/DataProvider";
import { ISessionClientData } from "sh-models";
import { IUserDatabase } from "sh-services/data/IUserDatabase";

/**
 * Session Client Data Provider
 */
export class SessionClientDataProvider extends DataProvider<ISessionClientData> {

    protected userDatabase: IUserDatabase;
    protected tenantId: string;
    protected userId: string;
    protected sessionId: string;
    // An in memory cache of SessionClientData
    private static sessionClientDataInMemoryCache: ISessionClientData;

    constructor(userDatabase: IUserDatabase, tenantId: string, userId: string, sessionId: string) {
        super();
        this.userDatabase = userDatabase;
        this.tenantId = tenantId;
        this.userId = userId;
        this.sessionId = sessionId;
    }

    /**
     * Return data if it's found in memory (otherwise return undefined)
     */
    async getDataFromMemory() {
        return SessionClientDataProvider.sessionClientDataInMemoryCache;
    }

    /**
     * Return data if it's found in the database (otherwise return undefined)
     */
    async getDataFromDatabase() {
        return this.userDatabase.getSessionClientData(this.sessionId);
    }

    /**
     * Make an HTTP request to fetch the data from the network
     */
    async getDataFromNetwork(): Promise<ISessionClientData> {
        // Initialize a new in memory cache and return it
        return {
            sessionId: this.sessionId,
            deviceHeader: null,
            syncKey: null,
            lastUpdateTimestamp: moment().valueOf(),
            isTeamsListCached: false
        };
    }

    /**
     * Set data in memory
     */
    async setDataInMemory(data: ISessionClientData) {
        SessionClientDataProvider.sessionClientDataInMemoryCache = data;
    }

    /**
     * Set data in the database
     */
    async setDataInDatabase(data: ISessionClientData) {
        return await this.userDatabase.setSessionClientData(data);
    }

    /**
     * Make a network call to update the data
     */
    async saveDataToNetwork(data: ISessionClientData): Promise<ISessionClientData> {
        throw new Error("SessionClientDataProvider.saveDataToNetwork not implemented");
    }

    /**
     * Whether to skip refreshing data from network if it was only in the database and not in memory
     * This method gets called when we don't find the data in memory, and find something in the database.
     * Because we can't always trust that the data from the database was in-sync and not missing changes from the service,
     * this method allows the provider to inspect the state of data from the db and
     * determine if we need to make a network call to refresh this data asynchronously.
     */
    async skipRefreshFromNetworkIfNotInMemory(data: ISessionClientData): Promise<boolean> {
        // Don't refresh if this data is found in the db
        return true;
    }
}