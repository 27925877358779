import { AppDb } from "sh-services/data/AppDb";
import {
    ILocalClientData,
    ILocateUserResponseEntity,
    ISessionClientData,
    IUserEntity,
    IUserPolicySettingsEntity,
    IUserSettingsEntity,
    UserPolicySettingsEntity
    } from "sh-models";
import { InstrumentationService } from "sh-services";
import { IUserDatabase } from "./IUserDatabase";

// The number of LRU Sessions to keep in the database
const LRU_SESSIONS_TO_KEEP = 5;

/**
 * User Database Accessor
 */
export class UserDatabase implements IUserDatabase {
    private db: AppDb;

    constructor(db: AppDb) {
        this.db = db;
    }

    /**
     * Fetch the user's local client data
     */
    public async getLocalClientData(userId: string): Promise<ILocalClientData> {
        if (!this.db) {
            return null;
        }

        try {
            return await this.db.localClientData.get(userId);
        } catch (error) {
            InstrumentationService.trackException(error, "getLocalClientDataFromDb");
        }
        return null;
    }

    /**
     * Set a user's LocalClientData in Database
     * @param localClientData User Local Client Data
     */
    public async setLocalClientData(localClientData: ILocalClientData): Promise<void> {
        if (!this.db) {
            return;
        }
        try {
            await this.db.localClientData.put(localClientData);
        } catch (error) {
            InstrumentationService.trackException(error, "setLocalClientDataInDb");
        }
    }

    /**
     * Fetch the user's session client data
     */
    public async getSessionClientData(sessionId: string): Promise<ISessionClientData> {
        if (!this.db) {
            return null;
        }

        try {
            return await this.db.sessionClientData.get(sessionId);
        } catch (error) {
            InstrumentationService.trackException(error, "getSessionClientDataFromDb");
        }
        return null;
    }

    /**
     * Set a user's SessionClientData in Database
     * @param sessionClientData User Session Client Data
     */
    public async setSessionClientData(sessionClientData: ISessionClientData): Promise<void> {
        if (!this.db) {
            return;
        }
        try {
            await this.db.sessionClientData.put(sessionClientData);
        } catch (error) {
            InstrumentationService.trackException(error, "setSessionClientDataInDb");
        }
    }

    /**
     * Returns a list of old sessionIds that need to be deleted
     */
    public async getOldSessionIdsToDelete(): Promise<string[]> {
        if (!this.db) {
            return;
        }
        try {
            return await this.db.sessionClientData.orderBy('lastUpdateTimestamp')
                .reverse()
                .offset(LRU_SESSIONS_TO_KEEP)
                .primaryKeys();
        } catch (error) {
            InstrumentationService.trackException(error, "getOldSessionIdsFromDb");
        }
    }

    /**
     * Delete the sessions
     * @param sessionIds List of sessionIds to delete
     */
    public async deleteSessionData(sessionIds: string[]) {
        if (!this.db || !sessionIds || !sessionIds.length) {
            return;
        }
        try {
            for (const sessionId of sessionIds) {
                await this.db.sessionClientData.where({sessionId: sessionId}).delete();
            }
        } catch (error) {
            InstrumentationService.trackException(error, "deleteSessionDataFromDb");
        }
    }

    /**
     * Fetch user settings
     */
    public async getUserSettings(userId: string): Promise<IUserSettingsEntity> {
        if (!this.db) {
            return null;
        }
        try {
            return await this.db.userSettings.get(userId);
        } catch (error) {
            InstrumentationService.trackException(error, "getUserSettingsFromDb");
            return null;
        }
    }

    /**
     * Fetch user policy settings
     */
    public async getUserPolicySettings(userId: string): Promise<IUserPolicySettingsEntity> {
        if (!this.db) {
            return null;
        }

        try {
            return await this.db.userPolicySettings.get(userId);
        } catch (error) {
            InstrumentationService.trackException(error, "getUserPolicySettingsFromDb");
            return null;
        }
    }

    /**
     * Set UserSettings in Database
     * @param userSettings User Settings
     */
    public async setUserSettings(userSettings: IUserSettingsEntity): Promise<void> {
        if (!this.db) {
            return;
        }
        try {
            await this.db.userSettings.put(userSettings);
        } catch (error) {
            InstrumentationService.trackException(error, "setUserSettingsInDb");
        }
    }

    /**
     * Set user policy settings
     */
    public async setUserPolicySettings(userSettings: UserPolicySettingsEntity): Promise<void> {
        if (!this.db) {
            return null;
        }

        try {
            await this.db.userPolicySettings.put(userSettings);
        } catch (error) {
            InstrumentationService.trackException(error, "setUserPolicySettingsInDb");
        }
    }

    /**
     * Fetch Locate User Response
     * @param userId User Id
     */
    public async getLocateUserResponse(userId: string): Promise<ILocateUserResponseEntity> {
        if (!this.db) {
            return null;
        }
        try {
            return await this.db.locateUserResponses.get(userId);
        } catch (error) {
            InstrumentationService.trackException(error, "getLocateUserResponse");
            return null;
        }
    }

    /**
     * Set Locate User Response in Database
     * @param locateResponse Locate User Response
     */
    public async setLocateUserResponse(locateResponse: ILocateUserResponseEntity): Promise<void> {
        if (!this.db) {
            return;
        }
        try {
            await this.db.locateUserResponses.put(locateResponse);
        } catch (error) {
            InstrumentationService.trackException(error, "setLocateUserResponse");
        }
    }

    /**
     * Delete LocateUserResponse from database
     * @param userId User Id
     */
    public async deleteLocateUserResponse(userId: string): Promise<void> {
        if (!this.db) {
            return null;
        }

        try {
            await this.db.locateUserResponses.delete(userId);
        } catch (error) {
            InstrumentationService.trackException(error, "deleteLocateUserResponse");
        }
    }

    /**
     * Fetch Logged In User
     */
    public async getLoggedInUser(userId: string): Promise<IUserEntity> {
        if (!this.db) {
            return null;
        }

        try {
            return await this.db.loggedInUsers.get(userId);
        } catch (error) {
            InstrumentationService.trackException(error, "getLoggedInUserFromDb");
        }
        return null;
    }

    /**
     * Set Logged In User in the Database
     * @param user User
     */
    public async setLoggedInUser(user: IUserEntity): Promise<void> {
        if (!this.db) {
            return;
        }

        try {
            // Update user
            await this.db.loggedInUsers.put(user);
        } catch (error) {
            InstrumentationService.trackException(error, "setLoggedInUserInDb");
        }
    }
}