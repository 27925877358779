import deleteTeamInStore from "sh-stores/sh-teaminfo-store/mutatorAction/deleteTeamInStore";
import StringsStore from "sh-strings/store";
import teamDeleted from "sh-stores/sh-teaminfo-store/actions/teamDeleted";
import { orchestrator } from "satcheljs";
import { setBlockingError } from "sh-application/components/errorWatcher";
import { UserStorageKeys } from "sh-models";
import { UserStorageService } from "sh-services";

export default orchestrator( teamDeleted, async actionMessage => {

    // remove recent team ID
    if (UserStorageService.getItem(UserStorageKeys.MostRecentTeamId) == actionMessage.teamId) {
        UserStorageService.removeItem(UserStorageKeys.MostRecentTeamId);

        // Show a message telling the user that the team they're viewing has been deleted and make them refresh the app
        const accessBlockedStrings = StringsStore().registeredStringModules.get("accessBlocked").strings;
        const commonStrings = StringsStore().registeredStringModules.get("common").strings;
        setBlockingError({
            title: "",
            primaryText: accessBlockedStrings.get("teamDeleted"),
            actionButton: {
                useAppRestartAction: true,
                actionButtonText: commonStrings.get("refresh")
            }
        });
    }

    // update the data in the store
    deleteTeamInStore(actionMessage.teamId);
});