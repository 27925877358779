import StringsStore from "sh-strings/store";
import { restartApp } from "sh-application";
import { StaffHubHttpError } from "./StaffHubError";
import { StaffHubErrorCodes } from "sh-services";

/**
 * Enum for possible error codes
 */
export enum ErrorCodes {
    NotFound = "NotFound",
    ConcurrencyConflict = "ConcurrencyConflict",
    RepeatRequest = "RepeatRequest",
    PreConditionFailed = "PreConditionFailed"
}

export enum HttpErrorCodes {
    NotFound = 404,
    ConcurrencyConflict = 409,
    PreConditionFailed = 412
}

/**
 * Utils class for extracting information from StaffHubHttpError
 */
export default class StaffHubHttpErrorUtils {
    /**
     * Check if an error is of type NotFound
     * @param error
     */
    public static isNotFoundError(error: StaffHubHttpError): boolean {
        return error && error.staffHubTopLevelErrorCode && error.staffHubTopLevelErrorCode === ErrorCodes.NotFound;
    }

    /**
     * Check if an error is of type ConcurrencyConflict (etag mismatch)
     * @param error
     */
    public static isConcurrencyConflictError(error: StaffHubHttpError): boolean {
        return error && error.staffHubTopLevelErrorCode && (error.staffHubTopLevelErrorCode === ErrorCodes.ConcurrencyConflict || error.staffHubTopLevelErrorCode === ErrorCodes.RepeatRequest);
    }

    /**
     * Check if an error is of type WorkforceIntegrationError
     * @param error
     */
    public static isWorkforceIntegrationError(error: StaffHubHttpError): boolean {
        return error && error.staffHubTopLevelErrorCode
            && (error.staffHubTopLevelErrorCode === StaffHubErrorCodes.WorkforceIntegrationError
            || error.staffHubTopLevelErrorCode === StaffHubErrorCodes.UnexpectedWorkforceIntegrationResponseError);
    }

    /**
     * Get the error message from a StaffHubHttpError
     * @param error
     * @param fallbackError optional - otherwise a generic error will be used as backup
     */
    public static getErrorMessage(error: StaffHubHttpError, fallbackError?: string): string {
        let errorMessage: string = null;
        if (error) {
            if (error.isNetworkError) {
                errorMessage = StringsStore().registeredStringModules.get("common").strings.get("networkError");
            } else if (error.staffHubTopLevelErrorMessage) {
                errorMessage = error.staffHubTopLevelErrorMessage;
            } else if (error.message) {
                errorMessage = error.message;
            }
        }
        if (!errorMessage) {
            errorMessage = fallbackError ? fallbackError : StringsStore().registeredStringModules.get("common").strings.get("genericError");
        }
        return errorMessage;
    }

    /**
     * Get's the action button title for the "Hero" action when we display this error
     * @param error
     * @param showRefreshAction
     */
    public static getErrorActionButtonTitle(error: StaffHubHttpError, showRefreshAction: boolean): string {
        if (showRefreshAction || StaffHubHttpErrorUtils.isConcurrencyConflictError(error)) {
            return StringsStore().registeredStringModules.get("common").strings.get("refresh");
        }

        return null;
    }

    /**
     * Get's the action button callback for the "Hero" action when we display this error
     * @param error
     */
    static onErrorActionButtonClick(error: StaffHubHttpError, showRefreshAction: boolean): Function {
        if (showRefreshAction || StaffHubHttpErrorUtils.isConcurrencyConflictError(error)) {
            return  () => { restartApp(); };
        }

        return null;
    }
}