import { mutatorAction } from "satcheljs";
import { TeamSetupPickerMode } from "sh-application/components/teamsetuppicker";
import { TeamSetupPickerViewState } from "../schema/TeamSetupPickerViewState";

export default mutatorAction("LAUNCH_TEAM_SETUP_PICKER",
    function launchTeamSetupPicker(
        viewState: TeamSetupPickerViewState,
        isOnboarding: boolean,
        teamSetupPickerMode: TeamSetupPickerMode,
        isUserDismissable: boolean,
        useTeamPickerPanel: boolean) {
        viewState.isOnboarding = isOnboarding;
        viewState.teamSetupPickerMode = teamSetupPickerMode;
        viewState.isUserDismissable = isUserDismissable;

        viewState.isOpen = true;
        viewState.isTeamPickerPanel = useTeamPickerPanel;
    });