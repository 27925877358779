import DateUtils from "sh-application/utility/DateUtils";
import MemberUtils from "sh-application/utility/MemberUtils";
import StringsStore from "sh-strings/store";
import { IMemberEntity } from "sh-models";

export default class AccessibilityUtils {

    /**
     * return an aria label for a row that belongs to a member
     * @param member the member the row is for
     * @param tagGroupName aria name for the group that the member belongs to
     * @param hours number of hours assigned for this member
     */
    public static getMemberRowAriaLabel(member: IMemberEntity, tagGroupName: string, hours: number): string {
        const hoursForDisplay: string = DateUtils.getHoursRoundedForDisplay(hours);
        return StringsStore().registeredStringModules.get("schedulePage").strings.get("memberRowAriaLabelFormat").format(MemberUtils.getDisplayNameForMember(member), tagGroupName, hoursForDisplay);
    }

    /**
     * Returns an aria label for open shift rows.
     * @param tagGroupName
     * @param numOpenShiftsnumOpenShifts
     * @param rowIdx
     * @param totalRows
     */
    public static getOpenShiftRowAriaLabel(tagGroupName: string, numOpenShifts: number, rowIdx: number, totalRows: number) {
        const strings: Map<string, string> = StringsStore().registeredStringModules.get("schedulePage").strings;
        return strings.get("openShiftRowAriaLabel").format(tagGroupName, String(numOpenShifts), String(rowIdx + 1), String(totalRows));
    }
}