import ConflictUtils from "sh-application/utility/ConflictUtils";
import ShiftUtils from "sh-application/utility/ShiftUtils";
import { calculateShiftConflicts, createShiftConflict, DismissedConflictsStore } from "sh-conflict-store";
import { ECSConfigKey, ECSConfigService } from "sh-services";
import { orchestrator } from "satcheljs";
import { ShiftStates } from "sh-models";

/**
 * This orchestrator is calculates shift-overlap and timeoff conflicts
 * It populates the ConflictStore() with the conflict entities,
 * as well as maintains the true conflict count.
 */
export default orchestrator(calculateShiftConflicts, (actionMessage) => {
    const currentShift = actionMessage.currentShift;
    const shiftsInRow = actionMessage.shiftsInRow;
    const isRequestShift = actionMessage.isRequestShift;
    const conflictDismissEnabled = ECSConfigService.isECSFeatureEnabled(ECSConfigKey.EnableConflictsDismissal);

    for (const shift of shiftsInRow) {
        // check if this shift in the shift row is active, and if its not the same shift we are calculating conflicts for
        if (shift.state !== ShiftStates.Active || currentShift.id === shift.id) {
            continue;
        }

        // check if they are conflicting shifts
        const areConflictingShifts = ShiftUtils.areConflictingShifts(currentShift, shift);
        let dismissedConflicts;

        if (conflictDismissEnabled) {
            dismissedConflicts = DismissedConflictsStore().shiftIdToEntityIdMap.get(currentShift.id);
        }
        const alreadyDismissed = conflictDismissEnabled ? ConflictUtils.isAlreadyDismissedShiftConflict(dismissedConflicts, shift.id) : false;
        if (areConflictingShifts && !alreadyDismissed) {
            // create shift entities
            // memberId is needed for shifr-request conflict
            createShiftConflict(currentShift, shift, isRequestShift, actionMessage.memberId);
        }
    }
});