import * as React from "react";
import StringsStore from "sh-strings/store";
import { AriaProperties, AriaRoles, generateDomPropertiesForAria } from "owa-accessibility";
import { PureComponent } from "react";
import { Shimmer, ShimmerElementsGroup, ShimmerElementType } from "@fluentui/react";

const styles = require("./SkeletonGrid.scss");
const ROW_PREFIX: string = "skeleton_row";
const CELL_PREFIX: string = "skeleton_cell";
const MEMBER_CELL_WIDTH: number = 180;
const ROW_CELL_HEIGHT: number = 65;
const MEMBER_OR_HEADER_HEIGHT: number = 40;
const GROUP_ROW_ITEM_HEIGHT: number = 50;
const MEMBER_GROUP_GAP_HEIGHT: number = 10;
const ACTION_CIRCLE_HEIGHT: number = 20;
const ACTION_GAP_WIDTH: number = 25;
const TITLE_HEIGHT: number = 15;
const HEADER_WIDTH: number = 90;
const MEMBER_CELL_GAP_HEIGHT_WIDTH: number = 20;

export interface SkeletonViewGridProps {
    numberofSkeletonCells?: number; // number of skeleton cells to render in skeleton grid, corresponds to the current calendar view, 30/31 for month, 7 for week etc.
}

// we need to add this for member cell as the shimmer color is not possible to override from css, fabric lets us override colors this way.
const app_gray_14 = "#FAF9F8"; // NOTE: this is $app-gray-14 of our gray pallette

const classNames = require("classnames/bind");

/**
 * This renders a view of a single skeleton grid group,
 * this is the prototype: https://app.abstract.com/projects/47e5cd80-443c-11e9-9d36-71703567c72b/branches/master/commits/baf4fa4ea8f736fa6d80c750f5827d41a05b05b4/files/2c1f0ceb-92da-4505-84c3-2d6df54ec719/layers/16DE04F1-BC0B-4A59-98B6-39AD6BECC56C?collectionId=86a3b05e-afdb-4950-96a1-e4a0abf3894d&collectionLayerId=b57482d5-7f96-4c60-86a9-e7aa832cb88a
 */
export default class SkeletonGrid extends PureComponent<SkeletonViewGridProps, {}> {
    private _strings: Map<string, string>;

    constructor(props: SkeletonViewGridProps) {
        super(props);
        this._strings = StringsStore().registeredStringModules.get("common").strings;
    }
    private generateRows(key: number) {
        let skeletonCells: JSX.Element[] = [];

        // add number of skelton cells
        for (let i = 0; i < this.props.numberofSkeletonCells; i++) {
            skeletonCells.push(this.generateSkeletonCell(i, key));
        }

        return (
            <div className={styles.skeletonRow} key={ROW_PREFIX + key}>
                <div className={styles.memberCell}>
                    <Shimmer
                        shimmerColors={{ background: app_gray_14 }}
                        customElementsGroup={this.generateMemberCell()}
                        width={MEMBER_CELL_WIDTH}
                        height={ROW_CELL_HEIGHT}
                    />
                </div>
                {skeletonCells}
            </div>
        );
    }

    private generateSkeletonCell(key: number, rowIndex: number) {
        return (
            <div className={classNames(styles.skeletonCell, { [`${styles.borderTop}`]: rowIndex === 0 })} key={CELL_PREFIX + key}>
                <div className={styles.cell}>
                    <Shimmer shimmerElements={[{ type: ShimmerElementType.line, height: ROW_CELL_HEIGHT, width: "100%" }]} />
                </div>
            </div>
        );
    }

    private generateMemberCell() {
        return (
            <div className={styles.member}>
                <ShimmerElementsGroup
                    backgroundColor={app_gray_14}
                    shimmerElements={[
                        { type: ShimmerElementType.gap, width: MEMBER_CELL_GAP_HEIGHT_WIDTH, height: MEMBER_OR_HEADER_HEIGHT },
                        { type: ShimmerElementType.circle, height: MEMBER_OR_HEADER_HEIGHT },
                        { type: ShimmerElementType.gap, width: 10, height: MEMBER_OR_HEADER_HEIGHT }
                    ]}
                />
                <ShimmerElementsGroup
                    flexWrap={true}
                    width="100%"
                    backgroundColor={app_gray_14}
                    shimmerElements={[
                        { type: ShimmerElementType.line, width: "65%", height: 10 },
                        { type: ShimmerElementType.gap, width: "35%", height: 15 },
                        { type: ShimmerElementType.line, width: "40%", height: 5, verticalAlign: "top" },
                        { type: ShimmerElementType.gap, width: "60%", height: MEMBER_CELL_GAP_HEIGHT_WIDTH, verticalAlign: "top" }
                    ]}
                />
            </div>
        );
    }

    private generateHeaderCell() {
        return (
            <div className={styles.header}>
                <div className={styles.headerCell}>
                    <Shimmer
                        shimmerElements={[{ type: ShimmerElementType.line, height: TITLE_HEIGHT, width: "100%", verticalAlign: "center" }]}
                        width={HEADER_WIDTH}
                        height={MEMBER_OR_HEADER_HEIGHT}
                    />
                </div>
            </div>
        );
    }

    private addRows(numTimes: Number): JSX.Element[] {
        let items = [];
        for (let i = 0; i < numTimes; i++) {
            items.push(this.generateRows(i));
        }
        return items;
    }

    private generateOpenShiftRow() {
        return <div className={styles.openShiftRow}>{this.addRows(1)}</div>;
    }

    private generateGroupRow() {
        return (
            <div className={styles.headerRow}>
                {this.generateHeaderCell()}
                <div className={styles.groupRow}>
                    <div className={styles.group}>
                        <Shimmer
                            shimmerElements={[
                                { type: ShimmerElementType.gap, width: "1%", height: GROUP_ROW_ITEM_HEIGHT },
                                { type: ShimmerElementType.line, width: "20%", height: TITLE_HEIGHT },
                                { type: ShimmerElementType.gap, width: "84%", height: GROUP_ROW_ITEM_HEIGHT }
                            ]}
                            height={GROUP_ROW_ITEM_HEIGHT}
                        />
                    </div>
                    <div className={styles.actions}>
                        <Shimmer
                            shimmerElements={[
                                { type: ShimmerElementType.circle, height: ACTION_CIRCLE_HEIGHT },
                                { type: ShimmerElementType.gap, width: ACTION_GAP_WIDTH, height: MEMBER_GROUP_GAP_HEIGHT },
                                { type: ShimmerElementType.circle, height: ACTION_CIRCLE_HEIGHT },
                                { type: ShimmerElementType.gap, width: ACTION_GAP_WIDTH, height: MEMBER_GROUP_GAP_HEIGHT },
                                { type: ShimmerElementType.circle, height: ACTION_CIRCLE_HEIGHT }
                            ]}
                            height={GROUP_ROW_ITEM_HEIGHT}
                        />
                    </div>
                </div>
            </div>
        );
    }

    render() {
        // always just have 5 rows,  as a default view
        let shiftRows = this.addRows(5);

        let ariaProps: AriaProperties = {
            role: AriaRoles.alert,
            label: this._strings.get("loadingScheduleAccessibilityAlert")
        };

        return (
            <div className={styles.skeletonGrid} {...generateDomPropertiesForAria(ariaProps)}>
                {this.generateGroupRow() /** the group header row */}
                {this.generateOpenShiftRow() /** the openshift row */}
                <div className={styles.headerRow}>{this.generateHeaderCell()}</div>
                {shiftRows /** the shift row */}
            </div>
        );
    }
}
