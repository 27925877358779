export enum RecurrencePatternTypes {
    Weekly = "Weekly"
}

/**
 * Recurrence range types.
 * Currently only supports EndDate, but is included to match Graph API.
 */
export enum RecurrenceRangeTypes {
    /**
     * This supports date range scenario which requires 'startDate' and 'endDate' properties to be set and valid.
     */
    EndDate = "EndDate"
}

export enum RecurringValues {
    sunday = "sunday",
    monday = "monday",
    tuesday = "tuesday",
    wednesday = "wednesday",
    thursday = "thursday",
    friday = "friday",
    saturday = "saturday"
}

/**
 * Recurrence pattern interface.
 * Used for recurring weekday availability.
 */
export interface IRecurrencePattern {
    interval: number;                   // unused property.  Will be used to set recurrence frequency
    recurringValues: RecurringValues[]; // day of week that this availability applies to
    type: RecurrencePatternTypes;       // always set to Weekly
}

/**
 * Recurrence range interface.
 * Used for specific date availability.
 */
export interface IRecurrenceRange {
    /**
     * End date for the recurrence range.
     * @example "2017-12-31T00:00:00.000Z"
     * TODO newAvailability: consider using timestamp (type = number) instead of string
     * TODO newAvailability: add validation to ensure endDate is after startDate
     */
    endDate: string;

    /**
     * Recurrence range type.
     * @example RecurrenceRangeTypes.EndDate
     */
    recurrenceType: RecurrenceRangeTypes;

    /**
     * Start date for the recurrence range.
     * @example "2017-12-31T00:00:00.000Z"
     */
    startDate: string;
}

export interface ITimeSlot {
    startTime: ITime;
    endTime: ITime;
}

export interface ITime {
    hour: number;    // whole number between 0 and 24 representing the hour of the day
    minute: number;  // whole number between 0 and 60 representing the minute of the hour
}

export interface IAvailabilityEntity {
    /**
     * Whether the availability is for the whole day or not
     */
    allDay: boolean;

    /**
     * Unique identifier for the availability entity
     */
    id: string;

    /**
     * Time slots that the user is available for. Only used with recurrencePattern.
     * If not allDay, these are the time slots that the user is available for.
    */
    timeSlots: ITimeSlot[];

    /**
     * Time zone that the availability is in.
     */
    timeZoneOlsonCode: string;

    /**
     * Recurrence pattern for the availability. This is used for recurring weekday availability.
     */
    recurrencePattern?: IRecurrencePattern;  // Which days does this availability entity apply to

    /**
     * Recurrence range for the availability. This is used for specific date availability.
     */
    recurrenceRange?: IRecurrenceRange;
}

export interface IMemberAvailabilityForDisplayEntity {
    memberAvailabilitiesDisplayByDate: string[];
    ariaLabel: string;
}