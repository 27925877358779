import * as React from "react";
import Shift from "../shift/Shift";
import ShiftThemeContainer from "../shift/ShiftThemeContainer";
import ShiftUtils from "../../utility/ShiftUtils";
import StringsStore from "sh-strings/store";
import { AriaProperties, generateDomPropertiesForAria } from "owa-accessibility";
import { ECSConfigKey, ECSConfigService } from "sh-services";
import { IconButton } from "@fluentui/react";
import { IShiftEntity, IUniqueShiftEntity } from "sh-models";
import { ScheduleCellRenderSize } from "sh-application/../StaffHubConstants";

const styles = require("./UniqueShift.scss");

interface UniqueShiftProps {
    uniqueShift: IUniqueShiftEntity;
    entryIndex: number;
    scheduleCellRenderSize?: ScheduleCellRenderSize;
    onSelectUniqueShiftCallback: (uniqueShift: IUniqueShiftEntity, uniqueShiftEntryIndex: number) => void;
    onRemoveShiftCallback: (uniqueShift: IUniqueShiftEntity, uniqueShiftEntryIndex: number) => void;
    ariaProps?: AriaProperties;
}

/**
 * UniqueShift Component for the shiftcontextualmenu
 */
export default class UniqueShift extends React.Component<UniqueShiftProps, {}> {
    private _shift: IShiftEntity;
    private _shiftsStrings: Map<string, string>;
    // TODO robv - I had to move this here because when I started using code splitting in the DEV build, ScheduleCellRenderSize
    // doesn't get loaded before this and we get a dynamic page load error. Need to figure this out.
    private DEFAULT_SCHEDULE_CELL_RENDER_SIZE = ScheduleCellRenderSize.Normal;

    constructor(props: UniqueShiftProps) {
        super(props);

        this._shiftsStrings = StringsStore().registeredStringModules.get("schedulePage").strings;
    }

    /**
     * Callback fired when the delete unique shift button is clicked on
     * @param e
     */
    private onDeleteUniqueShiftClick = (e: React.MouseEvent<any>) => {
        this.props.onRemoveShiftCallback(this.props.uniqueShift, this.props.entryIndex);
        e.stopPropagation();
    }

    /**
     * Apply the specified unique shift
     */
    private selectUniqueShift(): void {
        this.props.onSelectUniqueShiftCallback(this.props.uniqueShift, this.props.entryIndex);
    }

    private onClick = (e: React.MouseEvent<HTMLDivElement>) => {
        this.selectUniqueShift();
    }

    render() {
        const { uniqueShift } = this.props;
        const is24ShiftEnabled = ECSConfigService.isECSFeatureEnabled(ECSConfigKey.Enable24hShift);

        let { scheduleCellRenderSize } = this.props;
        this._shift = ShiftUtils.getShiftFromUniqueShift(uniqueShift);
        if (!scheduleCellRenderSize) {
            scheduleCellRenderSize = this.DEFAULT_SCHEDULE_CELL_RENDER_SIZE;
        }

        return (
            <div className={ styles.uniqueShiftContainer }>
                <div id={ this.props.uniqueShift.id }
                    className={ styles.uniqueShiftDiv }
                    onClick={ this.onClick }
                    tabIndex={ 0 }
                    data-is-focusable="true" /* causes pressing of enter key to trigger keydown event */
                    {...generateDomPropertiesForAria(this.props.ariaProps) } >
                    <ShiftThemeContainer theme={ this._shift.theme }>
                        <Shift shift={ this._shift }
                            showUnsharedStatus={ false }
                            scheduleCellRenderSize={ scheduleCellRenderSize }
                            doShowIcons={ false }
                            doShowNotes={ true }
                            doShow24HrShift={ is24ShiftEnabled }
                            showGroupName={ false }
                            isUniqueShift={ true }/>
                    </ShiftThemeContainer>
                </div>
                <IconButton
                    iconProps={ { iconName: "Cancel" } }
                    className={ styles.deleteUniqueShift }
                    title={ this._shiftsStrings.get("removeShiftSuggestion") }
                    ariaLabel={ this._shiftsStrings.get("removeShiftSuggestion") }
                    onClick={ this.onDeleteUniqueShiftClick }/>
            </div>
        );
    }
}