import { mutatorAction } from "satcheljs";
import { SchedulesViewStateStore } from "sh-application/components/schedules/lib/store/schema/SchedulesViewStateStore";

/**
 * Function that updates the row key for the member who is being reordered
 * @param viewState - Schedule view state
 * @param rowKeyForMemberInReorderMode - row key for the row that is being moved
 */
export default mutatorAction("SET_ROW_KEY_FOR_MEMBER_IN_REORDER_MODE",
    function setRowKeyForMemberInReorderMode(viewState: SchedulesViewStateStore, rowKeyForMemberInReorderMode: string) {
        viewState.rowKeyForMemberInReorderMode = rowKeyForMemberInReorderMode;
    }
);