import * as React from "react";
import ShiftDetails, { ShiftDetailsProps } from "sh-application/components/shift/shiftDetails/ShiftDetails";
import shiftDetailsStore from "./lib/store";
import { DirectionalHint, FocusTrapCallout } from "@fluentui/react";
import { observer } from "mobx-react";

const styles = require("./ShiftDetailsCallout.scss");
const classNames = require("classnames/bind");

export interface ShiftDetailsCalloutProps {

    /* Callback function when the callout is dismissed. Hide the callout in this function. */
    onDismissCallback: VoidFunction;

    shiftDetailsProps: ShiftDetailsProps;

    /* target element for the callout */
    targetElement: HTMLElement;

    /* optional - className to use for the callout*/
    className?: string;

    /* optional - if true, callout will set focus on first focusable element within the callout once it mounts */
    setInitialFocus?: boolean;

    /* optional- positions the callout. Defaults to right center */
    directionalHint?: DirectionalHint;
}

/**
 * ShiftDetailsCallout - Callout that wraps a ShiftDetails component
 */
@observer
export default class ShiftDetailsCallout extends React.Component<ShiftDetailsCalloutProps, any> {
    render() {
        const { onDismissCallback, targetElement, className, directionalHint, setInitialFocus, shiftDetailsProps } = this.props;

        return (
            <FocusTrapCallout
                role={ "dialog" }
                focusTrapProps={ {
                    isClickableOutsideFocusTrap: true,
                    elementToFocusOnDismiss: targetElement
                } }
                onDismiss={ onDismissCallback }
                doNotLayer={ true }
                setInitialFocus={ setInitialFocus }
                preventDismissOnScroll={ true }
                target={ targetElement }
                className={ classNames(className, styles.shiftDetailsCallOut) }
                directionalHint= { directionalHint || DirectionalHint.rightTopEdge }>
                <ShiftDetails
                    { ...shiftDetailsProps }
                    isSaving={ shiftDetailsStore.isSaving }
                    onFinishRequesting={ onDismissCallback }/>
            </FocusTrapCallout>
        );
    }
}