import { addRenderedComponent } from "../schedules/lib/mutators/addRenderedComponent";
import { ScheduleComponents } from "../schedules/lib/store/ScheduleComponents";

import { FilterableCommandBarProps } from "./FilterableCommandBar";

/**
 * Gets extra properties for the 'FilterableCommandBar' component.
 * @returns The extra properties for the 'FilterableCommandBar' component.
 */
export const getFilterableCommandBarExtraProps = (): Partial<FilterableCommandBarProps> => {

    const onDidRender = (): void => {
        addRenderedComponent(ScheduleComponents.HeaderGlobalCommandBar);
    };

    return { onDidRender };
};
