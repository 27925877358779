import TeamStore from "../store";
import { action } from "satcheljs/lib/legacy";

/**
 * Sets the enableLastNameInitial property of the team
 */
export let setEnableLastNameInitial = action("setEnableLastNameInitial")(

    function setEnableLastNameInitial(enable: boolean) {
        TeamStore().team.enableLastNameInitial = enable;
    });