import { DataProvider } from "sh-services/dataproviders/DataProvider";
import { IUserDatabase } from "sh-services/data/IUserDatabase";
import { IUserEntity } from "sh-models";
import { setUser, UserStore } from "sh-stores";

/**
 * Logged In User Data Provider
 */
export class LoggedInUserDataProvider extends DataProvider<IUserEntity> {

    protected userDatabase: IUserDatabase;
    protected tenantId: string;
    protected userId: string;

    constructor(userDatabase: IUserDatabase, tenantId: string, userId: string) {
        super();
        this.userDatabase = userDatabase;
        this.tenantId = tenantId;
        this.userId = userId;
    }

    /**
     * Return data if it's found in memory (otherwise return undefined)
     */
    async getDataFromMemory() {
        return (UserStore().user && UserStore().user.id == this.userId ? UserStore().user : undefined);
    }

    /**
     * Return data if it's found in the database (otherwise return undefined)
     */
    async getDataFromDatabase() {
        return await this.userDatabase.getLoggedInUser(this.userId);
    }

    /**
     * Make an HTTP request to fetch the data from the network
     */
    async getDataFromNetwork(): Promise<IUserEntity> {
        // We assume this will be set on login
        throw new Error("UserDataProvider.getDataFromNetwork not implemented");
    }

    /**
     * Set data in memory
     */
    async setDataInMemory(data: IUserEntity) {
        setUser(data);
    }

    /**
     * Set data in the database
     */
    async setDataInDatabase(data: IUserEntity) {
        return await this.userDatabase.setLoggedInUser(data);
    }

    /**
     * Make a network call to update the data
     */
    async saveDataToNetwork(data: IUserEntity): Promise<IUserEntity> {
        throw new Error("UserDataProvider.saveDataToNetwork not implemented");
    }

    /**
     * Whether to skip refreshing data from network if it was only in the database and not in memory
     * This method gets called when we don't find the data in memory, and find something in the database.
     * Because we can't always trust that the data from the database was in-sync and not missing changes from the service,
     * this method allows the provider to inspect the state of data from the db and
     * determine if we need to make a network call to refresh this data asynchronously.
     */
    async skipRefreshFromNetworkIfNotInMemory(data: IUserEntity): Promise<boolean> {
        // Don't refresh if this data is found in the db
        return true;
    }
}