import ConflictUtils from "sh-application/utility/ConflictUtils";
import ShiftUtils from "sh-application/utility/ShiftUtils";
import {
    addShiftInMemoryDateStore,
    addShiftsToStoreMap,
    setShiftIdToMemberDateIdsMap,
    ShiftMemberDateInfo
    } from "sh-conflict-store";
import { IShiftEntity } from "sh-models";
import { orchestrator } from "satcheljs";

/**
 * When updates to shifts are triggered via the UI or via sync notifications, this orchestrator will be triggered.
 * It will parse the added shifts and add them to ShiftsByMemberAndDateStore maps consequently
 */
export default orchestrator(addShiftsToStoreMap, actionMessage => {
    const shiftAdded: IShiftEntity = actionMessage.shift;
        // add shifts to the shiftsbyMemberAndDateStore, only if its an active shift

    if (ShiftUtils.isActiveShift(shiftAdded)) {
        // get ShiftMemberDateInfo generated for this shift
        const shiftMemberDateInfo: ShiftMemberDateInfo = ConflictUtils.generateShiftMemberDateInfo(shiftAdded);
        // add shift in memberDateIdToShiftIdMap
        addShiftInMemoryDateStore(shiftAdded, shiftMemberDateInfo);

        // add shift reference in shiftIdToMemberDateIdsMap
        setShiftIdToMemberDateIdsMap(shiftAdded, shiftMemberDateInfo);
    }
});