import { ConflictType, ModelUtils } from "..";
import { IBaseConflictEntity } from "sh-models";

const ConflictIdPrefix = "CF_";

export default class BaseConflictEntity implements IBaseConflictEntity {
    // unique conflict Id
    id: string;

    // the memberId associated to the conflict
    memberId: string;

    // the shiftId associated with the conflict
    shiftId: string;

    // the type of conflict, this could be overlapping shift conflict, or shift availability conflict
    conflictType: ConflictType;

    // Flag that tells you if the conflict is dismisses by the user
    isDismissed: boolean;

    constructor(
        memberId: string,
        shiftId: string,
        conflictType: ConflictType,
        isDismissed: boolean
    ) {
        this.id = BaseConflictEntity.generateNewConflictId();
        this.memberId = memberId;
        this.shiftId = shiftId;
        this.conflictType = conflictType;
        this.isDismissed = isDismissed;
    }

    /**
     * Generate new conflictDismissId used by StaffHub App
     * @returns {string} conflictDismissId in form of CD_<uuid>
     */
    public static generateNewConflictId(): string {
        return ModelUtils.generateUUIDWithPrefix(ConflictIdPrefix);
    }
}