import { ConflictDismissDataService } from "sh-services";
import {
    dismissConflict,
    removeConflictFromConflictStore,
    setDismissConflicts
    } from "sh-conflict-store";
import { ConflictType, IConflictDismissEntity } from "sh-models";
import { DismissEntity, DismissType, IDismissEntity } from "sh-models";
import { orchestrator } from "satcheljs";
import { TeamStore } from "sh-team-store";
import { trace } from "owa-trace";
import addConflictIdToDismissIdMap from '../mutatorActions/addConflictIdToDismissIdMap';

/**
 * When updates to shifts are triggered via the UI or via sync notifications, this orchestrator will be triggered.
 * It will parse the deletes shifts and delete them in ShiftsByMemberAndDateStore maps, this also takes care of the conflict store
 */
export default orchestrator(dismissConflict, async actionMessage => {
    const conflictingShiftId: string = actionMessage.conflictingShift;
    const conflictType: ConflictType = actionMessage.conflictType;
    const memberId: string = actionMessage.memberId;
    const otherConflictingShiftId: string = actionMessage.otherConflictingShift;
    const conflictingAvailabilityId: string = actionMessage.conflictingAvailability;
    const conflictId: string = actionMessage.conflictId;

    // delete any conflicts associated to this shift in ConflictStore
    removeConflictFromConflictStore(conflictingShiftId, conflictType, memberId, otherConflictingShiftId, conflictingAvailabilityId);

    try {
        const entityOne: IDismissEntity = new DismissEntity(conflictingShiftId, DismissType.Shift);
        let entityTwo: IDismissEntity;

        if (conflictType === ConflictType.ShiftAvailabilityConflict) {
            entityTwo = new DismissEntity(conflictingAvailabilityId, DismissType.Availability);
        } else {
            entityTwo = new DismissEntity(otherConflictingShiftId, DismissType.Shift);
        }
        // make a request to the service to store this dismiss entity
        const conflictDismissEntity: IConflictDismissEntity = await ConflictDismissDataService.createDismissEntity(TeamStore().teamId, entityOne, entityTwo);

        // update dismiss conflict store
        setDismissConflicts([conflictDismissEntity]);
        addConflictIdToDismissIdMap(conflictId, conflictDismissEntity.id);
    } catch (exception) {
        trace.error("There was an exception while dismissing conflicts:" + exception);
    }
});