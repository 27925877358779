import { mutatorAction } from "satcheljs";
import { SchedulesViewStateStore } from "../store/schema/SchedulesViewStateStore";

/**
 * Sets the isViewStatePopulated flag on the viewState
 */
export default mutatorAction("SET_IS_VIEW_STATE_POPULATED",
    function setIsViewStatePopulated(
        viewState: SchedulesViewStateStore,
        isPopulated: boolean) {
        viewState.isViewStatePopulated = isPopulated;
    });