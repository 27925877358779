import { action } from "satcheljs";

import { ClearFiltersActionMessage } from "./ClearFiltersActionMessage";

/**
 * Creates the action to clear the schedule view filters.
 */
export const clearFilters = action<ClearFiltersActionMessage, (shouldClearLabel: boolean) => ClearFiltersActionMessage>(
    "CLEAR_FILTERS",
    (shouldClearLabel: boolean) => ({ shouldClearLabel })
);
