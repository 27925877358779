import GroupedRequestDetailsStoreSchema, { IndividualOpenShiftRequestDetails } from "./schema/GroupedRequestDetailsStoreSchema";
import { createStore } from "satcheljs";
import { ObservableMap } from "mobx";

/**
 * --------------------------------------------------------
 * Don't forget to reinitialize these in resetStore mutator
 * --------------------------------------------------------
*/
const initialGroupedRequestDetailsStore: GroupedRequestDetailsStoreSchema = {
    groupedOpenShiftRequest: null,
    individualRequestDetails: new ObservableMap<string, IndividualOpenShiftRequestDetails>(),
    openShift: null,
    selectedRequestsCount: 0,
    userResponseMessage: null,
    isDataReady: false,
    isSaving: false,
    isApproving: false,
    closeCallback: null
};

const groupedRequestDetailsStore = createStore("groupedRequestDetailsStore", initialGroupedRequestDetailsStore)();
export default groupedRequestDetailsStore;

/* export action, mutatorActions and orchestrator for ease of import */
export { default as loadDataForGroupedRequest } from "../actions/loadDataForGroupedRequest";
export { default as approveDenyOpenShiftRequests } from "../actions/approveDenyOpenShiftRequests";
export { default as resetGroupedRequestDetailsStore } from "../mutatorActions/resetGroupedRequestDetailsStore";
export { default as setGroupedRequestInStore } from "../mutatorActions/setGroupedRequestInStore";
export { default as setIsDataReadyInStore } from "../mutatorActions/setIsDataReadyInStore";
export { default as setIsSavingInStore } from "../mutatorActions/setIsSavingInStore";
export { default as setOpenShiftInStore } from "../mutatorActions/setOpenShiftInStore";
export { default as toggleRequestSelectionInStore } from "../mutatorActions/toggleRequestSelectionInStore";
export { default as updateOpenShiftRequestDetailsInStore } from "../mutatorActions/updateOpenShiftRequestDetailsInStore";
export { default as setUserResponseMessageInStore } from "../mutatorActions/setUserResponseMessageInStore";

import "../orchestrators/approveDenyOpenShiftRequests";
import "../orchestrators/loadDataForGroupedRequest";