import * as React from "react";
import ActivityFlexItem from "../activity/ActivityFlexItem";
import DateUtils from "sh-application/utility/DateUtils";
import { EmptyFlexItem } from "./EmptyFlexItem";
import ShiftUtils from "sh-application/utility/ShiftUtils";
import StringsStore from "sh-strings/store";
import { ACTIVITY_MINIMUM_SEPARATION_IN_HOURS } from "sh-application/../StaffHubConstants";
import { ActivitySelectionItem } from "sh-application/components/schedules/lib";
import { AriaProperties, AriaRoles, generateDomPropertiesForAria } from "owa-accessibility";
import { IBaseShiftEntity } from "sh-models";
import { Moment } from "moment";
import { observer } from "mobx-react";
import {
    ScheduleData,
    TemporalItem,
    TemporalItemTypes,
} from '../schedule/ScheduleData';

const styles = require("./ActivityFlexContainer.scss");

export interface ActivityFlexContainerProps {
    shift: IBaseShiftEntity;
    hideActivityCodes: boolean;
    viewStart?: Moment;
    viewEnd?: Moment;
    selectionEnabled?: boolean;
    setFlexItemSelected?: SetFlexItemSelectedFunction;
    getFlexItemSelected?: GetFlexItemSelectedFunction;
    setGridCellSelected?: (isSelected: boolean) => void; // data selection value is handled in parent component
}

/**
 * Props shared by flex items rendered within the ActivityFlexContainer
 */
export interface ActivityFlexItemBaseProps {
    shift: IBaseShiftEntity;
    selectionEnabled: boolean;
    flexGrow: number;
    startTime: Moment;
    endTime: Moment;
    isSelected: boolean;
    setFlexItemSelected?: SetFlexItemSelectedFunction;
    setGridCellSelected?: (isSelected: boolean) => void; // data selection value is handled in parent component
}

/**
 * Callback functions used to manage flex items and grid cell selection state in day view
 */
export type SetFlexItemSelectedFunction = (item: ActivitySelectionItem, id: string, isSelected: boolean) => void;
export type GetFlexItemSelectedFunction = (id: string) => boolean;
export type SetGridCellSelectedFunction = (dataSelectionIndexVal: number, isSelected: boolean, shouldAnchor?: boolean) => void;

/**
 * The ActivityFlexContainer contains ActivityFlexItems and EmptyFlexItems that constitute the activity rendering and UI in Day View
 */
@observer
export default class ActivityFlexContainer extends React.Component<ActivityFlexContainerProps, {}> {
    private _orderedTemporalItems: TemporalItem[];
    private _activityStrings: Map<String, String>;

    constructor(props: ActivityFlexContainerProps) {
        super(props);

        this._activityStrings = StringsStore().registeredStringModules.get("activities").strings;
    }
    /**
     * Create an id for the empty flex item that starts at the given time. Needs to be distinct from empty flex items at the same time in different shifts
     * so we use the shift id.
     *
     * @param time
     */
    private getEmptyFlexId(time: Moment) {
        return `${this.props.shift.id}-EF-${time.valueOf()}`;
    }

    render() {
        const  { shift, viewStart, viewEnd, hideActivityCodes } = this.props;
        const { startTime, endTime } = ShiftUtils.getShiftTimesInView(shift, viewStart, viewEnd);
        this._orderedTemporalItems = ScheduleData.getOrderedTemporalItemsForShift(shift, viewStart, viewEnd);
        let subshiftsListClone = ScheduleData.filterActivitiesByTime(shift.subshifts, startTime, endTime);
        const ariaProps: AriaProperties = {
            role: AriaRoles.list,
            label: this._activityStrings.get("activityListAriaLabel").toString()
        };

        // Using the list of temporal items, construct a list of components to fill the ActivityFlexContainer. These components will be ActivityFlexItems and EmptyFlexItems. Each of these components
        // will have it's flex-grow property assigned based on the proportion of the shift duration comprised by the corresponding temporal item
        let flexItems = [];
        for (let i = 0; i < this._orderedTemporalItems.length; i++) {
            let temporalItem = this._orderedTemporalItems[i];

            // Durations are adjusted to the nearest quarter hour
            const adjustedDuration = DateUtils.getDifferenceInHoursFromMoments(DateUtils.roundMomentToQuarterHours(temporalItem.startTime), DateUtils.roundMomentToQuarterHours(temporalItem.endTime));

            // We consider quarter hours to be the smallest unit. Each component item will be scaled according to the number of quarter hours it spans.
            const flexGrow = adjustedDuration / .25;

            if (temporalItem.type === TemporalItemTypes.activity) {
                const activity = subshiftsListClone.shift();
                if (activity) {
                    const isSelected = this.props.getFlexItemSelected ? this.props.getFlexItemSelected(activity.id) : false;
                    flexItems.push(
                        <ActivityFlexItem
                            key={ `AFI_${i}` }
                            shift={ shift }
                            hideCode={ hideActivityCodes }
                            selectionEnabled={ this.props.selectionEnabled }
                            activity={ activity }
                            startTime={ temporalItem.startTime.clone() }
                            endTime={ temporalItem.endTime.clone() }
                            flexGrow={ flexGrow }
                            isSelected={ isSelected }
                            setFlexItemSelected={ this.props.setFlexItemSelected }
                            setGridCellSelected={ this.props.setGridCellSelected }/>
                    );
                }
            // Add an empty flex item so that activities align with the correct timing on the top of the schedule.
            } else if (temporalItem.type === TemporalItemTypes.empty) {
                const startTime = temporalItem.startTime.clone();
                    const time = startTime.clone();
                    const emptyFlexId = this.getEmptyFlexId(time);
                    flexItems.push(
                        <EmptyFlexItem key={ `EFI_${emptyFlexId}` } id={ emptyFlexId } flexGrow={flexGrow} />
                    );
                    startTime.add(ACTIVITY_MINIMUM_SEPARATION_IN_HOURS, "hours");
            }
        }

        return (
            <li className={ styles.activityFlexContainer }
                { ...generateDomPropertiesForAria(ariaProps) }>
                {
                    flexItems
                }
            </li>
        );
    }
}