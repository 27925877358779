import { action } from "satcheljs";
import { IOpenShiftEntity, OpenShiftEntity } from "sh-models";

export default action(
    "ADD_OPENSHIFTS_IN_STORE",
    (
        addedOpenShifts: IOpenShiftEntity[]
    ) => ({
        // We use clones of the argument entities. This is because setting the entities in the satchel stores will mark them as observables
        // and any callers who still hold references to these entities will not be able to mutate them (in event of an error, for example) without
        // triggering mobx exceptions
        addedOpenShifts: addedOpenShifts ? addedOpenShifts.map(openShift => OpenShiftEntity.clone(openShift)) : []
    })
);