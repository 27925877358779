/**
 * Generic function that moves items in an array that is passed
 * This updates the array that is being passed in
 * @param ar Array that needs to be reordered
 * @param oldIndex - index for the item that needs to be moved
 * @param newIndex  - index to which the item at oldIndex needs to be moved to
 */
export default function arrayMove(ar: Array<any>, oldIndex: number, newIndex: number) {
    if (!ar || oldIndex < 0 || oldIndex >= ar.length || oldIndex === newIndex) {
        return;
    }

    if (newIndex < 0) {
        newIndex = 0;
    }

    if (newIndex >= ar.length) {
        newIndex = ar.length - 1;
    }

    ar.splice(newIndex, 0, ar.splice(oldIndex, 1)[0]);

}