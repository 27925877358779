import schedulesViewStateStore from "../store/store";
import { mutatorAction } from "satcheljs";

/**
 * Marks a list entities as newly edited
 *
 * @param entities
 * @param isNewlyEdited
 */
export default mutatorAction("SET_NEWLY_EDITED_ENTITIES",
    function setNewlyEditedEntities(entities: Array<{id: string}>, isNewlyEdited: boolean) {
        const store = schedulesViewStateStore();
        store.newlyEditedEntities.clear();
        for (let i = 0; i < entities.length; i++) {
            store.newlyEditedEntities.set(entities[i].id, isNewlyEdited);
        }
});
