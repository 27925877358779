import RestClient from "sh-rest-client";
import {
    deleteNotes,
    NoteStore,
    resetNoteStore,
    setNotesCacheEndTimeInStore,
    setNotesCacheStartTimeInStore,
    updateNotes
    } from "sh-note-store";
import { DataFilter } from "sh-services";
import { IItemsInTimeRangeSyncState, INoteEntity } from "sh-models";
import { INoteDatabase } from "sh-services/data/INoteDatabase";
import { ItemsInDateRangeDataProvider } from "./ItemsInDateRangeDataProvider";
import { Moment } from "moment";
import { ObservableMap } from "mobx";

/**
 * Data Provider to fetch Notes in DateRange for the current team
 */
export class NotesInDateRangeDataProvider extends ItemsInDateRangeDataProvider<INoteEntity> {

    protected noteDatabase: INoteDatabase;

    constructor(noteDatabase: INoteDatabase, tenantId: string, teamId: string, sessionId: string, fetchStartTime: Moment, fetchEndTime: Moment, dontClearCache: boolean) {
        super(tenantId, teamId, sessionId, fetchStartTime, fetchEndTime, dontClearCache);
        this.noteDatabase = noteDatabase;
    }

    /**
     * Fetch all items that are in memory
     */
    getAllItemsFromMemory(): ObservableMap<string, INoteEntity> {
        return NoteStore().notes;
    }

    /**
     * Fetch the sync start time from memory
     */
    getSyncCacheStartTimeFromMemory(): Moment {
        return NoteStore().notesCacheStartTime;
    }

    /**
     * Fetch the sync end time from memory
     */
    getSyncStateEndTimeFromMemory(): Moment {
        return NoteStore().notesCacheEndTime;
    }

    /**
     * Set sync cache start time in memory
     */
    setSyncCacheStartTimeInMemory(syncStateStartTime: Moment): void {
        setNotesCacheStartTimeInStore(syncStateStartTime);
    }

    /**
     * Set sync cache end time in memory
     */
    setSyncStateEndTimeInMemory(syncStateEndTime: Moment): void {
        setNotesCacheEndTimeInStore(syncStateEndTime);
    }

    /**
     * Set list of items in memory
     */
    setItemsInMemory(items: INoteEntity[]): void {
        updateNotes(items, false /* isOptimisticUpdate */);
    }

    /**
     * Hard delete items in memory
     */
    hardDeleteItemsInMemory(items: INoteEntity[]): void {
        deleteNotes(items);
    }

    /**
     * Reset the items and sync state in memory
     */
    resetDataInMemory(): void {
        resetNoteStore();
    }

    /**
     * Returns true if this item matches the data filter.
     * @param item the note
     * @param dataFilter data filter
     */
    isItemInDataFilter(item: INoteEntity, dataFilter: DataFilter): boolean {
        // currently notes do not support filtering
        return true;
    }

    /**
     * Set Items and the sync state timerange in the Database
     */
    async setItemsAndSyncStateInDatabase(updatedItems: INoteEntity[], deletedItemIds: string[], syncStateStartTimestamp: number, syncStateEndTimestamp: number): Promise<void> {
        return await this.noteDatabase.setNotesInTimeRange(this.teamId, this.sessionId, updatedItems, deletedItemIds, syncStateStartTimestamp, syncStateEndTimestamp);
    }

    /**
     * Get items in the fetch time range from the Database
     */
    async getItemsInDateRangeFromDatabase(): Promise<INoteEntity[]> {
        return await this.noteDatabase.getNotes(this.teamId, this.fetchStartTime, this.fetchEndTime);
    }

    /**
     * Get sync state from the Database
     */
    async getItemsSyncStateFromDatabase(): Promise<IItemsInTimeRangeSyncState> {
        return await this.noteDatabase.getNotesSyncState(this.teamId, this.sessionId);
    }

    /**
     * Get list of items within time range from network
     */
    async getItemsInDateRangeFromNetwork(): Promise<INoteEntity[]> {
        return await RestClient.getNotes(this.tenantId, this.teamId, this.fetchStartTime, this.fetchEndTime);
    }
}