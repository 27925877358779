import { mutatorAction } from "satcheljs";
import { TeamSetupPickerViewState } from "../schema/TeamSetupPickerViewState";

export default mutatorAction("SET_SHOW_TEAM_SETUP_PICKER_ENTRYPOINTS",
    function setShowTeamSetupPickerEntrypoints(
        viewState: TeamSetupPickerViewState,
        showScheduleTeamPickerEntrypoints: boolean,
        showCreateNewScheduleTeamEntrypoints: boolean) {
        viewState.showScheduleTeamPickerEntrypoints = showScheduleTeamPickerEntrypoints;
        viewState.showCreateNewScheduleTeamEntrypoints = showCreateNewScheduleTeamEntrypoints;
    });