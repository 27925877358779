import ChangeStore from "../store";
import ChangeUtils from "sh-application/utility/ChangeUtils";
import { action } from "satcheljs/lib/legacy";
import { clearTrackedChanges } from "./clearTrackedChanges";
import { FlightKeys, IChangeEntity } from "sh-models";
import { FlightSettingsService } from "sh-services";
import { trace } from "owa-trace";

/**
 * Add set of changes to the stack. The changes will be grouped together and will be undone together.
 * This will reject the delete of a published shift and clears the stack.
 * This is occurring because revertChanges is not capable of reverting the delete of a published shift.
 * The stack is cleared for fear of a user rage clicking through the rest of their changes.
 *
 * @param changes - the changes that should be undone together next
 * @param fromUndo - true if this trackChanges call is from undo
 * @param fromRedo - true if being called from redo
 */
export let trackChanges = action("trackChanges")(
    function trackChanges(changes: Array<IChangeEntity>, fromUndo?: boolean, fromRedo?: boolean) {
        return new Promise((resolve, reject) => {
            if (!FlightSettingsService.isFlightEnabled(FlightKeys.EnableShiftUndoWeb)) {
                resolve(false);
                return;
            }
            if (!(changes && changes.length > 0)) {
                trace.warn("trackChanges: invalid inputs");
                resolve(false);
                return;
            }
            // check if there is a delete of a published shift
            if (changes.some(ChangeUtils.isDeleteOfPublishedShift)) {
                clearTrackedChanges();
                resolve(false);
                return;
            }
            if (fromUndo) {
                ChangeStore().redoStack.push(changes);
            } else {
                ChangeStore().undoStack.push(changes);
            }
            if (!fromUndo && !fromRedo) {
                // if this didn't come from an undo or a redo, then it should clear the redoStack
                ChangeStore().redoStack.clear();
            }
            resolve(true);
        });
    });
