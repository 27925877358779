import IJobDataService from "sh-services/dataservice/IJobDataService";
import RestClient from "sh-rest-client";
import ScheduleProvisionJobResult from "sh-models/job/ScheduleProvisionJobResult";
import StringsStore from "sh-strings/store";
import { DataService } from "./DataService";
import { ECSConfigKey, ECSConfigService, StaffHubErrorCodes } from "sh-services";
import { IJobEntity, JobStatus } from "sh-models";
import { JobPollUtils } from "sh-services/jobframework/JobPollUtils";
import { StaffHubError } from "sh-application";

/**
 * Functions for checking the status of Jobs
 */
class JobDataService extends DataService implements IJobDataService {

    /**
     * Get a team job status
     */
    public async checkTeamJobStatus(teamId: string, jobId: string): Promise<IJobEntity> {
        const importScheduleJob: IJobEntity = await RestClient.checkTeamJobStatus(teamId, jobId);
        return importScheduleJob;
    }

    /**
     * Initialize polling for a job
     * @param teamId teamId
     * @param jobId jobId
     * @param onJobResponse Function to call when a poll for job data responds
     * @param onMaxRetry Function to call when we've hit the max retry limit
     * @param intervalDuration - Number in ms indicating how often to poll
     * @param maxRetryCount - Number indicating how many times we should poll
     */
    public initPollingForJob(teamId: string, jobId: string, onJobResponse: (importScheduleJob: IJobEntity) => void, onMaxRetry: () => void, intervalDuration: number, maxRetryCount: number): void {
        const checkJobStatus = async (jobId: string) => {
            return await this.checkTeamJobStatus(teamId, jobId);
        };
        const jobPollUtils: JobPollUtils = new JobPollUtils();
        jobPollUtils.initPolling(jobId, intervalDuration, maxRetryCount, checkJobStatus, onJobResponse, onMaxRetry);
    }

    /**
     * Poll until the ProvisionSchedule job has completed and return the ID of the team that's provisioned
     * @param teamId teamId
     * @param jobId JobId
     */
    public async pollUntilProvisionScheduleComplete(teamId: string, jobId: string): Promise<string> {

        const intervalDuration = ECSConfigService.getECSFeatureSetting(ECSConfigKey.ProvisionSchedulePollIntervalInMilliseconds);
        const maxRetryCount = ECSConfigService.getECSFeatureSetting(ECSConfigKey.ProvisionScheduleMaxRetry);

        return new Promise<string>( (resolve, reject) => {
            const checkJobStatus = async (jobId: string) => {
                return await this.checkProvisionScheduleJobStatus(jobId);
            };
            const onJobResponse = (scheduleProvisionJob: IJobEntity) => {
                const jobResult: ScheduleProvisionJobResult = ScheduleProvisionJobResult.fromJson(scheduleProvisionJob.result);
                if (scheduleProvisionJob) {
                    if (scheduleProvisionJob.status == JobStatus.Success) {
                        // resolve
                        resolve(jobResult.teamId);
                    } else if (scheduleProvisionJob.status == JobStatus.Failure) {
                        // job failed
                        reject(jobResult.error);
                    } else {
                        // (scheduleProvisionJob.status == JobStatus.InProgress || scheduleProvisionJob.status == JobStatus.Queued)
                        // wait some more
                    }
                }
            };
            const onMaxRetry = () => {
                const scheduleProvisionStrings = StringsStore().registeredStringModules.get("scheduleProvision").strings;
                let error: StaffHubError = {
                    message: scheduleProvisionStrings.get("scheduleProvisionTimedOut"),
                    code: StaffHubErrorCodes.ScheduleProvisionTimedOut,
                    details: [],
                    innererror: null
                };
                reject(error);
            };
            const jobPollUtils: JobPollUtils = new JobPollUtils();
            jobPollUtils.initPolling(jobId, intervalDuration, maxRetryCount, checkJobStatus, onJobResponse, onMaxRetry);
        });
    }

    /**
     * Check the ProvisionSchedule job status
     */
    public async checkProvisionScheduleJobStatus(jobId: string): Promise<IJobEntity> {
        const job: IJobEntity = await RestClient.checkProvisionScheduleJobStatus(jobId);
        return job;
    }

    /**
     * Resets the sync state
     */
    public async resetSyncState() {
    }
}

const service: IJobDataService = new JobDataService();
export default service;