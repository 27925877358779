import { AxiosError } from "axios";
import { getActionQueue } from "sh-exception-handler/lib/middleware/addToActionQueue";

export interface Detail {
    code: string;
    message: string;
    target: string;
}
export interface StaffHubError {
    message: string;
    code: string;
    details: Array<Detail>;
    innererror: Detail;
    externalErrorMessage?: string;
    externalSource?: string;
    externalImageLink?: string;
    externalData?: string;
    title?: string;
}

export interface StaffHubHttpError extends Error {
    httpMethod?: string;
    httpUrl?: string;
    httpErrorCode?: number;
    httpErrorMessage?: string;
    staffHubTopLevelErrorCode?: string;
    staffHubTopLevelErrorMessage?: string;
    staffHubInnerErrorCode?: string;
    staffHubInnerErrorMessage?: string;
    staffHubError?: StaffHubError;
    actionQueue?: string;
    isNetworkError?: boolean;   // Tracks if there was a network error
    isHandled?: boolean;    // Tracks if the exception has already been handled
}

export function createNewHttpError(): StaffHubHttpError {
    return {
        name: "",
        message: "",
        httpMethod: null,
        httpUrl: null,
        httpErrorCode: null,
        httpErrorMessage: null,
        staffHubTopLevelErrorCode: null,
        staffHubTopLevelErrorMessage: null,
        staffHubInnerErrorCode: null,
        staffHubInnerErrorMessage: null,
        staffHubError: null,
        // include the satchel action queue (part of the satchel middleware)
        actionQueue: getActionQueue().join(",")
    };
}

/**
 * Parse and map httpError into its parts
 * @param {AxiosError} httpError
 * @returns {StaffHubHttpError}
 */
export function mapHttpError(httpError: AxiosError<any, any>): StaffHubHttpError {
    let error = createNewHttpError();

    if (httpError) {
        error.name = httpError.name || "";
        error.message = httpError.message;

        if (httpError.response) {
            error.httpErrorCode = httpError.response.status;
            error.httpErrorMessage = httpError.response.statusText;

            let httpErrorConfig = httpError.config;
            if (httpErrorConfig) {
                error.httpMethod = httpErrorConfig.method ? httpErrorConfig.method : null;
                error.httpUrl = httpErrorConfig.url ? httpErrorConfig.url : null;
            }

            if (httpError.response.data && httpError.response.data.error) {
                error.staffHubError = httpError.response.data.error as StaffHubError;
                error.staffHubTopLevelErrorCode = error.staffHubError.code ? error.staffHubError.code : null;
                error.staffHubTopLevelErrorMessage = error.staffHubError.message ? error.staffHubError.message : null;
                if (error.staffHubError.innererror) {
                    error.staffHubInnerErrorCode = error.staffHubError.innererror.code;
                    error.staffHubInnerErrorMessage = error.staffHubError.innererror.message;
                }
            }
        } else {
            error.isNetworkError = true;
        }
    }

    return error;
}

/**
 * Web specific error codes to be used as innerErrorCodes and are not in shiftrErrorCodes and commonErrorCodes being sent by shifts service
 */
export enum ShiftsWebErrorCodes {
    AuthTokenFetchFailed = "AuthTokenFetchFailed"
}