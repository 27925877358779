import { action } from "satcheljs";

/**
 * Triggers handler for rendering an accessibility alert message
 */
export default action(
    "FIRE_ACCESSIBILITY_ALERT",
    (
        alertText: string
    ) => ({
        alertText: alertText
    })
);