import FileDownloadUtils from "sh-application/utility/FileDownloadUtils";
import getDownloadErrorFile from "../actions/getDownloadErrorFile";
import StringsStore from "sh-strings/store";
import { getGenericEventPropertiesObject, InstrumentationEventPropertyInterface } from "sh-instrumentation";
import { InstrumentationService } from "sh-services";
import { orchestrator } from "satcheljs";
import { setTryImportingAgainButton } from "..";

/**
 * Orchestrator to download import schedule error file.
 */

export default orchestrator(getDownloadErrorFile, async actionMessage => {
        let eventProperties: Array<InstrumentationEventPropertyInterface> = [];
        const strings: Map<string, string> = StringsStore().registeredStringModules.get("common").strings;
        try {
                if (actionMessage.fileUrl) {
                        FileDownloadUtils.DownloadFile(actionMessage.fileUrl);
                } else {
                        throw new Error(strings.get("genericError"));
                }

                eventProperties.push(getGenericEventPropertiesObject(InstrumentationService.properties.ImportExcelErrorsDownloaded, true));
                InstrumentationService.logEvent(InstrumentationService.events.ImportExcel, eventProperties);
                setTryImportingAgainButton(false /* showButton */);
        } catch (err) {
                eventProperties = [getGenericEventPropertiesObject(InstrumentationService.properties.ImportExcelFailedWeb, strings.get("genericError"))];
                InstrumentationService.logEvent(InstrumentationService.events.ImportExcel, eventProperties);
        }
});