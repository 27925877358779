import { createStore } from "satcheljs";
import { IMemberEntity, IRoleEntity } from "sh-models";
import { ObservableMap } from "mobx";
import { TeamStoreSchema } from "./schema/TeamStore";

/**
 * Store for Teams data
 */
let TeamStore = createStore<TeamStoreSchema>(
    "teamStore",
    {
        teamId: null,
        groupId: null,
        tenantId: null,
        name: null,
        team: null,
        roles: new ObservableMap<string, IRoleEntity>(),
        adminRoleId: "",
        members: new ObservableMap<string, IMemberEntity>(),
        me: null
    });

export default TeamStore;