import { createStore } from "satcheljs";

import { TeamMemberPermissionsStoreSchema } from "./TeamMemberPermissionsStoreSchema";

/* Register Orchestrators */

/**
 * Store for Team Permissions data.
 */
// TODO: Extract store to its own folder called 'sh-team-member-permissions-store'
export const TeamMemberPermissionsStore = createStore<TeamMemberPermissionsStoreSchema>(
    "TeamMemberPermissionsStore",
    {
        permissions: null
    });