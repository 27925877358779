import deleteShiftRequest from "../actions/deleteShiftRequest";
import ShiftRequestUtils from "sh-application/utility/ShiftRequestUtils";
import StringsStore from "sh-strings/store";
import { fireAccessibilityAlert } from "sh-application/components/accessibilityAlert";
import { orchestrator } from "satcheljs";
import { setErrorMessageFromException, setIsSavingInStore } from "../";
import { ShiftRequestDataService } from "sh-services";
import { trace } from "owa-trace";

/**
 * This orchestrator will call the api to delete a shift request (it cancels the request)
 */
export default orchestrator(deleteShiftRequest, async actionMessage => {
    const { shiftRequest, onFinish } = actionMessage;
    if (!shiftRequest) {
        trace.warn("deleteShiftRequest: request not specified");
        return;
    }

    try {
        setIsSavingInStore(true);
        await ShiftRequestDataService.deleteShiftRequest(shiftRequest);
        if (onFinish) {
            onFinish();
        }

        // Accessibility message that the request was cancelled
        const shiftRequestType: string = ShiftRequestUtils.getTypeForAccessibility(shiftRequest);
        fireAccessibilityAlert(StringsStore().registeredStringModules.get("shiftRequests").strings.get("shiftRequestCancelled").format(shiftRequestType));
    } catch (error) {
        // workflow integration error would be handled earlier
        if (error && !error.isHandled) {
            setErrorMessageFromException(error);
        }
    } finally {
        setIsSavingInStore(false);
    }
});
