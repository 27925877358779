import { action } from "satcheljs";
import { ConflictType } from "sh-models";

const removeConflictFromConflictStore = action(
    "REMOVE_CONFLICT_FROM_CONFLICT_STORE",
    (
        conflictingShiftId: string,
        conflictType: ConflictType,
        memberId: string,
        otherConflictingShiftId?: string,
        conflictingAvailability?: string
    ) => ({
        conflictingShiftId: conflictingShiftId,
        conflictType: conflictType,
        memberId: memberId,
        otherConflictingShiftId: otherConflictingShiftId,
        conflictingAvailability: conflictingAvailability
    })
);

export default removeConflictFromConflictStore;