import { action } from "satcheljs/lib/legacy";
import { GlobalMessageViewState } from "../store/schema/GlobalMessageViewState";
import { MessageBarType } from "@fluentui/react";

/**
 * Action to set the message to display in the global message bar. Setting to blank string will clear the message.
 * @param {AppViewState} AppViewState
 * @param {string} message
 * @param {string} actionButtonTitle - title of optional action button (if you include a button title, you must include a callback function)
 * @param {Function} actionButtonCallback - callback for optional action button
 * @param {boolean} autoDismiss - set to true to have the message bar auto dismiss
 * @param {boolean} isMultiLine - set to true to prevent clipping of multi line message bar details
 */
export default action("setGlobalMessages")(
    function setGlobalMessages(viewState: GlobalMessageViewState,
        messages: Array<string> = null,
        messageBarType: MessageBarType = MessageBarType.info,
        actionButtonTitle: string = null,
        actionButtonCallback: Function = null,
        autoDismiss: boolean = false,
        isMultiline: boolean = true,
        isCenterAligned: boolean = false) {
            viewState.messages = messages;
            viewState.messageBarType = messageBarType;
            if (actionButtonTitle && actionButtonCallback) {
                // don't set the title or callback if either is missing
                viewState.actionButtonTitle = actionButtonTitle;
                viewState.actionButtonCallback = actionButtonCallback;
            } else {
                viewState.actionButtonTitle = viewState.actionButtonCallback = null;
            }
            viewState.autoDismiss = autoDismiss;
            viewState.isMultiline = isMultiline;
            viewState.isCenterAligned = isCenterAligned;
    });