import * as React from "react";
import * as ReactDOM from "react-dom";
import AutomationUtils from "sh-application/utility/AutomationUtil";
import StringsStore from "sh-strings/store";
import { AriaProperties, AriaRoles, generateDomPropertiesForAria } from "owa-accessibility";
import { ConfirmCustomizationOptions } from "../actions/confirm";
import { DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton } from "@fluentui/react";

let styles = require("./ConfirmDialog.scss");

declare type clickCallback = () => void;

interface ConfirmDialogProps {
    /**
     * Boolean to make the dialog controlled. If not provided the component will control it's own hidden state.
     */
    isHidden?: boolean;
    title?: string;
    subText?: string;
    customizationOptions?: ConfirmCustomizationOptions;
    showCloseButton?: boolean;
    callCancelOnDismiss?: boolean;
    okButtonDisabled?: boolean;
    onOkCallback?: () => void;
    onCancelCallback?: () => void;
    /**
     * Callback to close the dialog, if controlled you must explicitly set the hidden prop to true here.
     */
    onClose: () => void;
}

interface ConfirmDialogState {
    isHidden: boolean;
}

export class ConfirmDialog extends React.Component<ConfirmDialogProps, ConfirmDialogState> {
    private delayedCallback: () => void;
    private _strings: Map<string, string>;

    constructor(props: ConfirmDialogProps) {
        super(props);
        this._strings = StringsStore().registeredStringModules.get("common").strings;

        this.state = {
            isHidden: false
        };
    }

    render() {
        const { isHidden } = this.props;
        const shouldUseState = typeof isHidden !== "boolean";
        let customizationOptions = this.props.customizationOptions || {};
        const initialAriaAlert = customizationOptions.initialAriaAlert;
        let onOk = () => this.onClick(this.props.onOkCallback);
        let onCancel = () => this.onClick(this.props.onCancelCallback);

        const cancelButtonLabel: string = customizationOptions.cancelText || this._strings.get("cancel");
        const okButtonLabel: string = customizationOptions.okText || this._strings.get("ok");
        const ariaProps: AriaProperties = {
            role: AriaRoles.alert,
            live: "assertive"
        };

        return (
            <Dialog
                hidden={shouldUseState ? this.state.isHidden : isHidden}
                onDismiss={this.onDismiss}
                modalProps={{
                    isBlocking: customizationOptions.isBlocking || false,
                    containerClassName: customizationOptions.containerClassName,
                    className: styles.confirmDialog
                }}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: this.props.title,
                    subText: this.props.subText,
                    className: styles.content,
                    showCloseButton: this.props.showCloseButton
                }}>
                {/** We have the form so that 'enter' in input fields will trigger the 'ok' confirm action */}
                <form
                    onSubmit={evt => {
                        evt.preventDefault();
                        onOk();
                    }}>
                    {customizationOptions.bodyElement}
                </form>
                <div className={styles.accessibilityAlert} {...generateDomPropertiesForAria(ariaProps)}>
                    {initialAriaAlert}
                </div>
                <DialogFooter>
                    {customizationOptions.hideCancelButton ? null : (
                        <DefaultButton
                            className={AutomationUtils.getAutomationId("confirm", "QAIDConfirmCancelButton")}
                            onClick={onCancel}
                            ariaLabel={cancelButtonLabel}>
                            {cancelButtonLabel}
                        </DefaultButton>
                    )}
                    {!customizationOptions.hideOkButton && (
                        <PrimaryButton
                            className={AutomationUtils.getAutomationId("confirm", "QAIDConfirmOkayButton")}
                            onClick={onOk}
                            ariaLabel={okButtonLabel}
                            disabled={this.props.okButtonDisabled}>
                            {okButtonLabel}
                        </PrimaryButton>
                    )}
                </DialogFooter>
            </Dialog>
        );
    }

    private onClick = (callback: clickCallback) => {
        if (callback) {
            if (this.props.customizationOptions && this.props.customizationOptions.delayCallbackAfterAnimation) {
                this.delayedCallback = callback;
            } else {
                callback();
            }
        }
        this.setState({
            isHidden: true
        });
        this.props.onClose();
    };

    private onDismiss = () => {
        if (this.delayedCallback) {
            this.delayedCallback();
            this.delayedCallback = null;
        } else if (this.props.onCancelCallback && this.props.callCancelOnDismiss) {
            // We dismissed without issuing a callback.
            // Call 'cancel'
            this.props.onCancelCallback();
        }

        this.props.onClose();
    };
}

let instance = 0;
/**
 * @deprecated please use the ConfirmDialog directly because this creates multiple modals that can't be dismissed.
 */
function showConfirmDialog(
    title: string,
    subText?: string,
    customizationOptions?: ConfirmCustomizationOptions,
    onOkCallback?: () => void,
    onCancelCallback?: () => void,
    okButtonDisabled?: boolean,
    showCloseButton?: boolean,
    callCancelOnDismiss?: boolean
) {
    // Initialize it and append it to the body.
    let confirmDialogDiv = document.createElement("div");
    confirmDialogDiv.id = `confirmDialog${instance++}`;
    document.body.appendChild(confirmDialogDiv);
    ReactDOM.render(
        <ConfirmDialog
            title={title}
            subText={subText}
            showCloseButton={showCloseButton}
            customizationOptions={customizationOptions}
            onOkCallback={onOkCallback}
            callCancelOnDismiss={callCancelOnDismiss}
            onCancelCallback={onCancelCallback}
            okButtonDisabled={okButtonDisabled}
            onClose={() => {
                ReactDOM.unmountComponentAtNode(confirmDialogDiv);
                document.body.removeChild(confirmDialogDiv);
            }}
        />,
        confirmDialogDiv
    );
}

export default showConfirmDialog;
