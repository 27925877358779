import { createStore } from "satcheljs";
import { IOpenShiftEntity } from "sh-models";
import { ObservableMap } from "mobx";
import { OpenShiftStoreSchema } from "./schema/OpenShiftStoreSchema";

// Register Mutators
import "../mutators/addOpenShiftsInStoreMutator";
import "../mutators/updateOpenShiftsInStoreMutator";
import "../mutators/deleteOpenShiftsInStoreMutator";

/**
 * Store for OpenShifts
 */
let OpenShiftStore = createStore<OpenShiftStoreSchema>(
    "openShiftsStore",
    {
        openShifts: new ObservableMap<string, IOpenShiftEntity>(),
        cacheStartTime: null,
        cacheEndTime: null
    });

export default OpenShiftStore;