import IShiftDataService from "./IShiftDataService";
import RestClient from "sh-rest-client";
import RuleViolationUtils from "sh-application/utility/RuleViolationUtils";
import ShiftUtils from "sh-application/utility/ShiftUtils";
import TagUtils from "sh-application/utility/TagUtils";
import {
    CopyOfShiftsResponse,
    IAssignOpenShiftResponseEntity,
    IBulkShiftResponseEntity,
    IOpenShiftEntity,
    IShiftEntity,
    IShiftResponseEntity,
    IShiftResponseMultipleEntity,
    IUpdateShiftResponseEntity,
    OpenShiftEntity,
    ShiftEntity,
    ShiftState,
    ShiftStates
} from "sh-models";
import { DataService } from "./DataService";
import { deleteOpenShiftsInStore, OpenShiftStore, updateOpenShiftsInStore } from "sh-open-shift-store";
import { deleteShifts, ShiftStore, updateShifts, MyShiftStore } from "sh-shift-store";
import { InstrumentationService } from "sh-services";
import { Moment } from "moment";
import { prependShiftsAsUniqueShifts } from "sh-uniqueshift-store";
import { ShiftDataProvider } from "../dataproviders/ShiftDataProvider";
import { ShiftsInDateRangeDataProvider } from "../dataproviders/ShiftsInDateRangeDataProvider";
import { StaffHubHttpError } from "sh-application";
import { TeamStore } from "sh-team-store";
import { TestDataIDConstant, ShiftStoreTypes } from "sh-application/../StaffHubConstants";
import { transaction } from "mobx";

/**
 * Functions for retrieving and editing information related to instances of IBaseShiftEntity.
 * Over time, we should move all of the legacy actions that are currently unders sh-shift-store to here.
 */
class ShiftDataService extends DataService implements IShiftDataService {
    /**
     * Get a shift by the shiftId.  First check ShiftStore(), then make call to service
     * @param shiftId
     */
    public async getShiftById(shiftId: string): Promise<IShiftEntity> {
        const shiftDataProvider = new ShiftDataProvider(this.shiftDatabase, this.tenantId, TeamStore().teamId, shiftId);
        return await this.getData(shiftDataProvider);
    }

    /**
     * Get an open shift by the id.  First check ShiftStore(), then make call to service
     * @param openShiftId
     */
    public async getOpenShiftById(openShiftId: string): Promise<IOpenShiftEntity> {
        let openShift: IOpenShiftEntity = null;
        if (OpenShiftStore && OpenShiftStore().openShifts && OpenShiftStore().openShifts.has(openShiftId)) {
            openShift = OpenShiftStore().openShifts.get(openShiftId);
        } else {
            openShift = await RestClient.getOpenShiftById(TeamStore().tenantId, TeamStore().teamId, openShiftId);
        }
        return openShift;
    }

    /**
     * Get shifts within a date range.  Looks to see if this date range is cached, otherwise, goes to service.
     * If the date range requested is non contiguous with the currently cached date range, then the cache will be cleared.
     * @param tenantId
     * @param teamId
     * @param startDate
     * @param endDate
     * @param dontClearCache (optional)
     */
    public async getShiftsInDateRange(tenantId: string, teamId: string, startDate: Moment, endDate: Moment, dontClearCache: boolean = false): Promise<IShiftEntity[]> {
        const dateRangeDataProvider = new ShiftsInDateRangeDataProvider(this.shiftDatabase, this.tenantId, teamId, this.sessionId, startDate, endDate, dontClearCache, ShiftStoreTypes.ShiftStore);
        const shiftsInDateRange = await this.getData(dateRangeDataProvider);
        return shiftsInDateRange && shiftsInDateRange.data ? shiftsInDateRange.data : [];
    }

    /**
     * Updates a shift
     * @param shift
     * @param optimistic
     * @param isPublished Set to true to mark the shift as published/shared. Normally this should be set to false because we are
     *      modifying shifts data, and therefore the new changes will need to be shared/published to be visible to the team.
     * @param fetchLatestETag - if true, fetch the latest eTag from the shift cache. Otherwise use the eTag of the provided shift. This
     * is usually true in order to support cases where the UI has not updated yet, but the cache has updated. In rare cases we will launch back-to-back
     * requests for the same shift, where the cache may not be updated before we launch the second request with the result of the first
     */
    public async updateShift(shift: IShiftEntity, optimistic: boolean = false, isPublished: boolean = false, fetchLatestETag: boolean = true): Promise<IUpdateShiftResponseEntity> {
        let marker = "updateShift";
        marker = InstrumentationService.perfMarkerStart(marker);
        const shiftDataProvider = new ShiftDataProvider(this.shiftDatabase, this.tenantId, TeamStore().teamId, shift.id);

        // Get the latest eTag for the shift before making a network request
        const lastCachedShift = ShiftStore().shifts.get(shift.id);
        if (lastCachedShift && fetchLatestETag) {
            shift.eTag = lastCachedShift.eTag;
        }

        let originalShiftCopy: IShiftEntity = null;
        // Perform optimistic update if the optimistic flag is set, and the original shift is currently
        // available in the cache so we can save this original state in case we need to undo the update.
        if (optimistic && ShiftStore().shifts.has(shift.id)) {
            originalShiftCopy = ShiftEntity.clone(ShiftStore().shifts.get(shift.id));
            this.updateShiftsInStorage([shift], true /* isOptimisticUpdate */);
        }

        shift.isPublished = isPublished;

        try {
            const shiftAndAlertEntity: IUpdateShiftResponseEntity = await RestClient.updateShift(shift.tenantId, shift.teamId, shift);
            // we set a timeout to delay the actual cache updates so that
            // UI such as the add edit shift panel can quickly be closed before the time
            // consuming task of re-rendering the schedule.
            setTimeout(() => {
                // Even if we already optimistically updated shifts, we need to call this.updateShiftsInStorage again in order to attach the eTags
                if (shiftAndAlertEntity) {
                    // Update data in memory
                    transaction(() => {
                        this.updateShiftsInStorage([shiftAndAlertEntity.shift], false /* isOptimisticUpdate */);
                        // if updated shift response has any updated openshift, cache the updated open shift
                        if (shiftAndAlertEntity.openShift) {
                            this.updateOpenShiftsInStorage([shiftAndAlertEntity.openShift], false /* isOptimisticUpdate */);
                        }
                        prependShiftsAsUniqueShifts([shiftAndAlertEntity.shift]);
                    });

                    // Update data in the database
                    setTimeout(async () => {
                        await shiftDataProvider.setDataInDatabase(shiftAndAlertEntity.shift);
                    }, 0);
                }
            }, 0);
            return shiftAndAlertEntity;
        } catch (error) {
            if (optimistic && originalShiftCopy) {
                // Restore the original shift state in case of a failure
                this.updateShiftsInStorage([originalShiftCopy], true /*isOptimisticUpdate*/);
            }
            throw error;
        } finally {
            InstrumentationService.perfMarkerEnd(marker);
        }
    }

    /**
     * Update shifts. If optional parameter optimistic is true, update the shifts within the cache
     * (store) to immediately update the UI. If the API call fails, revert the shifts by updating them within the cache
     * with their previous state.
     * @param shifts
     * @param optimistic
     * @param isPublished Set to true to mark the shifts as published/shared. Normally this should be set to false because we are
     *      modifying shifts data, and therefore the new changes will need to be shared/published to be visible to the team.
     */
    public async updateShifts(shifts: Array<IShiftEntity>, optimistic: boolean, isPublished: boolean = false): Promise<IShiftResponseMultipleEntity> {
        let requestPromises = new Array<any>();
        shifts.forEach((shiftModelForUpdate) => {
            if (shiftModelForUpdate) {
                // Get the latest eTag for the shift before making a network request
                const lastCachedShift = ShiftStore().shifts.get(shiftModelForUpdate.id);
                if (lastCachedShift) {
                    shiftModelForUpdate.eTag = lastCachedShift.eTag;
                }

                let originalShiftCopy: IShiftEntity = null;
                // Perform optimistic update if the optimistic flag is set, and the original shift is currently
                // available in the cache so we can save this original state in case we need to undo the update.
                if (optimistic && ShiftStore().shifts.has(shiftModelForUpdate.id)) {
                    originalShiftCopy = ShiftEntity.clone(ShiftStore().shifts.get(shiftModelForUpdate.id));
                    this.updateShiftsInStorage([shiftModelForUpdate], true /*isOptimisticUpdate*/);
                }

                shiftModelForUpdate.isPublished = isPublished;

                let updateShiftPromise = RestClient.updateShift(shiftModelForUpdate.tenantId, shiftModelForUpdate.teamId, shiftModelForUpdate)
                    .then((shiftAndAlertEntity: IUpdateShiftResponseEntity) => {
                        // Even if we already optimistically updated shifts, we need to call updateShiftsInStorage again in order to attach the eTags and update the DB
                        if (shiftAndAlertEntity) {
                            this.updateShiftsInStorage([shiftAndAlertEntity.shift], false /* isOptimisticUpdate */);
                            // if any openShift got updated, store the update in cache
                            if (shiftAndAlertEntity.openShift) {
                                this.updateOpenShiftsInStorage([shiftAndAlertEntity.openShift], false /* isOptimisticUpdate */);
                            }
                            prependShiftsAsUniqueShifts([shiftAndAlertEntity.shift]);
                        }
                        return Promise.resolve(shiftAndAlertEntity);
                    })
                    .catch((error: StaffHubHttpError) => {
                        if (optimistic && originalShiftCopy) {
                            // Restore the original shift state in case of a failure
                            this.updateShiftsInStorage([originalShiftCopy], true /*isOptimisticUpdate*/);
                        }

                        return Promise.reject(error);
                    });
                requestPromises.push(updateShiftPromise);
            }
        });

        return Promise.all(requestPromises).then((updatedShiftResponses: IUpdateShiftResponseEntity[]) => {
            // Even if we already optimistically updated the shifts, we need to call this.updateShiftsInStorage again in order to update the eTag
            let shiftsAndAlerts: IShiftResponseMultipleEntity = RuleViolationUtils.createShiftResponseMultipleEntity(updatedShiftResponses);
            return shiftsAndAlerts;
        });
    }

    /**
     * Adds a shift. If the optional boolean parameter is true, optimistically add the shift within the cache
     * (store) to update the UI. If the service responds with an error, delete the shift within the cache to revert the UI.
     * Also takes an isPublished boolean that sets the isPublished flag on the shift before making the API call.
     * @param {IShiftEntity} shift
     * @param {boolean} optimistic
     * @param {boolean} isPublished
     */
    public async addShift(shift: IShiftEntity, optimistic?: boolean, isPublished: boolean = false): Promise<IShiftResponseEntity> {
        let marker = "addShift";
        marker = InstrumentationService.perfMarkerStart(marker);
        shift.isPublished = isPublished;
        if (optimistic) {
            this.updateShiftsInStorage([shift], true /* isOptimisticUpdate */);
        }

        try {
            const shiftAndAlertEntity: IShiftResponseEntity = await RestClient.addShift(shift.tenantId, shift.teamId, shift);
            // we set a timeout and delay the actual cache updates so that
            // UI such as the add edit shift panel can quickly be closed before the time
            // consuming task of re-rendering the schedule.
            setTimeout(() => {
                // Even if we already optimistically added the shift, we need to call updateShiftsInStorage again in order to attach the eTag and update the db
                if (shiftAndAlertEntity) {
                    transaction(() => {
                        this.updateShiftsInStorage([shiftAndAlertEntity.shift], false /* isOptimisticUpdate */);
                        prependShiftsAsUniqueShifts([shiftAndAlertEntity.shift]);
                    });
                }
            }, 0);

            return shiftAndAlertEntity;
        } catch (httpError) {
            if (optimistic) {
                shift.state = ShiftStates.Deleted; // deleteShiftsInStorage will only delete shifts that are not meant to display on the scheduler
                this.deleteShiftsInStorage([shift], true /* isOptimisticUpdate */);
            }
            throw httpError;
        } finally {
            InstrumentationService.perfMarkerEnd(marker);
        }
    }

    /**
     * Adds shifts and open shifts. If the optional boolean parameter is true, optimistically add the shifts within the cache
     * (store) to update the UI. If the service responds with an error, delete the shifts within the cache to revert the UI.
     *
     * @param {String} tenantId
     * @param {String} teamId
     * @param {Array<IShiftEntity>} assignedShifts
     * @param {Array<IOpenShiftEntity>} openShifts
     * @param {Boolean} optimistic
     * @param {Boolean} isPublished - indicates whether the shifts to be added should be published by default
     */
    public async bulkAddShifts(tenantId: string, teamId: string, assignedShifts: Array<IShiftEntity> = [], openShifts: Array<IOpenShiftEntity> = [], optimistic?: boolean, isPublished: boolean = false): Promise<IBulkShiftResponseEntity> {
        let marker = "bulkAddShifts";
        marker = InstrumentationService.perfMarkerStart(marker);

        assignedShifts = assignedShifts ?
            assignedShifts.map((assignedShift) => {
                assignedShift.teamId = teamId;
                assignedShift.tenantId = tenantId;
                assignedShift.isPublished = isPublished;
                return assignedShift;
            }) :
            [];

        openShifts = openShifts ?
            openShifts.map(openShift => {
                openShift.teamId = teamId;
                openShift.tenantId = tenantId;
                openShift.isPublished = isPublished;
                return openShift;
            }) :
            [];

        if (optimistic) {
            this.updateShiftsInStorage(assignedShifts, true /*isOptimisticUpdate*/);
            this.updateOpenShiftsInStorage(openShifts, true /*isOptimisticUpdate*/);
        }

        try {
            const shiftsAndAlertEntity: IBulkShiftResponseEntity = await RestClient.bulkAddShifts(tenantId, teamId, assignedShifts, openShifts);

            // Even if we already optimistically added the shifts, we need to call updateShiftsInStorage again in order to attach the eTags and update the db
            if (shiftsAndAlertEntity) {
                this.updateShiftsInStorage(shiftsAndAlertEntity.shifts, false /* isOptimisticUpdate */);
                this.updateOpenShiftsInStorage(shiftsAndAlertEntity.openShifts, false /* isOptimisticUpdate */);
                prependShiftsAsUniqueShifts(shiftsAndAlertEntity.shifts.concat(shiftsAndAlertEntity.openShifts));
            }
            return shiftsAndAlertEntity;
        } catch (httpError) {
            // If the add failed and was optimistic, we'll reverse the add by changing the state of the shifts back to deleted
            // and deleting them from our cache
            if (optimistic) {
                assignedShifts.forEach((assignedShift) => assignedShift.state = ShiftStates.Deleted /* deleteShiftsInStorage will only delete shifts that are not meant to display on the scheduler*/);
                openShifts.forEach((openShift) => openShift.state = ShiftStates.Deleted /* deleteOpenShiftsInStorage will only delete shifts that are not meant to display on the scheduler*/);
                this.deleteShiftsInStorage(assignedShifts, true /* isOptimisticUpdate */);
                this.deleteOpenShiftsInStorage(openShifts, true /* isOptimisticUpdate */);
            }
            throw httpError;
        } finally {
            InstrumentationService.perfMarkerEnd(marker);
        }
    }

    /**
     * Get CopyOfShifts when copied from given time duration to different
     * @param {String} teamId
     * @param {Moment} sourceStartTime - start of timerange for shifts to copy from
     * @param {Moment} sourceEndTime - end of timerange for shifts to copy from
     * @param {Number} destinationDaysOffset - offset of days for each shift to copy to
     * @param {Boolean} copyNotes - copy notes from source to dest
     * @param {Boolean} copyAbsenceShifts - copy abscence shifts from source to dest
     * @param {Boolean} copySubshifts - copy subshifts from source to dest
     * @param {Boolean} copyOpenShifts - copy open shifts from source to dest
     * @returns {Object} Promise that resolves to list of shifts assigned to given team
     */
    public async getCopyOfShifts(teamId: string, sourceStartTime: Moment, sourceEndTime: Moment, destinationDaysOffset: number, copyNotes: boolean, copyAbsenceShifts: boolean, copySubshifts: boolean, copyOpenShifts: boolean): Promise<CopyOfShiftsResponse> {
        let marker = "getCopyOfShifts";
        marker = InstrumentationService.perfMarkerStart(marker);

        try {
            return await RestClient.getCopyOfShifts(this.tenantId, teamId, sourceStartTime, sourceEndTime, destinationDaysOffset, copyNotes, copyAbsenceShifts, copySubshifts, copyOpenShifts);
        } finally {
            InstrumentationService.perfMarkerEnd(marker);
        }
    }

    /**
     * Update shifts in memory (and asynchronously in the db)
     * @param shifts Updated shifts
     * @param {boolean} isOptimisticUpdate - When true, the shift is being updated optimistically and should only be updated in memory
     * @param shiftStoreType - ShiftStoreSchema type to update ShiftStore/MyShiftStore
     */
    public updateShiftsInStorage(shifts: IShiftEntity[], isOptimisticUpdate: boolean): void {
        if (!shifts || !shifts.length) {
            return;
        }
        const myShifts = shifts.filter((shift => {
            return ShiftUtils.isCurrentUserShift(shift);
        }));
        if (shifts[0].teamId === TeamStore().teamId) {

            // Update the in-memory store if the shifts are for the current team
            updateShifts(shifts, isOptimisticUpdate, ShiftStoreTypes.ShiftStore);
            if (myShifts.length > 0 ) {
                updateShifts(myShifts, isOptimisticUpdate, ShiftStoreTypes.MyShiftStore);
            }
        }
        if (!isOptimisticUpdate) {
            // asynchronously update the database
            setTimeout(async () => {
                await this.shiftDatabase.setShifts(shifts);
                if (myShifts.length > 0 ) {
                    await this.myShiftDatabase.setShifts(myShifts);
                }
            }, 0);
        }
    }

    /**
     * Delete shifts in memory (and asynchronously in the db)
     * @param shifts Delete shifts
     * @param {boolean} isOptimisticUpdate - When true, the shift is being updated optimistically and should only be updated in memory
     */
    public deleteShiftsInStorage(shifts: IShiftEntity[], isOptimisticUpdate: boolean): void {
        if (!shifts || !shifts.length) {
            return;
        }

        let shiftsToSoftDelete: IShiftEntity[] = [];
        let shiftsToHardDelete: IShiftEntity[] = [];

        // We use clones of the argument entities. This is because setting the entities in the satchel stores will mark them as observables
        // and any callers who still hold references to these entities will not be able to mutate them (in event of an error, for example) without
        // triggering mobx exceptions
        const shiftsToProcess: Array<IShiftEntity> = shifts.map(shift => ShiftEntity.clone(shift));
        shiftsToProcess.map((shiftModel) => {
            // If the deleted shift is not intended to be displayed in the schedule, remove it from the store
            if (!ShiftUtils.isShiftDisplayableForScheduleView(shiftModel)) {
                shiftsToHardDelete.push(shiftModel);
            } else {
                // shift is not deleted because it is displayable. Just update the cache
                shiftsToSoftDelete.push(shiftModel);
            }
        });

        // update the shifts and myShifts that are soft deleted because they are still displayable
        if (shiftsToSoftDelete.length) {
            this.updateShiftsInStorage(shiftsToSoftDelete, isOptimisticUpdate);
        }

        // Remove the hard deleted shifts from memory and the db
        if (shiftsToHardDelete.length) {
            const myShiftsToHardDelete = shiftsToHardDelete.filter((shift => {
                return ShiftUtils.isCurrentUserShift(shift);
            }));
            if (shiftsToHardDelete[0].teamId === TeamStore().teamId) {
                // Update the in-memory store if the shifts and myshifts are for the current team
                deleteShifts(shiftsToHardDelete, ShiftStoreTypes.ShiftStore);
                if (myShiftsToHardDelete.length > 0) {
                    deleteShifts(myShiftsToHardDelete, ShiftStoreTypes.MyShiftStore);
                }
            }

            if (!isOptimisticUpdate) {
                // asynchronously update the both databases
                setTimeout(async () => {
                    await this.shiftDatabase.deleteShifts(shiftsToHardDelete[0].teamId, shiftsToHardDelete.map((shiftDeleted: IShiftEntity) => shiftDeleted.id));
                    if (myShiftsToHardDelete.length > 0) {
                        await this.myShiftDatabase.deleteShifts(myShiftsToHardDelete[0].teamId, myShiftsToHardDelete.map((shiftDeleted: IShiftEntity) => shiftDeleted.id));
                    }
                }, 0);
            }
        }

    }

    /*
     * Deletes a shift. If optional parameter optimistic is true, delete the shift within the cache
     * (store) to immediately update the UI. If the API call fails, replace the shift again by updating it within the cache
     * with its previous state. Also takes an isPublished boolean that sets the isPublished flag on the shift before making the API call.
     * @param {IShiftEntity} shift
     * @param {IShiftEntity} optimistic
     * @param {boolean} isPublished
     */
    public async deleteShift(shift: IShiftEntity, optimistic?: boolean, isPublished: boolean = false): Promise<IShiftResponseEntity> {
        let marker = "deleteShift";
        marker = InstrumentationService.perfMarkerStart(marker);
        // Get the latest eTag for the shift before making a network request
        const lastCachedShift = ShiftStore().shifts.get(shift.id);
        if (lastCachedShift) {
            shift.eTag = lastCachedShift.eTag;
        }

        const cachedState = shift.state;
        const cachedIsPublished = shift.isPublished;
        shift.isPublished = isPublished;
        if (optimistic) {
            shift.state = ShiftStates.Deleted;
            this.updateShiftsInStorage([shift], true /* isOptimisticUpdate */);
        }

        try {
            const shiftAndAlertEntity: IShiftResponseEntity = await RestClient.deleteShift(shift.tenantId, shift.teamId, shift);

            // we set a timeout to delay the actual cache updates so that
            // UI such as the add edit shift panel can quickly be closed before the time
            // consuming task of re-rendering the schedule.
            setTimeout(() => {
                if (shiftAndAlertEntity) {
                    this.deleteShiftsInStorage([shiftAndAlertEntity.shift], false /* isOptimisticUpdate */);
                }
            }, 0);

            return shiftAndAlertEntity;
        } catch (httpError) {
            if (optimistic) {
                shift.state = cachedState;
                shift.isPublished = cachedIsPublished;
                this.updateShiftsInStorage([shift], true /* isOptimisticUpdate */);
            }
            throw httpError;
        } finally {
            InstrumentationService.perfMarkerEnd(marker);
        }
    }

    /*
     * Deletes shifts. If optional parameter optimistic is true, delete the shifts within the cache
     * (store) to immediately update the UI. If the API call fails, replace the shifts again by updating them within the cache
     * with their previous state. Takes isPublished parameter which sets the isPublished flag on the shift.
     * @param {Array<IShiftEntity>} shifts
     * @param {IShiftEntity} optimistic
     * @param {boolean} isPublished
     */
    public async deleteShifts(shifts: Array<IShiftEntity>, optimistic?: boolean, isPublished: boolean = false): Promise<IShiftResponseMultipleEntity> {
        let requestPromises = new Array<any>();

        // Keep a clone of each shift prior to the delete so that we can revert optimistic changes upon errors
        let originalShifts: Map<string, IShiftEntity> = new Map<string, IShiftEntity>();

        for (let i = 0; i < shifts.length; i++) {
            const shift: IShiftEntity = shifts[i];
            originalShifts.set(shift.id, ShiftEntity.clone(shift));

            // update the published state of the shift. if we set it to true, we are sharing this delete
            shift.isPublished = isPublished;

            // If we are optimistically deleting this shift, manually set the state to deleted
            if (optimistic) {
                shift.state = ShiftStates.Deleted;
            }

            // Get the latest eTag for the shift before making a network request
            const lastCachedShift = ShiftStore().shifts.get(shift.id);
            if (lastCachedShift) {
                shift.eTag = lastCachedShift.eTag;
            }
        }

        // If we are optimistically deleting this shift, update the shifts in the cache
        if (optimistic) {
            this.updateShiftsInStorage(shifts, true /* isOptimisticUpdate */);

        }

        // Create a promise to delete this shift and add it to the list of delete shift promises
        for (let i = 0; i < shifts.length; i++) {
            const shift: IShiftEntity = shifts[i];

            let deleteShiftPromise = RestClient.deleteShift(shift.tenantId, shift.teamId, shift)
                .then((shiftAndAlertEntity: IShiftResponseEntity) => {
                    return Promise.resolve(shiftAndAlertEntity);
                })
                .catch((error: StaffHubHttpError) => {
                    // if we optimistically deleted this shift and now see an error, we revert the optimistic change with the original, undeleted shift
                    if (optimistic) {
                        this.updateShiftsInStorage([originalShifts.get(shift.id)], true /* isOptimisticUpdate */);
                    }

                    return Promise.reject(error);
                });
            requestPromises.push(deleteShiftPromise);
        }

        return Promise.all(requestPromises).then((updatedDeletedShifts: IShiftResponseEntity[]) => {
            // Even if we already optimistically added the shifts, we need to call this.updateShiftsInStorage again in order to attach the eTag
            // We also update the shifts in the store as we may still wish to render deleted shifts
            let shiftsAndAlerts = RuleViolationUtils.createShiftResponseMultipleEntity(updatedDeletedShifts);
            if (shiftsAndAlerts && shiftsAndAlerts.shifts.length) {
                this.deleteShiftsInStorage(shiftsAndAlerts.shifts, false /* isOptimisticUpdate */);
            }
            return shiftsAndAlerts;
        });
    }

    /**
     * Send addOpenShift request to StaffHub service and update store with response data
     * @param tenantId
     * @param teamId
     * @param openShift
     * @param optimistic
     * @param isPublished
     */
    public async addOpenShift(
        tenantId: string,
        teamId: string,
        openShift: IOpenShiftEntity,
        optimistic: boolean = false,
        isPublished: boolean = false): Promise<IOpenShiftEntity> {
        let marker = "addOpenShift";
        marker = InstrumentationService.perfMarkerStart(marker);

        openShift.isPublished = isPublished;
        if (optimistic) {
            this.updateOpenShiftsInStorage([openShift], true /* isOptimistic */);
        }

        try {
            const addedOpenShift: IOpenShiftEntity = await RestClient.addOpenShift(tenantId, teamId, openShift);
            this.updateOpenShiftsInStorage([addedOpenShift], false /* isOptimisticUpdate */);
            prependShiftsAsUniqueShifts([addedOpenShift]);
            return addedOpenShift;
        } catch (error) {
            if (optimistic) {
                openShift.state = ShiftStates.Deleted;
                this.deleteOpenShiftsInStorage([openShift], true /* isOptimistic */);
            }
            throw error;
        } finally {
            InstrumentationService.perfMarkerEnd(marker);
        }
    }

    /**
     * Send updateOpenShift request to StaffHub service and update store with response data
     * @param tenantId
     * @param teamId
     * @param openShift
     * @param optimistic
     * @param isPublished
     */
    public async updateOpenShift(
        tenantId: string,
        teamId: string,
        openShift: IOpenShiftEntity,
        optimistic: boolean = false,
        isPublished: boolean = false): Promise<IOpenShiftEntity> {
        let marker = "updateOpenShift";
        marker = InstrumentationService.perfMarkerStart(marker);

        openShift.isPublished = isPublished;

        // Get the latest eTag for the shift before making a network request
        const lastCachedShift = OpenShiftStore().openShifts.get(openShift.id);
        if (lastCachedShift) {
            openShift.eTag = lastCachedShift.eTag;
        }

        let originalOpenShift: IOpenShiftEntity = null;
        if (optimistic && lastCachedShift) {
            originalOpenShift = OpenShiftEntity.clone(openShift);
            this.updateOpenShiftsInStorage([openShift], true /* isOptimisticUpdate */);
        }

        try {
            const updatedOpenShift: IOpenShiftEntity = await RestClient.updateOpenShift(tenantId, teamId, openShift);
            this.updateOpenShiftsInStorage([updatedOpenShift], false /* isOptimisticUpdate */);
            prependShiftsAsUniqueShifts([updatedOpenShift]);
            return updatedOpenShift;
        } catch (error) {
            if (optimistic && originalOpenShift) {
                this.updateOpenShiftsInStorage([originalOpenShift], true /* isOptimisticUpdate */);
            }
            throw error;
        } finally {
            InstrumentationService.perfMarkerEnd(marker);
        }

    }

    /**
     * Send updateOpenShifts request to StaffHub service and update store with response data
     * @param tenantId
     * @param teamId
     * @param openShifts
     * @param optimistic
     * @param isPublished
     */
    public updateOpenShifts(
        tenantId: string,
        teamId: string,
        openShifts: IOpenShiftEntity[],
        optimistic: boolean = false,
        isPublished: boolean = false): Promise<IOpenShiftEntity[]> {
        let marker = "updateOpenShifts";
        marker = InstrumentationService.perfMarkerStart(marker);

        let requestPromises: Promise<{}>[] = [];
        openShifts.forEach((openShift: IOpenShiftEntity) => {
            requestPromises.push(this.updateOpenShift(tenantId, teamId, openShift, optimistic, isPublished));
        });

        return Promise.all(requestPromises).then((updatedOpenShifts: IOpenShiftEntity[]) => {
            InstrumentationService.perfMarkerEnd(marker);
            return updatedOpenShifts;
        });
    }

    /**
    * Send deleteOpenShift request to StaffHub service and update store with response data
    * @param tenantId
    * @param teamId
    * @param openShift
    * @param optimistic
    * @param isPublished
    */
    public async deleteOpenShift(
        tenantId: string,
        teamId: string,
        openShift: IOpenShiftEntity,
        optimistic: boolean = false,
        isPublished: boolean = false): Promise<IOpenShiftEntity> {
        let marker = "deleteOpenShift";
        marker = InstrumentationService.perfMarkerStart(marker);

        const originalShiftState: ShiftState = openShift.state;
        const originalPublishedState: boolean = openShift.isPublished;

        openShift.isPublished = isPublished;

        // Get the latest eTag for the shift before making a network request
        const lastCachedShift = OpenShiftStore().openShifts.get(openShift.id);
        if (lastCachedShift) {
            openShift.eTag = lastCachedShift.eTag;
        }

        if (optimistic) {
            openShift.state = ShiftStates.Deleted;
            this.deleteOpenShiftsInStorage([openShift], true /* isOptimisticUpdate */);
        }

        try {
            const deletedOpenShift: IOpenShiftEntity = await RestClient.deleteOpenShift(tenantId, teamId, openShift);
            this.deleteOpenShiftsInStorage([deletedOpenShift], false /* isOptimisticUpdate */);
            return deletedOpenShift;
        } catch (error) {
            if (optimistic) {
                openShift.state = originalShiftState;
                openShift.isPublished = originalPublishedState;
                this.updateOpenShiftsInStorage([openShift], true /* isOptimisticUpdate */);
            }
            throw error;
        }
    }

    /**
     * Send deleteOpenShifts request to StaffHub service and update store with response data
     * @param tenantId
     * @param teamId
     * @param openShifts
     * @param optimistic
     * @param isPublished
     */
    public deleteOpenShifts(
        tenantId: string,
        teamId: string,
        openShifts: IOpenShiftEntity[],
        optimistic: boolean = false,
        isPublished: boolean = false): Promise<IOpenShiftEntity[]> {
        let marker = "deleteOpenShifts";
        marker = InstrumentationService.perfMarkerStart(marker);

        let requestPromises: Promise<{}>[] = [];
        openShifts.forEach((openShift: IOpenShiftEntity) => {
            requestPromises.push(this.deleteOpenShift(tenantId, teamId, openShift, optimistic, isPublished));
        });

        return Promise.all(requestPromises).then((deletedOpenShifts: IOpenShiftEntity[]) => {
            InstrumentationService.perfMarkerEnd(marker);
            return deletedOpenShifts;
        });
    }

    /**
     * Send assignOpenShift request to StaffHub service and update store with response data
     * @param tenantId
     * @param teamId
     * @param openShift
     * @param memberId
     * @param tagId
     * @param startTime
     * @param endTime
     */
    public async assignOpenShift(
        tenantId: string,
        teamId: string,
        openShift: IOpenShiftEntity,
        memberId: string,
        tagId: string,
        startTime?: Moment,
        endTime?: Moment): Promise<IAssignOpenShiftResponseEntity> {
        let marker = "assignOpenShift";
        marker = InstrumentationService.perfMarkerStart(marker);

        // Get the latest eTag for the open shift before making a network request
        const lastCachedOpenShift = OpenShiftStore().openShifts.get(openShift.id);
        if (lastCachedOpenShift) {
            openShift.eTag = lastCachedOpenShift.eTag;
        }

        // Normalize the default tag
        if (TagUtils.isDefaultTag(tagId)) {
            tagId = "";
        }

        try {
            const assignOpenShiftResponse: IAssignOpenShiftResponseEntity = await RestClient.assignOpenShift(tenantId, teamId, openShift, memberId, tagId, startTime, endTime);
            this.updateOpenShiftsInStorage([assignOpenShiftResponse.openShift], false /* isOptimisticUpdate */);
            this.updateShiftsInStorage([assignOpenShiftResponse.assignedShift], false /* isOptimisticUpdate */);
            return assignOpenShiftResponse;
        } finally {
            InstrumentationService.perfMarkerEnd(marker);
        }
    }

    /**
     * Update open shifts in memory (and asynchronously in the db)
     * @param openShifts Updated open shifts
     * @param {boolean} isOptimisticUpdate - When true, the open shift is being updated optimistically and should only be updated in memory
     */
    public updateOpenShiftsInStorage(openShifts: IOpenShiftEntity[], isOptimisticUpdate: boolean): void {
        if (!openShifts || !openShifts.length) {
            return;
        }

        if (openShifts[0].teamId === TeamStore().teamId) {
            // Update the in-memory store if the open shifts are for the current team
            updateOpenShiftsInStore(openShifts);
        }

        if (!isOptimisticUpdate) {
            // asynchronously update the database
            setTimeout(async () => {
                await this.openShiftDatabase.setOpenShifts(openShifts);
            }, 0);
        }
    }

    /**
     * Delete openshifts in memory (and asynchronously in the db)
     * @param openShifts Deleted open shifts
     * @param {boolean} isOptimisticUpdate - When true, the shift is being updated optimistically and should only be updated in memory
     */
    public deleteOpenShiftsInStorage(openShifts: IOpenShiftEntity[], isOptimisticUpdate: boolean): void {
        if (!openShifts || !openShifts.length) {
            return;
        }

        let openShiftsToSoftDelete: IOpenShiftEntity[] = [];
        let openShiftsToHardDelete: IOpenShiftEntity[] = [];

        // We use clones of the argument entities. This is because setting the entities in the satchel stores will mark them as observables
        // and any callers who still hold references to these entities will not be able to mutate them (in event of an error, for example) without
        // triggering mobx exceptions
        const openShiftsToProcess: Array<IOpenShiftEntity> = openShifts.map(openShift => OpenShiftEntity.clone(openShift));
        openShiftsToProcess.map((openShiftModel) => {
            // If the deleted shift is not intended to be displayed in the schedule, remove it from the store
            if (!ShiftUtils.isOpenShiftDisplayableForScheduleView(openShiftModel)) {
                openShiftsToHardDelete.push(openShiftModel);
            } else {
                // shift is not deleted because it is displayable. Just update the cache
                openShiftsToSoftDelete.push(openShiftModel);
            }
        });

        // update the shifts that are soft deleted because they are still displayable
        if (openShiftsToSoftDelete.length) {
            this.updateOpenShiftsInStorage(openShiftsToSoftDelete, isOptimisticUpdate);
        }
        // Remove the hard deleted shifts from memory and the db
        if (openShiftsToHardDelete.length) {
            if (openShifts[0].teamId === TeamStore().teamId) {
                // Update the in-memory store if the open shifts are for the current team
                deleteOpenShiftsInStore(openShiftsToHardDelete);
            }

            if (!isOptimisticUpdate) {
                // asynchronously update the database
                setTimeout(async () => {
                    await this.openShiftDatabase.deleteOpenShifts(openShiftsToHardDelete[0].teamId, openShiftsToHardDelete.map((openShiftDeleted: IOpenShiftEntity) => openShiftDeleted.id));
                }, 0);
            }
        }
    }

    /**
     * FOR TESTING PURPOSES ONLY
     * Deletes all shifts found in the store that have ids containing the test data string constant. To ensure desired test
     * data is deleted, navigate consecutively, month-by-month or week-by-week through the desired range before calling this function.
     */
    public async deleteTestShifts(): Promise<IShiftResponseMultipleEntity> {
        let shiftsToDelete: IShiftEntity[] = [];
        let myShiftsToDelete: IShiftEntity[] = [];
        ShiftStore().shifts.forEach((shift: IShiftEntity) => {
            if (shift.id.startsWith(`SHFT_${TestDataIDConstant}`) && !ShiftUtils.isDeletedShift(shift)) {
                shiftsToDelete.push(shift);
            }
        });

        MyShiftStore().shifts.forEach((shift: IShiftEntity) => {
            if (shift.id.startsWith(`SHFT_${TestDataIDConstant}`) && !ShiftUtils.isDeletedShift(shift)) {
                myShiftsToDelete.push(shift);
            }
        });

        if (myShiftsToDelete.length) {
            this.deleteShifts(myShiftsToDelete, false /* optimistic*/, true /*isPublished*/);
        }

        if (shiftsToDelete.length) {
            return this.deleteShifts(shiftsToDelete, false /* optimistic*/, true /*isPublished*/);
        }
    }

    /**
     * FOR TESTING PURPOSES ONLY
     * Deletes all shifts found in the store that have ids containing the test data string constant. To ensure desired test
     * data is deleted, navigate consecutively, month-by-month or week-by-week through the desired range before calling this function.
     */
    public async deleteTestOpenShifts(): Promise<IOpenShiftEntity[]> {
        let openShiftsToDelete: IOpenShiftEntity[] = [];
        OpenShiftStore().openShifts.forEach((openShift: IOpenShiftEntity) => {
            if (openShift.id.startsWith(`OPNSHFT_${TestDataIDConstant}`) && !ShiftUtils.isDeletedShift(openShift)) {
                openShiftsToDelete.push(openShift);
            }
        });

        if (openShiftsToDelete.length) {
            return this.deleteOpenShifts(openShiftsToDelete[0].tenantId, openShiftsToDelete[0].teamId, openShiftsToDelete, false /* optimistic*/, true /*isPublished*/);
        }
    }

    /**
     * Resets the sync state
     */
    public async resetSyncState() {
        await this.shiftDatabase.deleteShiftsSyncState(this.sessionId);
        await this.myShiftDatabase.deleteShiftsSyncState(this.sessionId);
        await this.openShiftDatabase.deleteOpenShiftsSyncState(this.sessionId);
    }

    /**
     * Delete the old sessions
     * @param sessionIds List of sessionIds to delete
     */
    public async deleteSessionData(sessionIds: string[]) {
        await this.shiftDatabase.deleteSessionData(sessionIds);
        await this.myShiftDatabase.deleteSessionData(sessionIds);
        await this.openShiftDatabase.deleteSessionData(sessionIds);
    }
}

const service: IShiftDataService = new ShiftDataService();
export default service;