import * as MemoizeUtils from "sh-application/utility/MemoizeUtils";
import resetSchedulesViewStateStore from "../actions/resetSchedulesViewStateStore";
import schedulesViewStateStore from "../store/store";
import updateIsDataInDateRangeLoaded from "sh-application/components/schedules/lib/mutators/updateIsDataInDateRangeLoaded";
import updateScheduleViewDimensions from "../actions/updateScheduleViewDimensions";
import { Moment } from "moment";
import { orchestrator } from "satcheljs";
import { resetAvailabilityStore } from "sh-stores/sh-availability-store";
import { resetChangeStore } from "sh-stores/sh-change-store";
import { resetConflictStore, resetDismissedConflictStore, resetShiftsByMemberAndDateStore } from "sh-conflict-store";
import { resetMyShiftStore, resetShiftStore } from "sh-shift-store";
import { resetNoteStore } from "sh-note-store";
import { resetOpenShiftsStore } from "sh-open-shift-store";
import { resetShiftRequestsStore } from "sh-shiftrequests-store";
import { resetTimeClockEntryStore } from "sh-stores/sh-timeclock-store";
import { resetUniqueShiftStore } from "sh-stores/sh-uniqueshift-store";
import { resetUniqueSubshiftStore } from "sh-uniquesubshift-store";
import { TeamSettingsStore } from "sh-stores/sh-teamsettings-store";
import { timeZoneChanged } from "sh-teamsettings-store";
import { tz } from "moment-timezone";

/**
 * When the time zone is changed we must update the default timezone of the moment library and purge all the cache data that is used for date manipulation in the scheduler.
 * This includes shifts, open shifts, requests, and notes data. We must also update the timezones of the dates in the scheduler view state (selected, viewStart, viewEnd), which
 * happens when we call updateScheduleViewDimensions with an updated selection date
 */
export default orchestrator(timeZoneChanged, actionMessage => {
    if (actionMessage.timeZone && !TeamSettingsStore().timeZoneOlsonCode) {
        tz.setDefault(actionMessage.timeZone);
        /* NOTE: All schedule data containing moment objects must be purged here and refetched. Keep this updated as new entities are added to the app. */
        resetShiftStore();
        resetMyShiftStore();
        resetOpenShiftsStore();
        resetShiftRequestsStore();
        resetNoteStore();
        resetConflictStore();
        resetDismissedConflictStore();
        resetShiftsByMemberAndDateStore();
        resetAvailabilityStore();
        resetChangeStore();
        resetUniqueSubshiftStore();
        resetUniqueShiftStore();
        resetSchedulesViewStateStore();
        resetTimeClockEntryStore();

        // clear memoize caches
        MemoizeUtils.clearMemoizeStores();

        const viewState = schedulesViewStateStore();
        if (viewState && viewState.viewSelectedDate) {
            // setting isDataInDateRangeLoaded to false ensures we will fetch data during updateScheduleViewDimensions
            updateIsDataInDateRangeLoaded(viewState, false);
            // Updating the selected date with a clone that has its timezone updated ensures that the schedule's selected date, start, and end dates
            // are all updated with the correct timezone
            const updatedSelectedDate: Moment =  viewState.viewSelectedDate.clone().tz(actionMessage.timeZone);
            updateScheduleViewDimensions(viewState, false /* isTeamBootstrap */, null /*employeeViewType*/, null /*scheduleCalendarType*/, null /*scheduleOverViewType*/, updatedSelectedDate, null /* isViewGrouped */);
        }
    }
});
