import { AppDb } from "sh-services/data/AppDb";
import { AppSettingsClientModel, AppSettingsServiceModel } from "sh-models";
import { InstrumentationService } from "sh-services";
import { ITenantDatabase } from "./ITenantDatabase";

/**
 * Tenant Database Accessor
 */
export class TenantDatabase implements ITenantDatabase {
    private db: AppDb;

    constructor(db: AppDb) {
        this.db = db;
    }

    /**
     * Fetch tenant app settings
     */
    public async getTenantAppSettings(tenantId: string): Promise<AppSettingsClientModel> {
        if (!this.db) {
            return null;
        }

        try {
            const appSettingsServiceModel: AppSettingsServiceModel = await this.db.tenantAppSettings.get(tenantId);
            // Convert to client model object
            return AppSettingsServiceModel.toClientModel(appSettingsServiceModel);
        } catch (error) {
            InstrumentationService.trackException(error, "getTenantAppSettingsFromDb");
        }
    }

    /**
     * Set Tenant AppSettings in Database
     * @param tenantAppSettings tenant app settings
     */
    public async setTenantAppSettings(tenantAppSettings: AppSettingsClientModel): Promise<void> {
        if (!this.db) {
            return;
        }

        try {
            await this.db.tenantAppSettings.put(AppSettingsServiceModel.fromClientModel(tenantAppSettings));
        } catch (error) {
            InstrumentationService.trackException(error, "setTenantAppSettingsInDb");
        }
    }
}