import arrayMove from "sh-application/utility/arrayMove";
import { mutatorAction } from "satcheljs";
import { ReorderTagsPanelViewState } from "sh-application/store/schema/ReorderTagsPanelViewState";

/**
 * Reorders the tags in the view state tag array
 * Called when drag drop happens in the tags panel
 * @param viewState - Tags Panel view state
 * @param oldIndex - Old index of the row being moved
 * @param newIndex - New index of the where the row is being moved to
 */
export default mutatorAction("REORDER_TAGS",
    function reorderTags(viewState: ReorderTagsPanelViewState, oldIndex: number, newIndex: number) {
        let tags = viewState.activeSortedTags;

        if (oldIndex !== newIndex) {
            // limit the new index to the length of the active sorted tag list
            arrayMove(tags, oldIndex, newIndex);

            // WORKAROUND: since TagsPanel is set to be reactive to viewState, changes inside an array in the view state doesn't cause re-render
            // and so setting the the array to null and then back to tags
            // need to revisit use of reactive for components
            viewState.activeSortedTags = null;
            viewState.activeSortedTags = tags;
        }
    }
);