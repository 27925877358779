import { ITeamInfoEntity } from "sh-models";
import { getGenericEventPropertiesObject } from "sh-instrumentation";
import { InstrumentationService } from "sh-services";
import TeamSetupPickerUtils from "../TeamSetupPickerUtils";
import scheduleTeamPickerPaneStore, { setIsTeamPickerPaneDataLoading } from "./store/store";
import { TeamDataService } from "sh-services";

export interface ScheduleTeamPickerProps {
    instrumentationEventName: string;                   // Instrumentation event name
    onTeamSelected: (team: ITeamInfoEntity) => void;    // Handler for when a schedule team is selected
    onCreateNewScheduleClicked: () => void;             // Handler for when the Create new schedule button is clicked. Set to null to hide the button.
    autofocus?: boolean;                                // true to enable setting initial focus when the UX is launched
}

export default class ScheduleTeamPickerUtils {

    public static loadInitialData = async (instrumentationEventName: string): Promise<void> => {
        setIsTeamPickerPaneDataLoading(true /* isDataLoading */);

        try {
            const teamInfos: ITeamInfoEntity[] = await TeamDataService.getTeams();
            ScheduleTeamPickerUtils.instrumentLoadScreen(instrumentationEventName, teamInfos);
        } catch (error) {
            TeamSetupPickerUtils.handleStaffHubServiceError(error);
        } finally {
            setIsTeamPickerPaneDataLoading(false /* isDataLoading */);
        }
    }

    /**
     * Instrument load of this view
     */
    private static instrumentLoadScreen(eventName: string, teamInfos: ITeamInfoEntity[]) {
        let totalNumTeams: number = 0;
        let staffHubTeams: ITeamInfoEntity[] = [];
        if (teamInfos) {
            totalNumTeams = teamInfos.length;
            staffHubTeams = teamInfos.filter((teamInfo: ITeamInfoEntity) => TeamSetupPickerUtils.isStaffHubTeam(teamInfo));
        }
        const numStaffHubTeams: number = staffHubTeams.length;

        InstrumentationService.logEvent(
            eventName,
            [
                getGenericEventPropertiesObject(InstrumentationService.properties.EventType, InstrumentationService.values.ScheduleTeamPickerViewed),
                getGenericEventPropertiesObject(InstrumentationService.properties.TotalNumTeams, totalNumTeams),
                getGenericEventPropertiesObject(InstrumentationService.properties.NumStaffHubTeams, numStaffHubTeams)
            ]);
    }

    /**
     * Comparator for schedule TeamInfo items
     */
    public static scheduleTeamInfoComparator(firstTeamInfo: ITeamInfoEntity, secondTeamInfo: ITeamInfoEntity): number {
        // Sort order:
        // - Recently viewed (NYI)
        // - Schedule teams associated with MS Teams
        // - StaffHub teams (staffhub.com only)
        // - Alphabetical order by team name

        if (!firstTeamInfo && !secondTeamInfo) {
            return 0;
        } else if (!secondTeamInfo) {
            return -1;
        } else if (!firstTeamInfo) {
            return 1;
        } else {
            // Schedule teams associated with MS Teams come before staffhub.com schedule teams
            const isFirstTeamStaffHub: boolean = TeamSetupPickerUtils.isStaffHubTeam(firstTeamInfo);
            const isSecondTeamStaffHub: boolean = TeamSetupPickerUtils.isStaffHubTeam(secondTeamInfo);

            if (!isFirstTeamStaffHub && isSecondTeamStaffHub) {
                return -1;
            } else if (isFirstTeamStaffHub && !isSecondTeamStaffHub) {
                return 1;
            } else {
                // Otherwise sort by team name
                return TeamSetupPickerUtils.teamNameComparator(firstTeamInfo.name, secondTeamInfo.name);
            }
        }
    }

    /**
     * Returns true if the schedule teams data is currently being fetched
     */
    public static isScheduleTeamsDataLoading(): boolean {
        return scheduleTeamPickerPaneStore.isDataLoading;
    }
}
