import { ITeamInfoEntity } from "sh-models";
import { mutatorAction } from "satcheljs";
import { TeamInfoStore } from "sh-stores";

export default mutatorAction('UPDATE_TEAMS_IN_STORE', function updateTeamsInStore(teams: ITeamInfoEntity[], resetExistingTeams: boolean = false) {
    if (resetExistingTeams) {
        // reset all existing teams if this flag is set
        TeamInfoStore().teams.clear();
    }
    teams.map((teamInfo: ITeamInfoEntity) => {
        TeamInfoStore().teams.set(teamInfo.id, teamInfo);
    });
});
