import { ITeamPermissionEntity, TeamPermissionEntity } from "../../sh-models";
import RestClient from "../../sh-rest-client";
import { TeamPermissionsStore, setTeamPermissions } from "../../sh-stores/sh-team-permissions-store";
import { ITeamDatabase } from "../data/ITeamDatabase";

import { DataProvider } from "./DataProvider";

/**
 * Team Permission Data Provider
 */
export class TeamPermissionsDataProvider extends DataProvider<ITeamPermissionEntity>{
    protected teamDatabase: ITeamDatabase;
    protected tenantId: string;
    protected teamId: string;

    /**
     * Constructor for TeamPermissionsDataProvider.
     * @param teamDatabase Team Database.
     * @param teamId Team Id.
     * @param tenantId Tenant Id.
     */
    constructor(teamDatabase: ITeamDatabase, tenantId: string, teamId: string) {
        super();
        this.teamDatabase = teamDatabase;
        this.teamId = teamId;
        this.tenantId = tenantId;
    }

    /**
     * Return data from memory.
     * @returns {ITeamPermissionEntity} data.
     */
    async getDataFromMemory(): Promise<ITeamPermissionEntity> {
        return TeamPermissionsStore().permission;
    }

    /**
     * Return data if it's found in the database (otherwise return undefined).
     */
    async getDataFromDatabase(): Promise<ITeamPermissionEntity> {
        return await this.teamDatabase.getTeamPermissions(this.teamId);
    }

    /**
     * Return default data.
     * @returns {ITeamPermissionEntity} Data.
     */
    async getDefaultData(): Promise<ITeamPermissionEntity> {
        // set TeamPermissionsStore with default TeamPermissionEntity
        return new TeamPermissionEntity();
    }

    /**
     * Make an HTTP request to fetch the data from the network.
     * @returns {ITeamPermissionEntity} Data.
     */
    async getDataFromNetwork(): Promise<ITeamPermissionEntity> {
        const latestResponse = await RestClient.getTeamPermissions(this.tenantId, this.teamId);
        return latestResponse;
    }

    /**
     * Set data in memory.
     * @param data ITeamPermissionEntity.
     */
    async setDataInMemory(data: ITeamPermissionEntity) {
        setTeamPermissions(data);
    }

    /**
     * Set data in the database.
     * @param data ITeamPermissionEntity.
     */
    async setDataInDatabase(data: ITeamPermissionEntity) {
        if (data) {
            return await this.teamDatabase.setTeamPermissions(this.teamId, data);
        }
    }

    /**
     * Make a network call to update the data.
     */
    async saveDataToNetwork(data: ITeamPermissionEntity): Promise<ITeamPermissionEntity> {
        throw new Error("TeamPermissionDataProvider.saveDataToNetwork not implemented");
    }
}