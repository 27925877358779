import * as moment from "moment";
import PollHelper from "sh-application/utility/PollHelper";
import { AppSettingsClientModel } from "sh-models";
import { getAppSettingsForTeam } from "sh-appsettings-store";
import { StaffHubErrorCodes, TenantDataService } from "sh-services";

class FlightSettingsPollingService {
    private tenantSettingsPollHelper: PollHelper;
    private teamSettingsPollHelper: PollHelper;

    /**
     * Initializes polling for updated tenant appSettings
     * @param tenantSettings Current value for team appSettings
     */
    public initTenantPolling(tenantSettings: AppSettingsClientModel) {
        if (tenantSettings && tenantSettings.nextPollTime && tenantSettings.nextPollTime.diff) {
            // stop any previous timer
            this.stopTenantPolling();

            const durationUntilNextPollInMs: number = tenantSettings.nextPollTime.diff(moment(), "milliseconds");
            this.tenantSettingsPollHelper = new PollHelper(
                durationUntilNextPollInMs,
                600 * 1000 /* minimumPollDuration = 10 mins */,
                async () => {
                    await TenantDataService.getAppSettingsForTenant(true /* forceLoadFromNetwork */);
                }
            );
        }
    }

    /**
     * Stops polling for updated tenant appSettings
     */
    public stopTenantPolling() {
        // stop any previous timer
        if (this.tenantSettingsPollHelper) {
            this.tenantSettingsPollHelper.stopPolling();
        }
    }

    /**
     * Initialize polling for updated team appSettings
     * @param teamSettings Current value for team appSettings
     */
    public initTeamPolling(teamSettings: AppSettingsClientModel) {
        if (teamSettings && teamSettings.nextPollTime && teamSettings.nextPollTime.diff) {
            this.stopTeamPolling();

            const durationUntilNextPollInMs: number = teamSettings.nextPollTime.diff(moment(), "milliseconds");
            this.teamSettingsPollHelper = new PollHelper(
                durationUntilNextPollInMs,
                600 * 1000 /* minimumPollDuration = 10 mins */,
                async () => this.onTeamPollCallback(teamSettings)
            );
        }
    }

    private async onTeamPollCallback(teamSettings: AppSettingsClientModel) {
        try {
            await getAppSettingsForTeam(teamSettings.tenantId, teamSettings.teamId);
        } catch (error) {
            // Cancel polling for TeamNotFound error. For all other errors, the next poll should get the updated app settings
            // Error can happen if the app is unable to fetch the auth token, which should be transparent to the user and next poll should work
            if (error && error.staffHubInnerErrorCode === StaffHubErrorCodes.TeamNotFound) {
                this.stopTeamPolling();
            }
        }
    }

    /**
     * Stops polling for updated team appSettings
     */
    public stopTeamPolling() {
        // stop any previous timer
        if (this.teamSettingsPollHelper) {
            this.teamSettingsPollHelper.stopPolling();
        }
    }
}

const flightSettingsPollingService = new FlightSettingsPollingService();
export default flightSettingsPollingService as FlightSettingsPollingService;
