import { action } from "satcheljs";

const updateUnreadRequestsCountInStore = action(
    "UPDATE_UNREAD_REQUESTS_COUNT_IN_STORE",
    (
        unreadRequestsCount: number
    ) => ({
        unreadRequestsCount: unreadRequestsCount
    })
);

export default updateUnreadRequestsCountInStore;
