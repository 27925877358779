const automationIds: any = {
    "addEditTeamMember": {
        "QAIdAddTeamMember": "QAIdAddTeamMember",
        "QAIDAddFriend": "QAIDAddFriend",
        "QAIDNameLabel": "QAIDNameLabel",
        "QAIDFocusTeamMember": "QAIDFocusTeamMember",
        "QAIDPhoneNumber": "QAIDPhoneNumber",
        "QAIDFlagContainer": "QAIDFlagContainer"
    },
    "addEditShiftPanel": {
        "QAIDCustomTitleLabel": "QAIDCustomTitleLabel",
        "QAIDUnpaidBreak": "QAIDUnpaidBreak",
        "QAIDAddShiftTitle": "QAIDAddShiftTitle",
        "QAIDAddTimeoffTitle": "QAIDAddTimeoffTitle",
        "QAIDNotesLabel": "QAIDNotesLabel",
        "QAIDColorContainer": "QAIDColorContainer",
        "QAIDCrossLocationToggle": "QAIDCrossLocationToggle",
        "QAIDEditConfirmDialog": "QAIDEditConfirmDialog",
        "QAIDErrorDialog": "QAIDErrorDialog",
        "QAIDTimeContainer": "QAIDTimeContainer",
        "QAIDActivityDropdownOption": "QAIDActivityDropdownOption",
        "QAIDThemesCommandBarButton": "QAIDThemesCommandBarButton",
        "QAIDGroupsCommandBarButton": "QAIDGroupsCommandBarButton",
        "QAIDActivitiesDialog": "QAIDActivitiesDialog",
        "QAIDShiftThemeContainerWrapper": "QAIDShiftThemeContainerWrapper",
        "QAIDActivityRowInPanel": "QAIDActivityRowInPanel",
        "QAIDActivityRowInPanelTheme": "QAIDActivityRowInPanelTheme",
        "QAIDActivityRowInPanelRange": "QAIDActivityRowInPanelRange",
        "QAIDActivityRowInPanelName": "QAIDActivityRowInPanelName",
        "QAIDActivityRowInPanelCode": "QAIDActivityRowInPanelCode",
        "QAIDActivityRowInPanelType": "QAIDActivityRowInPanelType",
        "QAIDActivityEditRowInDialog": "QAIDActivityEditRowInDialog",
        "QAIDActivityPaidDropdown": "QAIDActivityPaidDropdown",
        "QAIDActivityDeleteButton": "QAIDActivityDeleteButton",
        "QAIDActivityTitle": "QAIDActivityTitle",
        "QAIDActivityCode": "QAIDActivityCode",
        "QAIDActivityStartTime": "QAIDActivityStartTime",
        "QAIDActivityEndTime": "QAIDActivityEndTime",
        "QAIDAddActivityButton": "QAIDAddActivityButton",
        "QAIDOpenShiftToggle": "QAIDOpenShiftToggle",
        "QAIDOpenShiftSlots": "QAIDOpenShiftSlots",
        "QAIDOpenShiftWarning": "QAIDOpenShiftWarning",
        "QAIDPeoplePicker": "QAIDPeoplePicker",
        "QAIDTitleError": "QAIDTitleError"
    },
    "scheduler": {
        "QAIDMenuMonth": "QAIDMenuMonth",
        "QAIDMenuWeek": "QAIDMenuWeek",
        "QAIDMenuDay": "QAIDMenuDay",
        "QAIDEmailInputField": "QAIDEmailInputField",
        "QAIdToggleAdmin": "QAIdToggleAdmin",
        "QAIDEditShiftStartTimePicker": "QAIDEditShiftStartTimePicker",
        "QAIDEditShiftEndTimePicker": "QAIDEditShiftEndTimePicker",
        "QAIDThemePicker": "QAIDThemePicker",
        "QAIDShare": "QAIDShare",
        "QAIDSave": "QAIDSave",
        "QAIDDelete": "QAIDDelete",
        "QAIDCancel": "QAIDCancel",
        "QAIDActivitySection": "QAIDActivitySection",
        "QAIDAddActivity": "QAIDAddActivity",
        "QAIDActivity": "QAIDActivity",
        "QAIDActivityRow": "QAIDActivityRow",
        "QAIDDateRangeIncludeOptions": "QAIDDateRangeIncludeOptions",
        "QAIDPeopleView": "QAIDPeopleView",
        "QAIDShiftView": "QAIDShiftView",
        "QAIDMoreScheduleElement": "QAIDMoreScheduleElement",
        "QAIDShiftDetailsCallout": "QAIDShiftDetailsCallout",
        "QAIDShiftUnsharedIndicator": "QAIDShiftUnsharedIndicator",
        "QAIDDeletedShiftUnsharedIndicator": "QAIDDeletedShiftUnsharedIndicator",
        "QAIDSearchIconButton": "QAIDSearchIconButton",
        "QAIDMoreIconButton": "QAIDMoreIconButton",
        "QAIDAddTimeOff": "QAIDAddTimeOff",
        "QAIDEditShift": "QAIDEditShift",
        "QAIDCopyShift": "QAIDCopyShift",
        "QAIDAssignOpenShift": "QAIDAssignOpenShift",
        "QAIDMoveToOpenShift": "QAIDMoveToOpenShift",
        "QAIDDeleteShift": "QAIDDeleteShift",
        "QAIDPasteShift": "QAIDPasteShift",
        "QAIDShareShift": "QAIDShareShift",
        "QAIDAddShift": "QAIDAddShift",
        "QAIDStartEndDatePickerKey": "QAIDStartEndDatePickerKey",
        "QAIDAddMemberToGroup": "QAIDAddMemberToGroup",
        "QAIDReOrder": "QAIDReOrder",
        "QAIDRename": "QAIDRename",
        "QAIDDeleteTag": "QAIDDeleteTag",
        "QAIDExportSchedule": "QAIDExportSchedule",
        "QAIDViewSharedSchedule": "QAIDViewSharedSchedule",
        "QAIDShareWithTeam": "QAIDShareWithTeam",
        "QAIDWithdrawScheduleDays": "QAIDWithdrawScheduleDays",
        "QAIDSpinner": "QAIDSpinner",
        "QAIDUndo": "QAIDUndo",
        "QAIDRedo": "QAIDRedo",
        "QAIDCopySchedule": "QAIDCopySchedule",
        "QAIDSignout": "QAIDSignout",
        "QAIDScheduleContainer": "QAIDScheduleContainer",
        "QAIDStopwatch": "QAIDStopwatch",
        "QAIDCollapseIcon": "QAIDCollapseIcon",
        "QAIDMemberRow": "QAIDMemberRow",
        "QAIDYearRangeArrow": "QAIDYearRangeArrow",
        "QAIDStartScrollTest": "QAIDStartScrollTest",
        "QAIDCreatePerfSchedule": "QAIDCreatePerfSchedule",
        "QAIDActivityThemePicker": "QAIDActivityThemePicker",
        "QAIDScheduleViewOptionsMenu": "QAIDScheduleViewOptionsMenu",
        "QAIDScheduleRow": "QAIDScheduleRow",
        "QAIDScheduleGroupStaffPerThirtyRow": "QAIDScheduleGroupStaffPerThirtyRow",
        "QAIDScheduleOpenShiftRow": "QAIDScheduleOpenShiftRow",
        "QAIDShiftTimeNotification": "QAIDShiftTimeNotification",
        "QAIDTagNameError": "QAIDTagNameError",
        "QAID24HrShiftToggle": "QAID24HrShiftToggle",
        "QAID24HrShiftInfoButton": "QAID24HrShiftInfoButton",
        "QAIDMoreMenuGroupHeader": "QAIDMoreMenuGroupHeader",
        "QAIDReorderGroupMenuOption": "QAIDReorderGroupMenuOption",
        "QAIDRenameGroupMenuOption": "QAIDRenameGroupMenuOption",
        "QAIDTimeClock": "QAIDTimeClock",
        "QAIDNoFilterMatchError": "QAIDNoFilterMatchError",
        "QAIDShiftTitle": "QAIDShiftTitle"
    },
    "daynotes": {
        "QAIDDayNotesContainer": "QAIDDayNotesContainer",
        "QAIDDayNotesCallOut": "QAIDDayNotesCallOut"
    },
    "shiftDetails": {
        "QAIDEmptyState": "QAIDEmptyState",
        "QAIDTime": "QAIDTime",
        "QAIDTitle": "QAIDTitle",
        "QAIDNotes": "QAIDNotes",
        "QAIDBreak": "QAIDBreak",
        "QAIDBreakDuration": "QAIDBreakDuration",
        "QAIDActivity": "QAIDActivity",
        "QAIDViewRequest": "QAIDViewRequest",
        "QAIDWarningMessage": "QAIDWarningMessage",
        "QAIDMakeRequest": "QAIDMakeRequest",
        "QAIDMemberName": "QAIDMemberName"
    },
    "group": {
        "QAIDScheduleGrid": "QAIDScheduleGrid",
        "QAIDGroupHeaderRow": "QAIDGroupHeaderRow",
        "QAIDScheduleMemberHeaderRow": "QAIDScheduleMemberHeaderRow",
        "QAIDScheduleAddGroupRow": "QAIDScheduleAddGroupRow",
        "QAIDGroupName": "QAIDGroupName",
        "QAIDGroupNameTextField": "QAIDGroupNameTextField"
    },
    "timeOff": {
        "QAIDTimeOffAllDayToggle": "QAIDTimeOffAllDayToggle",
        "QAIDTimeOffReasonPicker": "QAIDTimeOffReasonPicker",
        "QAIDTimeOffRequestIcon": "QAIDTimeOffRequestIcon",
        "QAIDTimeOffReasonName": "QAIDTimeOffReasonName",
        "QAIDTimeOffTimePickerStart": "QAIDTimeOffTimePickerStart",
        "QAIDTimeOffTimePickerEnd": "QAIDTimeOffTimePickerEnd",
        "QAIDTimeOffThemePicker": "QAIDTimeOffThemePicker",
        "QAIDTimeOffNotes": "QAIDTimeOffNotes"
    },
    "panel": {
            "QAIDPanelContentChildContainer": "QAIDPanelContentChildContainer",
            "QAIDPanelCancelButton": "QAIDPanelCancelButton",
            "QAIDPanelSaveButton": "QAIDPanelSaveButton",
            "QAIDPanelCloseButton": "QAIDPanelCloseButton"
    },
    "dialog": {
        "QAIDErrorDialogContent": "QAIDErrorDialogContent",
        "QAIDDialogCancelButton": "QAIDDialogCancelButton",
        "QAIDDialogOkayButton": "QAIDDialogOkayButton",
        "QAIDDialogApplyButton": "QAIDDialogApplyButton"
    },
    "export": {
        "QAIDExportOptionsContainer": "QAIDExportOptionsContainer",
        "QAIDExportOptionsPaidHour": "QAIDExportOptionsPaidHour",
        "QAIDExportDate": "QAIDExportDate",
        "QAIDExportToggle": "QAIDExportToggle"
    },
    "header": {
        "QAIDHeaderLinkRight": "QAIDHeaderLinkRight",
        "QAIDHeaderLinks": "QAIDHeaderLinks",
        "QAIDWaffle": "QAIDWaffle",
        "QAIDHelpShift": "QAIDHelpShift",
        "QAIDSchedule": "QAIDSchedule",
        "QAIDRequests": "QAIDRequests",
        "QAIDTeam": "QAIDTeam",
        "QAIDFiles": "QAIDFiles",
        "QAIDSchedulePreferences": "QAIDSchedulePreferences",
        "QAIDSchedulerHeader": "QAIDSchedulerHeader",
        "QAIDSettings": "QAIDSettings",
        "QAIDPersona": "QAIDPersona",
        "QAIDDateRangeArrow": "QAIDDateRangeArrow",
        "QAIDDateRangeDisplay": "QAIDDateRangeDisplay",
        "QAIDAppName": "QAIDAppName",
        "QAIDShellLauncher": "QAIDShellLauncher",
        "QAIDTeamName": "QAIDTeamName"
    },
    "settings": {
        "QAIDCompanyName": "QAIDCompanyName",
        "QAIDTimeZone": "QAIDTimeZone",
        "QAIDStartOfWeek": "QAIDStartOfWeek",
        "QAIDIconMenuButton": "QAIDIconMenuButton",
        "QAIDIconMenuButtonLink": "QAIDIconMenuButtonLink",
        "QAIDDeleteIcon": "QAIDDeleteIcon",
        "QAIDDeleteIconButton": "QAIDDeleteIconButton",
        "QAIDAddCustomOption": "QAIDAddCustomOption",
        "QAIDAddCustomOptionButton": "QAIDAddCustomOptionButton",
        "QAIDTimeClockChangeSettings": "QAIDTimeClockChangeSettings",
        "QAIDLocationLabel": "QAIDLocationLabel",
        "QAIDSearchAddressBox": "QAIDSearchAddressBox",
        "QAIDExportTimeReportSetting": "QAIDExportTimeReportSetting",
        "QAIDExportStartDate": "QAIDExportStartDate",
        "QAIDExportEndDate": "QAIDExportEndDate",
        "QAIDBeginSetup": "QAIDBeginSetup",
        "QAIDDelete": "QAIDDelete",
        "QAIDCopyShifts": "QAIDCopyShifts",
        "QAIDSwapShifts": "QAIDSwapShifts",
        "QAIDOfferShifts": "QAIDOfferShifts",
        "QAIDTimeOffRequests": "QAIDTimeOffRequests",
        "QAIDSeeTimeOffDetails": "QAIDSeeTimeOffDetails",
        "QAIDSeeUnlimitedPastScheduleData": "QAIDSeeUnlimitedPastScheduleData",
        "QAIDPastScheduleDataLimit": "QAIDPastScheduleDataLimit",
        "QAIDSeeShiftDetails": "QAIDSeeShiftDetails",
        "QAIDCLMShiftsEnabled": "QAIDCLMShiftsEnabled",
        "QAIDCLMRequestApprovalRequired": "QAIDCLMRequestApprovalRequired"
    },
    "team": {
        "QAIDNewTeam": "QAIDNewTeam",
        "QAIDBeginSetUp": "QAIDBeginSetUp",
        "QAIDTeamName": "QAIDTeamName",
        "QAIDTeamTimeZoneDropDown": "QAIDTeamTimeZoneDropDown"
    },
    "admin": {
        "QAIDCorpAnnouncement": "QAIDCorpAnnouncement"
    },
    "components": {
        "QAIDLocalizedDatePicker": "QAIDLocalizedDatePicker",
        "QAIDLocalizedMonthPicker": "QAIDLocalizedMonthPicker",
        "QAIDLocalizedMonthPickerShowCallout": "QAIDLocalizedMonthPickerShowCallout",
        "QAIDShiftHintDatePicker": "QAIDShiftHintDatePicker",
        "QAIDIFrameWrapper": "QAIDIFrameWrapper"
    },
    "confirm": {
        "QAIDConfirmOkayButton": "QAIDConfirmOkayButton",
        "QAIDConfirmCancelButton": "QAIDConfirmCancelButton",
        "QAIDConfirmDialog": "QAIDConfirmDialog"
    },
    "reorderTagsPanel": {
        "QAIDTagItem": "QAIDTagItem"
    },
    "timePicker": {
        "QAIDTimePickerDropdown": "QAIDTimePickerDropdown"
    },
    "shareDialog": {
        "QAIDShareDialog": "QAIDShareDialog",
        "QAIDWithdrawDialog": "QAIDWithdrawDialog",
        "QAIDShareDialogContent": "QAIDShareDialogContent",
        "QAIDWithdrawDialogContent": "QAIDWithdrawDialogContent",
        "QAIDShareDialogBusySpinner": "QAIDShareDialogBusySpinner",
        "QAIDNumAssignedShifts": "QAIDNumAssignedShifts",
        "QAIDNumAssignedOpenShifts": "QAIDNumAssignedOpenShifts",
        "QAIDShareToTeamOption": "QAIDShareToTeamOption",
        "QAIDShareToMembersOption": "QAIDShareToMembersOption"
    },
    "shiftRequestsPanel": {
        "QAIDAddRequestCommandBarButton": "QAIDAddRequestCommandBarButton",
        "QAIDLeftPane": "QAIDLeftPane",
        "QAIDRightPane": "QAIDRightPane"
    },
    "shiftRequestDetails": {
        "QAIDEmptyStateContainer": "QAIDEmptyStateContainer",
        "QAIDRequestDetailsContainer": "QAIDRequestDetailsContainer",
        "QAIDContentContainer": "QAIDContentContainer",
        "QAIDTimeOffRequestContainer": "QAIDTimeOffRequestContainer",
        "QAIDSwapRequestContainer": "QAIDSwapRequestContainer"
    },
    "createShiftRequest": {
        "QAIDCreateShiftRequest": "QAIDCreateShiftRequest",
        "QAIDCancelRequestButton": "QAIDCancelRequestButton",
        "QAIDSendRequestButton": "QAIDSendRequestButton"
    },
    "copyDialog": {
        "QAIDCopyDialog": "QAIDCopyDialog",
        "QAIDCopyDialogContent": "QAIDCopyDialogContent",
        "QAIDCopyDialogBusySpinner": "QAIDCopyDialogBusySpinner",
        "QAIDCopyDialogIncludeNotes": "QAIDCopyDialogIncludeNotes",
        "QAIDCopyDialogIncludeAbsenceShifts": "QAIDCopyDialogIncludeAbsenceShifts",
        "QAIDCopyDialogIncludeSubshifts": "QAIDCopyDialogIncludeSubshifts",
        "QAIDCopyDialogIncludeOpenShifts": "QAIDCopyDialogIncludeOpenShifts"
    },
    "copyShiftActivitiesSettingFREDialog": {
        "QAIDCopyShiftActivitiesDialog": "QAIDCopyShiftActivitiesDialog",
        "QAIDCopyShiftActivitiesToggleButton": "QAIDCopyShiftActivitiesToggleButton"
    },
    "memberEditorDialog": {
        "QAIDMemberPicker": "QAIDMemberPicker",
        "QAIDMemberEditorAddButton": "QAIDMemberEditorAddButton",
        "QAIDMemberEditorCloseButton": "QAIDMemberEditorCloseButton",
        "QAIDManageTeamLink": "QAIDManageTeamLink",
        "QAIDMemberListItem": "QAIDMemberListItem",
        "QAIDMemberEditorSpinner": "QAIDMemberEditorSpinner"
    },
    "filterCallout": {
        "QAIDGroupFilterSelectedCheckMark": "QAIDGroupFilterSelectedCheckMark",
        "QAIDMemberFilterSelectedCheckMark": "QAIDMemberFilterSelectedCheckMark",
        "QAIDShiftFilterSelectedCheckMark": "QAIDShiftFilterSelectedCheckMark",
        "QAIDGroupCollapseButton": "QAIDGroupCollapseButton",
        "QAIDMemberCollapseButton": "QAIDMemberCollapseButton",
        "QAIDShiftFilterCollapseButton": "QAIDShiftFilterCollapseButton",
        "QAIDClearButton": "QAIDClearButton",
        "QAIDSelectAllButton": "QAIDSelectAllButton",
        "QAIDFilterGroupList": "QAIDFilterGroupList",
        "QAIDFilterMemberList": "QAIDFilterMemberList",
        "QAIDShiftFilterList": "QAIDShiftFilterList",
        "QAIDGroupFilterName": "QAIDGroupFilterName",
        "QAIDMemberFilterName": "QAIDMemberFilterName",
        "QAIDShiftFilterName": "QAIDShiftFilterName"
    },
    "conflictCallout": {
        "QAIDDismissConflict": "QAIDDismissConflict",
        "QAIDConflictTypeTitle": "QAIDConflictTypeTitle",
        "QAIDConflictList": "QAIDConflictList",
        "QAIDConflictCalloutTitle": "QAIDConflictCalloutTitle",
        "QAIDConflictInfoIcon": "QAIDConflictInfoIcon",
        "QAIDConflictInfoIconTour": "QAIDConflictInfoIconTour",
        "QAIDConflictDetailsString": "QAIDConflictDetailsString",
        "QAIDAvailabityTimeSlotString": "QAIDAvailabityTimeSlotString",
        "QAIDTotalConflictCount": "QAIDTotalConflictCount",
        "QAIDConflictCountLoading": "QAIDConflictCountLoading",
        "QAIDRequestConflictMesssage": "QAIDRequestConflictMesssage"
    },
    "timeClockButton": {
        "QAIDTimeClockSpiltButton": "QAIDTimeClockSpiltButton",
        "QAIDTimeClockContextualMenu": "QAIDTimeClockContextualMenu",
        "QAIDClockButton": "QAIDClockButton",
        "QAIDBreakButton": "QAIDBreakButton",
        "QAIDExportButton": "QAIDExportButton"
    },
    "timeclock": {
        "QAIDTimeClockIncludeLocationCheckbox": "QAIDTimeClockIncludeLocationCheckbox",
        "QAIDTimeClockLocationCoordinatesInput": "QAIDTimeClockLocationCoordinatesInput",
        "QAIDTimeClockSearchAddressBoxError": "QAIDTimeClockSearchAddressBoxError"
    },
    "layout": {
        "QAIDGlobalMessageBar": "QAIDGlobalMessageBar"
    }
};

export default automationIds;