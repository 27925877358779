import {
    AWTEventProperties,
    AWTLogConfiguration,
    AWTLogManager,
    AWTLogger,
    AWTPiiKind,
    AWTSessionState,
    AWTUserIdType
} from "@aria/webjs-sdk";
import InstrumentationUtils from "sh-application/utility/InstrumentationUtils";
import { EUDBTelemetryConfig, ICollectorConfig, logLevel } from "sh-instrumentation";
import { UserStorageKeys } from "sh-models";
import { InstrumentationService, UserStorageService } from "sh-services";
import { UserStorageServiceScope } from "sh-services/lib/UserStorageServiceScope";

import { IInstrumentationLogger, InstrumentationEventPropertyInterface, PiiKind, getGenericEventPropertiesObject } from "../..";
import { loadTelemetryRegion } from "../../loadTelemetryConfig";

/**
 * Aria Logger Configuration.
 */
export interface AriaLoggerInitConfig {
    /**
     * Instrumentation key to Aria Tenant for UserBI metrics (Teams FLW Web Prod/Test tenant).
     */
    ariaWebAppKey: string;

    /**
     * Instrumentation key to Aria Tenant for Perf/Engineering metrics  (Sonoma Web Perf Prod/INT tenant).
     */
    ariaPerfWebAppKey: string;

    /**
     * Is perf logging enabled.
     */
    perfLoggingEnabled: boolean;

    /**
     * Log level for perf logs.
     */
    globalPerfLogLevel: logLevel;

    /**
     * EUDB Telemetry Config.
     */
    eudbTelemetryConfig: EUDBTelemetryConfig;

    /**
     * Collector Configuration by region.
     */
    collectorConfigs: ICollectorConfig[];

    /**
     * Tenant Id.
     */
    tenantId: string;
}

/**
 * Mapping of PiiKind to AWTPiiKind.
 */
const piiKindToAwtPiiKind = {
    [PiiKind.Identity]: AWTPiiKind.Identity,
    [PiiKind.DistinguishedName]: AWTPiiKind.DistinguishedName,
    [PiiKind.Fqdn]: AWTPiiKind.Fqdn,
    [PiiKind.GenericData]: AWTPiiKind.GenericData,
    [PiiKind.IPV4Address]: AWTPiiKind.IPV4Address,
    [PiiKind.IPV4AddressLegacy]: AWTPiiKind.IPV4AddressLegacy,
    [PiiKind.IPv6Address]: AWTPiiKind.IPv6Address,
    [PiiKind.MailSubject]: AWTPiiKind.MailSubject,
    [PiiKind.NotSet]: AWTPiiKind.NotSet,
    [PiiKind.PhoneNumber]: AWTPiiKind.PhoneNumber,
    [PiiKind.QueryString]: AWTPiiKind.QueryString,
    [PiiKind.SipAddress]: AWTPiiKind.SipAddress,
    [PiiKind.SmtpAddress]: AWTPiiKind.SmtpAddress,
    [PiiKind.Uri]: AWTPiiKind.Uri
  };

/**
 * Aria Logger class.
 */
export class AriaLogger implements IInstrumentationLogger {
    /**
     * Handles general instrumentation events for Aria
     */
    private ariaLogger: AWTLogger;
    /**
     * Handles performance instrumentation timing for Aria
     */
    private ariaPerfLogger: AWTLogger;

    /**
     * Perf logging enabled flag.
     */
    private perfLoggingEnabled: boolean;

    /**
     * The global performance logging level
     */
    private globalPerfLogLevel: logLevel;

    /**
     * Local Custom Dimensions that will be logged along with databag
     */
    private localCustomDimensions: { [name: string]: InstrumentationEventPropertyInterface } = {};

    /**
     * The delay in milliseconds to wait before updating the telemetry region cache.
     */
    private readonly updateTelemetryRegionCacheDelayMs = 5000;

    /**
     * Instrumentation Attribute Values
     * See: https://domoreexp.visualstudio.com/Teamspace/_wiki/wikis/Teamspace.wiki/2933/User-BI-Schema
     */
    private values = {
        App_Id_Shifts: "42f6c1da-a241-483a-a3cc-4f5be9185951",
        App_Name_Shifts: "Shifts",
        App_IsTenantApp: false,
        App_ScenarioCapability: 0,                                  // 0 represents "PersonalApp" 1 represents "Bot" 2 represents "Tab" 3 represents "MessagingExtension" 4 represents ""Connectors"
        App_Scope: 2,                                               // 0 represents "General" 1 represents "Team" 2 represents "Personal" 4 represents "Group" 8 represents "Meeting"
        AppInfo_Name_Shifts: "Teams.Shifts",                        // GDPRS related, should not be changed
        AppInfo_Platform_Web: "Web",                                // GDPRS related, should not be changed
        UserInfo_IdType_UserObjectId: "UserObjectId"                // GDPRS related, should not be changed
    };

    /**
     * Instrumentation Attribute Properties ("Key")
     */
    private properties = {
        App_Id: "App.Id",
        App_Name: "App.Name",
        App_IsTenantApp: "App.IsTenantApp",
        App_ScenarioCapability: "App.ScenarioCapability",
        App_Scope: "App.Scope",
        App_CapabilityId: "App.CapabilityId",
        AppInfo_Name: "AppInfo.Name",                       // GDPRS related, should not be changed
        AppInfo_Platform: "AppInfo.Platform",               // GDPRS related, should not be changed
        UserInfo_Id: "UserInfo.Id",                         // GDPRS related, should not be changed
        UserInfo_IdType: "UserInfo.IdType",                 // GDPRS related, should not be changed
        UserInfo_OMSTenantId: "UserInfo.OMSTenantId",       // GDPRS related, should not be changed
        UserInfo_TenantId: "UserInfo.TenantId"              // GDPRS related, should not be changed
    };

    /**
     * Initialize the Aria logger
     */
    public async init(config: AriaLoggerInitConfig) {
        this.globalPerfLogLevel = config.globalPerfLogLevel;
        this.perfLoggingEnabled = config.perfLoggingEnabled;

        const configuration: AWTLogConfiguration = {
            enableAutoUserSession: true
        };

        const collectorUri = await this.getCollectorUri(config);

        if (collectorUri) {
            configuration.collectorUri = collectorUri;
        }

        // Initialize the log manager
        AWTLogManager.initialize(config.ariaWebAppKey, configuration);

        AWTLogManager.setContext(this.properties.App_Id, this.values.App_Id_Shifts);
        AWTLogManager.setContext(this.properties.App_Name, this.values.App_Name_Shifts);
        AWTLogManager.setContext(this.properties.App_IsTenantApp, this.values.App_IsTenantApp);
        AWTLogManager.setContext(this.properties.App_ScenarioCapability, this.values.App_ScenarioCapability);
        AWTLogManager.setContext(this.properties.App_Scope, this.values.App_Scope);
        AWTLogManager.setContext(this.properties.App_CapabilityId, this.values.App_Id_Shifts);

        // Set the extra custom dimensions that Aria needs for GDPR
        AWTLogManager.setContext(this.properties.AppInfo_Name, this.values.AppInfo_Name_Shifts);
        AWTLogManager.setContext(this.properties.AppInfo_Platform, this.values.AppInfo_Platform_Web);
        AWTLogManager.setContext(InstrumentationService.customDimensionProperties.AppInfo_ClientType, this.values.AppInfo_Platform_Web);

        // Initialize the loggers
        this.ariaLogger = new AWTLogger(config.ariaWebAppKey);
        this.ariaPerfLogger = new AWTLogger(config.ariaPerfWebAppKey);

        // Start logging session. this will send SessionID as a parameter with every event that gets logged into Aria in this session.
        // Will be useful to debug from Kusto about all user actions in that session
        this.startLogSession();
    }

    /**
     * Set App Version to Aria Context
     * @param {String} version
     * @returns {}
     */
    public setAppVersion(version: string) {
        if (version) {
            const ariaSemanticContext = AWTLogManager.getSemanticContext();
            ariaSemanticContext.setAppVersion(version);
            ariaSemanticContext.setAppLanguage(window.sa.currentUICulture);
        }
    }

    /**
     * Start a new logging session in Aria. This will set session state to start.
     * If a session is already in started state, ARIA ignores subsequent session starts
     * @returns void
     */
    public startLogSession(): void {
        if (this.ariaLogger) {
            this.ariaLogger.logSession(AWTSessionState.Started);
        }
        if (this.ariaPerfLogger) {
            this.ariaPerfLogger.logSession(AWTSessionState.Started);
        }
    }

    /**
     * End existing logging session in Aria. This will reset sessionId in Aria logs
     * Invoking Aria log manager flush to flush out any pending events at this moment
     * @returns {}
     */
    public endLogSession(): void {
        if (this.ariaLogger) {
            this.ariaLogger.logSession(AWTSessionState.Ended);
        }
        if (this.ariaPerfLogger) {
            this.ariaPerfLogger.logSession(AWTSessionState.Ended);
        }
        AWTLogManager.flushAndTeardown();
    }

    /**
     * Set Custom Dimension for all events that gets logged to ARIA
     * @param {Object} eventData - eventData object with key, value, piiKind
     * @param {boolean} isGlobal - indicate if custom dimension should be logged as global data or within data bag
     */
    public setCustomDimension(eventData: InstrumentationEventPropertyInterface, isGlobal: boolean = true) {
         if (isGlobal) {
            // Use ARIA Log Manager setContext API to set custom dimension for ARIA
            if (eventData.piiKind) {
                // includes pii
                AWTLogManager.setContextWithPii(eventData.key, eventData.value, piiKindToAwtPiiKind[eventData.piiKind]);
            } else {
                AWTLogManager.setContext(eventData.key, eventData.value);
            }
         } else {
             // cache the local custom dimension for logging it with every event
            this.localCustomDimensions[eventData.key] = eventData;
         }
    }

    /**
     * Clear Custom Dimension for all events that gets logged to ARIA
     * @param {string} key - key to clear dimension for
     */
    public clearCustomDimension(key: string) {
        // Use ARIA Log Manager setContext API to set custom dimension for ARIA
        AWTLogManager.setContext(key, undefined);

        // delete any local custom dimensions
        delete this.localCustomDimensions[key];
    }

    /**
     * Sets the UserID and TenantId contexts for GDPR
     * @param userId
     * @param tenantId
     */
    public setUserContext(userId: string, tenantId: string) {
        // Set the user ID in the semantic context
        const semanticContext = AWTLogManager.getSemanticContext();
        if (semanticContext) {
            semanticContext.setUserId(userId /* userId */, AWTPiiKind.Identity /* piiKind */, AWTUserIdType.UserObjectId /* userIdType */);
        }

        // Set the extra custom dimensions that Aria needs for GDPR
        AWTLogManager.setContext(this.properties.UserInfo_Id, userId);
        AWTLogManager.setContext(this.properties.UserInfo_IdType, this.values.UserInfo_IdType_UserObjectId);
        AWTLogManager.setContext(this.properties.UserInfo_OMSTenantId, tenantId);
        AWTLogManager.setContext(this.properties.UserInfo_TenantId, tenantId);
    }

    /**
     * Clear the user context so that subsequent events will not be grouped with user
     * @returns {}
     */
    public clearUserContext() {
        this.setUserContext("", "");
    }

    /**
     * Get Event properties object in the way Aria expects - use microsoft.applications.telemetry.EventProperties function to create the required object
     * @param {Array} eventDataArray
     * @returns {Object} microsoft.applications.telemetry.EventProperties
     */
    public getAriaEventPropertiesObject(eventDataArray: Array<InstrumentationEventPropertyInterface>, measurements?: { [name: string]: number }): AWTEventProperties {
        const eventPropertiesObjectForAria = new AWTEventProperties();
        if (eventDataArray) {
            // Add any event data items
            for (let i = 0; i < eventDataArray.length; i++) {
                const eventData = eventDataArray[i];
                if (eventData.piiKind) {
                    eventPropertiesObjectForAria.setPropertyWithPii(eventData.key, eventData.value, piiKindToAwtPiiKind[eventData.piiKind]);
                } else {
                    eventPropertiesObjectForAria.setProperty(eventData.key, eventData.value);
                }
            }
        }
        if (measurements) {
            // Add any measurements
            const measurementsKeys = Object.keys(measurements);
            for (let i = 0; i < measurementsKeys.length; i++) {
                const key = measurementsKeys[i];
                eventPropertiesObjectForAria.setProperty(key, measurements[key]);
            }
        }
        return eventPropertiesObjectForAria;
    }

    private async getCollectorUri(config: AriaLoggerInitConfig): Promise<string | undefined> {
        let collectorUri: string | undefined = undefined;
        const { eudbTelemetryConfig, collectorConfigs } = config;

        if (eudbTelemetryConfig.useCompliantClient) {
            const currentRegion = eudbTelemetryConfig.currentTelemetryRegionCode ?? await this.getCurrentTelemetryRegion(config.tenantId);

            let defaultCollectorConfig: string = undefined;

            for (const collectorConfig of collectorConfigs) {
                if (!collectorConfig.Region) {
                    // If there is no region specified, this is the default CollectorUrl
                    defaultCollectorConfig = collectorConfig.CollectorUrl;
                } else if (currentRegion?.toLowerCase() == collectorConfig.Region.toLowerCase()) {
                    // If the current region matches the region specified in the config, use that CollectorUrl
                    collectorUri = collectorConfig.CollectorUrl;
                    break;
                }
            }

            if (!collectorUri && defaultCollectorConfig) {
                collectorUri = defaultCollectorConfig;
            }
        }

        return collectorUri;
    }

    private async getCurrentTelemetryRegion(tenantId: string): Promise<string> {
        const cachedRegion = UserStorageService.getItem(UserStorageKeys.TelemetryRegion, {
            scope: UserStorageServiceScope.CurrentTenant
        });

        if (cachedRegion) {
            setTimeout((): void => {
                this.updateCurrentTelemetryRegion(tenantId);
            }, this.updateTelemetryRegionCacheDelayMs);
        }

        return cachedRegion ?? await this.updateCurrentTelemetryRegion(tenantId);
    }

    private async updateCurrentTelemetryRegion(tenantId: string): Promise<string> {
        const telemetryRegion = await loadTelemetryRegion(tenantId);

        UserStorageService.setItem(UserStorageKeys.TelemetryRegion, telemetryRegion, {
            scope: UserStorageServiceScope.CurrentTenant
        });

        return telemetryRegion;
    }

    /**
     * Get all properties to log as a data bag with key/value dictionary
     * @param eventData all properties to be logged along with event
     * @param measurements any measurement data
     * @param customDimensions local custom dimensions property bag to be logged with the event
     */
    private getAriaEventPropertiesAsDataBag(eventData?: InstrumentationEventPropertyInterface[], measurements?: {[name: string]: number}, customDimensions?: {[name: string]: InstrumentationEventPropertyInterface }): {[key: string]: string | number | boolean} {
        let dataBag: {[key: string]: string | number | boolean} = {};
        if (eventData) {
            // add all event properties
            for (let i = 0; i < eventData.length; i++) {
                dataBag[eventData[i].key] = eventData[i].value;
            }
        }
        // Add any measurements
        if (measurements) {
            let measurementsKeys = Object.keys(measurements);
            for (let i = 0; i < measurementsKeys.length; i++) {
                let key = measurementsKeys[i];
                dataBag[key] = measurements[key];
            }
        }
        // add any local custom dimensions
        if (customDimensions) {
            let customDimensionKeys = Object.keys(customDimensions);
            for (let i = 0; i < customDimensionKeys.length; i++) {
                let item = customDimensions[customDimensionKeys[i]];
                dataBag[item.key] = item.value;
            }
        }
        return dataBag;
    }

    /**
     * Add Databag properties to Event Properties to be logged as separate columns in Aria
     * @param eventProperties - Aria Event Properties
     * @param dataBag - dataBag of key/value pairs
     */
    private addDataBagPropertiesToEventProperties(eventProperties: AWTEventProperties, dataBag: { [name: string]: string | number | boolean } ) {
        if (!eventProperties || !dataBag) {
            return;
        }

        let dataBagKeys = Object.keys(dataBag);
        for (let i = 0; i < dataBagKeys.length; i++) {
            let key = dataBagKeys[i];
            eventProperties.setProperty(key, dataBag[key]);
        }
    }

    /**
     * Log Event to ARIA using Aria SDK
     * This is an abstract function to log an event to ARIA.
     * To ARIA, event will be logged as
     * { eventName = "userbi",
     *   eventType = "panelaction",
     *   Action.ScenarioType = {eventName},
     *   Action.Scenario = {eventType},
     *   DataBag.FirstLn = all properties map (key/value dictionary), including local custom dimensions, serialized as string,
     *   ...CustomDimensions }.
     * @param {String} eventName
     * @param {Array} eventDataArray - Array of properites to log, eventData is composed of eventData.key, eventData.value, eventData.piiKind
     * @param { [name: string]: number } measurements map[string, number] - metrics associated with this event, displayed in Metrics Explorer on the portal. Defaults to empty.
     */
    public logEvent(eventName: string, eventDataArray: Array<InstrumentationEventPropertyInterface>, measurements?: { [name: string]: number }): void {
        if (this.ariaLogger) {
            const eventProperties: AWTEventProperties = new AWTEventProperties();
            // event name is always userbi
            eventProperties.setName(InstrumentationService.events.UserBI);
            // event type is panelaction
            eventProperties.setType(InstrumentationService.eventTypes.PanelAction);
            // add all properties including local custom dimensions into databag
            const dataBag = this.getAriaEventPropertiesAsDataBag(eventDataArray, measurements, this.localCustomDimensions);

            // we need to log scenarioType and scenario as top level properties to distinguise the events.
            // From AriaLogger consumers, there are some ARIA events that are being logged with eventName in format of scenarioType_scenario.
            // For those events, split eventName and log scenarioType and scenario separately.
            // There are few other events, that are being logged withe eventName at top level and eventType property within databag.
            // For those events, set eventName as ScenarioType and eventType as Scenario

            // if eventType is being passed as properties, use that as Scenario.
            if (dataBag[InstrumentationService.properties.EventType]) {
                eventProperties.setProperty(InstrumentationService.properties.ScenarioType, eventName);
                eventProperties.setProperty(InstrumentationService.properties.Scenario, dataBag[InstrumentationService.properties.EventType]);
                delete dataBag[InstrumentationService.properties.EventType];
            } else {
                // if eventName is in format scenarioType_scenario (e.g., Publish_ShareTeam etc.,), extract scenario info and set it. else use event name as scenario type
                const scenarios: string[] = eventName.split('_');
                eventProperties.setProperty(InstrumentationService.properties.ScenarioType, scenarios.length > 0 ? scenarios[0] : eventName);
                eventProperties.setProperty(InstrumentationService.properties.Scenario, scenarios.length > 1 ? scenarios[1] : InstrumentationService.values.Null);
            }
            this.addDataBagPropertiesToEventProperties(eventProperties, dataBag);
            // Log the event to ARIA
            this.ariaLogger.logEvent(eventProperties);
        }
    }

    /**
     * Log page view
     * @param {String} pageName
     * @param {String} currentPageUri
     * @param {Array} eventDataArray - Array of eventPropertyObjects (each object has key, value, piiKind)
     * @param { [name: string]: number } measurements - metrics associated with this event, displayed in Metrics Explorer on the portal. Defaults to empty.
     * @param  {number} duration - the number of milliseconds it took to load the page. Defaults to undefined. If set to default value, page load time is calculated internally.
     */
    public logPageView(pageName: string, currentPageUri?: string, eventDataArray?: Array<InstrumentationEventPropertyInterface>, measurements?: { [name: string]: number }, duration?: number) {
        if (this.ariaLogger) {
            let eventProperties: AWTEventProperties = new AWTEventProperties();
            // event name is always userbi
            eventProperties.setName(InstrumentationService.events.UserBI);
            // event type is panelView
            eventProperties.setType(InstrumentationService.eventTypes.PanelView);
            // set panel type
            eventProperties.setProperty(InstrumentationService.properties.PanelType, pageName);
            // set page URI and referral source
            eventDataArray = eventDataArray || [];
            eventDataArray.push(getGenericEventPropertiesObject(InstrumentationService.properties.PageURL, currentPageUri));
            eventDataArray.push(getGenericEventPropertiesObject(InstrumentationService.properties.ReferralSource, InstrumentationUtils.NormalizeUrl(document.referrer)));
            // add all properties including local custom dimensions into databag
            const dataBag = this.getAriaEventPropertiesAsDataBag(eventDataArray, measurements, this.localCustomDimensions);
            this.addDataBagPropertiesToEventProperties(eventProperties, dataBag);
            // Log the event to ARIA
            this.ariaLogger.logEvent(eventProperties);
        }
    }

    /**
     * Log Perf Event to Aria
     * @param {String} eventName
     * @param {Array} eventDataArray - Array of eventPropertyObjects (each object has key, value, piiKind)
     * @param { [name: string]: number } measurements - metrics associated with this event, displayed in Metrics Explorer on the portal. Defaults to empty.
     * @param {Number} perfLoggingLevel
     */
    public logPerfEvent(eventName: string, eventDataArray: Array<InstrumentationEventPropertyInterface>, measurements?: { [name: string]: number }, perfLoggingLevel: logLevel = logLevel.P1) {
        // log the perf event only if perfScenario logging level is equal or higher priority than global perf logging level
        if (this.perfLoggingEnabled && perfLoggingLevel <= this.globalPerfLogLevel && this.ariaPerfLogger) {
            const eventProperties = this.getAriaEventPropertiesObject(eventDataArray, measurements);
            eventProperties.setName(eventName);
            // append all local custom dimensions
            if (this.localCustomDimensions) {
                let customDimensionKeys = Object.keys(this.localCustomDimensions);
                for (let i = 0; i < customDimensionKeys.length; i++) {
                    let item = this.localCustomDimensions[customDimensionKeys[i]];
                    eventProperties.setProperty(item.key, item.value);
                }
            }
            this.ariaPerfLogger.logEvent(eventProperties);
        }
    }

    /**
     * These are used for unit testing to expose the logger objects
     */
    public get logger(): AWTLogger { return UNIT_TEST_MODE ? this.ariaLogger : undefined; }
    public get perfLogger(): AWTLogger { return UNIT_TEST_MODE ? this.ariaPerfLogger : undefined; }
}
