import ConflictUtils from "sh-application/utility/ConflictUtils";
import {
    addShiftConflict,
    ConflictStore,
    createShiftConflict,
    incrementConflictCount
} from "sh-conflict-store";
import {
    ConflictType,
    IShiftConflictEntity,
    IShiftEntity,
    ShiftTypes
} from "sh-models";
import { orchestrator } from "satcheljs";
import { setRequestShiftConflictsInStore } from "sh-requestconflict-store";

export default orchestrator(createShiftConflict, actionMessage => {
    const shift: IShiftEntity = actionMessage.shift;
    const conflictingShift: IShiftEntity = actionMessage.conflictingShift;
    const isRequestShift: boolean = actionMessage.isRequestShift;

    const conflictType: ConflictType = shift.shiftType === ShiftTypes.Absence || conflictingShift.shiftType === ShiftTypes.Absence
        ? ConflictType.ShiftTimeOffConflict : ConflictType.OverlappingShiftConflict;

    if (isRequestShift) {
        // if conflicts are calculated for request Shift are are temporary to convey to admin about future conflicts, hence they wont be store in ConflictStore
        // They will be be updated in memory conflictRequestStore
        setRequestShiftConflictsInStore(shift, conflictingShift, conflictType, actionMessage.memberId);
    } else {
        let conflictsArrayForShift = ConflictStore().shiftIdToConflictsMap.get(shift.id) || [];
        let conflictsArrayForConflictingShift = ConflictStore().shiftIdToConflictsMap.get(conflictingShift.id) || [];
        let alreadyAdded: boolean = false;

        // check if conflict already exists in conflictsArrayForShift
        conflictsArrayForShift.forEach((conflictEntity: IShiftConflictEntity) => {
            if (ConflictUtils.isSameConflictEntity(conflictEntity, shift.id, conflictingShift.id)) {
                alreadyAdded = true;
            }
        });

        if (!alreadyAdded) {
            // check if conflict already exists in conflictsArrayForConflictingShift
            conflictsArrayForConflictingShift.forEach((conflictEntity: IShiftConflictEntity) => {
                if (ConflictUtils.isSameConflictEntity(conflictEntity, shift.id, conflictingShift.id)) {
                    alreadyAdded = true;
                }
            });
        }

        if (!alreadyAdded) {
            addShiftConflict(shift, conflictingShift, conflictType);
            // increment conflict count
            incrementConflictCount(shift.memberId, conflictType);
        }
    }
});