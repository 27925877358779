import ModelUtils from "../ModelUtils";
import ShiftrObjectModelBase from "../ShiftrObjectModelBase";
import TestUtils from "sh-models/TestUtils";
import {
    CustomFieldEntity,
    ICustomFieldEntity,
    ITeamInfoEntity,
    LocationSetting,
    TeamManagedByType,
    TeamTypes
    } from "sh-models";
import { isFeatureEnabled } from "sh-feature-flags";
import { teamIdPrefix } from "../../StaffHubConstants";
import { ITeamInfoEntityParams } from "./TeamInfoEntityParams";

/**
 * Base class that sets up common aspects for the TeamInfo client and service models
 */
export default class TeamInfoEntity extends ShiftrObjectModelBase implements ITeamInfoEntity {
    tenantId: string;
    handle: string;
    name: string;
    location: string;
    pictureUrl: string;
    countryCode: string;
    groupId: string;
    groupDriveId: string;
    driveProvisionState: string;
    classification: string;
    crossLocationShiftsEnabled: boolean;
    requireCrossLocationShiftRequestApprovalEnabled: boolean;
    timeClockEnabled: boolean;
    hideOpenShifts: boolean;
    locationSettings: Array<LocationSetting>;
    customFields: Array<ICustomFieldEntity>;
    teamChatId: string;
    enableLastNameInitial: boolean;
    enableCrossTeamScheduling: boolean;
    swapShiftsRequestsEnabled: boolean;
    offerShiftRequestsEnabled: boolean;
    timeOffRequestsEnabled: boolean;
    copyShiftActivitiesWithShiftsEnabled: boolean;
    teamIdsToShareScheduleWith: Array<string>;
    managedBy: TeamManagedByType;
    workforceIntegrationIds: Array<string>;

    constructor(
        params: ITeamInfoEntityParams
    ) {

        const {
            classification,
            copyShiftActivitiesWithShiftsEnabled,
            countryCode,
            requireCrossLocationShiftRequestApprovalEnabled,
            crossLocationShiftsEnabled,
            customFields,
            driveProvisionState,
            enableCrossTeamScheduling, // TODO (CLM): Remove once the service is ready.
            enableLastNameInitial,
            eTag,
            groupId,
            groupDriveId,
            handle,
            hideOpenShifts,
            id,
            location,
            locationSettings,
            managedBy,
            name,
            offerShiftRequestsEnabled,
            pictureUrl,
            swapShiftsRequestsEnabled,
            teamChatId,
            teamIdsToShareScheduleWith, // TODO (CLM): Remove once the service is ready.
            tenantId,
            timeClockEnabled,
            timeOffRequestsEnabled,
            workforceIntegrationIds
        } = params;

        let modelId = id || TeamInfoEntity.generateNewTeamId();
        super(modelId, eTag);

        this.tenantId = tenantId;
        this.handle = handle;
        this.name = name;
        this.location = location || "";
        this.pictureUrl = pictureUrl || "";
        this.countryCode = countryCode || "";
        this.crossLocationShiftsEnabled = crossLocationShiftsEnabled ?? false;
        this.requireCrossLocationShiftRequestApprovalEnabled = requireCrossLocationShiftRequestApprovalEnabled ?? false;
        this.groupId = groupId;
        this.groupDriveId = groupDriveId;
        this.driveProvisionState = driveProvisionState;
        this.classification = classification;
        this.timeClockEnabled = timeClockEnabled;
        this.hideOpenShifts = hideOpenShifts;
        this.customFields = customFields ? this.customFields = customFields.map((detailedField: any) => {return CustomFieldEntity.fromJson(detailedField); }) : [];
        this.locationSettings = locationSettings ?  locationSettings.map((locationSetting: LocationSetting) => { return locationSetting; }) : [];
        this.teamChatId = teamChatId;
        this.enableLastNameInitial = enableLastNameInitial;
        this.enableCrossTeamScheduling = enableCrossTeamScheduling ?? null; // TODO (CLM): Remove once the service is ready.
        this.swapShiftsRequestsEnabled = swapShiftsRequestsEnabled;
        this.offerShiftRequestsEnabled = offerShiftRequestsEnabled;
        this.timeOffRequestsEnabled = timeOffRequestsEnabled;
        this.copyShiftActivitiesWithShiftsEnabled = copyShiftActivitiesWithShiftsEnabled;
        this.teamIdsToShareScheduleWith = teamIdsToShareScheduleWith && teamIdsToShareScheduleWith.length ? teamIdsToShareScheduleWith.map((teamId) => { return teamId; }) : []; // TODO (CLM): Remove once the service is ready.
        this.managedBy = managedBy;
        this.workforceIntegrationIds = workforceIntegrationIds && workforceIntegrationIds.length ? workforceIntegrationIds.map((workforceIntegrationId) => { return workforceIntegrationId; }) : [];
    }

    /**
     * Generate new teamID used by Shiftr App
     * @returns {string} teamId in form of TEAM_<uuid>
     */
    public static generateNewTeamId(): string {
        return ModelUtils.generateUUIDWithPrefix(teamIdPrefix);
    }

 /**
     * Generate new Test teamID used for testing App
     * @returns {string} teamId in form of TEAM_<uuid> to end with "00000"
     */
    public static generateNewTestTeamId(): string {
        return TestUtils.generateTestUUIDWithPrefix(teamIdPrefix);
    }

    /**
     * Function that converts json from service response to TeamInfoEntity
     * @param jsonData - response from service
     */
    public static fromJson(jsonData: ITeamInfoEntity): ITeamInfoEntity {
        if (!jsonData) {
            return null;
        } else {
            return new TeamInfoEntity(jsonData);
        }
    }

    /**
     * Returns a clone of the provided team model
     * @param team
     */
    public static clone(team: ITeamInfoEntity): ITeamInfoEntity {
        return TeamInfoEntity.fromJson(team);
    }

    /**
     * Function that creates service JSON from TeamInfoEntity
     * @param teamInfoModel - team entity that needs to be sent over the wire
     */
    public static toJson(teamInfoModel: ITeamInfoEntity): ITeamInfoEntity {
        let customFields: ICustomFieldEntity[] = [];
        if (teamInfoModel.customFields) {
            customFields = teamInfoModel.customFields.map((detailedField: any) => {
                return CustomFieldEntity.toJson(detailedField);
            });
        }

        let locationSettings: LocationSetting[] = [];
        if (teamInfoModel.locationSettings) {
            locationSettings = teamInfoModel.locationSettings.map((locationSetting: LocationSetting) => {
                return locationSetting;
            });
        }

        let teamInfoJson: ITeamInfoEntity = {
            id: teamInfoModel.id,
            eTag: teamInfoModel.eTag,
            tenantId: teamInfoModel.tenantId,
            handle: teamInfoModel.handle,
            name: teamInfoModel.name,
            location: teamInfoModel.location,
            pictureUrl: teamInfoModel.pictureUrl,
            countryCode: teamInfoModel.countryCode,
            crossLocationShiftsEnabled: teamInfoModel.crossLocationShiftsEnabled,
            requireCrossLocationShiftRequestApprovalEnabled: teamInfoModel.requireCrossLocationShiftRequestApprovalEnabled,
            groupId: teamInfoModel.groupId,
            groupDriveId: teamInfoModel.groupDriveId,
            driveProvisionState: teamInfoModel.driveProvisionState,
            classification: teamInfoModel.classification,
            timeClockEnabled: teamInfoModel.timeClockEnabled,
            hideOpenShifts: teamInfoModel.hideOpenShifts,
            locationSettings: locationSettings,
            customFields,
            teamChatId: teamInfoModel.teamChatId,
            enableLastNameInitial: teamInfoModel.enableLastNameInitial,
            enableCrossTeamScheduling: teamInfoModel.enableCrossTeamScheduling,
            swapShiftsRequestsEnabled: teamInfoModel.swapShiftsRequestsEnabled,
            offerShiftRequestsEnabled: teamInfoModel.offerShiftRequestsEnabled,
            timeOffRequestsEnabled: teamInfoModel.timeOffRequestsEnabled,
            copyShiftActivitiesWithShiftsEnabled: teamInfoModel.copyShiftActivitiesWithShiftsEnabled,
            teamIdsToShareScheduleWith: teamInfoModel.teamIdsToShareScheduleWith,
            managedBy: teamInfoModel.managedBy,
            workforceIntegrationIds: teamInfoModel.workforceIntegrationIds
        };

        return teamInfoJson;
    }

    /**
     * Static method to return the TeamType
     * @param {ITeamInfoEntity} team
     * @return {TeamType}
     */
    static getTeamType(team: ITeamInfoEntity): TeamTypes {
        if (isFeatureEnabled("newteam")) {
            return TeamTypes.New;
        }

        return TeamTypes.Standard;
    }
}