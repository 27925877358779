import { action } from "satcheljs";
import { ShiftRequestType } from "sh-models";

const createSwapHandoffRequest = action(
    "CREATE_SWAP_HANDOFF_REQUEST",
    (
        shiftId: string,
        otherShiftId: string,
        receiverMemberId: string,
        message: string,
        requestType: ShiftRequestType
    ) => ({
        shiftId: shiftId,
        otherShiftId: otherShiftId,
        receiverMemberId: receiverMemberId,
        message: message,
        requestType: requestType
    })
);

export default createSwapHandoffRequest;