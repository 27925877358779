/**
 * Error codes from the StaffHub Service (corresponds to ShiftrErrorCodes)
 */
export enum StaffHubErrorCodes {
    /// <summary>
    /// The request contains duplicate ids which are expected to be unique.
    /// </summary>
    DuplicateIdConflict = "DuplicateIdConflict",
    /// <summary>
    /// A conflicting resource with the same ID already exists
    /// </summary>
    ConflictingResourceExists = "ConflictingResourceExists",
    /// <summary>
    /// The team was not found or was not accessible
    /// </summary>
    TeamNotFound = "TeamNotFound",
    /// <summary>
    /// The role was not found or was not accessible
    /// </summary>
    RoleNotFound = "RoleNotFound",
    /// <summary>
    /// The role cannot be deleted
    /// </summary>
    RoleCannotBeDeleted = "RoleCannotBeDeleted",
    /// <summary>
    /// The user is not in the system or there is a failure provisioning the user
    /// </summary>
    UserNotFound = "UserNotFound",
    /// <summary>
    /// The user is not in the AAD hard deleted state
    /// </summary>
    UserNotAadHardDeleted = "UserNotAadHardDeleted",
    /// <summary>
    /// The user is not in AAD soft deleted state
    /// </summary>
    UserNotAadSoftDeleted = "UserNotAadSoftDeleted",
    /// <summary>
    /// The user settings were not found
    /// </summary>
    UserSettingsNotFound = "UserSettingsNotFound",
    /// <summary>
    /// One or more recipients invited to the conversation were not found
    /// </summary>
    InvalidRecipients = "InvalidRecipients",
    /// <summary>
    /// The user is not part of the conversation
    /// </summary>
    UserNotInConversation = "UserNotInConversation",
    /// <summary>
    /// Verified phone number is missing from the ticket to call the corresponding login API
    /// </summary>
    NeedsVerifiedPhoneNumber = "NeedsVerifiedPhoneNumber",
    /// <summary>
    /// Login API parallel processing failed
    /// </summary>
    LoginAsyncProcessingFailed = "LoginAsyncProcessingFailed",
    /// <summary>
    /// The AAD group is already linked to a StaffHub team.
    /// </summary>
    AadGroupIsLinkedToStaffHubTeam = "AadGroupIsLinkedToStaffHubTeam",
    /// <summary>
    /// A Team with the same ID already exists.
    /// </summary>
    TeamsTeamAlreadyCreated = "TeamsTeamAlreadyCreated",
    /// <summary>
    /// Provisioning a team for Microsoft Teams team failed.
    /// </summary>
    ScheduleProvisionFailed = "ScheduleProvisionFailed",
    /// <summary>
    /// It took too long to provision the team
    /// </summary>
    ScheduleProvisionTimedOut = "ScheduleProvisionTimedOut",
    /// <summary>
    /// After provisioning a team for Microsoft Teams team, will send CreateTeamNotification.
    /// This is the error code if createTeamNotification failed.
    /// </summary>
    CreateTeamNotificationFailed = "CreateTeamNotificationFailed",
    /// <summary>
    /// The user is not whitelisted to create a team
    /// </summary>
    TeamCreateForbidden = "TeamCreateForbidden",
    /// <summary>
    /// The team insert failed.
    /// </summary>
    TeamCreateFailed = "TeamCreateFailed",
    /// <summary>
    /// Provisioning a drive and group for the team failed.
    /// </summary>
    TeamProvisionFailed = "TeamProvisionFailed",
    /// <summary>
    /// The team delete failed.
    /// </summary>
    TeamDeleteFailed = "TeamDeleteFailed",
    /// <summary>
    /// The team update failed due to a conflicting change
    /// </summary>
    TeamUpdateConflict = "TeamUpdateConflict",
    /// <summary>
    /// The team update failed.
    /// </summary>
    TeamUpdateFailed = "TeamUpdateFailed",
    /// <summary>
    /// The team handle is unavailable
    /// </summary>
    TeamHandleUnavailable = "TeamHandleUnavailable",
    /// <summary>
    /// The team setting create failed.
    /// </summary>
    TeamSettingCreateFailed = "TeamSettingCreateFailed",
    /// <summary>
    /// The team setting update failed.
    /// </summary>
    TeamSettingUpdateFailed = "TeamSettingUpdateFailed",
    /// <summary>
    /// The team setting update failed due to a conflicting change.
    /// </summary>
    TeamSettingUpdateConflict = "TeamSettingUpdateConflict",
    /// <summary>
    /// The team setting does not exist.
    /// </summary>
    TeamSettingNotFound = "TeamSettingNotFound",
    /// <summary>
    /// The team didn't have a reference to an Exchange Group
    /// </summary>
    TeamGroupNotCreated = "TeamGroupNotCreated",
    /// <summary>
    /// Failed to soft delete the team's associated group
    /// </summary>
    TeamGroupNotDeleted = "TeamGroupNotDeleted",
    /// <summary>
    /// Failed to hard delete the team's associated group
    /// </summary>
    TeamGroupHardDeletionFailure = "TeamGroupHardDeletionFailure",
    /// <summary>
    /// Listing team files failed.
    /// </summary>
    TeamFileListingFailed = "TeamFileListingFailed",
    /// <summary>
    /// The role insert failed.
    /// </summary>
    RoleCreateFailed = "RoleCreateFailed",
    /// <summary>
    /// The role delete failed.
    /// </summary>
    RoleDeleteFailed = "RoleDeleteFailed",
    /// <summary>
    /// The role update failed due to a conflicting change.
    /// </summary>
    RoleUpdateConflict = "RoleUpdateConflict",
    /// <summary>
    /// The role update failed.
    /// </summary>
    RoleUpdateFailed = "RoleUpdateFailed",
    /// <summary>
    /// The command has failed because it has led to less than 1 admin.
    /// </summary>
    NeedAtLeastOneAdmin = "NeedAtLeastOneAdmin",
    /// <summary>
    /// The tag insert failed.
    /// </summary>
    TagCreateFailed = "TagCreateFailed",
    /// <summary>
    /// The tag update failed due to a conflicting change.
    /// </summary>
    TagNameTaken = "TagNameTaken",
    /// <summary>
    /// The tag update failed.
    /// </summary>
    TagUpdateFailed = "TagUpdateFailed",
    /// <summary>
    /// The tag delete failed.
    /// </summary>
    TagDeleteFailed = "TagDeleteFailed",
    /// <summary>
    /// The tag was not found or was not accessible
    /// </summary>
    TagNotFound = "TagNotFound",
    /// <summary>
    /// The tag update failed due to a conflicting change.
    /// </summary>
    TagUpdateConflict = "TagUpdateConflict",
    /// <summary>
    /// Indicates that the user already exists in the given context.
    /// </summary>
    UserExists = "UserExists",
    /// <summary>
    /// Username update failed.
    /// </summary>
    UsernameUpdateFailed = "UsernameUpdateFailed",
    /// <summary>
    /// Name update failed.
    /// </summary>
    NameUpdateFailed = "NameUpdateFailed",
    /// <summary>
    /// A search failed.
    /// </summary>
    SearchFailed = "SearchFailed",
    /// <summary>
    /// The user is missing the admin permissions
    /// </summary>
    MissingAdminPermissions = "MissingAdminPermissions",
    /// <summary>
    /// The user is not allowed to delete the shift requests
    /// </summary>
    MissingDeleteShiftRequestsPermission = "MissingDeleteShiftRequestsPermission",
    /// <summary>
    /// The member creation failed
    /// </summary>
    MemberCreateFailed = "MemberCreateFailed",
    /// <summary>
    /// The member update failed due to a conflicting change.
    /// </summary>
    MemberUpdateConflict = "MemberUpdateConflict",
    /// <summary>
    /// The member update failed.
    /// </summary>
    MemberUpdateFailed = "MemberUpdateFailed",
    /// <summary>
    /// The member was not found
    /// </summary>
    MemberNotFound = "MemberNotFound",
    /// <summary>
    /// The memberId is required and not found
    /// </summary>
    MemberIdRequired = "MemberIdRequired",
    /// <summary>
    /// Member is not part of the tag/group
    /// </summary>
    MemberNotPartOfGroup = "MemberNotPartOfGroup",
    /// <summary>
    /// You can't delete yourself
    /// </summary>
    MemberSelfNotDeletable = "MemberSelfNotDeletable",
    /// <summary>
    /// You cannot change the basic data for an active member
    /// </summary>
    MemberActiveBasicDataCannotChange = "MemberActiveBasicDataCannotChange",
    /// <summary>
    /// There is another member in the team with the same phone number
    /// </summary>
    MemberExistsWithSamePhone = "MemberExistsWithSamePhone",
    /// <summary>
    /// There is another member in the team with the same email
    /// </summary>
    MemberExistsWithSameEmail = "MemberExistsWithSameEmail",
    /// <summary>
    /// There is another member in the team with the same user ID
    /// </summary>
    MemberExistsWithSameUserId = "MemberExistsWithSameUserId",
    /// <summary>
    /// The shift creation failed
    /// </summary>
    ShiftCreateFailed = "ShiftCreateFailed",
    /// <summary>
    /// The shift was not found
    /// </summary>
    ShiftNotFound = "ShiftNotFound",
    /// <summary>
    /// The shift update failed.
    /// </summary>
    ShiftUpdateFailed = "ShiftUpdateFailed",
    /// <summary>
    /// The shift update failed due to a conflicting change
    /// </summary>
    ShiftUpdateConflict = "ShiftUpdateConflict",
    /// <summary>
    /// The shift is not available to be acted on
    /// </summary>
    ShiftNotAvailable = "ShiftNotAvailable",
    /// <summary>
    /// The shift request creation failed
    /// </summary>
    ShiftRequestCreateFailed = "ShiftRequestCreateFailed",
    /// <summary>
    /// The shift request was not found
    /// </summary>
    ShiftRequestNotFound = "ShiftRequestNotFound",
    /// <summary>
    /// The shift request is not available to be acted on
    /// </summary>
    ShiftRequestNotAvailable = "ShiftRequestNotAvailable",
    /// <summary>
    /// The shift request update failed due to a conflicting change.
    /// </summary>
    ShiftRequestUpdateConflict = "ShiftRequestUpdateConflict",
    /// <summary>
    /// The shift request update failed.
    /// </summary>
    ShiftRequestUpdateFailed = "ShiftRequestUpdateFailed",
    /// <summary>
    /// We failed to update the shift request read time because of invalid data.
    /// </summary>
    ShiftRequestMarkAsReadInvalid = "ShiftRequestMarkAsReadInvalid",
    /// <summary>
    /// The shift request type is invalid
    /// </summary>
    ShiftRequestTypeInvalid = "ShiftRequestTypeInvalid",
    /// <summary>
    /// The shift requests cannot be acted upon due to invalid permissions or team open shift settings
    /// </summary>
    ShiftRequestsCannotBeActedUpon = "ShiftRequestsCannotBeActedUpon",
    /// <summary>
    /// The shift requests manager member id is missing.
    /// </summary>
    ShiftRequestsManagerMemberIdMissing = "ShiftRequestsManagerMemberIdMissing",
    /// <summary>
    /// The shift requests manager member id is invalid, member is not manager of team.
    /// </summary>
    ShiftRequestsManagerMemberIdIsInvalid = "ShiftRequestsManagerMemberIdIsInvalid",
    /// <summary>
    /// The shift requests manager member id, user id is not available.
    /// </summary>
    ShiftRequestsManagerMemberId_UserIdNotAvailable = "ShiftRequestsManagerMemberId_UserIdNotAvailable",
    /// <summary>
    /// The time off reason was not found
    /// </summary>
    TimeOffReasonNotFound = "TimeOffReasonNotFound",
    /// <summary>
    /// The invite was not found
    /// </summary>
    InviteNotFound = "InviteNotFound",
    /// <summary>
    /// The invite has expired
    /// </summary>
    InviteExpired = "InviteExpired",
    /// <summary>
    /// The invite was sent to a different email
    /// </summary>
    InviteEmailMismatch = "InviteEmailMismatch",
    /// <summary>
    /// The invite was sent to a different tenant
    /// </summary>
    InviteTenantMismatch = "InviteTenantMismatch",
    /// <summary>
    /// The invite can only be accepted with an O365 account
    /// </summary>
    InviteNeedsO365Account = "InviteNeedsO365Account",
    /// <summary>
    /// The invite can only be accepted with a non-O365 account
    /// </summary>
    InviteNeedsNonO365Account = "InviteNeedsNonO365Account",
    /// <summary>
    /// The system is in read-only mode
    /// </summary>
    ReadOnlyMode = "ReadOnlyMode",
    /// <summary>
    /// The system is in maintenance mode (no read/write)
    /// </summary>
    MaintenanceMode = "MaintenanceMode",
    /// <summary>
    /// The note creation failed
    /// </summary>
    NoteCreateFailed = "NoteCreateFailed",
    /// <summary>
    /// The note was not found
    /// </summary>
    NoteNotFound = "NoteNotFound",
    /// <summary>
    /// The note update failed due to a conflicting change.
    /// </summary>
    NoteUpdateConflict = "NoteUpdateConflict",
    /// <summary>
    /// The note update failed.
    /// </summary>
    NoteUpdateFailed = "NoteUpdateFailed",
    /// <summary>
    /// The member's email is not a valid OrgId email
    /// </summary>
    InvalidOrgIdEmail = "InvalidOrgIdEmail",
    /// <summary>
    /// The API is not available
    /// </summary>
    ApiNotAvailable = "ApiNotAvailable",
    /// <summary>
    /// Acquiring token from AAD failed
    /// </summary>
    TokenAcquireFailed = "TokenAcquireFailed",
    /// <summary>
    /// Need O365 account
    /// </summary>
    NeedO365Account = "NeedO365Account",
    /// <summary>
    /// Data load failed from Graph api
    /// </summary>
    GraphDataLoadFailed = "GraphDataLoadFailed",
    /// <summary>
    /// The tenant settings were not found or were not accessible
    /// </summary>
    TenantSettingsNotFound = "TenantSettingsNotFound",
    /// <summary>
    /// The tenant settings update failed due to a conflicting change
    /// </summary>
    TenantSettingsConflict = "TenantSettingsConflict",
    /// <summary>
    /// The tenant settings update failed.
    /// </summary>
    TenantSettingsUpdateFailed = "TenantSettingsUpdateFailed",
    /// <summary>
    /// Error due to mismatch in phone number
    /// </summary>
    PhoneNumberMismatch = "PhoneNumberMismatch",
    /// <summary>
    /// Create user operation failed
    /// </summary>
    CreateUserFailed = "CreateUserFailed",
    /// <summary>
    /// Get user operation failed
    /// </summary>
    GetGraphUserAccountFailed = "GetGraphUserAccountFailed",
    /// <summary>
    /// The provisioning domain specified is not one of the verified domains for the tenant
    /// </summary>
    InvalidDomain = "InvalidDomain",
    /// <summary>
    /// Account provisioning has been disabled
    /// </summary>
    ProvisionDisabled = "ProvisionDisabled",
    /// <summary>
    /// Yammer Request Failed
    /// </summary>
    InvalidYammerRequest = "InvalidYammerRequest",
    /// <summary>
    /// Indicates the team's Modern Group wasn't found.
    /// </summary>
    TeamGroupNotFound = "TeamGroupNotFound",
    /// <summary>
    /// Indicates the tenant wasn't found.
    /// </summary>
    TenantNotFound = "TenantNotFound",
    /// <summary>
    /// Indicates the tenant's staffhub app was disabled.
    /// </summary>
    TenantStaffhubAppDisabled = "TenantStaffhubAppDisabled",
    /// <summary>
    /// The time off reason update failed.
    /// </summary>
    TimeOffReasonUpdateFailed = "TimeOffReasonUpdateFailed",
    /// <summary>
    /// The time off reason update failed due to a conflicting change
    /// </summary>
    TimeOffReasonUpdateConflict = "TimeOffReasonUpdateConflict",
    /// <summary>
    /// Indicates that creating the upload session failed.
    /// </summary>
    CreateUploadSessionFailed = "CreateUploadSessionFailed",
    /// <summary>
    /// Indicates that the file upload failed.
    /// </summary>
    UploadFileFailed = "UploadFileFailed",
    /// <summary>
    /// Indicates that the file deletion failed.
    /// </summary>
    DeleteFileFailed = "DeleteFileFailed",
    /// <summary>
    /// Indicates that the provided file name is invalid.
    /// </summary>
    FileNameInvalid = "FileNameInvalid",
    /// <summary>
    /// Create group failed
    /// </summary>
    CreateGroupFailed = "CreateGroupFailed",
    /// <summary>
    /// Update group failed
    /// </summary>
    UpdateGroupFailed = "UpdateGroupFailed",
    /// <summary>
    /// Indicates that adding the user to the group failed.
    /// </summary>
    AddGroupMemberFailed = "AddGroupMemberFailed",
    /// <summary>
    /// Indicates that the quota limit for the tenant has been exceeded.
    /// </summary>
    DirectoryQuotaLimitExceeded = "DirectoryQuotaLimitExceeded",
    /// <summary>
    /// Indicates that the Unified Group creation is not enabled at the tenant level.
    /// </summary>
    GroupCreationNotEnabled = "GroupCreationNotEnabled",
    /// <summary>
    /// Indicates that the file, user was trying to download, was not found.
    /// </summary>
    TeamFileNotFound = "TeamFileNotFound",
    /// <summary>
    /// Indicates that PDF conversion is disabled
    /// </summary>
    PdfConversionDisabled = "PdfConversionDisabled",
    /// <summary>
    /// Thumbnail Creation Failed
    /// </summary>
    ThumbnailCreationFailed = "ThumbnailCreationFailed",
    /// <summary>
    /// Thumbnail Save Failed
    /// </summary>
    ThumbnailSaveFailed = "ThumbnailSaveFailed",
    /// <summary>
    /// Indicates that PDF conversion failed
    /// </summary>
    PdfConversionFailed = "PdfConversionFailed",
    /// <summary>
    /// Multi factor authentication is required
    /// </summary>
    MultiFactorAuthRequired = "MultiFactorAuthRequired",
    /// <summary>
    /// Media (i.e. Image) not found in Azure Blob Storage
    /// </summary>
    MediaNotFound = "MediaNotFound",
    /// <summary>
    /// Media (i.e. Image) requires the user to be a part of the conversation to access
    /// </summary>
    InsufficientPermissionsForMedia = "InsufficientPermissionsForMedia",
    /// <summary>
    /// Failed to process service bus message
    /// </summary>
    ServiceBusMessageProcessingFailed = "ServiceBusMessageProcessingFailed",
    /// <summary>
    /// Running differential query failed
    /// </summary>
    DifferentialQueryFailed = "DifferentialQueryFailed",
    /// <summary>
    /// Indicates the parsing of the PowerApps identifier failed
    /// </summary>
    PowerAppsIdParseFailed = "PowerAppsIdParseFailed",
    /// <summary>
    /// Indicates a failure occurred while determining if the user has an assigned plan
    /// </summary>
    ServicePlanLookupFailed = "ServicePlanLookupFailed",
    /// <summary>
    /// Indicates a failure occurred while retrieving the user's profile
    /// </summary>
    UserProfileRetrievalFailed = "UserProfileRetrievalFailed",
    /// <summary>
    /// Indicates a failure occurred while checking if the user is an admin
    /// </summary>
    IsUserAdminLookupFailed = "IsUserAdminLookupFailed",
    /// <summary>
    /// Indicates a failure occurred while parsing a Graph Error
    /// </summary>
    GraphErrorParsingFailed = "GraphErrorParsingFailed",
    /// <summary>
    /// Indicates a failure occurred retrieving users from GAL
    /// </summary>
    GetUsersFromGalFailed = "GetUsersFromGalFailed",
    /// <summary>
    /// Indicates a failure occurred retrieving organization details from Graph
    /// </summary>
    GetOrganizationDetailsFailed = "GetOrganizationDetailsFailed",
    /// <summary>
    /// Indicates no details for the given organization were found
    /// </summary>
    OrganizationDetailsNotFound = "OrganizationDetailsNotFound",
    /// <summary>
    /// Indicates a drive was not found for the given team
    /// </summary>
    DriveItemNotFound = "DriveItemNotFound",
    /// <summary>
    /// Indicates no thumbnail metadata was found for the given item
    /// </summary>
    ThumbnailMetadataNotFound = "ThumbnailMetadataNotFound",
    /// <summary>
    /// Indicates the user has insufficient privileges to complete the operation
    /// </summary>
    InsufficientPrivileges = "InsufficientPrivileges",
    /// <summary>
    /// Indicates the request was unauthorized due to outdated credentials
    /// </summary>
    UnauthorizedInvalidCredentials = "UnauthorizedInvalidCredentials",
    /// <summary>
    /// Indicates the group or drive was not found. Potentially mistakenly deleted
    /// </summary>
    GraphResourceNotFound = "GraphResourceNotFound",
    /// <summary>
    /// Indicates that user is not yet added to private group.
    /// </summary>
    GraphAccessFailed = "GraphAccessFailed",
    /// <summary>
    /// The data is for another tenant
    /// </summary>
    TenantMismatch = "TenantMismatch",
    /// <summary>
    /// The user create failed.
    /// </summary>
    UserCreateFailed = "UserCreateFailed",
    /// <summary>
    /// The user update failed due to a conflicting change.
    /// </summary>
    UserUpdateConflict = "UserUpdateConflict",
    /// <summary>
    /// The user update failed.
    /// </summary>
    UserUpdateFailed = "UserUpdateFailed",
    /// <summary>
    /// The search query was invalid
    /// </summary>
    InvalidSearchQuery = "InvalidSearchQuery",
    /// <summary>
    /// The user settings update failed
    /// </summary>
    UserSettingsUpdateFailed = "UserSettingsUpdateFailed",
    /// <summary>
    /// The user settings update failed
    /// </summary>
    GetUserSettingsFailed = "GetUserSettingsFailed",
    /// <summary>
    /// The user settings failed due to a conflicting change
    /// </summary>
    UserSettingsConflict = "UserSettingsConflict",
    /// <summary>
    /// The member already exists
    /// </summary>
    MemberAlreadyExists = "MemberAlreadyExists",
    /// <summary>
    /// The note already exists
    /// </summary>
    NoteAlreadyExists = "NoteAlreadyExists",
    /// <summary>
    /// The role already exists
    /// </summary>
    RoleAlreadyExists = "RoleAlreadyExists",
    /// <summary>
    /// The tag already exists
    /// </summary>
    TagAlreadyExists = "TagAlreadyExists",
    /// <summary>
    /// The team already exists
    /// </summary>
    TeamAlreadyExists = "TeamAlreadyExists",
    /// <summary>
    /// The shift already exists
    /// </summary>
    ShiftAlreadyExists = "ShiftAlreadyExists",
    /// <summary>
    /// The shift request already exists
    /// </summary>
    ShiftRequestAlreadyExists = "ShiftRequestAlreadyExists",
    /// <summary>
    /// The shift must be active to be acted on
    /// </summary>
    ShiftMustBeActive = "ShiftMustBeActive",
    /// <summary>
    /// The offer shift request specifies an "other shift" when it should not
    /// </summary>
    ShiftRequestOfferContainsOtherShift = "ShiftRequestOfferContainsOtherShift",
    /// <summary>
    /// The shift to swap for needs a member associated with it
    /// </summary>
    ShiftRequestShiftNeedsMember = "ShiftRequestShiftNeedsMember",
    /// <summary>
    /// The drive item id was not specified
    /// </summary>
    DriveItemIdNotSpecified = "DriveItemIdNotSpecified",
    /// <summary>
    /// The extensibility link is invalid
    /// </summary>
    ExtensibilityLinkInvalid = "ExtensibilityLinkInvalid",
    /// <summary>
    /// The invalid security group name
    /// </summary>
    InvalidSecurityGroupName = "InvalidSecurityGroupName",
    /// <summary>
    /// There is no new security group to change settings of
    /// </summary>
    NoNewSecurityGroupToChangeSettingsOf = "NoNewSecurityGroupToChangeSettingsOf",
    /// <summary>
    /// The extension was not found.
    /// </summary>
    ExtensionNotFound = "ExtensionNotFound",
    /// <summary>
    /// The extension request failed.
    /// </summary>
    ExtensionRequestFailed = "ExtensionRequestFailed",
    /// <summary>
    /// The extension in not available.
    /// </summary>
    ExtensionNotAvailable = "ExtensionNotAvailable",
    /// <summary>
    /// The provided ActivityFeedItem Id was not found
    /// </summary>
    InvalidActivityFeedItemId = "InvalidActivityFeedItemId",
    /// <summary>
    /// The ActivityFeedItem update failed.
    /// </summary>
    ActivityFeedItemUpdateFailed = "ActivityFeedItemUpdateFailed",
    /// <summary>
    /// The ActivityFeedItem update failed due to a conflicting change.
    /// </summary>
    ActivityFeedItemUpdateConflict = "ActivityFeedItemUpdateConflict",
    /// <summary>
    /// We failed to update the activityfeed seen time because of invalid data.
    /// </summary>
    ActivityFeedMarkAsSeenInvalid = "ActivityFeedMarkAsSeenInvalid",
    /// <summary>
    /// Backfill request failed
    /// </summary>
    BackfillRequestFailed = "BackfillRequestFailed",
    /// <summary>
    /// The get thread from ms graph failed
    /// </summary>
    GetThreadFromMsGraphFailed = "GetThreadFromMsGraphFailed",
    /// <summary>
    /// The get post from ms graph failed
    /// </summary>
    GetPostFromMsGraphFailed = "GetPostFromMsGraphFailed",
    /// <summary>
    /// The get attachments from ms graph failed
    /// </summary>
    GetAttachmentsFromMsGraphFailed = "GetAttachmentsFromMsGraphFailed",
    /// <summary>
    /// The shift request is deleted and cannot be acted on
    /// </summary>
    ShiftRequestDeletedAndCannotBeActedOn = "ShiftRequestDeletedAndCannotBeActedOn",
    /// <summary>
    /// The punch creation failed
    /// </summary>
    ExternalPunchCreateFailed = "ExternalPunchCreateFailed",
    /// <summary>
    /// The shift creation failed
    /// </summary>
    ExternalShiftCreateFailed = "ExternalShiftCreateFailed",
    /// <summary>
    /// External time off request failed
    /// </summary>
    ExternalTimeOffRequestFailed = "ExternalTimeOffRequestFailed",
    /// <summary>
    /// The shifts creation failed
    /// </summary>
    ExternalShiftsCreateFailed = "ExternalShiftsCreateFailed",
    /// <summary>
    /// The shift update failed
    /// </summary>
    ExternalShiftUpdateFailed = "ExternalShiftUpdateFailed",
    /// <summary>
    /// The shift delete failed
    /// </summary>
    ExternalShiftDeleteFailed = "ExternalShiftDeleteFailed",
    /// <summary>
    /// handoff/swap shift failed
    /// </summary>
    ExternalShiftSwapHandoffFailed = "ExternalShiftSwapHandoffFailed",
    /// <summary>
    /// The extension scenario not supported
    /// </summary>
    ExtensionScenarioNotSupported = "ExtensionScenarioNotSupported",
    /// <summary>
    /// The extension shifts import failed.
    /// </summary>
    ExternalShiftsImportFailed = "ExternalShiftsImportFailed",
    /// <summary>
    /// External system login failed
    /// </summary>
    ExternalLoginFailed = "ExternalLoginFailed",
    /// <summary>
    /// Failed to get External Teams
    /// </summary>
    GetExternalTeamsFailed = "GetExternalTeamsFailed",
    /// <summary>
    /// Failed to get External Members
    /// </summary>
    GetExternalMembersFailed = "GetExternalMembersFailed",
    /// <summary>
    /// Failed to Import External Members
    /// </summary>
    ImportExternalMembersFailed = "ImportExternalMembersFailed",
    /// <summary>
    /// Failed to get External TimeOff reasons
    /// </summary>
    GetExternalTimeOffReasonsFailed = "GetExternalTimeOffReasonsFailed",
    /// <summary>
    /// Failed to import External TimeOff reasons
    /// </summary>
    ImportExternalTimeOffReasonsFailed = "ImportExternalTimeOffReasonsFailed",
    /// <summary>
    /// Extension configured but master not configured
    /// </summary>
    DataMasterNotConfigured = "DataMasterNotConfigured",
    /// <summary>
    /// The get threads from yammer failed
    /// Indicates a failure occurred retrieving threads from yammer
    /// </summary>
    GetThreadsFromYammerFailed = "GetThreadsFromYammerFailed",
    /// <summary>
    /// Indicates the creation of the associated team in Skype Teams failed.
    /// </summary>
    SkypeTeamCreationFailed = "SkypeTeamCreationFailed",
    /// <summary>
    /// Indicates that the announcement wasn't found in the system.
    /// </summary>
    AnnouncementNotFound = "AnnouncementNotFound",
    /// <summary>
    /// Indicates that the announcement attachment wasn't found in the system.
    /// </summary>
    AnnouncementAttachmentNotFound = "AnnouncementAttachmentNotFound",
    /// <summary>
    /// An email in the O365 group was not found
    /// </summary>
    GroupEmailNotFound = "GroupEmailNotFound",
    /// <summary>
    /// Indicates that the conversation still exists
    /// </summary>
    ConversationStillExists = "ConversationStillExists",
    /// <summary>
    /// Indicates that we could not create a subscription
    /// </summary>
    SubscriptionCreationFailed = "SubscriptionCreationFailed",
    /// <summary>
    /// Indicates that we could not update a subscription
    /// </summary>
    SubscriptionUpdateFailed = "SubscriptionUpdateFailed",
    /// <summary>
    /// Indicates that we could not delete a subscription
    /// </summary>
    SubscriptionDeletionFailed = "SubscriptionDeletionFailed",
    /// <summary>
    /// Failed to get External Accruals
    /// </summary>
    GetExternalAccrualsFailed = "GetExternalAccrualsFailed",
    /// <summary>
    /// Failed to set credentials
    /// </summary>
    SetCredentialsFailed = "SetCredentialsFailed",
    /// <summary>
    /// Failed to get credentials
    /// </summary>
    GetCredentialsFailed = "GetCredentialsFailed",
    /// <summary>
    /// Failed to delete credentials
    /// </summary>
    DeleteCredentialsFailed = "DeleteCredentialsFailed",
    /// <summary>
    /// Failed to delete tenant data
    /// </summary>
    DeleteTenantDataFailed = "DeleteTenantDataFailed",
    /// <summary>
    /// Set tenant extension settings failed
    /// </summary>
    SetTenantExtensionSettingFailed = "SetTenantExtensionSettingFailed",
    /// <summary>
    /// Setup team on extension failed
    /// </summary>
    SetupExtensionTeamFailed = "SetupExtensionTeamFailed",
    /// <summary>
    /// Linking to external team failed
    /// </summary>
    LinkExternalTeamFailed = "LinkExternalTeamFailed",
    /// <summary>
    /// Failed to delete member on extension
    /// </summary>
    DeleteMemberOnExtensionFailed = "DeleteMemberOnExtensionFailed",
    /// <summary>
    /// Failed to update member on extension
    /// </summary>
    UpdateMemberOnExtensionFailed = "UpdateMemberOnExtensionFailed",
    /// <summary>
    /// Failed to delete user on extension.
    /// </summary>
    DeleteUserOnExtensionFailed = "DeleteUserOnExtensionFailed",
    /// <summary>
    /// Failed to delete tenant on extension.
    /// </summary>
    DeleteTenantOnExtensionFailed = "DeleteTenantOnExtensionFailed",
    /// <summary>
    /// Time clock location not in range error
    /// </summary>
    LocationNotInRange = "LocationNotInRange",
    /// <summary>
    /// Missing time zone
    /// </summary>
    MissingTimeZone = "MissingTimeZone",
    /// <summary>
    /// Missing PaycodeProfileName
    /// </summary>
    MissingPaycodeProfileName = "MissingPaycodeProfileName",
    /// <summary>
    /// Invalid Paycode
    /// </summary>
    InvalidPaycode = "InvalidPaycode",
    /// <summary>
    /// Delete shift failed in update absence shift.
    /// </summary>
    DeleteOldShiftFailedInUpdateShift = "DeleteOldShiftFailedInUpdateShift",
    /// <summary>
    /// Create Shift failed in update absence shift.
    /// </summary>
    CreateNewShiftFailedInUpdateShift = "CreateNewShiftFailedInUpdateShift",
    /// <summary>
    /// Deleted shift in update absence shift.
    /// </summary>
    CreateOldShiftFailedInUpdateShift = "CreateOldShiftFailedInUpdateShift",
    /// <summary>
    /// Duplicate Time clock error
    /// </summary>
    DuplicateTimeClock = "DuplicateTimeClock",
    /// <summary>
    /// ShiftSegment overlap error
    /// </summary>
    ShiftSegmentOverlap = "ShiftSegmentOverlap",
    /// <summary>
    /// User not team creator
    /// </summary>
    UserNotTeamCreator = "UserNotTeamCreator",
    /// <summary>
    /// The Corporate Announcements feature is not flighted on
    /// </summary>
    AnnouncementsFeatureNotAvailable = "AnnouncementsFeatureNotAvailable",
    /// <summary>
    /// Feature not enabled
    /// </summary>
    FeatureNotEnabled = "FeatureNotEnabled",
    /// <summary>
    /// Task created failed
    /// </summary>
    TaskCreateFailed = "TaskCreateFailed",
    /// <summary>
    /// Task not found
    /// </summary>
    TaskNotFound = "TaskNotFound",
    /// <summary>
    /// Task update failed
    /// </summary>
    TaskUpdateFailed = "TaskUpdateFailed",
    /// <summary>
    /// Task update had a conflict
    /// </summary>
    TaskUpdateConflict = "TaskUpdateConflict",
    /// <summary>
    /// Deletion of tasks failed
    /// </summary>
    TasksDeleteFailed = "TasksDeleteFailed",
    /// <summary>
    /// Tasks delete had a conflict
    /// </summary>
    TasksDeleteConflict = "TasksDeleteConflict",
    /// <summary>
    /// Generic Error - The requesting entity does not have
    /// sufficient permissions to perform the operation.
    /// </summary>
    InsufficientPermissions = "InsufficientPermissions",
    /// <summary>
    /// Maximum number of WebHook Subscriptions for User is reached
    /// </summary>
    SubscriptionLimitReached = "SubscriptionLimitReached",
    /// <summary>
    /// There was an error enabling corporate announcements
    /// </summary>
    EnableAnnouncementsFailed = "EnableAnnouncementsFailed",
    /// <summary>
    /// The host part of external endpoint is invaild
    /// </summary>
    InvalidExternalEndpointHost = "InvalidExternalEndpointHost",
    /// <summary>
    /// The path part of external endpoint is bad
    /// </summary>
    BadExternalEndpointPath = "BadExternalEndpointPath",
    /// <summary>
    /// If settings details are inaccessible
    /// </summary>
    GroupSettingsInaccessible = "GroupSettingsInaccessible",
    /// <summary>
    /// Invalid Group Classification value
    /// </summary>
    InvalidClassification = "InvalidClassification",
    /// <summary>
    /// Error Fetching classification value
    /// </summary>
    ClassificationFetchFailed = "ClassificationFetchFailed",
    /// <summary>
    /// The search users in tenant function is temporarily disabled.
    /// </summary>
    SearchUsersInTenantDisabled = "SearchUsersInTenantDisabled",
    /// <summary>
    /// Validating/updating the group's display name and alias was unsuccessful.
    /// </summary>
    TeamGroupValidationFailed = "TeamGroupValidationFailed",
    /// <summary>
    /// Validating the group's display name/alias is impossible because the tenant admin set a prefix/suffix
    /// that exceeds the max group properties' lengths = "", or exceeds it even when we try using a shorter default value.
    /// </summary>
    TeamGroupCreationImpossiblePrefixSuffix = "TeamGroupCreationImpossiblePrefixSuffix",
    /// <summary>
    /// The time clock entry creation failed
    /// </summary>
    TimeClockCreateFailed = "TimeClockCreateFailed",
    /// <summary>
    /// The time clock entry updation failed
    /// </summary>
    TimeClockUpdateFailed = "TimeClockUpdateFailed",
    /// <summary>
    /// Invalid time clock state
    /// </summary>
    TimeClockStateInvalid = "TimeClockStateInvalid",
    /// <summary>
    /// Timeclock id already exists
    /// </summary>
    TimeClockIdAlreadyExists = "TimeClockIdAlreadyExists",
    /// <summary>
    /// Invalid time clock id
    /// </summary>
    TimeClockIdInvalid = "TimeClockIdInvalid",
    /// <summary>
    /// Time clock not found
    /// </summary>
    TimeClockNotFound = "TimeClockNotFound",
    /// <summary>
    /// Invalid next token to continue quering data storage for next set of items
    /// </summary>
    InvalidNextToken = "InvalidNextToken",
    /// <summary>
    /// The Microsoft Teams team creation failed
    /// </summary>
    SkypeTeamCreateFailed = "SkypeTeamCreateFailed",
    /// <summary>
    /// The user needs a Microsoft Teams license to create team
    /// </summary>
    MissingMicrosoftTeamsLicense = "MissingMicrosoftTeamsLicense",
    /// <summary>
    /// Failed to create a subscription for Corporate Announcements
    /// </summary>
    AnnouncementSubscriptionCreationFailed = "AnnouncementSubscriptionCreationFailed",
    /// <summary>
    /// Failed to renew a subscription for Corporate Announcements
    /// </summary>
    AnnouncementSubscriptionRenewalFailed = "AnnouncementSubscriptionRenewalFailed",
    /// <summary>
    /// Failed to store an announcement
    /// </summary>
    AnnouncementCreateFailed = "AnnouncementCreateFailed",
    /// <summary>
    /// The custom field was not found
    /// </summary>
    CustomFieldNotFound = "CustomFieldNotFound",
    /// <summary>
    /// The custom field selection option was not found
    /// </summary>
    CustomFieldSelectionOptionNotFound = "CustomFieldSelectionOptionNotFound",
    /// <summary>
    /// Attempt to update custom field type
    /// </summary>
    CustomFieldTypeCannotBeChanged = "CustomFieldTypeCannotBeChanged",
    /// <summary>
    /// Failed to ping kronos extension.
    /// </summary>
    ExtensionPingFailed = "ExtensionPingFailed",
    /// <summary>
    /// Update unique shift as deleted failed.
    /// </summary>
    UniqueShiftEntityUpdateFailed = "UniqueShiftEntityUpdateFailed",
    /// <summary>
    /// A custom field was not populated
    /// </summary>
    AllCustomFieldsMustBePopulated = "AllCustomFieldsMustBePopulated",
    /// <summary>
    /// A populated custom field can't be removed
    /// </summary>
    CustomFieldCannotBeRemoved = "CustomFieldCannotBeRemoved",
    /// <summary>
    /// Custom field value length is too long
    /// </summary>
    CustomFieldValueMaxLength = "CustomFieldValueMaxLength",
    /// <summary>
    /// Too many custom fields
    /// </summary>
    TooManyCustomFields = "TooManyCustomFields",
    /// <summary>
    /// Custom field names must be unique
    /// </summary>
    CustomFieldNamesMustBeUnique = "CustomFieldNamesMustBeUnique",
    /// <summary>
    /// Custom field name length is too long
    /// </summary>
    CustomFieldNameMaxLength = "CustomFieldNameMaxLength",
    /// <summary>
    /// Custom field description length is too long
    /// </summary>
    CustomFieldDescriptionMaxLength = "CustomFieldDescriptionMaxLength",
    /// <summary>
    /// Custom field option names must be unique
    /// </summary>
    CustomFieldOptionNamesMustBeUnique = "CustomFieldOptionNamesMustBeUnique",
    /// <summary>
    /// Only selection fields support having options defined on them
    /// </summary>
    CustomFieldOptionsOnlySupportedOnSelectionFields = "CustomFieldOptionsOnlySupportedOnSelectionFields",
    /// <summary>
    /// Too many custom field options
    /// </summary>
    TooManyCustomFieldOptions = "TooManyCustomFieldOptions",
    /// <summary>
    /// Need at least one custom field option on selection field
    /// </summary>
    EmptyCustomFieldOptions = "EmptyCustomFieldOptions",
    /// <summary>
    /// Custom field option name length is too long
    /// </summary>
    OptionNameMaxLength = "OptionNameMaxLength",
    /// <summary>
    /// Failed to save updates to shifts
    /// </summary>
    ShiftsUpdateFailed = "ShiftsUpdateFailed",
    /// <summary>
    /// Failed to save updates to notes
    /// </summary>
    NotesUpdateFailed = "NotesUpdateFailed",
    /// <summary>
    /// Failed to save updates to h published date
    /// </summary>
    PublishedDatesUpdateFailed = "PublishedDatesUpdateFailed",
    /// <summary>
    /// Indicates that adding an accepted sender to a group failed.
    /// </summary>
    AddAcceptedSenderToGroupFailed = "AddAcceptedSenderToGroupFailed",
    /// <summary>
    /// Indicates that removing an accepted sender from a group failed.
    /// </summary>
    RemoveAcceptedSenderFromGroupFailed = "RemoveAcceptedSenderFromGroupFailed",
    /// <summary>
    /// The oauth token in the request was invalid
    /// </summary>
    InvalidOAuthToken = "InvalidOAuthToken",
    /// <summary>
    /// Indicates the OpenShift has insufficient slot count and ShiftRequests are not allowed.
    /// </summary>
    OpenShiftWithInsufficientOpenSlots = "OpenShiftWithInsufficientOpenSlots",
    /// <summary>
    /// Creation of open shift failed
    /// </summary>
    OpenShiftCreateFailed = "OpenShiftCreateFailed",
    /// <summary>
    /// Open shift is not found
    /// </summary>
    OpenShiftNotFound = "OpenShiftNotFound",
    /// <summary>
    /// Conflict during open shift update
    /// </summary>
    OpenShiftUpdateConflict = "OpenShiftUpdateConflict",
    /// <summary>
    /// Update of open shift failed
    /// </summary>
    OpenShiftUpdateFailed = "OpenShiftUpdateFailed",
    /// <summary>
    /// Indicates that the open shift must be active
    /// </summary>
    OpenShiftMustBeActive = "OpenShiftMustBeActive",
    /// <summary>
    /// Open shift already exists
    /// </summary>
    OpenShiftAlreadyExists = "OpenShiftAlreadyExists",
    /// <summary>
    /// Updates of multiple open shifts failed
    /// </summary>
    OpenShiftsUpdateFailed = "OpenShiftsUpdateFailed",
    /// <summary>
    /// Open Shift is not published.
    /// </summary>
    OpenShiftMustBePublished = "OpenShiftMustBePublished",
    /// <summary>
    /// The open shift is not available to be acted upon
    /// </summary>
    OpenShiftNotAvailable = "OpenShiftNotAvailable",
    /// <summary>
    /// Update shift = "", shift requests and open shift failed due to e-tag conflicts (very specific scenario for bulk open shifts manager approval)
    /// </summary>
    ShiftEntitiesUpdateConflict = "ShiftEntitiesUpdateConflict",
    /// <summary>
    /// Update shift = "", shift requests and open shift failed due to storage reasons (very specific scenario for bulk open shifts manager approval)
    /// </summary>
    ShiftEntitiesUpdateFailed = "ShiftEntitiesUpdateFailed",
    /// <summary>
    /// Error code when bulk create of open shifts fail
    /// </summary>
    OpenShiftsCreateFailed = "OpenShiftsCreateFailed",
    /// <summary>
    /// The URL is not valid
    /// </summary>
    InvalidUrl = "InvalidUrl",
    /// <summary>
    /// The job was not found or was not accessible
    /// </summary>
    JobNotFound = "JobNotFound",
    /// <summary>
    /// File has both schedule and shifts tab filled
    /// </summary>
    FileWithBothScheduleAndShiftsFilled = "FileWithBothScheduleAndShiftsFilled",
    /// <summary>
    /// Invalid File extension
    /// </summary>
    InvalidFileExtension = "InvalidFileExtension",
    /// <summary>
    /// Invalid mime type
    /// </summary>
    NullORInvalidMimeType = "NullORInvalidMimeType",
    /// <summary>
    /// Request is throttled by the backend
    /// </summary>
    RequestThrottled = "RequestThrottled",
    /// <summary>
    /// Conflict when file is updated.
    /// </summary>
    FileUpdateConflict = "FileUpdateConflict",
    /// <summary>
    /// File update failed.
    /// </summary>
    FileUpdateFailed = "FileUpdateFailed",
    /// <summary>
    /// The get web hook tenant level subscription failed
    /// </summary>
    GetWebHookTenantLevelSubscriptionFailed = "GetWebHookTenantLevelSubscriptionFailed",
    /// <summary>
    /// Indicates a failure occurred while checking if the user is a member of a group in active directory
    /// </summary>
    IsUserMemberOfADGroupLookupFailed = "IsUserMemberOfADGroupLookupFailed",
    /// <summary>
    /// Too many matching resources
    /// </summary>
    TooManyMatchingResources = "TooManyMatchingResources",
    /// <summary>
    /// Can't link phone number to a user; it is already linked to another user
    /// </summary>
    PhoneLinkedToAnotherUser = "PhoneLinkedToAnotherUser",
    /// <summary>
    /// Can't change email address of AAD Phone Auth Linked Member
    /// </summary>
    CantChangeEmailForPhoneAuthUser = "CantChangeEmailForPhoneAuthUser",
    /// <summary>
    /// Failed to update an announcement
    /// </summary>
    AnnouncementUpdateFailed = "AnnouncementUpdateFailed",
    /// <summary>
    /// No Skype Team for this StaffHub team
    /// </summary>
    SkypeTeamNotFound = "SkypeTeamNotFound",
    /// <summary>
    /// User does not have a license to use the product
    /// </summary>
    UserNotLicensed = "UserNotLicensed",
    /// <summary>
    /// The request is to the wrong region
    /// </summary>
    InvalidTenantRegion = "InvalidTenantRegion",
    /// <summary>
    /// The request has an invalid device header
    /// </summary>
    InvalidTicket = "InvalidTicket",
    /// <summary>
    /// StaffHub has been disabled for the tenant
    /// </summary>
    StaffHubDisabled = "StaffHubDisabled",
    /// <summary>
    /// This tenant has a license that is not allowed
    /// </summary>
    LicenseNotAllowed = "LicenseNotAllowed",
    /// <summary>
    /// The client's sync key was not found
    /// (They need to clear their cache and perform a full sync again)
    /// </summary>
    ClientOutOfSync = "ClientOutOfSync",
        /// <summary>
    /// Unhandled exception
    /// </summary>
    InternalServerError = "InternalServerError",

    /// <summary>
    /// The server did not receive a timely response from the upstream server specified by the URI (e.g. HTTP, FTP, LDAP) or some other auxiliary server (e.g. DNS) it needed to access in attempting to complete the request.
    /// </summary>
    GatewayTimeout = "GatewayTimeout",

    /// <summary>
    /// This response means that server could not understand the request due to invalid syntax.
    /// </summary>
    BadRequest = "BadRequest",

    /// <summary>
    /// Authentication is needed to get requested response. This is similar to Forbidden, but in this case, authentication is possible.
    /// </summary>
    Unauthorized = "Unauthorized",

    /// <summary>
    /// Client does not have access rights to the content so server is rejecting to give proper response.
    /// </summary>
    Forbidden = "Forbidden",

    /// <summary>
    /// Server cannot find requested resource.
    /// </summary>
    NotFound = "NotFound",

    /// <summary>
    /// The specified resource already exists on the server.
    /// </summary>
    ResourceExists = "ResourceExists",

    /// <summary>
    /// This response would be sent when a request conflict with current state of server.
    /// </summary>
    ConcurrencyConflict = "ConcurrencyConflict",

    /// <summary>
    /// There was an error with storing the data
    /// </summary>
    StorageError = "StorageError",

    /// <summary>
    /// The same request was sent a second time
    /// </summary>
    RepeatRequest = "RepeatRequest",

    /// <summary>
    /// The user has sent too many requests in a given amount of time ("rate limiting").
    /// </summary>
    TooManyRequests = "TooManyRequests",

    /// <summary>
    /// The client's version is out-dated and they need to upgrade
    /// </summary>
    ClientStale = "ClientStale",

    /// <summary>
    /// An assert failed
    /// </summary>
    AssertError = "AssertError",

    /// <summary>
    /// The email has an invalid format (inner error code)
    /// </summary>
    InvalidEmail = "InvalidEmail",

    /// <summary>
    /// The phone number format is invalid (inner error code)
    /// </summary>
    InvalidPhoneNumber = "InvalidPhoneNumber",

    /// <summary>
    /// The culture code is invalid
    /// </summary>
    InvalidCulture = "InvalidCulture",

    /// <summary>
    /// The system is currently in maintenance mode
    /// </summary>
    Maintenance = "Maintenance",

    /// <summary>
    /// The passed in locale is invalid
    /// </summary>
    InvalidLocale = "InvalidLocale",

    /// <summary>
    /// Error encountered when talking to AAD
    /// </summary>
    AdalError = "AdalError",

    /// <summary>
    /// File format conversion couldn't be done as it is disabled
    /// </summary>
    FileConversionDisabled = "FileConversionDisabled",

    /// <summary>
    /// File format conversion failed
    /// </summary>
    FileConversionFailed = "FileConversionFailed",

    /// <summary>
    /// Request to Microsoft Graph API failed
    /// </summary>
    GraphRequestFailed = "GraphRequestFailed",

    /// <summary>
    /// Call to extension service failed
    /// </summary>
    ExtensionCallFailed = "ExtensionCallFailed",

    /// <summary>
    /// There was a conflict when handling the request
    /// </summary>
    Conflict = "Conflict",

    /// <summary>
    /// Call to chat service service failed
    /// </summary>
    SkypeChatServiceCallFailed = "SkypeChatServiceCallFailed",

    /// <summary>
    /// A call to one of the SignalR dependencies has failed
    /// </summary>
    SignalRCallFailed = "SignalRCallFailed",

    /// <summary>
    /// The request tries to change a resource in a way that fails a precondition set on the server.
    /// </summary>
    PreconditionFailed = "PreconditionFailed",

    /// <summary>
    /// Report generation error
    /// </summary>
    ReportError = "ReportError",

    /// <summary>
    /// Exception during Debug
    /// </summary>
    DebugException = "DebugException",

    /// <summary>
    /// Indicates that the message/call request to Telesign Failed
    /// </summary>
    TelesignRequestException = "TelesignRequestException",

    /// <summary>
    /// Indicates that the request had an invalid / missing client certificate
    /// </summary>
    InvalidClientCertificate = "InvalidClientCertificate",

    /// <summary>
    /// Janitor Error
    /// </summary>
    JanitorError = "JanitorError",

    /// <summary>
    /// Workforce Integration Error
    /// </summary>
    WorkforceIntegrationError = "WorkforceIntegrationError",

    /// <summary>
    /// Unexpected workforce integration response
    /// </summary>
    UnexpectedWorkforceIntegrationResponseError = "UnexpectedWorkforceIntegrationResponseError",

    /// <summary>
    /// We observed an error on a dependency
    /// </summary>
    BadGateway = "BadGateway",

    /// <summary>
    /// Connector authentication needed
    /// </summary>
    ConnectorAuthRequest = "MissingThirdPartyToken",

    /// <summary>
    /// Update request not found error if the update request is not found or supported.
    /// </summary>
    UpdateRequestNotFound = "UpdateRequestNotFound"
}
