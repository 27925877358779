import ShiftUtils from "sh-application/utility/ShiftUtils";
import { ConflictType, DismissType } from "sh-models";
import { orchestrator } from "satcheljs";
import { removeConflictFromConflictStore, syncCreateConflictDismissal } from "..";

export default orchestrator(syncCreateConflictDismissal, async actionMessage => {
    const conflictDismissal = actionMessage.conflictDismissEntity;
    // remove conflict from conflict store, decrement count
    if (conflictDismissal.entityOne && conflictDismissal.entityTwo) {
        const isShiftAvailabilityConflict = conflictDismissal.entityOne.type === DismissType.Availability ||
            conflictDismissal.entityTwo.type === DismissType.Availability;

        if (isShiftAvailabilityConflict) {
            const shiftEntity = conflictDismissal.entityOne.type === DismissType.Shift ?
                conflictDismissal.entityOne : conflictDismissal.entityTwo;
            const availabilityEntity = conflictDismissal.entityOne.type === DismissType.Availability ?
                conflictDismissal.entityOne : conflictDismissal.entityTwo;

            removeConflictFromConflictStore(shiftEntity.id, ConflictType.ShiftAvailabilityConflict, conflictDismissal.memberId, null, availabilityEntity.id);
        } else {
            // figure out if this is a shift or time off conflict
            const isShiftConflict = ShiftUtils.isWorkingShiftByShiftId(conflictDismissal.entityOne.id)
                && ShiftUtils.isWorkingShiftByShiftId(conflictDismissal.entityTwo.id);
            const conflictType = isShiftConflict ? ConflictType.OverlappingShiftConflict : ConflictType.ShiftTimeOffConflict;
            removeConflictFromConflictStore(conflictDismissal.entityOne.id, conflictType, conflictDismissal.memberId, conflictDismissal.entityTwo.id, null);
        }
    }
});