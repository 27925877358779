import { action } from "satcheljs";
import { IShiftEntity } from "sh-models";

const deleteShiftsInStoreMap = action(
    "DELETE_SHIFT_IN_STORE_MAP",
    (
        shift: IShiftEntity
    ) => ({
        shift: shift
    })
);

export default deleteShiftsInStoreMap;