import ConflictUtils from "sh-application/utility/ConflictUtils";
import DateUtils from "sh-application/utility/DateUtils";
import MemberUtils from "sh-application/utility/MemberUtils";
import { AvailabilityStore, setAvailabilities } from "sh-availability-store";
import {
    ConflictDismissDataService,
    ECSConfigKey,
    ECSConfigService,
    FlightSettingsService,
    InstrumentationService,
    TeamDataService
    } from "sh-services";
import { ConflictStore, DismissedConflictsStore, handleConflictManagement } from "sh-conflict-store";
import { ConflictType, FlightKeys, IAvailabilityEntity } from "sh-models";
import { getGenericEventPropertiesObject } from "sh-instrumentation";
import { Moment } from "moment";
import { orchestrator } from "satcheljs";
import { refreshConflictsInView, setIsConflictCalculationInProgress, setupConflicts } from "../index";
import { TeamStore } from "sh-team-store";
import { trace } from "owa-trace";

/**
 * This orchestrator is called whenever there is updates to shifts are triggered via the UI or via sync notifications
 * It will parse the updated shifts and pass those to conflict mangement orchestrators for calculations
 */
export default orchestrator(setupConflicts, async actionMessage => {
    const viewStartTime: Moment = actionMessage.fetchStartTime;
    const viewEndTime: Moment = actionMessage.fetchEndTime;

    const currentUser = TeamStore() && TeamStore().me;
    const isAdmin = MemberUtils.isAdmin(currentUser);
    // enable conflict management, this is the kill switch for all conflict management calculations
    // conflict calculations request is made from this if a shift is added/updated/deleted in memory.
    // this is also the point where conflict management is enabled on load
    const conflictManagementFlagEnabled = ConflictUtils.isConflictEnabledForAdminInDateRange(isAdmin, viewEndTime);
    const conflictDismissEnabled = ECSConfigService.isECSFeatureEnabled(ECSConfigKey.EnableConflictsDismissal);
    // calculate only when user settings is true
    const userSettingsEnabled = (actionMessage.userSettingsEnabled !== undefined || actionMessage.userSettingsEnabled !== null) ? actionMessage.userSettingsEnabled : true;
    const forceCalculate = actionMessage.forceCalculate || false;

    if (!conflictManagementFlagEnabled) {
        return;
    }

    // determine if any change in shifts occurred, which needs to be accounted for recalculation
    const shiftsNeedUpdate = (actionMessage.shiftsAdded && actionMessage.shiftsAdded.length > 0)
                                || (actionMessage.shiftsDeleted && actionMessage.shiftsDeleted.length > 0)
                                || (actionMessage.shiftsUpdated && actionMessage.shiftsUpdated.length > 0);

    // determine if the date range changed and conflicts need to be recalculated
    // first check if the conflict store was ever populated
    const firstRender = (ConflictStore().startTime === null) || (ConflictStore().endTime === null);
    const conflictDateRangeChanged = (!firstRender) &&
            (!ConflictStore().startTime.isSame(DateUtils.getStartOfDay(viewStartTime)) || !ConflictStore().endTime.isSame(DateUtils.getStartOfDay(viewEndTime)));

    const needsRecalculation = shiftsNeedUpdate || conflictDateRangeChanged || forceCalculate;
    const isAvailabilityConflictEnabled = ECSConfigService.isECSFeatureEnabled(ECSConfigKey.EnableScheduleAvailability)
                                            && FlightSettingsService.isFlightEnabled(FlightKeys.EnableScheduleAvailability)
                                            && ConflictUtils.isConflictTypeEnabled(ConflictType.ShiftAvailabilityConflict);
    let availabilities: Map<string, IAvailabilityEntity[]>;
    // when progressive rendering we dont need to do other activities like fetching availabilities/conflict dismissals
    // we just need to call the conflict management handler to fill up the shiftsByMemberDate Map
    // but not calculate conflicts
    if ((!userSettingsEnabled || actionMessage.isProgressiveRenderingMode) && shiftsNeedUpdate) {
        // this flag always just sorts the incoming shifts from onshiftsModified into shiftsByMemberAndDate map
        // forcecalculate does not work if we are still fetching shifts in progressive rendering mode
        // this does not do any calculatations so this need not be in run in the next paint cycle
        handleConflictManagement(actionMessage.shiftsAdded, actionMessage.shiftsDeleted, actionMessage.shiftsUpdated, viewStartTime, viewEndTime, forceCalculate, availabilities, false /* don't calculate conflicts */);
        return;
    }

    if (isAvailabilityConflictEnabled) {
        if (!AvailabilityStore().hasFetchedAvailabilities.get()) {
            availabilities = await loadAvailabilities();
        } else {
            availabilities = AvailabilityStore().membersAvailabilities;
        }
    }

    // if shift-availability conflict is enabled, wait to calculate all conflicts until all the availabilities are fethced
    // if not we can just calculate overlapping shift and time off conflicts
    if (isAvailabilityConflictEnabled && availabilities || (ConflictUtils.isAtleastOneConflictTypeEnabled() && !isAvailabilityConflictEnabled)) {
        if (!ConflictUtils.getIsFetchingDismissedConflicts()) {
            ConflictUtils.setIsFetchingDismissedConflicts(true);
            setIsConflictCalculationInProgress(true);

            fetchDismissedConflicts(conflictDismissEnabled, TeamStore().teamId, viewStartTime, viewEndTime, isAdmin)
                .then(() => {
                    /**
                     * This is techinique used to run the enclosed piece of code right after the current paint cycle.
                     * https://developer.mozilla.org/en-US/docs/Mozilla/Firefox/Performance_best_practices_for_Firefox_fe_engineers
                     */
                    window.requestAnimationFrame(() => {
                        setTimeout(() => {
                            // setup orchestrators to calculate conflicts
                            if (needsRecalculation) {
                                // we just need to call the conflict management handler to fill up the shiftsByMemberDateBuckets
                                // but not calculate conflicts
                                handleConflictManagement(actionMessage.shiftsAdded, actionMessage.shiftsDeleted, actionMessage.shiftsUpdated, viewStartTime, viewEndTime, forceCalculate, availabilities, true /* calculate conflicts */, refreshConflictsCallback);
                            } else {
                                // if no recalculation is needed, make sure calculation in progress is set to false
                                setIsConflictCalculationInProgress(false);
                            }
                        }, 0);
                    });
                }).finally(() => {
                    ConflictUtils.setIsFetchingDismissedConflicts(false);
                });
        }
    }
});

export async function fetchDismissedConflicts(conflictDismissEnabled: boolean, teamId: string, startDate: moment.Moment, endDate: moment.Moment, isAdmin: boolean) {
    // fetch only if needed, don't fetch if its already been fetched for that time range
    if (conflictDismissEnabled
        && isAdmin
        && (DismissedConflictsStore().fetchStartTime === null
            || DismissedConflictsStore().fetchEndTime === null
            || !DismissedConflictsStore().fetchStartTime.isSame(startDate)
            || !DismissedConflictsStore().fetchEndTime.isSame(endDate))) {
        await ConflictDismissDataService.getDismissedConflicts(teamId, startDate, endDate);
    }
}

/**
 * function to run after conflicts have been calculated.
 */
export function refreshConflictsCallback() {
    setIsConflictCalculationInProgress(false);
    refreshConflictsInView();

    InstrumentationService.logPerfEvent(InstrumentationService.events.ConflictsCalculated, [
        getGenericEventPropertiesObject(InstrumentationService.properties.TotalConflictCount, ConflictStore().conflictCount.totalConflictCount),
        getGenericEventPropertiesObject(InstrumentationService.properties.TotalOverlappingShiftConflictCount, ConflictStore().conflictCount.overlappingShiftConflictCount),
        getGenericEventPropertiesObject(InstrumentationService.properties.TotalShiftAvailabilityConflictCount, ConflictStore().conflictCount.shiftAvailabilityConflictCount),
        getGenericEventPropertiesObject(InstrumentationService.properties.TotalShiftTimeConflictCount, ConflictStore().conflictCount.shiftTimeoffConflictCount),
        getGenericEventPropertiesObject(InstrumentationService.properties.DidConflictCountExceedLimit, ConflictStore().didConflictCountExceedLimit),
        getGenericEventPropertiesObject(InstrumentationService.properties.DidIgnoreConflictsOnLongShifts, ConflictStore().didIgnoreConflictsOnLongShifts)
    ]);
}

/**
 * force fetch availabilities if not fetched yet
 * the main initializer will not re-fetch if its already fetched from here.
 */
export async function loadAvailabilities(): Promise<Map<string, IAvailabilityEntity[]>> {
    const teamStore = TeamStore();
    let availabilities: Map<string, IAvailabilityEntity[]>;
    if (teamStore) {
        try {
            availabilities = await TeamDataService.getAvailabilities(teamStore.tenantId, teamStore.teamId);
            setAvailabilities(availabilities);
        } catch (httpError) {
            trace.warn("getAvailabilities: Error in getting availabilities");
        }
    }

    return availabilities;
}