import { UserStore } from "sh-stores";
import { action } from "satcheljs/lib/legacy";
import { IUserSettingsEntity } from "sh-models";

/**
 * Store user settings
 */

export let setUserSettings = action("setUserSettings")(
    function setUserSettings(userSettingsModel: IUserSettingsEntity) {
        UserStore().userSettings = userSettingsModel;
    });
