import { DismissedConflictsStore } from "sh-conflict-store";
import { IDismissEntity, DismissType } from "sh-models";
import { mutatorAction } from "satcheljs";

export default mutatorAction('ADD_SHIFT_ID_ENTITYID_MAP', function addShiftIdToEntityIdMap(dismissedEntityId: string, entity: IDismissEntity) {
    if (entity && entity.type === DismissType.Shift) {
        const entityList = DismissedConflictsStore().shiftIdToEntityIdMap.get(entity.id) || [];
        entityList.push(dismissedEntityId);
        DismissedConflictsStore().shiftIdToEntityIdMap.set(entity.id, entityList);
    }
});
