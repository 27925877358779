import * as React from "react";
import AutomationUtil from "sh-application/utility/AutomationUtil";
import AvailabilityUtils from "sh-application/utility/AvailabilityUtils";
import classNames from "classnames/bind";
import ConflictUtils from "sh-application/utility/ConflictUtils";
import DateUtils from "sh-application/utility/DateUtils";
import MemberUtils from "sh-application/utility/MemberUtils";
import Shift from "sh-application/components/shift/Shift";
import ShiftThemeContainer from "sh-application/components/shift/ShiftThemeContainer";
import ShiftUtils from "sh-application/utility/ShiftUtils";
import StringsStore from "sh-strings/store";
import TagUtils from "sh-application/utility/TagUtils";
import TimeOffShift from "sh-application/components/shift/TimeOffShift";
import {
    ChangeEntity,
    ChangeSource,
    ConflictType,
    IAvailabilityEntity,
    IBaseConflictEntity,
    IChangeEntity,
    IShiftEntity,
    ScheduleCalendarType
    } from "sh-models";
import { dismissConflict } from "sh-conflict-store";
import { ECSConfigKey, ECSConfigService, InstrumentationService } from "sh-services";
import { fireAccessibilityAlert } from "sh-application/components/accessibilityAlert";
import { getGenericEventPropertiesObject } from "sh-instrumentation";
import { IconButton } from "@fluentui/react";
import { refreshConflictsInView } from "../../schedules/lib";
import { ScheduleCellRenderSize } from "sh-application/../StaffHubConstants";
import { trackChanges } from "sh-stores/sh-change-store";

const styles = require("./ConflictDetails.scss");

interface ConflictDetailsProps {
    conflictEntity: IBaseConflictEntity;            // conflict of the conflict item
    conflictingShift?: IShiftEntity;                // conflicting shift
    conflictingAvailability?: IAvailabilityEntity;  // conflicting availability
    shift: IShiftEntity;                            // shift on which conflict badge was clicked
    onConflictShiftItemClicked?: (event: React.MouseEvent<HTMLDivElement>, conflictingShift: IShiftEntity) => void;     // The callback function to open shifteditor when a conflicting shift item is click
    onDismissCallback: VoidFunction;                // Call back function to dismiss callout,
    conflictDetailsNum?: number;                    // Number of conflicts in the conflict callout
    conflictListIndex?: number;                     // Index of the conflict detail in the conflict details list
    scheduleCalendarType?: ScheduleCalendarType;    // schedule calendar type for instrumentation
}

/**
 * ConflictDetailsList renders a list of conflicts with a shift.
 * every item contains of title, description and shift.
 */
export default class ConflictDetails extends React.Component<ConflictDetailsProps, {}> {

    private _conflictCalloutStrings: Map<string, string>;
    private _commonStrings: Map<string, string>;
    private _dismissConflict = React.createRef<HTMLInputElement>();
    private _shiftDay: string;
    private _shiftEndDay: string;
    private _conflictingShiftDay: string;
    private _isECSConflictTypeEnabled: boolean;

    constructor(props: ConflictDetailsProps) {
        super(props);
        this._conflictCalloutStrings = StringsStore().registeredStringModules.get("conflictManagement").strings;
        this._commonStrings = StringsStore().registeredStringModules.get("common").strings;

        this._shiftDay = this.props.shift && DateUtils.getMonthAndDateForDisplay(this.props.shift.startTime);
        this._shiftEndDay = this.props.shift && DateUtils.getMonthAndDateForDisplay(this.props.shift.endTime);
        this._conflictingShiftDay = this.props.conflictingShift && DateUtils.getMonthAndDateForDisplay(this.props.conflictingShift.startTime);
        if (this.props.conflictEntity) {
            this._isECSConflictTypeEnabled = this.props.conflictEntity.conflictType && ConflictUtils.isConflictTypeEnabled (this.props.conflictEntity.conflictType);
        }
    }

    /**
     * Returns the Member name for the conflicting shifts
     */
    private getMemberNameFromShift(): string {
        const { shift } = this.props;
        if (!shift) {
            return "";
        }
        return MemberUtils.getDisplayNameFromMemberId(shift.memberId);
    }

    /**
     * Return the name of the group from where there is conflicting shift.
     */
    private getGroupNameFromShift(): string {
        const { conflictingShift } = this.props;
        if (!conflictingShift) {
            return "";
        }
        const tagId = conflictingShift && ShiftUtils.getTagIdFromShift(conflictingShift);
        const groupName = tagId && TagUtils.getTagNameFromId(tagId);
        // Returns the group name of where the user belongs to else return "Unnamed group" if its empty
        return groupName ? groupName : this._commonStrings.get("unnamedGroup");
    }

    /**
     * Returns description strings for different types of conflicts
     * @param conflictEntity conflicting shift entity
     */
    private getConflictDetailsString(): string {
        const { conflictEntity } = this.props;
        const memberDisplayName = this.getMemberNameFromShift();
        const groupName = this.getGroupNameFromShift();
        if (!conflictEntity) {
            return "";
        }

        switch (conflictEntity.conflictType) {
            case ConflictType.ShiftAvailabilityConflict: {
               return this.getAvailabilityForDayString(memberDisplayName);
            }
            case ConflictType.OverlappingShiftConflict: {
                return this._conflictCalloutStrings.get("overLappingShiftConflict").format(memberDisplayName, groupName, this._conflictingShiftDay);
            }
            case ConflictType.ShiftTimeOffConflict: {
                return this._conflictCalloutStrings.get("timeOffShiftConflict").format(memberDisplayName, this._shiftDay);
            } default: {
                return "";
            }
        }
    }

    /**
     * Returns true if the availability conflict is coming from the next day for crossover and 24hr shifts
     * @param shift conflicting shift
     * @param conflictingAvailability availability conflict
     */
    private isAvailabilityFromNextDay(shift: IShiftEntity, conflictingAvailability: IAvailabilityEntity): boolean {
        const shiftDayOfWeek = shift.startTime && ShiftUtils.getShiftDayOfWeek(shift.startTime);
        const availabilityDayOfWeek = AvailabilityUtils.getConflictingAvailabilityDayOfWeek(conflictingAvailability);
        // for case of shifts that span two dates the availability date will be coming from the shift end date
        return (shiftDayOfWeek.length > 0 && availabilityDayOfWeek.length > 0 && shiftDayOfWeek !== availabilityDayOfWeek);
    }

    /**
     * Returns the string in which mentions Member is not available for the day
     * @param memberDisplayName Member name who has the availability conflicts
     */
    private getAvailabilityForDayString(memberDisplayName: string): string {
        const { conflictingAvailability, shift } = this.props;
        const isAvailabilityFromNextDay = shift && this.isAvailabilityFromNextDay(shift, conflictingAvailability);
        const availabilityDate = isAvailabilityFromNextDay ? this._shiftEndDay : this._shiftDay;

        if (!conflictingAvailability ) {
            return "";
        }

        if (AvailabilityUtils.isUnavailable(conflictingAvailability)) {
            return this._conflictCalloutStrings.get("availabilityConflict").format(memberDisplayName, availabilityDate);
        } else if (AvailabilityUtils.hasTimeSlots(conflictingAvailability)) {
            return this._conflictCalloutStrings.get("availabilityConflictTimeSlotDay").format(memberDisplayName, availabilityDate);
        }
        return "";
    }

    /**
     * Returns a string mentioning the members availability time slots
     */
    private getAvailabilityTimeSlotString(): string {
        const { conflictingAvailability, shift } = this.props;
        const isAvailabilityFromNextDay = shift && this.isAvailabilityFromNextDay(shift, conflictingAvailability);
        const availabilityDate = isAvailabilityFromNextDay ? this._shiftEndDay : this._shiftDay;
         if (conflictingAvailability && AvailabilityUtils.hasTimeSlots(conflictingAvailability)) {
            const memberAvailabilityTimeSlotString = ConflictUtils.getAvailabilityTimeSlotString(conflictingAvailability);
            return this._conflictCalloutStrings.get("availabilityConflictTimeSlots").format(availabilityDate, memberAvailabilityTimeSlotString);
        }
        return null;
    }

    /**
     * The callback for when the dimiss buttom is clicked in conflict callout.
     */
    private onDismissConflictClicked = (event?: React.MouseEvent<HTMLDivElement>) => {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }

        const { shift, conflictingShift, conflictingAvailability, conflictEntity} = this.props;
        if (!shift || !conflictEntity) {
            return;
        }

        const conflictType = conflictEntity.conflictType;

        InstrumentationService.logEvent(InstrumentationService.events.ConflictsPopupIgnoreClicked,
            [ getGenericEventPropertiesObject(InstrumentationService.properties.CurrentView, this.props.scheduleCalendarType),
              getGenericEventPropertiesObject(InstrumentationService.properties.TypeOfConflict, conflictType)]);

        // if its a shift-availability conflict, make sure a conflicting availability id passed
        if (conflictType === ConflictType.ShiftAvailabilityConflict && conflictingAvailability) {
            dismissConflict(conflictEntity.id, shift.id, conflictType, shift.memberId, null, conflictingAvailability.id);
        } else if ((conflictType === ConflictType.OverlappingShiftConflict || conflictType === ConflictType.ShiftTimeOffConflict) && conflictingShift) {
            // if its a shift-overlap or shift-timeoff issue, make sure a conflicting shift id is passed
            dismissConflict(conflictEntity.id, shift.id, conflictType, shift.memberId, conflictingShift.id);
        } else {
            return;
        }

        // update schedule viewstore conflicts in view
        fireAccessibilityAlert(this._conflictCalloutStrings.get("conflictDismissedAlert"));
        refreshConflictsInView();

        if (this.props.onDismissCallback) {
            this.props.onDismissCallback();
        }

        // track the changes for undo
        let changeEntities = new Array<IChangeEntity>();
        changeEntities.push(new ChangeEntity(null, null, ChangeSource.ConflictDismsiss, null, null, conflictEntity));
        trackChanges(changeEntities);
    }

    /**
     * Returns the title of the conflicting shift type
     */
    private getConflictTypeTitleString(): string {
        const { conflictEntity } = this.props;
        if (!conflictEntity) {
            return "";
        }
        switch (conflictEntity.conflictType) {
            case ConflictType.ShiftAvailabilityConflict: {
                return this._conflictCalloutStrings.get("availability");
            }
            case ConflictType.OverlappingShiftConflict: {
                return this._conflictCalloutStrings.get("overLappingShift");
            }
            case ConflictType.ShiftTimeOffConflict: {
                return this._conflictCalloutStrings.get("timeOffShift");
            }
            default: {
                return "";
            }
        }
    }

    /**
     * Renders the conflicting shift with title, description and dismiss icon
     */
    private renderShift() {
        const { onConflictShiftItemClicked, conflictingShift } = this.props;
        const theme = conflictingShift ? conflictingShift.theme : "";
        if (!conflictingShift) {
            return null;
        }
        const isWorkingShift = ShiftUtils.isWorkingShift(conflictingShift);
        const isTimeOffShift = ShiftUtils.isTimeOffEvent(conflictingShift);
        return (
            <div className={ styles.shiftThemeContainerWrapper } onClick={ (event ) => onConflictShiftItemClicked( event, conflictingShift) } data-is-focusable={ true } >
                <ShiftThemeContainer
                    theme={ theme }
                    className={ styles.shiftThemeContainer }>
                    <div>
                        { isWorkingShift && <Shift
                            shift={ conflictingShift }
                            showUnsharedStatus={ true }
                            scheduleCellRenderSize={ ScheduleCellRenderSize.Large }
                            doShowIcons={ true }
                            doShowNotes={ true }
                            doShow24HrShift={ true }
                            showGroupName={ false } />
                        }
                        {
                            !isWorkingShift && isTimeOffShift &&
                            <TimeOffShift
                                shift={ conflictingShift }
                                scheduleCellRenderSize={ ScheduleCellRenderSize.Large }
                            />
                        }
                    </div>
                </ShiftThemeContainer>
            </div>
        );
    }

    render(): JSX.Element {
        const { conflictEntity, shift } = this.props;

        // BUG 1531701: if the shift is not associated the conflict list item, the user need not see the conflict list item.
        if (!shift) {
            return null;
        }

        const conflictTypeString = this._isECSConflictTypeEnabled && this.getConflictTypeTitleString();
        const conflictDetailsString = this._isECSConflictTypeEnabled && this.getConflictDetailsString();
        const availabilityTimeSlotString = this._isECSConflictTypeEnabled && this.getAvailabilityTimeSlotString();
        const isDissmisalEnabled = ECSConfigService.isECSFeatureEnabled(ECSConfigKey.EnableConflictsDismissal);
        const dismissConflictAriaLabel = this.props.conflictListIndex && this.props.conflictDetailsNum ? this._conflictCalloutStrings.get("dismissConflict").format((this.props.conflictListIndex).toString(), this.props.conflictDetailsNum.toString()) : "";
        const dismissConflictTitle = this._conflictCalloutStrings.get("dismissConflictTitle");

        return (
            <div className={ styles.iconTitleContainer }  >
                <div role="listitem">
                    <div>
                        <div data-automation-id={ AutomationUtil.getAutomationId("conflictCallout", "QAIDConflictTypeTitle") } className={ styles.conflictTypeTitle }>{ conflictTypeString }</div>
                        <div className={ styles.iconContainer } ref={ this._dismissConflict }>
                            { isDissmisalEnabled && <IconButton
                                tabIndex={ 0 }
                                allowDisabledFocus={ true }
                                className={ classNames(styles.dismissIcon, AutomationUtil.getAutomationId("conflictCallout", "QAIDDismissConflict")) }
                                ariaLabel={ dismissConflictAriaLabel }
                                title={ dismissConflictTitle }
                                onClick={ this.onDismissConflictClicked }
                                disabled={ false }
                                iconProps={ { iconName: "teams-delete-svg" } }
                                data-automation-id={ AutomationUtil.getAutomationId("conflictCallout", "QAIDDismissConflict") } /> }
                        </div>
                    </div>
                    <div className={ styles.conflictTypeDetails } data-automation-id={ AutomationUtil.getAutomationId("conflictCallout", "QAIDConflictDetailsString") }>{ conflictDetailsString } </div>
                    { availabilityTimeSlotString && <div className={ styles.conflictTypeDetails } data-automation-id={ AutomationUtil.getAutomationId("conflictCallout", "QAIDAvailabityTimeSlotString") }>{ availabilityTimeSlotString }</div> }
                    { conflictEntity && conflictEntity.conflictType !== ConflictType.ShiftAvailabilityConflict && this.renderShift() }
                </div>
            </div>);
    }
}