import * as React from "react";
import UrlFactory from "../utility/UrlFactory";
import { appViewState } from "../store";
import { LoginState } from "../store/schema/LoginViewState";
import { Redirect, Route, RouteComponentProps } from "react-router-dom";

export const AuthorizedRoute = ({ component: Component, ...rest }: any) => (
    <Route
      {...rest}
      render={ (props: RouteComponentProps<any>) =>
        appViewState().loginViewState.loginState === LoginState.LoggedInBootstrapComplete ? (
            <Component {...props} />
        ) : (
            <Redirect
                to={ {
                    ...props.location,
                    pathname: UrlFactory.getLoginUrl(),
                    state: {
                        from: props.location,
                        groupId: props.match && props.match.params && props.match.params.groupId,
                        teamId: props.match && props.match.params && props.match.params.teamId
                    }
                } }
            />
        )
      }
    />
);