import { action } from "satcheljs";
import { IShiftEntity } from "sh-models";
import { ShiftMemberDateInfo } from "sh-conflict-store";

const addShiftInMemoryDateStore = action(
    "ADD_SHIFTS_IN_MEMORY_DATE_STORE",
    (
        shift: IShiftEntity,
        shiftMemberDateInfo: ShiftMemberDateInfo
    ) => ({
        shift: shift,
        shiftMemberDateInfo: shiftMemberDateInfo
    })
);

export default addShiftInMemoryDateStore;