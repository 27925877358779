import { action } from "satcheljs";

const getAvailabilities = action(
    "getAvailabilities",
    (
        tenantId: string,
        teamId: string
    ) => ({
        tenantId: tenantId,
        teamId: teamId
    })
);

export default getAvailabilities;
