import TeamSetupPickerUtils, { ITeamSetupPickerEntrypointsState } from "sh-application/components/teamsetuppicker/TeamSetupPickerUtils";
import { orchestrator  } from "satcheljs";
import { setScheduleViewStateValues,
         setupScheduleViewStates } from "sh-application/components/schedules/lib";

/**
 * Setup final schedule viewstate settings before marking the schedule viewstate as ready for UX consumption
 */

export const setupScheduleViewStatesOrchestrator = orchestrator(setupScheduleViewStates, async actionMessage => {
    // Note: It doesn't look like Actions can be serialized (eg, via async await), so anything that needs to run after something else
    // needs to be done within the same action. Ordering cannot be enforced when triggering actions one after another.
    // For example, here the schedulesViewStateStore().isViewStatePopulated should only be set to true _after_ all setup (eg, the team setup picker
    // entrypoint calculations below) is completed. So all setup must be done here within the same orchestrator, and all setup values and setting
    // isViewStatePopulated are set within the same setScheduleViewStateValues action.
    const teamSetupPickerEntrypointsState: ITeamSetupPickerEntrypointsState = await TeamSetupPickerUtils.calculateTeamSetupPickerEntrypointsState();
    setScheduleViewStateValues(
        teamSetupPickerEntrypointsState.showTeamSwitcherEntrypoint,
        teamSetupPickerEntrypointsState.showCreateNewScheduleEntrypoint,
        true /* isScheduleViewStatePopulated */);
});