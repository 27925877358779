import { action } from "satcheljs";
import { IBaseShiftRequestEntity } from "sh-models";

const calculateRequestConflicts = action(
    "CALCULATE_REQUEST_CONFLICTS",
    (
        shiftRequest: IBaseShiftRequestEntity,
        isOpenShiftRequest: boolean
    ) => ({
        shiftRequest: shiftRequest,
        isOpenShiftRequest: isOpenShiftRequest
    })
);

export default calculateRequestConflicts;