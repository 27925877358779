import * as React from "react";
import AutomationUtils from "sh-application/utility/AutomationUtil";
import ThemeUtils from "sh-application/utility/ThemeUtils";
import { action } from "satcheljs/lib/legacy";
import { Dropdown, IDropdownOption } from "@fluentui/react";
import { observable } from "mobx";
import { observer } from "mobx-react";

const styles = require("./ThemePicker.scss");
const classNames = require("classnames/bind");

export interface ThemePickerProps {
    /** Text to display above the theme picker */
    label: string;

    /** Aria label for the theme picker */
    ariaLabel?: string;

    /**
     * The default theme to initialize the picker with.
     * If no default theme is specified, then ThemeUtils.shiftDefaultTheme will be used.
     */
    defaultTheme?: string;

    /** (optional) Callback that is invoked when the user changes the selected theme */
    onChanged?: (newValue: string) => void;

    /** (optional) class name for the drop down */
    className?: string;

    /** (optional) indicates whether themepicker is used for time off themes */
    isTimeOff?: boolean;
    dataAutomationId?: string;
}

/**
 * TimePicker - A picker of time
 */
@observer
export default class ThemePicker extends React.Component<ThemePickerProps, {}> {
    // robv - can't figure out why tslint is complaining about this. It's called from onThemeChanged.
    /* tslint:disable */
    @observable private _selectedTheme: string;
    /* tslint:enable */

    /**
     * Returns the fabric dropdown options for the theme picker dropdown.
     */
    private getThemeOptions(): IDropdownOption[] {
        let themeArray: IDropdownOption[] = [];

        const themeMap: Map<string, string> = ThemeUtils.getLocalizedThemeNames(!!this.props.isTimeOff);
        themeMap.forEach(
            (value, key) => {
                themeArray.push({ key: value, text: key, data: value });
            }
        );

        return themeArray;
    }

    private onThemeChanged = (event: React.FormEvent<HTMLElement>, option: IDropdownOption) => {
        if (this.props.onChanged) {
            this.props.onChanged(option.data);
            this.updateThemeWrapper(option.data);
        }
    }

    // Render content of item (i.e. text/icon inside of button)
    private onRenderOption(option: IDropdownOption): JSX.Element {
        return (
            <div className={ classNames('ms-Dropdown-optionText', styles[option.data], styles.faded, styles.optionText, styles.optionColorContainer, AutomationUtils.getAutomationId("addEditShiftPanel", "QAIDColorContainer")) }>
                <div className={ classNames(styles.optionColorBar, styles[option.data]) }></div>
                <div className={ styles.optionText } >
                    { option.text }
                </div>
            </div>
        );
    }

    /**
     * Renders the title for the theme picker
     * @param option
     */
    private onRenderTitle = (options: IDropdownOption[]): JSX.Element => {
        // Fabric has changed their dropdown onRenderTitle function to account for multiselect
        // This means it provides a list of options rather than a single option. We will only have one option selected, so we pick the first
        let option: IDropdownOption = options && options.length ? options[0] : null;
        return option ? this.onRenderOption(option) : null;
    }

    private updateThemeWrapper = action("updateThemeWrapper")((theme: string) => {
        this._selectedTheme = theme;
    });

    /**
     * Return the default theme
     */
    private getDefaultTheme(): string {
        return this.props.defaultTheme ? ThemeUtils.getValidShiftTheme(this.props.defaultTheme, !!this.props.isTimeOff) : ThemeUtils.getDefaultShiftTheme(!!this.props.isTimeOff);
    }

    render() {
        const { label, className, ariaLabel } = this.props;
        return (
                <Dropdown
                    calloutProps={ {className: styles.themePickerDropdown } }
                    label={ label }
                    ariaLabel={ ariaLabel }
                    data-automation-id={ this.props.dataAutomationId }
                    className={ classNames(styles.themePicker, className) }
                    defaultSelectedKey={ this.getDefaultTheme() }
                    options={ this.getThemeOptions() }
                    onRenderOption={ this.onRenderOption }
                    onRenderTitle={ this.onRenderTitle }
                    onChange={ this.onThemeChanged }
                    />
        );
    }
}