/**
 * Given a key, return query string with that key removed
 * @param key the key to remove
 * @param queryString the query string to remove it from
 */
export function removeParamFromQueryString(key: string, queryString: string): string {
    let paramsArray: string[];
    let returnQueryString: string = "";

    // remove ? from query string
    queryString = queryString.replace('?', '');

    if (queryString !== "") {
        paramsArray = queryString.split("&");
        for (let i = paramsArray.length - 1; i >= 0; i -= 1) {
            let param = paramsArray[i].split("=")[0];
            if (param === key) {
                paramsArray.splice(i, 1);
            }
        }
        returnQueryString = "?" + paramsArray.join("&");
    }
    return returnQueryString;
}