import AppSettingsModelBase from "./AppSettingsModelBase";
import IShiftrObjectClientModel from "../IShiftrObjectClientModel";
import { ExtraTeamSettings, FlightSettings } from "sh-models";
import { Moment } from "moment";

/**
 * App Settings client model
 */
class AppSettingsClientModel extends AppSettingsModelBase {
    nextPollTime: Moment;

    /**
     * Constructor
     */
    constructor(id: string,
        eTag: string,
        tenantId: string,
        teamId: string,
        flightSettings: FlightSettings,
        extraTeamSettings: ExtraTeamSettings,
        nextPollTime: Moment) {
        super(id, eTag, tenantId, teamId, flightSettings, extraTeamSettings);
        this.nextPollTime = nextPollTime;
    }
}

// Verify that this class adheres to the contract defined by our interface.
// This needs to be done separately since Typescript does not allow static methods in interfaces.
// tslint:disable-next-line:no-unused-declaration
let __verifyInterface: IShiftrObjectClientModel = AppSettingsClientModel;

export default AppSettingsClientModel;
