import * as React from "react";
import AutomationUtils from "sh-application/utility/AutomationUtil";
import GlobalSpinner from "./GlobalSpinner";
import KeyboardUtils from "sh-application/utility/KeyboardUtils";
import StringsStore from "sh-strings/store";
import { AriaProperties, AriaRoles, generateDomPropertiesForAria } from "owa-accessibility";
import {
    CommandBar,
    DefaultButton,
    IconButton,
    IContextualMenuItem,
    IPanelProps,
    IRenderFunction,
    MessageBar,
    MessageBarType,
    Panel,
    PanelType,
    PrimaryButton
    } from "@fluentui/react";
import { observer } from "mobx-react";
import { restartApp } from "sh-application";
import { setStaffHubPanelAlertMessageText } from "sh-application/store";
import { StaffHubPanelViewStateBase } from "sh-application/store/schema/StaffHubPanelViewStateBase";
import { trace } from "owa-trace";

const PANEL_CUSTOM_WIDTH = "450px";
const styles = require("./StaffHubPanel.scss");
const classNames = require("classnames/bind");

interface StaffHubPanelProps {
    /** Is the panel open - passing true will open the panel, false will close the panel */
    isOpen: boolean;

    /** Is the panel blocking (includes blocking overlay) - default true */
    isBlocking?: boolean;

    /** (optional) true to render a default close button in the navigation section - default is false */
    hasCloseButton?: boolean;

    /** Is the panel processing an onOk callback - passing true show animated progress dots on the ok button */
    onOkBusy?: boolean;

    /** Is the panel processing an onApply callback - passing true show animated progress dots on the apply button */
    onApplyBusy?: boolean;

    /** Custom width of panel. Default would be PANEL_CUSTOM_WIDTH */
    panelWidth?: string;

    /** Type of panel. Default PanelType.custom */
    panelType?: PanelType;

    /** Custom class to add to panel*/
    panelClass?: string;

    /** Custom class to add to panel's content*/
    contentClass?: string;

    /** Custom class to add to panel's content*/
    childrenContainer?: string;

    /** (optional) Text to display in the header */
    headerText?: string;

    /** Sub text to display under the headerText */
    headerSubText?: string;

    /** (optional) Shows the image just below the panel header */
    headerImage?: string;

    /** (optional) Custom label to use on the cancel button in the footer - default is Cancel */
    cancelButtonLabel?: string;

    /** (optional) Custom label to use on the Apply button in the footer - default is Apply */
    applyButtonLabel?: string;

    /** (optional) Custom label to use on the ok button in the footer - default is Save */
    okButtonLabel?: string;

    /** (optional) Aria properties for the Cancel button */
    cancelButtonAriaProps?: AriaProperties;

    /** (optional) Aria properties for the Apply button */
    applyButtonAriaProps?: AriaProperties;

    /** (optional) Aria properties for the Ok button*/
    okButtonAriaProps?: AriaProperties;

    /**
     * (optional) Callback that is invoked when the panel is dismissed.
     * onDismiss is the only callback that gets called when ESC is hit to close the panel.
     * TODO: investigate merging onDismiss and onCancel
     */
    onDismiss?: () => void;

    /** (optional) Callback that is invoked when the user clicks cancel button */
    onCancel?: () => void;

    /** (optional) Callback that is invoked when the user clicks apply button */
    onApply?: () => void;

    /** (optional) If true, the Apply button is shown */
    showApplyButton?: boolean;

    /** (optional) If true, the apply button is disabled */
    applyButtonDisabled?: boolean;

    /** (optional) Callback that is invoked when the user clicks ok button */
    onOk?: () => void;

    /** (optional) If true, the ok button is disabled */
    okButtonDisabled?: boolean;

    /** (optional) If true, the cancel button is disabled */
    cancelButtonDisabled?: boolean;

    /** (optional) If set, an error MessageBar will be displayed */
    messageBarError?: string;

    /** (optional) If we should show the refresh app button in the MessageBar */
    messageBarShowRefreshButton?: boolean;

    /** (optional) Callback that is invoked when the user dismisses the error MessageBar */
    onMessageBarDismiss?: () => void;

    /** (optional) If true, the footer section is hidden */
    hideFooter?: boolean;

    /**
     * (optional) Determines if content should stretch to fill available space putting footer at the bottom of the page
     */
    isFooterAtBottom?: boolean;

    /** (optional) Shows the element below the footer */
    footerNote?: JSX.Element;

    /** (optional) If set, spinner will be shown and not the content */
    showSpinner?: boolean;

    /** (optional) If showSpinner is set, you can add this spinner description*/
    spinnerLabel?: string;

    /** optionally render a custom header */
    onRenderHeader?: () => JSX.Element;

    /** optionally render a custom navigation component */
    onRenderNavigationContent?: IRenderFunction<IPanelProps>;

    /** optionally render a custom footer component */
    onRenderFooterContent?: IRenderFunction<IPanelProps>;

    /** (optional) command bar props */
    commandBarItems?: IContextualMenuItem[];

    /** (optional) CSS class name of the element to place initial focus on. If this is not set, then initial focus is set on the cancel button. */
    firstFocusableSelector?: string;

    /** (optional) CSS class to add to the footer container */
    footerClass?: string;

    /** (optional) Base StaffHubPanel viewstate. Setting this will read the header text for accessibility and can interrupt any other alert being read */
    panelViewState?: StaffHubPanelViewStateBase;

    /** (optional) we need this flag to set busystate to let panel know incase the panel is not using its default haeder render*/
    isPanelBusy?: boolean;

    /** (optional) flag to flip to convergence background and form field colors */
    convergenceBackground?: boolean;

    /** (optional) pass true to disable the built in panel focus trap */
    disableFocusTrap?: boolean;

    /** (optional) pass true to please the panel in squeeze mode which will give more room to the content */
    isSqueezed?: boolean;
}

/**
 * StaffHubPanel - A implementation of the fluent Panel that implements a common
 * design pattern for panels within StaffHub
 */
@observer
export default class StaffHubPanel extends React.Component<StaffHubPanelProps, {}> {
    private _commonStrings: Map<string, string>;

    private _defaultButtonClassName: string = "defaultButton"; // Used to set the initial focus to the default Cancel button

    constructor(props: StaffHubPanelProps) {
        super(props);
        this._commonStrings = StringsStore().registeredStringModules.get("common").strings;
    }

    // Set default props
    static defaultProps: StaffHubPanelProps = {
        isOpen: false,
        isBlocking: true,
        hasCloseButton: false,
        panelWidth: PANEL_CUSTOM_WIDTH,
        panelType: PanelType.custom
    };

    componentDidMount() {
        if (this.props.isOpen) {
            this.handleOpenPanel();
        }
    }

    componentDidUpdate(prevProps: StaffHubPanelProps) {
        // Note:  componentDidUpdate does not get called for initial render.
        // Also because of the way we setup and launch Panels, this may not be called because the Panel
        // may be recreated every time the panel UX is launched.
        if (!prevProps.isOpen && this.props.isOpen) {
            this.handleOpenPanel();
        }
    }

    /**
     * Handle opening of the panel
     */
    private handleOpenPanel() {
        // Use Aria alert to announce the dialog title for accessibility users.
        // This helps for JAWS users where the role="dialog" title doesn't always get read out.
        if (this.props.panelViewState) {
            setStaffHubPanelAlertMessageText(this.props.panelViewState, this.headerAriaText());
        }
    }

    /**
     * Returns the alert message text to be read aloud for accessibility alerts.  Empty string for no alert.
     */
    private alertMessageText(): string {
        return this.props.panelViewState ? this.props.panelViewState.panelAlertText : "";
    }

    /**
     * Returns the Aria label text for the panel header
     */
    private headerAriaText(): string {
        return this._commonStrings.get("ariaLabelMultipleLines").format(this.props.headerText, (this.props.headerSubText || "") );
    }

    /**
     * Renders the custom panel navigation component
     * @return {JSX.Element}
     */
    private onRenderNavigationContent = (panelProps: IPanelProps, defaultRender: IRenderFunction<IPanelProps>): JSX.Element => {
        const { onRenderNavigationContent } = this.props;
        if (onRenderNavigationContent) {
            return onRenderNavigationContent(panelProps, defaultRender);
        }

        return null;
    }

    /**
     * Renders the custom panel footer component
     * @return {JSX.Element}
     */
    private onRenderFooterContent = (props: IPanelProps, defaultRender: IRenderFunction<IPanelProps>): JSX.Element => {
        const { applyButtonDisabled, cancelButtonDisabled, hideFooter, footerClass, footerNote, okButtonDisabled, onOkBusy,
            onRenderFooterContent, onApplyBusy, showApplyButton } = this.props;
        if (onRenderFooterContent) {
            return onRenderFooterContent(props, defaultRender);
        }

        let { cancelButtonAriaProps, applyButtonAriaProps, okButtonAriaProps } = this.props;
        const saveButtonTitle = onOkBusy ? this._commonStrings.get("saving") : this._commonStrings.get("save");

        const cancelButtonLabel: string = this.props.cancelButtonLabel || this._commonStrings.get("cancel");
        const applyButtonLabel: string =  this.props.applyButtonLabel || this._commonStrings.get("apply");
        const okButtonLabel: string = this.props.okButtonLabel || saveButtonTitle;
        cancelButtonAriaProps = cancelButtonAriaProps || {} as AriaProperties;
        cancelButtonAriaProps.label = cancelButtonAriaProps.label || cancelButtonLabel;
        applyButtonAriaProps = applyButtonAriaProps || {} as AriaProperties;
        applyButtonAriaProps.label = applyButtonAriaProps.label || applyButtonLabel;
        okButtonAriaProps = okButtonAriaProps || {} as AriaProperties;
        okButtonAriaProps.label = okButtonAriaProps.label || okButtonLabel;

        return (
            !hideFooter &&
                <>
                    <div className={ classNames(styles.footer, footerClass, { [styles.isSqueezed]: this.props.isSqueezed } ) }>
                        <DefaultButton
                            data-automation-id={ AutomationUtils.getAutomationId("panel", "QAIDPanelCancelButton") }
                            className={ this._defaultButtonClassName }
                            onClick={ this.onCancel }
                            disabled= { cancelButtonDisabled }
                            ariaLabel={ cancelButtonAriaProps.label }
                            ariaHidden={ cancelButtonAriaProps.hidden }>
                                { cancelButtonLabel }
                        </DefaultButton>
                        {
                            showApplyButton &&
                                <PrimaryButton
                                    className={ styles.applyButton }
                                    disabled={ applyButtonDisabled }
                                    onClick={ this.onApply }
                                    ariaLabel={ applyButtonAriaProps.label }
                                    ariaHidden={ applyButtonAriaProps.hidden }>
                                        { applyButtonLabel }{ onApplyBusy && <span className={ styles.progressDots } /> }
                                </PrimaryButton>
                        }
                        <PrimaryButton
                            data-automation-id={ AutomationUtils.getAutomationId("panel", "QAIDPanelSaveButton") }
                            className={ styles.primaryButton }
                            disabled={ okButtonDisabled }
                            onClick={ this.onOk }
                            ariaLabel={ okButtonAriaProps.label }
                            ariaHidden={ okButtonAriaProps.hidden }>
                                { okButtonLabel }{ onOkBusy && <span className={ styles.progressDots } /> }
                        </PrimaryButton>
                    </div>
                    { footerNote }
                </>
        );
    }

    /**
     * Render function that is called by the panel class to render the header
     * @param IPanelProps
     * @param defaultRender
     * @param headerTextId Id for the HTML element that contains the header text for the panel dialog
     * @return {JSX.Element} header element
     */
    private onRenderPanelHeader = (IPanelProps?: IPanelProps, defaultRender?: IRenderFunction<IPanelProps>, headerTextId?: string): JSX.Element => {
        const { commandBarItems, onRenderHeader, messageBarError } = this.props;
        if (onRenderHeader) {
            return onRenderHeader();
        }
        const backgroundImageUrl: string = this.props.headerImage && "url(" + this.props.headerImage + ")";
        const headerAriaLabel: string = this.headerAriaText();

        const panelAlertContainerAriaProps: AriaProperties = {
            role: AriaRoles.alert,
            live: "assertive"
        };

        return (
            <div>
                <div className={ classNames(styles.panelTopBorder, styles.panelTopBorderHeight) } />

                <div className={ classNames(styles.topBar) }>
                    <CommandBar
                        className={ styles.commandBar }
                        items={ commandBarItems || [] } />

                    { /* Close panel button */ }
                    {
                        !messageBarError &&
                            <div className={ styles.panelCloseButtonContainer }>
                                <IconButton
                                    title={ this._commonStrings.get("closePanelAriaLabel") }
                                    ariaLabel={ this._commonStrings.get("closePanelAriaLabel") }
                                    onKeyPress={ this.onDismissKeyPress }
                                    onClick={ this.onDismissClicked }
                                    className={ styles.panelCloseButton }
                                    data-automation-id={ AutomationUtils.getAutomationId("panel", "QAIDPanelCloseButton") }
                                    iconProps={ { styles: { root: styles.panelCloseButtonIcon }, iconName: "Cancel" } } />
                            </div>
                    }
                </div>

                <div
                    className={ styles.accessibilityAlert }
                    { ...generateDomPropertiesForAria(panelAlertContainerAriaProps) }>
                    { this.alertMessageText() }
                </div>

                <div
                    id={ headerTextId }
                    className={ classNames(styles.header, { [styles.isSqueezed]: this.props.isSqueezed } ) }
                    style={ { backgroundImage: backgroundImageUrl } }
                    tabIndex={ -1 }
                    role={ AriaRoles.heading }
                    aria-label={ headerAriaLabel }
                    aria-level={ 2 } >
                    { this.props.headerText }
                    {
                        this.props.headerSubText &&
                            <div className={ styles.subText }>
                                { this.props.headerSubText }
                            </div>
                    }
                </div>
            </div>
        );
    }

    private onOk = () => {
        if (this.props.onOk) {
            this.props.onOk();
        }
    }

    private onApply = () => {
        if (this.props.onApply) {
            this.props.onApply();
        }
    }

    private onCancel = () => {
        if (this.props.onCancel) {
            this.props.onCancel();
        }
    }

    private onDismissKeyPress = (ev: React.KeyboardEvent<HTMLDivElement>) => {
        if (KeyboardUtils.isActionKeyPressed(ev)) {
            ev.preventDefault();
            ev.stopPropagation();
            this.onDismiss();
        }
    }

    private onDismissClicked = () => {
        this.onDismiss();
    }

    private onDismiss = () => {
        if (this.props.onDismiss) {
            this.props.onDismiss();
        }
    }

    /**
     * Property that renders spinner
     */
    private get Spinner(): JSX.Element {
        return (
            <GlobalSpinner label={ this.props.spinnerLabel } />
        );
    }

    /**
     * Returns true if the panel has a custom navigation render callback defined
     */
    private hasCustomRenderNavigation(): boolean {
        return !!this.props.onRenderNavigationContent;
    }

    /**
     * Returns true if any of the panel's props indicating a busy state are true.
     */
    private isPanelBusy(): boolean {
        return this.props.onOkBusy || this.props.onApplyBusy || this.props.isPanelBusy;
    }

    /**
     * Calculate and return the message bar props.
     */
    private getMessageBarProps() {
        // setup the MessageBar props (MessageBar only shows if the viewState has a message)
        const { onMessageBarDismiss, messageBarShowRefreshButton } = this.props;

        let messageBarProps = {
            isMultiline: true,
            messageBarType: MessageBarType.error,
            onDismiss: onMessageBarDismiss ?
                () => onMessageBarDismiss() :
                () => { trace.warn("you might want a message bar dismiss callback"); },
            dismissButtonAriaLabel: onMessageBarDismiss ? this._commonStrings.get("closeMessageAriaLabel") : null,
            actions: null as JSX.Element
        };

        // if this is an action button, add it
        if (messageBarShowRefreshButton) {
            messageBarProps.actions =
                <div>
                    <DefaultButton
                        onClick={ () => restartApp() }>
                        { this._commonStrings.get("refresh") }
                    </DefaultButton>
                </div>;
        }

        return messageBarProps;
    }

    render() {
        const { hasCloseButton, panelClass, panelWidth, panelType, isOpen, isBlocking, isFooterAtBottom,
            messageBarError, showSpinner, contentClass, children, childrenContainer, disableFocusTrap } = this.props;

        // add "busy" class when the panel is busy
        const hasCustomRenderNavigation = this.hasCustomRenderNavigation();
        const panelClasses = classNames(styles.staffHubPanel, panelClass, { [`${styles.convergenceBackground}`]: this.props.convergenceBackground }, { [`${styles.busy}`]: this.isPanelBusy() }, { [`${styles.noCustomNavigation}`]: !hasCustomRenderNavigation });

        // For initial focus, use the selector passed in via props.
        // Otherwise if the prop is not defined, then set initial focus on the default button (Cancel button)
        let firstFocusableSelector: string = this.props.firstFocusableSelector;
        if (!firstFocusableSelector) {
            firstFocusableSelector = this._defaultButtonClassName;
        }

        return (
            <Panel
                className={ panelClasses }
                isOpen={ isOpen }
                isBlocking={ isBlocking }
                isFooterAtBottom={ isFooterAtBottom }
                type={ panelType }
                customWidth={ panelWidth }
                hasCloseButton={ hasCloseButton }
                closeButtonAriaLabel={ this._commonStrings.get("close") }
                onDismiss={ this.onDismiss }
                headerText={ this.props.headerText }
                onRenderHeader={ this.onRenderPanelHeader }
                onRenderNavigationContent={ this.onRenderNavigationContent }
                onRenderFooterContent={ this.onRenderFooterContent }
                onOuterClick={ () => { event.preventDefault(); } }
                focusTrapZoneProps={
                    {
                        firstFocusableSelector: firstFocusableSelector,
                        disabled: !!disableFocusTrap
                    }
                } >
                    {
                        messageBarError &&
                            <MessageBar
                                className={ styles.messageBar }
                                { ...this.getMessageBarProps() }>
                                { messageBarError }
                            </MessageBar>
                    }
                    <div className={ classNames(styles.content, contentClass) }>
                        <div data-automation-id={ AutomationUtils.getAutomationId("panel", "QAIDPanelContentChildContainer") } className={ classNames(styles.childrenContainer, childrenContainer) }>
                            { children }
                        </div>
                    </div>
                    {
                        showSpinner &&
                            this.Spinner
                    }
            </Panel>);
    }
}