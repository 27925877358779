import * as moment from "moment";
import BaseShiftEntity from "sh-models/shift/BaseShiftEntity";
import ModelUtils from "sh-models/ModelUtils";
import TagUtils from "sh-application/utility/TagUtils";
import ThemeUtils from "sh-application/utility/ThemeUtils";
import {
    BreakEntity,
    IOpenShiftDbEntity,
    IOpenShiftEntity,
    IOpenShiftServiceEntity,
    IShiftEntity,
    SubshiftEntity
    } from "sh-models";
import { DEFAULT_OPEN_SHIFT_SLOTS } from "../../StaffHubConstants";
import { OPENSHIFT_ID_PREFIX } from "sh-application/../StaffHubConstants";

/**
 * OpenShiftEntity
 */
export default class OpenShiftEntity extends BaseShiftEntity implements IOpenShiftEntity {

    sharedChanges?: IOpenShiftEntity;
    openSlots: number;
    isCrossLocationOpenShift: boolean;

    // Empty object to prevent null reference exception
    constructor(params: IOpenShiftEntity = {} as IOpenShiftEntity) {

        const {
            breaks,
            eTag,
            endTime,
            id,
            isCrossLocationOpenShift = false,
            isPublished,
            memberId,
            notes,
            openSlots = DEFAULT_OPEN_SHIFT_SLOTS,
            shiftRequestId,
            shiftType,
            sharedChanges,
            startTime,
            state,
            tagIds,
            teamId,
            tenantId,
            theme,
            timeOffReasonId,
            title,
            subshifts
        } = params;

        // generate new Id if its missing
        let modelId = id || OpenShiftEntity.generateNewOpenShiftId();
        // initialize BaseShiftEntity
        super(modelId,
            eTag,
            tenantId,
            teamId,
            memberId,
            shiftType,
            startTime,
            endTime,
            state,
            title,
            notes,
            theme || ThemeUtils.openShiftDefaultTheme,
            tagIds,
            subshifts,
            breaks,
            shiftRequestId,
            timeOffReasonId,
            isPublished);

        // assign values to open shift member fields
        this.sharedChanges = sharedChanges;
        this.openSlots = openSlots;
        this.isCrossLocationOpenShift = isCrossLocationOpenShift;
    }

    /**
     * New Open Shift Id
     */
    public static generateNewOpenShiftId(): string {
        return ModelUtils.generateUUIDWithPrefix(OPENSHIFT_ID_PREFIX);
    }

    /**
     * Function that clones an OpenShiftEntity object
     * @param openShift - openShift to be clone
     */
    public static clone(openShift: IOpenShiftEntity): IOpenShiftEntity {
        if (!openShift) {
            return null;
        }

        const breaks = openShift.breaks ? openShift.breaks.map(breakEntity => BreakEntity.clone(breakEntity)) : [];
        const subshifts = openShift.subshifts ? openShift.subshifts.map(subshiftEntity => SubshiftEntity.clone(subshiftEntity)) : [];

        return new OpenShiftEntity(
            {
                ...openShift,
                subshifts: subshifts,
                breaks: breaks,
                sharedChanges: OpenShiftEntity.clone(openShift.sharedChanges),
                startTime: moment(openShift.startTime),
                endTime: moment(openShift.endTime)
            }
        );
    }

    /**
    * Function that converts json from IOpenShiftServiceEntity or IOpenShiftDbEntity to IOpenShiftEntity
    * @param jsonData - response from service or db
    */
    public static fromJson(jsonData: IOpenShiftServiceEntity | IOpenShiftDbEntity): IOpenShiftEntity {
        if (!jsonData) {
            return null;
        }

        return new OpenShiftEntity(
            {
                ...jsonData,
                startTime: moment(jsonData.startTime),
                endTime: moment(jsonData.endTime),
                subshifts: SubshiftEntity.fromJsonList(jsonData.subshifts),
                breaks: BreakEntity.fromJsonList(jsonData.breaks),
                sharedChanges: OpenShiftEntity.fromJson(jsonData.sharedChanges)
            }
        );
    }

    /**
     * Function that creates service JSON from OpenShiftEntity
     * @param openShiftEntity - openShift entity that needs to be sent over the wire
     */
    public static toJson(openShift: IOpenShiftEntity): IOpenShiftServiceEntity {
        if (!openShift) {
            return null;
        }

        const breaks = openShift.breaks ? openShift.breaks.map(breakEntity => BreakEntity.toJson(breakEntity)) : null;
        const subshifts = openShift.subshifts ? openShift.subshifts.map(subshiftEntity => SubshiftEntity.toJson(subshiftEntity)) : null;

        // Remove other group tag id from the list of tagIds
        let tagIds: string[] = [];
        if (openShift.tagIds && openShift.tagIds.length) {
            tagIds = openShift.tagIds.filter(tagId => !TagUtils.isDefaultTag(tagId));
        }

        // skipping sharedChanges here as it is read-only
        const openShiftJson: IOpenShiftServiceEntity = {
            ...openShift,
            openSlots: openShift.openSlots ?? DEFAULT_OPEN_SHIFT_SLOTS,
            startTime: openShift.startTime.toISOString(),   // Service uses an ISO string datetime format
            endTime: openShift.endTime.toISOString(),       // Service uses an ISO string datetime format
            breaks: breaks,
            subshifts: subshifts,
            tagIds: tagIds,
            sharedChanges: undefined
        };

        return openShiftJson;
    }

    /**
     * Function that creates IOpenShiftDbEntity from IOpenShiftEntity
     * @param openShift - open shift client entity
     */
    static toDbModel(openShift: IOpenShiftEntity): IOpenShiftDbEntity {
        if (!openShift) {
            return null;
        }

        const breaks = openShift.breaks ? openShift.breaks.map(breakEntity => BreakEntity.toDbModel(breakEntity)) : null;
        const subshifts = openShift.subshifts ? openShift.subshifts.map(subshiftEntity => SubshiftEntity.toDbModel(subshiftEntity)) : null;

        const openShiftDb: IOpenShiftDbEntity = {
            ...openShift,
            startTime: openShift.startTime.valueOf(),   // Service uses an ISO string datetime format
            endTime: openShift.endTime.valueOf(),       // Service uses an ISO string datetime format
            breaks: breaks,
            subshifts: subshifts,
            sharedChanges: OpenShiftEntity.toDbModel(openShift.sharedChanges),
            openSlots: openShift.openSlots ?? DEFAULT_OPEN_SHIFT_SLOTS
        };

        return openShiftDb;
    }

    /**
     * Transform an assigned shift to an open shift
     * @param assignedShift
     * @param openSlots
     */
    public static fromAssignedShift(assignedShift: IShiftEntity, openSlots?: number): IOpenShiftEntity {
        if (!assignedShift) {
            return null;
        }

        let openShift: IOpenShiftEntity = assignedShift as IOpenShiftEntity;
        openShift.openSlots = openSlots || DEFAULT_OPEN_SHIFT_SLOTS;
        openShift.id = ""; // clear out the existing id so that we generate one appropriate for OpenShiftEntity

        return OpenShiftEntity.clone(openShift);
    }
}