import {
    IOpenShiftEntity,
    IShiftEntity,
    OpenShiftEntity,
    ShiftEntity,
    IShiftServiceEntity,
    IOpenShiftServiceEntity
    } from "sh-models";

/**
 * This class is a client-side representation of the response from a copyOfShifts API call. Groups shifts into a shifts and open shifts list
 */
export default class CopyOfShiftsResponse {
    shifts: IShiftEntity[];
    openShifts: IOpenShiftEntity[];

    constructor(
        shifts: IShiftEntity[],
        openShifts: IOpenShiftEntity[]) {
        this.shifts = shifts;
        this.openShifts = openShifts;
    }

    /**
     * Function that converts json from service response to CopyOfShifts
     * @param jsonData - response from service
     */
    static fromJson(jsonData: any): CopyOfShiftsResponse {
        if (!jsonData) {
            return null;
        }

        let shifts: IShiftEntity[] = [];
        let openShifts: IOpenShiftEntity[] = [];

        if (jsonData.overlappingShifts) {
            jsonData.overlappingShifts.forEach((shiftJson: IShiftServiceEntity) => shifts.push(ShiftEntity.fromJson(shiftJson)));
        }

        if (jsonData.nonOverlappingShifts) {
            jsonData.nonOverlappingShifts.forEach((shiftJson: IShiftServiceEntity) => shifts.push(ShiftEntity.fromJson(shiftJson)));
        }

        if (jsonData.overlappingOpenShifts) {
            jsonData.overlappingOpenShifts.forEach((openShiftJson: IOpenShiftServiceEntity) => openShifts.push(OpenShiftEntity.fromJson(openShiftJson)));
        }

        if (jsonData.nonOverlappingOpenShifts) {
            jsonData.nonOverlappingOpenShifts.forEach((openShiftJson: IOpenShiftServiceEntity) => openShifts.push(OpenShiftEntity.fromJson(openShiftJson)));
        }

        return new CopyOfShiftsResponse(shifts, openShifts);
    }
}