import Features from '../store/schema/Features';
import store from '../store/Store';

export default function isFeatureEnabled(flight: Features): boolean {
    let isEnabled = false;

    if (!store().featureFlags.has(flight)) {
        throw `No flight information for ${flight}`;
    }

    isEnabled = store().featureFlags.get(flight);

    return isEnabled;
}