import * as React from "react";
import { getClassNames } from "./EmptyFlexItem.styles";

interface EmptyFlexItemProps {
    id: string;
    flexGrow: number;
}

export const EmptyFlexItem = (props: EmptyFlexItemProps): JSX.Element => {
    const classNames = getClassNames();

    return (
        <div className={classNames.emptyFlexItem} style={{flexGrow: props.flexGrow }} aria-hidden={ true }/>
    );
};