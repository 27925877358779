import * as SyncTasks from "synctasks";
import EcsClient from "@skype/ecsclient";
import { AppSettings } from "sh-application";
import { ConfigModel, EcsConfigType, EcsResponseBase } from "@skype/ecsclient/dist/Models";
import { InstrumentationService } from "sh-services";

const CONFIG_CLIENTNAME = "MicrosoftTeams";
const CONFIG_CLIENTVERSION = "1.0.0.0";
const CONFIG_HOSTS = "https://config.teams.microsoft.com";

class EcsConfig {

    /**
     * Helper method to create the client config object
     * @param tenantId
     * @param ringId
     * @param userId
     */
    private getClientConfig(tenantId: string, ringId: string, userId: string): ConfigModel<EcsResponseBase> {
        const ecsProjectName = AppSettings.getSetting("ECSProjectTeam");
        const ecsEnvironmentFilter = AppSettings.getSetting("ECSEnvironmentFilter");

        return {
            clientName: CONFIG_CLIENTNAME,
            clientVersion: CONFIG_CLIENTVERSION,
            configsToFetch: [EcsConfigType.Default],
            hosts: [CONFIG_HOSTS],
            initialAppActiveState: true,
            getEcsParameters: (): SyncTasks.Promise<{
                [key: string]: string | number;
            }> =>
                SyncTasks.Resolved({
                    agents: ecsProjectName,
                    AudienceGroup: ringId,
                    TenantID: tenantId,
                    UserID: userId,
                    Environment: ecsEnvironmentFilter
                })
        };
    }

    /**
     * Gets the ECS config based on the ring and sends it in the callback
     * Also handles the config updates
     * @param tenantId
     * @param ringId
     * @param userId
     * @param setECSConfigCallback callback method to send the ECS config
     */
    public async loadConfig(tenantId: string, ringId: string, userId: string, setECSConfigCallback: (ecsConfig: { [s: string]: any; }) => void ): Promise<void> {
        const ecsClient = new EcsClient<EcsResponseBase>();
        const ecsConfig: ConfigModel<EcsResponseBase> = this.getClientConfig(tenantId, ringId, userId);

        if (!setECSConfigCallback) {
            return;
        }

        // Initialize ECS client SDK
        let ecsClientInitializeMarker = "ecsClientInitialize";
        ecsClientInitializeMarker = InstrumentationService.perfMarkerStart(ecsClientInitializeMarker);
        try {
            await ecsClient.initialize(ecsConfig);
        } catch (err) {
            // This should ideally not fail, so we dont stop the app initialization because of it
            // If there is an error, the app will be shown based on base config file
            InstrumentationService.logEvent(InstrumentationService.events.ECSInitFailed);
        } finally {
            InstrumentationService.perfMarkerEnd(ecsClientInitializeMarker);
        }

        // Set the config if available. At this point in time calling getConfig may still return undefined
        const response = ecsClient.getConfig();
        if (!!response && !!response.config) {
            setECSConfigCallback(response.config);
        }

        ecsClient.configUpdated.subscribe(() => {
            const response = ecsClient.getConfig();
            const config = response && response.config;
            // If the config is not available, set the empty value. It will use the base config
            setECSConfigCallback(config);
        });
    }
}

export const ECSHelper = new EcsConfig();
