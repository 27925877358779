import shiftRequestDetailsStore from "../../store/store";
import { mutatorAction } from "satcheljs";

export default mutatorAction('RESET_REQUEST_DETAILS_STORE', function resetRequestDetailsStore() {
    shiftRequestDetailsStore.request = null;
    shiftRequestDetailsStore.senderMember = null;
    shiftRequestDetailsStore.receiverMember = null;
    shiftRequestDetailsStore.managerMember = null;
    shiftRequestDetailsStore.shift = null;
    shiftRequestDetailsStore.otherShift = null;
    shiftRequestDetailsStore.shiftNotFound = false;
    shiftRequestDetailsStore.otherShiftNotFound = false;
    shiftRequestDetailsStore.isDataReady = false;
    shiftRequestDetailsStore.userResponseMessage = null;
});