import schedulesViewStateStore from "../../store/store";
import { IOpenShiftEntity } from "sh-models";
import { mutatorAction } from "satcheljs";
import { ObservableMap } from "mobx";

export default mutatorAction('DELETE_OPEN_SHIFTS_IN_VIEW', function deleteOpenShiftsInView(
    openShiftsToDelete: IOpenShiftEntity[]
) {
    let openShifts: ObservableMap<string, IOpenShiftEntity> = schedulesViewStateStore().openShiftsInView;
    for (let i = 0; i < openShiftsToDelete.length; i++) {
        openShifts.delete(openShiftsToDelete[i].id);
    }
});
