import { memberEditorViewState } from '../';
import { action } from "satcheljs/lib/legacy";

/**
 * Generic action that updates a teamsChannelId on a view state
 * @param teamsChannelId - value for teamsChannelId view state property
 */
export default action("setTeamsChannelId")(
    function setTeamsChannelId(teamsChannelId: string) {
      memberEditorViewState().teamsChannelId = teamsChannelId;
    }
);