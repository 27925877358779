import setSelectedRequest from "../actions/setSelectedRequest";
import ShiftRequestUtils from "sh-application/utility/ShiftRequestUtils";
import { calculateRequestConflicts } from "sh-requestconflict-store";
import { loadDataForGroupedRequest } from "../groupedrequestdetails/store/store";
import { loadDataForRequest, resetRequestDetailsStore } from "../requestdetails/store/store";
import { resetGroupedRequestDetailsStore } from "../groupedrequestdetails/store/store";
import { setSelectedItemType, setSelectedRequestIdInStore, ShiftRequestPanelSelectedItemType } from "../";
import { IGroupedOpenShiftRequestEntity, IShiftRequestEntity } from "sh-models";
import { orchestrator } from "satcheljs";

/**
 * This orchestrator will handle the calls needed to set the selected request Id
 */
export default orchestrator(setSelectedRequest, async actionMessage => {
    const { shiftRequest } = actionMessage;

    resetRequestDetailsStore();
    resetGroupedRequestDetailsStore();
    if (shiftRequest && shiftRequest.id) {
        setSelectedRequestIdInStore(shiftRequest.id);
        if (ShiftRequestUtils.isGroupedOpenShiftRequest(shiftRequest)) {
            loadDataForGroupedRequest(shiftRequest as IGroupedOpenShiftRequestEntity);
            setSelectedItemType(ShiftRequestPanelSelectedItemType.GroupedOpenShift);
            calculateRequestConflicts(shiftRequest, true /* isOpenShiftRequest */ );
        } else {
            loadDataForRequest(shiftRequest as IShiftRequestEntity);
            setSelectedItemType(ShiftRequestPanelSelectedItemType.RegularShiftRequest);
            calculateRequestConflicts(shiftRequest, false /* isOpenShiftRequest */);
        }
    } else {
        setSelectedRequestIdInStore(null);
        setSelectedItemType(ShiftRequestPanelSelectedItemType.NotSelected);
    }
});