import schedulesViewStateStore from "../../store/store";
import { IShiftEntity } from "sh-models";
import { mutatorAction } from "satcheljs";
import { ObservableMap } from "mobx";

export default mutatorAction('DELETE_SHIFTS_IN_VIEW', function deleteShiftsInView(
    shiftsToDelete: IShiftEntity[]
) {
    let shifts: ObservableMap<string, IShiftEntity> = schedulesViewStateStore().shiftsInView;
    for (let i = 0; i < shiftsToDelete.length; i++) {
        shifts.delete(shiftsToDelete[i].id);
    }
});
