import { action } from "satcheljs";
import { IBaseShiftEntity } from "sh-models";
import { Moment } from "moment";
import { ShiftEditorViewStateSchema } from "../index";

const initializeLocalObservableTimeoff = action(
    'initializeLocalObservableTimeoff',
    (
        shiftEditorViewState: ShiftEditorViewStateSchema,
        timeoff: IBaseShiftEntity,
        memberId: string,
        newTimeOffStartTime: Moment,
        isEditTimeOff: boolean) =>
    ({
        shiftEditorViewState: shiftEditorViewState,
        timeoff: timeoff,
        memberId: memberId,
        newTimeOffStartTime: newTimeOffStartTime,
        isEditTimeOff: isEditTimeOff
    })
);

export default initializeLocalObservableTimeoff;