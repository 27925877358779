import UniqueSubshiftStore from "../store";
import { action } from "satcheljs/lib/legacy";
import { IUniqueSubshiftEntity } from "sh-models";

/**
 * setUniqueSubshifts action - sets unique Subshifts
 * @param {Array<IUniqueSubshiftEntity} uniqueSubshifts
 */
export let setUniqueSubshifts = action("setUniqueSubshifts")(
    function setUniqueSubshifts(uniqueSubshifts: Array<IUniqueSubshiftEntity>) {
        UniqueSubshiftStore().uniqueSubshifts.clear();
        UniqueSubshiftStore().hasFetchedUniqueSubshifts.set(false);
        uniqueSubshifts.forEach((uniqueShift: IUniqueSubshiftEntity) => {
            UniqueSubshiftStore().uniqueSubshifts.push(uniqueShift);
        });
        UniqueSubshiftStore().hasFetchedUniqueSubshifts.set(true);
    });