import { action } from "satcheljs";
import { Moment } from "moment";
import { ShiftEditorViewStateSchema } from "../../lib";
import { IBaseShiftEntity } from "sh-models";

const initializeLocalObservableShift = action(
    'initializeLocalObservableShift',
    (
        shiftEditorViewState: ShiftEditorViewStateSchema,
        shift: IBaseShiftEntity,
        memberId: string,
        groupTagId: string,
        startDate: Moment
    ) =>
    ({
        shiftEditorViewState: shiftEditorViewState,
        shift: shift,
        memberId: memberId,
        groupTagId: groupTagId,
        startDate: startDate
    })
);

export default initializeLocalObservableShift;