import * as React from "react";
import { Overlay } from "@fluentui/react";

const styles = require("./SpotlightOverlay.scss");
// we use an enormous border to make that no matter where on screen the spotlight it, everything else on the screen will be darkened
// by the border
const BORDER_WIDTH = 4000;
const SPOTLIGHT_WIDTH = 200; // 200 is half the height/width as defined in SpotlightOverlay.scss

export interface SpotlightOverlayProps {
    target: HTMLElement;
}

export interface SpotlightOverlayState {
    top: number;
    left: number;
}
/**
 * SpotlightOverlay
 */
export default class SpotlightOverlay extends React.PureComponent<SpotlightOverlayProps, SpotlightOverlayState> {

    constructor(props: SpotlightOverlayProps) {
        super(props);

        this.state = {
            top: 0,
            left: 0
        };
    }

    componentDidMount() {
        this.calculatePosition(this.props.target);
    }

    componentDidUpdate(newProps: SpotlightOverlayProps) {
        this.calculatePosition(newProps.target);
    }

    /**
     * Calculate the top and left coordinates of the spotlight so that it will be centered on the target
     * element
     * @param target HTMLElement - The target element to center the spotlight on
     */
    private calculatePosition(target: HTMLElement) {
        if (target) {
            let rect = target.getBoundingClientRect();
            // 200 is half the height/width as defined in SpotlightOverlay.scss
            // we use Math.round() to prevent fractional pixels
            const calcTop = Math.round((rect.top - SPOTLIGHT_WIDTH + (rect.height / 2)) - BORDER_WIDTH);
            const calcLeft = Math.round((rect.left - SPOTLIGHT_WIDTH + (rect.width / 2)) - BORDER_WIDTH);
            this.setState({ top: calcTop, left: calcLeft });
        }
    }

    render() {
        const { children } = this.props;
        const { left, top } = this.state;

        const position = {
            top: top + "px",
            left: left + "px"
        };

        return (
                <Overlay
                    className={ styles.spotlightOverlay }
                    style={ position } >
                    { children }
                </Overlay>
            );
        }
}