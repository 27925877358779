import { createStore } from "satcheljs";
import { TeamSettingsStoreSchema } from "./schema/TeamSettingsStore";

/**
 * The actual store for Team Settings data
 */
let TeamSettingsStore = createStore<TeamSettingsStoreSchema>(
    "teamsettingsstore",
    {
        startingDayOfWeek: null,
        timeZoneOlsonCode: null,
        scheduleStartDate: null,
        scheduleType: null,
        scheduleLength: null,
        timeClockEnabled: null,
        hideOpenShifts: null
    });

export default TeamSettingsStore;