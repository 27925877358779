import * as React from "react";
import LineHtmlFormatUtils from "sh-application/utility/LineHtmlFormatUtils";
import StringsStore from "sh-strings/store";
import StringUtils from "sh-application/utility/StringUtils";
import { LineHtmlModel, PlaceholderTypes } from "sh-models";
import { ScheduleCellRenderSize } from "sh-application/../StaffHubConstants";

const styles = require("./SlotBar.scss");
const classNames = require("classnames/bind");

export interface SlotBarProps {
    /* The color of the slot bar, which comes from the associated shift */
    theme: string;

    /* The number of open slots, or available instances of an open shift  */
    openSlots: number;

    /* The render size for this slot bar */
    scheduleCellRenderSize: ScheduleCellRenderSize;

    /* Optional - a string to override the default slots string with */
    slotsOverrideString?: string;
}

/**
 * SlotBar - Contains a thick color bar and slot information. Color bar is a horizontal bar with a shift theme color.
 * Displays number of open slots, or available instances of an open shift.
 */
export default class SlotBar extends React.Component<SlotBarProps, any> {
    private static _pluralSlotsString = "";
    private static _singularSlotsString = "";
    private static _slotsFormatString = "";

    constructor(props: SlotBarProps) {
        super(props);
        if (!SlotBar._pluralSlotsString) {
            SlotBar._pluralSlotsString = StringsStore().registeredStringModules.get("common").strings.get("openShiftSlotsPlural");
        }
        if (!SlotBar._singularSlotsString) {
            SlotBar._singularSlotsString = StringsStore().registeredStringModules.get("common").strings.get("openShiftSlotsSingular");
        }
        if (!SlotBar._slotsFormatString) {
            SlotBar._slotsFormatString = StringsStore().registeredStringModules.get("common").strings.get("openShiftsSlotLabelFormatString");
        }
    }

    /**
     * Returns the slot number label, which varies depending on the scheduleCellRenderSize
     */
    private renderSlotNumberLabel(): JSX.Element {
        const { openSlots } = this.props;
        let slotNumberLabel: JSX.Element = null;

        if (this.props.slotsOverrideString) {
            const lineHtmlModel: LineHtmlModel = {
                text : SlotBar._slotsFormatString, // localizable format string
                cssClasses : styles.slotNumberLabel,
                placeholders : [
                    { text: String(openSlots), placeholderType: PlaceholderTypes.TextSpan, cssClasses: styles.numberOfSlots }, // number of slots
                    { text: this.getSlotsString(), placeholderType: PlaceholderTypes.TextSpan, cssClasses: styles.slotsLabel }  // localized slot label
                ]
            };

            slotNumberLabel = LineHtmlFormatUtils.getLineHtmlElement(lineHtmlModel);
        } else {
            const openSlotString = StringsStore().registeredStringModules.get("common").strings.get("openShiftsSlotLabelCondensedFormatString").format(String(openSlots));
            slotNumberLabel = (
                    <span aria-hidden={ true } className={ classNames(styles.slotNumberLabel, styles.inSmallContainer) }>
                        <span className={ styles.numberOfSlots }>{ openSlotString }</span>
                    </span>
                );
        }

        return slotNumberLabel;
    }

    /**
     * Returns a slots string
     */
    private getSlotsString(): string {
        const { openSlots, slotsOverrideString } = this.props;

        let slotsString: string = "";

        if (slotsOverrideString) {
            slotsString = slotsOverrideString;
        } else {
            slotsString = StringUtils.usePluralForm(openSlots) ? SlotBar._pluralSlotsString :  SlotBar._singularSlotsString;
        }

        return slotsString;
    }

    render() {
        const { scheduleCellRenderSize, theme } = this.props;

        const containerStyles = classNames(styles.slotBarContainer,
            { [`${styles.small}`]: scheduleCellRenderSize === ScheduleCellRenderSize.Small },
            { [`${styles.large}`]: scheduleCellRenderSize === ScheduleCellRenderSize.Large });

        return (
            <div className={ containerStyles } >
                <div className={ classNames(styles.thickColorBar, styles[theme]) } />
                { this.renderSlotNumberLabel() }
            </div>
        );
    }
}