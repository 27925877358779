import RestClient from "sh-rest-client";
import setTeamDetails from "./setTeamDetails";
import { action } from "satcheljs/lib/legacy";
import { InstrumentationService } from "sh-services";
import { ITeamInfoEntity, TeamDetailsResponse, TeamInfoEntity } from "sh-models";

/**
 * Action to create a new team
 * @param {team} The team to be created
 * @param {string} teamStartingDayOfWeek  - The day of the week to start the calendars at for the team = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
 * @param {string} teamTimezone - The olson code that represents the teams time zone
 * @param {boolean} skipGroupProvisioning - Skip provisioning the modern group
 */
export let addTeam = action("addTeam")(
    async function addTeam(team: ITeamInfoEntity, teamStartingDayOfWeek: string, teamTimezone: string, skipGroupProvisioning: boolean) {
        let marker = "addTeam";
        marker = InstrumentationService.perfMarkerStart(marker);

        // Generate a teamId if one isn't specified
        if (!team.id) {
            team.id = TeamInfoEntity.generateNewTeamId();
        }

        // Generate a teamHandle if one isn't specified
        if (!team.handle) {
            team.handle = team.id.replace(/-/g, "");
        }

        try {
            const teamDetails: TeamDetailsResponse = await RestClient.addTeam(team, teamStartingDayOfWeek, teamTimezone, skipGroupProvisioning);
            setTeamDetails(teamDetails);
            return teamDetails;
        } finally {
            InstrumentationService.perfMarkerEnd(marker);
        }
    });