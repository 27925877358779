import { IDropdownStyleProps } from "@fluentui/react";
import { generateContrastStyles } from "sh-application/utility/styling";

/**
 * Style overrides for the dropdown, mostly applies to contrast.
 *
 * This can be potentially used in the ThemeProvider to override all the dropdowns
 * For the meantime is just overriding two dropdowns to solve a11y issues.
 */
export function getDropdownStyles({ theme: { semanticColors } }: IDropdownStyleProps) {
    const defaultContrastOverrides = generateContrastStyles({
        ":focus:hover": {
            backgroundColor: semanticColors.primaryButtonBackgroundHovered
        },
        ".ms-Fabric--isFocusVisible": {
            backgroundColor: semanticColors.primaryButtonBackgroundHovered
        }
    });
    const defaultContrastSelectedOverrides = generateContrastStyles({
        ":focus:hover": {
            backgroundColor: semanticColors.buttonBackgroundChecked,
            color: semanticColors.buttonTextCheckedHovered
        },
        ".ms-Fabric--isFocusVisible": {
            backgroundColor: semanticColors.buttonBackgroundChecked,
            color: semanticColors.buttonTextCheckedHovered
        },
        "&.ms-Button.ms-Dropdown-item:focus": {
            backgroundColor: semanticColors.buttonBackgroundChecked,
            color: semanticColors.buttonTextCheckedHovered
        }
    });

    return {
        dropdownItem: [defaultContrastOverrides],
        dropdownItemSelected: [{ fontWeight: "bold" }, defaultContrastSelectedOverrides]
    };
}
