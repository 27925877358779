/**
 * The loading states.
 */
export enum LoadingState {
    /**
     * The state when loading failed.
     */
    Failed = "Failed",

    /**
     * The state when loading has started.
     */
    Loading = "Loading",

    /**
     * The state when loading has not yet started.
     */
    NotStarted = "NotStarted",

    /**
     * The state when loading finished successfully.
     */
    Succeed = "Succeed"
}