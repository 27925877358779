import INoteEntity from "../../../sh-models/note/INoteEntity";
import { createStore } from "satcheljs";
import { NoteStoreSchema } from "./schema/NoteStoreSchema";
import { ObservableMap } from "mobx";

/**
 * Store for Note data
 */

let NoteStore = createStore<NoteStoreSchema>(
    "noteStore",
    {
        notes: new ObservableMap<string, INoteEntity>(),
        notesCacheStartTime: null,
        notesCacheEndTime: null
    });

export default NoteStore;