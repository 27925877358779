import ITimeOffReasonEntity from "./ITimeOffReasonEntity";
import ModelUtils from "../ModelUtils";
import ShiftrObjectModelBase from "../ShiftrObjectModelBase";

const timeOffReasonIdPrefix = "TOR_";

/**
 * Entity class for TimeOffReasons
 */
export default class TimeOffReasonEntity extends ShiftrObjectModelBase implements ITimeOffReasonEntity {
    tenantId: string;
    teamId: string;
    index: number;
    isPaid: boolean;
    name: string;
    iconType: string;
    timeOffReasonType: string;
    isHidden: boolean;
    state: string;
    code: string | null;

    constructor(
        id: string,
        eTag: string,
        tenantId: string,
        teamId: string,
        index: number,
        isPaid: boolean,
        name: string,
        iconType: string,
        timeOffReasonType: string,
        isHidden: boolean,
        state: string,
        code: string | null) {
        let timeOffId = id && id.length > 0 ? id : TimeOffReasonEntity.generateNewTimeOffReasonId();
        super(timeOffId, eTag);
        this.tenantId = tenantId;
        this.teamId = teamId;
        this.index = index;
        this.isPaid = isPaid;
        this.name = name;
        this.iconType = iconType;
        this.timeOffReasonType = timeOffReasonType;
        this.isHidden = isHidden;
        this.state = state;
        this.code = code;
    }

    /**
    * Generate new time off reason item ID
    * @returns {string} teamId in form of TOR_<uuid>
    */
    public static generateNewTimeOffReasonId(): string {
            return ModelUtils.generateUUIDWithPrefix(timeOffReasonIdPrefix);
    }

    /**
     * Check if there are UI Visible changes from a previous version (and if we need to resave the item)
     * It ignores etag changes because if the UI layer has an outdated etag but the same data, we don't want to resave
     * @param newTimeOffReason New version of TimeOffReason
     * @param oldTimeOffReason Previous version of the same TimeOffReason
     */
    public static containsVisibleChanges(newTimeOffReason: ITimeOffReasonEntity, oldTimeOffReason: ITimeOffReasonEntity) {
        return oldTimeOffReason.name !== newTimeOffReason.name
            || oldTimeOffReason.iconType !== newTimeOffReason.iconType
            || oldTimeOffReason.state !== newTimeOffReason.state;
    }

    public static fromJson(jsonData: ITimeOffReasonEntity): ITimeOffReasonEntity {
        if (!jsonData) {
            return null;
        } else {
            return TimeOffReasonEntity.clone(jsonData);
        }
    }

    public static toJson(tor: ITimeOffReasonEntity): ITimeOffReasonEntity {
        if (!tor) {
            return null;
        } else {
            return TimeOffReasonEntity.clone(tor);
        }
    }

    public static clone(tor: ITimeOffReasonEntity): ITimeOffReasonEntity {
        return new TimeOffReasonEntity(
            tor.id,
            tor.eTag,
            tor.tenantId,
            tor.teamId,
            tor.index,
            tor.isPaid,
            tor.name,
            tor.iconType,
            tor.timeOffReasonType,
            tor.isHidden,
            tor.state,
            tor.code
        );
    }
}
