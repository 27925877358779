import ShiftUtils from "sh-application/utility/ShiftUtils";
import StringsStore from "sh-strings/store";
import {
    ChangeEntity,
    ChangeSource,
    IBulkShiftResponseEntity,
    IChangeEntity,
    IOpenShiftEntity,
    IShiftEntity
    } from "sh-models";
import { getGenericEventPropertiesObject } from "sh-instrumentation";
import { InstrumentationService, ShiftDataService } from "sh-services";
import { mapOldShiftIdsToNewShiftIds } from "sh-change-store";

/**
 * ChangeUtils handles common functionality associated with a ChangeEntity.
 * Every function should take an IChangeEntity or a list of IChangeEntity as the first parameter.
 */
export default class ChangeUtils {

    /**
    * Helper function for logging undo events.
    *
    * @param change
    */
    public static logUndoEvent(changes: Array<IChangeEntity>, isKeyboardShortcut: boolean) {
        if (changes && changes.length > 0) {
            InstrumentationService.logEvent(InstrumentationService.events.Undo_Action,
                [getGenericEventPropertiesObject(InstrumentationService.properties.ChangeSource, changes[0].changeSource),
                    getGenericEventPropertiesObject(InstrumentationService.properties.ChangeType, ChangeUtils.getInstrumentationChangeTypeList(changes)),
                    getGenericEventPropertiesObject(InstrumentationService.properties.NumberShiftsChanged, ChangeUtils.getSumOfNumShiftsChanged(changes)),
                    getGenericEventPropertiesObject(InstrumentationService.properties.IsKeyboardShortcut, isKeyboardShortcut)]);
        }
    }

    /**
    * Helper function for logging redo events.
    *
    * @param change
    */
    public static logRedoEvent(changes: Array<IChangeEntity>, isKeyboardShortcut: boolean) {
        if (changes && changes.length > 0) {
            InstrumentationService.logEvent(InstrumentationService.events.Redo_Action,
                [getGenericEventPropertiesObject(InstrumentationService.properties.ChangeSource, changes[0].changeSource),
                    getGenericEventPropertiesObject(InstrumentationService.properties.ChangeType, ChangeUtils.getInstrumentationChangeTypeList(changes)),
                    getGenericEventPropertiesObject(InstrumentationService.properties.NumberShiftsChanged, ChangeUtils.getSumOfNumShiftsChanged(changes)),
                    getGenericEventPropertiesObject(InstrumentationService.properties.IsKeyboardShortcut, isKeyboardShortcut)]);
        }
    }

    /**
     * Method for check if a change involves a delete of a published shift
     * @param change
     */
    public static isDeleteOfPublishedShift(change: IChangeEntity): boolean {
        if (ChangeUtils.isDelete(change)) {
            if (change.previousShifts.some(ShiftUtils.hasSharedChanges)) {
                return true;
            }
        }
        return false;
    }

    /**
     * get the type list for one or many changes
     *
     * @param changes
     */
    public static getInstrumentationChangeTypeList(changes: Array<IChangeEntity>): string {
        let typeString = this.getInstrumentationChangeType(changes[0]);
        for (let i = 1; i < changes.length; i++) {
            typeString += "," + this.getInstrumentationChangeType(changes[i]);
        }
        return typeString;
    }
    /**
     * Method for getting the changeType for instrumentation
     * @param change
     */
    public static getInstrumentationChangeType(change: IChangeEntity): string {
        if (this.isAdd(change)) {
            return InstrumentationService.values.Add;
        } else if (this.isDelete(change)) {
            return InstrumentationService.values.Delete;
        } else if (this.isUpdate(change)) {
            return InstrumentationService.values.Edit;
        }
    }

    /**
     * Get the sum of the numShiftsChanged for each change
     * @param changes
     */
    public static getSumOfNumShiftsChanged(changes: Array<IChangeEntity>): number {
        let total = 0;
        for (let i = 0; i < changes.length; i++) {
            total += this.getNumShiftsChanged(changes[i]);
        }
        return total;
    }

    /**
     * Method for getting the numShiftsChanged for instrumentation
     * @param change
     */
    public static getNumShiftsChanged(change: IChangeEntity): number {
        if (change.resultingShifts) {
            return change.resultingShifts.length;
        } else if (change.previousShifts) {
            return change.previousShifts.length;
        } else {
            return 0;
        }
    }

    /**
     * Helper method for determing if a change is an add
     * @param change
     */
    public static isAdd(change: IChangeEntity): boolean {
        return change.previousShifts === null
            && change.resultingShifts !== null;
    }

    /**
     * Helper method for determing if a change is a conflict dismissal
     * @param change
     */
    public static isConflictDismiss(change: IChangeEntity): boolean {
        if (!change) {
            return false;
        }
        return change.previousConflict !== null;
    }

    /**
     * Helper method for determining if a change is a delete
     * @param change
     */
    public static isDelete(change: IChangeEntity): boolean {
        return change.previousShifts !== null
            && change.resultingShifts === null;
    }

     /**
     * Helper method for determining if change is an update
     * @param change
     */
    public static isUpdate(change: IChangeEntity): boolean {
        return change.previousShifts !== null
            && change.resultingShifts !== null;
    }

    /**
     * Helper method for determining if a change is a paste
     * @param change
     */
    public static isPaste(change: IChangeEntity): boolean {
        return change.changeSource === ChangeSource.Paste;
    }

    /**
     * Helper method for determining if a change is a copy of last week
     * @param change
     */
    public static isCopyDateRange(change: IChangeEntity): boolean {
        return change.changeSource === ChangeSource.CopyDateRange;
    }

    /**
     * Helper method for determining if a change is a copy of last week
     * @param change
     */
    public static isDragAndDrop(change: IChangeEntity): boolean {
        return change.changeSource === ChangeSource.DragAndDrop;
    }

    /**
     * Get the user friendly string to represent a set of changes.  This is used for the undo button.
     * Allows null
     * @param changes
     */
    public static getUserFriendlyStringForUndoUserAction(changes: Array<IChangeEntity>) {
        let _userActionStrings = StringsStore().registeredStringModules.get("userActions").strings;
        let undoableUserAction: string = "";
        if (changes) {
            if (changes.every((change) => ChangeUtils.isPaste(change))) {
                undoableUserAction = _userActionStrings.get("paste");
            } else if (changes.every((change) => ChangeUtils.isCopyDateRange(change))) {
                undoableUserAction = _userActionStrings.get("copySchedule");
            } else if (changes.every((change) => ChangeUtils.isDragAndDrop(change))) {
                undoableUserAction = _userActionStrings.get("dragAndDrop");
            } else if (changes.every((change) => ChangeUtils.isAdd(change))) {
                undoableUserAction = _userActionStrings.get("add");
            } else if (changes.every((change) => ChangeUtils.isDelete(change))) {
                undoableUserAction = _userActionStrings.get("delete");
            } else if (changes.every((change) => ChangeUtils.isUpdate(change))) {
                undoableUserAction = _userActionStrings.get("edit");
            } else {
                // We have covered most cases but this is a good string for any we miss or add later
                undoableUserAction = _userActionStrings.get("lastChange");
            }
        }
        return undoableUserAction;
    }

    /**
     * Get the user friendly string to represent a set of changes.  This is used for the redo button.
     * Allows null
     * @param changes
     */
    public static getUserFriendlyStringForRedoUserAction(changes: Array<IChangeEntity>) {
        let _userActionStrings = StringsStore().registeredStringModules.get("userActions").strings;
        let redoableUserAction: string = "";
        if (changes) {
            if (changes.every((change) => ChangeUtils.isPaste(change))) {
                redoableUserAction = _userActionStrings.get("paste");
            } else if (changes.every((change) => ChangeUtils.isCopyDateRange(change))) {
                redoableUserAction = _userActionStrings.get("copySchedule");
            } else if (changes.every((change) => ChangeUtils.isDragAndDrop(change))) {
                redoableUserAction = _userActionStrings.get("dragAndDrop");
            } else if (changes.every((change) => ChangeUtils.isDelete(change))) {
                redoableUserAction = _userActionStrings.get("add");
            } else if (changes.every((change) => ChangeUtils.isAdd(change))) {
                redoableUserAction = _userActionStrings.get("delete");
            } else if (changes.every((change) => ChangeUtils.isUpdate(change))) {
                redoableUserAction = _userActionStrings.get("edit");
            }
        }
        return redoableUserAction;
    }

    /**
     * This function returns a task which will be used in revertChanges() to revert a change that added open shifts.
     * That task will return a promise and prepare a change representing the undone action, which can be used in redo.
     */
    public static getRevertAddOpenShiftsTask(tenantId: string, teamId: string, addedOpenShifts: IOpenShiftEntity[], changeSource: ChangeSource) {
        return () => {
            const shiftsToDelete = ShiftUtils.cloneShiftList(addedOpenShifts);
            return ShiftDataService.deleteOpenShifts(tenantId, teamId, shiftsToDelete as IOpenShiftEntity[], true /* optimistic */, false /* isPublished */)
                .then((deletedOpenShifts: IOpenShiftEntity[] = []) => {
                    // persist the change source for messaging to the user on the redo button
                    return new ChangeEntity(deletedOpenShifts, null, changeSource, ChangeUtils.getRevertDeleteOpenShiftsTask(tenantId, teamId, deletedOpenShifts, changeSource));
                });
        };
    }

    /**
     * This function returns a task which will be used in revertChanges() to revert a change that deleted open shifts.
     * That task will return a promise and prepare a change representing the undone action, which can be used in redo.
     */
    public static getRevertDeleteOpenShiftsTask(tenantId: string, teamId: string, deletedOpenShifts: IOpenShiftEntity[], changeSource: ChangeSource) {
        return () => {
            // generate new ids for the shifts.  Service does not allow updating the shift state from Deleted to Active so we
            // create a new shift instead
            const shiftsToAdd = ShiftUtils.cloneShiftList(deletedOpenShifts, true /*genereateNewIds*/);
            return ShiftDataService.bulkAddShifts(tenantId, teamId, null /* assignedShifts */, shiftsToAdd as IOpenShiftEntity[], true /* optimistic */, false /* isPublished */)
                .then((bulkResponse: IBulkShiftResponseEntity) => {
                    mapOldShiftIdsToNewShiftIds(deletedOpenShifts.map((shift: IShiftEntity) => shift.id), bulkResponse.openShifts.map((shift: IShiftEntity) => shift.id));
                    // persist the change source for messaging to the user on the redo button
                    return new ChangeEntity(null, bulkResponse.openShifts, changeSource, ChangeUtils.getRevertAddOpenShiftsTask(tenantId, teamId, bulkResponse.openShifts, changeSource));
                });
        };
    }

    /**
     * This function returns a task which will be used in revertChanges() to revert a change that update open shifts.
     * That task will return a promise and prepare a change representing the undone action, which can be used in redo.
     */
    public static getRevertUpdateOpenShiftsTask(tenantId: string, teamId: string, originalOpenShifts: IOpenShiftEntity[], updatedOpenShifts: IOpenShiftEntity[], changeSource: ChangeSource) {
        return () => {
            const shiftsToUpdate = ShiftUtils.cloneShiftList(originalOpenShifts);
            return ShiftDataService.updateOpenShifts(tenantId, teamId, shiftsToUpdate as IOpenShiftEntity[], true /* optimistic */, false /* isPublished */)
                .then((latestOpenShifts: IOpenShiftEntity[] = []) => {
                    // persist the change source for messaging to the user on the redo button
                    return new ChangeEntity(updatedOpenShifts, latestOpenShifts, changeSource, ChangeUtils.getRevertUpdateOpenShiftsTask(tenantId, teamId, updatedOpenShifts, latestOpenShifts, changeSource));
                });
        };
    }
}