import { ConflictStore } from "sh-conflict-store";
import {
    ConflictType,
    IAvailabilityConflictEntity,
    IAvailabilityEntity,
    IShiftEntity,
    AvailabilityConflictEntity
    } from "sh-models";
import { mutatorAction } from "satcheljs";

export default mutatorAction('ADD_SHIFT_AVAILABILITY_CONFLICT', function addShiftAvailabilityConflict(shift: IShiftEntity, conflictingAvailability: IAvailabilityEntity) {
    const conflictAvailabilityEntity: IAvailabilityConflictEntity = new AvailabilityConflictEntity(
        shift.memberId,
        shift.id,
        ConflictType.ShiftAvailabilityConflict,
        false,
        conflictingAvailability.id
    );

    let availabilityArray = ConflictStore().shiftIdToConflictsMap.get(shift.id) || [];
    availabilityArray.push(conflictAvailabilityEntity);
    ConflictStore().shiftIdToConflictsMap.set(shift.id, availabilityArray);
});
