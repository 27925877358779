/**
 * Enum for all config names in ECS
 * Use camel case for names
 */
enum ECSConfigKey {
    RefreshCommandEnabled = "refreshCommandEnabled",
    RequestUnreadCountEnabled = "requestUnreadCountEnabled",
    NewScheduleHeaderEnabled = "newScheduleHeaderEnabled",
    DayNotesInNewScheduleHeaderEnabled = "dayNotesInNewScheduleHeaderEnabled",
    EnableTimeClockDialogs = "enableTimeClockDialogs",
    EnableCopyShiftActivitiesSetting = "enableCopyShiftActivitiesSetting",
    EnableCopyShiftActivitiesFreDialog = "enableCopyShiftActivitiesFreDialog",

    // Enable/disable FRE overhaul changes
    EnableFREMilestone1 = "enableFREMilestone1",

    // Enable/disable support for your multi team my shifts api and Indexed db creation
    EnableMyShiftsApi = "enableMyShiftsApi",

    // Enable/disable support for schedule filter
    EnableScheduleFilter = "enableScheduleFilter",

    // Enable/disable support for Request Swap filtering
    EnableRequestSwapFiltering = "enableRequestSwapFiltering",

    /**
     * Whether fetching team members and users profiles from Graph service is enabled.
     * This includes searching members to add to a schedule group and retrieving user profile info to show in Shifts web schedule and requests tab.
     */
    EnableTeamsAndUsersInfoFromGraph = "enableTeamsAndUsersInfoFromGraph",

    // Enable/disable support for requestable TimeOff reasons.
    EnableTimeOffReasonsFiltering = "enableTimeOffReasonsFiltering",

    EnableCopyDatePickerDialog = "enableCopyDatePickerDialog",
    EnableCopyHeader = "enableCopyHeader",

    // Whether OpenShifts are enabled
    EnableOpenShifts = "enableOpenShifts",

    // Store the sync state in IndexedDb
    EnableSyncStateInIndexedDb = "enableSyncStateInIndexedDb",

    // Store shifts in IndexedDb
    EnableShiftsIndexedDb = "enableShiftsIndexedDb",

    // Union support enabled
    EnableUnionSupport = "enableUnionSupport",

    // Schedule withdraw enabled
    EnableWithdrawSchedule = "enableWithdrawSchedule",

    // Users can request Open Shifts
    EnableOpenShiftRequests = "enableOpenShiftRequests",

    // Scheduled Grid threshold for using a virualized list
    ScheduleGridVirtualizedListThreshold = "scheduleGridVirtualizedListThreshold",

    // Schedule Availability enabled
    EnableScheduleAvailability = "enableScheduleAvailability",

    // Enable only grouped view for viewing the schedule.
    EnableOnlyGroupedViewForSchedule = "enableOnlyGroupedViewForSchedule",

    // Enable print scale to one page
    EnablePrintScaleToOnePage = "enablePrintScaleToOnePage",

    // Enable creating requests
    EnableCreateRequests = "enableCreateRequests",

    // Import schedule polling interval in milliseconds
    ImportSchedulePollIntervalInMilliseconds = "importSchedulePollIntervalInMilliseconds",

    // Import schedule max retry count
    ImportScheduleMaxRetry = "importScheduleMaxRetry",

    // Provision Schedule polling interval in milliseconds
    ProvisionSchedulePollIntervalInMilliseconds = "provisionSchedulePollIntervalInMilliseconds",

    // Provision Schedule max retry count
    ProvisionScheduleMaxRetry = "provisionScheduleMaxRetry",

    // Native Time Clock enabled
    EnableNativeTimeClock = "enableNativeTimeClock",

    // Import excel schedule Page is enabled
    EnableImportExcelSchedulePage = "enableImportExcelSchedulePage",

    // Enable Member Editor Dialog
    EnableMemberEditorDialog = "enableMemberEditorDialog",

    // Enable V2 File Download APIs
    EnableV2FileDownloadAPIs = "enableV2FileDownloadAPIs",

    // Max pages for GDIDR
    GDIDRMaxPages = "gdidrMaxPages",

    // Enable/Disable progressive rendering (rendering scheduler while paging data)
    EnableProgressiveRendering = "enableProgressiveRendering",

    // The number of tags (groups) to download per progressive rendering loop (the first loop will always be 1 for performance)
    ProgressiveRenderingTagsPerLoop = "progressiveRenderingTagsPerLoop",

    // Enable/Disable logging perf data to the console
    EnableConsoleLogPerfData = "enableConsoleLogPerfData",

    // The minimum frequency with which to call the /sync API (fallback in case SignalR is not working)
    MinSyncCallFrequencyMs = "minSyncCallFrequencyMs",

    // The duration that the user has to be inactive for us to assume that they're idle (and the tab is inactive)
    UserIdleStateDurationMs = "userIdleStateDurationMs",

    // The build version to use for the environment+ring+tenant+user combination
    BuildVersion = "buildVersion",

    // How long to wait before we notify the user that there is an updated client build available
    // (this should be at least 5 minutes so that the frontend service has time to refresh its ECS config cache)
    BuildUpdateNotificationDelayMs = "buildUpdateNotificationDelayMs",

    // The min number of members in a team to disable single page print for
    MinTeamSizeToDisableSinglePagePrint = "minTeamSizeToDisableSinglePagePrint",

    // Set the large teams lower limit through ecs, this way we can control large teams limit on run time
    TeamSizeToEnableLargeTeamFeatures = "teamSizeToEnableLargeTeamFeatures",

    // Show/Hide the help page link
    EnableHelpPage = "enableHelpPage",

    // Copy schedule polling interval in milliseconds
    CopySchedulePollIntervalInMilliseconds = "copySchedulePollIntervalInMilliseconds",

    // Copy schedule max retry count
    CopyScheduleMaxRetry = "copyScheduleMaxRetry",

    // Enable V2 Copy Schedule APIs
    EnableCopyScheduleAsyncAPIs = "enableCopyScheduleAsyncAPIs",

    // Enable copy schedule repetition
    EnableCopyScheduleRepetition = "enableCopyScheduleRepetition",

    // Enable Bing Location Detection
    EnableBingLocationDetection = "enableBingLocationDetection",

    // Enable WFI error handling
    EnableWFIErrorHandling = "enableWFIErrorHandling",

    // Enable overall conflict management
    EnableConflictManagement = "enableConflictManagement",

    // Enable conflict management in past shifts/availability
    EnableConflictManagementInPast = "enableConflictManagementInPast",

    // Enable shifts overlap conflict calculation and management
    EnableShiftOverlapConflicts = "enableShiftOverlapConflicts",

    // Enable shifts timeoff conflict calculation and management
    EnableShiftTimeoffConflicts = "enableShiftTimeoffConflicts",

    // Enable conflicts between shift and availability
    EnableShiftAvailabilityConflicts = "enableShiftAvailabilityConflicts",

    // Enable conflicts between shift and request
    EnableShiftRequestConflicts = "enableShiftRequestConflicts",

    // Enable conflicts between request and availability
    EnableRequestAvailabilityConflicts = "enableRequestAvailabilityConflicts",

    // Enable dismassal of conflicts in callout
    EnableConflictsDismissal = "enableConflictsDismissal",

    // Maximum length of a shift (working/absence) that we will calculate conflicts on (shifts longer than this are ignored).
    ConflictCalcMaxShiftLengthInDays = "conflictCalcMaxShiftLengthInDays",

    // Maximum number of conflicts that we'll compute in a view (we'll ignore conflicts past this point)
    ConflictCalcMaxConflictCount = "conflictCalcMaxConflictCount",

    // Enable 24h shift creation and render
    Enable24hShift = "enable24hShift",

    // Enable Copy Schedule By Group
    EnableCopyScheuleByGroups = "enableCopyScheduleByGroups",

    // Enable Copy Schedule By Member
    EnableCopyScheuleByMembers = "enableCopyScheduleByMembers",

    // Enable Time Clock on Web
    EnableTimeClockOnWeb = "enableTimeClockOnWeb",

    // Enable Filter Active Shifts on Schedule
    EnableFilterActiveShifts = "enableFilterActiveShifts",

    // Enable Dialog in Timezone Picker
    EnableDialogTimezonePicker = "enableDialogTimezonePicker",

    // Default Service Endpoint (overrides the value in AppSettings if specified)
    DefaultServiceEndpoint = "defaultServiceEndpoint",

    // Enable using AFD Service URLs
    UseAFDServiceUrl = "useAFDServiceUrl",

    // Enable Export Shifts Match Report
    EnableExportShiftsMatchReport = "enableExportShiftsMatchReport",

    // Enable Conflict Filtering
    EnableConflictFiltering = "enableConflictFiltering",

    // Enable Show Staffhub Teams
    EnableShowStaffhubTeams = "enableShowStaffhubTeams",

    // Enable Shifts targeting helper text
    EnableShiftsTargetingHelperText = "enableShiftsTargetingHelperText",

    // Honor Schedule Owner role in Web
    EnableScheduleOwnerPermission = "enableScheduleOwnerPermission",

    // The max number of items per virtual page to renter in the scheduler (example items: group header row, member row, open shift row)
    // The higher the number, the longer it takes to calculate and render, however there is less "overhead" with managing the pages
    // The lower the number, the faster it calculates and renders each page, however there is more "overhead" for managing each page
    SchedulerItemsPerVirtualPage = "schedulerItemsPerVirtualPage",

    // Export schedule date range limit
    ExportScheduleDateRangeLimit = "exportScheduleDateRangeLimit",

    // Export timeclock entries date range limit
    ExportTimeClocksDateRangeLimit = "exportTimeClocksDateRangeLimit",

    // Connector Dialog
    EnableConnectorAuthenticationFlow = "enableConnectorAuthenticationFlow",

    // Use Compliant Telemetry Client
    EnableEUDBCompliantTelemetryClient = "enableEUDBCompliantTelemetryClient",

    // Current Telemetry region code
    CurrentTelemetryRegionCode = "TelemetryRegion",
    // EU Telemetry Region Code
    EUDBTelemetryRegionCode = "eudbTelemetryRegionCode",

    // Connector authentication Done URL.
    ConnectorAuthenticationDoneURL = "connectorAuthenticationDoneURL",

    // Use the "/api/users/{userId}/dataindaterange" API instead of "/api/tenants/{tenantId}/teams/{teamId}/shifts" API to fetch member shifts for the date picker
    UseUserSpecificApiForShiftHintDatePicker = "useUserSpecificApiForShiftHintDatePicker",

    // Whether the new exceptions properties logging is enabled. When enabled, error message, stack trace and type are logged. This can be disabled if any related Privacy incidents or PII info is logged.
    NewExceptionsPropertiesLoggingEnabled = "NewExceptionsPropertiesLoggingEnabled",

    /** Whether Cross Location Marketplace is enabled.
    * When enabled, FLMs will be able to create open shifts.
    * These open shifts will be displayed in another tab for FLWs in other schedules to find and apply to.  */
    CrossLocationMarketplaceFeatureEnabled = "CrossLocationMarketplaceFeatureEnabled"
}

export default ECSConfigKey;
