import ChangeStore from "../store";
import { action } from "satcheljs/lib/legacy";
import { FlightKeys, IChangeEntity } from "sh-models";
import { FlightSettingsService } from "sh-services";
import { lastUndoableChanges } from "../computed/lastUndoableChanges";
import { revertChanges } from "./revertChanges";
import { trackChanges } from "./trackChanges";

/**
 * Perform an undo if there is a change on the stack to undo
 */
export let performUndo = action("performUndo")(
    function performUndo() {
        return new Promise<Array<IChangeEntity>>((resolve, reject) => {
            // check for undoable changes
            const hasUndoableChanges: boolean = !!lastUndoableChanges.get();
            if (hasUndoableChanges && FlightSettingsService.isFlightEnabled(FlightKeys.EnableShiftUndoWeb)) {
                // remove the last change from the undo stack and revert it
                return revertChanges(ChangeStore().undoStack.pop(), true /* fromUndo */, false /* fromRedo */)
                    .then((changes: Array<IChangeEntity>) => {
                        // track this change, it will go onto the redo stack so that it can be redid
                        trackChanges(changes, true /* fromUndo */);
                        resolve(changes);
                    }).catch(reject);
            } else {
                // resolve with empty array. Nothing was undid
                resolve([]);
            }
        });
    });
