import { action } from "satcheljs";
import { SchedulesViewStateStore } from "sh-application/components/schedules/lib/store/schema/SchedulesViewStateStore";
import { Moment } from "moment";
import {
    EmployeeViewType,
    ScheduleCalendarType,
    ScheduleOverViewType
    } from "sh-models";

// TODO: Make a single object so that objects can be passed instead of individual parameters.
export let updateScheduleViewDimensions = action(
    'UPDATE_SCHEDULE_VIEW_DIMENSIONS',
    (
        viewState: SchedulesViewStateStore,
        isTeamBootstrap: boolean,
        employeeViewType: EmployeeViewType | null,
        scheduleCalendarType: ScheduleCalendarType | null,
        scheduleOverViewType: ScheduleOverViewType | null,
        selectedDate: Moment | null,
        isViewGrouped: boolean | null
    ) =>
    ({
        viewState: viewState,
        isTeamBootstrap: isTeamBootstrap,
        employeeViewType: employeeViewType,
        scheduleCalendarType: scheduleCalendarType,
        scheduleOverViewType: scheduleOverViewType,
        selectedDate: selectedDate,
        isViewGrouped: isViewGrouped
    })
);

export default updateScheduleViewDimensions;