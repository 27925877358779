import { DataService } from "./DataService";
import { ITeamPermissionEntity } from "../../sh-models";
import { TeamPermissionsDataProvider } from "../dataproviders/TeamPermissionsDataProvider";
import { ITeamPermissionsDataService } from "./ITeamPermissionsDataService";

/**
 * Team Permissions Data Service.
 */
class TeamPermissionsDataService extends DataService implements ITeamPermissionsDataService {
    /**
     * Gets the team permissions for the given teamId.
     * @param tenantId Id for the tenant.
     * @param teamId Id for the team.
     * @param forceFetchFromNetwork If true, fetches data from network.
     */
    public async getTeamPermissions(tenantId: string, teamId: string, forceFetchFromNetwork?: boolean): Promise<ITeamPermissionEntity> {
        const teamPermissionsDataProvider = new TeamPermissionsDataProvider(this.teamDatabase, tenantId, teamId);
        return this.getData(teamPermissionsDataProvider, forceFetchFromNetwork);
    }

    /**
    * Resets the sync state.
    */
    public async resetSyncState() {
    }
}

const service: ITeamPermissionsDataService = new TeamPermissionsDataService();
export default service;