import { AppSettings } from "sh-application";
import { getDurationInMs } from "sh-application/utility/utility";
import { getGenericEventPropertiesObject, InstrumentationEventPropertyInterface } from "sh-instrumentation";
import { InstrumentationService } from "sh-services";

// The page view logger is a class which keeps track of parameters of a component to measure page views and render performance.
// Each page/component is associated with a PageViewLogger.
export class PageViewLogger {

    // Start time of when the render started
    private renderStartTimeStamp: number;

    // Page name
    private pageName: string;

    // Render count
    private renderCount: number;

    // Tracking when the first page in app is interactive
    // This will flip to true as soon as we've rendered "essential" components to make the first page in the app usable
    // e.g. shifts on the schedule page
    private static isFirstPageInAppInteractive: boolean = false;

    /**
     * Constructor is called when the component/page is first mounted
     * @param pageName The page name
     */
    constructor(pageName: string) {
        this.renderStartTimeStamp = InstrumentationService.getCurrentTimeStamp();
        this.pageName = pageName;
        this.renderCount = 0;
    }

    /**
     * Called when the component has first completely mounted
     * @param {Array} eventDataArray - Array of eventPropertyObjects (each object has key, value, piiKind)
     */
    public onComponentDidMount(eventDataArray: Array<InstrumentationEventPropertyInterface>) {
        let measurements: { [name: string]: number } = {};

        // Instrument the time when the initial page is first rendered with data
        this.instrumentIfAppInteractive(eventDataArray);

        // Page view duration is how long it took to first render the component since the component started loading
        let duration = getDurationInMs(this.renderStartTimeStamp, InstrumentationService.getCurrentTimeStamp());
        InstrumentationService.logPageView(
            this.pageName, // page name
            eventDataArray, // event properties
            measurements, // measurements
            duration   // duration
        );
    }

    /**
     * Called when the component starts updating
     * @param {Array} eventDataArray - Array of eventPropertyObjects (each object has key, value, piiKind)
     */
    public onComponentWillUpdate(eventDataArray: Array<InstrumentationEventPropertyInterface>) {
        this.renderStartTimeStamp = InstrumentationService.getCurrentTimeStamp();
    }

    /**
     * Called when the component has finished rendering after an update
     * @param {Array} eventDataArray - Array of eventPropertyObjects (each object has key, value, piiKind)
     */
    public onComponentDidUpdate(eventDataArray: Array<InstrumentationEventPropertyInterface>) {
        // Render duration is how long it took to render the component since it last updated
        const duration = getDurationInMs(this.renderStartTimeStamp, InstrumentationService.getCurrentTimeStamp());

        // Instrument the time when the initial page is first rendered with data
        this.instrumentIfAppInteractive(eventDataArray);

        // Limit how many renders we instrument so that we don't flood our instrumentation with pages that re-render a lot
        if (this.renderCount < AppSettings.getSetting("InstrumentationMaxRenderCount")) {

            eventDataArray.push(getGenericEventPropertiesObject(InstrumentationService.properties.ScreenName, this.pageName));

            let measurements: { [name: string]: number } = {};
            measurements[InstrumentationService.properties.RenderDuration] = duration;
            measurements[InstrumentationService.properties.RenderCount] = this.renderCount;

            InstrumentationService.logEvent(
                InstrumentationService.events.PageRender, // event name
                eventDataArray, // event properties
                measurements // measurements
            );

        }

        this.renderCount++;
    }

    /**
     * Instrument when the app is first interactive - When the first page is rendered with data available
     * @param {Array} eventDataArray - Array of eventPropertyObjects (each object has key, value, piiKind)
     */
    private instrumentIfAppInteractive(eventDataArray: Array<InstrumentationEventPropertyInterface>) {
        if (!PageViewLogger.isFirstPageInAppInteractive) {
            // check if data is available from the event data array
            let isDataAvailable: boolean = false;
            for (let i = 0; eventDataArray && i < eventDataArray.length; i++) {
                if (eventDataArray[i].key === InstrumentationService.properties.IsDataAvailable) {
                    isDataAvailable = eventDataArray[i].value as boolean;
                    break;
                }
            }

            if (isDataAvailable) {
                // For the first page that's mounted in the app with data, instrument this as an AppInteractive event
                PageViewLogger.isFirstPageInAppInteractive = true;
                InstrumentationService.logAppInteractive(this.pageName, eventDataArray.slice());
            }
        }
    }
}