import { TestDataIDConstant } from "sh-application/../StaffHubConstants";

/**
 * Model utilities
 */
export default class ModelUtils {
    /**
     * Generate UUID with prefix.
     * @param {} prefix
     * @returns {string} UUID with Prefix
     */
    static generateUUIDWithPrefix(prefix: string) {
        return prefix + ModelUtils._generateUUID();
    }

    /**
     * Generate UUID for test data with prefix.
     * @param {} prefix
     * @returns {string} UUID with Prefix
     */
    static generateTestUUIDWithPrefix(prefix: string) {
        return prefix + ModelUtils._generateTestUUID();
    }

    /**
     * Generate new UUID
     * @returns {string} UUID
     */
    private static _generateUUID(): string {
        let d = new Date().getTime();
        let uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function generateUUID$replace(c: string) {
            let r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c == "x" ? r : (r & 0x3 | 0x8)).toString(16);
        });
        return uuid;
    }

    /**
     * Generate new UUID for test data
     * @returns {string} UUID
     */
    private static _generateTestUUID(): string {
        let d = new Date().getTime();
        let uuid = `${TestDataIDConstant}-xxxx-4xxx-yxxx-xxxxxxxxxxxx`.replace(/[xy]/g, function generateUUID$replace(c: string) {
            let r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c == "x" ? r : (r & 0x3 | 0x8)).toString(16);
        });
        return uuid;
    }
}