import ChangeStore from "../store";
import resetChangeStore from "../actions/resetChangeStore";
import { mutator } from "satcheljs";

/**
 * Reset the change store
 */
export default mutator(resetChangeStore, ({ }) => {
    ChangeStore().undoStack.clear();
    ChangeStore().redoStack.clear();
});
