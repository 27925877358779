import * as moment from "moment";
import * as React from "react";
import DateNavigator from "./DateNavigator";
import { clearTrackedChanges } from "sh-change-store";
import { DayOfWeek } from "@fluentui/react";
import { observer } from "mobx-react";
import { SchedulesViewStateStore } from "sh-application/components/schedules/lib/store/schema/SchedulesViewStateStore";
import { transaction } from "mobx";
import { updateScheduleViewDimensions } from "sh-application/components/schedules/lib";

interface ScheduleDateNavigatorProps {
    viewState: SchedulesViewStateStore;

    // Team time zone
    timeZoneOlsonCode: string;
    // Starting day of week to use for the calendar date picker
    firstDayOfWeek?: DayOfWeek;
    navArrowsId?: string;

    // additional classname to add to the date navigator container
    containerClassName?: string;

    // boolean to force month date format
    forceMonthDateFormat?: boolean;

    // On date seelected call back
    onDateChangedCallBack?: () => void;

    // On selection start call back
    onDateSelectionStart?: () => void;

    // On selection end (Date selected or callout dismissed)
    onDateSelectionEnd?: () => void;
}

@observer
export default class ScheduleDateNavigator extends React.Component<ScheduleDateNavigatorProps, {}> {

    /**
     * Call back from DateNavigator to set the date selected by the user.
     */
    private onSelectDateClick = (newDate: moment.Moment, isFromCalendar?: boolean) => {
        transaction(() => {
            clearTrackedChanges();
            updateScheduleViewDimensions(
                this.props.viewState,
                false, /* isTeamBootstrap */
                null, /* employeeViewType */
                null /* scheduleCalendarType*/,  // pass null for values that should not be updated
                null /* scheduleOverViewType*/,
                newDate,
                null /* isViewGrouped */
            );
        });

        if (this.props.onDateChangedCallBack && isFromCalendar) {
            this.props.onDateChangedCallBack();
        }
    }

    render() {
        const { containerClassName, forceMonthDateFormat, navArrowsId, timeZoneOlsonCode, firstDayOfWeek, onDateSelectionStart, onDateSelectionEnd } = this.props;
        const viewState  = this.props.viewState;

        return (
            <DateNavigator
                navArrowsId={ navArrowsId }
                currentSelectedDate={ viewState.viewSelectedDate }
                viewStartDate={ viewState.viewStartDate }
                viewEndDate={ viewState.viewEndDate }
                timeZoneOlsonCode={ timeZoneOlsonCode }
                scheduleCalendarType={ viewState.scheduleCalendarType }
                onSelectedDateChange={ this.onSelectDateClick }
                firstDayOfWeek={ firstDayOfWeek }
                isDatePickerEnabled={ true }
                onDateSelectionStart={ onDateSelectionStart }
                onDateSelectionEnd={ onDateSelectionEnd }
                containerClassName={ containerClassName }
                forceMonthDateFormat={ forceMonthDateFormat } />
        );
    }
}
