import { createStore } from "satcheljs";

import ShiftRequestPanelStoreSchema, { ShiftRequestPanelSelectedItemType } from "./schema/ShiftRequestPanelStoreSchema";

import "../orchestrators/onActionShiftRequest";
import "../orchestrators/checkForShiftRequestUpdate";
import "../orchestrators/onApproveDeclineTimeOffRequest";
import "../orchestrators/onApproveSwapHandoffRequest";
import "../orchestrators/onCreateSwapHandoffRequest";
import "../orchestrators/onCreateTimeOffRequest";
import "../orchestrators/onDeclineSwapHandoffRequest";
import "../orchestrators/onDeleteShiftRequest";
import "../orchestrators/onGetShiftRequests";
import "../orchestrators/onSetErrorMessageFromException";
import "../orchestrators/onSetSelectedRequest";
import "../orchestrators/onSetShiftRequestRead";
import "../orchestrators/onSetShowCreateRequestDialog";

/**
 * --------------------------------------------------------
 * Don't forget to reinitialize these in resetStore mutator
 * --------------------------------------------------------
*/
const initialShiftRequestPanelStore: ShiftRequestPanelStoreSchema = {
    selectedItemType: ShiftRequestPanelSelectedItemType.NotSelected,
    errorMessage: null,
    messageBarShowRefreshButton: false,
    selectedRequestId: null,
    isSaving: false,
    shouldScrollToSelected: false,
    showCreateRequestDialog: false
};

const shiftRequestsPanelStore = createStore("shiftRequestsPanelStore", initialShiftRequestPanelStore);
export default shiftRequestsPanelStore;
