import IUserEntity, { IServiceUserEntity } from "./IUserEntity";
import ShiftrObjectModelBase from 'sh-models/ShiftrObjectModelBase';

export default class UserEntity extends ShiftrObjectModelBase implements IUserEntity {
    userId: string;
    tenantId: string;
    firstName: string;
    lastName: string;
    displayName: string;
    pictureUrl: string;
    thumbnailUrl: string;
    canCreateTeams: boolean;
    isAnonymous: boolean;
    primaryEmail: string;
    phoneNumber: string;
    verifiedPhoneNumber: string;

    constructor(
        id: string,
        eTag: string,
        userId: string,
        tenantId: string,
        firstName: string,
        lastName: string,
        displayName: string,
        pictureUrl: string,
        thumbnailUrl: string,
        canCreateTeams: boolean,
        isAnonymous: boolean,
        primaryEmail: string,
        phoneNumber: string,
        verifiedPhoneNumber: string) {

        super(id || userId, eTag);
        this.userId = userId;
        this.tenantId = tenantId;
        this.firstName = firstName;
        this.lastName = lastName;
        this.displayName = displayName;
        this.pictureUrl = pictureUrl;
        this.thumbnailUrl = thumbnailUrl;
        this.canCreateTeams = canCreateTeams;
        this.isAnonymous = isAnonymous;
        this.primaryEmail = primaryEmail;
        this.phoneNumber = phoneNumber;
        this.verifiedPhoneNumber = verifiedPhoneNumber;
    }

    public static fromJson(jsonData: IServiceUserEntity): IUserEntity {
        if (!jsonData) {
            return null;
        }
        return new UserEntity(
            jsonData.userId,
            jsonData.eTag,
            jsonData.userId,
            jsonData.tenantId,
            jsonData.firstName,
            jsonData.lastName,
            jsonData.displayName,
            jsonData.pictureUrl,
            jsonData.thumbnailUrl,
            jsonData.canCreateTeams,
            jsonData.isAnonymous,
            jsonData.primaryEmail,
            jsonData.phoneNumber,
            jsonData.verifiedPhoneNumber
        );
    }

    public static toJson(userEntity: IUserEntity): IUserEntity {
        return {...userEntity};
    }
}