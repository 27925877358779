import RestClient from "sh-rest-client";
import UniqueSubshiftStore from "../store";
import { action } from "satcheljs/lib/legacy";
import { IUniqueSubshiftEntity } from "sh-models";
import { setUniqueSubshifts } from "./setUniqueSubshifts";
import { InstrumentationService } from "sh-services";

/**
 * getUniqueSubshifts action - gets Subshifts
 *
 * @param {string} tenantId
 * @param {string} teamId
 * @param {Moment} startDate
 * @param {Moment} endDate
 * @returns {Promise}
 */
export let getUniqueSubshifts = action("getUniqueSubshifts")(
    async function getUniqueSubshifts(tenantId: string, teamId: string, count?: number) {
            let marker = "getUniqueSubshifts";
            marker = InstrumentationService.perfMarkerStart(marker);

            try {
                // these only need to be fetched once.  If there in store, resolve with what's in store
                if (UniqueSubshiftStore().hasFetchedUniqueSubshifts.get()) {
                    return UniqueSubshiftStore().uniqueSubshifts;
                } else {
                    const uniqueSubshifts: Array<IUniqueSubshiftEntity> = await RestClient.getUniqueSubshifts(tenantId, teamId);
                    // set unique Subshifts
                    setUniqueSubshifts(uniqueSubshifts);
                    return uniqueSubshifts;
                }
            } finally {
                InstrumentationService.perfMarkerEnd(marker);
            }
    });