import * as React from "react";
import { Icon } from "@fluentui/react";
import { ShiftRequestState, ShiftRequestStates } from "sh-models";
import StringsStore from "sh-strings/store";

const styles = require("./ShiftRequestStateIndicator.scss");
const classNames = require("classnames/bind");

export interface ShiftRequestStateIndicatorProps {
    /* The status of the response.  Shows at the top of the column. */
    status?: string;
    /* The shift request state*/
    shiftRequestState: ShiftRequestState;
}

/**
 * Component used by shift request to show the status of the request to the user
 */
export default class ShiftRequestStateIndicator extends React.Component<
    ShiftRequestStateIndicatorProps,
    {}
> {
    render() {
        const strings: Map<string, string> = StringsStore().registeredStringModules.get("shiftRequests").strings;
        const { status, shiftRequestState } = this.props;

        let statusIcon: string = null;
        let statusIconClass: string = null;
        let defaultString = "";

        switch (shiftRequestState) {
            case ShiftRequestStates.WaitingOnManager:
            case ShiftRequestStates.WaitingOnHomeManager:
            case ShiftRequestStates.WaitingOnReceiver:
                statusIcon = "teams-clock-pending";
                break;
            case ShiftRequestStates.ReceiverDeclined:
            case ShiftRequestStates.SenderDeclined:
            case ShiftRequestStates.ManagerDeclined:
            case ShiftRequestStates.AutoDeclined:
            case ShiftRequestStates.HomeManagerDeclined:
                statusIcon = "teams-cross";
                statusIconClass = styles.red;
                defaultString = strings.get("declinedStatus").format("");
                break;
            case ShiftRequestStates.ManagerApproved:
            case ShiftRequestStates.AnotherApproved:
                statusIcon = "teams-check";
                statusIconClass = styles.green;
                defaultString = strings.get("acceptedStatus").format("");
                break;
            default:
                break;
        }

        // Check if the status is accepted and show green check even if the shiftRequestState is pending
        // TODO: find a way to rework the ResponseDetailsForRequest.tsx to be able to send the correct shiftRequestState
        if (status?.includes(strings.get("acceptedStatus").format(""))) {
            statusIcon = "teams-check";
            statusIconClass = styles.green;
        }

        return (
            <div className={styles.indicatorContianer}>
                {statusIcon && (
                    <Icon
                        iconName={statusIcon}
                        className={classNames(styles.statusIcon, statusIconClass)}
                    />
                )}
                <span className={styles.status}>{status ? status : defaultString}</span>
            </div>
        );
    }
}
