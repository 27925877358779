import { TestingTeamIdSuffix } from 'sh-application/../StaffHubConstants';
/**
 * Test utilities
 */

export default class TestUtils {
    /**
     * Generate UUID with prefix.
     * @param {} prefix
     * @returns {string} UUID with Prefix
     */
    static generateTestUUIDWithPrefix(prefix: string) {
        return prefix + TestUtils._generateTestUUID();
    }

    /**
     * Generate new UUID
     * @returns {string} UUID
     */
    private static _generateTestUUID(): string {
        let d = new Date().getTime();
        let uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function generateUUID$replace(c: string) {
            let r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c == "x" ? r : (r & 0x3 | 0x8)).toString(16);
        });
        return uuid = uuid.substr( 0, uuid.length - TestingTeamIdSuffix.length ) + TestingTeamIdSuffix;
    }
}