import confirm from "sh-confirm-dialog/lib/actions/confirm";
import StringsStore from "sh-strings/store";
import { ECSConfigKey, ECSConfigService } from "sh-services";
import { trace } from "owa-trace";

class ClientVersionService {
    private _version: string = null;
    private _isUpdatePending: boolean = false;

    /**
     * Refreshes the site content silently.
     * @returns {}
     */
    private refreshApp() {
        // TODO - need to create confirm dialog that looks like the one we use in the Angular site
        const strings = StringsStore().registeredStringModules.get("clientVersionService").strings;
        confirm(
            strings.get("title"),
            strings.get("message"),
            false,
            {
                hideCancelButton: true
            })
            .then(() => {
                // don't use window.location.reload because that breaks out the iframe on Edge
                window.location.replace(window.location.href);
            })
            .catch(() => {
                trace.error("It should not have been possible to cancel the app refresh dialog");
                // don't use window.location.reload because that breaks out the iframe on Edge
                window.location.replace(window.location.href);
            });
    }

    /**
     * Returns if an update is pending
     */
    public isUpdatePending(): boolean {
        return this._isUpdatePending;
    }

    /**
     * We get notified here when ECS configs have been updated
     */
    public onBuildVersionUpdated() {
        const latestVersion = ECSConfigService.getECSFeatureSetting(ECSConfigKey.BuildVersion);
        if (latestVersion && this._version && latestVersion !== this._version) {
            // Detected a version update
            // Wait 5 minutes to notify the user about the update because the service may have an older cache of the ECS configs that still
            // points to the old app version cached
            const buildUpdateNotificationDelayMs = ECSConfigService.getECSFeatureSetting(ECSConfigKey.BuildUpdateNotificationDelayMs);
            setTimeout(() => { this.notifyUserAboutUpdate(latestVersion); }, buildUpdateNotificationDelayMs);
        }
    }

    /**
     * Notify the user that a new buildVersion is available
     * @param latestVersion The latest build version
     */
    private notifyUserAboutUpdate(latestVersion: string) {
        this._version = latestVersion;
        if (!this._isUpdatePending) {
            this._isUpdatePending = true;
            this.refreshApp();
        }
    }

    /**
     * Get and cache the client version.
     * @returns {}
     */
    public init(appVersion: string) {
        this._version = appVersion;
    }
}

const service = new ClientVersionService();
export default service as ClientVersionService;