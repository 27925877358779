import sleep from "./utils/sleep";
import { runAfterInitialRender } from "owa-delayed-initialization";
import { InstrumentationService } from "sh-services";

export const MAX_ATTEMPTS = 5;
export const INITIAL_DELAY = 1000;

// For usage of this API, see the owa-bundling README
export default class LazyModule<TModule> {
    private promise: Promise<TModule>;
    private isLoaded = false;
    private attempts = 0;

    constructor(private importCallback: () => Promise<TModule>) {}

    public import(): Promise<TModule> {
        if (!this.promise) {
            this.promise = new Promise<TModule>((resolve, reject) => {
                // Don't load any lazy resources until the initial render is complete
                runAfterInitialRender(async () => {
                    // We'll retry several times if the load fails
                    this.attempts = 0;
                    while (!this.isLoaded && this.attempts < MAX_ATTEMPTS) {
                        try {
                            this.attempts++;
                            await this.loadModule(resolve);
                        } catch (error) {
                            await this.onLoadFailed(error, reject);
                        }
                    }
                });
            });
        }

        return this.promise;
    }

    private async loadModule(resolve: (value: TModule) => void) {
        let lazyLoadMarker = "lazyLoadModule";
        lazyLoadMarker = InstrumentationService.perfMarkerStart(lazyLoadMarker);
        resolve(await this.importCallback());
        InstrumentationService.perfMarkerEnd(lazyLoadMarker);
        this.isLoaded = true;
    }

    private async onLoadFailed(error: any, reject: (reason: any) => void) {
        if (this.attempts >= MAX_ATTEMPTS) {
            // After MAX_ATTEMPTS, just fail
            let newError: any = new Error('Failed to load LazyModule');
            newError.knownError = true;
            reject(newError);

            // Reset the cached promise so that we'll retry again the next time
            // import is attempted
            this.promise = null;
        } else {
            // Delay before retrying
            await sleep(INITIAL_DELAY * Math.pow(2, this.attempts - 1));
        }
    }
}
