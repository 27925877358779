import { action } from "satcheljs/lib/legacy";
import { IBaseShiftEntity, ShiftTypes, UniqueShiftEntity } from "sh-models";
import { prependUniqueShift } from "./prependUniqueShift";

/**
 * Convert shifts to UniqueShifts and prepend them to the list of UniqueShifts
 * @param shifts
 */
export let prependShiftsAsUniqueShifts = action("prependShiftsAsUniqueShifts")(
    function prependShiftsAsUniqueShifts(shifts: IBaseShiftEntity[]) {
        shifts.forEach((shift: IBaseShiftEntity) => {
            if (shift.shiftType === ShiftTypes.Working) {
                prependUniqueShift(UniqueShiftEntity.fromShift(shift));
            }
        });
    });