import * as React from "react";
import accessibilityAlertViewStateStore from "../store/store";
import { AccessibilityAlertViewStateStore } from "../store/schema/AccessibilityAlertViewStateStore";
import { AriaProperties, AriaRoles, generateDomPropertiesForAria } from "owa-accessibility";
import { computed } from "mobx";
import { ObservableMap } from "mobx";
import { observer } from "mobx-react";

const styles = require("./AccessibilityAlert.scss");

/**
 * Component for rendering accessibility alert messages
 *
 * Accessibility alert messages are hidden visually, but will be detected and read aloud by screen readers.
 */
@observer
export default class AccessibilityAlert extends React.Component<{}, {}> {

    /**
     * Gets the viewstate store
     */
    @computed
    private get viewState(): AccessibilityAlertViewStateStore {
        return accessibilityAlertViewStateStore();
    }

    /**
     * Gets the viewstate store
     */
    private renderAlerts(alertMessages: ObservableMap<string, string>): JSX.Element[] {
        const alertAriaMessages: JSX.Element[] = [];
        alertMessages.forEach((alertText: string, alertId: string) => {
            if (alertId) {
                alertAriaMessages.push(
                    <div key={ alertId }>
                        { alertText }
                    </div>
                );
            }
        });
        return alertAriaMessages;
    }

    render() {
        const ariaProps: AriaProperties = {
            role: AriaRoles.alert,
            live: "assertive",
            atomic: false // it should be false by default, but messages somehow get repeated sometimes without this line
        };

        return (
            <div className={ styles.accessibilityAlert } { ...generateDomPropertiesForAria(ariaProps) }>
                { this.renderAlerts(this.viewState.alertMessages) }
            </div>
        );
    }
}
