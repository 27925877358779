import { action } from "satcheljs";
import { StaffHubHttpError } from '../utility/StaffHubError';

/**
 *  Action to set the message to display in the global message bar based on the exception thrown
 */
const setGlobalMessageFromException = action(
    "SET_GLOBAL_MESSAGE_FROM_EXCEPTION",
    (
        exception: StaffHubHttpError,
        autoDismiss: boolean,
        showRefreshAction: boolean = false // This is to force showing the refresh action used for scenarios like sync. Typically the actions should be shown based on exception type
    ) => ({
        exception: exception,
        autoDismiss: autoDismiss,
        showRefreshAction: showRefreshAction
    })
);

export default setGlobalMessageFromException;
