import AdminPermissionChecker from "sh-application/utility/AdminPermissionChecker";
import setShowWelcomeState from "../mutatorActions/setShowWelcomeState";
import { appViewState, closeTeamSetupPicker, launchTeamSetupPicker } from "sh-application/store";
import { getGenericEventPropertiesObject } from "sh-instrumentation";
import { InstrumentationService } from "sh-services";
import { orchestrator } from "satcheljs";
import { setFREsShownSoFar } from "sh-stores";
import { showWelcome } from "sh-application";
import { TeamInfoEntity, UserFRETypes } from "sh-models";
import { TeamSetupPickerMode } from "sh-application/components/teamsetuppicker";
import { TeamStore } from "sh-team-store";

/**
 * Shows or hides the Welcome to StaffHub UX
 */
export default orchestrator(showWelcome, async actionMessage => {
    const { visible } = actionMessage;
    // don't show welcome dialog to non-admin users
    if (!AdminPermissionChecker.isCurrentUserAdmin()) {
        return;
    }

    // TODO DCoh: The old Tour Welcome popup has been replaced with the Welcome UX from the TeamSetupPicker below.
    // Look into cleaning up the Welcome state handling if possible.
    setShowWelcomeState(visible);

    if (visible) {
        launchTeamSetupPicker(
            appViewState().teamSetupPickerViewState,
            false /* isOnboarding */,
            TeamSetupPickerMode.NewTeamWelcome,
            false /* isUserDismissable */,  // Prevent the user from accidentally cancelling out of the dialog
            false /* useTeamPickerPanel */);

        // mark the FRE as seen
        const teamType = TeamInfoEntity.getTeamType(TeamStore().team);
        InstrumentationService.logEvent(InstrumentationService.events.FREWelcomeShown,
            [getGenericEventPropertiesObject(InstrumentationService.properties.TeamType, teamType)]);
        setFREsShownSoFar([UserFRETypes.WelcomeToShifts]);
    } else {
        closeTeamSetupPicker(appViewState().teamSetupPickerViewState);
    }
});