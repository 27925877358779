import resetTeamStore from "../actions/resetTeamStore";
import TeamStore from "../store";
import { mutator } from "satcheljs";

/**
 * Reset the team store
 */
export default mutator(resetTeamStore, ({ }) => {
    TeamStore().teamId = null;
    TeamStore().groupId = null;
    TeamStore().tenantId = null;
    TeamStore().team = null;
    TeamStore().name = null;
    TeamStore().members.clear();
    TeamStore().roles.clear();
    TeamStore().adminRoleId = "";
    TeamStore().me = null;
});