import * as moment from "moment";
import ModelUtils from "../ModelUtils";
import {
    BreakType,
    BreakTypes,
    IBaseBreakEntity,
    IBreakEntity
    } from "./IBreakEntity";
import { Moment } from "moment";

const breakIdPrefix = "SBRK_";

/**
 * Entity class that represents Break model
 */
export default class BreakEntity implements IBreakEntity {
    id: string;
    duration: number;
    breakType: BreakType;
    startTime: Moment;

    constructor(id: string, duration: number, breakType: BreakType, startTime: Moment = null) {
        this.id = id || BreakEntity.generateNewBreakId();
        this.duration = duration;
        this.breakType = breakType;
        this.startTime = startTime && moment(startTime);
    }

    /**
     * Generate new break ID used by Shiftr App
     * @returns {string} breakId in form of SBRK_<uuid>
     */
    static generateNewBreakId(): string {
        return ModelUtils.generateUUIDWithPrefix(breakIdPrefix);
    }

    private static createBreak(durationInMin: number, breakType: BreakType): IBreakEntity {
        return new BreakEntity(
            BreakEntity.generateNewBreakId(),
            durationInMin || 0,
            breakType
        );
    }

    /**
     * Clones a client BreakEntity
     * @param breakEntity - client break entity
     */
    static clone(breakEntity: IBreakEntity): IBreakEntity {
        return new BreakEntity(
            breakEntity.id,
            breakEntity.duration,
            breakEntity.breakType,
            breakEntity.startTime && moment(breakEntity.startTime)
        );
    }

    /**
     * Function that converts json from service/db response to BreakEntity
     * @param jsonData - response from service
     */
    static fromJson(jsonData: IBaseBreakEntity<string | number>): IBreakEntity {
        return new BreakEntity(
            jsonData.id,
            jsonData.duration,
            jsonData.breakType,
            jsonData.startTime && moment(jsonData.startTime)
        );
    }

    /**
     * Function that converts a list of service/db json responses to client BreakEntity
     * @param jsonDataList - list of service/db entities
     */
    static fromJsonList(jsonDataList: IBaseBreakEntity<string | number>[]): IBreakEntity[] {
        const breaks: IBreakEntity[] = [];
        if (jsonDataList && jsonDataList.length) {
            for (let i = 0; i < jsonDataList.length; i++) {
                breaks.push(BreakEntity.fromJson(jsonDataList[i]));
            }
        }
        return breaks;
    }

    /**
     * Function that creates service JSON from BreakEntity
     * @param breakEntity - break entity that needs to be sent over the wire
     */
    static toJson(breakEntity: IBreakEntity): IBaseBreakEntity<string> {
        if (!breakEntity) {
            return null;
        }

        return {
            id: breakEntity.id,
            duration: breakEntity.duration,
            breakType: breakEntity.breakType,
            startTime: breakEntity.startTime && breakEntity.startTime.toISOString && breakEntity.startTime.toISOString() // Service uses a ISO string format
        };
    }

    /**
     * Function that creates db IBreakEntity<number> from client IBreakEntity<Moment>
     * @param shift - shift client entity
     */
    static toDbModel(breakEntity: IBreakEntity): IBaseBreakEntity<number> {
        if (!breakEntity) {
            return null;
        }

        return {
            id: breakEntity.id,
            duration: breakEntity.duration,
            breakType: breakEntity.breakType,
            startTime: breakEntity.startTime && breakEntity.startTime.valueOf && breakEntity.startTime.valueOf() // Db uses a numeric timestamp format
        };
    }

    static createUnpaidBreak(durationInMin: number): IBreakEntity {
        return BreakEntity.createBreak(durationInMin, BreakTypes.Unpaid);
    }

    static createPaidBreak(durationInMin: number): IBreakEntity {
        return BreakEntity.createBreak(durationInMin, BreakTypes.Paid);
    }
}