import { createStore } from "satcheljs";
import { IGroupedOpenShiftRequestEntity, IShiftRequestEntity } from "sh-models";
import { ObservableMap } from "mobx";
import { ShiftRequestStoreSchema } from "./schema/ShiftRequestStoreSchema";

// Register orchestrators
import "../orchestrators/onUpdateShiftRequestsInStore";

// Register Mutators
import "../mutators/resetShiftRequestsStoreMutator";
import "../mutators/updateRequestsNextCursorInStoreMutator";
import "../mutators/updateUnreadRequestsCountInStoreMutator";

/**
 * Store for ShiftRequests
 */
let shiftRequestStore = createStore<ShiftRequestStoreSchema>(
    "shiftRequestStore",
    {
        shiftRequests: new ObservableMap<string, IShiftRequestEntity>(),
        groupedOpenShiftRequests: new ObservableMap<string, IGroupedOpenShiftRequestEntity>(),
        unreadRequestsCount: 0,
        nextCursor: "",
        areAllActiveRequestsLoaded: false,
        deepLinkRequestLoaded: false
    });

export default shiftRequestStore;