import ITimeClockDataService from "./ITimeClockDataService";
import RestClient from "sh-rest-client";
import { DataService } from "./DataService";
import { ITimeClockEntity } from "sh-models";
import { TimeClockLatestEntryDataProvider } from "../dataproviders/TimeClockLatestEntryDataProvider";

class TimeClockDataService extends DataService implements ITimeClockDataService {

    /**
     * Gets the latest time clock entry for a member
     */
    public async getLatestTimeClockEntryForMember(teamId: string, forceFetchFromNetwork?: boolean): Promise<ITimeClockEntity> {
        const timeClockDataProvider = new TimeClockLatestEntryDataProvider(this.teamDatabase, teamId);
        return this.getData(timeClockDataProvider, forceFetchFromNetwork);
    }

    /**
     * Clocks in a member
     * @param isAtApprovedLocation - if user is clocking in from an approved location
     * @param teamId - id for the team the member belongs to
     */
    public async clockIn(isAtApprovedLocation: boolean, teamId: string): Promise<ITimeClockEntity> {
        const clockInResponse = await RestClient.clockIn(isAtApprovedLocation, teamId);
        const timeClockDataProvider = new TimeClockLatestEntryDataProvider(this.teamDatabase, teamId);
        this.updateDataInCache(clockInResponse, timeClockDataProvider);
        return clockInResponse;
    }

    /**
    * Clocks out a member
    * @param isAtApprovedLocation - if user is clocking out from an approved location
    * @param teamId - id for the team the member belongs to
    * @param timeClockId - id for the time clock entry that is being updated
    */
    public async clockOut(isAtApprovedLocation: boolean, teamId: string, timeClockId: string): Promise<ITimeClockEntity> {
        const clockOutResponse = await RestClient.clockOut(isAtApprovedLocation, teamId, timeClockId);
        const timeClockDataProvider = new TimeClockLatestEntryDataProvider(this.teamDatabase, teamId);
        this.updateDataInCache(clockOutResponse, timeClockDataProvider);
        return clockOutResponse;
    }

    /**
    * Starts a break for a clocked in member
    * @param isAtApprovedLocation - if user is starting break from an approved location
    * @param teamId - id for the team the member belongs to
    * @param timeClockId - id for the time clock entry that is being updated
    */
    public async startBreak(isAtApprovedLocation: boolean, teamId: string, timeClockId: string): Promise<ITimeClockEntity> {
        const startBreakResponse = await RestClient.startBreak(isAtApprovedLocation, teamId, timeClockId);
        const timeClockDataProvider = new TimeClockLatestEntryDataProvider(this.teamDatabase, teamId);
        this.updateDataInCache(startBreakResponse, timeClockDataProvider);
        return startBreakResponse;
    }

    /**
    * Ends break for a clocked in member
    * @param isAtApprovedLocation - if user is ending break from an approved location
    * @param teamId - id for the team the member belongs to
    * @param timeClockId - id for the time clock entry that is being updated
    * @returns {Promise} Promise that resolves to timeclock entity
    */
    public async endBreak(isAtApprovedLocation: boolean, teamId: string, timeClockId: string): Promise<ITimeClockEntity> {
        const endBreakResponse = await RestClient.endBreak(isAtApprovedLocation, teamId, timeClockId);
        const timeClockDataProvider = new TimeClockLatestEntryDataProvider(this.teamDatabase, teamId);
        this.updateDataInCache(endBreakResponse, timeClockDataProvider);
        return endBreakResponse;

    }

    /**
    * Updates or adds a time clock entry if is passed through sync service
    * @param timeClockEntityToAddOrUpdate - time clock entity to add or update
    */
    public async updateLatestTimeClockEntry(timeClockEntityToAddOrUpdate: ITimeClockEntity, teamId: string) {
        const timeClockDataProvider = new TimeClockLatestEntryDataProvider(this.teamDatabase, teamId );
        this.updateDataInCache(timeClockEntityToAddOrUpdate, timeClockDataProvider);
    }

    /**
    * Resets the sync state
    */
    public async resetSyncState() {
    }
}

const service: ITimeClockDataService = new TimeClockDataService();
export default service;