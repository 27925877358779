import ModelUtils from "../ModelUtils";
import ShiftrObjectModelBase from "../ShiftrObjectModelBase";
import IRoleEntity from "sh-models/role/IRoleEntity";
const roleIdPrefix = "ROLE_";

/**
 * Base class that sets up common aspects for the Role client and service models
 */
export default class RoleEntity extends ShiftrObjectModelBase implements IRoleEntity {
    // id
    // etag
    name: string;
    isAdmin: boolean;
    state: string;
    tenantId: string;
    teamId: string;

    constructor(
        id: string,
        eTag: string,
        name: string,
        isAdmin: boolean,
        state: string,
        tenantId: string,
        teamId: string) {

        let modelId = id || RoleEntity.generateNewRoleId();
        super(modelId, eTag);

        this.name = name;
        this.isAdmin = isAdmin;
        this.state = state;
        this.tenantId = tenantId;
        this.teamId = teamId;
    }

    /**
     * Generate new roleId used by StaffHub App
     * @returns {string} roleId in form of ROLE_<uuid>
     */
    static generateNewRoleId(): string {
        return ModelUtils.generateUUIDWithPrefix(roleIdPrefix);
    }

    /**
     * Function that converts json from service response to RoleEntity
     * @param jsonData - response from service
     */
    static fromJson(jsonData: any): RoleEntity {
        if (!jsonData) {
            return null;
        }

        return new RoleEntity(
            jsonData.id,
            jsonData.eTag,
            jsonData.name,
            jsonData.isAdmin,
            jsonData.state,
            jsonData.tenantId,
            jsonData.teamId);
    }

    /**
     * Function that creates service JSON from RoleEntity
     * @param roleEntity - role entity that needs to be sent over the wire
     */
    static toJson(role: IRoleEntity): any {
        if (!role) {
            return null;
        }

        // skipping sharedChanges here as it is read-only
        const roleJson = {
            id: role.id,
            eTag: role.eTag,
            name: role.name,
            isAdmin: role.isAdmin,
            state: role.state,
            tenantId: role.tenantId,
            teamId: role.teamId
        };

        return roleJson;
    }

}