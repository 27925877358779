import * as moment from "moment";
import timeOffInputStore from "../store/store";
import { mutatorAction } from "satcheljs";

export default mutatorAction('RESET_TIMEOFF_INPUT_STORE', function resetTimeOffInputStore() {

    timeOffInputStore.startDate = moment();
    timeOffInputStore.endDate = moment();
    timeOffInputStore.startTime = moment();
    timeOffInputStore.endTime = moment();
    timeOffInputStore.isAllDay = false;
    timeOffInputStore.timeOffReasonId = "";
    timeOffInputStore.notes = "";
    timeOffInputStore.member = null;
    timeOffInputStore.groupTagId = null;
    timeOffInputStore.theme = null;

});
