import MemberUtils from "sh-application/utility/MemberUtils";
import { TeamStore } from "../../sh-team-store";
import { calculateRequestConflicts, fetchAndCompareShiftsForConflicts } from "..";
import { DataProcessingHelpers, ECSConfigKey, ECSConfigService } from "sh-services";
import { IGroupedOpenShiftRequestEntity, IShiftRequestEntity, ShiftRequestTypes } from "sh-models";
import { orchestrator } from "satcheljs";

/**
 * This orchestrator will do the request conflict calculations
 */
export default orchestrator(calculateRequestConflicts, async actionMessage => {
    const { shiftRequest, isOpenShiftRequest } = actionMessage;
    const isShiftRequestConflictsEnabled = ECSConfigService.isECSFeatureEnabled(ECSConfigKey.EnableShiftRequestConflicts);
    const isConflictManagementEnabled = ECSConfigService.isECSFeatureEnabled(ECSConfigKey.EnableConflictManagement);
    const currentUser = TeamStore() && TeamStore().me;

    if (isShiftRequestConflictsEnabled && isConflictManagementEnabled && shiftRequest && MemberUtils.isAdmin(currentUser)) {
        if (isOpenShiftRequest) {
            const groupedOpenShiftRequest = shiftRequest as IGroupedOpenShiftRequestEntity;
            const openShiftRequestList = DataProcessingHelpers.getArrayFromMap(groupedOpenShiftRequest.shiftRequests);
            openShiftRequestList.forEach(async (openShiftRequest: IShiftRequestEntity) => {

                // for every open shift request fetch the shifts and do the conflict calcuations
                fetchAndCompareShiftsForConflicts(openShiftRequest, openShiftRequest.shiftId, openShiftRequest.senderMemberId);
            });
        } else {
            const shiftRequestEntity = shiftRequest as IShiftRequestEntity;
            const isSwapRequest = shiftRequestEntity.requestType === ShiftRequestTypes.Swap;
            const isHandOffRequest = shiftRequestEntity.requestType === ShiftRequestTypes.HandOff;

            if (isSwapRequest) {
                // for every shift request fetch the shifts and do the conflict calcuations for swapping shift
                fetchAndCompareShiftsForConflicts(shiftRequestEntity, shiftRequestEntity.shiftId, shiftRequestEntity.receiverMemberId);
                fetchAndCompareShiftsForConflicts(shiftRequestEntity, shiftRequestEntity.otherShiftInfo.shiftId, shiftRequestEntity.senderMemberId);

            } else if (isHandOffRequest) {
                // for handoff request compare receiver member shfits with current shift request for conflict
                fetchAndCompareShiftsForConflicts(shiftRequestEntity, shiftRequestEntity.shiftId, shiftRequestEntity.receiverMemberId);

            } else {
                // for every shift request fetch the shifts and do the conflict calcuations
                fetchAndCompareShiftsForConflicts(shiftRequestEntity, shiftRequestEntity.shiftId, shiftRequestEntity.senderMemberId);
            }
        }
    }
});