import { InstrumentationEventPropertyInterface } from "sh-instrumentation";
import { PageViewLogger } from "./PageViewLogger";
import { trace } from "owa-trace";

/**
 * Decorator to log page view and render performance.
 * This is a class decorator, which is applied to the constructor of the class.
 * More on decorators : https://www.typescriptlang.org/docs/handbook/decorators.html
 * Usage to log a page view:
 *  1. Add the logPageView decorator above the class declaration:
 *          @logPageView(InstrumentationService.pageNames.SchedulesPage)
 *          class Schedules extends React.Component<{}, {}> {
 *  2. Implement the public getPageViewInstrumentationData(): Array<InstrumentationEventPropertyInterface> to return custom properties
 */
export function logPageView(pageName: string) {
    return (target: any) => {

        let original = target;
        let instance: any;

        // a utility function to generate instances of a class
        function constructClassWithPageViewLogger(oldConstructor: Function, args: any) {
            let classConstructor: any = function() {
                return oldConstructor.apply(this, args);
            };
            classConstructor.prototype = oldConstructor.prototype;
            instance = new classConstructor();
            instance.pageViewLogger = new PageViewLogger(pageName);
            return instance;
        }

        // the new constructor behaviour
        let newConstructor: any = function(...args: any[]) {
            return constructClassWithPageViewLogger(original, args);
        };

        let originalGetPageViewInstrumentationData = original.prototype.getPageViewInstrumentationData;
        let originalComponentDidMount = original.prototype.componentDidMount;
        let originalComponentWillUpdate = original.prototype.UNSAFE_componentWillUpdate;
        let originalComponentDidUpdate = original.prototype.componentDidUpdate;

        if (!originalGetPageViewInstrumentationData) {
            trace.warn('PageViewLogger: The ' + pageName + ' has not implemented any custom getPageViewInstrumentationData method');
        }

        // copy prototype so intanceof operator still works
        newConstructor.prototype = original.prototype;

        newConstructor.prototype.componentDidMount = function() {
            let returnValue;
            if (originalComponentDidMount) {
                returnValue = originalComponentDidMount.apply(instance);
            }
            if (this.pageViewLogger) {
                this.pageViewLogger.onComponentDidMount(this.getPageViewInstrumentationData());
            }

            return returnValue;
        };

        newConstructor.prototype.getPageViewInstrumentationData = function(): Array<InstrumentationEventPropertyInterface> {
            let eventDataArray: Array<InstrumentationEventPropertyInterface> = [];
            if (originalGetPageViewInstrumentationData) {
                eventDataArray = originalGetPageViewInstrumentationData.apply(instance);
            }
            return eventDataArray;
        };

        newConstructor.prototype.UNSAFE_componentWillUpdate = function() {
            let returnValue;
            if (originalComponentWillUpdate) {
                returnValue = originalComponentWillUpdate.apply(instance);
            }

            if (this.pageViewLogger) {
                this.pageViewLogger.onComponentWillUpdate(this.getPageViewInstrumentationData());
            }

            return returnValue;
        };

        newConstructor.prototype.componentDidUpdate = function() {
            let returnValue;
            if (originalComponentDidUpdate) {
                returnValue = originalComponentDidUpdate.apply(instance);
            }

            if (this.pageViewLogger) {
                this.pageViewLogger.onComponentDidUpdate(this.getPageViewInstrumentationData());
            }

            return returnValue;
        };

        // return new constructor (will override original)
        return newConstructor;
    };
}
