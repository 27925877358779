import DateUtils from "sh-application/utility/DateUtils";
import RestClient from "sh-rest-client";
import setGlobalMessages from "sh-application/actions/setGlobalMessages";
import StringsStore from "sh-strings/store";
import { appViewState } from "sh-application/store";
import { DataProvider } from "sh-services/dataproviders/DataProvider";
import { DEFAULT_MAX_PAGES } from "../dataproviders/DataInDateRangeDataProvider";
import {
    DismissedConflictsStore,
    resetDismissedConflictStore,
    setDismissConflicts,
    setDismissedConflictStoreDateRange
    } from "sh-conflict-store";
import { ECSConfigKey, ECSConfigService } from "..";
import { IConflictDismissEntity, IConflictDismissResponseEntity } from "sh-models";
import { ITeamDatabase } from "../data/ITeamDatabase";
import { MessageBarType } from "@fluentui/react";
import { Moment } from "moment";

/**
 * Conflict Dismissals Data Provider
 */
export class ConflictDismissalsDataProvider extends DataProvider<IConflictDismissEntity[]> {

    protected teamDataBase: ITeamDatabase;
    protected teamId: string;
    protected startDate: Moment;
    protected endDate: Moment;

    constructor(teamDataBase: ITeamDatabase, teamId: string, startDate: Moment, endDate: Moment) {
        super();
        this.teamDataBase = teamDataBase;
        this.teamId = teamId;
        this.startDate = startDate;
        this.endDate = endDate;
    }

    /**
     * Return data if it's found in memory (otherwise return undefined)
     */
    async getDataFromMemory() {
        if (DismissedConflictsStore().fetchStartTime === null || DismissedConflictsStore().fetchEndTime === null) {
            return undefined;
        }

        // check if the requested provider range is within our memory cache range (the fetched range)
        const conflictInRange = DateUtils.overlapsStartsOrEndsBetween(this.startDate, this.endDate, DismissedConflictsStore().fetchStartTime, DismissedConflictsStore().fetchEndTime, true, true);
        if (conflictInRange) {
            // as long as the requested range is within the range of data we have in cache, we return everything from the cache
            const conflictDismissalEntities: IConflictDismissEntity[] = (DismissedConflictsStore() && DismissedConflictsStore().entityIdToDismissedConflictEntities) ? Array.from(DismissedConflictsStore() && DismissedConflictsStore().entityIdToDismissedConflictEntities.values()) : [];
            return conflictDismissalEntities;
        }

        return undefined;
    }

    /**
     * Return data if it's found in the database (otherwise return undefined)
     */
    async getDataFromDatabase() {
        return await this.teamDataBase.getDismissedConflicts(this.teamId, this.startDate, this.endDate);
    }

    /**
     * Make an HTTP request to fetch the data from the network
     */
    async getDataFromNetwork(): Promise<IConflictDismissEntity[]> {
        if (!this.teamId || !this.startDate || !this.endDate) {
            return null;
        }
        const firstResponse = await RestClient.getDismissedConflicts(this.teamId, this.startDate, this.endDate);

        let totalDismissedConflicts = (firstResponse) ? firstResponse.conflictDismissals : null;
        let nextPageCursor = (firstResponse) ? firstResponse.nextLink : null;
        // use the same default as for shift data pages, as the dismissed entities need to be fetched for all the shifts that has been fetched
        const maxPages: number = ECSConfigService.getECSFeatureSetting(ECSConfigKey.GDIDRMaxPages) || DEFAULT_MAX_PAGES;
        // Already fetched one page, get next maxPages-1 pages as long as we have nextLink in response
        for (let currentPageCount: number = 1; currentPageCount < maxPages && !!nextPageCursor; currentPageCount++) {
            let responseInDateRange: IConflictDismissResponseEntity = null;

            responseInDateRange = await RestClient.getDismissedConflicts(this.teamId, this.startDate, this.endDate, nextPageCursor);

            nextPageCursor = null;
            if (responseInDateRange) {
                nextPageCursor = responseInDateRange.nextLink;

                if (responseInDateRange.conflictDismissals) {
                    totalDismissedConflicts = totalDismissedConflicts.concat(responseInDateRange.conflictDismissals);
                }
            }
        }
        // This is means the there are more dismissed entities than the default max we support for shifts, show error message
        if (nextPageCursor) {
            setGlobalMessages(appViewState().globalMessageViewState, [StringsStore().registeredStringModules.get("common").strings.get("errorLoadingScheduleData")], MessageBarType.error);
        }
        return totalDismissedConflicts;
    }

    /**
     * Set data in memory
     */
    async setDataInMemory(data: IConflictDismissEntity[]) {
        let newRangeStart = this.startDate;
        let newRangeEnd = this.endDate;

        if (DismissedConflictsStore().fetchStartTime !== null && DismissedConflictsStore().fetchEndTime !== null) {
            // if range was not continguous - ShiftsInDateRangeDataProvider will have reset the dimissed conflicts store

            // range is contigous - grow the fetch range if needed
            // use the earliest start date and the latest end date
            if (!this.startDate.isBefore(DismissedConflictsStore().fetchStartTime)) {
                newRangeStart = DismissedConflictsStore().fetchStartTime;
            }

            if (!this.endDate.isAfter(DismissedConflictsStore().fetchEndTime)) {
                newRangeEnd = DismissedConflictsStore().fetchEndTime;
            }
        }

        setDismissedConflictStoreDateRange(newRangeStart, newRangeEnd);
        setDismissConflicts(data);
    }

    /**
     * Set data in the database
     */
    async setDataInDatabase(data: IConflictDismissEntity[]) {
        if (data) {
            return await this.teamDataBase.setDismissedConflicts(data);
        }
    }

    /**
     * Make a network call to update the data
     */
    async saveDataToNetwork(): Promise<IConflictDismissEntity[]> {
       throw new Error("ConflictDismissalsDataProvider.saveDataToNetwork not implemented");
    }

    /**
     * Delete data if it's found in memory
     */
    async hardDeleteDataFromMemory(): Promise<void> {
       resetDismissedConflictStore();
    }

    /**
     * Delete data if it's found in the database
     */
    async hardDeleteDataFromDatabase(data?: IConflictDismissEntity[]): Promise<void> {
        return await this.teamDataBase.deleteDismissedConflicts(this.teamId, data);
    }
}