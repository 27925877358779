
import { action } from "satcheljs";
import { SchedulesViewStateStore } from "sh-application/components/schedules/lib/store/schema/SchedulesViewStateStore";

const getScheduleDataInDateRange = action(
    "GET_SCHEDULE_DATA_IN_DATE_RANGE",
    (
        viewState: SchedulesViewStateStore, forceLoad: boolean = false
    ) => ({
        viewState: viewState, forceLoad: forceLoad
    })
);

export default getScheduleDataInDateRange;