export enum StaffHubTourMarker {
    assignedshifts = "tourstop_assignedshifts",
    shareOrSave = "tourstop_shareOrSave",
    addMember = "tourstop_addMember",
    shareWithTeam = "shareWithTeam",
    copySchedule = "copySchedule",
    schedulesPrintButton = "schedulesPrintButton",
    availability = "tourstop_availability",
    openshifts = "tourstop_openshifts",
    copyHeaderPrintButton = "copyHeaderPrintButton",
    employeeViewMenu = "tourstop_employeeViewMenu",
    scheduleFilterButton = "tourstop_scheduleFilterButton",
    timeClockButton = "tourstop_timeClockButton"
}