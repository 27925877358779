export enum ConflictType {
    OverlappingShiftConflict = "OverlappingShiftConflict",
    ShiftAvailabilityConflict = "ShiftAvailabilityConflict",
    ShiftTimeOffConflict = "ShiftTimeOffConflict"
}

export default interface IBaseConflictEntity {
    // unique conflict Id
    id: string;

    // the memberId associated to the conflict
    memberId: string;

    // the shiftId associated with the conflict
    shiftId: string;

    // the type of conflict, this could be overlapping shift conflict, or shift availability conflict
    conflictType: ConflictType;

    // Flag that tells you if the conflict is dismisses by the user
    isDismissed: boolean;
}