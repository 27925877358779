import { mutatorAction } from "satcheljs";
import { appViewState } from "sh-application/store";
import schedulesViewStateStore from "sh-application/components/schedules/lib/store/store";

export default mutatorAction("SET_SCHEDULE_VIEW_STATE_VALUES",
    function setScheduleViewStateValues(
        showScheduleTeamPickerEntrypoints: boolean,
        showCreateNewScheduleTeamEntrypoints: boolean,
        isScheduleViewStatePopulated: boolean) {
        appViewState().teamSetupPickerViewState.showScheduleTeamPickerEntrypoints = showScheduleTeamPickerEntrypoints;
        appViewState().teamSetupPickerViewState.showCreateNewScheduleTeamEntrypoints = showCreateNewScheduleTeamEntrypoints;

        // After all the Schedule view state values are initialized above, mark the Schedule view state as being ready for
        // consumption by the Schedule view UX.
        schedulesViewStateStore().isViewStatePopulated = isScheduleViewStatePopulated;
    });