import toggleHideConflictManagement from "../actions/toggleHideConflictManagement";
import { orchestrator } from "satcheljs";
import { setDisableHideConflictManagementToggle } from "..";
import { setGlobalMessageFromException } from "sh-application";
import { TeamDataService } from "sh-services";
import { UserSettingsEntity } from "sh-models";

/**
 * Toggles the hide conflict in the schedule
 */
export default orchestrator(toggleHideConflictManagement, async actionMessage => {
        const userSettings: UserSettingsEntity = await TeamDataService.getUserSettings();
        if (userSettings) {
            setDisableHideConflictManagementToggle(true);
            try {
                const updatedUserSettings: UserSettingsEntity = UserSettingsEntity.clone(userSettings);
                updatedUserSettings.hideConflictManagement = !(updatedUserSettings.hideConflictManagement);
                await TeamDataService.saveUserSettings(updatedUserSettings);
            } catch (error) {
                setGlobalMessageFromException(error, false /* AutoDismiss */);
            } finally {
                setDisableHideConflictManagementToggle(false);
            }
        }
    });
