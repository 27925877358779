import { FlightKeys, ITimeOffReasonEntity, TimeOffReasonStatus } from "sh-models";
import { FlightSettingsService } from "sh-services";
import { MAX_TIMEOFF_REASON_LENGTH, MIN_TIMEOFF_REASON_LENGTH } from "sh-application/../StaffHubConstants";
import { Moment } from "moment";

const DEFAULT_START_TIME = 8;
const DEFAULT_TIME_OFF_DURATION = 9;

/**
 * Utility class for time offs
 */
export default class TimeOffUtils {

    /**
     * Return if the time off reason name is valid or not.
     * @param timeOffReasonName: The time off reason name
     */
    public static isValidTimeOffReasonName(timeOffReasonName: string): boolean {
        if (!timeOffReasonName) {
                return false;
        }

        let trimmedInp = timeOffReasonName.trim();
        return ((trimmedInp.length >= MIN_TIMEOFF_REASON_LENGTH) &&
                (trimmedInp.length <= MAX_TIMEOFF_REASON_LENGTH));
    }

    /**
     * Calculate the default start time for the specified date
     */
    public static calculateDefaultStartTimeForDate(date: Moment): Moment {
        return date.clone().startOf('day').set('hour', DEFAULT_START_TIME);
    }

    /**
     * Calculate the default end time for the specified date
     */
    public static calculateDefaultEndTimeForDate(date: Moment, isSingleDayTimeOff: boolean): Moment {
        return this.calculateDefaultEndTimeForStartTime(this.calculateDefaultStartTimeForDate(date), isSingleDayTimeOff);
    }

    /**
     * Calculate the default end time for the specified start time
     */
    public static calculateDefaultEndTimeForStartTime(startTime: Moment, isSingleDayTimeOff: boolean): Moment {
        let newEndTime: Moment = startTime.clone().add(DEFAULT_TIME_OFF_DURATION, 'hours');

        /**
         * For teams supporting single say time off, newEndTime should not leave day boundary
         * If Adding DEFAULT_TIME_OFF_DURATION leaves day boundary then
         * we are setting new end time to end of day
         */
        if (isSingleDayTimeOff && !startTime.isSame(newEndTime, "date")) {
            newEndTime = startTime.clone().endOf('day');
        }

        return newEndTime;
    }

    /**
     * Normalize the specified date time to be used for representing dates for the Time Off's date range
     */
    public static normalizeStartEndDateValue(date: Moment): Moment {
        return date ? date.clone().startOf('day') : null;
    }

    /**
     * Checks whether All Day Time off is allowed
     */
    public static shouldDisableAllDayTimeOff(): boolean {
        return FlightSettingsService.isFlightEnabled(FlightKeys.DisableAllDayTimeOff);
    }

    /**
     * Function that checks if the ITimeOffReasonEntity has an active state.
     * @param {ITimeOffReasonEntity} timeOffReason
     * @returns {boolean} true if the TOR state is Active
     */
     public static isActive({ state }: ITimeOffReasonEntity): boolean {
        return state === TimeOffReasonStatus.Active;
    }
}