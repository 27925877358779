import updateLocateUserResponse from "sh-stores/sh-user-store/actions/updateLocateUserResponse";
import { ECSConfigKey, ECSConfigService, NetworkService } from "sh-services";
import { orchestrator } from "satcheljs";
import { removeUrlTrailingSlash } from "sh-application";

export default orchestrator( updateLocateUserResponse, async actionMessage => {
    const regionId: string = actionMessage.locateUserResponse && actionMessage.locateUserResponse.region && actionMessage.locateUserResponse.region.id ? actionMessage.locateUserResponse.region.id : "";
    const serviceUrl: string = actionMessage.locateUserResponse && actionMessage.locateUserResponse.region && actionMessage.locateUserResponse.region.serviceUrl ? actionMessage.locateUserResponse.region.serviceUrl : "";
    const afdServiceUrl: string = actionMessage.locateUserResponse && actionMessage.locateUserResponse.region && actionMessage.locateUserResponse.region.afdServiceUrl ? actionMessage.locateUserResponse.region.afdServiceUrl : "";

    // set the service endpoint
    NetworkService.setServiceAPIEndPoint({
        id: regionId,
        serviceUrl: removeUrlTrailingSlash(ECSConfigService.isECSFeatureEnabled(ECSConfigKey.UseAFDServiceUrl) && !!afdServiceUrl ? afdServiceUrl : serviceUrl)
    });
});