import { Moment } from "moment";
import { MobxUtils } from "sh-application";
import { IScheduleViewSettings } from "sh-application/components/schedule/grids/lib/ScheduleGridSettings";
import { ScheduleGridUtils } from "sh-application/components/schedules/lib";
import DateUtils from "sh-application/utility/DateUtils";
import MemberUtils from "sh-application/utility/MemberUtils";
import NoteUtils from "sh-application/utility/NoteUtils";
import ShiftUtils from "sh-application/utility/ShiftUtils";
import TagUtils from "sh-application/utility/TagUtils";
import { PeopleCountManager, PeopleCountResult } from "sh-managers";
import {
    FlightKeys,
    IBaseShiftEntity,
    IMemberEntity,
    INoteEntity,
    IShiftEntity,
    ISubshiftEntity,
    ITagEntity,
    ITimeOffReasonEntity,
    IUniqueShiftEntity,
    IUniqueSubshiftEntity,
    NoteStates,
    ScheduleCalendarType,
    ScheduleCalendarTypes,
    ShiftFilterType,
    ShiftTypes,
    TagEntity,
    UniqueShiftEntity,
    UniqueSubshiftEntity
} from "sh-models";
import IOpenShiftEntity from "sh-models/shift/IOpenShiftEntity";
import {
    ECSConfigKey,
    ECSConfigService,
    FlightSettingsService
} from "sh-services";
import { TagStore } from "sh-tag-store";
import { TeamStore } from "sh-team-store";

import { DayViewFormatCellInterval } from "../../../StaffHubConstants";
import indexComparator from "../../utility/indexComparator";

/**
 * One hour in milliseconds.
 */
const oneHourInMs = 3600000;

/**
 * One day in milliseconds.
 */
const oneDayInMs = 86400000;

// Grouped shift data for used for Schedule rendering.

// Overall grouped shift data for rendering shifts for a schedule
export interface GroupedShiftsData {
    peopleCount: PeopleCountResult;
    groupedTagData: Map<string, GroupedTagData>; // GroupedTagData indexed by tagId. Grouped data for tag groups.
    groupedMembersData: Map<string, GroupedMemberData>; // GroupedMemberData indexed by memberId. Grouped data for members.
    memberIds: string[];
    memberIdsWithTimeOffs: string[]; // Ids of member who have time offs
    hours: number; // Total paid hours
    shifts: IShiftEntity[]; // All Shifts in view
    openShifts: IOpenShiftEntity[]; // All OpenShifts in view
    openShiftsByDate: Map<number, IOpenShiftEntity[]>;
    notes: Map<number, INoteEntity>; // All notes that are either active or unpublished in view, indexed by date
    hoursByDate: Map<number, number>; // Total hours indexed by date index
    uniqueShifts: IUniqueShiftEntity[]; // unique shifts in the data.  We store them using uniqueShifts because we don't consider notes or dates, just times
    uniqueSubshifts: IUniqueSubshiftEntity[]; // unique sub shifts in the data
    groupedUniqueShiftData: Map<string, GroupedUniqueShiftData>; // GroupedUniqueShiftData indexed by uniqueShiftId. Grouped data for unique shifts.
}

// Grouped data for a tag group
export interface GroupedTagData {
    tag: ITagEntity;
    memberIds: string[]; // Member ids for the tag group
    hours: number; // Total paid hours for the tag group
    groupedMembersData: Map<string, GroupedMemberData>; // GroupedMemberData indexed by memberId
    uniqueShifts: IUniqueShiftEntity[]; // unique shifts for the tag
    groupedUniqueShiftData: Map<string, GroupedUniqueShiftData>;  // GroupedUniqueShiftData indexed by uniqueShiftId. Grouped data for unique shifts.
    openShiftsByDate: Map<number, IOpenShiftEntity[]>; // open shifts within tag group mapped by date index
}

// Grouped data for a member
export interface GroupedMemberData {
    memberId: string;
    shifts: IShiftEntity[]; // Shifts for the member
    hours: number; // Total paid hours for the member
    hasPaidTimeOff: boolean; // True if the member has any paid time off
    workingShiftsByDate: Map<number, IShiftEntity[]>; // Working shifts indexed by date index
    timeOffShifts: IShiftEntity[]; // Time off shifts for the member
    timeOffShiftsByDate: Map<number, IShiftEntity[]>; // Time off shifts indexed by date index. Needed for cases where time offs need to be broken down by date, such as for Excel export.
    hoursByDate: Map<number, number>; // Total hours indexed by date index
    tagIds: string[]; // tag ids to which the member belongs. used for export
}

// Grouped data for a uniqueShift group
export interface GroupedUniqueShiftData {
    memberIdsByDate: Map<number, string[]>; // Member Ids indexed by date index
    openShiftsByDate: Map<number, IOpenShiftEntity[]>; // OpenShifts for particular unique shift indexed by date index
}

// Data for rendering member shift rows

// For rendering, based on view settings, GroupedShiftsData will be grouped into sub groups of FlexiGroupSettings
//      Data for each Member (in a row) is represented with MemberShiftsData
//      For a group, MemberShiftsData items will be grouped with memberId and store in MembersShiftsDataGroup
//      List of those groups (MembershiftsDataGroup[]) indicate the view
//      For each of those groups, FlexiGridSettings will be calculated and view will be rendered

// Shifts data for a member row
export interface MemberShiftsData {
    tagId?: string; // tagId will be null for ungrouped view
    member: IMemberEntity;
    memberShifts: IShiftEntity[];
    memberTimeOffs: IShiftEntity[];
    memberShiftsByDate: Map<number, IShiftEntity[]>; // A member's shifts indexed by date index
    hoursByDate: Map<number, number>; // Total paid hours indexed by date index
    // TODO: This property can probably be replaced with memberShifts after some changes to the Day View rendering. See the ScheduleGrid rendering handling for
    // an example of how memberShifts is used and confined within the view range.
    memberShiftsForView?: IShiftEntity[]; // List of member's shifts and timeoffs visible in the view, used for day view
}

// Group of MemberShiftsData (indicating a group of rows of member shifts data)
export interface MemberShiftsDataGroup {
    tag?: ITagEntity; // tag will be null for ungrouped view
    members: IMemberEntity[];
    openShiftsByDate:  Map<number, IOpenShiftEntity[]>; // open shifts within this tag (and sometimes limited by date range) indexed by date index
    memberShiftsData: Map<string, MemberShiftsData>; // Member shifts indexed by member id
}

// Temporal Items comprise anything that might be found in a scheduler view cell. For example, a cell might be empty,
// it might contain a shift, or it might contain a time off request.
// They are defined with a start and end time, and a type. The start and end times should represent the ranges that these
// objects should actually occupy in the schedule view. For example, in Week/Month Schedule view, working shifts will
// always occupy one date cell, whereas timeoff shifts may occupy multiple date cells.

// TODO: cowuertz: update this with any other items that fill scheduler view cells
export enum TemporalItemTypes {
    empty,
    shift,
    timeOff,
    activity,
    timeOffRequest,
    openShift
}

export interface TemporalItem {
    startTime: Moment;
    endTime: Moment;
    type: TemporalItemTypes;
    shiftEvent?: IBaseShiftEntity; // Reference to the original shift event item for temporal items for shift events
}

// Function to generate member shifts data groups
export type GenerateMemberShiftsDataGroupsFunction = (datesInRange: Moment[],
    groupedShiftsData: GroupedShiftsData,
    tags: Array<ITagEntity>,
    filteredTags: Array<ITagEntity>,
    timeOffReasons: Array<ITimeOffReasonEntity>,
    members: Array<IMemberEntity>,
    filteredMembers: Array<IMemberEntity>,
    scheduleCalendarType?: ScheduleCalendarType,
    viewStart?: Moment,
    viewEnd?: Moment) => MemberShiftsDataGroup[];

/**
 * This class has support for organizing Schedule-related data (shifts, members, tag groups, etc) for
 * Schedule rendering.
 *
 * There are two sets of processing here:
 * - The Group Shifts handling will take shifts, tag group, and member
 * data and organize them into grouped data so that they can be looked up for Schedule view rendering.
 * - The Member Shift handling will use the grouped shift data to organize data into member
 * rows that contain shifts for each member. These member rows may be organized into tag groups for which
 * the members belong.
 *
 * Note that the generated data is not UX-specific so that it can be used to render schedule data for web
 * page rendering, Excel export, etc.
 */
export class ScheduleData {

    /**
     * Group shifts-related data for schedule output
     *
     * @param outputStartDate view start date
     * @param outputEndDate view end date
     * @param shifts shifts store data. contains all shift event types - working shifts, timeoff shifts
     * @param tags tags store data
     * @param filteredTags tags/groups that are selected in the schedule filter
     * @param timeOffReasons timeoff reasons store data
     * @param members members store data
     * @param filteredMembers members that are selected in the schedule filter
     * @param isViewGroupedByShifts true if we want to render a schedule view that is grouped by shifts
     * @param doIncludeSummaryData true to include summary data calculations (used for Export)
     * @param doIncludeUniqueSubshiftData true to include unique subshift data calculations (used for day view printing legend)
     * @param includeDrafts true if we want the last saved versions of the shifts rather than the last shared
     * @param isDayView Whether schedule 'Day' view is active.
     */
    public static groupShiftsDataForScheduleOutput(
        outputStartDate: Moment,
        outputEndDate: Moment,
        shifts: Array<IShiftEntity>,
        openShifts: Array<IOpenShiftEntity>,
        notes: Array<INoteEntity>,
        tags: Array<ITagEntity>,
        filteredTags: Array<ITagEntity>,
        timeOffReasons: Array<ITimeOffReasonEntity>,
        members: Array<IMemberEntity>,
        filteredMembers: Array<IMemberEntity>,
        selectedShiftFilters: Array<ShiftFilterType>,
        isViewGroupedByShifts: boolean,
        doIncludeSummaryData: boolean,
        doIncludeUniqueSubshiftData: boolean,
        includeDrafts: boolean = true,
        isDayView: boolean = false
    ): GroupedShiftsData {
        const groupedShiftsData: GroupedShiftsData = {
            groupedTagData: new Map<string, GroupedTagData>(),
            groupedMembersData: new Map<string, GroupedMemberData>(),
            memberIds: [],
            memberIdsWithTimeOffs: [],
            hours: 0,
            shifts: [],
            openShifts: [],
            openShiftsByDate: new Map<number, IOpenShiftEntity[]>(),
            peopleCount: {},
            notes: new Map<number, INoteEntity>(),
            hoursByDate: new Map<number, number>(),
            uniqueShifts: [],
            uniqueSubshifts: [],
            groupedUniqueShiftData: new Map<string, GroupedUniqueShiftData>()
        };

        const outputStartDateBegin = outputStartDate.clone().startOf('day');
        const outputEndDateEnd = outputEndDate.clone().endOf('day');
        const startTimestampMs = outputStartDateBegin.valueOf();
        const endTimestampMs = outputEndDateEnd.valueOf();
        const isScheduleGroupOrMemberFilterApplied = ScheduleGridUtils.isScheduleGroupOrMemberFilterApplied(filteredTags, filteredMembers);

        const peopleCountManager = new PeopleCountManager({
            intervalMs: isDayView ? oneHourInMs : oneDayInMs,
            limits: {
                endTimestampMs,
                startTimestampMs
            }
        });

        // If there are any filters, apply the filters and compute data for only those tags
        // Otherwise, compute group shift data for all tags
        const tagsForGroupedData = isScheduleGroupOrMemberFilterApplied
                                        ? ShiftUtils.getTagsForFilter(tags, filteredMembers, filteredTags, shifts)
                                        : tags;
        if (tagsForGroupedData) {
            for (let i = 0; i < tagsForGroupedData.length; i++) {
                const tag = tagsForGroupedData[i];

                // Get data for the current tag group
                let groupedTagData: GroupedTagData = groupedShiftsData.groupedTagData.get(tag.id);
                if (!groupedTagData) {
                    groupedTagData = ScheduleData.createNewGroupedTagData(tag);
                    groupedShiftsData.groupedTagData.set(tag.id, groupedTagData);
                }
            }
        }

        if (shifts) {
            for (let i = 0; i < shifts.length; i++) {
                let shift = shifts[i];
                let tagId = ShiftUtils.getTagIdFromShift(shift);
                // If we wish to ignore drafts (non-shared changes to the shift model) when grouping shift data,
                // we will convert the shift models into their last shared form
                if (!includeDrafts) {
                    shift = ShiftUtils.getSharedChanges(shift);
                    // If there is no shared version of the shift, we will not include this shift in our grouped data
                    if (!shift) {
                        continue;
                    }
                }

                // Skip shifts that should not be displayed, such as unshared shift events that have been deleted.
                // Or ignore shift if it is assigned to a member that is not part of the team
                // Ignore shifts out of range
                // Ignore shifts that don't match the filtered tags or tags containing filtered members
                if (!ShiftUtils.isDisplayableShiftInScheduleRange(shift, outputStartDateBegin, outputEndDateEnd)
                    || !members.find((member: IMemberEntity) => member.id === shift.memberId)
                    || ((isScheduleGroupOrMemberFilterApplied || selectedShiftFilters.length > 0) && !ShiftUtils.isDisplayableShiftForFilter(filteredTags, filteredMembers, selectedShiftFilters, shift))) {
                    continue;
                }

                let shiftHours = ShiftUtils.getPaidHoursForShift(shift);

                // Check whether the shift intersects with the current date output range
                const isShiftStartInView = DateUtils.isStartTimeInView(shift.startTime, outputStartDateBegin, outputEndDateEnd);

                // If the Shift's start time doesn't fall in the view's start and end range, don't count the hours.
                // Note:  We don't check the end time for Shifts since only the first day for a Shift is displayed in
                // the schedule.
                if (!isShiftStartInView) {
                    shiftHours = 0;
                }

                // Time Off-related events are different from regular Shifts such that they are split into individual days,
                // and so they are still output if only a subset of their days fall within the output range.
                const isTimeOffEvent = ShiftUtils.isTimeOffEvent(shift);
                const isTimeOffRequestEvent = ShiftUtils.isTimeOffRequestEvent(shift);
                const isTimeOffScheduleEvent = isTimeOffEvent || isTimeOffRequestEvent;

                // Determine whether the current shift event is a paid time off event
                let isPaidTimeOff = false;
                if (isTimeOffScheduleEvent) {
                    const timeOffReason = ShiftUtils.getTimeOffReasonFromShift(shift, timeOffReasons);
                    isPaidTimeOff = timeOffReason && timeOffReason.isPaid;
                }

                if (!tagId) {
                    // Set tagId as default if it is not assigned. Shifts without tags will be grouped into the default group ("Other" group)
                    tagId = TagUtils.DEFAULT_TAG_ID;

                    // add other group tag data
                    let otherGroupedTagData: GroupedTagData = groupedShiftsData.groupedTagData.get(tagId);
                    if (!otherGroupedTagData) {
                        let otherTag = TagEntity.createEmptyObject();
                        otherTag.id = tagId;
                        otherTag.name = ScheduleGridUtils.getOtherGroupName();
                        otherGroupedTagData = ScheduleData.createNewGroupedTagData(otherTag);
                        groupedShiftsData.groupedTagData.set(tagId, otherGroupedTagData);
                    }
                }

                const isWorkingShift = shift.shiftType == ShiftTypes.Working;
                const startDateIndex = DateUtils.fastCalculateDateIndex(shift.startTime);
                // Calculate total hours allocated
                groupedShiftsData.hours += shiftHours;

                if (isWorkingShift && isDayView) {
                    const shiftEnd = shift.endTime.clone();
                    const shiftStartTimestamp = shift.startTime.clone().startOf("hour").valueOf();

                    if (shiftEnd.minutes() > 0) {
                        // Example: If a shift ends at 5:15PM we want to count it within the 5:00PM hour so adding one hour so its ends time will be 6:00PM (exclusive).
                        shiftEnd.add(1, "hour");
                    }

                    peopleCountManager.addTimeRangeEntries({
                        endTimestampMs: shiftEnd.startOf("hour").valueOf(),
                        memberId: shift.memberId,
                        startTimestampMs: shiftStartTimestamp
                    });
                } else if (isWorkingShift) {
                    // 'Week' and 'Month' view only show people count per day
                    // 24h shifts or cross-days shifts will only be counted once based on their start date indexes (it represents the day under which the shift block will be shown in the schedule view).
                    peopleCountManager.addTimeEntry({
                        memberId: shift.memberId,
                        shiftHours,
                        timestampMs: startDateIndex
                    });
                }

                // Add current shift's member id to group shifts data
                let memberId = shift.memberId;
                if (memberId && groupedShiftsData.memberIds.indexOf(memberId) === -1) {
                    groupedShiftsData.memberIds.push(memberId);
                }

                // Populate groupedMembersData - data indexed by member id, across all tags

                let groupedMemberDataForMember: GroupedMemberData = groupedShiftsData.groupedMembersData.get(memberId);
                if (!groupedMemberDataForMember) {
                    groupedMemberDataForMember = {
                        memberId: memberId,
                        shifts: [],
                        hours: 0,
                        hasPaidTimeOff: false,
                        workingShiftsByDate: new Map<number, IShiftEntity[]>(),
                        timeOffShifts: [],
                        timeOffShiftsByDate: new Map<number, IShiftEntity[]>(),
                        hoursByDate: new Map<number, number>(),
                        tagIds: []
                    };
                    groupedShiftsData.groupedMembersData.set(memberId, groupedMemberDataForMember);
                }

                groupedMemberDataForMember.hours += shiftHours;
                // Record whether any paid time off shifts exist for this grouping
                groupedMemberDataForMember.hasPaidTimeOff = groupedMemberDataForMember.hasPaidTimeOff || isPaidTimeOff;

                // Count number of hours per member for each date
                if (!groupedMemberDataForMember.hoursByDate.has(startDateIndex)) {
                    groupedMemberDataForMember.hoursByDate.set(startDateIndex, 0);
                }
                let hoursByDate = groupedMemberDataForMember.hoursByDate.get(startDateIndex);
                groupedMemberDataForMember.hoursByDate.set(startDateIndex, (hoursByDate + shiftHours));

                // Count number of hours in each date
                if (!groupedShiftsData.hoursByDate.has(startDateIndex)) {
                    groupedShiftsData.hoursByDate.set(startDateIndex, 0);
                }
                hoursByDate = groupedShiftsData.hoursByDate.get(startDateIndex);
                groupedShiftsData.hoursByDate.set(startDateIndex, (hoursByDate + shiftHours));

                // Add shift to all shifts list
                groupedShiftsData.shifts.push(shift);

                if (isTimeOffScheduleEvent) {
                    // TimeOff handling

                    groupedMemberDataForMember.timeOffShifts.push(shift);
                    let shiftsForMemberByDate = groupedMemberDataForMember.timeOffShiftsByDate.get(startDateIndex);
                    if (shiftsForMemberByDate) {
                        shiftsForMemberByDate.push(shift);
                    } else {
                        groupedMemberDataForMember.timeOffShiftsByDate.set(startDateIndex, [shift]);
                    }

                    // Keep track of members who have time offs
                    groupedShiftsData.memberIdsWithTimeOffs = groupedShiftsData.memberIdsWithTimeOffs || [];
                    if (groupedShiftsData.memberIdsWithTimeOffs.indexOf(memberId) < 0) {
                         groupedShiftsData.memberIdsWithTimeOffs.push(memberId);
                    }
                } else {
                    // Shift handling

                    // Get data for the current tag group
                    let groupedTagData: GroupedTagData = groupedShiftsData.groupedTagData.get(tagId);
                    if (!groupedTagData) {
                        continue;
                    }

                    // Calculate hours allocated for this tag
                    groupedTagData.hours += shiftHours;

                    // Keep track of members for this tag
                    if (memberId && groupedTagData.memberIds.indexOf(memberId) === -1) {
                        groupedTagData.memberIds.push(memberId);
                    }

                    // Get data for the shift's member in the current tag group
                    let groupedTagMemberData: GroupedMemberData = groupedTagData.groupedMembersData.get(memberId);
                    if (!groupedTagMemberData) {
                        groupedTagMemberData = {
                            memberId: memberId,
                            shifts: [],
                            hours: 0,
                            hasPaidTimeOff: false,
                            workingShiftsByDate: new Map<number, IShiftEntity[]>(),
                            timeOffShifts: [],
                            timeOffShiftsByDate: new Map<number, IShiftEntity[]>(),
                            hoursByDate: new Map<number, number>(),
                            tagIds: []
                        };
                        groupedTagData.groupedMembersData.set(memberId, groupedTagMemberData);
                    }

                    // Add hours allotted for this member in this tag
                    groupedTagMemberData.hours += shiftHours;

                    // Add shift to member data grouping
                    groupedMemberDataForMember.shifts.push(shift);
                    let shiftsForMemberByDate = groupedMemberDataForMember.workingShiftsByDate.get(startDateIndex);
                    if (shiftsForMemberByDate) {
                        shiftsForMemberByDate.push(shift);
                    } else {
                        groupedMemberDataForMember.workingShiftsByDate.set(startDateIndex, [shift]);
                    }

                    // Add shift to member data in tag grouping
                    groupedTagMemberData.shifts.push(shift);
                    let shiftsForTagMemberByDate = groupedTagMemberData.workingShiftsByDate.get(startDateIndex);
                    if (shiftsForTagMemberByDate) {
                        shiftsForTagMemberByDate.push(shift);
                    } else {
                        groupedTagMemberData.workingShiftsByDate.set(startDateIndex, [shift]);
                    }
                }

                // Implement isViewGroupedByShifts support
                if (isViewGroupedByShifts && !isTimeOffScheduleEvent && isShiftStartInView && !ShiftUtils.isDeletedShift(shift)) {
                    let groupedTagData: GroupedTagData = groupedShiftsData.groupedTagData.get(tagId);

                    // Add this uniqueShift to groupedShiftsData.groupedTagData[tagId].uniqueShifts list
                    // if current shift is already in uniqueShifts, dont do anything; else add it
                    // get uniqueShiftId for current shift. Either its new or existing from earlier

                    let uniqueShiftToAdd: IUniqueShiftEntity = {
                        id: UniqueShiftEntity.generateNewUniqueShiftId(),
                        startTime: shift.startTime, // only hours are minutes in Time are applicable
                        endTime: shift.endTime, // only hours are minutes in Time are applicable
                        breaks: shift.breaks ? shift.breaks.slice(0) : shift.breaks,
                        title: shift.title,
                        theme: shift.theme,
                        eTag: ""
                    };

                    let uniqueShift: IUniqueShiftEntity;
                    // check if uniqueShift is already added, if not already existing, add it to the list
                    for (let i = 0; i < groupedTagData.uniqueShifts.length; i++) {
                        let existingUniqueShift = groupedTagData.uniqueShifts[i];
                        if (ShiftUtils.areSimilarUniqueShifts(existingUniqueShift, uniqueShiftToAdd)) {
                            uniqueShift = existingUniqueShift;
                            continue;
                        }
                    }
                    if (!uniqueShift) {
                        groupedTagData.uniqueShifts.push(uniqueShiftToAdd);
                        uniqueShift = uniqueShiftToAdd;
                    }

                    // Add current shift memberId to groupedShiftsData.groupedTagData[tagId].groupedUniqueShiftData[uniqueShift.id].memberIdsByDate[startDateIndex] list
                    let groupedUniqueShiftData = groupedTagData.groupedUniqueShiftData.get(uniqueShift.id);
                    if (!groupedUniqueShiftData) {
                        groupedUniqueShiftData = {
                            memberIdsByDate: new Map<number, string[]>(),
                            openShiftsByDate: new Map<number, IOpenShiftEntity[]>()
                        };
                        groupedTagData.groupedUniqueShiftData.set(uniqueShift.id, groupedUniqueShiftData);
                    }

                    let memberIds = groupedUniqueShiftData.memberIdsByDate.get(startDateIndex);
                    if (memberIds) { // add memberId mapping to the group for uniqueShift id, if it doesn't already exist in the mapping
                        if (memberId && memberIds.indexOf(memberId) === -1) {
                            memberIds.push(memberId);
                        }
                    } else {
                        groupedUniqueShiftData.memberIdsByDate.set(startDateIndex, [memberId]); // create map with startDateIndex
                    }

                    // Add uniqueShiftToAdd to groupedShiftsData.uniqueShifts list. Needed for non grouped view
                    if (!groupedShiftsData.uniqueShifts) {
                        groupedShiftsData.uniqueShifts = [];
                    }
                    uniqueShift = null;
                    for (let i = 0; i < groupedShiftsData.uniqueShifts.length; i++) {
                        let existingUniqueShift = groupedShiftsData.uniqueShifts[i];
                        if (ShiftUtils.areSimilarUniqueShifts(existingUniqueShift, uniqueShiftToAdd)) {
                            uniqueShift = existingUniqueShift;
                            break;
                        }
                    }
                    if (!uniqueShift) {
                        groupedShiftsData.uniqueShifts.push(uniqueShiftToAdd);
                        uniqueShift = uniqueShiftToAdd;
                    }

                    // calculate the unique sub shifts included in this vieww
                    if (doIncludeUniqueSubshiftData && shift.subshifts && shift.subshifts.length) {
                        let uniqueSubshift: IUniqueSubshiftEntity;
                        for (let i = 0; i < shift.subshifts.length; i++) {
                            const uniqueSubShiftToAdd = UniqueSubshiftEntity.subshiftToUniqueSubshiftEntity(shift.subshifts[i]);
                            uniqueSubshift = null;

                            for (let i = 0; i < groupedShiftsData.uniqueSubshifts.length; i++) {
                                let existingUniqueSubshift = groupedShiftsData.uniqueSubshifts[i];
                                if (ShiftUtils.areSimilarUniqueSubshifts(existingUniqueSubshift, uniqueSubShiftToAdd)) {
                                    uniqueSubshift = uniqueSubShiftToAdd;
                                    break;
                                }
                            }

                            if (!uniqueSubshift) {
                                groupedShiftsData.uniqueSubshifts.push(uniqueSubShiftToAdd);
                            }
                        }
                    }

                    // Add current shift MemberId to groupedShiftsData.groupedUniqueShiftData[uniqueShift.id].memberIdsByDate[startDateIndex]
                    if (!groupedShiftsData.groupedUniqueShiftData) {
                        groupedShiftsData.groupedUniqueShiftData = new Map<string, GroupedUniqueShiftData>();
                    }

                    groupedUniqueShiftData = groupedShiftsData.groupedUniqueShiftData.get(uniqueShift.id);
                    if (!groupedUniqueShiftData) {
                        groupedUniqueShiftData = {
                            memberIdsByDate: new Map<number, string[]>(),
                            openShiftsByDate: new Map<number, IOpenShiftEntity[]>()
                        };
                        groupedShiftsData.groupedUniqueShiftData.set(uniqueShift.id, groupedUniqueShiftData);
                    }

                    memberIds = groupedUniqueShiftData.memberIdsByDate.get(startDateIndex);
                    if (memberIds) { // add memberId to map with startDateIndex, if it doesn't already exist in the list
                        if (memberId && memberIds.indexOf(memberId) === -1) {
                            memberIds.push(memberId);
                        }
                    } else {
                        groupedUniqueShiftData.memberIdsByDate.set(startDateIndex, [memberId]); // create map with startDateIndex
                    }
                }
            }
        }

        if (openShifts && ScheduleData.isOpenShiftsEnabled()) {
            for (let i = 0; i < openShifts.length; i++) {
                let openShift = openShifts[i];
                // If we wish to ignore drafts (non-shared changes to the openshift model) when grouping openshift data,
                // we will convert the shift models into their last shared form
                if (!includeDrafts) {
                    openShift = ShiftUtils.getSharedChanges(openShift) as IOpenShiftEntity;
                    // If there is no shared version of the open shift, we will not include this open shift in our grouped data
                    if (!openShift) {
                        continue;
                    }
                }

                // Skip the openShifts that should not be displayed, like unshared open shift events that have been deleted
                // Group only open shifts within view
                if (!ShiftUtils.isDisplayableOpenShiftInScheduleRange(openShift, outputStartDateBegin, outputEndDateEnd)) {
                    continue;
                }

                // Check whether the open shift intersects with the current date output range
                const isShiftStartInView = DateUtils.isStartTimeInView(openShift.startTime, outputStartDateBegin, outputEndDateEnd);

                let tagId = ShiftUtils.getTagIdFromShift(openShift);
                if (!tagId) {
                    // Set tagId as default if it is not assigned. Open shifts without tags will be grouped into the default group ("Other" group)
                    tagId = TagUtils.DEFAULT_TAG_ID;

                    // add other group tag data
                    let otherGroupedTagData: GroupedTagData = groupedShiftsData.groupedTagData.get(tagId);
                    if (!otherGroupedTagData) {
                        let otherTag = TagEntity.createEmptyObject();
                        otherTag.id = tagId;
                        otherTag.name = ScheduleGridUtils.getOtherGroupName();
                        otherGroupedTagData = ScheduleData.createNewGroupedTagData(otherTag);
                        groupedShiftsData.groupedTagData.set(tagId, otherGroupedTagData);
                    }
                }

                const startDateIndex = DateUtils.fastCalculateDateIndex(openShift.startTime);

                // Add openShift to all open shifts list
                groupedShiftsData.openShifts.push(openShift);
                let openShiftsByDate = groupedShiftsData.openShiftsByDate.get(startDateIndex);
                if (openShiftsByDate) {
                    openShiftsByDate.push(openShift);
                } else {
                    groupedShiftsData.openShiftsByDate.set(startDateIndex, [openShift]);
                }

                // Group openShifts within Tags

                // Get data for the current tag group
                let groupedTagData: GroupedTagData = groupedShiftsData.groupedTagData.get(tagId);
                if (!groupedTagData) {
                    continue;
                }
                openShiftsByDate = groupedTagData.openShiftsByDate.get(startDateIndex);
                if (openShiftsByDate) {
                    openShiftsByDate.push(openShift);
                } else {
                    groupedTagData.openShiftsByDate.set(startDateIndex, [openShift]);
                }

                // Implement isViewGroupedByShifts support
                if (isViewGroupedByShifts && isShiftStartInView && !ShiftUtils.isDeletedShift(openShift)) {
                    let groupedTagData: GroupedTagData = groupedShiftsData.groupedTagData.get(tagId);

                    // Add this openShift as uniqueShift to groupedShiftsData.groupedTagData[tagId].uniqueShifts list
                    // if current openShift is already in uniqueShifts, don't do anything; else add it
                    // get uniqueShiftId for current open shift. Either it's new or existing from earlier

                    let uniqueShiftToAdd: IUniqueShiftEntity = {
                        id: UniqueShiftEntity.generateNewUniqueShiftId(),
                        startTime: openShift.startTime, // openShift start time
                        endTime: openShift.endTime, // openShift end time
                        breaks: openShift.breaks ? openShift.breaks.slice(0) : openShift.breaks,
                        title: openShift.title,
                        theme: openShift.theme,
                        eTag: ""
                    };

                    let uniqueShift: IUniqueShiftEntity;
                    // check if uniqueShift is already added, if not already existing, add it to the list
                    for (let i = 0; i < groupedTagData.uniqueShifts.length; i++) {
                        let existingUniqueShift = groupedTagData.uniqueShifts[i];
                        if (ShiftUtils.areSimilarUniqueShifts(existingUniqueShift, uniqueShiftToAdd)) {
                            uniqueShift = existingUniqueShift;
                            break;
                        }
                    }
                    if (!uniqueShift) {
                        groupedTagData.uniqueShifts.push(uniqueShiftToAdd);
                        uniqueShift = uniqueShiftToAdd;
                    }

                    let groupedUniqueShiftData = groupedTagData.groupedUniqueShiftData.get(uniqueShift.id);
                    if (!groupedUniqueShiftData) {
                        groupedUniqueShiftData = {
                            memberIdsByDate: new Map<number, string[]>(),
                            openShiftsByDate: new Map<number, IOpenShiftEntity[]>()
                        };
                        groupedTagData.groupedUniqueShiftData.set(uniqueShift.id, groupedUniqueShiftData);
                    }

                    let openShiftsByDate = groupedUniqueShiftData.openShiftsByDate.get(startDateIndex);
                    if (openShiftsByDate) {
                        openShiftsByDate.push(openShift);
                    } else {
                        groupedUniqueShiftData.openShiftsByDate.set(startDateIndex, [openShift]);
                    }
                    // Add uniqueShiftToAdd to groupedShiftsData.uniqueShifts list. Needed for non grouped view
                    if (!groupedShiftsData.uniqueShifts) {
                        groupedShiftsData.uniqueShifts = [];
                    }
                    uniqueShift = null;
                    for (let i = 0; i < groupedShiftsData.uniqueShifts.length; i++) {
                        let existingUniqueShift = groupedShiftsData.uniqueShifts[i];
                        if (ShiftUtils.areSimilarUniqueShifts(existingUniqueShift, uniqueShiftToAdd)) {
                            uniqueShift = existingUniqueShift;
                            break;
                        }
                    }
                    if (!uniqueShift) {
                        groupedShiftsData.uniqueShifts.push(uniqueShiftToAdd);
                        uniqueShift = uniqueShiftToAdd;
                    }

                    if (!groupedShiftsData.groupedUniqueShiftData) {
                        groupedShiftsData.groupedUniqueShiftData = new Map<string, GroupedUniqueShiftData>();
                    }

                    groupedUniqueShiftData = groupedShiftsData.groupedUniqueShiftData.get(uniqueShift.id);
                    if (!groupedUniqueShiftData) {
                        groupedUniqueShiftData = {
                            memberIdsByDate: new Map<number, string[]>(),
                            openShiftsByDate: new Map<number, IOpenShiftEntity[]>()
                        };
                        groupedShiftsData.groupedUniqueShiftData.set(uniqueShift.id, groupedUniqueShiftData);
                    }

                    openShiftsByDate = groupedUniqueShiftData.openShiftsByDate.get(startDateIndex);
                    if (openShiftsByDate) {
                        openShiftsByDate.push(openShift);
                    } else {
                        groupedUniqueShiftData.openShiftsByDate.set(startDateIndex, [openShift]);
                    }
                }
            }
        }

        if (notes) {
            for (let i = 0; i < notes.length; i++) {
                let note = notes[i];
                // include notes that are either active or is (deleted and unpublished)
                // skip notes that are not in the requested grouping range
                const isNoteInRangeAndDisplayableForView: boolean = NoteUtils.isDisplayableNoteInScheduleRange(note, outputStartDateBegin, outputEndDateEnd);
                if (!isNoteInRangeAndDisplayableForView) {
                    continue;
                }

                // If we wish to ignore drafts (non-shared changes to the note model) when grouping note data,
                // we will convert the note models into their last shared form
                if (!includeDrafts) {
                    note = NoteUtils.getSharedChangesForNote(note);
                    // If there is no shared version of the note, we will not include this note in our grouped data
                    if (!note) {
                        continue;
                    }
                }

                const startDateIndex = DateUtils.fastCalculateDateIndex(note.startTime);
                const notesInRange = groupedShiftsData.notes;

                if (!notesInRange.get(startDateIndex)) {
                    notesInRange.set(startDateIndex, note);
                } else {
                    // There can be multiple deleted unshared notes for a given date, but only 1 active unshared note
                    // Give preference to the active note, if an active unshared note exist
                    if (note.state === NoteStates.Active) {
                        notesInRange.set(startDateIndex, note);
                    }
                }
            }
        }

        // doIncludeSummaryData is used for Export
        if (doIncludeSummaryData) {
            // Process tag groups data
            if (tags) {
                for (let i = 0; i < tags.length; i++) {
                    const tag = tags[i];
                    if (tag.memberIds) {
                        tag.memberIds.forEach(memberId => {
                            // Record which tag groups that the members belong to
                            let groupedMemberData: GroupedMemberData = groupedShiftsData.groupedMembersData.get(memberId);
                            if (!groupedMemberData) {
                                groupedMemberData = {
                                    memberId: memberId,
                                    shifts: [],
                                    hours: 0,
                                    hasPaidTimeOff: false,
                                    workingShiftsByDate: new Map<number, IShiftEntity[]>(),
                                    timeOffShifts: [],
                                    timeOffShiftsByDate: new Map<number, IShiftEntity[]>(),
                                    hoursByDate: new Map<number, number>(),
                                    tagIds: []
                                };
                                groupedShiftsData.groupedMembersData.set(memberId, groupedMemberData);
                            }
                            groupedMemberData.tagIds.push(tag.id);
                        });
                    }
                }
            }
        }

        groupedShiftsData.peopleCount = peopleCountManager.getResult();

        // keep the unique shifts sorted for display
        groupedShiftsData.uniqueShifts = groupedShiftsData.uniqueShifts.sort(ShiftUtils.shiftComparatorTimeOfDay);
        groupedShiftsData.groupedTagData.forEach(groupedTagData => {
            groupedTagData.uniqueShifts  = groupedTagData.uniqueShifts.sort(ShiftUtils.shiftComparatorTimeOfDay);
        });

        return groupedShiftsData;
    }

    /**
     * Returns true if open shifts is enabled
     */
    private static isOpenShiftsEnabled() {
        return FlightSettingsService.isFlightEnabled(FlightKeys.EnableOpenShifts) && ECSConfigService.isECSFeatureEnabled(ECSConfigKey.EnableOpenShifts);
    }

    /**
     * Helper method that creates and returns a new empty grouped tag object.
     * @param tagId - tag id for the grouped tag data
     */
    private static createNewGroupedTagData(tag: ITagEntity): GroupedTagData {
        return {
            tag: tag,
            memberIds: [],
            hours: 0,
            groupedMembersData: new Map<string, GroupedMemberData>(),
            uniqueShifts: [],
            groupedUniqueShiftData: new Map<string, GroupedUniqueShiftData>(),
            openShiftsByDate: new Map<number, IOpenShiftEntity[]>()
        };
    }

    /**
     * Uses grouped shifts data to calculate member shift rows for grouped members schedule view.
     *
     * @param datesInRange
     * @param groupedShiftsData
     * @param shifts
     * @param tags
     * @param filteredTags
     * @param timeOffReasons
     * @param members
     * @param filteredMembers
     * @param scheduleCalendarType
     * @param viewStart
     * @param viewEnd
     */
    public static generateSortedMemberShiftsDataGroups: GenerateMemberShiftsDataGroupsFunction = (
        datesInRange: Moment[],
        groupedShiftsData: GroupedShiftsData,
        tags: Array<ITagEntity>,
        filteredTags: Array<ITagEntity>,
        timeOffReasons: Array<ITimeOffReasonEntity>,
        members: Array<IMemberEntity>,
        filteredMembers: Array<IMemberEntity>,
        scheduleCalendarType?: ScheduleCalendarType,
        viewStart?: Moment,
        viewEnd?: Moment
    ): MemberShiftsDataGroup[] => {
        let sortedMemberShiftsDataGroups: MemberShiftsDataGroup[] = [];
        const isScheduleGroupOrMemberFilterApplied = ScheduleGridUtils.isScheduleGroupOrMemberFilterApplied(filteredTags, filteredMembers);

        // Keep track of members that have been grouped so far
        let renderedMemberIds = new Map<string, boolean>();

        if (groupedShiftsData) {
            if (tags && tags.length > 0) {
                for (let i = 0; i < tags.length; i++) {
                    const currentTag = tags[i];
                    if (!currentTag || !currentTag.id || currentTag.id === TagUtils.DEFAULT_TAG_ID) {
                        continue;
                    }

                    // Get all members that belong to this Tag group
                    const membersForTag: IMemberEntity[] = ScheduleData.getMembersForTag(members, currentTag);

                    // Get all the members who have shifts assigned for this Tag group, but are not members of the Tag group.
                    const groupedTagData: GroupedTagData = groupedShiftsData.groupedTagData && groupedShiftsData.groupedTagData.get(currentTag.id);
                    let memberIdsWithShiftsForTag: string[] = groupedTagData && groupedTagData.memberIds ? groupedTagData.memberIds.slice(0) : [];

                    // Remove shift members who are already part of the Tag group (ie, they're already in membersForTag)
                    for (let tagMemberIndex = 0; tagMemberIndex < membersForTag.length; tagMemberIndex++) {
                        const tagMemberId: string = membersForTag[tagMemberIndex].id;
                        const shiftMemberIdIndex: number = memberIdsWithShiftsForTag.indexOf(tagMemberId);
                        if (shiftMemberIdIndex !== -1) {
                            memberIdsWithShiftsForTag.splice(shiftMemberIdIndex, 1);
                        }

                        // Keep track of who was in membersForTag
                        renderedMemberIds.set(tagMemberId, true);
                    }

                    // Get member objects for memberIdsWithShiftsForTag
                    let membersWithShiftsForTag: IMemberEntity[] = [];
                    for (let shiftMemberIndex = 0; shiftMemberIndex < memberIdsWithShiftsForTag.length; shiftMemberIndex++) {
                        const shiftMember: IMemberEntity = members.find((member: IMemberEntity) => member.id === memberIdsWithShiftsForTag[shiftMemberIndex]);
                        membersWithShiftsForTag.push(shiftMember);

                        // Keep track of who was in membersWithShiftsForTag
                        renderedMemberIds.set(shiftMember.id, true);
                    }

                    const allMembersForTag = membersForTag.concat(membersWithShiftsForTag);

                    let filteredMembersForTag = new Array<IMemberEntity>();

                    if (!isScheduleGroupOrMemberFilterApplied) {
                        // If a filter is not applied, show all members of the tag.
                        filteredMembersForTag = allMembersForTag;
                    } else {
                        // If a group filter is applied and it matches the group, show all members of the group.
                        // Else show only the members that are in the selected list in the filter.
                        const isTagSelected = filteredTags.find(tag => tag.id === currentTag.id);
                        if (isTagSelected) {
                            filteredMembersForTag = allMembersForTag;
                        } else {
                            filteredMembersForTag = ShiftUtils.matchFilterMembers(allMembersForTag, filteredMembers);
                        }
                    }

                    // Gather shift data for the calculated members who belong to the current tag group, or have shifts that belong to the tag group.
                    const memberShiftsDataGroupForTag: MemberShiftsDataGroup = ScheduleData.generateMemberShiftsDataGroupForTag(datesInRange, groupedShiftsData, timeOffReasons, filteredMembersForTag, currentTag, scheduleCalendarType, viewStart, viewEnd);
                    sortedMemberShiftsDataGroups.push(memberShiftsDataGroupForTag);
                }
            }

            // sort the MemberShiftsDataGroups based on tag index
            sortedMemberShiftsDataGroups = sortedMemberShiftsDataGroups.sort(ScheduleData.tagIndexComparator);

            // Calculate data for members and shift events that aren't associated with Tag groups (aka "Other" group).

            let otherMembers: IMemberEntity[] = [];
            const otherGroupTagId = TagUtils.DEFAULT_TAG_ID;
            let otherGroupTagData = groupedShiftsData.groupedTagData.get(otherGroupTagId);

            // Collect members who are associated with tagless Shifts
            if (otherGroupTagData) {
                for (let otherMemberIndex = 0; otherMemberIndex < otherGroupTagData.memberIds.length; otherMemberIndex++) {
                    let otherMemberId = otherGroupTagData.memberIds[otherMemberIndex];
                    let otherMember = members.find((member: IMemberEntity) => member.id === otherMemberId);
                    if (otherMember && otherMembers.indexOf(otherMember) < 0) {
                        otherMembers.push(otherMember);
                    }
                }
            }

            // Collect members who have time offs but were not rendered earlier because they no longer belong to any Tag groups
            for (let memberIdIndex = 0; memberIdIndex < groupedShiftsData.memberIdsWithTimeOffs.length; memberIdIndex++) {
                let timeOffMemberId = groupedShiftsData.memberIdsWithTimeOffs[memberIdIndex];
                if (!renderedMemberIds.get(timeOffMemberId)) {
                    let timeOffMember =  members.find((member: IMemberEntity) => member.id === timeOffMemberId);
                    if (timeOffMember && otherMembers.indexOf(timeOffMember) < 0) {
                        otherMembers.push(timeOffMember);
                    }
                }
            }

            // if otherGroupTagData is not available yet, initialize groupedTagData for default tag id
            if (!otherGroupTagData && otherMembers.length > 0) {
                let otherTag = TagEntity.createEmptyObject();
                otherTag.id = otherGroupTagId;
                otherTag.name = ScheduleGridUtils.getOtherGroupName();
                otherGroupTagData = ScheduleData.createNewGroupedTagData(otherTag);
                otherGroupTagData.memberIds = otherMembers.map((member: IMemberEntity) => member.id);
                groupedShiftsData.groupedTagData.set(otherGroupTagId, otherGroupTagData);
            }

            // Add the shifts of these Other members to the "Other" group
            if (otherMembers.length > 0 ||
                (ScheduleData.isOpenShiftsEnabled() && otherGroupTagData)) {
                // Sort the members by member index, which reflects how they were ordered by the user in the ungrouped Schedule view
                otherMembers.sort(indexComparator);

                let otherTag = TagEntity.createEmptyObject();
                otherTag.id = otherGroupTagId;
                otherTag.name = ScheduleGridUtils.getOtherGroupName();

                const memberShiftsDataGroupForOtherTag: MemberShiftsDataGroup =
                    ScheduleData.generateMemberShiftsDataGroupForTag(datesInRange, groupedShiftsData, timeOffReasons, otherMembers, otherTag, scheduleCalendarType, viewStart, viewEnd);
                    sortedMemberShiftsDataGroups.push(memberShiftsDataGroupForOtherTag);
            }
        }

        return sortedMemberShiftsDataGroups;
    }

    /**
     * Uses grouped shifts data to calculate member shift rows for ungrouped members schedule view.
     */
    public static generateUngroupedMemberShiftsDataGroups: GenerateMemberShiftsDataGroupsFunction = (
        datesInRange: Moment[],
        groupedShiftsData: GroupedShiftsData,
        tags: Array<ITagEntity>,
        filteredTags: Array<ITagEntity>,
        timeOffReasons: Array<ITimeOffReasonEntity>,
        members: Array<IMemberEntity>,
        filteredMembers: Array<IMemberEntity>,
        scheduleCalendarType?: ScheduleCalendarType,
        viewStart?: Moment,
        viewEnd?: Moment
    ): MemberShiftsDataGroup[] => {
        const memberShiftsDataGroupForTag: MemberShiftsDataGroup = ScheduleData.generateUngroupedMemberShiftsDataGroup(
            datesInRange,
            groupedShiftsData,
            timeOffReasons,
            filteredMembers,
            scheduleCalendarType,
            viewStart,
            viewEnd);

        // Ungrouped view contains only one Group of data with all members
        return [memberShiftsDataGroupForTag];
    }

    /**
     * Comparator for tagIndex in objects that contain tag
     */
    public static tagIndexComparator(firstObject: { tag?: ITagEntity }, secondObject: { tag?: ITagEntity }): number {
        const tagIdA = firstObject.tag && firstObject.tag.id || "";
        const tagIdB = secondObject.tag && secondObject.tag.id || "";
        const otherTagId = TagUtils.DEFAULT_TAG_ID;

        let result = 0;

        // The "Other" group is positioned to be the last group
        if (tagIdA === otherTagId && tagIdB === otherTagId) {
            result = 0;
        } else if (tagIdA === otherTagId) {
            result = 1;
        } else if (tagIdB === otherTagId) {
            result = -1;
        } else {
            // Order tag groups by their display index
            const tagA: ITagEntity = firstObject.tag;
            const tagB: ITagEntity = secondObject.tag;

            result = indexComparator(tagA, tagB);
            // if we have duplicate indexes, compare the ids so we at least have
            // a deterministic sort order
            if (result === 0) {
                return tagA.id.localeCompare(tagB.id);
            }
        }

        return result;
    }

    /**
     * Generate member shifts data for a tag group
     * @param datesInRange dates in the view range
     * @param groupedShiftsData grouped shifts data
     * @param shifts shifts store data
     * @param timeOffReasons timeoff reasons store data
     * @param tagMembers members store data
     * @param tagId id of the tag group
     */
    private static generateMemberShiftsDataGroupForTag(
        datesInRange: Moment[],
        groupedShiftsData: GroupedShiftsData,
        timeOffReasons: Array<ITimeOffReasonEntity>,
        tagMembers: IMemberEntity[],
        tag: ITagEntity,
        scheduleCalendarType?: ScheduleCalendarType,
        viewStart?: Moment,
        viewEnd?: Moment): MemberShiftsDataGroup {
        const tagId: string = tag && tag.id;

        let memberShiftsDataGroup: MemberShiftsDataGroup = {
            tag: tag,
            members: [],
            openShiftsByDate: new Map<number, IOpenShiftEntity[]>(),
            memberShiftsData: new Map<string, MemberShiftsData>()
        };

        for (let memberIndex = 0; memberIndex < tagMembers.length; memberIndex++) {
            const member = tagMembers[memberIndex];
            memberShiftsDataGroup.members = tagMembers.slice();
            const memberShiftsDataForMember: MemberShiftsData = ScheduleData.generateMemberShiftsDataForTagMember(datesInRange, groupedShiftsData, tagId, member, scheduleCalendarType, viewStart, viewEnd);
            if (memberShiftsDataForMember) {
                memberShiftsDataGroup.memberShiftsData.set(member.id, memberShiftsDataForMember);
            }
        }

        if (tagId) {
            let groupedTagData = groupedShiftsData.groupedTagData.get(tagId);
            memberShiftsDataGroup.openShiftsByDate = groupedTagData ? groupedTagData.openShiftsByDate : new Map<number, IOpenShiftEntity[]>();
        }

        return memberShiftsDataGroup;
    }

    /**
     * Generate member shifts data for ungrouped view
     * @param datesInRange dates in the view range
     * @param groupedShiftsData grouped shifts data
     * @param shifts shifts store data
     * @param timeOffReasons timeoff reasons store data
     * @param tagMembers members store data
     * @param tagId id of the tag group
     */
    private static generateUngroupedMemberShiftsDataGroup(
        datesInRange: Moment[],
        groupedShiftsData: GroupedShiftsData,
        timeOffReasons: Array<ITimeOffReasonEntity>,
        filteredMembers: Array<IMemberEntity>,
        scheduleCalendarType?: ScheduleCalendarType,
        viewStart?: Moment,
        viewEnd?: Moment): MemberShiftsDataGroup {

        let memberShiftsDataGroup: MemberShiftsDataGroup = {
            tag: null,
            members: [],
            openShiftsByDate: new Map<number, IOpenShiftEntity[]>(),
            memberShiftsData: new Map<string, MemberShiftsData>()
        };

        let membersToRender: IMemberEntity[] = MobxUtils.MapToReadonlyArray(TeamStore().members).filter((member: IMemberEntity) => (!MemberUtils.isMemberDeletedFromTeam(member)) || groupedShiftsData.memberIds.indexOf(member.id) !== -1);
        // If there are members selected in the filter, show only those members.
        // Else show all members.
        if (filteredMembers && filteredMembers.length > 0) {
            membersToRender = membersToRender.filter(member => filteredMembers.some(filteredMember => filteredMember.id === member.id));
        }

        // sort the members based on index
        membersToRender.sort(MemberUtils.memberComparator);

        membersToRender.forEach((member: IMemberEntity) => {
            memberShiftsDataGroup.members.push(member);
            // For each member, fetch the data to render
            const memberShiftsDataForMember: MemberShiftsData = ScheduleData.generateMemberShiftsDataForTagMember(
                datesInRange,
                groupedShiftsData,
                null, /* tagId */
                member,
                scheduleCalendarType,
                viewStart,
                viewEnd);
            if (memberShiftsDataForMember) {
                memberShiftsDataGroup.memberShiftsData.set(member.id, memberShiftsDataForMember);
            }
        });

        memberShiftsDataGroup.openShiftsByDate = groupedShiftsData.openShiftsByDate;

        return memberShiftsDataGroup;
    }

    /**
     * Generate shifts data for a member in a tag group
     * @param datesInRange dates in the view range
     * @param groupedShiftsData grouped shifts data
     * @param tagId id of the tag group
     * @param member id of the member
     */
    private static generateMemberShiftsDataForTagMember(
        datesInRange: Moment[],
        groupedShiftsData: GroupedShiftsData,
        tagId: string,
        member: IMemberEntity,
        scheduleCalendarType?: ScheduleCalendarType,
        viewStart?: Moment,
        viewEnd?: Moment
    ): MemberShiftsData {
        let memberShiftsData: MemberShiftsData = {
            tagId: tagId,
            member: member,
            memberShifts: [],
            memberTimeOffs: [],
            memberShiftsByDate: new Map<number, IShiftEntity[]>(),
            hoursByDate: new Map<number, number>()
        };

        // Get sorted TimeOff data for the member
        const groupedMemberData: GroupedMemberData = groupedShiftsData.groupedMembersData.get(member.id);
        if (groupedMemberData && groupedMemberData.timeOffShifts) {
            memberShiftsData.memberTimeOffs = groupedMemberData.timeOffShifts.slice(0).sort(ShiftUtils.shiftComparator);
        }

        // For day view, we will need to account for overnight shifts that end but don't start on the selected day
        const isDayView = scheduleCalendarType === ScheduleCalendarTypes.Day;
        if (isDayView) {
            let memberShiftsForView: IShiftEntity[] = memberShiftsData.memberShiftsForView || [];
            // We check all of the shifts for overlap with the viewStart and viewEnd times and add them to the memberShiftsForView array if they appear within the view
            if (groupedMemberData && groupedMemberData.shifts) {
                groupedMemberData.shifts.forEach(
                    (shift) => {
                        // check if tagId is null (for ungrouped case) or if shift has specific tagId, or if we are dealing with shifts
                        // in the default tag group, where the shifts will have no tagIds
                        const isInTag = !tagId || shift.tagIds.includes(tagId) || (shift.tagIds.length === 0 && TagUtils.isDefaultTag(tagId));
                        const isShiftForMember = shift.memberId === member.id;
                        if (isInTag && isShiftForMember && ShiftUtils.shiftOverlapsStartsOrEndsBetween(shift, viewStart, viewEnd, false /* includeEdges */)) {
                            memberShiftsForView.push(shift);
                        }
                    });
            }

            if (groupedMemberData && groupedMemberData.timeOffShifts) {
                groupedMemberData.timeOffShifts.forEach(
                    (timeOff) => {
                        const isShiftForMember = timeOff.memberId === member.id;
                        if (isShiftForMember && ShiftUtils.shiftOverlapsStartsOrEndsBetween(timeOff, viewStart, viewEnd, false /* includeEdges */)) {
                            memberShiftsForView.push(timeOff);
                        }
                    });
            }
            memberShiftsData.memberShiftsForView = memberShiftsForView.sort(ShiftUtils.shiftComparator);
        }

        // Get sorted Shifts data for the member
        let groupedMemberDataForMember: GroupedMemberData = ScheduleData.getGroupedMemberDataForMember(groupedShiftsData, member.id, tagId);
        if (groupedMemberDataForMember && groupedMemberDataForMember.shifts) {
            memberShiftsData.memberShifts = groupedMemberDataForMember.shifts.slice(0).sort(ShiftUtils.shiftComparator);
        }

        // Get Shifts data for the member, organized by dates
        for (let dateIndex = 0; dateIndex < datesInRange.length; dateIndex++) {
            const currentDate = datesInRange[dateIndex];
            const dateIndexKey = DateUtils.fastCalculateDateIndex(currentDate);
            const memberShiftsOnDate = ScheduleData.getMemberShiftsOnDate(dateIndexKey, groupedShiftsData, member.id, currentDate, tagId);
            memberShiftsData.memberShiftsByDate.set(dateIndexKey, memberShiftsOnDate);

            let hoursForDate: number = 0;
            memberShiftsOnDate.forEach(shift => {
                hoursForDate += ShiftUtils.getPaidHoursForShift(shift);
            });
            memberShiftsData.hoursByDate.set(dateIndexKey, hoursForDate);
        }

        return memberShiftsData;
    }

    /**
     * Return the shift events (both working shifts and timeoffs) from the grouped shifts data for a member on a date
     * @param dateIndex date index for the date parameter - for performance this is passed in instead of being calculated
     * @param groupedShiftsData grouped shifts data
     * @param memberId id for the member
     * @param date date for the shifts
     * @param tagId tag group for the shifts. pass null to fetch the member's shifts for all tag groups.
     */
    private static getMemberShiftsOnDate(
        dateIndex: number,
        groupedShiftsData: GroupedShiftsData,
        memberId: string,
        date: Moment,
        tagId?: string): IShiftEntity[] {

        let memberShiftsOnDate: IShiftEntity[] = [];

        // Append time off shifts before working shifts. That helps us render timeoff shifts first before other shifts
        const groupedMemberData: GroupedMemberData = groupedShiftsData.groupedMembersData.get(memberId);
        if (groupedMemberData) {
            const timeOffShiftsForDate = groupedMemberData.timeOffShiftsByDate.get(dateIndex);
            if (timeOffShiftsForDate) {
                memberShiftsOnDate = timeOffShiftsForDate.slice(0);
            }
        }

        // Add the working shifts

        let groupedMemberDataForMember: GroupedMemberData = ScheduleData.getGroupedMemberDataForMember(groupedShiftsData, memberId, tagId);
        if (groupedMemberDataForMember) {
            const shiftsForDate = groupedMemberDataForMember.workingShiftsByDate.get(dateIndex);
            if (shiftsForDate) {
                shiftsForDate.sort(ShiftUtils.shiftComparator);
                memberShiftsOnDate = memberShiftsOnDate.concat(shiftsForDate);
            }
        }

        return memberShiftsOnDate;
    }

    /**
     * Get GroupedMemberData for the specified member
     * @param groupedShiftsData
     * @param memberId
     * @param tagId
     */
    private static getGroupedMemberDataForMember(
        groupedShiftsData: GroupedShiftsData,
        memberId: string,
        tagId?: string
    ): GroupedMemberData {
        let groupedMemberDataForMember: GroupedMemberData;
        if (tagId) {
            // If a tag is specified, then get the member data for this tag only
            const groupedTagData: GroupedTagData = groupedShiftsData.groupedTagData.get(tagId);
            if (groupedTagData) {
                groupedMemberDataForMember = groupedTagData.groupedMembersData.get(memberId);
            }
        } else {
            // No tag, so get all of the data for the member
            groupedMemberDataForMember = groupedShiftsData.groupedMembersData.get(memberId);
        }
        return groupedMemberDataForMember;
    }

    /**
     * Calculate a date and time's index value used as a key for data lookups for shifts that fall on the date
     */
    public static calculateDateAndTimeIndex(date: Moment): number {
        return date.unix();
    }

    /**
     * Get members who are assigned to the given tag
     */
    private static getMembersForTag(teamMembers: Array<IMemberEntity>, tag: ITagEntity): IMemberEntity[] {
        let tagMembers: IMemberEntity[] = [];
        if (tag && tag.memberIds) {
            for (let i = 0; i < tag.memberIds.length; i++) {
                const currentMemberId: string = tag.memberIds[i];
                if (currentMemberId) {
                    const currentMember: IMemberEntity = teamMembers.find((member: IMemberEntity) => member.id === currentMemberId);
                    if (currentMember) {
                        tagMembers.push(currentMember);
                    }
                }
            }
        }
        return tagMembers;
    }

    /**
     * Return the TemporalItemTypes enum value for the given shift event
     */
    public static getTemporalTypeForShiftEvent(shiftEvent: IBaseShiftEntity): TemporalItemTypes {
        let temporalItemType = TemporalItemTypes.empty;

        if (shiftEvent) {
            if (ShiftUtils.isWorkingShift(shiftEvent)) {
                if (!ShiftUtils.isOpenShift(shiftEvent)) {
                    temporalItemType = TemporalItemTypes.shift;
                } else {
                    temporalItemType = TemporalItemTypes.openShift;
                }
            } else if (ShiftUtils.isTimeOffEvent(shiftEvent)) {
                if (!ShiftUtils.isOpenShift(shiftEvent)) {
                    temporalItemType = TemporalItemTypes.timeOff;
                } else {
                    // TODO: open time off shift will be added here
                }
            } else if (ShiftUtils.isTimeOffRequestEvent(shiftEvent)) {
                temporalItemType = TemporalItemTypes.timeOffRequest;
            }
        }

        return temporalItemType;
    }

    /**
     * Returns an ordered list of temporal items that represent the shifts, empty cells, time off requests, etc. that make up a row.
     * Uses data from parameters. The caller should  prune the list of shifts that are passed in to eliminate overlapping shifts.
     * Each temporal item is rounded to the nearest quarter hour.
     *
     * @param viewStart
     * @param viewEnd
     * @param orderedShifts
     */
    public static getOrderedTemporalItemsForRowInView(
        viewStart: Moment,
        viewEnd: Moment,
        orderedItems: TemporalItem[],
        roundMomentForTemporalItem: (momentTime: Moment, isStartTime: boolean) => Moment) {
        let orderedTemporalItems: TemporalItem[] = [];
        if (!orderedItems || !viewStart || !viewEnd) {
            return orderedTemporalItems;
        }
        let orderedItemsCopy = orderedItems ? orderedItems.slice() : []; // Not a deep copy, but we are not mutating the shifts so this is ok.

        // We will traverse the view temporally from start to finish, building a list of the various temporal items that comprise the view for the row.
        let currTime = viewStart.clone();
        while (currTime.isBefore(viewEnd)) {
            // Get the next item, if there is one.
            let nextItem: TemporalItem = orderedItemsCopy.shift();
            if (nextItem) {
                // Make sure start and end time for shifts outside the view boundaries are adjusted to start and end at the view boundaries
                const itemStartInView = nextItem.startTime.isBefore(viewStart) ? viewStart : nextItem.startTime.clone();
                const itemEndInView = nextItem.endTime.isAfter(viewEnd) ? viewEnd : nextItem.endTime.clone();

                const itemTemporalType = nextItem.type;
                const itemStartInViewRounded = roundMomentForTemporalItem(itemStartInView, true /* isStartTime */);
                const itemEndInViewRounded = roundMomentForTemporalItem(itemEndInView, false /* isStartTime */);

                // If the item starts after the currTime marker, create a temporal item to span the gap from
                // the curr time to the start of the item.
                if (currTime.isBefore(itemStartInViewRounded)) {
                    const emptyItem: TemporalItem = {
                        startTime: roundMomentForTemporalItem(currTime, true /* isStartTime */),
                        endTime: roundMomentForTemporalItem(itemStartInViewRounded, false /* isStartTime */),
                        type: TemporalItemTypes.empty
                    };
                    orderedTemporalItems.push(emptyItem);
                    currTime = itemStartInView.clone();
                }

                // Create a temporal item to span the duration of the shift.
                const shiftItem: TemporalItem = {
                    startTime: itemStartInViewRounded,
                    endTime: itemEndInViewRounded,
                    type: itemTemporalType,
                    shiftEvent: nextItem.shiftEvent
                };
                orderedTemporalItems.push(shiftItem);

                // advance the currTime marker
                currTime = roundMomentForTemporalItem(itemEndInViewRounded, true /* isStartTime */);
            } else {
                // If there are no shifts left, add an empty temporal item to span the gap between the currTime and the end of the view

                const currTimeRounded = roundMomentForTemporalItem(currTime, true /* isStartTime */);
                const viewEndRounded = roundMomentForTemporalItem(viewEnd, false /* isStartTime */);
                if (currTimeRounded.isBefore(viewEndRounded)) {
                    const emptyItem: TemporalItem = {
                        startTime: currTimeRounded,
                        endTime: viewEndRounded,
                        type: TemporalItemTypes.empty
                    };
                    orderedTemporalItems.push(emptyItem);
                }

                break;
            }
        }

        return orderedTemporalItems;
    }

    /**
     * Returns a list of sorted activities that contains no activities that don't overlap with the start and end time range
     * @param activities
     * @param start
     * @param end
     */
    public static filterActivitiesByTime(activities: ISubshiftEntity[], start: Moment, end: Moment): ISubshiftEntity[] {
        return activities
                .slice() // shallow copy is fine as activities are not being modified
                .sort(ShiftUtils.shiftComparator)
                .filter((activity: ISubshiftEntity) => {
                    // filter out activities that end before the list should begin or start after the list should end
                    return activity.endTime.isAfter(start) && activity.startTime.isBefore(end);
                });
    }

    /**
     * Returns an ordered list of temporal items that represent the subshifts and regular shift durations that comprise a shift. If view start and view end are
     * provided, limit this list only to those items that fit within the view.
     *
     * @param shift
     * @param viewStart
     * @param viewEnd
     */
    public static getOrderedTemporalItemsForShift(shift: IShiftEntity, viewStart?: Moment, viewEnd?: Moment) {
        let orderedTemporalItems: TemporalItem[] = [];
        if (!shift) {
            return orderedTemporalItems;
        }
        let listStart = shift.startTime.clone();
        let listEnd = shift.endTime.clone();

        if (viewStart && viewEnd) {
            const { startTime , endTime } = ShiftUtils.getShiftTimesInView(shift, viewStart, viewEnd);
            listStart = startTime;
            listEnd = endTime;
        }

        // We will traverse the shift  from start to finish, building a list of the various temporal items that comprise the shift
        let currTime = listStart.clone();
        let activitiesCopy = ScheduleData.filterActivitiesByTime(shift.subshifts, listStart, listEnd);

        while (currTime.isBefore(listEnd)) {
            // Get the next activity in the shift, if there is one.
            let nextActivity = activitiesCopy.shift();
            if (nextActivity) {
                const temporalItemStart = DateUtils.getTimeLimitedByLowerBound(nextActivity.startTime, listStart);
                const temporalItemEnd = DateUtils.getTimeLimitedByUpperBound(nextActivity.endTime, listEnd);

                // If the activity starts after the currTime marker, create a temporal item to span the gap from
                // the curr time to the start of the activity.
                if (currTime.isBefore(temporalItemStart)) {
                    const emptyItem: TemporalItem = {
                        startTime: DateUtils.roundMomentToQuarterHours(currTime),
                        endTime: DateUtils.roundMomentToQuarterHours(temporalItemStart),
                        type: TemporalItemTypes.empty
                    };
                    orderedTemporalItems.push(emptyItem);
                    currTime = temporalItemStart.clone();
                }
                // Create a temporal item to span the duration of the activity, but limit the start and end time to fit into the list (overnight shifts)
                const activityItem: TemporalItem = {
                    startTime: DateUtils.roundMomentToQuarterHours(temporalItemStart),
                    endTime: DateUtils.roundMomentToQuarterHours(temporalItemEnd),
                    type: TemporalItemTypes.activity
                };
                orderedTemporalItems.push(activityItem);

                // advance the currTime marker
                currTime = temporalItemEnd.clone();

                // If there are no activities) left, add an empty temporal item to span the gap between the currTime and the end of the view
            } else {
                const emptyItem: TemporalItem = {
                    startTime: DateUtils.roundMomentToQuarterHours(currTime),
                    endTime: DateUtils.roundMomentToQuarterHours(listEnd),
                    type: TemporalItemTypes.empty
                };
                orderedTemporalItems.push(emptyItem);
                currTime = listEnd.clone();
            }
        }
        return orderedTemporalItems;
    }

    /**
     * For a tag, find the number of shifts that overlap with time slots (ex: 5am - 5:30am is one time slot)
     * @param memberShiftsGroupData
     * @param startDateAndTime
     * @param endDateAndTime
     */
    public static getNumShiftsForMemberShiftsInTimeslots(memberShiftsGroupData: MemberShiftsDataGroup, startDateAndTime: Moment, endDateAndTime: Moment): number[] {

        let numShiftsArray: number[] = [];
        let currSlotStart = startDateAndTime.clone();
        let currSlotEnd = currSlotStart.clone().add(DayViewFormatCellInterval, 'hours');
        const numSlots: number = DateUtils.getNumberOfSlotsInRange(startDateAndTime, endDateAndTime, DayViewFormatCellInterval);

        // Note: Any logic changes should also be done in getNumShiftsForGroupedDataInTimeslots method, which is used for Shifts view
       for (let i = 0; i < numSlots; i++) {
            let numShiftsInTimeslot: number = 0;
            if (memberShiftsGroupData && memberShiftsGroupData.members && memberShiftsGroupData.memberShiftsData) {
                for (let i = 0; i < memberShiftsGroupData.members.length; i++) {
                    let memberId = memberShiftsGroupData.members[i].id;
                    let memberShiftsData: MemberShiftsData = memberShiftsGroupData.memberShiftsData.get(memberId);
                    if (memberShiftsData && memberShiftsData.memberShiftsForView) {
                        let memberShiftsForView: IShiftEntity[] = memberShiftsData.memberShiftsForView;
                        // Do not include the start/end edge times. 8am shift start should count against 8:00-8:30 slot and not against 7:30-8:00 slot
                        memberShiftsForView.forEach(shift => {
                            if (!ShiftUtils.isTimeOffEvent(shift) && !ShiftUtils.isTimeOffRequestEvent(shift) && ShiftUtils.shiftOverlapsStartsOrEndsBetween(shift, currSlotStart, currSlotEnd, false /* includeEdges */)) {
                                numShiftsInTimeslot++;
                            }
                        });
                    }
                }
            }
            numShiftsArray.push(numShiftsInTimeslot);

            // Move the time slot forward
            currSlotStart.add(DayViewFormatCellInterval, 'hours');
            currSlotEnd.add(DayViewFormatCellInterval, 'hours');
        }
        return numShiftsArray;
    }

    /**
     * For a tag, find the number of shifts that overlap with time slots (ex: 5am - 5:30am is one time slot)
     * @param groupedTagData
     * @param startDateAndTime
     * @param endDateAndTime
     */
    public static getNumShiftsForGroupedDataInTimeslots(groupedTagData: GroupedTagData, startDateAndTime: Moment, endDateAndTime: Moment): number[] {

        let numShiftsArray: number[] = [];
        let currSlotStart = startDateAndTime.clone();
        let currSlotEnd = currSlotStart.clone().add(DayViewFormatCellInterval, 'hours');
        const numSlots: number = DateUtils.getNumberOfSlotsInRange(startDateAndTime, endDateAndTime, DayViewFormatCellInterval);

        const dateIndex = DateUtils.fastCalculateDateIndex(startDateAndTime);

        // Note: Any logic changes here should also be done in getNumShiftsForMemberShiftsInTimeslots method, which is used for People view
        for (let i = 0; i < numSlots; i++) {
            let numMembersInTimeslot: number = 0;
            if (groupedTagData && groupedTagData.uniqueShifts && groupedTagData.groupedUniqueShiftData) {
                for (let i = 0; i < groupedTagData.uniqueShifts.length; i++) {
                    let uniqueShift = groupedTagData.uniqueShifts[i];

                    // uniqueShift start and end times don't necessarily have the date set to current view date
                    let shiftStartTime = uniqueShift.startTime.clone().year(startDateAndTime.year()).month(startDateAndTime.month()).date(startDateAndTime.date());
                    let shiftEndime = uniqueShift.endTime.clone().year(startDateAndTime.year()).month(startDateAndTime.month()).date(startDateAndTime.date());

                    // Do not include the start/end edge times. 8am shift start should count against 8:00-8:30 slot and not against 7:30-8:00 slot
                    if (DateUtils.overlapsStartsOrEndsBetween(shiftStartTime, shiftEndime, currSlotStart, currSlotEnd, false /* includeStartEdge */, false /* includeEndEdge */)) {
                        const groupedUniqueShiftData: GroupedUniqueShiftData = groupedTagData.groupedUniqueShiftData.get(uniqueShift.id);
                        const memberIds: string[] = groupedUniqueShiftData && groupedUniqueShiftData.memberIdsByDate && groupedUniqueShiftData.memberIdsByDate.get(dateIndex);
                        numMembersInTimeslot += memberIds ? memberIds.length : 0;
                    }
                }
            }
            numShiftsArray.push(numMembersInTimeslot);

            // Move the time slot forward
            currSlotStart.add(DayViewFormatCellInterval, 'hours');
            currSlotEnd.add(DayViewFormatCellInterval, 'hours');
        }
        return numShiftsArray;
    }

    /**
     * Helper function that returns true if the comparisonItem overlaps with any item within itemList
     * @param candidateItem
     * @param itemList
     * @param includeEdges
     */
    public static itemOverlapsWithTemporalItemList(candidateItem: TemporalItem, itemList: TemporalItem[], includeEdges: boolean) {
        let overlaps = false;
        for (let i = 0; i < itemList.length; i++) {
            const comparisonItem = itemList[i];
            if (DateUtils.overlapsStartsOrEndsBetween(candidateItem.startTime, candidateItem.endTime, comparisonItem.startTime, comparisonItem.endTime, includeEdges /* includeStartEdge */, includeEdges /* includeEndEdge */)) {
                overlaps = true;
                break;
            }
        }

        return overlaps;
    }

    /**
     * Takes a list of TemporalItems and return a list of lists, where each list is composed of non-overlapping TemporalItems in chronological order.
     * @param allItems
     * @param allowAdjacent
     */
    public static getNonOverlappingTemporalItemLists(allItems: TemporalItem[], allowAdjacent: boolean = true): TemporalItem[][] {
        let allItemsCopy = allItems ? allItems.slice() : [];
        let nonOverlappingTemporalItemsLists: TemporalItem[][] = [[]];

        // Assume allItems is sorted so that first item has earliest start time

        // Construct a list of lists of non overlapping items:
        //  -pop earliest item off of allItems
        //  -traverse allItems, examining items. Compare the current item to each list in nonOverlappingTemporalItemsLists.
        //   If an item does not overlap with any of the items in the list, remove it from allItems and add it to the list
        //   If an item does not fit into any of the existing lists, construct a new list and place the item in it

        while (allItemsCopy.length) {
            const candidateItem = allItemsCopy.shift();
            let itemPlaced = false;
            for (let i = 0; i < nonOverlappingTemporalItemsLists.length; i++) {
                if (!ScheduleData.itemOverlapsWithTemporalItemList(candidateItem, nonOverlappingTemporalItemsLists[i], !allowAdjacent)) {
                    nonOverlappingTemporalItemsLists[i].push(candidateItem);
                    itemPlaced = true;
                    break;
                }
            }

            if (!itemPlaced) {
                nonOverlappingTemporalItemsLists.push([candidateItem]);
            }
        }

        return nonOverlappingTemporalItemsLists;
    }

    /**
     * Returns a list of open shift temporal items for the user in the provided view range within the provided map of open shifts by date
     * @param currentUser
     * @param openShiftsByDate
     * @param scheduleViewSettings
     * @param roundMomentForTemporalItem
     * @param groupByDays - if this boolean is true, temporal items will be rounded off to end at the end of the day of the start time
     */
    public static getTemporalItemsForOpenShiftRows(
        currentUser: IMemberEntity,
        openShiftsByDate: Map<number, IOpenShiftEntity[]>,
        scheduleViewSettings: IScheduleViewSettings,
        roundMomentForTemporalItem: (momentTime: Moment, isStartTime: boolean) => Moment,
        groupByDays?: boolean): TemporalItem[] {
        let temporalItems: TemporalItem[] = [];
        const daysRange = DateUtils.fastGetDatesInRange(scheduleViewSettings.viewStartDate, scheduleViewSettings.viewEndDate);

        for (let i = 0; i < daysRange.length; i++) {
            const dayStart: Moment = daysRange[i];
            const openShiftsForDate = openShiftsByDate.get(DateUtils.fastCalculateDateIndex(dayStart));
            if (openShiftsForDate) {

                // for week and month view, add open shifts for each date to the temporal item array
                for (let i = 0; i < openShiftsForDate.length; i++) {
                    const openShiftForDate: IOpenShiftEntity = openShiftsForDate[i];
                    const tagId: string = ShiftUtils.getTagIdFromShift(openShiftForDate);
                    const tag: ITagEntity = TagStore().tags.get(tagId);
                    // non-admins should not see open shifts for groups they are not in
                    if (ScheduleGridUtils.showOpenShiftsForTag(tag, currentUser)) {
                        const temporalItemStartTime = roundMomentForTemporalItem(openShiftForDate.startTime, true /* isStartTime */);
                        let temporalItemEndTime = roundMomentForTemporalItem(openShiftForDate.endTime, false /* isStartTime */);
                        // In week and month view, shifts are always rendered as a single day schedule cell that is placed on the starting day,
                        // so here we set the shift's end time to end of the shift's starting day.
                        if (groupByDays) {
                            temporalItemEndTime = roundMomentForTemporalItem(temporalItemStartTime.clone().endOf("day"), false /* isStartTime */);
                        }

                        temporalItems.push(
                            {
                                startTime: temporalItemStartTime,
                                endTime: temporalItemEndTime,
                                type: ScheduleData.getTemporalTypeForShiftEvent(openShiftForDate),
                                shiftEvent: openShiftForDate
                            }
                        );
                    }
                }

            }
        }

        return temporalItems;
    }
}