import { action } from "satcheljs";

import { SetSearchErrorAction } from "./SetSearchErrorAction";

/**
 * Sets the search error.
 */
export const setSearchError = action<SetSearchErrorAction, (searchError?: Error) => SetSearchErrorAction>(
    "SET_SEARCH_ERROR",
    (searchError?: Error) => ({ searchError })
);
