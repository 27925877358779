import * as React from "react";
import StringsStore from "sh-strings/store";
import TeamSetupPickerUtils from "../TeamSetupPickerUtils";
import { fireAccessibilityAlert } from "sh-application/components/accessibilityAlert";
import { getGenericEventPropertiesObject, logPageView } from "sh-instrumentation";
import { InstrumentationService } from "sh-services";
import { scheduleStartupFREs } from "sh-models";
import { setFREsShownSoFar } from "sh-stores";
import { SetupNavigation } from "../SetupNavigation";
import { showWelcome } from "sh-application";
import { TeamSetupPickerStepProps } from "../TeamSetupPickerStepProps";

const styles = require("./TeamSetupStartTour.scss");

const WelcomeImage = require("-!svg-react-loader?name=WelcomeImage!../../../../../../assets/images/optimized-svg/FRE_Shifts_desktop_welcome.svg");

/**
 * React component for the start tour info step in the new team onboarding flow
 */
@logPageView("Setup.StartTour")
export default class TeamSetupStartTour extends React.Component<TeamSetupPickerStepProps, {}> {
    private _strings: Map<string, string>;

    constructor(props: TeamSetupPickerStepProps) {
        super(props);
        this._strings = StringsStore().registeredStringModules.get("teamSetupStartTour").strings;

        this.instrumentLoadScreen();
    }

    componentDidMount() {
        // Fire accessibility alert because the heading content doesn't get read via focus.
        // The team setup dialog is reused between steps and only its inner content is changed
        fireAccessibilityAlert(this._strings.get("startTourDialogAriaAlert"));
    }

    /**
     * Instrument load of this view
     */
    private instrumentLoadScreen() {
        InstrumentationService.logEvent(
            this.getInstrumentationEventName(),
            [getGenericEventPropertiesObject(InstrumentationService.properties.EventType, InstrumentationService.values.StartTourSetupViewed)]);
    }

    /**
     * Handler for when the begin tour button is clicked
     */
    private handleBeginTourClicked = () => {
        InstrumentationService.logEvent(
            this.getInstrumentationEventName(),
            [getGenericEventPropertiesObject(InstrumentationService.properties.EventType, InstrumentationService.values.StartTourClicked)]);

        showWelcome(false /* visible */);
    }

    /**
     * Handler for when the skip button is clicked
     */
    private handleSkipClicked = () => {
        InstrumentationService.logEvent(
            this.getInstrumentationEventName(),
            [getGenericEventPropertiesObject(InstrumentationService.properties.EventType, InstrumentationService.values.SkipTourClicked)]);

        this.markStartupToursAsSeen();
        this.props.onClose();
    }

    /**
     * Mark tours that launch upon startup as seen
     */
    private markStartupToursAsSeen() {
        setFREsShownSoFar(scheduleStartupFREs);
    }

    private getInstrumentationEventName(): string {
        return TeamSetupPickerUtils.getInstrumentationEventNameForProvisionScheduleTeam(this.props.isOnboarding);
    }

    render() {
        return (
            <div className={ styles.panel } role="region" aria-label={ this._strings.get("startTourTitle") }>
                <div className={ styles.content }>
                    <div className={ styles.header }>
                        <h3 className={ styles.headerTitle }>{ this._strings.get("startTourTitle") }</h3>
                        <div className={ styles.headerSubtitle }>{ this._strings.get("startTourDescription") }</div>
                    </div>
                    <WelcomeImage className={ styles.welcomeImage } />
                </div>
                <SetupNavigation
                    onOk={ this.handleBeginTourClicked }
                    okButtonLabel={ this._strings.get("beginTourButtonLabel") }
                    onCancel={ this.handleSkipClicked }
                    cancelButtonLabel={ this._strings.get("skipButtonLabel") }
                    showStepIndicator={ false } />
            </div>
        );
    }
}