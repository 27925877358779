import { conflictRequestStore } from "..";
import { ConflictType, IAvailabilityConflictEntity, IAvailabilityEntity, IShiftEntity, AvailabilityConflictEntity } from "sh-models";
import { mutatorAction } from "satcheljs";

export default mutatorAction('SET_REQUEST_AVAILABILITY_CONFLICT_IN_STORE', function setRequestAvailabilityConflictInStore(shift: IShiftEntity, conflictingAvailability: IAvailabilityEntity, memberId?: string) {
    // If a conflict entity is already added for the shift, verify we are not adding twice
    const conflictData = conflictRequestStore().requestShiftIdToConflictsMap.get(shift.id);

    let alreadyAdded = false;
    if (conflictData && conflictData.length > 0) {
        conflictData.forEach((conflict: IAvailabilityConflictEntity) => {
            if (conflict.availabilityId === conflictingAvailability.id) {
                alreadyAdded = true;
            }
        });
    }
    // create a new conflict entity to save in conflictRequestStore()
    if (!alreadyAdded) {
        const conflictAvailabilityEntity: IAvailabilityConflictEntity = new AvailabilityConflictEntity(
            memberId,
            shift.id,
            ConflictType.ShiftAvailabilityConflict,
            false,
            conflictingAvailability.id
        );

        let availabilityArray = conflictRequestStore().requestShiftIdToConflictsMap.get(shift.id) || [];
        availabilityArray.push(conflictAvailabilityEntity);
        conflictRequestStore().requestShiftIdToConflictsMap.set(shift.id, availabilityArray);
    }
});