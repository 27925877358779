import { ITeamMemberPermissionsEntity, TeamMemberPermissionsEntity } from "../../sh-models";
import RestClient from "../../sh-rest-client";
import { TeamMemberPermissionsStore, setTeamMemberPermissions } from "../../sh-stores/sh-team-permissions-store";
import { ITeamDatabase } from "../data/ITeamDatabase";

import CurrentTeamDataProvider from "./CurrentTeamDataProvider";

/**
 * Team Member Permissions Data Provider
 */
export class TeamMemberPermissionsDataProvider extends CurrentTeamDataProvider<ITeamMemberPermissionsEntity> {
    protected teamDatabase: ITeamDatabase;
    protected tenantId: string;
    protected teamId: string;

    /**
     * Constructor for TeamMemberPermissionsDataProvider.
     * @param teamDatabase Team Database.
     * @param teamId Team Id.
     * @param tenantId Tenant Id.
     */
    constructor(teamDatabase: ITeamDatabase, teamId: string, tenantId: string) {
        super(teamId);
        this.teamDatabase = teamDatabase;
        this.teamId = teamId;
        this.tenantId = tenantId;
    }

    /**
     * Return data from memory.
     */
    async getDataFromMemoryForCurrentTeam(): Promise<ITeamMemberPermissionsEntity> {
        const { permissions } = TeamMemberPermissionsStore();

        return permissions;
    }

    /**
     * Return data if it's found in the database (otherwise return undefined).
     */
    async getDataFromDatabase(): Promise<ITeamMemberPermissionsEntity> {
        return await this.teamDatabase.getTeamMemberPermissions(this.teamId);
    }

    /**
     * Return default data.
     */
    async getDefaultData(): Promise<ITeamMemberPermissionsEntity> {
        // set TeamPermissionsStore with default TeamPermissionEntity
        return new TeamMemberPermissionsEntity();
    }

    /**
     * Make an HTTP request to fetch the data from the network.
     */
    async getDataFromNetwork(): Promise<ITeamMemberPermissionsEntity> {
        return await RestClient.getTeamMemberPermissions(this.tenantId, this.teamId);
    }

    /**
     * Set data in memory.
     * @param data ITeamPermissionEntity.
     */
    async setDataInMemoryForCurrentTeam(data: ITeamMemberPermissionsEntity): Promise<void> {
        setTeamMemberPermissions(data);
    }

    /**
     * Set data in the database.
     * @param data ITeamPermissionEntity.
     */
    async setDataInDatabase(data: ITeamMemberPermissionsEntity): Promise<void> {
        if (data) {
            return await this.teamDatabase.setTeamMemberPermissions(this.teamId, data);
        }
    }

    /**
     * Make a network call to update the data.
     */
    async saveDataToNetwork(data: ITeamMemberPermissionsEntity ): Promise<ITeamMemberPermissionsEntity> {
        // TODO: Make it be taking Patrial<T> instead of <T>
        await RestClient.patchTeamMemberPermissions(this.tenantId, this.teamId, data);

        const { permissions } = TeamMemberPermissionsStore();
        const updatedPermissions: ITeamMemberPermissionsEntity = {
            ...permissions,
            ...data
        };

        this.setDataInMemory(updatedPermissions);
        this.setDataInDatabase(updatedPermissions);

        return updatedPermissions;
    }
}
